import React, { FunctionComponent } from 'react'
import { Path, Svg } from 'react-native-svg'

import { tID } from '../../utils'

type MiscellaneousIllustrationProps = {
  size?: string | number
  selected?: string
}

export const MiscellaneousIllustration: FunctionComponent<MiscellaneousIllustrationProps> = ({
  selected = false,
  size,
}) => (
  <Svg
    width={size}
    height={size}
    viewBox='0 0 48 48'
    fill='none'
    testID={tID(`somethingElse${selected ? '-Selected-' : '-'}Illustration`)}
  >
    <Path
      d='M24.4089 33.2014C24.5936 32.0913 24.7742 30.9812 24.9507 29.8691C25.1353 28.7036 25.4554 27.4539 25.4821 26.2741C25.5149 24.8623 24.5649 24.4684 23.3953 24.294C22.7428 24.0334 21.963 23.9308 21.3331 23.7912C19.5705 23.4034 17.7997 23.0505 16.0227 22.7324C12.5262 22.1086 9.00506 21.6264 5.46958 21.2756C5.37929 21.2674 5.29311 21.2674 5.20898 21.2756C4.66522 21.136 3.94704 21.372 3.75211 21.9301C2.16186 26.4772 0.871191 31.1515 0.0688841 35.9038C-0.0993746 36.8949 -0.00293297 37.7854 0.852724 38.4236C1.6776 39.0392 2.89645 39.152 3.87317 39.3777C6.25342 39.9277 8.64392 40.4222 11.0467 40.8613C13.3921 41.2901 15.7457 41.6698 18.1075 41.994C19.9727 42.2504 22.4781 42.8763 23.1121 40.4673C23.7318 38.1117 24.0068 35.6022 24.4089 33.1994V33.2014ZM5.94563 36.8744C5.94563 36.8744 5.94358 36.8662 5.94152 36.86C5.96204 36.8415 5.98462 36.8231 6.00513 36.8046C6.02771 36.8067 6.04617 36.8128 6.06874 36.8149C6.0749 36.8149 6.08106 36.8149 6.08721 36.8149C6.1857 36.8641 6.28625 36.9031 6.38885 36.94C6.38885 36.9441 6.3909 36.9503 6.39295 36.9544L5.94563 36.8723V36.8744Z'
      fill={selected ? '#3BA5B5' : '#FFAF9B'}
    />
    <Path
      d='M36.6671 9.15217C33.6446 4.05721 25.168 1.88627 20.8589 6.63855C18.7701 8.94082 17.2722 12.2301 17.0629 15.3367C16.87 18.2094 18.1647 21.0431 20.3747 22.8694C21.2652 23.604 22.2419 24.1457 23.2597 24.5068C25.5804 25.779 28.4367 26.0765 31.1884 24.9131C36.8168 22.5308 39.825 14.479 36.6671 9.15422V9.15217Z'
      fill={selected ? '#85CCD5' : '#85CCD5'}
    />
    <Path
      d='M47.9937 29.5046C47.8295 27.9451 45.558 27.703 44.3186 27.4732C42.7304 27.1777 41.1135 27.0238 39.5089 26.8309C36.0104 26.4103 32.5098 25.9876 29.0112 25.567C28.4079 25.4951 27.9175 26.0389 27.8313 26.597C27.8149 26.6401 27.8026 26.6832 27.7924 26.7284C27.6774 27.2639 27.6959 27.7851 27.8437 28.2714C27.8478 28.3822 27.8662 28.4992 27.9073 28.6223C29.7437 34.1153 32.2635 39.3703 35.4276 44.2457C35.8277 44.8613 36.7593 45.0542 37.3051 44.4838C39.472 42.2123 41.4911 39.8094 43.3542 37.2938C44.255 36.077 45.1189 34.8314 45.9397 33.5592C46.3788 32.8821 46.8056 32.1968 47.2201 31.5053C47.5853 30.8958 48.0696 30.2474 47.9916 29.5067L47.9937 29.5046Z'
      fill={selected ? '#66BAC5' : '#FFC03C'}
    />
  </Svg>
)
