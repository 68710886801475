import React from 'react'
import { useIntl } from 'react-intl'
import { View } from 'react-native'

import styled from 'styled-components/native'

import { BodyText, PrimaryButton } from '../../atoms'
import { BodyTextSize, getFontStyles, TextType } from '../../styles'
import { ThemeType, tID } from '../../utils'

const AssignmentsNoneContainer = styled(View)<{ theme: ThemeType }>(({ theme: { colors } }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: colors.backgroundSecondary,
  borderRadius: '12px',
  textAlign: 'center',
  padding: '30px 0',
  ...getFontStyles(colors)[TextType.BODY][BodyTextSize.DEFAULT],
}))

const AssignmentsNoneButtonContainer = styled(View)<{
  theme: ThemeType
}>(({ theme }) => ({
  alignSelf: 'center',
  marginTop: theme.spacing['16px'],
}))

export const AssignmentsNone: React.FC<AssignmentsNoneProps> = ({ addActivityHandler, noDataText }) => {
  const intl = useIntl()

  return (
    <AssignmentsNoneContainer>
      <BodyText
        text={
          noDataText ||
          intl.formatMessage({
            defaultMessage: 'No activities shared to this session',
            description: 'There are no activities being shared in the current session',
          })
        }
      />
      {addActivityHandler && (
        <AssignmentsNoneButtonContainer>
          <PrimaryButton
            testID={tID('AssignmentList-addActivityButton')}
            onPress={addActivityHandler}
            text={intl.formatMessage({
              defaultMessage: '+ Add Activity',
              description:
                'Button to add a new activity. The plus sign at the beginning signifies adding something new.',
            })}
          />
        </AssignmentsNoneButtonContainer>
      )}
    </AssignmentsNoneContainer>
  )
}

export interface AssignmentsNoneProps {
  addActivityHandler?: () => void | null
  noDataText?: string
}
