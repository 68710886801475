import React, { useState, FunctionComponent } from 'react'
import BaseModal from '../../../molecules/modal/BaseModal'
import PrimaryButton from '../primaryButton/PrimaryButton'
import FilterButtonModal, { FilterOptions } from '../../../molecules/modal/filterButtonModal/FilterButtonModal'
import styles from './filterButton.module.scss'

// A button that pops up a modal "FilterButtonModal" when pressed.
// Used in Payment Admin portal to apply filters to invoices.
const FilterButton: FunctionComponent<FilterButtonProps> = ({
  filterName,
  filterOptions,
  filterArray,
  saveOnClick,
}) => {
  const [buttonPressed, setButtonPressed] = useState(false)

  const closeModal = () => setButtonPressed(false)

  const modalBody = (
    <FilterButtonModal
      filterName={filterName}
      filterOptions={filterOptions}
      filterArray={filterArray}
      saveOnClick={saveOnClick}
      closeModal={closeModal}
    />
  )

  return (
    <div className={styles['filter-button']}>
      <PrimaryButton onClick={() => setButtonPressed(true)}>{filterName}</PrimaryButton>
      <BaseModal
        isOpen={buttonPressed}
        body={modalBody}
        closeModal={closeModal}
        title={undefined}
        modalClass={undefined}
        size={undefined}
        footer={undefined}
        customStyles={undefined}
      />
    </div>
  )
}

type FilterButtonProps = {
  filterName: string
  filterOptions: FilterOptions
  filterArray: Array<string>
  saveOnClick: () => void
}

export default FilterButton
