import { useState } from 'react'

import { useApptsByDateTime } from './useApptsByDateTime'
import { Appointment } from '../../../models'
import { useInterval } from '../../common/hooks/useInterval'

/**
 * This hook handles checking appointments every second for any active appointments that could be
 * available to show to the user
 */
export const useApptToShow = ({
  appts,
  reminderWindow,
}: {
  appts: Appointment[]
  reminderWindow: number
}): { apptToShow: Appointment | null } => {
  const [apptToShow, setApptToShow] = useState<Appointment | null>(null)
  const { activeAppts, futureAppts, getApptsByDateTime } = useApptsByDateTime({
    appts,
    decrement: reminderWindow,
    unitOfTime: 'minutes',
    sort: true,
  })
  // if there are appointments active or in the future, tick away
  const shouldTick = activeAppts.length > 0 || futureAppts.length > 0 || apptToShow
  // if there is an availableAppt OR an appointmentBanner current shown, tick
  useInterval(
    () => {
      // make re-renders to useApptsByDateTime happen every interval to check on appts
      getApptsByDateTime()
      if (apptToShow) {
        // see if the appt showing is still an active appt, if not, hide the banner by setting apptToShow to null
        const matchedAppt = activeAppts.find((appt) => apptToShow.appointmentId === appt.appointmentId)
        if (!matchedAppt) setApptToShow(null)
      } else {
        if (activeAppts.length > 0) setApptToShow(activeAppts[0])
      }
    },
    shouldTick ? 1000 : null,
  )
  return { apptToShow }
}
