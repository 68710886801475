import React, { FunctionComponent, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { View } from 'react-native'

import { flexRender, getCoreRowModel, getPaginationRowModel, useReactTable } from '@tanstack/react-table'
import styled, { useTheme } from 'styled-components/native'

import { ButtonModifier, ButtonSize } from '../../atoms/baseButton/BaseButton'
import { BaseText } from '../../atoms/baseText/BaseText'
import { BodyText, Size } from '../../atoms/bodyText/BodyText'
import { IconButton } from '../../atoms/iconButton/IconButton'
import { ChevronIcon, ChevronIconDirection } from '../../atoms/icons/ChevronIcon'
import { BodyTextSize, TextType } from '../../styles/typeStyles'
import { Flex1View } from '../../templates/content/CommonViews'
import { tID } from '../../utils'

export type DataTableProps = {
  columns: any
  data: Dict[]
  emptyTableText?: string
  headerColor?: string
  headerSize?: BodyTextSize
  bodySize?: BodyTextSize
  pagination?: boolean
  pageSize?: number
  striped?: boolean
  rowHeight?: string
  cellMargin?: string
  headerBackgroundColor?: string
  headerBottomBorderColor?: string
  headerBottomPadding?: string
  tableHeight?: string
  tableZebraColor?: string
}

export const DataTable: FunctionComponent<DataTableProps> = ({
  columns,
  data,
  emptyTableText = 'No data available',
  headerColor,
  headerSize = Size.CAPTION,
  bodySize = Size.DEFAULT,
  pagination = true,
  pageSize = 10,
  striped = true,
  rowHeight = '36px',
  cellMargin = '0',
  tableHeight,
  headerBackgroundColor,
  headerBottomBorderColor,
  headerBottomPadding = '8px',
  tableZebraColor,
}) => {
  const { colors, spacing: themeSpacing } = useTheme()
  const { formatMessage } = useIntl()

  const TableContainer = styled(View)({
    height: tableHeight ?? 'auto',
    overflow: 'auto',
  })

  const Table = styled(Flex1View)({
    alignItems: 'center',
    justifyContent: 'center',
  })

  const Header = styled(Flex1View)({
    alignSelf: 'stretch',
    flexDirection: 'row',
    padding: '8px',
    paddingBottom: headerBottomPadding,
    borderBottomWidth: '3px',
    borderBottomColor: headerBottomBorderColor || colors.borderDefault,
    margin: 0,
    position: 'sticky',
    top: 0,
    backgroundColor: headerBackgroundColor ?? 'transparent',
  })

  const Row = styled(Flex1View)<{ rowHeight: string }>(({ rowHeight }) => ({
    alignSelf: 'stretch',
    flexDirection: 'row',
    padding: '8px',
    height: rowHeight,
  }))

  const Cell = styled.View<{ cellMargin: string }>(({ cellMargin }) => ({
    flex: 1,
    display: 'inline-block',
    textAlign: 'center',
    margin: cellMargin,
  }))

  const MultipleLineCell = styled.View({
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
  })

  const ButtonContainer = styled.View({
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    marginTop: '10px',
  })

  const EmptyTable = styled(BodyText)({
    margin: themeSpacing['48px'],
  })

  const PageNumber = styled(BaseText)({
    fontSize: '17px',
    margin: '-3px 10px 0px 10px',
  })

  const PaginationButton = styled(IconButton)<{ disabled?: boolean }>(({ disabled }) => ({
    border: `1px solid ${disabled ? colors.borderDefault : colors.borderInput}`,
    borderRadius: '5',
    width: '20px',
    height: '20px',
    padding: '3px',
    margin: '5px',
  }))

  const standardTableArgs = {
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  }

  const paginationArg = {
    getPaginationRowModel: getPaginationRowModel(),
  }

  const tableArgs = pagination ? { ...standardTableArgs, ...paginationArg } : standardTableArgs

  const table = useReactTable(tableArgs)

  useEffect(() => {
    if (pagination) {
      table.setPageSize(Number(pageSize))
    }
  }, [pagination, pageSize, table])

  const renderPageNumbers = () => {
    return [...Array(table.getPageCount())].map((element, ind) => (
      <PageNumber
        key={ind + 1}
        size={Size.DEFAULT}
        color={ind === table.getState().pagination.pageIndex ? colors.textActive : colors.textPrimary}
        type={TextType.BODY}
        testID={tID(`DataTable-pagination-${ind}`)}
      >
        {ind + 1}
      </PageNumber>
    ))
  }

  return (
    <TableContainer>
      <Table testID={tID('DataTable-table')}>
        {table.getHeaderGroups().map((headerGroup) => (
          <Header key={headerGroup.id} testID={tID('DataTable-header')}>
            {headerGroup.headers.map((header) => {
              const SingleOrMultiLineCell =
                header.column.columnDef.meta && header.column.columnDef.meta === 'multiline' ? MultipleLineCell : Cell
              return (
                <SingleOrMultiLineCell key={header.id} testID={tID(`Datatable-cell-${header.id}`)}>
                  <BaseText
                    size={headerSize}
                    color={headerColor || colors.textSecondary}
                    type={TextType.BODY}
                    testID={tID(`DataTable-header-${header.id}`)}
                  >
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </BaseText>
                </SingleOrMultiLineCell>
              )
            })}
          </Header>
        ))}
        {table.getRowModel().rows.length ? (
          table.getRowModel().rows.map((row, index) => (
            <Row
              key={row.id}
              style={index % 2 || !striped ? null : { backgroundColor: tableZebraColor || colors.backgroundSecondary }}
              testID={tID('DataTable-dataRow')}
              rowHeight={rowHeight}
            >
              {row.getVisibleCells().map((cell) => (
                <Cell key={cell.id} cellMargin={cellMargin}>
                  <BodyText size={bodySize} color={colors.textPrimary}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </BodyText>
                </Cell>
              ))}
            </Row>
          ))
        ) : (
          <EmptyTable size={Size.DEFAULT} testID={tID('DataTable-emptyTable')}>
            {emptyTableText}
          </EmptyTable>
        )}
      </Table>
      {pagination && table.getRowModel().rows.length > 0 && (
        <ButtonContainer testID={tID('DataTable-paginationButtons')}>
          <PaginationButton
            onPress={() => table.previousPage()}
            testID={tID('DataTable-paginationButton-previous')}
            iconColor={table.getCanPreviousPage() ? colors.iconActive : colors.iconInactive}
            leftIcon={<ChevronIcon direction={ChevronIconDirection.LEFT} />}
            size={ButtonSize.SMALL}
            disabled={!table.getCanPreviousPage()}
            modifier={ButtonModifier.INVERSE}
            accessibilityLabel={formatMessage({
              defaultMessage: 'Previous page',
              description: 'Button for previous table page',
            })}
          />
          {renderPageNumbers()}
          <PaginationButton
            onPress={() => table.nextPage()}
            testID={tID('DataTable-paginationButton-next')}
            iconColor={table.getCanNextPage() ? colors.iconActive : colors.iconInactive}
            leftIcon={<ChevronIcon direction={ChevronIconDirection.RIGHT} />}
            size={ButtonSize.SMALL}
            disabled={!table.getCanNextPage()}
            modifier={ButtonModifier.INVERSE}
            accessibilityLabel={formatMessage({
              defaultMessage: 'Next page',
              description: 'Button for next table page',
            })}
          />
        </ButtonContainer>
      )}
    </TableContainer>
  )
}
