import React from 'react'
import colors from '../../../styles/_0colors.scss'
import { Icon } from './types'

const ReplayIcon: React.FC<ReplayIconProps> = ({ width = 29, fillColor = colors.x_primary_action, ...props }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} viewBox='0 0 24 24' {...props}>
      <g fill='none' fillRule='evenodd'>
        <polygon points='0 24 24 24 24 .001 0 .001' />
        <path
          fill={fillColor}
          d='M12,5.35789474 L12,1 L6.375,6.44736842 L12,11.8947368 L12,7.53684211 C15.72375,7.53684211 18.75,10.4675263 18.75,14.0736842 C18.75,17.6798421 15.72375,20.6105263 12,20.6105263 C8.27625,20.6105263 5.25,17.6798421 5.25,14.0736842 L3,14.0736842 C3,18.8891579 7.0275,22.7894737 12,22.7894737 C16.9725,22.7894737 21,18.8891579 21,14.0736842 C21,9.25821053 16.9725,5.35789474 12,5.35789474 Z'
        />
      </g>
    </svg>
  )
}

type ReplayIconProps = Icon

export default ReplayIcon
