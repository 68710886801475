import React from 'react'
import AssignmentsIcon from '../../atoms/illustrations/AssignmentsIcon'
import LessonsIcon from '../../atoms/illustrations/LessonsIcon'
import AssessmentsIcon from '../../atoms/illustrations/AssessmentsIcon'
import SessionsIcon from '../../atoms/illustrations/SessionsIcon'
import InfoSheetIcon from '../../atoms/illustrations/InfoSheetIcon'
import GraduationIcon from '../../atoms/illustrations/GraduationIcon'
import PastSessionsIcon from '../../atoms/illustrations/PastSessionsIcon'

const LtIcon: React.FC<LtIconProps> = ({ type, size = 40 }) => {
  switch (type) {
    case 'lesson':
      return <LessonsIcon size={size} />
    case 'exercise':
      return <AssignmentsIcon size={size} />
    case 'assessment':
      return <AssessmentsIcon size={size} />
    case 'session':
      return <SessionsIcon width={size} />
    case 'infosheet':
      return <InfoSheetIcon size={size} />
    case 'track':
      return <GraduationIcon width={size} />
    default:
      return <PastSessionsIcon width={size} />
  }
}

type LtIconProps = {
  type: string | undefined
  size?: number
}

export default LtIcon
