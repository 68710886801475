import React, { JSXElementConstructor, ReactElement, ReactNode, useState } from 'react'

import { isSupported } from '@twilio/video-processors'
import classNames from 'classnames'
import { capitalize } from 'lodash-es'

import { IO_DEVICES } from '@lyrahealth-inc/shared-app-logic'

import styles from './videoSession.module.scss'
import VideoSessionSettings from './VideoSessionSettings'
import { SparklesIcon } from '../../atoms'
import DropdownButton from '../../atoms/buttons/dropdownButton/DropdownButton'
import PrimaryButton from '../../atoms/buttons/primaryButton/PrimaryButton'
import GearIcon from '../../atoms/icons/GearIcon'
import MenuDotsIcon from '../../atoms/icons/MenuDotsIcon'
import MicrophoneIcon from '../../atoms/icons/MicrophoneIcon'
import PhoneIcon from '../../atoms/icons/PhoneIcon'
import RecordingIcon from '../../atoms/icons/RecordingIcon'
import VideoIcon from '../../atoms/icons/VideoIcon'
import XIcon from '../../atoms/icons/XIcon'
import Tooltip from '../../atoms/tooltip/Tooltip'
import { VideoCallEffects } from '../videoCall/VideoCall'

const VIDEO_EFFECTS_DROPDOWN_ITEMS = [
  {
    id: VideoCallEffects.NONE,
    text: 'None',
  },
  {
    id: VideoCallEffects.BLUR,
    text: 'Blur',
  },
  {
    id: VideoCallEffects.OFFICE,
    text: 'Office',
  },
  {
    id: VideoCallEffects.HILL_LANDSCAPE,
    text: 'Landscape',
  },
]

const ButtonsContainer: React.FC<ButtonsContainerProps> = ({
  defaultContraints,
  saveSettingsFunction,
  settings,
  setAndShowModal,
  track,
  type,
  showSharingScreenBanner,
  inSession,
  startingScreenshare,
  messenger,
  isRecording,
  videoOff,
  participantStatusMessage,
  startingSession,
  toggleAudio,
  startScreenshare,
  endSession,
  startSession,
  turnOffRecording,
  toggleVideo,
  changeEffects,
  muted,
}) => {
  const [activeEffectsIndex, setActiveEffectsIndex] = useState(0)
  const isProvider = type === 'provider'

  const getMediaDevices = () => {
    const videoInputOptions = {}
    const audioInputOptions = {}
    const audioOutputOptions = {}
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      // sort your options
      devices.forEach((device) => {
        if (device.kind === 'videoinput') {
          videoInputOptions[device.deviceId] = device.label
        } else if (device.kind === 'audioinput') {
          audioInputOptions[device.deviceId] = device.label
        } else if (device.kind === 'audiooutput') {
          audioOutputOptions[device.deviceId] = device.label
        }
      })

      const initialVideoInput = localStorage.getItem(IO_DEVICES.CAMERA) || Object.keys(videoInputOptions)[0]
      const initialAudioInput = localStorage.getItem(IO_DEVICES.MICROPHONE) || Object.keys(audioInputOptions)[0]
      const initialAudioOutput = localStorage.getItem(IO_DEVICES.SPEAKER) || Object.keys(audioOutputOptions)[0]

      const modalContent = (
        <VideoSessionSettings
          deviceConstraints={defaultContraints}
          saveSettingsFunction={saveSettingsFunction}
          settingsOptions={{
            // @ts-expect-error TS(2740): Type '{}' is missing the following properties from... Remove this comment to see the full error message
            videoInputOptions: videoInputOptions,
            // @ts-expect-error TS(2740): Type '{}' is missing the following properties from... Remove this comment to see the full error message
            audioInputOptions: audioInputOptions,
            // @ts-expect-error TS(2740): Type '{}' is missing the following properties from... Remove this comment to see the full error message
            audioOutputOptions: audioOutputOptions,
          }}
          currentSettings={Object.assign(
            {},
            {
              videoInput:
                initialVideoInput in Object.keys(videoInputOptions)
                  ? initialVideoInput
                  : Object.keys(videoInputOptions)[0],
              audioInput:
                initialAudioInput in Object.keys(audioInputOptions)
                  ? initialAudioInput
                  : Object.keys(audioInputOptions)[0],
              audioOutput:
                initialAudioOutput in Object.keys(audioOutputOptions)
                  ? initialAudioOutput
                  : Object.keys(audioOutputOptions)[0],
            },
            settings,
          )}
        />
      )
      setAndShowModal(modalContent)

      // fire mixpanel
      track({
        page: 'Video Session',
        event: 'Button Press',
        action: 'Open Video Settings',
        details: capitalize(type),
      })
    })
  }

  return (
    <div
      className={classNames(styles['buttons-container'], styles.container, {
        [styles.invisible]: showSharingScreenBanner,
      })}
    >
      <div className={styles['video-controls']}>
        {inSession && isProvider ? (
          <button
            aria-label='Start Screenshare'
            onClick={startScreenshare}
            className={classNames(styles['session-control'], styles['share-screen'], {
              [styles.active]: startingScreenshare,
            })}
          >
            Present
          </button>
        ) : (
          []
        )}
        {type === 'client' ? (
          // The MessagesPopover icon is already a button.
          <div
            aria-label='Session Control'
            className={styles['session-control']}
            style={{ position: 'static', padding: '0' }}
          >
            {messenger &&
              React.cloneElement(messenger, {
                // Custom styles needed to place the MessageIcon button over the <div> container
                customIconStyles: { height: '48px', width: '48px', 'justify-content': 'center' },
                iconSize: 20,
                iconStyles: { padding: '9px 11px', boxSizing: 'content-box' },
                iconFilled: true,
                isSessionControl: true,
              })}
          </div>
        ) : null}
        <Tooltip data-test-id='ButtonsContainer-muteBtnTooltip' content={muted ? 'Unmute' : 'Mute'} delayHide={0}>
          <button
            aria-label='Mute'
            data-test-id='ButtonsContainer-muteBtn'
            className={classNames(styles['session-control'], {
              [styles.active]: muted,
            })}
            onClick={toggleAudio}
          >
            <MicrophoneIcon isMuted={muted} isFilled width={20} fillColor='currentColor' />
          </button>
        </Tooltip>
        <Tooltip
          data-test-id='ButtonsContainer-videoBtnTooltip'
          content={videoOff ? 'Start video' : 'Stop video'}
          delayHide={0}
        >
          <button
            aria-label='Video'
            data-test-id='ButtonsContainer-videoBtn'
            className={classNames(styles['session-control'], {
              [styles.active]: videoOff,
            })}
            onClick={toggleVideo}
          >
            <VideoIcon isMuted={videoOff} isFilled width={20} fillColor='currentColor' />
          </button>
        </Tooltip>
        {isSupported && (
          <Tooltip content='Effects' delayHide={0}>
            <DropdownButton
              iconOnly
              activeIndex={activeEffectsIndex}
              className={classNames(styles['session-control-dropdown'])}
              id='ButtonsContainer-effectsBtn'
              dropdownItems={VIDEO_EFFECTS_DROPDOWN_ITEMS.map(({ id, text }, index) => {
                return {
                  id,
                  text: <span data-test-id={`ButtonsContainer-effectBtn-${id}`}>{text}</span>,
                  selectHandler: () => {
                    changeEffects(id)
                    setActiveEffectsIndex(index)
                    track({
                      page: 'Video Session',
                      event: 'change video background effect',
                      details: capitalize(id),
                    })
                  },
                }
              })}
            >
              <SparklesIcon width={20} fillColor='currentColor' />
            </DropdownButton>
          </Tooltip>
        )}
        {isProvider ? (
          <DropdownButton
            iconOnly
            styling='active'
            disabledIndex={!isRecording ? 0 : undefined}
            id='ButtonsContainer-moreBtn'
            className={styles['dots-menu']}
            dropdownItems={[
              {
                text: (
                  <span>
                    <RecordingIcon isFilled isOff fillColor='currentColor' width={20} />
                    {!isRecording ? 'Recording has been turned off' : 'Turn off recording'}
                  </span>
                ),
                id: '1',
                selectHandler: turnOffRecording,
              },
              {
                text: (
                  <span data-test-id='ButtonsContainer-settingsBtn'>
                    <GearIcon isFilled fillColor='currentColor' width={20} />
                    Settings
                  </span>
                ),
                id: '2',
                selectHandler: getMediaDevices,
              },
            ]}
          >
            <MenuDotsIcon isFilled />
          </DropdownButton>
        ) : (
          <button
            aria-label='Settings'
            onClick={getMediaDevices}
            className={classNames(styles['session-control'], styles.settings)}
          >
            <GearIcon isFilled width={20} fillColor='currentColor' />
          </button>
        )}
      </div>
      {inSession ? (
        <button
          aria-label='End Call'
          className={classNames(styles['session-control'], styles['end-call'])}
          onClick={endSession}
          data-test-id='ButtonsContainer-endCall'
        >
          <PhoneIcon isFilled fillColor='currentColor' width={20} />
        </button>
      ) : isProvider ? (
        <PrimaryButton
          disabled={participantStatusMessage !== 'waiting'}
          customClass={styles['start-session-button']}
          onClick={startSession}
          isLoading={startingSession}
          loaderWithin
          data-test-id='VideoSession-startSession'
        >
          Start<span className='hidden-xs'>&nbsp;Session</span>
        </PrimaryButton>
      ) : (
        []
      )}
      {!inSession ? (
        <button aria-label='Dismiss Call' className={styles['dismiss-session']} onClick={endSession}>
          <XIcon width={15} fillColor='currentColor' />
        </button>
      ) : (
        []
      )}
    </div>
  )
}

export default ButtonsContainer

type ButtonsContainerProps = {
  defaultContraints: any
  inSession: Boolean
  participantStatusMessage: string | null
  track: (params: Dict) => void
  muted: boolean
  videoOff: boolean
  startingScreenshare: boolean
  startingSession: boolean
  type: string
  isRecording: boolean
  messenger: ReactElement<any, string | JSXElementConstructor<any>>
  settings: Dict
  saveSettingsFunction: () => void
  setAndShowModal: (modal: ReactNode) => void
  showSharingScreenBanner: boolean
  toggleAudio: () => void
  startScreenshare: () => void
  endSession: () => void
  startSession: () => void
  turnOffRecording: () => void
  toggleVideo: () => void
  changeEffects: (effect: VideoCallEffects) => void
}
