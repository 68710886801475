import React, { FunctionComponent } from 'react'
import { ImageSourcePropType } from 'react-native'

import styled from 'styled-components/native'

import chevronDownImage from '../../assets/chevron-down.png'
import chevronUpImage from '../../assets/chevron-up.png'
import { Image } from '../image/Image'

interface KeyboardNavArrowProps {
  direction: 'up' | 'down'
  width?: number
  height?: number
}

const ArrowImage = styled(Image)<{ width: number; height: number }>(({ width, height }) => ({
  width: `${width}px`,
  height: `${height}px`,
}))

export const KeyboardNavArrow: FunctionComponent<KeyboardNavArrowProps> = ({ direction, width = 24, height = 14 }) => {
  const arrowImage = direction === 'down' ? chevronDownImage : chevronUpImage
  return <ArrowImage source={arrowImage as ImageSourcePropType} width={width} height={height} />
}
