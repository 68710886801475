import React from 'react'
import { ContentLayout } from '@lyrahealth-inc/ui-core'
import { LoadingIndicator } from '@lyrahealth-inc/ui-core-crossplatform'

const CalendarRoutes: React.FC = () => (
  <div>
    <ContentLayout>
      <div style={{ marginTop: '50px' }}>
        <LoadingIndicator />
      </div>
    </ContentLayout>
  </div>
)

export default CalendarRoutes
