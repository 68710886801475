import React, { FunctionComponent } from 'react'
import { View } from 'react-native'

import { Image } from '../image/Image'

export const EmptyActivityOverviewIllustration: FunctionComponent<EmptyActivityOverviewIllustrationProps> = ({
  width = 240,
  height = 240,
}) => {
  return (
    <View>
      {/* eslint-disable-next-line react-native-a11y/has-valid-accessibility-ignores-invert-colors */}
      <Image source={require('../../assets/Lyra_Therapy_EmptyState.png')} style={{ width: width, height: height }} />
    </View>
  )
}

type EmptyActivityOverviewIllustrationProps = {
  width?: string | number
  height?: string | number
}
