/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { format } from 'date-fns-tz'
import { outcomeUtils, WarningIcon } from '@lyrahealth-inc/ui-core'
import { isNil } from 'lodash-es'
import { parseISO } from 'date-fns'
import styles from './clientsDashboard.module.scss'
import sessionLimitWarning from '../../common/content/sessionLimitWarning'
import sessionResetDate from '../../common/content/sessionResetDate'

export const tableColumns = [
  {
    Header: 'Client Name',
    accessor: 'full_name',
    Cell: (row: any) => (
      <div>
        <h3 className='rt-client-name'>{row.value}</h3>
        <a data-test-id='ClientsDashboard-ViewClient' style={{ textDecoration: 'none' }}>
          View profile
        </a>
      </div>
    ),
  },
  {
    Header: 'Sessions',
    accessor: 'sessions_remaining',
    sortable: false,
    minWidth: 200,
    Cell: ({
      original: {
        sessions_used,
        sessions_limit,
        open_bhb_session_count,
        open_bhb_session_limit,
        health_plan_status,
        display_visits_per_issue_per_year,
      },
      original,
    }: any) => {
      const sessionLimitWarningText = sessionLimitWarning({ client: original })
      const sessionResetDateText = sessionResetDate({ client: original })
      const showBHBCount = open_bhb_session_count > 0 && open_bhb_session_limit > 0
      const sessionsUsed = showBHBCount ? open_bhb_session_count : sessions_used
      const sessionsLimit = showBHBCount ? open_bhb_session_limit : sessions_limit

      return (
        <div>
          {display_visits_per_issue_per_year > 0 ? (
            <div className='rt-session-count' data-test-id='ClientDashboard-eap-session-count'>
              {display_visits_per_issue_per_year} visits per issue per year. <br /> Issues determined between you <br />
              and your client.
            </div>
          ) : (
            <div className='rt-session-count' data-test-id='ClientDashboard-eap-session-count'>
              {sessionsUsed} of {sessionsLimit} {showBHBCount ? 'health plan' : 'EAP'} sessions used
            </div>
          )}
          {health_plan_status && isNil(open_bhb_session_limit) && (
            <div className='rt-assessment-description'>
              May be eligible to use health plan, check profile for details
            </div>
          )}
          {sessionLimitWarningText && (
            <div className='rt-session-description'>
              {sessionLimitWarningText} {sessionResetDateText}
            </div>
          )}
        </div>
      )
    },
  },
  {
    Header: 'Outcomes',
    accessor: 'outcomes',
    maxWidth: 225,
    sortable: false,
    Cell: (row: any) => {
      if (row.value.length === 0) {
        return (
          <div>
            <div className='rt-assessment-name'>Send assessments</div>
            <div className='rt-assessment-description'>No Outcomes possible yet</div>
          </div>
        )
      } else if (row.value[0] && row.value[0].scores) {
        const GADscore = row.value[0].scores.GAD
        const PHQscore = row.value[0].scores.PHQ
        return (
          <div>
            <div className='rt-outcome'>
              <div className='rt-assessment-name'>GAD7 - {GADscore}</div>
              <div className='rt-assessment-description'>
                {outcomeUtils.convertOutcomeScoreToDescription('GAD', GADscore)}
              </div>
            </div>
            <div className='rt-outcome'>
              <div className='rt-assessment-name'>PHQ9 - {PHQscore}</div>
              <div className='rt-assessment-description'>
                {outcomeUtils.convertOutcomeScoreToDescription('PHQ', PHQscore)}
              </div>
            </div>
          </div>
        )
      } else {
        return (
          <div>
            <div className='rt-assessment-name'>Not submitted</div>
            <div className='rt-assessment-description'>Please remind client</div>
          </div>
        )
      }
    },
  },
  {
    Header: 'Date Submitted',
    id: 'date_submitted',
    maxWidth: 150,
    accessor: 'outcomes',
    // @ts-expect-error TS(7030): Not all code paths return a value.
    sortMethod: (a: any, b: any) => {
      if (a[0] && b[0]) {
        return a[0].response_date > b[0].response_date ? 1 : -1
      }
    },
    // @ts-expect-error TS(7030): Not all code paths return a value.
    Cell: (row: any) => {
      if (row.value.length > 0) {
        return (
          <div className='rt-date'>
            {row.value[0].response_date ? format(parseISO(row.value[0].response_date), 'MM/dd/yyyy') : ''}
          </div>
        )
      }
    },
  },
  {
    Header: '',
    accessor: 'outcomes',
    sortable: false,
    maxWidth: 110,
    // @ts-expect-error TS(7030): Not all code paths return a value.
    Cell: (row: any) => {
      if (row.value[0] && row.value[0].scores && row.value[0].scores['PHQ-SELF-HARM'] > 0) {
        return (
          <div className='rt-alert'>
            <WarningIcon width={12} fillColor={styles.x_alert} /> Self harm
          </div>
        )
      }
    },
  },
]
