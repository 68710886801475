import React from 'react'
import CSSModules from 'react-css-modules'
import { Field, Form } from 'react-final-form'
import { connect } from 'react-redux'

import { bindActionCreators } from 'redux'

import {
  BootstrapContainer,
  ContentLayout,
  DataTable,
  InputField,
  LoadingIndicator,
  SecondaryButton,
} from '@lyrahealth-inc/ui-core'

import styles from './reconcilerDashboard.module.scss'
import { GET_CLIENTS_TO_RECONCILE } from '../../common/constants/reduxConstants'
import { RECONCILER_DETAILS } from '../../common/constants/routingConstants'
import { getErrorText, hasErrorForAction } from '../../common/utils/utils'
import * as alertActions from '../../data/alertActions'
import { getAlertsState } from '../../data/alertsSelectors'
import withRouter from '../../routing/withRouter'
import * as reconcilerDataActions from '../data/reconcilerDataActions'
import { getReconcilerDataClientsList, getReconcilerDataClientsListCount } from '../data/reconcilerDataSelectors'
import * as reconcilerDetailsActions from '../individualClient/data/reconcilerDetailsActions'

const tableColumns = [
  {
    Header: 'Client Name',
    accessor: 'full_name',
  },
  {
    Header: 'Sponsoring Company',
    accessor: 'displayed_employer',
    sortable: false,
  },
  {
    Header: 'DOB',
    accessor: 'dob',
    maxWidth: 200,
    sortable: false,
  },
  {
    Header: 'Source',
    accessor: 'source',
    maxWidth: 200,
    sortable: false,
  },
  {
    Header: '',
    accessor: 'resolveId',
    sortable: false,
    Cell: () => {
      return <SecondaryButton>Resolve ID</SecondaryButton>
    },
  },
]

const lyraId = (value: any) =>
  value && !/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(value)
    ? 'Invalid Lyra Id'
    : undefined

type ReconcilerDashboardProps = {
  router?: any
  actions?: any
  clientsList?: any // TODO: PropTypes.instanceOf(List)
  clientsListCount?: any // TODO: PropTypes.instanceOf(Number)
  alerts?: any // TODO: PropTypes.instanceOf(List)
}

class ReconcilerDashboard extends React.Component<ReconcilerDashboardProps> {
  componentDidMount() {
    this.props.actions.getClientsToReconcile({ start: 0, limit: 20 })
  }

  _rowClickHandler = (state: any, rowInfo: any) => {
    this.props.actions.clientSelected(rowInfo.original)
    this.props.router.navigate(RECONCILER_DETAILS.route)
  }

  _renderReconciler = () => {
    return (
      <div styleName='reconciler-container'>
        <Form
          validate={(values) => {
            const errors = {}
            const manualId = values.manual_canonical_id
            const id = values.lyra_id
            const manualIsNotValid = lyraId(manualId)
            const lyraIsNotValid = lyraId(id)
            if (manualIsNotValid || !manualId) (errors as any).manual_canonical_id = 'Invalid Canonical ID'
            if (lyraIsNotValid || !id) (errors as any).lyra_id = 'Invalid Lyra ID'
            return errors
          }}
          onSubmit={this._reconcileIDs}
          touchOnBlur={false}
        >
          {({ handleSubmit, pristine, invalid, submitting }) => {
            return (
              <>
                <h3>Manual Reconciler</h3>
                <div>
                  <Field
                    name='lyra_id'
                    component={InputField}
                    placeholder='Lyra ID'
                    type='text'
                    label='Lyra ID'
                    data-test-id='lyra-id-input'
                  />

                  <Field
                    name='manual_canonical_id'
                    component={InputField}
                    placeholder='Canonical ID'
                    type='text'
                    label='Canonical ID'
                    data-test-id='manual-canonical-id-input'
                  />

                  <SecondaryButton
                    type='submit'
                    onClick={handleSubmit}
                    disabled={pristine || submitting || invalid}
                    isLoading={submitting}
                    data-test-id='manual-reconciler-submit'
                  >
                    Resolve ID
                  </SecondaryButton>
                </div>
              </>
            )
          }}
        </Form>
      </div>
    )
  }

  _reconcileIDs = (values: any) => {
    const formData = new FormData()

    Object.keys(values).forEach((key) => {
      formData.append(key, values[key])
    })

    return this.props.actions.submitMatch(values.lyra_id, formData).then(
      () => {
        this.props.actions.addAlert({
          show: true,
          contents: "Thank you! The client's identity has been successfully reconciled",
          style: 'success',
          expires: false,
          autoDismissTimer: 8000,
        })
      },
      (error: any) => {
        const errorText = error.response.data.message || getErrorText(error.response.status)
        this.props.actions.addAlert({
          show: true,
          contents: errorText,
          style: 'danger',
          expires: true,
        })
      },
    )
  }

  render() {
    if (!this.props.clientsList) {
      if (hasErrorForAction(this.props.alerts, GET_CLIENTS_TO_RECONCILE)) {
        return false // custom workflow or display could render here
      } else {
        return (
          <ContentLayout>
            <BootstrapContainer>
              <h2 styleName='title'>Identity Reconciler</h2>
              <div styleName='card loading-container'>
                <LoadingIndicator size={45} />
              </div>
            </BootstrapContainer>
          </ContentLayout>
        )
      }
    }

    const filteredData = this.props.clientsList
    // if (this.state.search) {
    //   filteredData = filteredData.filter(row => {
    //     return row.full_name.toLowerCase().includes(this.state.search.toLowerCase())
    //   })
    // }

    return (
      <ContentLayout>
        <BootstrapContainer>
          <div styleName='title-container'>
            <h2 styleName='title'>Identity Reconciler</h2>
            <div styleName='filter-add-container' />
            {/* <InputField
              input={{
                name: 'external_filter',
                value: this.state.search,
                onChange: e => this.setState({search: e.target.value})
              }}
              className={styles['filter-input']}
              type='text'
              meta={{}}
              component={InputField}
              insetIcon={SearchIcon}
              placeholder='Search by client name' /> */}
            <h4>total: {this.props.clientsListCount}</h4>
          </div>

          <DataTable
            pagination={false}
            pageSize={20}
            striped={false}
            highlight={false}
            resizable={false}
            destinationSelector='lyra_id'
            // @ts-expect-error TS(2322): Type '(state: any, rowInfo: any) => void' is not a... Remove this comment to see the full error message
            rowClickHandler={this._rowClickHandler}
            className={styles['clients-table']}
            data={filteredData}
            columns={tableColumns}
          />

          {this._renderReconciler()}
        </BootstrapContainer>
      </ContentLayout>
    )
  }
}

const mapStateToProps = ($$state: any) => {
  return {
    clientsList: getReconcilerDataClientsList($$state),
    clientsListCount: getReconcilerDataClientsListCount($$state),
    alerts: getAlertsState($$state),
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    actions: bindActionCreators({ ...reconcilerDetailsActions, ...alertActions, ...reconcilerDataActions }, dispatch),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CSSModules(ReconcilerDashboard, styles, { allowMultiple: true })),
)
