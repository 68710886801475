import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

import { colors } from '../../styles/colors'

export const AssessmentIcon: FunctionComponent<AssessmentIconProps> = ({ size = 24, fillColor = colors.navy6 }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' aria-label='Assessment' accessibilityHidden>
      <Path
        d='M5.46934 17.47C5.32889 17.6106 5.25 17.8012 5.25 18C5.25 18.1987 5.32889 18.3893 5.46934 18.53L6.46934 19.53C6.60997 19.6704 6.80059 19.7493 6.99934 19.7493C7.19809 19.7493 7.38871 19.6704 7.52934 19.53L9.52934 17.53C9.66182 17.3878 9.73394 17.1997 9.73052 17.0054C9.72709 16.8111 9.64837 16.6258 9.51096 16.4883C9.37355 16.3509 9.18816 16.2722 8.99386 16.2688C8.79956 16.2654 8.61152 16.3375 8.46934 16.47L6.99934 17.94L6.52934 17.47C6.38871 17.3295 6.19809 17.2506 5.99934 17.2506C5.80059 17.2506 5.60997 17.3295 5.46934 17.47Z'
        fill={fillColor}
      />
      <Path
        d='M9.59 21.25H4.46C4.28125 21.2582 4.10648 21.1956 3.97358 21.0758C3.84067 20.956 3.76034 20.7886 3.75 20.61V5.38999C3.76034 5.21135 3.84067 5.04399 3.97358 4.92419C4.10648 4.80438 4.28125 4.74179 4.46 4.74999H6.25V5.99999C6.25259 6.19809 6.33244 6.38736 6.47253 6.52745C6.61263 6.66755 6.80189 6.7474 7 6.74999H15C15.1981 6.7474 15.3874 6.66755 15.5275 6.52745C15.6676 6.38736 15.7474 6.19809 15.75 5.99999V4.74999H17.54C17.7187 4.74179 17.8935 4.80438 18.0264 4.92419C18.1593 5.04399 18.2397 5.21135 18.25 5.38999V11.2C18.25 11.3989 18.329 11.5897 18.4697 11.7303C18.6103 11.871 18.8011 11.95 19 11.95C19.1989 11.95 19.3897 11.871 19.5303 11.7303C19.671 11.5897 19.75 11.3989 19.75 11.2V5.38999C19.7395 4.81354 19.501 4.26474 19.0869 3.86368C18.6727 3.46262 18.1165 3.24196 17.54 3.24999H15.75V2.99999C15.7474 2.80188 15.6676 2.61262 15.5275 2.47252C15.3874 2.33243 15.1981 2.25258 15 2.24999H13.12C12.862 1.9377 12.5382 1.68627 12.1717 1.51368C11.8052 1.34108 11.4051 1.25159 11 1.25159C10.5949 1.25159 10.1948 1.34108 9.82833 1.51368C9.46185 1.68627 9.13802 1.9377 8.88 2.24999H7C6.80189 2.25258 6.61263 2.33243 6.47253 2.47252C6.33244 2.61262 6.25259 2.80188 6.25 2.99999V3.24999H4.46C3.88352 3.24196 3.32733 3.46262 2.91315 3.86368C2.49896 4.26474 2.26052 4.81354 2.25 5.38999V20.61C2.26052 21.1864 2.49896 21.7352 2.91315 22.1363C3.32733 22.5374 3.88352 22.758 4.46 22.75H9.59C9.78891 22.75 9.97968 22.671 10.1203 22.5303C10.261 22.3897 10.34 22.1989 10.34 22C10.34 21.8011 10.261 21.6103 10.1203 21.4697C9.97968 21.329 9.78891 21.25 9.59 21.25ZM7.75 3.74999H9.27C9.40105 3.74959 9.52977 3.71531 9.64367 3.65048C9.75756 3.58565 9.85275 3.49247 9.92 3.37999C10.0373 3.20048 10.1974 3.05303 10.386 2.95097C10.5746 2.84891 10.7856 2.79547 11 2.79547C11.2144 2.79547 11.4254 2.84891 11.614 2.95097C11.8026 3.05303 11.9627 3.20048 12.08 3.37999C12.1472 3.49247 12.2424 3.58565 12.3563 3.65048C12.4702 3.71531 12.5989 3.74959 12.73 3.74999H14.25V5.24999H7.75V3.74999Z'
        fill={fillColor}
      />
      <Path
        d='M15.75 13.5C15.7474 13.3019 15.6676 13.1126 15.5275 12.9725C15.3874 12.8324 15.1981 12.7526 15 12.75H11C10.8011 12.75 10.6103 12.829 10.4697 12.9697C10.329 13.1103 10.25 13.3011 10.25 13.5C10.25 13.6989 10.329 13.8897 10.4697 14.0303C10.6103 14.171 10.8011 14.25 11 14.25H15C15.1981 14.2474 15.3874 14.1676 15.5275 14.0275C15.6676 13.8874 15.7474 13.6981 15.75 13.5Z'
        fill={fillColor}
      />
      <Path
        d='M21.53 15.25L19.75 13.47C19.6094 13.3295 19.4187 13.2506 19.22 13.2506C19.0212 13.2506 18.8306 13.3295 18.69 13.47L12.47 19.69C12.3293 19.8305 12.2502 20.0211 12.25 20.2199V22C12.2526 22.1981 12.3324 22.3873 12.4725 22.5274C12.6126 22.6675 12.8019 22.7474 13 22.75H14.78C14.9788 22.7498 15.1695 22.6706 15.31 22.53L21.53 16.3099C21.6705 16.1693 21.7493 15.9787 21.7493 15.78C21.7493 15.5812 21.6705 15.3906 21.53 15.25ZM18.89 16.83L18.17 16.11L19.17 15.11L19.89 15.83L18.89 16.83Z'
        fill={fillColor}
      />
      <Path
        d='M7.5 15C8.32843 15 9 14.3284 9 13.5C9 12.6716 8.32843 12 7.5 12C6.67157 12 6 12.6716 6 13.5C6 14.3284 6.67157 15 7.5 15Z'
        fill={fillColor}
      />
      <Path
        d='M7.5 11C8.32843 11 9 10.3284 9 9.5C9 8.67157 8.32843 8 7.5 8C6.67157 8 6 8.67157 6 9.5C6 10.3284 6.67157 11 7.5 11Z'
        fill={fillColor}
      />
      <Path
        d='M15 8.75H11C10.8011 8.75 10.6103 8.82902 10.4697 8.96967C10.329 9.11032 10.25 9.30109 10.25 9.5C10.25 9.69891 10.329 9.88968 10.4697 10.0303C10.6103 10.171 10.8011 10.25 11 10.25H15C15.1989 10.25 15.3897 10.171 15.5303 10.0303C15.671 9.88968 15.75 9.69891 15.75 9.5C15.75 9.30109 15.671 9.11032 15.5303 8.96967C15.3897 8.82902 15.1989 8.75 15 8.75Z'
        fill={fillColor}
      />
    </Svg>
  )
}

type AssessmentIconProps = {
  size?: string | number
  fillColor?: string
}
