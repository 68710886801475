import React, { FunctionComponent } from 'react'
import Svg, { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

export const HealthPlanIllustration: FunctionComponent<HealthPlanIllustrationProps> = ({ size = 48 }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 48 48' fill='none'>
      <G clip-path='url(#clip0_2750_46033)'>
        <Path
          d='M23.7209 1C21.6509 1 19.6409 1.27 17.7509 1.8C9.83088 4.02 3.62088 9.9 1.14088 17.52C-1.22912 24.78 0.130885 32.39 4.87088 38.4C9.01088 43.65 16.2209 46.78 24.1809 46.78H24.4309C35.4609 46.69 44.9009 39.51 47.3909 29.33C48.4109 25.15 48.1409 20.48 46.6309 16.19C45.0709 11.75 42.2509 8 38.7009 5.64C34.2209 2.64 28.9009 1 23.7209 1Z'
          fill='white'
        />
        <Path
          d='M24.4119 45.0498C17.6119 45.1098 10.4619 42.7198 6.22191 37.3298C-3.32809 25.2198 3.15191 7.6798 18.2219 3.4498C24.7419 1.6198 32.1919 3.34979 37.7519 7.05979C44.5719 11.6098 47.6419 21.0798 45.7319 28.9098C43.3419 38.6798 34.2519 44.9698 24.4219 45.0498H24.4119Z'
          fill='#CBECEF'
        />
        <Path
          d='M11.5182 14.9499C12.4382 13.6999 14.6082 15.0799 17.9782 13.4299C21.6282 11.6399 21.9282 9.9399 23.3782 10.0099C24.8282 10.0699 25.1082 10.7999 28.5182 12.9699C32.5982 15.5699 34.3782 13.0999 35.4282 14.8199C37.2282 17.7499 35.9582 27.2699 33.3882 31.3499C30.3382 36.1799 25.8782 38.9499 24.1682 38.9899C21.3382 39.0499 15.9382 34.5799 14.2282 31.6799C12.5182 28.7799 11.6582 25.4899 11.3282 22.3999C10.9982 19.3099 11.0482 15.5999 11.5282 14.9599L11.5182 14.9499Z'
          fill='#FFD2C4'
        />
        <Path
          d='M23.2983 10.0093C23.2983 10.0093 23.3483 10.0093 23.3783 10.0093C24.8283 10.0693 25.1083 10.7993 28.5183 12.9693C30.1883 14.0393 31.4783 14.2493 32.4883 14.2493C33.1683 14.2493 33.7183 14.1493 34.1883 14.1493C34.7083 14.1493 35.1083 14.2793 35.4383 14.8093C37.2383 17.7393 35.9683 27.2593 33.3983 31.3393C30.3483 36.1693 25.8883 38.9393 24.1783 38.9793H24.1183C21.2683 38.9793 15.9383 34.5493 14.2383 31.6693C12.5283 28.7693 11.6683 25.4793 11.3383 22.3893C11.0083 19.2993 11.0583 15.5893 11.5383 14.9493C12.4583 13.6993 14.6283 15.0793 17.9983 13.4293C21.5783 11.6693 21.9383 9.9993 23.3283 9.9993L23.2983 10.0093ZM23.3283 8.2793C22.0083 8.2793 21.1983 8.9993 20.4283 9.6993C19.7483 10.3093 18.8983 11.0693 17.2483 11.8793C15.8083 12.5893 14.6483 12.5993 13.6283 12.6193C12.4983 12.6293 11.0983 12.6493 10.1583 13.9293C8.92834 15.5893 9.55835 21.8693 9.63835 22.5793C10.0383 26.3693 11.0983 29.7293 12.7683 32.5593C14.6683 35.7793 20.5183 40.7193 24.1283 40.7193C24.1583 40.7193 24.1983 40.7193 24.2283 40.7193C26.8383 40.6593 31.7383 37.2493 34.8683 32.2793C37.6583 27.8493 39.1883 17.6093 36.9183 13.9293C36.3183 12.9493 35.3983 12.4493 34.2083 12.4493C33.9283 12.4493 33.6483 12.4693 33.3783 12.4993C33.0983 12.5193 32.8183 12.5493 32.5183 12.5493C31.8383 12.5493 30.8883 12.4293 29.4783 11.5393C28.3283 10.8093 27.5683 10.2593 26.9583 9.8193C25.7483 8.9493 24.9583 8.3793 23.4983 8.3093C23.4483 8.3093 23.3983 8.3093 23.3483 8.3093L23.3283 8.2793Z'
          fill='white'
        />
        <Path
          d='M23.7477 17.8187C24.5177 17.8387 25.1577 17.6587 25.7177 18.2687C26.6577 19.3087 24.7377 22.0387 27.7077 21.8087C29.7777 21.6387 30.3077 21.7287 30.3577 23.5687C30.4177 25.8387 30.3577 26.8287 27.8277 26.8387C26.5077 26.8387 26.2777 27.4287 26.2877 28.5487C26.3477 31.5687 26.0077 31.4087 21.7977 31.3387C20.9177 31.3287 20.8677 30.4587 20.8677 28.9487C20.8677 27.5387 20.2977 27.1787 18.8977 27.2687C16.8777 27.3787 16.3477 26.7187 16.3977 24.5387C16.4377 22.9187 16.6877 22.2987 18.6177 22.2587C21.7077 22.1887 20.6577 20.9587 20.9277 19.0687C21.0577 18.1287 21.6477 17.8987 23.1777 17.8587C23.3677 17.8587 23.5677 17.8287 23.7577 17.8387L23.7477 17.8187Z'
          fill='#F59372'
        />
      </G>
      <Defs>
        <ClipPath id='clip0_2750_46033'>
          <Rect width='48' height='45.77' fill='white' transform='translate(0 1)' />
        </ClipPath>
      </Defs>
    </Svg>
  )
}

type HealthPlanIllustrationProps = {
  size?: string | number
}
