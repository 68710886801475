import React from 'react'
import colors from '../../../styles/_0colors.scss'
import { Icon } from './types'

const MenuDotsIcon: React.FC<MenuDotsIcon> = ({
  width = 27,
  fillColor = colors.x_primary_action,
  isFilled = false,
  ...props
}) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} viewBox='0 0 24 24' {...props}>
      {isFilled ? (
        <path
          fill={fillColor}
          d='M12.5,14.5 C11.3954305,14.5 10.5,13.6045695 10.5,12.5 C10.5,11.3954305 11.3954305,10.5 12.5,10.5 C13.6045695,10.5 14.5,11.3954305 14.5,12.5 C14.5,13.6045695 13.6045695,14.5 12.5,14.5 Z M18.5,14.5 C17.3954305,14.5 16.5,13.6045695 16.5,12.5 C16.5,11.3954305 17.3954305,10.5 18.5,10.5 C19.6045695,10.5 20.5,11.3954305 20.5,12.5 C20.5,13.6045695 19.6045695,14.5 18.5,14.5 Z M6.5,14.5 C5.3954305,14.5 4.5,13.6045695 4.5,12.5 C4.5,11.3954305 5.3954305,10.5 6.5,10.5 C7.6045695,10.5 8.5,11.3954305 8.5,12.5 C8.5,13.6045695 7.6045695,14.5 6.5,14.5 Z'
        />
      ) : (
        <path
          fill={fillColor}
          d='M12.5,14 C11.6715729,14 11,13.3284271 11,12.5 C11,11.6715729 11.6715729,11 12.5,11 C13.3284271,11 14,11.6715729 14,12.5 C14,13.3284271 13.3284271,14 12.5,14 Z M18.5,14 C17.6715729,14 17,13.3284271 17,12.5 C17,11.6715729 17.6715729,11 18.5,11 C19.3284271,11 20,11.6715729 20,12.5 C20,13.3284271 19.3284271,14 18.5,14 Z M6.5,14 C5.67157288,14 5,13.3284271 5,12.5 C5,11.6715729 5.67157288,11 6.5,11 C7.32842712,11 8,11.6715729 8,12.5 C8,13.3284271 7.32842712,14 6.5,14 Z'
        />
      )}
    </svg>
  )
}

type MenuDotsIcon = Icon

export default MenuDotsIcon
