import React from 'react'

export const phq = (
  <div>
    <h3>PHQ9 Life Impairment Question</h3>
    <h4>Question</h4>
    <p>
      How difficult have these problems made it for you to do your work, take care of things at home, or get along with
      other people?
    </p>
    <h4>Range</h4>
    <div className='range-container'>
      <p>Not difficult at all</p>
      <p>Somewhat difficult</p>
      <p>Very difficult</p>
      <p>Extremely difficult</p>
    </div>
  </div>
)

export const alliance = (programName: string, providerType: string) => {
  return (
    <div>
      <h3>Alliance Questions</h3>
      <h4>Questions</h4>
      <ol>
        <li>How satisfied are you with the {programName.toLowerCase()} approach in terms of it meeting your needs?</li>
        <li>How satisfied are you with your relationship (and emotional bond) with your {providerType}?</li>
        <li>How satisfied are you with what you worked on or talked about?</li>
      </ol>
      <h4>Range</h4>
      <div className='range-container'>
        <p>Unsatisfied</p>
        <p>Somewhat satisfied</p>
        <p>Satisfied</p>
        <p>Very satisfied</p>
      </div>
    </div>
  )
}
export const selfHarm = (
  <div>
    <h3>PHQ9 Thoughts of Self Harm Question</h3>
    <h4>Question</h4>
    <p>Thoughts that you would be better off dead or of hurting yourself in some way.</p>
    <h4>Range</h4>
    <div className='range-container'>
      <p>Not at all</p>
      <p>Several days</p>
      <p>More than half the days</p>
      <p>Nearly everyday </p>
    </div>
  </div>
)
