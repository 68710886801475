import useSound from 'use-sound/dist/index'
import pip from './../sounds/pip.wav'

/**
 * Hook to play the a sound when a new message comes into the messenger
 * Sound Info: 16bit-mono
 * This hook encapsulates useSound:
 * https://github.com/joshwcomeau/use-sound
 * To return the sound created by @nickjohnson
 */
const useNewMessageSound = () => useSound(pip)

export default useNewMessageSound
