import React from 'react'
import { Image, ImageSourcePropType } from 'react-native'
import { useNavigate } from 'react-router-dom'

import styled, { useTheme } from 'styled-components/native'

import {
  BodyText,
  BodyTextSize,
  Link,
  PrimaryButton,
  Subhead,
  SubheadSize,
  tID,
} from '@lyrahealth-inc/ui-core-crossplatform'
import SimplePracticeImage from '@lyrahealth-inc/ui-core-crossplatform/src/assets/SimplePracticeStepThree.png'

import { actions, mixpanelEvents } from '../../../../mixpanel/mixpanelConstants'
import { track } from '../../../../mixpanel/mixpanelTracking'
import { SCHEDULING_ACTIVATE_SP_FINAL, SCHEDULING_ACTIVATE_STEP_FOUR } from '../../common/constants/routingConstants'
import {
  BodyTextContainer,
  ButtonGroupContainer,
  HeaderContainer,
  LinkButtonContainer,
  WalkthroughContentContainer,
} from '../constants'

const ImageContainer = styled.View(({ theme }) => ({
  marginTop: theme.spacing['32px'],
}))

export const SchedulingWalkthrough6: React.FC = () => {
  const navigate = useNavigate()
  const { colors } = useTheme()

  const onNextStep = () => {
    track({ event: mixpanelEvents.BUTTON_PRESS, action: actions.SCHEDULING_WALKTHROUGH_NEXT_STEP })
    navigate(SCHEDULING_ACTIVATE_SP_FINAL.route)
  }

  const onPreviousStep = () => {
    track({ event: mixpanelEvents.BUTTON_PRESS, action: actions.SCHEDULING_WALKTHROUGH_SIMPLE_PRACTICE_BACK })
    navigate(SCHEDULING_ACTIVATE_STEP_FOUR.route)
  }

  return (
    <WalkthroughContentContainer>
      <HeaderContainer>
        <Subhead
          level='2'
          size={SubheadSize.LARGE}
          textAlign='center'
          text='Does your SimplePractice calendar appear in Google Calendar?'
          color={colors.textPrimary}
        />
      </HeaderContainer>
      <BodyTextContainer>
        <BodyText
          color={colors.textSecondary}
          textAlign='center'
          text='It should appear in the left column under My calendars. If no calendars appear, expand the list by clicking the arrow to its right.'
        />
      </BodyTextContainer>
      <ImageContainer>
        <Image
          source={SimplePracticeImage as ImageSourcePropType}
          style={{ width: 720, height: 230, alignSelf: 'center' }}
          resizeMode='contain'
        />
      </ImageContainer>
      <ButtonGroupContainer>
        <LinkButtonContainer>
          <Link
            text='No, I’ll try again'
            onPress={onPreviousStep}
            size={BodyTextSize.DEFAULT}
            testID={tID('RetrySimplePractice-button')}
          />
        </LinkButtonContainer>
        <PrimaryButton text='Yes, it’s connected' onPress={onNextStep} testID={tID('CompleteStepSix-button')} />
      </ButtonGroupContainer>
    </WalkthroughContentContainer>
  )
}
