import React from 'react'
import CSSModules from 'react-css-modules'
import styles from './displayProviderRates.module.scss'
import { Link } from 'react-router-dom'
import { PROVIDERS_DETAILS_EDIT_RATES } from '../../../../common/constants/routingConstants'

type Props = {
  $$data?: any
}

// TODO! UPDATE SO THE DISPLAYED RATES ARE BASED ON DATA AND NOT HARD CODED.
class DisplayProviderRates extends React.Component<Props> {
  render() {
    const { $$data } = this.props

    return (
      <div styleName='content-container'>
        <div className='row'>
          <div className='col-xs-12 col-sm-6'>
            <div styleName='field-label'>Initial Evaluation (individual) Rate</div>
            <p>{$$data.get('individual_initial_evaluation')}</p>
          </div>
          <div className='col-xs-12 col-sm-6'>
            <div styleName='field-label'>Follow Up Session (individual) Rate</div>
            <p>{$$data.get('individual_follow_up_session')}</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12 col-sm-6'>
            <div styleName='field-label'>Initial Evaluation (couples) Rate</div>
            <p>{$$data.get('couples_initial_evaluation')}</p>
          </div>
          <div className='col-xs-12 col-sm-6'>
            <div styleName='field-label'>Follow Up Session (couples) Rate</div>
            <p>{$$data.get('couples_follow_up_session')}</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12 col-sm-6'>
            <div styleName='field-label'>Initial Evaluation (family) Rate</div>
            <p>{$$data.get('family_initial_evaluation')}</p>
          </div>
          <div className='col-xs-12 col-sm-6'>
            <div styleName='field-label'>Follow Up Session (family) Rate</div>
            <p>{$$data.get('family_follow_up_session')}</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12 col-sm-6'>
            <div styleName='field-label'>Initial Evaluation (group) Rate</div>
            <p>{$$data.get('group_initial_evaluation')}</p>
          </div>
          <div className='col-xs-12 col-sm-6'>
            <div styleName='field-label'>Follow Up Session (group) Rate</div>
            <p>{$$data.get('group_follow_up_session')}</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12 col-sm-6'>
            <div styleName='field-label'>Initial Evaluation (medication management) Rate</div>
            <p>{$$data.get('medication_management_initial_evaluation')}</p>
          </div>
          <div className='col-xs-12 col-sm-6'>
            <div styleName='field-label'>Follow Up Session (medication management) Rate</div>
            <p>{$$data.get('medication_management_follow_up_session')}</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12 col-sm-6'>
            <div styleName='field-label'>SMP Rate</div>
            <p>{$$data.get('smp_session')}</p>
          </div>
          <div className='col-xs-12 col-sm-6'>
            <div styleName='field-label'>GSC Rate</div>
            <p>{$$data.get('ssc_session')}</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12 col-sm-6'>
            <div styleName='field-label'>Initial Evaluation (CPP) Rate</div>
            <p>{$$data.get('child_initial_evaluation')}</p>
          </div>
          <div className='col-xs-12 col-sm-6'>
            <div styleName='field-label'>Follow Up Session (CPP) Rate</div>
            <p>{$$data.get('child_follow_up_session')}</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12 col-sm-6'>
            <div styleName='field-label'>AUD rate - Therapy</div>
            <p>{$$data.get('alcohol_use_disorder_session')}</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <Link styleName='edit-link' to={PROVIDERS_DETAILS_EDIT_RATES.route} replace>
              Edit Rates
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

export default CSSModules(DisplayProviderRates, styles, { allowMultiple: true })
