export const spacing = Object.freeze({
  '0px': '0px',
  '4px': '4px',
  '8px': '8px',
  '12px': '12px',
  '16px': '16px',
  '20px': '20px',
  '24px': '24px',
  '32px': '32px',
  '40px': '40px',
  '48px': '48px',
  '56px': '56px',
  '64px': '64px',
  '72px': '72px',
})

export interface Spacing {
  '0px': string
  '4px': string
  '8px': string
  '12px': string
  '16px': string
  '20px': string
  '24px': string
  '32px': string
  '40px': string
  '48px': string
  '56px': string
  '64px': string
  '72px': string
}
