import React, { FunctionComponent } from 'react'

import { BaseButton, BaseButtonProps, ButtonType } from '../baseButton/BaseButton'

export type IconButtonProps = Omit<BaseButtonProps, 'rightIcon' | 'loading' | 'buttonType' | 'text' | 'selected'>

export const IconButton: FunctionComponent<IconButtonProps> = (props) => {
  return <BaseButton {...props} buttonType={ButtonType.ICON} />
}

export default IconButton
