import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import { ProviderAvailability } from '@lyrahealth-inc/shared-app-logic'

import { getClientAppointmentsProviderAvailability } from '../../../data/lyraTherapy/clientSelectors'
import { logToSumoLogic } from '../../utils/utils'
import { ProviderAvailabilityResponse } from '../availabilityCard/AvailabilityCard'

type Params = {
  providerId?: string
}

export const useAvailabilitiesLogging = ({ providerId }: Params) => {
  const providerAvailability: ProviderAvailabilityResponse[] =
    useSelector(getClientAppointmentsProviderAvailability) ?? []
  const providerAvailabilities: ProviderAvailability[] = providerAvailability && providerAvailability[0]?.availability
  // useRef is not necessary in the depency array for the useEffect
  const loggedSumo = useRef(false)

  useEffect(() => {
    if (!loggedSumo.current && providerAvailabilities) {
      logToSumoLogic('providerCalendarAvailableSlots', providerId, {
        action: 'Provider loaded client list',
        providerAvailabilitiesCount: providerAvailabilities.length,
        providerId: providerId,
      })
      loggedSumo.current = true
    }
  }, [providerAvailabilities, providerId])
}
