import React from 'react'
import Tooltip from '../tooltip/Tooltip'

const CoachingIcon: React.FC<CoachingIconProps> = ({
  width = 20,
  showTooltip = true,
  tooltip = 'Coaching',
  customTooltipWidth,
  ...props
}) => {
  const icon = (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} viewBox='0 0 52 52' {...props}>
      <g id='CoachingIcon' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Traditional_Coaching_icon'>
          <rect id='Rectangle' fill='#D6E3EB' x='0' y='0' width='52' height='52' rx='4' />
          <circle
            id='Oval'
            fill='#5D7B8A'
            transform='translate(26.000000, 12.000000) rotate(-180.000000) translate(-26.000000, -12.000000) '
            cx='26'
            cy='12'
            r='6'
          />
          <circle
            id='Oval'
            fill='#5D7B8A'
            transform='translate(26.000000, 40.000000) rotate(-180.000000) translate(-26.000000, -40.000000) '
            cx='26'
            cy='40'
            r='6'
          />
          <circle
            id='Oval'
            fill='#5D7B8A'
            transform='translate(40.000000, 26.000000) rotate(-90.000000) translate(-40.000000, -26.000000) '
            cx='40'
            cy='26'
            r='6'
          />
          <circle
            id='Oval'
            fill='#5D7B8A'
            transform='translate(12.000000, 26.000000) rotate(-90.000000) translate(-12.000000, -26.000000) '
            cx='12'
            cy='26'
            r='6'
          />
        </g>
      </g>
    </svg>
  )

  return showTooltip ? (
    <Tooltip content={tooltip} delayHide={0} placement='bottom' customWidth={customTooltipWidth}>
      {icon}
    </Tooltip>
  ) : (
    icon
  )
}

type CoachingIconProps = {
  showTooltip: string
  width?: number
  tooltip?: string
  customTooltipWidth?: string
}

export default CoachingIcon
