import { createSelector } from '@reduxjs/toolkit'

import { getPracticesState } from '../../data/practicesSelectors'

export const getRegisterPractice = createSelector(getPracticesState, (practicesState) => practicesState.register)

export const getRegisterPracticeShowModal = createSelector(
  getRegisterPractice,
  (registerPracticeState) => registerPracticeState.showModal,
)
