import React, { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'

import { useTheme } from 'styled-components/native'

import { VideoControlButton } from './VideoControlButton'
import { tID } from '../../utils'
import { MessagingIcon } from '../icons'

export type MessengerVideoControlButtonProps = {
  disabled: boolean
  onPress: () => void
  enabledColor?: string
  disabledColor?: string
  iconColor?: string
  size?: number
  unreadMessageCount?: number
}

export const MessengerVideoControlButton: FunctionComponent<MessengerVideoControlButtonProps> = ({
  disabled,
  enabledColor,
  disabledColor,
  iconColor,
  onPress,
  size = 64,
  unreadMessageCount,
}) => {
  const { formatMessage } = useIntl()
  const { colors } = useTheme()
  const { videoControlButton } = colors.components
  const iconSize = size / 2.6

  return (
    <VideoControlButton
      enabledColor={enabledColor || videoControlButton.enabledColor.fill}
      enabledIcon={
        <MessagingIcon
          unreadMessages={unreadMessageCount}
          fillColor={iconColor || colors.iconInverse}
          size={iconSize}
        />
      }
      disabledColor={disabledColor || videoControlButton.disabledColor.fill}
      disabledIcon={
        <MessagingIcon
          unreadMessages={unreadMessageCount}
          fillColor={iconColor || colors.iconInverse}
          size={iconSize}
        />
      }
      onPress={onPress}
      disabled={disabled}
      testID={tID('messenger')}
      size={size}
      accessibilityLabel={formatMessage(
        {
          defaultMessage: '{unreadMessageCount} messages',
          description: 'Messaging icon button that displays a number badge representing unread messages',
        },
        {
          unreadMessageCount,
        },
      )}
    />
  )
}
