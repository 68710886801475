export const doesImageExist = (url: string) => {
  return new Promise(function (resolve, reject) {
    /* eslint-disable */
    // Image is part of DOM and not ECMAScript
    let img = new Image()
    /* eslint-enable */
    img.onload = function () {
      resolve(true)
    }
    img.onerror = function (error) {
      reject(error)
    }
    img.src = url
  })
}
