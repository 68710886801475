import React, { ReactElement } from 'react'
import ReactMarkdown from 'react-markdown'
import { isString } from 'lodash-es'
import classNames from 'classnames'
import styles from './checkbox.module.scss'
import { FieldInputProps } from 'react-final-form'
import BaseInput from '../baseInput/BaseInput'

const Checkbox: React.FC<CheckboxProps> = ({
  children,
  readOnly = false,
  stroked,
  fieldInfoId,
  disabled = false,
  input,
  meta: { touched, error, submitError, warning },
  clickableLabel = true,
  className = undefined,
  dataTestId,
}) => {
  let idText = ''
  if (clickableLabel) {
    idText = input.name
  }

  const components = { p: (props: any) => props.children }
  // eslint-disable-next-line react/no-children-prop
  const checkboxLabel = isString(children) ? <ReactMarkdown children={children} components={components} /> : children

  const labelContainerClasses = classNames(
    className,
    { selected: input.checked },
    styles['check-item-container'],
    { [styles['read-only']]: readOnly },
    { [styles.stroked]: stroked },
    { [styles.disabled]: disabled },
  )

  return (
    <BaseInput
      className={className}
      touched={touched}
      error={error || submitError}
      warning={warning}
      readOnly={readOnly}
      disabled={disabled}
      htmlFor={input.name}
      fieldInfoId={fieldInfoId}
    >
      <div className={styles.container}>
        <label className={labelContainerClasses}>
          <div className={styles['checkbox-container']}>
            <input
              {...input}
              id={input.name}
              aria-describedby={fieldInfoId}
              type='checkbox'
              disabled={readOnly ? true : disabled}
              className={touched && error ? styles['checkbox-invalid'] : styles.checkbox}
              data-test-id={dataTestId}
            />
          </div>
          <div
            htmlFor={idText}
            className={classNames(styles['checkbox-label'], { [styles.invalid]: touched && error })}
          >
            {checkboxLabel}
          </div>
        </label>
      </div>
    </BaseInput>
  )
}

type CheckboxProps = {
  meta: {
    touched: boolean
    error: string
    submitError: string
    warning: string
  }
  input: FieldInputProps<any, any>
  readOnly?: boolean
  stroked: boolean
  disabled?: boolean
  clickableLabel?: boolean
  className: string
  fieldInfoId: string
  children: ReactElement<any, any>
  dataTestId?: string
}

export default Checkbox
