import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

export const SendIcon: React.FC<SendIconProps> = ({ size = 24, fillColor, isFilled, testID }) => {
  const { colors } = useTheme()
  const { sendIcon } = colors.icons

  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' testID={testID}>
      {isFilled ? (
        <Path
          fill={fillColor || sendIcon.fill}
          d='M4.4,20.4 L21.85,12.92 C22.66,12.57 22.66,11.43 21.85,11.08 L4.4,3.6 C3.74,3.31 3.01,3.8 3.01,4.51 L3,9.12 C3,9.62 3.37,10.05 3.87,10.11 L18,12 L3.87,13.88 C3.37,13.95 3,14.38 3,14.88 L3.01,19.49 C3.01,20.2 3.74,20.69 4.4,20.4 Z'
        />
      ) : (
        <Path
          fill={fillColor || sendIcon.fill}
          d='M18,12 L3.87,10.11 C3.37,10.05 3,9.62 3,9.12 L3.01,4.51 C3.01,3.8 3.74,3.31 4.4,3.6 L21.85,11.08 C22.66,11.43 22.66,12.57 21.85,12.92 L4.4,20.4 C3.74,20.69 3.01,20.2 3.01,19.49 L3,14.88 C3,14.38 3.37,13.95 3.87,13.88 L18,12 Z M4.50833619,5.27837448 L4.50095254,8.68103602 L16.0500756,10.2258232 L4.50833619,5.27837448 Z M4.5009343,15.3092384 L4.5083337,18.7215629 L16.0551861,13.7719712 L4.5009343,15.3092384 Z'
        />
      )}
    </Svg>
  )
}

type SendIconProps = {
  size?: number
  fillColor?: string
  isFilled?: boolean
  testID?: string
}
