import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

export type NoteIconProps = {
  size?: number
  fillColor?: string
  testID?: string
}

export const NoteIcon: FunctionComponent<NoteIconProps> = ({ size = 24, fillColor, testID }) => {
  const { colors } = useTheme()

  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' fill='none' testID={testID}>
      <Path
        d='M3.5 23.25C2.26 23.25 1.25 22.24 1.25 21V3C1.25 1.76 2.26 0.75 3.5 0.75H18.5C19.74 0.75 20.75 1.76 20.75 3V21C20.75 22.24 19.74 23.25 18.5 23.25H3.5Z'
        stroke={fillColor || colors.iconInactive}
      />
      <Path
        d='M18.5 0H14H3.5C1.843 0 0.5 1.343 0.5 3V21C0.5 22.657 1.843 24 3.5 24H18.5C20.157 24 21.5 22.657 21.5 21V3C21.5 1.343 20.157 0 18.5 0ZM18.5 1.5C19.327 1.5 20 2.173 20 3V21C20 21.827 19.327 22.5 18.5 22.5H3.5C2.673 22.5 2 21.827 2 21V3C2 2.173 2.673 1.5 3.5 1.5H14H18.5Z'
        fill={fillColor || colors.iconInactive}
      />
      <Path
        d='M15.5 8.25H6.5C6.086 8.25 5.75 7.914 5.75 7.5C5.75 7.086 6.086 6.75 6.5 6.75H15.5C15.914 6.75 16.25 7.086 16.25 7.5C16.25 7.914 15.914 8.25 15.5 8.25Z'
        fill={fillColor || colors.iconInactive}
      />
      <Path
        d='M11 12.75H6.5C6.086 12.75 5.75 12.414 5.75 12C5.75 11.586 6.086 11.25 6.5 11.25H11C11.414 11.25 11.75 11.586 11.75 12C11.75 12.414 11.414 12.75 11 12.75Z'
        fill={fillColor || colors.iconInactive}
      />
    </Svg>
  )
}
