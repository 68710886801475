import { createReducer } from '@reduxjs/toolkit'
import { forIn, groupBy, mergeWith } from 'lodash-es'
import moment from 'moment'

import {
  Appointment,
  appointmentUtilization,
  AvailabilitiesResponse,
  HistoricCharges,
  historicUtilization,
  targetUtilization,
  UtilizationCharges,
  utilizationQuarters,
  VideoSession,
} from '@lyrahealth-inc/shared-app-logic'
import { dateUtils } from '@lyrahealth-inc/ui-core'

import {
  CLEAR_HISTORIC_UTILIZATION,
  CLEAR_LT_APPOINTMENTS,
  CLEAR_SELECTED_APPOINTMENT,
  GET_APPOINTMENT_UTILIZATION,
  GET_HISTORIC_UTILIZATION,
  GET_LT_APPOINTMENTS_FOR_PATIENT,
  GET_PROVIDER_AVAILABILITY_V2,
  GET_TARGET_UTILIZATION,
  GET_UTILIZATION_CHARGES,
  GET_UTILIZATION_CHARGES_QUARTER,
  GET_UTILIZATION_QUARTERS,
  GET_VIDEO_SESSIONS,
  SET_APPOINTMENT,
  SET_CURRENT_CASELOAD_MANAGEMENT_DASHBOARD_QUARTER,
  SET_CURRENT_CMD_DETAILED_WEEKLY_VIEW_QUARTER,
  SET_CURRENT_SESSION_COUNT,
  UPDATE_LT_APPOINTMENT,
} from '../../../../common/constants/reduxConstants'

type AppointmentsState = {
  data: Appointment[]
  availabilityV2?: AvailabilitiesResponse
  currentQuarterCMDDetailedWeeklyView?: string
  currentSessionCount?: number
  nextAppointment?: Appointment
  targetUtilization?: targetUtilization
  unclosedPastAppointments: Appointment[]
  utilizationCharges?: UtilizationCharges
  utilizationQuarters?: { [key: string]: [string, string] }
  videoSessions?: VideoSession[]
  appointmentUtilization?: appointmentUtilization
  historicUtilization?: historicUtilization
  utilizationChargesQuarter?: HistoricCharges[]
  currentCaseloadManagementDashboardQuarter?: utilizationQuarters
  appointmentDetails?: Appointment
}

const initialState = {
  data: [],
  unclosedPastAppointments: [],
}

export default createReducer<AppointmentsState>(initialState, (builder) => {
  builder.addCase(GET_LT_APPOINTMENTS_FOR_PATIENT, (state, action: any) => {
    const appointments: Appointment[] = action.data || []
    const nextAppt = appointments.find(
      (appt) => moment(`${appt.startDate} ${appt.startTime}`) > moment() && appt.appointmentStatus !== 'canceled',
    )
    const pastAppointmentsToClose = appointments.filter((appt) => {
      return (
        // @ts-expect-error TS(2345): Argument of type 'Moment' is not assignable to par... Remove this comment to see the full error message
        dateUtils.getAppointmentDateTimeObject(appt).isBefore(moment()) &&
        !['canceled', 'missed', 'completed'].includes(appt.appointmentStatus)
      )
    })

    state.nextAppointment = nextAppt
    state.data = appointments
    state.unclosedPastAppointments = pastAppointmentsToClose
    return state
  })
  builder.addCase(GET_APPOINTMENT_UTILIZATION, (state, action: any) => {
    state.appointmentUtilization = action.data
    return state
  })
  builder.addCase(GET_TARGET_UTILIZATION, (state, action: any) => {
    state.targetUtilization = action.data
    return state
  })
  builder.addCase(GET_HISTORIC_UTILIZATION, (state, action: any) => {
    state.historicUtilization = action.data
    return state
  })
  builder.addCase(GET_UTILIZATION_CHARGES, (state, action: any) => {
    state.utilizationCharges = action.data
    return state
  })
  builder.addCase(GET_UTILIZATION_CHARGES_QUARTER, (state, action: any) => {
    state.utilizationChargesQuarter = action.data
    return state
  })
  builder.addCase(GET_UTILIZATION_QUARTERS, (state, action: any) => {
    state.utilizationQuarters = action.data
    return state
  })
  builder.addCase(CLEAR_HISTORIC_UTILIZATION, (state) => {
    state.historicUtilization = undefined
    return state
  })
  builder.addCase(SET_CURRENT_CASELOAD_MANAGEMENT_DASHBOARD_QUARTER, (state, action: any) => {
    state.currentCaseloadManagementDashboardQuarter = action.quarter
    return state
  })
  builder.addCase(SET_CURRENT_CMD_DETAILED_WEEKLY_VIEW_QUARTER, (state, action: any) => {
    state.currentQuarterCMDDetailedWeeklyView = action.quarter
    return state
  })
  builder.addCase(SET_CURRENT_SESSION_COUNT, (state, action: any) => {
    const appointments = state.data
    let currentSessionCount = 0
    const appointmentsByEpisode = groupBy(appointments, 'episodeId')
    forIn(appointmentsByEpisode[action.episodeId], (appt: Appointment) => {
      if (appt.appointmentStatus === 'completed') {
        currentSessionCount++
      }
    })
    state.currentSessionCount = currentSessionCount + 1
    return state
  })
  builder.addCase(UPDATE_LT_APPOINTMENT, (state, action: any) => {
    const idx = state.data.findIndex((appt) => appt.appointmentId === action.data.appointmentId)
    if (idx !== -1) {
      state.data[idx] = action.data
    }

    const nextAppt = state.data.find((appt) => moment(`${appt.startDate} ${appt.startTime}`) > moment())
    const pastAppointmentsToClose = state.data.filter((appt) => {
      return (
        // @ts-expect-error TS(2345): Argument of type 'Moment' is not assignable to par... Remove this comment to see the full error message
        dateUtils.getAppointmentDateTimeObject(appt).isBefore(moment()) &&
        !['canceled', 'missed', 'completed'].includes(appt.appointmentStatus)
      )
    })
    state.nextAppointment = nextAppt
    state.unclosedPastAppointments = pastAppointmentsToClose
    return state
  })
  builder.addCase(GET_PROVIDER_AVAILABILITY_V2, (state, action: any) => {
    state.availabilityV2 = action.data
    return state
  })
  builder.addCase(SET_APPOINTMENT, (state, action: any) => {
    state.appointmentDetails = mergeWith({}, action.data.appointment, state.appointmentDetails, (a, b) => a || b)
    return state
  })
  builder.addCase(CLEAR_SELECTED_APPOINTMENT, (state) => {
    state.appointmentDetails = undefined
    return state
  })
  builder.addCase(GET_VIDEO_SESSIONS, (state, action: any) => {
    state.videoSessions = action.data
    return state
  })
  builder.addCase(CLEAR_LT_APPOINTMENTS, () => initialState)
})
