import React, { FunctionComponent, ReactElement } from 'react'
import { Pressable, View } from 'react-native'

import styled, { useTheme } from 'styled-components/native'

import { Subhead } from '../../atoms'
import { LyraLogo } from '../../atoms/icons/LyraLogo'
import { SubheadSize } from '../../styles'
import { ThemeType, tID } from '../../utils'
import { TabDropdown, TabDropdownProps } from '../tabDropdown/TabDropdown'

export interface ApplicationHeaderProps {
  backgroundColor?: string
  currentPathname: string
  handleLogoClick?: () => void
  logoItems: ReactElement[]
  menu?: ReactElement
  testID?: string
  title?: string
  tabs?: TabDropdownProps[]
}

const Container = styled.View<{ theme: ThemeType; backgroundColor?: string }>(
  ({ theme: { colors, spacing }, backgroundColor }) => ({
    backgroundColor: backgroundColor ?? colors.backgroundPrimary,
    padding: `${spacing['12px']} ${spacing['32px']}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottomWidth: '1px',
    borderBottomColor: colors.borderDefault,
    zIndex: 1,
  }),
)

const LogoSection = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  marginTop: spacing['4px'],
  flexDirection: 'row',
}))

const LogoContainer = styled(Pressable)({
  margin: 'auto 0',
})

const LeftContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  flexDirection: 'row',
  gap: spacing['48px'],
}))

const TabContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  flexDirection: 'row',
  gap: spacing['24px'],
  margin: 'auto 0',
}))

const RightContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  flexDirection: 'row',
  gap: spacing['12px'],
  marginTop: 'auto',
  marginBottom: 'auto',
}))

/**
 * ApplicationHeader Component that is display at the top of the page
 */
export const ApplicationHeader: FunctionComponent<ApplicationHeaderProps> = ({
  backgroundColor,
  currentPathname,
  handleLogoClick,
  logoItems,
  menu,
  tabs,
  testID,
  title,
}) => {
  const { colors } = useTheme()

  return (
    <Container backgroundColor={backgroundColor} testID={tID(testID)}>
      <LeftContainer>
        <LogoSection>
          <LogoContainer testID={tID('ApplicationHeader-logo')} onPress={handleLogoClick}>
            <LyraLogo width={55} height={31} fillColor={colors.iconActive} />
          </LogoContainer>
          {logoItems}
        </LogoSection>
        <TabContainer>
          {tabs?.map((tab, index) => (
            <View key={index}>
              <TabDropdown {...tab} currentPathname={currentPathname} />
            </View>
          ))}
        </TabContainer>
      </LeftContainer>
      <RightContainer testID={tID('ApplicationHeader-rightContainer')}>
        {title && (
          <Subhead
            text={title}
            size={SubheadSize.XSMALL}
            color={colors.textSecondary}
            testID={tID('ApplicationHeader-title')}
          />
        )}
        {menu}
      </RightContainer>
    </Container>
  )
}
