import React, { useEffect } from 'react'
import { CardSmall } from '@lyrahealth-inc/ui-core'
import { ProviderAdminProviderInfo, ProviderUser } from '@lyrahealth-inc/shared-app-logic'
import styles from './blockPaymentCard.module.scss'
import { track } from '../../../../mixpanel/mixpanelTracking'
import { AVAILABILITY_CONSIDERED_FRESH_DAYS, ROLES } from '../../common/constants/appConstants'
import { hasRole } from '../../common/utils/utils'
import { differenceInDays, parseISO } from 'date-fns'

const BlockPaymentCard: React.FC<BlockPaymentCardProps> = ({ loggedInUser, selectedProvider }) => {
  const provider = selectedProvider || loggedInUser
  const userRole = provider.roles || []

  // we assume all non individual provider is practice provider
  const updatedAtDateLimit = hasRole(userRole, [ROLES.INDIVIDUAL_PROVIDER])
    ? AVAILABILITY_CONSIDERED_FRESH_DAYS[ROLES.INDIVIDUAL_PROVIDER]
    : AVAILABILITY_CONSIDERED_FRESH_DAYS[ROLES.PRACTICE_PROVIDER]

  // user.roles could potentially be None (if it is practice_provider who has never logged in)
  const isAvailabilityProvider =
    ((userRole && userRole.length === 0) || hasRole(userRole, [ROLES.INDIVIDUAL_PROVIDER, ROLES.PRACTICE_PROVIDER])) &&
    !provider.scheduling_enabled

  const isStaleAvailability =
    !provider?.capacity_updated_at ||
    differenceInDays(new Date(), parseISO(provider?.capacity_updated_at)) >= updatedAtDateLimit

  // we do not want to block payment_admin frerom submitting invoices
  // we will check the loggedInUser making sure its not payment admin
  const isPaymentAdmin = hasRole(loggedInUser.roles, [ROLES.PAYMENTS_ADMIN])
  const blockPayment = !isPaymentAdmin && isAvailabilityProvider && isStaleAvailability

  useEffect(() => {
    if (blockPayment) {
      track({ event: 'BLOCK_PAYMENT' })
    }
  }, [blockPayment])

  const providerName = hasRole(userRole, [ROLES.INDIVIDUAL_PROVIDER]) ? 'your' : `${provider.first_name}'s`
  const staleDurationString = `${updatedAtDateLimit} days`

  return blockPayment ? (
    <div className={styles['payment-blocker']} data-test-id='BlockPaymentCard-Displayed'>
      <CardSmall>
        <p>
          It looks like it&apos;s been more than {staleDurationString} since you updated {providerName} availability.
          Please take a moment to confirm {providerName} current ability to take new clients. Having up-to-date
          information helps Lyra find you the right clients at the right time.
        </p>
      </CardSmall>
    </div>
  ) : null
}

type BlockPaymentCardProps = {
  loggedInUser: ProviderUser
  selectedProvider: ProviderAdminProviderInfo
}

export default BlockPaymentCard
