import React, { FunctionComponent } from 'react'
import { Path, Svg } from 'react-native-svg'

import { PrimaryNeedsWords } from '@lyrahealth-inc/shared-app-logic'

import { tID } from '../../utils'

type DrinkingUsingIllustrationProps = {
  size?: string | number
  selected?: string
}

export const DrinkingUsingIllustration: FunctionComponent<DrinkingUsingIllustrationProps> = ({
  selected = false,
  size,
}) => (
  <Svg
    width={size}
    height={size}
    viewBox='0 0 48 48'
    fill='none'
    testID={tID(`${PrimaryNeedsWords.ALCOHOL_DRUG_USE}${selected ? '-Selected-' : '-'}Illustration`)}
  >
    <Path
      d='M23.5215 39.8591C21.656 41.3712 19.7904 42.8833 17.9296 44.3954C16.3098 45.7062 14.8703 46.5957 12.738 45.966C9.11691 44.8963 5.78611 42.2396 3.56714 39.2365C2.41318 37.6776 1.46052 35.9502 0.830871 34.1174C0.222291 32.3479 -0.318407 30.356 0.222292 28.4998C0.620209 27.1258 1.63139 26.1123 2.72215 25.2135C2.87429 25.0871 3.02878 24.9653 3.18326 24.8413C3.49223 24.5932 3.80823 24.3521 4.10783 24.111C4.13358 24.0923 4.16167 24.0689 4.1921 24.0455C5.84229 22.7136 7.49481 21.3841 9.17543 20.092C12.9369 17.2036 16.6937 14.1116 20.722 11.5813C20.7267 11.5813 20.7291 11.5789 20.7314 11.5766C22.419 10.5186 24.2097 9.70171 26.2344 9.67129C28.0039 9.64554 29.8414 10.3571 31.5875 9.86556C33.8861 9.22187 35.6908 7.09185 37.4556 5.87703C38.8998 4.67626 40.337 3.4825 41.7812 2.27939C42.4507 1.66847 43.209 1.75039 43.7544 2.15299C43.9276 2.24662 44.0938 2.38238 44.2436 2.56495L47.104 6.07365C47.1859 6.1743 47.2491 6.27729 47.3006 6.38028L47.3076 6.38964C47.6329 6.78053 47.9068 7.23697 47.9513 7.70979C48.0285 8.00706 48.0238 8.31368 47.8647 8.63904C47.3825 9.61277 46.0998 10.4063 45.304 11.1225C44.2366 12.0845 43.2395 13.0817 42.1721 14.0437C40.8052 15.4341 39.0918 17.426 38.5558 19.4905C38.0268 21.5245 38.9045 23.8207 38.2889 25.8384C37.6195 28.034 36.0372 29.6748 34.3144 31.1097C30.7519 34.0659 27.1238 36.945 23.5309 39.8591H23.5215Z'
      fill={selected ? '#3BA5B5' : '#85CCD5'}
    />
    <Path
      d='M23.1171 31.2329C24.161 30.3551 25.198 29.4727 26.2325 28.5879C27.3186 27.6587 28.5943 26.7598 29.547 25.6925C30.6845 24.4191 30.1181 23.3097 29.1795 22.2283C28.7816 21.4769 28.1449 20.7724 27.6721 20.1474C26.3543 18.4036 25.0013 16.6878 23.611 15.0002C22.663 13.8463 21.6986 12.704 20.7155 11.5781C16.6872 14.1084 12.9304 17.2005 9.16892 20.0889C7.48831 21.3809 5.83578 22.7104 4.18559 24.0423C4.15751 24.0657 4.12942 24.0891 4.10133 24.1078C3.80172 24.3489 3.48807 24.5924 3.17676 24.8381C3.84151 25.6995 4.58117 26.9003 5.25763 27.7289C7.08103 29.9689 8.4831 32.2324 10.4025 34.3858C11.9169 36.0875 13.7356 38.6318 16.2121 36.9091C18.6347 35.2238 20.856 33.1288 23.1147 31.2329H23.1171Z'
      fill={selected ? '#A6DDE4' : '#EDE6DE'}
    />
    <Path
      d='M27.4058 37.5717C26.1442 37.0169 24.0188 36.4317 22.6004 37.1293C21.2545 37.7917 21.2662 39.1305 21.9169 40.0762C21.9614 40.3103 22.0878 40.535 22.3242 40.7152C23.738 41.7896 25.4022 42.6112 27.2162 42.9435C28.6885 43.2127 30.6313 42.5316 30.6055 40.7339C30.5821 39.1937 28.637 38.11 27.4082 37.5717H27.4058Z'
      fill={selected ? '#85CCD5' : '#FFB199'}
    />
    <Path
      d='M10.9109 9.48527C9.93488 8.22832 8.14894 7.60804 6.62515 8.30322C6.39576 8.40855 6.18042 8.53963 5.98146 8.69178C3.71801 10.0096 3.31073 13.4457 6.10083 14.8735C9.5276 16.6267 13.2282 12.4743 10.9109 9.48761V9.48527Z'
      fill={selected ? '#85CCD5' : '#FFDC69'}
    />
    <Path
      d='M45.685 30.7736C44.8494 29.6969 43.3209 29.1679 42.0148 29.7601C41.8182 29.849 41.6333 29.9637 41.4624 30.0924C39.5243 31.2207 39.1756 34.1629 41.5654 35.3847C44.4983 36.8851 47.6699 33.3296 45.685 30.7736Z'
      fill={selected ? '#3BA5B5' : '#8CC94B'}
    />
  </Svg>
)
