import React from 'react'
import colors from '../../../styles/_0colors.scss'

type OwnProps = {
  width?: string | number
}

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof ChartIcon.defaultProps

// @ts-expect-error TS(7022): 'ChartIcon' implicitly has type 'any' because it d... Remove this comment to see the full error message
const ChartIcon = ({ width, fillColor }: Props) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} viewBox='0 0 180 180'>
      <g fill='none' fillRule='evenodd' transform='translate(20 57)'>
        <circle cx='134' cy='59' r='6' fill={fillColor} />
        <circle cx='6' cy='6' r='6' fill={fillColor} />
        <circle cx='49' cy='37' r='6' fill={fillColor} />
        <circle cx='91' cy='23' r='6' fill={fillColor} />
        <polyline stroke={fillColor} strokeWidth='3' points='6 6 49 37.671 91 23 134 59' />
      </g>
    </svg>
  )
}

ChartIcon.defaultProps = {
  width: 180,
  fillColor: colors.x_secondary_action,
}

export default ChartIcon
