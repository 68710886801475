import { formValidationConstants } from '@lyrahealth-inc/ui-core'

const addPracticeValidate = ($$values: any) => {
  const errors = {}

  if (!$$values.get('practice_name')) {
    ;(errors as any).practice_name = 'Required'
  }

  if (!$$values.get('practice_email')) {
    ;(errors as any).practice_email = 'Required'
  } else if (!formValidationConstants.isValidEmail($$values.get('practice_email'))) {
    ;(errors as any).practice_email = 'Invalid'
  }

  return errors
}

export default addPracticeValidate
