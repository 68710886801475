import { IntlShape } from 'react-intl'

import { CountryCode, findPhoneNumbersInText, getExampleNumber, parsePhoneNumber } from 'libphonenumber-js'
import examples from 'libphonenumber-js/mobile/examples'
import { isNil, startCase } from 'lodash-es'

import { ProviderLanguage as BCP47LanguageItem, LanguageItemForUIDisplay } from '../../../models/providers/Providers'
import { CARE_LANGUAGES } from '../../common/constants/constants'

/**
 * Get the E.164 format a phone number to use, for example, in the href of phone number links.
 * @param {string} phoneNumber - String containing a phone number (e.g. '(844) 993-3322' or '(800) 333–4119 prompt 1')
 * @param {string} countryCode - Optional parameter that is a 2 letter ISO country code, defaults to 'US' if parameter is absent
 * @returns {string} The phone number in E.164 format (e.g. '+18449933322')
 */
export const getPhoneNumberInE164Format = ({
  phoneNumber,
  countryCode = 'US',
}: {
  phoneNumber: string
  countryCode?: CountryCode
}) => {
  const phoneNumbers = findPhoneNumbersInText(phoneNumber, countryCode)

  return phoneNumbers?.[0]?.number?.number
}

/**
 * Converts phone numbers formatted like: +18775057147 to (877) 505–7147
 */
export const convertInternationPhoneNumberToDomestic = (internationalNumber?: string): string | undefined => {
  if (internationalNumber) {
    // Remove all characters other than numbers
    const sanitizedNumber = internationalNumber.replace(/\D/g, '')
    if (sanitizedNumber.length === 11 && sanitizedNumber[0] === '1') {
      // remove 1 from begining of string
      const countryCodeRemoved = sanitizedNumber.substring(1)
      const match = countryCodeRemoved.match(/^(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        return ['(', match[1], ') ', match[2], '-', match[3]].join('')
      }
    }
  }
  console.warn('Not a valid international number')
  return undefined
}

/**
 * Formats a phone number string for display e.g. 3108484848 will become (310) 848-4848
 */
export const formatPhoneNumberForDisplay = (phoneNumber?: string, countryCode: CountryCode = 'US') => {
  const parsedPhoneNumber = !isNil(phoneNumber) ? parsePhoneNumber(phoneNumber, countryCode) : phoneNumber
  if (parsedPhoneNumber) {
    return countryCode !== 'US' ? parsedPhoneNumber.formatInternational() : parsedPhoneNumber.formatNational()
  }
  return parsedPhoneNumber
}

// Returns a placeholder phone number formatted according to the provided country code
export const getPlaceholderPhoneNumberByCountryCode = (countryCode: CountryCode = 'US') => {
  const exampleNumber = getExampleNumber(countryCode, examples)
  if (exampleNumber) {
    return exampleNumber.formatNational()
  }
  return '0000000000'
}

/**
 * Converts phone numbers formatted like: (877) 505–7147 to +18775057147
 */
export const convertDomesticPhoneNumberToInternational = (domesticNumber: string): string | undefined => {
  if (domesticNumber) {
    // Remove all characters other than numbers
    const sanitizedNumber = domesticNumber.replace(/\D/g, '')
    if (sanitizedNumber.length === 10) {
      return `+1${sanitizedNumber}`
    }
  }
  console.warn('Not a valid domestic number')
  return undefined
}

/**
 *
 * @param inputString - e.g. 'internationaleap@icasworld.com' or 'internationaleap@icasworld.com (French)'
 * @returns the email address found in `inputString`, e.g. getEmailAddressFromString('internationaleap@icasworld.com (French)') returns 'internationaleap@icasworld.com',
 * if no email is found, this function returns undefined
 */
export const getEmailAddressFromString = (inputString: string) => {
  // eslint-disable-next-line no-control-regex
  const emailRegex =
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
  const emails = inputString.match(emailRegex)
  return emails?.[0]
}

/**
 * @param inputString - e.g. 'internationaleap@icasworld.com' or 'internationaleap@icasworld.com (French)'
 * @returns the email client found in `inputString`, e.g. getEmailAddressClientFromString('testEmail@gmail.com') returns 'gmail',
 * if no email client is found, this function returns undefined
 */
export const getEmailAddressClientFromString = (inputString: string) => {
  const emailRegex = /.+@([\w]+)\..+/
  const emails = inputString.match(emailRegex)
  return emails?.[1]
}

/**
 * Cleans up an email address input. Removes any leading and trailing spaces.
 * @param inputString - e.g. '   test@email.com   '
 * @returns - test@email.com
 */
export const sanitizeEmailAddressInput = (inputString: string | undefined) => {
  return inputString ? inputString.trim() : undefined
}

/**
 * Given the supplied country return the di
 */
export const getCountryDisplayName = (countryCode: string) => {
  const countryName = new Intl.DisplayNames(['en'], { type: 'region' }).of(countryCode)

  if (!countryName) {
    return
  }

  const countriesWithDefiniteArticle = [
    'BAHAMAS',
    'CAYMAN ISLANDS',
    'CENTRAL AFRICAN REPUBLIC',
    'CHANNEL ISLANDS',
    'COMOROS',
    'CHECH REPUBLIC',
    'DOMINICAN REPUBLIC',
    'FALKLAND ISLANDS',
    'GAMBIA',
    'ISLE OF MAN',
    'IVORY COAST',
    'LEEWARD ISLANDS',
    'MALDIVES',
    'MARSHALL ISLANDS',
    'NETHERLANDS',
    'NETHERLANDS ANTILLES',
    'PHILIPPINES',
    'SOLOMON ISLANDS',
    'TURKS AND CAICOS ISLANDS',
    'UNITED ARAB EMIRATES',
    'UNITED KINGDOM',
    'UNITED STATES',
    'VIRGIN ISLANDS',
  ]
  return countryName && countriesWithDefiniteArticle.includes(countryName.toUpperCase())
    ? `The ${countryName}`
    : countryName
}

export const getInitials = (name: string) => {
  let letters = name.match(/\b\w/g) || []
  const titleIndex = name.indexOf(',')
  if (titleIndex > -1) {
    letters = name.slice(0, titleIndex).match(/\b\w/g) || []
  }
  return ((letters.shift() || '') + (letters.pop() || '')).toUpperCase()
}

export const articlize = (company = 'a') => {
  const letter = company.charAt(0).toLowerCase()
  let text = 'a'
  if (['a', 'e', 'i', 'o', 'u'].includes(letter)) {
    text = 'an'
  }
  return text
}

/**
 * Used for modality preferences to convert answers to int for the payload
 */
export const getStringIndexAsInt = (index: string, zeroBased?: boolean) => {
  const int = parseInt(index, 10)
  return zeroBased ? int - 1 : int
}

export const objectifyBCP47LanguageItems = ({
  languageItems = [],
  intl,
  tagOfSelectedLanguage,
}: {
  languageItems: BCP47LanguageItem[]
  intl: IntlShape
  tagOfSelectedLanguage?: string
}): LanguageItemForUIDisplay[] =>
  languageItems
    .map((language) => {
      if (!CARE_LANGUAGES[language?.tag]) {
        return null
      }
      return {
        name: intl.formatMessage(CARE_LANGUAGES[language?.tag]),
        id: language.tag, // e.g. 'fr', 'de', 'en'
        isSelected: tagOfSelectedLanguage === language.tag,
      } as LanguageItemForUIDisplay
    })
    .filter((lang): lang is LanguageItemForUIDisplay => lang !== null)

/**
 * Takes a delimited string and applies `startCase` to each segment
 * @param inputString
 * @param delimiter delimiter used in the input string
 * @param joinDelimiter delimiter to use for the output string
 */
export const startCaseDelimitedString = (inputString: string, joinDelimiter = ', ', delimiter = ',') => {
  return inputString
    .split(delimiter)
    .filter((element) => element.length > 0)
    .map((element) => startCase(element))
    .join(joinDelimiter)
}

export const getWorkLifeServiceCode = function (customerName: string, workLifeServiceDependentCareCode?: string) {
  return workLifeServiceDependentCareCode ? workLifeServiceDependentCareCode : `Lyra-${customerName}`
}

/**
 * Taken from ui-core. Customer phone number should be a US number.
 * Formats a string consisting of digits as a phone number of format +1 (xxx) yyy-zzzz,
 * with the international calling code +1 only included if the phone number already includes it.
 * Can only handle strings of 10+ digits long; will return the unmodified string otherwise.
 */
export const formatCustomerPhoneNumber = function (phone = '') {
  phone = phone?.replace(/\D/g, '')
  if (phone?.length < 10) {
    console.warn(`This phone number is missing digits.
    Please reach out to the appropriate person for the correct phone number.`)
    return phone
  }
  const internationalAccessCode = phone.charAt(0) === '1' ? '+1 ' : ''
  const domesticPhoneNumber = internationalAccessCode.length > 0 ? phone.substring(1) : phone
  const areaCode = domesticPhoneNumber.substring(0, 3)
  const centralOfficeCode = domesticPhoneNumber.substring(3, 6)
  const subscriberNumber = domesticPhoneNumber.substring(6, 10)
  const extension = domesticPhoneNumber.length > 10 ? ' ext. ' + domesticPhoneNumber.substring(10) : ''

  return `${internationalAccessCode}(${areaCode}) ${centralOfficeCode}-${subscriberNumber}${extension}`
}

/**
 * @param num number
 * @returns the number with an ordinal indicator added to the end, such as '1st', '2nd', '15th', etc
 * Ref: https://gist.github.com/jlbruno/1535691/db35b4f3af3dcbb42babc01541410f291a8e8fac
 */
export const addOrdinalSuffix = function (num: number) {
  const ordinalStrings = ['th', 'st', 'nd', 'rd']
  const doubleDigit = num % 100
  return num + (ordinalStrings[(doubleDigit - 20) % 10] || ordinalStrings[doubleDigit] || ordinalStrings[0])
}
