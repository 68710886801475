import { createReducer } from '@reduxjs/toolkit'

import { Appointment, VideoSession } from '@lyrahealth-inc/shared-app-logic'

import {
  CLEAR_VIDEO_SESSION_INFO,
  GET_LT_APPOINTMENTS,
  GET_VIDEO_CALL_TOKEN,
  RESET_VIDEO_SESSION_INFO,
  UPDATE_VIDEO_SESSION_METADATA,
  VIDEO_SESSION_SETTINGS_UPDATE,
  VIDEO_SESSION_STATUS_UPDATE,
} from '../../common/constants/reduxConstants'

type Settings = {
  videoInput?: string
  audioInput?: string
  audioOutput?: string
}

type VideoState = {
  sessionOpen: boolean
  sessionInfo?: VideoSession
  settings: Settings
  appointments: Appointment[]
}

const initialState = {
  sessionOpen: false,
  settings: {},
  appointments: [],
}

export default createReducer<VideoState>(initialState, (builder) => {
  builder.addCase(GET_LT_APPOINTMENTS, (state, action: any) => {
    state.appointments = action.data
    return state
  })
  builder.addCase(GET_VIDEO_CALL_TOKEN, (state, action: any) => {
    state.sessionInfo = action.data
    return state
  })
  builder.addCase(UPDATE_VIDEO_SESSION_METADATA, (state, action: any) => {
    state.sessionInfo = action.data
    return state
  })
  builder.addCase(CLEAR_VIDEO_SESSION_INFO, (state, _: any) => {
    state.sessionOpen = false
    state.sessionInfo = undefined
    // leave settings to save preferences
    return state
  })
  builder.addCase(VIDEO_SESSION_STATUS_UPDATE, (state, action: any) => {
    state.sessionOpen = action.data
    return state
  })
  builder.addCase(VIDEO_SESSION_SETTINGS_UPDATE, (state, action: any) => {
    state.settings = action.data
    return state
  })
  builder.addCase(RESET_VIDEO_SESSION_INFO, () => {
    return initialState
  })
})
