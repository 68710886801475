import React, { Component } from 'react'
import CSSModules from 'react-css-modules'
import styles from './tabFilters.module.scss'

type OwnProps = {
  tabList: string[]
  activeTab?: string
  handleClick?: (...args: any[]) => any
  className?: string
}

type State = any

type Props = OwnProps & typeof TabFilters.defaultProps

class TabFilters extends Component<Props, State> {
  static defaultProps = {
    handleClick: () => {},
  }

  constructor(props: Props) {
    super(props)
    const activeTab = props.activeTab || props.tabList[0]
    this.state = { activeTab }
  }

  componentDidUpdate() {
    if (this.props.activeTab && this.state.activeTab !== this.props.activeTab) {
      this.setState({
        activeTab: this.props.activeTab,
      })
    }
  }

  _handleTabClick = (tab: any) => {
    this.setState({ activeTab: tab })
    const { handleClick } = this.props
    handleClick(tab)
  }

  _renderTabs = () => {
    const { tabList } = this.props
    const tabs = tabList.map((tab: any, i: any) => {
      const key = `tabList_${i}`
      const isActive =
        this.state.activeTab && this.state.activeTab.toUpperCase() === tab.toUpperCase() ? 'active' : false
      return (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events
        <li
          key={key}
          onClick={() => {
            this._handleTabClick(tab)
          }}
          // @ts-expect-error TS(2322): Type 'false | "active"' is not assignable to type ... Remove this comment to see the full error message
          styleName={isActive}
          data-test-id={`TabFilters-tab-${tab.toLowerCase()}`}
        >
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a>{tab}</a>
        </li>
      )
    })
    return <ul>{tabs}</ul>
  }

  render() {
    return (
      <div className={this.props.className} styleName='navigation'>
        {this._renderTabs()}
      </div>
    )
  }
}

export default CSSModules(TabFilters, styles)
