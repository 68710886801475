import React, { ReactElement } from 'react'
import Tooltip from '../../tooltip/Tooltip'
import styles from './inputField.module.scss'
import BaseInput from '../baseInput/BaseInput'
import { FieldInputProps } from 'react-final-form'
import { Placement } from '@popperjs/core'
import { TOOLTIP_PLACEMENTS } from '../../../atoms/tooltip/Tooltip'

const InputField: React.FC<InputFieldProps> = ({
  input,
  insetIcon,
  label,
  labelStyle,
  placeholder,
  type,
  tooltipContent,
  tooltipIcon,
  tooltipPlacement = TOOLTIP_PLACEMENTS.TOP,
  tooltipStyle,
  hintContent,
  maxLength,
  meta: { touched, error, submitError, warning },
  className,
  disabled,
  readonly,
  autocomplete,
  ...rest
}) => {
  const fieldInfoId = `${input.name}_info`
  return (
    <BaseInput
      className={className}
      type={type ?? input.type}
      label={label}
      labelStyle={labelStyle}
      hintContent={hintContent}
      toolTip={
        tooltipContent ? (
          <Tooltip
            content={tooltipContent}
            id={`${input.name}_tooltip`}
            triggerStyle={tooltipStyle}
            placement={tooltipPlacement}
          >
            {tooltipIcon}
          </Tooltip>
        ) : null
      }
      touched={touched}
      error={error || submitError}
      warning={warning}
      readOnly={readonly}
      disabled={disabled}
      insetIcon={insetIcon}
      htmlFor={input.name}
      fieldInfoId={fieldInfoId}
    >
      <input
        {...input}
        id={input.name}
        aria-label={label}
        aria-describedby={fieldInfoId}
        readOnly={readonly}
        disabled={disabled}
        placeholder={placeholder}
        type={type ?? input.type}
        maxLength={maxLength}
        className={touched && error ? styles['input-invalid'] : styles.input}
        autoComplete={autocomplete}
        {...rest}
      />
    </BaseInput>
  )
}

type InputFieldProps = {
  placeholder: string
  type: string
  meta: {
    touched: boolean
    error: string
    submitError: string
    warning: string
  }
  input: FieldInputProps<any, any>
  label: string
  labelStyle?: Dict // This style will be applied to the span containing the text and tooltip icon inside the field input's label.
  tooltipIcon?: ReactElement // This prop is for passing in an icon component (e.g. InfoIcon) if you want to use a different icon rather than using the default QuestionMarkIcon.
  tooltipContent?: string // This string is the text that shows up in the tooltip bubble.
  tooltipPlacement?: Placement // This determines where the tooltip will show up relative to the trigger icon (e.g. top, right, etc.)
  tooltipStyle?: Dict // This style will be applied to the span that wraps the tooltip icon.
  maxLength: number
  className?: string
  insetIcon: any
  hintContent?: ReactElement
  readonly: boolean
  disabled: boolean
  autocomplete: string
}

export default InputField
