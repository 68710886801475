import { useIntl } from 'react-intl'

import { useLocalizedCountryList } from './useLocalizedCountryList'
import { BaseCountryItem } from '../../../models'

export function useLocalizedCountryListFilter<T extends BaseCountryItem>(
  countryList: T[],
  search: string,
): (T & { localizedCountryName: string })[] {
  const { locale } = useIntl()
  const localizedCountryList = useLocalizedCountryList(countryList)

  const filtered = localizedCountryList.filter((country) => {
    const searchLowerCase = search.toLocaleLowerCase(locale)
    return country.localizedCountryName.toLocaleLowerCase(locale).startsWith(searchLowerCase)
  })

  return filtered
}
