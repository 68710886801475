import React from 'react'
import { reduxForm } from 'redux-form/immutable'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import CSSModules from 'react-css-modules'
import styles from './editProviderInfo.module.scss'
import providerInfoValidate from '../../../common/components/forms/providerInfo/providerInfoValidate'
import ProviderInfoForm from '../../../common/components/forms/providerInfo/ProviderInfoForm'
import { PROVIDERS_DETAILS_INFO } from '../../../common/constants/routingConstants'
import TermsOfUse from '../../../common/content/TermsOfUse'
import { BaseModal } from '@lyrahealth-inc/ui-core'
import * as providerDetailsActions from '../data/providerDetailsActions'
import withRouter from '../../../routing/withRouter'
import { getProvidersRegisterShowModal } from '../../data/providerSelectors'

type EditProviderInfoProps = {
  handleSubmit?: (...args: any[]) => any
  submitting?: boolean
  router?: any
  actions?: any
  showModal?: boolean
}

class EditProviderInfo extends React.Component<EditProviderInfoProps> {
  _submitForm = (_: any) => {
    // TODO flesh out like provider register when we get there
    this.props.router.navigate(PROVIDERS_DETAILS_INFO.route, { replace: true })
  }

  render() {
    const { handleSubmit, submitting, showModal } = this.props

    return (
      <div styleName='content-container'>
        <h2 styleName='title'>Edit Provider Info</h2>

        <ProviderInfoForm
          handleSubmit={handleSubmit}
          submitFunction={this._submitForm}
          submitting={submitting}
          termsOfUseHandler={() => this.props.actions.toggleProviderDetailsModal(true)}
          primaryButtonText='Save'
        />

        <BaseModal
          // @ts-expect-error TS(2322): Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
          isOpen={showModal}
          body={<TermsOfUse />}
          closeModal={() => this.props.actions.toggleProviderDetailsModal(false)}
          size='large'
        />
      </div>
    )
  }
}

const mapStateToProps = ($$state: any) => {
  return {
    showModal: getProvidersRegisterShowModal($$state),
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    actions: bindActionCreators({ ...providerDetailsActions }, dispatch),
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    reduxForm({
      form: 'editProviderInfoForm',
      validate: providerInfoValidate,
      touchOnBlur: false,
      // @ts-expect-error TS(2345): Argument of type 'typeof EditProviderInfo' is not ... Remove this comment to see the full error message
    })(CSSModules(EditProviderInfo, styles)),
  ),
)
