import { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'

import { VideoCallEffect } from './types'
type Effect = {
  label: string
  value: VideoCallEffect
}
export const useVideoCallEffects: () => Effect[] = () => {
  const { formatMessage } = useIntl()
  const getNameForEffect = useCallback(
    (effect: VideoCallEffect) => {
      switch (effect) {
        case VideoCallEffect.NONE:
          return formatMessage({ defaultMessage: 'None', description: 'No video effect selected' })
        case VideoCallEffect.BLUR:
          return formatMessage({ defaultMessage: 'Blur', description: 'Video effect that blurs background' })
        case VideoCallEffect.OFFICE:
          return formatMessage({ defaultMessage: 'Office', description: 'Video effect that adds office background' })
        case VideoCallEffect.HILL_LANDSCAPE:
          return formatMessage({
            defaultMessage: 'Landscape',
            description: 'Video effect that adds landscape background',
          })
      }
    },
    [formatMessage],
  )

  return useMemo(
    () =>
      Object.values(VideoCallEffect).map((effect) => ({
        label: getNameForEffect(effect),
        value: effect,
      })),
    [getNameForEffect],
  )
}
