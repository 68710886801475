import React, { FunctionComponent } from 'react'

import styled from 'styled-components/native'

import { tID } from '../../utils'
import { Image } from '../image/Image'

const ImageContainer = styled(Image)<{ size: number }>(({ size }) => ({
  width: size,
  height: size,
}))

/**
 * Illustration to show on the treatment card
 */
export const CreditCardIllustration: FunctionComponent<CaredCardIllustrationProps> = ({ size = 124 }) => {
  return (
    <ImageContainer
      size={size}
      testID={`${tID('CreditCardIllustration')}`}
      source={require('../../assets/credit-card.png')}
    />
  )
}

type CaredCardIllustrationProps = {
  size?: number
}
