import { createSelector } from '@reduxjs/toolkit'

export const getPaymentHistoryState = (state: Map<string, any>) => state?.get('paymentHistory')

export const getPaymentHistoryShowModal = createSelector(
  getPaymentHistoryState,
  (paymentHistoryState) => paymentHistoryState?.showModal,
)

export const getPaymentHistoryModalData = createSelector(
  getPaymentHistoryState,
  (paymentHistoryState) => paymentHistoryState?.modalData,
)

export const getPaymentHistoryHistoryData = createSelector(
  getPaymentHistoryState,
  (paymentHistoryState) => paymentHistoryState?.historyData,
)
