import { CLINICAL_MEASURES } from '../../features/outcomes/types'
import { FieldSchema, UiMetadata } from '../formMetadata/FormMetadata'
import { ProgramNames } from '../programs/Programs'
/**
 * Types in models are types that are unmodified API related data
 */
export interface Assignment {
  name?: string
  // some assignments like infosheet might not have assignment_responses defined
  assignment_responses?: AssignmentResponse[]
  content: Content
  content_id: string
  content_meta_data: Contentmetadata
  content_meta_data_id: string
  create_date: string
  draft_count: number
  episode_id: string
  id: string
  instructions: string | null
  is_initial: boolean
  next_send_date?: unknown
  patient_id: string
  patient_notification_sent: boolean
  progress: number
  provider_id: string
  provider_patient_id: string
  response_count: number
  session_period: number
  status: string
  unread_count: number
  update_date: string
  group?: ActivityGroupType
  title?: string
  past?: boolean
}

export interface Link {
  linkText: string
  linkUrl: string
}
export interface Medication {
  highlights: string
  id: string
  instructions?: string
  label: string
}
export interface Contentmetadata {
  content?: string
  contents?: LessonContent[]
  schema?: FieldSchema
  uiSchema?: UiMetadata
  lessonNumber?: number
  videoTotalLength?: string
  stage?: string
  links?: Link[]
  imageUrl?: string
  imageAlt?: string
  medicationOptions?: Medication[]
  selectedMedication?: Medication
  title?: string
  defaultInstructions?: string
  initialValues?: Dict
  validateOnBlur?: boolean
  report?: {
    fieldsToDisplay: string[]
  }
  useCustomizedActivityIntroduction?: boolean
  // pair this up with fullPageIsConditional
  withConditionalLastPages?: boolean
  clinicalMeasuresIncluded?: CLINICAL_MEASURES[]
}

export interface LessonContent {
  content_type: string
  description: string
  estimatedCompletionTimeMinutes?: number
  instructions?: string
  meta_data: Metadata
  name: string
  title: string
}

export interface Metadata {
  thumbnail?: string
  url?: string
  schema?: FieldSchema
  // some lessons do support the newer metadata
  uiSchema?: UiMetadata
}

export interface Content {
  assign_multiple?: boolean
  content_type: ContentType
  description?: string
  group: ActivityGroupType
  id?: string
  instructions?: string | null
  name: string
  program_ids?: string[]
  tags?: string[] | null
  title: string
  program_names?: ProgramNames[]
  program_id?: string
  meta_data?: Contentmetadata
  estimatedCompletionTimeMinutes?: number
  global_oid?: string
  scorable?: boolean
  stage?: string
  content_tags_info?: { category_name: string; name: string }[]
}

export interface AssignmentResponse {
  assignment_id: string
  create_date: string
  id: string
  provider_feedback: string | null
  provider_started: boolean | null
  response: Response
  status: string
  submit_date: string | null
  update_date: string
  viewed: boolean
}

export interface Response {
  [key: string]: VideoStatus | any
}

export interface AssignmentResponsePayload {
  provider_started?: boolean
  response: {
    [key: string]: any
  }
  status: 'new' | 'in_progress' | 'completed' | 'draft' | 'provider_draft'
}

interface VideoStatus {
  progress: number
  status: string
}

export type ActivityGroupType = 'lesson' | 'exercise' | 'assessment' | 'infosheet'
type ContentType = 'form' | 'video' | 'text' | 'mixed'

export interface VimeoResponse {
  data: Array<{
    uri: string
    files: Array<VimeoFile>
    duration: number
  }>
}

export interface VimeoTextTrackResponse {
  data: Array<{
    language: string
    link: string
    uri: string
    link_expires_time: string
  }>
}

export interface VimeoFile {
  quality: string
  type: string
  width: number
  height: number
  link: string
  created_time: string
  fps: number
  size: number
  md5: string
  public_name: string
  size_short: string
  duration: number
}

export enum AssignmentGroup {
  LESSON = 'lesson',
  ASSESSMENT = 'assessment',
  EXERCISE = 'exercise',
  INFO_SHEET = 'infosheet',
}

export enum AssignmentGroupLabel {
  LESSON = 'Lesson',
  ASSESSMENT = 'Assessment',
  EXERCISE = 'Exercise',
  INFO_SHEET = 'Guide',
}

const assignmentGroupLabelMap = new Map<AssignmentGroup, AssignmentGroupLabel>([
  [AssignmentGroup.LESSON, AssignmentGroupLabel.LESSON],
  [AssignmentGroup.ASSESSMENT, AssignmentGroupLabel.ASSESSMENT],
  [AssignmentGroup.EXERCISE, AssignmentGroupLabel.EXERCISE],
  [AssignmentGroup.INFO_SHEET, AssignmentGroupLabel.INFO_SHEET],
])

/**
 * Helper function to get the assignment group label out of the assignmentGroupLabelMap map
 */
export const getAssignmentGroupLabel = (assignmentGroup: AssignmentGroup) => {
  return assignmentGroupLabelMap.get(assignmentGroup) ?? ''
}

export interface VideoLinks {
  files: VimeoFile[]
  duration: number
}

export interface setAssignmentInterface {
  provider_id: string
  patient_id: string
  content_id: string
  content_meta_data: Metadata
  instructions?: string
  session_period: number
}

export type ExerciseDraftPayload = {
  assignment_id: string | null
  provider_started: boolean
  response: Dict
  status: string
  id: string | null
}

export type ContentTag = {
  name: string
}

export type CategoryTag = {
  category_id: string
  category_name: string
  content_tags: ContentTag[]
}
