import React from 'react'
import colors from '../../../styles/_0colors.scss'
import { Icon } from './types'
import { isString } from 'lodash-es'

const MessageIcon: React.FC<MessageIconProps> = ({
  width = 24,
  fillColor = colors.x_soft_black,
  unreadMessages = 0,
  customBadgeStyle,
  isFilled,
  withBadge = false,
  ...props
}) => {
  width = isString(width) ? parseInt(width) : width
  const badgeStyle: React.CSSProperties = {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    padding: '0 10%',
    top: -width / 3 + 'px',
    right: -width / 3 + 'px',
    color: colors.x_white,
    fontSize: width / 3 + 'px',
    lineHeight: width / 3 + 'px',
    height: width / 1.5 + 'px',
    minWidth: width / 1.5 + 'px',
    fontWeight: 600,
    textAlign: 'center',
    backgroundColor: colors.x_alert,
    border: width / 20 + 'px solid' + colors.x_white,
    borderRadius: width / 4,
    userSelect: 'none',
  }
  if (width < 30) {
    Object.assign(badgeStyle, {
      height: width / 1.25 + 'px',
      minWidth: width / 1.25 + 'px',
      fontSize: width / 2 + 'px',
      lineHeight: width / 2 + 'px',
      borderWidth: width / 15 + 'px',
    })
  }
  if (customBadgeStyle) Object.assign(badgeStyle, customBadgeStyle)
  const icon = (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} viewBox='0 0 24 24'>
      {isFilled ? (
        <path
          fill={fillColor}
          d='M20,2 L4,2 C2.9,2 2.01,2.9 2.01,4 L2,22 L6,18 L20,18 C21.1,18 22,17.1 22,16 L22,4 C22,2.9 21.1,2 20,2 Z M9,11 L7,11 L7,9 L9,9 L9,11 Z M13,11 L11,11 L11,9 L13,9 L13,11 Z M17,11 L15,11 L15,9 L17,9 L17,11 Z'
        />
      ) : (
        <path
          fill={fillColor}
          d='M20,2 L4,2 C2.9,2 2,2.9 2,4 L2,22 L6,18 L20,18 C21.1,18 22,17.1 22,16 L22,4 C22,2.9 21.1,2 20,2 Z M20,16 L6,16 L4,18 L4,4 L20,4 L20,16 Z M7,9 L9,9 L9,11 L7,11 L7,9 Z M11,9 L13,9 L13,11 L11,11 L11,9 Z M15,9 L17,9 L17,11 L15,11 L15,9 Z'
        />
      )}
    </svg>
  )
  return withBadge ? (
    <div style={{ position: 'relative' }} {...props}>
      {unreadMessages > 0 && (
        <div style={badgeStyle}>
          <div>{unreadMessages}</div>
        </div>
      )}
      {icon}
    </div>
  ) : (
    icon
  )
}

type MessageIconProps = Icon & {
  unreadMessages?: number
  customBadgeStyle?: Dict
  withBadge?: boolean
  onMouseDown?: () => void
}

export default MessageIcon
