import React, { useState } from 'react'
import styles from './fullFormContentHeader.module.scss'
import DefaultButton from '../../atoms/buttons/defaultButton/DefaultButton'
import XIcon from '../../atoms/icons/XIcon'
import LyraLogo from '../../atoms/icons/LyraLogo'
import BaseModal from '../../molecules/modal/BaseModal'

type FullFormContentHeaderProps = {
  title: string
  date: string
  setModalOpen: (arg1: boolean) => void
  children: React.ReactNode
}

const FullFormContentHeader: React.FC<FullFormContentHeaderProps> = ({ title, date, setModalOpen, children }) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  return (
    <div>
      <div className={styles['title-container']}>
        <div className={styles['lyra-logo']}>
          <LyraLogo fillColor={styles.x_white} width={54} />
        </div>
        <div className={styles.title}>
          <h3>{title}</h3>
          {date}
        </div>
        <div>
          <DefaultButton
            unstyled
            onClick={() => {
              setModalOpen(false)
            }}
            aria-label='delete'
            data-test-id='delete-btn'
          >
            <XIcon width={24} isFilled fillColor={styles.x_white} />
          </DefaultButton>
        </div>
      </div>
      <div>{children}</div>
      <BaseModal
        size='sm'
        isOpen={isDeleteModalOpen}
        body={<div>placeholder</div>}
        closeModal={() => setIsDeleteModalOpen(false)}
      />
    </div>
  )
}

export default FullFormContentHeader
