import React, { FunctionComponent } from 'react'
import { ViewStyle } from 'react-native'
import { Path, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { tID } from '../../utils/utils'

/**
 * Standard (X) icon
 */
export const XIcon: FunctionComponent<XIconProps> = ({ size = 24, fillColor, style }) => {
  const { colors } = useTheme()
  const { xIcon } = colors.icons

  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' fill='none' style={style} testID={tID('XIcon')}>
      <Path
        d='M18.3 5.7102C18.1131 5.52295 17.8595 5.41771 17.595 5.41771C17.3305 5.41771 17.0768 5.52295 16.89 5.7102L12 10.5902L7.10997 5.7002C6.92314 5.51295 6.66949 5.40771 6.40497 5.40771C6.14045 5.40771 5.8868 5.51295 5.69997 5.7002C5.30997 6.0902 5.30997 6.7202 5.69997 7.1102L10.59 12.0002L5.69997 16.8902C5.30997 17.2802 5.30997 17.9102 5.69997 18.3002C6.08997 18.6902 6.71997 18.6902 7.10997 18.3002L12 13.4102L16.89 18.3002C17.28 18.6902 17.91 18.6902 18.3 18.3002C18.69 17.9102 18.69 17.2802 18.3 16.8902L13.41 12.0002L18.3 7.1102C18.68 6.7302 18.68 6.0902 18.3 5.7102Z'
        fill={fillColor || xIcon.fill}
        fillRule='evenodd'
        clipRule='evenodd'
      />
    </Svg>
  )
}

type XIconProps = {
  size?: number
  fillColor?: string
  style?: ViewStyle
}
