import { IntlShape, MessageDescriptor } from 'react-intl'

export function getFormattedMessageSafe(
  message: MessageDescriptor | string | undefined,
  formatMessage: IntlShape['formatMessage'],
  values?: Record<string, string | number | boolean>,
) {
  if (typeof message === 'string') {
    return message
  }

  if (formatMessage === undefined || message === undefined || !message?.defaultMessage || !message?.id) {
    return undefined
  }

  return formatMessage(message, values)
}
