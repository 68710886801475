import React from 'react'
import { useIntl } from 'react-intl'
import { ScrollView } from 'react-native'

import { isEmpty } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import { AIDraftStatus, getFriendlyDate, Note, NoteStatusStates } from '@lyrahealth-inc/shared-app-logic'

import { GenericListItem, NotesBadge, SecondaryButton, TextButton } from '../../atoms'
import { BodyText, Size as BodyTextSize } from '../../atoms/bodyText/BodyText'
import { ThemeType, tID } from '../../utils'

type NotesListItem = Partial<Note> & {
  aiSummaryStatus?: AIDraftStatus
  appointmentTimeslot?: string
  authorName?: string | null
  sessionDate?: string
  sessionNumber?: number
  title?: string
}

export type NotesListProps = {
  notes?: NotesListItem[]
  onFinishNoteClick?: ((note: NotesListItem) => void) | null
  onAddNoteClick?: ((note: NotesListItem) => void) | null
  onViewNoteClick?: (note: NotesListItem) => void
  emptyListText?: string
  showNoteTitle?: boolean
}

const ItemsListContainer = styled.View<{ theme: ThemeType }>(({ theme: { colors, spacing } }) => ({
  marginBottom: spacing['24px'],
  padding: 0,
  borderRadius: '12px',
  border: `1px solid ${colors.borderDefault}`,
  backgroundColor: colors.backgroundPrimary,
  minHeight: '80px',
  height: 'fit-content',
  color: colors.textPrimary,
}))

const NotesBadgeContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  width: 'fit-content',
  padding: `${theme.spacing['8px']} 0`,
}))

const ActionButton = styled(SecondaryButton)({
  width: '130px',
})

const ReadOnlyButton = styled(TextButton)({
  width: '130px',
})

const SubtextContainer = styled.View({
  width: '530px',
})

const AuthorContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  marginTop: spacing['8px'],
}))

const EmptyListText = styled(BodyText)({
  margin: 'auto',
})

export const NotesList = ({
  notes,
  onFinishNoteClick,
  onAddNoteClick,
  onViewNoteClick,
  showNoteTitle,
  emptyListText = 'No notes yet',
}: NotesListProps) => {
  const { colors } = useTheme()
  const { formatMessage } = useIntl()

  if (isEmpty(notes)) {
    return (
      <ItemsListContainer testID={tID('NotesList-empty-list')}>
        <EmptyListText text={emptyListText} size={BodyTextSize.LARGE} />
      </ItemsListContainer>
    )
  }

  const renderActionButton = (note: NotesListItem) => {
    switch (note.status) {
      case NoteStatusStates.DRAFT:
        if (onFinishNoteClick) {
          return (
            <ActionButton
              testID={tID('NotesList-finishNoteButton')}
              onPress={() => onFinishNoteClick(note)}
              text={formatMessage({
                defaultMessage: 'Finish note',
                description: 'finish in progress note button',
              })}
            />
          )
        }
        break
      case NoteStatusStates.COMPLETED:
        if (onViewNoteClick) {
          return (
            <ReadOnlyButton
              testID={tID('NotesList-viewNoteButton')}
              onPress={() => onViewNoteClick(note)}
              text={formatMessage({
                defaultMessage: 'View',
                description: 'view completed note button',
              })}
            />
          )
        }
        break
      default:
        if (onAddNoteClick) {
          return (
            <ActionButton
              testID={tID('NotesList-addNoteButton')}
              onPress={() => onAddNoteClick(note)}
              text={formatMessage({
                defaultMessage: 'Add note',
                description: 'add a new note for a session note button',
              })}
            />
          )
        }
        break
    }
    return <></>
  }

  const renderNoteSubtext = (note: NotesListItem) => {
    const approverName = note?.body?.approverName
    const authorName = note?.body?.authorName
    const noteTitle = note?.body?.noteTitle
    const updateDate = note?.submit_date ?? note?.update_date

    return (
      <SubtextContainer testID={tID('NotesList-subtextContainer')}>
        {note.appointmentTimeslot && (
          <BodyText text={note.appointmentTimeslot} size={BodyTextSize.SMALL} color={colors.textInactive} />
        )}
        {showNoteTitle && noteTitle && (
          <ScrollView horizontal={true} contentContainerStyle={{ flexGrow: 1 }}>
            <BodyText text={noteTitle} size={BodyTextSize.LARGE} color={colors.textPrimary} />
          </ScrollView>
        )}
        {updateDate && (
          <BodyText
            text={formatMessage(
              {
                defaultMessage: 'Note Last Updated {dateUpdated}',
                description: 'date that the note was last updated in a friendly format',
              },
              {
                dateUpdated:
                  updateDate &&
                  getFriendlyDate({ date: new Date(updateDate), addPreposition: true, displayTime: true }),
              },
            )}
            size={BodyTextSize.SMALL}
            color={colors.textInactive}
          />
        )}
        {authorName && (
          <AuthorContainer>
            <BodyText
              text={formatMessage(
                {
                  defaultMessage:
                    'Submitted by {authorName}{hasApprover, select, true { • Signed by {approverName}} other {}}',
                  description: 'text describing the author of the note',
                },
                {
                  authorName: authorName,
                  approverName: approverName,
                  hasApprover: !!approverName,
                },
              )}
              size={BodyTextSize.SMALL}
              color={colors.textInactive}
            />
          </AuthorContainer>
        )}
      </SubtextContainer>
    )
  }

  return (
    <ItemsListContainer>
      {notes?.map((note, index) => (
        <GenericListItem
          key={note.id || index}
          title={note.title}
          subText={renderNoteSubtext(note)}
          testID={tID(`NotesList-${note.title}`)}
          largeText
          height='fit-content'
          rightItems={[renderActionButton(note)]}
          isLastItem={index === notes.length - 1}
          leftContainerColumnFlex
          icon={
            note?.aiSummaryStatus &&
            note.status !== NoteStatusStates.COMPLETED && (
              <NotesBadgeContainer>
                <NotesBadge status={note.aiSummaryStatus} />
              </NotesBadgeContainer>
            )
          }
        />
      ))}
    </ItemsListContainer>
  )
}
