import {
  GET_PROVIDERS_FOR_PRACTICE,
  PRACTICE_SELECTED,
  CLEAR_PRACTICE_DETAILS_STORE,
  TOGGLE_PRACTICE_DETAILS_MODAL,
  TOGGLE_ADD_PROVIDER_MODAL,
  GET_PRACTICE_PAYMENT_HISTORY,
  GET_PRACTICE,
  ADD_PROVIDER_TO_PRACTICE,
  actionStyles,
  actionAlertHandler,
} from '../../../common/constants/reduxConstants'
import {
  getPracticeProvidersData,
  getPracticeData,
  addProviderToPracticeData,
} from '../../../common/http/data/practices'
import { bannerMessages } from '../../../common/constants/appConstants'

export const getPractice = (id: any) => {
  const request = getPracticeData(id)
  return (dispatch: any) => {
    return new Promise(function (resolve, reject) {
      request.then(
        (practiceDetailsReturn) => {
          dispatch({
            type: GET_PRACTICE,
            payload: practiceDetailsReturn.data,
          })

          resolve(practiceDetailsReturn)
        },
        (error) => {
          if (!error.response) return
          actionAlertHandler({
            actionStyle: actionStyles.ERROR,
            message: error,
            dispatch,
            expires: true,
            action: GET_PRACTICE,
          })
          reject(error)
        },
      )
    })
  }
}

export const practiceSelected = (dataObj: any) => {
  return {
    type: PRACTICE_SELECTED,
    payload: dataObj,
  }
}

export const getProvidersForPractice = (id: any) => {
  const request = getPracticeProvidersData(id)

  return (dispatch: any) => {
    return new Promise(function (resolve, reject) {
      request.then(
        (practiceprovidersReturn) => {
          dispatch({
            type: GET_PROVIDERS_FOR_PRACTICE,
            payload: practiceprovidersReturn,
          })

          resolve(practiceprovidersReturn)
        },
        (error) => {
          actionAlertHandler({
            actionStyle: actionStyles.ERROR,
            message: error,
            dispatch,
            expires: true,
            action: GET_PROVIDERS_FOR_PRACTICE,
          })
          reject(error)
        },
      )
    })
  }
}

export const clearPracticeDetailsStore = () => {
  return {
    type: CLEAR_PRACTICE_DETAILS_STORE,
  }
}

export const togglePracticeDetailsModal = (show: any, data: any) => {
  return {
    type: TOGGLE_PRACTICE_DETAILS_MODAL,
    visible: show,
    data: data,
  }
}

export const toggleAddProviderModal = (show: any) => {
  return {
    type: TOGGLE_ADD_PROVIDER_MODAL,
    visible: show,
  }
}

export const addProviderToPractice = (practiceId: any, data: any) => {
  const request = addProviderToPracticeData(practiceId, data)
  return (dispatch: any) => {
    return request.then(
      (addProviderReturn) => {
        dispatch({
          type: ADD_PROVIDER_TO_PRACTICE,
          payload: addProviderReturn.data,
        })
        dispatch(toggleAddProviderModal(false))
        actionAlertHandler({
          actionStyle: actionStyles.SUCCESS,
          message: bannerMessages.ADD_PROVIDER_SUCCESS,
          dispatch,
          expires: true,
          action: GET_PROVIDERS_FOR_PRACTICE,
          autoDismissTimer: 10000,
        })
      },
      (error) => {
        dispatch(toggleAddProviderModal(false))
        actionAlertHandler({
          actionStyle: actionStyles.ERROR,
          message: error,
          dispatch,
          expires: true,
          action: GET_PROVIDERS_FOR_PRACTICE,
          autoDismissTimer: 10000,
        })
      },
    )
  }
}

export const getPracticePaymentHistory = ({ practiceID, page, limit }: any) => ({
  action: { type: GET_PRACTICE_PAYMENT_HISTORY, status: 'all', page },
  request: {
    method: 'get',
    url: '/v1/transfers',
    params: {
      page,
      limit,
      practice_id: practiceID,
    },
  },
})
