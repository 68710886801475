// Reducers
import { combineReducers } from '@reduxjs/toolkit'

import data from './paymentsDataReducer'
import paymentsDashboard from '../dashboard/data/paymentsDashboardReducer'

export default combineReducers({
  paymentsDashboard,
  data,
})
