import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { GestureResponderEvent, Platform, Pressable } from 'react-native'

import { noop, uniqueId } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import { InfoPopoverPlacement, InfoPopoverTriggerAction } from '@lyrahealth-inc/shared-app-logic'

import { BodyText, GenericListItem, InfoPopover, MenuDotsIcon, PressableOpacity, Subhead } from '../../atoms'
import { BodyTextSize, SubheadSize } from '../../styles'
import { ThemeType, tID } from '../../utils'

export interface ItemAction {
  name: string
  text: string
  selectHandler: (e?: React.SyntheticEvent<{}> | GestureResponderEvent, name?: string) => void
}

export interface ExerciseConfigDraftItemProps {
  allowActions: boolean
  clientFirstName: string
  onDeleteExerciseDraft: () => void
  renderExerciseDraft: () => void
  timeSubText: string
  title: string
}

const ExerciseResponseListContainer = styled.View({
  display: 'flex',
  flexDirection: 'column',
})

const ExerciseResponseListTitle = styled(Subhead)<{ theme: ThemeType }>(({ theme }) => ({
  paddingBottom: theme.spacing['8px'],
  lineHeight: '20px',
}))

const MenuContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: spacing['24px'],
  height: spacing['24px'],
}))

const RightActionOption = styled(PressableOpacity)({
  padding: '10px',
  width: '100%',
})

const RightActionOptionText = styled(BodyText)<{ theme: ThemeType }>(({ theme }) => ({
  paddingLeft: theme.spacing['16px'],
}))

const TriggerContainer = styled(Pressable)<{ theme: ThemeType }>(({ theme }) => ({
  borderRadius: Platform.OS === 'web' ? '50%' : 0,
  padding: theme.spacing['12px'],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  right: '-12px',
}))

export const ExerciseConfigDraftItem = ({
  allowActions,
  clientFirstName,
  onDeleteExerciseDraft,
  renderExerciseDraft,
  title,
  timeSubText,
}: ExerciseConfigDraftItemProps) => {
  const { formatMessage } = useIntl()
  const { colors } = useTheme()

  const rightAction = [
    {
      name: 'edit',
      text: 'Edit',
      selectHandler: renderExerciseDraft,
    },
    {
      name: 'delete',
      text: 'Delete',
      selectHandler: onDeleteExerciseDraft,
    },
  ]

  const renderRightAction = () => {
    const actions = []
    actions.push(
      <>
        <MenuContainer testID={tID('ExerciseConfigDraftItem-right-action')}>
          <InfoPopover
            content={
              <>
                {rightAction.map((action: ItemAction) => (
                  <RightActionOption
                    testID={tID(`ExerciseConfigDraftItem-dropdownAction-${action.name}`)}
                    key={action.name}
                    onPress={(e: GestureResponderEvent) => {
                      e.preventDefault()
                      action.selectHandler()
                    }}
                    hoveredBackgroundColor={colors.tertiaryButtonBackgroundHover}
                    pressedBackgroundColor={colors.tertiaryButtonBackgroundPressed}
                  >
                    <RightActionOptionText
                      testID={tID(`menuItemText-${action.name}`)}
                      text={action.text}
                      size={BodyTextSize.DEFAULT}
                      color={colors.textSecondary}
                      textAlign={'left'}
                    />
                  </RightActionOption>
                ))}
              </>
            }
            offset={0}
            placement={InfoPopoverPlacement.BOTTOM_RIGHT}
            shouldDisplayCloseButton={false}
            trigger={
              <TriggerContainer>
                <MenuDotsIcon fillColor={colors.iconDefault} />
              </TriggerContainer>
            }
            popoverTriggerAction={InfoPopoverTriggerAction.PRESS}
            closeIconColor={colors.iconDefault}
            popoverStyle={{
              container: {
                borderRadius: '12px',
                width: '180px',
                paddingLeft: 0,
                paddingRight: 0,
              },
            }}
            allowContentUnderCloseButton
            clickToClose={false}
          />
        </MenuContainer>
      </>,
    )
    return actions
  }

  const renderBadge = () => (
    <FormattedMessage
      description='Badge that shows who the provider started the exercise with'
      defaultMessage='Started with {clientFirstName}'
      values={{
        clientFirstName,
      }}
    />
  )

  return (
    <ExerciseResponseListContainer>
      <ExerciseResponseListTitle
        size={SubheadSize.XSMALL}
        text={formatMessage({
          defaultMessage: 'Saved draft',
          description: 'Title for the saved draft list',
        })}
        color={colors.textPrimary}
      />
      <GenericListItem
        key={uniqueId('genericListItem')}
        testID={tID(`ExerciseConfigDraftItem`)}
        title={title}
        rightItems={allowActions ? renderRightAction() : undefined}
        onClick={allowActions ? renderExerciseDraft : noop}
        badgeContent={renderBadge()}
        badgeBackgroundColor={colors.backgroundHighlightTeal}
        badgeTextColor={colors.textActive}
        subText={timeSubText}
        fullBorder
        isFirstItem
        isLastItem
        height='94px'
        largeText={false}
        boldText
      />
    </ExerciseResponseListContainer>
  )
}
