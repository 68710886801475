export const videoViewStyle = {
  FULLSCREEN: 'fullscreen',
  PREVIEW: 'preview',
}

export const videoScreen = {
  USERPREVIEWCONTAINER: 'user-preview',
  PARTICIPANTPREVIEWCONTAINER: 'participant-preview',
  FULLSCREENVIDEOCONTAINER: 'fullscreen-video-container',
}

export const IO_DEVICES = {
  MICROPHONE: 'Microphone',
  SPEAKER: 'Speaker',
  CAMERA: 'Camera',
  EFFECT: 'Effect',
}

export enum RECORDING_STATUS {
  disabled = 'disabled',
  not_started = 'not_started',
  awaiting_recording_consent = 'awaiting_recording_consent',
}
