import React from 'react'
import './dataTable.module.scss'
import ReactTable, { ComponentPropsGetterRC, SortingRule, DefaultFilterFunction, Column } from 'react-table'
import { isString, includes } from 'lodash-es'
import ChevronIcon from '../../atoms/icons/ChevronIcon'
import DefaultButton from '../../atoms/buttons/defaultButton/DefaultButton'

const DataTable: React.FC<DataTableProps> = ({
  data,
  pageSize,
  pagination = false,
  showColumnFilters = false,
  filterMethod = (filter, row) => {
    const id = filter.pivotId || filter.id
    return row[id] !== undefined ? includes(row[id], filter.value) : true
  },
  columns,
  className,
  rowClickHandler,
  striped = true,
  highlight = true,
  resizable = true,
  defaultSorted,
  sortable = true,
  noDataText,
  destinationSelector,
}) => {
  // Lyra customizations
  const parsedColumns = columns.map((columnObj) => {
    // add sort arrows to TH's
    const headerText = columnObj.Header && isString(columnObj.Header) ? columnObj.Header.slice(0) : columnObj.Header
    const headerComp = () => {
      if (sortable === false || columnObj.sortable === false) {
        return <span style={columnObj.style}>{headerText}</span>
      } else {
        return (
          <DefaultButton unstyled>
            {headerText}
            <i className='sort-arrows'>
              <ChevronIcon className='up' isFilled fillColor='currentColor' direction='up' />
              <ChevronIcon className='down' isFilled fillColor='currentColor' direction='down' />
            </i>
          </DefaultButton>
        )
      }
    }
    columnObj = Object.assign({}, columnObj, { Header: headerComp })

    // add styling wrapper for clickable TD's
    if (columnObj.Cell) {
      return columnObj
    } else {
      const cell = (row: any) => {
        if (row.value) {
          if (destinationSelector && row.original[destinationSelector]) {
            return <span className='clickable-style'>{row.value}</span>
          } else {
            return <span>{row.value}</span>
          }
        } else {
          return <span>&ndash;</span>
        }
      }
      columnObj.Cell = cell
      return columnObj
    }
  })

  return (
    <ReactTable
      // The prop overrides the default "Loading..." string for accessibility reasons. In our version of react-table (6.8.6),
      // the loading indicator is present on the DOM even if it's hidden, causing the screen reader to read it, which is wrong.
      // See CLIENT-1376 for more info.
      // It is safe to to override as we do not use the `loading` prop at all, so the built-in loading spinner will never be shown anyway.
      loadingText=''
      data={data}
      showPagination={pagination}
      showPageSizeOptions={false}
      defaultPageSize={data.length}
      pageSize={pageSize}
      filterable={showColumnFilters}
      minRows={0}
      resizable={resizable}
      defaultSorted={defaultSorted}
      className={`${striped ? '-striped' : ''} ${highlight ? '-highlight' : ''} ${className}`}
      defaultFilterMethod={filterMethod}
      columns={parsedColumns}
      sortable={sortable}
      noDataText={noDataText}
      getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {
        return {
          onClick: (e: React.SyntheticEvent<{}>, handleOriginal: () => any) => {
            if (rowClickHandler && destinationSelector && rowInfo.original[destinationSelector]) {
              rowClickHandler(state, rowInfo, column, instance)
            } else {
              // IMPORTANT! React-Table uses onClick internally to trigger
              // events like expanding SubComponents and pivots.
              // By default a custom 'onClick' handler will override this functionality.
              // If you want to fire the original onClick handler, call the
              // 'handleOriginal' function.
              if (handleOriginal) {
                handleOriginal()
              }
            }
          },
        }
      }}
      // @ts-expect-error TS(2769): No overload matches this call.
      getTheadThProps={(state: TheadThState, rowInfo, column: Column) => {
        const firstHeader = state.sorted[0]
        const columnId = column.id
        // user should provide accessor property for column in order for it to be sorted
        if (!columnId) {
          console.error('Sorting for column failed: accessor property is missing for a column')
        } else if (firstHeader && firstHeader.id === columnId) {
          return {
            'aria-sort': firstHeader.desc ? 'descending' : 'ascending',
          }
        }
        return {}
      }}
    />
  )
}

type TheadThState = {
  sorted: SortingRule
}

export type DataTableProps = {
  data: Dict[]
  pageSize?: number
  pagination: boolean
  showColumnFilters?: boolean
  filterMethod?: DefaultFilterFunction
  columns: Column[]
  className?: string
  rowClickHandler?: ComponentPropsGetterRC
  striped?: boolean
  highlight?: boolean
  resizable?: boolean
  defaultSorted?: SortingRule[]
  sortable?: boolean
  destinationSelector?: string // the property on the data object that determines if the row is clickable
  noDataText?: string
}

/* Prop Notes
========================
- columns must be an object array, with at minimum a 'Header' and 'accessor' property. All other props defined here are supported: https://github.com/react-tools/react-table#columns
- data must be an array of object with key-value pairs that align with the 'accessor' properties set in the columns object
- to give a row a clickable destination, include a 'destination' property for that row within the data object, whose value is the URL to redirect to
*/

export default DataTable
