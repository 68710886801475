import validation from '../../../../common/utils/validation'

const addProviderValidate = ($$values: any) => {
  let errors = {}
  const justRequired = [
    {
      fieldName: 'first_name',
      errorText: 'First name',
    },
    {
      fieldName: 'last_name',
      errorText: 'Last name',
    },
  ]
  errors = Object.assign(
    errors,
    validation.requiredValidStringOfLength(
      $$values,
      {
        fieldName: 'provider_lyra_id',
        errorText: 'Lyra Id',
      },
      36,
    ),
  )
  errors = Object.assign(
    errors,
    validation.optionalValidEmail($$values, {
      fieldName: 'email',
      errorText: 'Email',
    }),
  )
  errors = Object.assign(errors, validation.requiredFields($$values, justRequired))

  return errors
}

export default addProviderValidate
