import React, { Component } from 'react'
import CSSModules from 'react-css-modules'

import numeral from 'numeral'

import { CheckIcon, CopyIcon, ExclamationIcon, MinusIcon } from '@lyrahealth-inc/ui-core'

import styles from './paymentHistoryCard.module.scss'

type Props = {
  data?: any // TODO: PropTypes.instanceOf(Map)
  copyClick: (...args: any[]) => any
  viewClick: (...args: any[]) => any
  isPracticeAdmin?: boolean
}

class PaymentHistoryCard extends Component<Props> {
  _onViewClick = () => {
    const { viewClick, data } = this.props
    viewClick(data)
  }

  _configureStatus = (data: any) => {
    const status = data.status.toLowerCase()
    if (status === 'approved') {
      return (
        <p>
          <b>Status: </b>
          <span styleName='icon-container'>
            <CheckIcon fillColor={styles.x_success} isFilled width='15' />
          </span>
          <span styleName='approved'>{status}</span>
        </p>
      )
    } else if (status === 'rejected') {
      return (
        <p>
          <b>Status: </b>
          <span styleName='icon-container'>
            <ExclamationIcon isFilled width='15' />
          </span>
          <span styleName='rejected'>{status}</span>
        </p>
      )
    } else if (status === 'reversed') {
      return (
        <p>
          <b>Status: </b>
          <span styleName='icon-container'>
            <MinusIcon isFilled width='15' fillColor={styles.x_highlight} />
          </span>
          <span styleName='reversed'>{status}</span>
        </p>
      )
    } else {
      return (
        <p>
          <b>Status: </b>
          {status === 'pending' ? 'submitted' : status}
        </p>
      )
    }
  }

  render() {
    const { data, isPracticeAdmin } = this.props
    const referenceNumber = data.reference_number ? `#${data.reference_number}` : null
    return (
      <div styleName='history-list-row'>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <div
          data-test-id='PaymentHistoryCard-copy'
          styleName='copy'
          onClick={() => {
            this.props.copyClick(data.id)
          }}
        >
          <div styleName='icon-only'>
            <CopyIcon width='15' />
          </div>
        </div>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <div
          styleName='card'
          onClick={() => {
            this._onViewClick()
          }}
        >
          <div styleName='content'>
            {isPracticeAdmin ? (
              <div styleName='left'>
                <div styleName='details'>
                  <h4>
                    {data.first_name} {data.last_name}
                  </h4>
                  <p>
                    {data.visit_date}
                    <span styleName='reference'>{referenceNumber}</span>
                  </p>
                </div>
                <div styleName='details'>
                  <h4>Provider Name</h4>
                  <p>
                    {data.provider_first_name} {data.provider_last_name}
                  </p>
                </div>
              </div>
            ) : (
              <div styleName='left'>
                <h4>
                  <span>
                    {data.first_name} {data.last_name}
                  </span>
                </h4>
                <div styleName='date'>{data.visit_date}</div>
                <div styleName='reference'>{referenceNumber}</div>
              </div>
            )}
            <div styleName='right'>
              {this._configureStatus(data)}
              <div styleName='amount'>
                <h3>{numeral(data.session_rate / 100).format('$0,0[.]00')}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CSSModules(PaymentHistoryCard, styles)
