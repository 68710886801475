import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Field, reduxForm } from 'redux-form/immutable'
import accountTypeValidate from './accountTypeValidate'
import * as registerProviderActions from '../data/registerProviderActions'
import styles from '../registerProvider.module.scss'
import { CardSmall, SelectField, PrimaryButton } from '@lyrahealth-inc/ui-core'

type AccountTypeProps = {
  handleSubmit?: (...args: any[]) => any
  actions?: any
}

class AccountType extends React.Component<AccountTypeProps> {
  _submitForm = (values: any) => {
    this.props.actions.selectAccountType(values.get('account_type'))
  }

  render() {
    const { handleSubmit } = this.props

    return (
      <CardSmall>
        <h2 className={styles.title}>Account Type</h2>

        {/* @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message */}
        <form onSubmit={handleSubmit(this._submitForm)}>
          <div className='row'>
            <div className='col-xs-12'>
              <Field name='account_type' label='' component={SelectField}>
                <option hidden value=''>
                  Select
                </option>
                <option value='individual'>Individual</option>
                <option value='company'>Business</option>
              </Field>
            </div>
          </div>

          <div className='row'>
            <div className='col-xs-12'>
              <PrimaryButton
                id='test-accounttype-submit'
                // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
                onClick={handleSubmit(this._submitForm)}
                // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
                onKeyDown={handleSubmit(this._submitForm)}
              >
                Continue
              </PrimaryButton>
            </div>
          </div>
        </form>
      </CardSmall>
    )
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    actions: bindActionCreators({ ...registerProviderActions }, dispatch),
  }
}

export default connect(
  null,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'RegisterProviderAccountType',
    validate: accountTypeValidate,
    touchOnBlur: false,
    // @ts-expect-error TS(2345): Argument of type 'typeof AccountType' is not assig... Remove this comment to see the full error message
  })(AccountType),
)
