import { isArray, isObject } from 'lodash-es'

import { TypeAheadValue } from '../../../organisms/formBody/types'

interface CanSelectForTest {
  testId?: string
}

export type Item = {
  menuItem_header?: boolean
  menuItem_divider?: boolean
  menuItem_section?: string
  [key: string]: any
}
/**
 * - Supports custom options for the TypeAhead component
 * - By default, the TypeAhead supports options that are strings only
 *
 * todo: this config also supports menus, so we should remove references to "typeahead" in this class
 */
export class TypeAheadCustomOptionsConfig<T> {
  options: T[]

  /** Function that renders a custom element to display in the dropdown results menu */
  menuItemRenderer?: (option: T, displayText: JSX.Element) => JSX.Element

  /** Function that renders a custom element to display in the input bar once an option has been selected */
  inputLabelRenderer?: (option: T) => JSX.Element

  /** Key of a property on T that gives a display string for T. If omitted, the direct values in options will be displayed  */
  labelKey?: string

  /** Key of a property on T that gives a value string for T. If omitted, the direct values in options will be the value  */
  valueKey?: string

  constructor(
    options: T[],
    menuItemRenderer?: (option: T, displayText: JSX.Element) => JSX.Element,
    inputLabelRenderer?: (option: T) => JSX.Element,
    labelKey?: string,
    valueKey?: string,
  ) {
    this.options = options
    this.menuItemRenderer = menuItemRenderer
    this.inputLabelRenderer = inputLabelRenderer
    this.labelKey = labelKey || 'label'
    this.valueKey = valueKey || 'value'
  }

  getDisplayString(item: T) {
    return this.labelKey ? item[this.labelKey] : item
  }

  getValue(value: TypeAheadValue) {
    if (isArray(value) && isObject(value[0]) && this.valueKey) {
      const valueKey = this.valueKey
      return value.map((val) => val[valueKey])
    }
    return value
  }

  // Used to support a section list. Items with these properties will not be rendered as a pressable list item
  getIsPressable<T extends Item>(item: T): boolean {
    return !(item?.menuItem_header || item?.menuItem_divider)
  }

  getOptionTestId<T extends CanSelectForTest>(item: T): string | undefined {
    return item?.testId
  }
}
