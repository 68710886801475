import React, { createRef, useState, useRef, useEffect } from 'react'
import classNames from 'classnames'
import styles from './outcomeChart.module.scss'
import moment from 'moment-timezone'
import { convertUTCToISO8601format, Outcome, QuestionResponse, useWindowSize } from '@lyrahealth-inc/shared-app-logic'
import CustomDot from './CustomDot'
import CustomTick from './CustomTick'
import outcomeUtils from '../../utils/outcomeUtils'
import { outcomeChartConstants } from '../../constants/outcomeConstants'
import { LineChart, ResponsiveContainer, CartesianGrid, Line, XAxis, YAxis, Tooltip } from 'recharts'
import LyraPopover, { LyraPopoverHandle } from '../../atoms/popover/LyraPopover'
import { isEmpty, isInteger, includes, sortBy, isNil } from 'lodash-es'
import TextButton from '../../atoms/buttons/textButton/TextButton'
import { useIntl } from 'react-intl'

export const OutcomeChart: React.FC<OutcomeChartProps> = ({
  answerModalAction,
  assessmentType,
  clientName,
  outcomesData,
  track,
}) => {
  const [toolTipActive, setToolTipActive] = useState(false)
  const [activeX, setActiveX] = useState(0)
  const [activeY, setActiveY] = useState(0)
  const [toolTipContent, setToolTipContent] = useState<Outcome | {}>({})
  const [overTooltip, setOverTooltip] = useState(false)
  const [tooltipIndex, setTooltipIndex] = useState(-1)
  const [scrollPosition, setScrollPosition] = useState(0)
  const windowSize = useWindowSize()
  const chartContainerRef = useRef<HTMLDivElement | null>(null)
  const lineRef = createRef<Line>()
  const popoverRef = useRef<LyraPopoverHandle>(null)
  const intl = useIntl()
  const chartHeight = 440
  const minDataPointWidth = 75

  const assessment = outcomeChartConstants.find((obj) => obj.name === assessmentType)
  const isMobile = window.innerWidth < 768
  const XAxisPadding = isMobile ? { left: 70, right: 45 } : { left: 115, right: 60 }
  const ranges = assessment?.ranges

  useEffect(() => {
    // Set initial scroll position on load
    const chartContainer = chartContainerRef?.current
    if (chartContainer && windowSize) {
      setScrollPosition(chartContainer.scrollWidth - chartContainer.clientWidth)
    }
  }, [windowSize])

  useEffect(() => {
    // For mobile: Close tooltip when touch outside
    const hideTooltip = (event: MouseEvent) => {
      if (
        toolTipActive &&
        chartContainerRef.current &&
        !chartContainerRef.current.contains(event.target as HTMLElement)
      ) {
        setToolTipActive(false)
        setTooltipIndex(-1)
      }
    }
    // @ts-expect-error TS(2769): No overload matches this call.
    document.addEventListener('touchstart', hideTooltip)
    return () => {
      // @ts-expect-error TS(2769): No overload matches this call.
      document.removeEventListener('touchstart', hideTooltip)
    }
  }, [toolTipActive])

  const onChartMouseMove = (chart: any) => {
    popoverRef?.current?.forceClose()
    if (tooltipIndex !== chart.activeTooltipIndex) {
      if (chart.isTooltipActive) {
        setToolTipActive(true)
        const activePoint: any = {
          payload: {},
          ...(lineRef?.current?.props?.points ? lineRef.current.props.points[chart?.activeTooltipIndex] : undefined),
        }
        if (activePoint) {
          setActiveX(activePoint.x ?? 0)
          setActiveY(activePoint.y ?? 0)
          setToolTipContent(activePoint?.payload)
          setTooltipIndex(chart.activeTooltipIndex)
        }
      }
    }
  }

  const onChartMouseLeave = () => {
    setTimeout(() => {
      if (!overTooltip) {
        setToolTipActive(false)
        setTooltipIndex(-1)
      }
    }, 3000) // To prevent tooltip flicker
  }

  const handleAnswersClick = () => {
    track({ event: 'BUTTON_PRESS', action: `OUTCOMES_${assessmentType}_TOOLTIP` })
    if (!('response_date' in toolTipContent)) return
    const answers = toolTipContent.response_details
    const assessment = outcomeChartConstants.find((obj) => obj.name === assessmentType)
    const data = {
      clientName: clientName,
      title: assessment?.fullName,
      dateSubmitted: intl.formatDate(convertUTCToISO8601format(toolTipContent.response_date), {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
      assessmentType: assessmentType,
      score: toolTipContent.scores[assessmentType],
      answers: answers?.filter((a) => includes(a.groups, assessmentType)),
    }
    answerModalAction && answerModalAction(data)
  }

  const onPopoverOpen = () => {
    track({ event: 'BUTTON_PRESS', action: `OUTCOMES_${assessmentType}_INFO` })
    setToolTipActive(false)
    setTooltipIndex(-1)
  }

  const renderCustomTooltip = (
    assessmentType: string,
    condition = '',
    toolTipFormat: Intl.DateTimeFormatOptions | undefined = { year: 'numeric', month: '2-digit', day: '2-digit' },
  ) => {
    if (!('response_date' in toolTipContent)) return <></>
    const toolTipWidth = 140
    const scoreDate = intl.formatDate(convertUTCToISO8601format(toolTipContent.response_date), toolTipFormat)
    let selfHarm = false
    let score = toolTipContent.scores[assessmentType]
    if (assessmentType === 'TFM') {
      score = toolTipContent.scores.actualTFM
    }
    const scoreDescription = outcomeUtils.convertOutcomeScoreToDescription(assessmentType, score).replace(condition, '')
    if (
      assessmentType === 'PHQ' &&
      'PHQ-SELF-HARM' in toolTipContent.scores &&
      toolTipContent.scores['PHQ-SELF-HARM'] &&
      toolTipContent.scores['PHQ-SELF-HARM'] > 0
    ) {
      selfHarm = true
    }
    return (
      <div
        data-test-id='OutcomeChart-customTooltip'
        onMouseOver={() => setOverTooltip(true)}
        onFocus={() => setOverTooltip(true)}
        onMouseOut={() => setOverTooltip(false)}
        onBlur={() => setOverTooltip(false)}
        className={styles['tooltip-container']}
        style={{
          left: activeX - toolTipWidth / 2 - scrollPosition,
          bottom: chartHeight - activeY + 30,
          width: toolTipWidth,
        }}
      >
        <div className={styles['tooltip-inner']}>
          <div className={styles['score-number']}>{score}</div>
          <div className={styles['score-description']}>{scoreDescription}</div>
          <div className={styles['score-date']}>{scoreDate}</div>
          {selfHarm ? <p className={styles['self-harm']}>Thoughts of self harm or suicide</p> : ''}
          {answerModalAction && toolTipContent.response_details && (
            <TextButton
              text='view answers'
              data-test-id='OutcomeChart-viewAnswers'
              onClick={handleAnswersClick}
              isSmall
              isPrimary
            />
          )}
        </div>
      </div>
    )
  }

  const renderCustomYaxisLabels = () => {
    return (
      <div
        className={classNames(styles['custom-yaxis'], {
          [styles.mobile]: isMobile,
        })}
      >
        {ranges?.map(({ upper, lower, description }, index) => {
          const totalRange = ranges[ranges.length - 1].upper
          const isLast = index === ranges?.length - 1
          const labelOfTypeRange = !isNil(lower)
          const lowerValue = labelOfTypeRange ? lower || 0 : assessment?.ticks[index] || 0
          const upperValue = labelOfTypeRange && !isLast ? upper + 1 : upper
          const rangeSize = upperValue - lowerValue
          const rangePercentage = (rangeSize / totalRange) * 100
          const showZeroLabel = !labelOfTypeRange && index === 0
          return (
            <div
              key={description}
              className={classNames(
                styles['yaxis-label'],
                !labelOfTypeRange ? styles.point : '',
                showZeroLabel ? styles.zero : '',
              )}
              style={{ flexBasis: `${rangePercentage}%` }}
            >
              <span>{description.replace(assessment?.condition ?? '', '')}</span>
              {showZeroLabel && <span>0</span>}
            </div>
          )
        })}
      </div>
    )
  }

  const timelineFollowbackMethodData = (data: Outcome[]) => {
    const chronological = data[data.length - 1]?.response_date > data[0]?.response_date
    const dataValues = data.reduce((entries, response) => {
      const pastMonday = moment(response.response_date, 'YYYY-MM-DDThh:mm:ss').subtract(1, 'weeks').startOf('isoWeek')
      const pastSunday = moment(response.response_date, 'YYYY-MM-DDThh:mm:ss').subtract(1, 'weeks').endOf('isoWeek')
      for (let day = pastMonday; day.isSameOrBefore(pastSunday); day.add(1, 'day')) {
        const newDay = day.format('dddd')
        const response_date = day.format('YYYY-MM-DD')
        const tFMScore = response.response_details?.find((response) => response.question === newDay)?.score
        entries[response_date] = {
          scores: {
            TFM: tFMScore && tFMScore > 7 ? 7 : tFMScore,
            actualTFM: tFMScore,
          },
          response_details: [response.response_details?.find((response) => response.question === newDay)],
          response_date,
        }
      }
      return entries
    }, {})
    const dataOrder = Object.keys(dataValues).sort()
    const startDate = dataOrder[0]
    const endDate = dataOrder[dataOrder.length - 1]
    for (
      const date = moment(startDate, 'YYYY-MM-DD');
      date.isBefore(moment(endDate, 'YYYY-MM-DD'));
      date.add(1, 'day')
    ) {
      if (!(date.format('YYYY-MM-DD') in dataValues)) {
        dataValues[date.format('YYYY-MM-DD')] = {
          scores: {
            TFM: null,
            actualTFM: null,
          },
          response_details: null,
          response_date: date.format('YYYY-MM-DDThh:mm:ss'),
        }
      }
    }
    const newResponseData: Outcome[] = sortBy(Object.values(dataValues), ['response_date'])
    if (!chronological) newResponseData.reverse()
    return newResponseData
  }

  const bingeDrinkingDays = (data: Outcome[]) => {
    const chronological = data[data.length - 1]?.response_date > data[0]?.response_date
    const startDate = chronological ? data[0].response_date : data[data.length - 1].response_date
    const endDate = chronological ? data[data.length - 1].response_date : data[0].response_date
    const uniqueMonthsData = sortBy(data, ['response_date']).reduce((cumulative, response) => {
      cumulative[moment(response.response_date).format('YYYY-MM')] = response
      return cumulative
    }, {})
    const currentMonths = Object.keys(uniqueMonthsData)
    const drinkingDaysData: Outcome[] = Object.values(uniqueMonthsData)
    for (
      const date = moment(startDate, 'YYYY-MM-DDThh:mm:ss');
      date.isBefore(moment(endDate, 'YYYY-MM-DDThh:mm:ss'));
      date.add(1, 'month')
    ) {
      if (!currentMonths.includes(date.format('YYYY-MM'))) {
        drinkingDaysData.push({
          scores: {
            BDD: null,
          },
          response_details: null,
          response_date: date.format('YYYY-MM-DDThh:mm:ss'),
        })
      }
    }
    chronological
      ? drinkingDaysData.sort((entryA, entryB) => (entryA.response_date > entryB.response_date ? 1 : -1))
      : drinkingDaysData.sort((entryA, entryB) => (entryA.response_date < entryB.response_date ? 1 : -1))
    return drinkingDaysData
  }

  let data = outcomesData.filter((outcome) => isInteger(outcome.scores[assessmentType]))
  if (assessmentType === 'TFM') data = timelineFollowbackMethodData(data)
  else if (assessmentType === 'BDD' && data.length > 0) data = bingeDrinkingDays(data)
  if (isEmpty(data)) return null
  return (
    <div data-test-id={`OutcomeChart-container-${assessment?.title}`}>
      <p className={styles.title}>
        {assessment?.title}
        <LyraPopover
          onOpen={onPopoverOpen}
          content={<p>{assessment?.description}</p>}
          iconId='assessment-description'
          iconSize={16}
          openOnHover
          ref={popoverRef}
        />
      </p>
      <div data-test-id={`OutcomeChart-chart-${assessmentType}`} className={classNames(styles.container)}>
        {renderCustomYaxisLabels()}
        <div
          className={classNames(styles['chart-container'])}
          ref={chartContainerRef}
          onScroll={({ currentTarget: { scrollLeft, scrollWidth, clientWidth } }) => {
            setScrollPosition(scrollWidth - clientWidth + scrollLeft)
          }}
        >
          <ResponsiveContainer height={chartHeight} minWidth={data.length * minDataPointWidth}>
            <LineChart
              data={data}
              margin={{ top: 5, right: 5, bottom: 25, left: 5 }}
              onClick={() => {}}
              onMouseMove={onChartMouseMove}
              onMouseLeave={onChartMouseLeave}
            >
              <CartesianGrid vertical={false} />
              <Line
                ref={lineRef}
                type='linear'
                isAnimationActive={false}
                dataKey={`scores.${assessmentType}`}
                stroke={assessment?.lineColor}
                strokeWidth={2}
                dot={<CustomDot tooltipIndex={tooltipIndex} fillNoValueEntries={assessment?.fillNoValueEntries} />}
                activeDot={
                  <CustomDot isActive tooltipIndex={tooltipIndex} fillNoValueEntries={assessment?.fillNoValueEntries} />
                }
              />
              <XAxis
                reversed
                dataKey='response_date'
                axisLine={false}
                tickLine={false}
                tick={<CustomTick data={data} />}
                ticks={[data[data.length - 1].response_date, data[0].response_date]}
                tickCount={2}
                padding={XAxisPadding}
              />
              <YAxis
                dataKey={`scores.${assessmentType}`}
                axisLine={false}
                tickLine={false}
                width={0}
                domain={[
                  ranges ? ranges[0]?.lower ?? 0 : 0,
                  ranges ? ranges[ranges.length - 1]?.upper ?? ranges[ranges.length - 1].lower ?? 0 : 0,
                ]}
                tick={{ marginBottom: 50 }}
                ticks={assessment?.ticks}
              />
              <Tooltip wrapperStyle={{ display: 'none' }} />
            </LineChart>
          </ResponsiveContainer>
          {toolTipActive && renderCustomTooltip(assessmentType, assessment?.condition, assessment?.toolTipFormat)}
        </div>
      </div>
    </div>
  )
}

export interface OutcomeChartResponse {
  clientName: string
  title: string | undefined
  dateSubmitted: string
  assessmentType: string
  score: number | string | null | undefined
  answers: QuestionResponse[] | undefined
}

type OutcomeChartProps = {
  outcomesData: Outcome[]
  assessmentType: string
  answerModalAction?: (response: OutcomeChartResponse) => void
  clientName: string
  track: (event: { event: string; action: string }) => void
}
