import React, { FunctionComponent } from 'react'
import { ViewStyle } from 'react-native'
import Svg, { Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { tID } from '../../utils/utils'

export interface CalendarBookingIconProps {
  size?: string
  style?: ViewStyle
  fillColor?: string
}

export const CalendarBookingIcon: FunctionComponent<CalendarBookingIconProps> = ({ size = '22', style, fillColor }) => {
  const { colors } = useTheme()
  const { fill } = colors.icons.calendarBookingIcon

  return (
    <Svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill='none'
      testID={tID('CalendarBookingIcon')}
      style={style}
    >
      <Path
        d='M17.75 2.80005H15.85V1.60005C15.85 1.20005 15.55 0.800049 15.05 0.800049C14.55 0.800049 14.25 1.10005 14.25 1.60005V2.80005H5.85001V1.60005C5.85001 1.20005 5.55002 0.800049 5.05002 0.800049C4.55002 0.800049 4.25 1.10005 4.25 1.60005V2.80005H2.35001C1.15001 2.80005 0.25 3.80005 0.25 5.00005V19C0.25 20.2001 1.15001 21.2 2.35001 21.2H17.65C18.85 21.2 19.75 20.2001 19.75 19V5.00005C19.85 3.80005 18.85 2.80005 17.75 2.80005ZM7.85001 9.30005H12.35V13.8H7.85001V9.30005ZM6.35001 13.8H1.85001V9.30005H6.35001V13.8ZM12.35 15.3V19.8H7.85001V15.3H12.35ZM13.85 13.8V9.30005H18.35V13.8H13.85ZM2.45001 4.30005H4.35001V5.60005C4.35001 6.00005 4.64999 6.40005 5.14999 6.40005C5.64999 6.40005 5.95001 6.10005 5.95001 5.60005V4.40005H14.45V5.60005C14.45 6.00005 14.75 6.40005 15.25 6.40005C15.75 6.40005 16.05 6.10005 16.05 5.60005V4.40005H17.95C18.25 4.40005 18.55 4.70005 18.55 5.20005V8.00005H1.85001V5.20005C1.85001 4.70005 2.05001 4.30005 2.45001 4.30005ZM1.85001 19.1V15.3H6.35001V19.8H2.45001C2.05001 19.8 1.85001 19.5 1.85001 19.1Z'
        fill={fillColor || fill}
      />
    </Svg>
  )
}
