import React, { FunctionComponent } from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { tID } from '../../utils'

export enum ChevronIconDirection {
  DOWN = 'Down',
  LEFT = 'Left',
  UP = 'Up',
  RIGHT = 'Right',
}

export const ChevronIcon: FunctionComponent<ChevronIconProps & SvgProps> = ({
  size = 16,
  fillColor,
  direction = ChevronIconDirection.RIGHT,
  ...rest
}) => {
  const { colors } = useTheme()
  const { fill } = colors.icons.chevronIcon

  function getPath(direction: ChevronIconDirection): string {
    switch (direction) {
      case ChevronIconDirection.LEFT:
        return 'M10.1212 12.1443C9.82826 12.4372 9.35338 12.4372 9.06049 12.1443L5.87851 8.96236C5.58562 8.66947 5.58562 8.1946 5.87851 7.9017L9.06049 4.71972C9.35338 4.42683 9.82826 4.42683 10.1212 4.71972C10.414 5.01262 10.414 5.48749 10.1212 5.78038L7.4695 8.43203L10.1212 11.0837C10.414 11.3766 10.414 11.8515 10.1212 12.1443Z'
      case ChevronIconDirection.RIGHT:
        return 'M5.87836 4.71967C6.17125 4.42678 6.64613 4.42678 6.93902 4.71967L10.121 7.90165C10.4139 8.19454 10.4139 8.66942 10.121 8.96231L6.93902 12.1443C6.64613 12.4372 6.17125 12.4372 5.87836 12.1443C5.58547 11.8514 5.58547 11.3765 5.87836 11.0836L8.53001 8.43198L5.87836 5.78033C5.58547 5.48744 5.58547 5.01256 5.87836 4.71967Z'
      case ChevronIconDirection.UP:
        return 'M4.28754 10.4622C3.99465 10.1693 3.99465 9.69445 4.28754 9.40155L7.46952 6.21957C7.76242 5.92668 8.23729 5.92668 8.53018 6.21957L11.7122 9.40155C12.0051 9.69445 12.0051 10.1693 11.7122 10.4622C11.4193 10.7551 10.9444 10.7551 10.6515 10.4622L7.99985 7.81056L5.3482 10.4622C5.05531 10.7551 4.58043 10.7551 4.28754 10.4622Z'
      case ChevronIconDirection.DOWN:
        return 'M11.712 7.21967C12.0049 7.51256 12.0049 7.98744 11.712 8.28033L8.52999 11.4623C8.2371 11.7552 7.76222 11.7552 7.46933 11.4623L4.28735 8.28033C3.99446 7.98744 3.99446 7.51256 4.28735 7.21967C4.58024 6.92678 5.05512 6.92678 5.34801 7.21967L7.99966 9.87132L10.6513 7.21967C10.9442 6.92678 11.4191 6.92678 11.712 7.21967Z'
    }
  }

  return (
    <Svg width={size} height={size} viewBox='0 0 16 16' testID={tID('ChevronIcon')} {...rest}>
      <Path d={getPath(direction)} fill={fillColor || fill} />
    </Svg>
  )
}

type ChevronIconProps = {
  size?: number
  fillColor?: string
  direction?: ChevronIconDirection
}
