import React, { useState } from 'react'
import AwayMessage from './AwayMessage'
import ConfirmationPrompt from './ConfirmationPrompt'
import SuccessPrompt from './SuccessPrompt'

export const OOOStates = {
  SETTING_MESSAGE: 'setting_message',
  CONFIRMING_SEND: 'confirming_send',
  DISPLAYING_CONFIRMATION: 'displaying_confirmation',
}

export const OutOfOfficeContainer: React.FC<OutOfOfficeContainerProps> = ({ closeModal, setShowTabs }) => {
  const [currentOperation, setCurrentOperation] = useState<string>(OOOStates.SETTING_MESSAGE)
  const [message, setMessage] = useState<string>('')
  const [scheduledDate, setScheduledDate] = useState(null)
  const [scheduledTime, setScheduledTime] = useState(null)

  switch (currentOperation) {
    case OOOStates.CONFIRMING_SEND:
      setShowTabs(false)
      return (
        <ConfirmationPrompt
          isScheduled={!!scheduledDate}
          message={message}
          scheduledDate={scheduledDate}
          scheduledTime={scheduledTime}
          onContinue={(nextOperation: string) => setCurrentOperation(nextOperation)}
        />
      )
    case OOOStates.DISPLAYING_CONFIRMATION:
      setShowTabs(false)
      return (
        <SuccessPrompt
          isScheduled={!!scheduledDate}
          scheduledDate={scheduledDate}
          scheduledTime={scheduledTime}
          closeModal={closeModal}
        />
      )
    case OOOStates.SETTING_MESSAGE:
    default:
      setShowTabs(true)
      return (
        <AwayMessage
          message={message}
          setMessage={setMessage}
          // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string | un... Remove this comment to see the full error message
          scheduledDate={scheduledDate}
          setScheduledDate={setScheduledDate}
          // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string | un... Remove this comment to see the full error message
          scheduledTime={scheduledTime}
          setScheduledTime={setScheduledTime}
          closeModal={closeModal}
          onContinue={(nextOperation: string) => setCurrentOperation(nextOperation)}
        />
      )
  }
}

type OutOfOfficeContainerProps = {
  closeModal: () => void
  setShowTabs: (show: boolean) => void
}

export default OutOfOfficeContainer
