import React, { FunctionComponent } from 'react'
import { Path, Svg } from 'react-native-svg'

/**
 * Cloud icon used for word cloud categories
 */

type ThoughtsBehaviorsIconProps = {
  size?: string | number
}

export const ThoughtsBehaviorsIcon: FunctionComponent<ThoughtsBehaviorsIconProps> = ({ size = 24 }) => (
  <Svg width={size} height={size} viewBox='0 0 24 24' fill='none'>
    <Path
      d='M6.69076 17.2C6.67716 17.0215 6.5324 16.88 6.34974 16.8648C4.98177 16.7527 -0.151995 15.9986 0.00345584 11.5698C0.144333 7.576 2.77534 7.45538 3.80423 7.56175C4.01506 7.5836 4.20452 7.43258 4.21812 7.22553C4.29002 6.10005 4.87587 3 9.15466 3C12.4745 3 13.3586 4.47689 13.5938 5.2633C13.6598 5.48365 13.9124 5.59288 14.1213 5.48745C14.9297 5.07715 16.7193 4.42561 18.4127 5.57103C20.251 6.81523 20.3831 8.89523 20.3656 9.65315C20.3617 9.82031 20.4754 9.96657 20.6396 10.0112C21.6568 10.2838 24.9232 11.4178 23.7486 14.5169C23.4494 15.3071 22.8178 15.9321 22.0211 16.2522C18.7314 17.5733 13.9513 17.0253 13.039 16.9037C12.9399 16.8904 12.8398 16.917 12.7611 16.9768L7.5642 20.9231C7.33006 21.1007 6.98709 20.9526 6.96378 20.6629L6.69076 17.1991V17.2Z'
      fill='#85CCD5'
    />
  </Svg>
)
