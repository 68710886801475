import React, { memo } from 'react'
import { ReactPlayer } from './react-player'
import styled from 'styled-components'

const VideoPlayerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
`

const VideoPlayer = ({ url, reference, ...props }: VideoPlayerProps) => {
  return (
    <VideoPlayerWrapper>
      <ReactPlayer height={0} width={0} url={url} ref={reference} {...props} />
    </VideoPlayerWrapper>
  )
}

export interface VideoPlayerProps {
  url?: string
  reference?: any
  onError?: (e: any) => void
  progressInterval?: number
  onProgress?: ({ played }: { played: number }) => void
  onEnded?: () => void
  onStart?: () => void
  onPlay?: () => void
  playing?: boolean
  onSeek?: (num: number) => void
  onDuration?: (duration: number) => void
  height?: string
  width?: string
  style?: { [key: string]: any }
  controls?: boolean
  config?: any
}

export default memo(VideoPlayer)
