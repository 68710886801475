export const eligibilityFormMetadata = (customers: string[] = []) => ({
  schema: {
    type: 'object',
    properties: {
      firstRow: {
        name: 'firstRow',
        type: 'array',
        inline: true,
        spaceBetweenFields: 16,
        items: [
          {
            name: 'company',
            type: 'string',
            title: 'Company',
            placeholder: 'Acme Inc.',
            multiSelect: false,
            forceMatch: true,
            options: [...customers],
            validation: {
              isRequired: true,
            },
          },
          {
            name: 'dob',
            type: 'string',
            title: 'Date of Birth',
            placeholder: 'MM/DD/YYYY',
            validation: {
              isRequired: true,
              isValidPastDate: true,
            },
          },
        ],
      },
      secondRow: {
        name: 'secondRow',
        type: 'array',
        inline: true,
        spaceBetweenFields: 16,
        items: [
          {
            name: 'firstName',
            type: 'string',
            title: 'First name',
            placeholder: 'Johnny',
            validation: {
              isRequired: true,
            },
          },
          {
            name: 'lastName',
            type: 'string',
            title: 'Last name',
            placeholder: 'Appleseed',
            validation: {
              isRequired: true,
            },
          },
        ],
      },
    },
  },
  uiSchema: {
    'ui:order': ['firstRow', 'secondRow'],
    firstRow: {
      items: [
        {
          'ui:widget': 'typeAhead',
          'ui:options': {
            baseInputStyle: {
              marginBottom: 24,
            },
            inputContainerStyle: {
              borderRadius: 8,
            },
          },
        },
        {
          'ui:widget': 'mask',
          'ui:options': {
            mask: '11/11/1111',
            baseInputStyle: {
              marginBottom: 24,
            },
            style: {
              borderRadius: 8,
            },
          },
        },
      ],
    },
    secondRow: {
      items: [
        {
          'ui:options': {
            baseInputStyle: {
              marginBottom: 24,
            },
            selectFieldStyle: {
              borderRadius: 8,
            },
            inputContainerStyle: {
              borderRadius: 8,
            },
          },
        },
        {
          'ui:options': {
            baseInputStyle: {
              marginBottom: 24,
            },
            selectFieldStyle: {
              borderRadius: 8,
            },
            inputContainerStyle: {
              borderRadius: 8,
            },
          },
        },
      ],
    },
  },
})
