import React, { useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useFlags } from '@lyrahealth-inc/shared-app-logic'
import { AlertBanner, AlertBannerTypes } from '@lyrahealth-inc/ui-core-crossplatform'
import { addAlert, removeAlert } from '../../../data/alertActions'
import { useSelector } from 'react-redux'
import { getAlertsState } from '../../../data/alertsSelectors'
import { getAuthIsLoggedIn } from '../../../data/auth/authSelectors'
import { Alert as AlertType } from '../../../data/alertTypes'

export const AlertsContainer: React.FC<ConnectedProps<typeof connector>> = ({ addAlert, removeAlert }) => {
  const alerts: AlertType[] = useSelector(getAlertsState)
  const isLoggedIn: boolean = useSelector(getAuthIsLoggedIn)
  const { shouldDisplayPPUIPSABanner, PPUIPSABannerContentString } = useFlags()
  // Scrolling to the top is the default behavior.
  // Hence, we scroll to the top if at least one of the alerts does not have explicitly
  // `scrollToTop` set to `false`.
  const scrollToTop = alerts.some((alert: any) => {
    return alert?.scrollToTop !== false
  })

  const bannerID = 'PPUIPSABanner'

  useEffect(() => {
    if (shouldDisplayPPUIPSABanner && PPUIPSABannerContentString) {
      addAlert({
        show: true,
        contents: PPUIPSABannerContentString,
        style: AlertBannerTypes.INFO,
        expires: false,
        id: bannerID,
      })
    } else {
      removeAlert(bannerID)
    }
  }, [addAlert, removeAlert, shouldDisplayPPUIPSABanner, PPUIPSABannerContentString])

  useEffect(() => {
    if (scrollToTop) {
      window.scrollTo(0, 0)
    }
  }, [scrollToTop, alerts.length])

  if (!isLoggedIn || !alerts || !alerts.length) {
    return null
  }

  return (
    <div>
      {alerts.map((alert: any, idx: any) => (
        <AlertBanner
          dismissHandler={() => removeAlert(alert.id)}
          includeX
          key={`alert_${idx}`}
          show={alert.show}
          autoDismissTimer={alert.autoDismissTimer}
          style={alert.style}
          contents={alert.contents}
        />
      ))}
    </div>
  )
}

const connector = connect(null, { addAlert, removeAlert })

export default connector(AlertsContainer)
