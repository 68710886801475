import React from 'react'
import { BootstrapContainer, CardSmall } from '@lyrahealth-inc/ui-core'
import styles from './notFoundRoute.module.scss'
import CSSModules from 'react-css-modules'

export default CSSModules(
  () => (
    <BootstrapContainer>
      <CardSmall>
        <div styleName='notFound-container'>
          <h1>Page not found.</h1>
          <p>The URL you are trying to access doesn't exist.</p>
        </div>
      </CardSmall>
    </BootstrapContainer>
  ),
  styles,
)
