import { createReducer } from '@reduxjs/toolkit'

import { ToastContent } from '@lyrahealth-inc/ui-core-crossplatform'

import { CLEAR_TOAST_CONTENT, SET_TOAST_CONTENT } from '../../common/constants/reduxConstants'

type ToastState = ToastContent | null

const initialState = null

export default createReducer<ToastState>(initialState, (builder) => {
  builder.addCase(SET_TOAST_CONTENT, (_, action: any) => {
    return action.content
  })
  builder.addCase(CLEAR_TOAST_CONTENT, () => {
    return initialState
  })
})
