import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

type LeaveSiteIconProps = {
  fillColor?: string
  size?: string | number
}

export const LeaveSiteIcon: FunctionComponent<LeaveSiteIconProps> = ({ fillColor, size = 20 }) => {
  const { colors } = useTheme()

  return (
    <Svg width={size} height={size} viewBox='0 0 20 20' fill='none'>
      <Path
        d='M16.0001 10.72V16.48C16.0001 16.9892 15.7978 17.4776 15.4377 17.8376C15.0777 18.1977 14.5893 18.4 14.0801 18.4H3.5201C3.01088 18.4 2.52252 18.1977 2.16245 17.8376C1.80238 17.4776 1.6001 16.9892 1.6001 16.48L1.6001 5.92C1.6001 5.41078 1.80238 4.92242 2.16245 4.56235C2.52252 4.20229 3.01088 4 3.5201 4L9.2801 4'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.5}
        stroke={fillColor || colors.borderDefault}
      />
      <Path
        d='M12.4004 1.59998L18.4004 1.59998V7.59998'
        stroke={fillColor || colors.borderDefault}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.5}
      />
      <Path
        d='M8.80029 11.2C13.0961 6.90421 14.1045 5.89574 18.4003 1.59998'
        stroke={fillColor || colors.borderDefault}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.5}
      />
    </Svg>
  )
}
