import React, { MutableRefObject, useEffect, useRef } from 'react'
import styles from './goSearchBar.module.scss'
import { Client } from './GoSearchBar'
import classNames from 'classnames'

const Hit: React.FC<HitProps> = ({ hit, selected = false, onClick }) => {
  const hitRef = useRef() as MutableRefObject<HTMLDivElement>
  useEffect(() => {
    if (selected) {
      hitRef.current.scrollIntoView({ block: 'nearest' })
    }
  }, [selected])
  const classes = classNames(styles.hitContainer, { [styles.selected]: selected })
  return (
    <div
      ref={hitRef}
      data-test-id={selected ? 'Hit-selected' : 'Hit'}
      key={hit.id}
      className={classes}
      onClick={onClick}
    >
      <div>
        {hit.first_name} {hit.last_name}
      </div>
    </div>
  )
}

type HitProps = {
  onClick: () => void
  hit: Client
  selected?: boolean
}

export default Hit
