import React, { Component } from 'react'

import { get, isEmpty, uniqueId } from 'lodash-es'

import { Assignment, AssignmentGroup, Content, Provider } from '@lyrahealth-inc/shared-app-logic'

import AssignmentListItem from './AssignmentListItem'
import styles from './assignmentsList.module.scss'
import AssignmentsNone from './AssignmentsNone'
import PrimaryTextButton from '../../atoms/buttons/primaryTextButton/PrimaryTextButton'

type PrivateAssignmentListProps = AssignmentListProps & typeof AssignmentsList.defaultProps

export class AssignmentsList extends Component<PrivateAssignmentListProps, {}> {
  static defaultProps = {
    sessionData: {},
    providers: [],
  }

  private renderList = (assignments: Assignment[] | undefined) => {
    const { providers, itemClickHandler, userRole, itemActions, sessionData, assignmentTags } = this.props

    return (
      <>
        {assignments &&
          assignments.map((assignment) => (
            <AssignmentListItem
              key={uniqueId('assignmentListItem')}
              assignment={assignment}
              provider={providers.find((provider: Provider) => provider.provider.lyra_id === assignment.provider_id)}
              multiProvider={providers.length > 1}
              itemClickHandler={itemClickHandler}
              userRole={userRole}
              itemActions={itemActions}
              sessionCount={get(sessionData, 'sessionCount')}
              assignmentTags={assignmentTags?.[assignment.id]}
            />
          ))}
      </>
    )
  }

  public render() {
    const { assignments, title, addActivityHandler, noDataText, ...props } = this.props
    let activities, assessments, lessons, exercises, infoSheets
    if (!isEmpty(assignments)) {
      lessons = assignments.filter((a) => {
        const group = a.group || get(a, 'content.group')
        return group === AssignmentGroup.LESSON
      })
      assessments = assignments.filter((a) => {
        const group = a.group || get(a, 'content.group')
        return group === AssignmentGroup.ASSESSMENT
      })
      exercises = assignments.filter((a) => {
        const group = a.group || get(a, 'content.group')
        return group === AssignmentGroup.EXERCISE
      })
      infoSheets = assignments.filter((a) => {
        const group = a.group || get(a, 'content.group')
        return group === AssignmentGroup.INFO_SHEET
      })
      // Order activities: lessons > worksheets > infosheets > assessments
      activities = lessons.concat(exercises, infoSheets, assessments)
    }

    return (
      <div className={styles.container} {...props}>
        <h3 className={styles.title}>{title}</h3>
        <ul className={styles.list}>
          {isEmpty(activities) ? (
            <AssignmentsNone addActivityHandler={addActivityHandler} noDataText={noDataText} />
          ) : (
            <>
              {this.renderList(activities)}
              {addActivityHandler ? (
                <div className={styles['add-activity']}>
                  <PrimaryTextButton
                    align='left'
                    data-test-id='AssignmentList-addActivityTextButton'
                    onClick={addActivityHandler}
                    text='+ Add Activity'
                  />
                </div>
              ) : (
                ''
              )}
            </>
          )}
        </ul>
      </div>
    )
  }
}

export type AssignmentListProps = {
  userRole: string
  addActivityHandler?: () => void
  providers: Provider[]
  sessionData?: any
  itemActions?: Array<ItemAction>
  assignments: Assignment[]
  noDataText?: string
  itemClickHandler?: (val: any) => void
  title?: string
  assignmentTags?: {
    id: string
  }
}

export type ItemAction = {
  name: string
  text: string
  selectHandler: (
    e?: React.SyntheticEvent<{}>,
    name?: string,
    assignment?: Assignment | Content,
    sessionCount?: number,
  ) => void
  shouldShow: (assignment?: Assignment, sessionCount?: number) => void
}

export default AssignmentsList
