import { createReducer } from '@reduxjs/toolkit'

import { CategoryTag, Content, Program } from '@lyrahealth-inc/shared-app-logic'

import { GET_CONTENT_CATEGORIES_TAGS, GET_CONTENTS, GET_PROGRAMS } from '../../common/constants/reduxConstants'

type ContentsState = {
  data: Content[]
  categoryTagsData: CategoryTag[]
  programs: Program[]
}

const initialState = {
  data: [],
  programs: [],
  categoryTagsData: [],
}

export default createReducer<ContentsState>(initialState, (builder) => {
  builder.addCase(GET_CONTENTS, (state, action: any) => {
    const contents = action.data.filter((content: Content) => content.group !== 'assessment')
    state.data = contents
    return state
  })
  builder.addCase(GET_CONTENT_CATEGORIES_TAGS, (state, action: any) => {
    const contentCategoriesTags = action.data
    state.categoryTagsData = contentCategoriesTags
    return state
  })
  builder.addCase(GET_PROGRAMS, (state, action: any) => {
    state.programs = action.data
    return state
  })
})
