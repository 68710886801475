import { useEffect, useState } from 'react'

import AsyncStorage from '@react-native-async-storage/async-storage'

import { DEFAULT_THEME, Theme } from '@lyrahealth-inc/shared-app-logic'

import { IS_WEB, LYRA_THEME_STORAGE_KEY } from '../constants'

// TODO: This hook is a temporary storage solution for the themeType type using local storage.
// Planning to remove once we have a DB persistence solution
export const useThemeSettings = () => {
  const [themeType, setThemeType] = useState<Theme>(DEFAULT_THEME)

  useEffect(() => {
    if (IS_WEB) {
      const storedTheme = localStorage.getItem(LYRA_THEME_STORAGE_KEY) as Theme
      Object.values(Theme).includes(storedTheme) ? setThemeType(storedTheme) : setThemeType(DEFAULT_THEME)
    } else {
      const getLyraThemeAsync = async () => {
        const storedTheme = (await AsyncStorage.getItem(LYRA_THEME_STORAGE_KEY)) as Theme
        Object.values(Theme).includes(storedTheme) ? setThemeType(storedTheme) : setThemeType(DEFAULT_THEME)
      }

      getLyraThemeAsync()
    }
  }, [setThemeType])

  useEffect(() => {
    if (IS_WEB) {
      localStorage.setItem(LYRA_THEME_STORAGE_KEY, themeType)
    } else {
      const setLyraThemeAsync = async () => {
        await AsyncStorage.setItem(LYRA_THEME_STORAGE_KEY, themeType)
      }
      setLyraThemeAsync()
    }
  }, [themeType])

  return { themeType, setThemeType }
}
