import React, { Component, ReactElement } from 'react'
import styles from './avatar.module.scss'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import Avatar from '@mui/material/Avatar'
import { doesImageExist } from '@lyrahealth-inc/shared-app-logic'
import LyraStarIcon from '../icons/LyraStarIcon'

const baseStyle = {
  background: styles.x_light_gray_40,
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: styles.x_white,
  textTransform: 'uppercase',
  color: styles.x_brand,
  fontSize: '32px',
  fontWeight: 300,
}

const smallStyle = {
  fontSize: '24px',
}

const recommendedStyle = {
  borderColor: styles.x_success,
}

const recommendedLarge = {
  borderColor: styles.x_success,
}

const ltStyleProps = {
  background: styles.x_light_gray_100,
  textTransform: 'uppercase',
  color: styles.x_brand,
  fontSize: '14px',
  fontWeight: 600,
  letterSpacing: '1px',
}

export class BaseAvatar extends Component<BaseAvatarProps, {}> {
  state = { imageIsServed: false }

  private configureProps = () => {
    const { ltStyle, size, recommended, lyraApproved, backgroundColor, textColor, borderless, square, alt, ...rest } =
      this.props

    let initialProps = Object.assign({}, baseStyle) as React.CSSProperties

    if (ltStyle) {
      initialProps = ltStyleProps as React.CSSProperties
    } else {
      if (size <= 80) {
        initialProps = Object.assign({}, initialProps, smallStyle)
        if (recommended || lyraApproved) {
          initialProps = Object.assign({}, initialProps, recommendedStyle)
        }
      } else {
        if (recommended) {
          initialProps = Object.assign({}, initialProps, recommendedStyle)
        }
        if (lyraApproved) {
          initialProps = Object.assign({}, initialProps, recommendedLarge)
        }
      }

      if (backgroundColor) {
        initialProps.background = backgroundColor
      }

      if (textColor) {
        initialProps.color = textColor
      }

      if (borderless) {
        delete initialProps.borderWidth
        delete initialProps.borderStyle
        delete initialProps.borderColor
        delete initialProps.boxShadow
      }

      if (square) {
        initialProps.borderRadius = '5px'
      }
    }
    if (size) {
      initialProps.width = `${size}px`
      initialProps.height = `${size}px`
    }

    const avatarProps = Object.assign({ sx: initialProps, alt }, rest)

    return avatarProps
  }

  private configureAvatar = () => {
    const avatarProps = this.configureProps()

    if (this.state.imageIsServed) {
      delete avatarProps.children
    } else {
      delete avatarProps.src
      delete avatarProps.icon
    }

    return <Avatar {...avatarProps} className={`${this.props.className ? this.props.className : ''}`} />
  }

  private renderLyraStar = () => {
    const containerSize = this.props.size / 3.3
    const starClass = this.props.size > 80 ? styles['lyra-star'] : styles['lyra-star-small']
    return (
      <div style={{ height: containerSize, width: containerSize }} className={starClass}>
        <LyraStarIcon width='62%' fillColor={styles.x_white} />
      </div>
    )
  }

  private getImage = (src?: string) => {
    if (!src) {
      this.setState({
        imageIsServed: false,
      })
      return
    }

    doesImageExist(src)
      .then(() => {
        this.setState({
          imageIsServed: true,
        })
      })
      .catch(() => {
        this.setState({
          imageIsServed: false,
        })
      })
  }

  public componentDidMount() {
    if ('src' in this.props) {
      this.getImage(this.props.src)
    }
  }

  public componentDidUpdate(prevProps: BaseAvatarProps) {
    if ('src' in this.props && this.props.src !== prevProps.src) {
      this.getImage(this.props.src)
    }
  }

  public render() {
    const avatar = this.configureAvatar()
    return (
      <MuiThemeProvider>
        <div className={styles.container}>
          {this.props.lyraApproved ? this.renderLyraStar() : ''}
          {avatar}
        </div>
      </MuiThemeProvider>
    )
  }
}

type BaseAvatarProps = {
  src?: string
  size: number
  className?: string
  backgroundColor?: string
  textColor?: string
  lyraApproved?: boolean
  borderless?: boolean
  square?: boolean
  ltStyle?: boolean
  recommended?: boolean
  icon?: ReactElement
  alt?: string
}

export default BaseAvatar
