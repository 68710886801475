import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

export const FamilyTherapySecondaryRec: FunctionComponent<FamilyTherapySecondaryRecProps> = ({
  width = 80,
  height = 80,
}) => {
  return (
    <Svg width={width} height={height} viewBox='0 0 81 80' fill='none'>
      <Path
        d='M40.7265 0.300781C35.2045 0.300781 29.8468 1.24773 24.8022 3.11476C12.7922 7.56654 4.34872 17.436 1.63592 30.1922C-0.156811 38.5092 0.0724182 46.2018 2.34099 53.6984C5.23718 63.2723 12.3464 71.3458 21.846 75.8482C26.5618 78.0883 31.9795 79.3673 37.9457 79.6487C38.7125 79.6834 39.4918 79.7024 40.2665 79.7024C45.0534 79.7024 49.8688 79.0464 54.5798 77.7532C54.9418 77.6631 55.2865 77.5698 55.6501 77.4623C59.8837 76.2229 63.6936 74.2626 66.9534 71.6431C70.493 68.8148 73.4066 65.2294 75.604 60.9926C76.0372 60.1579 76.4514 59.2789 76.9083 58.2276C79.4219 52.391 80.636 45.8572 80.421 39.325C80.3198 36.4367 79.8898 33.3666 79.1468 30.1922C77.9232 25.0417 75.9281 20.1978 73.3766 16.176C72.6778 15.0963 72.036 14.1889 71.4005 13.3731C66.8143 7.45746 59.6687 3.30605 50.743 1.3742C47.4642 0.662804 44.0938 0.302362 40.7281 0.302362L40.7265 0.300781Z'
        fill='white'
      />
      <Path
        d='M76.989 39.4318C76.891 36.6637 76.4673 33.7959 75.8002 30.9456C74.7094 26.3531 72.9167 21.8255 70.4584 17.9491C69.8877 17.0702 69.3027 16.2228 68.6672 15.4087C64.0574 9.46452 57.0714 6.1573 49.9211 4.60961C41.9566 2.88328 33.4878 3.38759 25.8315 6.22212C14.7558 10.3261 7.215 19.3166 4.75514 30.8808C3.80977 35.2614 3.53311 40.2128 3.95679 44.9697H3.92359C4.16863 47.673 4.65712 50.3115 5.38907 52.7381C8.10978 61.7286 14.721 68.8632 23.1424 72.8533C27.7033 75.0192 32.8001 76.0942 37.9806 76.3392C43.2734 76.5842 48.6485 75.9155 53.6172 74.5481C53.9429 74.4674 54.2527 74.3852 54.5784 74.2872C58.4705 73.1474 61.8584 71.3879 64.7577 69.0592C67.9179 66.5345 70.5232 63.3585 72.5436 59.4664C72.9831 58.619 73.3735 57.7733 73.7482 56.9085C76.1259 51.3865 77.1851 45.3933 76.9732 39.4492L76.989 39.4334V39.4318Z'
        fill='#FFE9E3'
      />
      <Path
        d='M60.256 37.7382C60.2465 36.366 59.6442 35.0649 58.6056 34.1685L55.4296 31.4289V24.4097C55.4296 23.9228 55.0343 23.5292 54.5474 23.5292H52.1429C51.656 23.5292 51.2608 23.9244 51.2608 24.4097V27.8339L42.8315 20.5634C41.5146 19.4268 39.5638 19.4268 38.2469 20.5634L22.4444 34.1954C21.4231 35.076 20.8239 36.3486 20.7939 37.6971L20.5378 52.6997C20.503 54.2774 21.7741 55.5753 23.3518 55.5753H57.5274C59.0909 55.5753 60.354 54.3027 60.343 52.7392L60.256 37.7382Z'
        fill='#6ECBD3'
      />
      <Path
        d='M25.7086 57.8311L27.2342 57.8896L27.2927 60.0017H28.4657L28.6412 57.7726L31.1042 57.7141L31.2212 60.0017L32.3357 60.1187L32.5112 57.9481H33.3901C33.3901 57.9481 33.7237 50.4136 33.2732 49.209C32.5697 47.3324 31.8077 47.0384 31.8077 47.0384C31.8077 47.0384 34.4588 46.229 34.6232 43.9304C34.7987 41.4674 33.8028 39.4723 30.6931 39.2383C30.6931 39.2383 27.0018 39.1925 26.6461 43.1099C26.4121 45.6899 28.5811 46.8629 28.5811 46.8629C28.5811 46.8629 27.642 47.2155 26.8216 48.8564C26.0011 50.499 25.707 57.8295 25.707 57.8295L25.7086 57.8311Z'
        fill='#127719'
      />
      <Path
        d='M54.6394 58.0723L53.174 57.9553L52.9985 60.3014L52.0009 60.1844L51.8255 57.9553L49.3039 57.8383L48.7174 60.0089L47.7784 59.8919L47.8954 57.5459H46.5469C46.5469 57.5459 46.7808 51.6808 47.8369 48.9822C48.4329 47.4614 49.5964 47.1057 49.5964 47.1057C49.5964 47.1057 47.5444 46.701 47.5444 43.8206C47.5444 40.7126 49.941 39.832 51.7085 39.832C53.234 39.832 55.2813 41.6453 55.169 43.5851C54.9936 46.6346 52.9985 47.2211 52.9985 47.2211C52.9985 47.2211 53.6435 47.6321 54.2885 48.7467C54.9335 49.8612 54.992 55.9017 54.641 58.0723H54.6394Z'
        fill='#127719'
      />
      <Path
        d='M43.394 58.3477L42.319 58.2623L42.1894 59.9807L41.459 59.8953L41.3294 58.2623L39.4813 58.1769L39.0513 59.7673L38.3636 59.6819L38.449 57.9635H37.4609C37.4609 57.9635 37.6333 53.6651 38.4063 51.689C38.8426 50.5744 39.6963 50.3136 39.6963 50.3136C39.6963 50.3136 38.1913 50.0164 38.1913 47.9075C38.1913 45.6294 39.9477 44.9923 41.2424 44.9844C42.6858 44.9765 43.8604 46.3139 43.7782 47.7352C43.6485 49.9705 42.1878 50.4005 42.1878 50.4005C42.1878 50.4005 42.6605 50.7009 43.1332 51.5182C43.6058 52.3355 43.6485 56.762 43.3908 58.3524L43.394 58.3477Z'
        fill='#127719'
      />
      <Path
        d='M36.2623 48.4883L35.4434 49.1317C35.4434 49.1317 37.9728 52.8752 40.4326 53.0317C44.1114 53.2657 46.7514 49.4637 46.7514 49.4637L46.0227 48.7618C46.0227 48.7618 42.2712 51.3544 40.8326 51.3165C39.3766 51.277 36.2623 48.4899 36.2623 48.4899V48.4883Z'
        fill='#127719'
      />
    </Svg>
  )
}

type FamilyTherapySecondaryRecProps = {
  width?: number
  height?: number
}
