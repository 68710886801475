import { submitRegisterPracticeForm } from '../../../common/http/data/practices'
import { TOGGLE_REGISTER_PRACTICE_MODAL } from '../../../common/constants/reduxConstants'

export const submitRegisterPractice = (data: any) => {
  const request = submitRegisterPracticeForm(data)

  return (_: any) => {
    return new Promise(function (resolve, reject) {
      request
        .then(() => {
          resolve(true)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

export const toggleRegisterPracticeModal = (show: any) => {
  return {
    type: TOGGLE_REGISTER_PRACTICE_MODAL,
    payload: show,
  }
}
