import React, { FunctionComponent } from 'react'

import styled, { useTheme } from 'styled-components/native'

import { tID } from '../../utils'
import { Image } from '../image/Image'

const ImageContainer = styled(Image)<{ isMobileSized: boolean }>(({ isMobileSized }) => ({
  width: isMobileSized ? '100%' : '369px',
  height: isMobileSized ? '234px' : '207px',
}))

export const RegistrationEnhancementIllustration: FunctionComponent = () => {
  const {
    breakpoints: { isMobileSized },
  } = useTheme()
  return (
    <ImageContainer
      isMobileSized={isMobileSized}
      source={isMobileSized ? require('../../assets/RegistrationMobile.png') : require('../../assets/Registration.png')}
      testID={tID('RegistrationIllustration')}
    />
  )
}
