import React, { FunctionComponent } from 'react'

import clinicalScreenerIllustrationUrl from '../../assets/clinicalScreenerIllustration.png'
import { Image } from '../image/Image'

interface ClinicalScreenerIllustrationProps {
  width?: number
  height?: number
}

export const ClinicalScreenerIllustration: FunctionComponent<ClinicalScreenerIllustrationProps> = ({
  width = 182,
  height = 125,
  ...props
}) => {
  return (
    <Image
      source={clinicalScreenerIllustrationUrl}
      style={{ width, height }}
      contentFit='contain'
      accessibilityIgnoresInvertColors
      {...props}
    />
  )
}
