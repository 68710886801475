import React from 'react'
import { Field } from 'react-final-form'
import { View, ViewStyle } from 'react-native'

import styled from 'styled-components/native'

import { UICoreArrayFieldTemplateProps, UICoreFieldTemplateProps } from './types'
import { BaseInput } from '../../atoms/formElements/BaseInput'
import { ContentField } from '../../atoms/formElements/ContentField'
import { IS_WEB } from '../../constants'
import { useAccessibilityFocus } from '../../hooks/useAccessibilityFocus'
import { Flex1View } from '../../templates/content/CommonViews'

const FlexField = styled(Flex1View)<{ zIndexNumber?: number }>(({ zIndexNumber }) => ({
  ...(IS_WEB && { zIndex: 'auto' }),
  ...(zIndexNumber && { zIndex: zIndexNumber }),
}))

const ArrayItemsContainer = styled(BaseInput)<{ inline?: boolean }>(({ inline, theme }) => ({
  ...(inline && { flexDirection: theme.breakpoints.isMinWidthTablet ? 'row' : 'column', marginBottom: 0 }),
}))

const ArrayItem = styled.View<{ index: number; inline?: boolean; spaceBetweenFields?: number; flex?: number[] }>(
  ({ index, inline, spaceBetweenFields, theme, flex }) => ({
    ...(inline &&
      theme.breakpoints.isMinWidthTablet && {
        flex: flex?.[index] ?? 1,
        marginLeft: index > 0 ? spaceBetweenFields : 0,
      }),
  }),
)

/**
 * Custom Field templates for RJSF. These are needed to replace the
 * default html rendering with native views
 */
export const customFieldTemplate: React.FC<UICoreFieldTemplateProps> = (props) => {
  const { schema, uiSchema, children, id, formContext } = props
  // use slice to transform root_field_key to field_key
  const name = id.slice(5) || ''
  if (schema.type === undefined) {
    return (
      <Field name={name}>
        {({ meta }) => <ContentField schema={schema} uiSchema={uiSchema} formContext={formContext} meta={meta} />}
      </Field>
    )
  }
  const uiOptions = uiSchema['ui:options']
  return <FlexField zIndexNumber={uiOptions?.zIndex as number}>{children}</FlexField>
}

export const customArrayFieldTemplate: React.FC<UICoreArrayFieldTemplateProps> = (props) => {
  const { schema, idSchema, items, uiSchema } = props
  const uiOptions = uiSchema['ui:options']

  // use slice to transform root_field_key to field_key
  const name = idSchema?.$id?.slice(5) || ''

  return (
    <Field name={name}>
      {({ meta }) => {
        const [focusRef] = useAccessibilityFocus({ active: meta.active, delay: 200 })
        return (
          <View style={uiOptions}>
            <ArrayItemsContainer
              labelRef={focusRef}
              name={name}
              label={schema.title}
              largeLabel={schema?.largeLabel ?? true}
              inline={schema.inline}
              style={schema.style as ViewStyle}
            >
              {items.map(({ children, key, index }) => (
                <ArrayItem
                  key={key}
                  index={index}
                  inline={schema.inline}
                  spaceBetweenFields={schema.spaceBetweenFields}
                  flex={schema.flex}
                >
                  {children}
                </ArrayItem>
              ))}
            </ArrayItemsContainer>
          </View>
        )
      }}
    </Field>
  )
}
