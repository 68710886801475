import React, { Component } from 'react'
import Dotdotdot from 'react-dotdotdot'

import classNames from 'classnames'
import { get, isEmpty } from 'lodash-es'
import moment from 'moment'

import { AssignmentResponse, AssignmentStatuses, Contentmetadata } from '@lyrahealth-inc/shared-app-logic'

import styles from './reportList.module.scss'

type Props = {
  data: AssignmentResponse[]
  rowClickHandler: (response: AssignmentResponse) => void
  UIMetadata: Object
  metaData?: Contentmetadata
  userRole: 'provider' | 'client'
  showProvider?: boolean
  providerFullName: string
}

type State = {
  filterReadResponses: boolean
}

class ReportList extends Component<Props, State> {
  fieldsToDisplay: string[]

  constructor(props: Props) {
    super(props)
    this.fieldsToDisplay = [
      ...new Set([
        ...(get(props.UIMetadata, 'report.fieldsToDisplay') || []),
        ...(get(props.metaData, 'report.fieldsToDisplay') || []),
      ]),
    ]
    this.state = { filterReadResponses: false }
  }

  renderListItem(response: AssignmentResponse, index: number) {
    const { userRole, rowClickHandler, showProvider, providerFullName } = this.props
    const unread = !get(response, 'viewed') && userRole !== 'client'
    const date = get(response, 'submit_date') || get(response, 'update_date')
    return (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events
      <li
        onClick={() => rowClickHandler(response)}
        key={response.id}
        className={classNames(styles['list-item'], { [styles.unread]: unread })}
        data-test-id={`ReportList-${index}`}
      >
        <div>
          <div className={styles['date-container']}>
            <div data-test-id='ReportList-date-provider' className={styles.date}>
              {moment(date).format('MMMM D, YYYY')}
              {showProvider && ` • ${providerFullName}`}
            </div>
            {unread ? <div className={styles.badge} /> : null}
          </div>
          <div className={styles.content}>
            <Dotdotdot clamp={1}>
              {this.fieldsToDisplay.map((field: string, index: number) => (
                <span key={field}>
                  {index !== 0 && ' - '}
                  {get(response.response, field)}
                </span>
              ))}
            </Dotdotdot>
          </div>
        </div>
        {unread ? <div className={styles['text-action']}>Review</div> : null}
      </li>
    )
  }

  renderResponses(data: AssignmentResponse[]) {
    const { filterReadResponses } = this.state
    const unreadResponses = data.filter((response: AssignmentResponse) => !get(response, 'viewed'))
    const listData = filterReadResponses ? unreadResponses : data
    const sortedListData = listData.sort((a: AssignmentResponse, b: AssignmentResponse) => {
      if (!b.submit_date) return 1
      if (!a.submit_date) return -1
      return b.submit_date > a.submit_date ? 1 : b.submit_date < a.submit_date ? -1 : 0
    })
    if (isEmpty(data)) return <></>
    return (
      <div>
        <div className={styles['top-container']}>
          {this.renderFilter(unreadResponses)}
          <div className={styles['num-entries']}>{data.length} entries</div>
        </div>
        <ul className={styles['list-container']}>
          {isEmpty(sortedListData) ? (
            <div className={styles['no-data']}>You&apos;re all caught up! No unread entries.</div>
          ) : null}
          {sortedListData.map((activityResponse: AssignmentResponse, index: number) =>
            this.renderListItem(activityResponse, index),
          )}
        </ul>
      </div>
    )
  }

  renderDrafts(listData: AssignmentResponse[]) {
    if (isEmpty(listData)) return <></>
    return (
      <div>
        <div className={styles['top-container']}>
          <div className={styles['filter-item']}>Drafts</div>
          <div className={styles['num-entries']}>{listData.length} entries</div>
        </div>
        <ul className={styles['list-container']}>
          {listData.map((activityResponse: AssignmentResponse, index: number) =>
            this.renderListItem(activityResponse, index),
          )}
        </ul>
      </div>
    )
  }

  renderFilter(unreadResponses: AssignmentResponse[]) {
    const { userRole } = this.props
    const { filterReadResponses } = this.state
    if (userRole === 'client') return <div className={styles['filter-item']}>Submitted</div>
    return (
      <div className={styles['filter-container']}>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
        <div
          onClick={() => this.setState({ filterReadResponses: false })}
          className={classNames(styles['filter-item'], { [styles.active]: !filterReadResponses })}
        >
          All
        </div>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
        <div
          onClick={() => this.setState({ filterReadResponses: true })}
          className={classNames(styles['filter-item'], { [styles.active]: filterReadResponses })}
        >
          Unread ({unreadResponses.length})
        </div>
      </div>
    )
  }

  render() {
    const { data, userRole } = this.props
    const { filterReadResponses } = this.state
    const drafts = data.filter((response) => response.status === AssignmentStatuses.draft)
    const completedSubmissions = data.filter((response) => response.status === AssignmentStatuses.completed)
    const isClient = userRole === 'client'
    if ((!isClient && isEmpty(completedSubmissions)) || (isClient && isEmpty(data)))
      return (
        <div className={styles['no-data']}>
          Submitted entries will show up here{' '}
          <img
            src={require('../../../../ui-core-crossplatform/src/assets/Activity_Empty_Draft_State.png')}
            alt='Empty entry'
          />
        </div>
      )
    return (
      <div>
        {isClient && this.renderDrafts(drafts)}
        {this.renderResponses(completedSubmissions)}
        {filterReadResponses && (
          <>
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
            <div onClick={() => this.setState({ filterReadResponses: false })} className={styles['filter-help']}>
              Not finding what you’re looking for? <span>View all entries</span>
            </div>
          </>
        )}
      </div>
    )
  }
}

export default ReportList
