import React, { FunctionComponent } from 'react'

import { useTheme } from 'styled-components/native'

import { Appointment } from '@lyrahealth-inc/shared-app-logic'

import { ZoomVideoSessionDesktop } from './ZoomVideoSessionDesktop'
import { ZoomVideoSessionMobile } from './ZoomVideoSessionMobile'
import { AvatarDetails } from '../../atoms'
import { AddToastFunction, JoinSessionParams } from '../../hooks/useZoom/types'
import { ThemeType } from '../../utils/themes/ThemeProvider'
import { FullScreenOverlay } from '../fullScreenOverlay/FullScreenOverlay'

export type ZoomVideoSessionProps = {
  getSessionParams: () => Promise<JoinSessionParams>
  appointment: Appointment
  participantName: string
  participantFirstName?: string
  avatarDetails?: AvatarDetails
  participantTitle?: string
  onSessionOpened?: () => void
  onSessionStarted?: () => void
  onSessionEnded?: () => void
  onSessionClosed?: () => void
  showRecordingStatus?: boolean
  isRecording?: boolean
  messenger?: React.ReactElement
  onOpenMessagesPressed?: () => void
  unreadMessageCount?: number
  addToast?: AddToastFunction
  onDisableRecordingPressed?: () => void
  disableMobileView?: boolean
}

export const ZoomVideoSession: FunctionComponent<ZoomVideoSessionProps> = (props) => {
  const theme = useTheme() as ThemeType
  if (!props.disableMobileView && theme.breakpoints.isMobileSized) {
    return (
      <FullScreenOverlay isOpen isFocused={false}>
        <ZoomVideoSessionMobile {...props} />
      </FullScreenOverlay>
    )
  }
  return <ZoomVideoSessionDesktop {...props} />
}
