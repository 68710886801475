import React, { FunctionComponent } from 'react'
import { Path, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

/**
 * Close Circle Icon used on inputs to clear text
 */
export const CloseCircleBordered: FunctionComponent<CloseCircleBorderedProps> = ({ size = 24 }) => {
  const { colors } = useTheme()
  const { fill } = colors.icons.closeCircleBorderedIcon

  return (
    <Svg width={size} height={size} fill='none'>
      <Path fill={colors.backgroundPrimary} stroke={fill} d='M12 20.4A9.2 9.2 0 1 0 12 2a9.2 9.2 0 0 0 0 18.4Z' />
      <Path
        stroke={colors.iconDefault}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.5}
        d='M14.4 8.8 12 11.2m0 0-2.4 2.4m2.4-2.4L9.6 8.8m2.4 2.4 2.4 2.4'
      />
    </Svg>
  )
}

type CloseCircleBorderedProps = {
  size?: string | number
}
