import React, { FunctionComponent } from 'react'

import styled from 'styled-components/native'

import {
  ASSETS_CDN_URL,
  TREATMENT_REC_PRIMARY_CARD_CDN_PREFIX as namespace,
  PRIMARY_TREATMENT_REC_IMAGE_CONTAINER_DIMENSIONS,
} from '../../constants'
import { ThemeType, tID } from '../../utils'
import { Image } from '../image/Image'

const ImageSizer = styled(Image)<{ height: string; width: string }>(({ height, width }) => ({
  width,
  height,
}))

const PADDING = {
  DESKTOP: {
    paddingLeft: '15px',
    paddingTop: '44px',
  },
  TABLET: {
    paddingLeft: '14px',
    paddingTop: '22px',
  },
  MOBILE: {
    paddingLeft: '47px',
    paddingTop: '15px',
  },
}

const Container = styled.View<{ viewport: 'DESKTOP' | 'TABLET' | 'MOBILE'; theme: ThemeType }>(
  ({ viewport, theme: { colors } }) => ({
    ...PRIMARY_TREATMENT_REC_IMAGE_CONTAINER_DIMENSIONS[viewport],
    ...PADDING[viewport],
    overflow: 'hidden',
    backgroundColor: viewport === 'MOBILE' ? colors.backgroundSecondary : 'none',
  }),
)

const IMAGE_SIZES = {
  DESKTOP: {
    height: '303px',
    width: '485px',
  },
  TABLET: {
    height: '281px',
    width: '450px',
  },
  MOBILE: {
    height: '201px',
    width: '322px',
  },
}

/**
 * Illustration to show on the treatment card for Therapy
 */
export const TherapyRec: FunctionComponent<TherapyRecProps> = ({ viewport = 'DESKTOP' }) => {
  return (
    <Container viewport={viewport}>
      <ImageSizer
        {...IMAGE_SIZES[viewport]}
        testID={`${tID('TherapyRecIllustration')}`}
        source={{ uri: ASSETS_CDN_URL + namespace + 'therapy.png' }}
        contentPosition={{
          top: 0,
          left: 0,
        }}
      />
    </Container>
  )
}

export type TherapyRecProps = {
  viewport?: 'DESKTOP' | 'TABLET' | 'MOBILE'
}
