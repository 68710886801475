import React, { useState } from 'react'
import { connect, useSelector } from 'react-redux'

import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import styled, { useTheme } from 'styled-components/native'

import { ClientListClientObject } from '@lyrahealth-inc/shared-app-logic'
import { DefaultButton, ExternalLinkIcon, Label, Tooltip } from '@lyrahealth-inc/ui-core'
import { HeaderText, tID, toJS } from '@lyrahealth-inc/ui-core-crossplatform'
import { Size } from '@lyrahealth-inc/ui-core-crossplatform/src/atoms/subhead/Subhead'

import AvailabilityCard from '../../../common/components/availabilityCard/AvailabilityCard'
import { ROLES } from '../../../common/constants/appConstants'
import { hasRole } from '../../../common/utils/utils'
import { getAuthRoles, getAuthUserId } from '../../../data/auth/authSelectors'
import { getActiveV2Clients } from '../../../data/lyraTherapy/clientSelectors'
import { getDSPrescriptionsNotificationsUrl } from '../../medications/data/medicationsAutoActions'

type ClientListHeaderProps = {
  actions?: {
    getDSPrescriptionsNotificationsUrl: ({ providerId }: { providerId: string }) => Promise<{ sso_url: string }>
  }
}

const HeaderContainer = styled.View(({ theme }) => ({
  alignItems: 'center',
  background: theme.colors.backgroundPrimary,
  borderBottomWidth: '1px',
  borderBottomColor: '#babec3',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: theme.spacing['16px'],
}))

const LeftContainer = styled.View({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
})

const AvailabilityCardContainer = styled.View({
  display: 'flex',
})

const ButtonContainer = styled.View(({ theme }) => ({
  marginLeft: theme.spacing['16px'],
}))

const ClientListHeader: React.FC<ClientListHeaderProps> = ({
  // @ts-expect-error TS(2339): Property 'getDSPrescriptionsNotificationsUrl' does... Remove this comment to see the full error message
  actions: { getDSPrescriptionsNotificationsUrl },
}) => {
  const { colors } = useTheme()
  const userId: string | undefined = useSelector(getAuthUserId)
  const userRoles: string[] | undefined = useSelector(getAuthRoles)
  const clientsActive: ClientListClientObject[] = useSelector(getActiveV2Clients)
  const [fetchingDSUrl, setFetchingDSUrl] = useState(false)

  return (
    <HeaderContainer testID={tID('ClientListHeader-container')}>
      <LeftContainer>
        <HeaderText text='Clients' size={Size.LARGE} />
        {clientsActive?.length > 0 && (
          <Tooltip content='Active clients' triggerStyle={{ marginLeft: '16px' }}>
            <Label
              text={clientsActive.length}
              textColor={colors.backgroundPrimary}
              backgroundColor={colors.iconActive}
              padding='2px 8px'
              borderRadius='10px'
            />
          </Tooltip>
        )}
        {hasRole(userRoles, [ROLES.LT_PRESCRIBER, ROLES.LT_PRESCRIBER_SUPERVISOR]) && (
          <ButtonContainer>
            <DefaultButton
              isOutlined
              data-test-id='ClientListHeader-dosespot-link'
              isLoading={fetchingDSUrl}
              onClick={async () => {
                try {
                  setFetchingDSUrl(true)
                  const doseSpotLink = await getDSPrescriptionsNotificationsUrl({ providerId: userId })
                  window.open(doseSpotLink?.sso_url, '_blank')
                } finally {
                  setFetchingDSUrl(false)
                }
              }}
            >
              Prescriptions Notifications
              <ExternalLinkIcon />
            </DefaultButton>
          </ButtonContainer>
        )}
      </LeftContainer>

      <AvailabilityCardContainer>
        <AvailabilityCard isMinified />
      </AvailabilityCardContainer>
    </HeaderContainer>
  )
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): any => {
  return {
    actions: bindActionCreators(
      {
        getDSPrescriptionsNotificationsUrl,
      },
      dispatch,
    ),
  }
}

export default connect(null, mapDispatchToProps)(toJS(ClientListHeader))
