import React, { FunctionComponent } from 'react'

import { Image } from '../image/Image'

type IntegratedBenefitsMinimizedIllustrationProps = {
  height?: number
  width?: number
}

export const IntegratedBenefitsMinimizedIllustration: FunctionComponent<
  IntegratedBenefitsMinimizedIllustrationProps
> = ({ height = 63, width = 142, ...props }) => {
  return (
    <Image
      {...props}
      style={{ height: height, width: width }}
      source={require('../../assets/integrated_benefits_illustration.png')}
      contentFit='contain'
      accessibilityIgnoresInvertColors
    />
  )
}
