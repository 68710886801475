import React, { FunctionComponent } from 'react'

import { BaseButton, BaseButtonProps, ButtonSize, ButtonType } from '../baseButton/BaseButton'

export interface TertiaryButtonProps extends Omit<BaseButtonProps, 'buttonType' | 'size' | 'modifier'> {
  isSmall?: boolean
}

export const TertiaryButton: FunctionComponent<TertiaryButtonProps> = (props) => (
  <BaseButton
    {...props}
    buttonType={ButtonType.TERTIARY}
    size={props.isSmall ? ButtonSize.SMALL : ButtonSize.DEFAULT}
  />
)
