import React, { KeyboardEvent } from 'react'
import styles from './goSearchBar.module.scss'

const GoSearchInput: React.FC<GoSearchInputProps> = ({ onSearch }) => {
  return (
    <div data-test-id='GoSearchInput-input' className={styles.searchInputContainer}>
      <input
        className={styles.searchInput}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={true}
        placeholder='Search by name or lyra id'
        onKeyUp={onSearch}
      />
    </div>
  )
}

type GoSearchInputProps = {
  onSearch: (event: KeyboardEvent) => void
}

export default GoSearchInput
