import { axiosAuthInstance } from '../axiosInstance'

export function submitRequestPaymentForm(data: any) {
  return axiosAuthInstance.post('/v1/transfers', data)
}

export function getChargeToCopyData(id: any) {
  return axiosAuthInstance.get(`/v1/transfers/${id}`)
}

export function getPaymentsBalancesData() {
  return axiosAuthInstance.get('/v1/transfers/balances')
}

export function downloadPaymentsData(status: any, start: any, end: any, providerId: any, practiceId: any) {
  return axiosAuthInstance.get(`/v1/transfers/csv`, {
    params: {
      status: status,
      start: start,
      end: end,
      providerId: providerId,
      practiceId: practiceId,
    },
  })
}

export function updateChargeStatusData(id: any, data: any) {
  return axiosAuthInstance.put(`/v1/transfers/${id}`, data)
}

export function updateCommentData(chargeId: any, commentText: any) {
  return axiosAuthInstance.put(`/v1/transfers/${chargeId}`, {
    admin_note: commentText,
  })
}
