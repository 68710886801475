import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { connect, ConnectedProps, useSelector } from 'react-redux'

import { endOfQuarter, format, getQuarter, getYear, startOfQuarter, subMonths } from 'date-fns'
import styled from 'styled-components/native'

import { getStartOfNextQuarterByMonth, useFlags, utilizationQuarters } from '@lyrahealth-inc/shared-app-logic'
import {
  colors,
  DashboardUpdateInfo,
  DropdownButton,
  ExternalLinkIcon,
  InlineErrorBanner,
  InlineInfoBanner,
  LoadingSkeleton,
  Subhead,
  SubheadSize,
  TextButton,
  ThemeType,
  tID,
} from '@lyrahealth-inc/ui-core-crossplatform'

import ClosedEpisodesClientDetails from './ClosedEpisodesClientDetails'
import ClosedEpisodesCountSnapshot from './ClosedEpisodesCountSnapshot'
import ClosedEpisodesPercentageSnapshot from './ClosedEpisodesPercentageSnapshot'
import { actions as mixpanelActions, mixpanelEvents } from '../../../../mixpanel/mixpanelConstants'
import { track } from '../../../../mixpanel/mixpanelTracking'
import { getDropdownItemsFromQuarters, getPreviousQuartersSorted } from '../../common/utils/utils'
import { getAuthUserId } from '../../data/auth/authSelectors'
import { getClosedEpisodes } from '../episodes/data/episodesAutoActions'

const TitleContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  padding: theme.spacing['24px'],
  backgroundColor: theme.colors.backgroundPrimary,
  borderBottomWidth: '1px',
  borderBottomColor: theme.colors.borderDefault,
}))

const Container = styled.View({
  marginLeft: '84px',
  marginRight: '84px',
})

const Heading = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  paddingTop: theme.spacing['32px'],
  paddingBottom: theme.spacing['32px'],
  justifyContent: 'space-between',
  flexDirection: 'row',
  alignItems: 'center',
  zIndex: 1,
}))

const HeadingTextContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing['24px'],
}))

const SnapshotSection = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  rowGap: theme.spacing['24px'],
}))

const DataBoardContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['16px'],
  backgroundColor: colors.white,
  padding: `${theme.spacing['32px']} ${theme.spacing['32px']}`,
  border: `1px solid ${colors.ui_oatmeal3}`,
  borderRadius: '16px',
}))

const ClosedEpisodesPercentageSnapshotContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  width: '50%',
  paddingRight: theme.spacing['12px'],
}))

const ClosedEpisodesClientDetailsContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  width: '50%',
  paddingLeft: theme.spacing['12px'],
}))

const DropdownContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginRight: theme.spacing['16px'],
  position: 'relative',
}))

const FlexRowContainer = styled.View({
  display: 'flex',
  flexDirection: 'row',
})

const ErrorContainer = styled.View({
  flexBasis: '100%',
  marginTop: '16px',
  width: '100%',
})

export const MeasurementBasedCare: React.FC<ConnectedProps<typeof connector>> = ({ getClosedEpisodes }) => {
  const userId: string = useSelector(getAuthUserId)
  const { formatMessage } = useIntl()
  const [selectedQuarter, setSelectedQuarter] = useState<string | null>(null)
  const [isLoadingHistoricData, setIsLoadingHistoricData] = useState(false)
  const [showPastQuarterError, setShowPastQuarterError] = useState(false)
  const { shouldShowPastQuartersInBCXDashboards } = useFlags()

  const resetDateTime = getStartOfNextQuarterByMonth()

  const { availableQuarters, previousQuartersSorted, originalStartDate, originalEndDate, currentQuarter } =
    useMemo(() => {
      const currentDate = new Date()
      const currentAndPreviousQuarters: utilizationQuarters = Object.fromEntries(
        [...Array(5).keys()].map((prevQuarter) => {
          const dateInPastQuarter = subMonths(currentDate, prevQuarter * 3)
          return [
            `Q${getQuarter(dateInPastQuarter)}_${getYear(dateInPastQuarter)}`,
            [
              format(startOfQuarter(subMonths(currentDate, prevQuarter * 3)), 'yyyy-MM-dd'),
              format(endOfQuarter(subMonths(currentDate, prevQuarter * 3)), 'yyyy-MM-dd'),
            ],
          ]
        }),
      )
      const sorted = getPreviousQuartersSorted({ availableQuarters: currentAndPreviousQuarters })
      return {
        availableQuarters: currentAndPreviousQuarters,
        previousQuartersSorted: sorted,
        originalStartDate: format(startOfQuarter(currentDate), 'yyyy-MM-dd'),
        originalEndDate: format(endOfQuarter(currentDate), 'yyyy-MM-dd'),
        currentQuarter: sorted[0],
      }
    }, [])

  useEffect(() => {
    if (originalStartDate && originalEndDate) {
      getClosedEpisodes({
        startDate: originalStartDate,
        endDate: originalEndDate,
        providerId: userId,
      })
    }
  }, [userId, originalStartDate, originalEndDate, getClosedEpisodes])

  useEffect(() => {
    if (previousQuartersSorted.length) {
      setSelectedQuarter(previousQuartersSorted[0])
    }
  }, [previousQuartersSorted])

  const dropdownClickHandler = (quarter: string) => {
    setIsLoadingHistoricData(true)
    Promise.all([
      getClosedEpisodes({
        startDate: availableQuarters && availableQuarters[quarter][0],
        endDate: availableQuarters && availableQuarters[quarter][1],
        providerId: userId,
      }),
      setSelectedQuarter(quarter),
    ])
      .then(() => {
        setIsLoadingHistoricData(false)
      })
      .catch((e) => {
        setShowPastQuarterError(true)
        console.error(e)
      })
  }

  const onLearnMoreClicked = useCallback(() => {
    track({
      event: mixpanelEvents.BUTTON_PRESS,
      action: mixpanelActions.LEARN_MORE,
    })
    window.open(
      'https://bct-lyrahealth.zendesk.com/hc/en-us/articles/360042313293-Caseload-Measurement-Based-Care-MBC-Compensation-Criteria',
      '_blank',
    )
  }, [])

  return (
    <>
      <TitleContainer testID={tID('MeasurementBasedCare-title')}>
        <Subhead
          size={SubheadSize.LARGE}
          text={formatMessage({
            defaultMessage: 'Measurement-Based Care',
            description: 'Header for Measurement Based Care Tab in Caseload',
          })}
          color={colors.ui_oatmeal6}
        />
      </TitleContainer>
      <Container>
        <Heading testID={tID('MeasurementBasedCare-heading')}>
          <HeadingTextContainer>
            <DashboardUpdateInfo
              resetDateTime={resetDateTime}
              quarterAndYear={selectedQuarter && selectedQuarter !== currentQuarter ? selectedQuarter : ''}
            />
          </HeadingTextContainer>
          <FlexRowContainer>
            {shouldShowPastQuartersInBCXDashboards && previousQuartersSorted?.length && (
              <DropdownContainer>
                <DropdownButton
                  dropdownItems={getDropdownItemsFromQuarters(previousQuartersSorted, dropdownClickHandler)}
                  dropdownWidth={185}
                />
              </DropdownContainer>
            )}
            <TextButton
              testID={tID('MeasurementBasedCare-learnMoreButton')}
              style={{ alignSelf: 'center' }}
              onPress={onLearnMoreClicked}
              text='Learn more'
              rightIcon={<ExternalLinkIcon size={24} strokeColor={colors.teal4} />}
            />
          </FlexRowContainer>
        </Heading>
        {showPastQuarterError && (
          <ErrorContainer>
            <InlineErrorBanner text='Something went wrong. Please try again later, or contact support for help.' />
          </ErrorContainer>
        )}
        {currentQuarter !== selectedQuarter && (
          <InlineInfoBanner
            marginBottom='0px'
            testId='MeasurementBasedCare-infoBanner'
            text={`You are viewing ${selectedQuarter?.replace(
              '_',
              ' ',
            )} data. If manual adjustments were made after the quarter they will not be reflected here.`}
          />
        )}
        <SnapshotSection>
          <ClosedEpisodesPercentageSnapshotContainer>
            <DataBoardContainer>
              {isLoadingHistoricData ? (
                <LoadingSkeleton width='100%' height={252} />
              ) : (
                <ClosedEpisodesPercentageSnapshot />
              )}
            </DataBoardContainer>
          </ClosedEpisodesPercentageSnapshotContainer>
          <ClosedEpisodesClientDetailsContainer>
            <DataBoardContainer>
              {isLoadingHistoricData ? <LoadingSkeleton width='100%' height={252} /> : <ClosedEpisodesCountSnapshot />}
            </DataBoardContainer>
          </ClosedEpisodesClientDetailsContainer>
        </SnapshotSection>
        <DataBoardContainer>
          {isLoadingHistoricData ? <LoadingSkeleton width='100%' height={252} /> : <ClosedEpisodesClientDetails />}
        </DataBoardContainer>
      </Container>
    </>
  )
}

const connector = connect(null, { getClosedEpisodes })

export default connector(MeasurementBasedCare)
