import { isIOS13 } from 'react-device-detect'
import DetectRTC from 'detectrtc'

export const getDefaultConstraints = () => {
  let constraints: Constraints
  // determine your default video constraints
  // desktop = 16:9 ratio with ideally HD quality
  // mobile = 9:16 ratio with ideally the resolution of the iPhone Facetime Camera
  if (DetectRTC.isMobileDevice) {
    constraints = {
      audio: true,
      video: { facingMode: { exact: 'user' }, aspectRatio: 0.5625, height: { min: 352, ideal: 1280, max: 1334 } },
    }
  } else {
    constraints = {
      audio: true,
      video: { facingMode: 'user', aspectRatio: 1.777777778, width: { min: 320, ideal: 1280, max: 1920 } },
    }
  }
  // Iphone Safari 13.3 requires the video resolution to be set
  if (isIOS13) {
    constraints.video = { ...constraints.video, frameRate: 24, width: 720, height: 1440 }
  }
  return constraints
}

type Constraints = {
  audio: boolean
  video: {
    facingMode: { exact: string } | string
    aspectRatio: number
    frameRate?: number
    width?: { min: number; ideal: number; max: number } | number
    height?: { min: number; ideal: number; max: number } | number
  }
}
