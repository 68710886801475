import React, { FunctionComponent, ReactNode } from 'react'
import { useIntl } from 'react-intl'

import styled, { useTheme } from 'styled-components/native'

import { CloseCircleBordered, PressableOpacity, Subhead, Tooltip } from '../../atoms'
import { SubheadSize } from '../../styles'
import { ThemeType, tID } from '../../utils'

export type MessageAttachmentProps = {
  onPress?: () => void
  onClosePress?: () => void
  showClose?: boolean
  text: string
  icon: ReactNode
  closeTooltipLabel?: ReactNode
}

const Container = styled.View({
  flexDirection: 'row',
  alignSelf: 'flex-start',
})
const InnerContainer = styled(PressableOpacity)(({ theme }) => ({
  flexDirection: 'row',
  backgroundColor: theme.colors.components.messageAttachment.innerContainer.background,
  alignItems: 'center',
  padding: theme.spacing['12px'],
  borderRadius: '16px',
  minWidth: '250px',
}))

const IconContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  backgroundColor: theme.colors.backgroundPrimary,
  padding: theme.spacing['8px'],
  marginRight: theme.spacing['12px'],
  borderRadius: '8px',
}))

const CloseTooltip = styled(Tooltip)({
  position: 'absolute',
  top: '-8px',
  right: '-8px',
})

export const MessageAttachment: FunctionComponent<MessageAttachmentProps> = ({
  onPress,
  text,
  icon,
  onClosePress = () => {},
  showClose = false,
  closeTooltipLabel,
}) => {
  const { formatMessage } = useIntl()
  const { colors } = useTheme()

  return (
    <Container>
      <InnerContainer
        accessible
        accessibilityRole='button'
        accessibilityLabel={text}
        testID={tID('MessageAttachment-button')}
        onPress={onPress}
      >
        <IconContainer>{icon}</IconContainer>
        <Subhead size={SubheadSize.SMALL} color={colors.textInverse} text={text} />
      </InnerContainer>
      {showClose && (
        <CloseTooltip
          testID={tID('MessageAttachment-close-button')}
          hoverEnabled
          content={closeTooltipLabel}
          placement='top'
          onPress={onClosePress}
          accessibilityLabel={formatMessage({
            defaultMessage: 'Remove attachment',
            description: 'Remove attachment',
          })}
        >
          <CloseCircleBordered />
        </CloseTooltip>
      )}
    </Container>
  )
}
