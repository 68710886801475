import { axiosAuthInstance } from '../axiosInstance'

export function getPracticesData() {
  return axiosAuthInstance.get('/v1/practices')
}

export function getPracticeData(id: any) {
  return axiosAuthInstance.get(`/v1/practices/${id}`)
}

export const returnPaymentHistory = (data: any) => {
  return axiosAuthInstance.post('/insertURLhere', data)
}

export function submitRegisterPracticeForm(data: any) {
  return axiosAuthInstance.put('/v1/practices', data)
}

export function getPracticeProvidersData(id: any) {
  return axiosAuthInstance.get(`/v1/practices/${id}/providers`)
}

export function getAvailablePracticesData() {
  return axiosAuthInstance.get(`/v1/provider_search/practices`)
}

export function addPracticeData(data: any) {
  return axiosAuthInstance.post(`/v1/practices`, data)
}

export function addProviderToPracticeData(id: any, data: any) {
  return axiosAuthInstance.post(`/v1/practices/${id}/providers`, data)
}
