import { useMemo } from 'react'
import { defineMessage, MessageDescriptor } from 'react-intl'

import UAParser from 'ua-parser-js'

import { useGetIsMobileWebBrowser } from '../useGetIsMobileWebBrowser'

type Instructions = {
  steps: MessageDescriptor
  link: {
    text: MessageDescriptor
    url: string
  } | null
}

enum SupportedBrowsers {
  CHROME = 'Chrome',
  EDGE = 'Edge',
  FIREFOX = 'Firefox',
  SAFARI = 'Safari',
}

type BrowserInstructions = {
  desktop: Instructions
  mobile?: {
    android?: Instructions
    ios?: Instructions
  }
}
type InstructionMap = { [key in SupportedBrowsers]: BrowserInstructions }

const instructionMap: InstructionMap = {
  [SupportedBrowsers.CHROME]: {
    desktop: {
      steps: defineMessage({
        defaultMessage: `
          <li>Click on the three dots menu icon (⋮) in the upper right corner.</li>
          <li>Select 'Settings'.</li>
          <li>Scroll down and click on 'Advanced'.</li>
          <li>Under 'Privacy and security', click on 'Site settings'.</li>
          <li>Find 'Camera' and "Microphone" and set it to 'Allow'.</li>
        `,
        description: 'Instructions for users on chrome to allow camera and microphone permissions',
      }),
      link: {
        text: defineMessage({
          defaultMessage: 'For more details, visit: <link>Google Chrome Help</link>',
          description: 'Link that shows instructions on how to enable permissions for browser',
        }),
        url: 'https://support.google.com/chrome/answer/2693767?co=GENIE.Platform%3DDesktop',
      },
    },
    mobile: {
      android: {
        steps: defineMessage({
          defaultMessage: `
            <li>Tap the three dots menu icon in the upper right corner.</li>
            <li>Select 'Settings' > 'Site settings'.</li>
            <li>Tap 'Camera' and "Microphone" and toggle it to 'Allow'.</li>
          `,
          description: 'Instructions for users on chrome android to allow camera and microphone permissions',
        }),
        link: {
          text: defineMessage({
            defaultMessage: 'For more details, visit: <link>Google Chrome Help</link>',
            description: 'Link that shows instructions on how to enable permissions for browser',
          }),
          url: 'https://support.google.com/chrome/answer/2693767?co=GENIE.Platform%3DAndroid',
        },
      },
      ios: {
        steps: defineMessage({
          defaultMessage: `
            <li>On the left of your address bar, tap Camera icon</li>
            <li>Tap switch to enable "Camera" and "Microphone"</li>
          `,
          description: 'Instructions for users on chrome iOS to allow camera and microphone permissions',
        }),
        link: {
          text: defineMessage({
            defaultMessage: 'For more details, visit: <link>Google Chrome Help</link>',
            description: 'Link that shows instructions on how to enable permissions for browser',
          }),
          url: 'https://support.google.com/chrome/answer/2693767?co=GENIE.Platform%3DiOS',
        },
      },
    },
  },
  [SupportedBrowsers.EDGE]: {
    desktop: {
      steps: defineMessage({
        defaultMessage: `
          <li>Click the lock to the left of the address bar.</li>
          <li>Find the Camera and Microphone menu items and select "Allow".</li>
          <li>Refresh the page.</li>
        `,
        description: 'Instructions for users on edge to allow camera and microphone permissions',
      }),
      link: null,
    },
    mobile: {
      android: {
        steps: defineMessage({
          defaultMessage: `
            <li>Tap the lock to the left of the address bar.</li>
            <li>Select Permissions from the menu.</li>
            <li>Tap the "Camera" and "Microphone" switch to "Allow"</li>
          `,
          description: 'Instructions for users on edge android to allow camera and microphone permissions',
        }),
        link: null,
      },
      ios: {
        steps: defineMessage({
          defaultMessage: `
            <li>Request permission again by tapping the "Camera" and "Microphone" buttons on the page</li>
            <li>An alert should display and tap "Allow"</li>
          `,
          description: 'Instructions for users on edge iOS to allow camera and microphone permissions',
        }),
        link: null,
      },
    },
  },
  [SupportedBrowsers.FIREFOX]: {
    desktop: {
      steps: defineMessage({
        defaultMessage: `
          <li>Click the permissions icon to the left of the address bar.</li>
          <li>Find Camera and Microphone and click the x for both to remove the restriction.</li>
          <li>Refresh the page.</li>
        `,
        description: 'Instructions for users on firefox to allow camera and microphone permissions',
      }),
      link: {
        text: defineMessage({
          defaultMessage: 'For more details, visit: <link>Firefox Support Article</link>',
          description: 'Link that shows instructions on how to enable permissions for browser',
        }),
        url: 'https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions',
      },
    },
    mobile: {
      android: {
        steps: defineMessage({
          defaultMessage: `
            <li>Tap the lock to the left of the address bar.</li>
            <li>Find the Camera and Microphone section and tap "Blocked" to toggle to "Allow".</li>
          `,
          description: 'Instructions for users on firefox android to allow camera and microphone permissions',
        }),
        link: null,
      },
      ios: {
        steps: defineMessage({
          defaultMessage: `
            <li>Request permission again by tapping the "Camera" and "Microphone" buttons on the page.</li>
            <li>An alert should display and tap "Allow"</li>
          `,
          description: 'Instructions for users on firefox iOS to allow camera and microphone permissions',
        }),
        link: null,
      },
    },
  },
  [SupportedBrowsers.SAFARI]: {
    desktop: {
      steps: defineMessage({
        defaultMessage: `
          <li>Go to Safari > Settings.</li>
          <li>Select the 'Websites' tab.</li>
          <li>Find 'Camera' in the list and set it to 'Allow' for this website.</li>
          <li>Refresh the page.</li>
        `,
        description: 'Instructions for users on safari to allow camera and microphone permissions',
      }),
      link: null,
    },
    mobile: {
      ios: {
        steps: defineMessage({
          defaultMessage: `
            <li>Tap "aA" to the left of your address bar.</li>
            <li>Select Website Settings.</li>
            <li>Tap camera and microphone and set to Allow.</li>
            <li>Select Done and refresh Safari.</li>
          `,
          description: 'Instructions for users on safari iOS to allow camera and microphone permissions',
        }),
        link: null,
      },
    },
  },
}

const DEFAULT_INSTRUCTIONS: Instructions = {
  steps: defineMessage({
    defaultMessage: "Refer to your browser's documentation on how to enable camera and microphone permissions",
    description: 'Default instructions for enabling camera and microphone permissions',
  }),
  link: null,
}
export const useZoomPermissionsInstructions: () => Instructions = () => {
  const isMobileBrowser = useGetIsMobileWebBrowser()

  const userAgent = useMemo(() => new UAParser(navigator.userAgent), [])

  return useMemo(() => {
    const browserName = userAgent.getBrowser().name
    if (!browserName) {
      return DEFAULT_INSTRUCTIONS
    }
    if (!Object.values<string>(SupportedBrowsers).includes(browserName)) {
      return DEFAULT_INSTRUCTIONS
    }
    const browserNameKey = browserName as SupportedBrowsers
    const browserInstructions = instructionMap[browserNameKey]
    if (!isMobileBrowser) {
      return browserInstructions.desktop
    }

    if (!browserInstructions.mobile) {
      return DEFAULT_INSTRUCTIONS
    }

    const isIOS = userAgent.getOS().name === 'iOS'

    if (isIOS) {
      return browserInstructions.mobile.ios ?? DEFAULT_INSTRUCTIONS
    }

    return browserInstructions.mobile.android ?? DEFAULT_INSTRUCTIONS
  }, [isMobileBrowser, userAgent])
}
