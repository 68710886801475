import React, { FunctionComponent } from 'react'
import { View } from 'react-native'

import styled from 'styled-components/native'

import { Image } from '../image/Image'

/**
 * This component contains a gif that animates dots representing a user is typing
 * It is wrapped in a container with a border
 */

const TypingIndicatorContainer = styled(View)(({ theme }) => ({
  backgroundColor: theme.colors.backgroundPrimary,
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '16px',
  width: '64px',
  height: '36px',
  borderWidth: '1px',
  borderColor: theme.colors.borderDefault,
}))

const TypingGif = styled(Image)`
  width: 42px;
  height: 20px;
`

export const TypingIndicator: FunctionComponent = () => {
  return (
    <TypingIndicatorContainer>
      <TypingGif source={require('../../assets/typingIndicator.gif')} />
    </TypingIndicatorContainer>
  )
}
