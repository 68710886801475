import React, { FunctionComponent } from 'react'
import { ViewStyle } from 'react-native'

import { isString } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

export type DividerProps = {
  width?: string | number
  color?: string
  height?: string | number
  style?: ViewStyle
}

const DividerContainer = styled.View<{ width?: string | number; height: string | number; backgroundColor?: string }>`
  align-self: ${({ width }) => (width !== undefined ? 'center' : 'stretch')};
  height: ${({ height }) => height}px;
  background-color: ${({ backgroundColor }) => backgroundColor};

  ${({ width }) => {
    return width !== undefined && `width: ${(width += isString(width) ? ';' : 'px;')}`
  }}
`

/**
 * A component that simply displays a line and can also have width
 */
export const Divider: FunctionComponent<DividerProps> = ({ width, color, height = 0.5, style }) => {
  const { colors } = useTheme()

  return (
    <DividerContainer width={width} height={height} backgroundColor={color || colors.dividerSecondary} style={style} />
  )
}
