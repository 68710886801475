import React, { useState, useEffect } from 'react'
import CSSModules from 'react-css-modules'
import { connect } from 'react-redux'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import styles from './assessmentTrackSettingsModal.module.scss'
import { getAssessmentTracksByProgram, updateAssessmentTrack } from '../episodes/data/episodesAutoActions'
import { BootstrapContainer, DropdownButton, DefaultButton, LoadingIndicator } from '@lyrahealth-inc/ui-core'
import { useFetcher } from '@lyrahealth-inc/ui-core-crossplatform'
import { isNil } from 'lodash-es'
import { Episode, AssessmentSet } from '@lyrahealth-inc/shared-app-logic'
import { addAlert } from '../../data/alertActions'
import { bannerMessages } from '../../common/constants/appConstants'
export const AssessmentTrackSettingsModal: React.FC<AssessmentTrackSettingsModalProps> = ({
  selectedEpisode,
  closeModal,
  actions: { getAssessmentTracksByProgram, updateAssessmentTrack, addAlert },
}) => {
  const [savingAssessmentTrackSelection, setSavingAssessmentTrackSelection] = useState(false)
  const [selectedAssessmentTrack, setSelectedAssessmentTrack] = useState<AssessmentSet | undefined | null>(null)

  // Load up the assessment_sets / tracks we will display in the dropdown
  const [loadingAssessmentTracks, assessmentTracks = []] = useFetcher([
    getAssessmentTracksByProgram,
    { programId: selectedEpisode.program_id },
  ])
  // Set this to the current episode's assessment_set
  useEffect(() => {
    const selectedAssessmentSet = (assessmentTracks as AssessmentSet[]).find(
      (assessmentSet: AssessmentSet) => selectedEpisode.assessment_set_id === assessmentSet.id,
    )
    setSelectedAssessmentTrack(selectedAssessmentSet)
  }, [assessmentTracks, selectedEpisode, setSelectedAssessmentTrack])
  let assessmentTrackDisplayName = ''
  if (!isNil(selectedAssessmentTrack)) {
    assessmentTrackDisplayName = `${selectedAssessmentTrack.display_name}${
      selectedAssessmentTrack.display_name_subtext ? ' · ' + selectedAssessmentTrack.display_name_subtext : ''
    }`
  }
  const getDropdownItems = (assessmentTracks: Array<AssessmentSet>) => {
    const dropdownItems: { id: string; text: string; selectHandler: () => void }[] = []
    assessmentTracks.forEach((assessmentSet) => {
      // We don't want to show assessment_sets with no display name or 'unset' as a selectable option
      if (isNil(assessmentSet.display_name) || assessmentSet.name === 'unset') {
        return
      }
      const displayName = `${assessmentSet.display_name}
        ${assessmentSet.display_name_subtext ? ' · ' + assessmentSet.display_name_subtext : ''}`
      dropdownItems.push({
        id: assessmentSet.id,
        text: displayName,
        selectHandler: () => {
          setSelectedAssessmentTrack(assessmentSet)
        },
      })
    })

    return dropdownItems
  }

  const onUpdateAssessmentTrack = () => {
    setSavingAssessmentTrackSelection(true)
    updateAssessmentTrack({ id: selectedEpisode.id, assessment_set_id: selectedAssessmentTrack?.id })
      .then(() => {
        addAlert({
          show: true,
          contents: bannerMessages.ASSESSMENT_TRACK_SUCCESS,
          style: 'success',
          expires: false,
          autoDismissTimer: 10000,
        })
      })
      .finally(() => {
        closeModal()
        setSavingAssessmentTrackSelection(false)
      })
  }

  return (
    <BootstrapContainer col='col-md-16'>
      <div styleName='assessment-track-modal-body'>
        <div styleName='assessment-track-headers'>
          <h3 styleName='track-header'>Select assessment track</h3>
        </div>
        <div styleName='track-sub-text'>
          <p>Do not change this setting if you have not yet been trained on proper usage for Lyra clients.</p>
        </div>
        <div styleName='dropdown-container'>
          {loadingAssessmentTracks ? (
            <LoadingIndicator />
          ) : (
            <DropdownButton
              styleName='dropdown-button'
              styling='inline'
              dropdownItems={getDropdownItems(assessmentTracks as Array<AssessmentSet>)}
            >
              <span styleName='assessment-track-select'>{assessmentTrackDisplayName}</span>
            </DropdownButton>
          )}
        </div>
        <DefaultButton
          styleType='loader-within'
          isLoading={savingAssessmentTrackSelection}
          onClick={onUpdateAssessmentTrack}
          data-test-id='AssessmentTrackSettingsModal-save'
          customClass={styles['track-settings-save']}
        >
          Save
        </DefaultButton>
      </div>
    </BootstrapContainer>
  )
}
type AssessmentTrackSettingsModalProps = {
  actions: any
  closeModal: any
  selectedEpisode: Episode
}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  actions: bindActionCreators({ getAssessmentTracksByProgram, updateAssessmentTrack, addAlert }, dispatch),
})

export default connect(null, mapDispatchToProps)(CSSModules(AssessmentTrackSettingsModal, styles))
