import { differenceInYears, parse } from 'date-fns'
import { isObjectLike } from 'lodash-es'

import { User } from '../../../models/user/User'
import { DEPENDENT_VARIANT } from '../constants/constants'

export const isAgeTeen = (age: number) => {
  return age >= 13 && age <= 17
}

export const isAgeYoungerThanTeen = (age: number) => {
  return age < 13
}

export const getUserAge = (user: User | undefined) => {
  if (!user?.dob) {
    return undefined
  }

  const dobDate = parse(user.dob, 'MM/dd/yyyy', new Date())
  if (isNaN(dobDate.getTime())) {
    return undefined
  }

  const age = differenceInYears(new Date(), dobDate)
  return age
}

export function sanitizeData(data: any): string | object | any[] {
  const sensitiveFields = ['password', 'token', 'ssn'] // Add more sensitive fields as needed

  // Sanitize arrays
  if (Array.isArray(data)) {
    return data.map((item) => sanitizeData(item)) // Recursively sanitize each item in the array
  }

  if (isObjectLike(data)) {
    const sanitizedData: any = {}

    // Use Object.keys or getOwnPropertyNames to handle non-plain objects
    Object.getOwnPropertyNames(data).forEach((key) => {
      if (sensitiveFields.includes(key)) {
        sanitizedData[key] = '[REDACTED]' // Mask sensitive data
      } else {
        sanitizedData[key] = sanitizeData(data[key]) // Recursively sanitize nested objects without checking the type
      }
    })
    return sanitizedData
  }

  // Sanitize strings using regular expressions
  if (typeof data === 'string') {
    let sanitizedString = data
    /**
     * This regex matches both single and double-quoted key-value pairs in an object-like string,
     * including handling escaped quotes.
     *
     * 1. [\'"]\\\\*: Match either a single quote (') or double quote (") at the start of the key,
     *    with optional backslashes (\\\\) to handle escaped quotes.
     * 2. ${field}: Dynamically insert the field name we're searching for.
     * 3. [\'"]\\\\*: Match the closing quote around the key, with optional backslashes (\\\\) to handle escaped quotes.
     * 4. \\s*: Allow for optional spaces around the colon (:).
     * 5. [\'"]\\\\*: Match either a single or double quote for the value, with optional backslashes (\\\\) to handle escaped quotes.
     * 6. ([^\'"]+): Capture the value inside the quotes (anything except single or double quotes).
     * 7. [\'"]\\\\*: Match the closing quote around the value, with optional backslashes (\\\\) to handle escaped quotes.
     *
     * Flags:
     * - 'g': Global search to match all instances.
     * - 'i': Case-insensitive search.
     */
    sensitiveFields.forEach((field) => {
      const regex = new RegExp(`[\'"]\\\\*${field}\\\\*[\'"]\\s*:\\s*[\'"]\\\\*([^\'"]+)\\\\*[\'"]`, 'gi')
      sanitizedString = sanitizedString.replace(regex, `"${field}": "[REDACTED]"`)
    })

    return sanitizedString
  }

  // Return data as is for other types (e.g., numbers, booleans)
  return data
}

export const getDependentVariant = (age: number, email?: string | undefined) => {
  if (isAgeYoungerThanTeen(age)) {
    return DEPENDENT_VARIANT.CHILD
  } else if (isAgeTeen(age)) {
    return email ? DEPENDENT_VARIANT.SELF_MANAGED_TEEN : DEPENDENT_VARIANT.TEEN
  } else {
    return DEPENDENT_VARIANT.ADULT
  }
}
