import { StyleSheet, TextStyle } from 'react-native'

import { CSSObject } from 'styled-components'
import styled from 'styled-components/native'

import { ThemeColors } from '@lyrahealth-inc/shared-app-logic'

import { IS_LIBRARY } from '../constants'

export enum TextType {
  HEADLINE = 'headline',
  SUBHEAD = 'subhead',
  BODY = 'body',
  CTA = 'cta',
}
export enum HeadlineSize {
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small',
  EYEBROW = 'eyebrow',
}
export enum SubheadSize {
  XLARGE = 'xlarge',
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small',
  XSMALL = 'xsmall',
}
export enum BodyTextSize {
  LARGE = 'large',
  DEFAULT = 'default',
  SMALL = 'small',
  CAPTION = 'caption',
  BADGE = 'badge',
}
export enum CTASize {
  BUTTON = 'button',
}

export type TextSize = HeadlineSize | SubheadSize | BodyTextSize | CTASize

export type TextAlign = 'left' | 'right' | 'center'

// Font weights
const REGULAR = '400'
const MEDIUM = '500'
const BOLD = '700'

// When exporting Ui-Core-Mobile components for the web we need to map the fonts to the declarations made in Ui-Core
export const moderatBold = IS_LIBRARY ? { fontFamily: 'moderat', fontWeight: BOLD } : { fontFamily: 'Moderat-Bold' }
export const moderatMedium = IS_LIBRARY
  ? { fontFamily: 'moderat', fontWeight: MEDIUM }
  : { fontFamily: 'Moderat-Medium' }
export const moderatRegular = IS_LIBRARY
  ? { fontFamily: 'moderat', fontWeight: REGULAR }
  : { fontFamily: 'Moderat-Regular' }
export const moderatPlain = { fontFamily: 'moderat' }

export const getFontStyles = (colors: ThemeColors) => ({
  [TextType.HEADLINE]: {
    [HeadlineSize.LARGE]: {
      ...moderatBold,
      fontSize: 56,
      lineHeight: 66,
      textTransform: 'none',
      color: colors.textPrimary,
    },
    [HeadlineSize.MEDIUM]: {
      ...moderatBold,
      fontSize: 48,
      lineHeight: 54,
      textTransform: 'none',
      color: colors.textPrimary,
    },
    [HeadlineSize.SMALL]: {
      ...moderatBold,
      fontSize: 34,
      lineHeight: 38,
      textTransform: 'none',
      color: colors.textPrimary,
    },
    [HeadlineSize.EYEBROW]: {
      ...moderatBold,
      fontSize: 12,
      lineHeight: 18,
      textTransform: 'uppercase',
      letterSpacing: 0.5,
      color: colors.textPrimary,
    } as CSSObject | TextStyle,
  },
  [TextType.SUBHEAD]: {
    [SubheadSize.XLARGE]: {
      ...moderatMedium,
      fontSize: 34,
      lineHeight: 40.8,
      textTransform: 'none',
      color: colors.textPrimary,
    },
    [SubheadSize.LARGE]: {
      ...moderatMedium,
      fontSize: 28,
      lineHeight: 34,
      textTransform: 'none',
      color: colors.textPrimary,
    },
    [SubheadSize.MEDIUM]: {
      ...moderatMedium,
      fontSize: 22,
      lineHeight: 28,
      textTransform: 'none',
      color: colors.textPrimary,
    },
    [SubheadSize.SMALL]: {
      ...moderatMedium,
      fontSize: 18,
      lineHeight: 24,
      textTransform: 'none',
      color: colors.textPrimary,
    },
    [SubheadSize.XSMALL]: {
      ...moderatMedium,
      fontSize: 16,
      lineHeight: 22,
      textTransform: 'none',
      color: colors.textPrimary,
    },
  },
  [TextType.BODY]: {
    [BodyTextSize.LARGE]: {
      ...moderatRegular,
      fontSize: 18,
      lineHeight: 28,
      color: colors.textPrimary,
    },
    [BodyTextSize.DEFAULT]: {
      ...moderatRegular,
      fontSize: 16,
      lineHeight: 24,
      color: colors.textPrimary,
    },
    [BodyTextSize.SMALL]: {
      ...moderatRegular,
      fontSize: 14,
      lineHeight: 20,
      color: colors.textPrimary,
    },
    [BodyTextSize.CAPTION]: {
      ...moderatRegular,
      fontSize: 12,
      lineHeight: 18,
      color: colors.textPrimary,
    },
    [BodyTextSize.BADGE]: {
      ...moderatBold,
      fontSize: 12,
      lineHeight: 18,
      color: colors.textPrimary,
    },
  },
  [TextType.CTA]: {
    [CTASize.BUTTON]: {
      ...moderatMedium,
      fontSize: 16,
      lineHeight: 24,
    },
  },
})

export const getTypeStyles = (colors: ThemeColors) => {
  const fontStyles = getFontStyles(colors)
  return StyleSheet.create({
    headlineLarge: fontStyles.headline.large,
    headlineMedium: fontStyles.headline.medium,
    headlineSmall: fontStyles.headline.small,
    subheadLarge: fontStyles.subhead.large,
    subheadMedium: fontStyles.subhead.medium,
    subheadSmall: fontStyles.subhead.small,
    subheadXsmall: fontStyles.subhead.xsmall,
    headlineEyebrow: fontStyles.headline.eyebrow,
    bodyLarge: fontStyles.body.large,
    bodyDefault: fontStyles.body.default,
    bodySmall: fontStyles.body.small,
    bodyCaption: fontStyles.body.caption,
    ctaButton: fontStyles.cta.button,
    em: { fontStyle: 'italic' },
  } as { [key: string]: TextStyle })
}

// This component is allowed to use styled.Text since it is the root component for all Text in the mobile app
// eslint-disable-next-line lyrahealth/no-react-native-text
export const StyledText = styled.Text<{ type: TextType; size: TextSize }>(({ theme, type, size }) => {
  const style = getFontStyles(theme.colors)?.[type]?.[size]
  if (style) {
    const styleWithUnits = { ...style, lineHeight: `${style.lineHeight}px` }
    return styleWithUnits as CSSObject
  } else {
    console.warn(`StyledText: The type style ${type} ${size} does not exist`)
    return {} as CSSObject
  }
})

export const getHighlightedText = (colors: ThemeColors) => ({ color: colors.textActive, fontStyle: 'normal' })
