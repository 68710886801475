import React from 'react'
import styles from './eligibilityResults.module.scss'
import { ChevronIcon, CheckSuccessIcon, AlertIcon, StopSignIcon, LoadingIndicator } from '@lyrahealth-inc/ui-core'
import { colors } from '@lyrahealth-inc/ui-core-crossplatform'

export const EligibilityResults: React.FC<EligibilityResultsProps> = ({ result, isLoading }) => {
  const eligibilityColors = {
    eligible: colors.green1,
    warning: colors.orange1,
    not_eligible: colors.red1,
    skipped_check: colors.orange1,
    lyra_code_customer: colors.ui_oatmeal3,
  }

  const eligibilityIcons = {
    eligible: <CheckSuccessIcon width={100} />,
    warning: <AlertIcon width={100} />,
    not_eligible: <StopSignIcon width={100} />,
    skipped_check: <AlertIcon width={100} />,
    lyra_code_customer: <AlertIcon width={100} />,
  }

  const openLink = () => {
    window.open(
      'https://lyrahealth.zendesk.com/knowledge/articles/6650922178835/en-us?brand_id=1106368',
      '_blank',
      'noopener',
    )
  }

  return isLoading ? (
    <div className={styles['loading-container']}>
      <LoadingIndicator size={45} />
    </div>
  ) : (
    <div
      role='link'
      tabIndex={0}
      className={styles['result-container']}
      style={{ backgroundColor: eligibilityColors[result.status || 'not_eligible'] }}
      data-test-id='EligibilityResults-container'
      onClick={openLink}
      onKeyDown={openLink}
    >
      <div className={styles['left-icon']}>{eligibilityIcons[result.status || 'not_eligible']}</div>
      <p className={styles['result-message']}>{result.message || result.error}</p>
      <div className={styles['right-icon']}>
        <ChevronIcon width={25} fillColor={styles.x_soft_black} style={{ marginRight: '20px' }} isFilled />
      </div>
    </div>
  )
}

type EligibilityResultsProps = {
  result: { status?: string; message?: string; error?: string }
  isLoading: boolean
}

export default EligibilityResults
