import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form/immutable'
import CSSModules from 'react-css-modules'
import { DateInputField, SelectField, PrimaryButton } from '@lyrahealth-inc/ui-core'
import styles from './downloadCSVForm.module.scss'
import downloadCSVValidate from './downloadCSVValidate'
import { formatISO } from 'date-fns'

type Props = {
  handleSubmit?: (...args: any[]) => any
  submitDownload?: (...args: any[]) => any
  submitting?: boolean
}
class DownloadCSVForm extends Component<Props> {
  render() {
    const { submitting, handleSubmit, submitDownload } = this.props

    return (
      // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      <form onSubmit={handleSubmit(submitDownload)}>
        <div className={'row'}>
          <div className={'col-xs-6 col-sm-6'}>
            <Field name='payment_status' component={SelectField} label='Payment Status'>
              <option hidden value=''>
                Select...
              </option>
              <option value='Submitted'>Submitted</option>
              <option value='Approved'>Approved</option>
              <option value='Rejected'>Rejected</option>
              <option value='Cancelled'>Cancelled</option>
              <option value='T0'>T0</option>
              <option value='Reversed'>Reversed</option>
              <option value='All'>All</option>
            </Field>
          </div>
        </div>
        <div className={'row'} styleName='date-range'>
          <div className={'col-xs-6 col-sm-6'}>
            <Field
              name='start_date'
              type='tel'
              component={DateInputField}
              showMonthDropdown
              showYearDropdown
              maxDate={formatISO(new Date())}
              dropdownMode='select'
              placeholder='MM/DD/YYYY'
              label='Start Date'
            />
          </div>
          <div className={'col-xs-6 col-sm-6'}>
            <Field
              name='end_date'
              type='tel'
              component={DateInputField}
              showYearDropdown
              maxDate={formatISO(new Date())}
              dropdownMode='select'
              placeholder='MM/DD/YYYY'
              label='End Date'
            />
          </div>
        </div>
        <div styleName='download-container'>
          <PrimaryButton type='submit' id='test-downloadcsv-submit' isLoading={submitting}>
            Download
          </PrimaryButton>
        </div>
      </form>
    )
  }
}

export default reduxForm({
  form: 'downloadCSVForm',
  validate: downloadCSVValidate,
  touchOnBlur: false,
  // @ts-expect-error TS(2345): Argument of type 'typeof DownloadCSVForm' is not a... Remove this comment to see the full error message
})(CSSModules(DownloadCSVForm, styles))
