/**
 * Avoid adding @data types here.
 * Types that are data specific should not live in ui-core-crossplatform
 */
import { defineMessages } from 'react-intl'
import { Dimensions, Platform } from 'react-native'

import {
  DEFAULT_WORKLIFE_SERVICES as DefaultWorklifeServices,
  IllustrationTypes,
} from '@lyrahealth-inc/shared-app-logic'

import DependentsIllustration from './assets/worklife/illustration-dependents.png'
import FinancialIllustration from './assets/worklife/illustration-financial.png'
import LegalIllustration from './assets/worklife/illustration-legal.png'
import SecurityIllustration from './assets/worklife/illustration-security.png'

export enum BiometricsTypes {
  FaceID = 'Face ID',
  TouchID = 'Touch ID',
  FaceUnlock = 'Face Unlock',
  Fingerprint = 'Fingerprint',
}

export const BiometricsMessages = defineMessages({
  [BiometricsTypes.FaceID]: {
    defaultMessage: 'Face ID',
    description: 'Type of iOS mobile device login method using the face of the user',
  },
  [BiometricsTypes.TouchID]: {
    defaultMessage: 'Touch ID',
    description: 'Type of iOS mobile device login method using the fingerprint of the user',
  },
  [BiometricsTypes.FaceUnlock]: {
    defaultMessage: 'Face Unlock',
    description: 'Type of Android mobile device login method using the face of the user',
  },
  [BiometricsTypes.Fingerprint]: {
    defaultMessage: 'Fingerprint',
    description: 'Type of Android mobile device login method using the fingerprint of the user',
  },
})

const width = Dimensions.get('window').width
const height = Dimensions.get('window').height

export const Layout = {
  window: {
    width,
    height,
  },
  isSmallDevice: width <= 375,
}
export const IS_WEB = Platform.OS === 'web'
export const IS_LIBRARY = IS_WEB && !!window.WEB_APP

export const breakpoints = {
  mobileXxs: 320,
  mobileXs: 376,
  mobileXsHeight: 668,
  mobileS: 460,
  mobileM: 575,
  mobileL: 640,
  tablet: 768,
  laptop: 992,
  desktop: 1200,
  desktopL: 1440,
}

export const STRIPE_API_VERSION = '2024-04-10'
export const STRIPE_TEST_KEY = 'pk_test_TYooMQauvdEDq54NiTphI7jx'

// React Native expects accessbilityRole to be from the
// following list of valid values, otherwise the app will error and crash
// https://reactnative.dev/docs/accessibility#accessibilityrole
export enum AccessibilityRolesNative {
  ADJUSTABLE = 'adjustable',
  ALERT = 'alert',
  BUTTON = 'button',
  CHECKBOX = 'checkbox',
  COMBOBOX = 'combobox',
  GRID = 'grid',
  HEADER = 'header',
  IMAGE = 'image',
  IMAGE_BUTTON = 'imagebutton',
  KEYBOARD_KEY = 'keyboardkey',
  LINK = 'link',
  MENU = 'menu',
  MENU_BAR = 'menubar',
  MENU_ITEM = 'menuitem',
  NONE = 'none',
  PROGRESS_BAR = 'progressbar',
  RADIO = 'radio',
  RADIO_GROUP = 'radiogroup',
  SCROLL_BAR = 'scrollbar',
  SEARCH = 'search',
  SPIN_BUTTON = 'spinbutton',
  SUMMARY = 'summary',
  SWITCH = 'switch',
  TAB = 'tab',
  TAB_LIST = 'tablist',
  TEXT = 'text',
  TIMER = 'timer',
  TOGGLE_BUTTON = 'togglebutton',
  TOOLBAR = 'toolbar',
}

export const LYRA_HUB_LINK = 'https://app.icas.health'

export enum SelfCareResourceConditions {
  INTERNATIONAL_ONLY = 'INTERNATIONAL_ONLY',
  DOMESTIC_ONLY = 'DOMESTIC_ONLY',
}

const Illustrations = {
  [IllustrationTypes.DEPENDENTS]: DependentsIllustration,
  [IllustrationTypes.FINANCIAL]: FinancialIllustration,
  [IllustrationTypes.SECURITY]: SecurityIllustration,
  [IllustrationTypes.LEGAL]: LegalIllustration,
}

const addImgPropertyToDefaultWorklifeServices = () => {
  for (const key in DefaultWorklifeServices) {
    DefaultWorklifeServices[key].img = Illustrations[DefaultWorklifeServices[key].illustrationType ?? '']
  }
  return DefaultWorklifeServices
}

export const DEFAULT_WORKLIFE_SERVICES = addImgPropertyToDefaultWorklifeServices()

export const NEXT_VIDEO_COUNTDOWN_SECS = 5

export const LYRA_THEME_STORAGE_KEY = 'lyraTheme'

export const PRIMARY_TREATMENT_REC_IMAGE_CONTAINER_DIMENSIONS = {
  DESKTOP: {
    minHeight: '332px',
    width: '416px',
  },
  TABLET: {
    minHeight: '276px',
    width: '354px',
  },
  MOBILE: {
    height: '190px',
    width: '343px',
  },
}

export const ASSETS_CDN_URL = 'https://assets.lyrahealth.com/'
export const TREATMENT_REC_PRIMARY_CARD_CDN_PREFIX = 'treatmentRecPrimaryCard-'
