import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'

import { ContentLayout } from '@lyrahealth-inc/ui-core'

import { ROLES } from '../common/constants/appConstants'
import { RECONCILER_DETAILS } from '../common/constants/routingConstants'
import { hasRole } from '../common/utils/utils'
import { getAuthRoles } from '../data/auth/authSelectors'
import { SentryRoutes } from '../index'
// pages
import ReconcilerDashboard from '../reconciler/dashboard/ReconcilerDashboard'
import ReconcilerDetails from '../reconciler/individualClient/ReconcilerDetails'

type ReconcilerRoutesProps = {
  roles?: any // TODO: PropTypes.instanceOf(List)
}

class ReconcilerRoutes extends Component<ReconcilerRoutesProps> {
  _getDashboard() {
    if (hasRole(this.props.roles, ROLES.PRACTICES_ADMIN)) {
      return <h1>RECONCILER_DASHBOARD, for Practice Admin </h1>
    } else if (hasRole(this.props.roles, ROLES.PROVIDERS_ADMIN)) {
      return <h1>RECONCILER_DASHBOARD, for Providers Admin </h1>
    } else if (hasRole(this.props.roles, [ROLES.PAYMENTS_ADMIN, ROLES.RECONCILER])) {
      return <ReconcilerDashboard />
    } else if (hasRole(this.props.roles, ROLES.INDIVIDUAL_PROVIDER)) {
      return <h1>RECONCILER_DASHBOARD, for Individual Provider </h1>
    } else {
      return <h1>RECONCILER_DASHBOARD, default</h1>
    }
  }

  _getDetails() {
    if (hasRole(this.props.roles, [ROLES.PAYMENTS_ADMIN, ROLES.RECONCILER])) {
      return <ReconcilerDetails />
    } else {
      return <h1>RECONCILER_DETAILS, default</h1>
    }
  }

  render() {
    return (
      <div>
        <ContentLayout>
          <div style={{ marginTop: '50px' }}>
            <SentryRoutes>
              <Route path='/' element={this._getDashboard()} />
              <Route path={RECONCILER_DETAILS.subroute} element={this._getDetails()} />
            </SentryRoutes>
          </div>
        </ContentLayout>
      </div>
    )
  }
}

const mapStateToProps = ($$state: any) => {
  return {
    roles: getAuthRoles($$state),
  }
}

export default connect(mapStateToProps, null)(ReconcilerRoutes)
