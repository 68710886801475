import { getPracticesData, addPracticeData, getAvailablePracticesData } from '../../common/http/data/practices'
import {
  GET_PRACTICES,
  ADD_PRACTICE,
  GET_AVAILABLE_PRACTICES,
  actionAlertHandler,
  actionStyles,
} from '../../common/constants/reduxConstants'
import { hideAddPracticeModal } from '../dashboard/data/practicesDashboardActions'
export const getPractices = () => {
  const request = getPracticesData()

  return (dispatch: any) => {
    return new Promise(function (resolve, reject) {
      request.then(
        (practicesReturn) => {
          dispatch({
            type: GET_PRACTICES,
            payload: practicesReturn,
          })

          resolve(practicesReturn)
        },
        (error) => {
          if (!error.response) return
          actionAlertHandler({
            actionStyle: actionStyles.ERROR,
            message: error,
            dispatch,
            expires: true,
            action: GET_PRACTICES,
          })
          reject(error)
        },
      )
    })
  }
}

export const getAvailablePractices = () => {
  const request = getAvailablePracticesData()

  return {
    type: GET_AVAILABLE_PRACTICES,
    payload: request,
  }
}

export const addPractice = (data: any) => {
  const request = addPracticeData(data)
  return (dispatch: any) => {
    return request.then(
      function (data) {
        dispatch({
          type: ADD_PRACTICE,
          payload: request,
        })
        dispatch(hideAddPracticeModal())
      },
      (error) => {
        dispatch(hideAddPracticeModal())
        if (!error.response) return
        actionAlertHandler({
          actionStyle: actionStyles.ERROR,
          message: error,
          dispatch,
          expires: true,
          action: GET_PRACTICES,
        })
      },
    )
  }
}
