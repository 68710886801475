import React, { FunctionComponent, useMemo } from 'react'
import { useIntl } from 'react-intl'

import styled from 'styled-components/native'

import { getTimeZoneName } from '@lyrahealth-inc/shared-app-logic'

import { Subhead } from '../../atoms'
import { SubheadSize } from '../../styles'

export interface ExpandedCalendarBookingHeaderProps {
  startDate: string
  endDate: string
  timeZone: string
  appointmentDuration: number
}

const Container = styled.View({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '16px',
})
export const ExpandedCalendarBookingHeader: FunctionComponent<ExpandedCalendarBookingHeaderProps> = ({
  timeZone,
  appointmentDuration,
  startDate,
  endDate,
}) => {
  const intl = useIntl()
  const rangeText = useMemo(() => {
    const startMonth = intl.formatDate(startDate, { month: 'short' })
    const endMonth = intl.formatDate(endDate, { month: 'short' })
    const startYear = intl.formatDate(startDate, { year: 'numeric' })
    const endYear = intl.formatDate(endDate, { year: 'numeric' })
    if (startMonth === endMonth && startYear === endYear) {
      return intl.formatDate(endDate, { month: 'long', year: 'numeric' })
    }
    const startDateFormatted = intl.formatDate(startDate, {
      month: 'short',
      year: startYear === endYear ? undefined : 'numeric',
    })

    const endDateFormatted = intl.formatDate(endDate, { month: 'short', year: 'numeric' })
    return `${startDateFormatted} - ${endDateFormatted}`
  }, [intl, startDate, endDate])

  return (
    <Container>
      <Subhead text={rangeText} size={SubheadSize.MEDIUM} />
      <Subhead
        text={`${appointmentDuration}-min sessions  •  ${getTimeZoneName({ timeZone })} (${getTimeZoneName({
          timeZone,
          timeZoneName: 'short',
        })})`}
        size={SubheadSize.XSMALL}
      />
    </Container>
  )
}
