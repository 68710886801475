import React, { forwardRef, ReactNode, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { LayoutChangeEvent, View } from 'react-native'
import Animated, { Easing, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'

import styled, { useTheme } from 'styled-components/native'

import { UnstyledIconButton } from '../../atoms'
import { BodyText } from '../../atoms/bodyText/BodyText'
import { BodyTextSize } from '../../styles/typeStyles'
import { AlertBannerTypes } from '../../ui-models/alertBanner/AlertBanner'
import { ThemeType, tID } from '../../utils'

export type AlertBannerHandle = {
  open: () => void
  close: () => void
}

export type AlertBannerProps = {
  autoDismissTimer?: number
  contents: ReactNode
  dismissHandler?: () => void
  includeX?: boolean
  show?: boolean
  style?: string
  minHeight?: string
  textSize?: BodyTextSize
}

const ContentOuterContainer = styled(Animated.View)({
  position: 'relative',
  overflow: 'hidden',
  zIndex: -1,
})

const ContentInnerContainer = styled(View)<{ backgroundColor: string; minHeight: string; theme: ThemeType }>(
  ({ backgroundColor, minHeight, theme }) => ({
    position: 'absolute',
    width: '100%',
    bottom: theme.spacing['0px'],
    alignItems: 'center',
    backgroundColor,
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing['16px'],
    zIndex: 1,
    minHeight,
  }),
)

const StyledBodyText = styled(BodyText)<{ theme: ThemeType }>(({ theme }) => ({
  flexGrow: 1,
  paddingRight: theme.spacing['12px'],
}))

const IconButtonContainer = styled.View({
  margin: 'auto',
})

export const AlertBanner = forwardRef<AlertBannerHandle, AlertBannerProps>(
  (
    {
      autoDismissTimer,
      contents,
      dismissHandler,
      includeX = true,
      show,
      style = 'notification',
      minHeight = '100px',
      textSize = BodyTextSize.LARGE,
    },
    ref,
  ) => {
    const { colors } = useTheme()
    const { danger, success, warning, info, notification, calm } = colors.components.alertBanner

    const alertTypeColors = {
      [AlertBannerTypes.DANGER]: danger.background,
      [AlertBannerTypes.SUCCESS]: success.background,
      [AlertBannerTypes.WARNING]: warning.background,
      [AlertBannerTypes.INFO]: info.background,
      [AlertBannerTypes.NOTIFICATION]: notification.background,
      [AlertBannerTypes.CALM]: calm.background,
    }

    const alertTypeTextColors = {
      [AlertBannerTypes.DANGER]: danger.text,
      [AlertBannerTypes.SUCCESS]: success.text,
      [AlertBannerTypes.WARNING]: warning.text,
      [AlertBannerTypes.INFO]: info.text,
      [AlertBannerTypes.NOTIFICATION]: notification.text,
      [AlertBannerTypes.CALM]: calm.text,
    }

    const CLOSE_ANIMATION_DURATION_IN_MS = 300
    const OPEN_ANIMATION_DURATION_IN_MS = 400

    const [isOpen, setIsOpen] = useState(show)
    const contentHeight = useRef<number>(0)

    const sharedHeight = useSharedValue(contentHeight.current || 0)
    const sharedRotation = useSharedValue(isOpen ? -180 : 0)

    const animatedStyles = useAnimatedStyle(() => {
      'worklet'
      return {
        height: sharedHeight.value,
      }
    })

    const animationTiming = {
      open: {
        duration: OPEN_ANIMATION_DURATION_IN_MS,
        easing: Easing.bezier(0.53, 0.08, 0.04, 1),
      },
      close: {
        duration: CLOSE_ANIMATION_DURATION_IN_MS,
        easing: Easing.bezier(0.9, -0.01, 0.68, 0.87),
      },
    }

    useImperativeHandle(ref, () => ({
      open: () => {
        setIsOpen(true)
      },
      close: () => {
        setIsOpen(false)
      },
    }))

    useEffect(() => {
      if (autoDismissTimer) {
        const timeout = setTimeout(() => {
          setIsOpen(false)
          dismissHandler && dismissHandler()
        }, autoDismissTimer)
        return () => {
          clearTimeout(timeout)
        }
      } else {
        return
      }
    }, [autoDismissTimer, dismissHandler])

    useEffect(() => {
      const newHeight = isOpen ? contentHeight.current : 0
      const newRotation = isOpen ? -180 : 0
      const timingOptions = isOpen ? animationTiming.open : animationTiming.close
      sharedHeight.value = withTiming(newHeight, timingOptions)
      sharedRotation.value = withTiming(newRotation, timingOptions)
    }, [animationTiming.close, animationTiming.open, isOpen, sharedHeight, sharedRotation])

    const onContentlayout = (e: LayoutChangeEvent) => {
      const height = e.nativeEvent.layout.height
      if (isOpen && Math.round(contentHeight.current) !== Math.round(height)) {
        // Set shared height here so that content properly flows on resize
        sharedHeight.value = height
      }
      contentHeight.current = height
    }

    const textColor = alertTypeTextColors[style]
    const { formatMessage } = useIntl()

    return (
      <View>
        <ContentOuterContainer style={animatedStyles} testID={tID('AlertBanner-container')}>
          <ContentInnerContainer
            onLayout={onContentlayout}
            accessibilityElementsHidden={!isOpen}
            accessibilityHidden={!isOpen}
            aria-labelledby='AlertBanner-title'
            nativeID={'AlertBanner-container'}
            backgroundColor={alertTypeColors[style]}
            minHeight={minHeight}
          >
            <StyledBodyText color={textColor} size={textSize} text={contents} textAlign={'center'} />
            {includeX && (
              <IconButtonContainer>
                <UnstyledIconButton
                  onPress={() => {
                    setIsOpen(false)
                    dismissHandler && dismissHandler()
                  }}
                  iconColor={textColor}
                  testID={tID('AlertBanner-closeButton')}
                  accessibilityLabel={formatMessage({
                    defaultMessage: 'Close Alert',
                    description: 'This button closes the alert banner',
                  })}
                />
              </IconButtonContainer>
            )}
          </ContentInnerContainer>
        </ContentOuterContainer>
      </View>
    )
  },
)
