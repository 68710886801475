import React from 'react'

import { useTheme } from 'styled-components/native'

import { AIDraftStatus, noteSummaryStatusText } from '@lyrahealth-inc/shared-app-logic'

import { BodyText, Size as BodyTextSize } from '../bodyText/BodyText'
import { Corners, IconBadge } from '../iconBadge/IconBadge'
import { SparklesIcon } from '../icons/SparklesIcon'

export type NotesBadgeProps = {
  status?: AIDraftStatus
  hideText?: boolean
}

export const NotesBadge = ({ status = AIDraftStatus.COMPLETE, hideText = false }: NotesBadgeProps) => {
  const { colors } = useTheme()
  const { notesBadge } = colors.components

  const styleAttibutes = {
    [AIDraftStatus.COMPLETE]: {
      fill: notesBadge.available.background,
      text: notesBadge.available.text,
    },
    [AIDraftStatus.IN_PROGRESS]: {
      fill: notesBadge.processing.background,
      text: notesBadge.processing.text,
    },
    [AIDraftStatus.NOT_STARTED]: {
      fill: notesBadge.processing.background,
      text: notesBadge.processing.text,
    },
    [AIDraftStatus.ERROR]: {
      fill: notesBadge.unavailable.background,
      text: notesBadge.unavailable.text,
    },
    [AIDraftStatus.NOT_FOUND]: {
      fill: notesBadge.unavailable.background,
      text: notesBadge.unavailable.text,
    },
  }

  return (
    <IconBadge
      text={
        hideText ? (
          ''
        ) : (
          <BodyText
            size={BodyTextSize.CAPTION}
            text={noteSummaryStatusText[status]}
            color={styleAttibutes[status].text}
          />
        )
      }
      color={styleAttibutes[status].fill}
      frontIcon={<SparklesIcon size={16} color={styleAttibutes[status].text} />}
      cornerType={Corners.CURVED}
      hideText={hideText}
      testId={`NotesBadge-${status}`}
    />
  )
}
