import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

type Props = {
  size?: number | string
}

export const EmployerBenefitsSpotIllustrationWhiteBorder: FunctionComponent<Props> = ({ size = 124 }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 513 513' fill='none'>
      <Path
        d='M257.823 2.84106C222.482 2.84106 188.193 8.90155 155.908 20.8505C79.0437 49.3419 25.0052 112.507 7.6433 194.146C-3.83015 247.375 -2.36309 296.608 12.1558 344.586C30.6914 405.859 76.1906 457.529 136.988 486.345C167.169 500.681 201.842 508.867 240.026 510.668C244.933 510.89 249.921 511.012 254.879 511.012C285.515 511.012 316.334 506.813 346.484 498.536C348.801 497.96 351.007 497.363 353.334 496.675C380.429 488.743 404.813 476.197 425.675 459.432C448.329 441.331 466.976 418.384 481.039 391.269C483.811 385.927 486.462 380.301 489.386 373.573C505.473 336.219 513.244 294.402 511.868 252.596C511.22 234.111 508.468 214.462 503.713 194.146C495.882 161.183 483.113 130.182 466.783 104.443C462.311 97.5324 458.204 91.7249 454.136 86.5041C424.785 48.6438 379.053 22.0748 321.929 9.71097C300.945 5.15801 279.374 2.85118 257.833 2.85118L257.823 2.84106Z'
        fill='white'
      />
      <Path
        d='M489.904 253.284C489.277 235.568 486.565 217.214 482.295 198.972C475.314 169.58 463.841 140.603 448.108 115.795C444.455 110.169 440.712 104.746 436.645 99.5355C407.141 61.4931 362.431 40.3269 316.669 30.4217C265.696 19.3732 211.496 22.6007 162.496 40.7417C91.6115 67.0072 43.3502 124.546 27.6071 198.557C21.5567 226.593 19.7861 258.282 22.4977 288.726H22.2852C23.8534 306.027 26.9798 322.914 31.6643 338.444C49.0768 395.983 91.3889 441.645 145.286 467.182C174.475 481.043 207.095 487.923 240.25 489.491C274.124 491.059 308.524 486.78 340.324 478.028C342.408 477.512 344.392 476.986 346.476 476.358C371.385 469.063 393.068 457.802 411.623 442.899C431.849 426.741 448.523 406.415 461.453 381.505C464.266 376.082 466.765 370.669 469.163 365.135C484.38 329.794 491.159 291.438 489.803 253.395L489.904 253.294V253.284Z'
        fill='#FFE9E3'
      />
      <Path
        d='M115.2 289.786C114.57 289.496 113.97 289.176 113.35 288.866C117.41 308.416 125.81 326.006 137.36 341.136C139.31 337.016 141.88 333.216 145.24 330.076C167.33 309.386 133.64 298.386 115.19 289.786H115.2Z'
        fill='#6ECBD3'
      />
      <Path
        d='M321.739 364.656C317.919 359.846 318.269 352.956 319.779 347.006C322.759 335.246 329.379 324.776 333.959 313.546C338.539 302.316 340.959 289.126 335.449 278.316C334.549 276.556 333.419 274.846 331.799 273.706C327.589 270.746 321.849 272.486 316.729 272.046C305.909 271.136 298.239 259.666 298.409 248.806C298.519 241.996 300.969 235.556 304.579 229.706C306.719 226.236 309.269 222.966 311.979 219.956C331.019 198.756 355.539 191.146 382.949 187.466C383.849 187.346 384.629 187.346 385.489 187.276C382.279 180.986 378.599 174.976 374.429 169.306C368.429 174.026 360.899 176.936 351.799 176.826C338.069 176.646 324.859 168.636 311.309 170.796C297.179 173.046 285.369 186.026 271.159 184.396C263.769 183.546 257.569 178.576 253.779 172.086C251.879 168.836 250.579 165.216 250.029 161.536C248.379 150.496 252.229 139.316 257.619 129.546C260.679 124.006 263.849 119.226 267.239 115.046C229.739 112.676 192.339 122.576 165.989 144.116C168.819 144.556 171.629 145.166 174.349 146.166C190.279 151.976 202.309 169.356 198.629 185.906C195.779 198.726 185.059 207.986 176.139 217.626C167.219 227.266 159.209 240.436 163.409 252.876C164.929 257.376 167.869 261.136 170.909 264.846C172.519 266.806 174.169 268.756 175.649 270.796C179.929 276.676 182.879 284.816 179.169 291.076C175.079 297.966 163.649 302.546 166.699 309.956C169.469 316.686 179.369 313.576 186.649 313.646C196.769 313.736 205.059 322.726 207.759 332.476C210.459 342.226 208.869 352.596 207.249 362.596C205.869 371.126 204.099 380.096 201.269 388.436C234.729 401.406 273.419 403.256 310.149 390.366C327.209 384.386 342.819 374.846 356.119 362.836C342.749 370.116 328.919 373.706 321.739 364.666V364.656ZM225.329 218.206C221.319 225.946 208.459 236.746 198.989 230.886C186.939 223.426 205.499 205.406 213.019 201.646C213.409 201.446 213.809 201.276 214.209 201.126C226.249 196.676 229.519 210.106 225.329 218.196V218.206Z'
        fill='#6ECBD3'
      />
      <Path
        d='M207.759 332.466C205.059 322.706 196.769 313.716 186.649 313.636C179.369 313.576 169.469 316.676 166.699 309.946C163.649 302.536 175.079 297.956 179.169 291.066C182.879 284.806 179.929 276.676 175.649 270.786C174.169 268.746 172.519 266.796 170.909 264.836C167.859 261.126 164.929 257.376 163.409 252.866C159.209 240.416 167.219 227.256 176.139 217.616C185.059 207.976 195.779 198.716 198.629 185.896C202.309 169.346 190.279 151.966 174.349 146.156C171.619 145.166 168.819 144.556 165.989 144.106C165.789 144.266 165.579 144.416 165.379 144.586C137.439 167.746 117.939 201.926 112.209 240.446C109.659 257.566 110.239 273.786 113.369 288.866C113.989 289.166 114.579 289.486 115.219 289.786C133.659 298.386 167.359 309.386 145.269 330.076C141.909 333.226 139.339 337.016 137.389 341.136C153.549 362.306 175.889 378.586 201.299 388.426C204.129 380.096 205.899 371.126 207.279 362.586C208.889 352.596 210.489 342.226 207.789 332.466H207.759Z'
        fill='#348E27'
      />
      <Path
        d='M250.019 161.546C250.569 165.226 251.869 168.846 253.769 172.096C257.569 178.586 263.759 183.556 271.149 184.406C285.359 186.036 297.169 173.046 311.299 170.806C324.859 168.646 338.069 176.666 351.789 176.836C360.889 176.956 368.409 174.036 374.419 169.316C353.839 141.336 321.579 121.516 277.869 116.006C274.329 115.556 270.779 115.266 267.229 115.046C263.829 119.236 260.659 124.006 257.609 129.546C252.219 139.316 248.369 150.496 250.019 161.536V161.546Z'
        fill='#348E27'
      />
      <Path
        d='M307.509 213.226C291.829 223.856 279.179 240.386 277.909 259.226C277.429 266.376 279.039 274.376 284.809 278.626C295.189 286.266 311.559 277.276 322.209 284.556C331.889 291.176 329.909 305.756 326.839 317.076C322.809 331.886 314.479 349.956 321.749 364.666C328.929 373.706 342.759 370.116 356.129 362.836C379.119 342.066 395.169 313.846 399.529 283.396C404.499 248.726 399.729 215.216 385.489 187.296C384.639 187.366 383.849 187.366 382.949 187.486C358.029 190.836 330.269 198.516 309.009 212.236C308.509 212.566 307.999 212.896 307.509 213.226Z'
        fill='#348E27'
      />
      <Path
        d='M214.209 201.126C213.809 201.276 213.419 201.446 213.019 201.646C205.499 205.406 186.939 223.426 198.989 230.886C208.449 236.746 221.319 225.946 225.329 218.206C229.519 210.116 226.249 196.686 214.209 201.136V201.126Z'
        fill='#348E27'
      />
    </Svg>
  )
}
