import React, { FunctionComponent } from 'react'
import { GestureResponderEvent, Platform, ViewStyle } from 'react-native'

import styled from 'styled-components/native'

import { PressableOpacity } from '../pressableOpacity/PressableOpacity'

export type VideoControlButtonProps = {
  onPress: (event: GestureResponderEvent) => void
  size?: number
  disabled: boolean
  enabledColor: string
  enabledIcon: React.ReactNode
  disabledColor: string
  disabledIcon: React.ReactNode
  accessibilityLabel: string
  style?: ViewStyle
  testID?: string
}

export enum VideoControlButtonTypes {
  CAMERA = 'CAMERA',
  MIC = 'MIC',
  MESSENGER = 'MESSENGER',
  END = 'END',
}

const Container = styled(PressableOpacity)<{ size: number; backgroundColor: string }>`
  ${({ size, backgroundColor }) => `
    background-color: ${backgroundColor};
    height: ${size}px;
    width: ${size}px;
    border-radius: ${size / 2}px;
    justify-content: center;
    align-items: center;
    ${Platform.OS === 'web' && `cursor: pointer;`}
  `}
`

export const VideoControlButton: FunctionComponent<VideoControlButtonProps> = ({
  onPress,
  size = 64,
  disabled,
  enabledIcon,
  enabledColor,
  style,
  testID,
  disabledIcon,
  disabledColor,
  accessibilityLabel,
}) => {
  const backgroundColor = disabled ? disabledColor : enabledColor
  return (
    <Container
      onPress={onPress}
      size={size}
      backgroundColor={backgroundColor}
      style={style}
      testID={`control-${testID}${disabled ? '-disabled' : ''}`}
      accessibilityLabel={accessibilityLabel}
      accessibilityLiveRegion='assertive'
    >
      {disabled ? disabledIcon : enabledIcon}
    </Container>
  )
}
