import React from 'react'
import styles from './availabilityCard.module.scss'
import { dateUtils } from '@lyrahealth-inc/ui-core'
import { ExpeditedBookingIcon, SecondaryButton } from '@lyrahealth-inc/ui-core-crossplatform'
import { size } from 'lodash-es'
import { programConfig, programNameTitleMap } from '../../constants/appConstants'
import { programIconMap } from '../../constants/programIconMap'
import { ProviderUser, Capacity, ProviderExpeditedBookingConfig } from '@lyrahealth-inc/shared-app-logic'
import { useSelector } from 'react-redux'
import { getAuthExpeditedBookingConfig } from '../../../data/auth/authSelectors'

const CaseloadCapacities: React.FC<CaseloadCapacitiesProps> = ({
  provider,
  currentAvailabilityStatusText,
  handleUpdateClick,
  currentCapacity,
}) => {
  const providerExpeditedBookingConfig: ProviderExpeditedBookingConfig | undefined =
    useSelector(getAuthExpeditedBookingConfig)
  return (
    <>
      {Object.entries(provider.capacity_value).map(([programName]) => {
        const ProgramIcon = programConfig[programName]?.icon && programIconMap[programConfig[programName].icon]
        return (
          <div className={styles['capacity-card']} key={programName}>
            {size(provider.capacity_value) > 1 ? (
              <div
                className={styles['icon-container']}
                data-test-id={`CaseloadCapacities-${programNameTitleMap[programName]}-${currentCapacity[programName]}`}
              >
                {ProgramIcon && (
                  <span className={styles['icon-inner-container']}>
                    <ProgramIcon
                      width={44}
                      tooltip={`${programNameTitleMap[programName]} - ${currentAvailabilityStatusText(
                        programName,
                      )} Last updated ${dateUtils.getFriendlyDate(provider.capacity_updated_at)}`}
                      customTooltipWidth='350px'
                    />
                    {providerExpeditedBookingConfig && providerExpeditedBookingConfig[programName]?.enabled && (
                      <div className={styles['expedited-booking-icon-wrapper']}>
                        <ExpeditedBookingIcon width={24} />
                      </div>
                    )}
                  </span>
                )}
                <span className={styles['icon-label']}>{currentCapacity[programName]}</span>
              </div>
            ) : (
              <>
                <div
                  className={styles['icon-container']}
                  data-test-id={`CaseloadCapacities-${programNameTitleMap[programName]}-${currentCapacity[programName]}`}
                >
                  {ProgramIcon && (
                    <span className={styles['icon-inner-container']}>
                      <ProgramIcon
                        width={44}
                        tooltip={`${programNameTitleMap[programName]} - ${currentAvailabilityStatusText(
                          programName,
                        )} Last updated ${dateUtils.getFriendlyDate(provider.capacity_updated_at)}`}
                      />
                      {providerExpeditedBookingConfig && providerExpeditedBookingConfig[programName]?.enabled && (
                        <div className={styles['expedited-booking-icon-wrapper']}>
                          <ExpeditedBookingIcon width={24} />
                        </div>
                      )}
                    </span>
                  )}
                </div>
                <div className={styles['session-info']}>
                  {
                    <p data-test-id='CaseloadCapacities-program-capacity' className={styles['capacity-title']}>
                      {size(provider.capacity_value) > 1 && `${programNameTitleMap[programName]} -`}{' '}
                      {currentAvailabilityStatusText(programName)}
                    </p>
                  }
                  {provider.capacity_updated_at && (
                    <p data-test-id='CaseloadCapacities-updated-date' className={styles['update-date']}>
                      Last updated {dateUtils.getFriendlyDate(provider.capacity_updated_at)}
                    </p>
                  )}
                </div>
              </>
            )}
          </div>
        )
      })}
      <div className={styles['right-button-container']}>
        <SecondaryButton onPress={handleUpdateClick} text='Update' />
      </div>
    </>
  )
}

type CaseloadCapacitiesProps = {
  provider: ProviderUser
  currentAvailabilityStatusText: (program_name: string) => React.ReactNode
  handleUpdateClick: () => void
  currentCapacity: Capacity
}

export default CaseloadCapacities
