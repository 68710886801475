import React from 'react'
import styles from './loadingIndicator.module.scss'
import { uniqueId } from 'lodash-es'

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
  size = 25,
  color = styles.x_semi_dark_gray,
  ...props
}) => {
  const gradientId = uniqueId()
  return (
    <svg
      data-test-id='LoadingIndicator'
      className={styles['loading-indicator']}
      width={size}
      height={size}
      viewBox='0 0 38 38'
      xmlns='http://www.w3.org/2000/svg'
      aria-label='loading indicator'
      {...props}
    >
      <defs>
        <linearGradient x1='8.042%' y1='0%' x2='65.682%' y2='23.865%' id={gradientId}>
          <stop stopColor={color} stopOpacity='0' offset='0%' />
          <stop stopColor={color} stopOpacity='.631' offset='63.146%' />
          <stop stopColor={color} offset='100%' />
        </linearGradient>
      </defs>
      <g fill='none' fillRule='evenodd'>
        <g>
          <path d='M37,19A18,18,0,0,1,1,19' id='Oval-2' stroke={`url(#${gradientId})`} strokeWidth='2' />
        </g>
      </g>
    </svg>
  )
}

type LoadingIndicatorProps = {
  size?: number | string
  color?: string
}

export default LoadingIndicator
