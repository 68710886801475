import { useMemo } from 'react'

type UseDateBlockParams = {
  dates: Array<string>
  currentPageIndex: number
  selectedIndex?: number
  numItems?: number
}

export const useDateBlock = ({ currentPageIndex, dates, selectedIndex = 0, numItems = 5 }: UseDateBlockParams) => {
  const isAtStart = currentPageIndex === 0
  const isAtEnd = dates.length <= numItems || (currentPageIndex + 1) * numItems >= dates.length

  const startIndex = isAtStart ? 0 : currentPageIndex * numItems
  const endIndex = (currentPageIndex + 1) * numItems >= dates.length ? dates.length : (currentPageIndex + 1) * numItems

  const data = useMemo(() => dates.slice(startIndex, endIndex), [startIndex, endIndex, dates])

  return {
    data,
    isAtStart,
    isAtEnd,
    selectedDate: data[selectedIndex],
  }
}
