import React, { FunctionComponent } from 'react'
import { Path, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

/**
 * Search Icon with a circle shown on the welcome screen
 */
export const SearchIcon: FunctionComponent<SearchIconProps> = ({ size = 24, fillColor }) => {
  const { colors } = useTheme()
  const { searchIcon } = colors.icons
  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' fill='none'>
      <Path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.3333 6.75C8.80203 6.75 6.75 8.80203 6.75 11.3333C6.75 13.8646 8.80203 15.9167 11.3333 15.9167C13.8646 15.9167 15.9167 13.8646 15.9167 11.3333C15.9167 8.80203 13.8646 6.75 11.3333 6.75ZM5.25 11.3333C5.25 7.9736 7.9736 5.25 11.3333 5.25C14.6931 5.25 17.4167 7.9736 17.4167 11.3333C17.4167 14.6931 14.6931 17.4167 11.3333 17.4167C7.9736 17.4167 5.25 14.6931 5.25 11.3333Z'
        fill={fillColor || searchIcon.fill}
      />
      <Path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.5697 14.5697C14.8626 14.2768 15.3374 14.2768 15.6303 14.5697L18.5303 17.4697C18.8232 17.7626 18.8232 18.2374 18.5303 18.5303C18.2374 18.8232 17.7626 18.8232 17.4697 18.5303L14.5697 15.6303C14.2768 15.3374 14.2768 14.8626 14.5697 14.5697Z'
        fill={fillColor || searchIcon.fill}
      />
    </Svg>
  )
}

type SearchIconProps = {
  size?: string | number
  fillColor?: string
}
