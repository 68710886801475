import React from 'react'
import * as _ from 'lodash-es'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'
import { getDateInDomesticFormat } from '@lyrahealth-inc/shared-app-logic'

export const isValidEmail = (value: $TSFixMe) => !!(value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value))
export const isNumber = (value: $TSFixMe) => !_.isNaN(value) && _.isNumber(value)

/**
 * Checks if a string is a valid currency amount.
 *
 * Valid amounts examples:
 *   - .57
 *   - 0.57
 *   - 0
 *   - 12
 *
 * @param {string} value The string to check
 * @return {boolean} `true` if the string contains only digits and decimal separator, false otherwise.
 */
export const isValidCurrencyAmount = (value: $TSFixMe) => value.match(/^(0?|\d+)(\.\d+)?$/)

/**
 * Checks if a string is a valid integer.
 *
 * @param {string} value The string to check
 * @return {boolean} `true` if the string is a valid integer, false otherwise.
 */
export const isValidInteger = (value: $TSFixMe) => value.match(/^(0|[1-9]\d*)$/)

export const isValidNameString = (value: $TSFixMe) => {
  let returnBoolean = false
  if (/^[A-Z a-z \u00C0-\u017F ' \- . *]+$/.test(value)) {
    returnBoolean = true
  }
  return returnBoolean
}
export const isValidPassword = (value: $TSFixMe) => {
  let isValid = true
  if (!/[A-Z]/.test(value)) {
    // message.push('UPPER')
    isValid = false
  }
  if (!/[a-z]/.test(value)) {
    // message.push('LOWER')
    isValid = false
  }
  // === NUMBER OF NUMBERS CHECK ===
  if (!/\d/.test(value)) {
    isValid = false
  }
  // === NUMBER OF CHARATERS CHECK ===
  if (value.length < 8) {
    isValid = false
  }
  return isValid
}

// Checks whether a given date is a valid date of birth for a user.
// You can specify a minimum and maximum age (in years) that the user has to meet.
// A user has to be at least a minimum age and can be no older than the maximum age.
export const isValidDateOfBirth = (
  value: $TSFixMe,
  ageConstraints = { min: 0, max: 150 },
  dateFormat = 'MM/DD/YYYY',
) => {
  let { isValid, message } = isValidDate(value, dateFormat)
  if (!isValid) {
    return { isValid, message }
  } else {
    const date = moment(value, dateFormat, true /* strict parsing */)
    const age = moment().diff(moment(date), 'years')
    if (!Number.isFinite(age)) {
      // Weeds out null, undefined, NaN
      isValid = false
      message = (
        <FormattedMessage
          defaultMessage='Invalid date of birth'
          description='Error message indicating that the input entered is not a valid date of birth'
        />
      )
    } else if (age < ageConstraints.min) {
      isValid = false
      message = (
        <FormattedMessage
          defaultMessage='Must be {minAge} or older'
          description='Error message indicating that the age corresponding to the entered date of birth is below the minimum allowable age'
          values={{
            minAge: ageConstraints.min,
          }}
        />
      )
    } else if (age > ageConstraints.max) {
      isValid = false
      message = (
        <FormattedMessage
          defaultMessage='Must be {maxAge} or younger'
          description='Error message indicating that the age corresponding to the entered date of birth is above the maximum allowable age'
          values={{
            maxAge: ageConstraints.max,
          }}
        />
      )
    }
    return { isValid, message }
  }
}

// Checks whether a given date is a real date.
export const isValidDate = (value: $TSFixMe, dateFormat = 'MM/DD/YYYY') => {
  const sysTime = new Date()
  const isValid = true
  const message = ''
  const momentDate = moment(value, dateFormat, true /* strict parsing */)
  const isValidDate = momentDate.isValid()
  const invalidDateErrorMessage = (
    <FormattedMessage
      defaultMessage='Invalid Date'
      description='Error message indicating that the entered value is not a valid date'
    />
  )

  if (!isValidDate) {
    return {
      isValid: false,
      message: invalidDateErrorMessage,
    }
  }
  // Check for date in the future
  // Need to make sure date is in domestic format before evaluating it
  const dateInDomesticFormat = getDateInDomesticFormat({ date: value, dateFormat })
  if (sysTime.getTime() - new Date(dateInDomesticFormat).getTime() < 0) {
    return {
      isValid: false,
      message: invalidDateErrorMessage,
    }
  }
  return {
    isValid,
    message,
  }
}

// Checks whether a given range of dates is valid, assuming that the individual dates themselves are valid.
export const isValidDateRange = (start: $TSFixMe, end: $TSFixMe) => moment(start).isSameOrBefore(end)

// The functions below are made to be used with use with `react-final-form`.
// They return an error object mapping the field name with the error message.

export const requiredField = (value: $TSFixMe, fieldName: $TSFixMe, validation: $TSFixMe) => {
  const errors = {}
  if (!validation) return errors
  if (
    (!_.isBoolean(value) && !_.isNumber(value) && _.isEmpty(value)) ||
    (typeof value.isEmpty === 'function' && value.isEmpty())
  ) {
    _.set(errors, fieldName, 'This field is required')
  }
  return errors
}

export const hasValue = (value: $TSFixMe, fieldName: $TSFixMe, validation: $TSFixMe) => {
  const errors = {}
  if (value !== validation) {
    _.set(errors, fieldName, 'This field has the wrong value')
  }
  return errors
}

export const characterLimit = (value: $TSFixMe, fieldName: $TSFixMe, validation: $TSFixMe) => {
  const errors = {}
  if (!validation || _.isNil(value)) return errors
  if (typeof value !== 'string') {
    _.set(errors, fieldName, 'Must be a string')
  }
  if (value.length > validation) {
    _.set(errors, fieldName, `Must be ${validation} characters or less`)
  }
  return errors
}

export const isValidPastDate = (value: $TSFixMe, fieldName: $TSFixMe, validation: $TSFixMe) => {
  const errors = {}
  if (!validation || _.isNil(value)) return errors
  const val = moment(value)
  if (!val.isValid()) {
    _.set(errors, fieldName, 'Please enter a valid date')
  } else if (moment().diff(val, 'days') < 0) {
    _.set(errors, fieldName, 'Date cannot be in the future')
  }
  return errors
}

export const isPositiveInteger = (value: $TSFixMe, fieldName: $TSFixMe, validation: $TSFixMe) => {
  const errors = {}
  if (!validation || _.isNil(value)) return errors
  if (!/^\+?[0-9][\d]*$/.test(value)) {
    _.set(errors, fieldName, 'Please enter a valid number')
  }
  return errors
}

export const maxValue = (value: $TSFixMe, fieldName: $TSFixMe, validation: $TSFixMe) => {
  const errors = {}
  if (_.isNil(value)) {
    return errors
  }
  if (!isNumber(parseInt(value))) {
    _.set(errors, fieldName, `Must be a number`)
  }
  if (value > validation) {
    _.set(errors, fieldName, `Must be ${validation} or less`)
  }
  return errors
}

export const minValue = (value: $TSFixMe, fieldName: $TSFixMe, validation: $TSFixMe) => {
  const errors = {}
  const parsedValue = parseInt(value)
  if (isNaN(parsedValue)) {
    _.set(errors, fieldName, `Must be a number`)
  } else if (value < validation) {
    _.set(errors, fieldName, `Must be ${validation} or more`)
  }
  return errors
}

export const isValidPastYear = (value: $TSFixMe, fieldName: $TSFixMe, validation: $TSFixMe) => {
  const errors = {}
  if (!validation || _.isNil(value)) return errors
  if (value.length !== 4 || !/^\+?[1-9][\d]*$/.test(value)) {
    _.set(errors, fieldName, 'Please enter a valid year')
  } else if (parseInt(value) > new Date().getFullYear()) {
    _.set(errors, fieldName, 'Year cannot be in the future')
  }
  return errors
}

export const isValid5DigitZipCode: FinalFormValidation = (value, fieldName, validation) => {
  const errors = {}
  if (!validation || _.isNil(value)) return errors
  if (!/^\d{5}/.test(value)) {
    _.set(errors, fieldName, 'Please enter a valid 5 digit zip code')
  }
  return errors
}

export default {
  isValidEmail,
  isNumber,
  isValidNameString,
  isValidPassword,
  isValidDateOfBirth,
  isValidDate,
  isValidDateRange,
  isValidCurrencyAmount,
  isValidInteger,
  requiredField,
  characterLimit,
  isValidPastDate,
  isPositiveInteger,
  maxValue,
  isValidPastYear,
  hasValue,
  isValid5DigitZipCode,
}

export interface FinalFormValidation {
  (value: any, fieldName: string, validation: boolean): Record<string, string>
}
