import React from 'react'
import { connect } from 'react-redux'

import { ContentLayout } from '@lyrahealth-inc/ui-core'

import { ROLES } from '../common/constants/appConstants'
import { hasRole } from '../common/utils/utils'
// Routes
import { getAuthRoles } from '../data/auth/authSelectors'
import PaymentsDashboard from '../payments/dashboard/PaymentsDashboard'

type PaymentsRoutesProps = {
  roles?: any // TODO: PropTypes.instanceOf(List)
}

class PaymentsRoutes extends React.Component<PaymentsRoutesProps> {
  _getContent() {
    const { roles } = this.props
    if (hasRole(roles, ROLES.PRACTICES_ADMIN)) {
      return <h1>PAYMENTS_DASHBOARD, for Practice Admin </h1>
    } else if (hasRole(roles, ROLES.PROVIDERS_ADMIN)) {
      return <h1>PAYMENTS_DASHBOARD, for Provider Admin </h1>
    } else if (hasRole(roles, ROLES.PAYMENTS_ADMIN)) {
      return <PaymentsDashboard />
    } else if (hasRole(roles, ROLES.INDIVIDUAL_PROVIDER)) {
      return <h1>PAYMENTS_DASHBOARD, for Individual Provider </h1>
    } else {
      return <h1>PAYMENTS_DASHBOARD, default</h1>
    }
  }

  render() {
    return (
      <div>
        <ContentLayout>
          <div style={{ marginTop: '50px' }}>{this._getContent()}</div>
        </ContentLayout>
      </div>
    )
  }
}

const mapStateToProps = ($$state: any) => {
  return {
    roles: getAuthRoles($$state),
  }
}

export default connect(mapStateToProps, null)(PaymentsRoutes)
