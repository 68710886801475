import { ClientObject } from '../client/Client'
import { Outcome } from '../outcome/Outcome'

export type OutcomesResponse = {
  client: ClientObject
  outcomes: Outcome[]
}

export enum ProviderAccessField {
  HEADER = 'header',
  SUBHEADER = 'subheader',
  FIRST_ROW = 'firstRow',
  SECOND_ROW = 'secondRow',
  PROVIDER_LAST_NAME = 'providerLastName',
  ACCESS_CODE = 'accessCode',
  CLIENT_LAST_NAME = 'clientLastName',
  CLIENT_DATE_OF_BIRTH = 'clientDateOfBirth',
  ERROR_BANNER = 'errorBanner',
}

export type ProviderAccessFormValues = {
  [ProviderAccessField.PROVIDER_LAST_NAME]: string
  [ProviderAccessField.ACCESS_CODE]: string
  [ProviderAccessField.CLIENT_LAST_NAME]: string
  [ProviderAccessField.CLIENT_DATE_OF_BIRTH]: string
}
