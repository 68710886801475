import React from 'react'
import CSSModules from 'react-css-modules'
import { connect } from 'react-redux'

import { format, parseISO } from 'date-fns'
import { bindActionCreators } from 'redux'

import { BaseModal, LoadingIndicator, SecondaryButton } from '@lyrahealth-inc/ui-core'

import styles from './frequencyConflict.module.scss'
import PaymentCard from '../../../../common/components/paymentCard/PaymentCard'
import PaymentDetailsModal from '../../../../common/components/paymentDetailsModal/PaymentDetailsModal'
import { ROLES } from '../../../../common/constants/appConstants'
import { GET_PROVIDER_PAYMENT_HISTORY } from '../../../../common/constants/reduxConstants'
import { hasErrorForAction, hasRole } from '../../../../common/utils/utils'
import { getAlertsState } from '../../../../data/alertsSelectors'
import { getAuthRoles } from '../../../../data/auth/authSelectors'
import { getProvidersDetails } from '../../../data/providerSelectors'
import * as providerDetailsActions from '../../data/providerDetailsActions'

type FrequencyConflictProps = {
  providerID?: string
  historyData?: any // TODO: PropTypes.instanceOf(Map)
  actions?: any
  showModal?: boolean
  modalData?: any // TODO: PropTypes.instanceOf(Map)
  alerts?: any // TODO: PropTypes.instanceOf(List)
  roles?: any // TODO: PropTypes.instanceOf(List)
}

type FrequencyConflictState = any

class FrequencyConflict extends React.Component<FrequencyConflictProps, FrequencyConflictState> {
  constructor(props: FrequencyConflictProps) {
    super(props)

    this.state = {
      loadingMore: false,
      noMoreForType: {
        all: false,
      },
    }
  }

  componentWillUnmount() {
    this.props.actions.clearProviderPaymentHistory()
  }

  shouldComponentUpdate(nextProps: FrequencyConflictProps, _: FrequencyConflictState) {
    if (!nextProps.historyData || nextProps.historyData.length === 0) {
      // prevents duplications on fast subview switches
      return false
    }
    return true
  }

  _onViewClick = (data: any) => {
    this.props.actions.toggleProviderDetailsModal(true, data)
  }

  _renderPaymentsList = () => {
    if (!this.props.historyData || !this.props.historyData?.all) {
      if (hasErrorForAction(this.props.alerts, GET_PROVIDER_PAYMENT_HISTORY)) {
        return false // custom workflow or display could render here
      } else {
        this.props.actions.getProviderPaymentHistory({ providerID: this.props.providerID, page: '0' })
        return (
          <div className={styles['loading-container']}>
            <LoadingIndicator size={45} />
          </div>
        )
      }
    }

    const allPayments = this.props.historyData?.all?.data ?? []

    const paymentsList = allPayments.filter((payment: any) => {
      return (
        ['Submitted', 'Pending', 'T0'].indexOf(payment.status) >= 0 &&
        payment.is_too_frequent_with_charge_ids !== null &&
        payment.is_too_frequent_with_charge_ids.length > 0
      )
    })

    if (paymentsList.length === 0) {
      return <div className={styles['loading-container']}>No conflicts found.</div>
    }

    return (
      <div>
        {paymentsList.map((payment: any, i: any) => {
          const key = `$$historyData${i}`

          const conflictingDates: any = []
          payment.get('is_too_frequent_with_charge_ids').map(($$charge: any) => {
            conflictingDates.push(format(parseISO($$charge.get('date_of_service')), 'MM/dd/yyyy'))
          })

          return (
            // @ts-expect-error TS(2739): FIXME! Missing properties 'reverseClick', 'rejectClick', 'excludeClick'
            <PaymentCard
              readonly={hasRole(this.props.roles, ROLES.VIEW_ONLY)}
              data={payment}
              conflicts={conflictingDates}
              type='patient'
              key={key}
              viewClick={this._onViewClick}
            />
          )
        })}
      </div>
    )
  }

  _loadMore = () => {
    const currentPage = this.props.historyData?.all?.page
    const nextPage = parseInt(currentPage, 10) + 1

    this.setState({
      loadingMore: true,
    })
    this.props.actions.getProviderPaymentHistory({ providerID: this.props.providerID, page: nextPage }).then(
      (successObj: any) => {
        const noMores = this.state.noMoreForType
        noMores.all = successObj.data.data.length === 0

        this.setState({
          loadingMore: false,
          noMoreForType: noMores,
        })
      },
      () => {
        this.setState({
          loadingMore: false,
        })
      },
    )
  }

  render() {
    const { showModal, modalData } = this.props

    const ModalData = (
      <PaymentDetailsModal
        // @ts-expect-error TS(2322): Type '{ type: string; viewData: any; closeModal: (... Remove this comment to see the full error message
        type='patient'
        viewData={modalData}
        closeModal={() => this.props.actions.toggleProviderDetailsModal(false)}
      />
    )

    return (
      <div>
        {this._renderPaymentsList()}
        {this.state.noMoreForType.all ? (
          <div styleName='end-of-list-spacer' />
        ) : (
          <div styleName='load-more'>
            {this.state.loadingMore ? (
              <LoadingIndicator size={30} />
            ) : this.props.historyData && this.props.historyData.all ? (
              <SecondaryButton onClick={this._loadMore}>Load More</SecondaryButton>
            ) : (
              []
            )}
          </div>
        )}
        <BaseModal
          // @ts-expect-error TS(2322): Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
          isOpen={showModal}
          body={ModalData}
          closeModal={() => this.props.actions.toggleProviderDetailsModal(false)}
        />
      </div>
    )
  }
}

const mapStateToProps = ($$state: any) => {
  const providerDetails = getProvidersDetails($$state)
  return {
    providerID: providerDetails?.data?.lyra_id,
    historyData: providerDetails.paymentHistory,
    showModal: providerDetails.showModal,
    modalData: providerDetails.modalData,
    alerts: getAlertsState($$state),
    roles: getAuthRoles($$state),
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    actions: bindActionCreators({ ...providerDetailsActions }, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CSSModules(FrequencyConflict, styles))
