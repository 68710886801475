import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

export const CouplesTherapySecondaryRec: FunctionComponent<CouplesTherapySecondaryRecProps> = ({
  width = 80,
  height = 80,
}) => {
  return (
    <Svg width={width} height={height} viewBox='0 0 80 80' fill='none'>
      <Path
        d='M40.2812 0.300781C34.7592 0.300781 29.4015 1.24773 24.3569 3.11476C12.3469 7.56654 3.9034 17.436 1.1906 30.1922C-0.602123 38.5092 -0.372894 46.2018 1.89568 53.6984C4.79186 63.2723 11.9011 71.3458 21.4007 75.8482C26.1164 78.0883 31.5341 79.3673 37.5004 79.6487C38.2671 79.6834 39.0465 79.7024 39.8212 79.7024C44.6081 79.7024 49.4235 79.0464 54.1345 77.7532C54.4965 77.6631 54.8412 77.5698 55.2048 77.4623C59.4384 76.2229 63.2483 74.2626 66.5081 71.6431C70.0477 68.8148 72.9613 65.2294 75.1587 60.9926C75.5919 60.1579 76.0061 59.2789 76.463 58.2276C78.9766 52.391 80.1907 45.8572 79.9757 39.325C79.8745 36.4367 79.4445 33.3666 78.7015 30.1922C77.4779 25.0417 75.4828 20.1978 72.9313 16.176C72.2325 15.0963 71.5907 14.1889 70.9551 13.3731C66.369 7.45746 59.2234 3.30605 50.2977 1.3742C47.0189 0.662804 43.6485 0.302362 40.2828 0.302362L40.2812 0.300781Z'
        fill='white'
      />
      <Path
        d='M76.5437 39.4318C76.4457 36.6637 76.022 33.7959 75.3549 30.9456C74.2641 26.3531 72.4713 21.8255 70.0131 17.9491C69.4424 17.0702 68.8574 16.2228 68.2219 15.4087C63.6121 9.46452 56.6261 6.1573 49.4758 4.60961C41.5113 2.88328 33.0424 3.38759 25.3862 6.22212C14.3105 10.3261 6.76968 19.3166 4.30982 30.8808C3.36445 35.2614 3.0878 40.2128 3.51148 44.9697H3.47828C3.72332 47.673 4.21181 50.3115 4.94376 52.7381C7.66447 61.7286 14.2757 68.8632 22.6971 72.8533C27.258 75.0192 32.3548 76.0942 37.5353 76.3392C42.8281 76.5842 48.2031 75.9155 53.1719 74.5481C53.4975 74.4674 53.8074 74.3852 54.1331 74.2872C58.0252 73.1474 61.413 71.3879 64.3124 69.0592C67.4726 66.5345 70.0779 63.3585 72.0983 59.4664C72.5377 58.619 72.9282 57.7733 73.3029 56.9085C75.6805 51.3865 76.7397 45.3933 76.5279 39.4492L76.5437 39.4334V39.4318Z'
        fill='#FFE9E3'
      />
      <Path
        d='M37.8069 36.4887C40.2731 34.1869 42.6871 33.9546 44.777 34.5996C45.1043 31.6559 43.9423 28.7329 41.3924 27.3354C34.7305 23.6819 29.5484 30.6979 29.5484 30.6979C29.5484 30.6979 21.2787 22.4125 15.7456 30.0592C9.5944 38.5597 29.3776 52.0162 31.2842 51.5925C32.5331 51.3159 34.8364 49.3382 37.2046 46.7645C35.4656 42.9008 35.053 39.0592 37.8069 36.4903V36.4887Z'
        fill='#FFAF9B'
      />
      <Path
        d='M63.5 38.7482C57.4151 33.5613 51.2307 39.7711 51.2307 39.7711C51.2307 39.7711 48.5353 35.7604 44.7775 34.6016C44.6574 35.6829 44.3412 36.7674 43.81 37.7807C42.3414 40.5789 39.7377 44.0142 37.2051 46.7665C40.3099 53.6655 47.6658 60.6419 48.9179 60.6103C51.5627 60.5439 60.0362 54.2299 63.8652 49.4619C66.529 46.1452 66.5274 41.3298 63.5 38.7482Z'
        fill='#F69170'
      />
      <Path
        d='M44.7778 34.6003C42.6894 33.9553 40.2738 34.1892 37.8077 36.4894C35.0538 39.0599 35.4648 42.8999 37.2053 46.7636C39.7379 44.0113 42.3416 40.576 43.8103 37.7778C44.3415 36.7661 44.6576 35.6816 44.7778 34.5987V34.6003Z'
        fill='#E5744C'
      />
    </Svg>
  )
}

type CouplesTherapySecondaryRecProps = {
  width?: number
  height?: number
}
