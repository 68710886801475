declare global {
  interface Window {
    Cypress: any
    twilioRoomMock: RoomMock
  }
}

class EventEmitter {
  events: { [key: string]: { (...args: any[]): void }[] }

  constructor() {
    this.events = {}
  }

  on(eventName: string, callback: (...args: any[]) => void) {
    if (this.events[eventName]) {
      this.events[eventName].push(callback)
    }
  }

  off(eventName: string, callback: (...args: any[]) => void) {
    const index = this.events[eventName].indexOf(callback)
    if (index !== -1) {
      this.events[eventName].splice(index, 1)
    }
  }

  trigger(eventName: string, ...params: any) {
    // eslint-disable-next-line standard/no-callback-literal
    this.events[eventName].forEach((callback) => callback(...params))
  }
}
export class Track extends EventEmitter {
  kind: string

  name: string

  constructor({ kind = 'audio', name = 'ffc7a878-68bf-4e78-8ae6-68753cec4e16' } = {}) {
    super()
    this.kind = kind
    this.name = name
  }
}
export class AudioTrack extends Track {
  isStarted: boolean

  isEnabled: boolean

  // @ts-expect-error TS(2564): Property 'mediaStreamTrack' has no initializer and... Remove this comment to see the full error message
  mediaStreamTrack: MediaStreamTrack

  constructor({ name = 'ffc7a878-68bf-4e78-8ae6-68753cec4e16', muted = false } = {}) {
    super({ kind: 'audio', name })
    this.isStarted = true
    this.isEnabled = !muted
    this.events = {
      disabled: [],
      enabled: [],
      started: [],
    }
  }

  attach() {
    return document.createElement('audio')
  }
}

export class RemoteAudioTrack extends AudioTrack {
  isSwitchedOff: boolean

  priority: string | null

  processedTrack: null

  sid: string

  constructor({
    name = 'ffc7a878-68bf-4e78-8ae6-68753cec4e16',
    sid = 'MTa6d4531e5026a46592324ea730cdfc51',
    muted = false,
  } = {}) {
    super({ name, muted })
    this.isSwitchedOff = false
    this.priority = null
    this.processedTrack = null
    this.sid = sid
    this.events = {
      disabled: [],
      enabled: [],
      started: [],
      switchedOff: [],
      switchedOn: [],
    }
  }

  muted() {
    this.isEnabled = false
    this.trigger('disabled', this)
  }

  unMuted() {
    this.isEnabled = true
    this.trigger('enabled', this)
  }
}

export class RemoteAudioTrackPublication extends EventEmitter {
  isSubscribed: boolean

  isTrackEnabled: boolean

  kind: string

  publishPriority: string

  track: RemoteAudioTrack

  trackName: string

  trackSid: string

  events: { [key: string]: { (...args: any[]): void }[] }

  constructor({
    name = 'ffc7a878-68bf-4e78-8ae6-68753cec4e16',
    sid = 'MTa6d4531e5026a46592324ea730cdfc51',
    muted = false,
  } = {}) {
    super()
    this.isSubscribed = true
    this.isTrackEnabled = !muted
    this.kind = 'audio'
    this.publishPriority = 'standard'
    this.track = new RemoteAudioTrack({ name, sid, muted })
    this.trackName = name
    this.trackSid = sid
    this.events = {
      subscribed: [],
      subscriptionFailed: [],
      trackDisabled: [],
      trackEnabled: [],
      unsubscribed: [],
    }
  }
}

export class VideoTrack extends Track {
  isStarted: boolean

  isEnabled: boolean

  dimensions: { width: number | null; height: number | null }

  // @ts-expect-error TS(2564): Property 'mediaStreamTrack' has no initializer and... Remove this comment to see the full error message
  mediaStreamTrack: MediaStreamTrack

  constructor({
    name = '0444ff48-2f37-42bc-b39a-7b8367eb91bf',
    dimensions = { width: 960, height: 540 },
    videoOff = false,
  } = {}) {
    super({ kind: 'video', name })
    this.isStarted = true
    this.isEnabled = !videoOff
    this.dimensions = dimensions
    this.events = {
      dimensionsChanged: [],
      disabled: [],
      enabled: [],
      started: [],
    }
  }

  attach() {
    const participantVideo = document.createElement('video')
    participantVideo.setAttribute('data-test-id', 'exampleParticipantVideo')
    participantVideo.setAttribute('id', 'exampleParticipantVideo')
    return participantVideo
  }

  detach() {
    return [document.getElementById('exampleParticipantVideo')]
  }
}

export class RemoteVideoTrack extends VideoTrack {
  isSwitchedOff: boolean

  priority: string | null

  processedTrack: null

  // @ts-expect-error TS(2564): Property 'processor' has no initializer and is not... Remove this comment to see the full error message
  processor: null

  sid: string

  constructor({
    name = '0444ff48-2f37-42bc-b39a-7b8367eb91bf',
    sid = 'MT0befe6b0cce65a7907023b38b831155a',
    videoOff = false,
  } = {}) {
    super({ name, videoOff })
    this.isSwitchedOff = false
    this.priority = null
    this.processedTrack = null
    this.sid = sid
    this.events = {
      dimensionsChanged: [],
      disabled: [],
      enabled: [],
      started: [],
      switchedOff: [],
      switchedOn: [],
    }
  }

  videoOff() {
    this.isEnabled = false
    this.trigger('disabled', this)
  }

  videoOn() {
    this.isEnabled = true
    this.trigger('enabled', this)
  }
}

export class RemoteVideoTrackPublication extends EventEmitter {
  isSubscribed: boolean

  isTrackEnabled: boolean

  kind: string

  publishPriority: string

  track: RemoteVideoTrack

  trackName: string

  trackSid: string

  events: { [key: string]: { (...args: any[]): void }[] }

  constructor({
    name = '0444ff48-2f37-42bc-b39a-7b8367eb91bf',
    sid = 'MT0befe6b0cce65a7907023b38b831155a',
    videoOff = false,
  } = {}) {
    super()
    this.isSubscribed = true
    this.isTrackEnabled = !videoOff
    this.kind = 'video'
    this.publishPriority = 'standard'
    this.track = new RemoteVideoTrack({ name, sid, videoOff })
    this.trackName = name
    this.trackSid = sid
    this.events = {
      subscribed: [],
      subscriptionFailed: [],
      trackDisabled: [],
      trackEnabled: [],
      unsubscribed: [],
    }
  }
}

export class DataTrack extends Track {
  constructor() {
    super()
    this.kind = 'data'
  }
}

export class RemoteDataTrack extends Track {
  isEnabled: boolean

  isSwitchedOff: boolean

  maxPacketLifeTime: number | null

  maxRetransmits: number | null

  ordered: boolean

  priority: string

  reliable: boolean

  sid: string

  constructor({ name = '9917288c-cebd-490a-b570-7362811eac0e', sid = 'MTdcc021d6bb04a2463836dafc2bce58b6' } = {}) {
    super({ kind: 'data', name })
    this.events = {
      dimensionsChanged: [],
      disabled: [],
      enabled: [],
      message: [],
      started: [],
    }
    this.isEnabled = true
    this.isSwitchedOff = false
    this.maxPacketLifeTime = null
    this.maxRetransmits = null
    this.ordered = true
    this.priority = 'standard'
    this.reliable = true
    this.sid = sid
  }

  setPriority() {}
}

export class RemoteDataTrackPublication {
  isSubscribed: boolean

  isTrackEnabled: boolean

  kind: string

  publishPriority: string

  track: RemoteDataTrack | null

  trackName: string

  trackSid: string

  events: { [key: string]: { (...args: any[]): void }[] }

  constructor({ name = '9917288c-cebd-490a-b570-7362811eac0e', sid = 'MTdcc021d6bb04a2463836dafc2bce58b6' } = {}) {
    this.isSubscribed = true
    this.isTrackEnabled = true
    this.kind = 'data'
    this.publishPriority = 'standard'
    this.track = new RemoteDataTrack({ name, sid })
    this.trackName = name
    this.trackSid = sid
    this.events = {
      publishPriorityChanged: [],
      subscribed: [],
      subscriptionFailed: [],
      trackDisabled: [],
      trackEnabled: [],
      trackSwithcedOff: [],
      trackSwithcedOn: [],
      unsubscribed: [],
    }
  }
}

export class LocalParticipantMock extends EventEmitter {
  audioTracks: Map<string, AudioTrack>

  dataTracks: Map<string, DataTrack>

  videoTracks: Map<string, VideoTrack>

  tracks: Map<string, AudioTrack | DataTrack | VideoTrack>

  signalingRegion: string

  state: string

  sid: string

  networkQualityStats: Map<any, any> | null

  networkQualityLevel: number

  identity: string

  constructor() {
    super()
    this.events = {
      reconnected: [],
      reconnecting: [],
      trackDimensionsChanged: [],
      trackDisabled: [],
      trackEnabled: [],
      trackPublicationFailed: [],
      trackPublished: [],
      trackStarted: [],
      trackStopped: [],
      trackMessage: [],
      trackSubscribed: [],
      trackSubscriptionFailed: [],
      trackUnsubscribed: [],
      networkQualityLevelChanged: [],
    }
    this.audioTracks = new Map()
    this.dataTracks = new Map()
    this.videoTracks = new Map()
    this.tracks = new Map()
    this.signalingRegion = 'us2'
    this.state = 'connected'
    this.sid = 'PAb06a64a24724ab057f44f7ac0751bcdf'
    this.networkQualityStats = null
    this.networkQualityLevel = 3
    this.identity = 'dZVVeUd_dWrkCrmpvzxgumHTv7Oj6Fh-I6CRbFcrOTM='
  }

  publishTracks() {}

  publishTrack() {}

  unpublishTrack() {}
}

export class RemoteParticipantMock extends EventEmitter {
  audioTracks: Map<string, RemoteAudioTrackPublication>

  dataTracks: Map<string, RemoteDataTrackPublication>

  videoTracks: Map<string, RemoteVideoTrackPublication>

  tracks: Map<string, RemoteAudioTrackPublication | RemoteDataTrackPublication | RemoteVideoTrackPublication>

  signalingRegion: string

  state: string

  sid: string

  networkQualityStats: Map<any, any> | null

  networkQualityLevel: number

  identity: string

  constructor({ sid, state }: { sid?: string; state?: string }) {
    super()
    this.events = {
      reconnected: [],
      reconnecting: [],
      trackDimensionsChanged: [],
      trackDisabled: [],
      trackEnabled: [],
      trackPublicationFailed: [],
      trackPublished: [],
      trackStarted: [],
      trackStopped: [],
      trackMessage: [],
      trackSubscribed: [],
      trackSubscriptionFailed: [],
      trackUnsubscribed: [],
      networkQualityLevelChanged: [],
    }
    this.audioTracks = new Map()
    this.dataTracks = new Map()
    this.videoTracks = new Map()
    this.tracks = new Map()
    this.signalingRegion = 'us2'
    this.state = state ?? 'connected'
    this.sid = sid ?? 'FAb06a64a24724ab057f44f7ac0751bcdf'
    this.networkQualityStats = null
    this.networkQualityLevel = 4
    this.identity = 'aZVVeUd_dWrkCrmpvzxgumHTv7Oj6Fh-I6CRbFcrOTM='
  }

  subscribeTracks(tracks: TrackConstructor[]) {
    let trackPublication: RemoteAudioTrackPublication | RemoteVideoTrackPublication | RemoteDataTrackPublication
    tracks.forEach((track) => {
      switch (track.kind) {
        case 'audio':
          trackPublication = new RemoteAudioTrackPublication(track)
          this.audioTracks.set(track.sid, trackPublication)
          break
        case 'video':
          trackPublication = new RemoteVideoTrackPublication(track)
          this.videoTracks.set(track.sid, trackPublication)
          break
        case 'data':
          trackPublication = new RemoteDataTrackPublication(track)
          this.dataTracks.set(track.sid, trackPublication)
          break
      }
      this.tracks.set(track.sid, trackPublication)
      this.trigger('trackSubscribed', trackPublication.track)
    })
  }
}

export class RoomMock extends EventEmitter {
  // @ts-expect-error TS(2564): Property 'localParticipant' has no initializer and... Remove this comment to see the full error message
  localParticipant: LocalParticipantMock

  // @ts-expect-error TS(2564): Property 'dominantSpeaker' has no initializer and ... Remove this comment to see the full error message
  dominantSpeaker: RemoteParticipantMock

  // @ts-expect-error TS(2564): Property 'isRecording' has no initializer and is n... Remove this comment to see the full error message
  isRecording: boolean

  // @ts-expect-error TS(2564): Property 'mediaRegion' has no initializer and is n... Remove this comment to see the full error message
  mediaRegion: string

  // @ts-expect-error TS(2564): Property 'name' has no initializer and is not defi... Remove this comment to see the full error message
  name: string

  // @ts-expect-error TS(2564): Property 'sid' has no initializer and is not defin... Remove this comment to see the full error message
  sid: string

  // @ts-expect-error TS(2564): Property 'state' has no initializer and is not def... Remove this comment to see the full error message
  state: string

  // @ts-expect-error TS(2564): Property 'participants' has no initializer and is ... Remove this comment to see the full error message
  participants: Map<string, RemoteParticipantMock>

  constructor() {
    super()
    this.initializeProperties()
  }

  initializeProperties() {
    this.events = {
      disconnected: [],
      participantConnected: [],
      participantDisconnected: [],
      participantReconnected: [],
      participantReconnecting: [],
      reconnected: [],
      reconnecting: [],
      recordingStarted: [],
      recordingStopped: [],
      trackDimensionsChanged: [],
      trackDisabled: [],
      trackEnabled: [],
      trackMessage: [],
      trackPublished: [],
      trackPublishPriorityChanged: [],
      trackStarted: [],
      trackSubscribed: [],
      trackSwitchedOff: [],
      trackSwitchedOn: [],
      trackUnpublished: [],
      trackUnsubscribed: [],
    }
    this.localParticipant = new LocalParticipantMock()
    this.dominantSpeaker = new RemoteParticipantMock({})
    this.isRecording = false
    this.mediaRegion = 'us2'
    this.name = 'RM' + Math.random().toString().substring(2, 15)
    this.sid = Math.random().toString().substring(2, 15)
    this.state = 'connected'
    this.participants = new Map()
  }

  addParticipant(participant: Dict = {}) {
    const newParticipant = new RemoteParticipantMock(participant)
    this.participants.set(newParticipant.sid, newParticipant)
    this.trigger('participantConnected', newParticipant)
  }

  disconnect(error: { message: string }) {
    this.trigger('disconnected', this, error)
  }

  getStats() {
    return new Promise((resolve, reject) => {
      resolve([
        {
          sessionId: 'c2c7f26f-5226-25bd-9700-d63c7be11dd5',
          timestamp: 'Wed, 17 Mar 2021 01:37:24 GMT',
          url: '',
          sessionInfo: {
            id: '4f5e40bb-0a69-4af8-b4a8-9be9720c7add',
            s3_object_name: null,
            max_video_bitrate: 1966080,
            episode_id: 'bd763c70-d1f7-4a66-9696-3e64548164ec',
            status: 'created',
            patient_id: '20ea64d0-ccdd-4162-8891-a2203acbd8b3',
            recording_status: 'not_started',
            provider_id: '7ffad6a1-9640-1bcc-d58e-a2af2fa0cd94',
            preferred_audio_codecs: ['opus'],
            room_name: 'u5yPwCxKVAOY7CqWEzCCpdTjOql4GnaKAEuAhIF6CgI=',
            expiration_time: '2021-02-06T01:27:52+00:00',
            access_token:
              'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiIsImN0eSI6InR3aWxpby1mcGE7dj0xIn0.eyJqdGkiOiJTSzE4YzMxNGIyZTI3MjNhNzJkNDBjNjA4NTc5YmI1OWVlLTE2MTI1Njk0NzIiLCJncmFudHMiOnsidmlkZW8iOnsicm9vbSI6InU1eVB3Q3hLVkFPWTdDcVdFekNDcGRUak9xbDRHbmFLQUV1QWhJRjZDZ0k9In0sImlkZW50aXR5IjoiZFpWVmVVZF9kV3JrQ3JtcHZ6eGd1bUhUdjdPajZGaC1JNkNSYkZjck9UTT0ifSwiaXNzIjoiU0sxOGMzMTRiMmUyNzIzYTcyZDQwYzYwODU3OWJiNTllZSIsImV4cCI6MTYxMjU3MzA3MiwibmJmIjoxNjEyNTY5NDcyLCJzdWIiOiJBQzc1YmZkMzE0MmE4NWIzMjQyOTAyODJlMWFhNDI0YmMzIn0.CSA9SVQn1tVr6EeGXC3K1kr_s27-HgRb5e6T1nxHues',
            provider_patient_id: '69154039-0597-477e-976f-5a9065517997',
            provider: 'twilio',
            preferred_video_codecs: [
              {
                codec: 'H264',
                simulcast: false,
              },
              {
                codec: 'VP9',
                simulcast: false,
              },
              {
                codec: 'VP8',
                simulcast: false,
              },
            ],
            appointment_id: '55041',
            end_time: null,
            room_sid: 'RM18d0241116ceee23358198135f41f695',
            start_time: null,
            max_audio_bitrate: 131072,
          },
          localAudioTrackStats: [
            {
              trackId: 'b5028a01-c164-41b4-876c-37d3ea30b383',
              trackSid: 'MT22646c82cdcd42c43a7b2cef33df90cf',
              timestamp: 1615945043995,
              ssrc: '22848989',
              packetsLost: null,
              codec: 'opus',
              bytesSent: 23672238,
              packetsSent: 94839,
              roundTripTime: null,
              audioLevel: null,
              jitter: null,
              bytesSentPerSecond: 13374.7,
              packetsSentPerSecond: 50,
            },
          ],
          localVideoTrackStats: [
            {
              trackId: '34dec1b1-1b6f-4ede-bccc-5b982607d684',
              trackSid: 'MT5db7d60f1fe8251d4a36723807e96332',
              timestamp: 1615945043995,
              ssrc: '1432172788',
              packetsLost: null,
              codec: 'H264',
              bytesSent: 226347576,
              packetsSent: 220467,
              roundTripTime: null,
              captureDimensions: null,
              dimensions: {
                width: 1280,
                height: 720,
              },
              captureFrameRate: null,
              frameRate: 21,
              bytesSentPerSecond: 82646.95,
              packetsSentPerSecond: 85.41666666666667,
            },
          ],
          remoteAudioTrackStats: [
            {
              trackId: '92e67088-d66f-4195-a8dc-40bd0f5a23e4',
              trackSid: 'MTf8adebec39d30141013e3d5fcbd7e22c',
              timestamp: 1615945043995,
              ssrc: '369916806',
              packetsLost: 416,
              codec: 'opus',
              bytesReceived: 28423480,
              packetsReceived: 94333,
              audioLevel: 142962421,
              jitter: 6,
              bytesSentPerSecond: null,
              packetsSentPerSecond: null,
            },
          ],
          remoteVideoTrackStats: [
            {
              trackId: 'e36f1a8c-9a36-4840-ba30-4fcf64fe2f30',
              trackSid: 'MTf1e274dbc7394c45bc2c1565497aeb37',
              timestamp: 1615945043995,
              ssrc: '4102612183',
              packetsLost: 65,
              codec: 'H264',
              bytesReceived: 428255789,
              packetsReceived: 385114,
              dimensions: {
                width: 1280,
                height: 720,
              },
              frameRate: null,
              bytesSentPerSecond: null,
              packetsSentPerSecond: null,
            },
          ],
        },
      ])
    })
  }

  participantDisconnected(participant: { sid: string }) {
    this.trigger('participantDisconnected', this.participants.get(participant.sid))
    this.participants.delete(participant.sid)
  }

  reconnected() {
    this.trigger('reconnected')
  }

  reconnecting(error: { message: string }) {
    this.trigger('reconnecting', error)
  }

  resetRoom() {
    this.initializeProperties()
  }
}

const roomMock = new RoomMock()
const audioTrack = new AudioTrack()
const videoTrack = new VideoTrack()
const dataTrack = new DataTrack()

if (window.Cypress) {
  window.twilioRoomMock = roomMock
}

export class TwilioVideoMock {
  tracks: Map<string, AudioTrack | VideoTrack | DataTrack>

  videoTrack: VideoTrack

  audioTrack: AudioTrack

  dataTrack: DataTrack

  constructor() {
    this.videoTrack = videoTrack
    this.audioTrack = audioTrack
    this.dataTrack = dataTrack
    this.tracks = new Map([
      [this.audioTrack.name, this.audioTrack],
      [this.videoTrack.name, this.videoTrack],
      [this.dataTrack.name, this.dataTrack],
    ])
  }

  connect() {
    return Promise.resolve(roomMock)
  }

  createLocalTracks() {
    return require('twilio-video').createLocalTracks()
  }

  createLocalVideoTrack() {
    return require('twilio-video').createLocalVideoTrack()
  }

  createLocalAudioTrack() {
    return require('twilio-video').createLocalAudioTrack()
  }

  LocalVideoTrack() {
    return new VideoTrack()
  }

  LocalDataTrack() {
    return new DataTrack()
  }
}

type TrackConstructor = {
  kind: string
  name: string
  sid: string
}
