import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

type Props = {
  size?: number | string
}

export const WorkplaceWellbeingSpotIllustrationWhiteBorder: FunctionComponent<Props> = ({ size = 124 }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 513 512' fill='none'>
      <Path
        d='M257.823 1.91479C222.482 1.91479 188.193 7.97528 155.908 19.9243C79.0437 48.4156 25.0052 111.58 7.6433 193.22C-3.83015 246.449 -2.36309 295.682 12.1558 343.66C30.6914 404.932 76.1906 456.603 136.988 485.418C167.169 499.755 201.842 507.94 240.026 509.741C244.933 509.964 249.921 510.085 254.879 510.085C285.515 510.085 316.334 505.886 346.484 497.61C348.801 497.033 351.007 496.437 353.334 495.749C380.429 487.816 404.813 475.27 425.675 458.505C448.329 440.405 466.976 417.458 481.039 390.343C483.811 385 486.462 379.375 489.386 372.647C505.473 335.292 513.244 293.476 511.868 251.67C511.22 233.185 508.468 213.536 503.713 193.22C495.882 160.256 483.113 129.256 466.783 103.517C462.311 96.6061 458.204 90.7986 454.136 85.5779C424.785 47.7175 379.053 21.1485 321.929 8.7847C300.945 4.23174 279.374 1.92491 257.833 1.92491L257.823 1.91479Z'
        fill='white'
      />
      <Path
        d='M489.904 252.357C489.277 234.641 486.565 216.288 482.295 198.046C475.314 168.654 463.841 139.677 448.108 114.868C444.455 109.243 440.712 103.82 436.645 98.6093C407.141 60.5668 362.431 39.4006 316.669 29.4954C265.696 18.4469 211.496 21.6745 162.496 39.8155C91.6115 66.0809 43.3502 123.62 27.6071 197.631C21.5567 225.667 19.7861 257.356 22.4977 287.8H22.2852C23.8534 305.101 26.9798 321.987 31.6643 337.518C49.0768 395.057 91.3889 440.718 145.286 466.255C174.475 480.117 207.095 486.997 240.25 488.565C274.124 490.133 308.524 485.853 340.324 477.101C342.408 476.585 344.392 476.059 346.476 475.432C371.385 468.137 393.068 456.876 411.623 441.973C431.849 425.815 448.523 405.489 461.453 380.579C464.266 375.156 466.765 369.743 469.163 364.208C484.38 328.867 491.159 290.511 489.803 252.469L489.904 252.368V252.357Z'
        fill='#FFE9E3'
      />
      <Path
        d='M172.033 204.003C172.033 204.003 122.103 198.563 108.993 214.713C91.2726 236.553 95.3926 264.193 108.993 278.593C122.593 292.993 154.723 325.543 181.913 324.303C209.103 323.063 181.913 291.753 181.913 291.753C181.913 291.753 167.493 292.163 155.953 285.983C144.413 279.803 114.753 259.203 118.873 241.073C122.993 222.943 149.773 219.233 170.783 243.133C191.793 267.033 172.023 203.993 172.023 203.993L172.033 204.003Z'
        fill='#85CCD5'
      />
      <Path
        d='M172.772 155.703C172.772 155.703 151.962 161.133 157.162 189.943C162.362 218.743 167.962 353.873 185.642 375.683C203.322 397.493 254.412 392.613 265.212 392.613C276.012 392.613 328.082 392.043 337.912 373.863C346.252 358.433 365.202 228.773 368.342 185.593C368.982 176.853 360.972 162.203 350.202 157.793C332.122 150.383 312.372 147.533 262.562 147.773C197.332 148.083 172.772 155.703 172.772 155.703Z'
        fill='#85CCD5'
      />
      <Path
        d='M338.142 174.273C327.062 176.343 315.902 177.993 304.702 179.173C265.462 183.303 225.952 181.733 186.962 175.383C185.742 175.183 184.862 174.043 184.442 172.723C182.932 170.163 183.952 165.483 187.512 165.303C198.442 164.783 209.382 164.373 220.332 164.073C259.262 163.003 298.212 163.433 337.122 165.343C341.292 165.543 342.652 173.423 338.152 174.263L338.142 174.273Z'
        fill='#068298'
      />
    </Svg>
  )
}
