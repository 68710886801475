import React, { Component, LegacyRef } from 'react'
import classNames from 'classnames'
import { injectIntl, IntlShape } from 'react-intl'
import DetectRTC from 'detectrtc'
import { LocalVideoTrack } from 'twilio-video'

import { IO_DEVICES } from '@lyrahealth-inc/shared-app-logic'

import VideoIcon from '../../atoms/icons/VideoIcon'
import MicrophoneIcon from '../../atoms/icons/MicrophoneIcon'
import SpeakerIcon from '../../atoms/icons/SpeakerIcon'
import DefaultButton from '../../atoms/buttons/defaultButton/DefaultButton'
import DropdownButton from '../../atoms/buttons/dropdownButton/DropdownButton'
import styles from './videoSessionSettings.module.scss'

import TwilioVideo from '../../utils/importTwilioVideo'
const { createLocalVideoTrack } = TwilioVideo

type VideoSessionSettingsProps = {
  saveSettingsFunction: (state: object) => void
  settingsOptions: { videoInputOptions: string[]; audioInputOptions: string[]; audioOutputOptions: string[] }
  currentSettings: { videoInput: string; audioInput: string; audioOutput: string }
  deviceConstraints: { video: any }
  intl: IntlShape
}

type VideoSessionState = {
  videoInput?: string
  audioInput?: string
  audioOutput?: string
}
class VideoSessionSettings extends Component<VideoSessionSettingsProps, VideoSessionState> {
  _videoTrack: LocalVideoTrack | null = null

  videoDevicePreviewContainer: LegacyRef<HTMLDivElement> | undefined

  constructor(props: VideoSessionSettingsProps) {
    super(props)
    this.state = props.currentSettings // { videoInput: key, audioInput: key, audioOutput: key }
    this.videoDevicePreviewContainer = React.createRef()
  }

  componentDidMount() {
    DetectRTC.load(() => {
      this._renderVideoPreview(this.state.videoInput as string)
    })
  }

  componentWillUnmount() {
    if (this._videoTrack) {
      const vidTrack = this._videoTrack as LocalVideoTrack
      vidTrack
        .stop()
        .detach()
        .forEach((element: HTMLElement) => element.remove())
    }
  }

  componentDidUpdate(prevProps: VideoSessionSettingsProps, prevState: VideoSessionState) {
    if (this.state.videoInput !== prevState.videoInput) {
      this._renderVideoPreview(this.state.videoInput as string)
    }
  }

  _updateConfiguration = (update: VideoSessionState) => {
    this.setState(update)
  }

  _renderVideoPreview = (deviceId: string) => {
    const options = Object.assign({}, this.props.deviceConstraints.video, { deviceId: { exact: deviceId } })

    // pull off default to allow others to be chosen
    if (options.facingMode) {
      delete options.facingMode
    }

    // create new video track for specific device ID and default constraints
    createLocalVideoTrack(options).then((localVideoTrack: LocalVideoTrack) => {
      if (this._videoTrack) {
        // if a preview is already visible, stop/remove it
        const vidTrack = this._videoTrack as LocalVideoTrack
        vidTrack
          .stop()
          .detach()
          .forEach((element: HTMLElement) => element.remove())
      }
      this._videoTrack = localVideoTrack // save new video track
      if (this.videoDevicePreviewContainer) {
        const divElement = this.videoDevicePreviewContainer as unknown as HTMLDivElement
        divElement.appendChild && divElement.appendChild(localVideoTrack.attach()) // inject to DOM
      }
    })
  }

  render() {
    const { settingsOptions, intl } = this.props

    return (
      <div className={styles['settings-modal-inner']}>
        <h2>Settings</h2>

        <div className={styles['setting-options-container']}>
          <div className={styles['selectors-container']}>
            <div className={styles.selector}>
              Camera
              <DropdownButton
                activeIndex={Object.keys(settingsOptions.videoInputOptions).findIndex(
                  (item) => item === this.state.videoInput,
                )}
                pullLeft
                styling='primary-line'
                id='VideoSessionSettings-cameraOptions'
                ariaLabel={intl.formatMessage({
                  defaultMessage: 'Camera Options',
                  description: 'Button for the camera options during video sessions',
                })}
                dropdownItems={Object.keys(settingsOptions.videoInputOptions).map((key) => {
                  return {
                    text: settingsOptions.videoInputOptions[key],
                    id: key,
                    selectHandler: () => {
                      this._updateConfiguration({ videoInput: key })
                      localStorage.setItem(IO_DEVICES.CAMERA, key)
                    },
                  }
                })}
              >
                <VideoIcon isFilled width={20} fillColor='currentColor' />
                <span className={styles['selector-text']} data-test-id='VideoSessionSettings-currentCamera'>
                  {settingsOptions.videoInputOptions[this.state.videoInput as string]}
                </span>
              </DropdownButton>
            </div>

            <div className={styles.selector}>
              Microphone
              <DropdownButton
                activeIndex={Object.keys(settingsOptions.audioInputOptions).findIndex(
                  (item) => item === this.state.audioInput,
                )}
                pullLeft
                styling='primary-line'
                id='VideoSessionSettings-microphoneOptions'
                ariaLabel={intl.formatMessage({
                  defaultMessage: 'Microphone Options',
                  description: 'Button for the microphone options during video sessions',
                })}
                dropdownItems={Object.keys(settingsOptions.audioInputOptions).map((key) => {
                  return {
                    text: settingsOptions.audioInputOptions[key],
                    id: key,
                    selectHandler: () => {
                      this._updateConfiguration({ audioInput: key })
                      localStorage.setItem(IO_DEVICES.MICROPHONE, key)
                    },
                  }
                })}
              >
                <MicrophoneIcon isFilled width={20} fillColor='currentColor' />
                <span className={styles['selector-text']} data-test-id='VideoSessionSettings-currentMicrophone'>
                  {settingsOptions.audioInputOptions[this.state.audioInput as string]}
                </span>
              </DropdownButton>
            </div>

            <div className={styles.selector}>
              Speaker
              <DropdownButton
                activeIndex={Object.keys(settingsOptions.audioOutputOptions).findIndex(
                  (item) => item === this.state.audioOutput,
                )}
                pullLeft
                styling='primary-line'
                id='VideoSessionSettings-speakerOptions'
                ariaLabel={intl.formatMessage({
                  defaultMessage: 'Speaker Options',
                  description: 'Button for the speaker options during video sessions',
                })}
                dropdownItems={Object.keys(settingsOptions.audioOutputOptions).map((key) => {
                  return {
                    text: settingsOptions.audioOutputOptions[key],
                    id: key,
                    selectHandler: () => {
                      this._updateConfiguration({ audioOutput: key })
                      localStorage.setItem(IO_DEVICES.SPEAKER, key)
                    },
                  }
                })}
              >
                <SpeakerIcon isFilled width={20} fillColor='currentColor' />
                <span className={styles['selector-text']} data-test-id='VideoSessionSettings-currentSpeaker'>
                  {settingsOptions.audioOutputOptions[this.state.audioOutput as string]}
                </span>
              </DropdownButton>
            </div>
          </div>
          <div
            ref={this.videoDevicePreviewContainer}
            className={classNames(styles['preview-container'], { [styles.mobile]: DetectRTC.isMobileDevice })}
          />
        </div>

        <DefaultButton
          className={styles['save-settings']}
          onClick={() => this.props.saveSettingsFunction(this.state)}
          data-test-id='VideoSessionSettings-saveButton'
        >
          Save
        </DefaultButton>
      </div>
    )
  }
}

export default injectIntl(VideoSessionSettings)
