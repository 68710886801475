import React, { FunctionComponent } from 'react'

import styled from 'styled-components/native'

import {
  ASSETS_CDN_URL,
  TREATMENT_REC_PRIMARY_CARD_CDN_PREFIX as namespace,
  PRIMARY_TREATMENT_REC_IMAGE_CONTAINER_DIMENSIONS,
} from '../../constants'
import { tID } from '../../utils'
import { Image } from '../image/Image'

const ImageSizer = styled(Image)<{ height: string; width: string }>(({ height, width }) => ({
  width,
  height,
}))

const Container = styled.View<{ viewport: 'DESKTOP' | 'TABLET' | 'MOBILE' }>(({ viewport }) => ({
  ...PRIMARY_TREATMENT_REC_IMAGE_CONTAINER_DIMENSIONS[viewport],
  justifyContent: 'flex-end',
  overflow: 'hidden',
}))

const IMAGE_SIZES = {
  DESKTOP: {
    height: '321px',
    width: '572px',
  },
  TABLET: {
    height: '282px',
    width: '513px',
  },
  MOBILE: {
    height: '294px',
    width: '513px',
  },
}

const IMAGE_POSITIONS = {
  DESKTOP: {
    top: 90,
    right: 105,
  },
  TABLET: {
    top: 76,
    right: 105,
  },
  MOBILE: {
    top: 100,
    right: 140,
  },
}

/**
 * Illustration to show on the treatment card for Coaching
 */
export const CoachingRec: FunctionComponent<CoachingRecProps> = ({ viewport = 'DESKTOP' }) => {
  return (
    <Container viewport={viewport}>
      <ImageSizer
        {...IMAGE_SIZES[viewport]}
        testID={`${tID('CoachingRecIllustration')}`}
        source={{ uri: ASSETS_CDN_URL + namespace + 'coaching.png' }}
        contentPosition={IMAGE_POSITIONS[viewport]}
        contentFit='contain'
      />
    </Container>
  )
}

export type CoachingRecProps = {
  viewport?: 'DESKTOP' | 'TABLET' | 'MOBILE'
}
