import React, { FunctionComponent } from 'react'
import { ViewStyle } from 'react-native'

import styled, { useTheme } from 'styled-components/native'

import { ThemeColors } from '@lyrahealth-inc/shared-app-logic'

import { BodyText } from '../../atoms/bodyText/BodyText'
import { BodyTextSize } from '../../styles'

export enum NoticeType {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

const getBackgroundColor = (key: NoticeType, colors: ThemeColors) => {
  const BackgroundColorMap: { [key in NoticeType]: string } = {
    [NoticeType.SUCCESS]: colors.backgroundSuccess,
    [NoticeType.ERROR]: colors.backgroundError,
  }

  return BackgroundColorMap[key]
}

const Container = styled.View<{ backgroundColor: string }>(({ backgroundColor, theme }) => ({
  borderRadius: 4,
  backgroundColor,
  padding: theme.spacing['8px'],
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing['4px'],
}))

const IconContainer = styled.View({
  alignSelf: 'flex-start',
  flexShrink: 0,
})

type NoticeProps = {
  type: NoticeType
  text: React.ReactNode
  icon?: React.ReactNode
  lineHeight?: number
  style?: ViewStyle
}

export const Notice: FunctionComponent<NoticeProps> = ({ type, icon, text, lineHeight = 12, style }) => {
  const { colors } = useTheme()

  return (
    <Container backgroundColor={getBackgroundColor(type, colors)} style={style}>
      {Boolean(icon) && <IconContainer>{icon}</IconContainer>}
      <BodyText style={{ lineHeight }} size={BodyTextSize.CAPTION} text={text} />
    </Container>
  )
}
