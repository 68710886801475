import React, { useState, useEffect, ReactElement, ReactNode } from 'react'
import Modal from 'react-bootstrap/lib/Modal'
import { Sizes } from 'react-bootstrap'
import XIcon from '../../atoms/icons/XIcon'
import styles from './baseModal.module.scss'
import DefaultButtton from '../../atoms/buttons/defaultButton/DefaultButton'
import { createFocusTrap, FocusTrap } from 'focus-trap'
import { noop, uniqueId } from 'lodash-es'

const BaseModal: React.FC<BaseModalProps> = ({
  modalClass = '',
  isOpen = false,
  blurable = true,
  closeModal = noop,
  size,
  showCloseButton = true,
  title,
  body,
  footer,
  customStyles,
  keyboard = false,
  fullScreenModal = false,
  onExited,
  ...props
}) => {
  let bsClass

  // customStyles is primarily for Storybook, but could be leveraged within a project to futher separate concerns
  if (customStyles) {
    bsClass = `${customStyles[modalClass]} modal`
  } else {
    bsClass = modalClass ? `${styles[modalClass]} modal` : 'modal'
  }

  const [id] = useState(() => uniqueId('basemodal-'))
  const [focusTrap, setFocusTrap] = useState<FocusTrap | null>(null)

  useEffect(() => {
    return () => {
      focusTrap && focusTrap.deactivate()
    }
  }, [focusTrap])

  // Before the modal starts to transition in
  const onEnter = () => {
    !focusTrap && setFocusTrap(createFocusTrap(`#${id}`, { fallbackFocus: `#${id}` }))
  }

  // When modal finishes transitioning in
  const onEntered = () => {
    focusTrap && focusTrap.activate()
  }

  // Before the modal starts to transition out
  const onExiting = () => {
    focusTrap && focusTrap.deactivate()
  }

  // When modal finishes transitioning out
  const onExitedDefault = () => {
    focusTrap && setFocusTrap(null)
  }

  return (
    <Modal
      show={isOpen}
      backdrop={blurable ? true : 'static'}
      onHide={closeModal}
      dialogClassName={fullScreenModal ? 'fullscreen-dialog' : ''}
      bsSize={size}
      bsClass={bsClass}
      keyboard={keyboard}
      onEntered={onEntered}
      onEnter={onEnter}
      onExiting={onExiting}
      onExited={() => {
        onExitedDefault()
        onExited && onExited()
      }}
      tabIndex={-1}
      {...props}
      id={id}
    >
      {/*
        ommitting <Modal.Header> for now, as it appears from current usage that titles are best rendered as part of the content body
        can always add it in later as needed, with title strings being passed in as a prop
      */}
      {title && (
        <Modal.Header role='heading' aria-level={1}>
          {title}
        </Modal.Header>
      )}

      {closeModal && showCloseButton && (
        <DefaultButtton
          unstyled
          onClick={closeModal}
          aria-label='close modal'
          data-test-id='BaseModal-close-btn'
          customClass='close-btn'
        >
          <XIcon width={16} isFilled fillColor='currentColor' />
        </DefaultButtton>
      )}

      <Modal.Body>
        {/*
          Using cloneElement allows us to pass the closeModal handler to the body component
          so that the close function doesn't have to be duplicated. Example, having a cancel button
          in the modal.
        */}
        {typeof body === 'string' ? body : React.cloneElement(body, { closeModal })}
      </Modal.Body>

      {footer && <Modal.Footer>{footer}</Modal.Footer>}
    </Modal>
  )
}

type BaseModalProps = {
  closeModal?: () => void
  isOpen: boolean
  body: string | ReactElement
  title?: string
  footer?: string | ReactNode
  showCloseButton?: boolean
  size?: Sizes
  blurable?: boolean
  modalClass?: string
  customStyles?: Dict
  keyboard?: boolean
  fullScreenModal?: boolean
  onExited?: () => void
  dialogClassName?: string
}

export default BaseModal
