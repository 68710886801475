// Treatment options as returned from triage.
// keys = our common terminology we use in discussions
// values = the keys used in triage to represent each treatment option
export const TREATMENT_OPTIONS_FROM_TRIAGE = {
  COACHING: 'stressManagement',
  LIVE_MESSAGING_COACHING: 'stressManagementLM',
  GUIDED_SELF_CARE: 'singleSessionCoaching',
  DIRECT_ACCESS_MEDS: 'medsSpecialistMD',
  BLENDED_CARE_MEDS: 'lyraMedManagement',
  INDIVIDUAL_THERAPY: 'individualTherapy',
  INDIVIDUAL_THERAPY_ICAS_PHONE: 'individualTherapyICASPhone',
  BLENDED_CARE_THERAPY: 'blendedCareTherapy',
  FAMILY_THERAPY: 'familyTherapy',
  COUPLES_THERAPY: 'couplesTherapy',
  SELF_CARE_APPS: 'selfCareApps',
  FAMILY_THERAPY_CHILD: 'familyTherapyChild',
  INDIVIDUAL_THERAPY_CHILD: 'individualTherapyChild',
  DIRECT_ACCESS_MEDS_CHILD: 'medsSpecialistMDChild',
  ALCOHOL_USE_DISORDER: 'alcoholUseDisorderTherapy',
  CLINICAL_LEAVE_EVALUATION: 'clinicalLeaveEvaluation',
}

const MODALITIES_TO_TREATMENTS = {
  alcoholusedisordertherapy: {
    display: 'Lyra Renew',
    formValue: TREATMENT_OPTIONS_FROM_TRIAGE.ALCOHOL_USE_DISORDER,
  },
  clinicalleaveevaluation: {
    display: 'Clinical Leave Evaluation',
    formValue: TREATMENT_OPTIONS_FROM_TRIAGE.CLINICAL_LEAVE_EVALUATION,
  },
  couples: {
    display: 'Couples Therapy',
    formValue: TREATMENT_OPTIONS_FROM_TRIAGE.COUPLES_THERAPY,
  },
  family: {
    display: 'Family Therapy',
    formValue: TREATMENT_OPTIONS_FROM_TRIAGE.FAMILY_THERAPY,
  },
  individual: {
    display: 'Individual Therapy',
    formValue: TREATMENT_OPTIONS_FROM_TRIAGE.INDIVIDUAL_THERAPY,
  },
  lyramedmanagement: {
    display: 'Medication Consultation',
    formValue: TREATMENT_OPTIONS_FROM_TRIAGE.BLENDED_CARE_MEDS,
  },
  lyratherapy: {
    display: 'Individual Therapy',
    formValue: TREATMENT_OPTIONS_FROM_TRIAGE.INDIVIDUAL_THERAPY,
  },
  stressmanagement: {
    display: 'Coaching',
    formValue: TREATMENT_OPTIONS_FROM_TRIAGE.COACHING,
  },
  singlesessioncoaching: {
    display: 'Guided Self-Care',
    formValue: TREATMENT_OPTIONS_FROM_TRIAGE.GUIDED_SELF_CARE,
  },
  'medication management': {
    display: 'Medication Consult',
    formValue: TREATMENT_OPTIONS_FROM_TRIAGE.DIRECT_ACCESS_MEDS,
  },
}

// Given a provider's treatment modality such as 'individual,' 'lyraTherapy,' or 'singleSessionCoaching,'
// return the corresponding treatment option
export const convertProviderTreatmentModalityToTreatmentOption = (modality: $TSFixMe) => {
  return MODALITIES_TO_TREATMENTS[modality.toLowerCase()]?.formValue
}

/**
 * Maps provider "modalities" to valid treatment options.
 *
 * In a provider object, the "modalities" attribute somewhat describes the groups of people to which the provider
 * supplies therapy. This array consists of the values ["couples", "family", "group", "individual",
 * "lyraTherapy", "stressManagement"].
 *
 * The mapping is required because:
 * * Group therapy is not a valid treatment option.
 * * There is no complete attribute that accurately describes all of the treatment options a provider can provide,
 *   so this attribute is our best option.
 *
 * @param modalities
 * @returns {*}
 */
export const convertProviderTreatmentModalitiesToDisplayedTreatmentOptions = (modalities: $TSFixMe) => {
  return modalities
    .filter((modality: $TSFixMe) => MODALITIES_TO_TREATMENTS[modality.toLowerCase()])
    .map((modality: $TSFixMe) => MODALITIES_TO_TREATMENTS[modality.toLowerCase()])
}

export default {
  TREATMENT_OPTIONS_FROM_TRIAGE,
  MODALITIES_TO_TREATMENTS,
  convertProviderTreatmentModalityToTreatmentOption,
  convertProviderTreatmentModalitiesToDisplayedTreatmentOptions,
}
