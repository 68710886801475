import React, { useEffect, useRef, useState } from 'react'
import ReactMarkdown from 'react-markdown'

import { get, isEmpty, isNil, noop } from 'lodash-es'
import { DeepPartial } from 'redux'

import { Assignment, AssignmentResponse, Content, Provider } from '@lyrahealth-inc/shared-app-logic'

import styles from './worksheet.module.scss'
import DefaultButton from '../../atoms/buttons/defaultButton/DefaultButton'
import MenuDotsIcon from '../../atoms/icons/MenuDotsIcon'
import NavigationMenu from '../../atoms/navigationMenu/NavigationMenu'
import BaseModal from '../../molecules/modal/BaseModal'
import { ItemAction } from '../assignmentsList/AssignmentsList'
import LtIcon from '../assignmentsList/LtIcon'
import ExerciseResponseTable from '../exerciseResponseTable/ExerciseResponseTable'
import { LinkRenderer } from '../formBody/fields'
import FormContainer from '../formContainer/FormContainer'
import FormContentHeader from '../formContentHeader/FormContentHeader'
import FullFormContentHeader from '../fullFormContentHeader/FullFormContentHeader'
import ReportList from '../reportList/ReportList'
import { UIMetadata } from '../UIMetadata'

type OwnProps = {
  itemActions: {
    name: string
    selectHander: (
      e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
      actionName: string,
      assignment: Assignment,
      sessionCount: number,
    ) => void
  }[]
  sessionCount: number
  content: Assignment | Content
  data: AssignmentResponse[]
  readOnly: boolean
  submitForm?: ($$values: object, status: string, responseId: string) => void
  submitComment: (inputValue: string, response: AssignmentResponse) => Promise<any>
  fetchComment: ({ messageId }: { messageId: string }) => Promise<any>
  responseId: string
  clearResponseId: () => void
  preview?: boolean
  receiverName: string
  userRole: 'provider' | 'client'
  track: ({
    event,
    action,
    details,
    page,
    properties,
  }: {
    event: string
    action?: string
    details?: string | { type: string; title: string }
    page?: string
    properties?: Dict
  }) => void
  supervisorMode: boolean
  updateResponse: (data: DeepPartial<AssignmentResponse>) => void
  deleteResponse?: ({ responseId, assignmentId }: { responseId: string; assignmentId: string }) => Promise<any>
  providers?: Provider[]
  fullScreenModal?: boolean
  onAddClick?: () => void
  onResponseClick?: (status: string) => void
  providerFullName: string
}

Worksheet.defaultProps = {
  itemActions: [],
  data: [],
  providers: [],
}

type Props = OwnProps & typeof Worksheet.defaultProps

function Worksheet({
  fullScreenModal = false,
  itemActions,
  sessionCount,
  content,
  data,
  readOnly,
  submitForm,
  submitComment,
  fetchComment,
  responseId,
  clearResponseId,
  preview,
  receiverName,
  userRole,
  track,
  supervisorMode,
  updateResponse,
  deleteResponse,
  providers,
  onAddClick = noop,
  onResponseClick = noop,
  providerFullName,
}: Props) {
  // We do a fallback because 'content' can have 2 different formats: it can be a Content object or an Assignment object.
  const metaData = 'meta_data' in content ? content.meta_data : get(content, 'content_meta_data')
  const group = content.group || get(content, 'content.group')
  const name = content.name || get(content, 'content.name') || ''
  const title = content.title || get(content, 'content.title')
  const instructions = content.instructions || get(content, 'content.instructions') || ''
  const uiMetaData = UIMetadata[name]
  const addButtonRef = useRef<HTMLElement>(null)

  const [responseIdx, setResponseIdx] = useState<number>()
  const [modalOpen, setmodalOpen] = useState(false)
  const [selectedResponse, setSelectedResponse] = useState<AssignmentResponse>()

  const dataStringified = JSON.stringify(data)
  useEffect(() => {
    const data = JSON.parse(dataStringified)
    setSelectedResponse(get(data, responseIdx || 0))
  }, [responseIdx, dataStringified])

  useEffect(() => {
    if (!isEmpty(data)) {
      const responseIdx = data.findIndex((response) => response.id === responseId)
      if (responseIdx !== -1) {
        setResponseIdx(responseIdx)
        setmodalOpen(true)
      }
    }
  }, [data, responseId])

  const getReportComponent = (name: string) => {
    switch (name) {
      case 'checkIn':
      case 'coachingCheckIn':
      case 'medsCheckIn':
      case 'bctCheckIn':
      case 'bcmCheckIn':
      case 'bccCheckIn':
      case 'baseSleepDiary':
        return ExerciseResponseTable
      default:
        return ReportList
    }
  }

  const rowClickHandler = (responseData: AssignmentResponse) => {
    const responseIdx = data.findIndex((response) => response.id === responseData.id)
    if (responseIdx === -1) {
      return
    }
    onResponseClick(responseData.status)
    setResponseIdx(responseIdx)
    setSelectedResponse(data[responseIdx])
    setmodalOpen(true)
  }

  const handleAddClick = () => {
    onAddClick()
    setResponseIdx(undefined)
    setmodalOpen(true)
  }

  const showPrevious = () => {
    track({ event: 'BUTTON_PRESS', action: 'Next/Previous Entry', page: 'Worksheet Entry' })
    if (data.length === 0) return
    if (responseIdx === 0) return
    setResponseIdx((responseIdx || 0) - 1)
  }

  const showNext = () => {
    track({ event: 'BUTTON_PRESS', action: 'Next/Previous Entry', page: 'Worksheet Entry' })
    if (data.length === 0) return
    if (responseIdx && responseIdx >= data.length - 1) return
    setResponseIdx((responseIdx || 0) + 1)
  }

  const closeModal = () => {
    addButtonRef.current && addButtonRef.current.focus()
    clearResponseId()
    setResponseIdx(undefined)
    setmodalOpen(false)
  }
  const submitResponse = async ($$values: object, status: string, responseId: string) => {
    submitForm && (await submitForm($$values, status, responseId))
    if (status === 'completed') {
      closeModal()
    }
  }
  const deleteAndCloseResponse = (responseId: string, assignmentId: string) => {
    deleteResponse && deleteResponse({ responseId, assignmentId }).finally(closeModal)
  }

  const renderResponse = () => {
    const isCompleted = get(selectedResponse, 'status') === 'completed'

    return (
      <FormContainer
        readOnly={isCompleted}
        content={content}
        responseIdx={responseIdx}
        dataLength={!isEmpty(data) ? data.length : undefined}
        showPrevious={showPrevious}
        showNext={showNext}
        response={selectedResponse}
        fetchComment={fetchComment}
        receiverName={receiverName}
        userRole={userRole}
        supervisorMode={supervisorMode}
        submitComment={submitComment}
        updateResponse={updateResponse}
        deleteResponse={deleteAndCloseResponse}
        submitForm={submitResponse}
      />
    )
  }

  const renderForm = () => {
    if (readOnly) return <div />

    return (
      <FormContainer
        Header={fullScreenModal ? FullFormContentHeader : FormContentHeader}
        fullScreen={fullScreenModal}
        preview={preview}
        submitForm={submitResponse}
        content={content}
        userRole={userRole}
        updateResponse={updateResponse}
        deleteResponse={deleteAndCloseResponse}
        setModalOpen={setmodalOpen}
      />
    )
  }

  if (!content || isEmpty(content)) {
    return <></>
  }

  const Report = getReportComponent(name)
  return (
    <div className={styles.container} data-test-id='Woksheet-report'>
      <div>
        <div className={styles['header-button']}>
          <div className={styles.title}>
            <LtIcon type={group} size={24} />
            <h3>{title}</h3>
          </div>
          {userRole === 'provider' ? (
            <NavigationMenu customIcon={<MenuDotsIcon />} alignment='right' menuType=''>
              {itemActions.map((action: ItemAction) => (
                <li key={action.name}>
                  <a
                    href='#0'
                    data-test-id={`Worksheet-dropdownAction-${action.name}`}
                    onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
                      action.selectHandler(e, action.name, content, sessionCount)
                    }
                  >
                    {action.text}
                  </a>
                </li>
              ))}
            </NavigationMenu>
          ) : readOnly ? (
            ''
          ) : (
            <span ref={addButtonRef}>
              <DefaultButton
                data-test-id='ContentContainer-addEntryButton'
                customClass={styles['add-button']}
                onClick={handleAddClick}
              >
                Add
              </DefaultButton>
            </span>
          )}
        </div>
        <p className={styles.description}>
          {/* eslint-disable-next-line react/no-children-prop */}
          <ReactMarkdown children={instructions} linkTarget={'_blank_'} components={{ link: LinkRenderer }} />
        </p>
        <Report
          userRole={userRole}
          metaData={metaData}
          data={data}
          // @ts-expect-error TS(2322): Type '(responseData: $TSFixMe) => void' is not ass... Remove this comment to see the full error message
          rowClickHandler={rowClickHandler}
          UIMetadata={uiMetaData}
          showProvider={providers.length > 1 && 'past' in content && content.past}
          readOnly={readOnly}
          name={name}
          providerFullName={providerFullName}
        />
      </div>
      <BaseModal
        showCloseButton={!fullScreenModal}
        isOpen={modalOpen}
        body={isNil(responseIdx) ? renderForm() : renderResponse()}
        closeModal={closeModal}
        fullScreenModal={fullScreenModal}
      />
    </div>
  )
}

export default Worksheet
