import React, { FunctionComponent } from 'react'

import LCTReminderBannerIllustrationImage from '../../assets/LCTTeenCareBanner.png'
import { Image } from '../image/Image'

interface LCTReminderBannerIllustrationProps {
  width?: number
  height?: number
}

/**
 * Illustration to show on the LCT Reminder Banner
 */
export const LCTReminderBannerIllustration: FunctionComponent<LCTReminderBannerIllustrationProps> = ({
  width = 88,
  height = 75,
}) => {
  return (
    <Image style={{ width, height }} accessibilityIgnoresInvertColors source={LCTReminderBannerIllustrationImage} />
  )
}
