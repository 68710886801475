import React from 'react'
import type { FunctionComponent } from 'react'
import { View } from 'react-native'

import { tID } from '../../utils'
import { Image } from '../image/Image'

type TimeoutIllustrationProps = {
  width?: number
  height?: number
  useSmall?: boolean
}

export const TimeoutIllustration: FunctionComponent<TimeoutIllustrationProps> = ({
  width = 301,
  height = 272,
  useSmall = false,
}) => {
  const size = useSmall ? { width: 232, height: 210 } : { width, height }
  return (
    <View>
      {/* eslint-disable-next-line react-native-a11y/has-valid-accessibility-ignores-invert-colors */}
      <Image
        source={require('../../assets/timeout.png')}
        testID={`${tID('TimeoutIllustration')}`}
        accessibilityLabel={'timeout'}
        style={size}
      />
    </View>
  )
}
