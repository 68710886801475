import React, { FunctionComponent, useCallback, useRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import BottomSheetGorhom from '@gorhom/bottom-sheet'
import styledWeb from 'styled-components'
import styled, { useTheme } from 'styled-components/native'

import {
  BodyText,
  ExternalLinkIcon,
  PressableOpacity,
  PrimaryButton,
  Subhead,
  TertiaryButton,
  XIcon,
} from '../../atoms'
import { useZoomPermissionsInstructions } from '../../hooks/useZoom/useZoomPermissionsInstructions'
import { Modal } from '../../organisms'
import { getFontStyles, SubheadSize } from '../../styles'
import { ThemeType, tID } from '../../utils'

const HeadingAndCloseButtonContainer = styled.View({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
})

const ButtonContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: 'row',
  flexWrap: 'wrap-reverse',
  justifyContent: 'flex-end',
  gap: theme.spacing['16px'],
}))

const Container = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  gap: theme.spacing['16px'],
  margin: theme.breakpoints.isMobileSized ? theme.spacing['16px'] : '0px',
}))

const InnnerContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  gap: theme.spacing['16px'],
}))

const CancelButton = styled(TertiaryButton)<{ theme: ThemeType }>(({ theme }) => ({
  width: theme.breakpoints.isMobileSized ? '100%' : undefined,
}))

const SaveButton = styled(PrimaryButton)<{ theme: ThemeType }>(({ theme }) => ({
  width: theme.breakpoints.isMobileSized ? '100%' : undefined,
}))

const A = styledWeb.a({
  display: 'inline-flex',
})

export type ZoomPermissionsModalProps = {
  show: boolean
  onClose: () => void
}

export const ZoomPermissionsModal: FunctionComponent<ZoomPermissionsModalProps> = ({ show, onClose }) => {
  const instructions = useZoomPermissionsInstructions()
  const { formatMessage } = useIntl()
  const { colors } = useTheme() as ThemeType
  const ref = useRef<BottomSheetGorhom>(null)

  const openBottomSheet = useCallback(() => {
    ref.current?.expand()
  }, [])

  const listStyle = getFontStyles(colors).body.default
  return (
    <Modal
      visible={show}
      onCloseEnd={onClose}
      onRequestClose={onClose}
      snapPoints={[480]}
      onLayout={openBottomSheet}
      bottomSheetRef={ref}
      width='621px'
      modalContents={
        <Container testID={tID('ZoomPermissionsModal')}>
          <HeadingAndCloseButtonContainer>
            <Subhead
              text={
                <FormattedMessage
                  defaultMessage='Enable your camera and microphone'
                  description='Title of modal provider instructions on how to enable camera and microphone'
                />
              }
              size={SubheadSize.MEDIUM}
            />
            <PressableOpacity hitSlop={60} onPress={onClose} testID={tID('ZoomPermissionsModal-confirm-closeButton')}>
              <XIcon size={24} fillColor={colors.iconDefault} />
            </PressableOpacity>
          </HeadingAndCloseButtonContainer>
          <InnnerContainer>
            <ol>
              {formatMessage(instructions.steps, {
                li: (text: string) => (
                  <li style={{ ...listStyle, lineHeight: `${listStyle.lineHeight}px` }} key={text}>
                    {text}
                  </li>
                ),
              })}
            </ol>
            {instructions.link && (
              <BodyText>
                {formatMessage(instructions.link.text, {
                  link: (text: string) => (
                    <A style={{ color: colors.linkDefault }} href={instructions.link!.url} target='blank'>
                      {text}
                      <ExternalLinkIcon strokeColor={colors.iconActive} size={24} />
                    </A>
                  ),
                })}
              </BodyText>
            )}
          </InnnerContainer>
          <ButtonContainer>
            <CancelButton
              onPress={onClose}
              text={<FormattedMessage defaultMessage='Cancel' description='Button text that closes modal' />}
              testID={tID('ZoomPermissionsModal-cancel-button')}
            />
            <SaveButton
              text={<FormattedMessage defaultMessage='OK' description='Button text that closes modal' />}
              onPress={onClose}
              testID={tID('ZoomPermissionsModal-ok-button')}
            />
          </ButtonContainer>
        </Container>
      }
    />
  )
}
