import React from 'react'
import classNames from 'classnames'
import DetectRTC from 'detectrtc'
import { Map } from 'immutable'
import { useIntl } from 'react-intl'

import { videoViewStyle } from '@lyrahealth-inc/shared-app-logic'

import PhoneIcon from '../../atoms/icons/PhoneIcon'
import VideoIcon from '../../atoms/icons/VideoIcon'
import MinimizeIcon from '../../atoms/icons/MinimizeIcon'
import MicrophoneIcon from '../../atoms/icons/MicrophoneIcon'
import NetworkIssueIcon from '../../atoms/icons/NetworkIssueIcon'
import Tooltip from '../../atoms/tooltip/Tooltip'
import PrimaryButton from '../../atoms/buttons/primaryButton/PrimaryButton'
import DefaultButton from '../../atoms/buttons/defaultButton/DefaultButton'
import styles from './videoSession.module.scss'

const FullScreenContainer: React.FC<FullScreenProps> = ({
  $$currentSession,
  participantStatusMessage,
  viewStyle,
  inSession,
  type,
  isProvider,
  alert,
  participantNetworkQuality,
  startingSession,
  startSession,
  setViewStyle,
  toggleAudio,
  endSession,
  toggleVideo,
  isFullScreenMuted,
  fullScreenOwnsVideo,
  isFullScreenVideoOff,
}) => {
  const intl = useIntl()
  const providerName = $$currentSession.getIn(['appointment', 'provider', 'first_name'], 'Provider')

  const renderProviderPending = () => {
    return (
      <div id='participant-status-message' className={styles['provider-pending']}>
        <div className={styles['waitingroom-graphic']} />
        {providerName} will start the session shortly
        <div className={styles['recording-notice']}>
          <span /> Calls may be recorded for quality review purposes with your consent only
        </div>
        <p className={styles['recording-notice']}>
          Close other tabs and exit unnecessary applications to ensure the best video experience.
        </p>
        {/* Commenting out per LYRA-4489 until feature is ready
          <PrimaryButton onClick={this.messageProvider} className={styles['message-provider']}>Message {providerName}</PrimaryButton>
        */}
      </div>
    )
  }

  const renderClientLeft = () => {
    return (
      <div id='participant-status-message' className={styles['client-left']}>
        {$$currentSession.getIn(['appointment', 'userInfo', 'firstName'], 'Client')} has left the session
      </div>
    )
  }

  const renderProviderLeft = () => {
    const providerName = $$currentSession.getIn(['appointment', 'provider', 'first_name'], 'Provider')
    return (
      <div id='participant-status-message' className={styles['provider-left']}>
        {providerName} has left the session
        {/* Commenting out per LYRA-4584 until feature is ready
          <PrimaryButton onClick={this.messageProvider} className={styles['message-provider']}>Message {providerName}</PrimaryButton>
        */}
      </div>
    )
  }

  return (
    <div
      id='full-screen-container'
      data-test-id='FullScreenContainer-container'
      data-hasmessage={`${participantStatusMessage !== null && viewStyle === videoViewStyle.FULLSCREEN && inSession}`}
      className={classNames(styles['fullscreen-container'], inSession ? styles.participant : styles.user, {
        [styles['has-video']]: fullScreenOwnsVideo,
      })}
    >
      {isFullScreenMuted && (
        <MicrophoneIcon
          data-test-id='FullScreenContainer-muteIcon'
          isMuted
          aria-label={intl.formatMessage(
            {
              defaultMessage: '{isNotYou, select, true {The other person is} other {You are}} muted',
              description: 'Label that indicates that the person on screen is muted',
            },
            { isNotYou: !isProvider && inSession },
          )}
          className={styles['full-muted-icon']}
          fillColor='currentColor'
        />
      )}
      {participantNetworkQuality !== null && participantNetworkQuality < 2 ? (
        <Tooltip content='Network quality issue detected'>
          <NetworkIssueIcon fillColor='currentColor' className={styles['networkissue-icon']} />
        </Tooltip>
      ) : (
        []
      )}
      <div id='participant-status-container' data-test-id='FullScreenContainer-participantStatusContainer'>
        {participantStatusMessage === 'pending' && type === 'client' ? renderProviderPending() : []}
        {participantStatusMessage === 'left' ? (isProvider ? renderClientLeft() : renderProviderLeft()) : []}
      </div>
      {isProvider ? (
        <button
          data-test-id='FullScreenContainer-minimizeBtn'
          className={classNames(styles['minimize-button'], { [styles['alert-present']]: alert })}
          onClick={() => setViewStyle(videoViewStyle.PREVIEW)}
        >
          <MinimizeIcon width={20} fillColor='currentColor' />
          Minimize
        </button>
      ) : (
        []
      )}
      {type === 'client' && !inSession ? (
        <div className={styles['client-soft-prompt']}>
          <h1>Ready to join?</h1>
          <PrimaryButton
            data-test-id='FullScreenContainer-clientSoftJoinButton'
            customClass={styles['client-soft-join-button']}
            onClick={startSession}
            isLoading={startingSession}
            loaderWithin
          >
            Join session
          </PrimaryButton>
        </div>
      ) : (
        []
      )}
      <div
        id='fullscreen-video-container'
        data-test-id='FullScreenContainer-videoContainer'
        className={classNames(
          styles['fullscreen-video-container'],
          { [styles.mobile]: DetectRTC.isMobileDevice },
          { [styles['in-session']]: inSession },
          { [styles['video-off']]: isFullScreenVideoOff },
        )}
      >
        <div className={classNames('visible-xs-block', styles['mobile-video-controls'])}>
          <DefaultButton
            data-test-id='FullScreenContainer-muteBtn'
            elliptic
            customClass={classNames(styles['session-control'], { [styles.active]: isFullScreenMuted })}
            onClick={toggleAudio}
          >
            <MicrophoneIcon isMuted isFilled width={20} fillColor='currentColor' />
          </DefaultButton>
          <DefaultButton
            data-test-id='FullScreenContainer-videoBtn'
            elliptic
            customClass={classNames(styles['session-control'], { [styles.active]: isFullScreenVideoOff })}
            onClick={toggleVideo}
          >
            <VideoIcon isMuted isFilled width={20} fillColor='currentColor' />
          </DefaultButton>
          {inSession ? (
            <DefaultButton
              data-test-id='FullScreenContainer-endCallBtn'
              isOutlined
              elliptic
              customClass={classNames(styles['session-control'], styles['end-call'])}
              onClick={endSession}
            >
              <PhoneIcon isFilled fillColor='currentColor' width={20} />
            </DefaultButton>
          ) : (
            []
          )}
        </div>
      </div>
      {type === 'client' && !inSession ? (
        <>
          <p className={styles['recording-notice']}>
            <span /> Calls may be recorded for quality review purposes with your consent only
          </p>
          <p className={styles['recording-notice']}>
            Close other tabs and exit unnecessary applications to ensure the best video experience.
          </p>
        </>
      ) : (
        []
      )}
    </div>
  )
}

export default FullScreenContainer

type FullScreenProps = {
  $$currentSession: Map<any, any>
  participantStatusMessage: string | null
  viewStyle: string
  inSession: boolean
  type: string
  isProvider: boolean
  alert: any
  participantNetworkQuality: number | null
  startingSession: boolean
  startSession: () => void
  setViewStyle: (viewStyle: string) => void
  toggleAudio: () => void
  endSession: () => void
  toggleVideo: () => void
  isFullScreenMuted: boolean
  fullScreenOwnsVideo: boolean
  isFullScreenVideoOff: boolean
}
