import React from 'react'
import PrimaryButton from '../../atoms/buttons/primaryButton/PrimaryButton'
import styles from './assignmentsList.module.scss'

const AssignmentsNone: React.FC<AssignmentsNoneProps> = ({
  addActivityHandler,
  noDataText,
  backgroundColor = '',
  ...props
}) => {
  return (
    <div className={styles['no-assignments']} style={{ backgroundColor }} {...props}>
      {noDataText || 'No activities shared to this session'}
      {addActivityHandler && (
        <PrimaryButton data-test-id='AssignmentList-addActivityButton' onClick={addActivityHandler}>
          + Add Activity
        </PrimaryButton>
      )}
    </div>
  )
}

export interface AssignmentsNoneProps {
  addActivityHandler?: () => void
  noDataText?: string
  backgroundColor?: string
}

export default AssignmentsNone
