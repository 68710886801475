import { useEffect, useState } from 'react'

import { getDocumentObject } from '../utils/commonUtils'

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0])

  useEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight])
    }
    // this hook is used cross platform
    const isWeb = getDocumentObject()
    if (isWeb) {
      window.addEventListener('resize', updateSize)
    }
    updateSize()
    return () => {
      isWeb && window.removeEventListener('resize', updateSize)
    }
  }, [])

  return size
}
