import { createReducer } from '@reduxjs/toolkit'

import {
  CLEAR_ACCOUNT_TYPE,
  SELECT_ACCOUNT_TYPE,
  TOGGLE_REGISTER_PROVIDER_MODAL,
} from '../../../common/constants/reduxConstants'

type RegisterProviderState = {
  showModal: boolean
  accountType?: string
}

const initialState = {
  showModal: false,
}

export default createReducer<RegisterProviderState>(initialState, (builder) => {
  builder.addCase(TOGGLE_REGISTER_PROVIDER_MODAL, (state, action: any) => {
    state.showModal = action.payload
    return state
  })
  builder.addCase(SELECT_ACCOUNT_TYPE, (state, action: any) => {
    state.accountType = action.payload
    return state
  })
  builder.addCase(CLEAR_ACCOUNT_TYPE, (state) => {
    state.accountType = undefined
    return state
  })
})
