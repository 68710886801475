import React, { FunctionComponent } from 'react'
import { Path, Svg } from 'react-native-svg'

import { PrimaryNeedsWords } from '@lyrahealth-inc/shared-app-logic'

import { tID } from '../../utils'

type DepressionIllustrationProps = {
  size?: string | number
  selected?: string
}

export const DepressionIllustration: FunctionComponent<DepressionIllustrationProps> = ({ selected = false, size }) => (
  <Svg
    width={size}
    height={size}
    viewBox='0 0 48 48'
    fill='none'
    testID={tID(`${PrimaryNeedsWords.DEPRESSION_SADNESS}${selected ? '-Selected-' : '-'}Illustration`)}
  >
    <Path
      d='M46.8117 16.2367C44.5906 12.0893 40.8657 11.7379 37.3541 12.3486C37.4817 11.2255 37.4294 10.0627 37.1826 8.95837C36.5343 6.04914 34.8695 4.08734 32.8512 2.98514C29.2706 1.02753 24.8639 2.12764 21.6472 5.04733C20.2961 6.27502 19.0915 7.78924 18.1336 9.56698C16.4646 7.8227 14.2434 7.06977 12.1352 7.29356C9.09634 7.61774 6.38371 9.76149 4.29643 12.8025C3.24024 14.3418 2.31581 16.0526 1.51896 17.8596C0.717932 19.6792 -0.0684586 21.7498 0.00474263 23.9144C0.0842183 26.2966 1.21779 28.1392 2.87214 28.7834C3.26743 28.9381 3.67317 29.0385 4.08937 29.1034C4.3048 29.3209 4.58714 29.4526 4.93642 29.4464C13.8879 29.3271 22.8373 29.21 31.7887 29.0908C34.0392 29.0615 36.2896 29.0322 38.54 29.0009C40.3115 28.9779 42.2021 29.1138 43.9234 28.3713C45.6698 27.6184 47.138 26.0101 47.7257 23.5672C48.3239 21.0784 47.9097 18.2821 46.8138 16.2346L46.8117 16.2367Z'
      fill={selected ? '#85CCD5' : '#E3D3C8'}
    />
    <Path
      d='M8.93249 32.6347C8.76936 32.5907 8.59786 32.5782 8.42636 32.5928C8.03525 32.5447 7.61696 32.6535 7.24886 32.9986C5.93961 34.2221 5.22851 35.8806 5.14694 37.6604C5.08838 38.9676 6.83894 39.6494 7.72153 38.7271C8.85302 37.5475 9.65405 36.096 9.98659 34.4898C10.1518 33.6929 9.75444 32.8605 8.93249 32.6347Z'
      fill={selected ? '#3BA5B5' : '#85CCD5'}
    />
    <Path
      d='M14.724 39.8611C14.3935 39.9803 14.0965 40.2167 13.8686 40.5199C12.9023 41.8062 12.4234 43.3225 12.3292 44.9183C12.2435 46.3718 13.971 46.7065 14.9038 45.9849C16.5645 44.6987 17.4743 42.6909 17.5307 40.6119C17.5746 38.966 15.4163 38.7171 14.724 39.859V39.8611Z'
      fill={selected ? '#3BA5B5' : '#85CCD5'}
    />
    <Path
      d='M26.4345 33.6427C26.2003 33.7724 25.9911 33.9606 25.828 34.1927C25.1085 35.2238 24.7593 36.4306 24.73 37.6813C24.6965 39.1056 26.3488 39.5134 27.3046 38.748C28.6368 37.6813 29.3689 35.9789 29.3312 34.2848C29.2936 32.5907 26.9846 32.3711 26.4324 33.6427H26.4345Z'
      fill={selected ? '#3BA5B5' : '#85CCD5'}
    />
    <Path
      d='M35.5193 37.9883C35.0801 37.9883 34.712 38.1681 34.4485 38.4526C34.1515 38.4986 33.8796 38.6325 33.6893 38.873C32.882 39.9103 32.4177 41.0314 32.3842 42.3594C32.357 43.4449 33.7081 44.3171 34.6534 43.6624C36.0798 42.6753 36.908 41.2301 37.0252 39.4983C37.0795 38.6847 36.2931 37.9904 35.5172 37.9904L35.5193 37.9883Z'
      fill={selected ? '#3BA5B5' : '#85CCD5'}
    />
  </Svg>
)
