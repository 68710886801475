import React, { FunctionComponent } from 'react'
import Svg, { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

type Props = {
  size?: number | string
}

export const LyraMobileAppSpotIllustration: FunctionComponent<Props> = ({ size = 124 }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 515 513' fill='none'>
      <G clip-path='url(#clip0_16344_11350)'>
        <Path
          d='M257.86 2C222.519 2 188.23 8.06049 155.945 20.0095C79.0808 48.5008 25.0423 111.666 7.68041 193.305C-3.79304 246.534 -2.32598 295.767 12.1929 343.745C30.7285 405.017 76.2277 456.688 137.025 485.504C167.206 499.84 201.879 508.026 240.063 509.826C244.97 510.049 249.958 510.171 254.916 510.171C285.552 510.171 316.371 505.972 346.521 497.695C348.838 497.119 351.044 496.522 353.371 495.834C380.466 487.901 404.85 475.356 425.712 458.591C448.366 440.49 467.013 417.543 481.076 390.428C483.849 385.086 486.499 379.46 489.423 372.732C505.511 335.378 513.281 293.561 511.905 251.755C511.257 233.27 508.505 213.621 503.75 193.305C495.919 160.342 483.15 129.341 466.821 103.602C462.349 96.6913 458.241 90.8838 454.173 85.6631C424.822 47.8027 379.09 21.2337 321.966 8.8699C300.982 4.31695 279.411 2.01012 257.87 2.01012L257.86 2Z'
          fill='white'
        />
        <Path
          d='M489.941 252.443C489.314 234.727 486.602 216.373 482.333 198.131C475.351 168.739 463.878 139.762 448.145 114.954C444.492 109.328 440.749 103.905 436.682 98.6945C407.178 60.652 362.468 39.4858 316.706 29.5806C265.733 18.5321 211.533 21.7597 162.533 39.9007C91.6486 66.1661 43.3873 123.705 27.6442 197.716C21.5938 225.752 19.8232 257.441 22.5348 287.885H22.3223C23.8905 305.186 27.0169 322.073 31.7014 337.603C49.1139 395.142 91.426 440.803 145.323 466.341C174.512 480.202 207.132 487.082 240.287 488.65C274.161 490.218 308.561 485.938 340.361 477.187C342.446 476.671 344.429 476.145 346.513 475.517C371.423 468.222 393.105 456.961 411.661 442.058C431.886 425.9 448.56 405.574 461.49 380.664C464.303 375.241 466.802 369.828 469.2 364.294C484.417 328.953 491.196 290.596 489.84 252.554L489.941 252.453V252.443Z'
          fill='#FFE9E3'
        />
        <Path
          d='M212.047 99.089C212.047 99.089 302.844 75.2108 311.751 76.6355C319.974 77.948 325.942 80.7529 334.283 104.082C342.308 126.544 402.359 352.501 402.469 353.026C409.338 383.955 397.744 386.659 386.947 391.751C376.265 396.778 255.246 428.652 223.023 437.039C214.584 439.235 205.186 439.342 197.708 436.207C191.783 433.723 185.503 431.647 177.129 406.286C166.475 374.051 113.609 159.605 113.609 159.605C113.609 159.605 107.703 133.214 129.175 125.747C150.658 118.278 212.047 99.089 212.047 99.089Z'
          fill='#008295'
        />
        <Path
          d='M216.412 114.444C216.412 114.444 295.083 93.6804 302.719 94.632C309.769 95.5108 314.858 97.6133 321.566 115.943C328.023 133.599 375.191 311.582 375.283 312C380.577 336.395 370.546 338.772 361.137 343.018C351.839 347.224 246.984 374.934 219.066 382.233C211.748 384.145 203.658 384.415 197.277 382.089C192.225 380.243 186.861 378.715 180.157 358.776C171.632 333.434 130.422 164.425 130.422 164.425C130.422 164.425 125.863 143.611 144.512 137.26C163.158 130.898 216.414 114.454 216.414 114.454L216.412 114.444Z'
          fill='#85CCD5'
        />
        <Path
          d='M294.97 405.922C301.998 404.001 306.137 396.747 304.216 389.719C302.295 382.692 295.041 378.552 288.013 380.473C280.986 382.394 276.846 389.649 278.768 396.676C280.689 403.704 287.943 407.843 294.97 405.922Z'
          fill='#00A4B3'
        />
        <Path
          d='M291.346 282.087C294.467 281.354 303.25 278.833 303.25 278.833L308.747 298.943C308.747 298.943 300.735 301.133 296.843 302.197C285.168 305.388 253.912 311.652 245.269 280.035C235.404 243.949 216.215 173.753 216.215 173.753L236.818 168.121C236.818 168.121 259.733 251.947 264.314 268.705C268.59 284.346 280.121 284.675 291.346 282.087Z'
          fill='white'
        />
      </G>
      <Defs>
        <ClipPath id='clip0_16344_11350'>
          <Rect width='512' height='512' fill='white' />
        </ClipPath>
      </Defs>
    </Svg>
  )
}
