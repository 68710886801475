import React from 'react'
import MaskedInput from 'react-maskedinput'
import classNames from 'classnames'
import BaseInput from '../baseInput/BaseInput'
import styles from './maskedInputField.module.scss'

type Props = {
  meta?: $TSFixMe
  input?: $TSFixMe
  label?: string
  mask: string
  placeholder?: string
  className?: string
  readOnly?: boolean
  disabled?: boolean
  autocomplete?: boolean
}

const MaskedInputField = ({
  input,
  label,
  meta: { touched, error, submitError, warning, asyncValidating },
  // @ts-expect-error TS(2339): Property 'indicateAsyncValidity' does not exist on... Remove this comment to see the full error message
  indicateAsyncValidity,
  // @ts-expect-error TS(2339): Property 'asyncValid' does not exist on type 'Prop... Remove this comment to see the full error message
  asyncValid,
  className,
  disabled,
  readOnly,
  // @ts-expect-error TS(2339): Property 'hintContent' does not exist on type 'Pro... Remove this comment to see the full error message
  hintContent,
  autocomplete,
  mask,
  ...rest
}: Props) => {
  const invalid = touched && error
  const valid = indicateAsyncValidity && !error && asyncValid
  const fieldInfoId = `${input.name}_info`
  return (
    <BaseInput
      className={className}
      label={label}
      touched={touched}
      error={error || submitError}
      warning={warning}
      asyncValid={asyncValid}
      asyncValidating={asyncValidating}
      indicateAsyncValidity={indicateAsyncValidity}
      disabled={disabled}
      readOnly={readOnly}
      hintContent={hintContent}
      htmlFor={input.name}
      fieldInfoId={fieldInfoId}
    >
      <MaskedInput
        className={classNames(styles.input, { [styles.invalid]: invalid }, { [styles.valid]: valid })}
        id={input.name}
        {...input}
        type='tel'
        disabled={disabled}
        readOnly={readOnly}
        aria-describedby={fieldInfoId}
        autocomplete={autocomplete}
        mask={mask}
        {...rest}
      />
    </BaseInput>
  )
}

export default MaskedInputField
