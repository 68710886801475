import React, { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'

import { useTheme } from 'styled-components/native'

import { ScheduleIcon } from '../../atoms/icons/ScheduleIcon'
import { MessageAttachment, MessageAttachmentProps } from '../messageAttachment/MessageAttachment'

export type DirectBookingLinkMessageAttachmentProps = Omit<
  MessageAttachmentProps,
  'text' | 'icon' | 'closeTooltipLabel'
>

export const DirectBookingLinkMessageAttachment: FunctionComponent<DirectBookingLinkMessageAttachmentProps> = (
  props,
) => {
  const { formatMessage } = useIntl()
  const { colors } = useTheme()

  return (
    <MessageAttachment
      {...props}
      text={formatMessage({
        defaultMessage: 'Book a session',
        description: 'Attachment to book session with provider',
      })}
      icon={<ScheduleIcon fillColor={colors.iconActive} />}
      closeTooltipLabel={formatMessage({
        defaultMessage: 'Remove direct booking link',
        description: 'Tooltip label to remove direct booking link',
      })}
    />
  )
}
