import React, { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { connect, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import axios, { CancelToken, CancelTokenSource } from 'axios'
import classNames from 'classnames'
import { Map } from 'immutable'
import { isEmpty, sumBy } from 'lodash-es'
import moment from 'moment-timezone'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import styled, { css, useTheme } from 'styled-components/native'

import {
  Assignment,
  AssignmentGroup,
  AssignmentStatuses,
  ClientListClientObject,
  ClientObject,
  ConversationObject,
  CustomerInfo,
  Episode,
  getAssignmentStatuesLabel,
  getCountryDisplayName,
  getInternationallyFormattedNumber,
  ProgramNames,
  useFlags,
} from '@lyrahealth-inc/shared-app-logic'
import {
  BoldCheckJaggedIcon,
  DefaultButton,
  IconBadge,
  Label,
  ListIcon,
  LyraPopover,
  MessageIcon,
  Tooltip,
  TOOLTIP_PLACEMENTS,
} from '@lyrahealth-inc/ui-core'
import {
  CheckMarkInCircleIcon,
  CloseIcon,
  CountryIcon,
  LoadingIndicator,
  tID,
  toJS,
} from '@lyrahealth-inc/ui-core-crossplatform'
import { ActivityBadge } from '@lyrahealth-inc/ui-core-crossplatform/src/atoms/activityBadge/ActivityBadge'

import styles from './clientPreviewPane.module.scss'
import SelfHarmIndicator from '../../../common/components/selfHarmIndicator/SelfHarmIndicator'
import SpecialAlertIndicator from '../../../common/components/specialAlertIndicator/SpecialAlertIndicator'
import {
  affirmativeResponses,
  LCTT_ACTIVITY_BADGE_TEXT,
  programConfig,
  programs,
} from '../../../common/constants/appConstants'
import { programIconMap } from '../../../common/constants/programIconMap'
import { CLIENT_HOME } from '../../../common/constants/routingConstants'
import { getClientFullName, getGSCDuration } from '../../../common/utils/utils'
import { getAuthUserId } from '../../../data/auth/authSelectors'
import { getClientAssignmentsData, getClientEpisodesData } from '../../../data/lyraTherapy/clientSelectors'
import { getRequestPaymentCustomers } from '../../../data/requestPayment/requestPaymentSelectors'
import { getAssignments } from '../../assignments/data/assignmentsAutoActions'
import { getClientEpisodes } from '../../episodes/data/episodesAutoActions'
import { getMessageConversation } from '../../messages/data/messagesAutoActions'
import { getSelectedConversation } from '../../messages/data/messagesSelectors'
import { getLtClient, selectLtClient } from '../clientDetails/data/ltClientDetailsAutoActions'
import HealthPlanStatus from '../clientDetails/profile/HealthPlanStatus'

const StatusIconSection = styled.View(({ theme }) => ({
  width: '24px',
  marginRight: theme.spacing['4px'],
}))

const StatusIconContainer = styled.View({
  margin: 'auto',
  zIndex: '0',
})

const ClientPreviewPane: React.FC<ClientPreviewPaneProps> = ({
  selectedClient,
  currentEpisode,
  assignments,
  customers,
  messageConversation,
  actions: { selectLtClient, getLtClient, getAssignments, getClientEpisodes, getMessageConversation },
}) => {
  const userId: string = useSelector(getAuthUserId)
  const intl = useIntl()
  const navigate = useNavigate()
  const { colors } = useTheme()
  const resourcesRef = useRef<CancelTokenSource | null>(null)
  const [isLoadingClientResources, setIsLoadingClientResources] = useState<boolean>(false)
  const [hasFetchedClientResources, setHasFetchedClientResources] = useState<boolean>(false)
  const clientRef = useRef<CancelTokenSource | null>(null)
  const [isLoadingClient, setIsLoadingClient] = useState<boolean>(false)
  const [hasFetchedClient, setHasFetchedClient] = useState<boolean>(false)
  const [clientDetails, setClientDetails] = useState<ClientObject | null>(null)
  const unreadMessages = messageConversation?.conversation_attributes?.unread_provider_messages_count || 0
  const unreadAssignments = sumBy(assignments, (assignment) => assignment.unread_count) || 0
  const ProgramIcon =
    currentEpisode?.program_id &&
    programConfig[currentEpisode?.program_name]?.icon &&
    programIconMap[programConfig[currentEpisode?.program_name].icon]
  const episodeWarning =
    currentEpisode?.program_name &&
    clientDetails?.session_number &&
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    clientDetails?.session_number > programConfig[currentEpisode?.program_name]?.recommendedEpisodeLength
  const { activityBadgeTeen } = colors.components
  const { isPreferredNameEnabled } = useFlags()

  useEffect(() => {
    if (!selectedClient?.id) {
      return
    }
    resourcesRef.current && resourcesRef.current.cancel()
    clientRef.current && clientRef.current.cancel()

    const resourcesCancelToken = axios.CancelToken.source()
    const clientCancelToken = axios.CancelToken.source()
    setClientDetails(null)

    resourcesRef.current = resourcesCancelToken
    clientRef.current = clientCancelToken
    setIsLoadingClientResources(true)
    setIsLoadingClient(true)
    getLtClient({ clientId: selectedClient?.id, providerId: userId, cancelToken: clientCancelToken.token })
      .then((res: any) => {
        setClientDetails(res)
      })
      .finally(() => {
        setIsLoadingClient(false)
        setHasFetchedClient(true)
      })
    Promise.all([
      getAssignments({ patient_id: selectedClient?.id, provider_id: userId, cancelToken: resourcesCancelToken.token }),
      getClientEpisodes({
        patient_id: selectedClient?.id,
        provider_id: userId,
        cancelToken: resourcesCancelToken.token,
      }),
      getMessageConversation({
        patient_lyra_id: selectedClient?.id,
        provider_lyra_id: userId,
        cancelToken: resourcesCancelToken.token,
      }),
    ]).finally(() => {
      setIsLoadingClientResources(false)
      setHasFetchedClientResources(true)
    })
  }, [selectedClient?.id, getAssignments, getClientEpisodes, getMessageConversation, getLtClient, userId])

  const goToClientHome = (selectedClient: ClientObject) => {
    selectLtClient(selectedClient)
    navigate(CLIENT_HOME.route)
  }

  const internationalFormatNumber =
    clientDetails?.phone !== undefined &&
    getInternationallyFormattedNumber(clientDetails?.phone, clientDetails?.country)

  const renderPersonalRow = () => {
    return (
      <div className={styles.row}>
        <div className={`${styles['row-label']} ${styles['row-item-container']}`}>Personal</div>
        {isPreferredNameEnabled && (
          <>
            <div className={styles['row-item-container']}>
              <div className={styles['row-item-label']}>Legal Name</div>
              <div className={styles['row-item-value']} data-test-id='ClientPreviewPane-legal-name'>
                {clientDetails?.full_name}
              </div>
              {!isEmpty(clientDetails?.pronouns) && (
                <>
                  <div className={styles['row-item-label']}>Pronoun(s)</div>
                  <div className={styles['row-item-value']} data-test-id='ClientPreviewPane-pronouns'>
                    {clientDetails?.pronouns?.map((pronoun: string) => {
                      return <div key={pronoun}>{pronoun}</div>
                    })}
                  </div>
                </>
              )}
            </div>
            <div className={styles['row-item-container']}>
              <div className={styles['row-item-label']}>Preferred Name</div>
              <div className={styles['row-item-value']} data-test-id='ClientPreviewPane-preferred-name'>
                {clientDetails?.preferred_first_name} {clientDetails?.preferred_last_name}
              </div>
              <div className={styles['row-item-label']}>Phone Number</div>
              <div className={styles['row-item-value']} data-test-id='ClientPreviewPane-phone-number'>
                <div className={styles['phone-container']}>
                  {internationalFormatNumber}{' '}
                  {clientDetails?.voicemail_consent && (
                    <Tooltip content='Consent for voicemail given' placement={TOOLTIP_PLACEMENTS.TOP}>
                      <BoldCheckJaggedIcon fillColor={styles.x_soft_black} />
                    </Tooltip>
                  )}{' '}
                </div>
              </div>
            </div>
          </>
        )}
        {!isPreferredNameEnabled && (
          <>
            <div className={styles['row-item-container']}>
              {clientDetails?.preferred_name && (
                <>
                  <div className={styles['row-item-label']}>Preferred Name</div>
                  <div className={styles['row-item-value']} data-test-id='ClientPreviewPane-preferred-name'>
                    {clientDetails?.preferred_name}
                  </div>
                </>
              )}
              <div className={styles['row-item-label']}>Phone Number</div>
              <div className={styles['row-item-value']} data-test-id='ClientPreviewPane-phone-number'>
                <div className={styles['phone-container']}>
                  {internationalFormatNumber}{' '}
                  {clientDetails?.voicemail_consent && (
                    <Tooltip content='Consent for voicemail given' placement={TOOLTIP_PLACEMENTS.TOP}>
                      <BoldCheckJaggedIcon fillColor={styles.x_soft_black} />
                    </Tooltip>
                  )}{' '}
                </div>
              </div>
            </div>
          </>
        )}
        <div className={styles['row-item-container']}>
          <div className={styles['row-item-label']}>Age</div>
          <div className={styles['row-item-value']} data-test-id='ClientPreviewPane-age'>{`${moment().diff(
            clientDetails?.date_of_birth,
            'year',
          )} (${intl.formatDate(clientDetails?.date_of_birth, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            timeZone: 'UTC',
          })})`}</div>
          <div className={styles['row-item-label']}>Time Zone</div>
          <div className={styles['row-item-value']} data-test-id='ClientPreviewPane-timezone'>
            {clientDetails?.time_zone}
          </div>
        </div>
        {(!isEmpty(clientDetails?.pronouns) || clientDetails?.country !== 'US') && (
          <div className={styles['row-item-container']}>
            {!isPreferredNameEnabled && !isEmpty(clientDetails?.pronouns) && (
              <>
                <div className={styles['row-item-label']}>Pronoun(s)</div>
                <div className={styles['row-item-value']} data-test-id='ClientPreviewPane-pronouns'>
                  {clientDetails?.pronouns?.map((pronoun: string) => {
                    return <div key={pronoun}>{pronoun}</div>
                  })}
                </div>
              </>
            )}
            {clientDetails?.country && clientDetails?.country !== 'US' && (
              <>
                <div className={styles['row-item-label']}>Country</div>
                <div className={styles['row-item-value']} data-test-id='ClientPreviewPane-country'>
                  {`${clientDetails.country} (${getCountryDisplayName(clientDetails.country)})`}
                </div>
                {clientDetails.language && (
                  <>
                    <div className={styles['row-item-label']}>Preferred Language</div>
                    <div className={styles['row-item-value']} data-test-id='ClientPreviewPane-preferred-language'>
                      {clientDetails.language}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        )}
      </div>
    )
  }

  const renderEpisodeRow = () => {
    const assignmentsForCurrentSession = assignments?.filter(({ session_period, content: { group } }: any) => {
      return group !== 'assessment' && session_period === clientDetails?.session_number
    })

    const assignmentOrder = [
      AssignmentGroup.LESSON,
      AssignmentGroup.EXERCISE,
      AssignmentGroup.ASSESSMENT,
      AssignmentGroup.INFO_SHEET,
    ]
    const intakeAssignments = assignments
      ?.filter(({ session_period, content: { title } }: any) => {
        return session_period === 0 && title !== 'Initial Assessment'
      })
      .sort(({ content: { group: aGroup } }: Assignment, { content: { group: bGroup } }: Assignment) => {
        return assignmentOrder.findIndex((group) => group === aGroup) <
          assignmentOrder.findIndex((group) => group === bGroup)
          ? -1
          : 1
      })

    const mostRecentOutcome = clientDetails?.outcomes
      ?.filter((outcome) => outcome.episode_id === currentEpisode?.id)
      ?.filter((outcome) => outcome.scores !== null)
      ?.sort((a, b) => {
        return new Date(b.response_date).getTime() - new Date(a.response_date).getTime()
      })[0]
    const scoresToShow: string[] = []
    mostRecentOutcome?.scores &&
      Object.keys(mostRecentOutcome?.scores).forEach((score) => {
        if (
          ['GAD', 'PHQ', 'PSS', 'WELL-BEING'].includes(score) &&
          mostRecentOutcome?.scores &&
          mostRecentOutcome?.scores[score] !== null
        ) {
          scoresToShow.push(`${score} ${mostRecentOutcome?.scores && mostRecentOutcome?.scores[score]}`)
        }
      })

    const currentProgess =
      currentEpisode?.program_name === programs.SingleSessionCoaching
        ? getGSCDuration(
            clientDetails?.first_appointment?.startDate,
            // @ts-expect-error TS(2345): Argument of type 'number | null' is not assignable... Remove this comment to see the full error message
            programConfig[currentEpisode?.program_name]?.recommendedEpisodeLength,
          )
        : clientDetails?.session_number
    return (
      <div className={styles.row}>
        <div className={`${styles['row-label']} ${styles['row-item-container']}`}>Episode</div>
        <div className={styles['row-item-container']}>
          <div className={styles['row-item-label']}>Session</div>
          <div data-test-id='ClientPreviewPane-session-count' className={`${styles['row-item-value']}`}>
            {currentEpisode?.program_name && programConfig[currentEpisode?.program_name]?.sessionLabel}{' '}
            {currentProgess ?? 1}{' '}
            {currentEpisode?.program_name &&
              currentEpisode.program_name !== ProgramNames.Coaching &&
              programConfig[currentEpisode?.program_name]?.recommendedEpisodeLength &&
              ` of ${programConfig[currentEpisode?.program_name]?.recommendedEpisodeLength}`}
          </div>
          <div className={styles['row-item-label']}>Next Session</div>
          <div className={styles['row-item-value']} data-test-id='ClientPreviewPane-next-session'>
            {clientDetails?.next_appointment ? (
              <>
                <div>
                  {intl.formatDate(
                    moment
                      .tz(
                        `${clientDetails?.next_appointment?.startDate} ${clientDetails?.next_appointment?.startTime}`,
                        clientDetails?.next_appointment?.timeZone,
                      )
                      .toDate(),
                    { weekday: 'long', year: 'numeric', month: '2-digit', day: '2-digit' },
                  )}
                </div>
                <div>
                  {intl.formatDate(
                    moment
                      .tz(
                        `${clientDetails?.next_appointment?.startDate} ${clientDetails?.next_appointment?.startTime}`,
                        clientDetails?.next_appointment?.timeZone,
                      )
                      .toDate(),
                    { hour: 'numeric', minute: 'numeric' },
                  )}
                </div>
              </>
            ) : (
              'No session scheduled'
            )}
          </div>
        </div>
        {clientDetails?.session_number && clientDetails?.session_number > 1 ? (
          <div className={styles['row-item-container']}>
            <div className={styles['row-item-label']}>Shared Activities</div>
            <div className={styles['row-item-value']} data-test-id='ClientPreviewPane-current-session-assignments'>
              {assignmentsForCurrentSession && assignmentsForCurrentSession?.length > 0
                ? // show a max of 5 assignments
                  assignmentsForCurrentSession.slice(0, 5)?.map((assignment: Assignment) => {
                    return (
                      <div
                        className={styles['assignment-item']}
                        key={assignment.id}
                        data-test-id={`ClientPreviewPane-current-session-activity-${assignment.content.name}`}
                      >
                        <Tooltip
                          content={getAssignmentStatuesLabel(assignment.status as AssignmentStatuses)}
                          placement='top'
                        >
                          <StatusIconSection>
                            <StatusIconContainer
                              testID={tID(`ClientPreviewPane-StatusIconContainer-${assignment.status}`)}
                            >
                              {assignment.status === 'completed' ? (
                                <CheckMarkInCircleIcon filled size={24} />
                              ) : (
                                <CloseIcon size={10} />
                              )}
                            </StatusIconContainer>
                          </StatusIconSection>
                        </Tooltip>
                        <div className={styles['assignment-title']}>{`${assignment.content.title}`}</div>
                      </div>
                    )
                  })
                : 'Nothing shared for the current session'}
            </div>
          </div>
        ) : (
          <div className={styles['row-item-container']}>
            <div className={styles['row-item-label']}>Intake Activities</div>
            <div className={styles['row-item-value']} data-test-id='ClientPreviewPane-intake-activities'>
              {intakeAssignments && intakeAssignments?.length > 0
                ? // show a max of 5 assignments
                  intakeAssignments.slice(0, 5)?.map((assignment: Assignment) => {
                    return (
                      <div
                        className={styles['assignment-item']}
                        key={assignment.id}
                        data-test-id={`ClientPreviewPane-intake-activity-${assignment.content.name}`}
                      >
                        <Tooltip
                          content={getAssignmentStatuesLabel(assignment.status as AssignmentStatuses)}
                          placement='top'
                        >
                          <StatusIconSection>
                            <StatusIconContainer
                              testID={tID(`ClientPreviewPane-StatusIconContainer-${assignment.status}`)}
                            >
                              {assignment.status === 'completed' ? (
                                <CheckMarkInCircleIcon filled size={24} />
                              ) : (
                                <CloseIcon size={10} />
                              )}
                            </StatusIconContainer>
                          </StatusIconSection>
                        </Tooltip>
                        <div className={styles['assignment-title']}>{`${assignment.content.title}`}</div>
                      </div>
                    )
                  })
                : 'No intake activities'}
            </div>
          </div>
        )}

        {scoresToShow.length > 0 && (
          <div className={styles['row-item-container']}>
            <div className={styles['row-item-label']} data-test-id={'ClientPreviewPane-LastAssessmentCompleted'}>
              Last Assessment Completed
            </div>
            <div className={styles['row-item-value']} data-test-id='ClientPreviewPane-current-session-assessment'>
              <div className={styles['assignment-item']}>
                {intl.formatDate(mostRecentOutcome?.response_date, {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                })}
              </div>
              <div className={styles['assignment-item']} style={{ width: '75%' }}>
                {scoresToShow.join(' • ')}
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }

  const renderBenefitsRow = () => {
    const clientCustomer = customers?.find(
      (customer: CustomerInfo) => customer?.value?.toLowerCase() === clientDetails?.employer?.toLowerCase(),
    )

    const currentProgramCoverageBreakdown =
      clientCustomer?.program_coverage_breakdown && clientCustomer?.program_coverage_breakdown[moment().format('YYYY')]

    const programCoverageName = currentEpisode?.program_name
      ? programConfig[currentEpisode?.program_name]?.programCoverageName
      : ''

    const validProgramCoverage =
      currentEpisode?.program_name &&
      currentProgramCoverageBreakdown &&
      programCoverageName in currentProgramCoverageBreakdown

    const currentProgramCoveredByEAP =
      validProgramCoverage && currentProgramCoverageBreakdown[programCoverageName]?.eapSupported

    const currentProgramCoveredByBHB =
      validProgramCoverage && currentProgramCoverageBreakdown[programCoverageName]?.bhbSupported

    const currentProgramCoveredBySpecialtyBHB =
      validProgramCoverage && currentProgramCoverageBreakdown[programCoverageName]?.specialtyBhbSupported

    return (
      <div className={styles.row}>
        <div className={`${styles['row-label']} ${styles['row-item-container']}`}>Benefit</div>
        <div className={`${styles['row-item-container']} ${styles['vertical-container']}`}>
          <div className={styles['row-item-label']}>Company</div>
          <div className={styles['row-item-value']} data-test-id='ClientPreviewPane-company'>
            {clientDetails?.displayed_employer}
          </div>
          <div className={styles['row-item-label']}>Sessions</div>
          <div className={styles['row-item-value']} data-test-id='ClientPreviewPane-sessions'>
            {currentProgramCoveredByEAP && (
              <div>
                {clientCustomer?.display_visits_per_issue_per_year
                  ? `${clientCustomer?.display_visits_per_issue_per_year} visits per issue per year. Issues determined between you and your client.`
                  : `${clientDetails?.sessions_used} of ${clientDetails?.sessions_limit} EAP sessions`}
              </div>
            )}
            {currentProgramCoveredByBHB && (
              <div>
                {`${clientDetails?.open_bhb_session_count}`} HPI{' '}
                {clientDetails?.open_bhb_session_count && clientDetails?.open_bhb_session_count === 1
                  ? `session`
                  : `sessions`}{' '}
                completed
              </div>
            )}
            {currentProgramCoveredBySpecialtyBHB && (
              <div>{`${clientDetails?.speciality_care_session_count} high-risk sessions`}</div>
            )}
            {!currentProgramCoveredByEAP && !currentProgramCoveredByBHB && !currentProgramCoveredBySpecialtyBHB && (
              <div>No coverage</div>
            )}
          </div>

          <div className={styles['row-item-label']}>Reset Date</div>
          <div className={styles['row-item-value']} data-test-id='ClientPreviewPane-reset-date'>
            {intl.formatDate(clientCustomer?.session_count_starts_on, {
              month: 'long',
              day: '2-digit',
            })}
          </div>
        </div>
        {clientDetails?.health_plan_status && currentProgramCoverageBreakdown && (
          <div data-test-id='ClientPreviewPane-health-plan-info'>
            <div className={styles['row-item-label']}>Health Plan Info</div>
            <p className={styles['health-plan-info']}>
              <HealthPlanStatus
                program={currentEpisode?.program_name}
                currentProgramCoverageBreakdown={currentProgramCoverageBreakdown}
                status={clientDetails?.health_plan_status}
                hasEAPSessions={clientDetails?.eap_session_limit !== 0}
              />
            </p>
          </div>
        )}
      </div>
    )
  }

  const renderLeftHandPreviewPaneHeader = () => {
    if (currentEpisode?.program_name === ProgramNames.TeensTherapy) {
      return (
        <>
          <ActivityBadge
            text={LCTT_ACTIVITY_BADGE_TEXT}
            backgroundColor={activityBadgeTeen.background}
            color={activityBadgeTeen.text}
            activityBadgeCustomStyle={css`
              border-radius: 4px;
              margin-left: 8px;
              padding: 4px 8px;
            `}
          />
          {ProgramIcon && (
            <div className={styles['icon-container']} data-test-id='ClientPreviewPane-program-icon'>
              <ProgramIcon width={44} />
            </div>
          )}
        </>
      )
    } else {
      return (
        ProgramIcon && (
          <div className={styles['icon-container']} data-test-id='ClientPreviewPane-program-icon'>
            <ProgramIcon width={44} />
          </div>
        )
      )
    }
  }

  return (
    <div className={styles['preview-container']} data-test-id={'ClientPreviewPane-container'}>
      {!selectedClient ? (
        'Select a client'
      ) : (
        <div className={styles.preview}>
          {!hasFetchedClient ||
          !hasFetchedClientResources ||
          isLoadingClient ||
          isLoadingClientResources ||
          !clientDetails ? (
            <div className={styles['loading-container']}>
              <LoadingIndicator size={45} />
            </div>
          ) : (
            <>
              <div className={styles.header} data-test-id='ClientPreviewPane-headerContainer'>
                <div className={styles['left-container']}>
                  <DefaultButton
                    unstyled
                    customClass={styles['client-name']}
                    onClick={() => goToClientHome(clientDetails)}
                  >
                    {getClientFullName(clientDetails, isPreferredNameEnabled)}
                  </DefaultButton>
                  {renderLeftHandPreviewPaneHeader()}
                  {clientDetails.country && !isEmpty(clientDetails.country) && clientDetails.country !== 'US' && (
                    <div className={classNames(styles['icon-container'], styles['country-container'])}>
                      {/* @ts-expect-error TS(2322): Type '{ children: Element; content: Element; iconI... Remove this comment to see the full error message */}
                      <LyraPopover
                        content={
                          <div>
                            This client is located in {getCountryDisplayName(clientDetails.country)}. For more guidance
                            on country specifics,{' '}
                            <a
                              href='https://coaching-lyrahealth.zendesk.com/hc/en-us/articles/6282342838675'
                              target='_blank'
                              rel='noreferrer'
                            >
                              go to Zendesk article to learn more.
                            </a>
                          </div>
                        }
                        iconId='life-impairment-question-popover'
                        openOnHover={true}
                        keepOpenOnHover={true}
                        placement='bottom'
                        customClass={styles.popover}
                      >
                        <CountryIcon countryCode={clientDetails.country} />
                      </LyraPopover>
                    </div>
                  )}
                  {clientDetails.self_harm && (
                    <Tooltip
                      content={`Ideation • ${clientDetails.self_harm_plan ? 'Plan' : 'No plan'} • ${
                        clientDetails.self_harm_intent ? 'Intent' : 'No intent'
                      }`}
                      placement={TOOLTIP_PLACEMENTS.TOP}
                    >
                      <SelfHarmIndicator />
                    </Tooltip>
                  )}
                  {clientDetails.eligibility_fields?.nuclear_badge_flag &&
                    affirmativeResponses.includes(clientDetails.eligibility_fields?.nuclear_badge_flag) && (
                      <SpecialAlertIndicator alertType='nuclearBadged' tooltipPlacement={TOOLTIP_PLACEMENTS.BOTTOM} />
                    )}
                  {episodeWarning &&
                    currentEpisode?.program_name &&
                    programConfig[currentEpisode?.program_name].displayCloseEpisodeBtn && (
                      <DefaultButton
                        unstyled
                        customClass={styles['close-episode']}
                        onClick={() => goToClientHome(clientDetails)}
                        data-test-id='ClientPreviewPane-close-episode'
                      >
                        <Label text='close episode' textColor={styles.x_alert} backgroundColor={styles.x_white} />
                      </DefaultButton>
                    )}
                </div>
                <div className={styles['right-container']}>
                  <IconBadge
                    badgeCount={unreadAssignments || 0}
                    width={20}
                    onClick={() => goToClientHome(clientDetails)}
                    data-test-id={`ClientPreviewPane-assignments-icon-count-${unreadAssignments || 0}`}
                  >
                    <ListIcon
                      fillColor={unreadAssignments > 0 ? styles.x_dark_gray : styles.x_light_gray_70}
                      isFilled
                      width={20}
                    />
                  </IconBadge>
                  <MessageIcon
                    fillColor={styles.x_medium_gray}
                    isFilled
                    withBadge
                    width={24}
                    className='message-icon'
                    unreadMessages={unreadMessages}
                    onMouseDown={() => goToClientHome(clientDetails)}
                    data-test-id={`ClientPreviewPane-messages-icon-count-${unreadMessages}`}
                  />
                  <div>
                    <DefaultButton
                      isOutlined
                      data-test-id='ClientPreviewPane-view-more'
                      onClick={() => goToClientHome(clientDetails)}
                    >
                      View more
                    </DefaultButton>
                  </div>
                </div>
              </div>
              <div className={styles['row-container']}>
                {renderPersonalRow()}
                {renderEpisodeRow()}
                {renderBenefitsRow()}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  )
}

type ClientPreviewPaneProps = {
  selectedClient?: ClientListClientObject | null
  userId?: string
  currentEpisode?: Episode
  assignments?: Assignment[]
  customers?: CustomerInfo[]
  messageConversation?: ConversationObject
  actions: {
    selectLtClient: (client: ClientObject) => void
    getLtClient: ({
      clientId,
      providerId,
      cancelToken,
    }: {
      clientId?: string
      providerId?: string
      cancelToken?: CancelToken
    }) => Promise<{}>
    getAssignments: ({
      patient_id,
      provider_id,
      cancelToken,
    }: {
      patient_id?: string
      provider_id?: string
      cancelToken?: CancelToken
    }) => Promise<{}>
    getClientEpisodes: ({
      patient_id,
      provider_id,
      cancelToken,
    }: {
      patient_id?: string
      provider_id?: string
      cancelToken?: CancelToken
    }) => Promise<{}>
    getMessageConversation: ({
      patient_lyra_id,
      provider_lyra_id,
      cancelToken,
    }: {
      patient_lyra_id?: string
      provider_lyra_id?: string
      cancelToken?: CancelToken
    }) => Promise<{}>
  }
}

const mapStateToProps = ($$state: Map<string, any>) => {
  const userId = getAuthUserId($$state)
  const currentEpisode = getClientEpisodesData($$state)
    .filter((episode) => {
      return episode.provider_id === userId && episode
    })
    .sort((a, b) => {
      return new Date(b.create_date).getTime() - new Date(a.create_date).getTime()
    })[0]
  const assignments = getClientAssignmentsData($$state).filter(
    (assignment) => assignment.episode_id === currentEpisode?.id,
  )
  return {
    currentEpisode,
    assignments,
    customers: getRequestPaymentCustomers($$state),
    messageConversation: getSelectedConversation($$state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): any => {
  return {
    actions: bindActionCreators(
      {
        selectLtClient,
        getLtClient,
        getAssignments,
        getClientEpisodes,
        getMessageConversation,
      },
      dispatch,
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(toJS(ClientPreviewPane))
