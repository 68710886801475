import React, { FunctionComponent } from 'react'
import Svg, { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

export const PaymentIllustration: FunctionComponent<PaymentIllustrationProps> = ({ size = 48 }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 48 48' fill='none'>
      <G clip-path='url(#clip0_2750_46043)'>
        <Path
          d='M23.751 1C12.791 1 3.30104 8.04 0.68104 18.13C-0.39896 22.3 -0.19896 26.96 1.26104 31.27C2.76104 35.73 5.53104 39.52 9.05104 41.93C13.531 45 19.081 46.77 24.291 46.77C26.241 46.77 28.141 46.53 29.941 46.05C37.891 43.94 44.181 38.15 46.771 30.56C49.241 23.33 47.981 15.71 43.331 9.64C39.211 4.28 31.941 1.05 23.891 1H23.751Z'
          fill='white'
        />
        <Path
          d='M23.8808 2.71905C30.6808 2.75905 37.7908 5.24905 41.9608 10.6891C51.3408 22.9291 44.6208 40.3791 29.4908 44.3891C22.9408 46.1291 15.5208 44.2891 10.0208 40.5091C3.26078 35.8791 0.320776 26.3691 2.35078 18.5591C4.87078 8.82905 14.0508 2.66905 23.8808 2.71905Z'
          fill='#CBECEF'
        />
        <Path
          d='M33.7287 33.54C29.8187 33.47 13.0987 33.21 11.3387 31.99C9.93874 31.03 10.3787 28.61 10.4287 27.05C10.5087 24.38 10.7787 21.71 11.2187 19.07C11.4287 17.78 11.5787 15.75 12.8387 14.97C14.3687 14.03 27.8887 14.51 32.0687 14.91C34.8787 15.18 37.7987 15.36 37.7087 18.91C37.6187 22.39 37.5687 25.69 37.2887 29.47C37.1987 30.7 37.3187 32.63 36.0587 33.32C35.5887 33.57 33.9887 33.55 33.7387 33.55H33.7287V33.54Z'
          fill='#FED2C4'
        />
        <Path
          d='M19.7085 14.4307C24.2185 14.4307 29.6785 14.6907 32.0685 14.9207C34.8785 15.1907 37.7985 15.3707 37.7085 18.9207C37.6185 22.4007 37.5685 25.7007 37.2885 29.4807C37.1985 30.7107 37.3185 32.6407 36.0585 33.3307C35.6885 33.5307 34.5985 33.5607 34.0385 33.5607C33.8985 33.5607 33.7885 33.5607 33.7385 33.5607H33.7285C29.8185 33.4907 13.0985 33.2307 11.3385 32.0107C9.93849 31.0507 10.3785 28.6307 10.4285 27.0707C10.5085 24.4007 10.7785 21.7307 11.2185 19.0907C11.4285 17.8007 11.5785 15.7707 12.8385 14.9907C13.4985 14.5907 16.3385 14.4407 19.7085 14.4407V14.4307ZM19.7085 12.7207C13.2385 12.7207 12.4285 13.2207 11.9385 13.5207C10.1485 14.6207 9.80849 16.8507 9.58849 18.3307C9.56849 18.5007 9.53849 18.6607 9.51849 18.8007C9.06849 21.5107 8.78849 24.2707 8.69849 27.0107C8.69849 27.2407 8.67849 27.4907 8.65849 27.7507C8.54849 29.4707 8.36849 32.0607 10.3485 33.4307C11.3785 34.1407 14.0785 34.9307 33.2585 35.2707H33.7085C33.7785 35.2707 33.8885 35.2807 34.0285 35.2807C35.8185 35.2807 36.4685 35.0607 36.8685 34.8407C38.8085 33.7907 38.9085 31.5007 38.9685 30.1307C38.9685 29.9507 38.9885 29.7707 38.9985 29.6107C39.2385 26.3707 39.3085 23.5607 39.3785 20.5807C39.3885 20.0507 39.4085 19.5107 39.4185 18.9707C39.5485 13.9207 35.1185 13.5007 32.4685 13.2407L32.2285 13.2207C29.7585 12.9807 24.2685 12.7307 19.6985 12.7307L19.7085 12.7207Z'
          fill='white'
        />
        <Path
          d='M33.6192 29.5208C33.5592 29.5208 33.5093 29.5208 33.4493 29.5208C32.7993 29.4908 32.1593 29.4008 31.5093 29.3708C31.1093 29.3508 30.4392 29.4008 30.2092 28.9908C30.1492 28.8908 30.1493 28.7708 30.1493 28.6508C30.1393 27.9608 30.1193 27.2308 30.2193 26.5508C30.2493 26.2808 30.2393 26.0108 30.2693 25.7408C30.2993 25.5208 30.3493 25.2908 30.4993 25.1208C30.8093 24.7408 31.3393 24.8108 31.7693 24.8508C32.6193 24.9308 33.5393 24.8508 34.3593 25.0608C34.9993 25.2208 35.1992 25.5708 35.0992 26.2208C34.9692 27.1108 34.8493 28.0108 34.7293 28.9008C34.6493 29.5208 34.1292 29.5508 33.6192 29.5308V29.5208Z'
          fill='#F69170'
        />
        <Path
          d='M11.2066 19.081C11.1266 19.591 11.0466 20.101 10.9766 20.611L37.6166 21.821C37.6466 20.861 37.6666 19.891 37.6866 18.911C37.6866 18.681 37.6866 18.461 37.6666 18.251L11.4566 17.541C11.3466 18.091 11.2766 18.621 11.2066 19.071V19.081Z'
          fill='#F69170'
        />
        <Path
          d='M21.5495 26.4402L12.9595 25.9902C12.6695 25.9802 12.4395 25.7202 12.4495 25.4302C12.4595 25.1402 12.7195 24.9102 13.0095 24.9202L21.5995 25.3702C21.8895 25.3802 22.1195 25.6402 22.1095 25.9302C22.0995 26.2202 21.8395 26.4502 21.5495 26.4402Z'
          fill='#F69170'
        />
        <Path
          d='M18.8367 28.6616L12.8267 28.3516C12.5367 28.3416 12.3067 28.0816 12.3167 27.7916C12.3267 27.5016 12.5867 27.2716 12.8767 27.2816L18.8867 27.5916C19.1767 27.6016 19.4067 27.8616 19.3967 28.1516C19.3867 28.4416 19.1267 28.6716 18.8367 28.6616Z'
          fill='#F69170'
        />
      </G>
      <Defs>
        <ClipPath id='clip0_2750_46043'>
          <Rect width='48' height='45.77' fill='white' transform='translate(0 1)' />
        </ClipPath>
      </Defs>
    </Svg>
  )
}

type PaymentIllustrationProps = {
  size?: string | number
}
