import { AxiosResponse } from 'axios'

import {
  actionAlertHandler,
  actionStyles,
  CONFIRM_ONLINE_BOOKING,
  GET_SCHEDULING,
  GET_SCHEDULING_UPDATED,
  SUBMIT_SCHEDULING_OAUTH,
  UPDATE_CALENDAR_USE,
} from '../../common/constants/reduxConstants'
import {
  confirmOnlineBooking,
  getScheduling,
  submitCalendarUse,
  submitSchedulingOAuth,
} from '../../common/http/data/scheduling'

const schedulingPromiseHelper = (schedulingPromise: Promise<AxiosResponse>, promiseType: string) => {
  return (dispatch: any) => {
    return new Promise(function (resolve, reject) {
      schedulingPromise.then(
        (schedulingReturn) => {
          dispatch({
            type: promiseType,
            payload: schedulingReturn,
          })

          resolve(schedulingReturn)
        },
        (error) => {
          actionAlertHandler({
            actionStyle: actionStyles.ERROR,
            message: error,
            dispatch,
            expires: true,
            action: promiseType,
          })
          reject(error)
        },
      )
    })
  }
}

export const getSchedulingData = (providerId: string) => {
  const request = getScheduling(providerId)
  return schedulingPromiseHelper(request, GET_SCHEDULING)
}

export const enableCalendar = (providerId: string) => {
  const request = confirmOnlineBooking(providerId)
  return schedulingPromiseHelper(request, CONFIRM_ONLINE_BOOKING)
}

export const updateCalendarUse = (data: { id: string; use: string }, providerId: string) => {
  const request = submitCalendarUse(data, providerId)
  return schedulingPromiseHelper(request, UPDATE_CALENDAR_USE)
}

// This is meant to only update the availabilities portion of the state after we submit a calendar use update
export const getSchedulingDataUpdated = (providerId: string) => {
  const request = getScheduling(providerId)
  return schedulingPromiseHelper(request, GET_SCHEDULING_UPDATED)
}

export const updateSchedulingOAuth = (data: { code: string }, providerId: string) => {
  const request = submitSchedulingOAuth(data, providerId)
  return schedulingPromiseHelper(request, SUBMIT_SCHEDULING_OAUTH)
}
