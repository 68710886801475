import {
  CLEAR_LT_CLIENTS,
  GET_LT_ACTIVE_CLIENTS_V2,
  GET_LT_INACTIVE_CLIENTS_V2,
  GET_LT_ACTIVE_CLIENTS_V2_BY_PROGRAM,
  GET_LT_INACTIVE_CLIENTS_V2_BY_PROGRAM,
  GET_CLIENTS_COUNTS,
  SET_INITIAL_ACTIVE_CLIENTS_FETCHED,
  SET_INITIAL_INACTIVE_CLIENTS_FETCHED,
} from '../../../common/constants/reduxConstants'

export const getLtClientsV2 = ({ providerId, status, cancelToken }: any) => ({
  action: status === 'active' ? GET_LT_ACTIVE_CLIENTS_V2 : GET_LT_INACTIVE_CLIENTS_V2,
  request: {
    method: 'get',
    url: '/v2/clients',
    params: { providerId, status },
    cancelToken,
  },
})

export const getLtClientsV2ByProgram = ({ providerId, status, programName, cancelToken }: any) => ({
  action: status === 'active' ? GET_LT_ACTIVE_CLIENTS_V2_BY_PROGRAM : GET_LT_INACTIVE_CLIENTS_V2_BY_PROGRAM,
  request: {
    method: 'get',
    url: '/v2/clients',
    params: { providerId, status, program_name: programName },
    cancelToken,
  },
  value: programName,
})

export const setInitialActiveClientsFetched = () => ({ type: SET_INITIAL_ACTIVE_CLIENTS_FETCHED })

export const setInitialInActiveClientsFetched = () => ({ type: SET_INITIAL_INACTIVE_CLIENTS_FETCHED })

export const clearLtClients = () => ({ type: CLEAR_LT_CLIENTS })

export const getClientsCounts = ({ cancelToken, ...data }: any) => ({
  action: GET_CLIENTS_COUNTS,
  request: {
    method: 'post',
    url: '/lt/v1/counts',
    data,
    cancelToken,
  },
})
