import React from 'react'
import { useNavigate } from 'react-router-dom'

import styled, { useTheme } from 'styled-components/native'

import { NavBar, ProgressBar, ThemeType, tID } from '@lyrahealth-inc/ui-core-crossplatform'

import { actions, mixpanelEvents } from '../../../../mixpanel/mixpanelConstants'
import { track } from '../../../../mixpanel/mixpanelTracking'
import { SCHEDULING } from '../../common/constants/routingConstants'

const FixedContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  position: 'sticky',
  top: '0px',
  left: '0px',
  right: '0px',
  zIndex: 100,
  background: theme.colors.backgroundSecondary,
}))

const ProgressBarContainer = styled(ProgressBar)({
  marginHorizontal: '16px',
  marginBottom: '12px',
})

type SchedulingWalkthroughNavBarProps = {
  screenIndex: number
}

export const SchedulingWalkthroughNavBar: React.FC<SchedulingWalkthroughNavBarProps> = ({ screenIndex }) => {
  const { colors } = useTheme()
  const navigate = useNavigate()
  const totalPages = screenIndex > 3 ? 8 : 4
  const percentProgress = screenIndex / totalPages

  return (
    <>
      <FixedContainer testID={tID('SchedulingWalkthroughNavBar')}>
        <NavBar
          onBack={() => {
            track({ event: mixpanelEvents.BUTTON_PRESS, action: actions.SCHEDULING_WALKTHROUGH_BACK })
            navigate(-1)
          }}
          onExit={() => {
            track({ event: mixpanelEvents.BUTTON_PRESS, action: actions.SCHEDULING_WALKTHROUGH_EXIT })
            navigate(SCHEDULING.route)
          }}
          isTransparent={true}
        />
        <ProgressBarContainer
          progress={percentProgress}
          backgroundColor={colors.progressIndicatorBackgroundTop}
          color={colors.progressIndicator}
          width={-1}
          height={5}
          borderRadius={5}
          duration={300}
        />
      </FixedContainer>
    </>
  )
}
