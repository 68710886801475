import React, { Component } from 'react'
import styles from './notifications.module.scss'
// @ts-expect-error TS(7016): Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import NotificationSystem from 'react-notification-system'

type Props = {
  setRef: $TSFixMeFunction
}

/*
  This is merely a Lyra styling wrapper for the plugin found here: http://igorprado.com/react-notification-system/.
  Use their documented implementation within the parent component mounting this UI-Core module
  (so we don't obscure with needless functional middlemen)
*/
class Notifications extends Component<Props> {
  render() {
    return (
      <div className={styles['lyra-notifications']}>
        <NotificationSystem ref={this.props.setRef} style={false} />
      </div>
    )
  }
}

export default Notifications
