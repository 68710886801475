import React from 'react'

import { useTheme } from 'styled-components/native'

import { InfoIcon } from '../../atoms'
import { InlineBanner } from '../inlineBanner/InlineBanner'

export interface InlineInfoBannerProps {
  text: React.ReactNode | string
  testId?: string
  backgroundColor?: string
  iconColor?: string
  textColor?: string
  marginBottom?: string
  marginTop?: string
}

export const InlineInfoBanner: React.FC<InlineInfoBannerProps> = ({
  text,
  testId,
  backgroundColor,
  iconColor,
  textColor,
  marginBottom,
  marginTop,
}) => {
  const { colors } = useTheme()
  const { inlineInfoBanner } = colors.components

  const icon = <InfoIcon size={15} fillColor={iconColor || inlineInfoBanner.infoIcon.fill} />
  return (
    <InlineBanner
      text={text}
      icon={icon}
      testId={testId}
      backgroundColor={backgroundColor || inlineInfoBanner.background}
      textColor={textColor || inlineInfoBanner.text}
      marginBottom={marginBottom}
      marginTop={marginTop}
    />
  )
}
