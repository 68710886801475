import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'

import styled from 'styled-components/native'

import { Subhead } from '../../atoms'
import { SubheadSize } from '../../styles'
import { tID } from '../../utils'
import { ToggleSwitch } from '../toggleSwitch/ToggleSwitch'

const Container = styled.View(({ theme }) => ({
  gap: theme.spacing['8px'],
  flexBasis: 0,
  flexGrow: 1,
}))

export type ZoomVideoToggleControlProps = {
  value: boolean
  onPress: () => void
}

export const ZoomVideoToggleControl: FunctionComponent<ZoomVideoToggleControlProps> = ({ value, onPress }) => {
  return (
    <Container testID={tID('ZoomVideoToggleControl-container')}>
      <Subhead
        size={SubheadSize.SMALL}
        text={
          <FormattedMessage
            defaultMessage='Client video'
            description='Label for toggle switch to control whether to show video'
          />
        }
      />
      <ToggleSwitch testID={tID('ZoomVideoToggleControl-toggle')} isOn={value} onPress={onPress} />
    </Container>
  )
}
