import React, { FunctionComponent, useState } from 'react'

import styled from 'styled-components/native'

import { AvatarDetails } from '../../atoms'
import { useZoom } from '../../hooks/useZoom'
import { RowView } from '../../templates'
import { tID } from '../../utils'
import { ZoomVideoBannerInfo } from '../zoomVideoBannerInfo/ZoomVideoBannerInfo'
import { ZoomVideoBannerVideos } from '../zoomVideoBannerVideos/ZoomVideoBannerVideos'
import { ZoomVideoControls } from '../zoomVideoControls/ZoomVideoControls'
import { ZoomVideoToggleControl } from '../zoomVideoToggleControl/ZoomVideoToggleControl'

const Container = styled(RowView)(({ theme }) => ({
  alignItems: 'center',
  gap: theme.spacing['8px'],
  justifyContent: 'space-between',
  paddingLeft: theme.spacing['16px'],
  paddingRight: theme.spacing['16px'],
  backgroundColor: theme.colors.components.zoomVideoSession.background,
  height: '90px',
  border: theme.isDarkMode ? undefined : `1px solid ${theme.colors.borderDefault}`,
  zIndex: 200,
}))

export type ZoomVideoBannerProps = {
  participantName: string
  sessionStartDate: Date
  sessionDuration: number
  participantFirstName?: string
  showRecordingStatus?: boolean
  isRecording?: boolean
  avatarDetails?: AvatarDetails
  sessionNumber?: number
  messenger?: React.ReactElement
  onDisableRecordingPressed?: () => void
}

export const ZoomVideoBanner: FunctionComponent<ZoomVideoBannerProps> = ({
  participantName,
  sessionStartDate,
  sessionDuration,
  participantFirstName,
  showRecordingStatus,
  isRecording,
  avatarDetails,
  sessionNumber,
  messenger,
  onDisableRecordingPressed,
}) => {
  const { isScreensharing } = useZoom()
  const [displayVideos, setDisplayVideos] = useState(false)
  return (
    <Container testID={tID('ZoomVideoBanner-container')}>
      {isScreensharing ? (
        <ZoomVideoToggleControl value={displayVideos} onPress={() => setDisplayVideos((prev) => !prev)} />
      ) : (
        <ZoomVideoBannerInfo
          participantName={participantName}
          sessionStartDate={sessionStartDate}
          sessionDuration={sessionDuration}
          showRecordingStatus={showRecordingStatus}
          isRecording={isRecording}
          avatarDetails={avatarDetails}
          sessionNumber={sessionNumber}
        />
      )}

      <ZoomVideoBannerVideos participantFirstName={participantFirstName} displayVideos={displayVideos} />
      <ZoomVideoControls
        displayVideos={displayVideos}
        messenger={messenger}
        isRecording={isRecording}
        onDisableRecordingPressed={onDisableRecordingPressed}
      />
    </Container>
  )
}
