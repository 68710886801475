import { format, isValid, parse } from 'date-fns'

const sessionResetDate = ({ client, employer, date }: any) => {
  if (client) {
    employer = client.displayed_employer
    date = client.session_count_starts_on
  }
  const sessionResetDate = parse(date, 'MMM-dd', new Date())

  return isValid(sessionResetDate) ? `${employer}’s benefits reset on ${format(sessionResetDate, 'MMMM do')}.` : ''
}

export default sessionResetDate
