import React from 'react'
import colors from '../../../styles/_0colors.scss'
import { Icon } from './types'

const DownloadIcon: React.FC<DownloadIconProps> = ({
  width = 20,
  fillColor = colors.x_light_gray_10,
  isFilled = false,
  ...props
}) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} viewBox='0 0 24 24' {...props}>
      {isFilled ? (
        <path
          fill={fillColor}
          d='M21,18 L21,14 C21,13.448 20.553,13 20,13 C19.447,13 19,13.448 19,14 L19,18 C19,18.551 18.552,19 18,19 L6,19 C5.448,19 5,18.551 5,18 L5,14 C5,13.448 4.553,13 4,13 C3.447,13 3,13.448 3,14 L3,18 C3,19.654 4.346,21 6,21 L18,21 C19.654,21 21,19.654 21,18 M16.71,11.71 L12.71,15.71 C12.61,15.8 12.5,15.87 12.39,15.92 C12.34,15.94 12.3,15.96 12.25,15.96 C12.17,15.99 12.09,16 12,16 C11.91,16 11.83,15.99 11.75,15.96 C11.7,15.96 11.66,15.94 11.61,15.92 C11.5,15.87 11.39,15.8 11.29,15.71 L7.29,11.71 C6.9,11.32 6.9,10.68 7.29,10.29 C7.68,9.9 8.32,9.9 8.71,10.29 L11,12.59 L11,4 C11,3.45 11.45,3 12,3 C12.55,3 13,3.45 13,4 L13,12.59 L15.29,10.29 C15.68,9.9 16.32,9.9 16.71,10.29 C17.1,10.68 17.1,11.32 16.71,11.71'
        />
      ) : (
        <path
          fill={fillColor}
          d='M16.7803,11.2798 L12.5303,15.5298 C12.4603,15.5998 12.3803,15.6598 12.2903,15.6898 C12.2003,15.7298 12.0993,15.7498 12.0003,15.7498 C11.9003,15.7498 11.8003,15.7298 11.7103,15.6898 C11.6203,15.6598 11.5403,15.5998 11.4693,15.5298 L7.2193,11.2798 C6.9293,10.9898 6.9293,10.5098 7.2193,10.2198 C7.5093,9.9298 7.9903,9.9298 8.2803,10.2198 L11.2503,13.1898 L11.2503,3.7498 C11.2503,3.3398 11.5903,2.9998 12.0003,2.9998 C12.4103,2.9998 12.7503,3.3398 12.7503,3.7498 L12.7503,13.1898 L15.7193,10.2198 C16.0093,9.9298 16.4903,9.9298 16.7803,10.2198 C17.0703,10.5098 17.0703,10.9898 16.7803,11.2798 M20.2503,12.9998 C19.8403,12.9998 19.5003,13.3398 19.5003,13.7498 L19.5003,17.9998 C19.5003,18.8298 18.8303,19.4998 18.0003,19.4998 L6.0003,19.4998 C5.1703,19.4998 4.5003,18.8298 4.5003,17.9998 L4.5003,13.7498 C4.5003,13.3398 4.1603,12.9998 3.7503,12.9998 C3.3403,12.9998 3.0003,13.3398 3.0003,13.7498 L3.0003,17.9998 C3.0003,19.6498 4.3493,20.9998 6.0003,20.9998 L18.0003,20.9998 C19.6503,20.9998 21.0003,19.6498 21.0003,17.9998 L21.0003,13.7498 C21.0003,13.3398 20.6603,12.9998 20.2503,12.9998'
        />
      )}
    </svg>
  )
}

type DownloadIconProps = Icon

export default DownloadIcon
