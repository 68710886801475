import dateUtils from './dateUtils'
import { get, isEmpty, sortBy, reject } from 'lodash-es'
import moment from 'moment'
import { List } from 'immutable'
import { convertProviderTreatmentModalityToTreatmentOption } from '../../lib/constants/providerTreatmentModalityConstants'

const { getAppointmentDateTimeObject } = dateUtils
const createAppointmentPayload = ({
  providerID = null,
  providerModalities = null,
  userID,
  appointment,
  referenceAppointment,
}: $TSFixMe) => {
  const treatmentType = appointment.get('treatmentType', referenceAppointment.get('treatmentType'))
  const modalities = providerModalities ?? appointment.getIn(['provider', 'modalities'], List())
  const selectedProviderModality = modalities.find(
    (modality: $TSFixMe) => convertProviderTreatmentModalityToTreatmentOption(modality) === treatmentType,
  )

  const payload = {
    address: appointment.get('address', referenceAppointment.get('address', '')),
    appointmentDuration: appointment.get('appointmentDuration', 50),
    appointmentId: appointment.get('appointmentId'),
    appointmentStatus: appointment.get('appointmentStatus', referenceAppointment.get('appointmentStatus')),
    appointmentType: appointment.get('appointmentType', referenceAppointment.get('appointmentType', 'googleCalendar')),
    details: appointment.get('details', referenceAppointment.get('details') || ''),
    eventId: appointment.get('eventId'),
    lyraProviderId: providerID || appointment.get('lyraProviderId', referenceAppointment.get('lyraProviderId')),
    meetingFormat: appointment.get('meetingFormat', 'video'),
    phone: appointment.get('phone', referenceAppointment.get('phone')),
    phoneType: appointment.get('phoneType', referenceAppointment.get('phoneType')),
    selectedProviderModality,
    startDate: appointment.get('startDate'),
    startTime: appointment.get('startTime'),
    supportedVisitTypes: appointment.get('supportedVisitTypes', referenceAppointment.get('supportedVisitTypes') || 1),
    textAppDownloadLink: false,
    title: appointment.get('title', 'New Appointment'),
    timeZone: appointment.get('timeZone'),
    treatmentType: appointment.get('treatmentType', referenceAppointment.get('treatmentType')),
    userId: userID || appointment.get('userId', referenceAppointment.get('userId')),
  }
  return payload
}

// Sorts a given `List` of providers by appointment date
const sortProviders = (providers: $TSFixMe) => {
  if (isEmpty(providers)) return []

  // Find out which providers have upcoming appointments for the client, and which do not
  const hasFutureApptFilter = (provider: $TSFixMe) => {
    // @ts-expect-error TS(2345): Argument of type 'Moment' is not assignable to par... Remove this comment to see the full error message
    return provider.appointments.some((appt: $TSFixMe) => getAppointmentDateTimeObject(appt).isAfter(moment()))
  }
  const upcomingProviders = providers.filter(hasFutureApptFilter)
  const pastProviders = reject(providers, hasFutureApptFilter)

  // Sort the providers with upcoming appointments by date descending (soonest first),
  //   and sort the providers without upcoming appointments by date ascending (most recent first)
  return [
    ...sortBy(upcomingProviders, (provider) => {
      // Sort by soonest appointment date for each provider in $$upcomingProviders
      let soonestAppt = get(provider, 'appointments[0]')
      provider.appointments.forEach((appt: $TSFixMe) => {
        if (getAppointmentDateTimeObject(appt).isAfter(getAppointmentDateTimeObject(soonestAppt))) {
          soonestAppt = appt
        }
      })

      // return this provider's soonest appointment date, to sort by
      return getAppointmentDateTimeObject(soonestAppt)
    }),
    ...sortBy(pastProviders, (provider) => {
      // Sort by last appointment date for each provider in $$pastProviders
      let lastAppt = get(provider, 'appointments[0]')
      provider.appointments.forEach((appt: $TSFixMe) => {
        if (getAppointmentDateTimeObject(appt).isAfter(getAppointmentDateTimeObject(lastAppt))) {
          lastAppt = appt
        }
      })

      // return this provider's last appointment date, to sort by
      return getAppointmentDateTimeObject(lastAppt)
    }).reverse(),
  ]
}

// Extracts `nearestAddress` data from an appointment object and then generates a Google Maps URL
const getMapUrlForAppointment = (appointment: $TSFixMe) => {
  const mapUrlBase = 'https://www.google.com/maps/place/'

  if (!appointment) {
    return
  }

  const address = appointment?.provider?.nearestAddress ?? null
  const location = address ? address?.location : null
  let addressQuery = ''
  let locationQuery = ''

  if (!address) {
    return
  }

  if (address) {
    const street1 = address?.street1
    const street2 = address?.street2 ? `, ${address?.street2}` : ''
    const city = address?.city
    const state = address?.state
    const zipcode = address?.zipcode

    addressQuery = `${street1}${street2}, ${city}, ${state} ${zipcode}`.replace(/ /g, '+')
  }
  if (location) {
    const lat = location?.lat
    const lon = location?.lon
    const zoom = '16z' // hard-coded zoom level

    locationQuery = `/@${lat},${lon},${zoom}`
  }

  return mapUrlBase + addressQuery + locationQuery
}

export default {
  createAppointmentPayload,
  sortProviders,
  getMapUrlForAppointment,
}
