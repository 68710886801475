import React, { FunctionComponent } from 'react'

import styled, { useTheme } from 'styled-components/native'

import { BodyText } from '../bodyText/BodyText'

export type DeletedLiveMessageTextProps = {
  text?: string
  fontColor?: string
  testID?: string
}

const DeletedLiveMessageTextContainer = styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
`

/**
 * Text bubble that is shown in the messenger for each message
 */
let DeletedLiveMessageText: FunctionComponent<DeletedLiveMessageTextProps> = ({
  text = 'To protect your privacy, live messaging sessions have been removed.',
  fontColor,
  testID = 'deleted-live-message',
}) => {
  const { colors } = useTheme()

  return (
    <DeletedLiveMessageTextContainer>
      <BodyText testID={testID} style={{ color: fontColor || colors.textPrimary, textAlign: 'center' }}>
        {text}
      </BodyText>
    </DeletedLiveMessageTextContainer>
  )
}

DeletedLiveMessageText = React.memo(DeletedLiveMessageText)

export { DeletedLiveMessageText }
