import React, { FunctionComponent } from 'react'
import { Path, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

/**
 * Icon for Face ID
 */
export const FaceIdIcon: FunctionComponent<FaceIdIconProps> = ({ size = 24 }) => {
  const { colors } = useTheme()

  return (
    <Svg width={size} height={size} fill='none'>
      <Path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.25 6A3.75 3.75 0 0 1 6 2.25h1a.75.75 0 0 1 0 1.5H6A2.25 2.25 0 0 0 3.75 6v1a.75.75 0 0 1-1.5 0V6Zm4.5 6a5.25 5.25 0 1 1 10.5 0 5.25 5.25 0 0 1-10.5 0ZM12 5.25a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5Zm-2.06 7.752a.75.75 0 0 1 1.05-.07l.002.002a1.458 1.458 0 0 0 .233.14c.179.088.442.176.775.176s.596-.088.774-.175a1.455 1.455 0 0 0 .234-.14l.002-.003a.75.75 0 0 1 .988 1.129L13.5 13.5l.498.561-.002.001-.002.002-.005.005-.012.01a2.105 2.105 0 0 1-.151.115A3.27 3.27 0 0 1 12 14.75a3.271 3.271 0 0 1-1.429-.325 2.956 2.956 0 0 1-.547-.346l-.013-.01-.005-.005-.002-.002h-.001v-.001l.497-.561-.498.56a.75.75 0 0 1-.063-1.058Zm.56-2.252a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm3.75.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM17 2.25a.75.75 0 0 0 0 1.5h1A2.25 2.25 0 0 1 20.25 6v1a.75.75 0 0 0 1.5 0V6A3.75 3.75 0 0 0 18 2.25h-1Zm4 14a.75.75 0 0 1 .75.75v1A3.75 3.75 0 0 1 18 21.75h-1a.75.75 0 0 1 0-1.5h1A2.25 2.25 0 0 0 20.25 18v-1a.75.75 0 0 1 .75-.75ZM3.75 17a.75.75 0 0 0-1.5 0v1A3.75 3.75 0 0 0 6 21.75h1a.75.75 0 0 0 0-1.5H6A2.25 2.25 0 0 1 3.75 18v-1Z'
        fill={colors.iconActive}
      />
    </Svg>
  )
}

type FaceIdIconProps = {
  size?: string | number
}
