import React, { ReactNode } from 'react'
import { FormattedDateTimeRange, FormattedTime } from 'react-intl'
import useIsMobile from '../../hooks/useIsMobile'
import styles from './sessionBannerInfo.module.scss'

const SessionBannerInfo: React.FC<SessionBannerInfoProps> = ({ sessionTitle, icon, startTime, endTime }) => {
  const [isMobile] = useIsMobile()

  const renderSessionTime = () => {
    return isMobile ? (
      <FormattedTime value={startTime} timeZoneName='short' />
    ) : (
      <>
        <FormattedDateTimeRange from={startTime} to={endTime} hour='numeric' minute='numeric' timeZoneName='short' />
      </>
    )
  }

  return (
    <div className={styles['session-banner-info']}>
      <span className={styles['session-banner-title']}>{sessionTitle}</span>
      <div className={styles['session-banner-schedule']}>
        <div className={styles['session-banner-icon']}>{icon}</div>
        <div className={styles['session-banner-time']}>{renderSessionTime()}</div>
      </div>
    </div>
  )
}

type SessionBannerInfoProps = {
  sessionTitle: string
  icon: ReactNode | null
  startTime: Date
  endTime: Date
}

export default SessionBannerInfo
