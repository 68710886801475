import React, { FunctionComponent } from 'react'

import styled from 'styled-components/native'

import { tID } from '../../utils'
import { Image } from '../image/Image'

const ImageContainer = styled(Image)<{ size: string }>(({ size }) => ({
  width: size,
  height: size,
}))

/**
 * Illustration to show on the treatment card
 */

export const Coaching: FunctionComponent<CoachingProps> = ({ size = 124 }) => {
  const sizeInPixels = `${size}px`
  return (
    <ImageContainer
      size={sizeInPixels}
      testID={`${tID('CoachingIllustration')}`}
      source={require('../../assets/coaching.png')}
    />
  )
}

type CoachingProps = {
  size?: number
}
