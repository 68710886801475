import validation from '../../../common/utils/validation'

const accountTypeValidate = ($$values: any) => {
  let errors = {}

  const justRequired = [
    {
      fieldName: 'account_type',
      errorText: 'Account type',
    },
  ]

  errors = Object.assign(errors, validation.requiredFields($$values, justRequired))

  return errors
}

export default accountTypeValidate
