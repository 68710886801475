import React, { FunctionComponent } from 'react'

import styled from 'styled-components/native'

import { Image } from '../image/Image'

type CareOptionCoachingIconProps = {
  size?: number
}

const CareOptionCoachingPNG = styled(Image)<{ size: number }>(({ size }) => ({
  width: `${size}px`,
  height: `${size}px`,
}))

export const CareOptionCoachingIcon: FunctionComponent<CareOptionCoachingIconProps> = ({ size = 64 }) => {
  return <CareOptionCoachingPNG size={size} source={require('./../../assets/careOptionCoaching.png')} />
}
