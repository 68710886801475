import React, { FunctionComponent, useContext } from 'react'
import {
  NativeSyntheticEvent,
  Platform,
  TextInputContentSizeChangeEventData,
  TextInputKeyPressEventData,
  View,
} from 'react-native'

import styled, { useTheme } from 'styled-components/native'

import { IS_WEB } from '../../constants'
import { AppContext } from '../../context'
import { getFontStyles } from '../../styles/typeStyles'
import { deviceIsSamsung, ThemeType, tID } from '../../utils'
import { BodyText } from '../bodyText/BodyText'

const TextInputContainer = styled.TextInput<{
  isIOS: boolean
  borderColor: string
  borderWidth?: number
  height: number
  isWeb: boolean
  theme: ThemeType
  outlineColor?: string
}>(({ isIOS, borderColor, borderWidth, height, theme, outlineColor, isWeb }) => ({
  borderColor,
  borderWidth,
  height,
  alignItems: 'center',
  paddingTop: isIOS ? theme.spacing['16px'] : isWeb ? theme.spacing['8px'] : 0,
  paddingBottom: isWeb ? theme.spacing['8px'] : 0,
  paddingLeft: isWeb ? theme.spacing['8px'] : 0,
  ...(IS_WEB && { outlineColor: outlineColor || theme.colors.iconActive }),
  ...getFontStyles(theme.colors).body.default,
  lineHeight: theme.spacing['24px'],
}))

const LabelContainer = styled(BodyText)<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: 8,
  fontWeight: 'bold',
  color: theme.colors.textPrimary,
}))

/**
 * Standard input field component allows inputValue to be passed in
 * and calls onInputChange callback if value changes
 *
 * TODO: Consolidate this field with `ui-core-crossplatform/atoms/formElements/InputField.tsx`
 */
export const BaseInputField: FunctionComponent<BaseInputFieldProps> = ({
  label,
  onInputChange,
  placeholderText,
  inputValue,
  height = 35,
  borderColor,
  borderWidth,
  onContentSizeChange,
  multiline = true,
  width = '80%',
  handleKeyPress,
  outlineColor,
  onFocus,
  onBlur,
}) => {
  const { copyPasteDisabled } = useContext(AppContext)
  const isSamsung = deviceIsSamsung()
  const { colors } = useTheme()

  return (
    <View style={{ width }}>
      {label !== undefined ? <LabelContainer>{label}</LabelContainer> : null}
      <TextInputContainer
        onKeyPress={(event) => handleKeyPress && handleKeyPress(event)}
        testID={tID('InputField')}
        onContentSizeChange={onContentSizeChange}
        onChangeText={(text) => {
          onInputChange(text)
        }}
        multiline={multiline}
        value={inputValue}
        borderColor={borderColor || colors.inputOutlineDefault}
        borderWidth={borderWidth}
        height={height}
        isIOS={Platform.OS === 'ios'}
        selectionColor={colors.backgroundActive}
        placeholder={placeholderText}
        contextMenuHidden={copyPasteDisabled}
        // See InputField.tsx for a description of this device specific logic
        caretHidden={copyPasteDisabled && isSamsung}
        isWeb={Platform.OS === 'web'}
        outlineColor={outlineColor}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholderTextColor={colors.inputTextPlaceholder}
      />
    </View>
  )
}

type BaseInputFieldProps = {
  label?: string
  borderColor?: string
  height?: number
  onInputChange: (text: string) => void
  placeholderText?: string
  inputValue?: string
  multiline?: boolean
  onContentSizeChange?: (event: NativeSyntheticEvent<TextInputContentSizeChangeEventData>) => void
  width?: string | number
  borderWidth?: number
  handleKeyPress?: (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => void
  outlineColor?: string
  onFocus?: () => void
  onBlur?: () => void
}
