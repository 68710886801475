/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Map } from 'immutable'
import { isEmpty } from 'lodash-es'

import { KEYS, SearchIcon } from '@lyrahealth-inc/ui-core'
import { toJS } from '@lyrahealth-inc/ui-core-crossplatform'

import styles from './goSearchBar.module.scss'
import GoSearchInput from './GoSearchInput'
import Hits from './Hits'
import { track } from '../../../../../mixpanel/mixpanelTracking'
import { CLIENT_HOME } from '../../../common/constants/routingConstants'
import { searchForClients } from '../../../common/utils/utils'
import { getActiveV2Clients, getInactiveV2Clients } from '../../../data/lyraTherapy/clientSelectors'
import { selectLtClient } from '../../../lyraTherapy/clients/clientDetails/data/ltClientDetailsAutoActions'
import { getLTVideoSessionOpen } from '../../../lyraTherapy/data/ltVideoSelectors'

export type Client = {
  first_name: string
  last_name: string
  id: string
}

/**
 * This component shows a overlayed search bar when hitting a magic key combination (CTRL + Space),
 * giving the user a typehead to search for clients and navigate to them. This is similar to the Spotlight search on Mac OS.
 */
const GoSearchBar: React.FC<ConnectedProps<typeof connector>> = ({
  videoSessionOpen,
  clientsActive = [],
  clientsInactive = [],
  selectLtClient,
}) => {
  const clients = [...clientsActive, ...clientsInactive]
  const [isGoOpen, setGoOpen] = useState(false)
  const [clientHits, setClientHits] = useState<Client[]>([])
  const navigate = useNavigate()

  const onHitClick = (hit: Client) => {
    track({ event: 'NAVIGATE_TO_CLIENT_FROM_GO_SEARCH_BAR', properties: { client: hit } })
    selectLtClient(hit)
    navigate(CLIENT_HOME.route)
    setClientHits([])
    setGoOpen(false)
  }

  // Close Go Search if start a video session.
  useEffect(() => {
    if (videoSessionOpen) {
      setClientHits([])
      setGoOpen(false)
    }
  }, [videoSessionOpen])

  useEffect(() => {
    const onMagicKeys = (event: { key: string; keyCode: number; ctrlKey: any; altKey: any }) => {
      // The magic key combination is CTRL + Space
      const isCtrlSpace = event.key === ' ' && event.ctrlKey
      const isAltSpace = event.key === ' ' && event.altKey
      if (isCtrlSpace || isAltSpace) {
        track({ event: isGoOpen ? 'CLOSE_GO_SEARCH_BAR' : 'OPEN_GO_SEARCH_BAR' })
        setClientHits([])
        setGoOpen(!isGoOpen)
      }
      if (event.key === KEYS.ESC) {
        setClientHits([])
        setGoOpen(false)
        track({ event: 'CLOSE_GO_SEARCH_BAR' })
      }
    }
    if (!videoSessionOpen) {
      document.body.addEventListener('keydown', onMagicKeys)
    }

    return () => {
      document.body.removeEventListener('keydown', onMagicKeys)
    }
  }, [isGoOpen, videoSessionOpen])

  if (!isGoOpen) {
    return null
  }
  return (
    <div className={styles.overlay} data-test-id='GoSearchBar'>
      <div data-test-id='GoSearchBar-search' className={styles.searchBar}>
        <div className={styles.searchIconContainer}>
          <SearchIcon fillColor={styles.x_white} width={40} />
        </div>
        <GoSearchInput onSearch={(event) => setClientHits(searchForClients({ clients, event }))} />
      </div>
      {!isEmpty(clientHits) && <Hits hits={clientHits} onHitClick={onHitClick} />}
    </div>
  )
}

type StateProps = {
  videoSessionOpen: boolean
  clientsActive: Client[]
  clientsInactive: Client[]
}
const mapStateToProps = ($$state: Map<string, any>): StateProps => ({
  videoSessionOpen: getLTVideoSessionOpen($$state),
  clientsActive: getActiveV2Clients($$state),
  clientsInactive: getInactiveV2Clients($$state),
})

const connector = connect(mapStateToProps, { selectLtClient })
export default connector(toJS(GoSearchBar))
