import { darkThemeColors, lightThemeColors, ThemeColors } from './colors'
import { DEFAULT_THEME, Theme } from './theme'

export const getColorTokensForTheme = (theme: Theme = DEFAULT_THEME): ThemeColors | {} => {
  switch (theme) {
    case Theme.DARK:
      return darkThemeColors
    case Theme.LIGHT:
      return lightThemeColors
    default:
      return lightThemeColors
  }
}
