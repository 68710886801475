/* global Blob */

const getExternalHref = function (href: $TSFixMe) {
  if (!href.startsWith('http')) {
    return `http://${href}`
  }
  return href
}

const createCSVDownload = function (data: $TSFixMe, filename: $TSFixMe) {
  const blob = new Blob([data], {
    type: 'text/csv;charset=utf-8;',
  })
  const url = URL.createObjectURL(blob)
  const link = window.document.createElement('a')
  link.setAttribute('href', url)
  link.setAttribute('download', filename)
  link.click()
}

export default {
  getExternalHref,
  createCSVDownload,
}
