/* eslint-disable formatjs/no-literal-string-in-jsx */
import React, { FunctionComponent } from 'react'
import { View } from 'react-native'

import styled from 'styled-components/native'

import { InfoIcon, Subhead, TextButton, Tooltip } from '../../atoms'
import { SubheadSize } from '../../styles'
import { tID } from '../../utils'

export interface PlatformEngagementYTDCardHeaderProps {
  onViewQuarterlyDetailsClicked: () => void
}

const Container = styled.View({
  flexDirection: 'row',
  justifyContent: 'space-between',
})
const SubheadInnerContainer = styled.View(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.spacing['4px'],
  alignItems: 'center',
}))

export const PlatformEngagementYTDCardHeader: FunctionComponent<PlatformEngagementYTDCardHeaderProps> = ({
  onViewQuarterlyDetailsClicked,
}) => {
  return (
    <Container>
      <View>
        <SubheadInnerContainer>
          <Subhead text='Platform Engagement YTD' size={SubheadSize.MEDIUM} />
          <Tooltip
            hoverEnabled
            content={
              'Completed and drop out episodes for the performance year. \n\nTo meet expectations, clinicians must achieve both >= 80% assignment rate and >= 25% feedback rate.'
            }
            placement='right'
          >
            <InfoIcon size={24} />
          </Tooltip>
        </SubheadInnerContainer>
      </View>
      <TextButton
        testID={tID('PlatformEngagementYTDCardHeaderQuarterlyDetailsButton')}
        onPress={onViewQuarterlyDetailsClicked}
        text='View quarterly details'
      />
    </Container>
  )
}
