import React from 'react'
import GenericListItem from '../genericListItem/GenericListItem'
import styles from './medicationsList.module.scss'
import TextButton from '../../atoms/buttons/textButton/TextButton'
import { get, isEmpty } from 'lodash-es'

type Props = {
  medications?: $TSFixMe[]
  onMedicationViewItemClick?: $TSFixMeFunction
}

function MedicationsList({ medications = [], onMedicationViewItemClick }: Props) {
  if (isEmpty(medications)) {
    return (
      <ul className={styles.list}>
        {/* @ts-expect-error TS(2322): Type '{ title: string; style: { justifyContent: st... Remove this comment to see the full error message */}
        <GenericListItem title={"This client doesn't have any medications"} style={{ justifyContent: 'center' }} />
      </ul>
    )
  }
  return (
    <ul className={styles.list}>
      {medications.map((medication, index) => {
        let rightItems = false
        if (onMedicationViewItemClick) {
          // @ts-expect-error TS(2322): Type 'Element' is not assignable to type 'boolean'... Remove this comment to see the full error message
          rightItems = (
            <TextButton
              data-test-id={'MedicationsList-viewMedicationButton'}
              onClick={onMedicationViewItemClick}
              text='View'
            />
          )
        }
        return (
          <GenericListItem
            key={get(medication, 'PrescriptionId', index)}
            title={get(medication, 'DisplayName')}
            // @ts-expect-error TS(2322): Type 'boolean' is not assignable to type 'ReactNod... Remove this comment to see the full error message
            rightItems={rightItems}
            subText={get(medication, 'Strength')}
          />
        )
      })}
    </ul>
  )
}

export default MedicationsList
