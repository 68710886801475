import React, { FunctionComponent } from 'react'

import styled, { useTheme } from 'styled-components/native'

import { BodyText, Size } from '../../atoms/bodyText/BodyText'
import { Divider } from '../../atoms/divider/Divider'
import { Flex1View } from '../../templates/content/CommonViews'

export type TimestampProps = {
  time: string
  timeSubMsg?: string
}

const TimeStampContainer = styled.View(({ theme }) => ({
  marginVertical: theme.spacing['16px'],
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: 'center',
}))

const DividerContainer = styled(Flex1View)({
  width: '100%',
  flexDirection: 'row',
})

const FontContainer = styled.View(({ theme }) => ({
  paddingHorizontal: theme.spacing['16px'],
}))

/**
 * Displays a timestamp with two dividers on the side
 * Optional:
 * Show a message under the time stamp
 */
export const Timestamp: FunctionComponent<TimestampProps> = ({ time, timeSubMsg }) => {
  const { colors } = useTheme()

  return (
    <TimeStampContainer>
      <DividerContainer defaultFlex>
        <Divider width='100%' color={colors.dividerSecondary} height={1} />
      </DividerContainer>
      <FontContainer>
        <BodyText text={time} size={Size.CAPTION} textAlign='center' color={colors.textSecondary} />
        {Boolean(timeSubMsg) && (
          <BodyText text={timeSubMsg} size={Size.CAPTION} textAlign='center' color={colors.textSecondary} />
        )}
      </FontContainer>
      <DividerContainer defaultFlex>
        <Divider width='100%' color={colors.dividerSecondary} height={1} />
      </DividerContainer>
    </TimeStampContainer>
  )
}
