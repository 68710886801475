import React, { useCallback } from 'react'
import { useNavigate } from 'react-router'

import styled from 'styled-components/native'

import { appointmentUtilization, UtilizationCharges } from '@lyrahealth-inc/shared-app-logic'
import {
  BodyText,
  BodyTextSize,
  colors,
  DataBoard,
  DataBoardItem,
  InfoIcon,
  Subhead,
  SubheadSize,
  TextButton,
  ThemeType,
  tID,
  Tooltip,
} from '@lyrahealth-inc/ui-core-crossplatform'

import { actions as mixpanelActions, mixpanelEvents } from '../../../../mixpanel/mixpanelConstants'
import { track } from '../../../../mixpanel/mixpanelTracking'
import { BC_PERFORMACE_DETAILED_WEEKLY_VIEW } from '../../common/constants/routingConstants'

const HeaderContainer = styled.View({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const HeaderTextContainer = styled.View({
  flexDirection: 'column',
})

const StyledTextButton = styled(TextButton)<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['4px'],
}))

const DataBoardSection = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  paddingTop: theme.spacing['24px'],
  display: 'flex',
  flexDirection: 'row',
}))

const DataBoardItemContainer = styled.View({
  width: '90px',
})

const CurrentWeekContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexGrow: 3,
  paddingRight: theme.spacing['12px'],
}))

const NextWeekContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexGrow: 1,
  paddingLeft: theme.spacing['12px'],
}))

export const WeeklySnapshot: React.FC<WeeklySnapshotProps> = ({
  appointmentUtilization,
  isHistorical = false,
  utilizationCharges,
}) => {
  const navigate = useNavigate()
  const completedCurrentWeek =
    appointmentUtilization.completedCurrentWeek + (utilizationCharges?.completedCurrentWeek ?? 0)
  const providerNoFaultCurrentWeek =
    appointmentUtilization.providerNoFaultCurrentWeek + (utilizationCharges?.providerNoFaultCurrentWeek ?? 0)

  const onViewDetailedWeeklyView = useCallback(() => {
    track({
      event: mixpanelEvents.BUTTON_PRESS,
      action: mixpanelActions.VIEW_QUARTERLY_DETAILS,
    })
    navigate(BC_PERFORMACE_DETAILED_WEEKLY_VIEW.route)
  }, [navigate])

  return (
    <div>
      <div>
        <HeaderContainer>
          <HeaderTextContainer>
            <Subhead text={'Weekly snapshot'} size={SubheadSize.MEDIUM} color={colors.ui_oatmeal6} />
            <BodyText
              size={BodyTextSize.LARGE}
              text={`Week ${
                isHistorical ? appointmentUtilization.totalWeekCount : appointmentUtilization.currentWeekCount
              } of ${appointmentUtilization.totalWeekCount}`}
              color={colors.ui_oatmeal4}
            />
          </HeaderTextContainer>
          <StyledTextButton
            text='Detailed Weekly View'
            testID={tID('WeeklySnapshot-detailedWeeklyViewButton')}
            onPress={onViewDetailedWeeklyView}
          />
        </HeaderContainer>
        <DataBoardSection>
          <CurrentWeekContainer>
            <DataBoard label='This week' backgroundColor={colors.ui_oatmeal2}>
              <DataBoardItemContainer>
                <DataBoardItem value={completedCurrentWeek} label='attended' />
              </DataBoardItemContainer>
              <DataBoardItemContainer>
                <DataBoardItem
                  value={providerNoFaultCurrentWeek}
                  label='credited'
                  labelTooltip={
                    <Tooltip
                      hoverEnabled
                      content={'Includes client late/missed sessions and technical issues'}
                      placement='right'
                    >
                      <InfoIcon size={18} testID={tID('WeeklySnapshot-no-fault')} />
                    </Tooltip>
                  }
                />
              </DataBoardItemContainer>
              <DataBoardItemContainer>
                <DataBoardItem value={appointmentUtilization.scheduledCurrentWeek} label='scheduled' />
              </DataBoardItemContainer>
            </DataBoard>
          </CurrentWeekContainer>
          <NextWeekContainer>
            <DataBoard label='Next week' backgroundColor={colors.ui_oatmeal2}>
              <DataBoardItem value={appointmentUtilization.scheduledNextWeek} label='scheduled' />
            </DataBoard>
          </NextWeekContainer>
        </DataBoardSection>
      </div>
    </div>
  )
}

type WeeklySnapshotProps = {
  appointmentUtilization: appointmentUtilization
  isHistorical: boolean
  utilizationCharges?: UtilizationCharges
}

export default WeeklySnapshot
