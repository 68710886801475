import React, { FunctionComponent } from 'react'
import Svg, { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

type MemberPreferencesMeetingSettingIllustrationIconProps = {
  size?: number
}

export const MemberPreferencesMeetingSettingIllustrationIcon: FunctionComponent<
  MemberPreferencesMeetingSettingIllustrationIconProps
> = ({ size = 519, ...props }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 519 519' fill='none' {...props}>
      <G clipPath='url(#clip0_3456_28275)'>
        <Path
          d='M105.636 56.0923C-11.5794 136.57 -27.0932 289.984 41.2104 407.522C125.028 543.806 331.77 548.008 445.753 448.892C532.695 373.37 536.142 237.948 484.43 142.172C413.11 9.98192 227.699 -27.6174 105.636 56.0923Z'
          fill='white'
        />
        <Path
          d='M117.121 72.0127C8.48598 146.6 -5.89223 288.784 57.4119 397.719C135.094 524.028 326.704 527.922 432.344 436.061C512.922 366.067 516.117 240.557 468.189 151.792C402.09 29.2774 230.25 -5.56979 117.121 72.0127Z'
          fill='#FFD2C4'
        />
        <Path
          d='M429.44 225.277C437.128 204.908 435.93 179.347 426.245 163.071C423.249 158.079 419.155 153.785 414.263 150.69C393.295 137.41 368.233 134.714 347.065 149.492C338.478 155.483 331.189 163.471 326.396 172.956C317.11 191.528 316.112 211.598 324.399 230.669C327.095 236.959 330.989 242.85 336.281 247.244C343.77 253.335 355.851 256.13 365.237 257.229C377.219 258.726 389.7 258.926 401.283 255.132C414.762 250.639 424.148 239.256 429.34 225.377L429.44 225.277Z'
          fill='#35220F'
        />
        <Path
          d='M463.5 284.189C454.514 274.603 438.338 266.615 418.968 261.823C394.904 255.832 366.347 254.234 339.388 260.624C314.626 266.515 291.061 279.296 275.685 300.164C265.7 313.744 257.712 328.522 251.122 343.998C238.541 373.753 226.559 404.007 221.667 435.859C217.573 462.518 234.048 478.195 248.526 499.263C314.925 500.86 383.022 478.894 432.347 435.959C464.698 407.901 484.369 370.758 493.455 330.818C486.166 313.644 476.381 297.768 463.6 284.089L463.5 284.189Z'
          fill='#017A8F'
        />
        <Path
          d='M339.389 260.625C341.685 266.716 355.564 299.965 393.706 292.377C414.076 288.283 419.567 262.223 418.868 261.923C406.187 256.531 402.194 256.032 399.098 251.838C397.201 249.342 396.003 245.348 398.499 243.152C408.983 234.265 417.87 214.794 415.673 201.215C402.194 189.932 396.802 182.843 395.304 176.353C395.104 175.354 391.61 188.335 376.133 196.322C368.545 200.217 357.062 205.009 345.779 203.911C346.178 212.198 347.177 218.489 349.973 226.277C351.97 231.769 357.361 238.259 359.858 241.055C363.253 244.849 359.858 248.743 358.659 250.44C355.764 254.534 346.478 258.129 339.389 260.625V260.625Z'
          fill='#CF8A61'
        />
        <Path
          d='M391.193 225.579C390.194 219.787 388.597 214.296 391.193 208.205C391.992 206.308 390.594 204.61 388.697 204.411C386.7 204.111 385.701 205.409 384.603 206.707C382.206 209.803 381.607 213.896 382.007 217.691C382.506 222.184 383.704 226.477 387.898 228.774C390.594 230.172 391.792 227.775 391.392 225.479L391.193 225.579Z'
          fill='#B26E3F'
        />
        <Path
          d='M117.121 72.008C8.48598 146.595 -5.89223 288.78 57.4119 397.714C101.445 469.306 182.023 501.457 262.901 499.36V30.9702C211.878 29.2727 160.256 42.4528 117.221 72.008H117.121Z'
          fill='#3BA5B5'
        />
        <Path
          d='M250.112 370.559L228.844 315.942C227.846 313.446 225.949 311.449 223.552 310.151C224.95 308.353 225.949 306.256 225.949 303.76V201.315C225.949 195.724 221.356 191.131 215.764 191.131H61.6977C56.1062 191.131 51.5131 195.724 51.5131 201.315V303.76C51.5131 306.256 52.5116 308.353 53.9095 310.151C51.5131 311.449 49.616 313.446 48.6175 315.942L35.4375 349.89C39.731 362.072 44.9231 373.954 50.914 385.536H238.63C247.017 385.536 252.908 377.848 250.112 370.559Z'
          fill='#FFB199'
        />
        <Path
          d='M68.8878 305.056H208.576C213.539 305.056 217.562 301.033 217.562 296.07V208.103C217.562 203.14 213.539 199.117 208.576 199.117H68.8878C63.9247 199.117 59.9014 203.14 59.9014 208.103V296.07C59.9014 301.033 63.9247 305.056 68.8878 305.056Z'
          fill='#FFE9E3'
        />
        <Path
          d='M134.693 271.111C127.005 273.408 119.816 278.5 115.023 286.688C111.928 292.08 109.431 297.871 107.434 303.962C107.334 304.261 107.235 304.661 107.135 304.96H183.918C181.522 295.874 178.027 287.287 172.935 280.297C170.139 276.503 165.147 273.408 159.156 271.411C151.767 269.015 142.881 268.415 134.593 270.912L134.693 271.111Z'
          fill='#095B71'
        />
        <Path
          d='M127.01 262.718C130.404 267.91 135.896 271.405 143.085 272.603C150.274 273.801 158.562 271.205 162.855 265.114C169.645 255.429 171.941 235.759 160.559 228.57C152.271 223.278 142.386 222.279 134.099 228.07C126.211 233.562 122.117 243.447 123.415 252.933C123.914 256.727 125.212 260.022 127.01 262.818V262.718Z'
          fill='#35220F'
        />
        <Path
          d='M138.684 263.322C140.881 265.318 143.777 266.517 146.672 266.517C155.659 266.617 162.448 257.031 161.15 248.444C155.858 243.951 153.761 241.155 153.063 238.659C153.063 238.259 151.565 243.352 145.474 246.547C142.479 248.045 137.985 249.942 133.492 249.542C133.692 254.135 134.591 259.028 137.886 262.623C138.085 262.922 138.385 263.122 138.584 263.421L138.684 263.322Z'
          fill='#CF8A61'
        />
      </G>
      <Defs>
        <ClipPath id='clip0_3456_28275'>
          <Rect width={519} height={519} fill='white' transform='translate(0.599609 0.803711)' />
        </ClipPath>
      </Defs>
    </Svg>
  )
}
