import React, { FunctionComponent } from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { ChevronIconDirection } from './ChevronIcon'
import { tID } from '../../utils'

function getPath(direction: ChevronIconDirection): string {
  switch (direction) {
    case ChevronIconDirection.LEFT:
      return 'M8.21128 11.2106C8.59624 10.8264 8.59624 10.2058 8.21128 9.82161L4.38136 5.99938L8.21128 2.17715C8.59624 1.79296 8.59624 1.17234 8.21128 0.788145C7.82631 0.403952 7.20444 0.403952 6.81948 0.788145L2.28872 5.3098C1.90376 5.69399 1.90376 6.31461 2.28872 6.69881L6.81948 11.2205C7.19457 11.5948 7.82631 11.5948 8.21128 11.2106Z'
    case ChevronIconDirection.RIGHT:
      return 'M3.78872 0.789388C3.40376 1.17358 3.40376 1.7942 3.78872 2.17839L7.61864 6.00062L3.78872 9.82285C3.40376 10.207 3.40376 10.8277 3.78872 11.2119C4.17369 11.596 4.79556 11.596 5.18052 11.2119L9.71128 6.6902C10.0962 6.30601 10.0962 5.68539 9.71128 5.30119L5.18052 0.779537C4.80543 0.405195 4.17369 0.405195 3.78872 0.789388Z'
    case ChevronIconDirection.UP:
      return 'M0.789388 8.21128C1.17358 8.59624 1.7942 8.59624 2.17839 8.21128L6.00062 4.38136L9.82285 8.21128C10.207 8.59624 10.8277 8.59624 11.2119 8.21128C11.596 7.82631 11.596 7.20444 11.2119 6.81948L6.6902 2.28872C6.30601 1.90376 5.68539 1.90376 5.30119 2.28872L0.779537 6.81948C0.405195 7.19457 0.405195 7.82631 0.789388 8.21128Z'
    case ChevronIconDirection.DOWN:
      return 'M11.2106 3.78872C10.8264 3.40376 10.2058 3.40376 9.82161 3.78872L5.99938 7.61864L2.17715 3.78872C1.79296 3.40376 1.17234 3.40376 0.788145 3.78872C0.403952 4.17369 0.403952 4.79556 0.788145 5.18052L5.3098 9.71128C5.69399 10.0962 6.31461 10.0962 6.69881 9.71128L11.2205 5.18052C11.5948 4.80543 11.5948 4.17369 11.2106 3.78872Z'
  }
}
export const ChevronV2Icon: FunctionComponent<ChevronV2IconProps & SvgProps> = ({
  size = 13,
  fillColor,
  direction = ChevronIconDirection.RIGHT,
  ...rest
}) => {
  const { colors } = useTheme()
  const { fill } = colors.icons.chevronIcon

  return (
    <Svg width={size} height={size} viewBox='0 0 12 12' testID={tID('ChevronV2Icon')} {...rest}>
      <Path d={getPath(direction)} fill={fillColor || fill} />
    </Svg>
  )
}

type ChevronV2IconProps = {
  size?: number
  fillColor?: string
  direction?: ChevronIconDirection
}
