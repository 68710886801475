import React, { useState } from 'react'
import { DefaultButton, InputField, SelectField, TextArea } from '@lyrahealth-inc/ui-core'
import { reduxForm, Field } from 'redux-form/immutable'
import { paymentExclusionReasons } from '../../../constants/appConstants'
import { Map } from 'immutable'

const ExcludePaymentForm: React.FC<ExcludePaymentFormProps> = ({
  initialValues,
  showExcludeText,
  submitting,
  submitFunction,
  handleSubmit,
}) => {
  const [exclusionReason, setExclusionReason] = useState(initialValues?.get('description') || '')
  const [exclusionReasonTextArea, setExclusionReasonTextArea] = useState(
    initialValues?.get('description_text_area') || '',
  )
  const renderExclusionOptions = () => {
    return [
      <option key={0} hidden>
        Select
      </option>,
      ...Object.keys(paymentExclusionReasons).map((reason, i) => (
        <option key={i + 1} value={reason}>
          {reason}
        </option>
      )),
      <option key={Object.keys(paymentExclusionReasons).length + 1} value='other'>
        Other
      </option>,
    ]
  }

  return (
    // @ts-expect-error TS(2322): Type 'void' is not assignable to type 'FormEventHa... Remove this comment to see the full error message
    <form onSubmit={handleSubmit(submitFunction)}>
      {showExcludeText && (
        <div>
          <h3>
            <strong>Exclude Session</strong>
          </h3>
          <p>Please describe the reason for excluding this session from this client’s session count</p>
        </div>
      )}
      <Field name='charge_id' type='hidden' component={InputField} />
      <Field
        name='description'
        component={SelectField}
        onChange={(e: any) => {
          if (e.target.value !== 'other' && exclusionReasonTextArea) {
            setExclusionReasonTextArea('')
          }
          setExclusionReason(e.target.value)
        }}
      >
        {renderExclusionOptions()}
      </Field>
      {exclusionReason === 'other' && (
        <Field
          name='description_text_area'
          component={TextArea}
          onChange={(e: any) => setExclusionReasonTextArea(e.target.value)}
          placeholder='Reason for excluding payment'
        />
      )}
      <DefaultButton
        id='requestexclude-submit'
        type='submit'
        isLoading={submitting}
        disabled={
          !(exclusionReason && exclusionReason !== 'other') && !(exclusionReason === 'other' && exclusionReasonTextArea)
        }
      >
        {showExcludeText ? 'Exclude Session' : 'Submit Comment'}
      </DefaultButton>
    </form>
  )
}

type ExcludePaymentFormProps = {
  initialValues: Map<string, any>
  showExcludeText: boolean
  submitting: boolean
  handleSubmit: (submitFunction: () => void) => void
  submitFunction: () => void
}

export default reduxForm({
  form: 'excludePaymentForm',
  touchOnBlur: false,
  enableReinitialize: true,
  // @ts-expect-error TS(2345): Argument of type 'FC<ExcludePaymentFormProps>' is ... Remove this comment to see the full error message
})(ExcludePaymentForm)
