import React, { FunctionComponent } from 'react'

import styled, { useTheme } from 'styled-components/native'

import { Headline } from '../../atoms/headline/Headline'
import { BodyTextSize, HeadlineSize, TextType } from '../../styles/typeStyles'
import { ThemeType } from '../../utils'
import { BaseText } from '../baseText/BaseText'

export type FieldBadgeProps = {
  text?: React.ReactNode
  selected?: boolean
  level?: string
  disableHeadline?: boolean
  badgeTheme?: 'Teal' | 'Periwinkle'
  testID?: string
}

const FieldBadgeContainer = styled.View<{
  theme: ThemeType
  selected?: boolean
  badgeTheme: FieldBadgeProps['badgeTheme']
}>(({ theme, selected, badgeTheme }) => {
  let backgroundColor = theme.colors.backgroundHighlightTeal
  if (selected) {
    backgroundColor = theme.colors.backgroundPrimary
  } else if (badgeTheme === 'Periwinkle') {
    backgroundColor = theme.colors.components.fieldBadge.periwinkleTheme.background
  }

  return {
    alignSelf: 'center',
    padding: '2px 8px',
    borderRadius: theme.spacing['4px'],
    backgroundColor,
  }
})

const FieldBadgeTextContainer = styled.View`
  justify-content: center;
  align-self: center;
`

/**
 * Badge used on in fields of form elements (such as Radio Buttons and Checkboxes)
 */
export const FieldBadge: FunctionComponent<FieldBadgeProps> = ({
  text,
  testID,
  selected,
  level,
  disableHeadline,
  badgeTheme = 'Teal',
}) => {
  const { colors } = useTheme()
  const textColor =
    badgeTheme === 'Periwinkle' ? colors.components.fieldBadge.periwinkleTheme.text : colors.secondaryButtonTextActive

  return (
    <FieldBadgeContainer testID={testID} selected={selected} badgeTheme={badgeTheme}>
      <FieldBadgeTextContainer>
        {!disableHeadline ? (
          <Headline text={text} bold={true} size={HeadlineSize.EYEBROW} color={textColor} level={level} />
        ) : (
          <BaseText bold={true} color={textColor} text={text} size={BodyTextSize.CAPTION} type={TextType.BODY} />
        )}
      </FieldBadgeTextContainer>
    </FieldBadgeContainer>
  )
}
