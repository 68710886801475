import { Message } from '../../../models/messages/Messages'

export const MESSAGE_REMOVED_TEXT = 'To protect your privacy, live messaging sessions have been removed.'

/**
 * Parses messages into a list of messages allowed for display.
 * @param    messages An array of raw messages.
 * @returns  An array of messages with deleted message groups replaced.
 */
export const parseAllowedMessages = (messages: Message[]) => {
  const deletedMessageAttr = `"retention_status": "DELETED_LIVE_MESSAGE"`
  const initialMessages: Message[] = []

  const replaceMessage = ({ attributes, date_created, message_id }: Message) => {
    return {
      attributes,
      body: MESSAGE_REMOVED_TEXT,
      date_created,
      message_id,
    }
  }

  return messages.reduce(
    (messagesToDisplay: Message[], current: Message, index: number, messages: Message[]): Message[] => {
      if (
        current.attributes.includes(deletedMessageAttr) &&
        !messages[index - 1]?.attributes.includes(deletedMessageAttr)
      ) {
        messagesToDisplay.push(replaceMessage(current))
      } else if (!current.attributes.includes(deletedMessageAttr)) {
        messagesToDisplay.push(current)
      }

      return messagesToDisplay
    },
    initialMessages,
  )
}
