import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

type LyraAppBenefitIllustrationProps = {
  size?: number
}

export const LyraAppBenefitIllustration: FunctionComponent<LyraAppBenefitIllustrationProps> = ({ size = 513 }) => {
  return (
    <Svg width={size} height={size} fill='none' viewBox='0 0 514 513'>
      <Path
        fill='#FFE9E3'
        d='M257.8.728c-35.341 0-69.63 6.06-101.916 18.01C79.02 47.227 24.982 110.394 7.62 192.032c-11.474 53.229-10.007 102.462 4.512 150.44 18.536 61.272 64.035 112.943 124.832 141.759 30.181 14.336 64.855 22.522 103.039 24.323 4.907.222 9.895.344 14.852.344 30.637 0 61.455-4.199 91.606-12.476a159.67 159.67 0 0 0 6.85-1.861c27.095-7.933 51.478-20.478 72.341-37.243 22.653-18.101 41.3-41.048 55.364-68.163 2.772-5.342 5.423-10.968 8.347-17.696 16.087-37.354 23.857-79.171 22.481-120.977-.647-18.485-3.399-38.134-8.154-58.45-7.832-32.963-20.6-63.964-36.93-89.703-4.472-6.91-8.58-12.718-12.647-17.939-29.351-37.86-75.083-64.43-132.208-76.793A302.864 302.864 0 0 0 257.81.738l-.01-.01Z'
      />
      <Path
        fill='#008295'
        d='M212.967 95.969s90.797-23.878 99.704-22.454c8.223 1.313 14.191 4.118 22.532 27.447 8.025 22.462 68.076 248.419 68.186 248.943 6.869 30.93-4.725 33.634-15.522 38.726-10.682 5.027-131.701 36.901-163.924 45.288-8.439 2.196-17.837 2.303-25.315-.832-5.925-2.484-12.205-4.56-20.58-29.921-10.653-32.236-63.519-246.681-63.519-246.681s-5.906-26.391 15.566-33.858c21.482-7.469 82.872-26.658 82.872-26.658Z'
      />
      <Path
        fill='#85CCD5'
        d='M217.332 111.324s78.671-20.764 86.307-19.812c7.05.879 12.139 2.981 18.847 21.311 6.456 17.656 53.625 195.639 53.717 196.057 5.294 24.395-4.737 26.771-14.146 31.018-9.298 4.206-114.153 31.916-142.071 39.215-7.319 1.912-15.408 2.182-21.789-.144-5.052-1.846-10.416-3.374-17.12-23.314-8.525-25.341-49.736-194.35-49.736-194.35s-4.558-20.814 14.091-27.166c18.646-6.361 71.902-22.805 71.902-22.805l-.002-.01Z'
      />
      <Path
        fill='#00A4B3'
        d='M295.89 402.802c7.028-1.921 11.167-9.175 9.246-16.203-1.921-7.027-9.175-11.167-16.203-9.246-7.027 1.921-11.167 9.175-9.246 16.203 1.922 7.027 9.176 11.167 16.203 9.246Z'
      />
      <Path
        fill='#fff'
        d='M286.131 278.967c3.121-.733 11.904-3.254 11.904-3.254l5.497 20.11-11.904 3.254c-11.675 3.191-42.931 9.454-51.574-22.162L211 170.633l20.603-5.632L259.1 265.585c4.275 15.64 15.806 15.97 27.031 13.382Z'
      />
    </Svg>
  )
}
