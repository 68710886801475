import React from 'react'
import styles from './label.module.scss'

const Label: React.FC<LabelProps> = ({
  textColor = styles.x_orange5,
  backgroundColor = styles.x_orange1,
  text,
  padding = '0 10px',
  borderRadius = '3px',
}) => {
  return (
    <div
      className={styles.container}
      style={{ color: textColor, background: backgroundColor, padding, borderRadius }}
      data-test-id={`Label-${text}`}
    >
      {text}
    </div>
  )
}

type LabelProps = {
  textColor?: string
  backgroundColor?: string
  text: string | number
  padding?: string
  borderRadius?: string
}

export default Label
