import React, { useEffect } from 'react'
import CSSModules from 'react-css-modules'
import styles from './requestPayment.module.scss'
import { PrimaryButton } from '@lyrahealth-inc/ui-core'
import PropTypes from 'prop-types'
import { track } from '../../../mixpanel/mixpanelTracking'

const OutcomeReminder = ({ firstName, outcomeSentDate, closeFunc }: any) => {
  useEffect(() => {
    track({ event: 'OUTCOME_REMINDER' })
  }, [])
  return (
    <div styleName='modal-content'>
      <h2 styleName='main-header'>Please remind {firstName} to submit Outcomes</h2>
      <p>
        We requested outcomes from {firstName} on {outcomeSentDate} but haven&apos;t received them. As you know,
        Outcomes data is important for tracking progress in therapy, catching any problems early, and supporting
        successful graduation.{' '}
        <b>
          Please ask {firstName} to submit Outcomes on a computer or mobile device by opening the link we sent via
          email.
        </b>
      </p>
      <PrimaryButton onClick={() => closeFunc()}>OK</PrimaryButton>
    </div>
  )
}

OutcomeReminder.propTypes = {
  outcomeSentDate: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  closeFunc: PropTypes.func.isRequired,
}

export default CSSModules(OutcomeReminder, styles)
