import React, { FunctionComponent } from 'react'
import { Path, Rect, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { Tooltip } from '../../atoms/tooltip/Tooltip'
import { tID } from '../../utils'

export const ExpeditedBookingIcon: FunctionComponent<ExpeditedBookingIconProps> = ({
  backgroundColor,
  fill,
  showTooltip = false,
  tooltipLabel = 'Expedited booking',
  width = 24,
  height = 24,
}) => {
  const { colors } = useTheme()

  const icon = (
    <Svg width={width} height={height} testID={tID('ExpeditedBookingIcon')}>
      <Rect width={width} height={height} fill={backgroundColor || colors.backgroundIcon} rx='5' />
      <Path
        fill={fill || colors.iconActive}
        d='M10.219 13.6H5.493a.25.25 0 0 1-.202-.398l7.757-10.586a.25.25 0 0 1 .452.148V9.35c0 .138.112.25.25.25h4.744a.25.25 0 0 1 .198.402l-8.637 11.274c-.155.202-.477.071-.447-.181l.859-7.215a.25.25 0 0 0-.248-.28Z'
      />
    </Svg>
  )

  return showTooltip ? (
    <Tooltip content={tooltipLabel} placement='bottom' hoverEnabled={true}>
      {icon}
    </Tooltip>
  ) : (
    <>{icon}</>
  )
}

type ExpeditedBookingIconProps = {
  backgroundColor?: string
  fill?: string
  showTooltip?: boolean
  tooltipLabel?: string
  width?: number
  height?: number
}
