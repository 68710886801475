import { isUndefined } from 'lodash-es'
import { AnyAction, Dispatch } from 'redux'

import { hrefUtils } from '@lyrahealth-inc/ui-core'

import {
  actionAlertHandler,
  actionStyles,
  DOWNLOAD_PROVIDERS_CSV,
  GET_PROVIDER_CAPACITY,
  GET_PROVIDERS_LITE,
  SET_PROVIDER_CAPACITY,
  SET_SELECTED_PROVIDER_CAPACITY,
  SUBMIT_CLIENT_EMAIL,
  SUBMIT_CLIENT_EMAIL_HEALTHPLAN,
  UPDATE_EXPEDITED_BOOKING_STATUS,
  UPDATE_GRACE_PERIOD,
  UPDATE_IN_PERSON_PREFERENCE_STATUS,
} from '../../common/constants/reduxConstants'
import {
  downloadProvidersData,
  getProvidersDataLite,
  submitClientEmailForHealthPlanRegistration,
  submitClientEmailForm,
  updateProviderCapacityData,
} from '../../common/http/data/providers'
import { getErrorText } from '../../common/utils/utils'

export const getProviders = () => {
  const request = getProvidersDataLite()

  return (dispatch: Dispatch<AnyAction>) => {
    return new Promise(function (resolve, reject) {
      request.then(
        (providersReturn) => {
          dispatch({
            type: GET_PROVIDERS_LITE,
            payload: providersReturn,
          })

          resolve(providersReturn)
        },
        (error) => {
          if (!error.response) return
          actionAlertHandler({
            actionStyle: actionStyles.ERROR,
            message: error,
            dispatch,
            expires: true,
            action: GET_PROVIDERS_LITE,
          })
          reject(error)
        },
      )
    })
  }
}

export const submitClientEmail = (id: string, data: Dict) => {
  const request = submitClientEmailForm(id, data)

  return {
    type: SUBMIT_CLIENT_EMAIL,
    payload: request,
  }
}

export const submitClientEmailForHealthPlan = (id: string, data: Dict) => {
  const request = submitClientEmailForHealthPlanRegistration(id, data)

  return {
    type: SUBMIT_CLIENT_EMAIL_HEALTHPLAN,
    payload: request,
  }
}

export const downloadProviders = () => {
  const request = downloadProvidersData()

  return (dispatch: Dispatch<AnyAction>) => {
    return new Promise<void>(function (resolve, reject) {
      dispatch({
        type: DOWNLOAD_PROVIDERS_CSV,
        status: 'pending',
        payload: {
          isDownloadingCSV: true,
        },
      })
      request.then(
        (csvPayments) => {
          hrefUtils.createCSVDownload(csvPayments.data, 'providers.csv')

          dispatch({
            type: DOWNLOAD_PROVIDERS_CSV,
            status: 'success',
            payload: {
              isDownloadingCSV: false,
            },
          })

          resolve()
        },
        (error) => {
          let errorText
          if (isUndefined(error.response)) {
            errorText = getErrorText('500')
          } else if (error.response.data && error.response.data.description) {
            errorText = error.response.data.description
          } else {
            errorText = getErrorText(error.response.status)
          }

          dispatch({
            type: DOWNLOAD_PROVIDERS_CSV,
            status: 'error',
            payload: {
              isDownloadingCSV: false,
            },
          })

          actionAlertHandler({
            actionStyle: actionStyles.ERROR,
            message: errorText,
            dispatch,
            expires: true,
            action: DOWNLOAD_PROVIDERS_CSV,
          })

          reject(error)
        },
      )
    })
  }
}

export const updateProviderCapacity = ({ id, data }: { id: string; data: Dict }) => {
  const request = updateProviderCapacityData(id, data)
  return {
    type: SET_PROVIDER_CAPACITY,
    payload: request,
  }
}

export const getProviderCapacity = ({ id }: { id: string }) => ({
  action: GET_PROVIDER_CAPACITY,
  request: {
    method: 'get',
    url: `/v1/providers/${id}/capacity`,
  },
})

export const updateSelectedProviderCapacity = ({ id, data }: { id: string; data: Dict }) => {
  const request = updateProviderCapacityData(id, data)
  return {
    type: SET_SELECTED_PROVIDER_CAPACITY,
    payload: request,
  }
}

export const updateProviderGracePeriod = ({ id, data }: { id: string; data: Dict }) => ({
  action: UPDATE_GRACE_PERIOD,
  request: {
    method: 'put',
    url: `/v1/providers/property/${id}/gracePeriod`,
    data,
  },
})

export const updateExpeditedBookingStatus = ({ id, data }: { id: string; data: Dict }) => ({
  action: UPDATE_EXPEDITED_BOOKING_STATUS,
  request: {
    method: 'put',
    url: `/v1/providers/property/${id}/providerExpeditedBookingConfig`,
    data,
  },
})

export const updateInPersonDesireStatus = ({ id, data }: any) => ({
  action: UPDATE_IN_PERSON_PREFERENCE_STATUS,
  request: {
    method: 'put',
    url: `/v1/providers/${id}/modality`,
    data,
  },
})
