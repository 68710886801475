import React from 'react'
import colors from '../../../styles/_0colors.scss'
import { Icon } from './types'

const ProfileIcon: React.FC<ProfileIconProps> = ({
  width = 38,
  fillColor = colors.x_medium_gray,
  isFilled = false,
  ...props
}) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} viewBox='0 0 24 24' {...props}>
      {isFilled ? (
        <path
          fill={fillColor}
          fillRule='nonzero'
          d='M10.5,8.5 C10.5,7.673 11.173,7 12,7 C12.827,7 13.5,7.673 13.5,8.5 C13.5,9.327 12.827,10 12,10 C11.173,10 10.5,9.327 10.5,8.5 Z M17,16 L17,16.25 C17,16.664 16.664,17 16.25,17 C15.836,17 15.5,16.664 15.5,16.25 L15.5,16 C15.5,15.173 14.827,14.5 14,14.5 L10,14.5 C9.173,14.5 8.5,15.173 8.5,16 L8.5,16.25 C8.5,16.664 8.164,17 7.75,17 C7.336,17 7,16.664 7,16.25 L7,16 C7,14.346 8.346,13 10,13 L14,13 C15.654,13 17,14.346 17,16 Z M9,8.5 C9,6.846 10.346,5.5 12,5.5 C13.654,5.5 15,6.846 15,8.5 C15,10.154 13.654,11.5 12,11.5 C10.346,11.5 9,10.154 9,8.5 Z M2,12.0002 C2,17.5192 6.48,22.0002 12,22.0002 C17.52,22.0002 22,17.5192 22,12.0002 C22,6.4802 17.52,2.0002 12,2.0002 C6.48,2.0002 2,6.4802 2,12.0002 Z'
        />
      ) : (
        <path
          fill={fillColor}
          fillRule='nonzero'
          d='M17,16 C17,14.346 15.654,13 14,13 L10,13 C8.346,13 7,14.346 7,16 L7,16.25 C7,16.664 7.336,17 7.75,17 C8.164,17 8.5,16.664 8.5,16.25 L8.5,16 C8.5,15.173 9.173,14.5 10,14.5 L14,14.5 C14.827,14.5 15.5,15.173 15.5,16 L15.5,16.25 C15.5,16.664 15.836,17 16.25,17 C16.664,17 17,16.664 17,16.25 L17,16 Z M10.5,8.5 C10.5,9.327 11.173,10 12,10 C12.827,10 13.5,9.327 13.5,8.5 C13.5,7.673 12.827,7 12,7 C11.173,7 10.5,7.673 10.5,8.5 Z M9,8.5 C9,6.846 10.346,5.5 12,5.5 C13.654,5.5 15,6.846 15,8.5 C15,10.154 13.654,11.5 12,11.5 C10.346,11.5 9,10.154 9,8.5 Z M12,20.5002 C7.31,20.5002 3.5,16.6902 3.5,12.0002 C3.5,7.3092 7.31,3.5002 12,3.5002 C16.69,3.5002 20.5,7.3092 20.5,12.0002 C20.5,16.6902 16.69,20.5002 12,20.5002 M2,12.0002 C2,17.5192 6.48,22.0002 12,22.0002 C17.52,22.0002 22,17.5192 22,12.0002 C22,6.4802 17.52,2.0002 12,2.0002 C6.48,2.0002 2,6.4802 2,12.0002 Z'
        />
      )}
    </svg>
  )
}

type ProfileIconProps = Icon

export default ProfileIcon
