import React, { FC, useState } from 'react'
import {
  BackButtonSmall,
  ClinicalDataSharingProviderAccessPage,
  InlineInfoBanner,
  SpacedView,
  ThemeType,
  tID,
} from '@lyrahealth-inc/ui-core-crossplatform'
import { OutcomesResponse, ProviderAccessFormValues } from '@lyrahealth-inc/shared-app-logic'
import ClientOutcomes from '../../clients/individualClient/ClientOutcomes'
import styled from 'styled-components/native'
import { useTheme } from 'styled-components/native'
import { getClientShareOutcomesData } from '../../common/http/data/clients'
import { useIntl } from 'react-intl'

const OutcomesContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginHorizontal: theme.breakpoints.isMobileSized ? theme.spacing['8px'] : theme.spacing['64px'],
  marginVertical: theme.spacing['24px'],
}))

const ClientShareOutcomes: FC = () => {
  const [outcomesResponse, setOutcomesResponse] = useState<OutcomesResponse | undefined>(undefined)
  const [error, setError] = useState<string | undefined>(undefined)
  const { spacing } = useTheme()
  const { formatMessage } = useIntl()
  const onSubmit = async (formResponse: Dict) => {
    setError(undefined)
    try {
      const res = await Promise.resolve(getClientShareOutcomesData(formResponse.values as ProviderAccessFormValues))
      setOutcomesResponse(res.data)
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setError(
          formatMessage({
            defaultMessage: 'The access code you entered is incorrect or has expired.\nPlease try again.',
            description: 'Error message for when the user inputs an invalid code or information',
          }),
        )
      } else {
        setError(
          formatMessage({
            defaultMessage: "There was an issue retrieving your client's assessment results.\nPlease try again later.",
            description: 'Error message for when the server fails to return any data.',
          }),
        )
      }
    }
  }

  if (!outcomesResponse) {
    return <ClinicalDataSharingProviderAccessPage saveForm={onSubmit} errorMessage={error} />
  } else {
    return (
      <OutcomesContainer>
        <BackButtonSmall
          testID={tID('ClientOutcomes-backButton')}
          onPress={() => {
            setOutcomesResponse(undefined)
          }}
        />
        {outcomesResponse.outcomes.length > 0 ? (
          <ClientOutcomes
            outcomes={outcomesResponse.outcomes}
            assignmentsWithSelfReflection={[]}
            clientFullName={outcomesResponse.client.full_name}
            clientFirstName={outcomesResponse.client.first_name}
          />
        ) : (
          <SpacedView marginTop={spacing['48px']}>
            <InlineInfoBanner
              testId={tID('ClientOutcomes-noOutcomesBanner')}
              text={formatMessage({
                defaultMessage: 'There are currently no assessment results.',
                description: 'Message to indicate that no results are available for the provider to view.',
              })}
            />
          </SpacedView>
        )}
      </OutcomesContainer>
    )
  }
}

export default ClientShareOutcomes
