import React from 'react'
import { Field } from 'redux-form/immutable'
import CSSModules from 'react-css-modules'
import styles from './practiceInfoForm.module.scss'
import { states } from '../../../constants/appConstants'

import {
  DropZone,
  CardIcon,
  InputField,
  MaskedInputField,
  SelectField,
  Checkbox,
  PrimaryButton,
} from '@lyrahealth-inc/ui-core'

const dropZonePlaceholder = CSSModules(() => {
  return (
    <div className={styles['dropzone-placeholder']}>
      <CardIcon fillColor={styles.x_light_gray_100} />
      <span>Drag image here</span>
    </div>
  )
}, styles)

const dropZoneContext = (
  <div>Please upload a photo of your passport, driver’s license or ID card in JPEG or PNG formats</div>
)

const tineinTooltipContent = (
  <div>
    <strong>Numbers Only.</strong>
    <br />
    Omit Characters and Hyphens
  </div>
)

const nameOnAccountToolTipContent = (
  <div>
    <strong>Check Line 1 of your Form W-9 for the owner’s name or business’s name.</strong>
  </div>
)

type OwnProps = {
  handleSubmit?: (...args: any[]) => any
  submitting?: boolean
  submitFunction?: (...args: any[]) => any
  primaryButtonText?: string
  termsOfUseHandler?: (...args: any[]) => any
  showEmailField?: boolean
}

type Props = OwnProps & typeof PracticeInfoForm.defaultProps

class PracticeInfoForm extends React.Component<Props> {
  static defaultProps = {
    showEmailField: false,
  }

  render() {
    const { handleSubmit, submitting, submitFunction, primaryButtonText, termsOfUseHandler, showEmailField } =
      this.props

    return (
      // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      <form onSubmit={handleSubmit(submitFunction)}>
        <Field name='id' type='hidden' component={InputField} />
        {!showEmailField ? <Field name='email' type='hidden' component={InputField} /> : []}

        <div className='row'>
          {showEmailField ? (
            <div className='col-xs-12 col-sm-6'>
              <Field
                name='email'
                type='text'
                readonly
                component={InputField}
                label='Practice Email'
                placeholder='Email'
                data-test-id='PracticeInfoForm-email-field'
              />
            </div>
          ) : (
            []
          )}
          <div className={`col-xs-12 ${showEmailField ? 'col-sm-6' : ''}`}>
            <Field
              name='name'
              type='text'
              readonly
              component={InputField}
              label='Practice Name'
              placeholder='Practice Name'
              data-test-id='PracticeInfoForm-name-field'
            />
          </div>
        </div>
        <p className={styles.header}>Identity</p>
        <p>Enter the information of the practice’s authorized owner or operator for verification purposes.</p>
        <div className='row'>
          <div className='col-xs-12 col-sm-6'>
            <Field
              name='first_name'
              type='text'
              component={InputField}
              label='First Name'
              placeholder='First Name'
              data-test-id='PracticeInfoForm-firstname-field'
            />
          </div>
          <div className='col-xs-12 col-sm-6'>
            <Field
              name='last_name'
              type='text'
              component={InputField}
              label='Last Name'
              placeholder='Last Name'
              data-test-id='PracticeInfoForm-lastname-field'
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12 col-sm-6'>
            <Field
              name='phone'
              type='tel'
              component={MaskedInputField}
              mask='(111) 111-1111'
              placeholder='(XXX) XXX-XXXX'
              label='Practice Phone Number'
              data-test-id='PracticeInfoForm-phonenumber-field'
            />
          </div>
          <div className='col-xs-12 col-sm-6'>
            <Field
              name='date_of_birth'
              type='tel'
              component={MaskedInputField}
              mask='11/11/1111'
              placeholder='MM/DD/YYYY'
              label='Date of Birth'
              data-test-id='PracticeInfoForm-dob-field'
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-xs-12 col-sm-6'>
            <Field
              name='ssn'
              type='tel'
              component={MaskedInputField}
              mask='111111111'
              label='Social Security Number'
              placeholder='XXXXXXXXX'
              data-test-id='PracticeInfoForm-ssn-field'
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-xs-12'>
            <Field
              name='identification_file_front'
              className={styles['id-dropzone']}
              component={DropZone}
              // @ts-expect-error TS(2769): No overload matches this call.
              placeholder={dropZonePlaceholder}
              context={dropZoneContext}
              label='Please Upload Front of Your Identification Document'
              dataTestId='PracticeInfoForm-idfront-field'
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-xs-12'>
            <Field
              name='identification_file_back'
              className={styles['id-dropzone']}
              component={DropZone}
              // @ts-expect-error TS(2769): No overload matches this call.
              placeholder={dropZonePlaceholder}
              context={dropZoneContext}
              label='Please Upload Back of Your Identification Document'
              dataTestId='PracticeInfoForm-idback-field'
            />
          </div>
        </div>

        <h3>Tax Information</h3>
        <p>
          Enter the exact tax name (Line 1), taxpayer ID number, and address from your W-9. We’ll send important tax
          documents to the address you enter.
        </p>
        <p></p>
        <div className='row'>
          <div className='col-xs-12 col-sm-6'>
            <Field
              name='street'
              type='text'
              component={InputField}
              label='Street Address'
              placeholder='Street'
              data-test-id='PracticeInfoForm-streetaddress-field'
            />
          </div>
          <div className='col-xs-12 col-sm-6'>
            <Field
              name='city'
              type='text'
              component={InputField}
              label='City'
              placeholder='City'
              data-test-id='PracticeInfoForm-city-field'
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12 col-sm-6'>
            <Field name='state' component={SelectField} label='State' dataTestId='PracticeInfoForm-state-field'>
              <option hidden value=''>
                Select
              </option>
              {states.map((state) => (
                <option value={state.value}>{state.text}</option>
              ))}
            </Field>
          </div>
          <div className='col-xs-12 col-sm-6'>
            <Field
              name='zip_code'
              type='tel'
              component={MaskedInputField}
              mask='11111'
              label='Zip Code'
              placeholder='Zip Code'
              data-test-id='PracticeInfoForm-zipcode-field'
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12 col-sm-6'>
            <Field
              name='account_identifier'
              type='tel'
              tooltipContent={tineinTooltipContent}
              component={MaskedInputField}
              mask='111111111'
              label='TIN or EIN Number'
              placeholder='Digits only'
              data-test-id='PracticeInfoForm-tin-field'
            />
          </div>
          <div className='col-xs-12 col-sm-6'>
            <Field
              name='account_name'
              type='text'
              component={InputField}
              tooltipContent={nameOnAccountToolTipContent}
              label='Name on account (for tax purposes)'
              placeholder='Name on account (for tax purposes)'
              data-test-id='PracticeInfoForm-nameonaccount-field'
            />
          </div>
        </div>
        <h3>Banking Information</h3>
        <p>Lyra will deposit your payments here.</p>
        <div className='row'>
          <div className='col-xs-12 col-sm-6'>
            <Field
              name='payment_preference'
              label='Payment Preference'
              component={SelectField}
              dataTestId='PracticeInfoForm-paymentpreference-field'
            >
              <option hidden value=''>
                Select
              </option>
              <option value='2'>ACH</option>
            </Field>
          </div>
          <div className='col-xs-12 col-sm-6'>
            <Field
              name='bank'
              type='text'
              component={InputField}
              label='Bank Name'
              placeholder='Bank Name'
              data-test-id='PracticeInfoForm-bankname-field'
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12 col-sm-6'>
            <Field
              name='routing_number'
              type='text'
              component={MaskedInputField}
              mask='111111111'
              label='Routing Number'
              placeholder='Routing Number'
              data-test-id='PracticeInfoForm-routingnumber-field'
            />
          </div>
          <div className='col-xs-12 col-sm-6'>
            <Field
              name='account_number'
              type='text'
              component={InputField}
              label='Account Number'
              placeholder='Account Number'
              data-test-id='PracticeInfoForm-accountnumber-field'
            />
          </div>
        </div>

        <div className={`row ${styles['consent-container']}`}>
          <Field
            name='tos'
            component={Checkbox}
            clickableLabel={false}
            dataTestId='PracticeInfoForm-tos-field'
            type='checkbox'
          >
            I have read and understand Lyra Health's&nbsp;
            <span id='termsOfServiceBtn' onClick={termsOfUseHandler} className={styles['modal-link']}>
              Terms of Use
            </span>
          </Field>
        </div>

        <div className='row'>
          <div className='col-xs-12'>
            <PrimaryButton
              id='test-practiceinfo-submit'
              isLoading={submitting}
              type='submit'
              // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
              onKeyDown={handleSubmit(submitFunction)}
              dataTestId='PracticeInfoForm-submit-button'
            >
              {primaryButtonText}
            </PrimaryButton>
          </div>
        </div>
      </form>
    )
  }
}

export default PracticeInfoForm
