import React from 'react'
import { useIntl } from 'react-intl'

import styled, { useTheme } from 'styled-components/native'

import { Avatar, avatarAltTextGeneric, AvatarDetails, BodyText, Subhead } from '../../atoms'
import { BodyTextSize, SubheadSize } from '../../styles'
import { Flex1View } from '../../templates'
import { ThemeType, tID } from '../../utils'

const BackgroundContainer = styled(Flex1View)<{ backgroundColor: string }>(({ backgroundColor, theme }) => ({
  backgroundColor,
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  alignSelf: 'stretch',
  gap: theme.spacing['8px'],
}))

export type ZoomParticipantEmptyViewProps = {
  participantFirstName?: string
  participantName?: string
  avatarDetails?: AvatarDetails
  isFullscreen?: boolean
  participantTitle?: string
}

export const ZoomParticipantEmptyView: React.FC<ZoomParticipantEmptyViewProps> = ({
  participantFirstName,
  avatarDetails,
  isFullscreen,
  participantName,
  participantTitle,
}) => {
  const { colors } = useTheme() as ThemeType
  const { formatMessage } = useIntl()

  if (avatarDetails && isFullscreen) {
    return (
      <BackgroundContainer
        backgroundColor={colors.components.zoomVideoSession.background}
        testID={tID('ZoomParticipantEmptyView')}
      >
        <Avatar
          details={avatarDetails}
          size={112}
          accessibilityLabel={formatMessage(avatarAltTextGeneric, { name: avatarDetails.displayName })}
        />
        <Subhead text={participantName} size={SubheadSize.LARGE} />
        <Subhead text={participantTitle} size={SubheadSize.SMALL} />
      </BackgroundContainer>
    )
  }
  return (
    <BackgroundContainer
      backgroundColor={colors.components.zoomEmptyView.background}
      testID={tID('ZoomParticipantEmptyView')}
    >
      <BodyText size={BodyTextSize.SMALL} color={colors.components.zoomEmptyView.text} text={participantFirstName} />
    </BackgroundContainer>
  )
}
