import React from 'react'
import { FormattedMessage, IntlShape, MessageDescriptor } from 'react-intl'

/**
 * Safely format a message into a string or a React node since during our localization process
 * we might have a mix of different types of messages that get to be passed to a component.
 * @param message message descriptor, string, or a React Node type message
 * @param renderAsComponent true if the message should be rendered as a React node
 * @param originalFormatMessage the original formatMessage function from react-intl.
 *    This is required if the message is a message descriptor, and the expected output is a string.
 */
export const formatMessageHelper = (
  message: MessageDescriptor | string | React.ReactNode,
  renderAsComponent = true,
  originalFormatMessage?: IntlShape['formatMessage'],
) => {
  if (!message) {
    return message
  }

  if (React.isValidElement(message)) {
    return message
  } else if (typeof message === 'string') {
    return renderAsComponent ? <>{message}</> : message
  } else {
    message = message as MessageDescriptor
    return renderAsComponent || !originalFormatMessage ? (
      <FormattedMessage {...message} />
    ) : (
      originalFormatMessage(message)
    )
  }
}
