import React from 'react'
import colors from '../../../styles/_0colors.scss'
import { Icon } from './types'

const TargetIcon: React.FC<TargetIconProps> = ({ width = 24, fillColor = colors.x_secondary_action, ...props }) => {
  return (
    <svg width={width} height={width} viewBox={`0 0 ${width} ${width}`} xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.99976 0C8.68654 0 7.38618 0.258658 6.17292 0.761205C4.95967 1.26375 3.85727 2.00035 2.92869 2.92893C1.05332 4.8043 -0.000244141 7.34784 -0.000244141 10C-0.000244141 12.6522 1.05332 15.1957 2.92869 17.0711C3.85727 17.9997 4.95967 18.7362 6.17292 19.2388C7.38618 19.7413 8.68654 20 9.99976 20C12.6519 20 15.1955 18.9464 17.0708 17.0711C18.9462 15.1957 19.9998 12.6522 19.9998 10C19.9998 8.84 19.7898 7.69 19.3898 6.61L17.7898 8.21C17.9298 8.8 17.9998 9.4 17.9998 10C17.9998 12.1217 17.1569 14.1566 15.6566 15.6569C14.1563 17.1571 12.1215 18 9.99976 18C7.87802 18 5.84319 17.1571 4.3429 15.6569C2.84261 14.1566 1.99976 12.1217 1.99976 10C1.99976 7.87827 2.84261 5.84344 4.3429 4.34315C5.84319 2.84285 7.87802 2 9.99976 2C10.5998 2 11.1998 2.07 11.7898 2.21L13.3998 0.6C12.3098 0.21 11.1598 0 9.99976 0ZM16.9998 0L12.9998 4V5.5L10.4498 8.05C10.2998 8 10.1498 8 9.99976 8C9.46932 8 8.96062 8.21071 8.58554 8.58579C8.21047 8.96086 7.99976 9.46957 7.99976 10C7.99976 10.5304 8.21047 11.0391 8.58554 11.4142C8.96062 11.7893 9.46932 12 9.99976 12C10.5302 12 11.0389 11.7893 11.414 11.4142C11.789 11.0391 11.9998 10.5304 11.9998 10C11.9998 9.85 11.9998 9.7 11.9498 9.55L14.4998 7H15.9998L19.9998 3H16.9998V0ZM9.99976 4C8.40846 4 6.88233 4.63214 5.75712 5.75736C4.6319 6.88258 3.99976 8.4087 3.99976 10C3.99976 11.5913 4.6319 13.1174 5.75712 14.2426C6.88233 15.3679 8.40846 16 9.99976 16C11.5911 16 13.1172 15.3679 14.2424 14.2426C15.3676 13.1174 15.9998 11.5913 15.9998 10H13.9998C13.9998 11.0609 13.5783 12.0783 12.8282 12.8284C12.078 13.5786 11.0606 14 9.99976 14C8.93889 14 7.92147 13.5786 7.17133 12.8284C6.42118 12.0783 5.99976 11.0609 5.99976 10C5.99976 8.93913 6.42118 7.92172 7.17133 7.17157C7.92147 6.42143 8.93889 6 9.99976 6V4Z'
        fill={fillColor}
      />
    </svg>
  )
}

type TargetIconProps = Icon

export default TargetIcon
