import React from 'react'
import './title.module.scss'

type Props = {
  level?: 1 | 2 | 3 | 4 | 5 | 6
  children: $TSFixMe
  className?: string
}

const Title = ({ level = 3, children, ...restProps }: Props) => {
  const TitleTag = `h${level}`
  // @ts-expect-error TS(2559): Type '{ children: any; className?: string | undefi... Remove this comment to see the full error message
  return <TitleTag {...restProps}>{children}</TitleTag>
}

export default Title
