import { createReducer } from '@reduxjs/toolkit'

import { TOGGLE_REGISTER_PRACTICE_MODAL } from '../../../common/constants/reduxConstants'

type RegisterPracticeState = {
  showModal: boolean
}

const initialState = {
  showModal: false,
}

export default createReducer<RegisterPracticeState>(initialState, (builder) => {
  builder.addCase(TOGGLE_REGISTER_PRACTICE_MODAL, (state, action: any) => {
    state.showModal = action.payload
    return state
  })
})
