import React, { ReactElement } from 'react'
import colors from '../../../styles/_0colors.scss'

const IconBadge: React.FC<IconBadgeProps> = ({ width = 34, badgeCount, children, ...props }) => {
  const badgeStyle: React.CSSProperties = {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    padding: '0 10%',
    top: -width / 3 + 'px',
    right: -width / 3 + 'px',
    color: colors.x_white,
    fontSize: width / 3 + 'px',
    lineHeight: width / 3 + 'px',
    height: width / 1.5 + 'px',
    minWidth: width / 1.5 + 'px',
    fontWeight: '600',
    textAlign: 'center',
    backgroundColor: colors.x_alert,
    border: width / 20 + 'px solid' + colors.x_white,
    borderRadius: width / 4,
    userSelect: 'none',
  }
  if (width < 30) {
    Object.assign(badgeStyle, {
      height: width / 1.25 + 'px',
      minWidth: width / 1.25 + 'px',
      fontSize: width / 2 + 'px',
      lineHeight: width / 2 + 'px',
      borderWidth: width / 15 + 'px',
    })
  }
  return (
    <div style={{ position: 'relative' }} {...props}>
      {badgeCount > 0 ? (
        <div style={badgeStyle}>
          <div>{badgeCount}</div>
        </div>
      ) : (
        ''
      )}
      {children}
    </div>
  )
}

type IconBadgeProps = {
  width: number
  badgeCount: number
  children?: ReactElement
  onClick?: () => void
}

export default IconBadge
