import React, { useEffect, useMemo, useState } from 'react'

import styled from 'styled-components'
import { useTheme } from 'styled-components/native'

import { useZoom } from '../../hooks/useZoom'
import { ThemeType } from '../../utils'

export type ZoomSelfViewInnerProps = {}

const Video = styled.video<{ show: boolean; isMobileSized: boolean }>(({ show, isMobileSized }) => ({
  maxWidth: '100%',
  maxHeight: '100%',
  objectFit: isMobileSized ? 'cover' : undefined,
  display: show ? undefined : 'none',
}))
const Canvas = styled.canvas<{ show: boolean }>(({ show }) => ({
  maxWidth: '100%',
  maxHeight: '100%',
  display: show ? undefined : 'none',
}))

export const ZoomSelfViewInner: React.FC<ZoomSelfViewInnerProps> = ({}) => {
  const { setSelfElement, videoOff, sessionStarted, stream } = useZoom()
  const [videoEl, setVideoEl] = useState<HTMLVideoElement | null>(null)
  const [canvasEl, setCanvasEl] = useState<HTMLCanvasElement | null>(null)
  const theme = useTheme() as ThemeType

  const usingVideoEl = useMemo(() => stream?.isRenderSelfViewWithVideoElement() ?? false, [stream])
  useEffect(() => {
    setSelfElement(usingVideoEl ? videoEl : canvasEl)
    return () => setSelfElement(null)
  }, [videoEl, canvasEl, usingVideoEl, setSelfElement])

  return (
    <>
      <Video
        playsInline
        show={!videoOff && sessionStarted && usingVideoEl}
        isMobileSized={theme.breakpoints.isMobileSized}
        ref={setVideoEl}
        width='1920'
        height='1080'
      />
      <Canvas show={!videoOff && sessionStarted && !usingVideoEl} ref={setCanvasEl} width='1920' height='1080' />
    </>
  )
}
