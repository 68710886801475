import React, { Component } from 'react'
import styles from './dropZone.module.scss'
// @ts-expect-error TS(7016): Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import DropzoneComponent from 'react-dropzone-component'
import ReactDOMServer from 'react-dom/server'
import BaseInput from '../baseInput/BaseInput'

type Props = {
  meta?: $TSFixMe
  input?: $TSFixMe
  label?: string
  context: $TSFixMe
  placeholder: $TSFixMe
  className?: string
  disabled?: boolean
}

type State = $TSFixMe

class DropZone extends Component<Props, State> {
  componentConfig: $TSFixMe

  djsConfig: $TSFixMe

  dropzone: $TSFixMe

  field: $TSFixMe

  constructor(props: Props) {
    super(props)

    this.state = {
      hasFiles: false,
    }

    // For a full list of possible configurations,
    // please consult http://www.dropzonejs.com/#configuration
    this.djsConfig = {
      addRemoveLinks: true,
      acceptedFiles: 'image/jpeg,image/png',
      autoProcessQueue: false,
      paramName: this.props.input.name,
      dictDefaultMessage: '',
      thumbnailWidth: 80,
      thumbnailHeight: 80,
      previewTemplate: ReactDOMServer.renderToStaticMarkup(
        <div className='dz-preview dz-file-preview'>
          <div className='inner'>
            <div className='dz-image'>
              <img data-dz-thumbnail alt='' />
            </div>
            <div className='dz-details'>
              <div className='dz-size'>
                <span data-dz-size />
              </div>
              <div className='dz-filename'>
                <span data-dz-name />
              </div>
            </div>
            <div className='dz-progress'>
              <span className='dz-upload' data-dz-uploadprogress />
            </div>
            <div className='dz-error-message'>
              <span data-dz-errormessage />
            </div>
            <div className='dz-success-mark'>
              <svg width='54px' height='54px' viewBox='0 0 54 54' version='1.1' xmlns='http://www.w3.org/2000/svg'>
                <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                  <path
                    d='M23.5,31.8431458 L17.5852419,25.9283877 C16.0248253,24.3679711 13.4910294,24.366835 11.9289322,25.9289322 C10.3700136,27.4878508 10.3665912,30.0234455 11.9283877,31.5852419 L20.4147581,40.0716123 C20.5133999,40.1702541 20.6159315,40.2626649 20.7218615,40.3488435 C22.2835669,41.8725651 24.794234,41.8626202 26.3461564,40.3106978 L43.3106978,23.3461564 C44.8771021,21.7797521 44.8758057,19.2483887 43.3137085,17.6862915 C41.7547899,16.1273729 39.2176035,16.1255422 37.6538436,17.6893022 L23.5,31.8431458 Z M27,53 C41.3594035,53 53,41.3594035 53,27 C53,12.6405965 41.3594035,1 27,1 C12.6405965,1 1,12.6405965 1,27 C1,41.3594035 12.6405965,53 27,53 Z'
                    id='Oval-2'
                    strokeOpacity='0.198794158'
                    stroke='#747474'
                    fillOpacity='0.816519475'
                    fill='#FFFFFF'
                  />
                </g>
              </svg>
            </div>
            <div className='dz-error-mark'>
              <svg width='54px' height='54px' viewBox='0 0 54 54' version='1.1' xmlns='http://www.w3.org/2000/svg'>
                <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                  <g
                    id='Check-+-Oval-2'
                    stroke='#747474'
                    strokeOpacity='0.198794158'
                    fill='#FFFFFF'
                    fillOpacity='0.816519475'
                  >
                    <path
                      d='M32.6568542,29 L38.3106978,23.3461564 C39.8771021,21.7797521 39.8758057,19.2483887 38.3137085,17.6862915 C36.7547899,16.1273729 34.2176035,16.1255422 32.6538436,17.6893022 L27,23.3431458 L21.3461564,17.6893022 C19.7823965,16.1255422 17.2452101,16.1273729 15.6862915,17.6862915 C14.1241943,19.2483887 14.1228979,21.7797521 15.6893022,23.3461564 L21.3431458,29 L15.6893022,34.6538436 C14.1228979,36.2202479 14.1241943,38.7516113 15.6862915,40.3137085 C17.2452101,41.8726271 19.7823965,41.8744578 21.3461564,40.3106978 L27,34.6568542 L32.6538436,40.3106978 C34.2176035,41.8744578 36.7547899,41.8726271 38.3137085,40.3137085 C39.8758057,38.7516113 39.8771021,36.2202479 38.3106978,34.6538436 L32.6568542,29 Z M27,53 C41.3594035,53 53,41.3594035 53,27 C53,12.6405965 41.3594035,1 27,1 C12.6405965,1 1,12.6405965 1,27 C1,41.3594035 12.6405965,53 27,53 Z'
                      id='Oval-2'
                    />
                  </g>
                </g>
              </svg>
            </div>
          </div>
        </div>,
      ),
    }

    this.componentConfig = {
      showFiletypeIcon: false,
      postUrl: 'no-url',
    }

    this.dropzone = null
    this.field = null
    this.handleFileAdded = this.handleFileAdded.bind(this)
    this.handleRemove = this.handleRemove.bind(this)
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.disabled !== prevProps.disabled) {
      if (this.props.disabled) {
        this.dropzone.disable()
      } else {
        this.dropzone.enable()
      }
    }
  }

  handleFileAdded() {
    if (this.dropzone && this.dropzone.files.length > 0) {
      this.setState({
        hasFiles: true,
      })
    }

    this.field.onChange(this.dropzone.files)
  }

  handleRemove() {
    let filesToBubbleUp = this.dropzone.files
    if (!this.dropzone || this.dropzone.files.length === 0) {
      this.setState({
        hasFiles: false,
      })

      filesToBubbleUp = null
    }

    this.field.onChange(filesToBubbleUp)
  }

  render() {
    const {
      input,
      placeholder: Component,
      label,
      context,
      meta: { touched, error, initial },
      className = null,
      disabled,
    } = this.props
    const eventHandlers = {
      init: (dz: $TSFixMe) => {
        this.dropzone = dz
        this.field = input

        if (initial) {
          // Create the mock file:
          const mockFile = initial.toJS()[0]
          // Call the default addedfile event handler:
          dz.emit('addedfile', mockFile)
          // And optionally show the thumbnail of the file:
          dz.createThumbnailFromUrl(mockFile, 'http://localhost:3000/images/lyra-logo.png')
          // Make sure that there is no progress bar, etc...
          dz.emit('complete', mockFile)

          // If you use the maxFiles option, make sure you adjust it to the
          // correct amount:
          const existingFileCount = 1 // The number of files already uploaded
          dz.options.maxFiles = dz.options.maxFiles - existingFileCount
          this.setState({
            hasFiles: true,
          })
        }
      },
      addedfile: this.handleFileAdded,
      removedfile: this.handleRemove,
    }

    return (
      // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
      <BaseInput className={className} label={label} touched={touched} error={error} disabled={disabled}>
        <div className={styles['dropzone-container']}>
          <DropzoneComponent
            className={touched && error ? styles['dropzone-invalid'] : styles.dropzone}
            config={this.componentConfig}
            eventHandlers={eventHandlers}
            djsConfig={this.djsConfig}
          >
            {this.state.hasFiles ? [] : <Component />}
          </DropzoneComponent>
          {context && <div className={styles['context-container']}>{context}</div>}
        </div>
      </BaseInput>
    )
  }
}

export default DropZone
