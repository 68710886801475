import React, { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'

import styled, { useTheme } from 'styled-components/native'

import { BodyText, Size as BodyTextSize } from '../../atoms/bodyText/BodyText'
import { Divider } from '../../atoms/divider/Divider'
import { AvatarDetails } from '../../atoms/icons/Avatar'
import { StyledMarkdown } from '../../atoms/styledMarkdown/StyledMarkdown'
import { Subhead, Size as SubheadSize } from '../../atoms/subhead/Subhead'
import { IS_WEB } from '../../constants'
import { ColumnView } from '../../templates/content/CommonViews'
import { ThemeType, tID } from '../../utils'
import { ProviderSessionCard } from '../providerSessionCard/ProviderSessionCard'

type ProviderAvatarDetails = Omit<AvatarDetails, 'hide'>

export type CustomizedActivityIntroductionProps = {
  content: string
  providerAvatar: ProviderAvatarDetails
  providerFirstName: string
  sessionInfo: string
}

const ProviderCardContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  paddingBottom: theme.spacing['16px'],
  marginBottom: theme.spacing['24px'],
  borderBottomWidth: '1px',
  ...(IS_WEB && { borderBottomStyle: 'solid' }),
  borderBottomColor: theme.colors.borderDefault,
}))

const FooterDivider = styled(Divider)(({ theme }) => ({
  marginTop: '28px',
  marginBottom: theme.spacing['16px'],
}))

export const CustomizedActivityIntroduction: FunctionComponent<CustomizedActivityIntroductionProps> = ({
  content,
  providerAvatar,
  providerFirstName,
  sessionInfo,
}) => {
  const { formatMessage } = useIntl()
  const { colors } = useTheme()
  const customStyles = {
    p2: {
      marginBottom: 12,
    },
  }

  return (
    <ColumnView>
      <ProviderCardContainer>
        <ProviderSessionCard
          avatarDetails={providerAvatar}
          sessionInfo={sessionInfo}
          testID={tID('CustomizedActivityIntroduction-provider-details')}
        />
      </ProviderCardContainer>
      <Subhead
        text={formatMessage(
          {
            defaultMessage: 'From: {providerFirstName}',
            description: 'Title stating the first name of who the introduction message is from',
          },
          { providerFirstName },
        )}
        size={SubheadSize.XSMALL}
      />
      <StyledMarkdown content={content} customStyles={customStyles} />
      <FooterDivider height={1} color={colors.dividerSecondary} />
      <BodyText
        text={formatMessage({
          defaultMessage:
            'NOTE: Your responses are confidential. We protect your identity by sharing only anonymized, aggregated results with the employer sponsoring your Lyra benefit.',
          description: 'Privacy message making the user aware that their identity is protected.',
        })}
        size={BodyTextSize.SMALL}
        color={colors.textSecondary}
      />
    </ColumnView>
  )
}
