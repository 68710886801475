import React, { FunctionComponent } from 'react'

import contactUsSuccess from '../../assets/contact_us_success.png'
import { Image } from '../image/Image'

interface ContactUsSuccessIllustrationProps {
  width?: number
  height?: number
}

export const ContactUsSuccessIllustration: FunctionComponent<ContactUsSuccessIllustrationProps> = ({
  width = 196,
  height = 196,
  ...props
}) => {
  return (
    <Image
      source={contactUsSuccess}
      style={{ width, height }}
      contentFit='contain'
      {...props}
      accessibilityIgnoresInvertColors
    />
  )
}
