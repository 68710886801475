import React, { FunctionComponent } from 'react'
import { Path, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { tID } from '../../utils'

/**
 * Standard checkmark icon used in ToggleSwitch and PasswordChecklist
 */
export const CheckIcon: FunctionComponent<CheckIconProps> = ({
  size = 20,
  fillColor,
  isFilled = false,
  withCircle = true,
}) => {
  const { colors } = useTheme()
  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' testID={tID('CheckIcon')}>
      {withCircle ? (
        isFilled ? (
          <Path
            fill={fillColor || colors.iconDefault}
            d='M16.2803,10.2798 L11.0303,15.5298 C10.8803,15.6798 10.6903,15.7498 10.5003,15.7498 C10.3103,15.7498 10.1203,15.6798 9.9693,15.5298 L7.7193,13.2798 C7.4303,12.9898 7.4303,12.5098 7.7193,12.2198 C8.0093,11.9298 8.4903,11.9298 8.7803,12.2198 L10.5003,13.9398 L15.2193,9.2198 C15.5093,8.9298 15.9903,8.9298 16.2803,9.2198 C16.5703,9.5098 16.5703,9.9898 16.2803,10.2798 M12.0003,1.9998 C6.4803,1.9998 2.0003,6.4798 2.0003,11.9998 C2.0003,17.5198 6.4803,21.9998 12.0003,21.9998 C17.5203,21.9998 22.0003,17.5198 22.0003,11.9998 C22.0003,6.4798 17.5203,1.9998 12.0003,1.9998'
          />
        ) : (
          <Path
            fill={fillColor || colors.iconDefault}
            d='M12,2 C17.523,2 22,6.477 22,12 C22,17.523 17.523,22 12,22 C6.477,22 2,17.523 2,12 C2,6.477 6.477,2 12,2 M12,3.5 C7.313,3.5 3.5,7.313 3.5,12 C3.5,16.687 7.313,20.5 12,20.5 C16.687,20.5 20.5,16.687 20.5,12 C20.5,7.313 16.687,3.5 12,3.5 M10.49975,15.75 C10.30775,15.75 10.11575,15.677 9.96975,15.53 L7.71975,13.28 C7.42675,12.987 7.42675,12.513 7.71975,12.22 C8.01275,11.927 8.48675,11.927 8.77975,12.22 L10.49975,13.939 L15.21975,9.22 C15.51275,8.927 15.98675,8.927 16.27975,9.22 C16.57275,9.513 16.57275,9.987 16.27975,10.28 L11.02975,15.53 C10.88375,15.677 10.69175,15.75 10.49975,15.75'
          />
        )
      ) : isFilled ? (
        <Path
          fill={fillColor || colors.iconDefault}
          d='M9.91627314,17.5627396 C9.5855881,17.5627396 9.25490306,17.4370104 9.00344465,17.1838297 L5.12822936,13.3086144 C4.62359021,12.8039752 4.62359021,11.9875965 5.12822936,11.4829574 C5.63286851,10.9783182 6.44924719,10.9783182 6.95388634,11.4829574 L9.91627314,14.4436219 L18.0456137,6.31600366 C18.5502528,5.81136451 19.3666315,5.81136451 19.8712706,6.31600366 C20.3759098,6.82064281 20.3759098,7.63702149 19.8712706,8.14166064 L10.8291016,17.1838297 C10.5776432,17.4370104 10.2469582,17.5627396 9.91627314,17.5627396'
        />
      ) : (
        <Path
          d='M8.99991 16.17L4.82991 12L3.40991 13.41L8.99991 19L20.9999 6.99997L19.5899 5.58997L8.99991 16.17Z'
          fill={fillColor || colors.iconDefault}
        />
      )}
    </Svg>
  )
}

type CheckIconProps = {
  size?: string | number
  fillColor?: string
  isFilled?: boolean
  withCircle?: boolean
}
