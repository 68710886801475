import React from 'react'
import CSSModules from 'react-css-modules'
import { connect } from 'react-redux'

import { bindActionCreators } from 'redux'

import {
  BaseModal,
  BootstrapContainer,
  ContentLayout,
  DataTable,
  InputField,
  LoadingIndicator,
  PrimaryButton,
  SearchIcon,
} from '@lyrahealth-inc/ui-core'

import AddPracticeView from './addPracticeView/AddPracticeView'
import modalStyles from './addPracticeView/addPracticeView.module.scss'
import * as practicesDashboardActions from './data/practicesDashboardActions'
import { getPracticesDashboardShowModal } from './data/practicesDashboardSelectors'
import styles from './practicesDashboard.module.scss'
import { ROLES } from '../../common/constants/appConstants'
import { GET_PRACTICES } from '../../common/constants/reduxConstants'
import { PRACTICES_DETAILS_INFO } from '../../common/constants/routingConstants'
import { axiosAuthInstance } from '../../common/http/axiosInstance'
import { getErrorText, hasErrorForAction, hasRole } from '../../common/utils/utils'
import * as alertActions from '../../data/alertActions'
import { getAlertsState } from '../../data/alertsSelectors'
import { getAuthRoles } from '../../data/auth/authSelectors'
import withRouter from '../../routing/withRouter'
import * as practicesDataActions from '../data/practicesDataActions'
import {
  getPracticesDataAvailablePractices,
  getPracticesDataIsLoadingAvailablePractices,
  getPracticesDataPracticesList,
} from '../data/practicesDataSelectors'
import * as practiceDetailsActions from '../individualPractice/data/practiceDetailsActions'

export const tableColumns = [
  {
    Header: 'Name',
    accessor: 'practice_name',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Number of Providers',
    accessor: 'providersNum',
    maxWidth: 200,
  },
]

type PracticesDashboardProps = {
  showModal?: boolean
  actions?: any
  router?: any
  roles?: any // TODO: PropTypes.instanceOf(List)
  practicesList?: any // TODO: PropTypes.instanceOf(List)
  alerts?: any // TODO: PropTypes.instanceOf(List)
  isLoadingAvailablePractices?: boolean
  availablePractices?: any // TODO: PropTypes.instanceOf(List)
}

type PracticesDashboardState = any

export class PracticesDashboard extends React.Component<PracticesDashboardProps, PracticesDashboardState> {
  constructor(props: PracticesDashboardProps) {
    super(props)

    this.state = {
      search: '',
    }
  }

  componentDidMount() {
    this.props.actions.getPractices()
  }

  componentWillUnmount() {
    if (typeof (axiosAuthInstance as any).cancelLastRequest === 'function') {
      ;(axiosAuthInstance as any).cancelLastRequest()
    }
  }

  _rowClickHandler = (state: any, rowInfo: any) => {
    this.props.actions.practiceSelected(rowInfo.original)
    this.props.router.navigate(PRACTICES_DETAILS_INFO.route)
  }

  _fetchAvailablePracticesAndShowModal() {
    this.props.actions.getAvailablePractices().then(
      () => {
        this.props.actions.showAddPracticeModal()
      },
      (error: any) => {
        const errorText = error.response.data.message || getErrorText(error.response.status)
        this.props.actions.addAlert({
          show: true,
          contents: errorText,
          style: 'danger',
          expires: true,
        })
      },
    )
  }

  _submitAddPractice = ($$values: any) => {
    const formData = new FormData()
    $$values.forEach((val: any, key: any) => {
      formData.append(key, val)
    })

    return this.props.actions.addPractice(formData)
  }

  render() {
    const { showModal, practicesList, availablePractices, isLoadingAvailablePractices } = this.props
    const { hideAddPracticeModal } = this.props.actions

    if (!practicesList) {
      if (hasErrorForAction(this.props.alerts, GET_PRACTICES)) {
        return false // custom workflow or display could render here
      } else {
        return (
          <ContentLayout>
            <BootstrapContainer>
              <h2 styleName='title'>Practices</h2>
              <div styleName='card loading-container'>
                <LoadingIndicator size={45} />
              </div>
            </BootstrapContainer>
          </ContentLayout>
        )
      }
    }

    let filteredData = practicesList
    if (this.state.search) {
      filteredData = filteredData.filter((row: any) => {
        return (
          row.practice_name.toLowerCase().includes(this.state.search.toLowerCase()) ||
          row.email.toLowerCase().includes(this.state.search.toLowerCase())
        )
      })
    }
    const AddPractice = (
      // @ts-expect-error TS(2322): Type '{ availablePractices: any; submitAddPractice... Remove this comment to see the full error message
      <AddPracticeView availablePractices={availablePractices ?? []} submitAddPractice={this._submitAddPractice} />
    )

    return (
      <ContentLayout>
        <BootstrapContainer>
          <h2 styleName='title'>Practices</h2>
          <div styleName='filter-add-container'>
            <InputField
              // @ts-expect-error TS(2739): Type '{ name: string; value: any; onChange: (e: an... Remove this comment to see the full error message
              input={{
                name: 'external_filter',
                value: this.state.search,
                onChange: (e: any) => this.setState({ search: e.target.value }),
              }}
              className={styles['filter-input']}
              type='text'
              // @ts-expect-error TS(2739): Type '{}' is missing the following properties from... Remove this comment to see the full error message
              meta={{}}
              component={InputField}
              insetIcon={SearchIcon}
              placeholder='Search by name or email'
            />
            {hasRole(this.props.roles, ROLES.PROVIDERS_ADMIN) ? (
              <PrimaryButton
                onClick={() => this._fetchAvailablePracticesAndShowModal()}
                isLoading={isLoadingAvailablePractices}
              >
                Add A Practice
              </PrimaryButton>
            ) : (
              []
            )}
          </div>

          <DataTable
            destinationSelector='id'
            // @ts-expect-error TS(2322): Type '(state: any, rowInfo: any) => void' is not a... Remove this comment to see the full error message
            rowClickHandler={this._rowClickHandler}
            className={styles.card}
            data={filteredData}
            columns={tableColumns}
          />
        </BootstrapContainer>

        <BaseModal
          // @ts-expect-error TS(2322): Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
          isOpen={showModal}
          body={AddPractice}
          size='large'
          modalClass='add-practice-modal'
          customStyles={modalStyles}
          closeModal={hideAddPracticeModal}
        />
      </ContentLayout>
    )
  }
}

const mapStateToProps = ($$state: any) => {
  return {
    showModal: getPracticesDashboardShowModal($$state),
    roles: getAuthRoles($$state),
    practicesList: getPracticesDataPracticesList($$state),
    alerts: getAlertsState($$state),
    isLoadingAvailablePractices: getPracticesDataIsLoadingAvailablePractices($$state),
    availablePractices: getPracticesDataAvailablePractices($$state),
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    actions: bindActionCreators(
      { ...practicesDashboardActions, ...practiceDetailsActions, ...practicesDataActions, ...alertActions },
      dispatch,
    ),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CSSModules(PracticesDashboard, styles, { allowMultiple: true })),
)
