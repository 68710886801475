export default {
  meta_data: {
    schema: {
      type: 'object',
      properties: {
        Header: {
          content: '### How was the audio and video quality?',
        },
        FeedbackStars: {
          name: 'FeedbackStars',
          type: 'number',
          validation: {
            isRequired: true,
          },
        },
        whatHappened: {
          content: '###### What went wrong?',
          condition: {
            parentField: 'FeedbackStars',
            parentValue: [1, 2, 3, 4],
          },
        },
        options: {
          type: 'array',
          condition: {
            parentField: 'FeedbackStars',
            parentValue: [1, 2, 3, 4],
          },
          items: [
            {
              name: 'couldntJoinSession',
              type: 'boolean',
              title: "Couldn't join session",
            },
            {
              name: 'videoWasPixelated',
              type: 'boolean',
              title: 'Video was pixelated',
            },
            {
              name: 'videoFroze',
              type: 'boolean',
              title: 'Video was freezing',
            },
            {
              name: 'videoAndAudioOutOfSync',
              type: 'boolean',
              title: 'Video and audio out of sync',
            },
            {
              name: 'sessionDropped',
              type: 'boolean',
              title: 'Session dropped',
            },
            {
              name: 'videoStoppedWorking',
              type: 'boolean',
              title: 'Video stopped working',
            },
            {
              name: 'soundStoppedWorking',
              type: 'boolean',
              title: 'Sound stopped working',
            },
          ],
          validation: {
            isRequired: true,
          },
        },
        tellUsMore: {
          name: 'tellUsMore',
          type: 'string',
          title: '###### Any other problems?',
          condition: {
            parentField: 'FeedbackStars',
            parentValue: [1, 2, 3, 4],
          },
        },
      },
    },
    uiSchema: {
      'ui:order': ['Header', 'FeedbackStars', 'whatHappened', 'options', 'tellUsMore'],
      Header: {
        'ui:options': { center: true, maxWidth: 300 },
      },
      FeedbackStars: {
        'ui:field': 'FeedbackStars',
      },
      whatHappened: {
        'ui:options': {
          customStyles: {
            p1: { marginTop: 24 },
          },
        },
      },
      options: {
        items: [
          { 'ui:widget': 'checkbox' },
          { 'ui:widget': 'checkbox' },
          { 'ui:widget': 'checkbox' },
          { 'ui:widget': 'checkbox' },
          { 'ui:widget': 'checkbox' },
          { 'ui:widget': 'checkbox' },
          { 'ui:widget': 'checkbox' },
        ],
      },
      tellUsMore: {
        'ui:widget': 'textarea',
      },
    },
  },
}
