import React, { FunctionComponent, useMemo } from 'react'

import styled from 'styled-components/native'

import { AvatarDetails } from '../../atoms'
import { useZoom } from '../../hooks/useZoom'
import { DisplayMode, JoinSessionParams } from '../../hooks/useZoom/types'
import { useZoomVideoPlacement } from '../../hooks/useZoom/useZoomVideoPlacement'
import { RowView } from '../../templates'
import { tID } from '../../utils'
import { ZoomDisplayModeButton } from '../zoomDisplayModeButton/ZoomDisplayModeButton'
import { ZoomParticipantView } from '../zoomParticipantView/ZoomParticipantView'
import { ZoomScreenshareView } from '../zoomScreenshareView/ZoomScreenshareView'
import { ZoomSelfView } from '../zoomSelfView/ZoomSelfView'
import { ZoomVideoCheck } from '../zoomVideoCheck/ZoomVideoCheck'
import { ZoomWaitingRoom } from '../zoomWaitingRoom/ZoomWaitingRoom'

const Container = styled(RowView)<{ show: boolean }>(({ show, theme }) => ({
  backgroundColor: theme.colors.components.zoomVideoSession.background,
  height: 'calc(100% - 90px)',
  display: show ? undefined : 'none',
}))

const InnerContainer = styled.View({
  justifyContent: 'center',
  width: '100%',
  alignItems: 'center',
})

const DisplayButton = styled(ZoomDisplayModeButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing['12px'],
  left: theme.spacing['12px'],
  zIndex: 2,
}))

export type ZoomFullScreenViewProps = {
  participantName: string
  participantFirstName?: string
  getSessionParams: () => Promise<JoinSessionParams>
  avatarDetails?: AvatarDetails
  participantTitle?: string
  sessionStartDate: Date
  sessionDuration: number
  sessionNumber?: number
}

export const ZoomFullScreenView: FunctionComponent<ZoomFullScreenViewProps> = ({
  participantFirstName,
  getSessionParams,
  participantName,
  avatarDetails,
  participantTitle,
  sessionStartDate,
  sessionDuration,
  sessionNumber,
}) => {
  const {
    remoteParticipantScreenSharing,
    inSession,
    sessionStarted,
    config: { canChangeDisplayMode, shouldJoinOnOpen },
  } = useZoom()
  const zoomVideoPlacement = useZoomVideoPlacement(DisplayMode.FULL_SCREEN)

  const childView = useMemo(() => {
    if (sessionStarted || shouldJoinOnOpen) {
      return (
        <InnerContainer>
          {zoomVideoPlacement.self && <ZoomSelfView isFullscreen />}
          <ZoomParticipantView
            show={zoomVideoPlacement.participant}
            participantFirstName={participantFirstName}
            participantName={participantName}
            avatarDetails={avatarDetails}
            isFullscreen
            participantTitle={participantTitle}
          />
          <ZoomScreenshareView />
        </InnerContainer>
      )
    }

    if (inSession) {
      return <ZoomWaitingRoom participantName={participantName} />
    }

    return (
      <ZoomVideoCheck
        getSessionParams={getSessionParams}
        participantName={participantName}
        sessionStartDate={sessionStartDate}
        sessionNumber={sessionNumber}
        sessionDuration={sessionDuration}
      />
    )
  }, [
    avatarDetails,
    inSession,
    participantFirstName,
    participantName,
    participantTitle,
    sessionDuration,
    sessionNumber,
    getSessionParams,
    sessionStartDate,
    sessionStarted,
    shouldJoinOnOpen,
    zoomVideoPlacement.participant,
    zoomVideoPlacement.self,
  ])

  return (
    <Container
      show={zoomVideoPlacement.self || zoomVideoPlacement.participant || remoteParticipantScreenSharing}
      testID={tID('ZoomFullScreenView-container')}
    >
      {canChangeDisplayMode && <DisplayButton />}
      {childView}
    </Container>
  )
}
