import React, { FunctionComponent } from 'react'

import styled from 'styled-components/native'

import {
  ASSETS_CDN_URL,
  TREATMENT_REC_PRIMARY_CARD_CDN_PREFIX as namespace,
  PRIMARY_TREATMENT_REC_IMAGE_CONTAINER_DIMENSIONS,
} from '../../constants'
import { tID } from '../../utils'
import { Image } from '../image/Image'

const ImageSizer = styled(Image)<{ height: string; width: string }>(({ height, width }) => ({
  width,
  height,
}))

const PADDING = {
  DESKTOP: {},
  TABLET: {
    paddingLeft: '38px',
  },
  MOBILE: {},
}

const Container = styled.View<{ viewport: 'DESKTOP' | 'TABLET' | 'MOBILE' }>(({ viewport }) => ({
  ...PRIMARY_TREATMENT_REC_IMAGE_CONTAINER_DIMENSIONS[viewport],
  ...PADDING[viewport],
  justifyContent: 'flex-end',
  overflow: 'hidden',
}))

const IMAGE_SIZES = {
  DESKTOP: {
    height: '367px',
    width: '417px',
  },
  TABLET: {
    height: '291px',
    width: '331px',
  },
  MOBILE: {
    height: '348px',
    width: '396px',
  },
}

const IMAGE_POSITIONS = {
  DESKTOP: {
    top: 10,
    left: 14,
  },
  TABLET: {
    top: 5,
    right: 0,
  },
  MOBILE: {
    top: 31,
    right: 22,
  },
}

/**
 * Illustration to show on the treatment card for Renew
 */
export const RenewRec: FunctionComponent<RenewRecProps> = ({ viewport = 'DESKTOP' }) => {
  return (
    <Container viewport={viewport}>
      <ImageSizer
        {...IMAGE_SIZES[viewport]}
        testID={`${tID('RenewRecIllustration')}`}
        source={{ uri: ASSETS_CDN_URL + namespace + 'renew.png' }}
        contentPosition={IMAGE_POSITIONS[viewport]}
        contentFit='cover'
      />
    </Container>
  )
}

export type RenewRecProps = {
  viewport?: 'DESKTOP' | 'TABLET' | 'MOBILE'
}
