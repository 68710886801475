import React, { FunctionComponent } from 'react'
import { Path, Svg } from 'react-native-svg'

import { PrimaryNeedsWords } from '@lyrahealth-inc/shared-app-logic'

import { tID } from '../../utils'

type DevelopmentalDelaysIllustrationProps = {
  size?: string | number
  selected?: string
}

export const DevelopmentalDelaysIllustration: FunctionComponent<DevelopmentalDelaysIllustrationProps> = ({
  selected = false,
  size,
}) => (
  <Svg
    width={size}
    height={size}
    viewBox='0 0 48 48'
    fill='none'
    testID={tID(`${PrimaryNeedsWords.DEVELOPMENTAL_CONCERNS}${selected ? '-Selected-' : '-'}Illustration`)}
  >
    <Path
      d='M44.7535 30.4398C44.4317 22.8753 45.3875 12.9299 37.5084 7.58662C40.6257 6.96935 40.3707 2.66992 36.7266 2.66992H11.3138C7.54822 2.66992 7.39569 7.26965 10.8657 7.63667C2.57433 13.2636 3.5062 23.75 3.18922 31.8293C3.09628 34.2459 2.92467 36.6697 2.91514 39.0887C2.90561 41.2766 3.561 43.5812 5.91329 44.8729C8.30848 46.1885 11.6475 45.9263 14.3954 45.9668C17.4913 46.0145 20.5871 46.0121 23.683 45.9573C26.7789 45.9025 29.8723 45.7976 32.9634 45.6427C34.4578 45.5665 35.9521 45.4807 37.4464 45.3806C39.0718 45.2733 40.6423 45.2018 42.0175 44.4106C44.4961 42.9854 45.0085 40.1517 45.0395 37.8494C45.0729 35.3827 44.8607 32.9041 44.7559 30.4398H44.7535Z'
      fill={selected ? '#85CCD5' : '#EDE6DE'}
    />
    <Path
      d='M39.3462 39.9548C38.2666 38.5439 36.2837 37.9695 34.5844 37.5167C34.1173 37.3927 33.643 37.2879 33.1687 37.1997C30.173 36.4084 26.7816 36.4895 23.8597 36.6158C18.5545 36.8446 13.3232 37.9266 8.39224 39.807C8.33742 39.8284 8.28976 39.8523 8.24209 39.8761C7.56762 40.0358 7.21014 40.5482 7.32931 41.256C7.69156 43.4367 10.878 42.9458 12.4843 42.9005L21.3453 42.6574C23.2376 42.605 25.211 42.6383 27.1891 42.6073C28.7168 42.6669 30.2469 42.6669 31.7769 42.6002C33.1163 42.5406 34.4533 42.4334 35.7832 42.2784C36.8461 42.1545 38.0402 42.1331 38.922 41.492C39.3867 41.1535 39.7561 40.4934 39.3438 39.9548H39.3462Z'
      fill={selected ? '#E7F8F8' : '#D7C7B5'}
    />
    <Path
      d='M37.3367 16.7811C36.8601 15.7539 34.9988 15.6156 33.9597 15.6514C32.4916 15.7038 31.0902 16.1376 29.7603 16.6786C27.5248 17.589 25.5658 18.9832 24.1572 20.785C22.9227 18.8116 20.9303 17.1695 18.597 16.3401C16.5522 15.6156 12.7676 15.3177 11.166 17.0623C9.42623 18.957 13.5946 21.0018 14.9459 21.7526C17.2219 23.0205 19.8626 23.9833 22.508 23.3541C21.283 27.9872 20.313 32.8157 21.8979 37.4774C22.365 38.8549 24.7626 38.2687 24.2907 36.8792C22.7988 32.4868 23.8236 27.961 24.989 23.6115C27.4795 23.8856 30.2656 22.98 32.4463 22.1124C33.7571 21.5905 35.0059 20.8708 35.9592 19.8936C36.6718 19.162 37.8182 17.8178 37.3344 16.7787L37.3367 16.7811Z'
      fill={selected ? '#008295' : '#8CC94B'}
    />
    <Path
      d='M10.2411 7.46262C19.4548 8.22288 28.6947 8.2491 37.9085 7.5365C40.7684 7.31485 40.7565 1.85717 37.8799 2.07881C28.6662 2.79141 19.4262 2.76758 10.2125 2.00493C9.00654 1.90483 7.9841 3.34671 7.99125 4.74569C8.00078 6.3115 9.03275 7.36252 10.2411 7.46262Z'
      fill={selected ? '#66BAC5' : '#FFB0AE'}
    />
  </Svg>
)
