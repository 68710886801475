import {
  characterLimit,
  requiredField as isRequired,
  isValidPastDate,
  isPositiveInteger,
  isValidPastYear,
  hasValue,
  maxValue,
  minValue,
} from '../../constants/formValidationConstants'
import { isFunction, get, merge } from 'lodash-es'
import { isFieldDisplayed, validPhoneNumber as isValidPhoneNumber } from '@lyrahealth-inc/shared-app-logic'

const validationRules = {
  isRequired,
  characterLimit,
  isValidPastDate,
  isValidPhoneNumber,
  isPositiveInteger,
  isValidPastYear,
  hasValue,
  maxValue,
  minValue,
}

export const validateForm = (fields: $TSFixMe, values: $TSFixMe, errors = {}) => {
  // @ts-expect-error TS(7030): Not all code paths return a value.
  Object.entries(fields).forEach(([, { name, items, condition, validation }]) => {
    if (items) {
      const fields = items.reduce(
        // @ts-expect-error TS(7006): Parameter 'obj' implicitly has an 'any' type.
        (obj, item) => ({
          [item.name]: item,
          ...obj,
        }),
        {},
      )
      return validateForm(fields, values, errors)
    }

    if (!validation || !isFieldDisplayed({ condition, formValues: values, formFields: fields, name })) {
      return
    }
    Object.entries(validation).forEach(([key, validationValue]) => {
      if (isFunction(validationRules[key])) {
        errors = merge(errors, validationRules[key](get(values, name), name, validationValue))
      }
    })
  })
  return errors
}
