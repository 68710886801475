import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

import { AnyAction, bindActionCreators, Dispatch } from 'redux'

import { LoadingIndicator } from '@lyrahealth-inc/ui-core-crossplatform'

import { SchedulingConnectedBody } from './connected/SchedulingConnectedBody'
import { googleOauthAuthError, googleOauthInternalError, LoadingContainer } from './constants'
import { getSchedulingData } from './data/schedulingActions'
import {
  getSchedulingAvailability,
  getSchedulingCalendars,
  getSchedulingCredentials,
  getSchedulingCredentialsValid,
  getSchedulingError,
  getSchedulingLoaded,
} from './data/schedulingSelectors'
import { SchedulingLandingBody } from './landing/SchedulingLandingBody'
import { SchedulingFooter } from './SchedulingFooter'
import { addAlert } from '../data/alertActions'
import { getAuthUserId } from '../data/auth/authSelectors'
import { getProviderDetailsCalendarState } from '../providers/data/providerSelectors'
import { getProvider } from '../providers/individualProvider/data/providerDetailsActions'

const SmartScheduling: React.FC<SmartSchedulingProps> = ({
  providerCalendarState,
  schedulingDataLoaded,
  schedulingCredentials,
  schedulingCredentialsValid,
  schedulingAvailability,
  schedulingCalendars,
  userId,
  schedulingError,
  actions: { getProvider, getSchedulingData, addAlert },
}) => {
  const [searchParams] = useSearchParams()
  const oauthError = searchParams.get('oac')

  useEffect(() => {
    if (!schedulingDataLoaded) {
      getSchedulingData(userId)
    }
  }, [getSchedulingData, schedulingDataLoaded, userId])

  // We can reach this page without loading providerData if we don't come from request payment form. So, we need to load
  // provider data here only in the case where it hasn't already been loaded.
  useEffect(() => {
    if (!providerCalendarState) {
      getProvider(userId)
    }
  }, [getProvider, userId, providerCalendarState])

  useEffect(() => {
    if (providerCalendarState && schedulingDataLoaded) {
      // This error is present when the Google authorization has been revoked.
      if (schedulingError) {
        addAlert({
          show: true,
          contents: schedulingError,
          style: 'danger',
          expires: true,
        })
      }
      if (oauthError) {
        // This error is present when returning from Google connection if an error occurred during that process
        let errorMessage = ''
        oauthError === '500' ? (errorMessage = googleOauthInternalError) : (errorMessage = googleOauthAuthError)
        addAlert({
          show: true,
          contents: errorMessage,
          style: 'danger',
          expires: true,
        })
      }
    }
  }, [schedulingError, providerCalendarState, schedulingDataLoaded, addAlert, oauthError])

  if (providerCalendarState && schedulingDataLoaded) {
    return (
      <>
        {schedulingCredentials ? (
          <SchedulingConnectedBody
            providerCalendarState={providerCalendarState}
            schedulingAvailability={schedulingAvailability}
            schedulingCalendars={schedulingCalendars}
            schedulingCredentialsValid={schedulingCredentialsValid}
            providerId={userId}
          />
        ) : (
          <SchedulingLandingBody />
        )}
        <SchedulingFooter
          providerCalendarState={providerCalendarState}
          schedulingCredentialsValid={schedulingCredentialsValid}
        />
      </>
    )
  } else {
    return (
      <LoadingContainer>
        <LoadingIndicator size={45} />
      </LoadingContainer>
    )
  }
}

type SmartSchedulingProps = {
  providerCalendarState: string
  schedulingDataLoaded: boolean
  schedulingCredentials: {
    access_token: string
    refresh_token: string
    valid: boolean
  }
  schedulingCredentialsValid: boolean
  schedulingAvailability: {
    availabilities: string[]
    times: string[][]
  }
  schedulingCalendars: {
    accessRole: string
    id: string
    summary: string
    use: string
    primary: boolean
  }[]
  userId: string
  schedulingError: string
  actions: {
    getProvider: (providerId: string) => void
    getSchedulingData: (providerId: string) => void
    addAlert: ({
      show,
      contents,
      style,
      expires,
      autoDismissTimer,
    }: {
      show: boolean
      contents: React.ReactNode
      style: string
      expires: boolean
      autoDismissTimer?: number
    }) => void
  }
}
const mapStateToProps = ($$state: any) => {
  return {
    providerCalendarState: getProviderDetailsCalendarState($$state),
    schedulingDataLoaded: getSchedulingLoaded($$state),
    schedulingCredentials: getSchedulingCredentials($$state),
    schedulingCredentialsValid: getSchedulingCredentialsValid($$state),
    schedulingAvailability: getSchedulingAvailability($$state),
    schedulingCalendars: getSchedulingCalendars($$state),
    schedulingError: getSchedulingError($$state),
    userId: getAuthUserId($$state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    actions: bindActionCreators(
      {
        getProvider,
        getSchedulingData,
        addAlert,
      },
      dispatch,
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SmartScheduling)
