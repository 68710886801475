export enum AIDraftStatus {
  COMPLETE = 'COMPLETE',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_STARTED = 'NOT_STARTED',
  ERROR = 'ERROR',
  NOT_FOUND = 'NOT_FOUND',
}

interface AppointmentSummaryEntry {
  prompt_type: string
  summary_text: string
}

export interface AppointmentSummary {
  appointment_id: string
  status: AIDraftStatus
  summary: AppointmentSummaryEntry[]
}

export const noteSummaryStatusText = {
  [AIDraftStatus.COMPLETE]: 'DRAFT AVAILABLE',
  [AIDraftStatus.IN_PROGRESS]: 'DRAFT PROCESSING',
  [AIDraftStatus.NOT_STARTED]: 'DRAFT PROCESSING',
  [AIDraftStatus.ERROR]: 'DRAFT UNAVAILABLE',
  [AIDraftStatus.NOT_FOUND]: 'DRAFT UNAVAILABLE',
}
