import React, { FunctionComponent } from 'react'
import { Path, Rect, Svg } from 'react-native-svg'

import { Tooltip } from '../../atoms/tooltip/Tooltip'
import { colors } from '../../styles'
import { tID } from '../../utils'

export const ClinicalLeaveEvaluationIcon: FunctionComponent<ClinicalLeaveEvaluationIconProps> = ({
  showTooltip = false,
  tooltipLabel = 'Clinical Leave Evaluation',
  width = 44,
  height = 44,
}) => {
  const icon = (
    <Svg width={width} height={height} viewBox={'0 0 44 44'} testID={tID('ClinicalLeaveEvaluationIcon')}>
      <Rect width={44} height={44} fill={colors.purple1} />
      <Path fill={colors.purple5} d='M19.4 27.7h11V32h-16V9.6H30v4.3H19.4v4.6h9v4.2h-9v5z' />
    </Svg>
  )

  return showTooltip ? (
    <Tooltip content={tooltipLabel} placement='bottom' hoverEnabled={true}>
      {icon}
    </Tooltip>
  ) : (
    <>{icon}</>
  )
}

type ClinicalLeaveEvaluationIconProps = {
  showTooltip?: boolean
  tooltipLabel?: string
  width?: number
  height?: number
}
