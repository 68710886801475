import React, { useState } from 'react'
import CSSModules from 'react-css-modules'
import { connect } from 'react-redux'
import { Outlet, useLocation } from 'react-router-dom'

import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'

import { DropdownButton } from '@lyrahealth-inc/ui-core'
import { toJS, useFetcher } from '@lyrahealth-inc/ui-core-crossplatform'

import styles from './clientNotesRoutes.module.scss'
import { CLIENT_NOTES } from '../../common/constants/routingConstants'
import { getEpisodeDropdownText } from '../../common/utils/utils'
import { getICD10s } from '../../data/healthPlanAutoActions'
import {
  getClientDataId,
  getClientEpisodeProgramConfigId,
  getClientSelectedEpisode,
} from '../../data/lyraTherapy/clientSelectors'
import { getContentsByProgramId } from '../data/ltContentsAutoActions'
import { getAllClientEpisodes, setSelectedEpisode } from '../episodes/data/episodesAutoActions'

export function ClientNotesRoutes({
  clientId,
  episode,
  selectedNotesEpisodeProgramId,
  actions: { getICD10s, getAllClientEpisodes, setSelectedEpisode, getContentsByProgramId },
}: any) {
  const [selectedEpisode, setSelectedNotesEpisode] = useState(episode)
  const { pathname } = useLocation()

  // @ts-expect-error TS(2548): Type 'unknown[] | undefined' is not an array type ... Remove this comment to see the full error message
  const [, [episodes = []]] = useFetcher([
    [getAllClientEpisodes, { clientId }],
    [getICD10s],
    [setSelectedEpisode, { episode }],
    [getContentsByProgramId, { programId: episode?.program_id }, episode?.program_id !== selectedNotesEpisodeProgramId],
  ])

  const getDropdownItems = (episodes: any) => {
    const dropdownItems: any = []
    episodes.forEach((ep: any) =>
      dropdownItems.push({
        id: ep.id,
        text: getEpisodeDropdownText(ep),
        selectHandler: () => {
          setSelectedNotesEpisode((previousEpisode: any) => {
            if (previousEpisode.program_id !== ep.program_id) {
              getContentsByProgramId({ programId: ep.program_id })
            }
            return ep
          })
          setSelectedEpisode({ episode: ep })
        },
      }),
    )

    return dropdownItems
  }

  return (
    <div>
      {pathname === CLIENT_NOTES.route && episodes.length > 1 && (
        <div styleName='dropdown-container'>
          <DropdownButton
            styleName='dropdown-button'
            styling='inline'
            data-test-id='ClientNotes-select-episode'
            id='selectEpisode'
            dropdownItems={getDropdownItems(episodes)}
          >
            <span styleName='episode-select'>{getEpisodeDropdownText(selectedEpisode)}</span>
          </DropdownButton>
        </div>
      )}
      <Outlet context={{ episode: selectedEpisode }} />
    </div>
  )
}

ClientNotesRoutes.propTypes = {
  clientId: PropTypes.string,
  episode: PropTypes.object,
  actions: PropTypes.object,
  selectedNotesEpisodeProgramId: PropTypes.string,
}

const mapStateToProps = ($$state: any) => {
  return {
    clientId: getClientDataId($$state),
    episode: getClientSelectedEpisode($$state),
    selectedNotesEpisodeProgramId: getClientEpisodeProgramConfigId($$state),
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    { getICD10s, getAllClientEpisodes, setSelectedEpisode, getContentsByProgramId },
    dispatch,
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(CSSModules(toJS(ClientNotesRoutes), styles))
