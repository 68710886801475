import React from 'react'
import DetectRTC from 'detectrtc'
import classNames from 'classnames'
import { useIntl } from 'react-intl'

import { videoViewStyle } from '@lyrahealth-inc/shared-app-logic'

import NetworkIssueIcon from '../../atoms/icons/NetworkIssueIcon'
import Tooltip from '../../atoms/tooltip/Tooltip'
import MaximizeIcon from '../../atoms/icons/MaximizeIcon'
import MicrophoneIcon from '../../atoms/icons/MicrophoneIcon'
import ProfileIcon from '../../atoms/icons/ProfileIcon'
import styles from './videoSession.module.scss'

export const VideoPreviews: React.FC<VideoPreviewsProps> = ({
  type,
  providerSharingScreen,
  $$currentSession,
  showVideoWhileScreenSharing,
  inSession,
  viewStyle,
  initializingTracks,
  setViewStyle,
  participantNetworkQuality,
  openingSession,
  participantStatusMessage,
  isUserPreviewMuted,
  isUserPreviewVideoOff,
  isParticipantPreviewMuted,
  isParticipantPreviewVideoOff,
}) => {
  const intl = useIntl()
  const isProvider = type === 'provider'
  const showSharingScreenBanner = isProvider && providerSharingScreen
  const clientsName = $$currentSession.getIn(['appointment', 'userInfo', 'firstName'], 'client')

  const renderClientPending = () => {
    return (
      <div id='participant-status-message' className={styles['client-pending']}>
        Waiting for {clientsName}
      </div>
    )
  }

  const renderClientLeft = () => {
    return (
      <div id='participant-status-message' className={styles['client-left']}>
        {clientsName} has left
      </div>
    )
  }

  const renderClientWaiting = () => {
    return (
      <div id='participant-status-message' className={styles['client-waiting']}>
        <ProfileIcon width={80} fillColor='currentColor' />
        {clientsName} is ready
      </div>
    )
  }

  return (
    <div
      className={classNames(
        styles['video-previews-container'],
        styles.container,
        { [styles.invisible]: showSharingScreenBanner && !showVideoWhileScreenSharing },
        { [styles['screen-sharing']]: showSharingScreenBanner },
      )}
    >
      <div
        id='user-preview'
        data-test-id='VideoPreviews-userPreview'
        className={classNames(
          { [styles.mobile]: DetectRTC.isMobileDevice },
          { [styles.hidden]: !inSession && viewStyle === videoViewStyle.FULLSCREEN },
          { [styles.loading]: initializingTracks },
          { [styles['video-off']]: isUserPreviewVideoOff },
        )}
      >
        {isUserPreviewMuted && (
          <MicrophoneIcon
            data-test-id='VideoPreviews-userMuteIcon'
            aria-label={intl.formatMessage({
              defaultMessage: 'You are muted',
              description: 'Label that indicates you are muted',
            })}
            className={styles['muted-icon']}
            fillColor='currentColor'
            isMuted
          />
        )}
        {participantNetworkQuality !== null && participantNetworkQuality < 2 ? (
          <Tooltip content='Network quality issue detected'>
            <NetworkIssueIcon
              data-test-id='VideoPreviews-userNetworkIssueIcon'
              fillColor='currentColor'
              className={styles['networkissue-icon']}
            />
          </Tooltip>
        ) : (
          []
        )}
        {isProvider && !inSession ? (
          <button
            data-test-id='VideoPreviews-userMaximizeBtn'
            className={styles['maximize-button']}
            onClick={() => setViewStyle(videoViewStyle.FULLSCREEN)}
          >
            <MaximizeIcon width={20} fillColor='currentColor' />
          </button>
        ) : (
          []
        )}
      </div>

      <div
        id='participant-preview'
        data-test-id='VideoPreviews-participantPreview'
        className={classNames(
          { [styles.loading]: openingSession },
          { [styles['video-off']]: inSession && isParticipantPreviewVideoOff },
        )}
        data-show={`${
          (isProvider && !(inSession && viewStyle === videoViewStyle.FULLSCREEN)) ||
          (providerSharingScreen && type === 'client')
        }`}
        data-hasmessage={`${
          participantStatusMessage !== null && viewStyle === videoViewStyle.PREVIEW && type === 'provider'
        }`}
      >
        {inSession && isParticipantPreviewMuted && (
          <MicrophoneIcon
            aria-label={intl.formatMessage({
              defaultMessage: 'They are muted',
              description: 'Label that indicates that the other person on screen is muted',
            })}
            data-test-id='VideoPreviews-participantMuteIcon'
            className={styles['muted-icon']}
            fillColor='currentColor'
            isMuted
          />
        )}
        {isProvider && participantStatusMessage ? (
          <div id='participant-status-container' data-test-id='VideoPreviews-participantStatusContainer'>
            {participantStatusMessage === 'pending' ? renderClientPending() : []}
            {participantStatusMessage === 'left' ? renderClientLeft() : []}
            {participantStatusMessage === 'waiting' ? renderClientWaiting() : []}
          </div>
        ) : (
          []
        )}
        {isProvider && inSession && !showSharingScreenBanner ? (
          <button
            data-test-id='VideoPreviews-participantMaximizeBtn'
            className={styles['maximize-button']}
            onClick={() => setViewStyle(videoViewStyle.FULLSCREEN)}
          >
            <MaximizeIcon width={20} fillColor='currentColor' />
          </button>
        ) : (
          []
        )}
      </div>
    </div>
  )
}

export default VideoPreviews

type VideoPreviewsProps = {
  inSession: boolean
  $$currentSession: any
  providerSharingScreen: boolean
  showVideoWhileScreenSharing: boolean
  viewStyle: string
  initializingTracks: boolean
  participantNetworkQuality: null | number
  openingSession: boolean
  participantStatusMessage: string | null
  type: string
  setViewStyle: (style: string) => void
  isUserPreviewMuted: boolean
  isUserPreviewVideoOff: boolean
  isParticipantPreviewMuted: boolean
  isParticipantPreviewVideoOff: boolean
}
