import React from 'react'
import { FormattedDateTimeRange, FormattedTime } from 'react-intl'
import dateUtils from '../../utils/dateUtils'
import PrimaryButton from '../../atoms/buttons/primaryButton/PrimaryButton'
import styles from './videoSession.module.scss'
import VideoIcon from '../../atoms/icons/VideoIcon'
import useIsMobile from '../../hooks/useIsMobile'

export const NextSessionReminder: React.FC<NextSessionReminderProps> = ({
  $$nextSession,
  timeZone,
  openSession,
  type,
}) => {
  const [isMobile] = useIsMobile()

  let duration = $$nextSession.get('startsIn')
  if (duration !== 'now') {
    duration = `in ${duration.format('mm:ss')}`
  }

  const isProvider = type === 'provider'
  const providerFirstName = $$nextSession.getIn(['appointment', 'provider', 'first_name'], 'Provider')
  const providerLastName = $$nextSession.getIn(['appointment', 'provider', 'last_name'], '')

  const sessionName = isProvider ? 'Next Session' : `${providerFirstName} ${providerLastName}`

  const startTimeMoment = dateUtils.getAppointmentDateTimeObject($$nextSession.get('appointment')).tz(timeZone)
  const endTimeMoment = startTimeMoment
    .clone()
    .add($$nextSession.getIn(['appointment', 'appointmentDuration']), 'minutes')

  const renderSessionTime = () => {
    return isMobile ? (
      <FormattedTime value={startTimeMoment.toDate()} timeZoneName='short' />
    ) : (
      <>
        <FormattedDateTimeRange
          from={startTimeMoment.toDate()}
          to={endTimeMoment.toDate()}
          hour='numeric'
          minute='numeric'
          timeZoneName='short'
        />
      </>
    )
  }

  return (
    <div className={styles.inner}>
      <div className={styles['session-info']}>
        <span className={styles['name-session']}>{sessionName}</span>
        <div className={styles['session-schedule']}>
          <VideoIcon width={28} isFilled />
          <span className={styles['session-time']}>
            <span className={styles['session-time']}>{renderSessionTime()}</span>
          </span>
        </div>
      </div>
      <PrimaryButton
        data-test-id='VideoSession-join-session'
        customClass={styles['join-open-button']}
        onClick={openSession}
      >
        Join<span className='hidden-xs'>&nbsp;(starts {duration})</span>
      </PrimaryButton>
    </div>
  )
}

export default NextSessionReminder

type NextSessionReminderProps = {
  $$nextSession: any
  timeZone: string
  openSession: () => void
  type: string
}
