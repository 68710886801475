import React from 'react'

import { MIXPANEL_PROPERTY } from '@lyrahealth-inc/shared-app-logic'
import { AppContext } from '@lyrahealth-inc/ui-core-crossplatform'

import { track } from '../../mixpanel/mixpanelTracking'

/*
ApplicationContext is used to add context to ui-core-crossplatform
*/
function ApplicationContext({ children }: { children: React.ReactNode }) {
  const trackEvent = (event: string, eventProps?: { [key in MIXPANEL_PROPERTY]?: any }) => {
    track({
      event,
      properties: eventProps,
    })
  }
  return <AppContext.Provider value={{ trackEvent }}>{children}</AppContext.Provider>
}

export default ApplicationContext
