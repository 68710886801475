import React, { FunctionComponent } from 'react'
import { Animated } from 'react-native'

import styled from 'styled-components/native'

import { MuteBadge, ZoomNetworkIndicator } from '../../atoms'
import { useZoom, ZOOM_MINI_VIEW_HEIGHT, ZOOM_MINI_VIEW_WIDTH } from '../../hooks/useZoom'
import { ZoomParticipantView } from '../zoomParticipantView/ZoomParticipantView'
import { ZoomSelfView } from '../zoomSelfView/ZoomSelfView'
import { ZoomVideoControls } from '../zoomVideoControls/ZoomVideoControls'

const VideoContainer = styled.View<{ landscape: boolean; show?: boolean }>(({ landscape, show, theme }) => ({
  width: `${landscape ? ZOOM_MINI_VIEW_HEIGHT : ZOOM_MINI_VIEW_WIDTH}px`,
  height: `${landscape ? ZOOM_MINI_VIEW_WIDTH : ZOOM_MINI_VIEW_HEIGHT}px`,
  borderRadius: '4px',
  overflow: 'hidden',
  alignSelf: 'flex-end',
  justifyContent: 'center',
  backgroundColor: theme.colors.components.zoomVideoSession.background,
  display: show ? undefined : 'none',
}))

const VideosContainer = styled.View<{ landscape: boolean }>(({ theme, landscape }) => ({
  position: 'absolute',
  flexDirection: landscape ? 'column' : 'row',
  justifyContent: 'flex-end',
  gap: theme.spacing['4px'],
  bottom: '120px',
  left: theme.spacing['24px'],
  right: theme.spacing['24px'],
  zIndex: 200,
}))

const ControlContainer = styled(Animated.View)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing['32px'],
  left: theme.spacing['24px'],
  right: theme.spacing['24px'],
  zIndex: 200,
}))

export type ZoomMiniViewProps = {
  landscape: boolean
  onOpenMessagesPressed?: () => void
  unreadMessageCount?: number
  elementOpacity: Animated.Value
  controlsHidden: boolean
  participantFirstName?: string
  messenger?: React.ReactElement
  isFocused?: boolean
}

const MuteIcon = styled(MuteBadge)<{ landscape: boolean }>(({ theme, landscape }) => ({
  bottom: theme.spacing['8px'],
  alignSelf: 'center',
  left: landscape ? theme.spacing['8px'] : undefined,
}))

const NetworkIndicator = styled(ZoomNetworkIndicator)(({ theme }) => ({
  right: theme.spacing['4px'],
}))

export const ZoomMiniView: FunctionComponent<ZoomMiniViewProps> = ({
  landscape,
  onOpenMessagesPressed,
  unreadMessageCount,
  elementOpacity,
  controlsHidden,
  participantFirstName,
  messenger,
  isFocused,
}) => {
  const { remoteParticipantScreenSharing, muted, remoteParticipant, networkQuality } = useZoom()
  return (
    <>
      <VideosContainer pointerEvents='none' landscape={landscape}>
        <VideoContainer landscape={landscape} show={remoteParticipantScreenSharing}>
          <ZoomParticipantView
            show={remoteParticipantScreenSharing}
            landscape={landscape}
            participantFirstName={participantFirstName}
          />
          {remoteParticipant?.isMuted && <MuteIcon landscape={landscape} />}
        </VideoContainer>
        <VideoContainer landscape={landscape} show={true}>
          <ZoomSelfView landscape={landscape} isFocused={isFocused} />
          {muted && <MuteIcon landscape={landscape} />}
          <NetworkIndicator networkQuality={networkQuality} />
        </VideoContainer>
      </VideosContainer>

      <ControlContainer pointerEvents={controlsHidden ? 'none' : 'auto'} style={{ opacity: elementOpacity }}>
        <ZoomVideoControls
          displayVideos={false}
          buttonSize={64}
          landscape={landscape}
          onOpenMessagesPressed={onOpenMessagesPressed}
          unreadMessageCount={unreadMessageCount}
          messenger={messenger}
        />
      </ControlContainer>
    </>
  )
}
