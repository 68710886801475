import { axiosAuthInstance } from '../axiosInstance'

/*
  NOTE - this file is maintained for backward compatibility.
    Please use Autothunk for REST actions
*/

export function getProvidersData() {
  return axiosAuthInstance.get('/v1/providers')
}

export function getProvidersDataLite() {
  return axiosAuthInstance.get('/v1/providers-lite')
}

export function submitRegisterProviderForm(data: any) {
  return axiosAuthInstance.post('/v1/providers', data)
}

export function updateProviderRatesData(id: any, data: any) {
  return axiosAuthInstance.put(`/v1/providers/${id}/rates`, data)
}

export function getProviderData(id: any) {
  return axiosAuthInstance.get(`/v1/providers/${id}`)
}

export function submitClientEmailForm(id: any, data: any) {
  return axiosAuthInstance.put(`/v1/clients/${id}`, data)
}

export function submitClientEmailForHealthPlanRegistration(id: any, data: any) {
  return axiosAuthInstance.post(`/v1/clients/${id}/healthplan/email-registration`, data)
}

export function downloadProvidersData() {
  return axiosAuthInstance.get(`/v1/providers/csv`)
}

export function updateProviderCapacityData(id: any, data: any) {
  return axiosAuthInstance.put(`/v1/providers/${id}/capacity`, data)
}
