import React, { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { LayoutChangeEvent, ListRenderItem } from 'react-native'

import styled, { useTheme } from 'styled-components/native'

import { AvailabilityDate } from '@lyrahealth-inc/shared-app-logic'

import { ChevronIconDirection } from '../../atoms'
import { Headline, Size as HeadlineSize } from '../../atoms/headline/Headline'
import { ChevronV2Icon } from '../../atoms/icons/ChevronV2Icon'
import { Subhead, Size as SubheadSize } from '../../atoms/subhead/Subhead'
import TertiaryIconButton from '../../atoms/tertiaryIconButton/TertiaryIconButton'
import { TimeSlotList } from '../../organisms'
import { tID } from '../../utils/utils'
import { useDateBlock } from '../dateBlockSelector/useDateBlock'

export interface ExpandedDateBlockSelectorProps {
  dates: Array<AvailabilityDate>
  onDatesChanged: (dates: Array<string>) => void
  onSelected: (dateTime: string) => void
  responsive?: boolean
  timeZone: string
}

const FlatListContainer = styled.FlatList({
  alignSelf: 'center',
})
const DateBlock = styled.View({
  alignItems: 'center',
})
const ExpandedDateBlockSelectorContainer = styled.View({
  flexDirection: 'row',
  justifyContent: 'space-around',
  alignSelf: 'stretch',
})

const ShowableIconButton = styled(TertiaryIconButton)<{ hidden: boolean }>(({ hidden }) => ({
  opacity: hidden ? 0 : 1,
  margin: '4px',
}))

export const ExpandedDateBlockSelector: FunctionComponent<ExpandedDateBlockSelectorProps> = ({
  dates,
  onDatesChanged,
  onSelected,
  responsive = false,
  timeZone,
}: ExpandedDateBlockSelectorProps) => {
  const { colors } = useTheme()
  const [currentPageIndex, setCurrentPageIndex] = useState(0)
  const [width, setWidth] = useState(0)
  const numCols = useMemo(() => {
    if (!responsive || width === 0) {
      return 5
    }

    if (width > 900) {
      return 5
    } else if (width > 700) {
      return 4
    } else if (width > 600) {
      return 3
    } else if (width > 400) {
      return 2
    } else {
      return 1
    }
  }, [width, responsive])

  useEffect(() => {
    if (!responsive) {
      return
    }
    setCurrentPageIndex(0)
  }, [numCols, responsive])

  const { data, isAtStart, isAtEnd } = useDateBlock({
    currentPageIndex,
    dates: dates.map((d) => d.date),
    numItems: numCols,
  })

  const { formatMessage, formatDate } = useIntl()

  useEffect(() => {
    onDatesChanged(data)
  }, [onDatesChanged, data])

  const onPrevPressed = () => {
    if (!isAtStart) {
      setCurrentPageIndex((prevState) => prevState - 1)
    }
  }

  const onNextPressed = () => {
    if (!isAtEnd) {
      setCurrentPageIndex((prevState) => prevState + 1)
    }
  }

  const renderItem: ListRenderItem<string> = ({ item: date }) => {
    const title = formatDate(date, { weekday: 'short', timeZone: 'UTC' })
    const subtitle = formatDate(date, { day: '2-digit', timeZone: 'UTC' })
    const dateObj = dates.find((d) => d.date === date)
    if (dateObj == null) {
      return null
    }
    return (
      <DateBlock testID={tID('DateBlock')}>
        <Headline testID={tID('DateBlockDay')} size={HeadlineSize.EYEBROW} text={title} color={colors.textSecondary} />
        <Subhead
          testID={tID('DateBlockDate')}
          size={SubheadSize.MEDIUM}
          text={subtitle}
          color={colors.textSecondary}
          style={{ paddingBottom: 32 }}
        />
        <TimeSlotList
          datetimes={dateObj.times}
          onPress={onSelected}
          shouldShowSelected={false}
          shouldShowEmptyTimeSlots
          timeZone={timeZone}
        />
      </DateBlock>
    )
  }
  return (
    <ExpandedDateBlockSelectorContainer
      onLayout={(event: LayoutChangeEvent) => setWidth(event.nativeEvent.layout.width)}
    >
      <ShowableIconButton
        testID={tID('DateBlockSelector-prev')}
        hidden={isAtStart}
        focusable={isAtStart ? false : true}
        leftIcon={<ChevronV2Icon direction={ChevronIconDirection.LEFT} />}
        onPress={onPrevPressed}
        iconColor={colors.iconDefault}
        accessibilityLabel={formatMessage({
          defaultMessage: 'Previous set of times and dates',
          description:
            'Accessibility label: Button to go to the previous set of times and dates when selecting their preferred schedule with the provider',
        })}
      />
      <FlatListContainer
        contentContainerStyle={{ justifyContent: 'space-around', flexGrow: 1 }}
        data={data}
        renderItem={renderItem}
        horizontal
      />
      <ShowableIconButton
        testID={tID('DateBlockSelector-next')}
        hidden={isAtEnd}
        focusable={isAtEnd ? false : true}
        leftIcon={<ChevronV2Icon direction={ChevronIconDirection.RIGHT} />}
        onPress={onNextPressed}
        iconColor={colors.iconDefault}
        accessibilityLabel={formatMessage({
          defaultMessage: 'Next set of times and dates',
          description:
            'Accessibility label: Button to go to the next set of times and dates when selecting their preferred schedule with the provider',
        })}
      />
    </ExpandedDateBlockSelectorContainer>
  )
}
