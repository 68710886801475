import React from 'react'
import { Outlet } from 'react-router-dom'
import { ContentLayout } from '@lyrahealth-inc/ui-core'

export default () => {
  return (
    // @ts-expect-error TS(2322): Type '{ children: Element; style: { overflow: stri... Remove this comment to see the full error message
    <ContentLayout style={{ overflow: 'visible' }}>
      <Outlet />
    </ContentLayout>
  )
}
