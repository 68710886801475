import React, { FunctionComponent } from 'react'
import Svg, { G, Mask, Path } from 'react-native-svg'

type Props = {
  size?: number | string
}

export const MedsPairingSpotIllustration: FunctionComponent<Props> = ({ size = 80 }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 80 80' fill='none'>
      <Path
        d='M40.2812 0.298828C34.7592 0.298828 29.4015 1.24578 24.3569 3.11281C12.3469 7.56459 3.9034 17.4341 1.1906 30.1902C-0.602123 38.5073 -0.372894 46.1999 1.89568 53.6965C4.79186 63.2703 11.9011 71.3439 21.4007 75.8463C26.1164 78.0864 31.5341 79.3653 37.5004 79.6467C38.2671 79.6815 39.0465 79.7005 39.8212 79.7005C44.6081 79.7005 49.4235 79.0444 54.1345 77.7512C54.4965 77.6611 54.8412 77.5678 55.2048 77.4604C59.4384 76.2209 63.2483 74.2606 66.5081 71.6411C70.0477 68.8129 72.9613 65.2274 75.1587 60.9907C75.5919 60.156 76.0061 59.277 76.463 58.2257C78.9766 52.3891 80.1907 45.8553 79.9757 39.323C79.8745 36.4347 79.4445 33.3647 78.7015 30.1902C77.4779 25.0397 75.4828 20.1959 72.9313 16.1741C72.2325 15.0944 71.5907 14.1869 70.9551 13.3712C66.369 7.4555 59.2234 3.30409 50.2977 1.37225C47.0189 0.660851 43.6485 0.300409 40.2828 0.300409L40.2812 0.298828Z'
        fill='white'
      />
      <Path
        d='M76.5437 39.4308C76.4457 36.6627 76.022 33.795 75.3549 30.9446C74.2641 26.3522 72.4713 21.8245 70.0131 17.9482C69.4424 17.0692 68.8574 16.2218 68.2219 15.4077C63.6121 9.46354 56.6261 6.15632 49.4758 4.60864C41.5113 2.88231 33.0424 3.38661 25.3862 6.22114C14.3105 10.3251 6.76968 19.3156 4.30982 30.8798C3.36445 35.2605 3.0878 40.2118 3.51148 44.9687H3.47828C3.72332 47.672 4.21181 50.3105 4.94376 52.7372C7.66447 61.7277 14.2757 68.8622 22.6971 72.8524C27.258 75.0182 32.3548 76.0932 37.5353 76.3382C42.8281 76.5833 48.2031 75.9145 53.1719 74.5471C53.4975 74.4665 53.8074 74.3842 54.1331 74.2862C58.0252 73.1464 61.413 71.3869 64.3124 69.0582C67.4726 66.5336 70.0779 63.3576 72.0983 59.4654C72.5377 58.6181 72.9282 57.7723 73.3029 56.9075C75.6805 51.3855 76.7397 45.3924 76.5279 39.4482L76.5437 39.4324V39.4308Z'
        fill='#FBFAF9'
      />
      <Path
        d='M49.9848 61.886H29.4428C28.0374 61.886 26.8976 60.7462 26.8976 59.3408L25.9316 26.4141L53.9228 28.1025L52.5285 59.3945C52.4984 60.7778 51.3681 61.8844 49.9848 61.8844V61.886Z'
        fill='#FFC03C'
      />
      <Path
        d='M34.6109 49.1744C33.1861 47.9874 31.0688 48.1801 29.8818 49.6048C28.6947 51.0296 28.8874 53.1469 30.3122 54.3339L35.5688 58.7136C36.9936 59.9007 39.1109 59.708 40.2979 58.2832C41.485 56.8585 41.2923 54.7412 39.8676 53.5541L34.6109 49.1744Z'
        fill='#00A4B3'
      />
      <Path
        d='M40.2982 58.2824C39.111 59.7068 36.9942 59.8997 35.5682 58.7124L33.0625 56.6415L37.3609 51.4814L39.8666 53.5524C41.291 54.7396 41.4839 56.8565 40.2966 58.2824H40.2982Z'
        fill='#008295'
      />
      <Path
        d='M44.1262 48.5766L42.544 55.2332C42.1152 57.0374 43.2302 58.8476 45.0344 59.2765C46.8386 59.7053 48.6488 58.5903 49.0776 56.7861L50.6598 50.1295C51.0886 48.3253 49.9736 46.515 48.1694 46.0862C46.3652 45.6574 44.555 46.7724 44.1262 48.5766Z'
        fill='#00A4B3'
      />
      <Path
        d='M48.1724 46.0892C49.9762 46.5176 51.0923 48.3293 50.6623 50.1331L49.9225 53.2981L43.3887 51.744L44.1285 48.5791C44.5569 46.7753 46.3686 45.6592 48.1724 46.0892Z'
        fill='#008295'
      />
      <Mask id='mask0_5713_45360' mask-type='luminance' maskUnits='userSpaceOnUse' x='25' y='26' width='29' height='36'>
        <Path
          d='M49.9848 61.886H29.4428C28.0374 61.886 26.8976 60.7462 26.8976 59.3408L25.9316 26.4141L53.9228 28.1025L52.5285 59.3945C52.4984 60.7778 51.3681 61.8844 49.9848 61.8844V61.886Z'
          fill='white'
        />
      </Mask>
      <G mask='url(#mask0_5713_45360)'>
        <Path
          d='M53.2311 46.9642L33.5823 46.7523C32.7381 46.7381 32.0583 46.0552 32.0488 45.211L32.0646 34.276C32.0552 33.4081 32.7555 32.6982 33.6234 32.6982L54.2887 32.8216L53.2311 46.9642Z'
          fill='white'
        />
        <Path d='M53.5858 36.0986H36.4395V37.9309H53.5858V36.0986Z' fill='#DCCDBF' />
        <Path d='M53.5858 40.127H36.4395V41.8011H53.5858V40.127Z' fill='#DCCDBF' />
      </G>
      <Path
        d='M54.2928 23.9532L25.7135 23.7666C25.2488 23.7635 24.863 24.1223 24.8314 24.5855L24.5468 28.8571C24.5136 29.363 24.9152 29.793 25.4227 29.793H54.4556C54.9552 29.793 55.3536 29.3772 55.333 28.8792L55.1639 24.7942C55.1449 24.3263 54.7608 23.9563 54.2928 23.9532Z'
        fill='#6ECBD3'
      />
      <Path
        d='M52.2972 20.6583L28.1286 19.7872C27.3698 19.7603 26.7043 20.2409 26.5699 20.916L25.4648 26.4601H54.3888L53.7469 21.8582C53.6536 21.1926 53.0403 20.6851 52.2972 20.6583Z'
        fill='#6ECBD3'
      />
    </Svg>
  )
}
