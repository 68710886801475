import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import styles from './dateInputField.module.scss'
import { get } from 'lodash-es'
import BaseInput from '../baseInput/BaseInput'
import moment, { Moment } from 'moment'
import { FieldInputProps } from 'react-final-form'
import MaskedInput from 'react-maskedinput'

const DateInputField: React.FC<DateInputFieldProps> = ({
  placeholder,
  readOnly,
  dropdownMode,
  maxDate,
  minDate,
  label,
  className,
  disabled,
  appendFormat = true,
  meta: { touched, error, submitError, warning },
  showMonthDropdown = false,
  showYearDropdown = false,
  isClearable = true,
  shouldForceTouch = false,
  input,
  mask,
  initialValue = null,
  ...props
}) => {
  const [value, setValue] = useState<Date | null | undefined>(initialValue)

  const [showError, setShowError] = useState<boolean>(false)

  const handleChange = (date: Date | null) => {
    const formattedDate = date ? moment(date).format('L') : date
    setValue(date)
    input.onChange(formattedDate)

    if (shouldForceTouch && !showError) {
      setShowError(true)
    }
  }

  // Modify selected days label to indicate that it is selected
  const calendarOpen = () => {
    window.requestAnimationFrame(() => {
      const monthSelect = document.querySelector('.react-datepicker__month-select')
      const yearSelect = document.querySelector('.react-datepicker__year-select')
      monthSelect && monthSelect.setAttribute('aria-label', 'Month')
      yearSelect && yearSelect.setAttribute('aria-label', 'Year')
      const selectedDateElement = document.querySelector('[tabindex="0"].react-datepicker__day')
      if (selectedDateElement) {
        const newSelectedDateLabel = selectedDateElement.getAttribute('aria-label')?.replace('Choose', 'Chosen')
        selectedDateElement.setAttribute('aria-label', `${newSelectedDateLabel}`)
      }
    })
  }

  const inputValue: Moment = get(input, 'value')
  const fieldInfoId = `${input.name}_info`
  const formatedLabel = appendFormat && !label.endsWith('(MM/DD/YYYY)') ? `${label} (MM/DD/YYYY)` : label
  return (
    <BaseInput
      className={className}
      label={formatedLabel}
      touched={touched || showError}
      error={error || submitError}
      warning={warning}
      readOnly={readOnly}
      disabled={disabled}
      htmlFor={input.name}
      fieldInfoId={fieldInfoId}
    >
      {readOnly ? (
        <p style={{ margin: 0 }}>{input.value}</p>
      ) : (
        <DatePicker
          className={touched && error ? styles['date-invalid'] : styles['date-input']}
          dropdownMode={dropdownMode}
          ariaLabelClose='Clear'
          showMonthDropdown={showMonthDropdown}
          showYearDropdown={showYearDropdown}
          onChange={handleChange}
          onBlur={input.onBlur}
          onFocus={input.onFocus}
          onCalendarClose={input.onBlur}
          placeholderText={placeholder}
          dateFormat='MM/dd/yyyy'
          isClearable={readOnly ? false : isClearable}
          maxDate={maxDate instanceof moment ? (maxDate as Moment).toDate() : (maxDate as Date)}
          minDate={minDate instanceof moment ? (minDate as Moment).toDate() : (minDate as Date)}
          disabled={readOnly ? true : disabled}
          selected={inputValue && inputValue instanceof moment ? moment(inputValue).toDate() : value}
          name={input.name}
          id={input.name}
          customInput={
            mask ? (
              <MaskedInput type='tel' mask={mask} aria-describedby={fieldInfoId} />
            ) : (
              <input type='text' aria-describedby={fieldInfoId} />
            )
          }
          onCalendarOpen={calendarOpen}
          {...props}
        />
      )}
    </BaseInput>
  )
}

type DateInputFieldProps = {
  meta: {
    touched: boolean
    error: string
    submitError: string
    warning: string
  }
  input: FieldInputProps<any, HTMLElement>
  label: string
  className: string
  showMonthDropdown?: boolean
  showYearDropdown?: boolean
  isClearable?: boolean
  dropdownMode: 'scroll' | 'select'
  placeholder: string
  maxDate: Moment | Date | null
  minDate: Moment | Date | null
  readOnly: boolean
  disabled: boolean
  appendFormat: boolean
  shouldForceTouch: boolean
  mask?: string
  initialValue?: Date
}

export default DateInputField
