import { axiosAuthInstance } from '../axiosInstance'
import { OutcomesResponse, ProviderAccessField, ProviderAccessFormValues } from '@lyrahealth-inc/shared-app-logic'
import { format } from 'date-fns'

export function getClientsData() {
  return axiosAuthInstance.get('/v1/clients')
}

export function getClientData(data: any, cancelToken: any) {
  return axiosAuthInstance.post(`/v1/clients/search`, data, {
    cancelToken: cancelToken,
  })
}

export function submitProviderOutcomesConsentData(data: any) {
  return axiosAuthInstance.put('/v1/user', data)
}

export function getClientsToReconcileData(data: any) {
  return axiosAuthInstance.get(`/v1/ira/reconciliation?limit=${data.limit}&start=${data.start}`)
}

export function getReconcilerPossibleMatchesData(data: any) {
  return axiosAuthInstance.get(
    `/v1/ira/reconciliation/matches?first_name=${data.first_name}&last_name=${data.last_name}&full_name=${data.full_name}&dob=${data.dob}`,
  )
}

export function submitReconciledClient(clientID: any, data: any) {
  return axiosAuthInstance.put(`/v1/ira/identities/${clientID}`, data)
}

export function getClientSessionData(data: any) {
  return axiosAuthInstance.post(`/v1/clients/session-count`, data)
}

export function getClientShareOutcomesData(values: ProviderAccessFormValues) {
  return axiosAuthInstance.get<OutcomesResponse>('v1/share-outcomes', {
    params: {
      access_code: values[ProviderAccessField.ACCESS_CODE],
      client_dob: format(new Date(values[ProviderAccessField.CLIENT_DATE_OF_BIRTH]), 'yyyy-MM-dd'),
      client_surname: values[ProviderAccessField.CLIENT_LAST_NAME],
      provider_surname: values[ProviderAccessField.PROVIDER_LAST_NAME],
    },
  })
}
