import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { View } from 'react-native'

import { FlashList } from '@shopify/flash-list'
import styled, { useTheme } from 'styled-components/native'

import { CountryValues, useLocalizedCountryListFilter } from '@lyrahealth-inc/shared-app-logic'

import { Flex1View } from '../../../../src/templates/content/CommonViews'
import { InputField } from '../../../atoms/formElements/InputField'
import { IS_WEB } from '../../../constants'
import { getFontStyles } from '../../../styles/typeStyles'
import { ThemeType, tID } from '../../../utils'

const SearchCountryBox = styled.View(({ theme }) => ({
  paddingHorizontal: theme.spacing['24px'],
}))

const CountryListBox = styled.ScrollView(({ theme }) => ({
  paddingHorizontal: theme.spacing['24px'],
}))

const InputFieldBox = styled(InputField)<{ theme: ThemeType }>(({ theme: { colors } }) => ({
  borderColor: colors.borderDefault,
}))

export const SearchCountryModal = ({
  countryList,
  customCountryButtonDisplay,
}: {
  countryList: CountryValues[]
  customCountryButtonDisplay: (country: CountryValues, key?: number | string) => React.ReactNode
}) => {
  const { colors } = useTheme()
  const [search, setSearch] = useState('')
  const { formatMessage } = useIntl()
  const filteredLocalizedCountryList = useLocalizedCountryListFilter(countryList, search)

  const labelId = 'SearchCountryModal-text-input-search-field'

  const countryCodeList = IS_WEB ? (
    <CountryListBox>
      {/* @ts-ignore next-line */}
      <View accessibilityRole={IS_WEB ? 'listbox' : undefined}>
        {customCountryButtonDisplay && filteredLocalizedCountryList.map(customCountryButtonDisplay)}
      </View>
    </CountryListBox>
  ) : (
    <FlashList
      accessibilityRole={IS_WEB ? 'list' : undefined}
      data={filteredLocalizedCountryList}
      renderItem={({ item }) => customCountryButtonDisplay(item as CountryValues) as JSX.Element}
      keyExtractor={(item: CountryValues) => item.countryIsoCode}
      estimatedItemSize={200}
      contentContainerStyle={{ paddingHorizontal: 24 }}
    />
  )

  return (
    <Flex1View defaultFlex testID={tID('CountrySelectionModal')}>
      <SearchCountryBox testID={tID('CountrySelectionInput-id')}>
        <InputFieldBox
          name={labelId}
          accessibilityLabelledBy={labelId}
          label={formatMessage({
            defaultMessage: 'Search for your country or territory',
            description:
              'Label of a search field that filters a list of countries or territories for ones that match the search query',
          })}
          value={search}
          onChange={(e) => setSearch(e.nativeEvent.text)}
          typeStyle={getFontStyles(colors).body.small}
          accessibilityLabel={formatMessage({
            defaultMessage: `Search for your country or territory, text field`,
            description: 'Text field for searching for your country or territory',
          })}
          baseInputStyle={{ marginBottom: 24 }}
        />
      </SearchCountryBox>
      {countryCodeList}
    </Flex1View>
  )
}

export default SearchCountryModal
