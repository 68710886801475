import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

type Props = {
  size?: number | string
}

export const CouplesAndFamilyTherapySpotIllustrationWhiteBorder: FunctionComponent<Props> = ({ size = 124 }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 513 513' fill='none'>
      <Path
        d='M258.3 2.8418C222.959 2.8418 188.67 8.90228 156.384 20.8513C79.5203 49.3426 25.4818 112.507 8.11986 194.147C-3.35359 247.376 -1.88652 296.609 12.6323 344.587C31.1679 405.859 76.6671 457.53 137.464 486.345C167.645 500.682 202.319 508.867 240.503 510.668C245.41 510.891 250.398 511.012 255.355 511.012C285.992 511.012 316.81 506.813 346.961 498.537C349.278 497.96 351.483 497.364 353.811 496.676C380.906 488.743 405.289 476.197 426.152 459.432C448.805 441.332 467.452 418.385 481.516 391.27C484.288 385.927 486.939 380.302 489.863 373.574C505.95 336.219 513.72 294.403 512.344 252.597C511.697 234.112 508.945 214.463 504.19 194.147C496.358 161.183 483.59 130.183 467.26 104.444C462.788 97.5331 458.68 91.7256 454.613 86.5049C425.262 48.6445 379.53 22.0755 322.405 9.7117C301.421 5.15874 279.85 2.85191 258.31 2.85191L258.3 2.8418Z'
        fill='white'
      />
      <Path
        d='M490.38 253.284C489.753 235.568 487.042 217.215 482.772 198.973C475.791 169.581 464.317 140.604 448.584 115.795C444.932 110.17 441.188 104.747 437.121 99.5363C407.618 61.4938 362.908 40.3276 317.146 30.4224C266.173 19.3739 211.972 22.6015 162.973 40.7425C92.0881 67.0079 43.8268 124.547 28.0837 198.558C22.0333 226.594 20.2627 258.283 22.9742 288.727H22.7618C24.33 306.028 27.4564 322.914 32.1408 338.445C49.5534 395.984 91.8655 441.645 145.762 467.182C174.952 481.044 207.571 487.924 240.727 489.492C274.601 491.06 309.001 486.78 340.801 478.028C342.885 477.512 344.868 476.986 346.952 476.359C371.862 469.064 393.544 457.803 412.1 442.9C432.325 426.742 448.999 406.416 461.93 381.506C464.742 376.083 467.241 370.67 469.639 365.135C484.856 329.794 491.635 291.438 490.279 253.396L490.38 253.295V253.284Z'
        fill='#FFE9E3'
      />
      <Path
        d='M242.467 234.441C258.25 219.709 273.7 218.222 287.075 222.35C289.17 203.511 281.733 184.803 265.413 175.859C222.778 152.478 189.612 197.38 189.612 197.38C189.612 197.38 136.686 144.353 101.274 193.292C61.9065 247.695 188.519 333.817 200.721 331.105C208.714 329.335 223.455 316.677 238.612 300.206C227.482 275.478 224.842 250.892 242.467 234.451V234.441Z'
        fill='#FFAF9B'
      />
      <Path
        d='M406.901 248.889C367.958 215.693 328.377 255.436 328.377 255.436C328.377 255.436 311.127 229.767 287.077 222.351C286.308 229.271 284.284 236.212 280.885 242.697C271.486 260.606 254.822 282.591 238.613 300.206C258.484 344.36 305.562 389.009 313.575 388.807C330.502 388.382 384.733 347.972 409.238 317.457C426.286 296.23 426.276 265.412 406.901 248.889Z'
        fill='#F69170'
      />
      <Path
        d='M287.078 222.351C273.712 218.223 258.253 219.721 242.469 234.442C224.844 250.893 227.475 275.469 238.614 300.197C254.823 282.582 271.487 260.596 280.886 242.688C284.285 236.212 286.309 229.272 287.078 222.341V222.351Z'
        fill='#E5744C'
      />
    </Svg>
  )
}
