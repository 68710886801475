import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'

import styled, { useTheme } from 'styled-components/native'

import { BodyTextSize } from '../../styles'
import { ThemeType, tID } from '../../utils'
import { BodyText } from '../bodyText/BodyText'
import { CircleIcon } from '../icons'

export type RecordingBadgeProps = {
  isRecording: boolean
}

const BadgeContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.spacing['4px'],
  alignItems: 'center',
  backgroundColor: theme.colors.components.recordingBadge.background,
  borderRadius: '4px',
  alignSelf: 'flex-start',
  paddingLeft: theme.spacing['8px'],
  paddingRight: theme.spacing['8px'],
  paddingTop: theme.spacing['4px'],
  paddingBottom: theme.spacing['4px'],
}))

export const RecordingBadge: FunctionComponent<RecordingBadgeProps> = ({ isRecording }) => {
  const { colors } = useTheme() as ThemeType
  return (
    <BadgeContainer testID={tID('RecordingBadge')}>
      <CircleIcon
        size={8}
        isFilled
        fillColor={isRecording ? colors.components.recordingBadge.text : colors.iconInactive}
      />
      <BodyText
        size={BodyTextSize.BADGE}
        color={colors.textInactive}
        text={
          isRecording ? (
            <FormattedMessage
              defaultMessage='RECORDING'
              description='Badge that appears when session is being recorded'
            />
          ) : (
            <FormattedMessage
              defaultMessage='NOT RECORDING'
              description='Badge that appears when session is not being recorded'
            />
          )
        }
      />
    </BadgeContainer>
  )
}
