import React, { FunctionComponent } from 'react'
import { View } from 'react-native'

import styled from 'styled-components/native'

import { ThemeType } from '../../utils/themes/ThemeProvider'
import { tID } from '../../utils/utils'

export interface IconBadgeProps {
  text: React.ReactNode
  color: string
  textColor?: string
  frontIcon?: React.ReactNode
  backIcon?: React.ReactNode
  testId?: string
  cornerType?: Corners
  hideText?: boolean
  noPadding?: boolean
}

const Container = styled(View)<{
  theme: ThemeType
  color: string
  cornerType: Corners
  hideText?: boolean
  noPadding?: boolean
}>(({ theme, color, cornerType, hideText, noPadding }) => {
  return {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: noPadding ? '0px' : `0 ${theme.spacing['8px']}`,
    backgroundColor: color,
    minWidth: '39px',
    minHeight: '24px',
    borderRadius: cornerType === Corners.ROUND ? '20px' : cornerType === Corners.CURVED ? '4px' : '0px',
    ...(hideText && {
      minWidth: '24px',
      padding: 0,
    }),
  }
})

const TextContainer = styled(View)<{
  theme: ThemeType
  hasFrontIcon: boolean
  hasBackIcon: boolean
  textColor?: string
}>(({ theme, hasFrontIcon, hasBackIcon, textColor }) => {
  return {
    flexShrink: 1,
    paddingLeft: hasFrontIcon ? theme.spacing['4px'] : 0,
    paddingRight: hasBackIcon ? theme.spacing['4px'] : 0,
    ...(textColor && { color: textColor }),
  }
})

export enum Corners {
  ROUND = 'round',
  CURVED = 'curved',
  SHARP = 'sharp',
}

export const IconBadge: FunctionComponent<IconBadgeProps> = ({
  text,
  color,
  textColor,
  frontIcon,
  backIcon,
  testId = 'IconBadge',
  cornerType = Corners.ROUND,
  hideText = false,
  noPadding = false,
  ...props
}) => {
  return (
    <Container
      testID={tID(testId)}
      color={color}
      cornerType={cornerType}
      hideText={hideText}
      noPadding={noPadding}
      {...props}
    >
      <View>{frontIcon}</View>
      <TextContainer hasFrontIcon={Boolean(frontIcon)} hasBackIcon={Boolean(backIcon)} textColor={textColor}>
        {text}
      </TextContainer>
      <View>{backIcon}</View>
    </Container>
  )
}
