import React, { Component } from 'react'
import styles from './reportTable.module.scss'
import ReactTable from 'react-table'
import { get } from 'lodash-es'

type Props = {
  metaData?: $TSFixMe
  data?: $TSFixMe[]
  rowClickHandler?: $TSFixMeFunction
  UIMetadata?: $TSFixMe
  destinationSelector?: string
}

type Column = {
  accessor: string
  Cell?: (props: any) => Element
  Header: string
  name?: string
  width?: number
  minWidth?: number
  moveToEnd?: boolean
}

class ReportTable extends Component<Props> {
  columns: Column[]

  constructor(props: Props) {
    super(props)
    this.columns = []
    const schemaProperties = get(props, 'metaData.schema.properties')
    const fixedColumns = get(props, 'UIMetadata.report.fixedColumns')
    const customColumns = get(props, 'UIMetadata.report.customColumns')
    const fields = Object.keys(get(props, 'data[0].response'))

    customColumns.forEach((column: Column) => {
      const customColumnName = get(column, 'name')
      if (customColumnName && fields.includes(customColumnName)) {
        this.columns.push(column)
      }
    })

    for (const field of fields) {
      const Header = get(schemaProperties[field], 'shortTitle') || get(schemaProperties[field], 'title')
      const accessor = 'response.' + get(schemaProperties[field], 'name')

      // Prevent potential duplicates of custom columns
      if (!customColumns.some((column: Column) => column.accessor.includes(accessor))) {
        this.columns.push({ Header, accessor, minWidth: 90 })
      }
    }

    fixedColumns.forEach((column: Column) =>
      column.moveToEnd ? this.columns.push(column) : this.columns.unshift(column),
    )
  }

  render() {
    const { destinationSelector, rowClickHandler, data } = this.props
    return (
      <div className={styles.container}>
        <ReactTable
          data={data}
          columns={this.columns}
          resizable={false}
          sortable={false}
          showPagination={false}
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          defaultPageSize={data.length}
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          pageSize={data.length}
          getTdProps={(state: $TSFixMe, rowInfo: $TSFixMe, column: $TSFixMe, instance: $TSFixMe) => {
            return {
              onClick: (e: $TSFixMe, handleOriginal: $TSFixMe) => {
                // @ts-expect-error TS(2538): Type 'undefined' cannot be used as an index type.
                if (rowClickHandler && rowInfo.original[destinationSelector]) {
                  rowClickHandler(rowInfo.original)
                } else {
                  // IMPORTANT! React-Table uses onClick internally to trigger
                  // events like expanding SubComponents and pivots.
                  // By default a custom 'onClick' handler will override this functionality.
                  // If you want to fire the original onClick handler, call the
                  // 'handleOriginal' function.
                  if (handleOriginal) {
                    handleOriginal()
                  }
                }
              },
            }
          }}
        />
      </div>
    )
  }
}

export default ReportTable
