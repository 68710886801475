import React from 'react'
import Tooltip from '../tooltip/Tooltip'

const SingleSessionCoachingIcon: React.FC<SingleSessionCoachingIconProps> = ({
  width = 20,
  showTooltip = true,
  tooltip = 'Guided Self-Care',
  customTooltipWidth,
  ...props
}) => {
  const icon = (
    <svg width={width} viewBox='0 0 52 52' version='1.1' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g id='SingleSessionCoachingIcon' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Personalized_care_icon'>
          <g id='Group-7' fill='#F2DCE8'>
            <rect id='Rectangle' x='0' y='0' width='52' height='52' rx='4' />
          </g>
          <circle id='Oval' fill='#AE5B89' cx='26' cy='26' r='6' />
        </g>
      </g>
    </svg>
  )
  return showTooltip ? (
    <Tooltip content={tooltip} delayHide={0} placement='bottom' customWidth={customTooltipWidth}>
      {icon}
    </Tooltip>
  ) : (
    icon
  )
}

type SingleSessionCoachingIconProps = {
  showTooltip: string
  width?: number
  tooltip?: string
  customTooltipWidth?: string
}

export default SingleSessionCoachingIcon
