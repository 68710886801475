import React, { FunctionComponent } from 'react'

import checkEmail from '../../assets/check_email.png'
import { Image } from '../image/Image'

interface CheckEmailIllustrationProps {
  width?: number
  height?: number
}

export const CheckEmailIllustration: FunctionComponent<CheckEmailIllustrationProps> = ({
  width = 196,
  height = 173,
  ...props
}) => {
  return (
    <Image
      source={checkEmail}
      style={{ width, height }}
      accessibilityIgnoresInvertColors={true}
      contentFit='contain'
      {...props}
    />
  )
}
