import { useRef } from 'react'
import { Animated, Easing } from 'react-native'

import LottieView from 'lottie-react-native'

import { IS_WEB } from '../constants'

const AnimatedLottieView = Animated.createAnimatedComponent(LottieView)

export const useAnimateLottie = (duration = 2000) => {
  const lottie = useRef(new Animated.Value(0))
  /**
   * On web, LottieView `progress` prop doesn't work if wrapped by `Animated`.
   * There's also a type discrepancy on `progress` -- `react-native-web-lottie` accepts `Animated.Value`, but the type defined in `lottie-react-native` accepts only number. Workaround this by typing using `any`
   */
  const AnimationView: any = IS_WEB ? LottieView : AnimatedLottieView

  const playLottie = () => {
    Animated.timing(lottie.current, {
      toValue: 1,
      duration,
      easing: Easing.linear,
      useNativeDriver: false,
    }).start()
  }

  const reverseLottie = () => {
    Animated.timing(lottie.current, {
      toValue: 0,
      duration,
      easing: Easing.linear,
      useNativeDriver: false,
    }).start()
  }

  return {
    progress: lottie,
    playLottie,
    reverseLottie,
    AnimationView,
  }
}
