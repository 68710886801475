import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

export const CoachingSecondaryRec: FunctionComponent<CoachingSecondaryRecProps> = ({ width = 80, height = 80 }) => {
  return (
    <Svg width={width} height={height} viewBox='0 0 81 81' fill='none'>
      <Path
        d='M40.9687 0.957031C35.4467 0.957031 30.089 1.90398 25.0444 3.77101C13.0344 8.22279 4.5909 18.0923 1.8781 30.8484C0.085377 39.1655 0.314606 46.8581 2.58318 54.3547C5.47936 63.9285 12.5886 72.0021 22.0882 76.5045C26.8039 78.7446 32.2216 80.0235 38.1879 80.3049C38.9546 80.3397 39.734 80.3587 40.5087 80.3587C45.2956 80.3587 50.111 79.7026 54.822 78.4094C55.184 78.3193 55.5287 78.2261 55.8923 78.1186C60.1259 76.8791 63.9358 74.9188 67.1956 72.2993C70.7352 69.4711 73.6488 65.8857 75.8462 61.6489C76.2794 60.8142 76.6936 59.9352 77.1505 58.8839C79.6641 53.0473 80.8782 46.5135 80.6632 39.9812C80.562 37.0929 80.132 34.0229 79.389 30.8484C78.1654 25.6979 76.1703 20.8541 73.6188 16.8323C72.92 15.7526 72.2782 14.8451 71.6426 14.0294C67.0565 8.11371 59.9109 3.9623 50.9852 2.03045C47.7064 1.31905 44.336 0.958612 40.9703 0.958612L40.9687 0.957031Z'
        fill='white'
      />
      <Path
        d='M77.2312 40.0881C77.1332 37.3199 76.7095 34.4522 76.0424 31.6019C74.9516 27.0094 73.1588 22.4817 70.7006 18.6054C70.1299 17.7264 69.5449 16.8791 68.9094 16.0649C64.2996 10.1208 57.3136 6.81355 50.1633 5.26586C42.1988 3.53953 33.7299 4.04384 26.0737 6.87837C14.998 10.9824 7.45718 19.9729 4.99732 31.537C4.05195 35.9177 3.7753 40.869 4.19898 45.6259H4.16578C4.41082 48.3292 4.89931 50.9677 5.63126 53.3944C8.35197 62.3849 14.9632 69.5194 23.3846 73.5096C27.9455 75.6754 33.0423 76.7504 38.2228 76.9955C43.5156 77.2405 48.8906 76.5718 53.8594 75.2043C54.185 75.1237 54.4949 75.0415 54.8206 74.9435C58.7127 73.8036 62.1005 72.0441 64.9999 69.7155C68.1601 67.1908 70.7654 64.0148 72.7858 60.1226C73.2252 59.2753 73.6157 58.4295 73.9904 57.5648C76.368 52.0427 77.4272 46.0496 77.2154 40.1054L77.2312 40.0896V40.0881Z'
        fill='#FFE9E3'
      />
      <Path
        d='M62.1433 52.4076C65.2524 45.3992 65.0438 35.1082 60.5457 28.7401C57.4238 24.3201 51.2963 20.9052 45.9349 20.3333C40.3362 19.736 34.3744 20.19 29.459 23.0475C25.1393 25.5577 21.487 29.3024 19.4912 33.9199C18.2027 36.9032 17.6261 40.1876 17.7885 43.4321C18.1135 49.918 21.1191 56.5664 26.6381 60.0944C33.304 64.3568 42.3193 66.1439 49.8851 63.3676C54.5536 61.6554 59.9197 57.4169 62.1417 52.4076H62.1433Z'
        fill='#F69170'
      />
      <Path
        d='M57.8563 41.1257C58.4552 48.8556 53.5637 56.8562 44.2251 58.2164C28.7001 60.475 17.8149 44.5104 28.9597 32.0404C38.507 21.3559 56.7254 26.55 57.8563 41.1257Z'
        fill='white'
      />
      <Path
        d='M41.323 53.3471C47.0813 53.3471 51.7494 48.679 51.7494 42.9206C51.7494 37.1622 47.0813 32.4941 41.323 32.4941C35.5646 32.4941 30.8965 37.1622 30.8965 42.9206C30.8965 48.679 35.5646 53.3471 41.323 53.3471Z'
        fill='#F69170'
      />
      <Path
        d='M41.3231 47.1561C43.6622 47.1561 45.5584 45.2599 45.5584 42.9208C45.5584 40.5817 43.6622 38.6855 41.3231 38.6855C38.9841 38.6855 37.0879 40.5817 37.0879 42.9208C37.0879 45.2599 38.9841 47.1561 41.3231 47.1561Z'
        fill='white'
      />
      <Path
        d='M41.9218 42.128L29.744 23.5961C29.4561 23.1579 28.8675 23.0361 28.4293 23.3241L28.428 23.3249C27.9898 23.6129 27.868 24.2015 28.156 24.6396L40.3338 43.1715C40.6217 43.6097 41.2103 43.7314 41.6484 43.4435L41.6498 43.4426C42.0879 43.1547 42.2097 42.5661 41.9218 42.128Z'
        fill='#068298'
      />
      <Path
        d='M29.3863 15.1191L35.2462 24.1232L33.1294 30.2746L33.1278 30.2778H33.1246L26.6435 29.7203L20.7852 20.7162L27.5912 21.7691L29.3863 15.1191Z'
        fill='#3BA5B5'
      />
    </Svg>
  )
}

type CoachingSecondaryRecProps = {
  width?: number
  height?: number
}
