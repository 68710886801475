import React from 'react'

import { useTheme } from 'styled-components/native'

import {
  BodyText,
  BodyTextSize,
  Link,
  PrimaryButton,
  Subhead,
  SubheadSize,
  tID,
} from '@lyrahealth-inc/ui-core-crossplatform'

import { actions, mixpanelEvents } from '../../../../mixpanel/mixpanelConstants'
import { track } from '../../../../mixpanel/mixpanelTracking'
import { BodyTextContainer, ButtonGroupContainer, HeaderContainer, LinkButtonContainer } from '../constants'

export const SchedulingSubmission: React.FC<SchedulingSubmissionProps> = ({ closeModal, submitCalendars }) => {
  const { colors } = useTheme()

  return (
    <>
      <HeaderContainer>
        <Subhead
          level='2'
          size={SubheadSize.LARGE}
          textAlign='left'
          text='Confirm your Availability'
          color={colors.textPrimary}
        />
      </HeaderContainer>
      <BodyTextContainer>
        <BodyText
          color={colors.textPrimary}
          textAlign='left'
          text={
            <>
              Before we share with clients, please confirm that your schedule is up-to-date. We&apos;ll notify you when
              your calendar is online and open to new appointments.{' '}
              <Link
                text='Learn more'
                onPress={() => {
                  track({ event: mixpanelEvents.BUTTON_PRESS, action: actions.SCHEDULING_SUBMISSION_LEARN_MORE })
                  window.open(
                    'https://provider-support.lyrahealth.com/hc/en-us/articles/115008263367-Connecting-Your-Calendar-with-Lyra',
                    '_blank',
                  )
                }}
                size={BodyTextSize.DEFAULT}
              />
            </>
          }
        />
      </BodyTextContainer>
      <ButtonGroupContainer>
        <LinkButtonContainer>
          <Link
            text='Cancel'
            onPress={closeModal}
            size={BodyTextSize.DEFAULT}
            testID={tID('CancelSubmission-button')}
          />
        </LinkButtonContainer>
        <PrimaryButton
          text='My Schedule is up-to-date'
          onPress={submitCalendars}
          testID={tID('SubmitSchedule-button')}
        />
      </ButtonGroupContainer>
    </>
  )
}

type SchedulingSubmissionProps = {
  closeModal: () => void
  submitCalendars: () => void
}
