import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { connect, useSelector } from 'react-redux'

import { Map } from 'immutable'
import { isEmpty } from 'lodash-es'
import styled from 'styled-components/native'

import { appointmentUtilization, ProviderUser, targetUtilization, useFlags } from '@lyrahealth-inc/shared-app-logic'
import {
  colors,
  InfoIcon,
  Link,
  Modal,
  PrimaryButton,
  Subhead,
  SubheadSize,
  ThemeType,
  tID,
  Tooltip,
} from '@lyrahealth-inc/ui-core-crossplatform'

import RecommendationExplanationContent from './RecommendationExplanationContent'
import { track } from '../../../../mixpanel/mixpanelTracking'
import AvailabilityCard, {
  ProviderAvailabilityResponse,
} from '../../common/components/availabilityCard/AvailabilityCard'
import { employmentTypes } from '../../common/constants/appConstants'
import { getAuthUser } from '../../data/auth/authSelectors'
import { getClientAppointmentsProviderAvailability } from '../../data/lyraTherapy/clientSelectors'
import { getRequestPaymentSelectedProvider } from '../../data/requestPayment/requestPaymentSelectors'
import { AvailabilityAndCapacityMismatchModal } from '../clients/AvailabilityAndCapacityMismatchModal'
import ProviderAvailabilityModal from '../clients/ProviderAvailabilityModal'

const Container = styled.View({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const SubheadInnerContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.spacing['4px'],
  alignItems: 'center',
}))

const TooltipContainer = styled.View({
  margin: 'auto',
})

const ButtonContainer = styled.View({
  margin: 'auto 0',
})

export const CapacityHeader: React.FC<CapacityHeaderProps> = ({
  targetUtilization,
  appointmentUtilization,
  selectedProvider,
  showMinified,
}) => {
  const user: ProviderUser = useSelector(getAuthUser)
  const { formatMessage } = useIntl()
  const { shouldShowCapacityToAvailabilityRatioAlert, showCalendarAvailabilitiesWithCapacity } = useFlags()

  type ModalContent = {
    body: React.ReactNode
    isLarge: boolean
  }
  const provider = selectedProvider || user
  const providerAvailability: ProviderAvailabilityResponse[] =
    useSelector(getClientAppointmentsProviderAvailability) ?? []
  const providerAvailabilities = providerAvailability && providerAvailability[0]?.availability
  const currentCapacity = provider.capacity_value
  const [modalContent, setModalContent] = useState<ModalContent>({} as ModalContent)
  const [isMismatchModalOpen, setMismatchModalOpen] = useState(false)
  const [availabilityCapacityMismatchCount, setAvailabilityCapacityMismatchCount] = useState(0)
  const upcomingWeekSessionTarget = targetUtilization?.upcomingWeekSessionTarget ?? 0
  const upcomingWeekSessionTargetPlus = targetUtilization?.upcomingWeekSessionTargetPlus ?? 0
  const upcomingWeekPaidTimeOff = targetUtilization?.upcomingWeekPaidTimeOff ?? 0
  const { scheduledNextWeek } = appointmentUtilization
  const cancellationBuffer = provider.employment_status === employmentTypes.FULL_TIME ? 4 : 2

  let currentTargetLevel: string, sessionsNeededToReachTargetLevel: number, targetInfoSentence: string
  if (upcomingWeekSessionTarget > scheduledNextWeek) {
    currentTargetLevel = 'Target'
    sessionsNeededToReachTargetLevel = upcomingWeekSessionTarget - scheduledNextWeek + cancellationBuffer
    targetInfoSentence = `Add ${sessionsNeededToReachTargetLevel} sessions to reach ${currentTargetLevel} next week`
  } else if (upcomingWeekSessionTargetPlus > scheduledNextWeek) {
    currentTargetLevel = 'Target Plus'
    sessionsNeededToReachTargetLevel = upcomingWeekSessionTargetPlus - scheduledNextWeek + cancellationBuffer
    targetInfoSentence = `Add ${sessionsNeededToReachTargetLevel} sessions to reach ${currentTargetLevel} next week`
  } else {
    currentTargetLevel = 'Target Plus'
    targetInfoSentence = `Great! You're above ${currentTargetLevel} next week`
  }

  return (
    <div>
      <Container>
        <div>
          {!showMinified && (
            <>
              <SubheadInnerContainer>
                <Subhead text={targetInfoSentence} size={SubheadSize.LARGE} color={colors.ui_oatmeal6} />
                <TooltipContainer>
                  <Tooltip
                    hoverEnabled
                    content={
                      'This dashboard is for planning purposes only. Your performance-based compensation will be calculated by operations at the end of the quarter.'
                    }
                    placement='right'
                  >
                    <InfoIcon size={18} />
                  </Tooltip>
                </TooltipContainer>
              </SubheadInnerContainer>
              <Link
                text='How do we calculate this?'
                onPress={() => {
                  setModalContent({
                    body: (
                      <RecommendationExplanationContent
                        upcomingWeekSessionTarget={
                          upcomingWeekSessionTarget > scheduledNextWeek
                            ? upcomingWeekSessionTarget
                            : upcomingWeekSessionTargetPlus
                        }
                        targetLevel={currentTargetLevel}
                        employmentType={provider.employment_status || ''}
                        scheduledNextWeek={scheduledNextWeek}
                        nextWeekSessionRecommendation={sessionsNeededToReachTargetLevel}
                        upcomingWeekPaidTimeOff={upcomingWeekPaidTimeOff}
                      />
                    ),
                    isLarge: true,
                  })
                  track({
                    event: 'SHOW_RECOMMENDATION_EXPLANATION_MODAL',
                  })
                }}
                testID={tID('CapacityHeader-explanation-button')}
              />
            </>
          )}
        </div>
        {showCalendarAvailabilitiesWithCapacity ? (
          <></>
        ) : showMinified ? (
          <AvailabilityCard isMinified />
        ) : (
          <ButtonContainer>
            <PrimaryButton
              testID={tID('CapacityHeader-update-button')}
              text={formatMessage({
                defaultMessage: 'Update availability',
                description: 'button to update provider availabity for new clients',
              })}
              onPress={() => {
                setModalContent({
                  body: (
                    <ProviderAvailabilityModal
                      currentCapacity={currentCapacity}
                      providerId={provider.id}
                      selectedProvider={selectedProvider || {}}
                      triggerMismatchModal={() => setMismatchModalOpen(true)}
                      setMismatchModalCount={setAvailabilityCapacityMismatchCount}
                      providerAvailabilities={providerAvailabilities}
                      closeModal={() => setModalContent({} as ModalContent)}
                    />
                  ),
                  isLarge: false,
                })
                track({
                  event: 'UPDATE_CAPACITY',
                })
              }}
            />
          </ButtonContainer>
        )}
      </Container>
      <Modal
        visible={!isEmpty(modalContent)}
        modalContents={
          Object.prototype.hasOwnProperty.call(modalContent, 'body') ? (
            (modalContent.body as React.ReactElement)
          ) : (
            <div />
          )
        }
        onRequestClose={() => setModalContent({} as ModalContent)}
        onCloseEnd={() => setModalContent({} as ModalContent)}
        scrollable
        scrollableModalHeight='auto'
        scrollableModalWidth='700px'
        isHeaderTransparent
        disableBottomSheet
      />
      {shouldShowCapacityToAvailabilityRatioAlert && (
        <AvailabilityAndCapacityMismatchModal
          onClose={() => setMismatchModalOpen(false)}
          isModalOpen={isMismatchModalOpen}
          shortfall={availabilityCapacityMismatchCount}
          providerAvailabilitiesCount={providerAvailabilities?.length ?? 0}
        />
      )}
    </div>
  )
}

type CapacityHeaderProps = {
  targetUtilization?: targetUtilization
  appointmentUtilization: appointmentUtilization
  selectedProvider: ProviderUser
  showMinified: boolean
}

const mapStateToProps = ($$state: Map<string, any>) => {
  return {
    selectedProvider: getRequestPaymentSelectedProvider($$state),
  }
}

export default connect(mapStateToProps)(CapacityHeader)
