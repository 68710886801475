import React, { FunctionComponent } from 'react'
import { ImageSourcePropType } from 'react-native'

import styled from 'styled-components/native'

import providerPreferences from './../../assets/provider-preferences.png'
import { ThemeType } from '../../utils/themes/ThemeProvider'
import { Image } from '../image/Image'

const ProviderPreferenceIllustration = styled(Image)<{ theme: ThemeType }>(({ theme }) => ({
  width: theme.breakpoints.isMinWidthTablet ? 434 : 343,
  height: theme.breakpoints.isMinWidthTablet ? 159 : 125.275,
}))

export const ProviderPreferencePersonalization: FunctionComponent = () => {
  return <ProviderPreferenceIllustration source={providerPreferences as ImageSourcePropType} />
}
