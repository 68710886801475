import React from 'react'
import { Icon } from './types'
import colors from '../../../styles/_0colors.scss'

const AlertIcon: React.FC<AlertIconProps> = ({ width = 64, fillColor = colors.x_warning_dark }) => {
  return (
    <svg fill='none' height={width} viewBox='0 0 64 64' width={width} xmlns='http://www.w3.org/2000/svg'>
      <path d='m31.998 8-28.998 50h58z' fill='#eac89a' />
      <path
        d='m32 42c-.2652 0-.5196-.1054-.7071-.2929s-.2929-.4419-.2929-.7071v-16c0-.2652.1054-.5196.2929-.7071s.4419-.2929.7071-.2929.5196.1054.7071.2929.2929.4419.2929.7071v16c0 .2652-.1054.5196-.2929.7071s-.4419.2929-.7071.2929zm0 9c-.5933 0-1.1734-.1759-1.6667-.5056-.4934-.3296-.8779-.7982-1.1049-1.3464-.2271-.5481-.2865-1.1513-.1708-1.7333.1158-.5819.4015-1.1165.8211-1.536.4195-.4196.9541-.7053 1.536-.8211.582-.1157 1.1852-.0563 1.7333.1708.5482.227 1.0168.6115 1.3464 1.1049.3297.4933.5056 1.0734.5056 1.6667 0 .7956-.3161 1.5587-.8787 2.1213s-1.3256.8787-2.1213.8787zm0-4c-.1978 0-.3911.0586-.5556.1685-.1644.1099-.2926.2661-.3683.4488s-.0955.3838-.0569.5778.1338.3722.2737.512c.1398.1399.318.2351.512.2737s.3951.0188.5778-.0569.3389-.2039.4488-.3683c.1099-.1645.1685-.3578.1685-.5556 0-.2652-.1054-.5196-.2929-.7071s-.4419-.2929-.7071-.2929z'
        fill={fillColor}
      />
    </svg>
  )
}

type AlertIconProps = Icon

export default AlertIcon
