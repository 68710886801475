import { useMemo } from 'react'
import { useIntl } from 'react-intl'

import { HealthPlansList } from '../../../models'
import { Section, SECTION_LIST_HEADER } from '../../common/utils/typeAheadUtils'

type FormattedHealthPlans = Section<any>[]

export const useFormattedHealthPlans = (
  integratedHealthPlans?: HealthPlansList[],
  nonIntegratedHealthPlans?: HealthPlansList[],
  showOtherOption?: boolean,
): FormattedHealthPlans => {
  const { formatMessage } = useIntl()
  const otherOption = formatMessage({
    defaultMessage: 'I can’t find my health plan on this list',
    description: 'Option text in a dropdown list containing health plans',
  })
  return useMemo(() => {
    const formattedHealthPlans: FormattedHealthPlans = []

    // Remove non-integrated plans that have an identical name to an integrated plan
    const filteredNonIntegratedHealthPlans = nonIntegratedHealthPlans?.filter(
      (plan) => !integratedHealthPlans?.some((integratedPlan) => integratedPlan.name === plan.name),
    )

    if (integratedHealthPlans && integratedHealthPlans.length > 0) {
      formattedHealthPlans.push({
        title: SECTION_LIST_HEADER.RECOMMENDED,
        items: integratedHealthPlans,
      })
    }
    if (filteredNonIntegratedHealthPlans && filteredNonIntegratedHealthPlans.length > 0) {
      formattedHealthPlans.push({
        title: SECTION_LIST_HEADER.ALL_HEALTH_PLANS,
        items: filteredNonIntegratedHealthPlans,
      })

      if (showOtherOption) {
        formattedHealthPlans.push({
          items: [{ name: otherOption, eligibility_name: 'other', menuItem_section: 'other' }],
        })
      }
    }

    return formattedHealthPlans
  }, [integratedHealthPlans, nonIntegratedHealthPlans, otherOption, showOtherOption])
}
