import React from 'react'
import CSSModules from 'react-css-modules'
import styles from './requestPayment.module.scss'
import sessionResetDate from '../common/content/sessionResetDate'
import { CustomerInfo } from '@lyrahealth-inc/shared-app-logic'

const LastSessionModal = ({ firstName, lastName, company }: LastSessionModalProps) => {
  return (
    <div styleName='modal-content'>
      <h2 styleName='main-header'>Thank you!</h2>
      <div>
        <p>
          Your payment was successfully submitted. Your client, <span styleName='capitalize'>{firstName}</span>{' '}
          <span styleName='capitalize'>{lastName}</span> has now used up all the available sessions covered by their
          Lyra benefit. Any additional sessions moving forward will not be reimbursed by Lyra.{' '}
          {sessionResetDate({ employer: company?.label, date: company?.session_count_starts_on })}
        </p>
        <p className='paragraph-small'>
          For any questions, please contact <a href='mailto:providers@lyrahealth.com'>providers@lyrahealth.com</a>
        </p>
      </div>
    </div>
  )
}

type LastSessionModalProps = {
  firstName: string
  lastName: string
  company?: CustomerInfo
}

export default CSSModules(LastSessionModal, styles)
