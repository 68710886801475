import React from 'react'
import { Icon } from './types'

const CheckSuccessIcon: React.FC<CheckSuccessIconProps> = ({ width = 64, fillColor = '#2b7e5b' }) => {
  return (
    <svg fill='none' height={width} viewBox='0 0 64 64' width={width} xmlns='http://www.w3.org/2000/svg'>
      <path d='m32 62c16.5685 0 30-13.4315 30-30s-13.4315-30-30-30-30 13.4315-30 30 13.4315 30 30 30z' fill='#b9d5c9' />
      <path
        d='m29.9895 42.007c-.2652 0-.5195-.1054-.707-.293l-8.992-8.992c-.1025-.0902-.1854-.2004-.2437-.3239-.0583-.1234-.0906-.2575-.0951-.394s.0191-.2724.0691-.3994c.0501-.1271.1256-.2425.222-.3392s.2115-.1727.3384-.2232c.1268-.0505.2627-.0745.3992-.0705.1364.004.2707.0359.3943.0937.1237.0578.2342.1404.3248.2425l8.285 8.285 15.3-15.3c.1866-.1887.4405-.2955.7059-.297.2653-.0015.5204.1025.7091.289.1887.1866.2956.4405.2971.7059s-.1025.5204-.2891.7091l-16.008 16.014c-.0932.0933-.2039.1671-.3257.2174-.1219.0503-.2525.076-.3843.0756z'
        fill={fillColor}
      />
    </svg>
  )
}

type CheckSuccessIconProps = Icon

export default CheckSuccessIcon
