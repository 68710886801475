import React, { FunctionComponent } from 'react'

import styled, { useTheme } from 'styled-components/native'

import { ClinicalScreenerIllustration } from '../../atoms/illustrations/ClinicalScreenerIllustration'
import { StyledMarkdown } from '../../atoms/styledMarkdown/StyledMarkdown'
import { getHighlightedText } from '../../styles/typeStyles'
import { tID } from '../../utils'

const Container = styled.View(({ theme }) => ({
  alignItems: 'center',
  textAlign: 'center',
  marginBottom: theme.spacing['16px'],
}))

const IllustrationContainer = styled.View(({ theme }) => ({
  marginBottom: theme.spacing['32px'],
}))

export const ClinicalScreenerHeader: FunctionComponent<ClinicalScreenerHeaderProps> = ({ title, subTitle, name }) => {
  const { colors } = useTheme()
  const markdownCustomStyles = { em: getHighlightedText(colors) }
  return (
    <Container testID={tID(name)}>
      <IllustrationContainer>
        <ClinicalScreenerIllustration />
      </IllustrationContainer>
      <StyledMarkdown content={title} center customStyles={markdownCustomStyles} />
      <StyledMarkdown content={subTitle} center customStyles={markdownCustomStyles} />
    </Container>
  )
}

type ClinicalScreenerHeaderProps = {
  title?: string
  subTitle?: string
  name?: string
}
