import React, { FunctionComponent } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import styled, { useTheme } from 'styled-components/native'

import { HeadphoneHeartIcon } from '../../atoms'
import { BaseButton, ButtonSize, ButtonType } from '../../atoms/baseButton/BaseButton'
import TertiaryIconButton from '../../atoms/tertiaryIconButton/TertiaryIconButton'
import { ThemeType } from '../../utils'
import { tID } from '../../utils/utils'

export interface CareNavigatorHeaderEntryPointButtonProps {
  isInternational?: boolean
  onPress: () => void
  renderIcon?: boolean
  isActive?: boolean
  lightBgColor?: boolean
  testID?: string
  suppressAccessibilitySelected?: boolean
}

const StyledBaseButton = styled(BaseButton)<{ theme: ThemeType }>(({ theme }) => ({
  justifyContent: 'center',
  paddingTop: theme.spacing['8px'],
  paddingBottom: theme.spacing['8px'],
  paddingLeft: theme.spacing['16px'],
  paddingRight: theme.spacing['16px'],
  borderWidth: 1.5,
  borderColor: theme.colors.borderDefault,
}))

export const CareNavigatorHeaderEntryPointButton: FunctionComponent<CareNavigatorHeaderEntryPointButtonProps> = ({
  isInternational = false,
  onPress,
  renderIcon = false,
  isActive = false,
  lightBgColor = true,
  testID = tID('CareNavigatorHeaderEntryPoint-button'),
  ...restProps
}) => {
  const { breakpoints, colors } = useTheme()
  const { formatMessage } = useIntl()

  const buttonColorText = lightBgColor ? colors.textLightBackground : colors.textDarkBackground
  const buttonColorIcon = lightBgColor ? colors.iconLightBackground : colors.iconDarkBackground
  const buttonText = isInternational ? (
    <FormattedMessage
      defaultMessage='Contact us'
      description='Label of button that takes the user to the page to get in touch with a Care Navigator'
    />
  ) : (
    <FormattedMessage
      defaultMessage='Get in touch'
      description='Label of button that takes the user to the page to get in touch with a Care Navigator'
    />
  )

  return !breakpoints.isMinWidthTablet || renderIcon ? (
    <TertiaryIconButton
      testID={testID}
      onPress={onPress}
      leftIcon={<HeadphoneHeartIcon fill={buttonColorIcon} />}
      accessibilityLabel={formatMessage({
        defaultMessage: 'Care Navigator',
        description: 'Care navigator entry point',
      })}
      size={ButtonSize.SMALL}
      {...restProps}
    />
  ) : (
    <StyledBaseButton
      selected={isActive}
      testID={testID}
      onPress={onPress}
      text={buttonText}
      leftIcon={<HeadphoneHeartIcon fill={buttonColorIcon} />}
      customTextColor={buttonColorText}
      buttonType={ButtonType.TERTIARY_HEADER}
      {...restProps}
    />
  )
}
