import React from 'react'
import { FormattedDate, FormattedMessage } from 'react-intl'
import { GestureResponderEvent, Platform, Pressable, View } from 'react-native'

import { includes, isEmpty, startCase, uniqueId } from 'lodash-es'
import pluralize from 'pluralize'
import styled, { useTheme } from 'styled-components/native'

import {
  Assignment,
  AssignmentGroup,
  assignmentHasNonCompletedProviderDraft,
  EpisodeContents,
  getAssignmentGroupLabel,
  getDateFromAppointment,
  InfoPopoverPlacement,
  InfoPopoverTriggerAction,
  Provider,
} from '@lyrahealth-inc/shared-app-logic'

import { AssignmentIconSelector } from './AssignmentIconSelector'
import { ItemAction } from './AssignmentsList'
import { BodyText, GenericListItem, InfoPopover, MenuDotsIcon, PressableOpacity } from '../../atoms'
import { BodyTextSize } from '../../styles'
import { ThemeType, tID } from '../../utils'

type AssignmentListItemProps = {
  assignment?: Assignment
  futureEpisode?: Assignment | EpisodeContents
  assignmentTags?: AssignmentTag[]
  fullBorder?: boolean
  itemActions?: Array<ItemAction>
  itemClickHandler?: (val: any) => void
  multiProvider?: boolean
  provider?: Provider
  sessionCount?: number
  showDueDate?: boolean
  showProviderName?: boolean
  userRole: string
  isFirstItem: boolean
  isLastItem: boolean
  clientFirstName?: string
  timeSubtext?: string
  height?: string
  largeText?: boolean
  boldText?: boolean
}

type AssignmentTag = {
  type: AssignmentTagType
  text: string
}

export enum AssignmentTagType {
  default,
  success,
}

const MenuContainer = styled(View)<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: spacing['24px'],
  height: spacing['24px'],
}))

const TriggerContainer = styled(Pressable)<{ theme: ThemeType }>(({ theme }) => {
  return {
    borderRadius: Platform.OS === 'web' ? '50%' : 0,
    padding: theme.spacing['12px'],
    display: 'flex',
    position: 'absolute',
    right: '-12px',
  }
})

const RightActionOption = styled(PressableOpacity)({
  padding: '10px',
  width: '100%',
})

const RightActionOptionText = styled(BodyText)<{ theme: ThemeType }>(({ theme }) => {
  return {
    paddingLeft: theme.spacing['16px'],
  }
})

const AssignmentTagText = styled(BodyText)<{ theme: ThemeType; tagType: AssignmentTagType }>(
  ({ theme: { colors }, tagType }) => ({
    backgroundColor:
      tagType === AssignmentTagType.success
        ? colors.components.assignmentsListItem.success.background
        : colors.components.assignmentsListItem.default.background,
    paddingLeft: '10px',
    paddingRight: '10px',
    borderRadius: 3,
  }),
)

export const AssignmentsListItem: React.FC<AssignmentListItemProps> = ({
  assignment,
  futureEpisode,
  assignmentTags,
  fullBorder = false,
  itemActions,
  itemClickHandler,
  multiProvider = false,
  provider,
  sessionCount,
  showDueDate = true,
  showProviderName = true,
  userRole,
  isFirstItem = false,
  isLastItem = true,
  clientFirstName,
  timeSubtext,
  height,
  largeText,
  boldText,
}) => {
  const { colors } = useTheme()
  const componentColors = colors.components.assignmentsListItem
  const { rightActionHover, rightActionPressed } = componentColors

  const assignmentName =
    assignment?.content?.name || assignment?.name || futureEpisode?.name || (futureEpisode as Assignment)?.content?.name

  const title =
    assignment?.content_meta_data?.title ||
    assignment?.content?.title ||
    assignment?.title ||
    futureEpisode?.title ||
    (futureEpisode as Assignment)?.content?.title

  const group =
    assignment?.content?.group ||
    assignment?.group ||
    futureEpisode?.group ||
    (futureEpisode as Assignment)?.content?.group

  const showProviderStartedWithBadge = assignmentHasNonCompletedProviderDraft(assignment || futureEpisode)

  let onClick = () => {}
  if (itemClickHandler) {
    onClick = () => itemClickHandler(assignment)
  }
  const inactive = userRole === 'client' && assignment?.status === 'missed'
  if (
    inactive ||
    (userRole === 'provider' && group === AssignmentGroup.ASSESSMENT && assignment?.status !== 'completed')
  ) {
    onClick = () => {}
  }

  const getDisplayStatus = () => {
    switch (assignment?.status) {
      case 'new':
        return <FormattedMessage description='The assignment has not yet been started' defaultMessage='Not started' />
      case 'opened':
        return <FormattedMessage description='The assignment has been opened' defaultMessage='Open' />
      case 'in_progress':
        return (
          <FormattedMessage description='The assignment has started and is in progress' defaultMessage='In progress' />
        )
      case 'draft':
        return (
          <FormattedMessage description='The assignment has started and a draft was saved' defaultMessage='Draft' />
        )
      case 'completed':
        return (
          <FormattedMessage
            description='Description of when this assignment was completed'
            defaultMessage='Completed on {completedOnDate}'
            values={{
              completedOnDate: (
                <FormattedDate
                  value={assignment?.assignment_responses?.[0]?.submit_date || undefined}
                  year='numeric'
                  month='long'
                  day='2-digit'
                />
              ),
            }}
          />
        )
      case 'missed':
        return (
          <FormattedMessage
            description='The assignment was missed and there is no entry for it'
            defaultMessage='No entry'
          />
        )
      default:
        return ''
    }
  }

  const renderDetails = () => {
    if (!assignment) {
      return ''
    }
    const numEntries = assignment?.response_count ? pluralize('entry', assignment.response_count, true) : null
    let sessionDueDate, providerName
    let details = []

    if (userRole === 'client') {
      if (!isEmpty(provider)) {
        const appointment = provider?.appointments.find(
          (appointment) => appointment.sessionNumber === assignment.session_period,
        )
        if (multiProvider) providerName = provider?.provider.display_name
        if (appointment) {
          sessionDueDate = getDateFromAppointment(appointment)
        }
      }
      if (assignment.past) {
        return group === AssignmentGroup.EXERCISE ? numEntries : null
      }
      details.push(startCase(getAssignmentGroupLabel(group as AssignmentGroup)))
      if (showDueDate) details.push(sessionDueDate)
      if (group === AssignmentGroup.EXERCISE) details.push(numEntries || 'Not started')
      else {
        if (getDisplayStatus()) return getDisplayStatus()
      }
      if (showProviderName) details.push(providerName)
    } else if (userRole === 'provider') {
      switch (group) {
        case AssignmentGroup.EXERCISE:
          details.push(numEntries)
          break
        default:
          return getDisplayStatus()
      }
    }
    details = details.filter((detail) => !isEmpty(detail))
    return details.join(' • ')
  }

  const renderBadge = () => {
    if (!assignment && !futureEpisode?.id) {
      return null
    }
    const unreadResponseCount = assignment ? assignment?.unread_count : 0
    if (showProviderStartedWithBadge && clientFirstName) {
      return (
        <FormattedMessage
          description='Tag that shows who the provider started the exercise with'
          defaultMessage='Started with {clientFirstName}'
          values={{
            clientFirstName,
          }}
        />
      )
    }
    if (userRole === 'client') {
      if (!assignment || assignment.past) return null
      switch (group) {
        case AssignmentGroup.ASSESSMENT:
          return !includes(['completed', 'missed'], assignment?.status) ? 'To-do' : null
        case AssignmentGroup.EXERCISE:
          return assignment.response_count < 1 ? 'To-do' : null
        default:
          return assignment?.status !== 'completed' ? 'To-do' : null
      }
    } else {
      if (userRole === 'provider' && unreadResponseCount > 0 && group !== AssignmentGroup.INFO_SHEET) {
        return unreadResponseCount
      }
    }
    return
  }

  const renderRightAction = () => {
    const actions = []
    itemActions =
      itemActions?.filter((action: ItemAction) =>
        action.shouldShow(assignment ? assignment : futureEpisode, sessionCount),
      ) || []
    if (itemActions.length && group !== AssignmentGroup.ASSESSMENT) {
      actions.push(
        <>
          <MenuContainer testID={tID('AssignmentListItem-right-action')}>
            <InfoPopover
              content={
                <div>
                  {itemActions.map((action: ItemAction) => (
                    <RightActionOption
                      testID={tID(`AssignmentListItem-dropdownAction-${action.name}`)}
                      key={action.name}
                      onPress={(e: GestureResponderEvent) => {
                        action.selectHandler(e, action.name, assignment || futureEpisode, sessionCount)
                      }}
                      hoveredBackgroundColor={rightActionHover.background}
                      pressedBackgroundColor={rightActionPressed.background}
                    >
                      <RightActionOptionText
                        testID={tID(`menuItemText-${action.name}`)}
                        text={action.text}
                        size={BodyTextSize.DEFAULT}
                        color={colors.textSecondary}
                        textAlign={'left'}
                      />
                    </RightActionOption>
                  ))}
                </div>
              }
              offset={0}
              placement={InfoPopoverPlacement.BOTTOM_RIGHT}
              shouldDisplayCloseButton={false}
              trigger={
                <TriggerContainer>
                  <MenuDotsIcon fillColor={colors.iconDefault} />
                </TriggerContainer>
              }
              popoverTriggerAction={InfoPopoverTriggerAction.PRESS}
              closeIconColor={colors.iconDefault}
              popoverStyle={{
                container: {
                  borderRadius: '12px',
                  width: '180px',
                  paddingLeft: 0,
                  paddingRight: 0,
                },
              }}
              allowContentUnderCloseButton
              clickToClose={false}
            />
          </MenuContainer>
        </>,
      )
    }
    return actions
  }

  const renderAssignmentTags = (tags: AssignmentTag[]) => {
    return tags.map((tag, idx) => {
      return (
        <AssignmentTagText
          testID={`Label-${tag.text}`}
          size={BodyTextSize.SMALL}
          key={idx}
          text={tag.text}
          color={tag.type === AssignmentTagType.success ? componentColors.success.text : componentColors.default.text}
          tagType={tag.type}
        />
      )
    })
  }

  return (
    <GenericListItem
      key={uniqueId('genericListItem')}
      onClick={onClick}
      title={title}
      listPressedBackgroundColor=''
      subText={timeSubtext ?? renderDetails()}
      badgeContent={renderBadge()}
      badgeBackgroundColor={showProviderStartedWithBadge ? colors.backgroundHighlightTeal : undefined}
      badgeTextColor={showProviderStartedWithBadge ? colors.textActive : undefined}
      icon={group && <AssignmentIconSelector type={group} size={24} />}
      rightItems={[assignmentTags && renderAssignmentTags(assignmentTags), renderRightAction()]}
      testID={tID(`AssignmentListItem-${assignmentName}`)}
      inactive={inactive}
      fullBorder={fullBorder}
      isFirstItem={isFirstItem}
      isLastItem={isLastItem}
      height={height}
      largeText={largeText}
      boldText={boldText}
    />
  )
}
