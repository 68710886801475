import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import CSSModules from 'react-css-modules'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'

import { differenceInMinutes } from 'date-fns'
import { Map } from 'immutable'
import { cloneDeep, isEmpty, isNumber } from 'lodash-es'
import moment from 'moment-timezone'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'

import {
  ActivityGroupType,
  ActivityGroupTypes,
  Appointment,
  Assignment,
  AssignmentStatuses,
  ClientObject,
  Curriculum,
  Episode,
  EpisodeContents,
  User,
} from '@lyrahealth-inc/shared-app-logic'
import {
  BaseModal,
  BootstrapContainer,
  dateUtils,
  DropdownButton,
  InfoSheet,
  Lesson,
  LoadingIndicator,
  NotificationBanner,
  SSCAssignmentsList,
  TOOLTIP_PLACEMENTS,
} from '@lyrahealth-inc/ui-core'
import { AssignmentsList, FormBody, FormContentHeader, toJS } from '@lyrahealth-inc/ui-core-crossplatform'

import styles from './assignments.module.scss'
import {
  clearSelectedAssignment,
  getAssignments,
  selectAssignment,
  updateAssignmentResponse,
} from './data/assignmentsAutoActions'
import { track } from '../../../../mixpanel/mixpanelTracking'
import SelfHarmIndicator from '../../common/components/selfHarmIndicator/SelfHarmIndicator'
import SpecialAlertIndicator from '../../common/components/specialAlertIndicator/SpecialAlertIndicator'
import { affirmativeResponses, programs } from '../../common/constants/appConstants'
import {
  CLIENTS_ASSIGNMENT_DETAILS,
  CLIENTS_CONTENT_LIBRARY,
  CLIENTS_TRACK_LIBRARY,
} from '../../common/constants/routingConstants'
import { getGSCDuration } from '../../common/utils/utils'
import { getAuthSupervisor, getAuthUser } from '../../data/auth/authSelectors'
import {
  getClientAppointmentsData,
  getClientAssignmentDetails,
  getClientAssignmentsData,
  getClientCurrentSessionCount,
  getClientDetailsData,
  getClientEpisodeProgramConfig,
  getClientEpisodesData,
  getClientNextAppointment,
  getClientSelectedEpisode,
  getSelectedEpisodeCurriculum,
} from '../../data/lyraTherapy/clientSelectors'
import { getLyraTherapyContentsData } from '../../data/lyraTherapy/contentSelectors'
import withRouter from '../../routing/withRouter'
import { getLTVideoSessionOpen } from '../data/ltVideoSelectors'

type AssignmentsProps = {
  router?: any
  actions?: any
  currentActivities?: Assignment[]
  contents?: any // TODO: PropTypes.instanceOf(List)
  curriculum?: Curriculum[]
  clientDetails?: ClientObject
  activityActions?: any[]
  notifications?: any[]
  currentSessionCount?: number
  appointments?: Appointment[]
  activities?: Assignment[]
  nextAppointment?: Appointment
  selectedEpisode?: Episode
  assessments?: Assignment[]
  supervisor?: any // TODO: PropTypes.instanceOf(Map)
  selectedAssignment?: Assignment
  user?: User
  programConfig?: any // TODO: PropTypes.instanceOf(Map)
  episodes?: Episode[]
  videoSessionOpen?: boolean
}

type DropdownItem = {
  text?: string | ReactNode
  id: string
  selectHandler: (event?: React.SyntheticEvent<unknown>) => void
  subText?: string
  header?: boolean
}

const Assignments: React.FC<AssignmentsProps> = ({
  router,
  currentSessionCount = 0,
  activities = [],
  currentActivities = [],
  curriculum = [],
  contents,
  clientDetails,
  programConfig,
  selectedEpisode,
  nextAppointment,
  selectedAssignment,
  actions,
  videoSessionOpen,
  supervisor,
  user,
  episodes = [],
  appointments = [],
  assessments = [],
}: AssignmentsProps) => {
  const [session, setSession] = useState(router?.location?.state?.sessionCount || currentSessionCount)
  const [dropdownItems, setDropdownItems] = useState<DropdownItem[]>([])
  const [showModal, setShowModal] = useState(false)
  const prevCurrentSessionCount = useRef<number>(currentSessionCount)
  const intl = useIntl()

  const updateResponseThenFetchAssignments = useCallback(
    (response: any) => {
      actions
        .updateAssignmentResponse(response)
        .then(() => actions.getAssignments({ patient_id: clientDetails?.id, provider_id: user?.id }))
    },
    [actions, clientDetails?.id, user?.id],
  )

  const appointmentDateFromSessionCount = useCallback(
    (count: number) => {
      const selectedAppointment = appointments?.find((appointment: Appointment) => appointment.sessionNumber === count)
      if (selectedAppointment) return moment(selectedAppointment.startDate, 'YYYY-MM-DD').format('MMM Do')
      return 'unscheduled'
    },
    [appointments],
  )

  const getAssessmentsForSession = useCallback(
    (sessionCount: number) => {
      let previousAppt: Appointment | undefined,
        sessionStartDate: Date | moment.Moment | undefined,
        sessionEndDate: Date | moment.Moment | undefined
      const sessionAppt = appointments?.find((appt: Appointment) => {
        return appt.appointmentStatus === 'completed' && appt.sessionNumber === sessionCount
      })
      if (sessionAppt) {
        sessionEndDate = dateUtils.getAppointmentDateTimeObject(sessionAppt)
      }
      if (sessionCount > 1) {
        previousAppt = appointments?.find((appt: Appointment) => {
          return appt.appointmentStatus === 'completed' && appt.sessionNumber === sessionCount - 1
        })
        if (previousAppt) {
          sessionStartDate = dateUtils.getAppointmentDateTimeObject(previousAppt)
        }
      } else if (selectedEpisode?.start_date) {
        sessionStartDate = moment.utc(selectedEpisode.start_date)
      }

      if (!sessionStartDate) {
        console.error(`the start date for session ${sessionCount} hasn't been found`)
        return []
      }
      const sessionAssessments = assessments?.filter((assessment: Assignment) => {
        const updateDate = moment.utc(assessment.update_date)
        const submitDate = moment.utc(assessment.assignment_responses?.[0]?.submit_date || '')

        switch (assessment.status) {
          case AssignmentStatuses.missed:
            return (
              updateDate.isSameOrAfter(sessionStartDate) && (updateDate.isBefore(sessionEndDate) || !sessionEndDate)
            )
          case AssignmentStatuses.completed:
            return (
              submitDate.isSameOrAfter(sessionStartDate) && (submitDate.isBefore(sessionEndDate) || !sessionEndDate)
            )
          default:
            // Assessment is active (status is new or in-progress)
            return currentSessionCount === sessionCount
        }
      })

      return sessionAssessments
    },
    [appointments, assessments, selectedEpisode, currentSessionCount],
  )

  const getDropdownItems = useCallback(() => {
    const futureCurriculum = curriculum.filter((curr: Curriculum) => curr.session > currentSessionCount)
    const dropdownItems: DropdownItem[] = []
    const chunkedPastAssignments = {}

    const renderSubtext = (numActivities: number, sessionCount: number) => {
      const pluralize = numActivities === 1 ? 'y' : 'ies'
      const date = appointmentDateFromSessionCount(sessionCount)
      const dateText = date === 'unscheduled' ? sessionCount : 'on ' + date
      return numActivities + ` activit${pluralize} for session ` + dateText
    }

    // Past Sessions
    if (currentSessionCount > 1) {
      dropdownItems.push({ text: 'Past', id: 'past-header', header: true, selectHandler: () => {} })
      for (let i = 1; i < currentSessionCount; i++) {
        const filteredActivities = activities.filter(
          (assignment: Assignment) =>
            assignment.session_period === i ||
            // filter so that session period 0s/auto assigned activities stay visible in the first session
            (i === 1 && assignment.session_period === 0),
        )
        const assessments = getAssessmentsForSession(i)
        chunkedPastAssignments[i] = [...filteredActivities, ...assessments]
      }

      Object.entries(chunkedPastAssignments).forEach(([sessionCount, assignments]) => {
        const convertedSessionCount = parseInt(sessionCount)
        dropdownItems.push({
          text: 'Session ' + convertedSessionCount,
          subText: renderSubtext((assignments as Assignment[]).length, convertedSessionCount),
          id: convertedSessionCount.toString(),
          selectHandler: () => changeSessionPeriod(convertedSessionCount, 'Past'),
        })
      })
    }

    // Current Sessions
    const assignments = [...currentActivities, ...getAssessmentsForSession(currentSessionCount)]
    dropdownItems.push(
      { text: 'Current', id: 'current-header', header: true, selectHandler: () => {} },
      {
        text: 'Session ' + currentSessionCount,
        subText: renderSubtext(assignments.length, currentSessionCount),
        id: currentSessionCount.toString(),
        selectHandler: () => changeSessionPeriod(currentSessionCount, 'Current'),
      },
    )

    // Future Sessions
    if (futureCurriculum) {
      dropdownItems.push({ text: 'Future', id: 'future-header', header: true, selectHandler: () => {} })
      futureCurriculum.map((sessionObject: Curriculum) => {
        const session = sessionObject.session
        const assignments = sessionObject.contents
        return dropdownItems.push({
          text: 'Session ' + session,
          subText: renderSubtext(assignments.length, session),
          id: session.toString(),
          selectHandler: () => changeSessionPeriod(session, 'Future'),
        })
      })
    }
    return dropdownItems
  }, [
    activities,
    currentActivities,
    curriculum,
    appointmentDateFromSessionCount,
    currentSessionCount,
    getAssessmentsForSession,
  ])

  useEffect(() => {
    setDropdownItems(getDropdownItems())
  }, [currentSessionCount, currentActivities, curriculum, getDropdownItems])

  const onModalClose = () => {
    setShowModal(false)
    actions.clearSelectedAssignment()
  }

  useEffect(() => {
    if (prevCurrentSessionCount.current != currentSessionCount) {
      setSession(currentSessionCount)
    }
    prevCurrentSessionCount.current = currentSessionCount
  }, [currentSessionCount])

  const getButtonName = () => {
    return `Session ${session} ( ${appointmentDateFromSessionCount(session)} )`
  }

  const changeSessionPeriod = (session: number, period: string) => {
    track({ event: 'FILTER_CHANGE', action: 'CHANGE_SESSION_PERIOD', details: period })
    setSession(session)
  }

  const addTrack = () => {
    track({ event: 'BUTTON_PRESS', action: 'ADD_ACTIVITY' })
    router.navigate(CLIENTS_TRACK_LIBRARY.route, { state: { sessionCount: session } })
  }

  const addActivity = () => {
    track({ event: 'BUTTON_PRESS', action: 'ADD_ACTIVITY' })
    router.navigate(CLIENTS_CONTENT_LIBRARY.route, { state: { sessionCount: session } })
  }

  const getActivitiesToDisplay = (): Assignment[] => {
    /* session: Indicates the current selected session in the provider portal filter
     * currentSessionCount: Indicates the current session number for the client
     */

    // Past Session
    if (session < currentSessionCount) {
      const filteredActivities = activities.filter(
        (assignment: Assignment) =>
          assignment.session_period === session ||
          // filter so that session period 0s/auto assigned activities stay visible in the first session
          (assignment.session_period === 0 && session === 1),
      )
      const assessments = getAssessmentsForSession(session)
      return [...filteredActivities, ...assessments]

      // Current Session
    } else if (session === currentSessionCount) {
      return [...currentActivities, ...getAssessmentsForSession(currentSessionCount)]
    } else {
      return []
    }
  }

  const getFutureEpisodesToDisplay = (): (Assignment | EpisodeContents)[] => {
    // Future Session
    let futureEpisodeActivities: (Assignment | EpisodeContents)[] = []

    if (session > currentSessionCount) {
      const sessionObject = curriculum.find((sessionObject: Curriculum) => sessionObject.session === session)

      if (sessionObject) {
        futureEpisodeActivities = sessionObject.contents.map((content: EpisodeContents) => {
          if (content.id) {
            const activity = activities.find((activity) => activity.id === content.id)
            return activity || content
          } else {
            return content
          }
        })
      }
    }
    return futureEpisodeActivities
  }

  const assignmentClicked = (assignment: Assignment) => {
    const contentGroup: ActivityGroupType = assignment?.content?.group

    switch (contentGroup) {
      case ActivityGroupTypes.assessment:
        if (assignment.response_count === 0) return
        actions.selectAssignment(assignment)
        track({
          event: 'REVIEW_CONTENT',
          details: {
            type: ActivityGroupTypes.assessment,
            title: assignment?.content?.title,
          },
        })
        setShowModal(true)
        break
      case ActivityGroupTypes.lesson:
        actions.selectAssignment(assignment)
        track({
          event: 'REVIEW_CONTENT',
          details: {
            type: ActivityGroupTypes.lesson,
            title: assignment?.content?.title,
          },
        })
        setShowModal(true)
        break
      case ActivityGroupTypes.infosheet:
        actions.selectAssignment(assignment)
        track({
          event: 'REVIEW_CONTENT',
          details: {
            type: ActivityGroupTypes.infosheet,
            title: assignment?.content?.title,
          },
        })
        setShowModal(true)
        break
      default:
        actions.selectAssignment(assignment)
        track({
          event: 'REVIEW_CONTENT',
          details: {
            type: ActivityGroupTypes.exercise,
            title: assignment?.content?.title,
          },
        })

        router.navigate(`${CLIENTS_ASSIGNMENT_DETAILS.route}?assignmentId=${encodeURIComponent(assignment.id)}`, {
          state: { assignment, sessionCount: session },
        })
    }
  }

  const renderModalContent = () => {
    if (isEmpty(selectedAssignment))
      return (
        <div styleName='loading-container'>
          <LoadingIndicator size={45} />
        </div>
      )
    if (isEmpty(supervisor)) {
      const selectedAssignmentResponse = cloneDeep(selectedAssignment?.assignment_responses?.[0])
      if (!isEmpty(selectedAssignmentResponse) && !selectedAssignmentResponse?.viewed) {
        updateResponseThenFetchAssignments({ ...selectedAssignmentResponse, viewed: true })
        selectedAssignmentResponse.viewed = true
      }
    }
    if (selectedAssignment.content?.group === ActivityGroupTypes.lesson) {
      return (
        <Lesson
          metaData={selectedAssignment.content_meta_data}
          instructions={selectedAssignment?.instructions ?? undefined}
          title={selectedAssignment.content?.title}
          response={selectedAssignment.assignment_responses?.[0]}
          userRole='provider'
        />
      )
    } else if (selectedAssignment.content?.group === ActivityGroupTypes.infosheet) {
      const content = { ...selectedAssignment, meta_data: selectedAssignment.content_meta_data }
      return <InfoSheet content={content} />
    } else {
      const content_meta_data = selectedAssignment?.content_meta_data
      const selectedAssignmentResponse = selectedAssignment?.assignment_responses?.[0]
      const response = selectedAssignmentResponse?.response
      const date = selectedAssignmentResponse?.submit_date
      const friendlyDate =
        differenceInMinutes(new Date(), new Date(date ?? 0)) < 1
          ? 'just now'
          : dateUtils.getFriendlyDate(date, false, true)
      const dateDisplay = `Submitted ${friendlyDate}`
      const group = selectedAssignment?.content?.group
      const title = selectedAssignment?.content?.title
      const formContext = {
        externalValues: {
          shouldSendNewWLQ: false,
          shouldShowNewPreferredName: false,
          onShowTopicDetailsPress: false,
        },
      }
      return (
        <>
          <FormContentHeader title={title} group={group} dateDisplay={dateDisplay} />
          <FormBody
            schema={content_meta_data?.schema}
            initialValues={response}
            uiSchema={content_meta_data?.uiSchema}
            instructions={selectedAssignment?.instructions}
            name={selectedAssignment?.content?.name}
            saveForm={() => {}}
            readOnly
            formContext={formContext}
            intl={intl}
            formButton={undefined}
            withPageBreaks={false}
            useCustomizedActivityIntroduction={selectedAssignment?.content_meta_data?.useCustomizedActivityIntroduction}
            hasNewLookAndFeel
          />
        </>
      )
    }
  }

  const { activityActions, notifications } = router.outletContext

  const isLoading = !contents || !clientDetails || !currentSessionCount
  let sessionsComplete
  if (isNumber(currentSessionCount)) {
    sessionsComplete = !currentSessionCount
      ? ''
      : `${currentSessionCount - 1} ${
          programConfig?.recommendedEpisodeLength && selectedEpisode?.program_name !== programs.Coaching
            ? `of ${programConfig.recommendedEpisodeLength} complete`
            : 'complete'
        }`
  }
  if (selectedEpisode?.program_name === programs.SingleSessionCoaching) {
    const duration = getGSCDuration(
      clientDetails?.first_appointment?.startDate,
      programConfig?.recommendedEpisodeLength,
    )
    sessionsComplete = duration
      ? `${duration} ${
          programConfig?.recommendedEpisodeLength ? `of ${programConfig.recommendedEpisodeLength} complete` : 'complete'
        }`
      : '0 complete'
  }
  const nextSession =
    !nextAppointment ||
    nextAppointment?.appointmentStatus === 'canceled' ||
    nextAppointment?.episodeId !== selectedEpisode?.id
      ? 'Not scheduled'
      : dateUtils.getAppointmentDateTimeObject(nextAppointment).tz(moment.tz.guess()).format('MMMM Do, h:mm a')
  const episodeStarted = moment(selectedEpisode?.start_date).format('MMMM Do YYYY')
  const lessonIsSelected = selectedAssignment?.content?.group === ActivityGroupTypes.lesson
  const programName = selectedEpisode?.program_name
  const clientFirstName = clientDetails?.first_name

  return (
    <div>
      <BootstrapContainer col='col-md-10 col-md-offset-1'>
        <div styleName='container'>
          <div styleName='overview-card' data-test-id='Assignments-overview-card'>
            {clientDetails?.country && clientDetails?.country !== 'US' && (
              <div>
                <div styleName='section-title'>Client Time</div>
                <div styleName='section-content'>{`${moment()
                  .tz(clientDetails?.time_zone_full || '')
                  ?.format('h:mm A')} ${clientDetails.time_zone}`}</div>
              </div>
            )}
            <div>
              <div styleName='section-title'>Episode Started</div>
              <div styleName='section-content'>{episodeStarted}</div>
            </div>
            <div styleName='section-title'>{`${programConfig?.sessionLabel}s`}</div>
            <div styleName='section-content'>{sessionsComplete}</div>
            <div styleName='section-title'>Next Session</div>
            <div styleName='section-content'>{nextSession}</div>
            {clientDetails?.self_harm && (
              <div style={{ marginTop: '15px' }}>
                <SelfHarmIndicator />{' '}
              </div>
            )}
            {clientDetails?.eligibility_fields?.nuclear_badge_flag &&
              affirmativeResponses.includes(clientDetails?.eligibility_fields?.nuclear_badge_flag) && (
                <div style={{ marginTop: '15px' }}>
                  <SpecialAlertIndicator alertType='nuclearBadged' tooltipPlacement={TOOLTIP_PLACEMENTS.BOTTOM} />
                </div>
              )}
          </div>
          <div styleName='assignment-list'>
            {isLoading ? (
              <div styleName='loading-container'>
                <LoadingIndicator size={45} />
              </div>
            ) : (
              <div>
                {!isEmpty(notifications) && selectedEpisode?.state === 'in_progress' && (
                  <NotificationBanner
                    // @ts-expect-error TS(2769): No overload matches this call.
                    notifications={notifications}
                    style={{ marginBottom: '10px' }}
                    data-test-id='Assignments-NotificationBanner'
                  />
                )}
                {programName === programs.SingleSessionCoaching && selectedEpisode ? (
                  <>
                    <SSCAssignmentsList
                      data-test-id='assignments-currentActivities'
                      activityActions={activityActions}
                      activities={activities}
                      // @ts-expect-error TS(2322): Type '(() => void) | null' is not assignable to ty... Remove this comment to see the full error message
                      addTrackHandler={
                        selectedEpisode?.state !== 'in_progress' &&
                        episodes.find((ep: Episode) => ep.state === 'in_progress')
                          ? null
                          : addTrack
                      }
                      appointments={appointments}
                      assessments={assessments}
                      currentEpisode={selectedEpisode}
                      itemClickHandler={assignmentClicked}
                      sessionData={{ sessionCount: session }}
                      userRole='provider'
                    />
                  </>
                ) : (
                  <>
                    <h3 styleName='title'>
                      <span>Complete before&nbsp;</span>
                      <DropdownButton
                        styling='inline'
                        dropdownItems={dropdownItems}
                        data-test-id='Assignments-session-dropdown'
                      >
                        {getButtonName()}
                      </DropdownButton>
                    </h3>
                    <AssignmentsList
                      // @ts-expect-error TS(2769): No overload matches this call.
                      addActivityHandler={
                        selectedEpisode?.state !== 'in_progress' &&
                        episodes.find((ep: Episode) => ep.state === 'in_progress')
                          ? null
                          : addActivity
                      }
                      assignments={getActivitiesToDisplay()}
                      futureEpisodes={getFutureEpisodesToDisplay()}
                      itemClickHandler={session <= currentSessionCount ? assignmentClicked : undefined}
                      userRole='provider'
                      itemActions={activityActions}
                      sessionData={{ sessionCount: session }}
                      clientFirstName={clientFirstName}
                    />
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </BootstrapContainer>
      {showModal && (
        <BaseModal
          data-test-id={'Assignments-modal'}
          isOpen={showModal}
          closeModal={onModalClose}
          // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
          modalClass={lessonIsSelected ? 'digital-lesson' : null}
          className={videoSessionOpen ? 'video-session-open' : ''}
          body={renderModalContent()}
        />
      )}
    </div>
  )
}

const mapStateToProps = ($$state: Map<string, any>) => {
  const selectedEpisode = getClientSelectedEpisode($$state)
  const assignments = getClientAssignmentsData($$state).filter(
    (assignment: Assignment) => assignment.episode_id === selectedEpisode?.id,
  )
  const activities = assignments.filter((assignment: Assignment) => assignment.content.group !== 'assessment')
  const assessments = assignments.filter((assignment: Assignment) => assignment.content.group === 'assessment')
  const appointments = getClientAppointmentsData($$state).filter(
    (appt: Appointment) => appt.episodeId === selectedEpisode?.id,
  )

  const currentSessionCount = getClientCurrentSessionCount($$state) || 0
  const currentActivities = activities.filter(
    (assignment: Assignment) =>
      assignment.session_period === currentSessionCount ||
      // filter so that session period 0s/auto assigned activities stay visible in the first session
      (currentSessionCount === 1 && assignment.session_period === 0),
  )

  return {
    assessments,
    currentActivities,
    activities,
    currentSessionCount,
    clientDetails: getClientDetailsData($$state) || {},
    contents: getLyraTherapyContentsData($$state) || [],
    curriculum: getSelectedEpisodeCurriculum($$state) || [],
    user: getAuthUser($$state),
    supervisor: getAuthSupervisor($$state),
    programConfig: getClientEpisodeProgramConfig($$state) || {},
    appointments,
    selectedEpisode,
    nextAppointment: getClientNextAppointment($$state),
    episodes: getClientEpisodesData($$state),
    selectedAssignment: getClientAssignmentDetails($$state),
    videoSessionOpen: getLTVideoSessionOpen($$state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    actions: bindActionCreators(
      { getAssignments, selectAssignment, clearSelectedAssignment, updateAssignmentResponse },
      dispatch,
    ),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(toJS(CSSModules(Assignments, styles))))
