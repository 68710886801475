/* eslint-disable formatjs/no-literal-string-in-jsx */
import React, { FunctionComponent, useMemo } from 'react'

import { CSSObject } from 'styled-components'
import styled, { useTheme } from 'styled-components/native'

import { LoadingIndicator, Subhead, SubheadSize, Table, TableCell, TableHeader, TableRow, TableTitle, tID } from '../..'
import { Accordion } from '../../molecules/accordion/Accordion'

export type PEMetric = {
  assignment_rate: number | null
  feedback_rate: number | null
  engagement_rate: number | null
}

export type PEMetrics = {
  isEmpty: boolean
  quarters: { [name: string]: PEMetric }
  ytd: PEMetric
}

export interface PlatformEngagementPerformanceYearCardProps {
  performanceYear: number
  metrics: PEMetrics | undefined
  initiallyOpen?: boolean
  onAccordianPressed?: (isExpanded: boolean) => void
  banner?: React.ReactNode
}

const convertToPercentage = (num: number | null): string => (num !== null ? Math.round(num * 1000) / 10 + '%' : '-')

export const PlatformEngagementPerformanceYearCard: FunctionComponent<PlatformEngagementPerformanceYearCardProps> = ({
  metrics,
  performanceYear,
  initiallyOpen = false,
  onAccordianPressed,
  banner,
}) => {
  const { colors } = useTheme()
  const PETableHeader = styled(TableHeader)({
    borderBottomWidth: '3px',
    borderBottomColor: colors.borderDefault,
    paddingTop: '16px',
  })
  const PETableRow = styled(TableRow)<{ striped?: boolean }>(({ striped }) => ({
    backgroundColor: striped ? colors.backgroundSecondary : 'inherit',
  }))
  const PETableRowYTD = styled(PETableRow)({
    borderTopWidth: '3px',
    borderTopColor: colors.borderDefault,
  })
  const sharedCellStyle: CSSObject = {
    textAlign: 'center',
    paddingVertical: '16px',
  }
  const PETableTitle = styled(TableTitle)({
    ...sharedCellStyle,
    color: colors.textSecondary,
  })
  const PETableCell = styled(TableCell)<{ isFirst?: boolean }>(({ isFirst }) => ({
    ...sharedCellStyle,
    borderRightWidth: isFirst ? '3px' : 'inherit',
    borderRightColor: colors.borderDefault,
  }))
  const PETableCellBig = styled(PETableCell)({
    paddingVertical: '32px',
  })
  const AccordionStyled = styled(Accordion)({
    padding: '32px',
    border: `1px solid ${colors.borderDefault}`,
    borderRadius: '16px',
  })

  const quarterEntries = useMemo(() => {
    const metricsQuarters = metrics?.quarters ? Object.entries(metrics?.quarters) : []
    if (performanceYear === 2024) {
      metricsQuarters.unshift([
        'Q4 2023',
        {
          assignment_rate: null,
          engagement_rate: null,
          feedback_rate: null,
        },
      ])
    }
    return metricsQuarters
  }, [metrics?.quarters, performanceYear])

  const [firstQuarterName, lastQuarterName] = useMemo(() => {
    if (performanceYear < 2024) {
      return [`Q4 ${performanceYear - 1}`, `Q3 ${performanceYear}`]
    }

    if (performanceYear === 2024) {
      return [`Q4 ${performanceYear - 1}`, `Q4 ${performanceYear}`]
    }

    return [`Q1 ${performanceYear}`, `Q4 ${performanceYear}`]
  }, [performanceYear])
  return (
    <AccordionStyled
      title={`Performance year ${firstQuarterName}-${lastQuarterName}`}
      initiallyOpen={initiallyOpen}
      onAccordianStateChanged={onAccordianPressed}
      titleColor={colors.textPrimary}
      chevronColor={colors.iconActive}
      testIDPrefix='PE'
      content={
        <>
          {!quarterEntries && <LoadingIndicator topPadding={24} />}
          {banner}
          {metrics && quarterEntries && (
            <Table>
              <PETableHeader>
                <PETableTitle>Quarter</PETableTitle>
                <PETableTitle>Assignment Rate</PETableTitle>
                <PETableTitle>Feedback Rate</PETableTitle>
              </PETableHeader>
              {quarterEntries.map(([name, metric], i) => (
                <PETableRow key={name} striped={i % 2 === 0} testID={tID('PEPerfRow')}>
                  <PETableCell testID={tID('PEPerfRowName')} isFirst>
                    {name}
                  </PETableCell>
                  <PETableCell testID={tID('PEPerfRowAssignmentRate')}>
                    {convertToPercentage(metric.assignment_rate)}
                  </PETableCell>
                  <PETableCell testID={tID('PEPerfRowFeedbackRate')}>
                    {convertToPercentage(metric.feedback_rate)}
                  </PETableCell>
                </PETableRow>
              ))}
              <PETableRowYTD>
                <PETableCellBig isFirst>
                  <Subhead size={SubheadSize.SMALL} text='Year to date' />
                </PETableCellBig>
                <PETableCellBig testID={tID('PEPerfRowYTDAssignmentRate')}>
                  {convertToPercentage(metrics.ytd.assignment_rate)}
                </PETableCellBig>
                <PETableCellBig testID={tID('PEPerfRowYTDFeedbackRate')}>
                  {convertToPercentage(metrics.ytd.feedback_rate)}
                </PETableCellBig>
              </PETableRowYTD>
            </Table>
          )}
        </>
      }
    />
  )
}
