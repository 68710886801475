import React, { FunctionComponent } from 'react'
import LottieView from 'lottie-react-native'
import spinnerAnimation from '../../assets/lottie/loadingspinner.json'
import styled from 'styled-components/native'

const Container = styled.View<{ size: number | string }>(({ size }) => ({
  height: `${size}px`,
  width: `${size}px`,
}))

export interface LoadingSpinnerProps {
  size?: string | number
}

export const LoadingSpinner: FunctionComponent<LoadingSpinnerProps> = ({ size = 24 }) => {
  return (
    <Container size={size}>
      <LottieView source={spinnerAnimation} loop autoPlay style={{ width: size, height: size }} />
    </Container>
  )
}
