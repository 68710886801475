import React, { FunctionComponent, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { ListRenderItem, ViewStyle } from 'react-native'

import { isEmpty, noop } from 'lodash-es'
import styled from 'styled-components/native'

import { BodyText } from '../../atoms'
import { WordCloudButton } from '../../atoms/wordCloudButton/WordCloudButton'
import { IS_WEB } from '../../constants'
import { tID } from '../../utils/utils'

export interface TimeSlotListProps {
  datetimes: Array<string | null>
  style?: ViewStyle
  onPress?: (dateTime: string) => void
  shouldShowSelected?: boolean
  shouldShowEmptyTimeSlots?: boolean
  timeZone?: string
}

const WordCloudButtonContainer = styled(WordCloudButton)<{ isLast: boolean }>(({ isLast }) => ({
  width: '100%',
  marginBottom: !isLast ? '12px' : 0,
}))

const TimeSlotListContainer = styled.View({
  margin: '0 16px',
  width: '100%',
  maxWidth: '343px',
  alignSelf: 'center',
})

const TimeSlotFlatList = styled.FlatList({
  ...(IS_WEB && { overflowX: 'visible' }),
  ...(IS_WEB && { overflowY: 'visible' }),
})

const EmptyTimeSlot = styled(BodyText)<{ isLast: boolean }>(({ isLast }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '48px',
  marginBottom: !isLast ? '12px' : 0,
}))

export const TimeSlotList: FunctionComponent<TimeSlotListProps> = ({
  datetimes,
  onPress = noop,
  style,
  shouldShowSelected = true,
  shouldShowEmptyTimeSlots = false,
  timeZone,
}) => {
  const intl = useIntl()
  const [selected, setSelected] = useState<number | null>(null)

  useEffect(() => {
    setSelected(null)
  }, [datetimes])

  if (isEmpty(datetimes)) {
    return <></>
  }

  const datesTimesToDisplay = datetimes.reduce<Array<{ value: string | null; formatted: string }>>((acc, next) => {
    if (next) {
      acc.push({
        value: next,
        formatted: intl.formatDate(next, { hour: 'numeric', minute: 'numeric', timeZone }),
      })
    } else if (shouldShowEmptyTimeSlots) {
      acc.push({ value: next, formatted: '-' })
    }
    return acc
  }, [])
  const renderItem: ListRenderItem<{ value: string | null; formatted: string }> = ({
    item: { value, formatted },
    index,
  }) => {
    if (formatted === '-') {
      // eslint-disable-next-line formatjs/no-literal-string-in-jsx
      return <EmptyTimeSlot testID={tID(`TimeSlot -`)} isLast={index === datetimes.length - 1} text='–' />
    }
    return (
      <WordCloudButtonContainer
        isSelected={index === selected}
        text={formatted}
        onPress={() => {
          onPress(value)
          if (shouldShowSelected) {
            setSelected(index)
          }
        }}
        isLast={index === datetimes.length - 1}
        testID={tID(`TimeSlot ${formatted}`)}
        suppressAccessibilitySelected={true}
      />
    )
  }
  return (
    <TimeSlotListContainer testID={tID(`TimeSlotList`)} style={style}>
      <TimeSlotFlatList
        data={datesTimesToDisplay}
        renderItem={renderItem}
        keyExtractor={(_, index) => `Timeslot-${index}`}
      />
    </TimeSlotListContainer>
  )
}
