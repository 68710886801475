import React, { FunctionComponent } from 'react'
import { Path, Svg } from 'react-native-svg'

import { PrimaryNeedsWords } from '@lyrahealth-inc/shared-app-logic'

import { tID } from '../../utils'

type ProblemsFocusingIllustrationProps = {
  size?: string | number
  selected?: string
}

export const ProblemsFocusingIllustration: FunctionComponent<ProblemsFocusingIllustrationProps> = ({
  selected = false,
  size,
}) => (
  <Svg
    width={size}
    height={size}
    viewBox='0 0 48 48'
    fill='none'
    testID={tID(`${PrimaryNeedsWords.ADHD}${selected ? '-Selected-' : '-'}Illustration`)}
  >
    <Path
      d='M23.9361 26.0156L6.1599 22.3246C5.83157 22.2564 5.51017 22.4673 5.44199 22.7957L1.75102 40.5718C1.68285 40.9002 1.89372 41.2216 2.22205 41.2897L19.9982 44.9807C20.3265 45.0489 20.648 44.838 20.7162 44.5097L24.4071 26.7335C24.4753 26.4052 24.2644 26.0838 23.9361 26.0156Z'
      fill={selected ? '#3BA5B5' : '#85CCD5'}
    />
    <Path
      d='M18.701 15.906L0.605156 17.3747C0.270928 17.4018 0.0219902 17.6948 0.0491164 18.029L1.51779 36.1248C1.54491 36.459 1.83783 36.708 2.17206 36.6809L20.2679 35.2122C20.6021 35.1851 20.8511 34.8921 20.824 34.5579L19.3553 16.4621C19.3282 16.1279 19.0352 15.8789 18.701 15.906Z'
      fill={selected ? '#85CCD5' : '#FFDC69'}
    />
    <Path
      d='M35.0873 4.84719L17.0202 3.05977C16.6865 3.02676 16.3893 3.27052 16.3562 3.60421L14.5688 21.6713C14.5358 22.005 14.7795 22.3023 15.1132 22.3353L33.1804 24.1227C33.5141 24.1557 33.8113 23.912 33.8443 23.5783L35.6317 5.51118C35.6648 5.17748 35.421 4.8802 35.0873 4.84719Z'
      fill={selected ? '#3BA5B5' : '#FFAF9B'}
    />
    <Path
      d='M43.1491 16.9474L25.438 20.9388C25.1109 21.0125 24.9055 21.3375 24.9792 21.6646L28.9706 39.3757C29.0443 39.7028 29.3692 39.9083 29.6963 39.8345L47.4075 35.8432C47.7346 35.7694 47.9401 35.4445 47.8663 35.1174L43.875 17.4062C43.8012 17.0791 43.4763 16.8737 43.1491 16.9474Z'
      fill={selected ? '#85CCD5' : '#C2E599'}
    />
    <Path
      d='M3.99108 27.8228C4.0199 28.6394 5.28805 28.5587 5.25923 27.7421C5.18813 25.7535 6.37365 23.8801 8.29313 23.248C10.1876 22.6235 12.6259 23.3018 13.4079 25.2789C14.1611 27.183 12.9256 29.5021 10.8236 29.7135C9.8994 29.8057 8.8849 29.4291 8.56018 28.4953C8.43337 28.1302 8.40838 27.7037 8.54864 27.3367C8.65432 27.06 9.24229 26.3587 9.52857 26.8352C9.95128 27.5365 11.0119 26.8391 10.5911 26.1377C10.0704 25.2731 8.91951 25.2635 8.15479 25.8169C7.3747 26.3818 7.08648 27.4308 7.22482 28.3493C7.58604 30.7453 10.3529 31.535 12.3396 30.5819C14.5742 29.5098 15.5195 26.766 14.4646 24.5372C13.4425 22.3756 10.8678 21.4092 8.60437 21.8607C5.78183 22.4237 3.89117 24.9811 3.99108 27.8247V27.8228Z'
      fill='#3BA5B5'
    />
    <Path
      d='M43.6751 31.346C41.8574 28.2256 40.1474 25.0361 38.5507 21.7851C38.3125 21.3028 37.6227 21.4162 37.444 21.9157C36.22 25.3089 35.096 28.7328 34.0661 32.1837C33.3898 29.5898 32.7135 26.9978 32.0371 24.4039C31.8411 23.6546 30.6653 23.9466 30.8632 24.7017C31.722 27.9931 32.5809 31.2864 33.4398 34.5777C33.5454 34.9832 34.0181 35.1369 34.3352 34.9467C34.4773 35.0139 34.6483 35.0293 34.8309 34.9543C37.5823 33.8438 40.3991 32.9407 43.2581 32.2336C43.6155 32.1453 43.8711 31.6803 43.677 31.346H43.6751ZM34.8655 33.6593C35.8492 30.2853 36.9252 26.9383 38.0915 23.6181C39.3923 26.2062 40.7642 28.7521 42.2052 31.2556C39.7189 31.907 37.2711 32.7121 34.8635 33.6593H34.8655Z'
      fill='#3BA5B5'
    />
  </Svg>
)
