import { createContext } from 'react'

import { DisplayMode, RemoteParticipantStatus, VideoCallEffect, Zoom, ZoomNetworkQuality } from './types'

export const ZoomContext = createContext<Zoom>({
  muted: true,
  videoOff: true,
  toggleVideo: () => {},
  toggleMute: () => {},
  userId: undefined,
  inSession: false,
  cameraList: [],
  joinSession: () => Promise.resolve(),
  leaveSession: () => {},
  remoteParticipants: [],
  setSelfElement: () => {},
  sharingUserId: null,
  startScreenshare: () => {},
  stopScreenshare: () => {},
  isScreensharing: false,
  setScreenshareElement: () => {},
  videoEffect: VideoCallEffect.NONE,
  setVideoEffect: () => {},
  remoteParticipantStatus: RemoteParticipantStatus.WAITING,
  startSession: () => {},
  sessionStarted: false,
  startAudio: () => {},
  remoteParticipant: null,
  setSessionStarted: () => {},
  displayMode: DisplayMode.BANNER,
  setDisplayMode: () => {},
  setParticipantElement: () => {},
  setPreviewVideoElement: () => {},
  setPreviewCanvasElement: () => {},
  isPreviewVBMode: false,
  setSessionEndedCallback: () => {},
  setSessionClosedCallback: () => {},
  remoteParticipantScreenSharing: false,
  updateSettings: () => {},
  settings: {},
  setParticipantScreenshareElement: () => {},
  stream: null,
  setCameraRef: () => {},
  setAddToast: () => {},
  joinTime: null,
  userIdentity: undefined,
  supportsVirtualBackground: false,
  setAttached: () => {},
  showPermissionsModal: false,
  closePermissionsModal: () => {},
  networkQuality: ZoomNetworkQuality.NONE,
  config: {
    canScreenShare: false,
    showMessenger: false,
    canStartSession: false,
    showRemoteParticipantStatus: false,
    defaultDisplayMode: DisplayMode.BANNER,
    canChangeDisplayMode: false,
    shouldEndSessionOnLeave: false,
    shouldJoinOnOpen: false,
    shouldNotifyOnParticipantReady: false,
    shouldStopSessionOnParticipantLeave: false,
  },
})
