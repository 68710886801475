import { SET_CLIENT_DETAILS, CLEAR_CLIENT_DETAILS } from '../../../common/constants/reduxConstants'

export const clientSelected = (data: any) => ({
  type: SET_CLIENT_DETAILS,
  data,
})

export const getClientDetails = ({ clientLyraId, cancelToken }: any) => ({
  action: SET_CLIENT_DETAILS,
  request: {
    method: 'get',
    url: `/v1/clients/${clientLyraId}`,
    cancelToken,
  },
})

export const clearClientSelected = () => ({ type: CLEAR_CLIENT_DETAILS })
