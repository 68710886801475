import React from 'react'
import Tooltip from '../tooltip/Tooltip'
import colors from '../../../styles/_0colors.scss'

const BlendedCareTherapyIcon: React.FC<BlendedCareTherapyIconProps> = ({
  width = 20,
  showTooltip = true,
  tooltip = 'Blended Care Therapy',
  ...props
}) => {
  const icon = (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={width}
      viewBox='0 0 44 44'
      fill='none'
      id='BlendedCareTherapyIcon'
      {...props}
    >
      <path d='m0 0h44v44h-44z' fill='#fff' />
      <rect fill={colors.x_navy1} height='44' rx='4' width='44' />
      <path d='m31.0226 10.6v4.672h-6.048v17.728h-5.056v-17.728h-6.016v-4.672z' fill={colors.x_navy5} />
    </svg>
  )
  return showTooltip ? (
    <Tooltip content={tooltip} delayHide={0} placement='bottom'>
      {icon}
    </Tooltip>
  ) : (
    icon
  )
}

type BlendedCareTherapyIconProps = {
  showTooltip: string
  width?: number
  tooltip?: string
}

export default BlendedCareTherapyIcon
