import React, { FunctionComponent, useMemo, useState } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import { createPortal } from 'react-dom'
import 'react-datepicker/dist/react-datepicker.min.css'
import { FieldInputProps, FieldRenderProps } from 'react-final-form'
import { createIntl, createIntlCache, useIntl } from 'react-intl'

import { match } from '@formatjs/intl-localematcher'
import { isValid, parse, toDate } from 'date-fns'
import * as allLocales from 'date-fns/locale'
import { get, isEmpty } from 'lodash-es'
import styled, { createGlobalStyle } from 'styled-components'

import { BaseInput } from './BaseInput'
import { colors } from '../../styles/colors'
import { moderatRegular } from '../../styles/typeStyles'

const stringLocaleToDateLocaleMapper = {
  'en-US': allLocales.enUS,
  'en-GB': allLocales.enGB,
  'en-CA': allLocales.enCA,
  'fr-CA': allLocales.frCA,
  'en-AU': allLocales.enAU,
  'en-IE': allLocales.enIE,
  de: allLocales.de,
  'pt-BR': allLocales.ptBR,
  pt: allLocales.pt,
  pl: allLocales.pl,
  ja: allLocales.ja,
  ko: allLocales.ko,
  nl: allLocales.nl,
  bg: allLocales.bg,
  cs: allLocales.cs,
  th: allLocales.th,
  hu: allLocales.hu,
  id: allLocales.id,
  ro: allLocales.ro,
  tr: allLocales.tr,
  vi: allLocales.vi,
  hi: allLocales.hi,
  it: allLocales.it,
  ms: allLocales.ms,
  fr: allLocales.fr,
  es: allLocales.es,
  'zh-CN': allLocales.zhCN,
  'zh-HK': allLocales.zhHK,
  'zh-TW': allLocales.zhTW,
}

const stringLocaleToDateLocale = (locale?: string): Locale => {
  // Default to en-US if no locale provided
  if (!locale) return stringLocaleToDateLocaleMapper['en-US']
  else {
    const key = match([locale], Object.keys(stringLocaleToDateLocaleMapper), 'en-US')
    return stringLocaleToDateLocaleMapper[key]
  }
}

const bodyDefault = { fontSize: 16, lineHeight: 24, ...moderatRegular }

const DatePickerContainer = styled(DatePicker)<{ active?: boolean; error?: string }>(({ active, error }) => ({
  ...bodyDefault,
  boxSizing: 'border-box',
  lineHeight: `${bodyDefault.lineHeight}px`,
  width: '100%',
  marginBottom: '0px',
  border: `1px solid ${colors.semiDarkGray}`,
  borderRadius: '4px',
  height: 'auto',
  padding: '11px 15px',
  ...(active && {
    border: `2px solid ${colors.teal5}`,
    padding: '10px 14px',
    outline: 'none',
  }),
  ...(error && {
    borderBolor: colors.red4,
  }),
}))

export const DatePickerGlobalStyles = createGlobalStyle`
  div.react-datepicker-wrapper {
    display: block;
    .react-datepicker__input-container {
      display: block;
      font-size: 16px;
      .react-datepicker__close-icon {
        &:after {
          background-color: ${colors.charcoal4};
        }
        &:focus:after {
          box-shadow: 0 0 0 4px ${colors.white}, 0 0 0 6px ${colors.teal4};
        }
      }
    }
  }
  div.react-datepicker-popper {
    z-index: 220;
    .react-datepicker {
      font-size: 1em;
    }
    .react-datepicker__header {
      padding-top: 0.8em;
    }
    .react-datepicker__header__dropdown {
      display: flex;
      margin: 8px 0;
      padding: 0 10px;
    }
    .react-datepicker__month {
      margin: 0.4em 1em;
    }
    .react-datepicker__month-dropdown-container {
      margin-right: 10px;
      flex-basis: 55%;
    }
    .react-datepicker__year-dropdown-container {
      flex-grow: 1;
    }
    .react-datepicker__year-dropdown-container select, .react-datepicker__month-dropdown-container select {
      font-family: 'moderat', sans-serif;
      color: ${colors.charcoal6};
      border-radius: 3px;
      height: 46px;
      caret-color: ${colors.teal5};
      padding: 0 35px 0 15px;
      outline: none;
      width: 100%;
      appearance: none;
      background-repeat: no-repeat;
      background-position: center right 10px;
      background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTRweCIgaGVpZ2h0PSI4cHgiIHZpZXdCb3g9IjAgMCAxNCA4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPHRpdGxlPmNoZXZEb3duIENvcHk8L3RpdGxlPgogICAgPGcgaWQ9ImNoZXZEb3duLUNvcHkiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxwYXRoIGQ9Ik05LjIyODczOTgxLDEwLjkwOTA3NSBDOS4wMjE5NTQ2NywxMC45MDkwNzUgOC44MTUxNjk1MywxMC44MjY1NTE1IDguNjU3OTI2NjcsMTAuNjYwMzczOSBMMy4yNzI4OTcwNSw1LjAwODA3NzE1IEMyLjk1NzMzNDMyLDQuNjc2ODUyNTYgMi45NTczMzQzMiw0LjE0MTAxNDgyIDMuMjcyODk3MDUsMy44MDk3OTAyMyBMOC42NTc5MjY2NywtMS44NDI1MDY1NiBDOC45NzM0ODk0LC0yLjE3MzczMTE1IDkuNDgzOTkwMjEsLTIuMTczNzMxMTUgOS43OTk1NTI5NSwtMS44NDI1MDY1NiBDMTAuMTE1MTE1NywtMS41MTEyODE5NiAxMC4xMTUxMTU3LC0wLjk3NTQ0NDIyOSA5Ljc5OTU1Mjk1LC0wLjY0NDIxOTYzNyBMNC45ODY0MTM0OCw0LjQwODkzMzY5IEw5Ljc5OTU1Mjk1LDkuNDYyMDg3MDIgQzEwLjExNTExNTcsOS43OTMzMTE2MSAxMC4xMTUxMTU3LDEwLjMyOTE0OTMgOS43OTk1NTI5NSwxMC42NjAzNzM5IEM5LjY0MjMxMDA4LDEwLjgyNjU1MTUgOS40MzU1MjQ5NSwxMC45MDkwNzUgOS4yMjg3Mzk4MSwxMC45MDkwNzUiIGlkPSJGaWxsLTQ3IiBmaWxsPSIjNjg3MDc4IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg2LjUzNjIyNSwgNC40MDkwNzUpIHJvdGF0ZSgtOTAuMDAwMDAwKSB0cmFuc2xhdGUoLTYuNTM2MjI1LCAtNC40MDkwNzUpICI+PC9wYXRoPgogICAgPC9nPgo8L3N2Zz4=');
    }
    .react-datepicker__month-select, .react-datepicker__year-select {
      &:focus {
        box-shadow: 0 0 0 1px ${colors.teal5}
      }
    }
    .react-datepicker__day-name, .react-datepicker__day {
      width: 1.9em;
      line-height: 1.9em;
      margin: 0.166em;
    }
    .react-datepicker__day {
      &.react-datepicker__day--keyboard-selected, &.react-datepicker__day--selected {
        background-color: ${colors.teal5};
        &:hover, &:focus {
          background-color: ${colors.teal6};
        }
        &:focus {
          outline: 0;
          box-shadow: 0 0 0 4px ${colors.white}, 0 0 0 6px ${colors.teal4};
        }
      }
    }
    .react-datepicker__current-month {
      font-size: 1em;
    }
    .react-datepicker__navigation {
      top: 1em;
      line-height: 1.7em;
      border: 0.45em solid transparent;
      border-radius: 2px;

      &:focus {
        outline: 0;
        box-shadow: 0 0 0 2px ${colors.teal4};
      }
    }
    .react-datepicker__navigation-icon::before {
      border-color: ${colors.charcoal5}
    }
    .react-datepicker__navigation-icon--previous {
      margin-left: 22px;
      margin-bottom: 10px;
    }
    .react-datepicker__navigation-icon--next {
      margin-right: 22px;
      margin-bottom: 10px;
    }
    div.react-datepicker__triangle {
      left: -10px !important;
    }
  }
`

const cache = createIntlCache()

export const DateField: FunctionComponent<DateInputFieldProps> = ({
  appendFormat = true,
  disabled,
  dropdownMode = 'select',
  error,
  input,
  isClearable = true,
  label,
  maxDate,
  minDate,
  readOnly,
  showMonthDropdown = true,
  showYearDropdown = true,
  submitError,
  locale,
  ...props
}) => {
  const intl = useIntl()
  const [focused, setFocused] = useState(false)
  const [value, setValue] = useState<Date | null | undefined>(null)
  const [datePlaceholder, setDatePlaceholder] = useState<string>('__/__/____')
  const [dateFormat, setDateFormat] = useState<string>('MM/dd/yyyy')
  // This will set the locale for the date picker library
  useMemo(() => {
    const dateLocale = stringLocaleToDateLocale(locale)
    locale && registerLocale(locale, dateLocale)
    const dateFormatStr = dateLocale.formatLong?.date({ width: 'short' })
    setDateFormat(dateFormatStr)
    setDatePlaceholder(dateFormatStr.replace(/[a-zA-Z]/g, '_'))
  }, [locale])

  const handleChange = (date: Date | null) => {
    let intlOverride = intl
    if (!isEmpty(locale)) {
      intlOverride = createIntl({ locale }, cache)
    }
    if (date && isValid(date)) {
      const formattedDate = intlOverride.formatDate(date, { year: 'numeric', month: '2-digit', day: '2-digit' })
      setValue(date)
      input.onChange(formattedDate)
    }
  }

  // Modify selected days label to indicate that it is selected
  const calendarOpen = () => {
    window.requestAnimationFrame(() => {
      const monthSelect = document.querySelector('.react-datepicker__month-select')
      const yearSelect = document.querySelector('.react-datepicker__year-select')
      monthSelect && monthSelect.setAttribute('aria-label', 'Month')
      yearSelect && yearSelect.setAttribute('aria-label', 'Year')
      const selectedDateElement = document.querySelector('[tabindex="0"].react-datepicker__day')
      if (selectedDateElement) {
        const newSelectedDateLabel = selectedDateElement.getAttribute('aria-label')?.replace('Choose', 'Chosen')
        selectedDateElement.setAttribute('aria-label', `${newSelectedDateLabel}`)
      }
    })
  }

  const inputValue = get(input, 'value')
  const parsedDate = parse(inputValue, dateFormat, new Date())
  const inputValueAsDateOrNull = isValid(parsedDate) ? parsedDate : null
  const fieldInfoId = `${input.name}_info`
  const formattedLabel = appendFormat ? `${label} (${dateFormat.toUpperCase()})` : label
  return (
    <BaseInput error={error || submitError} label={formattedLabel} name={input.name} readOnly={readOnly}>
      {readOnly ? (
        <p style={{ margin: 0 }}>{input.value}</p>
      ) : (
        <>
          <DatePickerContainer
            active={focused}
            ariaLabelClose='Clear'
            autoComplete={'off'}
            customInput={<input type='text' aria-describedby={fieldInfoId} />}
            disabled={readOnly ? true : disabled}
            dropdownMode={dropdownMode}
            error={error || submitError}
            id={`DateField-${input.name}`}
            isClearable={readOnly ? false : isClearable}
            name={input.name}
            maxDate={maxDate ? toDate(maxDate) : null}
            minDate={minDate ? toDate(minDate) : minDate}
            onBlur={() => setFocused(false)}
            onCalendarOpen={calendarOpen}
            onCalendarClose={() => setFocused(false)}
            onChange={handleChange}
            onFocus={() => setFocused(true)}
            placeholderText={datePlaceholder}
            popperContainer={({ children }: { children: React.ReactNode }) => createPortal(children, document.body)}
            showMonthDropdown={showMonthDropdown}
            showYearDropdown={showYearDropdown}
            selected={value || inputValueAsDateOrNull}
            locale={locale}
            dateFormat={'P'}
            {...props}
          />
          <DatePickerGlobalStyles />
        </>
      )}
    </BaseInput>
  )
}

export const DateFieldRFF: FunctionComponent<FieldRenderProps<string>> = ({
  appendFormat,
  disabled,
  dropdownMode,
  input,
  isClearable,
  label,
  maxDate,
  meta: { error, submitError, touched },
  minDate,
  readOnly,
  showMonthDropdown,
  showYearDropdown,
  locale,
  ...props
}) => {
  return (
    <DateField
      appendFormat={appendFormat}
      disabled={disabled}
      dropdownMode={dropdownMode}
      error={touched && error}
      input={input}
      isClearable={isClearable}
      label={label}
      maxDate={maxDate}
      minDate={minDate}
      readOnly={readOnly}
      showMonthDropdown={showMonthDropdown}
      showYearDropdown={showYearDropdown}
      submitError={submitError}
      locale={locale}
      {...props}
    />
  )
}

export interface DateInputFieldProps {
  appendFormat: boolean
  disabled: boolean
  dropdownMode: 'scroll' | 'select'
  error: string
  input: FieldInputProps<any, HTMLElement>
  isClearable?: boolean
  label: string
  maxDate: Date | null
  minDate: Date | null
  readOnly: boolean
  showMonthDropdown?: boolean
  showYearDropdown?: boolean
  submitError: string
  locale: string
}
