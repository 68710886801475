import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

import { colors } from '../../styles/colors'

export interface HeadphoneHeartIconProps {
  size?: number
  fill?: string
}

export const HeadphoneHeartIcon: FunctionComponent<HeadphoneHeartIconProps> = ({
  size = 24,
  fill = colors.ui_oatmeal5,
}) => {
  return (
    <Svg width={size} height={size} viewBox='8 8 24 24' fill='none'>
      <Path
        d='M19.7884 24.3776C19.8684 24.4076 19.9584 24.4276 20.0384 24.4276C20.1184 24.4276 20.2084 24.4076 20.2884 24.3776C20.3684 24.3476 20.4384 24.2876 20.4984 24.2276L24.1884 20.4576C24.4384 20.1976 24.6384 19.8876 24.7684 19.5476C24.8984 19.2076 24.9584 18.8476 24.9484 18.4776C24.9384 18.1076 24.8584 17.7476 24.7084 17.4176C24.5584 17.0876 24.3484 16.7876 24.0784 16.5476C23.5484 16.0676 22.8484 15.8176 22.1384 15.8676C21.4284 15.9076 20.7684 16.2376 20.2884 16.7776V16.7576L20.0384 17.0776L19.7084 16.7376C19.2284 16.2276 18.5784 15.9276 17.8984 15.8876C17.2084 15.8476 16.5384 16.0776 16.0084 16.5376C15.7484 16.7876 15.5384 17.0776 15.3884 17.4176C15.2384 17.7476 15.1584 18.1076 15.1484 18.4776C15.1484 18.8376 15.1984 19.2076 15.3284 19.5476C15.4584 19.8876 15.6584 20.1976 15.9084 20.4576L19.5784 24.2276C19.6384 24.2876 19.7084 24.3376 19.7884 24.3776Z'
        fill={colors.secondary_peach4}
      />
      <Path
        d='M28.8947 16.7H27.9747C27.6547 12.6 24.2247 10 20.0547 10C15.8847 10 12.4347 12.6 12.1247 16.7H11.2047C10.0147 16.7 9.05469 17.66 9.05469 18.85V22.27C9.05469 23.46 10.0147 24.42 11.2047 24.42H13.0247C13.3247 24.42 13.5747 24.18 13.5747 23.87V22.74V17.32C13.5747 13.74 16.4847 11.48 20.0547 11.48C23.6247 11.48 26.5347 13.75 26.5347 17.32V22.74V23.87C26.5347 24.17 26.7747 24.42 27.0847 24.42H27.9247C27.8347 26.46 26.1547 28.09 24.0947 28.09H22.0547C21.8247 27.67 21.3847 27.39 20.8747 27.39H19.2347C18.4947 27.39 17.8847 28 17.8847 28.74V28.94C17.8847 29.68 18.4947 30.29 19.2347 30.29H20.8747C21.3847 30.29 21.8247 30 22.0547 29.59H24.0947C27.0047 29.59 29.3747 27.24 29.4247 24.34C30.3547 24.1 31.0447 23.27 31.0447 22.27V18.85C31.0447 17.66 30.0847 16.7 28.8947 16.7Z'
        fill={fill}
      />
    </Svg>
  )
}
