import React, { FormEventHandler } from 'react'
import { Field, reduxForm } from 'redux-form/immutable'
import styles from './addPracticeView.module.scss'
import { PrimaryButton, TypeAheadField, InputField } from '@lyrahealth-inc/ui-core'
import addPracticeValidate from './addPracticeValidate'

const AddPracticeView: React.FC<AddPracticeViewProps> = ({
  handleSubmit,
  submitting,
  availablePractices,
  submitAddPractice,
}) => {
  // this is passed to a reduxForm Field, which expects an array of objects that contain a string,
  //  but the API end point returns it as an array with objects that look like { name: 'something' }
  //  [{ name: 'something', ... }] => [{ 'something' }, ...]
  const mappedAvailablePractices = availablePractices.map((item) => item.name)
  if (mappedAvailablePractices.length !== 0) {
    mappedAvailablePractices.sort()
  }

  return (
    <div className={styles['modal-content']}>
      <h2>Add A New Practice</h2>
      <p>
        An invitation email will be sent to the address entered below with instructions on how to log in and enter
        payment information.
      </p>
      <form onSubmit={handleSubmit(submitAddPractice)}>
        <div className={styles.section}>
          <div className='row'>
            <div className='col-xs-12 col-sm-6'>
              {mappedAvailablePractices.length !== 0 ? (
                <Field
                  name='name'
                  component={TypeAheadField}
                  multiSelect={false}
                  forceMatch
                  options={mappedAvailablePractices}
                  label='Practice Name'
                  placeholder='Select practice'
                />
              ) : (
                <Field
                  name='name'
                  type='text'
                  component={InputField}
                  label='Practice Name'
                  placeholder='Type practice name...'
                />
              )}
            </div>
            <div className='col-xs-12 col-sm-6'>
              <Field
                name='email'
                type='text'
                component={InputField}
                label='Email'
                placeholder='Practice Email Address'
              />
            </div>
          </div>
          <PrimaryButton type='submit' isLoading={submitting}>
            Add Practice
          </PrimaryButton>
        </div>
      </form>
    </div>
  )
}

type AvailablePractice = {
  name: string
}

type AddPracticeViewProps = {
  handleSubmit: (submitAddPractice: () => void) => FormEventHandler<HTMLFormElement>
  submitting: boolean
  availablePractices: AvailablePractice[]
  submitAddPractice: () => void
}

export default reduxForm({
  form: 'addPracticeForm',
  validate: addPracticeValidate,
  touchOnBlur: false,
  // @ts-expect-error TS(2345): Argument of type 'FC<AddPracticeViewProps>' is not... Remove this comment to see the full error message
})(AddPracticeView)
