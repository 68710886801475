import React, { FunctionComponent, useMemo } from 'react'
import { FormattedDateTimeRange, FormattedMessage, useIntl } from 'react-intl'

import { addMinutes } from 'date-fns'
import styled from 'styled-components/native'

import {
  Avatar,
  avatarAltTextGeneric,
  AvatarDetails,
  BodyText,
  RecordingBadge,
  Subhead,
  VideoIcon,
  VideoSessionIcon,
} from '../../atoms'
import { BodyTextSize, SubheadSize } from '../../styles'
import { RowView } from '../../templates'

const Container = styled.View({
  flexBasis: 0,
  flexGrow: 1,
})

const ContainerWithAvatar = styled(Container)(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.spacing['12px'],
  alignItems: 'center',
}))

const SessionTimeRow = styled(RowView)(({ theme }) => ({
  alignItems: 'center',
  gap: theme.spacing['8px'],
}))

const AvatarContainer = styled.View({
  position: 'relative',
  alignSelf: 'flex-start',
})

const IconContainer = styled.View({
  position: 'absolute',
  bottom: '-4px',
  right: '-4px',
})

const SessionDetails = styled.View(({ theme }) => ({
  gap: theme.spacing['4px'],
}))

export type ZoomVideoBannerInfoProps = {
  participantName?: string
  sessionStartDate: Date
  sessionDuration: number
  showRecordingStatus?: boolean
  isRecording?: boolean
  sessionNumber?: number
  avatarDetails?: AvatarDetails
}

export const ZoomVideoBannerInfo: FunctionComponent<ZoomVideoBannerInfoProps> = ({
  participantName,
  sessionStartDate,
  sessionDuration,
  showRecordingStatus = false,
  isRecording = false,
  avatarDetails,
  sessionNumber,
}) => {
  const { formatMessage } = useIntl()
  const sessionEndDate = useMemo(
    () => addMinutes(sessionStartDate, sessionDuration),
    [sessionStartDate, sessionDuration],
  )

  const timeRange = (
    <FormattedDateTimeRange
      from={sessionStartDate}
      to={sessionEndDate}
      hour='numeric'
      minute='numeric'
      timeZoneName='short'
    />
  )

  if (avatarDetails) {
    return (
      <ContainerWithAvatar>
        <AvatarContainer>
          {avatarDetails && (
            <Avatar
              details={avatarDetails}
              size={60}
              accessibilityLabel={formatMessage(avatarAltTextGeneric, { name: avatarDetails.displayName })}
            />
          )}
          <IconContainer>
            <VideoSessionIcon />
          </IconContainer>
        </AvatarContainer>
        <SessionDetails>
          <Subhead
            text={
              <FormattedMessage
                defaultMessage='{participantName} • Session {sessionNumber}'
                description='Title of video session with participant name and session number'
                values={{ participantName, sessionNumber }}
              />
            }
            size={SubheadSize.XSMALL}
          />
          <BodyText text={timeRange} size={BodyTextSize.SMALL} />
        </SessionDetails>
      </ContainerWithAvatar>
    )
  }

  const titleText = participantName ?? (
    <FormattedMessage defaultMessage='Next Session' description='Text for title of next session' />
  )
  return (
    <Container>
      <BodyText text={titleText} size={BodyTextSize.LARGE} />
      <SessionTimeRow>
        <VideoIcon isFilled size={18} />
        <BodyText text={timeRange} />
        {showRecordingStatus && <RecordingBadge isRecording={isRecording} />}
      </SessionTimeRow>
    </Container>
  )
}
