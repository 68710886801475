import { createReducer } from '@reduxjs/toolkit'

import { ReconcilerClient } from '@lyrahealth-inc/shared-app-logic'

import {
  CLEAR_RECONCILER_DETAILS_STORE,
  GET_POSSIBLE_MATCHES,
  UNRECONCILED_CLIENT_SELECTED,
} from '../../../common/constants/reduxConstants'

type ReconcilerDetailsState = {
  data?: ReconcilerClient
  possibleMatches?: { identity: ReconcilerClient; score: number }[]
}

const initialState = {}

export default createReducer<ReconcilerDetailsState>(initialState, (builder) => {
  builder.addCase(UNRECONCILED_CLIENT_SELECTED, (state, action: any) => {
    state.data = action.payload
    return state
  })
  builder.addCase(`${GET_POSSIBLE_MATCHES}_FULFILLED`, (state, action: any) => {
    state.possibleMatches = action.payload.data.matches
    return state
  })
  builder.addCase(CLEAR_RECONCILER_DETAILS_STORE, () => {
    return initialState
  })
})
