import React from 'react'
import CSSModules from 'react-css-modules'
import { DefaultButton } from '@lyrahealth-inc/ui-core'
import PropTypes from 'prop-types'
import styles from './configTrackContainer.module.scss'

export const ElectiveErrorContent = ({ title, body, closeFunc }: any) => {
  return (
    <div styleName='modal-container' data-test-id='ElectiveErrorContent-open'>
      <h2>{title}</h2>
      <p>{body}</p>
      <DefaultButton onClick={closeFunc} data-test-id='ElectiveErrorContent-dismiss-button'>
        Okay
      </DefaultButton>
    </div>
  )
}

ElectiveErrorContent.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  closeFunc: PropTypes.func.isRequired,
}

export default CSSModules(ElectiveErrorContent, styles)
