import { useCallback } from 'react'
import { useIntl } from 'react-intl'

import { uniqueId } from 'lodash-es'

import { ToastContent } from '../../ui-models'

export enum ZoomToast {
  SCREENSHARE = 'screenshare',
  DISCONNECTED = 'disconnected',
  RECONNECTING = 'reconnecting',
  RECONNECTED = 'reconnected',
  CAMERA_PERMISSION = 'camera_permission',
  MICROPHONE_PERMISSION = 'microphone_permission',
  BAD_CONNECTION = 'bad_connection',
}

type GetToast = (toast: ZoomToast) => void
type GetToastContent = (toast: ZoomToast) => Omit<ToastContent, 'id'>

type UseZoomToast = (toastFunction?: (toastContent: ToastContent) => void) => GetToast

export const useZoomToast: UseZoomToast = (toastFunction = () => {}) => {
  const { formatMessage } = useIntl()
  const getToastContent = useCallback<GetToastContent>(
    (toast: ZoomToast) => {
      switch (toast) {
        case ZoomToast.SCREENSHARE:
          return {
            text: formatMessage({
              defaultMessage:
                "Oops! It looks like you've selected a sharing source that isn't a tab, please select a tab that you wish to share.",
              description: "Toast that appears when provider selects a screenshare type that can't be used",
            }),
            toastType: 'error',
          }
        case ZoomToast.DISCONNECTED:
          return {
            text: formatMessage({
              defaultMessage: 'Unexpectedly disconnected',
              description: 'Toast that appears when user is diconnected from zoom session',
            }),
            toastType: 'error',
          }
        case ZoomToast.RECONNECTING:
          return {
            text: formatMessage({
              defaultMessage: 'An error occurred. Attempting to reconnect…',
              description: 'Toast that appears when user is attempting to reconnect to zoom session',
            }),
            toastType: 'info',
          }
        case ZoomToast.RECONNECTED:
          return {
            text: formatMessage({
              defaultMessage: 'Reconnected to room!',
              description: 'Toast that appears when user is reconnected to zoom session',
            }),
            toastType: 'success',
          }
        case ZoomToast.CAMERA_PERMISSION:
          return {
            text: formatMessage({
              defaultMessage: 'Unable to access Camera',
              description: 'Toast that appears when user denied camera permission',
            }),
            toastType: 'error',
          }
        case ZoomToast.MICROPHONE_PERMISSION:
          return {
            text: formatMessage({
              defaultMessage: 'Unable to access Microphone',
              description: 'Toast that appears when user denied microphone permission',
            }),
            toastType: 'error',
          }
        case ZoomToast.BAD_CONNECTION:
          return {
            text: formatMessage({
              defaultMessage: 'Low connection. If this persist, try turning off your video.',
              description: 'Toast that appears when user has a bad connection',
            }),
            toastType: 'warning',
          }
      }
    },
    [formatMessage],
  )

  return useCallback<GetToast>(
    (toast: ZoomToast) =>
      toastFunction({
        ...getToastContent(toast),
        id: uniqueId(toast),
      }),
    [getToastContent, toastFunction],
  )
}
