import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { tID } from '../../utils'

export const GearIcon: FunctionComponent<GearIconProps> = ({ size = 24, color }) => {
  const { colors } = useTheme()

  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' fill='none' testID={tID('GearIcon')}>
      <Path
        fillRule='evenodd'
        d='M19.329 12c0 .32-.025.63-.061.926l2.04 1.568a.452.452 0 0 1 .122.605l-1.955 3.29c-.122.213-.378.297-.598.213l-2.43-.95c-.502.369-1.051.69-1.65.927l-.366 2.517c-.025.238-.232.404-.477.404h-3.908a.493.493 0 0 1-.489-.404l-.366-2.517a7.209 7.209 0 0 1-1.65-.927l-2.43.95a.503.503 0 0 1-.599-.213l-1.954-3.29a.482.482 0 0 1 .122-.605l2.065-1.568A5.807 5.807 0 0 1 4.67 12c0-.309.037-.63.086-.926L2.693 9.506a.452.452 0 0 1-.123-.605l1.955-3.29c.122-.213.378-.297.598-.213l2.43.95c.502-.369 1.051-.69 1.65-.927l.366-2.517a.471.471 0 0 1 .477-.404h3.908c.245 0 .452.166.489.404l.366 2.517a7.209 7.209 0 0 1 1.65.926l2.43-.95c.22-.07.476 0 .599.214l1.954 3.29a.482.482 0 0 1-.122.605l-2.064 1.568c.048.297.073.605.073.926ZM8.437 12A3.573 3.573 0 0 0 12 15.563 3.573 3.573 0 0 0 15.563 12 3.573 3.573 0 0 0 12 8.437 3.573 3.573 0 0 0 8.437 12Z'
        clipRule='evenodd'
        fill={color ?? colors.iconActive}
      />
    </Svg>
  )
}

type GearIconProps = {
  size?: string | number
  color?: string
}
