import React from 'react'
import moment from 'moment-timezone'
import { connect } from 'react-redux'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import { useNavigate } from 'react-router'
import styled from 'styled-components/native'
import {
  BodyText,
  BodyTextSize,
  colors,
  DataTable,
  InfoIcon,
  Subhead,
  SubheadSize,
  TextButton,
  ThemeType,
  tID,
  Tooltip,
} from '@lyrahealth-inc/ui-core-crossplatform'
import { ClientListClientObject, EpisodeSummary } from '@lyrahealth-inc/shared-app-logic'
import { Map } from 'immutable'
import { createColumnHelper } from '@tanstack/react-table'
import { episodeTerminationStatuses } from '../../common/constants/appConstants'
import { selectLtClient } from '../clients/clientDetails/data/ltClientDetailsAutoActions'
import { CLIENT_PROGRESS } from '../../common/constants/routingConstants'
import {
  getActiveV2Clients,
  getClientClosedEpisodes,
  getInactiveV2Clients,
} from '../../data/lyraTherapy/clientSelectors'

const HeaderContainer = styled.View({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const ChartContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  margin: `${theme.spacing['16px']} ${theme.spacing['12px']} 0`,
}))

const CompensationCellContainer = styled.View({
  textAlign: 'center',
  flexDirection: 'row',
})

const CompensationCellTooltip = styled(Tooltip)({
  marginLeft: '4px',
})

export const ClosedEpisodesCountDetails: React.FC<ClosedEpisodesCountDetailsProps> = ({
  activeClients,
  inactiveClients,
  closedEpisodes,
  actions: { selectLtClient },
}) => {
  const navigate = useNavigate()
  const sortedClosedEpisodes = closedEpisodes
    ? Object.values(closedEpisodes).sort((episodeA, episodeB) => {
        return episodeA.episode_end_date > episodeB.episode_end_date ? -1 : 1
      })
    : []

  const onClientClickHandler = (client_id: string) => {
    const clients = activeClients.concat(inactiveClients)
    const selectedClient = clients.find(({ id }: { id: string }) => client_id === id)
    selectLtClient(selectedClient)
    navigate(CLIENT_PROGRESS.route)
  }

  const columnHelper = createColumnHelper<episodeDetails>()

  const columns = [
    columnHelper.accessor('episode_end_date', {
      header: 'EPISODE CLOSE DATE',
    }),
    columnHelper.accessor('client_name', {
      header: 'CLIENT NAME',
      cell: ({ row }) => (
        <TextButton
          text={row.original.client_name}
          onPress={() => onClientClickHandler(row.original.client_id)}
          data-testid={`ClosedEpisodesClientDetails-clientName-${row.original.client_name}`}
        />
      ),
    }),
    columnHelper.accessor('baseline', {
      header: 'BASELINE SEVERITY',
    }),
    columnHelper.accessor('episode_state', {
      header: 'EPISODE STATUS',
    }),
    columnHelper.accessor('care_compensation', {
      header: 'MEASUREMENT-BASE CARE COMPENSATION',
      cell: ({ row }) => (
        <CompensationCellContainer
          testID={tID(`ClosedEpisodesClientDetails-careCompensation-${row.original.care_compensation}`)}
        >
          <BodyText text={row.original.care_compensation} size={BodyTextSize.DEFAULT} color={colors.charcoal6} />
          {row.original.care_compensation === 'N/A' && (
            <CompensationCellTooltip
              content="This client did not fill out 2 or more assessments and therefore can't be evaluated for clinical success."
              testID={tID(`ClosedEpisodesClientDetails-careCompensation-tooltip`)}
              hoverEnabled
            >
              <InfoIcon size={18} />
            </CompensationCellTooltip>
          )}
        </CompensationCellContainer>
      ),
    }),
  ]

  const data = Object.values(sortedClosedEpisodes).map((episode) => {
    return {
      episode_end_date: moment(episode.episode_end_date, 'YYYY-MM-DD').format('MM/DD/YYYY'),
      client_name: episode.first_name + ' ' + episode.last_name,
      baseline: episode.baseline,
      episode_state: episodeTerminationStatuses.find((status) => status.value === episode.episode_state)?.name,
      care_compensation: episode.clinical_success ? 'Yes' : episode.clinical_success === false ? 'No' : 'N/A',
      client_id: episode.client_id,
    }
  })

  return (
    <div>
      <HeaderContainer>
        <Subhead text={'Closed Episode Client Details'} size={SubheadSize.MEDIUM} color={colors.ui_oatmeal6} />
      </HeaderContainer>
      <ChartContainer testID={tID('ClosedEpisodesClientDetails-dataTable')}>
        <DataTable
          columns={columns}
          data={data}
          headerSize={BodyTextSize.CAPTION}
          pageSize={15}
          emptyTableText='You do not have any closed episodes for this quarter'
          rowHeight={'48px'}
          headerBottomPadding='16px'
          tableZebraColor={colors.ui_oatmeal2}
        />
      </ChartContainer>
    </div>
  )
}

type episodeDetails = {
  episode_end_date: string
  client_name: string
  baseline: string
  episode_state: string
  care_compensation: string
  client_id: string
}

type ClosedEpisodesCountDetailsProps = {
  activeClients: ClientListClientObject[]
  inactiveClients: ClientListClientObject[]
  closedEpisodes: {
    [key: string]: EpisodeSummary
  }
  actions: any
}

const mapStateToProps = ($$state: Map<string, any>) => ({
  activeClients: getActiveV2Clients($$state),
  inactiveClients: getInactiveV2Clients($$state),
  closedEpisodes: getClientClosedEpisodes($$state),
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  actions: bindActionCreators({ selectLtClient }, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ClosedEpisodesCountDetails)
