import React, { FunctionComponent } from 'react'

import { BaseButton, BaseButtonProps, ButtonModifier, ButtonSize, ButtonType } from '../baseButton/BaseButton'

export interface SecondaryButtonProps extends Omit<BaseButtonProps, 'buttonType' | 'size' | 'modifier'> {
  isSmall?: boolean
  inverse?: boolean
  black?: boolean
  periwinkle?: boolean
}

export const SecondaryButton: FunctionComponent<SecondaryButtonProps> = (props) => {
  const modifier = props.periwinkle
    ? ButtonModifier.PERIWINKLE
    : props.black
    ? ButtonModifier.BLACK
    : props.inverse
    ? ButtonModifier.INVERSE
    : ButtonModifier.DEFAULT
  return (
    <BaseButton
      {...props}
      buttonType={ButtonType.SECONDARY}
      size={props.isSmall ? ButtonSize.SMALL : ButtonSize.DEFAULT}
      modifier={modifier}
    />
  )
}
