import React, { FunctionComponent, useCallback, useEffect, useMemo } from 'react'
import { connect, useSelector } from 'react-redux'

import { bindActionCreators } from 'redux'

import { useFlags } from '@lyrahealth-inc/shared-app-logic'
import { ProviderCalendar, useFetcher } from '@lyrahealth-inc/ui-core-crossplatform'

import './providerCalendar.scss'
import { OAUTH_SUCCESS_RESPONSE } from './constants'
import { getCalendars, getCalendarToken as getCalendarTokenAction, getOAuthURL } from './data/calendarActions'
import { getCalendarCalendars, getCalendarIsLoading, getCalendarToken } from './data/calendarSelectors'
import { getAuthUserEmail, getAuthUserId } from '../data/auth/authSelectors'

const CurrentCalendar: FunctionComponent<CurrentCalendarProps> = ({
  actions: { getOAuthURL, getCalendars, getCalendarTokenAction },
}) => {
  const { isInProductCalendarEnabled } = useFlags()
  const userId = useSelector(getAuthUserId)
  const userEmail = useSelector(getAuthUserEmail)
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'America/Los_Angeles'
  const calendars = useSelector(getCalendarCalendars)
  const accessToken = useSelector(getCalendarToken)
  const isLoading = useSelector(getCalendarIsLoading)

  const calendarToFetchTokenFor = useMemo(() => calendars?.[0], [calendars])

  useFetcher([[getCalendars, { providerId: userId }, !!userId]], [userId])
  useFetcher(
    [
      [
        getCalendarTokenAction,
        { providerId: userId, calendarId: calendarToFetchTokenFor?.calendar_id },
        !!calendarToFetchTokenFor,
      ],
    ],
    [userId, calendarToFetchTokenFor?.calendar_id],
  )

  const startOAuthFlow = useCallback(() => {
    if (!userEmail || !userId) {
      return
    }
    getOAuthURL({
      providerId: userId,
      providerEmail: userEmail,
      timeZone,
    }).then(({ data: url }) => {
      window.open(url)
    })
  }, [getOAuthURL, timeZone, userEmail, userId])

  useEffect(() => {
    const onMessage = (event: MessageEvent<any>) => {
      if (event.origin !== window.location.origin) {
        return
      }
      if (typeof event.data !== 'string') {
        return
      }

      if (event.data === OAUTH_SUCCESS_RESPONSE) {
        getCalendars({ providerId: userId })
      }
    }
    window.addEventListener('message', onMessage)
    return () => {
      window.removeEventListener('message', onMessage)
    }
  })

  if (!isInProductCalendarEnabled) {
    return null
  }
  return (
    <ProviderCalendar
      onAuthorizePressed={startOAuthFlow}
      accessToken={accessToken}
      calendarId={calendarToFetchTokenFor?.calendar_id}
      timeZone={timeZone}
      loading={isLoading}
    />
  )
}

export type CurrentCalendarProps = {
  actions: {
    getOAuthURL: (params: Parameters<typeof getOAuthURL>[0]) => Promise<{ data: string }>
    getCalendars: (params: Parameters<typeof getCalendars>[0]) => Promise<any>
    getCalendarTokenAction: (params: Parameters<typeof getCalendarTokenAction>[0]) => Promise<any>
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      getOAuthURL: getOAuthURL as any,
      getCalendars: getCalendars as any,
      getCalendarTokenAction: getCalendarTokenAction as any,
    },
    dispatch,
  ),
})

const connector = connect(null, mapDispatchToProps)

export default connector(CurrentCalendar)
