import React, { useRef, ReactElement } from 'react'
import realVideo from 'react-player'

const ReactPlayerMock = () => MockPlayer

const MockPlayer = ({ onEnded, onProgress, onStart, onPlay }: NewMockPlayerProps): ReactElement => {
  const player = useRef(null)

  const progress = ({ played }: { played: number }) => {
    if (onProgress) onProgress({ played })
  }

  const ended = () => {
    if (onEnded) onEnded()
    if (progress) progress({ played: 1 })
  }

  const start = () => {
    if (onStart) onStart()
  }

  const play = () => {
    if (onPlay) onPlay()
  }

  //  Helper function for mocking behavior of video at 50%(uncompleted status)
  const seek = () => {
    if (progress) progress({ played: 0.5 })
  }

  // Helper function for mocking behavior of video at 90%(sends a completed status)
  const setVideoForCompletion = () => {
    if (progress) progress({ played: 0.9 })
  }

  return (
    <div style={{ position: 'absolute', top: 0, backgroundColor: 'green' }} data-test-id='VideoPlayer' ref={player}>
      <button onClick={ended} data-test-id='VideoPlayer-onEnded'>
        onEnd
      </button>
      <button onClick={start} data-test-id='VideoPlayer-onStart'>
        onStart
      </button>
      <button onClick={play} data-test-id='VideoPlayer-onPlay'>
        onPlay
      </button>
      <button onClick={seek} data-test-id='VideoPlayer-onSeek'>
        onSeek
      </button>
      <button onClick={setVideoForCompletion} data-test-id='VideoPlayer-setVideoComplete'>
        setVideoComplete
      </button>
    </div>
  )
}
export interface NewMockPlayerProps {
  onProgress?: ({ played }: { played: number }) => void
  onEnded?: () => void
  onStart?: () => void
  onPlay?: () => void
}

export const ReactPlayer = window.Cypress ? ReactPlayerMock() : realVideo
