import React from 'react'
import { CellInfo } from 'react-table'

import { format, parseISO } from 'date-fns'

import ExposurePracticeFormIntro from './exposurePracticeFormIntro/ExposurePracticeFormIntro'
import styles from './worksheet/worksheet.module.scss'
import MessageIcon from '../atoms/icons/MessageIcon'

/**
 * This is a legacy file and should not be added to. All new content should have the uiSchema
 * included along side the schema in the json metadata file.
 */

export const UIMetadata = {
  // Exercises
  checkIn: {
    uiSchema: {
      'ui:order': ['*', 'notes'],
      depression: { 'ui:widget': 'range' },
      anxiety: { 'ui:widget': 'range' },
      anger: { 'ui:widget': 'range' },
      joy: { 'ui:widget': 'range' },
      sleep: { 'ui:widget': 'range' },
      loneliness: { 'ui:widget': 'range' },
      fear: { 'ui:widget': 'range' },
      shame: { 'ui:widget': 'range' },
      guilt: { 'ui:widget': 'range' },
      contentment: { 'ui:widget': 'range' },
      painLevel: { 'ui:widget': 'range' },
      conflictsWithOther: { 'ui:widget': 'range' },
      suicideIdeation: { 'ui:widget': 'range' },
      selfHarm: { 'ui:widget': 'range' },
      alcohol: { 'ui:widget': 'range' },
      activityLevel: { 'ui:widget': 'range' },
      eating: { 'ui:widget': 'range' },
      therapySkillsUsed: { 'ui:widget': 'radio' },
      therapySkillsComeToMind: { 'ui:widget': 'range' },
      mindfulnessSkills: { 'ui:widget': 'range' },
      interpersonalEffectiveness: { 'ui:widget': 'range' },
      emotionRegulation: { 'ui:widget': 'range' },
      distressTolerance: { 'ui:widget': 'range' },
      medicationAsPrescribed: { 'ui:widget': 'radio' },
      otherSubstances: { 'ui:widget': 'radio' },
      exercise: { 'ui:widget': 'radio' },
      notes: { 'ui:widget': 'textarea' },
    },
    report: {
      customColumns: [
        {
          Header: 'Notes',
          accessor: 'response.notes',
          name: 'notes',
          width: 70,
          Cell: (props: $TSFixMe) => {
            return <div>{props.value ? <MessageIcon fillColor={styles.x_dark_gray} width={20} /> : ''}</div>
          },
        },
      ],
      fixedColumns: [
        {
          Header: 'Date',
          accessor: 'submit_date',
          width: 70,
          Cell: (props: $TSFixMe) => {
            const displayDate = props.value || props.original.update_date
            return (
              <div className='rt-date-cell'>
                <div>{format(parseISO(displayDate), 'MMM d')}</div>
              </div>
            )
          },
        },
      ],
    },
    backgroundColor: styles.x_highlight,
    titleColor: styles.x_soft_black,
  },
  coachingCheckIn: {
    uiSchema: {
      'ui:order': ['*', 'notes'],
      depression: { 'ui:widget': 'range' },
      anxiety: { 'ui:widget': 'range' },
      anger: { 'ui:widget': 'range' },
      joy: { 'ui:widget': 'range' },
      sleep: { 'ui:widget': 'range' },
      loneliness: { 'ui:widget': 'range' },
      fear: { 'ui:widget': 'range' },
      shame: { 'ui:widget': 'range' },
      guilt: { 'ui:widget': 'range' },
      contentment: { 'ui:widget': 'range' },
      painLevel: { 'ui:widget': 'range' },
      conflictsWithOther: { 'ui:widget': 'range' },
      suicideIdeation: { 'ui:widget': 'range' },
      alcohol: { 'ui:widget': 'range' },
      activityLevel: { 'ui:widget': 'range' },
      eating: { 'ui:widget': 'range' },
      therapySkillsUsed: { 'ui:widget': 'radio' },
      therapySkillsComeToMind: { 'ui:widget': 'range' },
      medicationAsPrescribed: { 'ui:widget': 'radio' },
      otherSubstances: { 'ui:widget': 'radio' },
      exercise: { 'ui:widget': 'radio' },
      notes: { 'ui:widget': 'textarea' },
    },
    report: {
      customColumns: [
        {
          Header: 'Notes',
          accessor: 'response.notes',
          name: 'notes',
          width: 70,
          Cell: (props: $TSFixMe) => {
            return <div>{props.value ? <MessageIcon fillColor={styles.x_dark_gray} width={20} /> : ''}</div>
          },
        },
      ],
      fixedColumns: [
        {
          Header: 'Date',
          accessor: 'submit_date',
          width: 70,
          Cell: (props: $TSFixMe) => {
            const displayDate = props.value || props.original.update_date
            return (
              <div className='rt-date-cell'>
                <div>{format(parseISO(displayDate), 'MMM d')}</div>
              </div>
            )
          },
        },
      ],
    },
    backgroundColor: styles.x_highlight,
    titleColor: styles.x_soft_black,
  },
  medsCheckIn: {
    report: {
      customColumns: [
        {
          Header: 'Notes',
          accessor: 'response.notes',
          name: 'notes',
          width: 70,
          Cell: (props: $TSFixMe) => {
            return <div>{props.value ? <MessageIcon fillColor={styles.x_dark_gray} width={20} /> : ''}</div>
          },
        },
      ],
      fixedColumns: [
        {
          Header: 'Date',
          accessor: 'submit_date',
          width: 70,
          Cell: (props: $TSFixMe) => {
            const displayDate = props.value || props.original.update_date
            return (
              <div className='rt-date-cell'>
                <div>{format(parseISO(displayDate), 'MMM d')}</div>
              </div>
            )
          },
        },
      ],
    },
    backgroundColor: styles.x_highlight,
    titleColor: styles.x_soft_black,
  },
  bctCheckIn: {
    report: {
      customColumns: [
        {
          Header: 'Notes',
          accessor: 'response.notes',
          name: 'notes',
          width: 70,
          Cell: (props: $TSFixMe) => {
            return <div>{props.value ? <MessageIcon fillColor={styles.x_dark_gray} width={20} /> : ''}</div>
          },
        },
      ],
      fixedColumns: [
        {
          Header: 'Date',
          accessor: 'submit_date',
          width: 70,
          Cell: (props: $TSFixMe) => {
            const displayDate = props.value || props.original.update_date
            return (
              <div className='rt-date-cell'>
                <div>{format(parseISO(displayDate), 'MMM d')}</div>
              </div>
            )
          },
        },
      ],
    },
  },
  bccCheckIn: {
    report: {
      customColumns: [
        {
          Header: 'Notes',
          accessor: 'response.notes',
          name: 'notes',
          width: 70,
          Cell: (props: $TSFixMe) => {
            return <div>{props.value ? <MessageIcon fillColor={styles.x_dark_gray} width={20} /> : ''}</div>
          },
        },
      ],
      fixedColumns: [
        {
          Header: 'Date',
          accessor: 'submit_date',
          width: 70,
          Cell: (props: $TSFixMe) => {
            const displayDate = props.value || props.original.update_date
            return (
              <div className='rt-date-cell'>
                <div>{format(parseISO(displayDate), 'MMM d')}</div>
              </div>
            )
          },
        },
      ],
    },
  },
  bcmCheckIn: {
    report: {
      customColumns: [
        {
          Header: 'Notes',
          accessor: 'response.notes',
          name: 'notes',
          width: 70,
          Cell: (props: $TSFixMe) => {
            return <div>{props.value ? <MessageIcon fillColor={styles.x_dark_gray} width={20} /> : ''}</div>
          },
        },
      ],
      fixedColumns: [
        {
          Header: 'Date',
          accessor: 'submit_date',
          width: 70,
          Cell: (props: $TSFixMe) => {
            const displayDate = props.value || props.original.update_date
            return (
              <div className='rt-date-cell'>
                <div>{format(parseISO(displayDate), 'MMM d')}</div>
              </div>
            )
          },
        },
      ],
    },
  },
  baseSleepDiary: {
    report: {
      customColumns: [
        {
          Header: 'Meds/substances',
          accessor: 'response.describeMedications',
          name: 'describeMedications',
          width: 150,
          Cell: (props: CellInfo) => {
            return <div>{props.value}</div>
          },
        },
        {
          Header: 'Time in bed',
          accessor: 'response.timeWhenInBed',
          name: 'timeWhenInBed',
          width: 100,
          Cell: (props: CellInfo) => {
            return <div>{props.value}</div>
          },
        },
        {
          Header: 'Time asleep',
          accessor: 'response.timeWhenFallAsleep',
          name: 'timeWhenFallAsleep',
          width: 100,
          Cell: (props: CellInfo) => {
            return <div>{props.value}</div>
          },
        },
        {
          Header: '# Awakenings',
          accessor: 'response.wakeUpCount',
          name: 'wakeUpCount',
          width: 120,
          Cell: (props: CellInfo) => {
            return <div>{props.value}</div>
          },
        },
        {
          Header: 'Time awake',
          accessor: 'response.timeOfWakeUp',
          name: 'timeOfWakeUp',
          width: 100,
          Cell: (props: CellInfo) => {
            return <div>{props.value}</div>
          },
        },
        {
          Header: 'Time up',
          accessor: 'response.timeWhenOutOfBed',
          name: 'timeWhenOutOfBed',
          width: 100,
          Cell: (props: CellInfo) => {
            return <div>{props.value}</div>
          },
        },
        {
          Header: 'Sleep quality',
          accessor: 'response.qualityOfSleep',
          name: 'qualityOfSleep',
          width: 120,
          Cell: (props: CellInfo) => {
            const responseValues = ['Very poor', 'Poor', 'Fair', 'Good', 'Very good']
            return <div>{responseValues[props.value]}</div>
          },
        },
        {
          Header: 'Caffeine',
          accessor: 'response.caffeine',
          name: 'caffeine',
          width: 100,
          Cell: (props: CellInfo) => {
            const responseValues = ['Yes', 'No']
            return <div>{responseValues[props.value]}</div>
          },
        },
        {
          Header: '# Naps',
          accessor: 'response.napOrDozeAmount',
          name: 'napOrDozeAmount',
          width: 80,
          Cell: (props: CellInfo) => {
            return <div>{props.value}</div>
          },
        },
        {
          Header: 'Nap length',
          accessor: 'response.napOrDozeTime',
          name: 'napOrDozeTime',
          width: 100,
          Cell: (props: CellInfo) => {
            return <div>{props.value}</div>
          },
        },
        {
          Header: 'Sleepiness',
          accessor: 'response.howSleepy',
          name: 'howSleepy',
          width: 150,
          Cell: (props: CellInfo) => {
            const responseValues = ['So sleepy', 'Somewhat tired', 'Fairly tired', 'Alert']
            return <div>{responseValues[props.value]}</div>
          },
        },
      ],
      fixedColumns: [
        {
          Header: 'Date',
          accessor: 'submit_date',
          width: 70,
          Cell: (props: CellInfo) => {
            const displayDate = props.value || props.original.update_date
            return (
              <div className='rt-date-cell'>
                <div>{format(parseISO(displayDate), 'MMM d')}</div>
              </div>
            )
          },
        },
      ],
    },
  },
  thoughtRecord: {
    uiSchema: {
      'ui:order': [
        'situation',
        'unhelpfulThought',
        'emotions',
        'evidenceUnhelpfulThought',
        'thinkingTraps',
        'altThought',
        'newEmotions',
      ],
      percentBeliefAltThought: { 'ui:widget': 'range', 'ui:options': { percentage: true } },
      situation: { items: [{ 'ui:widget': 'date' }, { 'ui:widget': 'textarea' }] },
      unhelpfulThought: {
        items: [{ 'ui:widget': 'textarea' }, { 'ui:widget': 'range', 'ui:options': { percentage: true } }],
      },
      thinkingTraps: { 'ui:widget': 'checkbox' },
      emotions: { items: [{ 'ui:widget': 'textarea' }, { 'ui:widget': 'range', 'ui:options': { percentage: true } }] },
      evidenceUnhelpfulThought: { items: [{ 'ui:widget': 'textarea' }, { 'ui:widget': 'textarea' }] },
      altThought: {
        items: [{ 'ui:widget': 'textarea' }, { 'ui:widget': 'range', 'ui:options': { percentage: true } }],
      },
      newEmotions: {
        items: [{ 'ui:widget': 'textarea' }, { 'ui:widget': 'range', 'ui:options': { percentage: true } }],
      },
    },
    report: {
      fieldsToDisplay: ['response.situationDescription'],
    },
    backgroundColor: styles.x_primary_highlight,
    titleColor: styles.x_white,
    initialValues: {
      percentBeliefAltThought: 0,
      emotionsIntensity: 0,
      newEmotionsIntensity: 0,
      percentBeliefThought: 0,
    },
  },
  awarenessLog: {
    uiSchema: {
      'ui:order': ['date', 'whatDidYouNotice'],
      date: { 'ui:widget': 'date' },
      whatDidYouNotice: {
        items: [
          { 'ui:widget': 'textarea' },
          { 'ui:widget': 'textarea' },
          { 'ui:widget': 'textarea' },
          { 'ui:widget': 'range' },
        ],
      },
    },
    initialValues: {
      judge: 0,
    },
    report: {
      fieldsToDisplay: ['judge'],
    },
    backgroundColor: styles.x_primary_highlight,
    titleColor: styles.x_white,
  },
  emotionsInContext: {
    uiSchema: {
      'ui:order': ['date', 'situation', 'thoughts', 'emotions', 'otherEmotions', 'behaviors', 'consequences'],
      date: { 'ui:widget': 'date' },
      situation: { 'ui:widget': 'textarea' },
      thoughts: { 'ui:widget': 'textarea' },
      emotions: { 'ui:widget': 'checkbox' },
      otherEmotions: { 'ui:widget': 'textarea' },
      behaviors: { 'ui:widget': 'textarea' },
      consequences: { 'ui:widget': 'textarea' },
    },
    report: {
      fieldsToDisplay: ['situation'],
    },
    backgroundColor: styles.x_primary_highlight,
    titleColor: styles.x_white,
  },
  activityLog: {
    uiSchema: {
      'ui:order': ['activityName', 'activityDate', 'depression', 'pleasure', 'achievement', 'notes'],
      activityDate: { 'ui:widget': 'date' },
      depression: { items: [{ 'ui:widget': 'range' }, { 'ui:widget': 'range' }] },
      pleasure: { items: [{ 'ui:widget': 'range' }, { 'ui:widget': 'range' }] },
      achievement: { items: [{ 'ui:widget': 'range' }, { 'ui:widget': 'range' }] },
      notes: { 'ui:widget': 'textarea' },
    },
    initialValues: {
      depressionBefore: 0,
      depressionAfter: 0,
      pleasureBefore: 0,
      pleasureAfter: 0,
      achievementBefore: 0,
      achievementAfter: 0,
    },
    report: {
      fieldsToDisplay: ['activityName'],
    },
    backgroundColor: styles.x_primary_highlight,
    titleColor: styles.x_white,
  },
  values: {
    uiSchema: {
      'ui:order': ['importantValue', 'generateGoals', 'actionSteps'],
      importantValue: { 'ui:widget': 'radio', 'ui:options': { styleType: 'three-col' } },
      generateGoals: {
        items: [
          { 'ui:widget': 'textarea' },
          { 'ui:widget': 'textarea' },
          { 'ui:widget': 'textarea' },
          { 'ui:widget': 'textarea' },
          { 'ui:widget': 'select' },
        ],
      },
      actionSteps: {
        items: [
          { 'ui:widget': 'textarea' },
          { 'ui:widget': 'textarea' },
          { 'ui:widget': 'textarea' },
          { 'ui:widget': 'textarea' },
          { 'ui:widget': 'select' },
        ],
      },
    },
    report: {
      fieldsToDisplay: ['importantValue'],
    },
    backgroundColor: styles.x_primary_highlight,
    titleColor: styles.x_white,
  },
  activitySchedule: {
    uiSchema: {
      'ui:order': ['date', 'activity'],
      date: { 'ui:widget': 'date' },
    },
    report: {
      fieldsToDisplay: ['activity'],
    },
    backgroundColor: styles.x_primary_highlight,
    titleColor: styles.x_white,
  },
  prosConsChange: {
    uiSchema: {
      'ui:order': ['changeToMake', 'prosChange', 'consChange', 'changeToNotMake', 'prosNotChange', 'consNotChange'],
      prosChange: {
        items: [
          {},
          { 'ui:widget': 'radio', 'ui:options': { styleType: 'side-by-side' } },
          {},
          { 'ui:widget': 'radio', 'ui:options': { styleType: 'side-by-side' } },
          {},
          { 'ui:widget': 'radio', 'ui:options': { styleType: 'side-by-side' } },
        ],
      },
      consChange: {
        items: [
          {},
          { 'ui:widget': 'radio', 'ui:options': { styleType: 'side-by-side' } },
          {},
          { 'ui:widget': 'radio', 'ui:options': { styleType: 'side-by-side' } },
          {},
          { 'ui:widget': 'radio', 'ui:options': { styleType: 'side-by-side' } },
        ],
      },
      prosNotChange: {
        items: [
          {},
          { 'ui:widget': 'radio', 'ui:options': { styleType: 'side-by-side' } },
          {},
          { 'ui:widget': 'radio', 'ui:options': { styleType: 'side-by-side' } },
          {},
          { 'ui:widget': 'radio', 'ui:options': { styleType: 'side-by-side' } },
        ],
      },
      consNotChange: {
        items: [
          {},
          { 'ui:widget': 'radio', 'ui:options': { styleType: 'side-by-side' } },
          {},
          { 'ui:widget': 'radio', 'ui:options': { styleType: 'side-by-side' } },
          {},
          { 'ui:widget': 'radio', 'ui:options': { styleType: 'side-by-side' } },
        ],
      },
    },
    report: {
      fieldsToDisplay: ['changeToMake', 'changeToNotMake'],
    },
    backgroundColor: styles.x_primary_highlight,
    titleColor: styles.x_white,
  },
  wellBeing: {
    uiSchema: {
      'ui:order': [
        'wellBeing_1',
        'wellBeing_2',
        'wellBeing_3',
        'wellBeing_4',
        'wellBeing_5',
        'wellBeing_6',
        'wellBeing_7',
        'wellBeing_8',
        'wellBeing_9',
        'wellBeing_10',
        'wellBeing_11',
        'wellBeing_12',
        'wellBeing_13',
        'wellBeing_14',
      ],
      wellBeing_1: { 'ui:widget': 'radio' },
      wellBeing_2: { 'ui:widget': 'radio' },
      wellBeing_3: { 'ui:widget': 'radio' },
      wellBeing_4: { 'ui:widget': 'radio' },
      wellBeing_5: { 'ui:widget': 'radio' },
      wellBeing_6: { 'ui:widget': 'radio' },
      wellBeing_7: { 'ui:widget': 'radio' },
      wellBeing_8: { 'ui:widget': 'radio' },
      wellBeing_9: { 'ui:widget': 'radio' },
      wellBeing_10: { 'ui:widget': 'radio' },
      wellBeing_11: { 'ui:widget': 'radio' },
      wellBeing_12: { 'ui:widget': 'radio' },
      wellBeing_13: { 'ui:widget': 'radio' },
      wellBeing_14: { 'ui:widget': 'radio' },
    },
  },
  exposureLog: {
    uiSchema: {
      default: {
        'ui:order': [
          'formIntro',
          'exposureTask',
          'exposureDate',
          'situation',
          'sudsBefore',
          'sudsAfter',
          'sudsPeak',
          'takeaway',
        ],
        formIntro: { 'ui:field': (props: $TSFixMe) => <ExposurePracticeFormIntro /> },
        exposureTask: { 'ui:widget': 'textarea' },
        exposureDate: { 'ui:widget': 'date' },
        situation: { 'ui:widget': 'textarea' },
        sudsBefore: { 'ui:widget': 'range' },
        sudsAfter: { 'ui:widget': 'range' },
        sudsPeak: { 'ui:widget': 'range' },
        takeaway: { 'ui:widget': 'textarea' },
      },
      v2: {
        'ui:order': [
          'stressScale',
          'intro',
          'exposureTask',
          'exposureDate',
          'situation',
          'sudsBefore',
          'sudsAfter',
          'sudsPeak',
          'takeaway',
        ],
        stressScale: { 'ui:field': 'SUDSTable' },
        exposureTask: { 'ui:widget': 'textarea' },
        exposureDate: { 'ui:widget': 'date' },
        situation: { 'ui:widget': 'textarea' },
        sudsBefore: { 'ui:widget': 'range' },
        sudsAfter: { 'ui:widget': 'range' },
        sudsPeak: { 'ui:widget': 'range' },
        takeaway: { 'ui:widget': 'textarea' },
      },
    },
    initialValues: {
      sudsBefore: 0,
      sudsAfter: 0,
      sudsPeak: 0,
    },
  },
  therapyTargets: {
    customFields: {
      fields: {
        sliderField: {
          minValue: 0,
          maxValue: 10,
          minLabel: '0: Not a problem',
          maxLabel: '10: A very big problem',
          step: 1,
        },
      },
    },
  },
  baseTherapyTargets: {
    customFields: {
      fields: {
        sliderField: {
          minValue: 0,
          maxValue: 10,
          minLabel: '0: Not a problem',
          maxLabel: '10: A very big problem',
          step: 1,
        },
      },
    },
  },
  exposureHierarchy: {
    uiSchema: {
      'ui:order': ['stressScale', 'intro', 'date', '*'],
      stressScale: { 'ui:field': 'SUDSTable' },
      date: { 'ui:widget': 'date' },
    },
    customFields: {
      fields: {
        sliderField: {
          minValue: 0,
          maxValue: 100,
          minLabel: 'No Distress',
          maxLabel: 'Extreme Distress',
          step: 5,
        },
      },
    },
  },
  baseExposureHierarchy: {
    customFields: {
      fields: {
        sliderField: {
          minValue: 0,
          maxValue: 100,
          minLabel: 'No Distress',
          maxLabel: 'Extreme Distress',
          step: 5,
        },
      },
    },
  },
  coachingExposureHierarchy: {
    uiSchema: {
      'ui:order': ['stressScale', 'intro', 'date', '*'],
      stressScale: { 'ui:field': 'SUDSTable' },
      date: { 'ui:widget': 'date' },
    },
    customFields: {
      fields: {
        sliderField: {
          minValue: 0,
          maxValue: 100,
          minLabel: 'No Distress',
          maxLabel: 'Extreme Distress',
          step: 5,
        },
      },
    },
  },
  structuredProblemSolving: {
    uiSchema: {
      'ui:order': [
        'step1',
        'step2',
        'step3',
        'step4',
        'solution1Pros',
        'solution1Cons',
        'solution2Pros',
        'solution2Cons',
        'solution3Pros',
        'solution3Cons',
        'solution4Pros',
        'solution4Cons',
        'solution5Pros',
        'solution5Cons',
        'step5',
        'step5a',
        'step5b',
        'step6',
        '*',
      ],
      step1: { 'ui:widget': 'textarea' },
      step2: { 'ui:widget': 'textarea' },
      step3: { 'ui:widget': 'textarea' },
      solution1Pros: { 'ui:widget': 'textarea' },
      solution1Cons: { 'ui:widget': 'textarea' },
      solution2Pros: { 'ui:widget': 'textarea' },
      solution2Cons: { 'ui:widget': 'textarea' },
      solution3Pros: { 'ui:widget': 'textarea' },
      solution3Cons: { 'ui:widget': 'textarea' },
      solution4Pros: { 'ui:widget': 'textarea' },
      solution4Cons: { 'ui:widget': 'textarea' },
      solution5Pros: { 'ui:widget': 'textarea' },
      solution5Cons: { 'ui:widget': 'textarea' },
      step5a: { 'ui:widget': 'textarea' },
      step5b: { 'ui:widget': 'textarea' },
      step6: { 'ui:widget': 'radio' },
    },
  },
  coachingPhqGad: {
    uiSchema: {
      'ui:order': [
        'phqLabel',
        'phq9_1',
        'phq9_2',
        'phq9_3',
        'phq9_4',
        'phq9_5',
        'phq9_6',
        'phq9_7',
        'phq9_8',
        'phq9_9',
        'phq9_9_1',
        'phq9_9_2',
        'phq9_10',
        'gadLabel',
        'gad7_1',
        'gad7_2',
        'gad7_3',
        'gad7_4',
        'gad7_5',
        'gad7_6',
        'gad7_7',
      ],
      phq9_1: { 'ui:widget': 'radio' },
      phq9_2: { 'ui:widget': 'radio' },
      phq9_3: { 'ui:widget': 'radio' },
      phq9_4: { 'ui:widget': 'radio' },
      phq9_5: { 'ui:widget': 'radio' },
      phq9_6: { 'ui:widget': 'radio' },
      phq9_7: { 'ui:widget': 'radio' },
      phq9_8: { 'ui:widget': 'radio' },
      phq9_9: { 'ui:widget': 'radio' },
      phq9_9_1: { 'ui:widget': 'radio' },
      phq9_9_2: { 'ui:widget': 'radio' },
      phq9_10: { 'ui:widget': 'radio' },
      gad7_1: { 'ui:widget': 'radio' },
      gad7_2: { 'ui:widget': 'radio' },
      gad7_3: { 'ui:widget': 'radio' },
      gad7_4: { 'ui:widget': 'radio' },
      gad7_5: { 'ui:widget': 'radio' },
      gad7_6: { 'ui:widget': 'radio' },
      gad7_7: { 'ui:widget': 'radio' },
    },
  },
  coachingActivityLog: {
    uiSchema: {
      'ui:order': ['activityName', 'activityDate', 'depression', 'pleasure', 'achievement', 'notes'],
      activityDate: { 'ui:widget': 'date' },
      depression: { items: [{ 'ui:widget': 'range' }, { 'ui:widget': 'range' }] },
      pleasure: { items: [{ 'ui:widget': 'range' }, { 'ui:widget': 'range' }] },
      achievement: { items: [{ 'ui:widget': 'range' }, { 'ui:widget': 'range' }] },
      notes: { 'ui:widget': 'textarea' },
    },
    initialValues: {
      depressionBefore: 0,
      depressionAfter: 0,
      pleasureBefore: 0,
      pleasureAfter: 0,
      achievementBefore: 0,
      achievementAfter: 0,
    },
    report: {
      fieldsToDisplay: ['activityName'],
    },
    backgroundColor: styles.x_primary_highlight,
    titleColor: styles.x_white,
  },
  coachingActivitySchedule: {
    uiSchema: {
      'ui:order': ['date', 'activity'],
      date: { 'ui:widget': 'date' },
    },
    report: {
      fieldsToDisplay: ['activity'],
    },
    backgroundColor: styles.x_primary_highlight,
    titleColor: styles.x_white,
  },
  coachingAssertiveCommunication: {
    uiSchema: {
      'ui:order': [
        'questionsLabel',
        'whatDidYouAskFor',
        'situation',
        'howYouFeel',
        'askForWhatYouWant',
        'reinforceYourPosition',
        'beMindful',
        'appearConfident',
        'beWillingToNegotitate',
      ],
      whatDidYouAskFor: { 'ui:widget': 'textarea' },
      situation: { 'ui:widget': 'textarea' },
      howYouFeel: { 'ui:widget': 'textarea' },
      askForWhatYouWant: { 'ui:widget': 'textarea' },
      reinforceYourPosition: { 'ui:widget': 'textarea' },
      beMindful: { 'ui:widget': 'textarea' },
      appearConfident: { 'ui:widget': 'textarea' },
      beWillingToNegotitate: { 'ui:widget': 'textarea' },
    },
  },
  coachingAwarenessLog: {
    uiSchema: {
      'ui:order': ['date', 'whatDidYouNotice'],
      date: { 'ui:widget': 'date' },
      whatDidYouNotice: {
        items: [
          { 'ui:widget': 'textarea' },
          { 'ui:widget': 'textarea' },
          { 'ui:widget': 'textarea' },
          { 'ui:widget': 'range' },
        ],
      },
    },
    initialValues: {
      judge: 0,
    },
    report: {
      fieldsToDisplay: ['judge'],
    },
    backgroundColor: styles.x_primary_highlight,
    titleColor: styles.x_white,
  },
  coachingEmotionsInContext: {
    uiSchema: {
      'ui:order': ['date', 'situation', 'thoughts', 'emotions', 'otherEmotions', 'behaviors', 'consequences'],
      date: { 'ui:widget': 'date' },
      situation: { 'ui:widget': 'textarea' },
      thoughts: { 'ui:widget': 'textarea' },
      emotions: { 'ui:widget': 'checkbox' },
      otherEmotions: { 'ui:widget': 'textarea' },
      behaviors: { 'ui:widget': 'textarea' },
      consequences: { 'ui:widget': 'textarea' },
    },
    report: {
      fieldsToDisplay: ['situation'],
    },
    backgroundColor: styles.x_primary_highlight,
    titleColor: styles.x_white,
  },
  coachingExposureLog: {
    uiSchema: {
      default: {
        'ui:order': [
          'formIntro',
          'exposureTask',
          'exposureDate',
          'situation',
          'sudsBefore',
          'sudsAfter',
          'sudsPeak',
          'takeaway',
        ],
        formIntro: { 'ui:field': (props: $TSFixMe) => <ExposurePracticeFormIntro /> },
        exposureTask: { 'ui:widget': 'textarea' },
        exposureDate: { 'ui:widget': 'date' },
        situation: { 'ui:widget': 'textarea' },
        sudsBefore: { 'ui:widget': 'range' },
        sudsAfter: { 'ui:widget': 'range' },
        sudsPeak: { 'ui:widget': 'range' },
        takeaway: { 'ui:widget': 'textarea' },
      },
      v2: {
        'ui:order': [
          'stressScale',
          'intro',
          'exposureTask',
          'exposureDate',
          'situation',
          'sudsBefore',
          'sudsAfter',
          'sudsPeak',
          'takeaway',
        ],
        stressScale: { 'ui:field': 'SUDSTable' },
        exposureTask: { 'ui:widget': 'textarea' },
        exposureDate: { 'ui:widget': 'date' },
        situation: { 'ui:widget': 'textarea' },
        sudsBefore: { 'ui:widget': 'range' },
        sudsAfter: { 'ui:widget': 'range' },
        sudsPeak: { 'ui:widget': 'range' },
        takeaway: { 'ui:widget': 'textarea' },
      },
    },
    initialValues: {
      sudsBefore: 0,
      sudsAfter: 0,
      sudsPeak: 0,
    },
  },
  coachingProsConsChange: {
    uiSchema: {
      'ui:order': ['changeToMake', 'prosChange', 'consChange', 'changeToNotMake', 'prosNotChange', 'consNotChange'],
      prosChange: {
        items: [
          {},
          { 'ui:widget': 'radio', 'ui:options': { styleType: 'side-by-side' } },
          {},
          { 'ui:widget': 'radio', 'ui:options': { styleType: 'side-by-side' } },
          {},
          { 'ui:widget': 'radio', 'ui:options': { styleType: 'side-by-side' } },
        ],
      },
      consChange: {
        items: [
          {},
          { 'ui:widget': 'radio', 'ui:options': { styleType: 'side-by-side' } },
          {},
          { 'ui:widget': 'radio', 'ui:options': { styleType: 'side-by-side' } },
          {},
          { 'ui:widget': 'radio', 'ui:options': { styleType: 'side-by-side' } },
        ],
      },
      prosNotChange: {
        items: [
          {},
          { 'ui:widget': 'radio', 'ui:options': { styleType: 'side-by-side' } },
          {},
          { 'ui:widget': 'radio', 'ui:options': { styleType: 'side-by-side' } },
          {},
          { 'ui:widget': 'radio', 'ui:options': { styleType: 'side-by-side' } },
        ],
      },
      consNotChange: {
        items: [
          {},
          { 'ui:widget': 'radio', 'ui:options': { styleType: 'side-by-side' } },
          {},
          { 'ui:widget': 'radio', 'ui:options': { styleType: 'side-by-side' } },
          {},
          { 'ui:widget': 'radio', 'ui:options': { styleType: 'side-by-side' } },
        ],
      },
    },
    report: {
      fieldsToDisplay: ['changeToMake', 'changeToNotMake'],
    },
    backgroundColor: styles.x_primary_highlight,
    titleColor: styles.x_white,
  },
  coachingStructuredProblemSolving: {
    uiSchema: {
      'ui:order': [
        'step1',
        'step2',
        'step3',
        'step4',
        'solution1Pros',
        'solution1Cons',
        'solution2Pros',
        'solution2Cons',
        'solution3Pros',
        'solution3Cons',
        'solution4Pros',
        'solution4Cons',
        'solution5Pros',
        'solution5Cons',
        'step5',
        'step5a',
        'step5b',
        'step6',
        '*',
      ],
      step1: { 'ui:widget': 'textarea' },
      step2: { 'ui:widget': 'textarea' },
      step3: { 'ui:widget': 'textarea' },
      solution1Pros: { 'ui:widget': 'textarea' },
      solution1Cons: { 'ui:widget': 'textarea' },
      solution2Pros: { 'ui:widget': 'textarea' },
      solution2Cons: { 'ui:widget': 'textarea' },
      solution3Pros: { 'ui:widget': 'textarea' },
      solution3Cons: { 'ui:widget': 'textarea' },
      solution4Pros: { 'ui:widget': 'textarea' },
      solution4Cons: { 'ui:widget': 'textarea' },
      solution5Pros: { 'ui:widget': 'textarea' },
      solution5Cons: { 'ui:widget': 'textarea' },
      step5a: { 'ui:widget': 'textarea' },
      step5b: { 'ui:widget': 'textarea' },
      step6: { 'ui:widget': 'radio' },
    },
  },
  coachingThoughtRecord: {
    uiSchema: {
      'ui:order': [
        'situation',
        'unhelpfulThought',
        'emotions',
        'evidenceUnhelpfulThought',
        'thinkingTraps',
        'altThought',
        'newEmotions',
      ],
      percentBeliefAltThought: { 'ui:widget': 'range', 'ui:options': { percentage: true } },
      situation: { items: [{ 'ui:widget': 'date' }, { 'ui:widget': 'textarea' }] },
      unhelpfulThought: {
        items: [{ 'ui:widget': 'textarea' }, { 'ui:widget': 'range', 'ui:options': { percentage: true } }],
      },
      thinkingTraps: { 'ui:widget': 'checkbox' },
      emotions: { items: [{ 'ui:widget': 'textarea' }, { 'ui:widget': 'range', 'ui:options': { percentage: true } }] },
      evidenceUnhelpfulThought: { items: [{ 'ui:widget': 'textarea' }, { 'ui:widget': 'textarea' }] },
      altThought: {
        items: [{ 'ui:widget': 'textarea' }, { 'ui:widget': 'range', 'ui:options': { percentage: true } }],
      },
      newEmotions: {
        items: [{ 'ui:widget': 'textarea' }, { 'ui:widget': 'range', 'ui:options': { percentage: true } }],
      },
    },
    report: {
      fieldsToDisplay: ['response.situationDescription'],
    },
    backgroundColor: styles.x_primary_highlight,
    titleColor: styles.x_white,
    initialValues: {
      percentBeliefAltThought: 0,
      emotionsIntensity: 0,
      newEmotionsIntensity: 0,
      percentBeliefThought: 0,
    },
  },
  coachingValidation: {
    uiSchema: {
      'ui:order': [
        'situation',
        'checkBoxesLabel',
        'payAttention',
        'payAttentionDescription',
        'refectBack',
        'reflectBackDescription',
        'understand',
        'understandDescription',
        'validate',
        'validateDescription',
        'beGenuine',
        'beGenuineDescription',
      ],
      situation: { 'ui:widget': 'textarea' },
      payAttention: { 'ui:widget': 'checkbox' },
      payAttentionDescription: { 'ui:widget': 'textarea' },
      refectBack: { 'ui:widget': 'checkbox' },
      reflectBackDescription: { 'ui:widget': 'textarea' },
      understand: { 'ui:widget': 'checkbox' },
      understandDescription: { 'ui:widget': 'textarea' },
      validate: { 'ui:widget': 'checkbox' },
      validateDescription: { 'ui:widget': 'textarea' },
      beGenuine: { 'ui:widget': 'checkbox' },
      beGenuineDescription: { 'ui:widget': 'textarea' },
    },
    report: {
      fieldsToDisplay: ['situation'],
    },
  },
  coachingValues: {
    uiSchema: {
      'ui:order': ['importantValue', 'generateGoals', 'actionSteps'],
      importantValue: { 'ui:widget': 'radio', 'ui:options': { styleType: 'three-col' } },
      generateGoals: {
        items: [
          { 'ui:widget': 'textarea' },
          { 'ui:widget': 'textarea' },
          { 'ui:widget': 'textarea' },
          { 'ui:widget': 'textarea' },
          { 'ui:widget': 'select' },
        ],
      },
      actionSteps: {
        items: [
          { 'ui:widget': 'textarea' },
          { 'ui:widget': 'textarea' },
          { 'ui:widget': 'textarea' },
          { 'ui:widget': 'textarea' },
          { 'ui:widget': 'select' },
        ],
      },
    },
    report: {
      fieldsToDisplay: ['importantValue'],
    },
    backgroundColor: styles.x_primary_highlight,
    titleColor: styles.x_white,
  },

  // Lessons
  understandingEmotionsLessonReview: {
    uiSchema: {
      default: {
        question1: { 'ui:widget': 'radio' },
        question2: { 'ui:widget': 'radio' },
        question3: { 'ui:widget': 'radio' },
        question4: { 'ui:widget': 'radio' },
        question5: { 'ui:widget': 'radio' },
        question6: { 'ui:widget': 'radio' },
        question7: { 'ui:widget': 'radio' },
      },
      v2: {
        'ui:order': [
          'question1',
          'question2',
          'question3',
          'question4',
          'question5',
          'question6',
          'question7',
          'feedback1',
          'crc1',
          'crc2',
          'feedback2',
        ],
        question1: { 'ui:widget': 'radio' },
        question2: { 'ui:widget': 'radio' },
        question3: { 'ui:widget': 'radio' },
        question4: { 'ui:widget': 'radio' },
        question5: { 'ui:widget': 'radio' },
        question6: { 'ui:widget': 'radio' },
        question7: { 'ui:widget': 'radio' },
        feedback1: { 'ui:widget': 'radio' },
        crc1: { 'ui:widget': 'radio' },
        crc2: { 'ui:widget': 'radio' },
        feedback2: { 'ui:widget': 'textarea' },
      },
    },
  },
  introToCBTLessonReview: {
    uiSchema: {
      default: {
        question1: { 'ui:widget': 'radio' },
        question2: { 'ui:widget': 'radio' },
        question3: { 'ui:widget': 'radio' },
      },
      v2: {
        'ui:order': ['question1', 'question2', 'question3', 'feedback1', 'crc1', 'crc2', 'feedback2'],
        question1: { 'ui:widget': 'radio' },
        question2: { 'ui:widget': 'radio' },
        question3: { 'ui:widget': 'radio' },
        feedback1: { 'ui:widget': 'radio' },
        crc1: { 'ui:widget': 'radio' },
        crc2: { 'ui:widget': 'radio' },
        feedback2: { 'ui:widget': 'textarea' },
      },
    },
  },
  valuesLessonReview: {
    uiSchema: {
      default: {
        question1: { 'ui:widget': 'radio' },
        question2: { 'ui:widget': 'radio' },
        question3: { 'ui:widget': 'radio' },
        question4: { 'ui:widget': 'radio' },
      },
      v2: {
        'ui:order': ['question1', 'question2', 'question3', 'question4', 'feedback1', 'crc1', 'crc2', 'feedback2'],
        question1: { 'ui:widget': 'radio' },
        question2: { 'ui:widget': 'radio' },
        question3: { 'ui:widget': 'radio' },
        question4: { 'ui:widget': 'radio' },
        feedback1: { 'ui:widget': 'radio' },
        crc1: { 'ui:widget': 'radio' },
        crc2: { 'ui:widget': 'radio' },
        feedback2: { 'ui:widget': 'textarea' },
      },
    },
  },
  mindfulAwarenessLessonReview: {
    uiSchema: {
      default: {
        question1: { 'ui:widget': 'radio' },
        question2: { 'ui:widget': 'radio' },
        question3: { 'ui:widget': 'radio' },
        question4: { 'ui:widget': 'radio' },
        question5: { 'ui:widget': 'radio' },
      },
      v2: {
        'ui:order': [
          'question1',
          'question2',
          'question3',
          'question4',
          'question5',
          'feedback1',
          'crc1',
          'crc2',
          'feedback2',
        ],
        question1: { 'ui:widget': 'radio' },
        question2: { 'ui:widget': 'radio' },
        question3: { 'ui:widget': 'radio' },
        question4: { 'ui:widget': 'radio' },
        question5: { 'ui:widget': 'radio' },
        feedback1: { 'ui:widget': 'radio' },
        crc1: { 'ui:widget': 'radio' },
        crc2: { 'ui:widget': 'radio' },
        feedback2: { 'ui:widget': 'textarea' },
      },
    },
  },
  thinkingTrapsLessonReview: {
    uiSchema: {
      default: {
        question1: { 'ui:widget': 'radio' },
        question2: { 'ui:widget': 'radio' },
        question3: { 'ui:widget': 'radio' },
        question4: { 'ui:widget': 'radio' },
        question5: { 'ui:widget': 'radio' },
      },
      v2: {
        'ui:order': [
          'question1',
          'question2',
          'question3',
          'question4',
          'question5',
          'feedback1',
          'crc1',
          'crc2',
          'feedback2',
        ],
        question1: { 'ui:widget': 'radio' },
        question2: { 'ui:widget': 'radio' },
        question3: { 'ui:widget': 'radio' },
        question4: { 'ui:widget': 'radio' },
        question5: { 'ui:widget': 'radio' },
        feedback1: { 'ui:widget': 'radio' },
        crc1: { 'ui:widget': 'radio' },
        crc2: { 'ui:widget': 'radio' },
        feedback2: { 'ui:widget': 'textarea' },
      },
    },
  },
  challengingAvoidanceLessonReview: {
    uiSchema: {
      default: {
        question1: { 'ui:widget': 'radio' },
        question2: { 'ui:widget': 'radio' },
        question3: { 'ui:widget': 'radio' },
        question4: { 'ui:widget': 'radio' },
        question5: { 'ui:widget': 'radio' },
      },
      v2: {
        'ui:order': [
          'question1',
          'question2',
          'question3',
          'question4',
          'question5',
          'feedback1',
          'crc1',
          'crc2',
          'feedback2',
        ],
        question1: { 'ui:widget': 'radio' },
        question2: { 'ui:widget': 'radio' },
        question3: { 'ui:widget': 'radio' },
        question4: { 'ui:widget': 'radio' },
        question5: { 'ui:widget': 'radio' },
        feedback1: { 'ui:widget': 'radio' },
        crc1: { 'ui:widget': 'radio' },
        crc2: { 'ui:widget': 'radio' },
        feedback2: { 'ui:widget': 'textarea' },
      },
    },
  },
  relationshipSkillsLessonReview: {
    uiSchema: {
      'ui:order': [
        'question1',
        'question2',
        'question3',
        'question4',
        'question5',
        'feedback1',
        'crc1',
        'crc2',
        'feedback2',
      ],
      question1: { 'ui:widget': 'radio' },
      question2: { 'ui:widget': 'radio' },
      question3: { 'ui:widget': 'radio' },
      question4: { 'ui:widget': 'radio' },
      question5: { 'ui:widget': 'radio' },
      feedback1: { 'ui:widget': 'radio' },
      crc1: { 'ui:widget': 'radio' },
      crc2: { 'ui:widget': 'radio' },
      feedback2: { 'ui:widget': 'textarea' },
    },
  },
  improvingSleepLessonReview: {
    uiSchema: {
      default: {
        'ui:order': ['question1', 'question2', 'question3', 'feedback1', 'feedback2'],
        question1: { 'ui:widget': 'radio' },
        question2: { 'ui:widget': 'radio' },
        question3: { 'ui:widget': 'radio' },
        feedback1: { 'ui:widget': 'radio' },
        feedback2: { 'ui:widget': 'textarea' },
      },
    },
  },
  understandingSleepLessonReview: {
    uiSchema: {
      default: {
        'ui:order': ['question1', 'question2', 'question3', 'feedback1', 'crc1', 'crc2', 'feedback2'],
        question1: { 'ui:widget': 'radio' },
        question2: { 'ui:widget': 'radio' },
        question3: { 'ui:widget': 'radio' },
        feedback1: { 'ui:widget': 'radio' },
        crc1: { 'ui:widget': 'radio' },
        crc2: { 'ui:widget': 'radio' },
        feedback2: { 'ui:widget': 'textarea' },
      },
    },
  },
  understandingAnxietyLessonReview: {
    uiSchema: {
      default: {
        'ui:order': ['question1', 'question2', 'question3', 'feedback1', 'crc1', 'crc2', 'feedback2'],
        question1: { 'ui:widget': 'radio' },
        question2: { 'ui:widget': 'radio' },
        question3: { 'ui:widget': 'radio' },
        feedback1: { 'ui:widget': 'radio' },
        crc1: { 'ui:widget': 'radio' },
        crc2: { 'ui:widget': 'radio' },
        feedback2: { 'ui:widget': 'textarea' },
      },
    },
  },
  understandingDepressionLessonReview: {
    uiSchema: {
      default: {
        'ui:order': ['question1', 'question2', 'question3', 'feedback1', 'feedback2'],
        question1: { 'ui:widget': 'radio' },
        question2: { 'ui:widget': 'radio' },
        question3: { 'ui:widget': 'radio' },
        feedback1: { 'ui:widget': 'radio' },
        feedback2: { 'ui:widget': 'textarea' },
      },
    },
  },
  selfCompassionReview: {
    uiSchema: {
      default: {
        'ui:order': ['question1', 'question2', 'question3', 'feedback1', 'crc1', 'crc2', 'feedback2'],
        question1: { 'ui:widget': 'radio' },
        question2: { 'ui:widget': 'radio' },
        question3: { 'ui:widget': 'radio' },
        feedback1: { 'ui:widget': 'radio' },
        crc1: { 'ui:widget': 'radio' },
        crc2: { 'ui:widget': 'radio' },
        feedback2: { 'ui:widget': 'textarea' },
      },
    },
  },
  communicationStylesLessonReview: {
    uiSchema: {
      default: {
        'ui:order': ['question1', 'question2', 'question3', 'feedback1', 'feedback2'],
        question1: { 'ui:widget': 'radio' },
        question2: { 'ui:widget': 'radio' },
        question3: { 'ui:widget': 'radio' },
        feedback1: { 'ui:widget': 'radio' },
        feedback2: { 'ui:widget': 'textarea' },
      },
    },
  },
  coachingUnderstandingEmotionsLessonReview: {
    uiSchema: {
      'ui:order': [
        'question1',
        'question2',
        'question3',
        'question4',
        'question5',
        'question6',
        'question7',
        'feedback1',
        'crc1',
        'crc2',
        'feedback2',
      ],
      question1: { 'ui:widget': 'radio' },
      question2: { 'ui:widget': 'radio' },
      question3: { 'ui:widget': 'radio' },
      question4: { 'ui:widget': 'radio' },
      question5: { 'ui:widget': 'radio' },
      question6: { 'ui:widget': 'radio' },
      question7: { 'ui:widget': 'radio' },
      feedback1: { 'ui:widget': 'radio' },
      crc1: { 'ui:widget': 'radio' },
      crc2: { 'ui:widget': 'radio' },
      feedback2: { 'ui:widget': 'textarea' },
    },
  },
  coachingIntroToCbcLessonReview: {
    uiSchema: {
      'ui:order': ['question1', 'question2', 'question3', 'feedback1', 'feedback2'],
      question1: { 'ui:widget': 'radio' },
      question2: { 'ui:widget': 'radio' },
      question3: { 'ui:widget': 'radio' },
      feedback1: { 'ui:widget': 'radio' },
      feedback2: { 'ui:widget': 'textarea' },
    },
  },
  coachingValuesLessonReview: {
    uiSchema: {
      'ui:order': ['question1', 'question2', 'question3', 'question4', 'feedback1', 'crc1', 'crc2', 'feedback2'],
      question1: { 'ui:widget': 'radio' },
      question2: { 'ui:widget': 'radio' },
      question3: { 'ui:widget': 'radio' },
      question4: { 'ui:widget': 'radio' },
      feedback1: { 'ui:widget': 'radio' },
      crc1: { 'ui:widget': 'radio' },
      crc2: { 'ui:widget': 'radio' },
      feedback2: { 'ui:widget': 'textarea' },
    },
  },
  coachingMindfulAwarenessLessonReview: {
    uiSchema: {
      'ui:order': [
        'question1',
        'question2',
        'question3',
        'question4',
        'question5',
        'feedback1',
        'crc1',
        'crc2',
        'feedback2',
      ],
      question1: { 'ui:widget': 'radio' },
      question2: { 'ui:widget': 'radio' },
      question3: { 'ui:widget': 'radio' },
      question4: { 'ui:widget': 'radio' },
      question5: { 'ui:widget': 'radio' },
      feedback1: { 'ui:widget': 'radio' },
      crc1: { 'ui:widget': 'radio' },
      crc2: { 'ui:widget': 'radio' },
      feedback2: { 'ui:widget': 'textarea' },
    },
  },
  coachingThinkingTrapsLessonReview: {
    uiSchema: {
      'ui:order': [
        'question1',
        'question2',
        'question3',
        'question4',
        'question5',
        'feedback1',
        'crc1',
        'crc2',
        'feedback2',
      ],
      question1: { 'ui:widget': 'radio' },
      question2: { 'ui:widget': 'radio' },
      question3: { 'ui:widget': 'radio' },
      question4: { 'ui:widget': 'radio' },
      question5: { 'ui:widget': 'radio' },
      feedback1: { 'ui:widget': 'radio' },
      crc1: { 'ui:widget': 'radio' },
      crc2: { 'ui:widget': 'radio' },
      feedback2: { 'ui:widget': 'textarea' },
    },
  },
  coachingChallengingAvoidanceLessonReview: {
    uiSchema: {
      'ui:order': [
        'question1',
        'question2',
        'question3',
        'question4',
        'question5',
        'feedback1',
        'crc1',
        'crc2',
        'feedback2',
      ],
      question1: { 'ui:widget': 'radio' },
      question2: { 'ui:widget': 'radio' },
      question3: { 'ui:widget': 'radio' },
      question4: { 'ui:widget': 'radio' },
      question5: { 'ui:widget': 'radio' },
      feedback1: { 'ui:widget': 'radio' },
      crc1: { 'ui:widget': 'radio' },
      crc2: { 'ui:widget': 'radio' },
      feedback2: { 'ui:widget': 'textarea' },
    },
  },
  coachingRelationshipSkillsLessonReview: {
    uiSchema: {
      'ui:order': [
        'question1',
        'question2',
        'question3',
        'question4',
        'question5',
        'feedback1',
        'crc1',
        'crc2',
        'feedback2',
      ],
      question1: { 'ui:widget': 'radio' },
      question2: { 'ui:widget': 'radio' },
      question3: { 'ui:widget': 'radio' },
      question4: { 'ui:widget': 'radio' },
      question5: { 'ui:widget': 'radio' },
      feedback1: { 'ui:widget': 'radio' },
      crc1: { 'ui:widget': 'radio' },
      crc2: { 'ui:widget': 'radio' },
      feedback2: { 'ui:widget': 'textarea' },
    },
  },
  stressManagementLessonReview: {
    uiSchema: {
      default: {
        'ui:order': ['question1', 'question2', 'question3', 'feedback1', 'crc1', 'crc2', 'feedback2'],
        question1: { 'ui:widget': 'radio' },
        question2: { 'ui:widget': 'radio' },
        question3: { 'ui:widget': 'radio' },
        feedback1: { 'ui:widget': 'radio' },
        crc1: { 'ui:widget': 'radio' },
        crc2: { 'ui:widget': 'radio' },
        feedback2: { 'ui:widget': 'textarea' },
      },
    },
  },
  coachingSelfCompassionReview: {
    uiSchema: {
      default: {
        'ui:order': ['question1', 'question2', 'question3', 'feedback1', 'feedback2'],
        question1: { 'ui:widget': 'radio' },
        question2: { 'ui:widget': 'radio' },
        question3: { 'ui:widget': 'radio' },
        feedback1: { 'ui:widget': 'radio' },
        feedback2: { 'ui:widget': 'textarea' },
      },
    },
  },
  coachingUnderstandingAnxietyLessonReview: {
    uiSchema: {
      default: {
        'ui:order': ['question1', 'question2', 'question3', 'feedback1', 'crc1', 'crc2', 'feedback2'],
        question1: { 'ui:widget': 'radio' },
        question2: { 'ui:widget': 'radio' },
        question3: { 'ui:widget': 'radio' },
        feedback1: { 'ui:widget': 'radio' },
        crc1: { 'ui:widget': 'radio' },
        crc2: { 'ui:widget': 'radio' },
        feedback2: { 'ui:widget': 'textarea' },
      },
    },
  },
  coachingUnderstandingSleepLessonReview: {
    uiSchema: {
      default: {
        'ui:order': ['question1', 'question2', 'question3', 'feedback1', 'feedback2'],
        question1: { 'ui:widget': 'radio' },
        question2: { 'ui:widget': 'radio' },
        question3: { 'ui:widget': 'radio' },
        feedback1: { 'ui:widget': 'radio' },
        feedback2: { 'ui:widget': 'textarea' },
      },
    },
  },
  socialAnxietyHierarchyLessonReview: {
    uiSchema: {
      'ui:order': ['question1', 'question2', 'question3', 'feedback1', 'feedback2'],
      question1: { 'ui:widget': 'radio' },
      question2: { 'ui:widget': 'radio' },
      question3: { 'ui:widget': 'radio' },
      feedback1: { 'ui:widget': 'radio' },
      feedback2: { 'ui:widget': 'textarea' },
    },
  },
  medsSocialAnxietyHierarchyLessonReview: {
    uiSchema: {
      'ui:order': ['question1', 'question2', 'question3', 'feedback1', 'feedback2'],
      question1: { 'ui:widget': 'radio' },
      question2: { 'ui:widget': 'radio' },
      question3: { 'ui:widget': 'radio' },
      feedback1: { 'ui:widget': 'radio' },
      feedback2: { 'ui:widget': 'textarea' },
    },
  },
  socialAnxietyExposuresLessonReview: {
    uiSchema: {
      'ui:order': ['question1', 'question2', 'question3', 'feedback1', 'feedback2'],
      question1: { 'ui:widget': 'radio' },
      question2: { 'ui:widget': 'radio' },
      question3: { 'ui:widget': 'radio' },
      feedback1: { 'ui:widget': 'radio' },
      feedback2: { 'ui:widget': 'textarea' },
    },
  },
  medsSocialAnxietyExposuresLessonReview: {
    uiSchema: {
      'ui:order': ['question1', 'question2', 'question3', 'feedback1', 'feedback2'],
      question1: { 'ui:widget': 'radio' },
      question2: { 'ui:widget': 'radio' },
      question3: { 'ui:widget': 'radio' },
      feedback1: { 'ui:widget': 'radio' },
      feedback2: { 'ui:widget': 'textarea' },
    },
  },
  coachingSocialAnxietyExposuresLessonReview: {
    uiSchema: {
      'ui:order': ['question1', 'question2', 'question3', 'feedback1', 'feedback2'],
      question1: { 'ui:widget': 'radio' },
      question2: { 'ui:widget': 'radio' },
      question3: { 'ui:widget': 'radio' },
      feedback1: { 'ui:widget': 'radio' },
      feedback2: { 'ui:widget': 'textarea' },
    },
  },
  copingCovid19LessonReview: {
    uiSchema: {
      'ui:order': [
        'question1',
        'question2',
        'question3',
        'question4',
        'question5',
        'question6',
        'feedback1',
        'feedback2',
      ],
      question1: { 'ui:widget': 'radio' },
      question2: { 'ui:widget': 'radio' },
      question3: { 'ui:widget': 'radio' },
      question4: { 'ui:widget': 'radio' },
      question5: { 'ui:widget': 'radio' },
      question6: { 'ui:widget': 'radio' },
      feedback1: { 'ui:widget': 'radio' },
      feedback2: { 'ui:widget': 'textarea' },
    },
  },
  decisionsLessonReview: {
    uiSchema: {
      'ui:order': ['question1', 'question2', 'question3', 'feedback1', 'feedback2'],
      question1: { 'ui:widget': 'radio' },
      question2: { 'ui:widget': 'radio' },
      question3: { 'ui:widget': 'radio' },
      feedback1: { 'ui:widget': 'radio' },
      feedback2: { 'ui:widget': 'textarea' },
    },
  },
  socialAnxietyThinkingTrapsLessonReview: {
    uiSchema: {
      'ui:order': ['question1', 'question2', 'question3', 'feedback1', 'feedback2'],
      question1: { 'ui:widget': 'radio' },
      question2: { 'ui:widget': 'radio' },
      question3: { 'ui:widget': 'radio' },
      feedback1: { 'ui:widget': 'radio' },
      feedback2: { 'ui:widget': 'textarea' },
    },
  },

  // Assessments
  initialAssessmentMetadata: {
    uiSchema: {
      default: {
        'ui:order': [
          'phq9_1',
          'phq9_2',
          'phq9_3',
          'phq9_4',
          'phq9_5',
          'phq9_6',
          'phq9_7',
          'phq9_8',
          'phq9_9',
          'gad7_1',
          'gad7_2',
          'gad7_3',
          'gad7_4',
          'gad7_5',
          'gad7_6',
          'gad7_7',
          'phq9_10',
        ],
        phq9_1: { 'ui:widget': 'radio' },
        phq9_2: { 'ui:widget': 'radio' },
        phq9_3: { 'ui:widget': 'radio' },
        phq9_4: { 'ui:widget': 'radio' },
        phq9_5: { 'ui:widget': 'radio' },
        phq9_6: { 'ui:widget': 'radio' },
        phq9_7: { 'ui:widget': 'radio' },
        phq9_8: { 'ui:widget': 'radio' },
        phq9_9: { 'ui:widget': 'radio' },
        phq9_10: { 'ui:widget': 'radio' },
        gad7_1: { 'ui:widget': 'radio' },
        gad7_2: { 'ui:widget': 'radio' },
        gad7_3: { 'ui:widget': 'radio' },
        gad7_4: { 'ui:widget': 'radio' },
        gad7_5: { 'ui:widget': 'radio' },
        gad7_6: { 'ui:widget': 'radio' },
        gad7_7: { 'ui:widget': 'radio' },
      },
      v2: {
        'ui:order': [
          'gad7_1',
          'gad7_2',
          'gad7_3',
          'gad7_4',
          'gad7_5',
          'gad7_6',
          'gad7_7',
          'phq9_1',
          'phq9_2',
          'phq9_3',
          'phq9_4',
          'phq9_5',
          'phq9_6',
          'phq9_7',
          'phq9_8',
          'phq9_9',
          'phq9_9_1',
          'phq9_9_2',
          'phq9_10',
        ],
        phq9_1: { 'ui:widget': 'radio' },
        phq9_2: { 'ui:widget': 'radio' },
        phq9_3: { 'ui:widget': 'radio' },
        phq9_4: { 'ui:widget': 'radio' },
        phq9_5: { 'ui:widget': 'radio' },
        phq9_6: { 'ui:widget': 'radio' },
        phq9_7: { 'ui:widget': 'radio' },
        phq9_8: { 'ui:widget': 'radio' },
        phq9_9: { 'ui:widget': 'radio' },
        phq9_9_1: { 'ui:widget': 'radio' },
        phq9_9_2: { 'ui:widget': 'radio' },
        phq9_10: { 'ui:widget': 'radio' },
        gad7_1: { 'ui:widget': 'radio' },
        gad7_2: { 'ui:widget': 'radio' },
        gad7_3: { 'ui:widget': 'radio' },
        gad7_4: { 'ui:widget': 'radio' },
        gad7_5: { 'ui:widget': 'radio' },
        gad7_6: { 'ui:widget': 'radio' },
        gad7_7: { 'ui:widget': 'radio' },
      },
      v3: {
        'ui:order': [
          'gadLabel',
          'gad7_1',
          'gad7_2',
          'gad7_3',
          'gad7_4',
          'gad7_5',
          'gad7_6',
          'gad7_7',
          'phqLabel',
          'phq9_1',
          'phq9_2',
          'phq9_3',
          'phq9_4',
          'phq9_5',
          'phq9_6',
          'phq9_7',
          'phq9_8',
          'phq9_9',
          'phq9_9_1',
          'phq9_9_2',
          'phq9_10',
          'pssLabel',
          'pss_1',
          'pss_2',
          'pss_3',
          'pss_4',
          'pss_5',
          'pss_6',
          'pss_7',
          'pss_8',
          'pss_9',
          'pss_10',
          'wellBeingLabel',
          'wellBeing_1',
          'wellBeing_2',
          'wellBeing_3',
          'wellBeing_4',
          'wellBeing_5',
          'wellBeing_6',
          'wellBeing_7',
          'wellBeing_8',
          'wellBeing_9',
          'wellBeing_10',
          'wellBeing_11',
          'wellBeing_12',
          'wellBeing_13',
          'wellBeing_14',
          'productivity_label',
          'wlq_label1',
          'questions1Through_2',
          'wlq_1',
          'wlq_2',
          'nextTwoQuestions_5',
          'wlq_7',
          'wlq_8',
          'questionAsksAbout_8',
          'wlq_3',
          'nextQuestionAsks_10',
          'wlq_4',
          'questionsAskAbout_12',
          'wlq_5',
          'wlq_6',
          'wlq_label2',
          'nextQuestionsAsk_15',
          'aboutManyHours_16',
          'manyHoursDoes_17',
        ],
        phq9_1: { 'ui:widget': 'radio' },
        phq9_2: { 'ui:widget': 'radio' },
        phq9_3: { 'ui:widget': 'radio' },
        phq9_4: { 'ui:widget': 'radio' },
        phq9_5: { 'ui:widget': 'radio' },
        phq9_6: { 'ui:widget': 'radio' },
        phq9_7: { 'ui:widget': 'radio' },
        phq9_8: { 'ui:widget': 'radio' },
        phq9_9: { 'ui:widget': 'radio' },
        phq9_9_1: { 'ui:widget': 'radio' },
        phq9_9_2: { 'ui:widget': 'radio' },
        phq9_10: { 'ui:widget': 'radio' },
        gad7_1: { 'ui:widget': 'radio' },
        gad7_2: { 'ui:widget': 'radio' },
        gad7_3: { 'ui:widget': 'radio' },
        gad7_4: { 'ui:widget': 'radio' },
        gad7_5: { 'ui:widget': 'radio' },
        gad7_6: { 'ui:widget': 'radio' },
        gad7_7: { 'ui:widget': 'radio' },
        pss_1: { 'ui:widget': 'radio' },
        pss_2: { 'ui:widget': 'radio' },
        pss_3: { 'ui:widget': 'radio' },
        pss_4: { 'ui:widget': 'radio' },
        pss_5: { 'ui:widget': 'radio' },
        pss_6: { 'ui:widget': 'radio' },
        pss_7: { 'ui:widget': 'radio' },
        pss_8: { 'ui:widget': 'radio' },
        pss_9: { 'ui:widget': 'radio' },
        pss_10: { 'ui:widget': 'radio' },
        wellBeing_1: { 'ui:widget': 'radio' },
        wellBeing_2: { 'ui:widget': 'radio' },
        wellBeing_3: { 'ui:widget': 'radio' },
        wellBeing_4: { 'ui:widget': 'radio' },
        wellBeing_5: { 'ui:widget': 'radio' },
        wellBeing_6: { 'ui:widget': 'radio' },
        wellBeing_7: { 'ui:widget': 'radio' },
        wellBeing_8: { 'ui:widget': 'radio' },
        wellBeing_9: { 'ui:widget': 'radio' },
        wellBeing_10: { 'ui:widget': 'radio' },
        wellBeing_11: { 'ui:widget': 'radio' },
        wellBeing_12: { 'ui:widget': 'radio' },
        wellBeing_13: { 'ui:widget': 'radio' },
        wellBeing_14: { 'ui:widget': 'radio' },
        wlq_1: { 'ui:widget': 'radio' },
        wlq_2: { 'ui:widget': 'radio' },
        wlq_7: { 'ui:widget': 'radio' },
        wlq_8: { 'ui:widget': 'radio' },
        wlq_3: { 'ui:widget': 'radio' },
        wlq_4: { 'ui:widget': 'radio' },
        wlq_5: { 'ui:widget': 'radio' },
        wlq_6: { 'ui:widget': 'radio' },
      },
    },
  },
  mildOngoingAssessmentMetadata: {
    uiSchema: {
      default: {
        'ui:order': [
          'pssLabel',
          'pss_1',
          'pss_2',
          'pss_3',
          'pss_4',
          'pss_5',
          'pss_6',
          'pss_7',
          'pss_8',
          'pss_9',
          'pss_10',
          'wellBeingLabel',
          'wellBeing_1',
          'wellBeing_2',
          'wellBeing_3',
          'wellBeing_4',
          'wellBeing_5',
          'wellBeing_6',
          'wellBeing_7',
          'wellBeing_8',
          'wellBeing_9',
          'wellBeing_10',
          'wellBeing_11',
          'wellBeing_12',
          'wellBeing_13',
          'wellBeing_14',
          'allianceLabel',
          'alliance_1',
          'alliance_2',
          'alliance_3',
        ],
        pss_1: { 'ui:widget': 'radio' },
        pss_2: { 'ui:widget': 'radio' },
        pss_3: { 'ui:widget': 'radio' },
        pss_4: { 'ui:widget': 'radio' },
        pss_5: { 'ui:widget': 'radio' },
        pss_6: { 'ui:widget': 'radio' },
        pss_7: { 'ui:widget': 'radio' },
        pss_8: { 'ui:widget': 'radio' },
        pss_9: { 'ui:widget': 'radio' },
        pss_10: { 'ui:widget': 'radio' },
        wellBeing_1: { 'ui:widget': 'radio' },
        wellBeing_2: { 'ui:widget': 'radio' },
        wellBeing_3: { 'ui:widget': 'radio' },
        wellBeing_4: { 'ui:widget': 'radio' },
        wellBeing_5: { 'ui:widget': 'radio' },
        wellBeing_6: { 'ui:widget': 'radio' },
        wellBeing_7: { 'ui:widget': 'radio' },
        wellBeing_8: { 'ui:widget': 'radio' },
        wellBeing_9: { 'ui:widget': 'radio' },
        wellBeing_10: { 'ui:widget': 'radio' },
        wellBeing_11: { 'ui:widget': 'radio' },
        wellBeing_12: { 'ui:widget': 'radio' },
        wellBeing_13: { 'ui:widget': 'radio' },
        wellBeing_14: { 'ui:widget': 'radio' },
        alliance_1: { 'ui:widget': 'radio' },
        alliance_2: { 'ui:widget': 'radio' },
        alliance_3: { 'ui:widget': 'radio' },
      },
    },
  },
  ongoingAssessmentMetadata: {
    uiSchema: {
      default: {
        'ui:order': [
          'phq9_1',
          'phq9_2',
          'phq9_3',
          'phq9_4',
          'phq9_5',
          'phq9_6',
          'phq9_7',
          'phq9_8',
          'phq9_9',
          'gad7_1',
          'gad7_2',
          'gad7_3',
          'gad7_4',
          'gad7_5',
          'gad7_6',
          'gad7_7',
          'phq9_10',
          'alliance_1',
          'alliance_2',
          'alliance_3',
        ],
        phq9_1: { 'ui:widget': 'radio' },
        phq9_2: { 'ui:widget': 'radio' },
        phq9_3: { 'ui:widget': 'radio' },
        phq9_4: { 'ui:widget': 'radio' },
        phq9_5: { 'ui:widget': 'radio' },
        phq9_6: { 'ui:widget': 'radio' },
        phq9_7: { 'ui:widget': 'radio' },
        phq9_8: { 'ui:widget': 'radio' },
        phq9_9: { 'ui:widget': 'radio' },
        phq9_10: { 'ui:widget': 'radio' },
        gad7_1: { 'ui:widget': 'radio' },
        gad7_2: { 'ui:widget': 'radio' },
        gad7_3: { 'ui:widget': 'radio' },
        gad7_4: { 'ui:widget': 'radio' },
        gad7_5: { 'ui:widget': 'radio' },
        gad7_6: { 'ui:widget': 'radio' },
        gad7_7: { 'ui:widget': 'radio' },
        alliance_1: { 'ui:widget': 'radio' },
        alliance_2: { 'ui:widget': 'radio' },
        alliance_3: { 'ui:widget': 'radio' },
      },
      v2: {
        'ui:order': [
          'gad7_1',
          'gad7_2',
          'gad7_3',
          'gad7_4',
          'gad7_5',
          'gad7_6',
          'gad7_7',
          'phq9_1',
          'phq9_2',
          'phq9_3',
          'phq9_4',
          'phq9_5',
          'phq9_6',
          'phq9_7',
          'phq9_8',
          'phq9_9',
          'phq9_9_1',
          'phq9_9_2',
          'phq9_10',
          'alliance_1',
          'alliance_2',
          'alliance_3',
        ],
        phq9_1: { 'ui:widget': 'radio' },
        phq9_2: { 'ui:widget': 'radio' },
        phq9_3: { 'ui:widget': 'radio' },
        phq9_4: { 'ui:widget': 'radio' },
        phq9_5: { 'ui:widget': 'radio' },
        phq9_6: { 'ui:widget': 'radio' },
        phq9_7: { 'ui:widget': 'radio' },
        phq9_8: { 'ui:widget': 'radio' },
        phq9_9: { 'ui:widget': 'radio' },
        phq9_9_1: { 'ui:widget': 'radio' },
        phq9_9_2: { 'ui:widget': 'radio' },
        phq9_10: { 'ui:widget': 'radio' },
        gad7_1: { 'ui:widget': 'radio' },
        gad7_2: { 'ui:widget': 'radio' },
        gad7_3: { 'ui:widget': 'radio' },
        gad7_4: { 'ui:widget': 'radio' },
        gad7_5: { 'ui:widget': 'radio' },
        gad7_6: { 'ui:widget': 'radio' },
        gad7_7: { 'ui:widget': 'radio' },
        alliance_1: { 'ui:widget': 'radio' },
        alliance_2: { 'ui:widget': 'radio' },
        alliance_3: { 'ui:widget': 'radio' },
      },
      v3: {
        'ui:order': [
          'gadLabel',
          'gad7_1',
          'gad7_2',
          'gad7_3',
          'gad7_4',
          'gad7_5',
          'gad7_6',
          'gad7_7',
          'phqLabel',
          'phq9_1',
          'phq9_2',
          'phq9_3',
          'phq9_4',
          'phq9_5',
          'phq9_6',
          'phq9_7',
          'phq9_8',
          'phq9_9',
          'phq9_9_1',
          'phq9_9_2',
          'phq9_10',
          'allianceLabel',
          'alliance_1',
          'alliance_2',
          'alliance_3',
        ],
        phq9_1: { 'ui:widget': 'radio' },
        phq9_2: { 'ui:widget': 'radio' },
        phq9_3: { 'ui:widget': 'radio' },
        phq9_4: { 'ui:widget': 'radio' },
        phq9_5: { 'ui:widget': 'radio' },
        phq9_6: { 'ui:widget': 'radio' },
        phq9_7: { 'ui:widget': 'radio' },
        phq9_8: { 'ui:widget': 'radio' },
        phq9_9: { 'ui:widget': 'radio' },
        phq9_9_1: { 'ui:widget': 'radio' },
        phq9_9_2: { 'ui:widget': 'radio' },
        phq9_10: { 'ui:widget': 'radio' },
        gad7_1: { 'ui:widget': 'radio' },
        gad7_2: { 'ui:widget': 'radio' },
        gad7_3: { 'ui:widget': 'radio' },
        gad7_4: { 'ui:widget': 'radio' },
        gad7_5: { 'ui:widget': 'radio' },
        gad7_6: { 'ui:widget': 'radio' },
        gad7_7: { 'ui:widget': 'radio' },
        alliance_1: { 'ui:widget': 'radio' },
        alliance_2: { 'ui:widget': 'radio' },
        alliance_3: { 'ui:widget': 'radio' },
      },
    },
  },
  // DEPRECATED: The ui metadata for the finalAssessmentMetadata now lives in finalAssessmentMetadata.json
  finalAssessmentMetadata: {
    uiSchema: {
      default: {
        'ui:order': [
          'phq9_1',
          'phq9_2',
          'phq9_3',
          'phq9_4',
          'phq9_5',
          'phq9_6',
          'phq9_7',
          'phq9_8',
          'phq9_9',
          'gad7_1',
          'gad7_2',
          'gad7_3',
          'gad7_4',
          'gad7_5',
          'gad7_6',
          'gad7_7',
          'phq9_10',
          'alliance_1',
          'alliance_2',
          'alliance_3',
          'satisfaction_1',
          'satisfaction_2',
          'satisfaction_3',
          'satisfaction_4',
          'satisfaction_5',
          'satisfaction_6',
          'satisfaction_7',
        ],
        phq9_1: { 'ui:widget': 'radio' },
        phq9_2: { 'ui:widget': 'radio' },
        phq9_3: { 'ui:widget': 'radio' },
        phq9_4: { 'ui:widget': 'radio' },
        phq9_5: { 'ui:widget': 'radio' },
        phq9_6: { 'ui:widget': 'radio' },
        phq9_7: { 'ui:widget': 'radio' },
        phq9_8: { 'ui:widget': 'radio' },
        phq9_9: { 'ui:widget': 'radio' },
        phq9_10: { 'ui:widget': 'radio' },
        gad7_1: { 'ui:widget': 'radio' },
        gad7_2: { 'ui:widget': 'radio' },
        gad7_3: { 'ui:widget': 'radio' },
        gad7_4: { 'ui:widget': 'radio' },
        gad7_5: { 'ui:widget': 'radio' },
        gad7_6: { 'ui:widget': 'radio' },
        gad7_7: { 'ui:widget': 'radio' },
        alliance_1: { 'ui:widget': 'radio' },
        alliance_2: { 'ui:widget': 'radio' },
        alliance_3: { 'ui:widget': 'radio' },
        satisfaction_1: { 'ui:widget': 'radio' },
        satisfaction_2: { 'ui:widget': 'radio' },
        satisfaction_3: { 'ui:widget': 'radio' },
        satisfaction_4: { 'ui:widget': 'radio' },
        satisfaction_5: { 'ui:widget': 'radio' },
        satisfaction_6: { 'ui:widget': 'radio' },
        satisfaction_7: { 'ui:widget': 'textarea' },
      },
      v2: {
        'ui:order': [
          'gad7_1',
          'gad7_2',
          'gad7_3',
          'gad7_4',
          'gad7_5',
          'gad7_6',
          'gad7_7',
          'phq9_1',
          'phq9_2',
          'phq9_3',
          'phq9_4',
          'phq9_5',
          'phq9_6',
          'phq9_7',
          'phq9_8',
          'phq9_9',
          'phq9_9_1',
          'phq9_9_2',
          'phq9_10',
          'alliance_1',
          'alliance_2',
          'alliance_3',
          'satisfaction_1',
          'satisfaction_2',
          'satisfaction_3',
          'satisfaction_4',
          'satisfaction_5',
          'satisfaction_6',
          'satisfaction_7',
        ],
        phq9_1: { 'ui:widget': 'radio' },
        phq9_2: { 'ui:widget': 'radio' },
        phq9_3: { 'ui:widget': 'radio' },
        phq9_4: { 'ui:widget': 'radio' },
        phq9_5: { 'ui:widget': 'radio' },
        phq9_6: { 'ui:widget': 'radio' },
        phq9_7: { 'ui:widget': 'radio' },
        phq9_8: { 'ui:widget': 'radio' },
        phq9_9: { 'ui:widget': 'radio' },
        phq9_9_1: { 'ui:widget': 'radio' },
        phq9_9_2: { 'ui:widget': 'radio' },
        phq9_10: { 'ui:widget': 'radio' },
        gad7_1: { 'ui:widget': 'radio' },
        gad7_2: { 'ui:widget': 'radio' },
        gad7_3: { 'ui:widget': 'radio' },
        gad7_4: { 'ui:widget': 'radio' },
        gad7_5: { 'ui:widget': 'radio' },
        gad7_6: { 'ui:widget': 'radio' },
        gad7_7: { 'ui:widget': 'radio' },
        alliance_1: { 'ui:widget': 'radio' },
        alliance_2: { 'ui:widget': 'radio' },
        alliance_3: { 'ui:widget': 'radio' },
        satisfaction_1: { 'ui:widget': 'radio' },
        satisfaction_2: { 'ui:widget': 'radio' },
        satisfaction_3: { 'ui:widget': 'radio' },
        satisfaction_4: { 'ui:widget': 'radio' },
        satisfaction_5: { 'ui:widget': 'radio' },
        satisfaction_6: { 'ui:widget': 'radio' },
        satisfaction_7: { 'ui:widget': 'textarea' },
      },
      v3: {
        'ui:order': [
          'gadLabel',
          'gad7_1',
          'gad7_2',
          'gad7_3',
          'gad7_4',
          'gad7_5',
          'gad7_6',
          'gad7_7',
          'phqLabel',
          'phq9_1',
          'phq9_2',
          'phq9_3',
          'phq9_4',
          'phq9_5',
          'phq9_6',
          'phq9_7',
          'phq9_8',
          'phq9_9',
          'phq9_9_1',
          'phq9_9_2',
          'phq9_10',
          'allianceLabel',
          'alliance_1',
          'alliance_2',
          'alliance_3',
          'satisfactionLabel',
          'satisfaction_1',
          'satisfaction_2',
          'satisfaction_3',
          'satisfaction_4',
          'satisfaction_5',
          'satisfaction_6',
          'satisfaction_7',
          'productivity_label',
          'wlq_label1',
          'questions1Through_2',
          'wlq_1',
          'wlq_2',
          'nextTwoQuestions_5',
          'wlq_7',
          'wlq_8',
          'questionAsksAbout_8',
          'wlq_3',
          'nextQuestionAsks_10',
          'wlq_4',
          'questionsAskAbout_12',
          'wlq_5',
          'wlq_6',
          'wlq_label2',
          'nextQuestionsAsk_15',
          'aboutManyHours_16',
          'manyHoursDoes_17',
        ],
        phq9_1: { 'ui:widget': 'radio' },
        phq9_2: { 'ui:widget': 'radio' },
        phq9_3: { 'ui:widget': 'radio' },
        phq9_4: { 'ui:widget': 'radio' },
        phq9_5: { 'ui:widget': 'radio' },
        phq9_6: { 'ui:widget': 'radio' },
        phq9_7: { 'ui:widget': 'radio' },
        phq9_8: { 'ui:widget': 'radio' },
        phq9_9: { 'ui:widget': 'radio' },
        phq9_9_1: { 'ui:widget': 'radio' },
        phq9_9_2: { 'ui:widget': 'radio' },
        phq9_10: { 'ui:widget': 'radio' },
        gad7_1: { 'ui:widget': 'radio' },
        gad7_2: { 'ui:widget': 'radio' },
        gad7_3: { 'ui:widget': 'radio' },
        gad7_4: { 'ui:widget': 'radio' },
        gad7_5: { 'ui:widget': 'radio' },
        gad7_6: { 'ui:widget': 'radio' },
        gad7_7: { 'ui:widget': 'radio' },
        alliance_1: { 'ui:widget': 'radio' },
        alliance_2: { 'ui:widget': 'radio' },
        alliance_3: { 'ui:widget': 'radio' },
        satisfaction_1: { 'ui:widget': 'radio' },
        satisfaction_2: { 'ui:widget': 'radio' },
        satisfaction_3: { 'ui:widget': 'radio' },
        satisfaction_4: { 'ui:widget': 'radio' },
        satisfaction_5: { 'ui:widget': 'radio' },
        satisfaction_6: { 'ui:widget': 'radio' },
        satisfaction_7: { 'ui:widget': 'textarea' },
        wlq_1: { 'ui:widget': 'radio' },
        wlq_2: { 'ui:widget': 'radio' },
        wlq_7: { 'ui:widget': 'radio' },
        wlq_8: { 'ui:widget': 'radio' },
        wlq_3: { 'ui:widget': 'radio' },
        wlq_4: { 'ui:widget': 'radio' },
        wlq_5: { 'ui:widget': 'radio' },
        wlq_6: { 'ui:widget': 'radio' },
      },
    },
  },
  // DEPRECATED: The ui metadata for the mildFinalAssessmentMetadata now lives in mildFinalAssessmentMetadata.json
  mildFinalAssessmentMetadata: {
    uiSchema: {
      'ui:order': [
        'pssLabel',
        'pss_1',
        'pss_2',
        'pss_3',
        'pss_4',
        'pss_5',
        'pss_6',
        'pss_7',
        'pss_8',
        'pss_9',
        'pss_10',
        'wellBeingLabel',
        'wellBeing_1',
        'wellBeing_2',
        'wellBeing_3',
        'wellBeing_4',
        'wellBeing_5',
        'wellBeing_6',
        'wellBeing_7',
        'wellBeing_8',
        'wellBeing_9',
        'wellBeing_10',
        'wellBeing_11',
        'wellBeing_12',
        'wellBeing_13',
        'wellBeing_14',
        'allianceLabel',
        'alliance_1',
        'alliance_2',
        'alliance_3',
        'satisfaction_1',
        'satisfactionLabel',
        'satisfaction_2',
        'satisfaction_3',
        'satisfaction_4',
        'satisfaction_5',
        'satisfaction_6',
        'satisfaction_7',
      ],
      pss_1: { 'ui:widget': 'radio' },
      pss_2: { 'ui:widget': 'radio' },
      pss_3: { 'ui:widget': 'radio' },
      pss_4: { 'ui:widget': 'radio' },
      pss_5: { 'ui:widget': 'radio' },
      pss_6: { 'ui:widget': 'radio' },
      pss_7: { 'ui:widget': 'radio' },
      pss_8: { 'ui:widget': 'radio' },
      pss_9: { 'ui:widget': 'radio' },
      pss_10: { 'ui:widget': 'radio' },
      wellBeing_1: { 'ui:widget': 'radio' },
      wellBeing_2: { 'ui:widget': 'radio' },
      wellBeing_3: { 'ui:widget': 'radio' },
      wellBeing_4: { 'ui:widget': 'radio' },
      wellBeing_5: { 'ui:widget': 'radio' },
      wellBeing_6: { 'ui:widget': 'radio' },
      wellBeing_7: { 'ui:widget': 'radio' },
      wellBeing_8: { 'ui:widget': 'radio' },
      wellBeing_9: { 'ui:widget': 'radio' },
      wellBeing_10: { 'ui:widget': 'radio' },
      wellBeing_11: { 'ui:widget': 'radio' },
      wellBeing_12: { 'ui:widget': 'radio' },
      wellBeing_13: { 'ui:widget': 'radio' },
      wellBeing_14: { 'ui:widget': 'radio' },
      alliance_1: { 'ui:widget': 'radio' },
      alliance_2: { 'ui:widget': 'radio' },
      alliance_3: { 'ui:widget': 'radio' },
      satisfaction_1: { 'ui:widget': 'radio' },
      satisfaction_2: { 'ui:widget': 'radio' },
      satisfaction_3: { 'ui:widget': 'radio' },
      satisfaction_4: { 'ui:widget': 'radio' },
      satisfaction_5: { 'ui:widget': 'radio' },
      satisfaction_6: { 'ui:widget': 'radio' },
    },
  },
  // DEPRECATED: The ui metadata for the clientIntake now lives in clientIntake.json
  clientIntake: {
    uiSchema: {
      default: {
        'ui:order': [
          'emergencyInfo',
          'emergencyContact',
          'personalInfo',
          'familyHistory',
          'educationHistory',
          'currentProblems',
          'legalProceedings',
          'treatmentGoals',
          'wellBeing',
          'informedConsent',
        ],
        personalInfo: {
          items: [
            {},
            {},
            {},
            {},
            { 'ui:widget': 'radio' },
            {},
            {},
            {},
            {},
            {},
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
          ],
        },
        wellBeing: { items: { 'ui:widget': 'radio' } },
        ethnicity: { 'ui:widget': 'checkbox' },
        informedConsent: { 'ui:field': 'InformedConsent' },
        familyHistory: {
          items: [
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'textarea' },
          ],
        },
        educationHistory: {
          items: [
            { 'ui:widget': 'text' },
            { 'ui:widget': 'text' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'text' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'textarea' },
          ],
        },
        emergencyInfo: { items: [{ 'ui:widget': 'radio' }, { 'ui:widget': 'textarea' }, { 'ui:widget': 'textarea' }] },
        currentProblems: {
          items: [
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'text' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'text' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'text' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'text' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'textarea' },
          ],
        },
        legalProceedings: { items: [{ 'ui:widget': 'radio' }, { 'ui:widget': 'textarea' }] },
        treatmentGoals: { items: [{ 'ui:widget': 'textarea' }] },
      },
      v2: {
        'ui:order': [
          'emergencyInfo',
          'emergencyContact',
          'personalInfo',
          'familyHistory',
          'educationHistory',
          'currentProblems',
          'otherQuestions',
          'wellBeing',
          'informedConsent',
        ],
        emergencyContact: {
          items: [
            { 'ui:widget': 'text' },
            { 'ui:widget': 'text' },
            { 'ui:widget': 'mask', 'ui:options': { mask: '111-111-1111' } },
          ],
        },
        personalInfo: {
          items: [
            { 'ui:widget': 'text' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'text' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'select' },
            { 'ui:widget': 'text' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'date' },
            { 'ui:widget': 'mask', 'ui:options': { mask: '1111-11' } },
            { 'ui:widget': 'text' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
          ],
        },
        wellBeing: { items: { 'ui:widget': 'radio' } },
        ethnicity: { 'ui:widget': 'checkbox' },
        informedConsent: { 'ui:field': 'InformedConsent' },
        familyHistory: {
          items: [
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'radio' },
          ],
        },
        educationHistory: {
          items: [
            { 'ui:widget': 'select' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'text' },
            { 'ui:widget': 'textarea' },
          ],
        },
        emergencyInfo: { items: [{ 'ui:widget': 'radio' }, { 'ui:widget': 'textarea' }, { 'ui:widget': 'textarea' }] },
        currentProblems: {
          items: [
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'mask', 'ui:options': { mask: '1111-11' } },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'text' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'text' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'select' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'mask', 'ui:options': { mask: '1111-11' } },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'mask', 'ui:options': { mask: '1111-11' } },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'mask', 'ui:options': { mask: '1111-11' } },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'mask', 'ui:options': { mask: '1111-11' } },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'radio' },
          ],
        },
        otherQuestions: {
          items: [
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'select' },
            { 'ui:widget': 'text' },
          ],
        },
      },
      v3: {
        'ui:order': [
          'emergencyInfo',
          'emergencyContact',
          'personalInfo',
          'familyHistory',
          'educationHistory',
          'currentProblems',
          'otherQuestions',
          'informedConsent',
        ],
        emergencyContact: {
          items: [
            { 'ui:widget': 'text' },
            { 'ui:widget': 'text' },
            { 'ui:widget': 'mask', 'ui:options': { mask: '111-111-1111' } },
          ],
        },
        personalInfo: {
          items: [
            { 'ui:widget': 'text' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'text' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'select' },
            { 'ui:widget': 'text' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'date' },
            { 'ui:widget': 'mask', 'ui:options': { mask: '1111-11' } },
            { 'ui:widget': 'text' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
          ],
        },
        ethnicity: { 'ui:widget': 'checkbox' },
        informedConsent: { 'ui:field': 'InformedConsent' },
        familyHistory: {
          items: [
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'radio' },
          ],
        },
        educationHistory: {
          items: [
            { 'ui:widget': 'select' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'text' },
            { 'ui:widget': 'textarea' },
          ],
        },
        emergencyInfo: { items: [{ 'ui:widget': 'radio' }, { 'ui:widget': 'textarea' }, { 'ui:widget': 'textarea' }] },
        currentProblems: {
          items: [
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'mask', 'ui:options': { mask: '1111-11' } },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'text' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'text' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'select' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'mask', 'ui:options': { mask: '1111-11' } },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'mask', 'ui:options': { mask: '1111-11' } },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'mask', 'ui:options': { mask: '1111-11' } },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'mask', 'ui:options': { mask: '1111-11' } },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'radio' },
          ],
        },
        otherQuestions: {
          items: [
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'select' },
            { 'ui:widget': 'text' },
          ],
        },
      },
    },
  },
  assertiveCommunication: {
    uiSchema: {
      'ui:order': [
        'questionsLabel',
        'whatDidYouAskFor',
        'situation',
        'howYouFeel',
        'askForWhatYouWant',
        'reinforceYourPosition',
        'beMindful',
        'appearConfident',
        'beWillingToNegotitate',
      ],
      whatDidYouAskFor: { 'ui:widget': 'textarea' },
      situation: { 'ui:widget': 'textarea' },
      howYouFeel: { 'ui:widget': 'textarea' },
      askForWhatYouWant: { 'ui:widget': 'textarea' },
      reinforceYourPosition: { 'ui:widget': 'textarea' },
      beMindful: { 'ui:widget': 'textarea' },
      appearConfident: { 'ui:widget': 'textarea' },
      beWillingToNegotitate: { 'ui:widget': 'textarea' },
    },
  },
  validation: {
    uiSchema: {
      'ui:order': [
        'situation',
        'checkBoxesLabel',
        'payAttention',
        'payAttentionDescription',
        'refectBack',
        'reflectBackDescription',
        'understand',
        'understandDescription',
        'validate',
        'validateDescription',
        'beGenuine',
        'beGenuineDescription',
      ],
      situation: { 'ui:widget': 'textarea' },
      payAttention: { 'ui:widget': 'checkbox' },
      payAttentionDescription: { 'ui:widget': 'textarea' },
      refectBack: { 'ui:widget': 'checkbox' },
      reflectBackDescription: { 'ui:widget': 'textarea' },
      understand: { 'ui:widget': 'checkbox' },
      understandDescription: { 'ui:widget': 'textarea' },
      validate: { 'ui:widget': 'checkbox' },
      validateDescription: { 'ui:widget': 'textarea' },
      beGenuine: { 'ui:widget': 'checkbox' },
      beGenuineDescription: { 'ui:widget': 'textarea' },
    },
    report: {
      fieldsToDisplay: ['situation'],
    },
  },
  medsClientIntake: {
    uiSchema: {
      'ui:order': [
        'personalInformation_2_1',
        'your_name_2_2',
        'fullLegalName_3',
        'firstName_4',
        'middleName_5',
        'name_6',
        'preferredName_15',
        'preferredPronoun',
        'pronounOther',
        'contactInformationReach_7',
        'contactEmergency_7_1',
        'homeAddressPharmacy_9',
        'homeAddressStreet',
        'homeAddressCity',
        'homeAddressState',
        'homeAddressZipCode',
        'locationForSessions_9_1',
        'usualLocationDuring_10',
        'otherAddressStreet',
        'otherAddressCity',
        'otherAddressState',
        'otherAddressZipCode',
        'primaryPhone',
        'leaveVoicemailsPersonal_8',
        'emergencyContact',
        'emergencyContactFirst_11',
        'emergencyContactLast_11_1',
        'emergencyContactRelationship_12',
        'emergencyContactPhone_13',
        'currentProblemsTreatment_40',
        'problemBroughtSee_41',
        'returnToCare_96',
        'problemsStart_42',
        'goalsWorkingPrescriber_48',
        'checkSymptomsExperienced_81',
        'psychiatricHistory',
        'currentlySeeingMental_49',
        'currentlySeeingMental_50',
        'previouslyReceivedMental_51',
        'previouslyReceivedMental_52',
        'previousPsychiatricDiagnoses_45',
        'previousPsychiatricDiagnoses_46',
        'previousPsychiatricDiagnoses_47',
        'currentlyTakingPrescription_60',
        'takingMedicationsCurrently_61',
        'otherPsychiatricMedicationTaken',
        'currentlyTakingPrescription_62',
        'hereRefillToday_55',
        'hereRefillToday_56',
        'hereRefillToday_58',
        'hereRefillToday_57',
        'pastPsychiatricMedications',
        'psychiatricMedicationsTaken_53',
        'otherMedicationTaken',
        'hospitalizedInpatientPartial_78',
        'hospitalizedInpatientPartial_79',
        'sideEffectsAffected_54',
        'attemptedSuicide_72',
        'attemptedSuicide_73',
        'purposelySelfInjured_74',
        'purposelySelfInjured_75',
        'assaultedCommittedAct_76',
        'assaultedCommittedAct_77',
        'accessGun_80',
        'medicalHistorySection',
        'medicationAllergiesAll_63',
        'chronicOngoingMedical_64',
        'otherChronicMedicalConditions',
        'pregnantPlanningBecome_66',
        'havePainToday',
        'levelPainToday_67',
        'levelPainToday_68',
        'ratePhysicalHealth_71',
        'behaviorsSection',
        'fallAsleepAverage_83',
        'wakeUpAverage_84',
        'manyCaffeinatedBeverages_82',
        'oftenDrinkContaining_85',
        'oftenDrinkContaining_86',
        'oftenDrinkContaining_87',
        'oftenUseTobacco_88',
        'oftenUsePrescription_89',
        'oftenUseIllegal_90',
        'oftenUseCannabis_91',
        'oftenElectronicCigarette_92',
        'familySocialHistory_27',
        'raceEthnicityCheck_16',
        'raceEthnicityOther_17',
        'considerSpiritualReligious_18',
        'faithBelief_19',
        'household',
        'relationshipStatus_20',
        'numberPersonsLiving_26',
        'childrenGenderAge_25',
        'familySocialHistory_27_1',
        'agesMotherFather_28',
        'anyoneParticularSupport_30',
        'historyPsychiatricEmotional_31',
        'historyPsychiatricEmotional_32',
        'educationEmploymentHistory_34',
        'highestLevelEducation_35',
        'workingNow_36',
        'workingNow_37',
        'workingNow_38',
        'workingNow_39',
        'financialAndLegal',
        'rateFinancialStatus_33',
        'currentlyInvolvedLegal_94',
        'currentlyInvolvedLegal_95',
        'cancellationPolicyLabel',
        'cancellationPolicy',
        'informedConsent_98',
      ],
      firstName_4: {
        'ui:widget': 'text',
      },
      homeAddressStreet: {
        'ui:widget': 'text',
      },
      homeAddressCity: {
        'ui:widget': 'text',
      },
      homeAddressState: {
        'ui:widget': 'select',
      },
      homeAddressZipCode: {
        'ui:widget': 'mask',
        'ui:options': { mask: '11111' },
      },
      middleName_5: {
        'ui:widget': 'text',
      },
      name_6: {
        'ui:widget': 'text',
      },
      leaveVoicemailsPersonal_8: {
        'ui:widget': 'radio',
      },
      locationForSessions_9_1: {
        'ui:widget': 'radio',
      },
      otherAddressStreet: {
        'ui:widget': 'text',
      },
      otherAddressCity: {
        'ui:widget': 'text',
      },
      otherAddressState: {
        'ui:widget': 'select',
      },
      otherAddressZipCode: {
        'ui:widget': 'mask',
        'ui:options': { mask: '11111' },
      },
      primaryPhone: {
        'ui:widget': 'mask',
        'ui:options': { mask: '111-111-1111' },
      },
      emergencyContactFirst_11: {
        'ui:widget': 'text',
      },
      emergencyContactLast_11_1: {
        'ui:widget': 'text',
      },
      emergencyContactRelationship_12: {
        'ui:widget': 'text',
      },
      emergencyContactPhone_13: {
        'ui:widget': 'mask',
        'ui:options': { mask: '111-111-1111' },
      },
      preferredName_15: {
        'ui:widget': 'text',
      },
      preferredPronoun: {
        'ui:widget': 'checkbox',
      },
      pronounOther: {
        'ui:widget': 'text',
      },
      raceEthnicityCheck_16: {
        'ui:widget': 'checkbox',
      },
      raceEthnicityOther_17: {
        'ui:widget': 'text',
      },
      considerSpiritualReligious_18: {
        'ui:widget': 'radio',
      },
      faithBelief_19: {
        'ui:widget': 'text',
      },
      relationshipStatus_20: {
        'ui:widget': 'select',
      },
      childrenGenderAge_25: {
        'ui:widget': 'textarea',
      },
      numberPersonsLiving_26: {
        'ui:widget': 'textarea',
      },
      agesMotherFather_28: {
        'ui:widget': 'textarea',
      },
      anyoneParticularSupport_30: {
        'ui:widget': 'textarea',
      },
      historyPsychiatricEmotional_31: {
        'ui:widget': 'radio',
      },
      historyPsychiatricEmotional_32: {
        'ui:widget': 'textarea',
      },
      rateFinancialStatus_33: {
        'ui:widget': 'radio',
      },
      highestLevelEducation_35: {
        'ui:widget': 'select',
      },
      workingNow_36: {
        'ui:widget': 'radio',
      },
      workingNow_37: {
        'ui:widget': 'textarea',
      },
      workingNow_38: {
        'ui:widget': 'radio',
      },
      workingNow_39: {
        'ui:widget': 'textarea',
      },
      problemBroughtSee_41: {
        'ui:widget': 'textarea',
      },
      returnToCare_96: {
        'ui:widget': 'select',
      },
      problemsStart_42: {
        'ui:widget': 'text',
      },
      previousPsychiatricDiagnoses_45: {
        'ui:widget': 'radio',
      },
      previousPsychiatricDiagnoses_46: {
        'ui:widget': 'checkbox',
      },
      previousPsychiatricDiagnoses_47: {
        'ui:widget': 'textarea',
      },
      goalsWorkingPrescriber_48: {
        'ui:widget': 'textarea',
      },
      currentlySeeingMental_49: {
        'ui:widget': 'radio',
      },
      currentlySeeingMental_50: {
        'ui:widget': 'textarea',
      },
      previouslyReceivedMental_51: {
        'ui:widget': 'radio',
      },
      previouslyReceivedMental_52: {
        'ui:widget': 'textarea',
      },
      pastPsychiatricMedications: {
        'ui:widget': 'radio',
      },
      psychiatricMedicationsTaken_53: {
        'ui:widget': 'checkbox',
      },
      otherMedicationTaken: {
        'ui:widget': 'text',
      },
      sideEffectsAffected_54: {
        'ui:widget': 'checkbox',
      },
      hereRefillToday_55: {
        'ui:widget': 'radio',
      },
      hereRefillToday_56: {
        'ui:widget': 'textarea',
      },
      hereRefillToday_57: {
        'ui:widget': 'text',
      },
      hereRefillToday_58: {
        'ui:widget': 'radio',
      },
      currentlyTakingPrescription_60: {
        'ui:widget': 'radio',
      },
      takingMedicationsCurrently_61: {
        'ui:widget': 'checkbox',
      },
      otherPsychiatricMedicationTaken: {
        'ui:widget': 'text',
      },
      currentlyTakingPrescription_62: {
        'ui:widget': 'textarea',
      },
      medicationAllergiesAll_63: {
        'ui:widget': 'textarea',
      },
      chronicOngoingMedical_64: {
        'ui:widget': 'checkbox',
      },
      otherChronicMedicalConditions: {
        'ui:widget': 'text',
      },
      pregnantPlanningBecome_66: {
        'ui:widget': 'radio',
      },
      havePainToday: {
        'ui:widget': 'radio',
      },
      levelPainToday_67: {
        'ui:widget': 'range',
      },
      levelPainToday_68: {
        'ui:widget': 'text',
      },
      ratePhysicalHealth_71: {
        'ui:widget': 'select',
      },
      attemptedSuicide_72: {
        'ui:widget': 'radio',
      },
      attemptedSuicide_73: {
        'ui:widget': 'text',
      },
      purposelySelfInjured_74: {
        'ui:widget': 'radio',
      },
      purposelySelfInjured_75: {
        'ui:widget': 'text',
      },
      assaultedCommittedAct_76: {
        'ui:widget': 'radio',
      },
      assaultedCommittedAct_77: {
        'ui:widget': 'text',
      },
      hospitalizedInpatientPartial_78: {
        'ui:widget': 'radio',
      },
      hospitalizedInpatientPartial_79: {
        'ui:widget': 'text',
      },
      accessGun_80: {
        'ui:widget': 'radio',
      },
      checkSymptomsExperienced_81: {
        'ui:widget': 'checkbox',
      },
      manyCaffeinatedBeverages_82: {
        'ui:widget': 'text',
      },
      fallAsleepAverage_83: {
        'ui:widget': 'text',
      },
      wakeUpAverage_84: {
        'ui:widget': 'text',
      },
      oftenDrinkContaining_85: {
        'ui:widget': 'select',
      },
      oftenDrinkContaining_86: {
        'ui:widget': 'radio',
      },
      oftenDrinkContaining_87: {
        'ui:widget': 'radio',
      },
      oftenUseTobacco_88: {
        'ui:widget': 'select',
      },
      oftenUsePrescription_89: {
        'ui:widget': 'select',
      },
      oftenUseIllegal_90: {
        'ui:widget': 'select',
      },
      oftenUseCannabis_91: {
        'ui:widget': 'select',
      },
      oftenElectronicCigarette_92: {
        'ui:widget': 'select',
      },
      currentlyInvolvedLegal_94: {
        'ui:widget': 'radio',
      },
      currentlyInvolvedLegal_95: {
        'ui:widget': 'textarea',
      },
      informedConsent_98: {
        'ui:field': 'MedsInformedConsent',
      },
    },
    initialValues: {
      levelPainToday_67: 1,
    },
  },
  medsBaselineExtendedMetadata: {
    uiSchema: {
      default: {
        'ui:order': [
          'phqLabel',
          'phq9_1',
          'phq9_2',
          'phq9_3',
          'phq9_4',
          'phq9_5',
          'phq9_6',
          'phq9_7',
          'phq9_8',
          'phq9_9',
          'phq9_9_1',
          'phq9_9_2',
          'phq9_10',
          'gadLabel',
          'gad7_1',
          'gad7_2',
          'gad7_3',
          'gad7_4',
          'gad7_5',
          'gad7_6',
          'gad7_7',
          'swls_label',
          'swls_1',
          'swls_2',
          'swls_3',
          'swls_4',
          'swls_5',
          'productivity_label',
          'wlq_label1',
          'questions1Through_2',
          'past2Weeks_3',
          'past2Weeks_4',
          'nextTwoQuestions_5',
          'past2Weeks_6',
          'past2Weeks_7',
          'questionAsksAbout_8',
          'past2Weeks_9',
          'nextQuestionAsks_10',
          'past2Weeks_11',
          'questionsAskAbout_12',
          'past2Weeks_13',
          'past2Weeks_14',
          'wlq_label2',
          'nextQuestionsAsk_15',
          'aboutManyHours_16',
          'manyHoursDoes_17',
        ],
        phq9_1: { 'ui:widget': 'radio' },
        phq9_2: { 'ui:widget': 'radio' },
        phq9_3: { 'ui:widget': 'radio' },
        phq9_4: { 'ui:widget': 'radio' },
        phq9_5: { 'ui:widget': 'radio' },
        phq9_6: { 'ui:widget': 'radio' },
        phq9_7: { 'ui:widget': 'radio' },
        phq9_8: { 'ui:widget': 'radio' },
        phq9_9: { 'ui:widget': 'radio' },
        phq9_9_1: { 'ui:widget': 'radio' },
        phq9_9_2: { 'ui:widget': 'radio' },
        phq9_10: { 'ui:widget': 'radio' },
        gad7_1: { 'ui:widget': 'radio' },
        gad7_2: { 'ui:widget': 'radio' },
        gad7_3: { 'ui:widget': 'radio' },
        gad7_4: { 'ui:widget': 'radio' },
        gad7_5: { 'ui:widget': 'radio' },
        gad7_6: { 'ui:widget': 'radio' },
        gad7_7: { 'ui:widget': 'radio' },
        swls_1: { 'ui:widget': 'radio' },
        swls_2: { 'ui:widget': 'radio' },
        swls_3: { 'ui:widget': 'radio' },
        swls_4: { 'ui:widget': 'radio' },
        swls_5: { 'ui:widget': 'radio' },
        past2Weeks_3: { 'ui:widget': 'radio' },
        past2Weeks_4: { 'ui:widget': 'radio' },
        past2Weeks_6: { 'ui:widget': 'radio' },
        past2Weeks_7: { 'ui:widget': 'radio' },
        past2Weeks_9: { 'ui:widget': 'radio' },
        past2Weeks_11: { 'ui:widget': 'radio' },
        past2Weeks_13: { 'ui:widget': 'radio' },
        past2Weeks_14: { 'ui:widget': 'radio' },
      },
      v2: {
        'ui:order': [
          'phqLabel',
          'phq9_1',
          'phq9_2',
          'phq9_3',
          'phq9_4',
          'phq9_5',
          'phq9_6',
          'phq9_7',
          'phq9_8',
          'phq9_9',
          'phq9_9_1',
          'phq9_9_2',
          'phq9_10',
          'gadLabel',
          'gad7_1',
          'gad7_2',
          'gad7_3',
          'gad7_4',
          'gad7_5',
          'gad7_6',
          'gad7_7',
          'swls_label',
          'swls_1',
          'swls_2',
          'swls_3',
          'swls_4',
          'swls_5',
          'productivity_label',
          'wlq_label1',
          'questions1Through_2',
          'wlq_1',
          'wlq_2',
          'nextTwoQuestions_5',
          'wlq_7',
          'wlq_8',
          'questionAsksAbout_8',
          'wlq_3',
          'nextQuestionAsks_10',
          'wlq_4',
          'questionsAskAbout_12',
          'wlq_5',
          'wlq_6',
          'wlq_label2',
          'nextQuestionsAsk_15',
          'aboutManyHours_16',
          'manyHoursDoes_17',
        ],
        phq9_1: { 'ui:widget': 'radio' },
        phq9_2: { 'ui:widget': 'radio' },
        phq9_3: { 'ui:widget': 'radio' },
        phq9_4: { 'ui:widget': 'radio' },
        phq9_5: { 'ui:widget': 'radio' },
        phq9_6: { 'ui:widget': 'radio' },
        phq9_7: { 'ui:widget': 'radio' },
        phq9_8: { 'ui:widget': 'radio' },
        phq9_9: { 'ui:widget': 'radio' },
        phq9_9_1: { 'ui:widget': 'radio' },
        phq9_9_2: { 'ui:widget': 'radio' },
        phq9_10: { 'ui:widget': 'radio' },
        gad7_1: { 'ui:widget': 'radio' },
        gad7_2: { 'ui:widget': 'radio' },
        gad7_3: { 'ui:widget': 'radio' },
        gad7_4: { 'ui:widget': 'radio' },
        gad7_5: { 'ui:widget': 'radio' },
        gad7_6: { 'ui:widget': 'radio' },
        gad7_7: { 'ui:widget': 'radio' },
        swls_1: { 'ui:widget': 'radio' },
        swls_2: { 'ui:widget': 'radio' },
        swls_3: { 'ui:widget': 'radio' },
        swls_4: { 'ui:widget': 'radio' },
        swls_5: { 'ui:widget': 'radio' },
        wlq_1: { 'ui:widget': 'radio' },
        wlq_2: { 'ui:widget': 'radio' },
        wlq_7: { 'ui:widget': 'radio' },
        wlq_8: { 'ui:widget': 'radio' },
        wlq_3: { 'ui:widget': 'radio' },
        wlq_4: { 'ui:widget': 'radio' },
        wlq_5: { 'ui:widget': 'radio' },
        wlq_6: { 'ui:widget': 'radio' },
      },
    },
  },
  medsBaselineMetadata: {
    uiSchema: {
      default: {
        'ui:order': [
          'swls_label',
          'swls_1',
          'swls_2',
          'swls_3',
          'swls_4',
          'swls_5',
          'productivity_label',
          'wlq_label1',
          'questions1Through_2',
          'past2Weeks_3',
          'past2Weeks_4',
          'nextTwoQuestions_5',
          'past2Weeks_6',
          'past2Weeks_7',
          'questionAsksAbout_8',
          'past2Weeks_9',
          'nextQuestionAsks_10',
          'past2Weeks_11',
          'questionsAskAbout_12',
          'past2Weeks_13',
          'past2Weeks_14',
          'wlq_label2',
          'nextQuestionsAsk_15',
          'aboutManyHours_16',
          'manyHoursDoes_17',
        ],
        swls_1: { 'ui:widget': 'radio' },
        swls_2: { 'ui:widget': 'radio' },
        swls_3: { 'ui:widget': 'radio' },
        swls_4: { 'ui:widget': 'radio' },
        swls_5: { 'ui:widget': 'radio' },
        past2Weeks_3: { 'ui:widget': 'radio' },
        past2Weeks_4: { 'ui:widget': 'radio' },
        past2Weeks_6: { 'ui:widget': 'radio' },
        past2Weeks_7: { 'ui:widget': 'radio' },
        past2Weeks_9: { 'ui:widget': 'radio' },
        past2Weeks_11: { 'ui:widget': 'radio' },
        past2Weeks_13: { 'ui:widget': 'radio' },
        past2Weeks_14: { 'ui:widget': 'radio' },
      },
      v2: {
        'ui:order': [
          'swls_label',
          'swls_1',
          'swls_2',
          'swls_3',
          'swls_4',
          'swls_5',
          'productivity_label',
          'wlq_label1',
          'questions1Through_2',
          'wlq_1',
          'wlq_2',
          'nextTwoQuestions_5',
          'wlq_7',
          'wlq_8',
          'questionAsksAbout_8',
          'wlq_3',
          'nextQuestionAsks_10',
          'wlq_4',
          'questionsAskAbout_12',
          'wlq_5',
          'wlq_6',
          'wlq_label2',
          'nextQuestionsAsk_15',
          'aboutManyHours_16',
          'manyHoursDoes_17',
        ],
        swls_1: { 'ui:widget': 'radio' },
        swls_2: { 'ui:widget': 'radio' },
        swls_3: { 'ui:widget': 'radio' },
        swls_4: { 'ui:widget': 'radio' },
        swls_5: { 'ui:widget': 'radio' },
        wlq_1: { 'ui:widget': 'radio' },
        wlq_2: { 'ui:widget': 'radio' },
        wlq_7: { 'ui:widget': 'radio' },
        wlq_8: { 'ui:widget': 'radio' },
        wlq_3: { 'ui:widget': 'radio' },
        wlq_4: { 'ui:widget': 'radio' },
        wlq_5: { 'ui:widget': 'radio' },
        wlq_6: { 'ui:widget': 'radio' },
      },
    },
  },
  medsPreSessionBamMetadata: {
    uiSchema: {
      'ui:order': [
        'standardSetQuestions_1',
        'past30Days_2',
        'past30Days_3',
        'past30Days_4',
        'past30Days_5',
        'past30Days_6',
        'past30Days_7',
        'past30Days_8',
        'marijuanaCannabisPot_9',
        'sedativesTranquilizersBenzos_10',
        'cocaineCrack_11',
        'otherStimulantsAmphetamine_12',
        'opiatesHeroinMorphine_13',
        'inhalantsGluesAdhesives_14',
        'otherDrugsSteroids_15',
        'past30Days_16',
        'confidentNotUse_17',
        'past30Days_18',
        'past30Days_19',
        'doesReligionSpirituality_20',
        'past30Days_21',
        'enoughIncomeFrom_22',
        'past30Days_23',
        'past30Days_24',
        'satisfiedProgressToward_25',
      ],
      past30Days_2: { 'ui:widget': 'radio' },
      past30Days_3: { 'ui:widget': 'radio' },
      past30Days_4: { 'ui:widget': 'radio' },
      past30Days_5: { 'ui:widget': 'radio' },
      past30Days_6: { 'ui:widget': 'radio' },
      past30Days_7: { 'ui:widget': 'radio' },
      marijuanaCannabisPot_9: { 'ui:widget': 'radio' },
      sedativesTranquilizersBenzos_10: { 'ui:widget': 'radio' },
      cocaineCrack_11: { 'ui:widget': 'radio' },
      otherStimulantsAmphetamine_12: { 'ui:widget': 'radio' },
      opiatesHeroinMorphine_13: { 'ui:widget': 'radio' },
      inhalantsGluesAdhesives_14: { 'ui:widget': 'radio' },
      otherDrugsSteroids_15: { 'ui:widget': 'radio' },
      past30Days_16: { 'ui:widget': 'radio' },
      confidentNotUse_17: { 'ui:widget': 'radio' },
      past30Days_18: { 'ui:widget': 'radio' },
      past30Days_19: { 'ui:widget': 'radio' },
      doesReligionSpirituality_20: { 'ui:widget': 'radio' },
      past30Days_21: { 'ui:widget': 'radio' },
      enoughIncomeFrom_22: { 'ui:widget': 'radio' },
      past30Days_23: { 'ui:widget': 'radio' },
      past30Days_24: { 'ui:widget': 'radio' },
      satisfiedProgressToward_25: { 'ui:widget': 'radio' },
    },
  },
  medsPreSessionEddsMetadata: {
    uiSchema: {
      'ui:order': [
        'overPast3_1',
        'feltFat_2',
        'definiteFearMight_3',
        'hasWeightInfluenced_4',
        'hasShapeInfluenced_5',
        'duringPast6_6',
        'duringTimesAte_7',
        'manyDaysPer_8',
        'manyTimesPer_9',
        'duringEpisodesOvereating_10',
        'eatMuchRapidly_11',
        'eatUntilFelt_12',
        'eatLargeAmounts_13',
        'eatAloneBecause_14',
        'feelDisgustedDepressed_15',
        'feelVeryUpset_16',
        'manyTimesPer_17',
        'manyTimesPer_18',
        'manyTimesPer_19',
        'manyTimesPer_20',
        'muchWeighLbs_21',
        'tallSpecifyInches_22',
        'overPast3_23',
        'takingBirthControl_24',
      ],
      feltFat_2: { 'ui:widget': 'range' },
      definiteFearMight_3: { 'ui:widget': 'range' },
      hasWeightInfluenced_4: { 'ui:widget': 'range' },
      hasShapeInfluenced_5: { 'ui:widget': 'range' },
      duringPast6_6: { 'ui:widget': 'radio' },
      duringTimesAte_7: { 'ui:widget': 'radio' },
      eatMuchRapidly_11: { 'ui:widget': 'radio' },
      eatUntilFelt_12: { 'ui:widget': 'radio' },
      eatLargeAmounts_13: { 'ui:widget': 'radio' },
      eatAloneBecause_14: { 'ui:widget': 'radio' },
      feelDisgustedDepressed_15: { 'ui:widget': 'radio' },
      feelVeryUpset_16: { 'ui:widget': 'radio' },
      manyDaysPer_8: { 'ui:widget': 'range' },
      manyTimesPer_9: { 'ui:widget': 'range' },
      manyTimesPer_17: { 'ui:widget': 'range' },
      manyTimesPer_18: { 'ui:widget': 'range' },
      manyTimesPer_19: { 'ui:widget': 'range' },
      manyTimesPer_20: { 'ui:widget': 'range' },
      overPast3_23: { 'ui:widget': 'radio' },
      takingBirthControl_24: { 'ui:widget': 'radio' },
    },
    initialValues: {
      definiteFearMight_3: 0,
      hasWeightInfluenced_4: 0,
      feltFat_2: 0,
      hasShapeInfluenced_5: 0,
      manyDaysPer_8: 0,
      manyTimesPer_9: 0,
      manyTimesPer_17: 0,
      manyTimesPer_18: 0,
      manyTimesPer_19: 0,
      manyTimesPer_20: 0,
    },
  },
  medsPreSessionRos1Metadata: {
    uiSchema: {
      'ui:order': ['twoWeeksExperienced_1'],
    },
  },
  medsPreSessionRos2Metadata: {
    uiSchema: {
      'ui:order': ['twoWeeksExperienced_1'],
    },
  },
  medsPreSessionRos3Metadata: {
    uiSchema: {
      'ui:order': ['twoWeeksExperienced_1'],
    },
  },
  medsPreSessionRos4Metadata: {
    uiSchema: {
      'ui:order': ['twoWeeksExperienced_1'],
    },
  },
  medsPreSessionRos5Metadata: {
    uiSchema: {
      'ui:order': ['twoWeeksExperienced_1'],
    },
  },
  medsPreSessionRos6Metadata: {
    uiSchema: {
      'ui:order': ['twoWeeksExperienced_1'],
    },
  },
  medsPreSessionRos7Metadata: {
    uiSchema: {
      'ui:order': ['twoWeeksExperienced_1'],
    },
  },
  medsPreSessionRos8Metadata: {
    uiSchema: {
      'ui:order': ['twoWeeksExperienced_1'],
    },
  },
  medsPreSessionRos9Metadata: {
    uiSchema: {
      'ui:order': ['twoWeeksExperienced_1'],
    },
  },
  medsPreSessionRos10Metadata: {
    uiSchema: {
      'ui:order': ['twoWeeksExperienced_1'],
    },
  },
  medsOngoingAltmanMetadata: {
    uiSchema: {
      'ui:order': ['altman_label1', 'altman_1', 'altman_2', 'altman_3', 'altman_4', 'altman_5'],
      altman_1: { 'ui:widget': 'radio' },
      altman_2: { 'ui:widget': 'radio' },
      altman_3: { 'ui:widget': 'radio' },
      altman_4: { 'ui:widget': 'radio' },
      altman_5: { 'ui:widget': 'radio' },
    },
  },
  medsOngoingBOCSMetadata: {
    uiSchema: {
      'ui:order': [
        'bocs_label1',
        'bocs_1',
        'bocs_2',
        'bocs_3',
        'bocs_4',
        'bocs_5',
        'bocs_label2',
        'bocs_6',
        'bocs_7',
        'bocs_8',
        'bocs_9',
        'bocs_10',
      ],
      bocs_1: { 'ui:widget': 'radio' },
      bocs_2: { 'ui:widget': 'radio' },
      bocs_3: { 'ui:widget': 'radio' },
      bocs_4: { 'ui:widget': 'radio' },
      bocs_5: { 'ui:widget': 'radio' },
      bocs_6: { 'ui:widget': 'radio' },
      bocs_7: { 'ui:widget': 'radio' },
      bocs_8: { 'ui:widget': 'radio' },
      bocs_9: { 'ui:widget': 'radio' },
      bocs_10: { 'ui:widget': 'radio' },
    },
  },
  medsOngoingExtendedMetadata: {
    uiSchema: {
      'ui:order': [
        'phqLabel',
        'phq9_1',
        'phq9_2',
        'phq9_3',
        'phq9_4',
        'phq9_5',
        'phq9_6',
        'phq9_7',
        'phq9_8',
        'phq9_9',
        'phq9_9_1',
        'phq9_9_2',
        'phq9_10',
        'gadLabel',
        'gad7_1',
        'gad7_2',
        'gad7_3',
        'gad7_4',
        'gad7_5',
        'gad7_6',
        'gad7_7',
        'swls_label',
        'swls_1',
        'swls_2',
        'swls_3',
        'swls_4',
        'swls_5',
        'adherenceLabel',
        'adherence',
        'fibser_label',
        'fibser_1',
        'fibser_2',
        'fibser_3',
        'asec_1',
      ],
      phq9_1: { 'ui:widget': 'radio' },
      phq9_2: { 'ui:widget': 'radio' },
      phq9_3: { 'ui:widget': 'radio' },
      phq9_4: { 'ui:widget': 'radio' },
      phq9_5: { 'ui:widget': 'radio' },
      phq9_6: { 'ui:widget': 'radio' },
      phq9_7: { 'ui:widget': 'radio' },
      phq9_8: { 'ui:widget': 'radio' },
      phq9_9: { 'ui:widget': 'radio' },
      phq9_9_1: { 'ui:widget': 'radio' },
      phq9_9_2: { 'ui:widget': 'radio' },
      phq9_10: { 'ui:widget': 'radio' },
      gad7_1: { 'ui:widget': 'radio' },
      gad7_2: { 'ui:widget': 'radio' },
      gad7_3: { 'ui:widget': 'radio' },
      gad7_4: { 'ui:widget': 'radio' },
      gad7_5: { 'ui:widget': 'radio' },
      gad7_6: { 'ui:widget': 'radio' },
      gad7_7: { 'ui:widget': 'radio' },
      swls_1: { 'ui:widget': 'radio' },
      swls_2: { 'ui:widget': 'radio' },
      swls_3: { 'ui:widget': 'radio' },
      swls_4: { 'ui:widget': 'radio' },
      swls_5: { 'ui:widget': 'radio' },
      adherence: { 'ui:widget': 'radio' },
      fibser_1: { 'ui:widget': 'radio' },
      fibser_2: { 'ui:widget': 'radio' },
      fibser_3: { 'ui:widget': 'radio' },
    },
  },
  medsOngoingMetadata: {
    uiSchema: {
      'ui:order': [
        'swls_label',
        'swls_1',
        'swls_2',
        'swls_3',
        'swls_4',
        'swls_5',
        'adherenceLabel',
        'adherence',
        'fibser_label',
        'fibser_1',
        'fibser_2',
        'fibser_3',
        'asec_1',
      ],
      swls_1: { 'ui:widget': 'radio' },
      swls_2: { 'ui:widget': 'radio' },
      swls_3: { 'ui:widget': 'radio' },
      swls_4: { 'ui:widget': 'radio' },
      swls_5: { 'ui:widget': 'radio' },
      adherence: { 'ui:widget': 'radio' },
      fibser_1: { 'ui:widget': 'radio' },
      fibser_2: { 'ui:widget': 'radio' },
      fibser_3: { 'ui:widget': 'radio' },
    },
  },
  medsOngoingPCL5Metadata: {
    uiSchema: {
      'ui:order': [
        'pcl_label',
        'pcl5_1',
        'pcl5_2',
        'pcl5_3',
        'pcl5_4',
        'pcl5_5',
        'pcl5_6',
        'pcl5_7',
        'pcl5_8',
        'pcl5_9',
        'pcl5_10',
        'pcl5_11',
        'pcl5_12',
        'pcl5_13',
        'pcl5_14',
        'pcl5_15',
        'pcl5_16',
        'pcl5_17',
        'pcl5_18',
        'pcl5_19',
        'pcl5_20',
      ],
      pcl5_1: { 'ui:widget': 'radio' },
      pcl5_2: { 'ui:widget': 'radio' },
      pcl5_3: { 'ui:widget': 'radio' },
      pcl5_4: { 'ui:widget': 'radio' },
      pcl5_5: { 'ui:widget': 'radio' },
      pcl5_6: { 'ui:widget': 'radio' },
      pcl5_7: { 'ui:widget': 'radio' },
      pcl5_8: { 'ui:widget': 'radio' },
      pcl5_9: { 'ui:widget': 'radio' },
      pcl5_10: { 'ui:widget': 'radio' },
      pcl5_11: { 'ui:widget': 'radio' },
      pcl5_12: { 'ui:widget': 'radio' },
      pcl5_13: { 'ui:widget': 'radio' },
      pcl5_14: { 'ui:widget': 'radio' },
      pcl5_15: { 'ui:widget': 'radio' },
      pcl5_16: { 'ui:widget': 'radio' },
      pcl5_17: { 'ui:widget': 'radio' },
      pcl5_18: { 'ui:widget': 'radio' },
      pcl5_19: { 'ui:widget': 'radio' },
      pcl5_20: { 'ui:widget': 'radio' },
    },
  },
  medsPostSessionExtendedMetadata: {
    uiSchema: {
      default: {
        'ui:order': [
          'wai_label',
          'wai_1',
          'wai_2',
          'wai_3',
          'wai_4',
          'wai_5',
          'wai_6',
          'wai_7',
          'wai_8',
          'wai_9',
          'wai_10',
          'wai_11',
          'wai_12',
          'productivity_label',
          'wlq_label1',
          'questions1Through_2',
          'past2Weeks_3',
          'past2Weeks_4',
          'nextTwoQuestions_5',
          'past2Weeks_6',
          'past2Weeks_7',
          'questionAsksAbout_8',
          'pastWeeks_9',
          'nextQuestionAsks_10',
          'past2Weeks_11',
          'questionsAskAbout_12',
          'past2Weeks_13',
          'past2Weeks_14',
          'wlq_label2',
          'nextQuestionsAsk_15',
          'aboutManyHours_16',
          'manyHoursDoes_17',
        ],
        wai_1: { 'ui:widget': 'radio' },
        wai_2: { 'ui:widget': 'radio' },
        wai_3: { 'ui:widget': 'radio' },
        wai_4: { 'ui:widget': 'radio' },
        wai_5: { 'ui:widget': 'radio' },
        wai_6: { 'ui:widget': 'radio' },
        wai_7: { 'ui:widget': 'radio' },
        wai_8: { 'ui:widget': 'radio' },
        wai_9: { 'ui:widget': 'radio' },
        wai_10: { 'ui:widget': 'radio' },
        wai_11: { 'ui:widget': 'radio' },
        wai_12: { 'ui:widget': 'radio' },
        past2Weeks_3: { 'ui:widget': 'radio' },
        past2Weeks_4: { 'ui:widget': 'radio' },
        past2Weeks_6: { 'ui:widget': 'radio' },
        past2Weeks_7: { 'ui:widget': 'radio' },
        pastWeeks_9: { 'ui:widget': 'radio' },
        past2Weeks_11: { 'ui:widget': 'radio' },
        past2Weeks_13: { 'ui:widget': 'radio' },
        past2Weeks_14: { 'ui:widget': 'radio' },
      },
      v2: {
        'ui:order': [
          'wai_label',
          'wai_1',
          'wai_2',
          'wai_3',
          'wai_4',
          'wai_5',
          'wai_6',
          'wai_7',
          'wai_8',
          'wai_9',
          'wai_10',
          'wai_11',
          'wai_12',
          'productivity_label',
          'wlq_label1',
          'questions1Through_2',
          'wlq_1',
          'wlq_2',
          'nextTwoQuestions_5',
          'wlq_7',
          'wlq_8',
          'questionAsksAbout_8',
          'wlq_3',
          'nextQuestionAsks_10',
          'wlq_4',
          'questionsAskAbout_12',
          'wlq_5',
          'wlq_6',
          'wlq_label2',
          'nextQuestionsAsk_15',
          'aboutManyHours_16',
          'manyHoursDoes_17',
        ],
        wai_1: { 'ui:widget': 'radio' },
        wai_2: { 'ui:widget': 'radio' },
        wai_3: { 'ui:widget': 'radio' },
        wai_4: { 'ui:widget': 'radio' },
        wai_5: { 'ui:widget': 'radio' },
        wai_6: { 'ui:widget': 'radio' },
        wai_7: { 'ui:widget': 'radio' },
        wai_8: { 'ui:widget': 'radio' },
        wai_9: { 'ui:widget': 'radio' },
        wai_10: { 'ui:widget': 'radio' },
        wai_11: { 'ui:widget': 'radio' },
        wai_12: { 'ui:widget': 'radio' },
        wlq_1: { 'ui:widget': 'radio' },
        wlq_2: { 'ui:widget': 'radio' },
        wlq_7: { 'ui:widget': 'radio' },
        wlq_8: { 'ui:widget': 'radio' },
        wlq_3: { 'ui:widget': 'radio' },
        wlq_4: { 'ui:widget': 'radio' },
        wlq_5: { 'ui:widget': 'radio' },
        wlq_6: { 'ui:widget': 'radio' },
      },
    },
  },
  medsFinalMetadata: {
    uiSchema: {
      default: {
        'ui:order': [
          'lyraSatisfaction_label',
          'lyraSatisfaction_1',
          'lyraSatisfaction_2',
          'lyraSatisfaction_3',
          'lyraSatisfaction_4',
          'lyraSatisfaction_5',
          'lyraSatisfaction_6',
          'lyraSatisfaction_7',
          'lyraSatisfaction_8',
          'productivity_label',
          'wlq_label1',
          'questions1Through_2',
          'past2Weeks_3',
          'past2Weeks_4',
          'nextTwoQuestions_5',
          'past2Weeks_6',
          'past2Weeks_7',
          'questionAsksAbout_8',
          'pastWeeks_9',
          'nextQuestionAsks_10',
          'past2Weeks_11',
          'questionsAskAbout_12',
          'past2Weeks_13',
          'past2Weeks_14',
          'wlq_label2',
          'nextQuestionsAsk_15',
          'aboutManyHours_16',
          'manyHoursDoes_17',
        ],
        lyraSatisfaction_1: { 'ui:widget': 'radio' },
        lyraSatisfaction_2: { 'ui:widget': 'radio' },
        lyraSatisfaction_3: { 'ui:widget': 'radio' },
        lyraSatisfaction_4: { 'ui:widget': 'radio' },
        lyraSatisfaction_5: { 'ui:widget': 'radio' },
        lyraSatisfaction_6: { 'ui:widget': 'radio' },
        lyraSatisfaction_7: { 'ui:widget': 'radio' },
        lyraSatisfaction_8: { 'ui:widget': 'textarea' },
        past2Weeks_3: { 'ui:widget': 'radio' },
        past2Weeks_4: { 'ui:widget': 'radio' },
        past2Weeks_6: { 'ui:widget': 'radio' },
        past2Weeks_7: { 'ui:widget': 'radio' },
        pastWeeks_9: { 'ui:widget': 'radio' },
        past2Weeks_11: { 'ui:widget': 'radio' },
        past2Weeks_13: { 'ui:widget': 'radio' },
        past2Weeks_14: { 'ui:widget': 'radio' },
      },
      v2: {
        'ui:order': [
          'lyraSatisfaction_label',
          'lyraSatisfaction_1',
          'lyraSatisfaction_2',
          'lyraSatisfaction_3',
          'lyraSatisfaction_4',
          'lyraSatisfaction_5',
          'lyraSatisfaction_6',
          'lyraSatisfaction_7',
          'lyraSatisfaction_8',
          'productivity_label',
          'wlq_label1',
          'questions1Through_2',
          'wlq_1',
          'wlq_2',
          'nextTwoQuestions_5',
          'wlq_7',
          'wlq_8',
          'questionAsksAbout_8',
          'wlq_3',
          'nextQuestionAsks_10',
          'wlq_4',
          'questionsAskAbout_12',
          'wlq_5',
          'wlq_6',
          'wlq_label2',
          'nextQuestionsAsk_15',
          'aboutManyHours_16',
          'manyHoursDoes_17',
        ],
        lyraSatisfaction_1: { 'ui:widget': 'radio' },
        lyraSatisfaction_2: { 'ui:widget': 'radio' },
        lyraSatisfaction_3: { 'ui:widget': 'radio' },
        lyraSatisfaction_4: { 'ui:widget': 'radio' },
        lyraSatisfaction_5: { 'ui:widget': 'radio' },
        lyraSatisfaction_6: { 'ui:widget': 'radio' },
        lyraSatisfaction_7: { 'ui:widget': 'radio' },
        lyraSatisfaction_8: { 'ui:widget': 'textarea' },
        wlq_1: { 'ui:widget': 'radio' },
        wlq_2: { 'ui:widget': 'radio' },
        wlq_7: { 'ui:widget': 'radio' },
        wlq_8: { 'ui:widget': 'radio' },
        wlq_3: { 'ui:widget': 'radio' },
        wlq_4: { 'ui:widget': 'radio' },
        wlq_5: { 'ui:widget': 'radio' },
        wlq_6: { 'ui:widget': 'radio' },
      },
    },
  },
  medsPostSessionMetadata: {
    uiSchema: {
      'ui:order': [
        'wai_label',
        'wai_1',
        'wai_2',
        'wai_3',
        'wai_4',
        'wai_5',
        'wai_6',
        'wai_7',
        'wai_8',
        'wai_9',
        'wai_10',
        'wai_11',
        'wai_12',
      ],
      wai_1: { 'ui:widget': 'radio' },
      wai_2: { 'ui:widget': 'radio' },
      wai_3: { 'ui:widget': 'radio' },
      wai_4: { 'ui:widget': 'radio' },
      wai_5: { 'ui:widget': 'radio' },
      wai_6: { 'ui:widget': 'radio' },
      wai_7: { 'ui:widget': 'radio' },
      wai_8: { 'ui:widget': 'radio' },
      wai_9: { 'ui:widget': 'radio' },
      wai_10: { 'ui:widget': 'radio' },
      wai_11: { 'ui:widget': 'radio' },
      wai_12: { 'ui:widget': 'radio' },
    },
  },
  coachingPostSessionMetadata: {
    uiSchema: {
      'ui:order': [
        'wai_label',
        'wai_1',
        'wai_2',
        'wai_3',
        'wai_4',
        'wai_5',
        'wai_6',
        'wai_7',
        'wai_8',
        'wai_9',
        'wai_10',
        'wai_11',
        'wai_12',
      ],
      wai_1: { 'ui:widget': 'radio' },
      wai_2: { 'ui:widget': 'radio' },
      wai_3: { 'ui:widget': 'radio' },
      wai_4: { 'ui:widget': 'radio' },
      wai_5: { 'ui:widget': 'radio' },
      wai_6: { 'ui:widget': 'radio' },
      wai_7: { 'ui:widget': 'radio' },
      wai_8: { 'ui:widget': 'radio' },
      wai_9: { 'ui:widget': 'radio' },
      wai_10: { 'ui:widget': 'radio' },
      wai_11: { 'ui:widget': 'radio' },
      wai_12: { 'ui:widget': 'radio' },
    },
  },
  coachingPostSessionExtendedMetadata: {
    uiSchema: {
      default: {
        'ui:order': [
          'wai_label',
          'wai_1',
          'wai_2',
          'wai_3',
          'wai_4',
          'wai_5',
          'wai_6',
          'wai_7',
          'wai_8',
          'wai_9',
          'wai_10',
          'wai_11',
          'wai_12',
          'productivity_label',
          'wlq_label1',
          'questions1Through_2',
          'past2Weeks_3',
          'past2Weeks_4',
          'nextTwoQuestions_5',
          'past2Weeks_6',
          'past2Weeks_7',
          'questionAsksAbout_8',
          'pastWeeks_9',
          'nextQuestionAsks_10',
          'past2Weeks_11',
          'questionsAskAbout_12',
          'past2Weeks_13',
          'past2Weeks_14',
          'wlq_label2',
          'nextQuestionsAsk_15',
          'aboutManyHours_16',
          'manyHoursDoes_17',
        ],
        wai_1: { 'ui:widget': 'radio' },
        wai_2: { 'ui:widget': 'radio' },
        wai_3: { 'ui:widget': 'radio' },
        wai_4: { 'ui:widget': 'radio' },
        wai_5: { 'ui:widget': 'radio' },
        wai_6: { 'ui:widget': 'radio' },
        wai_7: { 'ui:widget': 'radio' },
        wai_8: { 'ui:widget': 'radio' },
        wai_9: { 'ui:widget': 'radio' },
        wai_10: { 'ui:widget': 'radio' },
        wai_11: { 'ui:widget': 'radio' },
        wai_12: { 'ui:widget': 'radio' },
        questions1Through_2: { 'ui:widget': 'radio' },
        past2Weeks_3: { 'ui:widget': 'radio' },
        past2Weeks_4: { 'ui:widget': 'radio' },
        nextTwoQuestions_5: { 'ui:widget': 'radio' },
        past2Weeks_6: { 'ui:widget': 'radio' },
        past2Weeks_7: { 'ui:widget': 'radio' },
        questionAsksAbout_8: { 'ui:widget': 'radio' },
        pastWeeks_9: { 'ui:widget': 'radio' },
        nextQuestionAsks_10: { 'ui:widget': 'radio' },
        past2Weeks_11: { 'ui:widget': 'radio' },
        questionsAskAbout_12: { 'ui:widget': 'radio' },
        past2Weeks_13: { 'ui:widget': 'radio' },
        past2Weeks_14: { 'ui:widget': 'radio' },
      },
      v2: {
        'ui:order': [
          'wai_label',
          'wai_1',
          'wai_2',
          'wai_3',
          'wai_4',
          'wai_5',
          'wai_6',
          'wai_7',
          'wai_8',
          'wai_9',
          'wai_10',
          'wai_11',
          'wai_12',
          'productivity_label',
          'wlq_label1',
          'questions1Through_2',
          'wlq_1',
          'wlq_2',
          'nextTwoQuestions_5',
          'wlq_7',
          'wlq_8',
          'questionAsksAbout_8',
          'wlq_3',
          'nextQuestionAsks_10',
          'wlq_4',
          'questionsAskAbout_12',
          'wlq_5',
          'wlq_6',
          'wlq_label2',
          'nextQuestionsAsk_15',
          'aboutManyHours_16',
          'manyHoursDoes_17',
        ],
        wai_1: { 'ui:widget': 'radio' },
        wai_2: { 'ui:widget': 'radio' },
        wai_3: { 'ui:widget': 'radio' },
        wai_4: { 'ui:widget': 'radio' },
        wai_5: { 'ui:widget': 'radio' },
        wai_6: { 'ui:widget': 'radio' },
        wai_7: { 'ui:widget': 'radio' },
        wai_8: { 'ui:widget': 'radio' },
        wai_9: { 'ui:widget': 'radio' },
        wai_10: { 'ui:widget': 'radio' },
        wai_11: { 'ui:widget': 'radio' },
        wai_12: { 'ui:widget': 'radio' },
        questions1Through_2: { 'ui:widget': 'radio' },
        wlq_1: { 'ui:widget': 'radio' },
        wlq_2: { 'ui:widget': 'radio' },
        nextTwoQuestions_5: { 'ui:widget': 'radio' },
        wlq_7: { 'ui:widget': 'radio' },
        wlq_8: { 'ui:widget': 'radio' },
        questionAsksAbout_8: { 'ui:widget': 'radio' },
        wlq_3: { 'ui:widget': 'radio' },
        nextQuestionAsks_10: { 'ui:widget': 'radio' },
        wlq_4: { 'ui:widget': 'radio' },
        questionsAskAbout_12: { 'ui:widget': 'radio' },
        wlq_5: { 'ui:widget': 'radio' },
        wlq_6: { 'ui:widget': 'radio' },
      },
    },
  },
  coachingFinalMildExtendedMetadata: {
    uiSchema: {
      default: {
        'ui:order': [
          'pssLabel',
          'pss_1',
          'pss_2',
          'pss_3',
          'pss_4',
          'pss_5',
          'pss_6',
          'pss_7',
          'pss_8',
          'pss_9',
          'pss_10',
          'wellBeingLabel',
          'wellBeing_1',
          'wellBeing_2',
          'wellBeing_3',
          'wellBeing_4',
          'wellBeing_5',
          'wellBeing_6',
          'wellBeing_7',
          'wellBeing_8',
          'wellBeing_9',
          'wellBeing_10',
          'wellBeing_11',
          'wellBeing_12',
          'wellBeing_13',
          'wellBeing_14',
          'productivity_label',
          'wlq_label1',
          'questions1Through_2',
          'past2Weeks_3',
          'past2Weeks_4',
          'nextTwoQuestions_5',
          'past2Weeks_6',
          'past2Weeks_7',
          'questionAsksAbout_8',
          'pastWeeks_9',
          'nextQuestionAsks_10',
          'past2Weeks_11',
          'questionsAskAbout_12',
          'past2Weeks_13',
          'past2Weeks_14',
          'wlq_label2',
          'nextQuestionsAsk_15',
          'aboutManyHours_16',
          'manyHoursDoes_17',
          'lyraSatisfaction_label',
          'lyraSatisfaction_1',
          'lyraSatisfaction_2',
          'lyraSatisfaction_3',
          'lyraSatisfaction_4',
          'lyraSatisfaction_5',
          'lyraSatisfaction_6',
          'lyraSatisfaction_coachContent_label',
          'lyraSatisfaction_coachContent',
          'lyraSatisfaction_coachContent_specify',
          'lyraSatisfaction_skills_label',
          'lyraSatisfaction_skills',
          'lyraSatisfaction_skills_specify',
          'lyraSatisfaction_7',
          'lyraSatisfaction_8',
        ],
        pss_1: { 'ui:widget': 'radio' },
        pss_2: { 'ui:widget': 'radio' },
        pss_3: { 'ui:widget': 'radio' },
        pss_4: { 'ui:widget': 'radio' },
        pss_5: { 'ui:widget': 'radio' },
        pss_6: { 'ui:widget': 'radio' },
        pss_7: { 'ui:widget': 'radio' },
        pss_8: { 'ui:widget': 'radio' },
        pss_9: { 'ui:widget': 'radio' },
        pss_10: { 'ui:widget': 'radio' },
        wellBeing_1: { 'ui:widget': 'radio' },
        wellBeing_2: { 'ui:widget': 'radio' },
        wellBeing_3: { 'ui:widget': 'radio' },
        wellBeing_4: { 'ui:widget': 'radio' },
        wellBeing_5: { 'ui:widget': 'radio' },
        wellBeing_6: { 'ui:widget': 'radio' },
        wellBeing_7: { 'ui:widget': 'radio' },
        wellBeing_8: { 'ui:widget': 'radio' },
        wellBeing_9: { 'ui:widget': 'radio' },
        wellBeing_10: { 'ui:widget': 'radio' },
        wellBeing_11: { 'ui:widget': 'radio' },
        wellBeing_12: { 'ui:widget': 'radio' },
        wellBeing_13: { 'ui:widget': 'radio' },
        wellBeing_14: { 'ui:widget': 'radio' },
        questions1Through_2: { 'ui:widget': 'radio' },
        past2Weeks_3: { 'ui:widget': 'radio' },
        past2Weeks_4: { 'ui:widget': 'radio' },
        nextTwoQuestions_5: { 'ui:widget': 'radio' },
        past2Weeks_6: { 'ui:widget': 'radio' },
        past2Weeks_7: { 'ui:widget': 'radio' },
        questionAsksAbout_8: { 'ui:widget': 'radio' },
        pastWeeks_9: { 'ui:widget': 'radio' },
        nextQuestionAsks_10: { 'ui:widget': 'radio' },
        past2Weeks_11: { 'ui:widget': 'radio' },
        questionsAskAbout_12: { 'ui:widget': 'radio' },
        past2Weeks_13: { 'ui:widget': 'radio' },
        past2Weeks_14: { 'ui:widget': 'radio' },
        lyraSatisfaction_1: { 'ui:widget': 'radio' },
        lyraSatisfaction_2: { 'ui:widget': 'radio' },
        lyraSatisfaction_3: { 'ui:widget': 'radio' },
        lyraSatisfaction_4: { 'ui:widget': 'radio' },
        lyraSatisfaction_5: { 'ui:widget': 'radio' },
        lyraSatisfaction_6: { 'ui:widget': 'radio' },
        lyraSatisfaction_coachContent: { 'ui:widget': 'checkbox' },
        lyraSatisfaction_skills: { 'ui:widget': 'checkbox' },
        lyraSatisfaction_7: { 'ui:widget': 'radio' },
      },
      v2: {
        'ui:order': [
          'pssLabel',
          'pss_1',
          'pss_2',
          'pss_3',
          'pss_4',
          'pss_5',
          'pss_6',
          'pss_7',
          'pss_8',
          'pss_9',
          'pss_10',
          'wellBeingLabel',
          'wellBeing_1',
          'wellBeing_2',
          'wellBeing_3',
          'wellBeing_4',
          'wellBeing_5',
          'wellBeing_6',
          'wellBeing_7',
          'wellBeing_8',
          'wellBeing_9',
          'wellBeing_10',
          'wellBeing_11',
          'wellBeing_12',
          'wellBeing_13',
          'wellBeing_14',
          'productivity_label',
          'wlq_label1',
          'questions1Through_2',
          'wlq_1',
          'wlq_2',
          'nextTwoQuestions_5',
          'wlq_7',
          'wlq_8',
          'questionAsksAbout_8',
          'wlq_3',
          'nextQuestionAsks_10',
          'wlq_4',
          'questionsAskAbout_12',
          'wlq_5',
          'wlq_6',
          'wlq_label2',
          'nextQuestionsAsk_15',
          'aboutManyHours_16',
          'manyHoursDoes_17',
          'lyraSatisfaction_label',
          'lyraSatisfaction_1',
          'lyraSatisfaction_2',
          'lyraSatisfaction_3',
          'lyraSatisfaction_4',
          'lyraSatisfaction_5',
          'lyraSatisfaction_6',
          'lyraSatisfaction_coachContent_label',
          'lyraSatisfaction_coachContent',
          'lyraSatisfaction_coachContent_specify',
          'lyraSatisfaction_skills_label',
          'lyraSatisfaction_skills',
          'lyraSatisfaction_skills_specify',
          'lyraSatisfaction_7',
          'lyraSatisfaction_8',
        ],
        pss_1: { 'ui:widget': 'radio' },
        pss_2: { 'ui:widget': 'radio' },
        pss_3: { 'ui:widget': 'radio' },
        pss_4: { 'ui:widget': 'radio' },
        pss_5: { 'ui:widget': 'radio' },
        pss_6: { 'ui:widget': 'radio' },
        pss_7: { 'ui:widget': 'radio' },
        pss_8: { 'ui:widget': 'radio' },
        pss_9: { 'ui:widget': 'radio' },
        pss_10: { 'ui:widget': 'radio' },
        wellBeing_1: { 'ui:widget': 'radio' },
        wellBeing_2: { 'ui:widget': 'radio' },
        wellBeing_3: { 'ui:widget': 'radio' },
        wellBeing_4: { 'ui:widget': 'radio' },
        wellBeing_5: { 'ui:widget': 'radio' },
        wellBeing_6: { 'ui:widget': 'radio' },
        wellBeing_7: { 'ui:widget': 'radio' },
        wellBeing_8: { 'ui:widget': 'radio' },
        wellBeing_9: { 'ui:widget': 'radio' },
        wellBeing_10: { 'ui:widget': 'radio' },
        wellBeing_11: { 'ui:widget': 'radio' },
        wellBeing_12: { 'ui:widget': 'radio' },
        wellBeing_13: { 'ui:widget': 'radio' },
        wellBeing_14: { 'ui:widget': 'radio' },
        questions1Through_2: { 'ui:widget': 'radio' },
        wlq_1: { 'ui:widget': 'radio' },
        wlq_2: { 'ui:widget': 'radio' },
        nextTwoQuestions_5: { 'ui:widget': 'radio' },
        wlq_7: { 'ui:widget': 'radio' },
        wlq_8: { 'ui:widget': 'radio' },
        questionAsksAbout_8: { 'ui:widget': 'radio' },
        wlq_3: { 'ui:widget': 'radio' },
        nextQuestionAsks_10: { 'ui:widget': 'radio' },
        wlq_4: { 'ui:widget': 'radio' },
        questionsAskAbout_12: { 'ui:widget': 'radio' },
        wlq_5: { 'ui:widget': 'radio' },
        wlq_6: { 'ui:widget': 'radio' },
        lyraSatisfaction_1: { 'ui:widget': 'radio' },
        lyraSatisfaction_2: { 'ui:widget': 'radio' },
        lyraSatisfaction_3: { 'ui:widget': 'radio' },
        lyraSatisfaction_4: { 'ui:widget': 'radio' },
        lyraSatisfaction_5: { 'ui:widget': 'radio' },
        lyraSatisfaction_6: { 'ui:widget': 'radio' },
        lyraSatisfaction_coachContent: { 'ui:widget': 'checkbox' },
        lyraSatisfaction_skills: { 'ui:widget': 'checkbox' },
        lyraSatisfaction_7: { 'ui:widget': 'radio' },
      },
    },
  },
  coachingFinalMildMetadata: {
    uiSchema: {
      'ui:order': [
        'pssLabel',
        'pss_1',
        'pss_2',
        'pss_3',
        'pss_4',
        'pss_5',
        'pss_6',
        'pss_7',
        'pss_8',
        'pss_9',
        'pss_10',
        'wellBeingLabel',
        'wellBeing_1',
        'wellBeing_2',
        'wellBeing_3',
        'wellBeing_4',
        'wellBeing_5',
        'wellBeing_6',
        'wellBeing_7',
        'wellBeing_8',
        'wellBeing_9',
        'wellBeing_10',
        'wellBeing_11',
        'wellBeing_12',
        'wellBeing_13',
        'wellBeing_14',
        'lyraSatisfaction_label',
        'lyraSatisfaction_1',
        'lyraSatisfaction_2',
        'lyraSatisfaction_3',
        'lyraSatisfaction_4',
        'lyraSatisfaction_5',
        'lyraSatisfaction_6',
        'lyraSatisfaction_coachContent_label',
        'lyraSatisfaction_coachContent',
        'lyraSatisfaction_coachContent_specify',
        'lyraSatisfaction_skills_label',
        'lyraSatisfaction_skills',
        'lyraSatisfaction_skills_specify',
        'lyraSatisfaction_7',
        'lyraSatisfaction_8',
      ],
      pss_1: { 'ui:widget': 'radio' },
      pss_2: { 'ui:widget': 'radio' },
      pss_3: { 'ui:widget': 'radio' },
      pss_4: { 'ui:widget': 'radio' },
      pss_5: { 'ui:widget': 'radio' },
      pss_6: { 'ui:widget': 'radio' },
      pss_7: { 'ui:widget': 'radio' },
      pss_8: { 'ui:widget': 'radio' },
      pss_9: { 'ui:widget': 'radio' },
      pss_10: { 'ui:widget': 'radio' },
      wellBeing_1: { 'ui:widget': 'radio' },
      wellBeing_2: { 'ui:widget': 'radio' },
      wellBeing_3: { 'ui:widget': 'radio' },
      wellBeing_4: { 'ui:widget': 'radio' },
      wellBeing_5: { 'ui:widget': 'radio' },
      wellBeing_6: { 'ui:widget': 'radio' },
      wellBeing_7: { 'ui:widget': 'radio' },
      wellBeing_8: { 'ui:widget': 'radio' },
      wellBeing_9: { 'ui:widget': 'radio' },
      wellBeing_10: { 'ui:widget': 'radio' },
      wellBeing_11: { 'ui:widget': 'radio' },
      wellBeing_12: { 'ui:widget': 'radio' },
      wellBeing_13: { 'ui:widget': 'radio' },
      wellBeing_14: { 'ui:widget': 'radio' },
      lyraSatisfaction_1: { 'ui:widget': 'radio' },
      lyraSatisfaction_2: { 'ui:widget': 'radio' },
      lyraSatisfaction_3: { 'ui:widget': 'radio' },
      lyraSatisfaction_4: { 'ui:widget': 'radio' },
      lyraSatisfaction_5: { 'ui:widget': 'radio' },
      lyraSatisfaction_6: { 'ui:widget': 'radio' },
      lyraSatisfaction_coachContent: { 'ui:widget': 'checkbox' },
      lyraSatisfaction_skills: { 'ui:widget': 'checkbox' },
      lyraSatisfaction_7: { 'ui:widget': 'radio' },
    },
  },
  coachingFinalExtendedMetadata: {
    uiSchema: {
      default: {
        'ui:order': [
          'gadLabel',
          'gad7_1',
          'gad7_2',
          'gad7_3',
          'gad7_4',
          'gad7_5',
          'gad7_6',
          'gad7_7',
          'phqLabel',
          'phq9_1',
          'phq9_2',
          'phq9_3',
          'phq9_4',
          'phq9_5',
          'phq9_6',
          'phq9_7',
          'phq9_8',
          'phq9_9',
          'phq9_9_1',
          'phq9_9_2',
          'phq9_10',
          'productivity_label',
          'wlq_label1',
          'questions1Through_2',
          'past2Weeks_3',
          'past2Weeks_4',
          'nextTwoQuestions_5',
          'past2Weeks_6',
          'past2Weeks_7',
          'questionAsksAbout_8',
          'pastWeeks_9',
          'nextQuestionAsks_10',
          'past2Weeks_11',
          'questionsAskAbout_12',
          'past2Weeks_13',
          'past2Weeks_14',
          'wlq_label2',
          'nextQuestionsAsk_15',
          'aboutManyHours_16',
          'manyHoursDoes_17',
          'lyraSatisfaction_label',
          'lyraSatisfaction_1',
          'lyraSatisfaction_2',
          'lyraSatisfaction_3',
          'lyraSatisfaction_4',
          'lyraSatisfaction_5',
          'lyraSatisfaction_6',
          'lyraSatisfaction_coachContent_label',
          'lyraSatisfaction_coachContent',
          'lyraSatisfaction_coachContent_specify',
          'lyraSatisfaction_skills_label',
          'lyraSatisfaction_skills',
          'lyraSatisfaction_skills_specify',
          'lyraSatisfaction_7',
          'lyraSatisfaction_8',
        ],
        gad7_1: { 'ui:widget': 'radio' },
        gad7_2: { 'ui:widget': 'radio' },
        gad7_3: { 'ui:widget': 'radio' },
        gad7_4: { 'ui:widget': 'radio' },
        gad7_5: { 'ui:widget': 'radio' },
        gad7_6: { 'ui:widget': 'radio' },
        gad7_7: { 'ui:widget': 'radio' },
        phq9_1: { 'ui:widget': 'radio' },
        phq9_2: { 'ui:widget': 'radio' },
        phq9_3: { 'ui:widget': 'radio' },
        phq9_4: { 'ui:widget': 'radio' },
        phq9_5: { 'ui:widget': 'radio' },
        phq9_6: { 'ui:widget': 'radio' },
        phq9_7: { 'ui:widget': 'radio' },
        phq9_8: { 'ui:widget': 'radio' },
        phq9_9: { 'ui:widget': 'radio' },
        phq9_9_1: { 'ui:widget': 'radio' },
        phq9_9_2: { 'ui:widget': 'radio' },
        phq9_10: { 'ui:widget': 'radio' },
        questions1Through_2: { 'ui:widget': 'radio' },
        past2Weeks_3: { 'ui:widget': 'radio' },
        past2Weeks_4: { 'ui:widget': 'radio' },
        nextTwoQuestions_5: { 'ui:widget': 'radio' },
        past2Weeks_6: { 'ui:widget': 'radio' },
        past2Weeks_7: { 'ui:widget': 'radio' },
        questionAsksAbout_8: { 'ui:widget': 'radio' },
        pastWeeks_9: { 'ui:widget': 'radio' },
        nextQuestionAsks_10: { 'ui:widget': 'radio' },
        past2Weeks_11: { 'ui:widget': 'radio' },
        questionsAskAbout_12: { 'ui:widget': 'radio' },
        past2Weeks_13: { 'ui:widget': 'radio' },
        past2Weeks_14: { 'ui:widget': 'radio' },
        nextQuestionsAsk_15: { 'ui:widget': 'radio' },
        lyraSatisfaction_1: { 'ui:widget': 'radio' },
        lyraSatisfaction_2: { 'ui:widget': 'radio' },
        lyraSatisfaction_3: { 'ui:widget': 'radio' },
        lyraSatisfaction_4: { 'ui:widget': 'radio' },
        lyraSatisfaction_5: { 'ui:widget': 'radio' },
        lyraSatisfaction_6: { 'ui:widget': 'radio' },
        lyraSatisfaction_coachContent: { 'ui:widget': 'checkbox' },
        lyraSatisfaction_skills: { 'ui:widget': 'checkbox' },
        lyraSatisfaction_7: { 'ui:widget': 'radio' },
      },
      v2: {
        'ui:order': [
          'gadLabel',
          'gad7_1',
          'gad7_2',
          'gad7_3',
          'gad7_4',
          'gad7_5',
          'gad7_6',
          'gad7_7',
          'phqLabel',
          'phq9_1',
          'phq9_2',
          'phq9_3',
          'phq9_4',
          'phq9_5',
          'phq9_6',
          'phq9_7',
          'phq9_8',
          'phq9_9',
          'phq9_9_1',
          'phq9_9_2',
          'phq9_10',
          'productivity_label',
          'wlq_label1',
          'questions1Through_2',
          'wlq_1',
          'wlq_2',
          'nextTwoQuestions_5',
          'wlq_7',
          'wlq_8',
          'questionAsksAbout_8',
          'wlq_3',
          'nextQuestionAsks_10',
          'wlq_4',
          'questionsAskAbout_12',
          'wlq_5',
          'wlq_6',
          'wlq_label2',
          'nextQuestionsAsk_15',
          'aboutManyHours_16',
          'manyHoursDoes_17',
          'lyraSatisfaction_label',
          'lyraSatisfaction_1',
          'lyraSatisfaction_2',
          'lyraSatisfaction_3',
          'lyraSatisfaction_4',
          'lyraSatisfaction_5',
          'lyraSatisfaction_6',
          'lyraSatisfaction_coachContent_label',
          'lyraSatisfaction_coachContent',
          'lyraSatisfaction_coachContent_specify',
          'lyraSatisfaction_skills_label',
          'lyraSatisfaction_skills',
          'lyraSatisfaction_skills_specify',
          'lyraSatisfaction_7',
          'lyraSatisfaction_8',
        ],
        gad7_1: { 'ui:widget': 'radio' },
        gad7_2: { 'ui:widget': 'radio' },
        gad7_3: { 'ui:widget': 'radio' },
        gad7_4: { 'ui:widget': 'radio' },
        gad7_5: { 'ui:widget': 'radio' },
        gad7_6: { 'ui:widget': 'radio' },
        gad7_7: { 'ui:widget': 'radio' },
        phq9_1: { 'ui:widget': 'radio' },
        phq9_2: { 'ui:widget': 'radio' },
        phq9_3: { 'ui:widget': 'radio' },
        phq9_4: { 'ui:widget': 'radio' },
        phq9_5: { 'ui:widget': 'radio' },
        phq9_6: { 'ui:widget': 'radio' },
        phq9_7: { 'ui:widget': 'radio' },
        phq9_8: { 'ui:widget': 'radio' },
        phq9_9: { 'ui:widget': 'radio' },
        phq9_9_1: { 'ui:widget': 'radio' },
        phq9_9_2: { 'ui:widget': 'radio' },
        phq9_10: { 'ui:widget': 'radio' },
        questions1Through_2: { 'ui:widget': 'radio' },
        wlq_1: { 'ui:widget': 'radio' },
        wlq_2: { 'ui:widget': 'radio' },
        nextTwoQuestions_5: { 'ui:widget': 'radio' },
        wlq_7: { 'ui:widget': 'radio' },
        wlq_8: { 'ui:widget': 'radio' },
        questionAsksAbout_8: { 'ui:widget': 'radio' },
        wlq_3: { 'ui:widget': 'radio' },
        nextQuestionAsks_10: { 'ui:widget': 'radio' },
        wlq_4: { 'ui:widget': 'radio' },
        questionsAskAbout_12: { 'ui:widget': 'radio' },
        wlq_5: { 'ui:widget': 'radio' },
        wlq_6: { 'ui:widget': 'radio' },
        nextQuestionsAsk_15: { 'ui:widget': 'radio' },
        lyraSatisfaction_1: { 'ui:widget': 'radio' },
        lyraSatisfaction_2: { 'ui:widget': 'radio' },
        lyraSatisfaction_3: { 'ui:widget': 'radio' },
        lyraSatisfaction_4: { 'ui:widget': 'radio' },
        lyraSatisfaction_5: { 'ui:widget': 'radio' },
        lyraSatisfaction_6: { 'ui:widget': 'radio' },
        lyraSatisfaction_coachContent: { 'ui:widget': 'checkbox' },
        lyraSatisfaction_skills: { 'ui:widget': 'checkbox' },
        lyraSatisfaction_7: { 'ui:widget': 'radio' },
      },
    },
  },
  coachingFinalMetadata: {
    uiSchema: {
      'ui:order': [
        'gadLabel',
        'gad7_1',
        'gad7_2',
        'gad7_3',
        'gad7_4',
        'gad7_5',
        'gad7_6',
        'gad7_7',
        'phqLabel',
        'phq9_1',
        'phq9_2',
        'phq9_3',
        'phq9_4',
        'phq9_5',
        'phq9_6',
        'phq9_7',
        'phq9_8',
        'phq9_9',
        'phq9_9_1',
        'phq9_9_2',
        'phq9_10',
        'lyraSatisfaction_label',
        'lyraSatisfaction_1',
        'lyraSatisfaction_2',
        'lyraSatisfaction_3',
        'lyraSatisfaction_4',
        'lyraSatisfaction_5',
        'lyraSatisfaction_6',
        'lyraSatisfaction_coachContent_label',
        'lyraSatisfaction_coachContent',
        'lyraSatisfaction_coachContent_specify',
        'lyraSatisfaction_skills_label',
        'lyraSatisfaction_skills',
        'lyraSatisfaction_skills_specify',
        'lyraSatisfaction_7',
        'lyraSatisfaction_8',
      ],
      gad7_1: { 'ui:widget': 'radio' },
      gad7_2: { 'ui:widget': 'radio' },
      gad7_3: { 'ui:widget': 'radio' },
      gad7_4: { 'ui:widget': 'radio' },
      gad7_5: { 'ui:widget': 'radio' },
      gad7_6: { 'ui:widget': 'radio' },
      gad7_7: { 'ui:widget': 'radio' },
      phq9_1: { 'ui:widget': 'radio' },
      phq9_2: { 'ui:widget': 'radio' },
      phq9_3: { 'ui:widget': 'radio' },
      phq9_4: { 'ui:widget': 'radio' },
      phq9_5: { 'ui:widget': 'radio' },
      phq9_6: { 'ui:widget': 'radio' },
      phq9_7: { 'ui:widget': 'radio' },
      phq9_8: { 'ui:widget': 'radio' },
      phq9_9: { 'ui:widget': 'radio' },
      phq9_9_1: { 'ui:widget': 'radio' },
      phq9_9_2: { 'ui:widget': 'radio' },
      phq9_10: { 'ui:widget': 'radio' },
      lyraSatisfaction_1: { 'ui:widget': 'radio' },
      lyraSatisfaction_2: { 'ui:widget': 'radio' },
      lyraSatisfaction_3: { 'ui:widget': 'radio' },
      lyraSatisfaction_4: { 'ui:widget': 'radio' },
      lyraSatisfaction_5: { 'ui:widget': 'radio' },
      lyraSatisfaction_6: { 'ui:widget': 'radio' },
      lyraSatisfaction_coachContent: { 'ui:widget': 'checkbox' },
      lyraSatisfaction_skills: { 'ui:widget': 'checkbox' },
      lyraSatisfaction_7: { 'ui:widget': 'radio' },
    },
  },
  coachingBaselineMetadata: {
    uiSchema: {
      default: {
        'ui:order': [
          'gadLabel',
          'gad7_1',
          'gad7_2',
          'gad7_3',
          'gad7_4',
          'gad7_5',
          'gad7_6',
          'gad7_7',
          'phqLabel',
          'phq9_1',
          'phq9_2',
          'phq9_3',
          'phq9_4',
          'phq9_5',
          'phq9_6',
          'phq9_7',
          'phq9_8',
          'phq9_9',
          'phq9_9_1',
          'phq9_9_2',
          'phq9_10',
          'pssLabel',
          'pss_1',
          'pss_2',
          'pss_3',
          'pss_4',
          'pss_5',
          'pss_6',
          'pss_7',
          'pss_8',
          'pss_9',
          'pss_10',
          'wellBeingLabel',
          'wellBeing_1',
          'wellBeing_2',
          'wellBeing_3',
          'wellBeing_4',
          'wellBeing_5',
          'wellBeing_6',
          'wellBeing_7',
          'wellBeing_8',
          'wellBeing_9',
          'wellBeing_10',
          'wellBeing_11',
          'wellBeing_12',
          'wellBeing_13',
          'wellBeing_14',
          'productivity_label',
          'wlq_label1',
          'questions1Through_2',
          'past2Weeks_3',
          'past2Weeks_4',
          'nextTwoQuestions_5',
          'past2Weeks_6',
          'past2Weeks_7',
          'questionAsksAbout_8',
          'past2Weeks_9',
          'nextQuestionAsks_10',
          'past2Weeks_11',
          'questionsAskAbout_12',
          'past2Weeks_13',
          'past2Weeks_14',
          'wlq_label2',
          'nextQuestionsAsk_15',
          'aboutManyHours_16',
          'manyHoursDoes_17',
        ],
        gad7_1: { 'ui:widget': 'radio' },
        gad7_2: { 'ui:widget': 'radio' },
        gad7_3: { 'ui:widget': 'radio' },
        gad7_4: { 'ui:widget': 'radio' },
        gad7_5: { 'ui:widget': 'radio' },
        gad7_6: { 'ui:widget': 'radio' },
        gad7_7: { 'ui:widget': 'radio' },
        phq9_1: { 'ui:widget': 'radio' },
        phq9_2: { 'ui:widget': 'radio' },
        phq9_3: { 'ui:widget': 'radio' },
        phq9_4: { 'ui:widget': 'radio' },
        phq9_5: { 'ui:widget': 'radio' },
        phq9_6: { 'ui:widget': 'radio' },
        phq9_7: { 'ui:widget': 'radio' },
        phq9_8: { 'ui:widget': 'radio' },
        phq9_9: { 'ui:widget': 'radio' },
        phq9_9_1: { 'ui:widget': 'radio' },
        phq9_9_2: { 'ui:widget': 'radio' },
        phq9_10: { 'ui:widget': 'radio' },
        pss_1: { 'ui:widget': 'radio' },
        pss_2: { 'ui:widget': 'radio' },
        pss_3: { 'ui:widget': 'radio' },
        pss_4: { 'ui:widget': 'radio' },
        pss_5: { 'ui:widget': 'radio' },
        pss_6: { 'ui:widget': 'radio' },
        pss_7: { 'ui:widget': 'radio' },
        pss_8: { 'ui:widget': 'radio' },
        pss_9: { 'ui:widget': 'radio' },
        pss_10: { 'ui:widget': 'radio' },
        wellBeing_1: { 'ui:widget': 'radio' },
        wellBeing_2: { 'ui:widget': 'radio' },
        wellBeing_3: { 'ui:widget': 'radio' },
        wellBeing_4: { 'ui:widget': 'radio' },
        wellBeing_5: { 'ui:widget': 'radio' },
        wellBeing_6: { 'ui:widget': 'radio' },
        wellBeing_7: { 'ui:widget': 'radio' },
        wellBeing_8: { 'ui:widget': 'radio' },
        wellBeing_9: { 'ui:widget': 'radio' },
        wellBeing_10: { 'ui:widget': 'radio' },
        wellBeing_11: { 'ui:widget': 'radio' },
        wellBeing_12: { 'ui:widget': 'radio' },
        wellBeing_13: { 'ui:widget': 'radio' },
        wellBeing_14: { 'ui:widget': 'radio' },
        past2Weeks_3: { 'ui:widget': 'radio' },
        past2Weeks_4: { 'ui:widget': 'radio' },
        past2Weeks_6: { 'ui:widget': 'radio' },
        past2Weeks_7: { 'ui:widget': 'radio' },
        past2Weeks_9: { 'ui:widget': 'radio' },
        past2Weeks_11: { 'ui:widget': 'radio' },
        past2Weeks_13: { 'ui:widget': 'radio' },
        past2Weeks_14: { 'ui:widget': 'radio' },
      },
      v2: {
        'ui:order': [
          'gadLabel',
          'gad7_1',
          'gad7_2',
          'gad7_3',
          'gad7_4',
          'gad7_5',
          'gad7_6',
          'gad7_7',
          'phqLabel',
          'phq9_1',
          'phq9_2',
          'phq9_3',
          'phq9_4',
          'phq9_5',
          'phq9_6',
          'phq9_7',
          'phq9_8',
          'phq9_9',
          'phq9_9_1',
          'phq9_9_2',
          'phq9_10',
          'pssLabel',
          'pss_1',
          'pss_2',
          'pss_3',
          'pss_4',
          'pss_5',
          'pss_6',
          'pss_7',
          'pss_8',
          'pss_9',
          'pss_10',
          'wellBeingLabel',
          'wellBeing_1',
          'wellBeing_2',
          'wellBeing_3',
          'wellBeing_4',
          'wellBeing_5',
          'wellBeing_6',
          'wellBeing_7',
          'wellBeing_8',
          'wellBeing_9',
          'wellBeing_10',
          'wellBeing_11',
          'wellBeing_12',
          'wellBeing_13',
          'wellBeing_14',
          'productivity_label',
          'wlq_label1',
          'questions1Through_2',
          'wlq_1',
          'wlq_2',
          'nextTwoQuestions_5',
          'wlq_7',
          'wlq_8',
          'questionAsksAbout_8',
          'wlq_3',
          'nextQuestionAsks_10',
          'wlq_4',
          'questionsAskAbout_12',
          'wlq_5',
          'wlq_6',
          'wlq_label2',
          'nextQuestionsAsk_15',
          'aboutManyHours_16',
          'manyHoursDoes_17',
        ],
        gad7_1: { 'ui:widget': 'radio' },
        gad7_2: { 'ui:widget': 'radio' },
        gad7_3: { 'ui:widget': 'radio' },
        gad7_4: { 'ui:widget': 'radio' },
        gad7_5: { 'ui:widget': 'radio' },
        gad7_6: { 'ui:widget': 'radio' },
        gad7_7: { 'ui:widget': 'radio' },
        phq9_1: { 'ui:widget': 'radio' },
        phq9_2: { 'ui:widget': 'radio' },
        phq9_3: { 'ui:widget': 'radio' },
        phq9_4: { 'ui:widget': 'radio' },
        phq9_5: { 'ui:widget': 'radio' },
        phq9_6: { 'ui:widget': 'radio' },
        phq9_7: { 'ui:widget': 'radio' },
        phq9_8: { 'ui:widget': 'radio' },
        phq9_9: { 'ui:widget': 'radio' },
        phq9_9_1: { 'ui:widget': 'radio' },
        phq9_9_2: { 'ui:widget': 'radio' },
        phq9_10: { 'ui:widget': 'radio' },
        pss_1: { 'ui:widget': 'radio' },
        pss_2: { 'ui:widget': 'radio' },
        pss_3: { 'ui:widget': 'radio' },
        pss_4: { 'ui:widget': 'radio' },
        pss_5: { 'ui:widget': 'radio' },
        pss_6: { 'ui:widget': 'radio' },
        pss_7: { 'ui:widget': 'radio' },
        pss_8: { 'ui:widget': 'radio' },
        pss_9: { 'ui:widget': 'radio' },
        pss_10: { 'ui:widget': 'radio' },
        wellBeing_1: { 'ui:widget': 'radio' },
        wellBeing_2: { 'ui:widget': 'radio' },
        wellBeing_3: { 'ui:widget': 'radio' },
        wellBeing_4: { 'ui:widget': 'radio' },
        wellBeing_5: { 'ui:widget': 'radio' },
        wellBeing_6: { 'ui:widget': 'radio' },
        wellBeing_7: { 'ui:widget': 'radio' },
        wellBeing_8: { 'ui:widget': 'radio' },
        wellBeing_9: { 'ui:widget': 'radio' },
        wellBeing_10: { 'ui:widget': 'radio' },
        wellBeing_11: { 'ui:widget': 'radio' },
        wellBeing_12: { 'ui:widget': 'radio' },
        wellBeing_13: { 'ui:widget': 'radio' },
        wellBeing_14: { 'ui:widget': 'radio' },
        wlq_1: { 'ui:widget': 'radio' },
        wlq_2: { 'ui:widget': 'radio' },
        wlq_7: { 'ui:widget': 'radio' },
        wlq_8: { 'ui:widget': 'radio' },
        wlq_3: { 'ui:widget': 'radio' },
        wlq_4: { 'ui:widget': 'radio' },
        wlq_5: { 'ui:widget': 'radio' },
        wlq_6: { 'ui:widget': 'radio' },
      },
    },
  },
  coachingMildPreSessionMetadata: {
    uiSchema: {
      'ui:order': [
        'pssLabel',
        'pss_1',
        'pss_2',
        'pss_3',
        'pss_4',
        'pss_5',
        'pss_6',
        'pss_7',
        'pss_8',
        'pss_9',
        'pss_10',
        'wellBeingLabel',
        'wellBeing_1',
        'wellBeing_2',
        'wellBeing_3',
        'wellBeing_4',
        'wellBeing_5',
        'wellBeing_6',
        'wellBeing_7',
        'wellBeing_8',
        'wellBeing_9',
        'wellBeing_10',
        'wellBeing_11',
        'wellBeing_12',
        'wellBeing_13',
        'wellBeing_14',
      ],
      pss_1: { 'ui:widget': 'radio' },
      pss_2: { 'ui:widget': 'radio' },
      pss_3: { 'ui:widget': 'radio' },
      pss_4: { 'ui:widget': 'radio' },
      pss_5: { 'ui:widget': 'radio' },
      pss_6: { 'ui:widget': 'radio' },
      pss_7: { 'ui:widget': 'radio' },
      pss_8: { 'ui:widget': 'radio' },
      pss_9: { 'ui:widget': 'radio' },
      pss_10: { 'ui:widget': 'radio' },
      wellBeing_1: { 'ui:widget': 'radio' },
      wellBeing_2: { 'ui:widget': 'radio' },
      wellBeing_3: { 'ui:widget': 'radio' },
      wellBeing_4: { 'ui:widget': 'radio' },
      wellBeing_5: { 'ui:widget': 'radio' },
      wellBeing_6: { 'ui:widget': 'radio' },
      wellBeing_7: { 'ui:widget': 'radio' },
      wellBeing_8: { 'ui:widget': 'radio' },
      wellBeing_9: { 'ui:widget': 'radio' },
      wellBeing_10: { 'ui:widget': 'radio' },
      wellBeing_11: { 'ui:widget': 'radio' },
      wellBeing_12: { 'ui:widget': 'radio' },
      wellBeing_13: { 'ui:widget': 'radio' },
      wellBeing_14: { 'ui:widget': 'radio' },
    },
  },
  coachingPreSessionMetadata: {
    uiSchema: {
      'ui:order': [
        'gadLabel',
        'gad7_1',
        'gad7_2',
        'gad7_3',
        'gad7_4',
        'gad7_5',
        'gad7_6',
        'gad7_7',
        'phqLabel',
        'phq9_1',
        'phq9_2',
        'phq9_3',
        'phq9_4',
        'phq9_5',
        'phq9_6',
        'phq9_7',
        'phq9_8',
        'phq9_9',
        'phq9_9_1',
        'phq9_9_2',
        'phq9_10',
      ],
      gad7_1: { 'ui:widget': 'radio' },
      gad7_2: { 'ui:widget': 'radio' },
      gad7_3: { 'ui:widget': 'radio' },
      gad7_4: { 'ui:widget': 'radio' },
      gad7_5: { 'ui:widget': 'radio' },
      gad7_6: { 'ui:widget': 'radio' },
      gad7_7: { 'ui:widget': 'radio' },
      phq9_1: { 'ui:widget': 'radio' },
      phq9_2: { 'ui:widget': 'radio' },
      phq9_3: { 'ui:widget': 'radio' },
      phq9_4: { 'ui:widget': 'radio' },
      phq9_5: { 'ui:widget': 'radio' },
      phq9_6: { 'ui:widget': 'radio' },
      phq9_7: { 'ui:widget': 'radio' },
      phq9_8: { 'ui:widget': 'radio' },
      phq9_9: { 'ui:widget': 'radio' },
      phq9_9_1: { 'ui:widget': 'radio' },
      phq9_9_2: { 'ui:widget': 'radio' },
      phq9_10: { 'ui:widget': 'radio' },
    },
  },

  // Notes
  generalNote: {
    uiSchema: {
      'ui:order': ['subject', 'details', 'accurateAndComplete'],
      details: { 'ui:widget': 'textarea' },
      accurateAndComplete: { 'ui:widget': 'checkbox' },
    },
  },
  initialNote: {
    uiSchema: {
      default: {
        'ui:order': [
          'clientConsent',
          'identifyingInformationTitle',
          'age',
          'gender',
          'ethnicity',
          'maritalStatus',
          'employed',
          'otherIdInfo',
          'intakeSessionComponentsTitle',
          'consentSessionRecording',
          'orientedToTreatmentApproach',
          'discussedTentativeExpectedTreatmentDuration',
          'obtainedCommitmentTreatmentPlan',
          'orientedToDigitalPractices',
          'orientedToDigitalLessons',
          'orientedToOutcomesAssessments',
          'orientedToReminderNotifications',
          'orientedToMessenger',
          'assignedDigitalPractice',
          'assignedDigitalLesson',
          'elicitedAndAdressedFeedbackOnSession',
          'intakeSessionComponentsAdditionalDetails',
          'currentMentalStatus',
          'generalAppearance',
          'cognitiveFunctioning',
          'mood',
          'affect',
          'judgment',
          'functionalStatus',
          'otherMentalHealthObs',
          'safetyIssuesTitle',
          'safetyRiskAssessmentPerformed',
          'safetyRiskAssessmentWhyNot',
          'safetyRiskAssessmentWhen',
          'safetyIssuesIdentified',
          'suicidalIdeation',
          'homicidalIdeation',
          'otherSafetyIssues',
          'completeRiskAssessmentLabel',
          'detailedRiskAssessmentTitle',
          'riskFactors',
          'additionalDetailsOnSafetyPlan',
          'psychiatricHistoryAndTreatment',
          'priorDiagnoses',
          'historySuicidalBehavior',
          'historySelfHarmBehavior',
          'historyPsychiatricHospitalization',
          'historyTrauma',
          'historyMentalHealthServices',
          'historyViolentBehaviors',
          'currentMedicalConditions',
          'currentMedications',
          'substanceUse',
          'psychologicalHistoryTitle',
          'familySocialhistory',
          'familyPsychiatricHistory',
          'developmentalHistory',
          'occupationalHistory',
          'historyOtherInformationTitle',
          'strenghtsLiabilities',
          'otherSymptomsEndorsed',
          'otherBackgroundInformation',
          'caseFormulationTreatmentPlanTitle',
          'precipitantsPresentingProblem',
          'crossSectionalCognitions',
          'longitudinalViewCognitions',
          'workingHypothesis',
          'problemList',
          'problem1',
          'problem2',
          'problem3',
          'otherProblems',
          'treatmentGoals',
          'goal1',
          'goal2',
          'goal3',
          'otherGoals',
          'treatmentPlan',
          'interventionUsedTitle',
          'cbt',
          'additionalSkillsInterventionUsed',
          'additionalSkillsMoreDetails',
          'digitalPracticeAssigned',
          'digitalPracticeAssignedMoreDetails',
          'digitalLessonAssigned',
          'primaryDiagnosis',
          'secondaryDiagnosis',
          'substanceAbuseDiagnosis',
          'noteBridgeToNextSession',
          'planForNextSession',
          'sign',
          'declareInfoAccurateAndComplete',
        ],
        clientConsent: { 'ui:widget': 'radio' },
        employed: { 'ui:widget': 'radio' },
        otherIdInfo: { 'ui:widget': 'textarea' },
        generalAppearance: { 'ui:widget': 'typeAhead' },
        cognitiveFunctioning: { 'ui:widget': 'typeAhead' },
        mood: { 'ui:widget': 'typeAhead' },
        affect: { 'ui:widget': 'typeAhead' },
        judgment: { 'ui:widget': 'typeAhead' },
        functionalStatus: { 'ui:widget': 'typeAhead' },
        otherMentalHealthObs: { 'ui:widget': 'textarea' },
        safetyRiskAssessmentPerformed: { 'ui:widget': 'radio' },
        safetyRiskAssessmentWhyNot: { 'ui:widget': 'textarea' },
        safetyRiskAssessmentWhen: { 'ui:widget': 'text' },
        safetyIssuesIdentified: { 'ui:widget': 'radio' },
        suicidalIdeation: { 'ui:widget': 'select' },
        homicidalIdeation: { 'ui:widget': 'select' },
        otherSafetyIssues: { 'ui:widget': 'textarea' },
        riskFactors: {
          items: [
            {},
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            // otherRiskFactors
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
            {},
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'textarea' },
            // Protective Factors
            {},
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'textarea' },
            // Overall assessment of risk
            { 'ui:widget': 'textarea' },
            {},
            {},
            { 'ui:widget': 'typeAhead' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'typeAhead' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'text' },
            // Safety Plan
            {},
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
            {},
            {},
            { 'ui:widget': 'checkbox' },
            {},
            {},
            {},
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'textarea' },
          ],
        },
        additionalDetailsOnSafetyPlan: { 'ui:widget': 'textarea' },
        priorDiagnoses: { 'ui:widget': 'textarea' },
        historySuicidalBehavior: { 'ui:widget': 'textarea' },
        historySelfHarmBehavior: { 'ui:widget': 'textarea' },
        historyPsychiatricHospitalization: { 'ui:widget': 'textarea' },
        historyTrauma: { 'ui:widget': 'textarea' },
        historyMentalHealthServices: { 'ui:widget': 'textarea' },
        historyViolentBehaviors: { 'ui:widget': 'textarea' },
        currentMedicalConditions: { 'ui:widget': 'textarea' },
        currentMedications: { 'ui:widget': 'textarea' },
        substanceUse: { 'ui:widget': 'textarea' },
        familySocialhistory: { 'ui:widget': 'textarea' },
        familyPsychiatricHistory: { 'ui:widget': 'textarea' },
        developmentalHistory: { 'ui:widget': 'textarea' },
        occupationalHistory: { 'ui:widget': 'textarea' },
        strenghtsLiabilities: { 'ui:widget': 'textarea' },
        otherSymptomsEndorsed: { 'ui:widget': 'textarea' },
        otherBackgroundInformation: { 'ui:widget': 'textarea' },
        precipitantsPresentingProblem: { 'ui:widget': 'textarea' },
        crossSectionalCognitions: { 'ui:widget': 'textarea' },
        longitudinalViewCognitions: { 'ui:widget': 'textarea' },
        workingHypothesis: { 'ui:widget': 'textarea' },
        problem1: { 'ui:widget': 'text' },
        problem2: { 'ui:widget': 'text' },
        problem3: { 'ui:widget': 'text' },
        otherProblems: { 'ui:widget': 'text' },
        goal1: { 'ui:widget': 'text' },
        goal2: { 'ui:widget': 'text' },
        goal3: { 'ui:widget': 'text' },
        otherGoals: { 'ui:widget': 'text' },
        treatmentPlan: { 'ui:widget': 'textarea' },
        consentSessionRecording: { 'ui:widget': 'checkbox' },
        orientedToTreatmentApproach: { 'ui:widget': 'checkbox' },
        discussedTentativeExpectedTreatmentDuration: { 'ui:widget': 'checkbox' },
        obtainedCommitmentTreatmentPlan: { 'ui:widget': 'checkbox' },
        orientedToDigitalPractices: { 'ui:widget': 'checkbox' },
        orientedToDigitalLessons: { 'ui:widget': 'checkbox' },
        orientedToOutcomesAssessments: { 'ui:widget': 'checkbox' },
        orientedToReminderNotifications: { 'ui:widget': 'checkbox' },
        orientedToMessenger: { 'ui:widget': 'checkbox' },
        assignedDigitalPractice: { 'ui:widget': 'checkbox' },
        assignedDigitalLesson: { 'ui:widget': 'checkbox' },
        elicitedAndAdressedFeedbackOnSession: { 'ui:widget': 'checkbox' },
        intakeSessionComponentsAdditionalDetails: { 'ui:widget': 'textarea' },
        cbt: { 'ui:widget': 'checkbox' },
        additionalSkillsInterventionUsed: { 'ui:widget': 'typeAhead' },
        additionalSkillsMoreDetails: { 'ui:widget': 'text' },
        digitalPracticeAssigned: { 'ui:widget': 'typeAhead' },
        digitalPracticeAssignedMoreDetails: { 'ui:widget': 'textarea' },
        digitalLessonAssigned: { 'ui:widget': 'typeAhead' },
        primaryDiagnosis: { 'ui:widget': 'typeAhead' },
        secondaryDiagnosis: { 'ui:widget': 'typeAhead' },
        substanceAbuseDiagnosis: { 'ui:widget': 'select' },
        noteBridgeToNextSession: { 'ui:widget': 'textarea' },
        planForNextSession: { 'ui:widget': 'textarea' },
        declareInfoAccurateAndComplete: { 'ui:widget': 'checkbox' },
      },
      v2: {
        'ui:order': [
          'clientConsent',
          'isBoosterSession',
          'identifyingInformationTitle',
          'age',
          'gender',
          'ethnicity',
          'maritalStatus',
          'employed',
          'otherIdInfo',
          'intakeSessionComponentsTitle',
          'consentSessionRecording',
          'orientedToTreatmentApproach',
          'discussedTentativeExpectedTreatmentDuration',
          'obtainedCommitmentTreatmentPlan',
          'orientedToDigitalPractices',
          'orientedToDigitalLessons',
          'orientedToOutcomesAssessments',
          'orientedToReminderNotifications',
          'orientedToMessenger',
          'assignedDigitalPractice',
          'assignedDigitalLesson',
          'elicitedAndAdressedFeedbackOnSession',
          'currentMentalStatus',
          'generalAppearance',
          'cognitiveFunctioning',
          'mood',
          'affect',
          'judgment',
          'functionalStatus',
          'additionalMentalStatus',
          'otherMentalHealthObs',
          'safetyIssuesTitle',
          'safetyRiskAssessmentPerformed',
          'safetyRiskAssessmentWhyNot',
          'safetyRiskAssessmentWhen',
          'safetyIssuesIdentified',
          'suicidalIdeation',
          'homicidalIdeation',
          'completeRiskAssessmentLabel',
          'detailedRiskAssessmentTitle',
          'riskFactors',
          'additionalDetailsOnSafetyPlan',
          'psychiatricHistoryAndTreatment',
          'priorDiagnoses',
          'historySuicidalBehavior',
          'historySelfHarmBehavior',
          'historyPsychiatricHospitalization',
          'historyTrauma',
          'historyMentalHealthServices',
          'historyViolentBehaviors',
          'currentMedicalConditions',
          'currentMedications',
          'substanceUse',
          'psychologicalHistoryTitle',
          'familySocialhistory',
          'familyPsychiatricHistory',
          'developmentalHistory',
          'occupationalHistory',
          'historyOtherInformationTitle',
          'strenghtsLiabilities',
          'otherSymptomsEndorsed',
          'otherBackgroundInformation',
          'isClientCaregiverForChildren',
          'caseFormulationTreatmentPlanTitle',
          'precipitantsPresentingProblem',
          'crossSectionalCognitions',
          'longitudinalViewCognitions',
          'workingHypothesis',
          'problemList',
          'problem1',
          'problem2',
          'problem3',
          'otherProblems',
          'addressedThisSession',
          'treatmentGoals',
          'goal1',
          'goal2',
          'goal3',
          'otherGoals',
          'goalsAddressedThisSession',
          'treatmentPlan',
          'interventionUsedTitle',
          'cbt',
          'additionalSkillsInterventionUsed',
          'additionalSkillsMoreDetails',
          'digitalPracticeAssigned',
          'digitalPracticeAssignedMoreDetails',
          'digitalLessonAssigned',
          'primaryDiagnosis',
          'secondaryDiagnosis',
          'substanceAbuseDiagnosis',
          'noteBridgeToNextSession',
          'sign',
          'declareInfoAccurateAndComplete',
        ],
        clientConsent: { 'ui:widget': 'radio' },
        isBoosterSession: { 'ui:widget': 'radio' },
        employed: { 'ui:widget': 'radio' },
        otherIdInfo: { 'ui:widget': 'textarea' },
        generalAppearance: { 'ui:widget': 'typeAhead' },
        cognitiveFunctioning: { 'ui:widget': 'typeAhead' },
        mood: { 'ui:widget': 'typeAhead' },
        affect: { 'ui:widget': 'typeAhead' },
        judgment: { 'ui:widget': 'typeAhead' },
        functionalStatus: { 'ui:widget': 'typeAhead' },
        additionalMentalStatus: { 'ui:widget': 'textarea' },
        otherMentalHealthObs: { 'ui:widget': 'textarea' },
        safetyRiskAssessmentPerformed: { 'ui:widget': 'radio' },
        safetyRiskAssessmentWhyNot: { 'ui:widget': 'textarea' },
        safetyRiskAssessmentWhen: { 'ui:widget': 'text' },
        safetyIssuesIdentified: { 'ui:widget': 'radio' },
        suicidalIdeation: { 'ui:widget': 'select' },
        homicidalIdeation: { 'ui:widget': 'select' },
        riskFactors: {
          items: [
            {},
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            // otherRiskFactors
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
            {},
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'textarea' },
            // Protective Factors
            {},
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'textarea' },
            // Overall assessment of risk
            { 'ui:widget': 'textarea' },
            {},
            {},
            { 'ui:widget': 'typeAhead' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'typeAhead' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'text' },
            // Safety Plan
            {},
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
            {},
            {},
            { 'ui:widget': 'checkbox' },
            {},
            {},
            {},
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'textarea' },
          ],
        },
        additionalDetailsOnSafetyPlan: { 'ui:widget': 'textarea' },
        priorDiagnoses: { 'ui:widget': 'textarea' },
        historySuicidalBehavior: { 'ui:widget': 'textarea' },
        historySelfHarmBehavior: { 'ui:widget': 'textarea' },
        historyPsychiatricHospitalization: { 'ui:widget': 'textarea' },
        historyTrauma: { 'ui:widget': 'textarea' },
        historyMentalHealthServices: { 'ui:widget': 'textarea' },
        historyViolentBehaviors: { 'ui:widget': 'textarea' },
        currentMedicalConditions: { 'ui:widget': 'textarea' },
        currentMedications: { 'ui:widget': 'textarea' },
        substanceUse: { 'ui:widget': 'textarea' },
        familySocialhistory: { 'ui:widget': 'textarea' },
        familyPsychiatricHistory: { 'ui:widget': 'textarea' },
        developmentalHistory: { 'ui:widget': 'textarea' },
        occupationalHistory: { 'ui:widget': 'textarea' },
        strenghtsLiabilities: { 'ui:widget': 'textarea' },
        otherSymptomsEndorsed: { 'ui:widget': 'textarea' },
        otherBackgroundInformation: { 'ui:widget': 'textarea' },
        isClientCaregiverForChildren: { 'ui:widget': 'select' },
        precipitantsPresentingProblem: { 'ui:widget': 'textarea' },
        crossSectionalCognitions: { 'ui:widget': 'textarea' },
        longitudinalViewCognitions: { 'ui:widget': 'textarea' },
        workingHypothesis: { 'ui:widget': 'textarea' },
        problem1: { 'ui:widget': 'text' },
        problem2: { 'ui:widget': 'text' },
        problem3: { 'ui:widget': 'text' },
        otherProblems: { 'ui:widget': 'text' },
        addressedThisSession: { 'ui:widget': 'checkbox' },
        goal1: { 'ui:widget': 'text' },
        goal2: { 'ui:widget': 'text' },
        goal3: { 'ui:widget': 'text' },
        otherGoals: { 'ui:widget': 'text' },
        goalsAddressedThisSession: { 'ui:widget': 'checkbox' },
        treatmentPlan: { 'ui:widget': 'textarea' },
        consentSessionRecording: { 'ui:widget': 'checkbox' },
        orientedToTreatmentApproach: { 'ui:widget': 'checkbox' },
        discussedTentativeExpectedTreatmentDuration: { 'ui:widget': 'checkbox' },
        obtainedCommitmentTreatmentPlan: { 'ui:widget': 'checkbox' },
        orientedToDigitalPractices: { 'ui:widget': 'checkbox' },
        orientedToDigitalLessons: { 'ui:widget': 'checkbox' },
        orientedToOutcomesAssessments: { 'ui:widget': 'checkbox' },
        orientedToReminderNotifications: { 'ui:widget': 'checkbox' },
        orientedToMessenger: { 'ui:widget': 'checkbox' },
        assignedDigitalPractice: { 'ui:widget': 'checkbox' },
        assignedDigitalLesson: { 'ui:widget': 'checkbox' },
        elicitedAndAdressedFeedbackOnSession: { 'ui:widget': 'checkbox' },
        cbt: { 'ui:widget': 'checkbox' },
        additionalSkillsInterventionUsed: { 'ui:widget': 'typeAhead' },
        additionalSkillsMoreDetails: { 'ui:widget': 'text' },
        digitalPracticeAssigned: { 'ui:widget': 'typeAhead' },
        digitalPracticeAssignedMoreDetails: { 'ui:widget': 'textarea' },
        digitalLessonAssigned: { 'ui:widget': 'typeAhead' },
        primaryDiagnosis: { 'ui:widget': 'typeAhead' },
        secondaryDiagnosis: { 'ui:widget': 'typeAhead' },
        substanceAbuseDiagnosis: { 'ui:widget': 'select' },
        noteBridgeToNextSession: { 'ui:widget': 'textarea' },
        declareInfoAccurateAndComplete: { 'ui:widget': 'checkbox' },
      },
    },
  },
  sessionNote: {
    uiSchema: {
      default: {
        'ui:order': [
          'lastSession',
          'terminationReason',
          'additionalTerminationReason',
          'clientPresentation',
          'symptomDescription',
          'sessionComponents',
          'collectAndReviewOutcomes',
          'discussedOutcomes',
          'reviewedDigitalPractice',
          'reviewedDigitalLesson',
          'discussedTreatmentProgress',
          'discussedTherapyTermination',
          'assignedPractice',
          'assignedLesson',
          'obtainedAssignmentCommitment',
          'troubleshootAssignment',
          'assignedWellBeingScale',
          'elicitedClientFeedback',
          'notableClientFeedback',
          'additionalComponentDetails',
          'mentalStatus',
          'generalAppearance',
          'cognitiveFunctioning',
          'mood',
          'affect',
          'judgment',
          'functionalStatus',
          'otherMentalStatus',
          'primaryDiagnosis',
          'secondaryDiagnosis',
          'otherDiagnosis',
          'substanceAbuseDiagnosis',
          'safetyIssuesLabel',
          'suicidalIdeation',
          'homicidalIdeation',
          'domesticViolence',
          'otherSafetyIssues',
          'additionalInfoSafetyIssues',
          'riskAssessmentLabel',
          'medicationChanges',
          'updateFormulationAndTreatmentLabel',
          'problemPrecipitants',
          'crossSectionalSymptoms',
          'longitudinalSymptoms',
          'workingHypothesis',
          'problemListLabel',
          'problemOne',
          'problemTwo',
          'problemThree',
          'otherProblems',
          'treatmentGoalsLabel',
          'treatmentGoalOne',
          'treatmentGoalTwo',
          'treatmentGoalThree',
          'otherTreatmentGoals',
          'treatmentPlan',
          'problemsAndTreatmentGoalsLabel',
          'problemOneAddressed',
          'problemTwoAddressed',
          'problemThreeAddressed',
          'otherProblemAddressed',
          'goalOneAddressed',
          'goalTwoAddressed',
          'goalThreeAddressed',
          'otherGoalsAddressed',
          'progressOnProblemsAndGoals',
          'clientMakingProgress',
          'progressBarriers',
          'sessionInterventions',
          'cbtCheckbox',
          'additionalIntervention',
          'symptomMonitoring',
          'thinkingTraps',
          'problemSolving',
          'assertivness',
          'interpersonalEffectiveness',
          'exposure',
          'behavioralActivation',
          'values',
          'mindfulness',
          'understandingEmotions',
          'prosCons',
          'distressTolerance',
          'sleepHygiene',
          'validation',
          'otherInterventions',
          'additionalInterventionDetails',
          'terminationSessionComponents',
          'collectedOutcomeMeasures',
          'discussedOutcomeMeasures',
          'reviewedCompletionOfPractice',
          'reviewedCompletionOfLesson',
          'discussedGoalsProgress',
          'assignedDigitalPractice',
          'assignedDigitalLesson',
          'createdRelapsePreventionPlan',
          'consideredSchedulingBoosterSession',
          'elicitedTreatmentFeedbackFromClient',
          'elicitedPlatformFeedbackFromClient',
          'assignedWellBeingScaleAtTermination',
          'notableClientFeedbackOnTreatment',
          'additionalDetailsOnTermination',
          'digitalPracticeAssigned',
          'additionalPracticeAssignedDetails',
          'digitalLessonAssigned',
          'nextSessionPlan',
          'estimatedAdditionalSessions',
          'additionalDetailsOnAdditionalSessions',
          'sign',
          'accurateAndComplete',
        ],
        lastSession: { 'ui:widget': 'radio' },
        terminationReason: { 'ui:widget': 'typeAhead' },
        additionalTerminationReason: { 'ui:widget': 'textarea' },
        symptomDescription: { 'ui:widget': 'textarea' },
        generalAppearance: { 'ui:widget': 'typeAhead' },
        cognitiveFunctioning: { 'ui:widget': 'typeAhead' },
        mood: { 'ui:widget': 'typeAhead' },
        affect: { 'ui:widget': 'typeAhead' },
        judgment: { 'ui:widget': 'typeAhead' },
        functionalStatus: { 'ui:widget': 'typeAhead' },
        otherMentalStatus: { 'ui:widget': 'textarea' },
        primaryDiagnosis: { 'ui:widget': 'typeAhead' },
        secondaryDiagnosis: { 'ui:widget': 'typeAhead' },
        otherDiagnosis: { 'ui:widget': 'typeAhead' },
        substanceAbuseDiagnosis: { 'ui:widget': 'select' },
        suicidalIdeation: { 'ui:widget': 'checkbox' },
        homicidalIdeation: { 'ui:widget': 'checkbox' },
        domesticViolence: { 'ui:widget': 'checkbox' },
        otherSafetyIssues: { 'ui:widget': 'textarea' },
        additionalInfoSafetyIssues: { 'ui:widget': 'textarea' },
        medicationChanges: { 'ui:widget': 'textarea' },
        problemPrecipitants: { 'ui:widget': 'textarea' },
        crossSectionalSymptoms: { 'ui:widget': 'textarea' },
        longitudinalSymptoms: { 'ui:widget': 'textarea' },
        workingHypothesis: { 'ui:widget': 'textarea' },
        otherProblems: { 'ui:widget': 'textarea' },
        treatmentGoalOne: { 'ui:widget': 'textarea' },
        treatmentGoalTwo: { 'ui:widget': 'textarea' },
        treatmentGoalThree: { 'ui:widget': 'textarea' },
        otherTreatmentGoals: { 'ui:widget': 'textarea' },
        treatmentPlan: { 'ui:widget': 'textarea' },
        problemOneAddressed: { 'ui:widget': 'checkbox' },
        problemTwoAddressed: { 'ui:widget': 'checkbox' },
        problemThreeAddressed: { 'ui:widget': 'checkbox' },
        otherProblemAddressed: { 'ui:widget': 'textarea' },
        goalOneAddressed: { 'ui:widget': 'checkbox' },
        goalTwoAddressed: { 'ui:widget': 'checkbox' },
        goalThreeAddressed: { 'ui:widget': 'checkbox' },
        otherGoalsAddressed: { 'ui:widget': 'textarea' },
        progressOnProblemsAndGoals: { 'ui:widget': 'textarea' },
        clientMakingProgress: { 'ui:widget': 'radio' },
        progressBarriers: { 'ui:widget': 'textarea' },
        cbtCheckbox: { 'ui:widget': 'checkbox' },
        symptomMonitoring: { 'ui:widget': 'checkbox' },
        thinkingTraps: { 'ui:widget': 'checkbox' },
        problemSolving: { 'ui:widget': 'checkbox' },
        assertivness: { 'ui:widget': 'checkbox' },
        interpersonalEffectiveness: { 'ui:widget': 'checkbox' },
        exposure: { 'ui:widget': 'checkbox' },
        behavioralActivation: { 'ui:widget': 'checkbox' },
        values: { 'ui:widget': 'checkbox' },
        mindfulness: { 'ui:widget': 'checkbox' },
        understandingEmotions: { 'ui:widget': 'checkbox' },
        prosCons: { 'ui:widget': 'checkbox' },
        distressTolerance: { 'ui:widget': 'checkbox' },
        sleepHygiene: { 'ui:widget': 'checkbox' },
        validation: { 'ui:widget': 'checkbox' },
        otherInterventions: { 'ui:widget': 'textarea' },
        additionalInterventionDetails: { 'ui:widget': 'textarea' },
        collectedOutcomeMeasures: { 'ui:widget': 'checkbox' },
        discussedOutcomeMeasures: { 'ui:widget': 'checkbox' },
        reviewedCompletionOfPractice: { 'ui:widget': 'checkbox' },
        reviewedCompletionOfLesson: { 'ui:widget': 'checkbox' },
        discussedGoalsProgress: { 'ui:widget': 'checkbox' },
        assignedDigitalPractice: { 'ui:widget': 'checkbox' },
        assignedDigitalLesson: { 'ui:widget': 'checkbox' },
        createdRelapsePreventionPlan: { 'ui:widget': 'checkbox' },
        consideredSchedulingBoosterSession: { 'ui:widget': 'checkbox' },
        elicitedTreatmentFeedbackFromClient: { 'ui:widget': 'checkbox' },
        assignedWellBeingScaleAtTermination: { 'ui:widget': 'checkbox' },
        notableClientFeedbackOnTreatment: { 'ui:widget': 'textarea' },
        additionalDetailsOnTermination: { 'ui:widget': 'textarea' },
        collectAndReviewOutcomes: { 'ui:widget': 'checkbox' },
        discussedOutcomes: { 'ui:widget': 'checkbox' },
        reviewedDigitalPractice: { 'ui:widget': 'checkbox' },
        reviewedDigitalLesson: { 'ui:widget': 'checkbox' },
        discussedTreatmentProgress: { 'ui:widget': 'checkbox' },
        discussedTherapyTermination: { 'ui:widget': 'checkbox' },
        assignedPractice: { 'ui:widget': 'checkbox' },
        assignedLesson: { 'ui:widget': 'checkbox' },
        obtainedAssignmentCommitment: { 'ui:widget': 'checkbox' },
        troubleshootAssignment: { 'ui:widget': 'checkbox' },
        assignedWellBeingScale: { 'ui:widget': 'checkbox' },
        elicitedClientFeedback: { 'ui:widget': 'checkbox' },
        notableClientFeedback: { 'ui:widget': 'textarea' },
        additionalComponentDetails: { 'ui:widget': 'textarea' },
        digitalPracticeAssigned: { 'ui:widget': 'typeAhead' },
        additionalPracticeAssignedDetails: { 'ui:widget': 'textarea' },
        digitalLessonAssigned: { 'ui:widget': 'typeAhead' },
        nextSessionPlan: { 'ui:widget': 'textarea' },
        additionalDetailsOnAdditionalSessions: { 'ui:widget': 'textarea' },
        accurateAndComplete: { 'ui:widget': 'checkbox' },
      },
      v2: {
        'ui:order': [
          'lastSession',
          'isBoosterSession',
          'terminationReason',
          'additionalTerminationReason',
          'clientPresentation',
          'symptomDescription',
          'sessionComponents',
          'collectAndReviewOutcomes',
          'askedVerbalInformedConsent',
          'discussedOutcomes',
          'reviewedDigitalPractice',
          'reviewedDigitalLesson',
          'discussedTreatmentProgress',
          'discussedTherapyTermination',
          'assignedPractice',
          'assignedLesson',
          'obtainedAssignmentCommitment',
          'troubleshootAssignment',
          'assignedWellBeingScale',
          'elicitedClientFeedback',
          'agreedToRecordSession',
          'mentalStatus',
          'generalAppearance',
          'cognitiveFunctioning',
          'mood',
          'affect',
          'judgment',
          'functionalStatus',
          'additionalMentalStatus',
          'otherMentalStatus',
          'primaryDiagnosis',
          'secondaryDiagnosis',
          'otherDiagnosis',
          'substanceAbuseDiagnosis',
          'safetyIssuesLabel',
          'suicidalIdeation',
          'homicidalIdeation',
          'domesticViolence',
          'additionalInfoSafetyIssues',
          'riskAssessmentLabel',
          'updateFormulationAndTreatmentLabel',
          'problemPrecipitants',
          'crossSectionalSymptoms',
          'longitudinalSymptoms',
          'workingHypothesis',
          'problemListLabel',
          'problemOne',
          'problemTwo',
          'problemThree',
          'otherProblems',
          'addressedThisSession',
          'treatmentGoalsLabel',
          'treatmentGoalOne',
          'treatmentGoalTwo',
          'treatmentGoalThree',
          'otherTreatmentGoals',
          'medicationChanges',
          'medicationChangesDetails',
          'treatmentPlan',
          'problemsAndTreatmentGoalsLabel',
          'problemOneAddressed',
          'problemTwoAddressed',
          'problemThreeAddressed',
          'otherProblemAddressed',
          'goalOneAddressed',
          'goalTwoAddressed',
          'goalThreeAddressed',
          'otherGoalsAddressed',
          'goalsAddressedThisSession',
          'clientMakingProgress',
          'progressBarriers',
          'sessionInterventions',
          'additionalIntervention',
          'assertivness',
          'behavioralActivation',
          'cbtCheckbox',
          'distressTolerance',
          'exposure',
          'interpersonalEffectiveness',
          'mindfulness',
          'problemSolving',
          'prosCons',
          'sleepHygiene',
          'symptomMonitoring',
          'thinkingTraps',
          'understandingEmotions',
          'validation',
          'values',
          'otherInterventions',
          'terminationSessionComponents',
          'collectedOutcomeMeasures',
          'askedVerbalInformedConsentTermination',
          'discussedOutcomeMeasures',
          'reviewedCompletionOfPractice',
          'reviewedCompletionOfLesson',
          'discussedGoalsProgress',
          'assignedDigitalPractice',
          'assignedDigitalLesson',
          'createdRelapsePreventionPlan',
          'consideredSchedulingBoosterSession',
          'elicitedTreatmentFeedbackFromClient',
          'elicitedPlatformFeedbackFromClient',
          'assignedWellBeingScaleAtTermination',
          'notableClientFeedbackOnTreatment',
          'additionalDetailsOnTermination',
          'digitalPracticeAssigned',
          'additionalPracticeAssignedDetails',
          'digitalLessonAssigned',
          'nextSessionPlan',
          'estimatedAdditionalSessions',
          'additionalDetailsOnAdditionalSessions',
          'sign',
          'accurateAndComplete',
        ],
        lastSession: { 'ui:widget': 'radio' },
        isBoosterSession: { 'ui:widget': 'radio' },
        terminationReason: { 'ui:widget': 'typeAhead' },
        additionalTerminationReason: { 'ui:widget': 'textarea' },
        symptomDescription: { 'ui:widget': 'textarea' },
        generalAppearance: { 'ui:widget': 'typeAhead' },
        cognitiveFunctioning: { 'ui:widget': 'typeAhead' },
        mood: { 'ui:widget': 'typeAhead' },
        affect: { 'ui:widget': 'typeAhead' },
        judgment: { 'ui:widget': 'typeAhead' },
        functionalStatus: { 'ui:widget': 'typeAhead' },
        additionalMentalStatus: { 'ui:widget': 'textarea' },
        otherMentalStatus: { 'ui:widget': 'textarea' },
        primaryDiagnosis: { 'ui:widget': 'typeAhead' },
        secondaryDiagnosis: { 'ui:widget': 'typeAhead' },
        otherDiagnosis: { 'ui:widget': 'typeAhead' },
        substanceAbuseDiagnosis: { 'ui:widget': 'select' },
        suicidalIdeation: { 'ui:widget': 'checkbox' },
        homicidalIdeation: { 'ui:widget': 'checkbox' },
        domesticViolence: { 'ui:widget': 'checkbox' },
        additionalInfoSafetyIssues: { 'ui:widget': 'textarea' },
        medicationChanges: { 'ui:widget': 'checkbox' },
        medicationChangesDetails: { 'ui:widget': 'textarea' },
        problemPrecipitants: { 'ui:widget': 'textarea' },
        crossSectionalSymptoms: { 'ui:widget': 'textarea' },
        longitudinalSymptoms: { 'ui:widget': 'textarea' },
        workingHypothesis: { 'ui:widget': 'textarea' },
        otherProblems: { 'ui:widget': 'text' },
        addressedThisSession: { 'ui:widget': 'checkbox' },
        treatmentGoalOne: { 'ui:widget': 'text' },
        treatmentGoalTwo: { 'ui:widget': 'text' },
        treatmentGoalThree: { 'ui:widget': 'text' },
        otherTreatmentGoals: { 'ui:widget': 'text' },
        treatmentPlan: { 'ui:widget': 'textarea' },
        problemOneAddressed: { 'ui:widget': 'checkbox' },
        problemTwoAddressed: { 'ui:widget': 'checkbox' },
        problemThreeAddressed: { 'ui:widget': 'checkbox' },
        otherProblemAddressed: { 'ui:widget': 'textarea' },
        goalOneAddressed: { 'ui:widget': 'checkbox' },
        goalTwoAddressed: { 'ui:widget': 'checkbox' },
        goalThreeAddressed: { 'ui:widget': 'checkbox' },
        otherGoalsAddressed: { 'ui:widget': 'textarea' },
        goalsAddressedThisSession: { 'ui:widget': 'checkbox' },
        clientMakingProgress: { 'ui:widget': 'radio' },
        progressBarriers: { 'ui:widget': 'textarea' },
        cbtCheckbox: { 'ui:widget': 'checkbox' },
        symptomMonitoring: { 'ui:widget': 'checkbox' },
        thinkingTraps: { 'ui:widget': 'checkbox' },
        problemSolving: { 'ui:widget': 'checkbox' },
        assertivness: { 'ui:widget': 'checkbox' },
        interpersonalEffectiveness: { 'ui:widget': 'checkbox' },
        exposure: { 'ui:widget': 'checkbox' },
        behavioralActivation: { 'ui:widget': 'checkbox' },
        values: { 'ui:widget': 'checkbox' },
        mindfulness: { 'ui:widget': 'checkbox' },
        understandingEmotions: { 'ui:widget': 'checkbox' },
        prosCons: { 'ui:widget': 'checkbox' },
        distressTolerance: { 'ui:widget': 'checkbox' },
        sleepHygiene: { 'ui:widget': 'checkbox' },
        validation: { 'ui:widget': 'checkbox' },
        otherInterventions: { 'ui:widget': 'checkbox' },
        collectedOutcomeMeasures: { 'ui:widget': 'checkbox' },
        askedVerbalInformedConsentTermination: { 'ui:widget': 'checkbox' },
        discussedOutcomeMeasures: { 'ui:widget': 'checkbox' },
        reviewedCompletionOfPractice: { 'ui:widget': 'checkbox' },
        reviewedCompletionOfLesson: { 'ui:widget': 'checkbox' },
        discussedGoalsProgress: { 'ui:widget': 'checkbox' },
        assignedDigitalPractice: { 'ui:widget': 'checkbox' },
        assignedDigitalLesson: { 'ui:widget': 'checkbox' },
        createdRelapsePreventionPlan: { 'ui:widget': 'checkbox' },
        consideredSchedulingBoosterSession: { 'ui:widget': 'checkbox' },
        elicitedTreatmentFeedbackFromClient: { 'ui:widget': 'checkbox' },
        assignedWellBeingScaleAtTermination: { 'ui:widget': 'checkbox' },
        notableClientFeedbackOnTreatment: { 'ui:widget': 'textarea' },
        additionalDetailsOnTermination: { 'ui:widget': 'textarea' },
        collectAndReviewOutcomes: { 'ui:widget': 'checkbox' },
        askedVerbalInformedConsent: { 'ui:widget': 'checkbox' },
        discussedOutcomes: { 'ui:widget': 'checkbox' },
        reviewedDigitalPractice: { 'ui:widget': 'checkbox' },
        reviewedDigitalLesson: { 'ui:widget': 'checkbox' },
        discussedTreatmentProgress: { 'ui:widget': 'checkbox' },
        discussedTherapyTermination: { 'ui:widget': 'checkbox' },
        assignedPractice: { 'ui:widget': 'checkbox' },
        assignedLesson: { 'ui:widget': 'checkbox' },
        obtainedAssignmentCommitment: { 'ui:widget': 'checkbox' },
        troubleshootAssignment: { 'ui:widget': 'checkbox' },
        assignedWellBeingScale: { 'ui:widget': 'checkbox' },
        elicitedClientFeedback: { 'ui:widget': 'checkbox' },
        agreedToRecordSession: { 'ui:widget': 'radio' },
        digitalPracticeAssigned: { 'ui:widget': 'typeAhead' },
        additionalPracticeAssignedDetails: { 'ui:widget': 'textarea' },
        digitalLessonAssigned: { 'ui:widget': 'typeAhead' },
        nextSessionPlan: { 'ui:widget': 'textarea' },
        additionalDetailsOnAdditionalSessions: { 'ui:widget': 'textarea' },
        accurateAndComplete: { 'ui:widget': 'checkbox' },
      },
    },
  },
  medsInitialNote: {
    uiSchema: {
      'ui:order': [
        'verbalInformedConsent_1',
        'isBoosterSession',
        'identifyingInformation_2',
        'identifyingInformation_3',
        'chiefComplaint_5',
        'hpi_8',
        'psychiatricReviewSystems_9',
        'symptomsEndorsedIntake_10',
        'depression_11',
        'depressedMood_12',
        'moodInformationFrom_13',
        'sleep_14',
        'sleepInformationFrom_15',
        'interest_16',
        'interestInformationFrom_17',
        'psychomotorAgitationRetardation_18',
        'psychomotorAgitationRetardation_18_2',
        'psychomotorAgitationRetardation_19',
        'guiltWorthlessness_20',
        'guiltWorthlessness_20_2',
        'guiltWorthlessnessInformation_21',
        'energy_22',
        'energyInformationFrom_23',
        'concentration_24',
        'concentrationInformationFrom_25',
        'appetite_26',
        'appetite_26_2',
        'appetiteInformationFrom_27',
        'si_28',
        'siInformationFrom_29',
        'hi_30',
        'hiInformationFrom_31',
        'bipolarMania_32',
        'mood_33',
        'moodInformationFrom_34',
        'irritability_35',
        'irritabilityInformationFrom_36',
        'noSleep_37',
        'noSleepInformation_38',
        'speech_39',
        'speechInformationFrom_40',
        'grandiosity_41',
        'grandiosityInformationFrom_42',
        'racingThoughts_43',
        'racingThoughtsInformation_44',
        'goalOrientedRisky_45',
        'goalOrientedRisky_46',
        'distractibilityConcentration_47',
        'distractibilityConcentrationInformation_48',
        'psychosis_49',
        'ah_50',
        'ahInformationFrom_51',
        'vh_52',
        'vhInformationFrom_53',
        'paranoia_54',
        'paranoiaInformationFrom_55',
        'delusionsInformationFrom_57',
        'anxiety_58',
        'irritability_59',
        'irritabilityInformationFrom_60',
        'concentration_61',
        'concentrationInformationFrom_62',
        'sleep_63',
        'sleepInformationFrom_64',
        'physicalSymptomsAnxiety_66',
        'socialAnxiety_67',
        'socialAnxietyInformation_68',
        'worry_69',
        'worryInformationFrom_70',
        'agoraphobia_71',
        'agoraphobiaInformationFrom_72',
        'panicAttacks_73',
        'panicAttacksInformation_74',
        'ptsd_75',
        'traumaInformationFrom_76',
        'avoidanceInformationFrom_77',
        'ocd_82',
        'obsessiveThoughts_83',
        'obsessiveThoughtsInformation_84',
        'compulsiveBehaviors_85',
        'compulsiveBehaviorsInformation_86',
        'impulseControl_87',
        'impulseControlInformation_88',
        'eatingDisorders_89',
        'restrictingEating_90',
        'restrictingEatingInformation_91',
        'intenseFearGaining_92',
        'intenseFearGaining_93',
        'bingeEating_94',
        'bingeEatingInformation_95',
        'bulimia_96',
        'bulimiaInformationFrom_97',
        'history_99',
        'psychiatricHistory_100',
        'suicideAttempts_101',
        'suicideAttempts_102',
        'suicideAttempts_103',
        'selfInjury_104',
        'selfInjury_105',
        'selfInjury_106',
        'violenceHistory_107',
        'violenceHistory_108',
        'violenceHistory_109',
        'psychiatricHospitalizations_110',
        'psychiatricHospitalizations_111',
        'psychiatricHospitalizations_112',
        'previousPsychiatricDiagnoses_46',
        'previousPsychiatricDiagnoses_47',
        'pastPsychiatricMedications_115',
        'currentPreviousProviders_117',
        'socialHistory_119',
        'detailsAboutChildhood_120',
        'relationshipStatus_122',
        'relationshipStatus_123',
        'relationshipStatus_124',
        'relationshipStatus_125',
        'children_126',
        'livingSituation_127',
        'familySocialSupports_128',
        'considerSpiritualReligious_130',
        'considerSpiritualReligious_131',
        'education_132',
        'workingNow_134',
        'workingNow_135',
        'legalHistory_136',
        'currentlyInvolvedLegal_137',
        'currentlyInvolvedLegal_138',
        'accessGun_139',
        'additionalSocialHistoryDetails_139_1',
        'substanceUseHistory_140',
        'clientDrinks_142',
        'clientDrinks_141',
        'clientBinges_143',
        'clientUsesTobacco_144',
        'clientUsesPrescription_145',
        'clientUsesIllicit_146',
        'clientUsesCannabis_147',
        'additionalSubstanceUse_148',
        'sexualPhysicalAbuse_149',
        'additionalSocialHistory_150',
        'familyHistory_151',
        'historyPsychiatricEmotional_152',
        'historyPsychiatricEmotional_153',
        'additionalDetailsFrom_154',
        'medicalHistory_155',
        'chronicOngoingMedical_156',
        'medicalConditionsOther_47',
        'additionalMedicalIssues_157',
        'snoringGaspingBreath_158',
        'painLevel_159',
        'painLevel_160',
        'additionalDetailsFrom_161',
        'pregnancy_162',
        'currentMedicationsAllergies_163',
        'currentlyTakingPrescription_164',
        'currentMedications_165',
        'allergies_166',
        'sideEffectConcerns_167',
        'currentMentalStatus_168',
        'generalAppearance_169',
        'cognitiveFunctioning_170',
        'mood_171',
        'affect_172',
        'judgment_173',
        'functionalStatus_174',
        'otherNotableCurrent_175',
        'riskAssessmentManagement_176',
        'safetyRiskAssessment_177',
        'safetyRiskAssessment_178',
        'safetyRiskAssessment_179',
        'safetyIssuesIdentified_180',
        'suicidalIdeation_181',
        'homicidalIdeation_182',
        'otherSafetyIssues_183',
        'safetyIssuesIdentified_184',
        'safetyIssuesIdentified_185',
        'safetyIssuesIdentified_186',
        'safetyIssuesIdentified_187',
        'safetyIssuesIdentified_188',
        'safetyIssuesIdentified_189',
        'safetyIssuesIdentified_190',
        'safetyIssuesIdentified_191',
        'safetyIssuesIdentified_192',
        'safetyIssuesIdentified_193',
        'safetyIssuesIdentified_194',
        'safetyIssuesIdentified_195',
        'safetyIssuesIdentified_196',
        'safetyIssuesIdentified_197',
        'safetyIssuesIdentified_198',
        'safetyIssuesIdentified_199',
        'safetyIssuesIdentified_200',
        'safetyIssuesIdentified_201',
        'safetyIssuesIdentified_202',
        'safetyIssuesIdentified_203',
        'safetyIssuesIdentified_204',
        'safetyIssuesIdentified_205',
        'safetyIssuesIdentified_206',
        'safetyIssuesIdentified_207',
        'safetyIssuesIdentified_208',
        'safetyIssuesIdentified_209',
        'safetyIssuesIdentified_210',
        'safetyIssuesIdentified_211',
        'safetyIssuesIdentified_212',
        'safetyIssuesIdentified_213',
        'safetyIssuesIdentified_214',
        'safetyIssuesIdentified_215',
        'safetyIssuesIdentified_216',
        'safetyIssuesIdentified_217',
        'safetyIssuesIdentified_218',
        'safetyIssuesIdentified_219',
        'safetyIssuesIdentified_220',
        'safetyIssuesIdentified_221',
        'safetyIssuesIdentified_222',
        'safetyIssuesIdentified_223',
        'safetyIssuesIdentified_224',
        'safetyIssuesIdentified_225',
        'safetyIssuesIdentified_226',
        'safetyIssuesIdentified_227',
        'safetyIssuesIdentified_228',
        'safetyIssuesIdentified_229',
        'safetyIssuesIdentified_230',
        'safetyIssuesIdentified_231',
        'safetyIssuesIdentified_232',
        'safetyIssuesIdentified_233',
        'safetyIssuesIdentified_234',
        'safetyIssuesIdentified_235',
        'safetyIssuesIdentified_236',
        'safetyIssuesIdentified_237',
        'safetyIssuesIdentified_238',
        'safetyIssuesIdentified_239',
        'safetyIssuesIdentified_240',
        'safetyIssuesIdentified_241',
        'safetyIssuesIdentified_242',
        'safetyIssuesIdentified_243',
        'safetyIssuesIdentified_244',
        'safetyIssuesIdentified_245',
        'safetyIssuesIdentified_246',
        'safetyIssuesIdentified_247',
        'safetyIssuesIdentified_248',
        'safetyIssuesIdentified_249',
        'safetyIssuesIdentified_250',
        'safetyIssuesIdentified_251',
        'safetyIssuesIdentified_252',
        'safetyIssuesIdentified_253',
        'safetyIssuesIdentified_254',
        'safetyIssuesIdentified_255',
        'safetyIssuesIdentified_256',
        'safetyIssuesIdentified_257',
        'safetyIssuesIdentified_258',
        'safetyIssuesIdentified_259',
        'safetyIssuesIdentified_260',
        'safetyIssuesIdentified_261',
        'safetyIssuesIdentified_262',
        'safetyIssuesIdentified_263',
        'safetyIssuesIdentified_264',
        'safetyIssuesIdentified_265',
        'safetyIssuesIdentified_266',
        'safetyIssuesIdentified_267',
        'safetyIssuesIdentified_268',
        'safetyIssuesIdentified_269',
        'safetyIssuesIdentified_270',
        'safetyIssuesIdentified_271',
        'safetyIssuesIdentified_272',
        'safetyIssuesIdentified_273',
        'safetyIssuesIdentified_274',
        'safetyIssuesIdentified_275',
        'safetyIssuesIdentified_276',
        'safetyIssuesIdentified_277',
        'safetyIssuesIdentified_278',
        'safetyIssuesIdentified_279',
        'safetyIssuesIdentified_280',
        'safetyIssuesIdentified_281',
        'safetyIssuesIdentified_282',
        'safetyIssuesIdentified_283',
        'safetyIssuesIdentified_284',
        'safetyIssuesIdentified_285',
        'safetyIssuesIdentified_286',
        'safetyIssuesIdentified_287',
        'safetyIssuesIdentified_288',
        'labsImaging_289',
        'labsImaging_290',
        'assessments_291',
        'caseFormulation_296',
        'bioGeneticDevelopmental_298',
        'psychoCognitiveStyle_299',
        'socialRelationshipsFamily_300',
        'summary_301',
        'dsmDiagnosis_304',
        'primaryDiagnosis_305',
        'secondaryDiagnosis_308',
        'coOccurringSubstance_311',
        'differentialConsiderations_314',
        'otherDsmDiagnoses_315',
        'treatmentPlan_316',
        'treatmentGoals_303',
        'depression_317',
        'depression_318',
        'depression_319',
        'depression_320',
        'depression_321',
        'depression_322',
        'depression_323',
        'anxiety_324',
        'anxiety_325',
        'anxiety_326',
        'anxiety_327',
        'anxiety_328',
        'anxiety_329',
        'anxiety_330',
        'otherDiagnoses_331',
        'otherDiagnoses_332',
        'otherDiagnoses_333',
        'otherDiagnoses_334',
        'otherDiagnoses_335',
        'otherDiagnoses_336',
        'sleepIssues_337',
        'sleepIssues_338',
        'sleepIssues_339',
        'sleepIssues_340',
        'sleepIssues_341',
        'sleepIssues_342',
        'sleepIssues_343',
        'sleepIssues_344',
        'substanceUse_346',
        'recommendationsSubstances_347',
        'generalMedicalCare_348',
        'labs_349',
        'generalHealthInformation_350',
        'chronicMedicationConditions_351',
        'sawPrimaryCare_352',
        'therapy_354',
        'roi_355',
        'medicationReconciliationDone_356',
        'intakeSessionComponents_357',
        'obtainedConsentSession_358',
        'obtainedConsentTelehealth_359',
        'orientedClientOutcomes_360',
        'orientedClientReminder_361',
        'orientedClientMessenger_362',
        'noAboveWhy_363',
        'followUpInformation_364',
        'returnClinic_365',
        'returnClinic_366',
        'recommendedIntervalMedication_367',
        'noFollowUp_368',
      ],
      verbalInformedConsent_1: {
        'ui:widget': 'radio',
      },
      isBoosterSession: { 'ui:widget': 'radio' },
      identifyingInformation_3: {
        'ui:widget': 'textarea',
      },
      chiefComplaint_5: {
        'ui:widget': 'textarea',
      },
      hpi_8: {
        'ui:widget': 'textarea',
      },
      depressedMood_12: {
        'ui:widget': 'checkbox',
      },
      moodInformationFrom_13: {
        'ui:widget': 'text',
      },
      sleep_14: {
        'ui:widget': 'checkbox',
      },
      sleepInformationFrom_15: {
        'ui:widget': 'text',
      },
      interest_16: {
        'ui:widget': 'checkbox',
      },
      interestInformationFrom_17: {
        'ui:widget': 'text',
      },
      psychomotorAgitationRetardation_18: {
        'ui:widget': 'checkbox',
      },
      psychomotorAgitationRetardation_18_2: {
        'ui:widget': 'checkbox',
      },
      psychomotorAgitationRetardation_19: {
        'ui:widget': 'text',
      },
      guiltWorthlessness_20: {
        'ui:widget': 'checkbox',
      },
      guiltWorthlessness_20_2: {
        'ui:widget': 'checkbox',
      },
      guiltWorthlessnessInformation_21: {
        'ui:widget': 'text',
      },
      energy_22: {
        'ui:widget': 'checkbox',
      },
      energyInformationFrom_23: {
        'ui:widget': 'text',
      },
      concentration_24: {
        'ui:widget': 'checkbox',
      },
      concentrationInformationFrom_25: {
        'ui:widget': 'text',
      },
      appetite_26: {
        'ui:widget': 'checkbox',
      },
      appetite_26_2: {
        'ui:widget': 'checkbox',
      },
      appetiteInformationFrom_27: {
        'ui:widget': 'text',
      },
      si_28: {
        'ui:widget': 'checkbox',
      },
      siInformationFrom_29: {
        'ui:widget': 'text',
      },
      hi_30: {
        'ui:widget': 'checkbox',
      },
      hiInformationFrom_31: {
        'ui:widget': 'text',
      },
      mood_33: {
        'ui:widget': 'checkbox',
      },
      moodInformationFrom_34: {
        'ui:widget': 'text',
      },
      irritability_35: {
        'ui:widget': 'checkbox',
      },
      irritabilityInformationFrom_36: {
        'ui:widget': 'text',
      },
      noSleep_37: {
        'ui:widget': 'checkbox',
      },
      noSleepInformation_38: {
        'ui:widget': 'text',
      },
      speech_39: {
        'ui:widget': 'checkbox',
      },
      speechInformationFrom_40: {
        'ui:widget': 'text',
      },
      grandiosity_41: {
        'ui:widget': 'checkbox',
      },
      grandiosityInformationFrom_42: {
        'ui:widget': 'text',
      },
      racingThoughts_43: {
        'ui:widget': 'checkbox',
      },
      racingThoughtsInformation_44: {
        'ui:widget': 'text',
      },
      goalOrientedRisky_45: {
        'ui:widget': 'checkbox',
      },
      goalOrientedRisky_46: {
        'ui:widget': 'text',
      },
      distractibilityConcentration_47: {
        'ui:widget': 'checkbox',
      },
      distractibilityConcentrationInformation_48: {
        'ui:widget': 'text',
      },
      ah_50: {
        'ui:widget': 'checkbox',
      },
      ahInformationFrom_51: {
        'ui:widget': 'text',
      },
      vh_52: {
        'ui:widget': 'checkbox',
      },
      vhInformationFrom_53: {
        'ui:widget': 'text',
      },
      paranoia_54: {
        'ui:widget': 'checkbox',
      },
      paranoiaInformationFrom_55: {
        'ui:widget': 'text',
      },
      delusionsInformationFrom_57: {
        'ui:widget': 'text',
      },
      irritability_59: {
        'ui:widget': 'checkbox',
      },
      irritabilityInformationFrom_60: {
        'ui:widget': 'text',
      },
      concentration_61: {
        'ui:widget': 'checkbox',
      },
      concentrationInformationFrom_62: {
        'ui:widget': 'text',
      },
      sleep_63: {
        'ui:widget': 'checkbox',
      },
      sleepInformationFrom_64: {
        'ui:widget': 'text',
      },
      physicalSymptomsAnxiety_66: {
        'ui:widget': 'text',
      },
      socialAnxiety_67: {
        'ui:widget': 'checkbox',
      },
      socialAnxietyInformation_68: {
        'ui:widget': 'text',
      },
      worry_69: {
        'ui:widget': 'checkbox',
      },
      worryInformationFrom_70: {
        'ui:widget': 'text',
      },
      agoraphobia_71: {
        'ui:widget': 'checkbox',
      },
      agoraphobiaInformationFrom_72: {
        'ui:widget': 'text',
      },
      panicAttacks_73: {
        'ui:widget': 'checkbox',
      },
      panicAttacksInformation_74: {
        'ui:widget': 'text',
      },
      traumaInformationFrom_76: {
        'ui:widget': 'textarea',
      },
      avoidanceInformationFrom_77: {
        'ui:widget': 'text',
      },
      obsessiveThoughts_83: {
        'ui:widget': 'checkbox',
      },
      obsessiveThoughtsInformation_84: {
        'ui:widget': 'text',
      },
      compulsiveBehaviors_85: {
        'ui:widget': 'checkbox',
      },
      compulsiveBehaviorsInformation_86: {
        'ui:widget': 'text',
      },
      impulseControl_87: {
        'ui:widget': 'checkbox',
      },
      impulseControlInformation_88: {
        'ui:widget': 'text',
      },
      restrictingEating_90: {
        'ui:widget': 'checkbox',
      },
      restrictingEatingInformation_91: {
        'ui:widget': 'text',
      },
      intenseFearGaining_92: {
        'ui:widget': 'checkbox',
      },
      intenseFearGaining_93: {
        'ui:widget': 'text',
      },
      bingeEating_94: {
        'ui:widget': 'checkbox',
      },
      bingeEatingInformation_95: {
        'ui:widget': 'text',
      },
      bulimia_96: {
        'ui:widget': 'checkbox',
      },
      bulimiaInformationFrom_97: {
        'ui:widget': 'text',
      },
      suicideAttempts_101: {
        'ui:widget': 'radio',
      },
      suicideAttempts_102: {
        'ui:widget': 'text',
      },
      suicideAttempts_103: {
        'ui:widget': 'textarea',
      },
      selfInjury_104: {
        'ui:widget': 'radio',
      },
      selfInjury_105: {
        'ui:widget': 'text',
      },
      selfInjury_106: {
        'ui:widget': 'textarea',
      },
      violenceHistory_107: {
        'ui:widget': 'radio',
      },
      violenceHistory_108: {
        'ui:widget': 'text',
      },
      violenceHistory_109: {
        'ui:widget': 'textarea',
      },
      psychiatricHospitalizations_110: {
        'ui:widget': 'radio',
      },
      psychiatricHospitalizations_111: {
        'ui:widget': 'text',
      },
      psychiatricHospitalizations_112: {
        'ui:widget': 'textarea',
      },
      previousDiagnoses_113: {
        'ui:widget': 'textarea',
      },
      pastPsychiatricMedications_115: {
        'ui:widget': 'textarea',
      },
      currentPreviousProviders_117: {
        'ui:widget': 'textarea',
      },
      detailsAboutChildhood_120: {
        'ui:widget': 'textarea',
      },
      relationshipStatus_122: {
        'ui:widget': 'select',
      },
      relationshipStatus_123: {
        'ui:widget': 'text',
      },
      relationshipStatus_124: {
        'ui:widget': 'text',
      },
      relationshipStatus_125: {
        'ui:widget': 'textarea',
      },
      children_126: {
        'ui:widget': 'textarea',
      },
      livingSituation_127: {
        'ui:widget': 'textarea',
      },
      familySocialSupports_128: {
        'ui:widget': 'textarea',
      },
      considerSpiritualReligious_130: {
        'ui:widget': 'radio',
      },
      considerSpiritualReligious_131: {
        'ui:widget': 'textarea',
      },
      education_132: {
        'ui:widget': 'textarea',
      },
      workingNow_134: {
        'ui:widget': 'radio',
      },
      workingNow_135: {
        'ui:widget': 'textarea',
      },
      currentlyInvolvedLegal_137: {
        'ui:widget': 'radio',
      },
      currentlyInvolvedLegal_138: {
        'ui:widget': 'textarea',
      },
      accessGun_139: {
        'ui:widget': 'radio',
      },
      additionalSocialHistoryDetails_139_1: {
        'ui:widget': 'textarea',
      },
      clientDrinks_141: {
        'ui:widget': 'select',
      },
      clientDrinks_142: {
        'ui:widget': 'select',
      },
      clientBinges_143: {
        'ui:widget': 'select',
      },
      clientUsesTobacco_144: {
        'ui:widget': 'select',
      },
      clientUsesPrescription_145: {
        'ui:widget': 'select',
      },
      clientUsesIllicit_146: {
        'ui:widget': 'select',
      },
      clientUsesCannabis_147: {
        'ui:widget': 'select',
      },
      additionalSubstanceUse_148: {
        'ui:widget': 'textarea',
      },
      sexualPhysicalAbuse_149: {
        'ui:widget': 'textarea',
      },
      additionalSocialHistory_150: {
        'ui:widget': 'textarea',
      },
      historyPsychiatricEmotional_152: {
        'ui:widget': 'radio',
      },
      historyPsychiatricEmotional_153: {
        'ui:widget': 'textarea',
      },
      additionalDetailsFrom_154: {
        'ui:widget': 'textarea',
      },
      chronicOngoingMedical_156: {
        'ui:widget': 'checkbox',
      },
      additionalMedicalIssues_157: {
        'ui:widget': 'checkbox',
      },
      snoringGaspingBreath_158: {
        'ui:widget': 'text',
      },
      painLevel_159: {
        'ui:widget': 'range',
      },
      painLevel_160: {
        'ui:widget': 'text',
      },
      additionalDetailsFrom_161: {
        'ui:widget': 'textarea',
      },
      pregnancy_162: {
        'ui:widget': 'textarea',
      },
      currentlyTakingPrescription_164: {
        'ui:widget': 'radio',
      },
      currentMedications_165: {
        'ui:widget': 'textarea',
      },
      allergies_166: {
        'ui:widget': 'text',
      },
      sideEffectConcerns_167: {
        'ui:widget': 'textarea',
      },
      generalAppearance_169: {
        'ui:widget': 'typeAhead',
      },
      cognitiveFunctioning_170: {
        'ui:widget': 'typeAhead',
      },
      mood_171: {
        'ui:widget': 'typeAhead',
      },
      affect_172: {
        'ui:widget': 'typeAhead',
      },
      judgment_173: {
        'ui:widget': 'typeAhead',
      },
      functionalStatus_174: {
        'ui:widget': 'typeAhead',
      },
      otherNotableCurrent_175: {
        'ui:widget': 'textarea',
      },
      safetyRiskAssessment_177: {
        'ui:widget': 'radio',
      },
      safetyRiskAssessment_178: {
        'ui:widget': 'textarea',
      },
      safetyRiskAssessment_179: {
        'ui:widget': 'text',
      },
      safetyIssuesIdentified_180: {
        'ui:widget': 'radio',
      },
      suicidalIdeation_181: {
        'ui:widget': 'select',
      },
      homicidalIdeation_182: {
        'ui:widget': 'select',
      },
      otherSafetyIssues_183: {
        'ui:widget': 'textarea',
      },
      safetyIssuesIdentified_187: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_188: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_189: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_190: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_191: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_192: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_193: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_194: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_195: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_196: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_197: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_198: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_199: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_200: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_201: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_202: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_203: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_204: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_205: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_206: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_207: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_208: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_209: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_210: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_211: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_212: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_213: {
        'ui:widget': 'textarea',
      },
      safetyIssuesIdentified_214: {
        'ui:widget': 'textarea',
      },
      safetyIssuesIdentified_216: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_217: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_218: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_219: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_220: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_221: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_222: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_223: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_224: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_225: {
        'ui:widget': 'textarea',
      },
      safetyIssuesIdentified_227: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_228: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_229: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_230: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_231: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_232: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_233: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_234: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_235: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_236: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_237: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_238: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_239: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_240: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_241: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_242: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_243: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_244: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_245: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_246: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_247: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_248: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_249: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_250: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_251: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_252: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_253: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_254: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_255: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_256: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_257: {
        'ui:widget': 'textarea',
      },
      safetyIssuesIdentified_258: {
        'ui:widget': 'textarea',
      },
      safetyIssuesIdentified_261: {
        'ui:widget': 'typeAhead',
      },
      safetyIssuesIdentified_262: {
        'ui:widget': 'textarea',
      },
      safetyIssuesIdentified_263: {
        'ui:widget': 'typeAhead',
      },
      safetyIssuesIdentified_264: {
        'ui:widget': 'textarea',
      },
      safetyIssuesIdentified_265: {
        'ui:widget': 'text',
      },
      safetyIssuesIdentified_267: {
        'ui:widget': 'textarea',
      },
      safetyIssuesIdentified_268: {
        'ui:widget': 'textarea',
      },
      safetyIssuesIdentified_269: {
        'ui:widget': 'textarea',
      },
      safetyIssuesIdentified_272: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_276: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_277: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_278: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_279: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_280: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_281: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_282: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_283: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_284: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_285: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_286: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_287: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_288: {
        'ui:widget': 'textarea',
      },
      labsImaging_290: {
        'ui:widget': 'textarea',
      },
      assessments_291: {
        'ui:widget': 'textarea',
      },
      bioGeneticDevelopmental_298: {
        'ui:widget': 'textarea',
      },
      psychoCognitiveStyle_299: {
        'ui:widget': 'textarea',
      },
      socialRelationshipsFamily_300: {
        'ui:widget': 'textarea',
      },
      summary_301: {
        'ui:widget': 'textarea',
      },
      treatmentGoals_303: {
        'ui:widget': 'textarea',
      },
      primaryDiagnosis_305: {
        'ui:widget': 'typeAhead',
      },
      secondaryDiagnosis_308: {
        'ui:widget': 'typeAhead',
      },
      coOccurringSubstance_311: {
        'ui:widget': 'select',
      },
      differentialConsiderations_314: {
        'ui:widget': 'textarea',
      },
      otherDsmDiagnoses_315: {
        'ui:widget': 'textarea',
      },
      depression_317: {
        'ui:widget': 'checkbox',
      },
      depression_318: {
        'ui:widget': 'textarea',
      },
      depression_319: {
        'ui:widget': 'textarea',
      },
      depression_320: {
        'ui:widget': 'radio',
      },
      depression_321: {
        'ui:widget': 'radio',
      },
      depression_322: {
        'ui:widget': 'textarea',
      },
      depression_323: {
        'ui:widget': 'textarea',
      },
      anxiety_324: {
        'ui:widget': 'checkbox',
      },
      anxiety_325: {
        'ui:widget': 'textarea',
      },
      anxiety_326: {
        'ui:widget': 'textarea',
      },
      anxiety_327: {
        'ui:widget': 'radio',
      },
      anxiety_328: {
        'ui:widget': 'radio',
      },
      anxiety_329: {
        'ui:widget': 'textarea',
      },
      anxiety_330: {
        'ui:widget': 'textarea',
      },
      otherDiagnoses_331: {
        'ui:widget': 'checkbox',
      },
      otherDiagnoses_332: {
        'ui:widget': 'textarea',
      },
      otherDiagnoses_333: {
        'ui:widget': 'textarea',
      },
      otherDiagnoses_334: {
        'ui:widget': 'radio',
      },
      otherDiagnoses_335: {
        'ui:widget': 'radio',
      },
      otherDiagnoses_336: {
        'ui:widget': 'textarea',
      },
      sleepIssues_337: {
        'ui:widget': 'checkbox',
      },
      sleepIssues_338: {
        'ui:widget': 'textarea',
      },
      sleepIssues_339: {
        'ui:widget': 'textarea',
      },
      sleepIssues_340: {
        'ui:widget': 'radio',
      },
      sleepIssues_341: {
        'ui:widget': 'radio',
      },
      sleepIssues_342: {
        'ui:widget': 'textarea',
      },
      sleepIssues_344: {
        'ui:widget': 'textarea',
      },
      substanceUse_346: {
        'ui:widget': 'textarea',
      },
      recommendationsSubstances_347: {
        'ui:widget': 'textarea',
      },
      labs_349: {
        'ui:widget': 'textarea',
      },
      generalHealthInformation_350: {
        'ui:widget': 'textarea',
      },
      chronicMedicationConditions_351: {
        'ui:widget': 'textarea',
      },
      sawPrimaryCare_352: {
        'ui:widget': 'textarea',
      },
      therapy_354: {
        'ui:widget': 'textarea',
      },
      roi_355: {
        'ui:widget': 'textarea',
      },
      medicationReconciliationDone_356: {
        'ui:widget': 'radio',
      },
      obtainedConsentSession_358: {
        'ui:widget': 'checkbox',
      },
      obtainedConsentTelehealth_359: {
        'ui:widget': 'checkbox',
      },
      orientedClientOutcomes_360: {
        'ui:widget': 'checkbox',
      },
      orientedClientReminder_361: {
        'ui:widget': 'checkbox',
      },
      orientedClientMessenger_362: {
        'ui:widget': 'checkbox',
      },
      noAboveWhy_363: {
        'ui:widget': 'textarea',
      },
      returnClinic_365: {
        'ui:widget': 'checkbox',
      },
      returnClinic_366: {
        'ui:widget': 'text',
      },
      noFollowUp_368: {
        'ui:widget': 'checkbox',
      },
    },
    initialValues: {
      identifyingInformation_3:
        'The patient is a XX year old, EMPLOYMENT status, RELATIONSHIP status GENDER presenting for a medication consultation.',
      additionalSocialHistoryDetails_139_1: 'Reviewed details from intake. Other details if any include:',
      additionalSocialHistory_150: 'Reviewed details from intake. Other details if any include:',
      additionalDetailsFrom_154: 'Reviewed details from intake. Other details if any include:',
      painLevel_159: 0,
      additionalDetailsFrom_161: 'Reviewed details from intake. Other details if any include:',
      treatmentGoals_303: '1.\n2.\n3.\n',
      depression_319:
        'Current medication dose and changes:\nDate of medication initiation:\nList of historical dose adjustments:',
      depression_318: 'The patient scored an XX on PHQ9 most recently. They report the following clinical changes:',
      depression_322:
        'Non-pharmacological treatment strategies may include group or individual programming may include CBT, DBT, ACT, community reinforcement approach, motivational enhancement therapy, behavioral activation (daily structure), regular sleep and meals, and exercise as appropriate',
      anxiety_325: 'The patient scored a XX on GAD7 most recently. They report the following clinical changes:',
      anxiety_326:
        'Current medication dose and changes:\nDate of medication initiation:\nList of historical dose adjustments:',
      depression_329:
        'Non-pharmacological treatment strategies may include group or individual programming may include CBT, DBT, ACT, community reinforcement approach, motivational enhancement therapy, behavioral activation (daily structure), regular sleep and meals, and exercise as appropriate',
      otherDiagnoses_332: 'The patient reports',
      otherDiagnoses_333:
        'Current medication dose and changes:\nDate of medication initiation:\nList of historical dose adjustments:',
      substanceUse_346:
        '-Level of Alcohol consumption:\n-Does the patient use tobacco/nicotine products?\n-Does the patient use any other substances?',
      labs_349: 'Labs ordered MM/YY\nMost recent labs of interest:\n    TSH\n    Hemoglobin\n    Hematocrit\n    B12',
      generalHealthInformation_350: 'See H&P, no acute issues identified today',
      therapy_354: 'Patient is in therapy with',
      roi_355: 'Verbal permission obtained and Release of Information requests sent for:',
    },
  },
  medsSessionNote: {
    uiSchema: {
      'ui:order': [
        'verbalInformedConsent_1',
        'isBoosterSession',
        'identifyingInformation_2',
        'identifyingInformation_3',
        'visitDate_4',
        'chiefComplaint_5',
        'ccFromIntake_6',
        'ccFromVisit_7',
        'intervalHistory_8',
        'intervalHistory_9',
        'medicationSideEffects_10',
        'psychiatricReviewSystems_11',
        'whichCommonSymptoms_12',
        'otherRosInformation_13',
        'updatesPsychiatricSocial_14',
        'currentMedicationsAllergies_15',
        'currentMedications_16',
        'allergies_17',
        'currentMentalStatus_18',
        'generalAppearance_19',
        'cognitiveFunctioning_20',
        'mood_21',
        'affect_22',
        'judgment_23',
        'functionalStatus_24',
        'otherNotableCurrent_25',
        'riskAssessmentManagement_26',
        'safetyRiskAssessment_27',
        'safetyRiskAssessment_28',
        'safetyRiskAssessment_29',
        'safetyIssuesIdentified_30',
        'suicidalIdeation_31',
        'homicidalIdeation_32',
        'otherSafetyIssues_33',
        'safetyIssuesIdentified_34',
        'detailedRiskAssessment_35',
        'safetyIssuesIdentified_36',
        'safetyIssuesIdentified_37',
        'safetyIssuesIdentified_38',
        'safetyIssuesIdentified_39',
        'safetyIssuesIdentified_40',
        'safetyIssuesIdentified_41',
        'safetyIssuesIdentified_42',
        'safetyIssuesIdentified_43',
        'safetyIssuesIdentified_44',
        'safetyIssuesIdentified_45',
        'safetyIssuesIdentified_46',
        'safetyIssuesIdentified_47',
        'safetyIssuesIdentified_48',
        'safetyIssuesIdentified_49',
        'safetyIssuesIdentified_50',
        'safetyIssuesIdentified_51',
        'safetyIssuesIdentified_52',
        'safetyIssuesIdentified_53',
        'safetyIssuesIdentified_54',
        'safetyIssuesIdentified_55',
        'safetyIssuesIdentified_56',
        'safetyIssuesIdentified_57',
        'safetyIssuesIdentified_58',
        'safetyIssuesIdentified_59',
        'safetyIssuesIdentified_60',
        'safetyIssuesIdentified_61',
        'safetyIssuesIdentified_62',
        'safetyIssuesIdentified_63',
        'safetyIssuesIdentified_64',
        'safetyIssuesIdentified_65',
        'safetyIssuesIdentified_66',
        'safetyIssuesIdentified_67',
        'safetyIssuesIdentified_68',
        'safetyIssuesIdentified_69',
        'safetyIssuesIdentified_70',
        'safetyIssuesIdentified_71',
        'safetyIssuesIdentified_72',
        'safetyIssuesIdentified_73',
        'safetyIssuesIdentified_74',
        'safetyIssuesIdentified_75',
        'safetyIssuesIdentified_76',
        'safetyIssuesIdentified_77',
        'safetyIssuesIdentified_78',
        'safetyIssuesIdentified_79',
        'safetyIssuesIdentified_80',
        'safetyIssuesIdentified_81',
        'safetyIssuesIdentified_82',
        'safetyIssuesIdentified_83',
        'safetyIssuesIdentified_84',
        'safetyIssuesIdentified_85',
        'safetyIssuesIdentified_86',
        'safetyIssuesIdentified_87',
        'safetyIssuesIdentified_88',
        'safetyIssuesIdentified_89',
        'safetyIssuesIdentified_90',
        'safetyIssuesIdentified_91',
        'safetyIssuesIdentified_92',
        'safetyIssuesIdentified_93',
        'safetyIssuesIdentified_94',
        'safetyIssuesIdentified_95',
        'safetyIssuesIdentified_96',
        'safetyIssuesIdentified_97',
        'safetyIssuesIdentified_98',
        'safetyIssuesIdentified_99',
        'safetyIssuesIdentified_100',
        'safetyIssuesIdentified_101',
        'safetyIssuesIdentified_102',
        'safetyIssuesIdentified_103',
        'safetyIssuesIdentified_104',
        'safetyIssuesIdentified_105',
        'safetyIssuesIdentified_106',
        'safetyIssuesIdentified_107',
        'safetyIssuesIdentified_108',
        'safetyIssuesIdentified_109',
        'safetyIssuesIdentified_110',
        'safetyIssuesIdentified_111',
        'safetyIssuesIdentified_112',
        'safetyIssuesIdentified_113',
        'safetyIssuesIdentified_114',
        'safetyIssuesIdentified_115',
        'safetyIssuesIdentified_116',
        'safetyIssuesIdentified_117',
        'safetyIssuesIdentified_118',
        'safetyIssuesIdentified_119',
        'safetyIssuesIdentified_120',
        'safetyIssuesIdentified_121',
        'safetyIssuesIdentified_122',
        'safetyIssuesIdentified_123',
        'safetyIssuesIdentified_124',
        'safetyIssuesIdentified_125',
        'safetyIssuesIdentified_126',
        'safetyIssuesIdentified_127',
        'safetyIssuesIdentified_128',
        'safetyIssuesIdentified_129',
        'safetyIssuesIdentified_130',
        'safetyIssuesIdentified_131',
        'safetyIssuesIdentified_132',
        'safetyIssuesIdentified_133',
        'safetyIssuesIdentified_134',
        'safetyIssuesIdentified_135',
        'safetyIssuesIdentified_136',
        'safetyIssuesIdentified_137',
        'safetyIssuesIdentified_138',
        'labsImaging_139',
        'labsImaging_140',
        'assessments_141',
        'phq9Gad7Pcl_142',
        'caseFormulation_143',
        'bio_145',
        'psycho_146',
        'social_147',
        'summary_148',
        'dsmDiagnosis_152',
        'primaryDiagnosis_153',
        'secondaryDiagnosis_154',
        'coOccurringSubstance_155',
        'differentialConsiderations_156',
        'otherDsmDiagnoses_157',
        'treatmentGoals_150',
        'treatmentGoals_151',
        'depression_159',
        'depression_160',
        'depression_161',
        'depression_162',
        'depression_163',
        'depression_164',
        'depression_165',
        'anxiety_166',
        'anxiety_167',
        'anxiety_168',
        'anxiety_169',
        'anxiety_170',
        'anxiety_171',
        'anxiety_172',
        'otherDiagnoses_173',
        'otherDiagnoses_174',
        'otherDiagnoses_175',
        'otherDiagnoses_176',
        'otherDiagnoses_177',
        'otherDiagnoses_178',
        'sleepIssues_179',
        'sleepIssues_180',
        'sleepIssues_181',
        'sleepIssues_182',
        'sleepIssues_183',
        'sleepIssues_184',
        'sleepIssues_185',
        'sleepIssues_186',
        'substanceUse_187',
        'currentLevelsUse_188',
        'recommendationsSubstances_189',
        'generalMedicalCare_190',
        'labs_191',
        'acuteMedicalConditions_192',
        'chronicMedicationConditions_193',
        'sawPrimaryCare_194',
        'therapy_196',
        'roi_197',
        'followUpInformation_198',
        'returnClinic_199',
        'returnClinic_200',
        'recommendedIntervalMedication_201',
        'noFollowUp_202',
      ],
      verbalInformedConsent_1: {
        'ui:widget': 'radio',
      },
      isBoosterSession: { 'ui:widget': 'radio' },
      identifyingInformation_3: {
        'ui:widget': 'textarea',
      },
      visitDate_4: {
        'ui:widget': 'text',
      },
      ccFromIntake_6: {
        'ui:widget': 'textarea',
      },
      ccFromVisit_7: {
        'ui:widget': 'textarea',
      },
      intervalHistory_9: {
        'ui:widget': 'textarea',
      },
      medicationSideEffects_10: {
        'ui:widget': 'textarea',
      },
      whichCommonSymptoms_12: {
        'ui:widget': 'checkbox',
      },
      otherRosInformation_13: {
        'ui:widget': 'textarea',
      },
      updatesPsychiatricSocial_14: {
        'ui:widget': 'textarea',
      },
      currentMedications_16: {
        'ui:widget': 'textarea',
      },
      allergies_17: {
        'ui:widget': 'text',
      },
      generalAppearance_19: {
        'ui:widget': 'select',
      },
      cognitiveFunctioning_20: {
        'ui:widget': 'select',
      },
      mood_21: {
        'ui:widget': 'select',
      },
      affect_22: {
        'ui:widget': 'select',
      },
      judgment_23: {
        'ui:widget': 'select',
      },
      functionalStatus_24: {
        'ui:widget': 'select',
      },
      otherNotableCurrent_25: {
        'ui:widget': 'textarea',
      },
      safetyRiskAssessment_27: {
        'ui:widget': 'radio',
      },
      safetyRiskAssessment_28: {
        'ui:widget': 'textarea',
      },
      safetyRiskAssessment_29: {
        'ui:widget': 'text',
      },
      safetyIssuesIdentified_30: {
        'ui:widget': 'radio',
      },
      suicidalIdeation_31: {
        'ui:widget': 'select',
      },
      homicidalIdeation_32: {
        'ui:widget': 'select',
      },
      otherSafetyIssues_33: {
        'ui:widget': 'textarea',
      },
      safetyIssuesIdentified_37: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_38: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_39: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_40: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_41: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_42: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_43: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_44: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_45: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_46: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_47: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_48: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_49: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_50: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_51: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_52: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_53: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_54: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_55: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_56: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_57: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_58: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_59: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_60: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_61: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_62: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_63: {
        'ui:widget': 'textarea',
      },
      safetyIssuesIdentified_64: {
        'ui:widget': 'textarea',
      },
      safetyIssuesIdentified_66: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_67: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_68: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_69: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_70: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_71: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_72: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_73: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_74: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_75: {
        'ui:widget': 'textarea',
      },
      safetyIssuesIdentified_77: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_78: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_79: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_80: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_81: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_82: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_83: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_84: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_85: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_86: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_87: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_88: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_89: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_90: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_91: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_92: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_93: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_94: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_95: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_96: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_97: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_98: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_99: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_100: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_101: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_102: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_103: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_104: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_105: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_106: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_107: {
        'ui:widget': 'textarea',
      },
      safetyIssuesIdentified_108: {
        'ui:widget': 'textarea',
      },
      safetyIssuesIdentified_111: {
        'ui:widget': 'typeAhead',
      },
      safetyIssuesIdentified_112: {
        'ui:widget': 'textarea',
      },
      safetyIssuesIdentified_113: {
        'ui:widget': 'typeAhead',
      },
      safetyIssuesIdentified_114: {
        'ui:widget': 'textarea',
      },
      safetyIssuesIdentified_115: {
        'ui:widget': 'text',
      },
      safetyIssuesIdentified_117: {
        'ui:widget': 'textarea',
      },
      safetyIssuesIdentified_118: {
        'ui:widget': 'textarea',
      },
      safetyIssuesIdentified_119: {
        'ui:widget': 'textarea',
      },
      safetyIssuesIdentified_122: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_126: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_127: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_128: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_129: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_130: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_131: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_132: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_133: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_134: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_135: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_136: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_137: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_138: {
        'ui:widget': 'textarea',
      },
      labsImaging_140: {
        'ui:widget': 'textarea',
      },
      phq9Gad7Pcl_142: {
        'ui:widget': 'textarea',
      },
      bio_145: {
        'ui:widget': 'textarea',
      },
      psycho_146: {
        'ui:widget': 'textarea',
      },
      social_147: {
        'ui:widget': 'textarea',
      },
      summary_148: {
        'ui:widget': 'textarea',
      },
      treatmentGoals_151: {
        'ui:widget': 'textarea',
      },
      primaryDiagnosis_153: {
        'ui:widget': 'typeAhead',
      },
      secondaryDiagnosis_154: {
        'ui:widget': 'typeAhead',
      },
      coOccurringSubstance_155: {
        'ui:widget': 'select',
      },
      differentialConsiderations_156: {
        'ui:widget': 'textarea',
      },
      otherDsmDiagnoses_157: {
        'ui:widget': 'textarea',
      },
      depression_159: {
        'ui:widget': 'checkbox',
      },
      depression_160: {
        'ui:widget': 'textarea',
      },
      depression_161: {
        'ui:widget': 'textarea',
      },
      depression_162: {
        'ui:widget': 'radio',
      },
      depression_163: {
        'ui:widget': 'radio',
      },
      depression_164: {
        'ui:widget': 'textarea',
      },
      depression_165: {
        'ui:widget': 'textarea',
      },
      anxiety_166: {
        'ui:widget': 'checkbox',
      },
      anxiety_167: {
        'ui:widget': 'textarea',
      },
      anxiety_168: {
        'ui:widget': 'textarea',
      },
      anxiety_169: {
        'ui:widget': 'radio',
      },
      anxiety_170: {
        'ui:widget': 'radio',
      },
      anxiety_171: {
        'ui:widget': 'textarea',
      },
      anxiety_172: {
        'ui:widget': 'textarea',
      },
      otherDiagnoses_173: {
        'ui:widget': 'checkbox',
      },
      otherDiagnoses_174: {
        'ui:widget': 'textarea',
      },
      otherDiagnoses_175: {
        'ui:widget': 'textarea',
      },
      otherDiagnoses_176: {
        'ui:widget': 'radio',
      },
      otherDiagnoses_177: {
        'ui:widget': 'radio',
      },
      otherDiagnoses_178: {
        'ui:widget': 'textarea',
      },
      sleepIssues_179: {
        'ui:widget': 'checkbox',
      },
      sleepIssues_180: {
        'ui:widget': 'textarea',
      },
      sleepIssues_181: {
        'ui:widget': 'textarea',
      },
      sleepIssues_182: {
        'ui:widget': 'radio',
      },
      sleepIssues_183: {
        'ui:widget': 'radio',
      },
      sleepIssues_184: {
        'ui:widget': 'textarea',
      },
      sleepIssues_186: {
        'ui:widget': 'textarea',
      },
      currentLevelsUse_188: {
        'ui:widget': 'textarea',
      },
      recommendationsSubstances_189: {
        'ui:widget': 'textarea',
      },
      labs_191: {
        'ui:widget': 'textarea',
      },
      acuteMedicalConditions_192: {
        'ui:widget': 'textarea',
      },
      chronicMedicationConditions_193: {
        'ui:widget': 'textarea',
      },
      sawPrimaryCare_194: {
        'ui:widget': 'textarea',
      },
      therapy_196: {
        'ui:widget': 'textarea',
      },
      roi_197: {
        'ui:widget': 'textarea',
      },
      returnClinic_199: {
        'ui:widget': 'checkbox',
      },
      returnClinic_200: {
        'ui:widget': 'text',
      },
      noFollowUp_202: {
        'ui:widget': 'checkbox',
      },
    },
    initialValues: {
      depression_160: 'The patient scored an XX on PHQ9 most recently. They report the following clinical changes:',
      depression_161:
        'Current medication dose and changes:\nDate of medication initiation:\nList of historical dose adjustments:',
      anxiety_167: 'The patient scored a XX on GAD7 most recently. They report the following clinical changes:',
      anxiety_171:
        'Non-pharmacological treatment strategies may include group or individual programming may include CBT, DBT, ACT, community reinforcement approach, motivational enhancement therapy, behavioral activation (daily structure), regular sleep and meals, and exercise as appropriate',
      otherDiagnoses_175:
        'Current medication dose and changes:\nDate of medication initiation:\nList of historical dose adjustments:',
      sleepIssues_180: 'The patient reports:',
      currentLevelsUse_188:
        '-Level of Alcohol consumption:\n-Does the patient use tobacco/nicotine products?\n-Does the patient use any other substances?',
    },
  },
}

export default UIMetadata
