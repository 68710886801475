import React, { FunctionComponent, useState } from 'react'
import { useIntl } from 'react-intl'

import styled from 'styled-components/native'

import { BodyText, Size } from '../../../src/atoms/bodyText/BodyText'
import { FeedbackStarIcon } from '../../../src/atoms/icons/FeedbackStarIcon'
import { PressableOpacity } from '../../../src/atoms/pressableOpacity/PressableOpacity'
import { tID } from '../../utils'

export type FeedbackStarsProps = {
  input?: any
  starLabels?: string[]
  testID?: string
  periwinkle?: boolean
}

const FeedbackStarsContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding: 7px 26px 0px 26px;
`

const FeedbackStarContainer = styled.View`
  flex-direction: column;
  align-items: center;
`

const StarLabelContainer = styled.View`
  padding-top: 4px;
`

/**
 * Feedback Stars that is used for reviews
 */
export const FeedbackStars: FunctionComponent<FeedbackStarsProps> = ({
  input,
  starLabels = [],
  testID = 'VideoSessionFeedback',
  periwinkle = false,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [hoverIndex, setHoverIndex] = useState(-1)
  const [isHovered, setIsHovered] = useState(false)
  const { formatMessage } = useIntl()

  const selectStar = (index: number) => {
    setSelectedIndex(index)
    if (input) {
      input.onChange(index)
    }
  }

  const getStars = () =>
    [1, 2, 3, 4, 5].map((star, index) => {
      const selected = star <= (input?.value ?? selectedIndex)
      return (
        <FeedbackStarContainer key={index}>
          <PressableOpacity
            key={`feedbackStars-${star}`}
            testID={tID(`${testID}-FeedbackStars-${star}${selected ? ' selected' : ''}`)}
            accessibilityLabel={formatMessage(
              {
                defaultMessage: '{star, plural, one {1 star} other {{star} stars}}',
                description: 'Shows how many stars',
              },
              {
                star,
              },
            )}
            onPress={() => selectStar(star)}
            onHoverIn={() => {
              setHoverIndex(star)
              setIsHovered(true)
            }}
            onHoverOut={() => {
              setIsHovered(false)
              setHoverIndex(-1)
            }}
          >
            <FeedbackStarIcon
              isHighlighted={selected}
              isHovered={isHovered && hoverIndex >= star && !selected}
              periwinkle={periwinkle}
            />
          </PressableOpacity>
          {!!starLabels[index] && (
            <StarLabelContainer>
              <BodyText text={starLabels[index]} size={Size.CAPTION} />
            </StarLabelContainer>
          )}
        </FeedbackStarContainer>
      )
    })

  return <FeedbackStarsContainer testID={tID(`${testID}-FeedbackStars`)}>{getStars()}</FeedbackStarsContainer>
}
