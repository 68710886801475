import React from 'react'
import { Field } from 'redux-form/immutable'
import styles from './clientEmailForm.module.scss'
import { InputField, Checkbox, DefaultButton } from '@lyrahealth-inc/ui-core'
import { trim } from '../../../utils/utils'

type Props = {
  handleSubmit?: (...args: any[]) => any
  submitting?: boolean
  submitFunction?: (...args: any[]) => any
  thankyou?: any
  continueFunction?: (...args: any[]) => any
  firstName?: string
  lastName?: string
}

class ClientEmailForm extends React.Component<Props> {
  render() {
    const { handleSubmit, submitting, submitFunction, continueFunction, thankyou, firstName, lastName } = this.props

    return (
      // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      <form onSubmit={handleSubmit(submitFunction)}>
        <div className='row'>
          <div className={styles['thank-you-container']}>{thankyou}</div>
        </div>
        <div className='row'>
          <div className={styles['context-container']}>
            <p className={styles['provide-title']}>Please Provide {firstName}'s Email</p>
            <p>
              Before you go, in order to collect outcomes data and provide information about Lyra&apos;s services, we
              need their email address. Please provide their email address if known.
            </p>
          </div>
        </div>
        <div className='row'>
          <Field
            name='email'
            type='text'
            component={InputField}
            label={firstName + ' ' + lastName + "'s Email"}
            normalize={trim}
          />
        </div>

        <div className='row'>
          <Field name='client_consent' component={Checkbox} type='checkbox'>
            I have obtained informed consent from my client to provide their email address to Lyra to provide{' '}
            <a
              href='https://provider-support.lyrahealth.com/hc/en-us/articles/115012608808'
              target='_blank'
              rel='noreferrer'
            >
              outcomes data
            </a>{' '}
            and information about Lyra&apos;s services.
          </Field>
        </div>

        <div className='row'>
          <DefaultButton
            id='test-clientemail-submit'
            customClass={styles['submit-button']}
            isLoading={submitting}
            type='submit'
            LoaderWithin
            // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
            onKeyDown={handleSubmit(submitFunction)}
          >
            Submit
          </DefaultButton>
          <DefaultButton
            id='test-clientemail-continue'
            isOutlined
            // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
            onClick={() => continueFunction(false)}
            // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
            onKeyDown={() => continueFunction(false)}
          >
            I don't know my client's email
          </DefaultButton>
        </div>
      </form>
    )
  }
}

export default ClientEmailForm
