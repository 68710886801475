import React, { FunctionComponent, KeyboardEvent, useCallback, useMemo, useState } from 'react'
import { FieldRenderProps } from 'react-final-form'
import { View, ViewStyle } from 'react-native'

import { noop } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import { KEYS } from '@lyrahealth-inc/shared-app-logic'

import { BaseInput } from './../formElements/BaseInput'
import { FieldBadge } from './FieldBadge'
import { getCheckboxButtonStyles } from './styles/checkboxStyles'
import { AccessibilityRolesNative, IS_WEB } from '../../constants'
import { useAccessibilityFocus } from '../../hooks/useAccessibilityFocus'
import { useSetAriaDescribedBy } from '../../hooks/useSetAriaDescribedBy'
import { getFocusBoxShadow } from '../../styles/commonStyles'
import { BodyTextSize, getFontStyles } from '../../styles/typeStyles'
import { tID } from '../../utils'
import { BodyText } from '../bodyText/BodyText'
import { CheckMarkIcon } from '../icons/CheckMarkIcon'
import { PressableOpacity } from '../pressableOpacity/PressableOpacity'
import { StyledMarkdown } from '../styledMarkdown/StyledMarkdown'

export enum CheckboxButtonType {
  TEXT_OUTLINE = 'textOutline', // current style of checkbox button on prod
  CHECKBOX_TEXT_OUTLINE = 'outlinedTextCheckbox', // new design - checkbox button with title text and outlined container
  CHECKBOX_DESCRIPTION_NO_OUTLINE = 'descriptionNoOutline', // new design - checkbox button with checkbox and description, no outlined container
}

export const checkBoxButtonTypeHasOutline = {
  [CheckboxButtonType.TEXT_OUTLINE]: false,
  [CheckboxButtonType.CHECKBOX_TEXT_OUTLINE]: true,
  [CheckboxButtonType.CHECKBOX_DESCRIPTION_NO_OUTLINE]: false,
}

const CheckboxButtonContainer = styled(PressableOpacity)<{
  buttonType: CheckboxButtonType
  checked?: boolean
  selected?: boolean
  hovered?: boolean
  style?: ViewStyle
  theme?: string
  accessibilityLabel?: string
}>`
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  ${IS_WEB && 'outline-width: 0;'}

  ${({ buttonType, selected, hovered, theme }) => {
    if (buttonType === CheckboxButtonType.CHECKBOX_TEXT_OUTLINE) {
      return `
      border-radius: 8px;
      padding: 14px 18px;
      border: 1px solid ${
        selected || hovered ? theme.colors.checkboxOutlineSelected : theme.colors.checkboxOutlineInactive
      };
      /* primary_teal1 is not available in colorTokens */
      background-color: ${selected ? theme.colors.backgroundHighlightTeal : theme.colors.checkboxBackgroundPrimary};
      `
    }
    if (buttonType === CheckboxButtonType.CHECKBOX_DESCRIPTION_NO_OUTLINE) {
      return `
      border-radius: 8px;
      background-color: ${theme.colors.checkboxBackgroundPrimary};
      `
    }
    return ``
  }}
`

const CheckboxTextContainer = styled.View({
  flexShrink: '1',
  flexGrow: '1',
})

const CheckboxOutlinedTextContainer = styled(CheckboxTextContainer)({
  flexDirection: 'row',
})

const BadgeContainer = styled.View({
  alignSelf: 'center',
})

const TextContainer = styled.View({
  flexShrink: 1,
})

const IconContainer = styled.View(({ theme }) => ({
  marginRight: theme.spacing['8px'],
}))

export const Checkbox: FunctionComponent<CheckBoxProps> = ({
  label,
  title,
  titleColor,
  description,
  checked,
  onPress,
  error,
  name,
  readOnly,
  children,
  labelSize = BodyTextSize.DEFAULT,
  badge,
  buttonType = CheckboxButtonType.TEXT_OUTLINE,
  onFocus = noop,
  onBlur = noop,
  baseInputStyle,
  markdownStyle,
  active,
  accessibilityLabelledBy,
  accessibilityLabel,
  hideErrorMessage,
  styleDescriptionAsTitle,
  textContainerStyle,
  customErrorMessageId,
  icon,
  checkboxStyle,
}) => {
  const [focusRef] = useAccessibilityFocus({ active, delay: 200 })
  const [focusVisible, setFocusVisible] = useState(false)
  const [hoverVisible, setHoverVisible] = useState(false)
  const focusVisibleCallback = useCallback((focus) => setFocusVisible(focus), [])
  const hoverVisibleCallback = useCallback((hover) => setHoverVisible(hover), [])
  const handleKeyPress = (event: KeyboardEvent<Element>) => {
    if (event?.key === KEYS.SPACE) {
      event.stopPropagation()
      event.preventDefault()
      onPress()
    }
  }

  const errorMessageId = customErrorMessageId ?? name ? `${name}-error-message` : ''
  useSetAriaDescribedBy({ ariaDescribedBy: errorMessageId, ref: focusRef })

  const { colors } = useTheme()

  const getCheckboxStylesFromState = ({
    buttonType,
    focused,
    hovered,
    disabled,
    selected,
    error,
  }: GetCheckboxButtonStylesArgs) => {
    let state = 'active'
    if (hovered) state = 'hovered'
    if (disabled) state = 'inactive'
    if (selected) state = 'selected'
    if (error) state = 'error'
    if (selected && disabled) state = 'selectedInactive'

    const checkboxButtonStyles = getCheckboxButtonStyles(colors)[buttonType]

    const styles = {
      ...checkboxButtonStyles.base,
      ...(focused && checkboxButtonStyles.focused),
      ...checkboxButtonStyles.states[state],
      ...checkboxStyle,
    }
    return styles
  }

  /** Memoize this label, otherwise it rerenders, causing it to take 2 clicks to check the field */
  const labelMarkdown = useMemo(() => {
    const mergedStyles = {
      // merge the overrides with defaults
      p2: {
        marginTop: labelSize === BodyTextSize.SMALL ? 2 : 0,
        marginBottom: 0,
        ...getFontStyles(colors).body[labelSize],
        ...markdownStyle?.p2,
      },
      link: markdownStyle?.link,
    }

    return <StyledMarkdown content={title as string} customStyles={mergedStyles} />
  }, [colors, labelSize, markdownStyle?.link, markdownStyle?.p2, title])

  return (
    <BaseInput
      label={label}
      error={error}
      name={name}
      style={baseInputStyle}
      errorMessageId={errorMessageId}
      hideErrorMessage={hideErrorMessage}
    >
      <CheckboxButtonContainer
        pressableRef={focusRef}
        buttonType={buttonType}
        selected={checked}
        onPress={onPress}
        style={[
          buttonType === CheckboxButtonType.CHECKBOX_TEXT_OUTLINE && focusVisible
            ? getFocusBoxShadow({
                innerBoxShadowSize: '6.5px',
                outerBoxShadowSize: '7.5px',
                outerBoxShadowColor: colors.primaryButtonBackgroundFocus,
                colors,
              })
            : {},
          baseInputStyle?.backgroundColor ? { backgroundColor: baseInputStyle.backgroundColor } : {},
        ]}
        disabled={readOnly}
        hitSlop={20}
        testID={tID(`${name}-Checkbox`)}
        accessibilityRole={AccessibilityRolesNative.CHECKBOX}
        accessibilityChecked={checked}
        accessibilityLabel={accessibilityLabel ?? (typeof title === 'string' ? title : undefined)}
        accessibilityState={{ checked }}
        accessibilityLabelledBy={accessibilityLabelledBy}
        onFocus={onFocus}
        onBlur={onBlur}
        pressedOpacity={1}
        showFocusIndicator={false}
        getFocusVisible={focusVisibleCallback}
        getHoverVisible={hoverVisibleCallback}
        hovered={hoverVisible}
        onKeyDown={handleKeyPress}
      >
        <View
          style={getCheckboxStylesFromState({
            buttonType: buttonType,
            selected: checked,
            hovered: hoverVisible,
            focused: focusVisible,
            disabled: readOnly,
            error: error,
          })}
          testID={tID(`${name}-${checked}`)}
        >
          {checked && (
            <CheckMarkIcon
              stroke={colors.checkboxCheckSelected}
              fillColor={colors.checkboxCheckSelected}
              width='12'
              height='8'
            />
          )}
        </View>
        {children && <View style={{ flexShrink: 1 }}>{children}</View>}
        {buttonType === CheckboxButtonType.TEXT_OUTLINE && (
          <TextContainer style={textContainerStyle}>
            {/* @todo: support markdown in other button types as needed */}
            {typeof title === 'string' ? labelMarkdown : <BodyText size={labelSize} text={title} color={titleColor} />}
          </TextContainer>
        )}
        {buttonType === CheckboxButtonType.CHECKBOX_TEXT_OUTLINE && (
          <>
            <CheckboxOutlinedTextContainer style={textContainerStyle}>
              {Boolean(icon) && <IconContainer>{icon}</IconContainer>}
              <BodyText
                size={labelSize}
                text={title}
                color={
                  readOnly && checked
                    ? colors.secondaryButtonTextActive
                    : readOnly
                    ? colors.primaryButtonTextActive
                    : checked
                    ? colors.secondaryButtonTextActive
                    : colors.inputTextActive
                }
              />
            </CheckboxOutlinedTextContainer>
            {badge !== undefined && (
              <BadgeContainer>{<FieldBadge text={badge} selected={checked} level={'4'} />}</BadgeContainer>
            )}
          </>
        )}
        {buttonType === CheckboxButtonType.CHECKBOX_DESCRIPTION_NO_OUTLINE && (
          <CheckboxTextContainer style={textContainerStyle}>
            <BodyText size={labelSize} text={title} color={error ? colors.inputTextError : colors.inputTextActive} />
            <BodyText
              size={labelSize}
              text={description}
              color={
                styleDescriptionAsTitle
                  ? error
                    ? colors.inputTextError
                    : colors.inputTextActive
                  : colors.inputTextDefault
              }
            />
          </CheckboxTextContainer>
        )}
      </CheckboxButtonContainer>
    </BaseInput>
  )
}

export const CheckboxRFF: FunctionComponent<FieldRenderProps<boolean>> = ({
  input: { value, onChange, name, onFocus, onBlur },
  meta: { touched, error, active, submitFailed },
  title,
  description,
  readOnly,
  labelSize,
  buttonType = CheckboxButtonType.TEXT_OUTLINE,
  badge,
  children,
  icon,
  errorOnSubmitFailed = false,
  ...rest
}) => {
  /**
   * Since we do not use a real input field with type='checkbox', checked
   * does not work with react-final-form and is never triggered. Hence we need to use
   * a type other than type='checkbox' for the field and use value here to determine if the field is true/false
   */
  const checked = Boolean(value)
  return (
    <Checkbox
      checked={checked}
      onPress={() => onChange(!checked)}
      error={errorOnSubmitFailed ? submitFailed && error : touched && error}
      name={name}
      title={title}
      description={description}
      readOnly={readOnly}
      labelSize={labelSize}
      onFocus={onFocus}
      onBlur={onBlur}
      buttonType={buttonType}
      badge={badge}
      active={active}
      icon={icon}
      {...rest}
    >
      {children}
    </Checkbox>
  )
}

type CheckBoxProps = {
  label?: string
  title: string | React.ReactNode
  titleColor?: string
  description?: string
  checked: boolean
  onPress: () => void
  error?: string
  name?: string
  readOnly?: boolean
  labelSize?: BodyTextSize
  badge?: React.ReactNode
  buttonType?: CheckboxButtonType
  onFocus?: () => void
  onBlur?: () => void
  children?: React.ReactNode
  baseInputStyle?: ViewStyle
  markdownStyle?: { p2?: ViewStyle; link?: ViewStyle }
  active?: boolean
  accessibilityLabelledBy?: string
  accessibilityLabel?: string
  textContainerStyle?: ViewStyle
  styleDescriptionAsTitle?: boolean
  hideErrorMessage?: boolean
  customErrorMessageId?: string
  icon?: React.ReactNode
  checkboxStyle?: ViewStyle
}

type GetCheckboxButtonStylesArgs = {
  buttonType: CheckboxButtonType
  focused?: boolean
  hovered?: boolean
  pressed?: boolean
  disabled?: boolean
  selected?: boolean
  error?: string
}
