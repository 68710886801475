import React from 'react'
import styles from './specialAlertIndicator.module.scss'
import { ExclamationCircleIcon, ExternalLinkIcon, Tooltip, TOOLTIP_PLACEMENTS } from '@lyrahealth-inc/ui-core'
// import { Indicator } from '@lyrahealth-inc/ui-core-crossplatform'
import CSSModules from 'react-css-modules'
import { Placement } from '@popperjs/core'

const SpecialAlertIndicator: React.FC<SpecialAlertIndicatorProps> = ({
  alertType = 'nuclearBadged',
  tooltipPlacement = TOOLTIP_PLACEMENTS.BOTTOM,
}) => {
  const tooltipContent = {
    nuclearBadged: (
      <div className={styles.tooltip} data-test-id='SpecialAlert-nuclearBadgedTooltip'>
        <div>
          <b>Alert: Elevated safety risk</b>
        </div>
        <br />
        <div>
          This client has a nuclear badge at an energy plant. Given the increased safety risk presented by this access,
          please immediately escalate any safety concerns (such as self harm, harm to others, impairment from
          drugs/alcohol).
        </div>
        <br />
        <div>
          <a href='https://blended-care.zendesk.com/hc/en-us/articles/4619922890387' className={styles['tooltip-link']}>
            View nuclear access help center article <ExternalLinkIcon fillColor={styles.x_white} />
          </a>
        </div>
      </div>
    ),
  }

  return (
    <Tooltip content={tooltipContent[alertType]} placement={tooltipPlacement}>
      <div className={styles['special-alert']} data-test-id='SpecialAlert'>
        <ExclamationCircleIcon fillColor={styles.x_highlight} width={12} /> Special alert
      </div>
    </Tooltip>
  )
}

type SpecialAlertIndicatorProps = {
  alertType?: string
  tooltipPlacement?: Placement
}

export default CSSModules(SpecialAlertIndicator, styles)
