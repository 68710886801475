import { combineReducers } from '@reduxjs/toolkit'

import contents from './ltContentsReducer'
import toast from './ltToastReducer'
import video from './ltVideoReducer'
import clients from '../clients/data/ltClientsReducer'
import messenger from '../messages/data/messagesReducer'

export default combineReducers({
  clients,
  messenger,
  video,
  contents,
  toast,
})
