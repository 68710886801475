import React, { FunctionComponent } from 'react'
import Svg, { G, Mask, Path } from 'react-native-svg'

type MemberPreferencesReligionIllustrationIconProps = {
  size?: number
}

export const MemberPreferencesReligionIllustrationIcon: FunctionComponent<
  MemberPreferencesReligionIllustrationIconProps
> = ({ size = 519, ...props }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 519 519' fill='none' {...props} pointerEvents='none'>
      <Path
        d='M262.394 10.8245C227.979 10.8245 194.607 16.7538 163.168 28.4162C88.3194 56.2534 35.7189 117.944 18.8154 197.706C7.64786 249.718 9.08183 297.807 23.2042 344.674C41.2592 404.534 85.5601 455.02 144.744 483.162C174.14 497.179 207.904 505.157 245.078 506.923C249.858 507.141 254.725 507.25 259.548 507.25C289.379 507.25 319.384 503.152 348.737 495.064C350.996 494.498 353.147 493.931 355.407 493.233C381.783 485.473 405.53 473.222 425.845 456.851C447.898 439.172 466.061 416.763 479.749 390.255C482.443 385.046 485.029 379.552 487.875 372.969C503.54 336.478 511.101 295.627 509.776 254.776C509.146 236.726 506.473 217.521 501.824 197.684C494.198 165.487 481.77 135.209 465.866 110.053C461.499 103.295 457.523 97.6276 453.547 92.5267C424.976 55.534 380.436 29.5934 324.815 17.495C304.392 13.048 283.382 10.8027 262.394 10.8027V10.8245Z'
        fill='white'
      />
      <Mask
        id='mask0_3456_28247'
        mask-type='luminance'
        maskUnits='userSpaceOnUse'
        x={30}
        y={28}
        width={462}
        height={460}
      >
        <Path
          d='M491.014 254.462C490.4 236.997 487.747 218.917 483.538 200.924C476.676 171.956 465.408 143.383 449.952 118.924C446.379 113.381 442.674 108.036 438.684 102.889C409.701 65.3863 365.768 44.5343 320.804 34.7682C270.731 23.8803 217.459 27.0697 169.338 44.9303C99.6881 70.8192 52.2686 127.546 36.8129 200.506C30.8718 228.155 29.1399 259.389 31.7925 289.391H31.5952C33.1299 306.438 36.1991 323.088 40.8029 338.397C57.9028 395.124 99.4908 440.128 152.435 465.291C181.11 478.95 213.162 485.747 245.739 487.287C279.018 488.826 312.824 484.625 344.064 475.981C346.103 475.475 348.054 474.947 350.115 474.331C374.581 467.138 395.89 456.031 414.13 441.337C433.992 425.413 450.391 405.374 463.084 380.805C465.846 375.46 468.302 370.115 470.669 364.682C485.621 329.841 492.285 292.03 490.948 254.528L491.058 254.418L491.014 254.462Z'
          fill='white'
        />
      </Mask>
      <G mask='url(#mask0_3456_28247)'>
        <Path
          d='M490.942 254.462C490.328 236.997 487.675 218.917 483.466 200.924C476.604 171.956 465.336 143.383 449.88 118.924C446.306 113.381 442.601 108.036 438.611 102.889C409.629 65.3863 365.695 44.5343 320.731 34.7682C270.659 23.8803 217.386 27.0697 169.265 44.9303C99.6159 70.8192 52.1964 127.546 36.7406 200.506C30.7995 228.155 29.0676 259.389 31.7203 289.391H31.523C33.0576 306.438 36.1268 323.088 40.7306 338.397C57.8306 395.124 99.4185 440.128 152.363 465.291C181.038 478.95 213.089 485.747 245.667 487.287C278.946 488.826 312.751 484.625 343.992 475.981C346.031 475.475 347.982 474.947 350.042 474.331C374.508 467.138 395.818 456.031 414.058 441.337C433.92 425.413 450.318 405.374 463.012 380.805C465.774 375.46 468.229 370.115 470.597 364.682C485.549 329.841 492.213 292.03 490.876 254.528L490.985 254.418L490.942 254.462Z'
          fill='#F5F2EE'
        />
        <Path
          d='M-78.4482 61.4077C-78.4482 61.4077 234.284 79.8841 338.111 271.269L264.472 315.524C264.472 315.524 203.548 153.218 -83.5781 94.8412C-370.682 36.4865 -78.4482 61.4077 -78.4482 61.4077Z'
          fill='white'
        />
        <Path
          d='M276.749 114.044C276.749 114.044 169.852 7.43038 -21.4478 29.91C-212.748 52.4117 -35.4127 49.7062 -35.4127 49.7062C-35.4127 49.7062 161.544 62.6837 237.375 151.239L276.727 114.022L276.749 114.044Z'
          fill='white'
        />
        <Path
          d='M130.19 263.351C130.19 263.351 53.2837 206.206 -64.6402 201.345C-182.564 196.484 -64.6402 213.618 -64.6402 213.618C-64.6402 213.618 85.3133 258.204 109.034 298.082L130.19 263.351Z'
          fill='white'
        />
        <Path
          d='M207.182 314.009C207.182 295.994 255.895 306.288 284.088 278.002C317.411 244.568 304.586 200.841 322.541 200.841C343.039 200.841 328.942 248.418 355.864 275.428C382.785 302.439 435.334 293.443 435.334 314.009C435.334 334.575 389.187 319.156 360.994 347.442C334.072 374.453 337.711 426.847 319.866 426.847C302.021 426.847 307.151 375.729 284.088 352.589C259.731 328.152 207.182 332.023 207.182 314.009Z'
          fill='#FFB84D'
        />
        <Path
          d='M205.346 150.796C205.346 139.952 234.657 146.155 251.625 129.13C271.685 109.004 263.968 82.6973 274.754 82.6973C287.097 82.6973 278.612 111.336 294.814 127.569C311.015 143.823 342.628 138.412 342.628 150.774C342.628 163.136 314.873 153.875 297.905 170.9C280.936 187.925 283.896 218.675 273.154 218.675C262.411 218.675 265.502 187.925 251.625 173.98C236.981 159.286 205.346 161.596 205.346 150.774V150.796Z'
          fill='#FFD793'
        />
        <Path
          d='M86.6196 293.243C86.6196 286.16 105.78 290.208 116.873 279.078C129.983 265.924 124.941 248.724 132 248.724C140.068 248.724 134.521 267.442 145.11 278.066C155.699 288.69 176.35 285.149 176.35 293.243C176.35 301.337 158.198 295.267 147.105 306.396C136.516 317.02 137.941 337.63 130.926 337.63C123.911 337.63 125.928 317.526 116.851 308.42C107.271 298.808 86.5977 300.326 86.5977 293.243H86.6196Z'
          fill='#FFD793'
        />
      </G>
    </Svg>
  )
}
