import React, { FunctionComponent } from 'react'
import { Path, Svg } from 'react-native-svg'

import { PrimaryNeedsWords } from '@lyrahealth-inc/shared-app-logic'

import { tID } from '../../utils'

type BehaviorProblemsIllustrationProps = {
  size?: string | number
  selected?: string
}

export const BehaviorProblemsIllustration: FunctionComponent<BehaviorProblemsIllustrationProps> = ({
  selected = false,
  size,
}) => (
  <Svg
    width={size}
    height={size}
    viewBox='0 0 48 48'
    fill='none'
    testID={tID(`${PrimaryNeedsWords.BEHAVIOR_PROBLEMS}${selected ? '-Selected-' : '-'}Illustration`)}
  >
    <Path
      d='M38.4329 25.6392L33.6246 28.306C32.7272 28.8041 31.8144 29.275 30.8899 29.7208L16.1625 36.7967C11.5963 39.0836 6.23759 37.6262 4.02818 33.2171C3.07852 31.3216 2.81883 29.6142 3.11729 27.647C3.53979 24.8659 5.82283 22.6584 8.42567 21.3541L14.926 17.7667L30.698 10.1946C33.2912 9.06085 35.6498 8.85349 37.9678 9.52406C40.2043 10.1714 41.7277 11.5687 42.8324 13.7743C43.8906 15.8868 44.4778 18.1892 43.8673 20.2726C43.2995 22.2088 41.1036 24.298 38.4349 25.6353L38.4329 25.6392Z'
      fill={selected ? '#85CCD5' : '#FCD0C2'}
    />
    <Path
      d='M30.6921 27.3946L29.2677 28.1079L21.653 31.922C20.6626 32.4182 19.9087 32.0228 19.4184 31.046L18.3757 29.1525C18.0423 28.5459 17.7245 27.9296 17.426 27.3055L14.8813 21.9874C14.4394 21.1075 14.3057 20.2819 15.517 19.6733L18.2148 18.0938C18.8641 17.7139 19.5327 17.3709 20.2207 17.0666L24.5562 15.1441C25.3489 14.7468 26.5292 15.2196 26.971 16.1015L29.6127 21.1424C29.9499 21.7859 30.2561 22.4429 30.5294 23.1154L31.3278 25.0767C32.0023 26.4237 31.4848 26.9935 30.6921 27.3908V27.3946Z'
      fill={selected ? '#00A4B3' : '#FCB097'}
    />
    <Path
      d='M11.2831 11.9989L6.89919 8.81075C5.88945 8.07622 4.92045 9.75653 5.9205 10.4833L10.3044 13.6715C11.3141 14.406 12.2832 12.7257 11.2831 11.9989Z'
      fill={selected ? '#85CCD5' : '#BBE4E8'}
    />
    <Path
      d='M22.9383 7.03596C22.977 5.00098 23.0139 2.96793 23.0526 0.932952C23.0759 -0.315171 21.1378 -0.309361 21.1146 0.9407C21.0758 2.97568 21.039 5.00873 21.0002 7.04371C20.977 8.29183 22.915 8.28602 22.9383 7.03596Z'
      fill={selected ? '#85CCD5' : '#BBE4E8'}
    />
    <Path
      d='M44.9977 32.1412C43.7088 31.5772 42.3735 31.1566 40.9975 30.862C39.7765 30.6023 39.4974 32.5191 40.7145 32.7788C41.9239 33.0365 43.0828 33.4183 44.2147 33.9126C45.3581 34.4126 46.1411 32.6392 44.9977 32.1392V32.1412Z'
      fill={selected ? '#85CCD5' : '#BBE4E8'}
    />
    <Path
      d='M22.1206 42.3107L22.9346 47.2198C23.1381 48.4505 25.051 48.1346 24.8475 46.902L24.0335 41.9928C23.83 40.7621 21.9171 41.0781 22.1206 42.3107Z'
      fill={selected ? '#85CCD5' : '#BBE4E8'}
    />
    <Path
      d='M38.023 41.7775C36.6721 40.2425 35.3213 38.7076 33.9705 37.1726C33.1487 36.2385 31.6525 37.4711 32.4801 38.4111C33.8309 39.946 35.1818 41.481 36.5326 43.0159C37.3543 43.9501 38.8505 42.7175 38.023 41.7775Z'
      fill={selected ? '#85CCD5' : '#BBE4E8'}
    />
    <Path
      d='M10.6339 25.5834C10.5951 25.4671 10.5312 25.3664 10.4401 25.2811C10.3761 25.2326 10.3141 25.1842 10.2502 25.1338C10.1048 25.0485 9.94589 25.0059 9.77727 25.0059C9.69394 25.0175 9.61059 25.0291 9.52725 25.0388C9.36833 25.0834 9.23075 25.1629 9.11447 25.2791L8.93807 25.4555C8.84698 25.5408 8.78302 25.6416 8.74426 25.7578C8.68612 25.8702 8.659 25.9904 8.66288 26.1183C8.65707 26.2482 8.68418 26.3683 8.74426 26.4788C8.78302 26.5951 8.84698 26.6959 8.93807 26.7811C9.00203 26.8296 9.06404 26.8781 9.128 26.9284C9.27336 27.0137 9.43231 27.0563 9.60093 27.0563C9.68426 27.0447 9.76761 27.0331 9.85095 27.0234C10.0099 26.9788 10.1474 26.8994 10.2637 26.7831L10.4401 26.6067C10.5312 26.5214 10.5951 26.4207 10.6339 26.3044C10.692 26.192 10.7192 26.0718 10.7153 25.9439C10.7211 25.814 10.694 25.6939 10.6339 25.5834Z'
      fill={selected ? '#3BA5B5' : '#FCB097'}
    />
    <Path
      d='M9.60658 31.398C9.49999 31.0549 9.23448 30.7797 8.88368 30.6848C8.70732 30.6363 8.5135 30.6363 8.33713 30.6848C8.17433 30.7294 8.09679 30.7797 7.97663 30.8728C7.85259 30.9677 7.79447 31.0162 7.70338 31.1712C7.65299 31.2584 7.60839 31.3515 7.58319 31.4503C7.5328 31.6519 7.52506 31.8631 7.58707 32.0647C7.6646 32.3108 7.81577 32.5085 8.0212 32.6422C8.10842 32.7062 8.20532 32.7566 8.31191 32.7798C8.38556 32.8031 8.45729 32.8128 8.53094 32.8128C8.55032 32.8128 8.57165 32.8186 8.59103 32.8167C8.66274 32.8128 8.78869 32.7973 8.85847 32.7818C9.02902 32.743 9.18602 32.6384 9.31199 32.5221C9.45735 32.3883 9.56008 32.1984 9.6124 32.0104C9.66473 31.8205 9.66473 31.5957 9.60658 31.4057V31.398Z'
      fill={selected ? '#3BA5B5' : '#FCB097'}
    />
    <Path
      d='M15.8061 31.2271C15.8041 31.2097 15.7983 31.1283 15.7847 31.0857C15.7828 31.0779 15.7808 31.0701 15.777 31.0643C15.7595 31.012 15.7053 30.8783 15.6781 30.8298C15.6742 30.8221 15.6685 30.8143 15.6627 30.8066C15.6278 30.7523 15.5541 30.6535 15.5095 30.6089C15.5037 30.6031 15.4979 30.5992 15.494 30.5934C15.4301 30.5449 15.368 30.4965 15.3041 30.4461C15.3002 30.4422 15.2944 30.4383 15.2905 30.4364C15.2517 30.4151 15.1878 30.3918 15.1626 30.3821C15.1239 30.3647 15.0774 30.3414 15.0308 30.3298C14.9785 30.3162 14.8234 30.2969 14.7692 30.2949C14.7575 30.2949 14.7479 30.2949 14.7362 30.2949L14.4862 30.3279C14.4862 30.3279 14.4785 30.3279 14.4726 30.3298C14.4649 30.3317 14.4552 30.3356 14.4475 30.3376C14.3796 30.3589 14.2731 30.4035 14.211 30.4383C14.2013 30.4442 14.1916 30.45 14.1819 30.4558C14.023 30.5585 13.8815 30.6903 13.7924 30.8589C13.7614 30.9325 13.7284 31.0081 13.6974 31.0837L13.6935 31.0953C13.6935 31.0953 13.6897 31.1128 13.6877 31.1205C13.6393 31.3376 13.6586 31.4461 13.6877 31.6593C13.7168 31.7814 13.773 31.888 13.8564 31.9772C13.9222 32.0799 14.0094 32.1594 14.1199 32.2194C14.1916 32.2659 14.2672 32.295 14.3467 32.3125C14.4184 32.357 14.494 32.39 14.5773 32.4094L14.8273 32.4423C14.9959 32.4423 15.1549 32.3997 15.3002 32.3144C15.3719 32.2601 15.4572 32.2078 15.5192 32.14C15.5502 32.1051 15.649 31.981 15.6723 31.9403C15.6762 31.9345 15.6801 31.9268 15.6839 31.921C15.713 31.8648 15.7673 31.7427 15.7847 31.6806C15.7867 31.6729 15.7886 31.6651 15.7905 31.6574C15.8061 31.5876 15.8215 31.4675 15.8254 31.3977C15.8254 31.3899 15.8254 31.3822 15.8254 31.3744C15.8254 31.326 15.8119 31.264 15.8061 31.2271Z'
      fill={selected ? '#3BA5B5' : '#FCB097'}
    />
    <Path
      d='M36.5435 21.4501C36.4854 21.3415 36.404 21.2524 36.3012 21.1865C36.2102 21.1031 36.1035 21.0469 35.9834 21.0179C35.8807 20.9849 35.7799 20.9772 35.6772 20.9907C35.5706 20.983 35.464 20.9907 35.3574 21.0198C35.2837 21.0508 35.2082 21.0818 35.1326 21.1148C34.9911 21.1981 34.8806 21.3105 34.7954 21.4501C34.7527 21.5256 34.7159 21.607 34.6946 21.6904C34.6422 21.8803 34.6558 21.9152 34.6771 22.076C34.6984 22.235 34.6926 22.266 34.7915 22.4346C34.8341 22.5063 34.8845 22.578 34.9465 22.6342C34.964 22.6497 35.121 22.7718 35.1403 22.7835C35.1423 22.7835 35.1461 22.7873 35.1481 22.7873C35.1694 22.799 35.2489 22.8319 35.2779 22.8435C35.3051 22.8571 35.3613 22.8804 35.3845 22.8862C35.4776 22.9114 35.5745 22.9249 35.6694 22.9249C35.714 22.9249 35.7857 22.9094 35.8128 22.9075C35.8439 22.9036 35.9097 22.8997 35.9543 22.8862C36.0377 22.8629 36.121 22.8319 36.1927 22.7854C36.1946 22.7854 36.1985 22.7815 36.2005 22.7815C36.2644 22.7331 36.3264 22.6846 36.3904 22.6342C36.3904 22.6342 36.3943 22.6323 36.3943 22.6303C36.4233 22.6013 36.4718 22.5296 36.4718 22.5315C36.5086 22.485 36.5261 22.4675 36.5493 22.4307C36.5939 22.3609 36.6268 22.2815 36.6462 22.204C36.6462 22.204 36.6462 22.2001 36.6462 22.1981C36.6579 22.1148 36.6695 22.0315 36.6792 21.9481C36.6869 21.7834 36.6268 21.5838 36.5435 21.4442V21.4501Z'
      fill={selected ? '#3BA5B5' : '#FCB097'}
    />
    <Path
      d='M33.741 14.5985C33.6809 14.4822 33.6227 14.3659 33.5626 14.2496C33.4599 14.048 33.2157 13.8775 33.0025 13.8194C32.8785 13.7806 32.7525 13.7748 32.6284 13.8019C32.5044 13.8077 32.3882 13.8446 32.2796 13.9124C32.08 14.0422 31.9037 14.2341 31.8494 14.4725L31.8164 14.7225C31.8164 14.8911 31.8591 15.05 31.9443 15.1954C32.0044 15.3117 32.0626 15.428 32.1226 15.5443C32.2254 15.7458 32.4695 15.9164 32.6827 15.9745C32.8068 16.0133 32.9328 16.0191 33.0568 15.992C33.1808 15.9861 33.2971 15.9493 33.4056 15.8815C33.6052 15.7516 33.7816 15.5598 33.8359 15.3214C33.8475 15.238 33.8592 15.1547 33.8688 15.0714C33.8688 14.9028 33.8262 14.7438 33.741 14.5985Z'
      fill={selected ? '#3BA5B5' : '#FCB097'}
    />
    <Path
      d='M39.8284 15.4992C39.7431 15.3558 39.5959 15.2026 39.4486 15.1251C39.2683 15.0282 39.123 14.9953 38.9156 14.9817C38.685 14.9662 38.4117 15.098 38.2527 15.2569C38.0899 15.4197 37.9679 15.6872 37.9775 15.9197C37.9795 15.976 37.9873 16.0322 37.9969 16.0864C37.9814 16.1523 37.9737 16.2201 37.9775 16.2841C37.9892 16.5264 38.0686 16.7783 38.2527 16.9469C38.4291 17.1097 38.6675 17.2376 38.9156 17.2221C39.1075 17.2105 39.2819 17.1834 39.4486 17.0787C39.6036 16.9818 39.7335 16.8616 39.8284 16.7047C39.9389 16.5225 39.9951 16.3151 39.9951 16.1019C39.9951 15.8887 39.937 15.6833 39.8284 15.4992Z'
      fill={selected ? '#3BA5B5' : '#FCB097'}
    />
  </Svg>
)
