import { createReducer } from '@reduxjs/toolkit'

import { AppointmentSummary, Feedback, Note } from '@lyrahealth-inc/shared-app-logic'

import {
  GET_ALL_NOTES,
  GET_APPOINTMENT_SUMMARIES,
  GET_NOTES,
  GET_NOTES_FEEDBACK,
} from '../../../common/constants/reduxConstants'

type ClientNotesState = {
  data: Note[]
  allNotes: Note[]
  appointmentSummaries?: AppointmentSummary[]
  notesFeedback?: Feedback[]
}

const initialState = {
  data: [],
  allNotes: [],
}

export default createReducer<ClientNotesState>(initialState, (builder) => {
  builder.addCase(GET_NOTES, (state, action: any) => {
    state.data = action.data
    return state
  })
  builder.addCase(GET_ALL_NOTES, (state, action: any) => {
    state.allNotes = action.data
    return state
  })
  builder.addCase(GET_APPOINTMENT_SUMMARIES, (state, action: any) => {
    state.appointmentSummaries = action.data
  })
  builder.addCase(GET_NOTES_FEEDBACK, (state, action: any) => {
    state.notesFeedback = action.data
    return state
  })
})
