import { BodyTextSize } from '../../styles/typeStyles'

export enum AlertBannerTypes {
  DANGER = 'danger',
  SUCCESS = 'success',
  WARNING = 'warning',
  INFO = 'info',
  NOTIFICATION = 'notification',
  CALM = 'calm',
}

export type AlertBannerContent = {
  text: string
  id: string
  alertBannerType: AlertBannerTypes
  textSize?: BodyTextSize
}
