import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

/**
 * Pencil Icon
 */
export const EditIconBoxed: FunctionComponent<EditIconBoxedProps> = ({ size = 24, fillColor }) => {
  const { colors } = useTheme()

  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' fill='none'>
      <Path
        d='M9.66602 3.33301H3.83268C3.39065 3.33301 2.96673 3.5086 2.65417 3.82116C2.34161 4.13372 2.16602 4.55765 2.16602 4.99967V16.6663C2.16602 17.1084 2.34161 17.5323 2.65417 17.8449C2.96673 18.1574 3.39065 18.333 3.83268 18.333H15.4993C15.9414 18.333 16.3653 18.1574 16.6779 17.8449C16.9904 17.5323 17.166 17.1084 17.166 16.6663V10.833'
        stroke={fillColor || colors.iconActive}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <Path
        d='M15.916 2.0832C16.2475 1.75168 16.6972 1.56543 17.166 1.56543C17.6349 1.56543 18.0845 1.75168 18.416 2.0832C18.7475 2.41472 18.9338 2.86436 18.9338 3.3332C18.9338 3.80204 18.7475 4.25168 18.416 4.5832L10.4993 12.4999L7.16602 13.3332L7.99935 9.99986L15.916 2.0832Z'
        stroke={fillColor || colors.iconActive}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </Svg>
  )
}

type EditIconBoxedProps = {
  size?: string | number
  fillColor?: string
}
