import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-final-form'
import { Animated, FlatList, LayoutChangeEvent, ListRenderItem, Platform, ScrollView } from 'react-native'
import { KeyboardAwareFlatList } from 'react-native-keyboard-aware-scroll-view'

import { ObjectFieldTemplateProps } from '@rjsf/core'
import { groupBy, includes, isArray, isEmpty } from 'lodash-es'
import { FlattenSimpleInterpolation } from 'styled-components'
import styled, { useTheme } from 'styled-components/native'

import { PROVIDER_TYPES, ProviderEnums, usePrevious } from '@lyrahealth-inc/shared-app-logic'

import { BaseButton, ButtonSize, ButtonType } from '../../atoms/baseButton/BaseButton'
import { Divider } from '../../atoms/divider/Divider'
import { EditIconBoxed } from '../../atoms/icons/EditIconBoxed'
import { LoadingIndicator } from '../../atoms/icons/LoadingIndicator'
import { StyledMarkdown } from '../../atoms/styledMarkdown/StyledMarkdown'
import { Size, Subhead } from '../../atoms/subhead/Subhead'
import { IS_WEB, Layout } from '../../constants'
import { CustomizedActivityIntroduction } from '../../molecules/customizedActivityIntroduction/CustomizedActivityIntroduction'
import { getCommonStyles } from '../../styles/commonStyles'
import { Flex1View } from '../../templates/content/CommonViews'
import { isChromaticWeb, ThemeType, tID } from '../../utils'

type CalculateLayoutParams = {
  fieldHeight: number
  containerHeight: number
  page: number
}

type RJSFObjectProperty = {
  content: React.ReactElement
  name: string
  disabled: boolean
  readonly: boolean
}

const FormBodyPageContainer = styled(Animated.View)<{
  theme: ThemeType
  itemIsInstructions: boolean
  hide: boolean
  styles?: { [key: string]: FlattenSimpleInterpolation }
  editMode?: boolean
  backgroundColor?: string
}>`
  align-self: center;
  max-width: 576px;
  width: 100%;
  ${({ theme, itemIsInstructions, editMode, backgroundColor }) =>
    !itemIsInstructions &&
    `
    background-color: ${backgroundColor ? backgroundColor : theme.colors.backgroundPrimary};
    padding-top: ${editMode ? '18px' : '30px'};
  `}
  ${({ hide }) =>
    hide &&
    `
    display: none;
  `}
  ${({ styles }) => styles?.formBodyPageContainer}
`

const ListItemContainer = styled.View<{
  theme: ThemeType
  backgroundColor?: string
}>`
  padding-top: 24px;
  ${({ theme, backgroundColor }) =>
    `
  background-color: ${backgroundColor ? backgroundColor : theme.colors.backgroundPrimary};
  `}
`

const EditIconContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  marginBottom: spacing['16px'],
  alignSelf: 'flex-end',
  paddingTop: '6px',
}))

/**
 * This component is a custom template for RJSF ObjectField. It iterates over all top level
 * schema objects and adds them to a scrollable keyboard aware flatlist.
 */

export const ScrollFieldList: FunctionComponent<ObjectFieldTemplateProps> = (props) => {
  const {
    properties,
    uiSchema,
    formContext: {
      setIsAtTopOfPage,
      currentPage,
      totalPages,
      instructions,
      hideInstructions,
      readOnly,
      showEditButtons,
      onEditPress,
      initialScrollIndex,
      withPageBreaks,
      scrollContainerCustomStyles,
      scrollListHeight,
      scrollEnabled,
      loading,
      intl,
      useCustomizedActivityIntroduction,
      activityIntroductionProviderInfo,
      CustomLoadingIndicator,
      backgroundColor,
      insertElementInScrollContainer,
      scrollFieldContainerHeight,
      withConditionalLastPages,
      showCurrentPageOnly,
    },
  } = props
  const flatListRef = useRef<FlatList | null>(null)
  const [containerHeight, setContainerHeight] = useState(scrollFieldContainerHeight ?? 0)
  const [hasScrolledToInitialIndex, setHasScrolledToInitialIndex] = useState(false)
  const fadeInAnimation = useRef(new Animated.Value(0)).current
  const [layout, setLayout] = useState({ page: 0, amount: 0 })
  const verticalScroll = useRef<number>(0)
  const [verticalScrollBeforeKeyboard, setVerticalScrollBeforeKeyboard] = useState<number>(0)
  const prevInitialScrollIndex = usePrevious(initialScrollIndex)
  const { focus } = useForm()
  const { colors } = useTheme()
  const commonStyles = getCommonStyles(colors)
  const [currentIndex, setCurrentIndex] = useState<number>(0)

  const initialCurrentPage = useRef(currentPage)
  const [renderedPages, setRenderedPages] = useState<string[]>([])
  const pageHeights = useRef<{ [key: number]: number }>({})
  const [formState, setFormState] = useState({
    allPreviousPagesShown: currentPage <= 2 || currentPage === undefined,
    loadingPreviousPages: false,
  })
  const currentPageVisible = useRef(currentPage)
  const previousPageVisible = useRef()

  const fadeIn = useCallback(() => {
    Animated.timing(fadeInAnimation, {
      toValue: 1,
      duration: 500,
      useNativeDriver: true,
    }).start()
  }, [fadeInAnimation])

  const scrollToIndex = useCallback(
    ({ index, animated = true }: { index: number; animated?: boolean }) => {
      const listOfFields = flatListRef.current
      if (listOfFields) {
        setTimeout(() => listOfFields.scrollToIndex({ index, viewPosition: 0, animated }), 10)
      }
    },
    [flatListRef],
  )

  const scrollListData = useMemo(() => {
    // Since the instructions aren't part of the schema we need to append it to our scroll data.
    // We render an empty view when no instructions to maintain a consistent number of scroll elements.

    let InstructionsView

    if (hideInstructions) {
      InstructionsView = <></>
    } else if (useCustomizedActivityIntroduction && !isEmpty(activityIntroductionProviderInfo)) {
      const { displayName, firstName, imageUrl, lyraType } = activityIntroductionProviderInfo

      InstructionsView = (
        <ListItemContainer backgroundColor={backgroundColor}>
          <CustomizedActivityIntroduction
            content={instructions}
            providerAvatar={{
              src: imageUrl,
              displayName: displayName,
            }}
            providerFirstName={firstName}
            sessionInfo={PROVIDER_TYPES[ProviderEnums[lyraType.toUpperCase()]].TITLE}
          />
        </ListItemContainer>
      )
    } else if (instructions) {
      InstructionsView = (
        <ListItemContainer backgroundColor={backgroundColor}>
          <Subhead
            size={Size.MEDIUM}
            text={intl.formatMessage({
              defaultMessage: 'Introduction',
              description: 'Heading to show this is the first page of an activity to be completed.',
            })}
          />
          <StyledMarkdown content={instructions} />
        </ListItemContainer>
      )
    }

    // Use ui:order to group field names by mobilePage
    const groupFieldNames = () => {
      const uiOrder = uiSchema['ui:order']
      if (includes(uiOrder, '*')) {
        // When using the wildcard property in ui:order we should place all fields on page 1
        const propertyNames = properties.map((property) => property.content.key)
        return { '1': propertyNames }
      } else {
        return groupBy(uiOrder, (field) => {
          const property = properties.find((property) => property.content.key === field)
          const schema = property?.content.props.schema
          // All hidden fields should be placed on page 1
          return schema?.show !== false ? schema?.mobilePage : 1
        })
      }
    }

    // Create a grouped version of properties from groupedFieldNames
    const groupedProperties = Object.values(groupFieldNames()).map((group) =>
      group.map((field) => properties.find((property) => property.content.key === field)),
    )
    return {
      properties: InstructionsView ? [InstructionsView, ...groupedProperties] : groupedProperties,
      names: groupFieldNames(),
    }
  }, [
    instructions,
    hideInstructions,
    uiSchema,
    properties,
    useCustomizedActivityIntroduction,
    activityIntroductionProviderInfo,
    backgroundColor,
    intl,
  ])

  // Scroll to the next section when the page changes
  useEffect(() => {
    const nextIndex = layout.page
    const nextIndexLessThanTotalPages = showCurrentPageOnly && totalPages > 1 ? nextIndex < totalPages : true // if total pages is greater than the default value 1, make sure the nextIndex is less than totalPages to prevent out of bounds error in scrollToIndex
    if (nextIndex > 0 && nextIndexLessThanTotalPages && currentIndex !== nextIndex) {
      scrollToIndex({ index: nextIndex })
      setCurrentIndex(nextIndex)
      fadeIn()
      focus(scrollListData?.names?.[nextIndex]?.[0])
    }
  }, [layout.page, fadeIn, scrollToIndex, focus, scrollListData.names, currentIndex, totalPages, showCurrentPageOnly])

  // Scroll to initial position
  useEffect(() => {
    if (!hasScrolledToInitialIndex && layout.page >= initialScrollIndex) {
      scrollToIndex({ index: initialScrollIndex, animated: false })
      setHasScrolledToInitialIndex(true)
    }
  }, [hasScrolledToInitialIndex, initialScrollIndex, layout.page, scrollToIndex])

  // Reset hasScrolledToInitialIndex when initialScrollIndex changes
  useEffect(() => {
    if (prevInitialScrollIndex !== initialScrollIndex) {
      setHasScrolledToInitialIndex(false)
    }
  }, [initialScrollIndex, prevInitialScrollIndex])

  /*
   * To ensure that new sections appear at the top of the screen we need to add additional margin to the
   * bottom of sections when they are shorter than the screen height.
   */
  const calculateLayout = useCallback(
    ({ fieldHeight, containerHeight, page }: CalculateLayoutParams): void => {
      const difference = containerHeight - fieldHeight
      if (page !== layout.page) {
        setLayout({ page, amount: Math.max(0, difference) })
      }
    },
    [layout.page],
  )

  const updateVisiblePageInfo = useCallback((visiblePage?: number) => {
    if (visiblePage !== undefined) {
      previousPageVisible.current = currentPageVisible.current
      currentPageVisible.current = visiblePage
    }
  }, [])

  const getCurrentPageVisible = () => {
    let startYPosition = 0
    for (const [page, height] of Object.entries(pageHeights.current)) {
      const endYPosition = startYPosition + height
      if (verticalScroll.current >= startYPosition && verticalScroll.current <= endYPosition) {
        const visiblePage = Number(page)
        return visiblePage
      }
      startYPosition += height
    }

    return -1
  }

  const listItem: ListRenderItem<RJSFObjectProperty[]> = useCallback(
    ({ item, index }) => {
      // If item is not an array then we know it is the instructions
      const itemIsInstructions = !isArray(item)
      // Instructions are always on page 0. If no mobile page is defined put content at the end.
      const mobilePage = !itemIsInstructions ? item[0]?.content?.props.schema.mobilePage || totalPages : 0
      const hide = mobilePage > currentPage || (showCurrentPageOnly && mobilePage !== currentPage)
      const currentItem = currentPage === mobilePage && !readOnly
      const isReviewMode = readOnly && showEditButtons
      /* Hide divider on first page (instructions) and on the last rendered page (because we only want to see the divider between previous questions) */
      const showDivider = (index !== 0 && mobilePage < currentPage) || isReviewMode
      const renderEditButton = readOnly && showEditButtons && (mobilePage <= totalPages || !withConditionalLastPages)

      // additional bottom margin for the box error
      const style = currentItem && {
        opacity: index === 0 || isChromaticWeb() ? 1 : fadeInAnimation,
      }

      // If a user is returning to a in-progress form, we only will show the current page being worked on and the page prior. This reduces load time
      // of opening large forms since pertinent pages will be shown first unless a user explicitly indicates they want to view previously filled pages.
      const shouldShow =
        IS_WEB ||
        isReviewMode ||
        currentPage === undefined ||
        (formState.allPreviousPagesShown && currentPage >= index) ||
        (index >= initialCurrentPage.current - 1 && index <= currentPage)

      return (
        <>
          {shouldShow && (
            <FormBodyPageContainer
              styles={scrollContainerCustomStyles}
              testID={tID('FormBody-page')}
              onLayout={({ nativeEvent: { layout } }) => {
                pageHeights.current[index] = layout.height
                setRenderedPages(Object.keys(pageHeights.current))
                if (currentItem) {
                  calculateLayout({
                    fieldHeight: layout.height,
                    containerHeight,
                    page: mobilePage,
                  })
                }
              }}
              itemIsInstructions={itemIsInstructions}
              editMode={isReviewMode && !itemIsInstructions}
              hide={hide}
              style={style}
              backgroundColor={backgroundColor}
            >
              <ScrollView
                scrollEnabled={scrollEnabled !== undefined ? scrollEnabled : true}
                contentContainerStyle={{
                  paddingHorizontal: 16,
                  ...scrollContainerCustomStyles?.scrollContainerContentCustomStyles,
                }}
                nestedScrollEnabled
              >
                {insertElementInScrollContainer}
                {isReviewMode && !itemIsInstructions && renderEditButton && (
                  <EditIconContainer>
                    <BaseButton
                      onPress={() => {
                        setFormState({ ...formState, allPreviousPagesShown: true })
                        onEditPress(mobilePage)
                      }}
                      testID={tID('FormBody-editButton')}
                      accessibilityLabel={intl.formatMessage({
                        defaultMessage: 'Edit',
                        description: 'Button text to edit',
                      })}
                      customTextColor={colors.textActive}
                      text={intl.formatMessage({
                        defaultMessage: 'Edit',
                        description: 'Edit text to edit',
                      })}
                      rightIcon={<EditIconBoxed />}
                      iconColor={colors.iconActive}
                      buttonType={ButtonType.SECONDARY}
                      size={ButtonSize.SMALL}
                    />
                  </EditIconContainer>
                )}
                {itemIsInstructions ? item : item.map((content) => content?.content)}
              </ScrollView>
              {showDivider && withPageBreaks && <Divider width='100%' height={8} color={colors.dividerPrimary} />}
            </FormBodyPageContainer>
          )}
        </>
      )
    },
    [
      totalPages,
      currentPage,
      readOnly,
      showEditButtons,
      fadeInAnimation,
      formState,
      scrollContainerCustomStyles,
      backgroundColor,
      scrollEnabled,
      insertElementInScrollContainer,
      intl,
      colors.textActive,
      colors.iconActive,
      colors.dividerPrimary,
      withPageBreaks,
      calculateLayout,
      containerHeight,
      onEditPress,
      withConditionalLastPages,
      showCurrentPageOnly,
    ],
  )

  /**
   * Sometimes scrollToIndex is called before the list item heights have been calculated.
   * In this case scrolling fails so we retry scrollToIndex every 40ms.
   * 40ms is about the longest interval before the user starts noticing the flash of the unscrolled page.
   */
  const retryScrollToIndex = ({ index }: { index: number }) =>
    setTimeout(() => scrollToIndex({ index: index, animated: false }), 40)

  const containerOnLayout = (event: LayoutChangeEvent) => {
    // nativeEvents don't exist on the web, this function is called, but we should fallback to window height
    const height = Platform.OS !== 'web' ? event.nativeEvent?.layout?.height : Layout.window.height
    if (height !== containerHeight) {
      setContainerHeight(height)
    }
  }

  useEffect(() => {
    if (formState.loadingPreviousPages && renderedPages.length === currentPage) {
      setFormState({ ...formState, loadingPreviousPages: false })
      const focusedPage = currentPageVisible.current - 1
      // after previous pages have loaded we reset the view to the page before the page where user pulled down to view previous pages
      setTimeout(() => scrollToIndex({ index: focusedPage, animated: true }), 50)
      updateVisiblePageInfo(focusedPage)
    }
  }, [currentPage, formState, renderedPages, scrollToIndex, updateVisiblePageInfo])

  const handleOnRefresh = () => {
    setFormState({ allPreviousPagesShown: true, loadingPreviousPages: true })
  }

  // KeyboardAwareFlatList needs a fixed height so that the scrollToIndex work in web
  return (
    <Flex1View onLayout={containerOnLayout}>
      {containerHeight && !loading ? (
        <KeyboardAwareFlatList
          keyboardOpeningTime={Number.MAX_SAFE_INTEGER}
          enableResetScrollToCoords
          resetScrollToCoords={Platform.OS === 'ios' ? { x: 0, y: verticalScrollBeforeKeyboard } : undefined}
          onKeyboardWillShow={() => {
            setVerticalScrollBeforeKeyboard(verticalScroll.current)
          }}
          innerRef={(ref: any) => {
            flatListRef.current = ref
          }}
          style={scrollListHeight ? { height: scrollListHeight, paddingBottom: 56 } : { ...commonStyles.flex1View }}
          renderItem={listItem}
          keyExtractor={(item, index) => item[0]?.content?.key || `introduction-prepended-key-${index}`}
          data={scrollListData.properties}
          onScroll={(event) => {
            verticalScroll.current = event.nativeEvent.contentOffset.y
            if (setIsAtTopOfPage) {
              setIsAtTopOfPage(verticalScroll.current < 1)
            }
          }}
          onMomentumScrollEnd={() => {
            const currentIndexVisible = getCurrentPageVisible()
            if (currentIndexVisible > -1 && currentIndexVisible !== previousPageVisible.current) {
              updateVisiblePageInfo(currentIndexVisible)
            }
          }}
          onScrollToIndexFailed={retryScrollToIndex}
          enableOnAndroid
          scrollEnabled={scrollEnabled !== undefined ? scrollEnabled : true}
          extraScrollHeight={50}
          refreshing={formState.loadingPreviousPages}
          onRefresh={!formState.allPreviousPagesShown ? handleOnRefresh : undefined}
        />
      ) : !!CustomLoadingIndicator ? (
        <CustomLoadingIndicator />
      ) : (
        <LoadingIndicator />
      )}
    </Flex1View>
  )
}
