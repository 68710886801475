import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'

import styled from 'styled-components/native'

import { PrimaryButton, Subhead } from '../../atoms'
import { SubheadSize } from '../../styles'
import { tID } from '../../utils'

export type ProviderCalendarAuthorizationCardProps = {
  onAuthorizePressed?: () => void
}

const Container = styled.View({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  height: '100%',
  flexDirection: 'column',
  justifyContent: 'center',
  zIndex: 10,
  alignItems: 'center',
})

const InnerContainer = styled.View(({ theme }) => ({
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.colors.backgroundPrimary,
  gap: theme.spacing['12px'],
  boxShadow: `0 2px 10px ${theme.colors.shadowLow}`,
  borderRadius: '16px',
  padding: theme.spacing['24px'],
}))

const AuthorizeButton = styled(PrimaryButton)({
  alignSelf: 'center',
})

export const ProviderCalendarAuthorizationCard: FunctionComponent<ProviderCalendarAuthorizationCardProps> = ({
  onAuthorizePressed,
}) => {
  return (
    <Container>
      <InnerContainer>
        <Subhead
          size={SubheadSize.MEDIUM}
          text={
            <FormattedMessage
              defaultMessage='Please authorize access to your calendar below'
              description='Subhead for provider calendar authorization card'
            />
          }
        />
        <AuthorizeButton
          testID={tID('ProviderCalendarAuthorizationCard-button')}
          text={<FormattedMessage defaultMessage='Authorize' description='Button to authorize calendar' />}
          onPress={() => onAuthorizePressed?.()}
        />
      </InnerContainer>
    </Container>
  )
}
