import React from 'react'
import { Icon } from './types'

const StopSignIcon: React.FC<StopSignIconProps> = ({ width = 64 }) => {
  return (
    <svg width={width} height={width} viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M19.574 62L2 44.426V19.573L19.574 2H44.428L62 19.573V44.426L44.428 62H19.574Z' fill='#EAAFAF' />
      <rect x='43.4551' y='19.375' width='2' height='34' rx='1' transform='rotate(45 43.4551 19.375)' fill='#B63131' />
      <rect x='44.7891' y='43.375' width='2' height='34' rx='1' transform='rotate(135 44.7891 43.375)' fill='#B63131' />
    </svg>
  )
}

type StopSignIconProps = Icon

export default StopSignIcon
