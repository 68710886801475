import { FunctionComponent, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { logToSumoLogic } from '../../utils/utils'
import { get } from 'lodash-es'
import { useLocation } from 'react-router'
import { getAuthUserId } from '../../../data/auth/authSelectors'

const JSErrorHandler: FunctionComponent<{}> = () => {
  const userId = useSelector(getAuthUserId)
  const location = useLocation()
  const logError = (e: any) => {
    logToSumoLogic('jsError', userId, {
      message: get(e, 'message'),
      url: get(e, 'filename'),
      stack: get(e, 'error.stack'),
      route: get(location, 'pathname'),
    })
  }

  useEffect(() => {
    window.removeEventListener('error', logError)
    window.addEventListener('error', logError)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  return null
}

export default JSErrorHandler
