import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

export type InfoIconProps = {
  size?: number
  fillColor?: string
  testID?: string
}

export const InfoIcon: FunctionComponent<InfoIconProps> = ({ size = 24, fillColor, testID }) => {
  const { colors } = useTheme()

  return (
    <Svg width={size} height={size} viewBox={`0 0 24 24`} fill='none' testID={testID}>
      <Path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM13 11V17H11V11H13ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM13 7V9H11V7H13Z'
        fill={fillColor || colors.iconActive}
      />
    </Svg>
  )
}
