import React, { ReactElement, ReactNode } from 'react'
import ReactMarkdown from 'react-markdown'
import classNames from 'classnames'
import styles from './baseInput.module.scss'
import LoadingIndicator from '../../loadingIndicator/LoadingIndicator'
import CheckIcon from '../../icons/CheckIcon'
import WarningIcon from '../../icons/WarningIcon'
import AlertLoudIcon from '../../icons/AlertLoudIcon'

const BaseInput: React.FC<BaseInputProps> = ({
  children,
  label,
  labelId,
  labelStyle,
  subLabel,
  toolTip,
  hintContent,
  touched,
  error,
  warning,
  success,
  className,
  disabled,
  style,
  readOnly,
  type,
  indicateAsyncValidity,
  asyncValidating,
  asyncValid,
  insetIcon,
  optional,
  htmlFor,
  fieldInfoId,
  dataTestId,
}) => {
  const renderAsyncStatus = () => {
    if (asyncValidating) {
      return <LoadingIndicator size={20} />
    } else if (asyncValid && !error) {
      return <CheckIcon width={18} isFilled fillColor={styles.x_success} />
    } else if (!asyncValid && touched) {
      return <WarningIcon width={17} isFilled fillColor={styles.error_red5} />
    } else {
      return null
    }
  }
  const renderIcon = () => {
    if (indicateAsyncValidity) {
      return renderAsyncStatus()
    } else if (insetIcon) {
      return insetIcon
    } else {
      return null
    }
  }
  const components = { p: (props: any) => props.children }

  const classes = classNames(
    className,
    styles.container,
    { [styles.disabled]: disabled },
    touched ? { [styles.error]: error } || { [styles.warning]: warning } || { [styles.success]: success } : '',
    { [styles['read-only']]: readOnly },
    { [styles.hidden]: type === 'hidden' },
    { [styles['has-inset-icon']]: insetIcon },
  )
  return (
    <div data-test-id={dataTestId} className={classes} style={style}>
      {label && (
        <div className={styles['field-label-container']}>
          <label htmlFor={htmlFor} id={labelId} className={styles['field-label']}>
            <span style={labelStyle}>
              {/* eslint-disable-next-line react/no-children-prop */}
              <ReactMarkdown children={label} components={components} />
              {toolTip}
            </span>
            {optional && <span className={styles.optional}>optional</span>}
          </label>
          {subLabel && <div className={styles['field-sub-label']}>{subLabel}</div>}
        </div>
      )}
      <div className={styles['input-container']}>
        <div className={styles['icon-container']}>{renderIcon()}</div>
        {children}
      </div>
      <div id={fieldInfoId} className={styles['field-info-container']} data-test-id='BaseInput-error-container'>
        {(touched &&
          ((error && (
            <div className={classNames(styles['field-info'], styles.validation)} role='alert'>
              <AlertLoudIcon /> {error}
            </div>
          )) ||
            (warning && <div className={classNames(styles['field-info'], styles.warning)}>{warning}</div>))) ||
          (success && (
            <div className={classNames(styles['field-info'], styles.success)}>
              <CheckIcon fillColor={styles.x_success} width={14} /> {success}
            </div>
          ))}
        {hintContent && <div className={classNames(styles['field-info'], styles.hint)}>{hintContent}</div>}
      </div>
    </div>
  )
}

type BaseInputProps = {
  readOnly?: boolean
  touched?: boolean
  error: string | ReactNode
  label?: string
  labelId?: string
  labelStyle?: Dict
  subLabel?: string
  toolTip?: ReactNode | null
  className?: string
  disabled?: boolean
  style?: Dict
  hintContent?: ReactElement
  indicateAsyncValidity?: boolean
  asyncValidating?: boolean
  asyncValid?: boolean
  type?: string
  warning?: string
  success?: string
  optional?: string
  children: ReactElement<any, any>
  insetIcon?: ReactElement
  htmlFor?: string
  fieldInfoId: string
  dataTestId?: string
}

export default BaseInput
