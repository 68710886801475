import React from 'react'
import styles from './assignmentsList.module.scss'
import AssignmentListItem from './AssignmentListItem'
import CollapsibleSection from '../collapsibleSection/CollapsibleSection'
import { uniqueId, isEmpty, get } from 'lodash-es'
import { Assignment, Provider, Episode } from '@lyrahealth-inc/shared-app-logic'

import { ItemAction } from './AssignmentsList'

const WeekAssignmentsList: React.FC<WeekAssignmentsListProps> = ({
  activityActions,
  assignments,
  currentEpisode,
  isOpen = false,
  itemClickHandler,
  providers = [],
  sessionData = {},
  subtext,
  userRole,
  week,
}) => {
  const completedAssignments = assignments.reduce(
    (completed, assignment) =>
      completed +
      (assignment.status === 'completed' ||
      (assignment?.assignment_responses &&
        assignment?.assignment_responses.filter((response) => response.status === 'completed').length > 0)
        ? 1
        : 0),
    0,
  )

  const title =
    week < 1 ? 'To complete before session' : `Week ${week} - ${currentEpisode?.curriculum[week - 1]?.title}`

  return (
    <CollapsibleSection
      title={title}
      subtext={subtext}
      isOpen={isOpen}
      rightItems={`${completedAssignments}/${assignments.length}`}
      iconOnLeft
      listStyle
    >
      {isEmpty(assignments) ? (
        <div className={styles['no-assignments']}>No activities shared to this session</div>
      ) : (
        <>
          {assignments.map((assignment: Assignment) => (
            <AssignmentListItem
              key={uniqueId('assignmentListItem')}
              assignment={assignment}
              fullBorder
              itemActions={activityActions}
              itemClickHandler={itemClickHandler}
              multiProvider={providers.length > 1}
              provider={providers.find((provider) => provider.provider.lyra_id === assignment.provider_id)}
              sessionCount={get(sessionData, 'sessionCount')}
              showDueDate={false}
              showProviderName={false}
              userRole={userRole}
            />
          ))}
        </>
      )}
    </CollapsibleSection>
  )
}

type WeekAssignmentsListProps = {
  activityActions: Array<ItemAction>
  assignments: Assignment[]
  currentEpisode: Episode
  isOpen: boolean
  itemClickHandler?: (val: any) => void
  providers?: Provider[]
  sessionData: any
  subtext: string
  userRole: string
  week: number
}

export default WeekAssignmentsList
