import React from 'react'
import CSSModules from 'react-css-modules'
import styles from './requestPayment.module.scss'
import sessionResetDate from '../common/content/sessionResetDate'
import { CustomerInfo } from '@lyrahealth-inc/shared-app-logic'

const CancelledSessionsExceededModal = ({
  firstName,
  lastName,
  visitDate,
  company,
}: CancelledSessionsExceededModalProps) => {
  return (
    <div styleName='modal-content'>
      <h2 styleName='main-header'>Whoops! Invalid invoice</h2>
      <div>
        <p>
          We regret that your invoice for client <span styleName='capitalize'>{firstName}</span>{' '}
          <span styleName='capitalize'>{lastName}</span>, who did not show for the appointment (or cancelled within 24
          hours) dated {visitDate}, cannot be paid by Lyra. The client has exceeded the number of cancellations allowed
          by the sponsoring employer (which, in some cases, is zero). Please contact your client to collect any payments
          owed to you. {sessionResetDate({ employer: company?.label, date: company?.session_count_starts_on })}
        </p>
      </div>
    </div>
  )
}

type CancelledSessionsExceededModalProps = {
  firstName: string
  lastName: string
  visitDate: string
  company?: CustomerInfo
}

export default CSSModules(CancelledSessionsExceededModal, styles)
