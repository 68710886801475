import React, { FunctionComponent } from 'react'

import styled from 'styled-components/native'

import { Image } from '../image/Image'

const CheckListIllustrationPNG = styled(Image)({
  width: '136px',
  height: '113px',
})

export const CheckListIllustration: FunctionComponent = () => {
  return <CheckListIllustrationPNG source={require('./../../assets/checklist.png')} />
}
