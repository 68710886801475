import React from 'react'
import DefaultButton, { DefaultButtonProps } from '../defaultButton/DefaultButton'

const PrimaryButton: React.FC<PrimaryButtonProps> = ({ ...props }) => {
  return <DefaultButton isPrimary {...props} />
}

type PrimaryButtonProps = DefaultButtonProps

export default PrimaryButton
