import React, { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'

import styled, { useTheme } from 'styled-components/native'

import {
  BodyText,
  BodyTextSize,
  Modal,
  PressableOpacity,
  PrimaryButton,
  Subhead,
  SubheadSize,
  TertiaryButton,
  ThemeType,
  tID,
  XIcon,
} from '@lyrahealth-inc/ui-core-crossplatform'

export type AddActivityModalProps = {
  onPrimaryButtonPress: () => void
  onRequestClose: () => void
  onTertiaryButtonPress: () => void
}

const ModalContent = styled.View<{ theme: ThemeType }>(({ theme: { breakpoints, spacing } }) => ({
  ...(!breakpoints.isMinWidthLaptop && { padding: `0px ${spacing['16px']} ${spacing['32px']}` }),
}))

const HeaderContainer = styled.View<{ theme: ThemeType }>(({ theme: { breakpoints, spacing } }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: spacing['16px'],
  ...(!breakpoints.isMinWidthLaptop && { marginTop: spacing['32px'] }),
}))

const ButtonsContainer = styled.View<{ theme: ThemeType }>(({ theme: { breakpoints, spacing } }) => ({
  flexDirection: breakpoints.isMinWidthLaptop ? 'row' : 'column-reverse',
  justifyContent: 'flex-end',
  marginTop: breakpoints.isMinWidthLaptop ? spacing['32px'] : spacing['64px'],
}))

const PrimaryButtonContainer = styled.View<{ theme: ThemeType }>(({ theme: { breakpoints, spacing } }) => ({
  ...(breakpoints.isMinWidthLaptop && { marginLeft: spacing['16px'] }),
}))

const TertiaryButtonContainer = styled.View<{ theme: ThemeType }>(({ theme: { breakpoints, spacing } }) => ({
  ...(!breakpoints.isMinWidthLaptop && { marginTop: spacing['8px'] }),
}))

export const AddActivityModal: FunctionComponent<AddActivityModalProps> = ({
  onPrimaryButtonPress,
  onRequestClose,
  onTertiaryButtonPress,
}) => {
  const { formatMessage } = useIntl()
  const { breakpoints, colors } = useTheme()

  return (
    <Modal
      disableBottomSheet
      modalContents={
        <ModalContent testID={tID('AddActivityModal')}>
          <HeaderContainer>
            <Subhead
              text={formatMessage({
                defaultMessage: 'Add activity',
                description: 'Title for a modal in the activity workflow',
              })}
              size={SubheadSize.MEDIUM}
            />
            <PressableOpacity onPress={onRequestClose} testID={tID('ActivityExitModal-close-btn')}>
              <XIcon size={24} fillColor={colors.iconDefault} />
            </PressableOpacity>
          </HeaderContainer>

          <BodyText
            text={formatMessage({
              defaultMessage: 'Would you like to save the draft and add the activity before exiting?',
              description:
                'Question asking the user if they want to save a draft and assign the activity before navigating away from the page',
            })}
            size={BodyTextSize.DEFAULT}
          />

          <ButtonsContainer>
            <TertiaryButtonContainer>
              <TertiaryButton
                fullWidth={!breakpoints.isMinWidthLaptop}
                onPress={onTertiaryButtonPress}
                text={formatMessage({
                  defaultMessage: 'Exit without adding',
                  description: 'Button to leave the page without adding the activity to the session',
                })}
                testID={tID('AddActivityModal-exit-btn')}
              />
            </TertiaryButtonContainer>
            <PrimaryButtonContainer>
              <PrimaryButton
                fullWidth={!breakpoints.isMinWidthLaptop}
                onPress={onPrimaryButtonPress}
                text={formatMessage({
                  defaultMessage: 'Add activity',
                  description: 'Button to add the activity to the session',
                })}
                testID={tID('AddActivityModal-add-btn')}
              />
            </PrimaryButtonContainer>
          </ButtonsContainer>
        </ModalContent>
      }
      onCloseEnd={onRequestClose}
      onRequestClose={onRequestClose}
      visible
    />
  )
}
