/**
 * Constants currently used for /availability API for calendar service
 */
export const SLICE_DURATION_MINUTES = 30
export const SLICE_DURATION_USER_MINUTES = 60
export const DAILY_START_TIME = '07:00:00'
export const DAILY_END_TIME = '22:00:00'
export const FOURTEEN_DAYS_TO_MS = 1.21e9
export const SIXTY_DAYS_TO_MS = 5.184e9
export const NINETY_DAYS_TO_MS = 7.776e9

export enum PHONE_TYPES {
  MOBILE = 'mobile',
  LANDLINE = 'landline',
}

export type CalendarAppointment = {
  time: string
  date: string
  timeZone: string
}

export enum BOOKING_CHANNEL_TYPE {
  CLIENT_REBOOKING = 'client_rebooking',
  DIRECT_BOOKING_LINK = 'direct_booking_link',
  DIRECT_PATH = 'direct_program_link',
  TRIAGE = 'triage',
  CNT_BOOKING = 'cnt_booking',
}
