import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

export const GuidedSelfCareSecondaryRec: FunctionComponent<GuidedSelfCareSecondaryRecProps> = ({
  width = 80,
  height = 80,
}) => {
  return (
    <Svg width={width} height={height} viewBox='0 0 81 81' fill='none'>
      <Path
        d='M41.2656 1.12109C35.7435 1.12109 30.3859 2.06804 25.3413 3.93507C13.3313 8.38685 4.88778 18.2563 2.17498 31.0125C0.382252 39.3296 0.611481 47.0222 2.88005 54.5187C5.77624 64.0926 12.8855 72.1662 22.385 76.6685C27.1008 78.9086 32.5185 80.1876 38.4848 80.469C39.2515 80.5038 40.0309 80.5227 40.8055 80.5227C45.5925 80.5227 50.4078 79.8667 55.1189 78.5735C55.4809 78.4834 55.8255 78.3901 56.1891 78.2826C60.4228 77.0432 64.2327 75.0829 67.4925 72.4634C71.0321 69.6352 73.9457 66.0497 76.1431 61.8129C76.5763 60.9782 76.9905 60.0992 77.4473 59.048C79.9609 53.2113 81.1751 46.6775 80.9601 40.1453C80.8589 37.257 80.4289 34.1869 79.6859 31.0125C78.4623 25.862 76.4672 21.0181 73.9156 16.9964C73.2169 15.9166 72.575 15.0092 71.9395 14.1934C67.3534 8.27777 60.2078 4.12636 51.2821 2.19452C48.0033 1.48312 44.6329 1.12267 41.2672 1.12267L41.2656 1.12109Z'
        fill='white'
      />
      <Path
        d='M77.5281 40.2521C77.4301 37.484 77.0064 34.6163 76.3393 31.7659C75.2485 27.1734 73.4557 22.6458 70.9974 18.7694C70.4267 17.8905 69.8418 17.0431 69.2063 16.229C64.5964 10.2848 57.6105 6.97761 50.4601 5.42993C42.4956 3.7036 34.0268 4.2079 26.3706 7.04243C15.2949 11.1464 7.75406 20.1369 5.2942 31.7011C4.34883 36.0817 4.07217 41.0331 4.49585 45.79H4.46265C4.70769 48.4933 5.19618 51.1318 5.92814 53.5584C8.64884 62.549 15.2601 69.6835 23.6815 73.6736C28.2424 75.8395 33.3391 76.9145 38.5197 77.1595C43.8125 77.4045 49.1875 76.7358 54.1563 75.3684C54.4819 75.2877 54.7918 75.2055 55.1174 75.1075C59.0096 73.9677 62.3974 72.2082 65.2968 69.8795C68.457 67.3548 71.0623 64.1788 73.0826 60.2867C73.5221 59.4393 73.9126 58.5936 74.2873 57.7288C76.6649 52.2068 77.7241 46.2136 77.5123 40.2695L77.5281 40.2537V40.2521Z'
        fill='#FFE9E3'
      />
      <Path d='M32.1516 24.8828L32.15 24.8875L32.1484 24.8828H32.1516Z' fill='#3BA5B5' />
      <Path d='M32.152 24.8789L32.1551 24.882H32.1504L32.152 24.8789Z' fill='#3BA5B5' />
      <Path
        d='M63.4426 54.7518C63.4348 55.4127 63.0051 55.9971 62.3676 56.2127C60.1316 56.969 54.8582 58.7784 51.3535 60.1752C47.2098 61.8268 43.0738 63.0159 42.3738 63.2127C42.3004 63.2331 42.227 63.2487 42.1504 63.2596C41.7332 63.3159 40.2895 63.4768 38.852 63.2424C38.852 63.2424 38.8395 63.2377 38.8145 63.2299C37.9691 62.9456 22.7801 57.8237 18.5098 56.0315C17.9223 55.7846 17.541 55.219 17.541 54.5924V23.8752C17.541 22.7752 18.6645 22.0206 19.7113 22.4143C24.9723 24.4002 38.0301 29.8721 38.8129 30.2018C38.8363 30.2112 38.8504 30.2159 38.8504 30.2159H41.9801C42.1723 30.2159 42.3629 30.1815 42.541 30.1159L61.252 23.2159C62.3004 22.8299 63.4176 23.5924 63.4098 24.6909C63.377 29.2534 63.3191 39.2909 63.4269 41.8221C63.541 44.4768 63.4723 51.9268 63.441 54.7518H63.4426Z'
        fill='#FFC31C'
      />
      <Path
        d='M60.7922 22.7325V53.7137L40.7297 61.0075C40.6313 61.0418 40.5266 61.0512 40.425 61.0294C40.4031 61.0247 40.3813 61.0184 40.3594 61.0106L20.0547 53.9434V22.3497C20.0547 21.8918 20.3719 21.4887 20.8187 21.3918C32.8156 18.7684 40.425 29.4325 40.425 29.4325C43.4953 23.1606 55.7625 21.8825 59.6234 21.6325C60.2578 21.5918 60.7922 22.0965 60.7922 22.7309V22.7325Z'
        fill='white'
      />
      <Path
        d='M60.791 22.7296V53.7109L40.7285 61.0046C40.6301 61.039 40.5254 61.0484 40.4238 61.0265V29.4312C41.4191 27.3968 43.3816 25.8874 45.7113 24.7703C47.6676 23.8296 49.8801 23.1656 51.9973 22.6984C55.1363 21.9999 58.0629 21.7328 59.6223 21.6312C60.2566 21.5906 60.791 22.0953 60.791 22.7296Z'
        fill='white'
      />
      <Path
        d='M51.9988 22.6992L51.7004 33.7711L48.8926 31.6695L45.7129 35.2945V24.7711C47.6691 23.8305 49.8816 23.1664 51.9988 22.6992Z'
        fill='#3BA5B5'
      />
      <Path
        d='M40.9473 60.1027L40.9473 30.7712C40.9473 30.4812 40.7122 30.2461 40.4222 30.2461C40.1322 30.2461 39.8971 30.4812 39.8971 30.7712L39.8971 60.1027C39.8971 60.3927 40.1322 60.6278 40.4222 60.6278C40.7122 60.6278 40.9473 60.3927 40.9473 60.1027Z'
        fill='#EDE6DE'
      />
    </Svg>
  )
}

type GuidedSelfCareSecondaryRecProps = {
  width?: number
  height?: number
}
