import React, { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'

import { useTheme } from 'styled-components/native'

import { VideoControlButton } from './VideoControlButton'
import { tID } from '../../utils'
import { MicrophoneIcon } from '../icons/MicrophoneIcon'

export type MicVideoControlButtonProps = {
  disabled: boolean
  onPress: () => void
  enabledColor?: string
  disabledColor?: string
  iconColor?: string
  size?: number
}

export const MicVideoControlButton: FunctionComponent<MicVideoControlButtonProps> = ({
  disabled,
  enabledColor,
  disabledColor,
  iconColor,
  onPress,
  size = 64,
}) => {
  const { formatMessage } = useIntl()
  const { colors } = useTheme()
  const { videoControlButton } = colors.components
  const iconSize = size / 2.6

  return (
    <VideoControlButton
      enabledColor={enabledColor || videoControlButton.enabledColor.fill}
      enabledIcon={<MicrophoneIcon fillColor={iconColor || colors.iconInverse} isFilled size={iconSize} />}
      disabledColor={disabledColor || videoControlButton.disabledColor.fill}
      disabledIcon={<MicrophoneIcon fillColor={iconColor || colors.iconInverse} isFilled size={iconSize} isMuted />}
      onPress={onPress}
      disabled={disabled}
      testID={tID('mic')}
      size={size}
      accessibilityLabel={formatMessage(
        {
          defaultMessage: 'Microphone is {enabled , select, true {enabled} other {disabled}}',
          description: 'Microphone icon button that displays if the mic is muted or not',
        },
        {
          enabled: !disabled,
        },
      )}
    />
  )
}
