import React, { FunctionComponent } from 'react'

import experienceWithCareIllustrationUrl from '../../assets/experienceWithCare.png'
import { Image } from '../image/Image'

interface ExperienceWithCareIllustrationProps {
  width?: number
  height?: number
}

export const ExperienceWithCareIllustration: FunctionComponent<ExperienceWithCareIllustrationProps> = ({
  width = 184,
  height = 125,
  ...props
}) => {
  return (
    <Image
      source={experienceWithCareIllustrationUrl}
      style={{ width, height }}
      contentFit='contain'
      accessibilityIgnoresInvertColors
      {...props}
    />
  )
}
