import React from 'react'
import styles from './skeleton.module.scss'

const Skeleton: React.FC<SkeletonProps> = ({ size }) => {
  let width = 160

  if (size) {
    switch (size) {
      case 'icon':
        width = 40
        break
      case 'small':
        width = 80
        break
      case 'medium':
        width = 160
        break
    }
  }

  const skeletonStyle = {
    width: width + 'px',
  } as React.CSSProperties

  return (
    <div style={skeletonStyle} className={styles['skeleton-container']}>
      <div className={styles['skeleton-animation']} />
    </div>
  )
}

type SkeletonProps = {
  size: 'icon' | 'small' | 'medium'
}

export default Skeleton
