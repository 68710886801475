import React, {
  useRef,
  useState,
  useEffect,
  ReactNode,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
} from 'react'
import { noop } from 'lodash-es'
import InfoIcon from '../icons/InfoIcon'
import sassStyles from './lyraPopover.module.scss'
import { usePopper } from '@lyrahealth-inc/react-popper'
import { Placement } from '@popperjs/core'
import DefaultButton from '../buttons/defaultButton/DefaultButton'
import { KEYS } from '../../constants/keyboardConstants'

const LyraPopover: ForwardRefRenderFunction<LyraPopoverHandle, LyraPopoverProps> = (
  {
    content,
    iconId,
    iconSize = 11,
    children,
    placement = 'top',
    customClass,
    onOpen = noop,
    openOnHover = false,
    keepOpenOnHover = false,
  },
  ref,
) => {
  const classes = `${sassStyles['lyra-popover']} ${customClass}`
  const [show, setShow] = useState(false)
  const popoverElement = useRef<HTMLDivElement>(null)
  const referenceElement = useRef<HTMLElement>(null)
  const { styles, attributes } = usePopper(referenceElement.current, popoverElement.current, {
    placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 5],
        },
      },
    ],
  })

  useImperativeHandle(ref, () => ({
    forceClose() {
      setShow(false)
    },
  }))

  useEffect(() => {
    const onESCKey = (e: { key: string }) => {
      if (e.key === KEYS.ESC) {
        setShow(false)
      }
    }
    const hidePopover = (event: MouseEvent) => {
      if (
        show &&
        popoverElement.current &&
        !popoverElement.current.contains(event.target as HTMLElement) &&
        referenceElement.current &&
        !referenceElement.current.contains(event.target as HTMLElement)
      ) {
        setShow(false)
      }
    }
    document.addEventListener('keydown', onESCKey)
    document.addEventListener('mousedown', hidePopover)
    return () => {
      document.removeEventListener('keydown', onESCKey)
      document.removeEventListener('mousedown', hidePopover)
    }
  }, [show])

  const onClick = () => {
    setShow(!show)
    onOpen()
  }

  const onMouseEnter = () => {
    if (openOnHover) {
      setShow(true)
      onOpen()
    }
  }

  const onMouseLeave = () => {
    if (openOnHover && !keepOpenOnHover) {
      setShow(false)
    }
  }

  return (
    <>
      <span ref={referenceElement} id={iconId} className={sassStyles['popover-icon-container']}>
        <DefaultButton unstyled onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          {children ?? <InfoIcon width={iconSize} />}
        </DefaultButton>
      </span>
      <div data-show={show} ref={popoverElement} className={classes} style={styles.popper} {...attributes.popper}>
        {content}
      </div>
    </>
  )
}

type LyraPopoverProps = {
  customClass?: string
  content: ReactNode
  iconId?: string
  placement?: Placement
  iconSize?: number
  onOpen?: () => void
  openOnHover?: boolean
  keepOpenOnHover?: boolean
}

export type LyraPopoverHandle = {
  forceClose: () => void
}

export default forwardRef(LyraPopover)
