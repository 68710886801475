import React from 'react'

export const LiveMessagingIcon: React.FC<LiveMessagingIconProps> = ({ width = 36 }) => {
  const colorBg = '#FDE5E7'
  const colorBorder = '#FFFFFF'
  const colorFill = '#F697A3'
  return (
    <svg width={width} height={width} viewBox={`0 0 108 108`} role='img' aria-label='Chat Messaging Session'>
      <g id='Client' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='icon-MsgSession-B-Copy'>
          <circle id='Oval' fill={colorBg} fillRule='nonzero' cx='54' cy='54' r='54' />
          <g id='ic_chat_filled' transform='translate(24.000000, 24.000000)'>
            <polygon id='Bounds' points='0 63 63 63 63 0.002625 0 0.002625' />
            <path
              d='M34.125,26.90625 C34.125,25.83 33.2325,24.9375 32.15625,24.9375 L12.46875,24.9375 C11.3925,24.9375 10.5,25.83 10.5,26.90625 C10.5,27.9825 11.3925,28.875 12.46875,28.875 L32.15625,28.875 C33.2325,28.875 34.125,27.9825 34.125,26.90625 M10.5,17.71875 C10.5,18.795 11.3925,19.6875 12.46875,19.6875 L32.15625,19.6875 C33.2325,19.6875 34.125,18.795 34.125,17.71875 C34.125,16.6425 33.2325,15.75 32.15625,15.75 L12.46875,15.75 C11.3925,15.75 10.5,16.6425 10.5,17.71875 M44.625,13.125 L44.625,31.5 C44.625,35.83125 41.08125,39.375 36.75,39.375 L17.85,39.375 C15.855,42 11.13,47.25 5.25,47.25 C4.8825,47.25 4.54125,47.04 4.38375,46.69875 C4.2,46.38375 4.22625,45.99 4.4625,45.675 C6.27375,43.2075 6.5625,40.76625 6.51,39.24375 C2.80875,38.5875 0,35.35875 0,31.5 L0,13.125 C0,8.79375 3.54375,5.25 7.875,5.25 L36.75,5.25 C41.08125,5.25 44.625,8.79375 44.625,13.125 M63,28.875 L63,44.625 C63,48.48375 60.19125,51.7125 56.49,52.36875 C56.4375,53.89125 56.72625,56.3325 58.5375,58.8 C58.77375,59.115 58.8,59.50875 58.61625,59.82375 C58.45875,60.165 58.1175,60.375 57.75,60.375 C51.87,60.375 47.145,55.125 45.15,52.5 L31.5,52.5 C27.16875,52.5 23.625,48.95625 23.625,44.625 L23.625,43.3125 L36.75,43.3125 C43.26,43.3125 48.5625,38.01 48.5625,31.5 L48.5625,21 L55.125,21 C59.45625,21 63,24.54375 63,28.875'
              stroke='#FFFFFF'
              strokeWidth='9'
              fill={colorBorder}
            />
            <path
              d='M34.125,26.90625 C34.125,25.83 33.2325,24.9375 32.15625,24.9375 L12.46875,24.9375 C11.3925,24.9375 10.5,25.83 10.5,26.90625 C10.5,27.9825 11.3925,28.875 12.46875,28.875 L32.15625,28.875 C33.2325,28.875 34.125,27.9825 34.125,26.90625 M10.5,17.71875 C10.5,18.795 11.3925,19.6875 12.46875,19.6875 L32.15625,19.6875 C33.2325,19.6875 34.125,18.795 34.125,17.71875 C34.125,16.6425 33.2325,15.75 32.15625,15.75 L12.46875,15.75 C11.3925,15.75 10.5,16.6425 10.5,17.71875 M44.625,13.125 L44.625,31.5 C44.625,35.83125 41.08125,39.375 36.75,39.375 L17.85,39.375 C15.855,42 11.13,47.25 5.25,47.25 C4.8825,47.25 4.54125,47.04 4.38375,46.69875 C4.2,46.38375 4.22625,45.99 4.4625,45.675 C6.27375,43.2075 6.5625,40.76625 6.51,39.24375 C2.80875,38.5875 0,35.35875 0,31.5 L0,13.125 C0,8.79375 3.54375,5.25 7.875,5.25 L36.75,5.25 C41.08125,5.25 44.625,8.79375 44.625,13.125 M63,28.875 L63,44.625 C63,48.48375 60.19125,51.7125 56.49,52.36875 C56.4375,53.89125 56.72625,56.3325 58.5375,58.8 C58.77375,59.115 58.8,59.50875 58.61625,59.82375 C58.45875,60.165 58.1175,60.375 57.75,60.375 C51.87,60.375 47.145,55.125 45.15,52.5 L31.5,52.5 C27.16875,52.5 23.625,48.95625 23.625,44.625 L23.625,43.3125 L36.75,43.3125 C43.26,43.3125 48.5625,38.01 48.5625,31.5 L48.5625,21 L55.125,21 C59.45625,21 63,24.54375 63,28.875'
              fill={colorFill}
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

type LiveMessagingIconProps = {
  width?: string | number
}
