import React, { FunctionComponent, KeyboardEvent } from 'react'
import { FieldRenderProps } from 'react-final-form'
import classNames from 'classnames'
import styles from './toggleSwitch.module.scss'
import { KEYS } from '../../../constants/keyboardConstants'
import XIcon from '../../icons/XIcon'
import CheckIcon from '../../icons/CheckIcon'

const ToggleSwitch: FunctionComponent<ToggleSwitchProps> = ({
  isOn = false,
  onLabel = '',
  offLabel = '',
  disabled = false,
  onClick = () => {},
  id = '',
  ...props
}) => {
  const onEnterKey = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === KEYS.ENTER) {
      onClick()
    }
  }
  return (
    <div
      role='switch'
      id={id}
      aria-checked={isOn}
      aria-disabled={disabled}
      tabIndex={0}
      className={classNames(styles.container, { [styles.off]: !isOn })}
      onKeyDown={onEnterKey}
      onClick={onClick}
      {...props}
    >
      {isOn
        ? onLabel || <CheckIcon isFilled width={24} fillColor={styles.x_white} withCircle={false} label='' />
        : offLabel || <XIcon isFilled width={17} fillColor={styles.x_white} style={{ marginRight: '2px' }} />}
    </div>
  )
}

export const ToggleSwitchRFF: FunctionComponent<ToggleSwitchRFFProps> = ({
  input: { value, onChange, name },
  label,
  disabled,
}) => {
  return (
    <div className={classNames(styles.RFFContainer, disabled && styles.disabled)}>
      <label className={styles.label}>{label}</label>
      <ToggleSwitch
        id={name}
        isOn={!!value}
        aria-label={name}
        disabled={disabled}
        onClick={() => !disabled && onChange(!value)}
      />
    </div>
  )
}

type ToggleSwitchProps = {
  isOn?: boolean
  disabled?: boolean
  onLabel?: string
  offLabel?: string
  onClick: () => void
  id?: string
}

interface ToggleSwitchRFFProps extends FieldRenderProps<boolean, HTMLElement> {
  label: string
  disabled: boolean
}

export default ToggleSwitch
