import { MutableRefObject, useEffect } from 'react'

import { IS_WEB } from '../constants'

interface UseSetAriaDescribedByProps {
  ariaDescribedBy: string
  ref: MutableRefObject<any>
}

export const useSetAriaDescribedBy = ({ ariaDescribedBy, ref }: UseSetAriaDescribedByProps) => {
  useEffect(() => {
    if (IS_WEB) {
      ref?.current?.setNativeProps?.({
        'aria-describedby': ariaDescribedBy,
      })
    }
  }, [ariaDescribedBy, ref])
}
