import React from 'react'
import colors from '../../../styles/_0colors.scss'
import { Icon } from './types'

const CalendarCancelIcon: React.FC<CalendarCancelIconProps> = ({
  width = 24,
  height = 26,
  fillColor = colors.x_secondary_action,
  ...props
}) => {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${width}`} xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16 2H15V0H13V2H5V0H3V2H2C0.9 2 0 2.9 0 4V18C0 19.1 0.9 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM16 18H2V8H16V18ZM2 6V4H16V6H2ZM5.23 15.41L6.29 16.47L8.73 14.03L11.17 16.47L12.23 15.41L9.79 12.97L12.23 10.53L11.17 9.47L8.73 11.91L6.29 9.47L5.23 10.53L7.67 12.97L5.23 15.41Z'
        fill={fillColor}
      />
    </svg>
  )
}

type CalendarCancelIconProps = Icon

export default CalendarCancelIcon
