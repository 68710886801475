import React, { Dispatch, SetStateAction, useEffect } from 'react'
import ReactMarkdown from 'react-markdown'

import classNames from 'classnames'
import { get, isEmpty } from 'lodash-es'
import moment from 'moment'
import { DeepPartial } from 'redux'

import { Assignment, AssignmentResponse, Content } from '@lyrahealth-inc/shared-app-logic'

import styles from './formContainer.module.scss'
import NavButton from '../../atoms/buttons/navButton/NavButton'
import dateUtils from '../../utils/dateUtils'
import { LinkRenderer } from '../formBody/fields'
import FormBody from '../formBody/FormBody'
import FormContentHeader from '../formContentHeader/FormContentHeader'
import Comment from '../messaging/Comment'

type Props = {
  readOnly?: boolean
  preview?: boolean
  responseIdx?: number
  dataLength?: number
  showPrevious?: () => void
  showNext?: () => void
  response?: AssignmentResponse
  fetchComment?: ({ messageId }: { messageId: string }) => Promise<any>
  receiverName?: string
  userRole?: 'provider' | 'client'
  submitComment?: (inputValue: string, response: AssignmentResponse) => Promise<any>
  content?: Content | Assignment
  submitForm?: ($$values: object, status: string, responseId: string) => void
  deleteResponse?: (responseId: string, assignmentId: string) => void
  updateResponse?: (data: DeepPartial<AssignmentResponse>) => void
  formContext?: Dict
  onClickBack?: () => void
  backButtonText?: string
  supervisorMode?: boolean
  autoSave?: boolean
  setModalOpen?: Dispatch<SetStateAction<boolean>>
  Header?: React.ReactNode
  fullScreen?: boolean
}

const FormContainer = ({
  responseIdx,
  dataLength,
  response,
  submitForm,
  fetchComment,
  receiverName,
  userRole,
  submitComment,
  showNext,
  readOnly,
  preview,
  showPrevious,
  formContext,
  onClickBack,
  backButtonText,
  content,
  updateResponse,
  deleteResponse,
  supervisorMode = false,
  autoSave = true,
  setModalOpen,
  Header = FormContentHeader,
  fullScreen = false,
}: Props) => {
  useEffect(() => {
    if (!supervisorMode && !response?.viewed && updateResponse && userRole === 'provider' && !isEmpty(response)) {
      updateResponse({ ...response, viewed: true })
    }
  }, [response, supervisorMode, updateResponse, userRole])

  const isDraft = response?.status === 'draft'
  let date = response?.submit_date || ''
  if (isDraft) date = response.update_date
  const friendlyDate =
    moment().diff(moment(date), 'minutes', true) < 1 ? 'just now' : dateUtils.getFriendlyDate(date, false, true)
  const dateDisplay = isDraft ? `Draft saved ${friendlyDate}` : `Submitted ${friendlyDate}`
  const draftId = response?.id || ''
  const assignmentId = response?.assignment_id || ''
  const group = content?.group || get(content, 'content.group')
  const title = content?.title || get(content, 'content.title')
  const isExerciseAssessment = group === 'assessment' || group === 'exercise'
  const instructions = content?.instructions || get(content, 'content.instructions')

  return (
    // @ts-expect-error TS(2604): JSX element type 'Header' does not have any constr... Remove this comment to see the full error message
    <Header
      isDraft={isDraft}
      deleteDraft={deleteResponse ? () => deleteResponse(draftId, assignmentId) : undefined}
      responseIdx={responseIdx}
      dataLength={dataLength}
      group={group}
      title={title}
      date={date ? dateDisplay : null}
      showPrevious={showPrevious}
      showNext={showNext}
      setModalOpen={setModalOpen}
    >
      <Comment
        response={response}
        fetchComment={fetchComment}
        receiverName={receiverName}
        userRole={userRole}
        submitHandler={submitComment}
      />
      {onClickBack ? <NavButton text={backButtonText || 'Back'} styleType='back' onClick={onClickBack} /> : ''}
      {instructions && (
        <div className={classNames(styles.instructions, { [styles['full-screen-instructions']]: fullScreen })}>
          {isExerciseAssessment && <h3>Introduction</h3>}
          {/* eslint-disable-next-line react/no-children-prop */}
          <ReactMarkdown children={instructions} components={{ link: LinkRenderer }} />
        </div>
      )}
      <div className={fullScreen ? styles['full-form'] : ''}>
        <FormBody
          key={response?.submit_date}
          userRole={userRole}
          preview={preview}
          readOnly={readOnly}
          content={content}
          response={response}
          submitForm={submitForm}
          formContext={formContext}
          autoSave={autoSave}
        />
      </div>
    </Header>
  )
}

export default FormContainer
