import React from 'react'
import CSSModules from 'react-css-modules'

import PropTypes from 'prop-types'

import styles from './displayPracticeInfo.module.scss'
import { paymentPreferences } from '../../../common/constants/appConstants'

const DisplayPracticeInfo = ({ data }: any) => (
  <div styleName='content-container'>
    <div styleName='section'>
      <h3>Practice Info</h3>

      <div className='row'>
        <div className='col-xs-12 col-sm-6'>
          <div styleName='field-label'>Practice Name</div>
          <div>{data.practice_name}</div>
        </div>
        <div className='col-xs-12 col-sm-6'>
          <div styleName='field-label'>Practice Admin First Name</div>
          <div>{data.first_name}</div>
        </div>
        <div className='col-xs-12 col-sm-6'>
          <div styleName='field-label'>Practice Admin Last Name</div>
          <div>{data.last_name}</div>
        </div>
        <div className='col-xs-12 col-sm-6'>
          <div styleName='field-label'>Date of Birth</div>
          <div>{data.date_of_birth}</div>
        </div>
      </div>
    </div>

    <div styleName='section'>
      <h3>Contact Info</h3>

      <div className='row'>
        <div className='col-xs-12 col-sm-6'>
          <div styleName='field-label'>Practice Email</div>
          <div>{data.email}</div>
        </div>
        <div className='col-xs-12 col-sm-6'>
          <div styleName='field-label'>Practice Phone Number</div>
          <div>{data.phone}</div>
        </div>
        <div className='col-xs-12 col-sm-6'>
          <div styleName='field-label'>Street Address</div>
          <div>{data.street}</div>
        </div>
        <div className='col-xs-12 col-sm-6'>
          <div styleName='field-label'>City</div>
          <div>{data.city}</div>
        </div>
        <div className='col-xs-12 col-sm-6'>
          <div styleName='field-label'>State</div>
          <div>{data.state}</div>
        </div>
        <div className='col-xs-12 col-sm-6'>
          <div styleName='field-label'>Zip Code</div>
          <div>{data.zip_code}</div>
        </div>
      </div>
    </div>

    <div styleName='section'>
      <h3>Bank Info</h3>

      <div className='row'>
        <div className='col-xs-12 col-sm-6'>
          <div styleName='field-label'>Payment Preference</div>
          <div>{paymentPreferences[data.payment_preference]}</div>
        </div>
        <div className='col-xs-12 col-sm-6'>
          <div styleName='field-label'>Bank Name</div>
          <div>{data.bank}</div>
        </div>
        <div className='col-xs-12 col-sm-6'>
          <div styleName='field-label'>TIN Or EIN Number</div>
          <div>{data.account_identifier}</div>
        </div>
        <div className='col-xs-12 col-sm-6'>
          <div styleName='field-label'>Routing Number</div>
          <div>{data.routing_number}</div>
        </div>
        <div className='col-xs-12 col-sm-6'>
          <div styleName='field-label'>Account Number</div>
          <div>{data.account_number}</div>
        </div>
        <div className='col-xs-12 col-sm-6'>
          <div styleName='field-label'>Name on account (for tax purposes)</div>
          <div>{data.account_name}</div>
        </div>
        <div className='col-xs-12 col-sm-6'>
          <div styleName='field-label'>Last 4 Digits of SSN</div>
          <div>{data.ssn_last_4}</div>
        </div>
      </div>
    </div>
  </div>
)

DisplayPracticeInfo.propTypes = {
  data: PropTypes.object,
}

export default CSSModules(DisplayPracticeInfo, styles, { allowMultiple: true })
