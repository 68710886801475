import React, { FunctionComponent } from 'react'

import { tID } from '../../utils/utils'
import { Image } from '../image/Image'

type VisitWebsiteIllustrationProps = {
  width?: number
  height?: number
}

export const VisitWebsiteIllustration: FunctionComponent<VisitWebsiteIllustrationProps> = ({
  width = 239,
  height = 157,
}) => {
  return (
    <Image
      testID={tID('VisitWebsiteIllustration')}
      source={require('../../assets/visitWebsite.png')}
      style={{ width, height }}
      accessibilityIgnoresInvertColors
    />
  )
}
