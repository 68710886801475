import React from 'react'
import colors from '../../../styles/_0colors.scss'

type OwnProps = {
  width?: string | number
  fillColor?: string
}

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof MedicineIcon.defaultProps

// @ts-expect-error TS(7022): 'MedicineIcon' implicitly has type 'any' because i... Remove this comment to see the full error message
const MedicineIcon = ({ width, fillColor }: Props) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={width} viewBox='0 0 109 108'>
      <g fill='none' fillRule='evenodd' transform='translate(-12 -21)'>
        <polygon points='0 7 140 7 140 148 0 148' />
        <g transform='scale(1 -1) rotate(-45 -70.943 -33.685)'>
          <rect width='110' height='50' y='1' stroke={fillColor} strokeWidth='3' rx='25' />
          <path
            fill={fillColor}
            fillRule='nonzero'
            d='M55,0.999999998 L55,51 L25,51 C11.1928813,51 0,39.8071186 0,26 C0,12.1928813 11.1928813,0.999999998 25,0.999999998 L55,0.999999998 Z M6.99999999,25 C6.17157287,25 5.49999999,25.6715729 5.49999999,26.5 C5.49999999,37.5456949 14.454305,46.5 25.5,46.5 L39.5,46.5 C40.328427,46.5 41,45.828427 41,45 C41,44.1715728 40.328427,43.5 39.5,43.5 L25.5,43.5 C16.1111593,43.5 8.49999999,35.8888406 8.49999999,26.5 C8.49999999,25.6715729 7.82842711,25 6.99999999,25 Z'
          />
          <g stroke={fillColor} strokeWidth='3' transform='translate(28)'>
            <line x1='27.5' x2='27.5' y1='.5' y2='50.5' />
            <line x1='27.587' x2='27.587' y1='63.644' y2='83.644' strokeLinecap='round' />
            <line x1='14.658' x2='.516' y1='66.573' y2='80.715' strokeLinecap='round' />
            <line x1='40.516' x2='54.658' y1='66.573' y2='80.715' strokeLinecap='round' />
          </g>
        </g>
      </g>
    </svg>
  )
}

MedicineIcon.defaultProps = {
  width: 180,
  fillColor: colors.x_secondary_action,
}

export default MedicineIcon
