import React from 'react'
import CSSModules from 'react-css-modules'
import styles from './selfHarmIndicator.module.scss'
import { WarningIcon } from '@lyrahealth-inc/ui-core'

const SelfHarmIndicator = () => {
  return (
    <div styleName='self-harm'>
      <WarningIcon fillColor={styles.x_alert} width={12} /> Self harm
    </div>
  )
}

export default CSSModules(SelfHarmIndicator, styles)
