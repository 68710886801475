/* eslint-disable formatjs/no-literal-string-in-jsx */

import React, { FunctionComponent, MutableRefObject, ReactElement, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'

import { format, parseISO } from 'date-fns'
import { isEmpty } from 'lodash-es'
import { css } from 'styled-components'
import styled, { useTheme } from 'styled-components/native'

import { FieldSchema, Note, NoteStatusStates } from '@lyrahealth-inc/shared-app-logic'

import { CheckIcon, Headline, LoadingIndicator, TextButton } from '../../atoms'
import { BodyText, Size as BodyTextSize } from '../../atoms/bodyText/BodyText'
import { ExclamationIcon } from '../../atoms/icons/ExclamationIcon'
import { IS_WEB } from '../../constants'
import { HeadlineSize } from '../../styles'
import { ThemeType } from '../../utils'
import { tID } from '../../utils/utils'
import { FormBody, FormBodyCustomStyles, FormButtonParams } from '../formBody/FormBody'
import { UICoreFormContext } from '../formBody/types'

export type NoteContainerProps = {
  note: Note
  showSubmit: boolean
  submitButton: ({ handleSubmit, loading }: FormButtonParams) => ReactElement
  saveForm: ({ values, formSubmit }: { values: Dict; formSubmit: boolean }) => void
  formContext?: UICoreFormContext
  noteIsBeingAutoSaved: boolean
  autoSaveFailed: boolean
  asyncSaveForm: ({ values, formSubmit }: { values: Dict; formSubmit: boolean }) => Promise<void>
  noteRef?: MutableRefObject<any>
  providerName?: string
}

const Header = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  backgroundColor: theme.colors.backgroundPrimary,
  paddingLeft: theme.spacing['24px'],
  paddingTop: theme.spacing['32px'],
  paddingBottom: theme.spacing['24px'],
  borderBottomWidth: '1px',
  ...(IS_WEB && { borderBottomStyle: 'solid' }),
  borderBottomColor: `${theme.colors.borderDefault}`,
  borderTopLeftRadius: theme.spacing['16px'],
  borderTopRightRadius: theme.spacing['16px'],
}))

const DateContainer = styled.View(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  paddingTop: theme.spacing['8px'],
}))

const Date = styled(BodyText)(({ theme }) => ({
  marginLeft: theme.spacing['8px'],
}))

const FormContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  height: '100%',
  borderWidth: '1px',
  borderColor: theme.colors.borderDefault,
  borderRadius: theme.spacing['16px'],
}))

export const NoteContainer: FunctionComponent<NoteContainerProps> = ({
  note,
  showSubmit,
  submitButton,
  saveForm,
  asyncSaveForm,
  formContext,
  noteIsBeingAutoSaved,
  autoSaveFailed,
  noteRef,
  providerName,
}) => {
  const intl = useIntl()
  const { colors } = useTheme()

  const [readOnly, date] = useMemo(() => {
    switch (note?.status) {
      case NoteStatusStates.COMPLETED:
        return [
          true,
          note?.submit_date
            ? `Submitted on ${format(parseISO(note?.submit_date), 'MM/d/yy')} at ${format(
                parseISO(note?.submit_date),
                'hh:mm a',
              )}`
            : '',
        ]
      case NoteStatusStates.DRAFT:
        return [
          false,
          `Saved at ${format(parseISO(note?.update_date), 'MM/d/yy')} at ${format(
            parseISO(note?.update_date),
            'hh:mm a',
          )}`,
        ]
      default:
        return [false, null]
    }
  }, [note])
  const initialNote = useRef<Note>(note)
  const updatedNoteValues = useRef<Dict>({})
  const [isManuallySubmitting, setIsManuallySubmitting] = useState(false)

  const renderDateContainer = () => {
    return (
      <DateContainer testID={tID('NoteContainer-date-container')}>
        {!isEmpty(date) && (
          <>
            {noteIsBeingAutoSaved ? (
              <LoadingIndicator size={24} topPadding={0} />
            ) : autoSaveFailed ? (
              <ExclamationIcon size={24} />
            ) : (
              <CheckIcon size={24} />
            )}
            <Date
              size={BodyTextSize.SMALL}
              color={colors.textSecondary}
              text={
                noteIsBeingAutoSaved || isManuallySubmitting
                  ? 'Saving note'
                  : autoSaveFailed
                  ? "We're having trouble saving"
                  : date
              }
            />
            {!noteIsBeingAutoSaved && !isManuallySubmitting && (
              <TextButton
                text={autoSaveFailed ? 'Try again' : 'Save note'}
                onPress={() => {
                  setIsManuallySubmitting(true)
                  asyncSaveForm({ values: updatedNoteValues.current, formSubmit: false }).then(() => {
                    setIsManuallySubmitting(false)
                  })
                }}
                disabled={
                  isManuallySubmitting ||
                  note.status === NoteStatusStates.COMPLETED ||
                  isEmpty(updatedNoteValues.current)
                }
                style={{ marginTop: '3px', marginLeft: '8px' }}
                testID={tID('NoteContainer-manual-save')}
              />
            )}
          </>
        )}
      </DateContainer>
    )
  }

  return (
    <>
      <FormContainer testID={tID('NoteContainer')}>
        <Header>
          <Headline
            size={HeadlineSize.MEDIUM}
            text={note?.content_meta_data?.title}
            style={{ fontSize: 38 }}
            testID={tID('NoteContainer-title')}
          />
          {renderDateContainer()}
        </Header>
        <FormBody
          intl={intl}
          readOnly={readOnly}
          formContext={{ useMultiSelectTypeAhead: true, saveSingleSelectFieldsAsArray: true, ...formContext }}
          schema={initialNote.current?.content_meta_data?.meta_data?.schema as FieldSchema}
          uiSchema={initialNote.current?.content_meta_data?.meta_data?.uiSchema}
          name={initialNote.current?.content_meta_data?.name}
          initialValues={{
            ...initialNote.current?.content_meta_data?.meta_data?.initialValues,
            ...initialNote.current,
            ...initialNote.current?.body,
            ...{ declarationProviderName: providerName },
          }}
          formButton={showSubmit ? submitButton : undefined}
          saveForm={saveForm}
          onFormChange={({ values, formSubmit }: { values: Dict; formSubmit: boolean }) => {
            saveForm({ values, formSubmit })
            updatedNoteValues.current = values
          }}
          withPageBreaks={false}
          formBodyCustomStyles={
            {
              submitButtonContainer: {
                borderTopWidth: 0,
                padding: 0,
                width: '100%',
                boxShadow: 'none',
                borderBottomLeftRadius: '16px',
                borderBottomRightRadius: '16px',
              },
              submitButtonWrapper: { maxWidth: 'none', marginLeft: '40px', marginBottom: '20px' },
            } as unknown as FormBodyCustomStyles
          }
          scrollContainerCustomStyles={{
            formBodyPageContainer: css`
              max-width: none;
            `,
          }}
          formRef={noteRef}
        />
      </FormContainer>
      {!isEmpty(date) && renderDateContainer()}
    </>
  )
}
