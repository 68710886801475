import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { tID } from '../../utils'

export interface SparklesIconProps {
  size?: number | string
  color?: string
  filled?: boolean
}

export const SparklesIcon: FunctionComponent<SparklesIconProps> = ({ size = 24, color, filled = true, ...props }) => {
  const { colors } = useTheme()
  const fillColor = filled ? color || colors.iconAccent : colors.backgroundPrimary

  return (
    <Svg testID={tID('SparklesIcon')} width={size} height={size} viewBox='0 0 24 24' fill='none' {...props}>
      <Path
        d='M10.91 21.8298C10.5 21.8298 10.16 21.4898 10.16 21.0798C10.16 17.5998 7.22 14.6698 3.75 14.6698C3.34 14.6698 3 14.3298 3 13.9198C3 13.5098 3.34 13.1698 3.75 13.1698C7.23 13.1698 10.16 10.2298 10.16 6.75977C10.16 6.34977 10.5 6.00977 10.91 6.00977C11.32 6.00977 11.66 6.34977 11.66 6.75977C11.66 10.2398 14.6 13.1698 18.07 13.1698C18.48 13.1698 18.82 13.5098 18.82 13.9198C18.82 14.3298 18.48 14.6698 18.07 14.6698C14.59 14.6698 11.66 17.6098 11.66 21.0798C11.66 21.4898 11.32 21.8298 10.91 21.8298Z'
        fill={color || colors.iconAccent}
      />
      <Path
        d='M7.05 13.9198C8.73 14.7198 10.11 16.0998 10.91 17.7798C11.71 16.0998 13.09 14.7198 14.77 13.9198C13.09 13.1198 11.71 11.7398 10.91 10.0598C10.11 11.7398 8.73 13.1198 7.05 13.9198Z'
        fill={fillColor}
      />
      <Path
        d='M18.082 10.5C17.672 10.5 17.332 10.16 17.332 9.75C17.332 8.55 16.282 7.5 15.082 7.5C14.672 7.5 14.332 7.16 14.332 6.75C14.332 6.34 14.672 6 15.082 6C16.282 6 17.332 4.95 17.332 3.75C17.332 3.34 17.672 3 18.082 3C18.492 3 18.832 3.34 18.832 3.75C18.832 4.95 19.882 6 21.082 6C21.492 6 21.832 6.34 21.832 6.75C21.832 7.16 21.492 7.5 21.082 7.5C19.882 7.5 18.832 8.55 18.832 9.75C18.832 10.16 18.492 10.5 18.082 10.5Z'
        fill={color || colors.iconAccent}
      />
      <Path
        d='M17.302 6.75C17.602 6.97 17.862 7.24 18.082 7.53C18.302 7.23 18.572 6.97 18.862 6.75C18.562 6.53 18.302 6.26 18.082 5.97C17.862 6.27 17.592 6.53 17.302 6.75Z'
        fill={fillColor}
      />
    </Svg>
  )
}
