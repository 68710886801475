import React, { FunctionComponent } from 'react'

import { ReactNativeZoomableView } from '@openspacelabs/react-native-zoomable-view'
import styled, { useTheme } from 'styled-components/native'

import { AvatarDetails } from '../../atoms'
import { useZoom } from '../../hooks/useZoom'
import { ZoomParticipantView } from '../../molecules/zoomParticipantView/ZoomParticipantView'
import { ZoomScreenshareView } from '../../molecules/zoomScreenshareView/ZoomScreenshareView'
import { ThemeType } from '../../utils'

const Container = styled.View({
  height: '100%',
  width: '100%',
  flexGrow: 1,
})

const InnerContainer = styled.View({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: -1,
  alignItems: 'center',
  justifyContent: 'center',
})

export type ZoomVideoSessionMobileViewProps = {
  participantName: string
  avatarDetails?: AvatarDetails
  participantTitle?: string
  participantFirstName?: string
  landscape: boolean
  onPress?: () => void
}

export const ZoomVideoSessionMobileView: FunctionComponent<ZoomVideoSessionMobileViewProps> = ({
  participantName,
  participantFirstName,
  avatarDetails,
  participantTitle,
  landscape,
  onPress,
}) => {
  const theme = useTheme() as ThemeType
  const { remoteParticipantScreenSharing } = useZoom()

  return (
    <Container>
      <ReactNativeZoomableView
        maxZoom={10}
        minZoom={1}
        style={{ backgroundColor: theme.colors.components.zoomVideoSession.background, justifyContent: 'center' }}
        onStartShouldSetPanResponder={() => {
          onPress?.()
          return true
        }}
      >
        <InnerContainer pointerEvents='none'>
          <ZoomScreenshareView landscape={landscape} />
          <ZoomParticipantView
            isFullscreen
            avatarDetails={avatarDetails}
            participantName={participantName}
            participantTitle={participantTitle}
            participantFirstName={participantFirstName}
            landscape={landscape}
            show={!remoteParticipantScreenSharing}
          />
        </InnerContainer>
      </ReactNativeZoomableView>
    </Container>
  )
}
