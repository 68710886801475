import { defineMessages } from 'react-intl'

export const HEALTH_PLAN_INFO_MESSAGES = defineMessages({
  profileHeader: {
    defaultMessage: 'Health plan information',
    description: 'Health plan info - Profile header text',
  },
  primaryHealthPlanHeader: {
    defaultMessage: 'Primary health plan',
    description: 'Health plan info - Profile header text',
  },
  secondaryHealthPlanHeader: {
    defaultMessage: 'Secondary health plan',
    description: 'Health plan info - Profile header text',
  },
  confirmPlanHeader: {
    defaultMessage: 'Confirm health plan',
    description: 'Health plan info - Confirm health plan header text',
  },
  newPlanHeader: {
    defaultMessage: 'Add a health plan',
    description: 'Health plan info - Add health plan header text',
  },
  subHeader: {
    defaultMessage: 'Enter the information exactly as it appears on your insurance ID card.',
    description: 'Health plan info - SubHeader text',
  },
  subHeader2: {
    defaultMessage:
      'You may be responsible for co-pays, co-insurances, and deductibles for Lyra services covered by your health plan. <link>Learn more</link>.',
    description: 'Health plan info - SubHeader line 2 text',
  },
  firstName: {
    defaultMessage: 'First name',
    description: 'Health plan info - Legal first name label',
  },
  lastName: {
    defaultMessage: 'Last name',
    description: 'Health plan info - Legal last name label',
  },
  healthPlanPicker: {
    defaultMessage: 'Health plan',
    description: 'Health plan info - Health plan picker label',
  },
  primaryHealthPlanPickerTitle: {
    defaultMessage: 'Primary health plan',
    description: 'Health plan info - Health plan picker label',
  },
  secondaryHealthPlanPickerTitle: {
    defaultMessage: 'Secondary health plan',
    description: 'Health plan info - Health plan picker label',
  },
  primaryHealthPlanPickerSubtitle: {
    defaultMessage: 'Select the main insurance plan covering your health care needs.',
    description: 'Health plan info - Health plan picker label',
  },
  secondaryHealthPlanPickerSubtitle: {
    defaultMessage: 'Select a second insurance plan that covers your health care needs.',
    description: 'Health plan info - Health plan picker label',
  },
  profilePageHealthPlanPickerSubtitle: {
    defaultMessage: 'Please add your information exactly as it appears on your insurance card.',
    description: 'Health plan info - Health plan picker label',
  },
  dobWeb: {
    defaultMessage: 'Date of birth',
    description: 'Health plan info - Date of birth web label',
  },
  dobNative: {
    defaultMessage: 'Date of birth (MM/DD/YYYY)',
    description: 'Health plan info - Date of birth native label',
  },
  memberId: {
    defaultMessage: 'Member or subscriber ID',
    description: 'Health plan info - Member or subscriber ID label',
  },
  save: {
    defaultMessage: 'Save',
    description: 'Health plan info - Save button text',
  },
  next: {
    defaultMessage: 'Next',
    description: 'Health plan info - Next button text',
  },
  continue: {
    defaultMessage: 'Continue',
    description: 'Health plan info - Continue button text',
  },
  remove: {
    defaultMessage: 'Remove',
    description: 'Health plan info - Remove button text',
  },
  healthPlanPickerTooltip: {
    defaultMessage: 'We check your health plan information to determine your coverage and recommend care options.',
    description: 'Health plan info - Health plan picker tooltip content',
  },
  memberIdTooltip: {
    defaultMessage: 'The Member ID Number is listed on the left side of your medical plan card.',
    description: 'Health plan info - Member id tooltip content',
  },
  primaryHeader: {
    defaultMessage: 'Let’s get started',
    description: 'Header text for a page to enter health plan information',
  },
  secondaryHeader: {
    defaultMessage: 'Add a secondary health plan',
    description: 'Header text for a page to enter health plan information',
  },
  primarySubheader: {
    defaultMessage:
      'To connect you with quality care, we will first check your health plan to determine your coverage. Cost-sharing may apply depending on your plan. <link>Learn more</link>.',
    description: 'Subheader text for a page to enter health plan information',
  },
  secondarySubheader: {
    defaultMessage:
      'Let us know if you are insured by more than one health plan so that we can determine your coverage and recommend different care options. Cost-sharing may apply depending on your plan. <link>Learn more</link>.',
    description: 'Subheader text for a page to enter health plan information',
  },
  costSharingSubheader: {
    defaultMessage: 'Cost-sharing may apply depending on your plan. <link>Learn more</link>.',
    description:
      'Subheader text for a page to enter health plan information. Contains a link for copay/coinsurance terms and conditions',
  },
  secondaryHealthPlanQuestion: {
    defaultMessage: 'Are you also covered by a secondary health plan? []()',
    description: 'Text above a yes/no radio button group asking the user if they have a secondary health plan',
  },
  secondaryHealthPlanTooltipHeader: {
    defaultMessage: 'What is a secondary health plan?',
    description: 'Header text for tooltip describing what a secondary health plan is',
  },
  secondaryHealthPlanTooltipContent: {
    defaultMessage:
      'You may have more than one health plan if you are covered by an employer, school, or family member. ',
    description: 'Content text for tooltip describing what a secondary health plan is',
  },
  secondaryHealthPlanWarning: {
    defaultMessage:
      'If you don’t have a secondary health plan, your primary health plan must be sponsored by the organization that grants you access to Lyra.',
    description: 'Banner message displayed as a warning to inform the user they must have a Lyra-supported health plan',
  },
  healthPlanTypeLabel: {
    defaultMessage: 'Type',
    description: 'Label text of a health plan dropdown list',
  },
  otherHealthPlan: {
    defaultMessage: 'Other',
    description: 'Text displayed when the user selects in a dropdown list that they can’t find their health plan',
  },
  noSecondaryHealthPlan: {
    defaultMessage: 'I don’t have a secondary health plan',
    description: 'Button text for a user to opt out of adding a secondary health plan',
  },
  healthPlanSelectorLabel: {
    defaultMessage: 'Select a health plan',
    description: 'Label of a search field that can be used to select a health plan',
  },
  secondaryHealthPlanDescription: {
    defaultMessage: 'A secondary health insurance plan may be able to cover expenses that your primary plan doesn’t.',
    description: 'Subheader text on a profile page where a user can add a secondary health plan',
  },
  primary: {
    defaultMessage: 'Primary',
    description: 'Part of a header that shows a health plan and specifies that it is a primary health plan',
  },
  secondary: {
    defaultMessage: 'Secondary',
    description: 'Part of a header that shows a health plan and specifies that it is a secondary health plan',
  },
  healthPlanRemovalHeader: {
    defaultMessage: 'Are you sure?',
    description: 'Header of a modal for the user to confirm removing their health plans',
  },
  healthPlanRemovalSubheader: {
    defaultMessage:
      'You must have a Lyra-integrated health plan present. Removing this will automatically remove both health plans.',
    description: 'Subheader of a modal for the user to confirm removing their health plans',
  },
})
