import React, { useState } from 'react'

import styled from 'styled-components/native'

import { appointmentUtilization, targetUtilization, UtilizationCharges } from '@lyrahealth-inc/shared-app-logic'
import {
  BodyText,
  BodyTextSize,
  colors,
  DropdownButton,
  ProgressBar,
  Subhead,
  SubheadSize,
  ThemeType,
  tID,
} from '@lyrahealth-inc/ui-core-crossplatform'

import { track } from '../../../../mixpanel/mixpanelTracking'

const HeaderContainer = styled.View({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  zIndex: 1,
})

const ProgressBarContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['24px'],
}))

const InfoContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['24px'],
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}))

export const QuarterlyProgressChart: React.FC<QuarterlyProgressChartProps> = ({
  appointmentUtilization: { completedThisQuarter = 0, providerNoFaultThisQuarter = 0 } = {},
  targetUtilization: { quarterSessionTarget = 0, quarterSessionTargetPlus = 0 } = {},
  utilizationCharges,
}) => {
  const targetLevels = { TARGET: 'Target', TARGET_PLUS: 'Target Plus' }

  const [selectedTarget, setTargetLevel] = useState({
    targetLevel: targetLevels.TARGET,
    quarterTarget: quarterSessionTarget,
  })
  const completedAndNoFault =
    completedThisQuarter +
    providerNoFaultThisQuarter +
    (utilizationCharges?.completedThisQuarter ?? 0) +
    (utilizationCharges?.providerNoFaultThisQuarter ?? 0)
  const quarterTargetStatus = completedAndNoFault - selectedTarget.quarterTarget
  const belowTargetPercentage = completedAndNoFault / selectedTarget.quarterTarget
  const quarterTargetProgress = Math.min(belowTargetPercentage, 1)

  return (
    <div>
      <HeaderContainer>
        <Subhead text={'Quarterly progress'} size={SubheadSize.MEDIUM} color={colors.ui_oatmeal6} />
        <DropdownButton
          buttonTextWidth={'105px'}
          customButtonText={`To ${selectedTarget.targetLevel}`}
          dropdownWidth={'150px'}
          testID={tID('QuarterlyProgressChart-dropdownButton')}
          dropdownItems={[
            {
              text: targetLevels.TARGET,
              id: '0',
              selectHandler: () => {
                setTargetLevel({ targetLevel: targetLevels.TARGET, quarterTarget: quarterSessionTarget })
                track({ event: 'CHANGE_QUARTERLY_PROGRESS_TARGET', details: targetLevels.TARGET })
              },
            },
            {
              text: targetLevels.TARGET_PLUS,
              id: '1',
              selectHandler: () => {
                setTargetLevel({ targetLevel: targetLevels.TARGET_PLUS, quarterTarget: quarterSessionTargetPlus })
                track({ event: 'CHANGE_QUARTERLY_PROGRESS_TARGET', details: targetLevels.TARGET_PLUS })
              },
            },
          ]}
        />
      </HeaderContainer>
      <ProgressBarContainer>
        <ProgressBar
          progress={quarterTargetProgress}
          color={colors.teal5}
          backgroundColor={colors.ui_oatmeal2}
          width={-1}
          height={8}
        />
      </ProgressBarContainer>

      <InfoContainer>
        <BodyText
          color={colors.ui_oatmeal5}
          size={BodyTextSize.DEFAULT}
          text={
            completedAndNoFault > selectedTarget.quarterTarget
              ? `Above ${selectedTarget.targetLevel} by ${quarterTargetStatus} sessions`
              : `${Math.abs(quarterTargetStatus)} sessions remaining for the quarter to hit the total ${
                  selectedTarget.targetLevel
                } number of sessions`
          }
        />
        <BodyText
          size={BodyTextSize.DEFAULT}
          text={`${completedAndNoFault}/${selectedTarget.quarterTarget} credited sessions`}
          color={colors.ui_oatmeal5}
          testID={tID('QuarterlyProgressChart-numberOfCreditedSessions')}
        />
      </InfoContainer>
    </div>
  )
}

type QuarterlyProgressChartProps = {
  appointmentUtilization: appointmentUtilization
  targetUtilization?: targetUtilization
  utilizationCharges?: UtilizationCharges
}

export default QuarterlyProgressChart
