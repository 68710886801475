import { createReducer } from '@reduxjs/toolkit'

import { ClientObject } from '@lyrahealth-inc/shared-app-logic'

import {
  CLEAR_LT_CLIENT_DATA,
  DECREASE_SELECTED_CLIENT_UNREAD_COUNT,
  LT_SELECTED_CLIENT,
} from '../../../../common/constants/reduxConstants'

type ClientDetailsDataState = ClientObject | null
const initialState = null

export default createReducer<ClientDetailsDataState>(initialState, (builder) => {
  builder.addCase(CLEAR_LT_CLIENT_DATA, () => initialState)
  builder.addCase(LT_SELECTED_CLIENT, (_, action: any) => {
    return action.data
  })
  builder.addCase(DECREASE_SELECTED_CLIENT_UNREAD_COUNT, (state) => {
    if (!state) {
      return state
    }
    const unreadCount = state.unread_responses
    state.unread_responses = unreadCount - 1
    return state
  })
})
