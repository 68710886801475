import React, { FunctionComponent } from 'react'
import Svg, { Circle, Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { tID } from '../../utils/utils'

export interface CheckMarkInCircleIconProps {
  size?: number
  fillColor?: string
  filled?: boolean
}

export const CheckMarkInCircleIcon: FunctionComponent<CheckMarkInCircleIconProps> = ({
  size = 28,
  fillColor,
  filled = false,
}) => {
  const { colors } = useTheme()
  const { checkMarkInCircleIcon } = colors.icons
  return filled ? (
    <Svg testID={tID('CheckMarkInCircleIcon')} width={size} height={size} viewBox='0 0 28 27' fill='none'>
      <Circle cx='12.0002' cy='12' r='10.5' fill='#429572' />
      <Path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M9.83315 16.125C9.58781 16.125 9.34247 16.0317 9.15591 15.8439L6.2808 12.9688C5.9064 12.5944 5.9064 11.9887 6.2808 11.6143C6.6552 11.2399 7.26089 11.2399 7.6353 11.6143L9.83315 13.8109L15.8645 7.7808C16.2389 7.4064 16.8446 7.4064 17.219 7.7808C17.5934 8.1552 17.5934 8.76089 17.219 9.1353L10.5104 15.8439C10.3238 16.0317 10.0785 16.125 9.83315 16.125Z'
        fill={colors.textButton}
      />
    </Svg>
  ) : (
    <Svg testID={tID('CheckMarkInCircleIcon')} width={size} height={size} viewBox='0 0 28 27' fill='none'>
      <Path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M13.9998 0.166748C21.3638 0.166748 27.3332 6.13608 27.3332 13.5001C27.3332 20.8641 21.3638 26.8334 13.9998 26.8334C6.63584 26.8334 0.666504 20.8641 0.666504 13.5001C0.666504 6.13608 6.63584 0.166748 13.9998 0.166748ZM13.9998 2.16675C7.7505 2.16675 2.6665 7.25075 2.6665 13.5001C2.6665 19.7494 7.7505 24.8334 13.9998 24.8334C20.2492 24.8334 25.3332 19.7494 25.3332 13.5001C25.3332 7.25075 20.2492 2.16675 13.9998 2.16675ZM11.293 18.2068C11.4877 18.4028 11.7437 18.5002 11.9997 18.5002C12.2557 18.5002 12.5117 18.4028 12.7063 18.2068L19.7063 11.2068C20.097 10.8162 20.097 10.1842 19.7063 9.79349C19.3157 9.40282 18.6837 9.40282 18.293 9.79349L11.9997 16.0855L9.70633 13.7935C9.31567 13.4028 8.68367 13.4028 8.293 13.7935C7.90233 14.1842 7.90233 14.8162 8.293 15.2068L11.293 18.2068Z'
        fill={fillColor || checkMarkInCircleIcon.fill}
      />
    </Svg>
  )
}
