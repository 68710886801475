import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components/native'

import {
  Subhead,
  SubheadSize,
  BodyText,
  BodyTextSize,
  ThemeType,
  TextButton,
  PrimaryButton,
  tID,
} from '@lyrahealth-inc/ui-core-crossplatform'

const DeleteModalHeader = styled.View({
  flexDirection: 'column',
})

const DeleteModalContent = styled(BodyText)<{ theme: ThemeType }>(({ theme }) => ({
  display: 'block',
  marginTop: `${theme.spacing['16px']}`,
  marginBottom: `${theme.spacing['32px']}`,
}))

const DeleteModalButtonContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'end',
  gap: `${theme.spacing['32px']}`,
}))

export const DeleteExerciseDraftModal: FunctionComponent<DeleteExerciseDraftModalProps> = ({
  adjustedDate,
  onCancel,
  onDelete,
}) => {
  return (
    <>
      <DeleteModalHeader>
        <Subhead
          text={
            <FormattedMessage
              defaultMessage='Delete draft?'
              description='Asking the user if they want to delete their draft'
            />
          }
          size={SubheadSize.MEDIUM}
        />
        <DeleteModalContent
          text={
            <FormattedMessage
              defaultMessage='This will permanently delete the draft for {date}'
              description='Informing the user that the draft will be permanently deleted, with a reference to the date the draft was created'
              values={{
                date: adjustedDate,
              }}
            />
          }
          size={BodyTextSize.DEFAULT}
        />
      </DeleteModalHeader>
      <DeleteModalButtonContainer>
        <TextButton
          onPress={onCancel}
          text={
            <FormattedMessage defaultMessage='Keep draft' description='Button to keep the draft and not delete it' />
          }
          style={{ alignSelf: 'center' }}
        />
        <PrimaryButton
          testID={tID('deleteModal-confirm-delete')}
          text={<FormattedMessage defaultMessage='Delete' description='Button to confirm deletion of the draft' />}
          onPress={onDelete}
        />
      </DeleteModalButtonContainer>
    </>
  )
}

export type DeleteExerciseDraftModalProps = {
  adjustedDate: string
  onCancel: () => void
  onDelete: () => void
}
