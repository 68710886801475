import React, { FunctionComponent } from 'react'
import { Path, Rect, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

/**
 * Close Circle Icon used on inputs to clear text
 */
export const CloseCircleIcon: FunctionComponent<CloseCircleIconProps> = ({ size = 24, fillColor }) => {
  const { colors } = useTheme()
  const { fill } = colors.icons.closeCircleIcon

  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' fill='none'>
      <Path
        d='M15 9L12 12M9 15L12 12M12 12L9 9M12 12L15 15'
        stroke={fillColor || fill}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <Rect x='2.75' y='2.75' width='18.5' height='18.5' rx='9.25' stroke={fillColor || fill} strokeWidth='1.5' />
    </Svg>
  )
}

type CloseCircleIconProps = {
  size?: string | number
  fillColor?: string
}
