import { useEffect } from 'react'

import { get } from 'lodash-es'

import { useFetcher } from '@lyrahealth-inc/ui-core-crossplatform'

import { TwilioConversations } from '../../twilio-conversations'

/**
 * Hook to use Twilio messaging.
 * Creates a Twilio conversations client
 */
export default ({
  userId,
  conversationsClient,
  actions: { getAccessToken, incrementUnreadMessageCount, setConversationsClient },
}: any) => {
  const [, accessToken] = useFetcher([getAccessToken], [userId])

  useEffect(() => {
    if (accessToken && !conversationsClient) {
      // @ts-expect-error TS(2345): Argument of type 'unknown[]' is not assignable to ... Remove this comment to see the full error message
      const twilioClient = new TwilioConversations(accessToken)
      setConversationsClient(twilioClient)
    }
  }, [accessToken, conversationsClient, setConversationsClient])

  useEffect(() => {
    const incrementCount = (message: any) => {
      if (get(message, 'state.attributes.from') === userId) {
        return
      }
      incrementUnreadMessageCount({ conversationId: message.conversation.sid })
    }

    if (conversationsClient) {
      conversationsClient.on('messageAdded', incrementCount)
    }
    return () => {
      if (conversationsClient) {
        conversationsClient.off('messageAdded', incrementCount)
      }
    }
  }, [conversationsClient, incrementUnreadMessageCount, userId])
}
