import React, { FunctionComponent } from 'react'

import { Image } from '../image/Image'

type CalendarIllustrationAngledProps = {
  size?: number
}

export const CalendarIllustrationAngled: FunctionComponent<CalendarIllustrationAngledProps> = ({
  size = 48,
  ...props
}) => {
  return (
    <Image
      {...props}
      style={{ height: size, width: size }}
      source={require('../../assets/calendar.png')}
      contentFit='contain'
      accessibilityIgnoresInvertColors
    />
  )
}
