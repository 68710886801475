import React, { FunctionComponent } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { ImageSourcePropType, View } from 'react-native'

import styled, { css, useTheme } from 'styled-components/native'

import { useGetProviderAccessFormMetadata } from './providerAccessFormMetadata'
import { PrimaryButton, SpacedView, ThemeType } from '../..'
import { SelfCareWellnessTopicBackgroundImage } from '../../assets'
import { Image } from '../../atoms/image/Image'
import { FormBody, FormButtonParams } from '../../organisms'
import { tID } from '../../utils/utils'

export interface ClinicalDataSharingProviderAccessPageProps {
  saveForm: (values: Dict) => void
  errorMessage?: string
}

const BackgroundImageContainer = styled(Image)({
  width: '100%',
  height: '100%',
  position: 'absolute',
})

const ButtonContainer = styled.View({
  flexDirection: 'row',
  justifyContent: 'flex-end',
})

const SubmitContainer = ({ handleSubmit, loading }: FormButtonParams) => {
  return (
    <ButtonContainer>
      <PrimaryButton
        text={<FormattedMessage defaultMessage='Continue' description='Button to continue to next screen' />}
        onPress={handleSubmit}
        testID={tID('ClientShareOutcomesForm-submit')}
        disabled={false}
        loading={loading}
        fullWidth={true}
      />
    </ButtonContainer>
  )
}

const FormContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  backgroundColor: theme.colors.backgroundPrimary,
  paddingHorizontal: theme.spacing['24px'],
  paddingBottom: theme.spacing['24px'],
  maxWidth: theme.breakpoints.isMobileSized ? '100%' : '70%',
  alignSelf: 'center',
  borderRadius: theme.spacing['8px'],
}))

export const ClinicalDataSharingProviderAccessPage: FunctionComponent<ClinicalDataSharingProviderAccessPageProps> = ({
  saveForm,
  errorMessage,
}) => {
  const intl = useIntl()
  const { breakpoints, spacing } = useTheme()
  const metadata = useGetProviderAccessFormMetadata()
  return (
    <>
      <BackgroundImageContainer
        source={SelfCareWellnessTopicBackgroundImage as ImageSourcePropType}
        contentFit='fill'
        accessibilityIgnoresInvertColors
      />
      <View testID={tID('ClientShareOutcomesPage')}>
        {!breakpoints.isMobileSized && <SpacedView marginTop={spacing['64px']} />}
        <FormContainer>
          <FormBody
            intl={intl}
            name={'ClientShareOutcomesForm'}
            schema={metadata.schema}
            uiSchema={metadata.uiSchema}
            saveForm={saveForm}
            formButton={SubmitContainer}
            inlineErrorBannerMessage={errorMessage}
            formBodyCustomStyles={{
              submitButtonContainer: css`
                box-shadow: none;
                border-top-width: 0;
              `,
            }}
            formContext={{ labelAlignment: 'center' }}
          />
        </FormContainer>
      </View>
    </>
  )
}
