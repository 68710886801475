import { ThemeColors } from '@lyrahealth-inc/shared-app-logic'

export enum SelectStyleType {
  DEFAULT = 'default',
  LEGACY = 'legacy',
  TRIAGE = 'triage',
}

export const getSelectStyles = (colors: ThemeColors) => ({
  [SelectStyleType.DEFAULT]: {
    select: {
      borderColor: colors.inputOutlineDefault,
      borderRadius: 8,
      height: 48,
      paddingLeft: 16,
      paddingRight: 44,
    },
    iconContainer: {
      right: 16,
    },
    icon: {
      size: 16,
    },
  },
  [SelectStyleType.LEGACY]: {
    select: {
      borderColor: colors.inputOutlineDefault,
      borderRadius: 4,
      height: 48,
      paddingLeft: 15,
      paddingRight: 40,
    },
    iconContainer: {
      right: 15,
    },
    icon: {
      size: 16,
    },
  },
  [SelectStyleType.TRIAGE]: {
    select: {
      paddingLeft: 23,
      paddingRight: 48,
      borderRadius: 100,
      height: 56,
      borderColor: colors.inputOutlineTriageDefault,
    },
    iconContainer: {
      right: 24,
    },
    icon: {
      size: 24,
    },
  },
})
