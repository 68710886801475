import React, { useEffect, useRef } from 'react'
import { Dimensions } from 'react-native'

import styled from 'styled-components'

import { useZoom } from '../../hooks/useZoom'

export type ZoomParticipantViewInnerProps = {
  show?: boolean
}

const Canvas = styled.canvas<{ show: boolean }>(({ show }) => ({
  maxWidth: '100%',
  maxHeight: '100%',
  display: show ? undefined : 'none',
}))

export const ZoomParticipantViewInner: React.FC<ZoomParticipantViewInnerProps> = ({ show }) => {
  const { setParticipantElement, sessionStarted, remoteParticipant } = useZoom()
  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    if (show) {
      setParticipantElement(canvasRef.current)
    }
    return () => setParticipantElement(null)
  }, [setParticipantElement, show])

  const width = Math.min(1920, Dimensions.get('window').width)
  return (
    <Canvas
      show={!!show && sessionStarted && !!remoteParticipant?.isVideoOn}
      ref={canvasRef}
      width={width}
      height={(width * 9) / 16}
    />
  )
}
