import React, { FunctionComponent } from 'react'
import { ImageSourcePropType, ImageStyle } from 'react-native'

import styled from 'styled-components/native'

import LightBulbImage from '../../assets/lightbulb.png'
import { tID } from '../../utils'
import { Image } from '../image/Image'

const ImageContainer = styled(Image)<{ size: string }>(({ size }) => ({
  width: size,
  height: size,
}))

/**
 * Illustration to show on the treatment card
 */

export const LightBulb: FunctionComponent<LightBulbProps> = ({ size = 32, style }) => {
  const sizeInPixels = `${size}px`
  return (
    <ImageContainer
      size={sizeInPixels}
      style={style}
      testID={`${tID('LightBulbIllustration')}`}
      source={LightBulbImage as ImageSourcePropType}
    />
  )
}

type LightBulbProps = {
  size?: number
  style?: ImageStyle
}
