import React from 'react'
import { useSelector } from 'react-redux'
import { NavLink, Route, useLocation } from 'react-router-dom'

import { isEmpty } from 'lodash-es'

import { useFlags } from '@lyrahealth-inc/shared-app-logic'
import { toJS } from '@lyrahealth-inc/ui-core-crossplatform'

import CaseloadManagementContainer from './CaseloadManagementContainer'
import CaseloadManagementDetailedWeeklyViewContainer from './CaseloadManagementDetailedWeeklyViewContainer'
import MeasurementBasedCare from './MeasurementBasedCare'
import PlatformEngagementContainer from './platformEngagement/PlatformEngagementContainer'
import PlatformEngagementQuarterlyDetailsContainer from './platformEngagement/PlatformEngagementQuarterlyDetailsContainer'
import styles from './providerCaseload.module.scss'
import ProviderPerformance from './ProviderPerformance'
import {
  BC_CASELOAD,
  BC_CASELOAD_DETAILED_WEEKLY_VIEW,
  BC_MEASUREMENT_BASED_CARE,
  BC_PERFORMACE_DETAILED_WEEKLY_VIEW,
  BC_PERFORMANCE,
  BC_PLATFORM_ENGAGEMENT,
  BC_PLATFORM_ENGAGEMENT_QUARTERLY_DETAILS,
} from '../../../js/common/constants/routingConstants'
import { BCT_ROLES, employmentTypes, ROLES } from '../../common/constants/appConstants'
import { hasRole } from '../../common/utils/utils'
import { getAuthEmploymentStatus, getAuthRoles, getAuthSupervisor } from '../../data/auth/authSelectors'
import { SentryRoutes } from '../../index'

export const ProviderCaseload: React.FC = () => {
  const userRoles = useSelector(getAuthRoles)
  const employmentStatus = useSelector(getAuthEmploymentStatus)
  const supervisor = useSelector(getAuthSupervisor)
  const {
    showNewCaseloadDashboardProviders,
    showNewCaseloadDashboardSupervisors,
    showPlatformEngagementDashboard: showPlatformEngagementDashboardFlag,
    useCrossPlatformAppHeader,
  } = useFlags()

  const newCaseloadDashboardVersion =
    hasRole(userRoles, [ROLES.LT_COACH, ROLES.LT_SS_COACH]) &&
    [employmentTypes.FULL_TIME, employmentTypes.PART_TIME].includes(employmentStatus)

  const isBCCSupervisor =
    supervisor &&
    !isEmpty(supervisor.roles) &&
    hasRole(supervisor.roles, [ROLES.LT_COACH_SUPERVISOR, ROLES.LT_SS_COACH_SUPERVISOR])

  const showPlatformEngagementDashboard = showPlatformEngagementDashboardFlag && hasRole(userRoles, BCT_ROLES)
  const { pathname } = useLocation()
  return (
    <div>
      {!useCrossPlatformAppHeader && (hasRole(userRoles, BCT_ROLES) || isBCCSupervisor) && (
        <div className={styles['sub-nav']} data-test-id='ProviderCaseload-subNav'>
          <NavLink
            end={pathname.indexOf('new-caseload-dashboard') > -1 ? true : false}
            to={BC_PERFORMANCE.route}
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            Caseload
          </NavLink>
          {isBCCSupervisor && newCaseloadDashboardVersion && showNewCaseloadDashboardSupervisors && (
            <NavLink to={BC_CASELOAD.route} className={({ isActive }) => (isActive ? 'active' : '')}>
              New Caseload Dashboard
            </NavLink>
          )}
          {hasRole(userRoles, BCT_ROLES) && (
            <NavLink to={BC_MEASUREMENT_BASED_CARE.route}>Measurement Based Care</NavLink>
          )}
          {showPlatformEngagementDashboard && <NavLink to={BC_PLATFORM_ENGAGEMENT.route}>Platform Engagement</NavLink>}
        </div>
      )}
      <SentryRoutes>
        <Route
          path='/'
          element={
            newCaseloadDashboardVersion && showNewCaseloadDashboardProviders && !isBCCSupervisor ? (
              <CaseloadManagementContainer />
            ) : (
              <ProviderPerformance />
            )
          }
        />
        <Route
          path={BC_PERFORMACE_DETAILED_WEEKLY_VIEW.subroute}
          element={<CaseloadManagementDetailedWeeklyViewContainer />}
        />
        <Route
          path={BC_CASELOAD_DETAILED_WEEKLY_VIEW.subroute}
          element={<CaseloadManagementDetailedWeeklyViewContainer />}
        />
        <Route path={BC_CASELOAD.subroute} element={<CaseloadManagementContainer />} />
        <Route path={BC_MEASUREMENT_BASED_CARE.subroute} element={<MeasurementBasedCare />} />
        {showPlatformEngagementDashboard && (
          <>
            <Route path={BC_PLATFORM_ENGAGEMENT.subroute} element={<PlatformEngagementContainer />} />
            <Route
              path={BC_PLATFORM_ENGAGEMENT_QUARTERLY_DETAILS.subroute}
              element={<PlatformEngagementQuarterlyDetailsContainer />}
            />
          </>
        )}
      </SentryRoutes>
    </div>
  )
}

export default toJS(ProviderCaseload)
