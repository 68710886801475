import React, { FunctionComponent } from 'react'

import styled, { useTheme } from 'styled-components/native'

import { ExperienceWithCareIllustration } from '../../atoms/illustrations/ExperienceWithCareIllustration'
import { StyledMarkdown } from '../../atoms/styledMarkdown/StyledMarkdown'
import { getHighlightedText } from '../../styles/typeStyles'
import { tID } from '../../utils'

const Container = styled.View(({ theme }) => ({
  alignItems: 'center',
  textAlign: 'center',
  marginBottom: theme.spacing['24px'],
}))

const IllustrationContainer = styled.View(({ theme }) => ({
  marginBottom: theme.spacing['24px'],
}))

export const ExperienceWithCareHeader: FunctionComponent<ExperienceWithCareHeaderProps> = ({ title, name }) => {
  const { colors } = useTheme()
  const markdownCustomStyles = { em: getHighlightedText(colors) }
  return (
    <Container testID={tID(name)}>
      <IllustrationContainer>
        <ExperienceWithCareIllustration />
      </IllustrationContainer>
      <StyledMarkdown content={title} center customStyles={markdownCustomStyles} />
    </Container>
  )
}

type ExperienceWithCareHeaderProps = {
  title?: string
  name?: string
}
