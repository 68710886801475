import React from 'react'
import { Icon } from './types'
import colors from '../../../styles/_0colors.scss'

const AlertLoudIcon: React.FC<AlertLoudIconProps> = ({ width = 18, height = 16, fillColor = colors.error_red5 }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.00067 1.26128C7.30614 1.08983 7.65029 1 8 1C8.34971 1 8.69386 1.08983 8.99933 1.26128C9.30484 1.43275 9.56166 1.68028 9.74441 1.98062L9.74626 1.98364L15.7249 11.9336C15.904 12.2428 15.999 12.5939 16 12.9518C16.001 13.3098 15.9079 13.6614 15.7306 13.9715C15.5533 14.2817 15.2981 14.5393 14.991 14.7194C14.684 14.8995 14.3356 14.996 13.9803 14.9999L13.9734 15L2.0197 15C1.66443 14.9961 1.316 14.8995 1.00899 14.7194C0.701945 14.5393 0.446734 14.2817 0.269409 13.9715C0.0920558 13.6614 -0.000997312 13.3098 8.06118e-06 12.9518C0.00101343 12.5939 0.09604 12.2428 0.275124 11.9337L0.280251 11.9248L6.25375 1.98365L4.84211 4.60605L6.25559 1.98062C6.43834 1.68028 6.69516 1.43275 7.00067 1.26128ZM7.33326 2.64454L7.33247 2.64583L1.3639 12.579C1.29808 12.6941 1.26353 12.8238 1.26316 12.9554C1.26279 13.0885 1.29735 13.2198 1.36393 13.3362C1.43052 13.4527 1.52704 13.5506 1.64452 13.6195C1.76121 13.6879 1.89423 13.7252 2.03047 13.7272L13.9695 13.7272C14.1058 13.7252 14.2388 13.6879 14.3555 13.6195C14.473 13.5506 14.5695 13.4527 14.6361 13.3362C14.7026 13.2198 14.7372 13.0885 14.7368 12.9554C14.7365 12.8238 14.7019 12.6941 14.6361 12.579L8.66753 2.64583L8.66674 2.64454C8.59808 2.53221 8.50116 2.43854 8.38464 2.37314C8.26763 2.30746 8.13513 2.27272 8 2.27272C7.86487 2.27272 7.73237 2.30746 7.61536 2.37314C7.49884 2.43854 7.40192 2.53221 7.33326 2.64454Z'
        fill={fillColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8 9.83325C7.58579 9.83325 7.25 9.49747 7.25 9.08325L7.25 6.41658C7.25 6.00237 7.58579 5.66658 8 5.66658C8.41421 5.66658 8.75 6.00237 8.75 6.41658L8.75 9.08325C8.75 9.49747 8.41421 9.83325 8 9.83325Z'
        fill={fillColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.75 11.75C8.75 12.1642 8.41421 12.5 8 12.5L7.99333 12.5C7.57912 12.5 7.24333 12.1642 7.24333 11.75C7.24333 11.3358 7.57912 11 7.99333 11L8 11C8.41421 11 8.75 11.3358 8.75 11.75Z'
        fill={fillColor}
      />
    </svg>
  )
}

type AlertLoudIconProps = Icon

export default AlertLoudIcon
