// A utility to get a full time zone name such as 'Pacific Daylight Time' or
// an abbreviation such as 'PDT' given a time zone (e.g. 'America/Los_Angeles'),
// date, locale, and time zone name format
export const getTimeZoneName = ({
  date = new Date(),
  locale = 'en-US',
  timeZone,
  timeZoneName = 'long',
}: {
  date?: Date
  locale?: string
  timeZone: string
  timeZoneName?: Intl.DateTimeFormatOptions['timeZoneName']
}) => {
  // The full time zone name or abbreviation is generated by formatting a date into an array representation of
  // the date string that contains the full time zone name or abbreviation and extracting the name or abbreviation
  // from the array.
  // E.g. `parts` would look like this: [{type: 'day', value: '12'}, {type: 'literal', value: ', '}, {type: 'timeZoneName', value: 'Pacific Daylight Time'}]
  // Then the full time zone name or abbreviation can be found from the value of the object with type 'timeZoneName'.
  const parts = new Intl.DateTimeFormat(locale, {
    day: '2-digit',
    timeZone,
    timeZoneName,
  }).formatToParts(date)
  const longTimeZoneName = parts?.find(({ type }) => type === 'timeZoneName')?.value

  return longTimeZoneName
}
