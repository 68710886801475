import React from 'react'
import get from 'lodash/get'
import uniq from 'lodash/uniq'
import without from 'lodash/without'
import { FieldInputProps, FieldMetaState } from 'react-final-form'
import BaseInput from '../baseInput/BaseInput'
import Checkbox from '../checkbox/Checkbox'
import styles from './checkboxGroup.module.scss'
import { includes } from 'lodash-es'
import { FormattedMessage } from 'react-intl'

const CheckboxGroup: React.FC<CheckboxGroupProps> = ({
  className,
  options,
  input,
  label,
  meta: { touched, error },
  stroked,
  readOnly = false,
  disabled = false,
}) => {
  const onCheckboxChange = ({ name, checkboxValue }: { name: string; checkboxValue: boolean }) => {
    const nextInputValue = checkboxValue ? uniq([...input.value, name]) : without(input.value, name)
    const groupsChecked = uniq(
      nextInputValue.map((selectedId: string) =>
        get(
          options.find(({ id }: { id: string }) => selectedId === id),
          'group',
        ),
      ),
    )
    input.onChange(groupsChecked.length > 1 ? [name] : nextInputValue)
  }

  const fieldInfoId = `${input.name}_info`
  const fieldDescriptionId = `${input.name}_description`
  const titleId = `checkbox-group-${input.name}`
  return (
    <BaseInput
      className={className}
      fieldInfoId={fieldInfoId}
      label={label}
      labelId={titleId}
      touched={touched}
      error={error}
      readOnly={readOnly}
      disabled={disabled}
      dataTestId={`CheckboxGroup-${input.name}`}
    >
      <>
        <p className={styles.description} id={fieldDescriptionId}>
          <FormattedMessage
            defaultMessage='Select all that apply.'
            description='Describes a checkbox group in a form that allows multiple selections'
          />
        </p>
        <div role='group' aria-labelledby={titleId}>
          {options.map(({ id, text, dataTestId }) => (
            <Checkbox
              key={id}
              input={{
                ...input,
                onChange: (e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement
                  return onCheckboxChange({ name: id, checkboxValue: target.checked })
                },
                value: input.value.includes(id),
                checked: includes(input.value, id),
              }}
              meta={{
                touched: false,
                error: '',
                submitError: '',
                warning: '',
              }}
              htmlFor={input.name}
              readOnly={readOnly}
              stroked={stroked}
              className={className}
              fieldInfoId={`${fieldInfoId}, ${fieldDescriptionId}`}
              dataTestId={dataTestId || id}
              disabled={disabled}
            >
              <>{text}</>
            </Checkbox>
          ))}
        </div>
      </>
    </BaseInput>
  )
}

type CheckboxGroupProps = {
  className: string
  options: Array<CheckboxGroupDatum>
  input: FieldInputProps<any, any>
  label: string
  meta: FieldMetaState<any>
  stroked: boolean
  readOnly?: boolean
  disabled?: boolean
}

type CheckboxGroupDatum = {
  group: number
  id: string
  text: string
  dataTestId?: string
}

export default CheckboxGroup
