import React, { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'

import styled, { useTheme } from 'styled-components/native'

import { BodyText, Size as BodyTextSize } from '../../atoms/bodyText/BodyText'
import { XIcon } from '../../atoms/icons/XIcon'
import { PressableOpacity } from '../../atoms/pressableOpacity/PressableOpacity'
import { PrimaryButton } from '../../atoms/primaryButton/PrimaryButton'
import { Subhead, Size as SubheadSize } from '../../atoms/subhead/Subhead'
import { TertiaryButton } from '../../atoms/tertiaryButton/TertiaryButton'
import { ThemeType, tID } from '../../utils'

export type ActivityExitModalContentProps = {
  draftDate?: string | null
  exitModalContentType: ACTIVITY_EXIT_MODAL_CONTENT
  onLayout?: () => void
  onPrimaryButtonPress: () => void
  onRequestClose: () => void
  onTertiaryButtonPress: () => void
}

export enum ACTIVITY_EXIT_MODAL_CONTENT {
  CLOSE_NEW_DRAFT = 'CLOSE_NEW_DRAFT',
  CLOSE_NEW_PROVIDER_STARTED_DRAFT = 'CLOSE_NEW_PROVIDER_STARTED_DRAFT',
  DELETE_DRAFT = 'DELETE_DRAFT',
}

const ModalContent = styled.View<{ theme: ThemeType }>(({ theme: { breakpoints, spacing } }) => ({
  ...(!breakpoints.isMinWidthLaptop && { padding: `0px ${spacing['16px']} ${spacing['32px']}` }),
}))

const HeaderContainer = styled.View<{ theme: ThemeType }>(({ theme: { breakpoints, spacing } }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: spacing['16px'],
  ...(!breakpoints.isMinWidthLaptop && { marginTop: spacing['32px'] }),
}))

const ButtonsContainer = styled.View<{ theme: ThemeType }>(({ theme: { breakpoints, spacing } }) => ({
  flexDirection: breakpoints.isMinWidthLaptop ? 'row' : 'column-reverse',
  justifyContent: 'flex-end',
  marginTop: breakpoints.isMinWidthLaptop ? spacing['32px'] : spacing['64px'],
}))

const PrimaryButtonContainer = styled.View<{ theme: ThemeType }>(({ theme: { breakpoints, spacing } }) => ({
  ...(breakpoints.isMinWidthLaptop && { marginLeft: spacing['16px'] }),
}))

const TertiaryButtonContainer = styled.View<{ theme: ThemeType }>(({ theme: { breakpoints, spacing } }) => ({
  ...(!breakpoints.isMinWidthLaptop && { marginTop: spacing['8px'] }),
}))

export const ActivityExitModalContent: FunctionComponent<ActivityExitModalContentProps> = ({
  draftDate,
  exitModalContentType,
  onLayout,
  onPrimaryButtonPress,
  onRequestClose,
  onTertiaryButtonPress,
}) => {
  const { formatMessage } = useIntl()
  const { breakpoints, colors } = useTheme()

  const modalContent = {
    DELETE_DRAFT: {
      title: formatMessage({
        defaultMessage: 'Delete draft?',
        description: 'Title of the modal when exiting an entry',
      }),
      body: formatMessage(
        {
          defaultMessage: 'This will permanently delete your draft for {draftDate}',
          description: 'Prompt indicating that you will delete the draft for the given date',
        },
        { draftDate },
      ),
      primaryButtonTestId: 'delete-draft-btn',
      primaryButtonText: formatMessage({
        defaultMessage: 'Delete',
        description: 'Label for button that allows user to delete their form',
      }),
      tertiaryButtonTestId: 'save-draft-btn',
      tertiaryButtonText: formatMessage({
        defaultMessage: 'Keep draft',
        description: 'Label for button that allows user to keep their form as a draft',
      }),
    },
    CLOSE_NEW_DRAFT: {
      title: formatMessage({
        defaultMessage: 'Save your progress',
        description: 'Title of the modal when exiting a new entry',
      }),
      body: formatMessage({
        defaultMessage: 'You can save your current progress as a draft and pick up where you left off anytime.',
        description: 'Prompt indicating that the user has the option to save a draft of their form',
      }),
      primaryButtonTestId: 'save-draft-btn',
      primaryButtonText: formatMessage({
        defaultMessage: 'Save as draft',
        description: 'Label for button allowing a user to save their current progress',
      }),
      tertiaryButtonTestId: 'delete-draft-btn',
      tertiaryButtonText: formatMessage({
        defaultMessage: 'Exit without saving',
        description: 'Label for button that allows user to leave without saving their current progress',
      }),
    },
    CLOSE_NEW_PROVIDER_STARTED_DRAFT: {
      title: formatMessage({
        defaultMessage: 'Save progress',
        description: 'Title of the modal when exiting a new provider started draft entry',
      }),
      body: formatMessage({
        defaultMessage: 'Would you like to save the progress of this exercise before exiting? ',
        description: 'Prompt asking if the user would like to save a draft of their form',
      }),
      primaryButtonTestId: 'save-draft-btn',
      primaryButtonText: formatMessage({
        defaultMessage: 'Save as draft',
        description: 'Label for button allowing a user to save their current progress',
      }),
      tertiaryButtonTestId: 'exit-draft-btn',
      tertiaryButtonText: formatMessage({
        defaultMessage: 'Exit without saving',
        description: 'Label for button that allows user to leave without saving their current progress',
      }),
    },
  }

  const { body, primaryButtonTestId, primaryButtonText, tertiaryButtonTestId, tertiaryButtonText, title } =
    modalContent[exitModalContentType]

  return (
    <ModalContent testID={tID('ActivityExitModal')} onLayout={onLayout}>
      <HeaderContainer>
        <Subhead text={title} size={SubheadSize.MEDIUM} />
        <PressableOpacity
          onPress={onRequestClose}
          testID={tID('ActivityExitModal-close-btn')}
          accessibilityLabel={formatMessage({
            defaultMessage: 'Close Button',
            description: 'This button closes the modal window',
          })}
        >
          <XIcon size={24} fillColor={colors.iconDefault} />
        </PressableOpacity>
      </HeaderContainer>

      <BodyText text={body} size={BodyTextSize.DEFAULT} />

      <ButtonsContainer>
        <TertiaryButtonContainer>
          <TertiaryButton
            fullWidth={!breakpoints.isMinWidthLaptop}
            onPress={onTertiaryButtonPress}
            text={tertiaryButtonText}
            testID={tID(`ActivityExitModal-${tertiaryButtonTestId}`)}
          />
        </TertiaryButtonContainer>
        <PrimaryButtonContainer>
          <PrimaryButton
            fullWidth={!breakpoints.isMinWidthLaptop}
            onPress={onPrimaryButtonPress}
            text={primaryButtonText}
            testID={tID(`ActivityExitModal-${primaryButtonTestId}`)}
          />
        </PrimaryButtonContainer>
      </ButtonsContainer>
    </ModalContent>
  )
}
