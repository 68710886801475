import React from 'react'
import colors from '../../../styles/_0colors.scss'
import { Icon } from './types'

const CardIcon: React.FC<CardIconProps> = ({
  width = 57,
  fillColor = colors.x_light_gray_10,
  isFilled = false,
  ...props
}) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} viewBox='0 0 24 24' {...props}>
      {isFilled ? (
        <path
          fill={fillColor}
          d='M18.25,11 L13.75,11 C13.34,11 13,10.66 13,10.25 C13,9.84 13.34,9.5 13.75,9.5 L18.25,9.5 C18.66,9.5 19,9.84 19,10.25 C19,10.66 18.66,11 18.25,11 Z M18.25,14.5 L13.75,14.5 C13.34,14.5 13,14.16 13,13.75 C13,13.34 13.34,13 13.75,13 L18.25,13 C18.66,13 19,13.34 19,13.75 C19,14.16 18.66,14.5 18.25,14.5 Z M11,15.25 C11,15.39 10.89,15.5 10.75,15.5 L5.25,15.5 C5.11,15.5 5,15.39 5,15.25 L5,15 C5,13.9 5.9,13 7,13 L9,13 C10.1,13 11,13.9 11,15 L11,15.25 Z M8,8 C8.97,8 9.75,8.78 9.75,9.75 C9.75,10.72 8.97,11.5 8,11.5 C7.03,11.5 6.25,10.72 6.25,9.75 C6.25,8.78 7.03,8 8,8 Z M20,3 L4,3 C2.34,3 1,4.34 1,6 L1,18 C1,19.66 2.34,21 4,21 L20,21 C21.66,21 23,19.66 23,18 L23,6 C23,4.34 21.66,3 20,3 Z'
        />
      ) : (
        <path
          fill={fillColor}
          d='M19,13.75 C19,13.336 18.664,13 18.25,13 L13.75,13 C13.336,13 13,13.336 13,13.75 C13,14.164 13.336,14.5 13.75,14.5 L18.25,14.5 C18.664,14.5 19,14.164 19,13.75 Z M19,10.25 C19,9.836 18.664,9.5 18.25,9.5 L13.75,9.5 C13.336,9.5 13,9.836 13,10.25 C13,10.664 13.336,11 13.75,11 L18.25,11 C18.664,11 19,10.664 19,10.25 Z M20,3 C21.657,3 23,4.343 23,6 L23,18 C23,19.657 21.657,21 20,21 L4,21 C2.343,21 1,19.657 1,18 L1,6 C1,4.343 2.343,3 4,3 L20,3 Z M20,4.5 L4,4.5 C3.173,4.5 2.5,5.173 2.5,6 L2.5,18 C2.5,18.827 3.173,19.5 4,19.5 L20,19.5 C20.827,19.5 21.5,18.827 21.5,18 L21.5,6 C21.5,5.173 20.827,4.5 20,4.5 Z M11.5,15.25 L11.5,14.5 C11.5,13.397 10.603,12.5 9.5,12.5 L6.5,12.5 C5.397,12.5 4.5,13.397 4.5,14.5 L4.5,15.25 C4.5,15.664 4.836,16 5.25,16 C5.664,16 6,15.664 6,15.25 L6,14.5 C6,14.224 6.224,14 6.5,14 L9.5,14 C9.776,14 10,14.224 10,14.5 L10,15.25 C10,15.664 10.336,16 10.75,16 C11.164,16 11.5,15.664 11.5,15.25 Z M9.75,9.75 C9.75,10.716 8.966,11.5 8,11.5 C7.034,11.5 6.25,10.716 6.25,9.75 C6.25,8.784 7.034,8 8,8 C8.966,8 9.75,8.784 9.75,9.75 Z'
        />
      )}
    </svg>
  )
}

type CardIconProps = Icon

export default CardIcon
