import { validateLyraCode } from '../../../../requestPayment/data/requestPaymentActions'
import { sanitizeLyraCode } from '../../../../common/utils/utils'

const paymentRequestAsyncValidate = ($$values: any, dispatch: any) => {
  const lyraCode = sanitizeLyraCode($$values.get('lyra_code', ''))
  return dispatch(validateLyraCode(lyraCode)).then((response: any) => {
    if (response.error) {
      throw { lyra_code: response.error }
    }
  })
  // TODO: handle other errors
}

export default paymentRequestAsyncValidate
