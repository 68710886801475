/* eslint-disable react/no-unused-prop-types */
/* eslint-disable formatjs/no-literal-string-in-jsx */
import React, { FunctionComponent, useRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Popover } from 'react-native-popper'

import styled, { useTheme } from 'styled-components/native'

import { BodyText, GearIcon, MenuDotsIcon, PressableOpacity, RecordingIcon } from '../../atoms'
import { ThemeProvider, ThemeType, tID } from '../../utils'

const MenuButton = styled(PressableOpacity)<{ theme: ThemeType }>(({ theme }) => ({
  backgroundColor: theme.colors.components.zoomVideoControls.enabled.background,
  height: '48px',
  width: '48px',
  borderRadius: '24px',
  alignItems: 'center',
  justifyContent: 'center',
}))

const DropdownContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  backgroundColor: theme.colors.backgroundPrimary,
  padding: `${theme.spacing['16px']} 0`,
  borderRadius: '8px',
  boxShadow: `0 2px 16px ${theme.colors.shadowLow}`,
  flexDirection: 'column',
  gap: theme.spacing['4px'],
}))

const MenuItem = styled(PressableOpacity)<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: 'row',
  borderRadius: '8px',
  padding: `${theme.spacing['4px']} ${theme.spacing['16px']}`,
  gap: theme.spacing['8px'],
}))
export type ZoomSettingsMenuProps = {
  onSettingsPressed: () => void
  onDisableRecordingPressed: () => void
  disabledRecording: boolean
}

const ZoomSettingsMenuContent: FunctionComponent<ZoomSettingsMenuProps> = ({
  disabledRecording,
  onDisableRecordingPressed,
  onSettingsPressed,
}) => {
  const { colors } = useTheme() as ThemeType
  return (
    <Popover.Content>
      <DropdownContainer>
        <MenuItem
          testID={tID('ZoomSettingsMenuContent-disable-recording-button')}
          disabled={disabledRecording}
          onPress={onDisableRecordingPressed}
        >
          <RecordingIcon isFilled isOff fillColor={colors.iconDefault} />
          <BodyText
            color={disabledRecording ? colors.textSecondary : colors.textPrimary}
            text={
              disabledRecording ? (
                <FormattedMessage
                  defaultMessage='Recording has been turned off'
                  description='Disabled button text after provider has turned off recording'
                />
              ) : (
                <FormattedMessage
                  defaultMessage='Turn off recording'
                  description='Button that disabled recording video session'
                />
              )
            }
          />
        </MenuItem>
        <MenuItem testID={tID('ZoomSettingsMenuContent-settings-button')} onPress={onSettingsPressed}>
          <GearIcon color={colors.iconDefault} />
          <BodyText text='Settings' />
        </MenuItem>
      </DropdownContainer>
    </Popover.Content>
  )
}

export const ZoomSettingsMenu: FunctionComponent<ZoomSettingsMenuProps> = ({
  disabledRecording,
  onDisableRecordingPressed,
  onSettingsPressed,
}) => {
  const { colors } = useTheme() as ThemeType
  const [isOpen, setIsOpen] = React.useState(false)
  const triggerRef = useRef(null)
  const { formatMessage } = useIntl()

  return (
    <>
      <MenuButton
        testID={tID('ZoomSettingsMenu-button')}
        pressableRef={triggerRef}
        onPress={() => setIsOpen((prev) => !prev)}
        accessibilityLabel={formatMessage({
          defaultMessage: 'Video Call Settings Button',
          description:
            'This button allows users to either go to settings or record the Zoom session, if recording is enabled.',
        })}
      >
        <MenuDotsIcon fillColor={colors.components.zoomVideoControls.icon.text} />
      </MenuButton>
      <Popover isOpen={isOpen} onOpenChange={setIsOpen} trigger={triggerRef} placement='bottom right' offset={8}>
        <Popover.Backdrop />
        <ThemeProvider>
          <ZoomSettingsMenuContent
            disabledRecording={disabledRecording}
            onDisableRecordingPressed={() => {
              onDisableRecordingPressed()
              setIsOpen(false)
            }}
            onSettingsPressed={() => {
              onSettingsPressed()
              setIsOpen(false)
            }}
          />
        </ThemeProvider>
      </Popover>
    </>
  )
}
