import { getMyInfoProviderData } from '../../../common/http/data/myInfo'
import { setMyInfoData } from '../../data/myInfoDataActions'
import { addAlert } from '../../../data/alertActions'
import { getErrorText } from '../../../common/utils/utils'

export const returnProviderInfo = (id: any) => {
  return (dispatch: any) => {
    getMyInfoProviderData(id).then(
      (response) => {
        dispatch(setMyInfoData({ data: response.data.data }))
      },
      (error) => {
        const errorText = error.response.data.error || getErrorText(error.response.status)
        dispatch(
          addAlert({
            show: true,
            contents: `Error in getting My Info: ${errorText}`,
            style: 'danger',
            expires: true,
          }),
        )
      },
    )
  }
}
