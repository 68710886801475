import React, { FunctionComponent } from 'react'

import LottieView from 'lottie-react-native'
import styled from 'styled-components/native'

import skeletonAnimation from '../../assets/lottie/loadingSkeleton.json'
import { IS_WEB } from '../../constants'

const Container = styled.View<{
  width: number | string
  height: number | string
  marginTop: number | string
  marginBottom: number | string
}>(({ width, height, marginTop, marginBottom }) => ({
  height: `${height}px`,
  width: `${width}px`,
  marginTop: `${marginTop}px`,
  marginBottom: `${marginBottom}px`,
  overflow: 'hidden',
  borderRadius: '4px',
}))

const Skeleton = styled(LottieView)(() => ({
  // This is needed along with resizeMode='cover' for Android (works with or without on iOS too)
  // in order to scale the animation properly provided a width and height
  //
  // Context: https://github.com/lottie-react-native/lottie-react-native/issues/214
  flex: IS_WEB ? undefined : 1,
}))

export interface LoadingSkeletonProps {
  width?: string | number
  height?: string | number
  marginTop?: string | number
  marginBottom?: string | number
}

export const LoadingSkeleton: FunctionComponent<LoadingSkeletonProps> = ({
  width = 24,
  height = 24,
  marginTop = 0,
  marginBottom = 0,
}) => {
  return (
    <Container width={width} height={height} marginTop={marginTop} marginBottom={marginBottom}>
      <Skeleton source={skeletonAnimation} loop autoPlay resizeMode='cover' />
    </Container>
  )
}
