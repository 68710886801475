import React, { useState, FunctionComponent } from 'react'
import CheckboxGroup from '../../../atoms/form/checkboxGroup/CheckboxGroup'
import SecondaryButton from '../../../atoms/buttons/secondaryButton/SecondaryButton'
import styles from './filterButtonModal.module.scss'

// Used in the FilterButton component.
// A modal containing a CheckboxGroup used for applying filters.
const FilterButtonModal: FunctionComponent<FilterButtonModalProps> = ({
  filterName,
  filterOptions,
  filterArray,
  saveOnClick,
  closeModal,
}) => {
  const [filterArrayState, setFilterArrayState] = useState(filterArray)

  return (
    <div className={styles['checkbox-group']}>
      <CheckboxGroup
        options={filterOptions.data}
        meta={{
          touched: false,
          error: false,
        }}
        label={`${filterName} Filter`}
        input={{
          name: 'CheckboxGroup',
          value: filterArrayState,
          onChange: (array) => {
            setFilterArrayState(array)
          },
          onBlur: () => {},
          onFocus: () => {},
        }}
        stroked={false}
        className={'filter-checkbox-group'}
      />
      <div className={styles['modal-buttons']}>
        <SecondaryButton
          onClick={() => {
            setFilterArrayState([])
          }}
          disabled={!filterArrayState.length}
        >
          Clear
        </SecondaryButton>
        <SecondaryButton
          onClick={() => {
            saveOnClick(filterArrayState)
            closeModal()
          }}
        >
          Save
        </SecondaryButton>
      </div>
    </div>
  )
}

type FilterButtonModalProps = {
  filterName: string
  filterOptions: FilterOptions
  filterArray: Array<string>
  saveOnClick: (filterArray: Array<string>) => void
  closeModal: () => void
}

export interface FilterOptions {
  data: [{ group: number; id: string; text: string }]
}

export default FilterButtonModal
