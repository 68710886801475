import React, { Component } from 'react'
import CSSModules from 'react-css-modules'
import { connect } from 'react-redux'

import { bindActionCreators } from 'redux'

import { BaseModal, DefaultButton, DownloadIcon } from '@lyrahealth-inc/ui-core'

import * as downloadActions from './data/downloadActions'
import { getDownloadCSVModalState } from './data/downloadSelectors'
import styles from './downloadCSVButton.module.scss'
import DownloadHistoryView from './downloadCSVModal/DownloadCSVHistoryView'

type OwnDownloadCSVProps = {
  downloadClick?: (...args: any[]) => any
  buttonText?: string
  showDownloadCSVModal?: boolean
  actions?: any
}

type DownloadCSVProps = OwnDownloadCSVProps & typeof DownloadCSV.defaultProps
class DownloadCSV extends Component<DownloadCSVProps> {
  static defaultProps = {
    downloadClick: () => {},
    buttonText: 'CSV',
  }

  render() {
    const DownloadHistory = <DownloadHistoryView downloadClick={this.props.downloadClick} />

    return (
      <div>
        <DefaultButton isOutlined onClick={() => this.props.actions.toggleDownloadCSVModal(true)}>
          <DownloadIcon fillColor='currentColor' />
          {this.props.buttonText}
        </DefaultButton>
        <BaseModal
          // @ts-expect-error TS(2322): Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
          isOpen={this.props.showDownloadCSVModal}
          body={DownloadHistory}
          closeModal={() => this.props.actions.toggleDownloadCSVModal(false)}
        />
      </div>
    )
  }
}
const mapStateToProps = ($$state: any) => {
  return {
    showDownloadCSVModal: getDownloadCSVModalState($$state),
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    actions: bindActionCreators({ ...downloadActions }, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CSSModules(DownloadCSV, styles))
