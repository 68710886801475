import { ThemeColors } from '@lyrahealth-inc/shared-app-logic'

import { getFocusBoxShadow } from '../../../styles/commonStyles'

/**
 * This file contains all the styling for our checkbox component.
 * All low level keys should be valid ViewStyles.
 * Sizes, states and focus properties overwrite base values.
 */

export const getCheckboxButtonStyles = (tokenColors: ThemeColors) => ({
  textOutline: {
    base: {
      justifyContent: 'center',
      alignItems: 'center',
      height: 20,
      width: 20,
      borderWidth: 1,
      borderRadius: 3,
      borderColor: tokenColors.checkboxOutlineDefault,
      marginRight: 10,
      marginTop: 3,
    },
    states: {
      hovered: {
        backgroundColor: tokenColors.checkboxBackgroundPrimary,
      },
      pressed: {
        backgroundColor: tokenColors.checkboxBackgroundPressed,
      },
      selected: {
        backgroundColor: tokenColors.checkboxBackgroundSelected,
        borderColor: tokenColors.checkboxOutlineSelected,
      },
      inactive: {
        backgroundColor: tokenColors.checkboxBackgroundPrimary,
        borderColor: tokenColors.checkboxOutlineInactive,
      },
      selectedInactive: {
        backgroundColor: tokenColors.checkboxBackgroundSelectedInactive,
        borderColor: tokenColors.checkboxOutlineSelectedInactive,
      },
      error: {
        borderColor: tokenColors.borderError,
      },
    },
    focused: {
      ...getFocusBoxShadow({ colors: tokenColors }),
    },
  },
  outlinedTextCheckbox: {
    base: {
      justifyContent: 'center',
      alignItems: 'center',
      height: 20,
      width: 20,
      borderWidth: 1,
      borderRadius: 4,
      borderColor: tokenColors.checkboxOutlineDefault,
      marginRight: 18,
      flexDirection: 'row',
      flexShrink: 0,
      alignSelf: 'center',
    },
    states: {
      hovered: {
        backgroundColor: tokenColors.checkboxBackgroundPrimary,
      },
      pressed: {
        backgroundColor: tokenColors.checkboxBackgroundPressed,
      },
      selected: {
        borderColor: tokenColors.checkboxOutlineSelected,
        backgroundColor: tokenColors.checkboxBackgroundSelected,
      },
      inactive: {
        backgroundColor: tokenColors.checkboxBackgroundPrimary,
        borderColor: tokenColors.checkboxOutlineInactive,
      },
      selectedInactive: {
        backgroundColor: tokenColors.checkboxBackgroundSelectedInactive,
        borderColor: tokenColors.checkboxOutlineSelectedInactive,
      },
      error: {
        borderColor: tokenColors.borderError,
      },
    },
    focused: {
      backgroundColor: tokenColors.checkboxBackgroundPrimary,
    },
  },
  descriptionNoOutline: {
    base: {
      justifyContent: 'center',
      alignItems: 'center',
      height: 20,
      width: 20,
      borderWidth: 1,
      borderRadius: 4,
      borderColor: tokenColors.checkboxOutlineDefault,
      marginRight: 18,
      marginTop: 3,
      flexDirection: 'row',
      flexShrink: 0,
    },
    states: {
      hovered: {
        borderColor: tokenColors.borderHover,
        backgroundColor: tokenColors.checkboxBackgroundPrimary,
      },
      pressed: {
        backgroundColor: tokenColors.checkboxBackgroundPressed,
      },
      selected: {
        borderColor: tokenColors.checkboxOutlineSelected,
        backgroundColor: tokenColors.checkboxBackgroundSelected,
      },
      inactive: {
        borderColor: tokenColors.checkboxOutlineInactive,
        backgroundColor: tokenColors.checkboxBackgroundPrimary,
      },
      selectedInactive: {
        backgroundColor: tokenColors.checkboxBackgroundSelectedInactive,
        borderColor: tokenColors.checkboxOutlineSelectedInactive,
      },
      error: {
        borderColor: tokenColors.borderError,
      },
    },
    focused: {
      ...getFocusBoxShadow({ innerBoxShadowSize: '1.5px', outerBoxShadowSize: '2.5px', colors: tokenColors }),
    },
  },
})
