import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'

import { Map } from 'immutable'
import { cloneDeep, isEmpty } from 'lodash-es'
import moment, { Moment } from 'moment-timezone'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'

import {
  Appointment,
  ClientListClientObject,
  ClientObject,
  getAppointmentDateTimeObject,
  useFlags,
} from '@lyrahealth-inc/shared-app-logic'
import { DefaultButton } from '@lyrahealth-inc/ui-core'
import {
  colors,
  LoadingIndicator,
  SearchIcon,
  toJS,
  UnstyledIconButton,
  useFetcher,
  XIcon,
} from '@lyrahealth-inc/ui-core-crossplatform'

import styles from './clientList.module.scss'
import {
  appointmentStatuses,
  episodeProgress,
  episodeStates,
  programConfig,
} from '../../../common/constants/appConstants'
import { getClientFullName, searchForClients } from '../../../common/utils/utils'
import { getAuthUserId } from '../../../data/auth/authSelectors'
import {
  getActiveV2Clients,
  getClientsDataFiltered,
  getInactiveV2Clients,
} from '../../../data/lyraTherapy/clientSelectors'
import { getLtClientsV2, getLtClientsV2ByProgram } from '../../clients/data/ltClientsAutoActions'
import { getLTVideoAppointments } from '../../data/ltVideoSelectors'

interface ClientV2WithNextAppointment extends ClientListClientObject {
  next_appointment?: Moment | null
  session_number?: number
}

const ClientList: React.FC<ClientListProps> = ({
  appointments,
  clientsActive,
  clientsForSelectedProgram,
  clientsInactive,
  selectedClient,
  onSelectClient,
  programFilter,
  selectedEpisodeProgressOption,
  showActiveClients,
  showUnscheduledOnly,
  sortProperty,
  actions: { getLtClientsV2, getLtClientsV2ByProgram },
}) => {
  const userId: string = useSelector(getAuthUserId)
  const [searchValue, setSearchValue] = useState<string>('')
  const { isPreferredNameEnabled } = useFlags()

  const clients = useMemo(() => {
    return (
      cloneDeep(programFilter ? clientsForSelectedProgram : showActiveClients ? clientsActive : clientsInactive) || []
    )
  }, [programFilter, clientsForSelectedProgram, showActiveClients, clientsActive, clientsInactive])

  const clientsToDisplay = useMemo(() => {
    return searchForClients({
      clients,
      showAll: true,
      searchValue,
    })
  }, [clients, searchValue])

  useEffect(() => {
    // When a supervisor selects a new provider to emulate, clear the selected client
    onSelectClient && onSelectClient(null)
  }, [userId, onSelectClient])

  useFetcher(
    [
      [
        isEmpty(programFilter) ? getLtClientsV2 : getLtClientsV2ByProgram,
        { providerId: userId, status: showActiveClients ? 'active' : 'inactive', programName: programFilter },
      ],
    ],
    [userId, showActiveClients, programFilter],
  )

  const sortedClients = useMemo(() => {
    let shouldReverse = false
    const appointmentsClone = cloneDeep(appointments)
    const sortedAppointmentsBySessionNumber = appointmentsClone?.sort((a: Appointment, b: Appointment) => {
      return b.sessionNumber - a.sessionNumber
    })

    const filterByEpisodeProgress = (client: ClientV2WithNextAppointment) => {
      const sortedInProgressEpisodeAppointments = sortedAppointmentsBySessionNumber?.filter(
        (appointment: Appointment) => appointment?.episodeState === episodeStates.IN_PROGRESS,
      )

      const mostRecentAppointment = sortedInProgressEpisodeAppointments?.find(
        (appt: Appointment) => appt.userId === client.id,
      )

      const recommendedEpisodeLength = Object.values(programConfig).find(
        (config: any) => config.programId === mostRecentAppointment?.programId,
      )?.recommendedEpisodeLength

      switch (selectedEpisodeProgressOption) {
        case episodeProgress.INTAKE_ONLY:
          return (
            mostRecentAppointment?.sessionNumber === 1 &&
            mostRecentAppointment?.appointmentStatus !== appointmentStatuses.completed
          )
        case episodeProgress.FOLLOWUP_ONLY:
          return (
            mostRecentAppointment?.sessionNumber &&
            (mostRecentAppointment?.sessionNumber > 1 ||
              (mostRecentAppointment?.sessionNumber === 1 &&
                mostRecentAppointment?.appointmentStatus === appointmentStatuses.completed)) &&
            (recommendedEpisodeLength ? mostRecentAppointment?.sessionNumber <= recommendedEpisodeLength : true)
          )
        case episodeProgress.OVER_EPISODE_COUNT:
        case episodeProgress.LONGER_EPISODES:
          return (
            mostRecentAppointment?.programId &&
            recommendedEpisodeLength &&
            mostRecentAppointment?.sessionNumber > recommendedEpisodeLength
          )
        default:
          return clientsToDisplay
      }
    }

    if (sortProperty === 'next_appointment' || showUnscheduledOnly) {
      const sortedFutureAppointments = appointments
        ?.filter((appt: Appointment) => {
          return moment(getAppointmentDateTimeObject(appt)).isAfter(moment())
        })
        .sort((a: Appointment, b: Appointment) => {
          return getAppointmentDateTimeObject(a).valueOf() - getAppointmentDateTimeObject(b).valueOf()
        })
      clientsToDisplay.forEach((client: ClientV2WithNextAppointment) => {
        const nextAppointment = sortedFutureAppointments?.find(
          (appt: Appointment) => appt.userId === client.id && appt.appointmentStatus !== appointmentStatuses.canceled,
        )
        client = Object.assign(client, {
          next_appointment: nextAppointment ? getAppointmentDateTimeObject(nextAppointment) : null,
        })
      })
    } else if (sortProperty === 'session_number') {
      clientsToDisplay.forEach((client: ClientV2WithNextAppointment) => {
        client = Object.assign(client, {
          session_number: sortedAppointmentsBySessionNumber?.find((appt: Appointment) => appt.userId === client.id)
            ?.sessionNumber,
        })
      })
      shouldReverse = true
    }
    const sortedClients = clientsToDisplay.sort((a: ClientV2WithNextAppointment, b: ClientV2WithNextAppointment) => {
      let aVal = a[sortProperty]
      let bVal = b[sortProperty]
      if (['next_appointment', 'session_number'].includes(sortProperty)) {
        if (!aVal && !bVal) return 0
        if (!aVal) return 1
        if (!bVal) return -1
      }
      if (typeof aVal === 'string') aVal = aVal.toLowerCase()
      if (typeof bVal === 'string') bVal = bVal.toLowerCase()
      let result
      if (aVal < bVal) {
        result = -1
      } else if (aVal > bVal) {
        result = 1
      } else {
        result = 0
      }
      return shouldReverse ? -result : result
    })

    return sortedClients
      .filter((client: ClientV2WithNextAppointment) => {
        return showUnscheduledOnly ? !client.next_appointment : client
      })
      .filter((client: ClientV2WithNextAppointment) => {
        return filterByEpisodeProgress(client)
      })
      .map((client: ClientV2WithNextAppointment) => {
        return (
          <DefaultButton
            unstyled={true}
            key={client.id}
            data-test-id={`ClientList-client-list-item-${client.id}`}
            customClass={`${styles['client-list-item']} ${client.id === selectedClient?.id ? styles.selected : ''}`}
            onClick={() => {
              // @ts-expect-error TS(2345): Argument of type 'ClientV2WithNextAppointment' is ... Remove this comment to see the full error message
              onSelectClient && onSelectClient(client)
            }}
          >
            {getClientFullName(client, isPreferredNameEnabled)}
          </DefaultButton>
        )
      })
  }, [
    appointments,
    clientsToDisplay,
    isPreferredNameEnabled,
    onSelectClient,
    selectedClient?.id,
    selectedEpisodeProgressOption,
    showUnscheduledOnly,
    sortProperty,
  ])

  return (
    <div className={styles['list-container']}>
      {isEmpty(clients) || isEmpty(appointments) ? (
        <LoadingIndicator size={45} />
      ) : (
        <>
          <div className={styles['search-bar-container']}>
            <input
              className={styles.search}
              placeholder='Search by name or lyra id'
              data-test-id='ClientList-search'
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setSearchValue(event.target.value)
              }}
              value={searchValue}
            />
            {searchValue ? (
              <div className={styles.resetSearchBtn}>
                <UnstyledIconButton
                  onPress={() => {
                    setSearchValue('')
                  }}
                  leftIcon={<XIcon size={15} fillColor={colors.ui_oatmeal5} />}
                />
              </div>
            ) : (
              <div className={styles.searchIcon}>
                <SearchIcon size={24} fillColor={colors.ui_oatmeal5} />
              </div>
            )}
          </div>
          <div data-test-id='ClientList-clients-sortedClients' className={styles['clients-container']}>
            {clients &&
              (sortedClients.length === 0 ? (
                <div className={styles['no-results']} data-testid='ClientList-noResults'>
                  No results
                </div>
              ) : (
                sortedClients
              ))}
          </div>
        </>
      )}
    </div>
  )
}

type ClientListProps = {
  appointments?: Appointment[]
  clientsActive?: ClientListClientObject[]
  clientsInactive?: ClientListClientObject[]
  clientsForSelectedProgram?: ClientListClientObject[]
  selectedClient: ClientListClientObject | null
  onSelectClient?: (client: ClientObject | null) => void
  programFilter: string | null
  selectedEpisodeProgressOption: string
  showActiveClients?: boolean
  showUnscheduledOnly: boolean
  sortProperty: string
  actions: {
    getLtClientsV2: ({
      providerId,
      status,
      cancelToken,
    }: {
      providerId: string
      status: boolean
      cancelToken: string
    }) => { action: string; request: { method: string; url: string; params: {}; cancelToken: any } }
    getLtClientsV2ByProgram: ({
      providerId,
      status,
      programName,
      cancelToken,
    }: {
      providerId: string
      status: boolean
      programName: string
      cancelToken: string
    }) => { action: string; request: { method: string; url: string; params: {}; cancelToken: any } }
  }
}

const mapStateToProps = ($$state: Map<string, any>, ownProps: ClientListProps) => {
  return {
    clientsActive: getActiveV2Clients($$state),
    clientsInactive: getInactiveV2Clients($$state),
    appointments: getLTVideoAppointments($$state),
    clientsForSelectedProgram:
      getClientsDataFiltered($$state)?.[
        `${ownProps.programFilter}-${ownProps.showActiveClients ? 'active' : 'inactive'}`
      ] || [],
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  actions: bindActionCreators(
    {
      getLtClientsV2,
      getLtClientsV2ByProgram,
    },
    dispatch,
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(toJS(ClientList))
