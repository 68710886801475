import { get, cloneDeep } from 'lodash-es'
import { UIMetadata } from '../lyraTherapy/UIMetadata'

export const getUISchema = (content: $TSFixMe) => {
  const metaData = content.meta_data || get(content, 'content_meta_data') || content
  const schema = metaData.schema
  const name = content.name || get(content, 'content.name')
  return (
    metaData.uiSchema ??
    get(UIMetadata[name], `uiSchema.${schema.version}`) ??
    get(UIMetadata[name], `uiSchema.default`) ??
    get(UIMetadata[name], `uiSchema`)
  )
}

export const getSectionHeaders = (content: $TSFixMe, fields: $TSFixMe, headers = []) => {
  const uiSchema = getUISchema(content)

  // @ts-expect-error TS(7030): Not all code paths return a value.
  uiSchema['ui:order'].forEach((field: $TSFixMe) => {
    if (get(fields[field], 'sectionHeader')) {
      // @ts-expect-error TS(2322): Type 'any' is not assignable to type 'never'.
      return headers.push({ ...fields[field], name: field })
    } else if (get(fields[field], 'items')) {
      // Transform `items` array to an object
      const subFields = fields[field].items.reduce(
        // @ts-expect-error TS(7006): Parameter 'obj' implicitly has an 'any' type.
        (obj, item) => ({
          [item.name]: item,
          ...obj,
        }),
        {},
      )
      return getSectionHeaders(content, subFields, headers)
    }
  })
  return headers
}

export const extendMetadata = (content1: $TSFixMe, content2: $TSFixMe, reverseOrder = false) => {
  const content = cloneDeep(content1)
  content.meta_data.schema.properties = {
    ...content1.meta_data.schema.properties,
    ...content2.meta_data.schema.properties,
  }
  content.meta_data.uiSchema = { ...content1.meta_data.uiSchema, ...content2.meta_data.uiSchema }
  content.meta_data.uiSchema['ui:order'] = !reverseOrder
    ? [...content1.meta_data.uiSchema['ui:order'], ...content2.meta_data.uiSchema['ui:order']]
    : [...content2.meta_data.uiSchema['ui:order'], ...content1.meta_data.uiSchema['ui:order']]

  content.meta_data.initialValues = { ...content1.meta_data.initialValues, ...content2.meta_data.initialValues }
  return content
}

export const removeMetadataField = (contentMetadata: $TSFixMe, fieldToRemove: $TSFixMe) => {
  const cloned = cloneDeep(contentMetadata)

  if (cloned?.meta_data?.schema?.properties[fieldToRemove]) {
    delete cloned.meta_data.schema.properties[fieldToRemove]
  }

  if (cloned?.meta_data?.uiSchema && Array.isArray(cloned.meta_data.uiSchema['ui:order'])) {
    cloned.meta_data.uiSchema['ui:order'] = cloned.meta_data.uiSchema['ui:order'].filter(
      (item) => item !== fieldToRemove,
    )
  }

  if (cloned?.meta_data?.uiSchema[fieldToRemove]) {
    delete cloned.meta_data.uiSchema[fieldToRemove]
  }

  return cloned
}

export const getFieldSchema = (name: $TSFixMe, fields: $TSFixMe, fieldSchema = []) => {
  Object.entries(fields).forEach(([fieldName, schema]) => {
    if (fieldName === name) {
      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      fieldSchema.push(schema)
    } else if ((schema as $TSFixMe).items) {
      // Transform `items` array to an object
      const subFields = (schema as $TSFixMe).items.reduce(
        // @ts-expect-error TS(7006): Parameter 'obj' implicitly has an 'any' type.
        (obj, item) => ({
          [item.name]: item,
          ...obj,
        }),
        {},
      )
      return getFieldSchema(name, subFields, fieldSchema)
    }
  })
  return fieldSchema[0]
}

export default { extendMetadata, getSectionHeaders, getFieldSchema, removeMetadataField }
