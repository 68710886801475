import React from 'react'
import { useParams } from 'react-router-dom'

import { SchedulingWalkthrough1 } from './SchedulingWalkthrough1'
import { SchedulingWalkthrough2 } from './SchedulingWalkthrough2'
import { SchedulingWalkthrough4 } from './SchedulingWalkthrough4'
import { SchedulingWalkthrough5 } from './SchedulingWalkthrough5'
import { SchedulingWalkthrough6 } from './SchedulingWalkthrough6'
import SchedulingWalkthroughFinal from './SchedulingWalkthroughFinal'

const schedulingSteps = {
  SchedulingWalkthrough1,
  SchedulingWalkthrough2,
  SchedulingWalkthrough3: SchedulingWalkthroughFinal,
  SchedulingWalkthrough4,
  SchedulingWalkthrough5,
  SchedulingWalkthrough6,
  SchedulingWalkthrough7: SchedulingWalkthroughFinal,
}

export const SchedulingWalkthroughStep: React.FC = () => {
  const { pageIndex } = useParams()

  const SelectedStep = schedulingSteps['SchedulingWalkthrough' + pageIndex]

  return <SelectedStep />
}
