import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { tID } from '../../utils'

/**
 * Alert Loud Icon - triangle icon with an exclaimation mark to show high urgency
 */
export const AlertLoudIcon: FunctionComponent<AlertLoudIconProps> = ({ width = 16, height = 14, fillColor }) => {
  const { colors } = useTheme()
  return (
    <Svg width={width} height={height} style={{ overflow: 'visible' }} fill='none' testID={tID('AlertLoudIcon')}>
      <Path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7 .261a2.042 2.042 0 0 1 2.744.72l.002.003 5.979 9.95A2.043 2.043 0 0 1 13.98 14H2.02a2.042 2.042 0 0 1-1.745-3.066l.005-.01L6.254.985 4.842 3.606 6.256.981c.182-.3.44-.548.745-.72Zm.333 1.384-5.97 9.934a.763.763 0 0 0 .282 1.04c.116.069.25.106.385.108h11.94a.786.786 0 0 0 .666-.39.763.763 0 0 0 0-.758L8.668 1.646l-.001-.001A.775.775 0 0 0 8 1.273a.786.786 0 0 0-.667.372Z'
        fill={fillColor || colors.iconError}
      />
      <Path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8 8.833a.75.75 0 0 1-.75-.75V5.417a.75.75 0 0 1 1.5 0v2.666a.75.75 0 0 1-.75.75ZM8.75 10.75a.75.75 0 0 1-.75.75h-.007a.75.75 0 0 1 0-1.5H8a.75.75 0 0 1 .75.75Z'
        fill={fillColor || colors.iconError}
      />
    </Svg>
  )
}

type AlertLoudIconProps = {
  width?: string | number
  height?: string | number
  fillColor?: string
}
