import React, { FunctionComponent } from 'react'

import { useTheme } from 'styled-components/native'

import { BaseButton, BaseButtonProps, ButtonModifier, ButtonType } from '../baseButton/BaseButton'
import { XIcon } from '../icons/XIcon'

export type UnstyledIconButtonProps = Omit<BaseButtonProps, 'size' | 'text'>

export const UnstyledIconButton: FunctionComponent<UnstyledIconButtonProps> = (props) => {
  const { colors } = useTheme()
  return (
    <BaseButton
      {...props}
      buttonType={ButtonType.ICON}
      modifier={ButtonModifier.UNSTYLED}
      leftIcon={props.leftIcon ?? <XIcon size={24} />}
      iconColor={colors.iconDefault}
    />
  )
}
