import React, { FunctionComponent, useState } from 'react'
import { FieldRenderProps } from 'react-final-form'
import { LayoutChangeEvent, TextProps, View } from 'react-native'

import { useTheme } from 'styled-components/native'

import { getRadioButtonWidth, RadioButtonSize, RadioButtonType, RadioGroup } from './RadioButtons'
import { getRadioButtonStyles } from './styles/radioButtonStyles'
import { IS_WEB } from '../../constants'
import { useMediaQuerySize } from '../../hooks'
import { BodyTextSize, getFontStyles, StyledText, TextType } from '../../styles'

/**
 * Handles logic to render list view or grid view for Radio Group that has images in the buttons
 * i.e. Primary Needs
 */
export const ImageRadioGroupRFF: FunctionComponent<FieldRenderProps<string | number>> = ({
  input: { value, onChange, name, onFocus, onBlur },
  meta: { touched, error },
  buttons,
  label,
  subLabel,
  subLabelComponent,
  largeSubLabel,
  contextProps,
  labelAlignment,
  readOnly,
  showDescription,
  correctValueDetails,
  correctValue,
  isYesNo = false,
  buttonType: propButtonType = RadioButtonType.GRID,
}) => {
  // When buttonType is GRID, change to to CIRCLE_TEXT_OUTLINE when text oveflows.

  // Beware! This is an incredibly hacky solution.
  // It relies on an initial duplicate rendering using dummy text components to measure the text overflow,
  // then sets curButtonType to the final buttonType.

  const [curButtonType, setCurButtonType] = useState<RadioButtonType | null>(
    propButtonType === RadioButtonType.GRID ? null : propButtonType,
  )

  const { isMinWidthLaptop, isMinWidthTablet } = useMediaQuerySize()
  const radioSize = isMinWidthTablet ? RadioButtonSize.DEFAULT : RadioButtonSize.SMALL
  const gridButtonsGap = isMinWidthLaptop ? 16 : 8

  const [containerWidth, setContainerWidth] = useState(0)
  const onLayout = (e: LayoutChangeEvent) => {
    const { width } = e.nativeEvent.layout
    setContainerWidth(width)
    if (width !== containerWidth) {
      setCurButtonType(null)
    }
  }

  const { colors } = useTheme()
  const radioButtonStyles = getRadioButtonStyles(colors)

  const buttonType = curButtonType ?? propButtonType

  const maxLines = 2
  const maxTextWidth =
    containerWidth > 0
      ? getRadioButtonWidth(containerWidth + gridButtonsGap, gridButtonsGap) -
        2 * (radioButtonStyles.grid.sizes[radioSize].paddingHorizontal + radioButtonStyles.grid.base.borderWidth)
      : 0
  const maxTextHeight = maxLines * getFontStyles(colors)[TextType.BODY][BodyTextSize.DEFAULT].lineHeight

  // When all entries are true, none of the text overflowed.
  const textFits: (null | true)[] = []
  textFits.length = buttons.length
  textFits.fill(null)
  function textDidNotOverflow(buttonIndex: number) {
    textFits[buttonIndex] = true
    if (textFits.every(Boolean)) {
      setCurButtonType(propButtonType)
    }
  }

  return (
    <View>
      {/* Dummy view that renders the text to measure its dimensions with onLayout */}
      <View
        style={{ width: maxTextWidth, position: 'absolute', opacity: 0, zIndex: -99, top: 0 }}
        aria-hidden={true}
        accessibilityHidden={true}
        importantForAccessibility={'no-hide-descendants'}
      >
        {(buttons as { label: string; value: number | string }[]).map(({ label, value }, index) => {
          // on mobile, onLayout can fire multiple times which makes the measurement inaccurate, so use onTextLayout
          // react-native-web does not support onTextLayout
          let onLayout: TextProps['onLayout']
          let onTextLayout: TextProps['onTextLayout']
          if (buttonType === RadioButtonType.GRID) {
            if (IS_WEB) {
              onLayout = (evt) => {
                if (maxTextWidth <= 0) {
                  return
                }
                const { width, height } = evt.nativeEvent.layout
                if (width > maxTextWidth + 1 || height > maxTextHeight + 1) {
                  setCurButtonType(RadioButtonType.CIRCLE_TEXT_OUTLINE)
                } else {
                  textDidNotOverflow(index)
                }
              }
            } else {
              onTextLayout = (evt) => {
                const lines = evt.nativeEvent.lines
                if (lines.length > maxLines) {
                  // Text wrapped > 2 lines
                  setCurButtonType(RadioButtonType.CIRCLE_TEXT_OUTLINE)
                } else {
                  textDidNotOverflow(index)
                }
              }
            }
          }
          return (
            <StyledText
              // Force text to re-mount to fire onLayout
              // Subsequent renders of ImageRadioGroup must re-check all text lengths and fill the textFits array
              key={[curButtonType, maxTextWidth, value].map(String).join('_')}
              style={{ flexShrink: 0, alignSelf: 'center', textAlign: 'center' }}
              type={TextType.BODY}
              size={BodyTextSize.DEFAULT}
              onLayout={onLayout}
              onTextLayout={onTextLayout}
            >
              {label}
            </StyledText>
          )
        })}
      </View>
      <View onLayout={onLayout} style={{ opacity: containerWidth === 0 || curButtonType === null ? 0 : undefined }}>
        <RadioGroup
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          label={label}
          subLabel={subLabel}
          subLabelComponent={subLabelComponent}
          contextProps={contextProps}
          labelAlignment={labelAlignment}
          largeSubLabel={largeSubLabel}
          selectedValue={value}
          buttons={buttons}
          name={name}
          error={touched && error}
          readOnly={readOnly}
          showDescription={showDescription}
          correctValueDetails={correctValueDetails}
          correctValue={correctValue}
          isYesNo={isYesNo}
          buttonType={buttonType}
          parentContainerWidth={containerWidth > 0 ? containerWidth : undefined}
          size={radioSize}
        />
      </View>
    </View>
  )
}
