import React, { FunctionComponent } from 'react'
import Svg, { ClipPath, Defs, G, Path } from 'react-native-svg'

type ProviderIdentityIllustrationProps = {
  size?: number
}

export const ProviderIdentityIllustration: FunctionComponent<ProviderIdentityIllustrationProps> = ({
  size = 48,
  ...props
}) => {
  return (
    <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill='none' {...props}>
      <G clipPath='url(#clip0_4_7676)'>
        <G clipPath='url(#clip1_4_7676)'>
          <Path
            d='M24.17 0c-3.315 0-6.525.57-9.555 1.69C7.41 4.36 2.34 10.28.715 17.935c-1.075 4.99-.94 9.605.425 14.105 1.74 5.745 6.005 10.59 11.705 13.29 2.83 1.345 6.08 2.11 9.66 2.28.46.02.93.03 1.39.03 2.87 0 5.76-.395 8.59-1.17.215-.055.425-.11.64-.175 2.54-.745 4.825-1.92 6.78-3.49 2.125-1.695 3.87-3.85 5.19-6.39.26-.5.51-1.03.78-1.66 1.51-3.5 2.235-7.42 2.11-11.34-.06-1.735-.32-3.575-.765-5.48-.735-3.09-1.93-5.995-3.46-8.41-.42-.65-.805-1.195-1.185-1.68-2.75-3.55-7.04-6.04-12.395-7.2A28.506 28.506 0 0024.17 0z'
            fill='#fff'
          />
          <Path
            d='M46 23.48c-.06-1.66-.316-3.38-.716-5.09-.655-2.755-1.73-5.475-3.205-7.8-.34-.53-.695-1.035-1.075-1.525-2.765-3.565-6.96-5.55-11.25-6.48-4.78-1.035-9.86-.735-14.455.965C8.654 6.015 4.13 11.405 2.654 18.345c-.565 2.63-.735 5.6-.48 8.455h-.02c.145 1.62.44 3.205.88 4.66 1.63 5.395 5.6 9.675 10.65 12.07 2.735 1.3 5.795 1.945 8.905 2.09 3.175.145 6.4-.255 9.38-1.075.195-.05.38-.1.575-.155 2.335-.685 4.37-1.74 6.11-3.135 1.895-1.515 3.46-3.42 4.67-5.755.265-.51.5-1.015.725-1.535 1.425-3.315 2.06-6.91 1.935-10.475l.01-.01H46z'
            fill='#FFD2C4'
          />
          <Path
            d='M23.754 23.41c.95.645 2.11.985 3.265.9.4-.03.81-.105 1.175-.285 1.445-.685 1.69-2.695.745-3.89-.02 0-.04-.02-.06-.03-.265-.1-.88-.215-.85-.585.01-.165.115-.305.245-.4.92-.77 1.175-2.005 1.115-3.205-.02.01-.06-.11-.07-.115-.195-.125-1.37-.585-2.58-2.16-1.22.605-3.145 1.925-4.33 2.23.04.5.175 1.075.295 1.565.115.48.615 1.21.575 1.69 0 .09-.03.165-.07.255-.105.235-.315.45-.49.605-.175.165-.36.315-.555.46.225 1.165.565 2.265 1.595 2.97l-.005-.005z'
            fill='#E6B087'
          />
          <Path
            d='M29.346 15.935s.03 0 .04-.01c.125-.115.275-.965.235-1.125.07.225.86.705 1.075.705.32.05.655.01.96-.105 1.72-.705 1.67-3.33.225-4.29-.45-.295-.985-.4-1.515-.315-.255.04-.5.125-.715.265-.06.04-.5.41-.535.38a3.03 3.03 0 00-1.165-.575 3.162 3.162 0 00-1.3-.04c-.115.03-.245.04-.36.03-.07-.02-.125-.04-.185-.07a3.016 3.016 0 00-.86-.215c-.49-.04-1.26 0-1.61.39-.225-.575-.95-.85-1.515-.965a2.567 2.567 0 00-1.905.32c-1.19.75-1.63 2.705-.705 3.84.305.34.715.575 1.165.665.47.1 1.045.09 1.445-.215-.01.275.06.705.305 1.37.02 0 .05-.02.07-.02 1.19-.305 3.05-1.525 4.27-2.13 1.21 1.565 2.395 1.965 2.59 2.09v.02h-.01z'
            fill='#073345'
          />
          <Path
            d='M30.274 28.025c.315-.985.77-1.955 1.135-2.885.43-1.105.83-2.325 1.32-3.51-.745-.38-1.395-.75-2.395-1.085-.33-.105-1.055-.38-1.415-.43 0 0 .01.02.02.03.95 1.19.695 2.775-.75 3.46-.36.175-.77.255-1.175.285a5.234 5.234 0 01-3.265-.9c-1.025-.705-1.36-1.38-1.585-2.54v-.03c-1.19.635-2.13.97-3.135 1.445-.625.305-1.26.705-1.835 1.115.05.555.07 1.155.03 1.74-.04.585-.135 1.165-.36 1.67-.1.225-.235.42-.4.585-.08.08-.175.115-.265.175.575.555 1.35 1.125 1.65 1.34 0 0 .02-.01.03-.02.115.07.235.155.35.225 2.25 1.425 4.23 3.285 5.67 5.52a39.608 39.608 0 011.985 3.48c1.24-2.265 2.735-4.37 4.62-6.155-.48-.695-.665-1.565-.48-2.53.07-.33.145-.665.255-.985h-.005z'
            fill='#3BA5B5'
          />
          <Path
            d='M26.65 17.705c.245.165.38-.06.36-.285-.05-.575-.155-1.125.135-1.69.09-.175-.03-.35-.215-.39-.185-.04-.305.07-.42.195-.265.285-.35.675-.35 1.055 0 .45.09.88.48 1.135l.01-.02z'
            fill='#C17C4D'
          />
          <Path
            d='M6.615 19.12c-1.465-.47-1.145 2.23-.275 2.785.09.05.185.105.215.205.01.04.01.09 0 .125-.06.88-.37 1.525-.83 2.06-.43.5-.985.9-1.565 1.3 2.355 3.285 6.01 4.985 9.87 4.7 1.435-.105 2.745-.78 3.79-1.82-.305-.215-1.075-.78-1.65-1.34-.545.34-9.46-7.62-9.565-8.025h.01v.01z'
            fill='#936457'
          />
          <Path
            d='M7.31 21.075c.07.245.126.49.166.735.04.34.06.685.05 1.025 0 .685.01 1.39.215 2.05.145.44.38.84.705 1.18.315.34.705.605 1.135.78 1.465.625 3.06.88 4.64.745.715-.06 1.435-.105 1.985-.45.09-.06.185-.1.265-.175.175-.165.315-.36.4-.585.225-.51.32-1.085.36-1.67.04-.585.02-1.18-.03-1.74 0-.09-.01-.195-.02-.285-.02-.245-.08-.9-.215-1.18-.115.185-.83-4.585-.665-4.29v-.02c.04-.39-.105-.94-.245-1.28-.135-.4-.4-.745-.745-.985-2.425-1.475-7.155-1.485-8.56 1.465-.42.88-.36 1.825-.115 2.735.105.39.245.78.4 1.155.11.255.205.53.285.79h-.01z'
            fill='#211307'
          />
          <Path
            d='M15.344 16.63a16.697 16.697 0 00-3-.1c-1.135.03-3.155.185-3.5 1.425-.265.975-.225 2.06 0 3.05.295 1.23.705 1.485 1.76 1.975.38.175.78.305 1.2.36.125.02.265.03.4.05.33.02.665.05 1.005.03.125 0 .245-.02.37-.03.07 0 .135-.01.215-.02.135-.02.265-.05.4-.07.07-.01.135-.03.205-.05.135-.03.275-.07.4-.115l.155-.06c.215-.08.42-.175.625-.285.1-.05.195-.105.285-.165.08-.05.145-.11.215-.155a3.03 3.03 0 00.33-.295l.115-.115a3.55 3.55 0 00.4-.535h.02c0-.105 0-.195-.02-.305-.1-.92-.205-1.835-.37-2.745-.06-.34-.145-.82-.285-1.26v.02c-.175-.295-.315-.5-.95-.585l.01-.02h.015z'
            fill='#936457'
          />
          <Path
            d='M14.27 19.015c.174-.245-.03-.39-.276-.37-.255.07-.49.175-.705.33-.38.295-.39.78-.34 1.21.02.4.165.78.42 1.085.115.125.52.42.77.39.04 0 .07-.02.11-.03.03-.02.06-.05.08-.08.07-.125-.02-.275-.1-.38-.47-.715-.47-1.465.05-2.15l-.01-.01v.005z'
            fill='#71483C'
          />
          <Path
            d='M3.04 31.46c1.63 5.395 5.6 9.675 10.65 12.07 2.735 1.3 5.795 1.945 8.905 2.09.86-2.735 1.915-5.435 3.285-7.925a37.872 37.872 0 00-1.985-3.48c-1.425-2.24-3.42-4.095-5.67-5.52a6.861 6.861 0 00-.35-.225s-.02.01-.03.02c-1.055 1.035-2.425 1.395-3.86 1.505-3.86.285-6.83-1.76-9.8-4.39-.39.185-1.125.635-1.985 1.2h-.02c.145 1.62.44 3.205.88 4.66l-.02-.01v.005z'
            fill='#0A475C'
          />
          <Path
            d='M41.22 33.575s-1.985-1.915-1.975-3.37l-.285.1c-.195.01-.4 0-.605-.02-.215 0-.605-.115-.81-.195h-.115c-.115 1.585-.965 2.715-2.825 2.66-.255 0-.5-.03-.745-.05-.615-.06-1.22-.185-1.8-.44-.635-.285-1.24-.235-1.575-.725-1.885 1.78-3.38 3.88-4.62 6.155-1.36 2.49-2.425 5.19-3.285 7.925 3.175.145 6.4-.255 9.38-1.075.195-.05.38-.1.575-.155 2.335-.685 4.37-1.74 6.11-3.135 1.895-1.515 3.46-3.42 4.67-5.755.265-.51.5-1.015.725-1.535-.8.155-2.65-.295-2.845-.38h.02l.005-.005z'
            fill='#086B81'
          />
          <Path
            d='M39.52 22.12c-.06 0-.106 0-.156.01-.38.06-.76.575-.85 1.145-.03.205-.06.695.325.89.225.1.47.115.705.07.235-.06.44-.185.595-.37.135-.195.215-.42.235-.655.01-.235-.04-.47-.155-.675a.847.847 0 00-.285-.295.77.77 0 00-.4-.105l-.01-.01-.005-.005z'
            fill='#FEDFB7'
          />
          <Path
            d='M38.975 30.2c0-.275.42-.605.48-.87.07-.295.07-.555.135-.85.145-.585.52-1.23.665-1.81.285-1.095.665-2.345.685-3.49 0-.33.07-.85-.09-1.145-.205-.39-.615-.615-.82-1.005-.225-.42-.52-1.015-.685-1.465-.295-.78-1.565-.315-2.13-.02-2.315 1.18-2.895 4.905-1.81 7.085.32.645 1.035 1.085 1.445 1.67.43.605.93 1.18.88 1.875.215.05.42.09.645.105.205.02.4.03.615.02v-.1l-.02-.01.005.01z'
            fill='#ECBCAB'
          />
          <Path
            d='M35.62 24.135c.235-.06.44-.185.595-.37.134-.195.214-.42.234-.655.01-.235-.04-.47-.154-.675a.847.847 0 00-.285-.295.77.77 0 00-.4-.105c-.06 0-.11 0-.155.01-.03 0-.05.03-.07.03-.175.605-.255 1.33-.235 2.05.154.03.32.05.48 0l-.01.01z'
            fill='#ECBCAB'
          />
          <Path
            d='M43.956 17.295a8.168 8.168 0 00-3.275-1.125 7.947 7.947 0 00-3.44.38c-2.365.83-3.595 2.885-4.505 5.08-.49 1.18-.89 2.405-1.32 3.51-.36.94-.83 1.895-1.135 2.885a9.49 9.49 0 00-.255.985c-.185.97 0 1.835.48 2.53.34.49.83.9 1.465 1.175.575.255 1.18.38 1.8.44.245.03.49.04.745.05 1.855.06 3.11-1.435 3.215-3.02.05-.695-.105-1.405-.535-2.015-.41-.585-1.23-1.045-1.555-1.68-.575-1.155-.685-2.62-.39-3.87.295-1.25.96-2.305 2.07-2.875.565-.295 1.495-.565 1.79.215.48 1.28 1.545 2.435 1.455 3.87-.05.83-.39 1.67-.605 2.47-.225.86-.51 1.71-.75 2.57-.52 1.835.02 4.035 1.895 4.895.195.09.4.155.605.205.03 0 .06.02.105.03a6.119 6.119 0 002.255-.02c1.425-3.315 2.06-6.91 1.935-10.475-.06-1.66-.315-3.38-.715-5.09a6.513 6.513 0 00-1.32-1.095l-.02-.03.005.005z'
            fill='#F69170'
          />
          <Path
            d='M36.94 24.545c.53.185 1.165-.91.685-1.24l-.06-.03c-.255-.11-.48.155-.595.35-.11.175-.33.675-.145.84.04.04.08.06.125.08h-.01z'
            fill='#D2947F'
          />
          <Path
            d='M40.554 22.305a1.194 1.194 0 00-.53-.5h-.01a1.159 1.159 0 00-.735-.1c-.52.09-.91.585-1.095 1.105a1.767 1.767 0 00-.535-.215c-.305-.07-.605.04-.83.155-.03-.185-.09-.37-.185-.535a1.084 1.084 0 00-.535-.5 1.275 1.275 0 00-.565-.1h-.165c-.645.11-1.105.85-1.2 1.485-.09.615.115 1.105.555 1.33.135.07.285.1.43.115.06 0 .125.02.185.02.225 0 .45-.05.655-.145.205-.095.39-.235.53-.41.165-.225.265-.5.305-.78.265-.185.53-.275.725-.225.245.06.42.135.53.285-.06.565.145 1.015.555 1.22.195.1.4.145.615.145a1.529 1.529 0 001.185-.555c.07-.1.115-.205.165-.315.08-.175.135-.37.145-.565.02-.32-.06-.635-.215-.92h.03l-.01.005zm-4.34 1.455a1.1 1.1 0 01-.595.37c-.155.04-.32.03-.48 0-.08-.02-.145-.03-.215-.06-.38-.185-.36-.685-.32-.89.08-.53.42-.995.78-1.105.03 0 .05-.03.07-.03.05 0 .105-.01.155-.01.135 0 .275.04.4.105.115.07.215.175.285.295.115.205.175.44.155.675-.01.235-.09.46-.235.655v-.01.005zm3.91.09a1.1 1.1 0 01-.595.37c-.235.055-.48.03-.705-.07-.38-.185-.35-.685-.32-.89.09-.565.47-1.075.85-1.145.05 0 .105-.01.155-.01.135 0 .275.04.4.105.115.07.215.175.285.295.115.205.175.44.155.675-.01.235-.09.46-.235.655l.01.01v.005z'
            fill='#068298'
          />
        </G>
      </G>
      <Defs>
        <ClipPath id='clip0_4_7676'>
          <Path fill='#fff' d='M0 0H48V48H0z' />
        </ClipPath>
        <ClipPath id='clip1_4_7676'>
          <Path fill='#fff' d='M0 0H48V47.64H0z' />
        </ClipPath>
      </Defs>
    </Svg>
  )
}
