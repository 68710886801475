import { defineMessages } from 'react-intl'

import { Item } from '@lyrahealth-inc/ui-core-crossplatform/src/atoms/formElements/typeAhead/TypeAheadCustomOptionsConfig'

/**
 * Helper function that generates an item array to be used for a section list
 * This is based on https://shopify.github.io/flash-list/docs/guides/section-list/ and
 * adapted to work with our TypeAhead and DropdownMenu components
 *
 * @param sections - A list of objects that have the following structure:
 * {
 *    title,  // String displayed at the top of the section in the dropdown list
 *    items,   // An array of the list options contained within this section
 * }
 * @param labelKey - Property key of T that will be used to display header strings
 *                   Note: This property key is used in divider items to uniquely identify
 *                   them since FlashList does not render duplicate items
 * @param isSeparated - If true, sets a divider at the end of each section
 * @returns A flat array with the following structure:
 * [
 *  { label: 'Even', menuItem_header: true},
 *  { label: 'zero', value: 0 },
 *  { label: 'two', value: 2 },
 *  { label: 'Even-section-divider, menuItem_divider: true '},
 *  { label: 'Odd', menuItem_header: true },
 *  { label: 'one', value: 1 },
 *  { label: 'three', value: 3 },
 * ]
 */

export interface Section<T> {
  title?: string
  items: T[]
}

export const generateTypeAheadSectionList = (sections: Section<any>[], labelKey: string, isSeparated = false) => {
  const list: Item[] = []
  sections?.forEach((section, index) => {
    if (section.title) {
      list.push({ [labelKey]: section.title, menuItem_header: true })
    }

    section.items?.forEach((item) => {
      const newItem = { ...item }
      newItem.menuItem_section = newItem.menuItem_section ?? (section.title || undefined)
      list.push(newItem)
    })

    if (isSeparated && index < sections.length - 1) {
      list.push({
        [labelKey]: `section-divider-${index}`,
        menuItem_divider: true,
      })
    }
  })

  return list
}

export const sectionListHeaderMessages = defineMessages({
  Recommended: {
    defaultMessage: 'Your organization’s health plans',
    description: 'Dropdown section header for a list of recommended health plans',
  },
  'All health plans': {
    defaultMessage: 'All health plans',
    description: 'Dropdown section header for a list of all health plans',
  },
})

export enum SECTION_LIST_HEADER {
  RECOMMENDED = 'Recommended',
  ALL_HEALTH_PLANS = 'All health plans',
}
