import React, { FunctionComponent } from 'react'

import styled from 'styled-components/native'

import {
  ASSETS_CDN_URL,
  TREATMENT_REC_PRIMARY_CARD_CDN_PREFIX as namespace,
  PRIMARY_TREATMENT_REC_IMAGE_CONTAINER_DIMENSIONS,
} from '../../constants'
import { tID } from '../../utils'
import { Image } from '../image/Image'

const ImageSizer = styled(Image)<{ height: string; width: string }>(({ height, width }) => ({
  width,
  height,
}))

const PADDING = {
  TABLET: {
    paddingTop: '25px',
    paddingLeft: '25px',
  },
  DESKTOP: {
    paddingTop: '32px',
    paddingLeft: '31px',
  },
  MOBILE: {},
}

const Container = styled.View<{ viewport: 'DESKTOP' | 'TABLET' | 'MOBILE' }>(({ viewport }) => ({
  ...PRIMARY_TREATMENT_REC_IMAGE_CONTAINER_DIMENSIONS[viewport],
  ...PADDING[viewport],
  overflow: 'hidden',
}))

const IMAGE_SIZES = {
  DESKTOP: {
    height: '253px',
    width: '348px',
  },
  TABLET: {
    height: '223px',
    width: '306px',
  },
  MOBILE: {
    height: '100%',
    width: '100%',
  },
}

function getUrl(viewport: 'DESKTOP' | 'TABLET' | 'MOBILE') {
  const fileName = viewport === 'MOBILE' ? 'childMedsMobile' : 'childMeds'
  return ASSETS_CDN_URL + namespace + fileName + '.png'
}

/**
 * Illustration to show on the treatment card for Child Meds
 */
export const ChildMedsRec: FunctionComponent<ChildMedsRec> = ({ viewport = 'DESKTOP' }) => {
  return (
    <Container viewport={viewport}>
      <ImageSizer
        {...IMAGE_SIZES[viewport]}
        testID={`${tID('ChildMedsRecIllustration')}`}
        source={{ uri: getUrl(viewport) }}
        contentFit='cover'
      />
    </Container>
  )
}

export type ChildMedsRec = {
  viewport?: 'DESKTOP' | 'TABLET' | 'MOBILE'
}
