import React from 'react'
import { Field, Form } from 'react-final-form'
import { useIntl } from 'react-intl'
import { View } from 'react-native'

import styled from 'styled-components/native'

import { ClientObject, Episode, thisFieldRequiresResponse } from '@lyrahealth-inc/shared-app-logic'
import {
  BodyText,
  BodyTextSize,
  colors,
  InputFieldRFF,
  PrimaryButton,
  RadioButtonType,
  RadioGroupRFF,
  SecondaryButton,
  Subhead,
  SubheadSize,
  ThemeType,
  tID,
} from '@lyrahealth-inc/ui-core-crossplatform'

import { episodeStates } from '../../constants/appConstants'

const HeaderContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['16px'],
}))

const CloseSessionWarningContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['16px'],
}))

const AdjacentButtonContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  position: 'relative',
  flexDirection: 'row',
  marginTop: theme.spacing['8px'],
}))

const StyledCancelButton = styled(SecondaryButton)<{ theme: ThemeType }>(({ theme }) => ({
  marginRight: theme.spacing['8px'],
}))

const UpdateEpisodeModal: React.FC<UpdateEpisodeModalProps> = ({
  clientDetails,
  selectedEpisode,
  updateEpisode,
  closeModal,
  isUpdatingEpisode,
  terminationStatuses,
}) => {
  const { formatMessage } = useIntl()

  const episodeInProgress = selectedEpisode.state === episodeStates.IN_PROGRESS

  const title = episodeInProgress
    ? `End ${clientDetails.first_name}'s current episode...`
    : `Update ${clientDetails.first_name}'s termination status...`

  return (
    <View>
      <HeaderContainer>
        <Subhead size={SubheadSize.LARGE} text={title} color={colors.ui_oatmeal6} />
      </HeaderContainer>
      <CloseSessionWarningContainer>
        <BodyText
          testID={tID('UpdateEpisodeModal-closeSessionWarning')}
          text={formatMessage({
            defaultMessage: 'Please make sure you’ve closed all sessions before ending the episode.',
            description: 'warning to provider to close all sessions before ending episode',
          })}
          size={BodyTextSize.DEFAULT}
          color={colors.red5}
        />
      </CloseSessionWarningContainer>
      <Form
        onSubmit={({ endEpisodeReason, endEpisodeComment }) => {
          updateEpisode(endEpisodeReason, endEpisodeComment)
        }}
        render={({ handleSubmit }) => {
          return (
            <>
              <Field
                name='endEpisodeReason'
                component={RadioGroupRFF}
                label={formatMessage({
                  defaultMessage: 'What is the reason?',
                  description: 'Radio question asking why the provider needs to terminate the episode',
                })}
                buttons={terminationStatuses}
                buttonType={RadioButtonType.CIRCLE_TEXT_OUTLINE}
                testID={tID('UpdateEpisodeModal-endEpisodeReason')}
                validate={(value) => (value ? undefined : formatMessage(thisFieldRequiresResponse))}
              />
              <Field
                name='endEpisodeComment'
                component={InputFieldRFF}
                label={formatMessage({
                  defaultMessage: 'Comment',
                  description: 'Text area for comments on why provider is closing session',
                })}
                testID={tID('UpdateEpisodeModal-endEpisodeComment')}
                numberOfLines={3}
                multiline
                scrollEnabled
              />
              <AdjacentButtonContainer>
                <StyledCancelButton
                  text={formatMessage({
                    defaultMessage: 'Cancel',
                    description: 'Button that cancels the process of ending the episode',
                  })}
                  accessibilityLabel={formatMessage({
                    defaultMessage: 'Cancel',
                    description: 'Button that cancels the process of ending the episode',
                  })}
                  onPress={closeModal}
                  testID={tID('UpdateEpisodeModal-cancelButton')}
                />
                <PrimaryButton
                  text={formatMessage({
                    defaultMessage: 'End Episode',
                    description: 'Button that ends the episode with the client',
                  })}
                  accessibilityLabel={formatMessage({
                    defaultMessage: 'End Episode',
                    description: 'Button that ends the episode with the client',
                  })}
                  onPress={() => {
                    handleSubmit()
                  }}
                  loading={isUpdatingEpisode}
                  testID={tID('UpdateEpisodeModal-endEpisodeButton')}
                />
              </AdjacentButtonContainer>
            </>
          )
        }}
      />
    </View>
  )
}

export default UpdateEpisodeModal

type UpdateEpisodeModalProps = {
  clientDetails: ClientObject
  selectedEpisode: Episode
  updateEpisode: (terminationStatus: string, terminationComment: string) => void
  closeModal: () => void
  isUpdatingEpisode: boolean
  terminationStatuses: { label: string; value: string }[]
}
