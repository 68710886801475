import React, { FunctionComponent } from 'react'
import Svg, { G, Mask, Path } from 'react-native-svg'

type MemberPreferenceLGBTQIAIllustrationIconProps = {
  size?: number
}

export const MemberPreferenceLGBTQIAIllustrationIcon: FunctionComponent<
  MemberPreferenceLGBTQIAIllustrationIconProps
> = ({ size = 519, ...props }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 519 519' fill='none' {...props} pointerEvents='none'>
      <Path
        d='M255.816 11.0236C220.739 11.0236 186.726 17.0468 154.683 28.8939C78.3966 57.172 24.7856 119.84 7.55748 200.865C-3.82462 253.701 -2.3631 302.551 12.0306 350.161C30.4324 410.969 75.5843 462.255 135.905 490.843C165.866 505.082 200.278 513.187 238.167 514.98C243.038 515.202 247.999 515.312 252.915 515.312C283.319 515.312 313.9 511.149 343.816 502.934C346.119 502.358 348.312 501.782 350.615 501.074C377.498 493.19 401.701 480.746 422.406 464.115C444.882 446.156 463.395 423.392 477.346 396.465C480.092 391.172 482.727 385.592 485.628 378.905C501.594 341.835 509.3 300.337 507.949 258.839C507.307 240.504 504.583 220.995 499.844 200.843C492.072 168.136 479.405 137.378 463.196 111.824C458.745 104.959 454.692 99.2016 450.64 94.0199C421.52 56.4413 376.125 30.0897 319.436 17.7997C298.62 13.2823 277.207 11.0015 255.816 11.0015V11.0236Z'
        fill='white'
      />
      <Mask
        id='mask0_3456_28228'
        mask-type='luminance'
        maskUnits='userSpaceOnUse'
        x={21}
        y={32}
        width={467}
        height={463}
      >
        <Path
          d='M486.906 259.568C486.286 241.986 483.607 223.784 479.355 205.67C472.424 176.506 461.042 147.74 445.43 123.116C441.821 117.536 438.079 112.155 434.048 106.973C404.774 69.2173 360.397 48.2246 314.979 38.3926C264.402 27.4313 210.592 30.6422 161.985 48.6232C91.6332 74.6869 43.7354 131.797 28.1238 205.249C22.1227 233.084 20.3733 264.529 23.0528 294.733H22.8535C24.4036 311.895 27.5038 328.658 32.154 344.071C49.4265 401.18 91.4339 446.487 144.912 471.82C173.877 485.572 206.251 492.414 239.158 493.964C272.772 495.514 306.919 491.285 338.474 482.582C340.534 482.073 342.504 481.542 344.586 480.921C369.299 473.68 390.823 462.498 409.247 447.705C429.309 431.673 445.873 411.5 458.695 386.765C461.485 381.383 463.965 376.002 466.357 370.533C481.459 335.456 488.191 297.391 486.84 259.635L486.951 259.524L486.906 259.568Z'
          fill='white'
        />
      </Mask>
      <G mask='url(#mask0_3456_28228)'>
        <Path
          d='M298.878 522.817C452.363 522.817 576.787 398.393 576.787 244.908C576.787 91.4232 452.363 -33.001 298.878 -33.001C145.393 -33.001 20.9688 91.4232 20.9688 244.908C20.9688 398.393 145.393 522.817 298.878 522.817Z'
          fill='#FFF3F0'
        />
        <Path
          d='M-106.523 206.952C-106.877 198.98 98.4877 123.424 181.462 388.933C264.436 654.441 284.078 212.222 284.078 212.222L170.39 56.6816L-30.5907 89.7207L-106.523 206.952Z'
          fill='#FFBFC0'
        />
        <Path
          d='M-12.5938 74.976C-10.0029 67.4249 208.759 71.8758 189.272 349.386C169.808 626.875 349.22 222.213 349.22 222.213L300.015 35.9359L100.829 -6.53662L-12.5938 74.976Z'
          fill='#85CCD5'
        />
        <Path
          d='M110.385 6.39213C114.194 -0.627569 329.169 40.1177 263.866 310.52C198.563 580.922 442.724 211.691 442.724 211.691L425.164 19.8336L235.787 -55.1465L110.385 6.39213Z'
          fill='#A2633E'
        />
        <Path
          d='M220.252 -39.6461C224.681 -46.2894 435.05 13.8763 345.366 277.215C255.683 540.553 532.485 195.082 532.485 195.082V2.42776L322.115 -70.6479L220.252 -39.6461Z'
          fill='#602508'
        />
        <Path
          d='M36.4707 516.173C36.4707 509.53 51.9716 356.735 171.55 285.874C291.128 215.013 364.204 241.586 419.565 172.939C474.925 104.292 446.138 2.42871 446.138 2.42871L585.646 66.6468L654.293 374.45L473.087 584.82L91.8311 620.251L36.4707 516.173Z'
          fill='#D96D4A'
        />
        <Path
          d='M82.9414 556.032C82.9414 549.389 98.4423 396.594 218.021 325.733C337.599 254.871 410.675 281.444 466.035 212.798C521.396 144.151 492.608 42.2876 492.608 42.2876L632.116 106.506L700.763 414.309L519.558 624.679L138.302 660.109L82.9414 556.032Z'
          fill='#FCB097'
        />
        <Path
          d='M125.057 591.464C125.057 584.821 140.558 432.026 260.136 361.165C379.714 290.304 452.79 316.877 508.15 248.23C563.511 179.583 534.723 77.7197 534.723 77.7197L674.232 141.938L742.879 449.741L561.673 660.111L180.417 695.542L125.057 591.464Z'
          fill='#FFD793'
        />
        <Path
          d='M178.211 622.467C178.211 615.824 193.712 463.03 313.29 392.168C432.869 321.307 505.944 347.88 561.305 279.233C616.665 210.586 587.878 108.723 587.878 108.723L727.386 172.941L796.033 480.745L614.827 691.114L233.571 726.545L178.211 622.467Z'
          fill='#8CC94B'
        />
        <Path
          d='M222.498 664.539C222.498 657.896 237.999 505.101 357.577 434.24C477.156 363.378 550.232 389.951 605.592 321.304C660.952 252.658 632.165 150.794 632.165 150.794L771.673 215.012L840.32 522.816L659.114 733.186L277.858 768.616L222.498 664.539Z'
          fill='#3BA5B5'
        />
        <Path
          d='M277.826 688.897C277.826 682.253 293.327 529.459 412.906 458.598C532.484 387.736 605.56 414.309 660.92 345.662C716.28 277.015 687.493 175.152 687.493 175.152L827.001 239.37L895.648 547.174L714.442 757.544L333.187 792.974L277.826 688.897Z'
          fill='#086B81'
        />
      </G>
    </Svg>
  )
}
