import React, { useState } from 'react'
import CSSModules from 'react-css-modules'
import { connect, useSelector } from 'react-redux'

import { format, parseISO } from 'date-fns'
import { Map } from 'immutable'
import { isEmpty } from 'lodash-es'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'

import { Transfer } from '@lyrahealth-inc/shared-app-logic'
import { BaseModal, dateUtils, LoadingIndicator, SecondaryButton } from '@lyrahealth-inc/ui-core'

import styles from './paymentHistoryDashboard.module.scss'
import DownloadCSVButton from '../common/components/downloadCSV/DownloadCSVButton'
import ExcludePaymentForm from '../common/components/forms/excludePaymentModal/ExcludePaymentForm'
import RejectPaymentForm from '../common/components/forms/rejectPaymentModal/RejectPaymentForm'
import ReversePaymentForm from '../common/components/forms/reversePaymentModal/ReversePaymentForm'
import PaymentCard from '../common/components/paymentCard/PaymentCard'
import PaymentDetailsModal from '../common/components/paymentDetailsModal/PaymentDetailsModal'
import {
  INVOICE_NUMBER_PER_PAGE,
  paymentExclusionReasons,
  paymentRejectionReasons,
  paymentReversalReasons,
  ROLES,
} from '../common/constants/appConstants'
import { GET_PRACTICE_PAYMENT_HISTORY, GET_PROVIDER_PAYMENT_HISTORY } from '../common/constants/reduxConstants'
import { hasErrorForAction, hasRole } from '../common/utils/utils'
import { getAlertsState } from '../data/alertsSelectors'
import { Alert } from '../data/alertTypes'
import { getAuthRoles } from '../data/auth/authSelectors'
import { downloadPayments, updateChargeStatus } from '../payments/data/paymentsDataActions'
import { getPracticePaymentHistory } from '../practices/individualPractice/data/practiceDetailsActions'
import { getPracticeDetails } from '../practices/individualPractice/data/practiceDetailsSelectors'
import { getProvidersDetails } from '../providers/data/providerSelectors'
import { getProviderPaymentHistory } from '../providers/individualProvider/data/providerDetailsActions'

const PaymentHistoryDashboard: React.FC<PaymentHistoryDashboardProps> = ({
  isPractice,
  lyraID,
  historyData,
  actions: { getPracticePaymentHistory, getProviderPaymentHistory, downloadPayments, updateChargeStatus },
}) => {
  const roles = useSelector(getAuthRoles)
  const alerts: Alert[] = useSelector(getAlertsState)
  const [loadingMore, setLoadingMore] = useState(false)
  const [reversePayment, setReversePayment] = useState(false)
  const [rejectPayment, setRejectPayment] = useState(false)
  const [excludePayment, setExcludePayment] = useState(false)
  const [viewPayment, setViewPayment] = useState(false)
  const [modalData, setModalData] = useState<any>({})
  const [noMoreForType, setNoMoreForType] = useState({ all: false })

  const onViewClick = (data: Map<string, any>) => {
    setReversePayment(false)
    setRejectPayment(false)
    setExcludePayment(false)
    setViewPayment(true)
    setModalData(data)
  }

  const onReverseClick = (chargeData: Map<string, any>) => {
    setReversePayment(true)
    setRejectPayment(false)
    setExcludePayment(false)
    setViewPayment(false)
    setModalData(chargeData)
  }

  const onRejectClick = (chargeData: Map<string, any>) => {
    setRejectPayment(true)
    setReversePayment(false)
    setExcludePayment(false)
    setViewPayment(false)
    setModalData(chargeData)
  }

  const onExcludeClick = (chargeData: Map<string, any>) => {
    setExcludePayment(true)
    setReversePayment(false)
    setRejectPayment(false)
    setViewPayment(false)
    setModalData(chargeData)
  }

  const renderPaymentsList = () => {
    if (!historyData || !('all' in historyData)) {
      const action = isPractice ? GET_PRACTICE_PAYMENT_HISTORY : GET_PROVIDER_PAYMENT_HISTORY
      if (hasErrorForAction(alerts, action)) {
        return false // custom workflow or display could render here
      } else {
        if (isPractice) {
          getPracticePaymentHistory({ practiceID: lyraID, page: 0, limit: INVOICE_NUMBER_PER_PAGE })
        } else {
          getProviderPaymentHistory({ providerID: lyraID, page: 0, limit: INVOICE_NUMBER_PER_PAGE })
        }
        return (
          <div className={styles['loading-container']}>
            <LoadingIndicator size={45} />
          </div>
        )
      }
    }

    const paymentsList = historyData.all.data

    if (paymentsList.length === 0) {
      return <div className={styles['loading-container']}>No payments found.</div>
    }

    return (
      <div>
        {paymentsList.map((payment, i) => {
          const key = `$$historyData${i}`

          const conflictingDates: string[] = []
          const statusToShowConflicts = ['SUBMITTED', 'PENDING', 'APPROVED']
          const showConflicts = statusToShowConflicts.includes(payment.status.toUpperCase())
          if (
            showConflicts &&
            payment.is_too_frequent_with_charge_ids !== null &&
            payment.is_too_frequent_with_charge_ids.length !== 0 &&
            Array.isArray(payment.is_too_frequent_with_charge_ids)
          ) {
            payment.is_too_frequent_with_charge_ids.map((charge) => {
              return conflictingDates.push(format(parseISO(charge.date_of_service), 'MM/dd/yyyy'))
            })
          }

          return (
            <PaymentCard
              readonly={hasRole(roles, ROLES.VIEW_ONLY)}
              data={payment}
              // @ts-expect-error TS(2322): Type 'string[]' is not assignable to type 'never[]... Remove this comment to see the full error message
              conflicts={conflictingDates}
              type='patient'
              key={key}
              viewClick={onViewClick}
              reverseClick={onReverseClick}
              rejectClick={onRejectClick}
              excludeClick={onExcludeClick}
            />
          )
        })}
      </div>
    )
  }

  const onCSVDownloadClick = ($$values: Map<string, any>) => {
    const startDate = dateUtils.getDisplayDate($$values.get('start_date'), 'YYYY-MM-DD')
    const endDate = dateUtils.getDisplayDate($$values.get('end_date'), 'YYYY-MM-DD')
    if (isPractice) {
      return downloadPayments($$values.get('payment_status'), startDate, endDate, undefined, lyraID)
    } else {
      return downloadPayments($$values.get('payment_status'), startDate, endDate, lyraID)
    }
  }

  const loadMore = () => {
    const currentPage = historyData?.all?.page
    const nextPage = parseInt(currentPage, 10) + 1

    setLoadingMore(true)
    const action = isPractice
      ? getPracticePaymentHistory({ practiceID: lyraID, page: nextPage, limit: INVOICE_NUMBER_PER_PAGE })
      : getProviderPaymentHistory({ providerID: lyraID, page: nextPage, limit: INVOICE_NUMBER_PER_PAGE })
    action.then(
      (successObj) => {
        const noMores = noMoreForType
        noMores.all = successObj.data.length < INVOICE_NUMBER_PER_PAGE

        setLoadingMore(false)
        setNoMoreForType(noMores)
      },
      () => {
        setLoadingMore(false)
      },
    )
  }

  const submitReversePayment = (values: Map<string, any>) => {
    return new Promise((resolve) => {
      let chargeDescription = values.get('description')
      if (chargeDescription === 'other') {
        chargeDescription = values.get('description_text_area')
      } else {
        chargeDescription = paymentReversalReasons[chargeDescription]
      }
      updateChargeStatus(modalData.chargeId, modalData.payload, modalData.is_too_frequent, chargeDescription)
      setReversePayment(false)
      resolve(true)
    })
  }

  const submitRejectPayment = (values: Map<string, any>) => {
    return new Promise((resolve) => {
      let rejectionDescription
      if (values.get('description') === 'other') {
        rejectionDescription = values.get('description_text_area')
      } else {
        rejectionDescription = paymentRejectionReasons[values.get('description')]
      }
      updateChargeStatus(modalData.chargeId, modalData.payload, modalData.is_too_frequent, rejectionDescription)
      setRejectPayment(false)
      resolve(true)
    })
  }

  const submitExcludePayment = (values: Map<string, any>) => {
    return new Promise((resolve) => {
      let exclusionDescription
      if (values.get('description') === 'other') {
        exclusionDescription = values.get('description_text_area')
      } else {
        exclusionDescription = paymentExclusionReasons[values.get('description')]
      }
      updateChargeStatus(modalData.chargeId, modalData.payload, modalData.is_too_frequent, exclusionDescription)
      setExcludePayment(false)
      resolve(true)
    })
  }

  const ModalData = (
    <PaymentDetailsModal
      // @ts-expect-error TS(2322): Type '{ type: string; viewData: any; closeModal: (... Remove this comment to see the full error message
      type='patient'
      viewData={viewPayment ? modalData : {}}
      closeModal={() => setViewPayment(false)}
    />
  )

  // Populate initial values for RejectPaymentForm if there are any
  let initialRejectionValues
  const prevRejectionComment = rejectPayment && modalData ? modalData.admin_note : undefined
  if (prevRejectionComment) {
    if (Object.values(paymentRejectionReasons).includes(prevRejectionComment)) {
      const rejectionReason = Object.keys(paymentRejectionReasons).find(
        (key) => paymentRejectionReasons[key] === prevRejectionComment,
      )
      initialRejectionValues = {
        description: rejectionReason,
      }
    } else {
      initialRejectionValues = {
        description: 'other',
        description_text_area: prevRejectionComment,
      }
    }
  }

  let initialReversalValues
  const prevReversalComment = reversePayment && modalData ? modalData.admin_note : undefined
  if (prevReversalComment) {
    if (Object.values(paymentReversalReasons).includes(prevReversalComment)) {
      const reversalReason = Object.keys(paymentReversalReasons).find(
        (key) => paymentReversalReasons[key] === prevReversalComment,
      )
      initialReversalValues = {
        description: reversalReason,
      }
    } else {
      initialReversalValues = {
        description: 'other',
        description_text_area: prevReversalComment,
      }
    }
  }

  let initialExclusionValues
  const prevExclusionComment = excludePayment && modalData ? modalData.admin_note : undefined
  if (prevExclusionComment) {
    if (Object.values(paymentExclusionReasons).includes(prevExclusionComment)) {
      const exclusionReason = Object.keys(paymentExclusionReasons).find(
        (key) => paymentExclusionReasons[key] === prevExclusionComment,
      )
      initialExclusionValues = {
        description: exclusionReason,
      }
    } else {
      initialExclusionValues = {
        description: 'other',
        description_text_area: prevExclusionComment,
      }
    }
  }

  const ReversePaymentModal = (
    // @ts-expect-error TS(2322): Type '{ submitFunction: (values: Map<string, any>)... Remove this comment to see the full error message
    <ReversePaymentForm submitFunction={submitReversePayment} showReverseText initialValues={initialReversalValues} />
  )

  const RejectPaymentModal = (
    // @ts-expect-error TS(2322): Type '{ submitFunction: (values: Map<string, any>)... Remove this comment to see the full error message
    <RejectPaymentForm submitFunction={submitRejectPayment} showRejectText initialValues={initialRejectionValues} />
  )

  const ExcludePaymentModal = (
    // @ts-expect-error TS(2322): Type '{ submitFunction: (values: Map<string, any>)... Remove this comment to see the full error message
    <ExcludePaymentForm submitFunction={submitExcludePayment} showExcludeText initialValues={initialExclusionValues} />
  )

  let modalToShow
  if (reversePayment) {
    modalToShow = (
      <BaseModal isOpen={reversePayment} body={ReversePaymentModal} closeModal={() => setReversePayment(false)} />
    )
  } else if (rejectPayment) {
    modalToShow = (
      <BaseModal isOpen={rejectPayment} body={RejectPaymentModal} closeModal={() => setRejectPayment(false)} />
    )
  } else if (excludePayment) {
    modalToShow = (
      <BaseModal isOpen={excludePayment} body={ExcludePaymentModal} closeModal={() => setExcludePayment(false)} />
    )
  } else {
    modalToShow = <BaseModal isOpen={viewPayment} body={ModalData} closeModal={() => setViewPayment(false)} />
  }

  const hasPayments = !!historyData && 'all' in historyData && !isEmpty(historyData.all.data)
  return (
    <div>
      {hasPayments ? (
        <div styleName='top-container'>
          {/* @ts-expect-error TS(2322): Type '($$values: Map<string, any>) => Dispatch<Any... Remove this comment to see the full error message */}
          <DownloadCSVButton downloadClick={onCSVDownloadClick} />
        </div>
      ) : (
        ''
      )}
      {renderPaymentsList()}
      {noMoreForType.all ? (
        <div styleName='end-of-list-spacer' />
      ) : (
        <div styleName='load-more'>
          {loadingMore ? (
            <LoadingIndicator size={30} />
          ) : hasPayments ? (
            <SecondaryButton onClick={loadMore}>Load More</SecondaryButton>
          ) : (
            []
          )}
        </div>
      )}
      {modalToShow}
    </div>
  )
}

const mapStateToProps = ($$state: Map<string, any>, props: PaymentHistoryDashboardProps) => {
  const details = props.isPractice ? getPracticeDetails($$state) : getProvidersDetails($$state)
  return {
    lyraID: props.isPractice ? details?.data?.id : details?.data?.lyra_id,
    historyData: details.paymentHistory,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  actions: bindActionCreators(
    {
      getProviderPaymentHistory,
      getPracticePaymentHistory,
      downloadPayments,
      updateChargeStatus,
    },
    dispatch,
  ),
})

type PaymentHistoryDashboardProps = {
  isPractice: boolean
  lyraID: string
  historyData: {
    [status: string]: {
      data: Transfer[]
      page: string
    }
  }
  actions: {
    getPracticePaymentHistory: (params: object) => Promise<any>
    getProviderPaymentHistory: (params: object) => Promise<any>
    downloadPayments: (
      status: string,
      start: string,
      end: string,
      providerId?: string,
      practiceId?: string,
    ) => Dispatch<AnyAction>
    updateChargeStatus: (
      id: string,
      values: Map<string, any>,
      isTooFrequent: boolean,
      description?: string,
    ) => Dispatch<AnyAction>
  }
}

// @ts-expect-error TS(2345): Argument of type 'FC<PaymentHistoryDashboardProps>... Remove this comment to see the full error message
export default connect(mapStateToProps, mapDispatchToProps)(CSSModules(PaymentHistoryDashboard, styles))
