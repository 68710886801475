import { createReducer } from '@reduxjs/toolkit'

import { ClientObject } from '@lyrahealth-inc/shared-app-logic'

import { CLEAR_CLIENT_DETAILS, SET_CLIENT_DETAILS } from '../../../common/constants/reduxConstants'

type ClientDetailsState = {
  data: ClientObject | {}
}
const initialState = {
  data: {},
}

export default createReducer<ClientDetailsState>(initialState, (builder) => {
  builder.addCase(SET_CLIENT_DETAILS, (state, action: any) => {
    state.data = action.data
    return state
  })
  builder.addCase(CLEAR_CLIENT_DETAILS, (state) => {
    state.data = {}
    return state
  })
})
