import { createReducer } from '@reduxjs/toolkit'

import { Prescription } from '@lyrahealth-inc/shared-app-logic'

import { CLEAR_CLIENT_PRESCRIPTIONS, GET_CLIENT_PRESCRIPTIONS } from '../../../common/constants/reduxConstants'

type MedicationsState = {
  prescriptions: Prescription[]
}

const initialState = {
  prescriptions: [],
}

export default createReducer<MedicationsState>(initialState, (builder) => {
  builder.addCase(GET_CLIENT_PRESCRIPTIONS, (state, action: any) => {
    state.prescriptions = action.data.Items
    return state
  })
  builder.addCase(CLEAR_CLIENT_PRESCRIPTIONS, () => initialState)
})
