import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { ThemeType } from '../../utils'

export const MinimizeIcon: FunctionComponent<MinimizeIconProps> = ({ size = 24, fillColor }) => {
  const { colors } = useTheme() as ThemeType

  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' fill='none'>
      <Path
        fill={fillColor ?? colors.iconDefault}
        fillRule='evenodd'
        d='M8.3 5.078v2.184l-3.018-3.04a.75.75 0 0 0-1.064 1.056l3.026 3.05H5.05a.75.75 0 0 0 0 1.5h4a.75.75 0 0 0 .75-.75v-4a.75.75 0 1 0-1.5 0Zm10.65 3.25h-2.194l3.026-3.05a.75.75 0 0 0-1.064-1.056L15.7 7.262V5.078a.75.75 0 0 0-1.5 0v4c0 .414.336.75.75.75h4a.75.75 0 0 0 0-1.5Zm-3.25 8.438v2.183a.75.75 0 0 1-1.5 0V14.95a.75.75 0 0 1 .75-.75h4a.75.75 0 0 1 0 1.5h-2.194l3.026 3.05a.75.75 0 1 1-1.064 1.056l-3.018-3.04ZM5.05 15.7h2.194l-3.026 3.05a.75.75 0 1 0 1.064 1.056l3.018-3.04v2.183a.75.75 0 0 0 1.5 0V14.95a.75.75 0 0 0-.75-.75h-4a.75.75 0 0 0 0 1.5Z'
        clipRule='evenodd'
      />
    </Svg>
  )
}

type MinimizeIconProps = {
  size?: string | number
  fillColor?: string
}
