import { createSelector } from '@reduxjs/toolkit'

export const getClientsState = (state: Map<string, any>) => state?.get('clients')

export const getClientsData = createSelector(getClientsState, (clientsState) => clientsState.data)

export const getClientsDataClientList = createSelector(getClientsData, (data) => data.clientList)

export const getClientsDataSessionData = createSelector(getClientsData, (data) => data.sessionData)

export const getClientsDataCounts = createSelector(getClientsData, (data) => data.counts)
