import React from 'react'
import { View } from 'react-native'
import styled from 'styled-components/native'
import {
  BodyText,
  BodyTextSize,
  colors,
  Subhead,
  SubheadSize,
  ThemeType,
  tID,
} from '@lyrahealth-inc/ui-core-crossplatform'
import { benefitsProgramNames } from '../../../../common/constants/appConstants'

const ProgramContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['16px'],
}))

const SubheadContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['16px'],
}))

const BenefitsEligibility: React.FC<BenefitsEligibilityProps> = ({ currentProgramCoverageBreakdown }) => {
  const coverageStates = {
    EAP: 'Your client can use EAP sessions.',
    EAP_HP: 'After using their EAP sessions, your client can use an eligible health plan to pay for sessions.',
    EAP_SPECIALTY:
      'Your client can use EAP sessions. If they have an active substance use disorder, are at risk for hospitalization, or have treatment resistant conditions, they may continue past the recommended sessions.',
    EAP_HP_SPECIALTY:
      'After using their EAP sessions, your client can use an eligible health plan to pay for sessions. If they have an active substance use disorder, are at risk for hospitalization, or have treatment resistant conditions, they may continue past the recommended sessions.',
    HP: 'Your client can use an eligible health plan to pay for sessions.',
    HP_SPECIALTY:
      'Your client can use an eligible health plan to pay for sessions. If they have an active substance use disorder, are at risk for hospitalization, or have treatment resistant conditions, they may continue past the recommended sessions',
    SPECIALTY:
      'If your client has an active substance use disorder, is at risk for hospitalization, or has treatment resistant conditions, they may continue with sessions.',
  }

  const getIsProgramCovered = (program: string) => {
    return (
      program in currentProgramCoverageBreakdown &&
      !(
        !currentProgramCoverageBreakdown[program]?.eapSupported &&
        !currentProgramCoverageBreakdown[program]?.bhbSupported &&
        !currentProgramCoverageBreakdown[program]?.specialtyBhbSupported
      )
    )
  }

  const getProgramCoverageDescription = (program: string) => {
    if (program in currentProgramCoverageBreakdown) {
      if (
        !currentProgramCoverageBreakdown[program]?.eapSupported &&
        !currentProgramCoverageBreakdown[program]?.bhbSupported &&
        currentProgramCoverageBreakdown[program]?.specialtyBhbSupported
      ) {
        return coverageStates.SPECIALTY
      } else if (
        !currentProgramCoverageBreakdown[program]?.eapSupported &&
        currentProgramCoverageBreakdown[program]?.bhbSupported &&
        !currentProgramCoverageBreakdown[program]?.specialtyBhbSupported
      ) {
        return coverageStates.HP
      } else if (
        !currentProgramCoverageBreakdown[program]?.eapSupported &&
        currentProgramCoverageBreakdown[program]?.bhbSupported &&
        currentProgramCoverageBreakdown[program]?.specialtyBhbSupported
      ) {
        return coverageStates.HP_SPECIALTY
      } else if (
        currentProgramCoverageBreakdown[program]?.eapSupported &&
        !currentProgramCoverageBreakdown[program]?.bhbSupported &&
        !currentProgramCoverageBreakdown[program]?.specialtyBhbSupported
      ) {
        return coverageStates.EAP
      } else if (
        currentProgramCoverageBreakdown[program]?.eapSupported &&
        !currentProgramCoverageBreakdown[program]?.bhbSupported &&
        currentProgramCoverageBreakdown[program]?.specialtyBhbSupported
      ) {
        return coverageStates.EAP_SPECIALTY
      } else if (
        currentProgramCoverageBreakdown[program]?.eapSupported &&
        currentProgramCoverageBreakdown[program]?.bhbSupported &&
        !currentProgramCoverageBreakdown[program]?.specialtyBhbSupported
      ) {
        return coverageStates.EAP_HP
      } else if (
        currentProgramCoverageBreakdown[program]?.eapSupported &&
        currentProgramCoverageBreakdown[program]?.bhbSupported &&
        currentProgramCoverageBreakdown[program]?.specialtyBhbSupported
      ) {
        return coverageStates.EAP_HP_SPECIALTY
      }
    }
    return null
  }

  return (
    <View>
      {Object.keys(currentProgramCoverageBreakdown)
        .filter((program) => Object.keys(benefitsProgramNames).includes(program) && getIsProgramCovered(program))
        .map((program: string) => (
          <ProgramContainer key={program} testID={tID(`BenefitsEligibility-${program}`)}>
            <SubheadContainer>
              <Subhead
                testID={tID(`BenefitsEligibility-${program}-programName`)}
                text={benefitsProgramNames[program]}
                size={SubheadSize.XSMALL}
                color={colors.ui_oatmeal6}
              />
            </SubheadContainer>
            <BodyText
              testID={tID(`BenefitsEligibility-${program}-coverageDescription`)}
              text={getProgramCoverageDescription(program)}
              size={BodyTextSize.DEFAULT}
              color={colors.ui_oatmeal5}
            />
          </ProgramContainer>
        ))}
    </View>
  )
}

export type ProgramCoverageBreakdown = {
  blendedCareMeds?: {
    bhbSupported: boolean
    eapSupported: boolean
    specialtyBhbSupported: boolean
  }
  directAccessTherapy?: {
    bhbSupported: boolean
    eapSupported: boolean
    specialtyBhbSupported: boolean
  }
  considerLyraTherapy?: {
    bhbSupported: boolean
    eapSupported: boolean
    specialtyBhbSupported: boolean
  }
  alcoholUseDisorderTherapy?: {
    bhbSupported: boolean
    eapSupported: boolean
    specialtyBhbSupported: boolean
  }
  guidedSelfCareEnabled?: {
    bhbSupported: boolean
    eapSupported: boolean
    specialtyBhbSupported: boolean
  }
  considerMeds?: {
    bhbSupported: boolean
    eapSupported: boolean
    specialtyBhbSupported: boolean
  }
  stressManagement?: {
    bhbSupported: boolean
    eapSupported: boolean
    specialtyBhbSupported: boolean
  }
}

type BenefitsEligibilityProps = {
  currentProgramCoverageBreakdown: ProgramCoverageBreakdown
}

export default BenefitsEligibility
