import React, { ReactElement } from 'react'
import { View, ViewStyle } from 'react-native'

import { isEmpty } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import { BodyText, Headline } from '../../atoms'
import { BodyTextSize, HeadlineSize } from '../../styles/typeStyles'
import { tID } from '../../utils'

export type DataBoardItemProps = {
  value: string | number
  title?: string | ReactElement
  label?: string
  tooltip?: ReactElement
  labelTooltip?: ReactElement
  style?: ViewStyle
  titlePosition?: DataBoardItemTitlePosition
  labelStyle?: ViewStyleWithColor
  titleStyle?: ViewStyle
  infoDataStyle?: ViewStyleWithColor
  testID?: string
}

interface ViewStyleWithColor extends ViewStyle {
  color?: string | undefined
}

export enum DataBoardItemTitlePosition {
  TOP,
  BOTTOM,
}
export const DataBoardItem: React.FC<DataBoardItemProps> = ({
  value,
  title = '',
  label = '',
  tooltip,
  labelTooltip,
  style,
  infoDataStyle,
  labelStyle,
  titleStyle,
  titlePosition = DataBoardItemTitlePosition.TOP,
  testID,
}) => {
  const { colors } = useTheme()

  const InfoDataContainer = styled.View(({ theme }) => ({
    marginTop: theme.spacing['16px'],
    flexDirection: 'row',
    alignItems: 'center',
  }))

  const InfoData = styled(Headline)({
    margin: 0,
    display: 'flex',
  })

  const TooltipContainer = styled.View(({ theme }) => ({
    margin: theme.spacing['4px'],
  }))

  const TitleContainer = styled.View<{ theme: string }>(({ theme }) => ({
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: theme.spacing['16px'],
  }))

  const LabelContainer = styled.View<{ theme: string }>(({ theme }) => ({
    flexDirection: 'row',
    marginTop: theme.spacing['8px'],
    marginBottom: theme.spacing['16px'],
  }))

  const titleNode = !!title && (
    <TitleContainer style={titleStyle}>
      <BodyText size={BodyTextSize.LARGE} color={colors.textPrimary}>
        {title}
      </BodyText>
      {tooltip && <TooltipContainer>{tooltip}</TooltipContainer>}
    </TitleContainer>
  )
  return (
    <View testID={testID ?? tID(`DataBoardItem-${!isEmpty(testID) ? testID : label}-${value}`)} style={style}>
      {titlePosition === DataBoardItemTitlePosition.TOP && titleNode}
      <InfoDataContainer
        style={infoDataStyle}
        testID={tID(`DataBoardItem-${!isEmpty(testID) ? testID : label}-${value}`)}
      >
        <InfoData text={value} size={HeadlineSize.MEDIUM} color={colors.textPrimary} />
        {!title && tooltip && <TooltipContainer>{tooltip}</TooltipContainer>}
      </InfoDataContainer>
      {titlePosition === DataBoardItemTitlePosition.BOTTOM && titleNode}
      {!!label && (
        <LabelContainer style={labelStyle}>
          <BodyText size={BodyTextSize.DEFAULT} color={colors.textSecondary}>
            {label}
          </BodyText>
          {labelTooltip && <TooltipContainer>{labelTooltip}</TooltipContainer>}
        </LabelContainer>
      )}
    </View>
  )
}
