import validation from '../../../utils/validation'

const downloadCSVValidate = ($$values: any) => {
  let errors = {}

  const justRequired = [
    {
      fieldName: 'payment_status',
      errorText: 'Payment status',
    },
  ]

  errors = Object.assign(errors, validation.requiredFields($$values, justRequired))

  errors = Object.assign(
    errors,
    validation.requiredValidDate($$values, {
      fieldName: 'start_date',
      errorText: 'Start date',
    }),
  )

  errors = Object.assign(
    errors,
    validation.requiredValidDate($$values, {
      fieldName: 'end_date',
      errorText: 'End date',
    }),
  )

  errors = Object.assign(
    errors,
    validation.requiredValidDateRange(
      $$values,
      {
        fieldName: 'start_date',
        errorText: 'Start date',
      },
      {
        fieldName: 'end_date',
        errorText: 'End date',
      },
    ),
  )

  return errors
}

export default downloadCSVValidate
