import React, { FunctionComponent } from 'react'
import Svg, { ClipPath, Defs, G, Path } from 'react-native-svg'

type HowWeMeetIllustrationProps = {
  size?: number
}

export const HowWeMeetIllustration: FunctionComponent<HowWeMeetIllustrationProps> = ({ size = 48, ...props }) => {
  return (
    <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill='none' {...props}>
      <G clipPath='url(#clip0_4_7642)'>
        <G clipPath='url(#clip1_4_7642)'>
          <Path
            d='M23.494.64C18.474.64 13.74 2.07 9.8 4.77 4.654 8.3 1.284 13.66.304 19.86c-.925 5.85.29 12.04 3.42 17.436l.01.02.015.02c3.96 6.44 11.375 10.28 19.835 10.28 6.55 0 12.95-2.3 17.56-6.305 3.875-3.365 6.205-8.105 6.74-13.705.485-5.07-.585-10.335-3.005-14.82C40.84 5.296 32.644.64 23.494.64z'
            fill='#fff'
          />
          <Path
            d='M10.92 6.41C.957 13.257-.363 26.3 5.44 36.297c7.126 11.59 24.71 11.945 34.4 3.52 7.396-6.42 7.686-17.94 3.29-26.08C37.067 2.49 21.3-.71 10.92 6.41z'
            fill='#FFD2C4'
          />
          <Path
            d='M39.576 20.47c.705-1.87.595-4.214-.295-5.704-.275-.46-.65-.85-1.1-1.135-1.925-1.22-4.225-1.465-6.165-.11a5.878 5.878 0 00-1.895 2.155c-.85 1.705-.945 3.545-.185 5.295.245.575.605 1.12 1.09 1.52.685.56 1.795.815 2.655.915 1.1.135 2.245.155 3.305-.19 1.235-.41 2.1-1.455 2.575-2.73l.01-.01.005-.005z'
            fill='#35220F'
          />
          <Path
            d='M42.7 25.88c-.826-.88-2.31-1.61-4.086-2.05-2.21-.55-4.83-.694-7.3-.11-2.27.54-4.435 1.716-5.845 3.63-.915 1.246-1.65 2.6-2.255 4.02-1.155 2.73-2.255 5.506-2.705 8.43-.375 2.446 1.135 3.886 2.465 5.816 6.09.145 12.34-1.87 16.865-5.81 2.97-2.575 4.775-5.98 5.605-9.645-.67-1.575-1.565-3.03-2.74-4.285l-.01.01.005-.005z'
            fill='#095B71'
          />
          <Path
            d='M31.316 23.716c.21.56 1.485 3.61 4.985 2.915 1.87-.375 2.374-2.765 2.31-2.795-1.165-.495-1.53-.54-1.816-.925-.175-.23-.285-.595-.055-.795.96-.815 1.776-2.6 1.575-3.85-1.234-1.035-1.73-1.685-1.87-2.28-.02-.09-.34 1.1-1.76 1.83-.695.355-1.75.795-2.785.695.036.76.13 1.34.386 2.05.184.505.68 1.1.904 1.355.31.35 0 .705-.11.86-.264.375-1.12.705-1.77.935l.006.005z'
            fill='#CF8A61'
          />
          <Path
            d='M36.07 20.5c-.09-.53-.24-1.034 0-1.594.075-.175-.055-.33-.23-.35-.185-.025-.275.09-.375.21-.22.285-.275.66-.24 1.01.045.41.155.805.54 1.015.245.13.355-.09.32-.3l-.02.01h.005z'
            fill='#B26E3F'
          />
          <Path
            d='M10.92 6.41C.957 13.255-.363 26.3 5.44 36.296c4.04 6.57 11.436 9.52 18.856 9.325V2.647C19.616 2.49 14.876 3.7 10.93 6.41h-.01z'
            fill='#3BA5B5'
          />
          <Path
            d='M23.126 33.8l-1.95-5.01c-.09-.23-.265-.41-.485-.53a.928.928 0 00.22-.584v-9.4a.937.937 0 00-.935-.935H5.836a.937.937 0 00-.935.935v9.4c0 .23.09.42.22.585-.22.12-.395.3-.485.53l-1.21 3.115c.395 1.12.87 2.21 1.42 3.27H22.07c.77 0 1.31-.705 1.055-1.375z'
            fill='#FFB199'
          />
          <Path
            d='M6.495 27.79H19.31c.455 0 .825-.37.825-.824v-8.07a.826.826 0 00-.825-.825H6.495a.826.826 0 00-.825.825v8.07c0 .455.37.825.825.825z'
            fill='#FFE9E3'
          />
          <Path
            d='M12.535 24.68c-.705.21-1.365.68-1.805 1.43a8.189 8.189 0 00-.695 1.585.605.605 0 00-.025.09h7.045c-.22-.834-.54-1.62-1.01-2.265-.255-.35-.715-.63-1.265-.814-.68-.22-1.495-.276-2.255-.046l.01.02z'
            fill='#095B71'
          />
          <Path
            d='M11.825 23.91c.31.475.815.795 1.475.905.66.11 1.42-.13 1.815-.685.625-.89.835-2.695-.21-3.355-.76-.485-1.665-.575-2.43-.045-.725.505-1.1 1.41-.98 2.28.045.35.165.65.33.905v-.01.005z'
            fill='#35220F'
          />
          <Path
            d='M12.9 23.966c.2.185.465.295.735.295.825.01 1.445-.87 1.33-1.66-.485-.41-.68-.67-.74-.9 0-.035-.135.43-.695.725-.275.135-.685.31-1.1.275.02.42.1.87.405 1.2.02.025.045.045.065.075l.01-.01h-.01z'
            fill='#CF8A61'
          />
        </G>
      </G>
      <Defs>
        <ClipPath id='clip0_4_7642'>
          <Path fill='#fff' d='M0 0H48V48H0z' />
        </ClipPath>
        <ClipPath id='clip1_4_7642'>
          <Path fill='#fff' transform='translate(0 .64)' d='M0 0H48V46.975H0z' />
        </ClipPath>
      </Defs>
    </Svg>
  )
}
