import React, { FunctionComponent } from 'react'
import Svg, { G, Mask, Path } from 'react-native-svg'

type SelfCareBenefitIllustrationProps = {
  size?: number
}

export const SelfCareBenefitIllustration: FunctionComponent<SelfCareBenefitIllustrationProps> = ({ size = 513 }) => {
  return (
    <Svg width={size} height={size} fill='none' viewBox='0 0 513 513'>
      <Path
        fill='#FFE9E3'
        d='M258.442 2.599c-35.341 0-69.63 6.06-101.915 18.01C79.663 49.098 25.624 112.263 8.262 193.903-3.21 247.133-1.744 296.365 12.775 344.343 31.31 405.616 76.81 457.287 137.607 486.102c30.181 14.337 64.854 22.522 103.038 24.323 4.907.223 9.895.344 14.853.344 30.636 0 61.455-4.199 91.605-12.475a160.764 160.764 0 0 0 6.85-1.862c27.095-7.932 51.479-20.478 72.341-37.243 22.654-18.1 41.301-41.047 55.364-68.163 2.773-5.342 5.423-10.967 8.347-17.695 16.088-37.355 23.858-79.171 22.482-120.978-.648-18.485-3.4-38.133-8.155-58.449-7.831-32.964-20.599-63.964-36.929-89.704-4.472-6.91-8.58-12.718-12.648-17.938-29.351-37.86-75.083-64.43-132.207-76.793a302.867 302.867 0 0 0-64.096-6.86l-.01-.01Z'
      />
      <Mask
        id='SelfCareBenefitIllustration-a'
        width={513}
        height={509}
        x={0}
        y={2}
        maskUnits='userSpaceOnUse'
        // @ts-ignore-next-line
        style={{
          maskType: 'luminance',
        }}
      >
        <Path
          fill='#fff'
          d='M258.443 2.599c-35.341 0-69.63 6.06-101.915 18.01C79.664 49.098 25.625 112.263 8.263 193.903-3.21 247.133-1.743 296.365 12.776 344.343 31.31 405.616 76.81 457.287 137.608 486.102c30.181 14.337 64.854 22.522 103.038 24.323 4.907.223 9.895.344 14.853.344 30.636 0 61.455-4.199 91.605-12.475a160.764 160.764 0 0 0 6.85-1.862c27.095-7.932 51.479-20.478 72.341-37.243 22.654-18.1 41.301-41.047 55.364-68.163 2.773-5.342 5.423-10.967 8.347-17.695 16.088-37.355 23.858-79.171 22.482-120.978-.648-18.485-3.4-38.133-8.155-58.449-7.831-32.964-20.6-63.964-36.929-89.704-4.472-6.91-8.58-12.718-12.648-17.938-29.351-37.86-75.083-64.43-132.207-76.793a302.867 302.867 0 0 0-64.096-6.86l-.01-.01Z'
        />
      </Mask>
      <G mask='url(#SelfCareBenefitIllustration-a)'>
        <Path
          fill='#127719'
          d='M259.624 281.443c1.63-16.496 5.723-29.019 7.775-33.888-1.257 2.978-14.223-1.679-17.341-2.524-14.464 31.463-14.725 128.286-9.163 162.465 4.003.886 16.003 1.63 20.047 2.294-5.02-24.221-8.962-50.836-1.318-128.347Z'
        />
        <Path
          fill='#127719'
          d='M293.395 310.903c-30.86 20.348-42.236 54.658-43.875 66.115-.141.995 0 2.373 1.006 2.504.412.06.774-.251 1.176-.362 23.749-6.035 61.539-21.686 100.214-86.815.342-.574.955-1.237.482-1.71-1.317.211-24.261-2.646-59.013 20.258l.01.01ZM187.311 305.31c16.556 23.527 46.711 44.53 52.968 39.259 7.453-6.277-8.53-34.984-11.226-38.635-33.213-45.052-58.42-49.629-61.528-48.11-6.719 3.299 14.646 40.184 19.786 47.486Z'
        />
        <Path fill='#FFF5DB' d='M246.94 236.661c-3.39-.03-4.999-.473-5.271-3.209 2.615-.784 3.45.896 5.271 3.209Z' />
        <Path
          fill='#FFECB9'
          d='M252.694 241.007c-1.76.091-3.48.131-3.812-2.313 1.76-1.559 2.585.03 3.581 1.026.382.221.543.493.482.794-.06.302-.14.463-.251.493Z'
        />
        <Path
          fill='#F47352'
          d='M204.955 201.919c-5.402-12.976-8.49-26.434-2.274-39.259 5.834-12.03 14.455-21.776 27.189-26.735 10.893-4.245 24.774-3.742 32.569 4.979 8.128-9.254 22.38-12.513 33.727-7.715 11.346 4.798 18.93 17.281 17.964 29.562 8.228-2.635 17.653 1.75 22.39 8.962 10.3 15.671 5.774 39.45-3.611 54.196-10.762 16.908-28.878 28.485-48.763 31.03-21.848 2.807-55.664 1.348-72.502-14.997-7.293-7.081-11.98-16.546-14.374-26.424-.764-3.158-1.288-6.568-.07-9.576 1.217-3.007 4.767-5.28 7.765-4.023h-.01Z'
        />
        <Path
          fill='#FFD974'
          d='M272.006 162.098c6.357-2.354 13.911 1.669 17.602 7.564 6.267 9.998-5.955 22.108-16.033 17.28-6.518-3.128-11.819-15.118-6.407-21.394 1.448-1.68 3.098-2.797 4.828-3.44l.01-.01Z'
        />
        <Path
          fill='#5DAD3E'
          d='M219.306 623.04c226.885 0 410.811-49.231 410.811-109.96 0-60.729-183.926-109.96-410.811-109.96-226.884 0-410.811 49.231-410.811 109.96 0 60.729 183.927 109.96 410.811 109.96Z'
        />
      </G>
    </Svg>
  )
}
