import React, { FunctionComponent } from 'react'
import Svg, { G, Mask, Path } from 'react-native-svg'

type Props = {
  size?: number | string
}

export const ResidentialTreatmentSpotIllustration: FunctionComponent<Props> = ({ size = 124 }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 512 512' fill='none'>
      <Path
        d='M257.8 2C222.469 2 188.18 8.06049 155.884 20.0095C79.0203 48.4907 24.9919 111.655 7.61986 193.295C-3.85359 246.524 -2.38652 295.757 12.1323 343.735C30.6679 405.007 76.1671 456.678 136.964 485.493C167.145 499.83 201.819 508.015 240.003 509.816C244.91 510.039 249.898 510.16 254.855 510.16C285.492 510.16 316.31 505.962 346.461 497.685C348.778 497.109 350.983 496.512 353.311 495.824C380.406 487.891 404.789 475.345 425.652 458.58C448.305 440.48 466.952 417.533 481.016 390.418C483.788 385.076 486.439 379.45 489.363 372.722C505.45 335.367 513.22 293.551 511.844 251.745C511.197 233.26 508.445 213.611 503.69 193.295C495.858 160.332 483.09 129.331 466.76 103.592C462.288 96.6812 458.18 90.8737 454.113 85.653C424.762 47.7926 379.03 21.2236 321.905 8.85979C300.921 4.30683 279.35 2 257.8 2Z'
        fill='white'
      />
      <Mask
        id='mask0_18566_6947'
        mask-type='luminance'
        maskUnits='userSpaceOnUse'
        x='21'
        y='23'
        width='469'
        height='466'
      >
        <Path
          d='M489.872 252.442C489.244 234.726 486.533 216.373 482.263 198.131C475.282 168.739 463.809 139.762 448.076 114.953C444.423 109.328 440.68 103.905 436.612 98.6942C407.109 60.6518 362.399 39.4856 316.637 29.5804C265.664 18.5319 211.464 21.7594 162.464 39.9004C91.5793 66.1659 43.318 123.705 27.5749 197.716C21.5245 225.752 19.7539 257.441 22.4654 287.885H22.253C23.8212 305.186 26.9476 322.072 31.6321 337.603C49.0446 395.142 91.3567 440.803 145.254 466.34C174.443 480.201 207.062 487.081 240.218 488.65C274.092 490.218 308.492 485.938 340.292 477.186C342.376 476.67 344.359 476.144 346.444 475.517C371.353 468.222 393.035 456.961 411.591 442.058C431.817 425.9 448.49 405.573 461.421 380.664C464.234 375.241 466.733 369.828 469.131 364.293C484.348 328.952 491.126 290.596 489.771 252.554L489.872 252.453V252.442Z'
          fill='white'
        />
      </Mask>
      <G mask='url(#mask0_18566_6947)'>
        <Path
          d='M255.584 510.169C397.118 510.169 511.855 396.412 511.855 256.084C511.855 115.757 397.118 1.99902 255.584 1.99902C114.05 1.99902 -0.686523 115.757 -0.686523 256.084C-0.686523 396.412 114.05 510.169 255.584 510.169Z'
          fill='#FFE9E3'
        />
        <Path
          d='M256.003 556.751C463.066 556.751 630.923 503.308 630.923 437.383C630.923 371.457 463.066 318.014 256.003 318.014C48.9397 318.014 -118.918 371.457 -118.918 437.383C-118.918 503.308 48.9397 556.751 256.003 556.751Z'
          fill='#7FB04D'
        />
      </G>
      <Path d='M398.918 150.711H214.705V378.915H398.918V150.711Z' fill='#DCCDBF' />
      <Path d='M216.519 241.971L91.8994 273.619V378.914H214.708L216.519 241.971Z' fill='#DCCDBF' />
      <Path
        d='M287.812 183.704H255.79C253.549 183.704 251.732 185.52 251.732 187.761V250.663C251.732 252.903 253.549 254.72 255.79 254.72H287.812C290.053 254.72 291.869 252.903 291.869 250.663V187.761C291.869 185.52 290.053 183.704 287.812 183.704Z'
        fill='white'
      />
      <Path
        d='M361.127 183.704H329.105C326.864 183.704 325.048 185.52 325.048 187.761V250.663C325.048 252.903 326.864 254.72 329.105 254.72H361.127C363.368 254.72 365.185 252.903 365.185 250.663V187.761C365.185 185.52 363.368 183.704 361.127 183.704Z'
        fill='white'
      />
      <Path
        d='M287.812 274.935H255.79C253.549 274.935 251.732 276.752 251.732 278.992V341.894C251.732 344.135 253.549 345.951 255.79 345.951H287.812C290.053 345.951 291.869 344.135 291.869 341.894V278.992C291.869 276.752 290.053 274.935 287.812 274.935Z'
        fill='white'
      />
      <Path
        d='M138.15 298.034H179.805C182.273 298.034 184.287 300.047 184.287 302.516V378.914H133.668V302.516C133.668 300.047 135.681 298.034 138.15 298.034Z'
        fill='white'
      />
      <Path
        d='M361.127 274.935H329.105C326.864 274.935 325.048 276.752 325.048 278.992V341.894C325.048 344.135 326.864 345.951 329.105 345.951H361.127C363.368 345.951 365.185 344.135 365.185 341.894V278.992C365.185 276.752 363.368 274.935 361.127 274.935Z'
        fill='white'
      />
      <Path
        d='M407.106 133.257H206.523C205.416 133.257 204.52 134.154 204.52 135.26V148.707C204.52 149.813 205.416 150.71 206.523 150.71H407.106C408.212 150.71 409.109 149.813 409.109 148.707V135.26C409.109 134.154 408.212 133.257 407.106 133.257Z'
        fill='#FD826D'
      />
      <Path
        d='M214.714 242.487L78.834 281.44C77.9639 281.693 77.0938 281.036 77.0938 280.135V265.839C77.0938 265.232 77.4985 264.696 78.0752 264.534L214.704 224.933V242.497L214.714 242.487Z'
        fill='#FD826D'
      />
    </Svg>
  )
}
