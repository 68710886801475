import * as _ from 'lodash-es'
import { Dict } from 'mixpanel-browser'

import { COMMON_MIXPANEL_EVENTS, MIXPANEL_VENDOR_API_URL } from '@lyrahealth-inc/shared-app-logic'

import mixpanel from './mixpanel-browser'
import { actions, mixpanelEvents, trackElementsMap } from './mixpanelConstants'
import { mixpanelToken } from '../source/js/common/constants/appConstants'

let isTracking = false
export function initializeTracking() {
  if (isTracking) return
  mixpanel.init(mixpanelToken)
  isTracking = true
}

export function initializeTrackingWithProxy() {
  if (isTracking) return
  mixpanel.init(mixpanelToken, {
    // LYRA-9009: Point all mixpanel events to our proxy API which will then forward to mixpanel. This will ensure we capture 100% of events.
    api_host: MIXPANEL_VENDOR_API_URL,
  })
  isTracking = true
}

export function setUser(user: { id: string; email: string; first_name: string; last_name: string }) {
  mixpanel.identify(user.id)
  // @ts-expect-error TS(2554)
  mixpanel.people.set({
    lyra_id: user.id,
    email: user.email,
    name: `${user.first_name} ${user.last_name}`,
  })
}

/**
 * Tracks a page load passing the route name in the `page` property.
 *
 * Call this function on route change.
 *
 * @type {string} pathName The current path.
 *   Exple: If the url is https://provider.lyrahealth.com/providers, the `pathName` is `/providers`.
 */
export function trackPageLoad(pathName: string) {
  mixpanel.track(COMMON_MIXPANEL_EVENTS.PAGE_LOAD, { page: pathName.slice(1, pathName.length) })
}

/**
 * Proxy for `mixpanel.track_links`. Pass the component name to track and all the elements present in the
 * track map of this component in `mixpanelConstants,js` will be tracked.
 *
 * Call this function in `componentDidMount` method of the component you want to track. Note that all the elements
 * of the track map need to be rendered on the page before calling this function.
 *
 * @type {string} event The component name to track.
 */
export function trackClicks(componentName: string) {
  const pathName = window.location.pathname.slice(1, window.location.pathname.length)
  _.each(trackElementsMap[componentName], (element) => {
    mixpanel.track_links(element.target, mixpanelEvents.BUTTON_PRESS, { page: pathName, action: element.action })
  })
}

/**
 * Proxy for `mixpanel.track` that adds the `page` property.
 *
 * This method should only be used when `trackClicks` cannot be used. This can happen for elements that
 * are not displayed on page when the component is mounted. Exple: A tooltip that is created on hover.
 *
 * @type {string} event The event name defined in the `mixpanelEvents` hash in `mixpanelConstants.js`.
 * @type {string} action The action name defined in the `actions` hash in `mixpanelConstants.js`.
 * @type {Object} [properties] The properties object to be sent to Mixpanel.
 */
export function track({
  event,
  action = '',
  details,
  page,
  properties,
}: {
  event: string
  action?: string
  details?: string | { type: string; title: string } | { [key: string]: any }
  page?: string
  properties?: Dict
}) {
  properties = properties || {}
  const pathName = window.location.pathname.slice(1, window.location.pathname.length)
  // The page parameter if passed trumps the pathName
  properties.page = page || pathName
  // The action parameter passed overrides the one in the passed properties, if any.
  const actionString = actions[action] || action
  properties.action = actionString
  properties.action_detail = details
  event = mixpanelEvents[event] || event
  mixpanel.track(event, properties)
}
