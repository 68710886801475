import { some } from 'lodash-es'
import Cookies from 'universal-cookie'

import { IS_PRODUCTION } from '../common/constants/constants'
import { getDocumentObject } from '../common/utils/commonUtils'

export enum LD_SHARED_CONTEXT_KEYS {
  LAUNCH_DARKLY_USER_UUID = 'unknown-user-key',
}

/** LDUser key for demo user, allows specific users to demo customer site before official launch date */
export const DEMO_LD_USER_KEY = 'demo-user'

// 🍪 Cookie Key or ❓ URL param to check to disable 💰 costly vendors
export const SHOULD_DISABLE_COSTLY_VENDORS_KEY = 'shouldDisableCostlyVendors'

const shouldDisableCostlyVendorsOptions = [
  {
    domain: '.lyrahealth.com',
    path: '/',
  },
  // Option below is for Cypress so we can set cookie on localhost
  {
    domain: 'localhost',
    path: '/',
  },
]

/**
 * Some vendors (LD, Mixpanel) charge us per user,
 * so this cookie or url param provides a way to disable them (or make their usage free like by sharing the context key in LD)
 * in test environments, especially for E2E and load tests which are simulating a lot of users.
 */
export const shouldDisableCostlyVendors = (() => {
  if (getDocumentObject() && !IS_PRODUCTION) {
    const disableVendorsCookie = shouldDisableCostlyVendorsOptions.map((opts) => {
      const cookies = new Cookies(opts)
      return cookies.get(SHOULD_DISABLE_COSTLY_VENDORS_KEY)
    })
    const searchParams = new URLSearchParams(window.location.search)
    const disableVendorsUrlParam = searchParams.has(SHOULD_DISABLE_COSTLY_VENDORS_KEY)
    if (some(disableVendorsCookie) || disableVendorsUrlParam) {
      console.warn(
        `Vendor - shouldDisableCostlyVendors 🍪 or❓exists ${JSON.stringify([
          { disableVendorsCookie, disableVendorsUrlParam },
        ])}: 💰 costly vendor related logic should be disabled`,
      )
      return true
    }
  }
  return false
})()
