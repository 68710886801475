import React, { FunctionComponent, useRef, useState } from 'react'
import { ListRenderItem, Platform } from 'react-native'
import { KeyboardAwareFlatList } from 'react-native-keyboard-aware-scroll-view'
import Animated from 'react-native-reanimated'

import { ObjectFieldTemplateProps } from '@rjsf/core'
import { FlattenSimpleInterpolation } from 'styled-components'
import styled, { useTheme } from 'styled-components/native'

import { LoadingIndicator } from '../../atoms/icons/LoadingIndicator'
import { getCommonStyles } from '../../styles/commonStyles'
import { Flex1View } from '../../templates/content/CommonViews'

type RJSFObjectProperty = {
  content: React.ReactElement
  name: string
  disabled: boolean
  readonly: boolean
}

const Container = styled(Flex1View)<{
  styles?: { [key: string]: FlattenSimpleInterpolation }
}>`
  align-self: center;
  width: 100%;
  ${({ styles }) => styles?.formBodyPageContainer}
`

/**
 * This component is a custom template for RJSF ObjectField. It iterates over all top level
 * schema objects and adds them to a scrollable keyboard aware flatlist.
 */

export const BasicObjectFieldTemplate: FunctionComponent<ObjectFieldTemplateProps> = (props) => {
  const {
    properties,
    formContext: {
      loading,
      CustomLoadingIndicator,
      animatedSharedValues,
      scrollContainerCustomStyles,
      setIsAtTopOfPage,
      insertElementInScrollContainer,
    },
  } = props
  const verticalScroll = useRef<number>(0)
  const [verticalScrollBeforeKeyboard, setVerticalScrollBeforeKeyboard] = useState<number>(0)
  const { colors } = useTheme()
  const commonStyles = getCommonStyles(colors)

  const listItem: ListRenderItem<RJSFObjectProperty> = ({ item }) => {
    return item.content
  }

  return (
    <Animated.View
      style={{
        flexGrow: 1,
        opacity: animatedSharedValues?.opacity || 1,
        transform: [{ translateY: animatedSharedValues?.translateY || 0 }],
      }}
    >
      <Container styles={scrollContainerCustomStyles}>
        {insertElementInScrollContainer}
        {!loading ? (
          <KeyboardAwareFlatList
            keyboardOpeningTime={Number.MAX_SAFE_INTEGER}
            enableResetScrollToCoords
            resetScrollToCoords={Platform.OS === 'ios' ? { x: 0, y: verticalScrollBeforeKeyboard } : undefined}
            onKeyboardWillShow={() => {
              setVerticalScrollBeforeKeyboard(verticalScroll.current)
            }}
            style={[commonStyles.flex1View, scrollContainerCustomStyles?.scrollContainerContentCustomStyles]}
            renderItem={listItem}
            keyExtractor={(item) => item?.content?.key}
            data={properties}
            enableOnAndroid
            extraScrollHeight={50}
            onScroll={(event) => {
              verticalScroll.current = event.nativeEvent.contentOffset.y
              if (setIsAtTopOfPage) {
                setIsAtTopOfPage(verticalScroll.current < 1)
              }
            }}
          />
        ) : !!CustomLoadingIndicator ? (
          <CustomLoadingIndicator />
        ) : (
          <LoadingIndicator />
        )}
      </Container>
    </Animated.View>
  )
}
