import React from 'react'
import PropTypes from 'prop-types'
import CSSModules from 'react-css-modules'
import styles from './downloadCSVHistoryView.module.scss'
import DownloadCSVForm from '../downloadCSVForm/DownloadCSVForm'

const DownloadHistoryView = (props: any) => {
  return (
    <div styleName='payment-history-modal'>
      <h3>Download payment history</h3>
      <p>Payment history data will be downloaded in a CSV format (Excel)</p>
      {/* @ts-expect-error TS(2322): Type '{ submitDownload: any; }' is not assignable ... Remove this comment to see the full error message */}
      <DownloadCSVForm submitDownload={props.downloadClick} />
    </div>
  )
}

DownloadHistoryView.propTypes = {
  downloadClick: PropTypes.func,
}

DownloadHistoryView.defaultProps = {
  downloadClick: () => {},
}

export default CSSModules(DownloadHistoryView, styles)
