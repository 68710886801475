import React, { FunctionComponent } from 'react'
import { ImageSourcePropType } from 'react-native'

import styled from 'styled-components/native'

import providerMatching from './../../assets/provider-matching.png'
import { Image } from '../image/Image'

const ProviderMatchingIllustrationPNG = styled(Image)({
  width: 202,
  height: 202,
})

export const ProviderMatchingIllustration: FunctionComponent = () => {
  return <ProviderMatchingIllustrationPNG source={providerMatching as ImageSourcePropType} />
}
