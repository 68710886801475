import React, { ComponentType, ReactElement } from 'react'
import { Iterable } from 'immutable'
import { ImmutableTypedMap } from '@lyrahealth-inc/shared-app-logic'

export const toJS =
  <T extends {}>(WrappedComponent: ComponentType<T>) =>
  (wrappedComponentProps: T): ReactElement<T> => {
    const KEY = 0
    const VALUE = 1

    const propsJS = Object.entries(wrappedComponentProps).reduce((newProps, wrappedComponentProp) => {
      newProps[wrappedComponentProp[KEY]] = Iterable.isIterable(wrappedComponentProp[VALUE])
        ? (wrappedComponentProp[VALUE] as ImmutableTypedMap<T>).toJS()
        : wrappedComponentProp[VALUE]
      return newProps
    }, {}) as T

    return <WrappedComponent {...propsJS} />
  }
