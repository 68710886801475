import React from 'react'
import CSSModules from 'react-css-modules'
import styles from './recommendationExplanationContent.module.scss'
import { employmentTypes } from '../../common/constants/appConstants'
import { TargetIcon, CalendarCancelIcon, CheckInCircleIcon } from '@lyrahealth-inc/ui-core'

export const RecommendationExplanationContent: React.FC<RecommendationExplanationContentProps> = ({
  upcomingWeekSessionTarget,
  targetLevel,
  employmentType,
  scheduledNextWeek,
  nextWeekSessionRecommendation,
  upcomingWeekPaidTimeOff,
}) => {
  return (
    <div styleName='container' data-test-id='RecommendationExplanationContent-open'>
      <h2>Your weekly recommendation</h2>
      <p styleName='text-block'>
        Our goal is to help you reach Target or Target Plus. To help you reach this goal, we calculate a weekly
        recommendation that considers potential client cancellations, PTO, and number of scheduled sessions per week.
      </p>
      <div styleName='icon-line-item'>
        <TargetIcon />
        <p>
          <span styleName='strong'> {`+ ${upcomingWeekSessionTarget}`}</span> sessions recommended per week to reach{' '}
          {targetLevel}
        </p>
      </div>
      <div styleName='icon-line-item'>
        <CalendarCancelIcon />
        <p>
          <span styleName='strong'>{`+ ${employmentType === employmentTypes.FULL_TIME ? 4 : 2}`}</span> sessions
          recommended per week to account for client cancellations
        </p>
      </div>
      <div styleName='icon-line-item'>
        <CheckInCircleIcon />
        <p>
          <span styleName='strong'>{`- ${scheduledNextWeek}`}</span> already scheduled for next week
        </p>
      </div>
      <div styleName='icon-line-item'>
        <CalendarCancelIcon />
        <p>
          <span styleName='strong'>{`- ${upcomingWeekPaidTimeOff}`}</span> of PTO hours scheduled for next week
        </p>
      </div>
      {nextWeekSessionRecommendation && (
        <p>
          <span styleName='strong'>= Total recommendation:</span> {nextWeekSessionRecommendation} additional sessions
          next week
        </p>
      )}
    </div>
  )
}

type RecommendationExplanationContentProps = {
  upcomingWeekSessionTarget: number
  targetLevel: string
  employmentType: string
  scheduledNextWeek: number
  nextWeekSessionRecommendation: number
  upcomingWeekPaidTimeOff: number
}

export default CSSModules(RecommendationExplanationContent, styles)
