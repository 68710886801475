import React, { FunctionComponent } from 'react'
import { ImageSourcePropType } from 'react-native'

import styled, { useTheme } from 'styled-components/native'

import homeBaseIllustration from '../../assets/homebase_illustration.png'
import { RotatingProvidersLottie } from '../icons/RotatingProvidersLottie'
import { Image } from '../image/Image'

export enum IllustrationSize {
  MOBILE = 'mobile',
  TABLET = 'tablet',
  LAPTOP = 'laptop',
}

const ImageCenterIllustrationContainer = styled(Image)(({ theme }) => ({
  ...(theme.breakpoints.isMinWidthTablet && { width: '200px', height: '129px' }),
  ...(theme.breakpoints.isMinWidthLaptop && { width: '211px', height: '136px' }),
  ...(theme.breakpoints.isMobileSized && { width: '192px', height: '123px' }),
}))
export const SearchForCareRotatingProvidersLottie: FunctionComponent = () => {
  const { breakpoints } = useTheme()
  let width = 212
  let height = 157

  if (breakpoints.isMinWidthLaptop) {
    width = 311
    height = 230
  } else if (breakpoints.isMinWidthTablet) {
    width = 260
    height = 195
  }

  return <RotatingProvidersLottie width={width} height={height} />
}

export const SearchForCareFindAProviderHomebaseIllustration: FunctionComponent = () => {
  return (
    <ImageCenterIllustrationContainer
      source={homeBaseIllustration as ImageSourcePropType}
      contentFit='fill'
      accessibilityIgnoresInvertColors
    />
  )
}
