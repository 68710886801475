import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react'

import styled from 'styled-components/native'

import { Appointment, getDateFromAppointment, Theme } from '@lyrahealth-inc/shared-app-logic'

import providerSessionAlertSound from '../../assets/sounds/providerSessionAlert.wav'
import { AvatarDetails } from '../../atoms'
import { useZoom } from '../../hooks/useZoom'
import { AddToastFunction, DisplayMode, JoinSessionParams, RemoteParticipantStatus } from '../../hooks/useZoom/types'
import { ZoomPermissionsModal } from '../../molecules'
import { ZoomFullScreenView } from '../../molecules/zoomFullScreenView/ZoomFullScreenView'
import { ZoomVideoBanner } from '../../molecules/zoomVideoBanner/ZoomVideoBanner'
import { ThemeProvider } from '../../utils/themes/ThemeProvider'

export type ZoomVideoSessionDesktopProps = {
  getSessionParams: () => Promise<JoinSessionParams>
  appointment: Appointment
  participantName: string
  participantFirstName?: string
  avatarDetails?: AvatarDetails
  participantTitle?: string
  onSessionOpened?: () => void
  onSessionStarted?: () => void
  onSessionEnded?: () => void
  onSessionClosed?: () => void
  showRecordingStatus?: boolean
  isRecording?: boolean
  messenger?: React.ReactElement
  addToast?: AddToastFunction
  onDisableRecordingPressed?: () => void
}

const Container = styled.View<{ fixed: boolean; fullscreen: boolean }>(({ fixed, fullscreen }) => ({
  position: fixed ? 'fixed' : undefined,
  top: 0,
  bottom: fullscreen ? 0 : undefined,
  right: 0,
  left: 0,
  zIndex: 100,
}))

export const ZoomVideoSessionDesktop: FunctionComponent<ZoomVideoSessionDesktopProps> = ({
  getSessionParams,
  appointment,
  participantName,
  participantFirstName,
  onSessionOpened,
  onSessionStarted,
  onSessionEnded,
  onSessionClosed,
  showRecordingStatus,
  isRecording,
  avatarDetails,
  participantTitle,
  messenger,
  addToast,
  onDisableRecordingPressed,
}) => {
  const {
    joinSession,
    displayMode,
    setSessionEndedCallback,
    setSessionClosedCallback,
    setAddToast,
    sessionStarted,
    inSession,
    remoteParticipantStatus,
    setAttached,
    showPermissionsModal,
    closePermissionsModal,
    config: { shouldJoinOnOpen, shouldNotifyOnParticipantReady },
  } = useZoom()
  const hasFiredOpenEvent = useRef(false)
  const hasAttemptedJoin = useRef(false)
  const hasStarted = useRef(false)
  const startDate = useMemo(() => getDateFromAppointment(appointment), [appointment])

  const [theme, setTheme] = useState<Theme>(Theme.DARK)
  useEffect(() => {
    if (!shouldJoinOnOpen) {
      if (!hasFiredOpenEvent.current) {
        hasFiredOpenEvent.current = true
        onSessionOpened?.()
      }
      return
    }

    if (hasAttemptedJoin.current) {
      return
    }
    hasAttemptedJoin.current = true
    getSessionParams().then((sessionParams) => joinSession(sessionParams).then(() => onSessionOpened?.()))
  }, [getSessionParams, joinSession, onSessionOpened, shouldJoinOnOpen])

  useEffect(() => {
    setAttached(true)
    return () => setAttached(false)
  }, [setAttached])

  useEffect(() => {
    setSessionEndedCallback(onSessionEnded)
    setSessionClosedCallback(onSessionClosed)
    setAddToast(addToast)
  }, [addToast, onSessionClosed, onSessionEnded, setAddToast, setSessionClosedCallback, setSessionEndedCallback])

  useEffect(() => {
    if (sessionStarted && !hasStarted.current) {
      hasStarted.current = true
      onSessionStarted?.()
    }
  }, [onSessionStarted, sessionStarted])

  useEffect(() => {
    if (shouldNotifyOnParticipantReady && remoteParticipantStatus === RemoteParticipantStatus.READY) {
      new Audio(providerSessionAlertSound).play()
    }
  }, [remoteParticipantStatus, shouldNotifyOnParticipantReady])

  useEffect(() => {
    setTheme(!shouldJoinOnOpen && inSession && !sessionStarted ? Theme.LIGHT : Theme.DARK)
  }, [inSession, sessionStarted, shouldJoinOnOpen])
  return (
    <>
      <ThemeProvider theme={theme}>
        <Container
          fixed={displayMode === DisplayMode.FULL_SCREEN || sessionStarted}
          fullscreen={displayMode === DisplayMode.FULL_SCREEN}
        >
          <ZoomVideoBanner
            participantName={participantName}
            sessionStartDate={startDate}
            sessionDuration={appointment.appointmentDuration}
            participantFirstName={participantFirstName}
            showRecordingStatus={showRecordingStatus}
            isRecording={isRecording}
            avatarDetails={avatarDetails}
            sessionNumber={appointment.sessionNumber}
            messenger={messenger}
            onDisableRecordingPressed={onDisableRecordingPressed}
          />
          <ZoomFullScreenView
            participantFirstName={participantFirstName}
            getSessionParams={getSessionParams}
            participantName={participantName}
            avatarDetails={avatarDetails}
            participantTitle={participantTitle}
            sessionStartDate={startDate}
            sessionDuration={appointment.appointmentDuration}
            sessionNumber={appointment.sessionNumber}
          />
        </Container>
      </ThemeProvider>
      <ZoomPermissionsModal show={showPermissionsModal} onClose={closePermissionsModal} />
    </>
  )
}
