import { GET_PROVIDER_TASK, PATCH_PROVIDER_TASK } from '../../../common/constants/reduxConstants'

export const getProviderTasks = ({ providerId, type }: any) => ({
  action: GET_PROVIDER_TASK,
  request: {
    method: 'get',
    url: '/v1/provider/task',
    params: { provider_id: providerId, type },
  },
})

export const patchProviderTask = ({ id, data }: any) => ({
  action: PATCH_PROVIDER_TASK,
  request: {
    method: 'patch',
    url: `/v1/provider/task/${id}`,
    data,
  },
})
