// Reducers
import { combineReducers } from '@reduxjs/toolkit'

import data from './practicesDataReducer'
import practicesDashboard from '../dashboard/data/practicesDashboardReducer'
import practiceDetails from '../individualPractice/data/practiceDetailsReducer'
import register from '../register/data/registerPracticeReducer'

export default combineReducers({
  practicesDashboard,
  practiceDetails,
  register,
  data,
})
