import React, { useRef, useState, useEffect, useCallback, MouseEvent } from 'react'
import classNames from 'classnames'

import styles from './navigationMenu.module.scss'
import ChevronIcon from '../icons/ChevronIcon'
import XIcon from '../icons/XIcon'

const NavigationMenu: React.FC<NavigationMenuProps> = ({
  title = '',
  iconColor = '',
  alignment = 'right',
  className,
  menuType = 'hamburger',
  trackMenuClick = () => {},
  customIcon,
  buttonLabel,
  children,
  ...restProps
}) => {
  const menuContainer = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [menuHasTriggered, setMenuHasTriggered] = useState(false)

  const handleOutsideClick = useCallback(
    (e) => {
      e.stopPropagation()
      if (menuContainer.current && !menuContainer.current.contains(e.target)) {
        setIsOpen(false)
      }
    },
    [menuContainer, setIsOpen],
  )

  useEffect(() => {
    window.addEventListener('mousedown', handleOutsideClick, false)
    return () => {
      window.removeEventListener('mousedown', handleOutsideClick, false)
    }
  }, [handleOutsideClick])

  const _handleDropdownClick = (e: MouseEvent) => {
    e.stopPropagation()
    setIsOpen(!isOpen)
    setMenuHasTriggered(true)
    trackMenuClick()
  }

  const _renderHamburger = (iconColor: string) => {
    let btnToggleClass = ''
    if (isOpen === false && menuHasTriggered === false) btnToggleClass = ''
    if (isOpen === true) btnToggleClass = 'open'
    if (isOpen === false && menuHasTriggered === true) btnToggleClass = 'closed'

    const classes = classNames(
      styles.hamburger,
      iconColor && styles[`${iconColor}`],
      btnToggleClass && styles[`${btnToggleClass}`],
    )

    return (
      <div className={classes}>
        <div className={styles['button-content']} data-test-id='NavigationMenu-menuButton'>
          <span />
          <span />
          <span />
        </div>
      </div>
    )
  }

  const _renderDropdown = (iconColor: string) => {
    return (
      <div className={classNames(styles['open-close-container'], iconColor && styles[`${iconColor}`])}>
        {isOpen === false ? <ChevronIcon direction='down' isFilled /> : <XIcon width={11} isFilled />}
      </div>
    )
  }

  const _renderIcon = (iconColor: string, menuType: string) => {
    if (menuType === 'hamburger') {
      return _renderHamburger(iconColor)
    } else if (customIcon) {
      return <div className={classNames(styles['open-close-container'], styles['custom-icon'])}>{customIcon}</div>
    } else {
      return _renderDropdown(iconColor)
    }
  }

  const _renderInnerNodes = (alignment: string, title: string, iconColor: string, menuType: string) => {
    if (alignment === 'right') {
      return (
        <div className={styles['btn-inner-container']}>
          <span className={styles.title}>{title}</span>
          {_renderIcon(iconColor, menuType)}
        </div>
      )
    } else {
      return (
        <div className={styles['btn-inner-container']}>
          {_renderIcon(iconColor, menuType)}
          <span className={styles.title}>{title}</span>
        </div>
      )
    }
  }

  return (
    <div
      ref={menuContainer}
      className={classNames(className, styles['dropdown-container'], styles[`align-${alignment}`], {
        [styles['non-hamburger']]: menuType !== 'hamburger',
      })}
      {...restProps}
    >
      <button
        className={styles['dropdown-button']}
        data-test-id='NavigationMenu-userActions'
        onClick={_handleDropdownClick}
        aria-expanded={isOpen}
        aria-label={buttonLabel}
      >
        {_renderInnerNodes(alignment, title, iconColor, menuType)}
      </button>
      {/* jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */}
      {/* eslint-disable-next-line  */}
      <ul
        onClick={() => setIsOpen(false)}
        className={`${isOpen ? styles['dropdown-list-open'] : styles['dropdown-list-closed']} ${
          styles['updated-look-feel']
        }`}
      >
        {children}
      </ul>
    </div>
  )
}
export type NavigationMenuProps = {
  children: React.ReactNode
  iconColor?: string
  title?: string
  alignment: string
  className?: string
  trackMenuClick?: () => void
  menuType: string
  customIcon: React.ReactNode
  buttonLabel?: string
}

export default NavigationMenu
