import React from 'react'
import classNames from 'classnames'
import { Icon } from './types'
import styles from './accordionIcon.module.scss'

const AccordionIcon: React.FC<AccordionIconProps> = ({
  width = 30,
  fillColor = styles.x_light_gray_10,
  isOpen = false,
  isPristine = true,
  ...props
}) => {
  const classes = isPristine
    ? { [styles['down-arrow']]: isOpen }
    : isOpen
    ? styles['section-open']
    : styles['section-closed']

  return (
    <div className={styles.container}>
      <div color={fillColor} className={classNames(classes)}>
        <svg
          width={width}
          height='24px'
          viewBox='0 0 24 24'
          version='1.1'
          xmlns='http://www.w3.org/2000/svg'
          {...props}
        >
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g id='client-home-started' transform='translate(-500.000000, -183.000000)'>
              <g
                id='ic-24-Accordion_arrow'
                transform='translate(512.000000, 195.000000) rotate(-90.000000) translate(-512.000000, -195.000000) translate(500.000000, 183.000000)'
              >
                <rect id='Rectangle' x='0' y='0' width='24' height='24' />
                <path
                  d='M12,10 L16.8,16.4 C17.1313708,16.8418278 17.0418278,17.4686292 16.6,17.8 C16.4269038,17.9298221 16.2163702,18 16,18 L8,18 C7.44771525,18 7,17.5522847 7,17 C7,16.7836298 7.07017787,16.5730962 7.2,16.4 L12,10 L12,10 Z'
                  id='Icon-Color'
                  fill='#343A40'
                  transform='translate(12.000000, 14.000000) rotate(-180.000000) translate(-12.000000, -14.000000) '
                />
              </g>
            </g>
          </g>
        </svg>
      </div>
    </div>
  )
}

type AccordionIconProps = Icon & {
  isOpen: boolean
  isPristine: boolean
  [key: string]: any
}

export default AccordionIcon
