import React, { FunctionComponent } from 'react'
import Svg, { G, Mask, Path } from 'react-native-svg'

type Props = {
  size?: number | string
}

export const IndividualTherapySpotIllustrationWhiteBorder: FunctionComponent<Props> = ({ size = 124 }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 513 513' fill='none'>
      <Path
        d='M258.686 0.227539C223.345 0.227539 189.057 6.28803 156.771 18.237C79.907 46.7284 25.8685 109.893 8.50658 191.533C-2.96687 244.762 -1.4998 293.994 13.0191 341.972C31.5546 403.245 77.0538 454.916 137.851 483.731C168.032 498.068 202.705 506.253 240.889 508.054C245.796 508.277 250.784 508.398 255.742 508.398C286.378 508.398 317.197 504.199 347.348 495.923C349.664 495.346 351.87 494.749 354.197 494.061C381.292 486.129 405.676 473.583 426.539 456.818C449.192 438.718 467.839 415.771 481.903 388.655C484.675 383.313 487.326 377.688 490.25 370.96C506.337 333.605 514.107 291.789 512.731 249.982C512.084 231.497 509.332 211.849 504.576 191.533C496.745 158.569 483.977 127.569 467.647 101.829C463.175 94.9189 459.067 89.1113 455 83.8906C425.648 46.0303 379.916 19.4612 322.792 7.09744C301.808 2.54449 280.237 0.237656 258.696 0.237656L258.686 0.227539Z'
        fill='white'
      />
      <Path
        d='M490.767 250.67C490.14 232.954 487.428 214.601 483.159 196.359C476.178 166.967 464.704 137.99 448.971 113.181C445.319 107.556 441.575 102.133 437.508 96.922C408.005 58.8796 363.295 37.7134 317.532 27.8082C266.56 16.7597 212.359 19.9872 163.359 38.1282C92.4748 64.3937 44.2135 121.933 28.4704 195.944C22.42 223.98 20.6494 255.668 23.3609 286.112H23.1485C24.7167 303.414 27.8431 320.3 32.5276 335.831C49.9401 393.37 92.2522 439.031 146.149 464.568C175.339 478.429 207.958 485.309 241.114 486.878C274.988 488.446 309.388 484.166 341.188 475.414C343.272 474.898 345.255 474.372 347.339 473.745C372.249 466.45 393.931 455.189 412.487 440.286C432.712 424.128 449.386 403.801 462.316 378.892C465.129 373.469 467.628 368.056 470.026 362.521C485.243 327.18 492.022 288.824 490.666 250.782L490.767 250.68V250.67Z'
        fill='#FFE9E3'
      />
      <Mask id='mask0_17041_10554' maskUnits='userSpaceOnUse' x='22' y='21' width='469' height='467'>
        <Path
          d='M490.767 250.67C490.14 232.954 487.428 214.601 483.159 196.359C476.178 166.967 464.704 137.99 448.971 113.181C445.319 107.556 441.575 102.133 437.508 96.922C408.005 58.8796 363.295 37.7134 317.532 27.8082C266.56 16.7597 212.359 19.9872 163.359 38.1282C92.4748 64.3937 44.2135 121.933 28.4704 195.944C22.42 223.98 20.6494 255.668 23.3609 286.112H23.1485C24.7167 303.414 27.8431 320.3 32.5276 335.831C49.9401 393.37 92.2522 439.031 146.149 464.568C175.339 478.429 207.958 485.309 241.114 486.878C274.988 488.446 309.388 484.166 341.188 475.414C343.272 474.898 345.255 474.372 347.339 473.745C372.249 466.45 393.931 455.189 412.487 440.286C432.712 424.128 449.386 403.801 462.316 378.892C465.129 373.469 467.628 368.056 470.026 362.521C485.243 327.18 492.022 288.824 490.666 250.782L490.767 250.68V250.67Z'
          fill='#FFE9E3'
        />
      </Mask>
      <G mask='url(#mask0_17041_10554)'>
        <Path
          d='M254.38 514.22C393.749 514.22 506.73 401.239 506.73 261.87C506.73 122.501 393.749 9.52026 254.38 9.52026C115.011 9.52026 2.03027 122.501 2.03027 261.87C2.03027 401.239 115.011 514.22 254.38 514.22Z'
          fill='#FFE9E3'
          stroke='#3BA5B5'
          stroke-miterlimit='10'
        />
        <Path
          d='M117.855 121.264C124.971 108.762 120.121 90.3277 128.856 90.0034C137.59 89.6791 132.254 114.522 140.342 121.435C148.431 128.348 157.812 124.729 157.493 133.938C157.174 143.146 144.227 136.737 138.404 146.278C132.581 155.819 136.625 169.329 129.183 169.986C121.74 170.643 123.36 147.925 117.209 144.964C111.059 142.003 96.501 142.822 96.501 136.08C96.501 126.043 110.74 133.775 117.855 121.273V121.264Z'
          fill='#FFC03C'
        />
        <Path
          d='M92.401 228.322C97.041 220.992 93.871 210.202 99.561 210.002C105.251 209.802 101.771 224.362 107.041 228.412C112.311 232.462 118.421 230.342 118.211 235.742C118.001 241.142 109.571 237.382 105.781 242.972C101.991 248.562 104.621 256.482 99.781 256.862C94.941 257.242 95.991 243.932 91.981 242.202C87.971 240.472 78.501 240.952 78.501 236.992C78.501 231.112 87.771 235.642 92.411 228.312L92.401 228.322Z'
          fill='#FFC03C'
        />
        <Path
          d='M377.434 268.727C371.344 278.017 375.174 291.917 367.884 292.047C360.594 292.177 365.344 273.597 358.674 268.307C352.004 263.017 344.134 265.607 344.514 258.697C344.894 251.787 355.614 256.777 360.584 249.687C365.554 242.597 362.344 232.407 368.564 232.007C374.784 231.607 373.164 248.647 378.254 250.947C383.344 253.247 395.504 252.827 395.424 257.887C395.304 265.427 383.524 259.427 377.434 268.717V268.727Z'
          fill='#FFC03C'
        />
        <Path
          d='M399.269 196.892C390.099 190.152 376.939 195.192 376.509 186.672C376.079 178.152 394.089 182.952 398.879 174.942C403.669 166.932 400.859 157.842 407.499 158.002C414.139 158.162 409.809 170.902 416.819 176.422C423.819 181.942 433.459 177.772 434.099 185.022C434.739 192.272 418.339 191.072 416.349 197.122C414.359 203.172 415.279 217.362 410.419 217.472C403.189 217.632 408.439 203.622 399.279 196.882L399.269 196.892Z'
          fill='#FFC03C'
        />
        <Path
          d='M326.835 193.896C325.904 199.517 324.015 205.058 321.111 210.172C313.074 224.283 299.331 241.324 286.151 254.819C273.828 267.441 262.002 276.964 255.864 277.977C246.471 279.526 154.89 202.277 187.482 160.013C216.811 121.989 254.232 168.186 254.232 168.186C254.232 168.186 281.644 133.34 312.77 154.76C324.688 162.963 329.379 178.643 326.826 193.906L326.835 193.896Z'
          fill='#F47352'
        />
        <Path
          d='M356.23 322.954C356.186 323.297 356.154 323.631 356.111 323.954C355.873 325.703 355.473 327.516 354.998 329.192C354.231 331.859 353.259 334.161 352.373 335.233C351.866 335.858 351.639 338.722 352.417 341.889C352.924 343.941 353.345 346.576 353.41 349.606C353.453 351.585 353.335 353.752 352.957 356.053C351.714 363.657 347.718 372.676 337.769 381.456C317.786 399.068 274.783 420.503 244.711 433.939C214.649 447.374 194.244 480.526 188.098 497.107C181.941 513.688 175.978 541.935 162.325 551.475C148.671 561.005 48.1381 553.798 66.8037 497.42C67.8083 494.389 114.602 394.142 124.734 373.187C134.877 352.252 183.81 280.345 192.203 280.022C200.585 279.71 209.486 282.365 197.949 313.715C186.424 345.065 194.882 338.066 196.707 337.316C209.562 331.432 218.808 329.984 242.097 325.808C281.653 319.985 280.8 325.381 331.094 307.237C345.363 302.269 353.443 305.289 356.154 313.351C356.64 314.788 356.575 319.506 356.208 322.954H356.23Z'
          fill='#BC6A41'
        />
        <Path
          d='M353.493 349C353.536 350.996 353.418 353.169 353.042 355.478C325.092 361.382 304.211 382.718 277.526 391.756C262.758 396.751 248.536 395.424 233.221 395.069C216.522 394.692 201.335 400.157 191.36 413.709C188.937 417 183.703 413.343 186.127 410.052C194.986 398.015 208.671 390.294 223.847 389.061C240.621 387.692 257.063 391.694 273.494 386.501C301.84 377.536 323.441 354.81 353.192 349.053C353.3 349.032 353.396 349.021 353.493 349.011V349Z'
          fill='#925333'
        />
        <Path
          d='M353.501 342.196C339.301 337.094 324.305 343.829 312.073 350.769C298.346 358.53 284.824 367.051 268.289 367C264.126 367 264.847 360.85 268.978 360.861C299.11 360.953 322.261 326.16 353.458 335.636C352.952 336.252 352.726 339.075 353.501 342.196Z'
          fill='#925333'
        />
        <Path
          d='M356.501 323.271C356.458 323.619 356.425 323.955 356.382 324.282C356.143 326.05 355.742 327.881 355.266 329.575L350.118 327.702C318.809 319.704 290.329 344.308 260.201 347.97C256.04 348.486 254.978 342.235 259.15 341.73C291.033 337.846 319.037 313.253 352.459 321.787C352.48 321.787 352.502 321.787 352.524 321.809C352.762 321.914 354.637 322.514 356.501 323.282V323.271Z'
          fill='#925333'
        />
      </G>
    </Svg>
  )
}
