import { createReducer } from '@reduxjs/toolkit'
import { isEmpty, uniqBy } from 'lodash-es'

import { ProviderAdminProviderInfo, Transfer } from '@lyrahealth-inc/shared-app-logic'

import {
  CLEAR_PROVIDER_DETAILS_PAYMENT_HISTORY,
  CLEAR_PROVIDER_DETAILS_STORE,
  GET_PROVIDER,
  GET_PROVIDER_PAYMENT_HISTORY,
  GET_PROVIDER_PAYMENT_HISTORY_CSV,
  PROVIDER_SELECTED,
  PURGE_TOO_FREQUENT_REJECTIONS,
  UPDATE_CHARGE_COMMENT,
  UPDATE_CHARGE_STATUS,
  UPDATE_RATES,
} from '../../../common/constants/reduxConstants'

type ProviderDetailsState = {
  data?: ProviderAdminProviderInfo
  showModal: boolean
  modalData?: any
  paymentHistory?: {
    [status: string]: {
      data: Transfer[]
      page: number
    }
  }
  paymentHistoryDownload?: {
    file_name?: string
    data?: string
  }
}

const initialState = {
  showModal: false,
  modalData: {},
}

export default createReducer<ProviderDetailsState>(initialState, (builder) => {
  builder.addCase(PROVIDER_SELECTED, (state, action: any) => {
    state.data = action.payload
    return state
  })
  builder.addCase(GET_PROVIDER, (state, action: any) => {
    state.data = action.payload.data.data
    return state
  })
  builder.addCase(CLEAR_PROVIDER_DETAILS_STORE, () => {
    return initialState
  })
  builder.addCase(CLEAR_PROVIDER_DETAILS_PAYMENT_HISTORY, (state) => {
    state.paymentHistory = undefined
    return state
  })
  builder.addCase(GET_PROVIDER_PAYMENT_HISTORY, (state, action: any) => {
    // create or append to list
    const list = state?.paymentHistory?.[action.status]?.data
    let newList
    if (list && list.length > 0) {
      const existingHistoryData = list
      const newHistoryData: Transfer[] = action.data.data

      // concatenate existing items with new items and filter out duplicates by ID
      const mergedHistoryData = [...existingHistoryData, ...newHistoryData]
      const uniqueHistoryData = uniqBy(mergedHistoryData, 'id')

      newList = uniqueHistoryData
    } else {
      const newHistoryData = action.data.data
      const uniqueHistoryData = uniqBy(newHistoryData, 'id')

      newList = uniqueHistoryData
    }

    state.paymentHistory = {
      ...state.paymentHistory,
      [action.status]: {
        data: newList,
        page: action.page,
      },
    }
    return state
  })
  builder.addCase(UPDATE_CHARGE_STATUS, (state, action: any) => {
    const allPayments = state.paymentHistory?.all?.data
    if (allPayments) {
      const idx = allPayments.findIndex((payment) => payment.id === action.payload.id)
      if (idx === -1) {
        return state
      }

      if (action.payload.admin_note) {
        allPayments[idx].admin_note = action.payload.admin_note
      }
      if (action.wasExcluded) {
        allPayments[idx].exclude_from_session_count = action.payload.exclude_from_session_count
      }
      allPayments[idx].status = action.payload.status
    }

    return state
  })
  builder.addCase(UPDATE_CHARGE_COMMENT, (state, action: any) => {
    const allPayments = state.paymentHistory?.all?.data
    if (allPayments) {
      const idx = allPayments.findIndex((payment) => payment.id === action.payload.id)
      if (idx !== -1) {
        allPayments[idx].admin_note = action.payload.admin_note
      }
      if (!isEmpty(state.modalData)) {
        state.modalData.admin_note = action.payload.admin_note
      }
    }
    return state
  })
  builder.addCase(`${UPDATE_RATES}_FULFILLED`, (state, action: any) => {
    if (state.data) {
      state.data.rates = action.payload.data.data
    }
    return state
  })
  builder.addCase(PURGE_TOO_FREQUENT_REJECTIONS, (state, action: any) => {
    // remove this id's charge from any linked charges' conflict array, since it's been rejected
    // this FE purge mirrors the BE cleanup done by the API, so that we don't have to re-fetch immediately, and can rely on the next fetch to match
    const typeList = state.paymentHistory?.all?.data
    if (typeList) {
      const typePurged = typeList.map((charge) => {
        if (charge.is_too_frequent) {
          const idx = charge.is_too_frequent_with_charge_ids.findIndex((obj) => {
            return obj.id === action.payload
          })
          if (idx !== -1) {
            charge.is_too_frequent_with_charge_ids.splice(idx, 1)
          }
        }
        return charge
      })
      state.paymentHistory!.all.data = typePurged
    }

    return state
  })
  builder.addCase(GET_PROVIDER_PAYMENT_HISTORY_CSV, (state, action: any) => {
    state.paymentHistoryDownload = action.data
    return state
  })
})
