import React, { FunctionComponent } from 'react'
import { ImageSourcePropType } from 'react-native'

import styled from 'styled-components/native'

import highFiveIllustrationUrl from '../../assets/highfive.png'
import { tID } from '../../utils'
import { Image } from '../image/Image'

type HighFiveIllustrationProps = {
  height?: number
  width?: number
}

const ImageContainer = styled(Image)<{ imageHeight: string; imageWidth: string }>(({ imageHeight, imageWidth }) => ({
  height: imageHeight,
  width: imageWidth,
}))

/**
 * Illustration shown on appointment booking confirmation
 */
export const HighFiveIllustration: FunctionComponent<HighFiveIllustrationProps> = ({ height = 118, width = 124 }) => {
  const heightInPixels = `${height}px`
  const widthInPixels = `${width}px`
  return (
    <ImageContainer
      imageHeight={heightInPixels}
      imageWidth={widthInPixels}
      testID={`${tID('HighFiveIllustration')}`}
      source={highFiveIllustrationUrl as ImageSourcePropType}
    />
  )
}
