import React, { FunctionComponent } from 'react'

import styled from 'styled-components/native'

import { Image } from '../image/Image'

type CareOptionMedicationManagementIconProps = {
  size?: number
}

const CareOptionMedicationManagementPNG = styled(Image)<{ size: number }>(({ size }) => ({
  width: `${size}px`,
  height: `${size}px`,
}))

export const CareOptionMedicationManagementIcon: FunctionComponent<CareOptionMedicationManagementIconProps> = ({
  size = 64,
}) => {
  return (
    <CareOptionMedicationManagementPNG
      size={size}
      source={require('./../../assets/careOptionMedicationManagement.png')}
    />
  )
}
