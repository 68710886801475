import React, { cloneElement, FunctionComponent, ReactElement, ReactNode, useMemo, useRef } from 'react'
import {
  GestureResponderEvent,
  NativeSyntheticEvent,
  Pressable,
  PressableProps,
  PressableStateCallbackType,
  TargetedEvent,
  ViewStyle,
} from 'react-native'

import { noop } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'
import { useFocusVisible } from 'use-focus-visible'

import { getButtonStyles } from './buttonStyles'
import { AccessibilityRolesNative, IS_WEB } from '../../constants'
import { BodyTextSize, getFontStyles, TextAlign } from '../../styles/typeStyles'
import { BodyText, Size as TextSize } from '../bodyText/BodyText'

export enum ButtonType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  TEXT = 'text',
  WORDCLOUD = 'wordCloud',
  ICON = 'icon',
  TERTIARY_ICON = 'tertiary_icon',
  TERTIARY_HEADER = 'tertiary_header',
  SECONDARY_TERTIARY_ICON = 'secondary_tertiary_icon',
}

export enum ButtonSize {
  DEFAULT = 'default',
  SMALL = 'small',
  LARGE = 'large',
  UNSIZED = 'unsized',
}

export enum ButtonModifier {
  DEFAULT = 'default',
  INVERSE = 'inverse',
  UNSTYLED = 'unstyled',
  BLACK = 'black',
  PERIWINKLE = 'periwinkle',
}
export interface BaseButtonProps
  extends Omit<PressableProps, 'accessibilityLabelledBy' | 'accessibilityRole' | 'accessibilityLabel'> {
  onPress: (event: GestureResponderEvent) => void
  text?: React.ReactNode
  buttonType?: ButtonType
  leftIcon?: ReactElement | null
  rightIcon?: ReactElement | null
  iconAtEnd?: boolean
  testID?: string
  disabled?: boolean
  loading?: boolean
  style?: ViewStyle
  size?: ButtonSize
  onFocus?: () => void
  onBlur?: () => void
  fullWidth?: boolean
  modifier?: ButtonModifier
  iconColor?: string
  accessibilityLabel?: string | ReactNode
  accessibilityLabelledBy?: string | ReactNode
  accessibilityRole?: AccessibilityRolesNative
  suppressAccessibilitySelected?: boolean
  selected?: boolean
  textAlign?: TextAlign
  customTextColor?: string
  customTextSize?: BodyTextSize
  underlineText?: boolean
  focusable?: boolean
}

type GetButtonStylesArgs = {
  buttonType: ButtonType
  modifier?: string
  size?: ButtonSize
  focused?: boolean
  hovered?: boolean
  pressed?: boolean
  disabled?: boolean
  selected?: boolean
  loading?: boolean
}

const iconButtons = [ButtonType.ICON, ButtonType.TERTIARY_ICON, ButtonType.SECONDARY_TERTIARY_ICON]

const Container = styled(Pressable)<{ fullWidth?: boolean }>(({ fullWidth }) => ({
  position: 'relative',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '100px',
  alignSelf: 'flex-start',
  ...(IS_WEB && { width: 'fit-content' }),
  ...(fullWidth && (IS_WEB ? { width: 'auto', alignSelf: 'auto' } : { alignSelf: 'auto' })),
}))

const FocusBorder = styled.View<{ theme: string }>(({ theme }) => ({
  position: 'absolute',
  top: '-6px',
  right: '-6px',
  bottom: '-6px',
  left: '-6px',
  backgroundColor: 'transparent',
  border: '2px',
  borderColor: theme.colors.borderFocus,
  borderRadius: '100px',
}))

const LeftIconContainer = styled.View<{ noMargin?: boolean; iconAtEnd?: boolean }>(({ noMargin, iconAtEnd }) => ({
  ...(iconAtEnd
    ? { position: 'absolute', left: '0' }
    : { justifyContent: 'center', marginRight: noMargin ? '0' : '8px' }),
}))

const RightIconContainer = styled.View<{ iconAtEnd?: boolean }>(({ iconAtEnd }) => ({
  ...(iconAtEnd ? { position: 'absolute', right: '0' } : { justifyContent: 'center', marginLeft: '8px' }),
}))

const ContentContainer = styled.View<{ hide?: boolean; iconAtEnd?: boolean }>(({ hide, iconAtEnd }) => ({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  ...(iconAtEnd && { width: '100%' }),
  ...(hide && { opacity: 0 }),
}))

const LoadingIndicator = styled.ActivityIndicator({
  position: 'absolute',
  left: '0px',
  right: '0px',
})

export const BaseButton: FunctionComponent<BaseButtonProps> = ({
  onPress,
  text,
  leftIcon,
  rightIcon,
  iconAtEnd,
  buttonType = ButtonType.PRIMARY,
  modifier,
  testID = 'Button',
  disabled,
  loading,
  selected,
  style,
  size = ButtonSize.DEFAULT,
  fullWidth,
  iconColor,
  accessibilityLabel,
  accessibilityLabelledBy,
  accessibilityRole,
  suppressAccessibilitySelected = false,
  onFocus = noop,
  onBlur = noop,
  textAlign = 'center',
  customTextColor,
  customTextSize,
  underlineText = false,
  focusable = true,
  ...rest
}) => {
  const { focusVisible, onBlur: fvBlur, onFocus: fvFocus } = useFocusVisible()
  const pressableRef = useRef<any>(null)
  const { colors } = useTheme()
  const buttonStyles = useMemo(() => getButtonStyles(colors), [colors])
  const fontStyles = getFontStyles(colors)

  const focus = (event: NativeSyntheticEvent<TargetedEvent>) => {
    fvFocus()
    onFocus(event)
  }

  const blur = (event: NativeSyntheticEvent<TargetedEvent>) => {
    fvBlur()
    onBlur(event)
  }

  const getButtonStylesFromState = ({
    buttonType,
    modifier = 'default',
    focused,
    hovered,
    pressed,
    disabled,
    selected,
    loading,
  }: GetButtonStylesArgs) => {
    let state = 'active'
    if (hovered) state = 'hovered'
    if (pressed) state = 'pressed'
    if (disabled) state = 'inactive'
    if (selected) state = 'selected'
    if (selected && disabled) state = 'selected-inactive'
    if (loading) state = 'loading'
    const styles = {
      ...buttonStyles[buttonType][modifier].base,
      ...buttonStyles[buttonType][modifier].sizes[size],
      ...(focused && buttonStyles[buttonType][modifier].focused),
      ...buttonStyles[buttonType][modifier].states[state],
    }
    return styles
  }
  const accessibilityAttr = accessibilityLabelledBy ? { accessibilityLabelledBy } : { accessibilityLabel }

  return (
    <Container
      ref={pressableRef}
      accessible
      focusable={focusable}
      // ignoring the next line because it looks like styled-components/native AccessibilityRole is different than the 0.68 react-native
      // @ts-ignore next-line
      accessibilityRole={accessibilityRole || AccessibilityRolesNative.BUTTON}
      accessibilitySelected={
        accessibilityRole === AccessibilityRolesNative.TAB && !suppressAccessibilitySelected ? selected : undefined
      }
      aria-checked={
        accessibilityRole === AccessibilityRolesNative.RADIO ||
        accessibilityRole === AccessibilityRolesNative.CHECKBOX ||
        accessibilityRole === AccessibilityRolesNative.SWITCH
          ? selected
            ? true
            : false
          : undefined
      }
      onFocus={focus}
      onBlur={blur}
      onPress={loading || disabled ? () => {} : onPress}
      testID={testID}
      disabled={disabled}
      fullWidth={fullWidth}
      style={({ hovered, pressed }: PressableStateCallbackType) => [
        { outline: 0 },
        getButtonStylesFromState({
          buttonType,
          selected,
          hovered,
          pressed,
          focused: focusVisible,
          disabled,
          size,
          modifier,
          loading,
        }),
        style,
      ]}
      {...accessibilityAttr}
      {...rest}
    >
      {({ hovered, pressed }) => {
        const { color: textColor, textSize: textSizeFromButtonStyles } = getButtonStylesFromState({
          buttonType,
          hovered,
          pressed,
          focused: focusVisible,
          disabled,
          size,
          modifier,
          selected,
          loading,
        })
        const textSize = customTextSize || textSizeFromButtonStyles || TextSize.DEFAULT
        return (
          <>
            {/* To do use LoadingIndicator in ui-core-crossplatform instead of this custom one */}
            {loading && <LoadingIndicator color={textColor} accessibilityLabel='Loading indicator' />}
            {focusVisible && <FocusBorder />}
            <ContentContainer hide={loading} iconAtEnd={iconAtEnd}>
              {leftIcon && (
                <LeftIconContainer iconAtEnd={iconAtEnd} noMargin={iconButtons.includes(buttonType)}>
                  {cloneElement(leftIcon, { fillColor: iconColor || textColor })}
                </LeftIconContainer>
              )}
              <BodyText
                text={text}
                color={customTextColor ?? textColor}
                textAlign={textAlign}
                size={textSize}
                underline={underlineText}
                // For buttons the lineHeight should be equal to fontSize
                style={{ lineHeight: fontStyles.body[textSize].fontSize + 2 }} // add a little buffer so languages with taller characters (like Chinese) are not cut off
                selectable={false}
                maxFontSizeMultiplier={2}
              />
              {rightIcon && (
                <RightIconContainer iconAtEnd={iconAtEnd}>
                  {cloneElement(rightIcon, { fillColor: iconColor || textColor })}
                </RightIconContainer>
              )}
            </ContentContainer>
          </>
        )
      }}
    </Container>
  )
}
