import React, { FunctionComponent } from 'react'

import styled from 'styled-components/native'

import {
  ASSETS_CDN_URL,
  TREATMENT_REC_PRIMARY_CARD_CDN_PREFIX as namespace,
  PRIMARY_TREATMENT_REC_IMAGE_CONTAINER_DIMENSIONS,
} from '../../constants'
import { ThemeType, tID } from '../../utils'
import { Image } from '../image/Image'

const ImageSizer = styled(Image)<{ height: string; width: string }>(({ height, width }) => ({
  width,
  height,
}))

const PADDING = {
  DESKTOP: {},
  TABLET: {
    paddingLeft: '30px',
    paddingTop: '5px',
  },
  MOBILE: {
    paddingLeft: '20px',
  },
}

const Container = styled.View<{ viewport: 'DESKTOP' | 'TABLET' | 'MOBILE'; theme: ThemeType }>(
  ({ viewport, theme: { colors } }) => ({
    ...PRIMARY_TREATMENT_REC_IMAGE_CONTAINER_DIMENSIONS[viewport],
    ...PADDING[viewport],
    overflow: 'hidden',
    backgroundColor: viewport === 'MOBILE' ? colors.backgroundElement : 'none',
  }),
)

const IMAGE_SIZES = {
  DESKTOP: {
    height: '332px',
    width: '416px',
  },
  TABLET: {
    height: '273px',
    width: '308px',
  },
  MOBILE: {
    height: '201px',
    width: '322px',
  },
}

function getUrl(viewport: 'DESKTOP' | 'TABLET' | 'MOBILE', intendedFor: 'CHILD' | 'TEEN') {
  const imageName = intendedFor === 'CHILD' ? 'childTherapy' : 'teensTherapy'
  const backgroundModifier = viewport === 'MOBILE' ? 'NoBg' : ''
  const fileName = imageName + backgroundModifier + '.png'
  return ASSETS_CDN_URL + namespace + fileName
}

/**
 * Illustration to show on the treatment card for Child Meds
 */
export const ChildTherapyRec: FunctionComponent<ChildTherapyRec> = ({
  viewport = 'DESKTOP',
  intendedFor = 'CHILD',
}) => {
  return (
    <Container viewport={viewport}>
      <ImageSizer
        {...IMAGE_SIZES[viewport]}
        testID={`${tID('ChildTherapyRecIllustration')}`}
        source={getUrl(viewport, intendedFor)}
        contentFit='cover'
      />
    </Container>
  )
}

export type ChildTherapyRec = {
  intendedFor?: 'CHILD' | 'TEEN'
  viewport?: 'DESKTOP' | 'TABLET' | 'MOBILE'
}
