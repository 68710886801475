import {
  CLEAR_LT_CLIENT_DATA,
  CLEAR_LT_APPOINTMENTS,
  CLEAR_CURRICULUM,
  CLEAR_ASSIGNMENTS,
  LT_SELECTED_CLIENT,
  CLEAR_SELECTED_CONVERSATION,
  CLEAR_EPISODES,
  CLEAR_CLIENT_PRESCRIPTIONS,
} from '../../../../common/constants/reduxConstants'

export const getLtClient = ({ clientId, cancelToken, providerId }: any) => ({
  action: LT_SELECTED_CLIENT,
  request: {
    method: 'get',
    url: `/v1/clients/${clientId}`,
    cancelToken,
    params: { providerId, is_bcx: true },
  },
})

export const getClientId = ({ relationshipId }: any) => ({
  request: {
    method: 'get',
    url: `/lt/v1/provider_patient/${relationshipId}`,
  },
})

export const selectLtClient = (data: any) => ({
  type: LT_SELECTED_CLIENT,
  data,
})

export const clearSelectedClientStore = () => {
  return (dispatch: any) => {
    dispatch({ type: CLEAR_LT_CLIENT_DATA })
    dispatch({ type: CLEAR_LT_APPOINTMENTS })
    dispatch({ type: CLEAR_CURRICULUM })
    dispatch({ type: CLEAR_ASSIGNMENTS })
    dispatch({ type: CLEAR_SELECTED_CONVERSATION })
    dispatch({ type: CLEAR_EPISODES })
    dispatch({ type: CLEAR_CLIENT_PRESCRIPTIONS })
  }
}
