import React, { FunctionComponent } from 'react'
import { ImageSourcePropType } from 'react-native'

import styled from 'styled-components/native'

import mobileAppPromo from './../../assets/mobile-app-promo.png'
import { ImageComponentProps } from '../formElements/RadioButtons'
import { Image } from '../image/Image'

const MobileAppPromoIllustrationPNG = styled(Image)<{ width: number; height: number }>(({ width, height }) => ({
  width: `${width}px`,
  height: `${height}px`,
  resizeMode: 'contain',
}))

export const MobileAppPromoIllustration: FunctionComponent<ImageComponentProps> = ({ width = 448, height = 440 }) => {
  return <MobileAppPromoIllustrationPNG source={mobileAppPromo as ImageSourcePropType} width={width} height={height} />
}
