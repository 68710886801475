import { useMemo } from 'react'
import { useIntl } from 'react-intl'

import { BaseCountryItem } from '../../../models'

export function useLocalizedCountryList<T extends BaseCountryItem>(
  countryList: T[],
): (T & { localizedCountryName: string })[] {
  const { formatDisplayName } = useIntl()

  return useMemo(() => {
    const collator = new Intl.Collator()

    const sortedCountryList = countryList.map((country) => ({
      ...country,
      localizedCountryName: formatDisplayName(country.countryIsoCode, { type: 'region' }) ?? country.countryName,
    }))
    sortedCountryList.sort((a, b) => collator.compare(a.localizedCountryName, b.localizedCountryName))

    return sortedCountryList
  }, [countryList, formatDisplayName])
}
