import React, { FunctionComponent, ReactNode } from 'react'

import { useTheme } from 'styled-components/native'

import { BodyText, PressableOpacity } from '../../atoms'
import { BodyTextSize } from '../../styles'
import { tID } from '../../utils'

export type ChatMenuItemProps = {
  formattedText: ReactNode
  name: string
  onPress?: () => void
}

export const ChatMenuItem: FunctionComponent<ChatMenuItemProps> = ({ formattedText, name, onPress }) => {
  const { colors } = useTheme()
  const { active, hover } = colors.components.chatMenu

  return (
    <PressableOpacity
      testID={tID(`ChatHeader-menuItemPressable-${name}`)}
      key={name}
      onPress={onPress}
      hoveredBackgroundColor={hover.background}
      pressedBackgroundColor={active.background}
      style={{ padding: '10px' }}
    >
      <BodyText
        testID={tID(`ChatHeader-menuItemText-${name}`)}
        text={formattedText}
        size={BodyTextSize.DEFAULT}
        color={colors.textSecondary}
        textAlign={'center'}
      />
    </PressableOpacity>
  )
}
