import React, { FunctionComponent } from 'react'
import { View } from 'react-native'

import { Image } from '../image/Image'

export const CareTeamIllustration: FunctionComponent<CareTeamIllustrationProps> = ({ width = 387, height = 322 }) => {
  return (
    <View>
      {/* eslint-disable-next-line react-native-a11y/has-valid-accessibility-ignores-invert-colors */}
      <Image source={require('../../assets/careTeamIllustration.png')} style={{ width: width, height: height }} />
    </View>
  )
}

type CareTeamIllustrationProps = {
  width?: string | number
  height?: string | number
}
