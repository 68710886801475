import { useEffect, useState } from 'react'

/**
 * Hook for determining whether or not the current window is visible. copied from ui core
 */
export const useVisibilityChange = () => {
  // starts out true on mount
  const [windowVisible, setWindowVisible] = useState(document.visibilityState === 'visible')

  useEffect(() => {
    const onVisibilityChange = () => setWindowVisible(document.visibilityState === 'visible')
    document.addEventListener('visibilitychange', onVisibilityChange)
    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange)
    }
  }, [windowVisible])

  return [windowVisible]
}
