import { isEqual, parse } from 'date-fns'
import { fromJS } from 'immutable'
import { get } from 'lodash-es'
import { change } from 'redux-form/immutable'

import { getAge } from '@lyrahealth-inc/shared-app-logic'
import { formValidationConstants } from '@lyrahealth-inc/ui-core'

import { PAYMENT_FORM, ROLES } from '../../../constants/appConstants'
import { hasRole } from '../../../utils/utils'
import validation from '../../../utils/validation'

const requestPaymentDOB = ({ $$values, field, lyraCodeInfo, FORM_NAME, dispatch, $$formMeta, user }: any) => {
  const errors = {}
  const enteredDob = $$values.get(field.fieldName)
  const lyraCodeDOB = lyraCodeInfo?.user_info?.dob
  const isDependent = $$values.get('relationship') === 'dependent'
  // Payment admins should not get an error for non matching lyra code date of birth
  const shouldThrowLyraCodeError = !hasRole(user.roles, ROLES.PAYMENTS_ADMIN)

  if (!enteredDob) {
    errors[field.fieldName] = `${field.errorText} is required`
  } else {
    const { isValid, message } = formValidationConstants.isValidDateOfBirth(enteredDob, { min: 1, max: 150 })
    if (!isValid) {
      errors[field.fieldName] = message
    } else if (lyraCodeDOB) {
      const enteredAge = getAge({ date: enteredDob })
      if (isEqual(parse(enteredDob, 'MM/dd/yyyy', new Date()), parse(lyraCodeDOB, 'MM-dd-yyyy', new Date()))) {
        if (
          !$$formMeta.getIn([PAYMENT_FORM.FIELDS.FIRST_NAME, 'visited']) &&
          !$$formMeta.getIn([PAYMENT_FORM.FIELDS.LAST_NAME, 'visited'])
        ) {
          /*
          Dispatching form change in validation is not ideal. The alternative is to put this logic in componentDidUpdate of
          RequestPayment.js but without upgrading to redux-form v8 there is no way to manually throw a synchronous error
          from there. This was a compromise in order to keep all of the business logic together.
          */
          dispatch(change(FORM_NAME, PAYMENT_FORM.FIELDS.FIRST_NAME, lyraCodeInfo.user_info.first_name))
          dispatch(change(FORM_NAME, PAYMENT_FORM.FIELDS.LAST_NAME, lyraCodeInfo.user_info.last_name))
        }
      } else if (shouldThrowLyraCodeError && (!isDependent || enteredAge >= 18)) {
        errors[field.fieldName] = `${field.errorText} Does not correspond with Lyra code`
      }
    }
  }
  return errors
}

const requestPaymentValidate = ($$values = fromJS({}), props: any) => {
  let errors = {}

  const requiredForGuaranteedTime = [
    {
      fieldName: 'company',
      errorText: 'Sponsoring company',
    },
    {
      fieldName: 'session_type',
      errorText: 'Session type',
    },
    {
      fieldName: 'visit_date',
      errorText: 'Visit date',
    },
    {
      fieldName: 'provider_lyra_id',
      errorText: 'Provider',
    },
  ]

  const restRequired = [
    {
      fieldName: 'diagnosis',
      errorText: 'Diagnosis',
    },
    {
      fieldName: 'eligible_member_first_name',
      errorText: 'Eligible member first name',
    },
    {
      fieldName: 'eligible_member_last_name',
      errorText: 'Eligible member last name',
    },
    {
      fieldName: 'relationship',
      errorText: 'Relationship',
    },
    {
      fieldName: 'other_diagnosis',
      errorText: 'Other diagnosis',
    },
    {
      fieldName: 'other_session_description',
      errorText: 'Session description',
    },
    {
      fieldName: 'modality',
      errorText: 'Modality',
    },
    {
      fieldName: 'location',
      errorText: 'Location',
    },
    {
      fieldName: 'primary_condition',
      errorText: 'Primary condition',
    },
    {
      fieldName: 'other_primary_description',
      errorText: 'Other primary description',
    },
  ]

  let justRequired

  if ($$values.get('is_guaranteed_time')) {
    justRequired = requiredForGuaranteedTime
    errors = Object.assign(errors, validation.requiredFields($$values, justRequired))
  } else {
    justRequired = requiredForGuaranteedTime.concat(restRequired)
    errors = Object.assign(errors, validation.requiredFields($$values, justRequired))
    errors = Object.assign(
      errors,
      validation.requiredValidName($$values, {
        fieldName: 'first_name',
        errorText: 'First name',
      }),
    )
    errors = Object.assign(
      errors,
      validation.requiredValidName($$values, {
        fieldName: 'last_name',
        errorText: 'Last name',
      }),
    )
    errors = Object.assign(
      errors,
      validation.requiredValidMinimumDOB($$values, {
        fieldName: 'eligible_member_dob',
        errorText: 'Eligible member Date of Birth',
      }),
    )
    errors = Object.assign(
      errors,
      validation.requiredValidCurrencyAmount($$values, {
        fieldName: 'session_rate',
        errorText: 'Session rate',
      }),
    )
  }

  errors = Object.assign(
    errors,
    validation.requiredValidInteger($$values, {
      fieldName: 'session_duration',
      errorText: 'Session duration',
    }),
  )

  errors = Object.assign(
    errors,
    validation.validLyraCode(
      $$values,
      {
        fieldName: 'lyra_code',
        errorText: 'Lyra code',
      },
      get(props, 'selectedCompany[0].lyra_code_required') === 'true',
    ),
  )
  // This file is shared by RequestPayment.js and RequestPaymentAdmins.tsx
  // The OR assignments for $$formMeta, $$lyraCodeInfo, and $$user will be removed once ICD-10 collection changes are enabled for DA providers
  // https://lyrahealth.atlassian.net/browse/INTP-2653
  errors = Object.assign(
    errors,
    requestPaymentDOB({
      $$values,
      field: { fieldName: 'date_of_birth', errorText: 'Date of birth' },
      $$formMeta: props.$$formMeta || props.formMeta,
      lyraCodeInfo: props.lyraCodeValidationInfo,
      user: props.user,
      FORM_NAME: props.form,
      dispatch: props.dispatch,
    }),
  )

  errors = Object.assign(
    errors,
    validation.requiredBooleanField($$values, {
      fieldName: 'onsite_session',
      errorText: 'Onsite sesson',
    }),
  )
  errors = Object.assign(
    errors,
    validation.validVisitDate($$values, {
      fieldName: 'visit_date',
      errorText: 'Visit date',
    }),
  )

  return errors
}

export default requestPaymentValidate
