import React, { FunctionComponent } from 'react'
import { StyleSheet, View } from 'react-native'

import { useTheme } from 'styled-components/native'

import { getTypeStyles } from '../../styles/typeStyles'
import { tID } from '../../utils'
import { BodyText } from '../bodyText/BodyText'

export type CountryIconProps = {
  countryCode: string
  backgroundColor?: string
  textColor?: string
}

export const CountryIcon: FunctionComponent<CountryIconProps> = ({ countryCode = '', backgroundColor, textColor }) => {
  const { colors } = useTheme()
  const { countryIcon } = colors.components

  const styles = StyleSheet.create({
    text: {
      ...getTypeStyles(colors).bodyDefault,
      fontWeight: '900',
      fontSize: 24,
    },
    countryContainer: {
      height: 44,
      width: 44,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 4,
    },
  })

  // only ISO Alpha-2 country codes are allowed
  return (
    <View
      style={[styles.countryContainer, { backgroundColor: backgroundColor || countryIcon.background }]}
      testID={tID(`CountryIcon-${countryCode}`)}
    >
      <BodyText style={{ color: textColor || countryIcon.text, ...styles.text }}>
        {countryCode.substring(0, 2)}
      </BodyText>
    </View>
  )
}
