import { createReducer } from '@reduxjs/toolkit'
import { isEmpty, uniqBy } from 'lodash-es'

import { Practice, ProviderInfo, Transfer } from '@lyrahealth-inc/shared-app-logic'

import {
  ADD_PROVIDER_TO_PRACTICE,
  CLEAR_PRACTICE_DETAILS_STORE,
  GET_PRACTICE,
  GET_PRACTICE_PAYMENT_HISTORY,
  GET_PROVIDERS_FOR_PRACTICE,
  PRACTICE_SELECTED,
  TOGGLE_ADD_PROVIDER_MODAL,
  TOGGLE_PRACTICE_DETAILS_MODAL,
  UPDATE_CHARGE_COMMENT,
  UPDATE_CHARGE_STATUS,
} from '../../../common/constants/reduxConstants'

type PaymentHistory = { [status: string]: { data: Transfer[]; page: number } }
type PracticeDetailsState = {
  addProviderModalShown: boolean
  showModal: boolean
  modalData: any
  data: Practice | null
  providers: ProviderInfo[] | null
  paymentHistory: PaymentHistory
}

const initialState = {
  addProviderModalShown: false,
  showModal: false,
  modalData: {},
  data: null,
  providers: null,
  paymentHistory: {},
}

export default createReducer<PracticeDetailsState>(initialState, (builder) => {
  builder.addCase(PRACTICE_SELECTED, (state, action: any) => {
    state.data = action.payload
    return state
  })
  builder.addCase(GET_PRACTICE, (state, action: any) => {
    state.data = action.payload.data
    return state
  })
  builder.addCase(GET_PROVIDERS_FOR_PRACTICE, (state, action: any) => {
    state.providers = action.payload.data.practice_providers
    return state
  })
  builder.addCase(ADD_PROVIDER_TO_PRACTICE, (state, action: any) => {
    state.providers = [...(state.providers ?? []), action.payload.data]
    return state
  })
  builder.addCase(CLEAR_PRACTICE_DETAILS_STORE, (state) => {
    state = initialState
    return state
  })
  builder.addCase(TOGGLE_PRACTICE_DETAILS_MODAL, (state, action: any) => {
    state.showModal = action.visible
    if (action.data) {
      state.modalData = action.data
    }

    return state
  })
  builder.addCase(TOGGLE_ADD_PROVIDER_MODAL, (state, action: any) => {
    state.addProviderModalShown = action.visible
    return state
  })
  builder.addCase(GET_PRACTICE_PAYMENT_HISTORY, (state, action: any) => {
    // create or append to list
    const list = state.paymentHistory[action.status]?.data
    let newList: Transfer[] = []
    if (list && list.length > 0) {
      const existingHistoryData = list
      const newHistoryData = action.data.data

      // concatenate existing items with new items and filter out duplicates by ID
      const mergedHistoryData = [...existingHistoryData, ...newHistoryData]
      const uniqueHistoryData = uniqBy<Transfer>(mergedHistoryData, 'id')

      newList = uniqueHistoryData
    } else {
      const newHistoryData = action.data.data
      const uniqueHistoryData = uniqBy<Transfer>(newHistoryData, 'id')

      newList = uniqueHistoryData
    }

    state.paymentHistory[action.status] = { data: newList, page: action.page }
    return state
  })
  builder.addCase(UPDATE_CHARGE_STATUS, (state, action: any) => {
    const list = state.paymentHistory.all?.data
    if (list) {
      const idx = list.findIndex((item) => item.id === action.payload.id)
      if (idx !== -1) {
        if (action.payload.admin_note) {
          list[idx].admin_note = action.payload.admin_note
        }

        if (action.wasExcluded) {
          list[idx].exclude_from_session_count = action.payload.exclude_from_session_count
        }
        list[idx].status = action.payload.status
      }
    }

    return state
  })
  builder.addCase(UPDATE_CHARGE_COMMENT, (state, action: any) => {
    const list = state.paymentHistory.all?.data
    if (list) {
      const idx = list.findIndex((item) => item.id === action.payload.id)
      if (idx !== -1) {
        list[idx].admin_note = action.payload.admin_note
      }
      if (!isEmpty(state.modalData)) {
        state.modalData.admin_note = action.payload.admin_note
      }
    }
    return state
  })
})
