import { GET_CONTENT_CATEGORIES_TAGS, GET_CONTENTS, GET_PROGRAMS } from '../../common/constants/reduxConstants'

export const getContentsByProgramId = ({ programId, cancelToken }: any) => ({
  action: GET_CONTENTS,
  request: {
    method: 'get',
    url: `/lt/v1/contentsmetadata?program_id=${programId}`,
    cancelToken,
  },
})

export const getContentCategoriesTagsByProgramId = ({ programId, cancelToken }: any) => ({
  action: GET_CONTENT_CATEGORIES_TAGS,
  request: {
    method: 'get',
    url: `/lt/v1/contentsmetadata/categoriestags?program_id=${programId}`,
    cancelToken,
  },
})

export const getPrograms = () => ({
  action: GET_PROGRAMS,
  request: {
    method: 'get',
    url: '/lt/v1/programs',
  },
})
