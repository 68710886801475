import { useEffect, useState } from 'react'

export default (breakpoint = 968) => {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < breakpoint)

  useEffect(() => {
    const checkForMobile = () => setIsMobile(window.innerWidth < breakpoint)
    checkForMobile()
    window.addEventListener('resize', checkForMobile)
    return () => window.removeEventListener('resize', checkForMobile)
  }, [breakpoint])
  return [isMobile]
}
