import React, { FunctionComponent } from 'react'

import requestCareTeamIllustrationUrl from '../../assets/requestCareTeamIllustration.png'
import { Image } from '../image/Image'

interface RequestCareTeamIllustrationProps {
  width?: number
  height?: number
}

export const RequestCareTeamIllustration: FunctionComponent<RequestCareTeamIllustrationProps> = ({
  width = 185,
  height = 146,
  ...props
}) => {
  return (
    <Image
      source={requestCareTeamIllustrationUrl}
      style={{ width, height }}
      contentFit='contain'
      {...props}
      accessibilityIgnoresInvertColors
    />
  )
}
