import { MessageDescriptor } from 'react-intl'

import { FormMetadata } from '../formMetadata/FormMetadata'

export enum TriageScreenerNames {
  PRIMARY_NEEDS = 'primaryNeeds',
  PRIMARY_NEEDS_DIRECT_CN = 'primaryNeedsDirectCN',
  WORD_CLOUD_1 = 'wordCloud1',
  WORD_CLOUD_2 = 'wordCloud2',
  CARE_EXPERIENCES = 'careExperiences',
  INTENSITY = 'intensity',
  WORK_LIFE = 'workLifeBalance',
  LIFE_CHANGES = 'lifeChanges',
  CLINICAL_SCREENERS = 'clinicalScreeners',
  AUD_SUD = 'audSud',
  SELF_HARM = 'selfHarm',
  SLIDER = 'sliders',
}

// TODO: (MOBILE-1049) Remove `| string` from TMessage when all triage screeners are internationalized
export interface TriageScreener<TMessage = MessageDescriptor | string> {
  name: string
  description: string
  id: string
  min_age?: number
  max_age?: number
  data: FormMetadata<TMessage>
}

export type TriageScreenerDefinition = () => Omit<TriageScreener<MessageDescriptor>, 'id'>
