import React, { Component } from 'react'
import CSSModules from 'react-css-modules'
import { connect } from 'react-redux'

import { format, parseISO } from 'date-fns'
import numeral from 'numeral'
import { bindActionCreators } from 'redux'
import { formValueSelector } from 'redux-form/immutable'

import { Avatar, PrimaryButton } from '@lyrahealth-inc/ui-core'
import { TextButton, tID, toJS } from '@lyrahealth-inc/ui-core-crossplatform'

import styles from './paymentDetailsModal.module.scss'
import {
  attendanceOptions,
  deprecatedConditions,
  diagnosesMap,
  ebtTypes,
  getConditions,
  modalityOptions,
  paymentRejectionReasons,
  paymentReversalReasons,
  ROLES,
  sessionTypes,
  severityOptions,
} from '../../../common/constants/appConstants'
import { hasRole } from '../../../common/utils/utils'
import * as alertActions from '../../../data/alertActions'
import { getAuthRoles } from '../../../data/auth/authSelectors'
import store from '../../../data/store'
import * as paymentDataActions from '../../../payments/data/paymentsDataActions'
import RejectPaymentForm from '../forms/rejectPaymentModal/RejectPaymentForm'
import ReversePaymentForm from '../forms/reversePaymentModal/ReversePaymentForm'

type PaymentDetailsModalProps = {
  viewData?: any
  actions?: any
  copyClick?: (...args: any[]) => any
  closeModal?: (...args: any[]) => any
  roles?: any[]
}

type PaymentDetailsModalState = any

class PaymentDetailsModal extends Component<PaymentDetailsModalProps, PaymentDetailsModalState> {
  constructor(props: PaymentDetailsModalProps) {
    super(props)

    this.state = {
      adminNote: this.props.viewData.admin_note,
      approvePending: false,
      rejectPending: false,
      cancelPending: false,
      showConfirmationButtons: false,
    }
  }

  _prepUpdatePayload(status: any) {
    if (status === 'exclude') {
      if (this.props.viewData.exclude_from_session_count) {
        status = 'include'
      }
      return {
        status,
      }
    }
    const selector = formValueSelector(`chargeCommentModal_${this.props.viewData.id}`)

    return {
      status: status,
      note: selector(store.getState(), 'comment'),
    }
  }

  _updateCharge = (status: any) => {
    const stateObj = {}
    stateObj[`${status}Pending`] = true

    this.setState(stateObj)
    this.props.actions
      .updateChargeStatus(this.props.viewData.id, this._prepUpdatePayload(status), this.props.viewData.is_too_frequent)
      .then(
        () => {
          stateObj[`${status}Pending`] = false
          this.setState(stateObj)
          // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
          this.props.closeModal()
        },
        () => {
          stateObj[`${status}Pending`] = false
          this.setState(stateObj)
          // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
          this.props.closeModal()
        },
      )
  }

  onActionButtonClick = (e: any) => {
    this._updateCharge(e.target.value)
  }

  _onCopyClick = () => {
    // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    return this.props.copyClick(this.props.viewData.id)
  }

  _colorPriceBackground = () => {
    const { viewData } = this.props

    const status = viewData.status
    let statusColor = styles.x_medium_gray
    if (status === 'Approved') {
      statusColor = styles.x_success_dark
    } else if (status === 'Rejected') {
      statusColor = styles.x_alert
    }
    return (
      <Avatar textColor={styles.x_white} backgroundColor={statusColor} size={70}>
        {numeral(viewData.session_rate / 100).format('$0,0[.]00')}
      </Avatar>
    )
  }

  _showConflictingDates = () => {
    const { viewData } = this.props

    if (
      !viewData.is_too_frequent_with_charge_ids ||
      viewData.is_too_frequent_with_charge_ids === null ||
      viewData.is_too_frequent_with_charge_ids.size === 0
    ) {
      return false
    }

    return (
      <div styleName='detail'>
        <h4>
          Same Day
          <i styleName='indicator-dot' />
        </h4>
        <p>
          {viewData.is_too_frequent_with_charge_ids
            .map(($$charge: any) => {
              return format(parseISO($$charge.date_of_service), 'MM/dd/yyyy')
            })
            .join(', ')}
        </p>
      </div>
    )
  }

  _showPracticeName = () => {
    const { viewData } = this.props

    if (!viewData.practiceName) return false

    return (
      <div styleName='detail'>
        <h4>Practice Name</h4>
        <p>{viewData.practiceName}</p>
      </div>
    )
  }

  // @ts-expect-error TS(7030): Not all code paths return a value.
  getInitialValues = (chargeStatus: any) => {
    const prevComment = this.props.viewData.admin_note
    let reasons: any
    if (prevComment) {
      if (chargeStatus === 'submitted') {
        reasons = paymentRejectionReasons
      } else if (chargeStatus === 'approved') {
        reasons = paymentReversalReasons
      }
      if (reasons) {
        if (Object.values(reasons).includes(prevComment)) {
          const submittedReason = Object.keys(reasons).find((key) => reasons[key] === prevComment)
          return {
            description: submittedReason,
          }
        } else {
          return {
            description: 'other',
            description_text_area: prevComment,
          }
        }
      }
    }
  }

  submitComment = (values: any) => {
    const chargeId = this.props.viewData.id
    const chargeStatus = this.props.viewData.status.toLowerCase()
    let comment = values.get('description')
    if (comment === 'other') {
      comment = values.get('description_text_area')
    } else {
      comment = chargeStatus === 'submitted' ? paymentRejectionReasons[comment] : paymentReversalReasons[comment]
    }
    if (!comment) {
      return
    }
    this.setState({ adminNote: true })
    return this.props.actions.submitComment(chargeId, comment, chargeStatus)
  }

  _showButtons = () => {
    if (hasRole(this.props.roles, ROLES.VIEW_ONLY)) {
      return false
    }

    const copyButton = (
      <PrimaryButton disabled={this.state.cancelPending} onClick={this._onCopyClick}>
        Copy Payment
      </PrimaryButton>
    )
    const cancelButton = (
      <PrimaryButton
        styleType='line'
        isLoading={this.state.cancelPending}
        value='cancel'
        onClick={this.onActionButtonClick}
      >
        Cancel Payment
      </PrimaryButton>
    )
    const chargeStatus = this.props.viewData.status.toLowerCase()
    if (chargeStatus !== 'submitted' && !hasRole(this.props.roles, ROLES.PAYMENTS_ADMIN)) {
      return (
        <div className={styles['modal-button-group']}>
          {copyButton}
          {chargeStatus === 'pending' ? cancelButton : ''}
        </div>
      )
    }

    let formToShow
    if (hasRole(this.props.roles, ROLES.PAYMENTS_ADMIN)) {
      if (chargeStatus === 'submitted') {
        formToShow = (
          <RejectPaymentForm
            // @ts-expect-error TS(2322): Type '{ submitFunction: (values: any) => any; show... Remove this comment to see the full error message
            submitFunction={this.submitComment}
            showRejectText={false}
            initialValues={this.getInitialValues(chargeStatus)}
          />
        )
      } else if (chargeStatus === 'approved') {
        formToShow = (
          <ReversePaymentForm
            // @ts-expect-error TS(2322): Type '{ submitFunction: (values: any) => any; show... Remove this comment to see the full error message
            submitFunction={this.submitComment}
            showReverseText={false}
            initialValues={this.getInitialValues(chargeStatus)}
          />
        )
      }
    }
    const excludeButtonText = this.props.viewData.exclude_from_session_count ? 'Include' : 'Exclude'
    return (
      <div>
        {hasRole(this.props.roles, ROLES.PAYMENTS_ADMIN) ? (
          <div>
            {formToShow}
            {chargeStatus === 'submitted' && (
              <div className={styles['modal-button-group']}>
                <PrimaryButton
                  isLoading={this.state.approvePending}
                  disabled={this.state.rejectPending}
                  value='approve'
                  onClick={this.onActionButtonClick}
                >
                  Approve
                </PrimaryButton>
                <PrimaryButton
                  id='detailsmodalreject-submit'
                  isLoading={this.state.rejectPending}
                  disabled={this.state.approvePending || !this.state.adminNote}
                  value='reject'
                  onClick={this.onActionButtonClick}
                >
                  Reject
                </PrimaryButton>
              </div>
            )}
            {(chargeStatus === 'approved' || chargeStatus === 't0_sessions') && !this.props.viewData.is_health_plan && (
              <div className={styles['modal-button-group']}>
                <PrimaryButton
                  isLoading={this.state.excludePending}
                  value={'exclude'}
                  onClick={() => this.setState({ showConfirmationButtons: true })}
                  data-test-id={tID('PaymentDetailsModal-excludeIncludeButton')}
                >
                  {excludeButtonText}
                </PrimaryButton>
                {this.state.showConfirmationButtons && (
                  <div>
                    <TextButton
                      text={excludeButtonText}
                      testID={tID(`PaymentDetailsModal-${excludeButtonText}-button`)}
                      onPress={() => this._updateCharge('exclude')}
                    ></TextButton>
                    <TextButton
                      text={'Cancel'}
                      onPress={() => this.setState({ showConfirmationButtons: false })}
                    ></TextButton>
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <div className={styles['modal-button-group']}>
            {copyButton}
            {cancelButton}
          </div>
        )}
      </div>
    )
  }

  _showProviderComment = () => {
    const { viewData } = this.props

    if (!viewData.notes) return false

    return (
      <div styleName='detail detail-comment'>
        <h4>Provider Comment</h4>
        <p>{viewData.notes}</p>
      </div>
    )
  }

  _showAdminComment = () => {
    const { viewData } = this.props

    if (!viewData.admin_note) return false

    return (
      <div styleName='detail detail-comment'>
        <h4>Admin Comment</h4>
        <p>{viewData.admin_note}</p>
      </div>
    )
  }

  getECDCDisplayValue = (valuesToSearch: string[], constantsList: { label: string; value: string }[]): string => {
    let matchingLabelsString = ''

    valuesToSearch.forEach((valueToSearch) => {
      const foundCondition = constantsList.find((condition) => condition.value === valueToSearch)

      if (foundCondition) {
        if (matchingLabelsString !== '') {
          matchingLabelsString += ', '
        }
        matchingLabelsString += foundCondition.label
      }
    })

    return matchingLabelsString
  }

  render() {
    const { viewData, roles } = this.props
    // This component was rendering when viewData was an empty object.
    if (Object.keys(viewData).length === 0) return null

    const allConditions: { label: string; value: string }[] = [...getConditions({}), ...deprecatedConditions]
    const diagnosesArray = viewData.diagnosis.split(',')
    const diagnosesToDisplay = diagnosesArray.map((key: any) => diagnosesMap[key]).join(', ')

    // ECDC information to display if selected transfer contains ECDC data.
    // Using a local variable instead of LaunchDarkly (LD) flag because LD cannot be used in class components
    let isECDCTransfer = false
    let primaryConditionToDisplay
    let secondaryConditionToDisplay
    let ebtTypeToDisplay
    let severityToDisplay

    // set isECDCTransfer to true if transfer data contains necessary data
    isECDCTransfer = !!viewData.direct_access_info?.primary_condition

    if (isECDCTransfer === true) {
      const primaryConditionArray = viewData.direct_access_info.primary_condition.split(',') ?? ''
      const secondaryConditionArray = viewData.direct_access_info.secondary_condition?.split(',') ?? ''
      const ebtTypeArray = viewData.direct_access_info.ebt_type?.split(',') ?? ''
      const severityArray = viewData.direct_access_info.primary_condition_severity?.split(',') ?? ''

      // only search if optional ECDC values are present
      primaryConditionToDisplay = this.getECDCDisplayValue(primaryConditionArray, allConditions)
      ebtTypeToDisplay = ebtTypeArray ? this.getECDCDisplayValue(ebtTypeArray, ebtTypes) : ''
      secondaryConditionToDisplay = secondaryConditionArray
        ? this.getECDCDisplayValue(secondaryConditionArray, allConditions)
        : ''
      ebtTypeToDisplay = ebtTypeArray ? this.getECDCDisplayValue(ebtTypeArray, ebtTypes) : ''
      severityToDisplay = severityArray ? severityOptions[severityArray] : ''
    }

    return (
      <div styleName='payment-details-modal' data-test-id='paymentDetailsModal'>
        <div styleName='summary'>
          <div styleName='price'>{this._colorPriceBackground()}</div>
          <h3>
            <span>
              {viewData.first_name} {viewData.last_name}
            </span>
          </h3>
          <p>{viewData.visit_date}</p>
        </div>
        <div styleName='detail-group'>
          <div styleName='detail'>
            <h4>Status</h4>
            <p>
              {!hasRole(roles, ROLES.PAYMENTS_ADMIN) && viewData.status.toLowerCase() === 'pending'
                ? 'submitted'
                : viewData.status}
            </p>
          </div>
          {this._showConflictingDates()}
          {this._showPracticeName()}
          <div styleName='detail'>
            <h4>Session Duration</h4>
            <p>{viewData.session_duration}</p>
          </div>
          <div styleName='detail'>
            <h4>Reference Number</h4>
            <p>{viewData.reference_number}</p>
          </div>
          {hasRole(roles, [ROLES.PRACTICES_ADMIN, ROLES.PAYMENTS_ADMIN]) && (
            <div styleName='detail'>
              <span>
                <h4>Provider Name</h4>
                <p>
                  {viewData.provider_first_name} {viewData.provider_last_name}
                </p>
              </span>
            </div>
          )}
          <div styleName='detail'>
            <h4>Session Type</h4>
            <p>{sessionTypes[viewData.session_type]}</p>
          </div>
          {hasRole(roles, ROLES.PAYMENTS_ADMIN) && viewData.session_type === 'other' && (
            <div styleName='detail'>
              <span>
                <h4>Session Description</h4>
                <p>{viewData.other_session_description}</p>
              </span>
            </div>
          )}
          <div styleName='detail'>
            <h4>Patient Attendance</h4>
            <p>{attendanceOptions[viewData.attendance]}</p>
          </div>
          <div styleName='detail'>
            <h4>Patient&apos;s Date of Birth</h4>
            <p>{viewData.date_of_birth}</p>
          </div>
          <div styleName='detail'>
            <h4>Modality</h4>
            <p>{modalityOptions[viewData.modality]}</p>
          </div>
          <div styleName='detail'>
            <h4>Sponsoring Company</h4>
            <p>{viewData.displayed_company}</p>
          </div>
          {viewData.friendly_lyra_code && (
            <div styleName='detail'>
              <h4>Lyra code</h4>
              <p>{viewData.friendly_lyra_code}</p>
            </div>
          )}
          {isECDCTransfer ? (
            <>
              <div styleName='detail'>
                <h4>Primary Condition</h4>
                <p>{primaryConditionToDisplay}</p>
              </div>
              <div styleName='detail'>
                <h4>Primary Condition Severity</h4>
                <p>{severityToDisplay}</p>
              </div>
              <div styleName='detail'>
                <h4>Secondary Condition</h4>
                <p>{secondaryConditionToDisplay}</p>
              </div>
              <div styleName='detail'>
                <h4>EBT</h4>
                <p>{ebtTypeToDisplay}</p>
              </div>
            </>
          ) : (
            <div styleName='detail'>
              <h4>Diagnosis</h4>
              <p>{diagnosesToDisplay}</p>
            </div>
          )}
          <div styleName='detail'>
            <h4>Last Session</h4>
            <p>{viewData.last_session || viewData.last_session_terminated ? 'True' : 'False'}</p>
          </div>
          <div styleName='detail'>
            <h4>Patient Relationship</h4>
            <p>{viewData.relationship}</p>
          </div>
          {['dependent', 'other'].includes(viewData.relationship) && (
            <div styleName='detail'>
              <h4>Eligible member</h4>
              <p>
                {viewData.eligible_member_first_name} {viewData.eligible_member_last_name}
              </p>
            </div>
          )}
          {['dependent', 'other'].includes(viewData.relationship) && (
            <div styleName='detail'>
              <h4>Eligible Member Date of Birth</h4>
              <p>{viewData.eligible_member_dob}</p>
            </div>
          )}
          {hasRole(roles, ROLES.PAYMENTS_ADMIN) && (
            <div styleName='detail'>
              <h4>Submission Date</h4>
              <p>{format(parseISO(viewData.created_at), 'MM/dd/yyyy')}</p>
            </div>
          )}
          {hasRole(roles, ROLES.PAYMENTS_ADMIN) && (
            <div styleName='detail'>
              <h4>Excluded From Session Count</h4>
              <p>{viewData.exclude_from_session_count ? 'True' : 'False'}</p>
            </div>
          )}
          {this._showProviderComment()}
          {this._showAdminComment()}
        </div>
        <div styleName='bottom'>{this._showButtons()}</div>
      </div>
    )
  }
}

const mapStateToProps = ($$state: any) => {
  return {
    roles: getAuthRoles($$state),
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    actions: bindActionCreators({ ...paymentDataActions, ...alertActions }, dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(toJS(CSSModules(PaymentDetailsModal, styles, { allowMultiple: true })))
