import React, { FunctionComponent } from 'react'
import { BodyText, Size as BodyTextSize } from '../../bodyText/BodyText'
import { FormattedMessage } from 'react-intl'
import { Link } from '../../link/Link'
import { UICoreFormContext } from '../../../organisms/formBody/types'
import { noop } from 'lodash-es'
import { tID } from '../../../utils/utils'

export interface ConfirmEligibilityDisclaimerProps {
  contextProps?: UICoreFormContext
}

export const ConfirmEligibilityDisclaimer: FunctionComponent<ConfirmEligibilityDisclaimerProps> = ({
  contextProps = {},
}) => {
  const { stringMessage, onContactCNT } = contextProps

  if (!stringMessage) {
    return <></>
  }

  return (
    <BodyText
      text={
        <FormattedMessage
          {...stringMessage}
          values={{
            contactCNTLink: (text: string) => (
              <Link
                text={text}
                onPress={onContactCNT || noop}
                underline
                testID={tID('ConfirmEligibilityDisclaimer-ContactCNT-link')}
              />
            ),
          }}
        />
      }
      size={BodyTextSize.DEFAULT}
    />
  )
}
