import { useMemo } from 'react'

import { DisplayMode } from './types'
import { useZoom } from './useZoom'

type VideoPlacement = {
  self: boolean
  participant: boolean
}
export const useZoomVideoPlacement: (displayMode: DisplayMode) => VideoPlacement = (displayMode) => {
  const {
    displayMode: currentDisplayMode,
    sessionStarted,
    remoteParticipant,
    inSession,
    remoteParticipantScreenSharing,
    config: { showRemoteParticipantStatus, shouldJoinOnOpen },
  } = useZoom()

  const isMinimized = currentDisplayMode === DisplayMode.BANNER
  const displayBannerSelfView =
    inSession && (isMinimized || (sessionStarted && !!remoteParticipant) || remoteParticipantScreenSharing)

  const displayBannerParticipantView = useMemo(() => {
    if (!inSession) {
      return false
    }

    if (remoteParticipantScreenSharing) {
      return true
    }

    if (isMinimized) {
      return (sessionStarted && !!remoteParticipant) || showRemoteParticipantStatus
    }
    return showRemoteParticipantStatus && (!sessionStarted || !remoteParticipant)
  }, [
    inSession,
    isMinimized,
    remoteParticipant,
    remoteParticipantScreenSharing,
    sessionStarted,
    showRemoteParticipantStatus,
  ])

  return useMemo(() => {
    if (!inSession) {
      return {
        self: displayMode === currentDisplayMode,
        participant: false,
      }
    }

    if (!sessionStarted && !shouldJoinOnOpen) {
      return {
        self: true,
        participant: false,
      }
    }
    return {
      self: displayMode === DisplayMode.BANNER ? displayBannerSelfView : !displayBannerSelfView,
      participant: displayMode === DisplayMode.BANNER ? displayBannerParticipantView : !displayBannerParticipantView,
    }
  }, [
    currentDisplayMode,
    displayBannerParticipantView,
    displayBannerSelfView,
    displayMode,
    inSession,
    sessionStarted,
    shouldJoinOnOpen,
  ])
}
