import { createReducer } from '@reduxjs/toolkit'

import { ProviderCalendarConfiguration } from '@lyrahealth-inc/shared-app-logic'

import { GET_CALENDAR_TOKEN, GET_CALENDARS } from '../../common/constants/reduxConstants'

type CalendarState = {
  token?: string
  oAuthUrl?: string
  calendars?: ProviderCalendarConfiguration[]
  isLoading: boolean
}

const initialState = {
  isLoading: true,
}

export default createReducer<CalendarState>(initialState, (builder) => {
  builder.addCase(GET_CALENDAR_TOKEN, (state, action: any) => {
    state.token = action.data
    state.isLoading = false
    return state
  })
  builder.addCase(GET_CALENDARS, (state, action: any) => {
    state.calendars = action.data.filter((calendar: ProviderCalendarConfiguration) => !calendar.read_only)
    if (state.calendars && state.calendars.length === 0) {
      state.isLoading = false
    }
    return state
  })
})
