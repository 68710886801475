import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

export const TherapySecondaryRec: FunctionComponent<TherapySecondaryRecProps> = ({ width = 80, height = 80 }) => {
  return (
    <Svg width={width} height={height} viewBox='0 0 80 80' fill='none'>
      <Path
        d='M40.2812 0.269531C34.7592 0.269531 29.4015 1.21648 24.3569 3.08351C12.3469 7.53529 3.9034 17.4048 1.1906 30.1609C-0.602123 38.478 -0.372894 46.1706 1.89568 53.6672C4.79186 63.241 11.9011 71.3146 21.4007 75.817C26.1164 78.0571 31.5341 79.336 37.5004 79.6174C38.2671 79.6522 39.0465 79.6712 39.8212 79.6712C44.6081 79.6712 49.4235 79.0151 54.1345 77.7219C54.4965 77.6318 54.8412 77.5386 55.2048 77.4311C59.4384 76.1916 63.2483 74.2313 66.5081 71.6118C70.0477 68.7836 72.9613 65.1982 75.1587 60.9614C75.5919 60.1267 76.0061 59.2477 76.463 58.1964C78.9766 52.3598 80.1907 45.826 79.9757 39.2937C79.8745 36.4054 79.4445 33.3354 78.7015 30.1609C77.4779 25.0104 75.4828 20.1666 72.9313 16.1448C72.2325 15.0651 71.5907 14.1576 70.9551 13.3419C66.369 7.42621 59.2234 3.2748 50.2977 1.34295C47.0189 0.631554 43.6485 0.271112 40.2828 0.271112L40.2812 0.269531Z'
        fill='white'
      />
      <Path
        d='M76.5437 39.4006C76.4457 36.6324 76.022 33.7647 75.3549 30.9144C74.2641 26.3219 72.4713 21.7942 70.0131 17.9179C69.4424 17.0389 68.8574 16.1916 68.2219 15.3774C63.6121 9.43327 56.6261 6.12605 49.4758 4.57836C41.5113 2.85203 33.0424 3.35634 25.3862 6.19087C14.3105 10.2949 6.76968 19.2854 4.30982 30.8495C3.36445 35.2302 3.0878 40.1815 3.51148 44.9384H3.47828C3.72332 47.6417 4.21181 50.2802 4.94376 52.7069C7.66447 61.6974 14.2757 68.8319 22.6971 72.8221C27.258 74.9879 32.3548 76.0629 37.5353 76.308C42.8281 76.553 48.2031 75.8843 53.1719 74.5168C53.4975 74.4362 53.8074 74.354 54.1331 74.256C58.0252 73.1161 61.413 71.3566 64.3124 69.028C67.4726 66.5033 70.0779 63.3273 72.0983 59.4351C72.5377 58.5878 72.9282 57.742 73.3029 56.8773C75.6805 51.3552 76.7397 45.3621 76.5279 39.4179L76.5437 39.4021V39.4006Z'
        fill='#FFE9E3'
      />
      <Path
        d='M49.632 52.407C43.2563 63.2788 27.9185 61.2378 18.332 59.2301C20.1991 58.7843 22.4107 57.9986 22.7775 56.1158C23.0083 54.9269 22.3775 53.7681 21.9064 52.652C16.4697 39.801 30.4985 33.8822 40.885 37.1546C49.6304 39.9085 52.9218 46.7996 49.632 52.4086V52.407Z'
        fill='#008295'
      />
      <Path
        d='M34.9604 37.2457C44.2686 45.2719 58.1947 38.0947 66.6982 31.3632C56.7007 32.3798 62.12 22.319 50.3851 17.3218C35.6955 11.0662 24.5755 28.2916 34.9604 37.2457Z'
        fill='#00A4B3'
      />
    </Svg>
  )
}

type TherapySecondaryRecProps = {
  width?: number
  height?: number
}
