import { Platform, ScrollView, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'

import styled from 'styled-components/native'

import { Spacing, spacing } from '@lyrahealth-inc/shared-app-logic'

export const CenteredView = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
`

export const HorizontalCenteredView = styled.View`
  align-items: center;
`

export const VerticalCenteredView = styled.View`
  justify-content: center;
`

export const RowView = styled.View`
  display: flex;
  flex-direction: row;
`

export const ColumnView = styled.View`
  display: flex;
  flex-direction: column;
`

/**
 * Use flex-grow: 1; instead of flex: 1
 * flex: 1 is deprecated in styled-components. By default
 * flex: 1 with styled-components will add flex-grow and flex-shrink so avoid
 * using it with styled-components.
 * https://github.com/styled-components/styled-components/issues/465
 *
 * There are some places that were designed around the above implication,
 * so passing defaultFlex will do that
 **/
export const Flex1View = styled(View)<{ defaultFlex?: boolean }>`
  ${({ defaultFlex }) => (defaultFlex ? 'flex: 1;' : 'flex-grow: 1;')}
`

export const Flex1SafeAreaView = styled(SafeAreaView)<{ defaultFlex?: boolean }>`
  ${({ defaultFlex }) => (defaultFlex ? 'flex: 1;' : 'flex-grow: 1;')}
`

export const Flex1ScrollView = styled(Platform.OS !== 'web' ? ScrollView : View)<{ defaultFlex?: boolean }>`
  ${({ defaultFlex }) => (defaultFlex ? 'flex: 1;' : 'flex-grow: 1;')}
`

export const DisplayNoneView = styled.View`
  display: none;
`

export const EqualVerticalMarginsContainer = styled.View(
  ({ marginSize = spacing['24px'] }: { marginSize?: keyof Spacing }) => ({
    marginTop: marginSize,
    marginBottom: marginSize,
  }),
)

export interface SpacedViewProps {
  marginTop?: string
  marginRight?: string
  marginBottom?: string
  marginLeft?: string
  paddingTop?: string
  paddingRight?: string
  paddingBottom?: string
  paddingLeft?: string
}

export const SpacedView = styled.View<SpacedViewProps>(
  ({
    theme: { spacing },
    marginTop = '0px',
    marginRight = '0px',
    marginBottom = '0px',
    marginLeft = '0px',
    paddingTop = '0px',
    paddingRight = '0px',
    paddingBottom = '0px',
    paddingLeft = '0px',
  }) => ({
    marginTop: spacing[marginTop],
    marginRight: spacing[marginRight],
    marginBottom: spacing[marginBottom],
    marginLeft: spacing[marginLeft],

    paddingTop: spacing[paddingTop],
    paddingRight: spacing[paddingRight],
    paddingBottom: spacing[paddingBottom],
    paddingLeft: spacing[paddingLeft],
  }),
)
