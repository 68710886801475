import React from 'react'

const PastSessionsIcon: React.FC<PastSessionsIconProps> = ({ width = 36, isChecked = false }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} viewBox='0 0 36 36' role='img' aria-label='Gray Circle'>
      <g fill='none' fillRule='evenodd'>
        <circle cx='18' cy='18' r='18' fill='#F8F8F8' fillRule='nonzero' />
        {isChecked ? (
          <g strokeLinecap='round' strokeLinejoin='round' transform='translate(13 14)'>
            <polyline stroke='#FFF' strokeWidth='5' points='11 0 2.75 8 0 5.143' />
            <polyline stroke='silver' strokeWidth='3' points='11 0 2.75 8 0 5.143' />
          </g>
        ) : null}
      </g>
    </svg>
  )
}

type PastSessionsIconProps = {
  isChecked?: boolean
  width?: number | string
}

export default PastSessionsIcon
