import React, { useEffect } from 'react'

import classNames from 'classnames'

import { Appointment, SESSION_TYPES, useApptToShow, USER_ROLES } from '@lyrahealth-inc/shared-app-logic'

import styles from './sessionBanner.module.scss'
import SessionBannerInfo from './SessionBannerInfo'
import { useApptBannerInfo } from './useApptBannerInfo'
import colors from '../../../styles/_0colors.scss'
import PrimaryButton from '../../atoms/buttons/primaryButton/PrimaryButton'
import MessageIcon from '../../atoms/icons/MessageIcon'
import VideoIcon from '../../atoms/icons/VideoIcon'

const SessionBanner: React.FC<SessionBannerProps> = ({
  sessionType,
  title,
  onJoin,
  inSession,
  userRole,
  appts = [],
  customClass,
  onActiveSession,
  timeZone,
  children,
}) => {
  const isClient = userRole === USER_ROLES.CLIENT
  // get the appt that is available to show to the user
  const { apptToShow = null } = useApptToShow({ appts, reminderWindow: 10 })
  // get the information to display for this appointment
  const { sessionTitle, startTime, endTime, joinText, startsIn } = useApptBannerInfo({
    appt: apptToShow,
    isClient,
    title,
    sessionType,
    timeZone,
  })

  const Icon = sessionType === SESSION_TYPES.MESSAGE ? MessageIcon : VideoIcon

  useEffect(() => {
    onActiveSession && onActiveSession({ startsIn, appt: apptToShow })
  }, [apptToShow, onActiveSession, startsIn])

  return (
    apptToShow && (
      <div className={classNames(customClass, styles['session-container'])} data-test-id='SessionBanner'>
        {sessionType === SESSION_TYPES.MESSAGE && (
          <>
            <SessionBannerInfo
              sessionTitle={sessionTitle}
              icon={<Icon fillColor={colors.x_white} width={28} style={{ paddingTop: '4px' }} isFilled />}
              startTime={startTime.toDate()}
              endTime={endTime.toDate()}
            />
            <PrimaryButton
              customClass={'live-msg-join-btn'}
              disabled={inSession}
              data-test-id='SessionBanner-joinBtn'
              onClick={onJoin}
            >
              {joinText}
            </PrimaryButton>
            {children}
          </>
        )}
        {sessionType === SESSION_TYPES.VIDEO && (
          <>
            <SessionBannerInfo
              sessionTitle={sessionTitle}
              icon={<Icon fillColor={colors.x_white} width={28} isFilled />}
              startTime={startTime.toDate()}
              endTime={endTime.toDate()}
            />
            {/* TODO: Render Video Session children here */}
            <PrimaryButton disabled={inSession} data-test-id='SessionBanner-joinBtn' onClick={onJoin}>
              {joinText}
            </PrimaryButton>
            {children}
          </>
        )}
      </div>
    )
  )
}

type SessionBannerProps = {
  customClass?: string
  sessionType: SESSION_TYPES
  title?: string
  appts: Appointment[]
  onJoin: () => void
  inSession: boolean
  userRole: USER_ROLES
  timeZone: string
  onActiveSession?: (sessionInfo: SessionInfo) => {}
}

interface SessionInfo {
  startsIn: string
  appt: Appointment | null
}

export default SessionBanner
