import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import BottomSheetGorhom from '@gorhom/bottom-sheet'
import styled, { useTheme } from 'styled-components/native'

import {
  PressableOpacity,
  PrimaryButton,
  SelectField,
  SparklesIcon,
  Subhead,
  TertiaryButton,
  VideoIcon,
  VolumeUpIcon,
  XIcon,
} from '../../atoms'
import { MicrophoneIcon } from '../../atoms/icons/MicrophoneIcon'
import { useGetIsMobileWebBrowser } from '../../hooks'
import { useVideoCallEffects, useZoom } from '../../hooks/useZoom'
import { DeviceSettings, VideoCallEffect } from '../../hooks/useZoom/types'
import ZoomVideo, { MobileVideoFacingMode } from '../../hooks/useZoom/ZoomVideo'
import { Modal } from '../../organisms'
import { SubheadSize } from '../../styles'
import { ThemeType, tID } from '../../utils'
import { Notice, NoticeType } from '../notice/Notice'

const HeadingAndCloseButtonContainer = styled.View({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
})

const ButtonContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: 'row',
  flexWrap: 'wrap-reverse',
  justifyContent: 'flex-end',
  gap: theme.spacing['16px'],
}))

const Container = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  gap: theme.spacing['16px'],
  margin: theme.breakpoints.isMobileSized ? theme.spacing['16px'] : '0px',
}))

const SettingsContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexGrow: 1,
  flexShrink: 1,
  gap: theme.spacing['16px'],
}))

const DecorativeIconContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  position: 'absolute',
  zIndex: 1,
  bottom: theme.spacing['12px'],
  left: theme.spacing['16px'],
}))

const CancelButton = styled(TertiaryButton)<{ theme: ThemeType }>(({ theme }) => ({
  width: theme.breakpoints.isMobileSized ? '100%' : undefined,
}))

const SaveButton = styled(PrimaryButton)<{ theme: ThemeType }>(({ theme }) => ({
  width: theme.breakpoints.isMobileSized ? '100%' : undefined,
}))

const inputStyle = { marginBottom: 0 }

type Options = {
  label: string
  value: string
}[]

export type ZoomSettingsModalProps = {
  settings: DeviceSettings
  show: boolean
  onClose: () => void
  onSave: (settings: DeviceSettings) => void
}

export const ZoomSettingsModal: FunctionComponent<ZoomSettingsModalProps> = ({ settings, show, onClose, onSave }) => {
  const { formatMessage } = useIntl()
  const { colors } = useTheme() as ThemeType
  const ref = useRef<BottomSheetGorhom>(null)

  const { supportsVirtualBackground, sessionStarted } = useZoom()

  const [videoInputOptions, setVideoInputOptions] = useState<Options>([])
  const [audioInputOptions, setAudioInputOptions] = useState<Options>([])
  const [audioOutputOptions, setAudioOutputOptions] = useState<Options>([])
  const videoEffectOptions = useVideoCallEffects()

  const [selectedVideoInput, setSelectedVideoInput] = useState<string | undefined>(settings.videoInput)
  const [selectedAudioInput, setSelectedAudioInput] = useState<string | undefined>(settings.audioInput)
  const [selectedAudioOutput, setSelectedAudioOutput] = useState<string | undefined>(settings.audioOutput)
  const [selectedVideoEffect, setSelectedVideoEffect] = useState<VideoCallEffect>(
    settings.videoEffect ?? VideoCallEffect.NONE,
  )

  const isMobileBrowser = useGetIsMobileWebBrowser()

  useEffect(() => {
    if (videoInputOptions.some((option) => option.value === settings.videoInput)) {
      setSelectedVideoInput(settings.videoInput)
    }

    if (audioInputOptions.some((option) => option.value === settings.audioInput)) {
      setSelectedAudioInput(settings.audioInput)
    }

    if (audioOutputOptions.some((option) => option.value === settings.audioOutput)) {
      setSelectedAudioOutput(settings.audioOutput)
    }

    if (settings.videoEffect) {
      setSelectedVideoEffect(settings.videoEffect)
    }
  }, [audioInputOptions, audioOutputOptions, settings, videoInputOptions])

  const openBottomSheet = useCallback(() => {
    ref.current?.expand()
  }, [])

  const onDeviceChange = useCallback(() => {
    ZoomVideo.getDevices().then((devices) => {
      const mappedDevices = devices.map((device) => ({
        label: device.label,
        value: device.deviceId,
        kind: device.kind,
      }))

      const videoInputOptions = isMobileBrowser
        ? [
            {
              value: MobileVideoFacingMode.User,
              label: formatMessage({
                defaultMessage: 'Front-facing',
                description: 'Option to select front-facing camera from settings',
              }),
            },
            {
              value: MobileVideoFacingMode.Environment,
              label: formatMessage({
                defaultMessage: 'Rear-facing',
                description: 'Option to select rear-facing camera from settings',
              }),
            },
          ]
        : mappedDevices.filter((device) => device.kind === 'videoinput')

      setVideoInputOptions(videoInputOptions)
      setAudioInputOptions(mappedDevices.filter((device) => device.kind === 'audioinput'))
      setAudioOutputOptions(mappedDevices.filter((device) => device.kind === 'audiooutput'))
    })
  }, [formatMessage, isMobileBrowser])

  useEffect(() => {
    onDeviceChange()
    navigator.mediaDevices.addEventListener('devicechange', onDeviceChange)
    return () => navigator.mediaDevices.removeEventListener('devicechange', onDeviceChange)
  }, [onDeviceChange])

  const onOptionsChange = useCallback(
    (
      selectedOption: string | undefined,
      options: Options,
      setAction: (value: React.SetStateAction<string | undefined>) => void,
    ) => {
      if (options.length === 0) {
        return
      }

      const exists = options.find((option) => option.value === selectedOption)
      if (exists) {
        return
      }

      const defaultOption = options.find((option) => option.label === 'default')
      setAction(defaultOption?.value ?? options[0].value)
    },
    [],
  )
  useEffect(() => {
    onOptionsChange(selectedVideoInput, videoInputOptions, setSelectedVideoInput)
  }, [onOptionsChange, selectedVideoInput, videoInputOptions])

  useEffect(() => {
    onOptionsChange(selectedAudioInput, audioInputOptions, setSelectedAudioInput)
  }, [onOptionsChange, selectedAudioInput, audioInputOptions])

  useEffect(() => {
    onOptionsChange(selectedAudioOutput, audioOutputOptions, setSelectedAudioOutput)
  }, [onOptionsChange, selectedAudioOutput, audioOutputOptions])

  const onCloseModal = useCallback(() => {
    setSelectedVideoInput(settings.videoInput)
    setSelectedAudioInput(settings.audioInput)
    setSelectedAudioOutput(settings.audioOutput)
    setSelectedVideoEffect(settings.videoEffect ?? VideoCallEffect.NONE)
    onClose()
  }, [settings, onClose])
  return (
    <Modal
      visible={show}
      onCloseEnd={onCloseModal}
      onRequestClose={onCloseModal}
      snapPoints={[480]}
      onLayout={openBottomSheet}
      bottomSheetRef={ref}
      width='621px'
      modalContents={
        <Container>
          <HeadingAndCloseButtonContainer>
            <Subhead
              text={
                <FormattedMessage
                  defaultMessage='Settings'
                  description='Title of modal where user is selecting video settings'
                />
              }
              size={SubheadSize.MEDIUM}
            />
            <PressableOpacity hitSlop={60} onPress={onCloseModal} testID={tID('ZoomSettingsModal-confirm-closeButton')}>
              <XIcon size={24} fillColor={colors.iconDefault} />
            </PressableOpacity>
          </HeadingAndCloseButtonContainer>
          <SettingsContainer>
            <SelectField
              label={formatMessage({
                defaultMessage: 'Camera',
                description: 'Label for field to select camera for video settings',
              })}
              value={selectedVideoInput ?? ''}
              onChange={(input) => setSelectedVideoInput(input)}
              options={videoInputOptions}
              baseInputStyle={inputStyle}
              disablePlaceholder
              decorativeIcon={
                <DecorativeIconContainer>
                  <VideoIcon size={24} />
                </DecorativeIconContainer>
              }
            />
            <SelectField
              label={formatMessage({
                defaultMessage: 'Microphone',
                description: 'Label for field to select microphone for video settings',
              })}
              value={selectedAudioInput ?? ''}
              onChange={(input) => setSelectedAudioInput(input)}
              options={audioInputOptions}
              baseInputStyle={inputStyle}
              disablePlaceholder
              decorativeIcon={
                <DecorativeIconContainer>
                  <MicrophoneIcon size={24} />
                </DecorativeIconContainer>
              }
            />
            <SelectField
              label={formatMessage({
                defaultMessage: 'Speaker',
                description: 'Label for field to select speaker for video settings',
              })}
              value={selectedAudioOutput ?? ''}
              onChange={(output) => setSelectedAudioOutput(output)}
              options={audioOutputOptions}
              baseInputStyle={inputStyle}
              disablePlaceholder
              decorativeIcon={
                <DecorativeIconContainer>
                  <VolumeUpIcon isFilled={false} color={colors.iconDefault} height={24} width={24} />
                </DecorativeIconContainer>
              }
            />
            {supportsVirtualBackground && (
              <SelectField
                label={formatMessage({
                  defaultMessage: 'Background effects',
                  description: 'Label for field to select background effect for video settings',
                })}
                value={selectedVideoEffect}
                onChange={(output) => setSelectedVideoEffect(output)}
                options={videoEffectOptions}
                baseInputStyle={inputStyle}
                disablePlaceholder
                decorativeIcon={
                  <DecorativeIconContainer>
                    <SparklesIcon color={colors.iconDefault} size={24} />
                  </DecorativeIconContainer>
                }
              />
            )}
            {selectedVideoEffect !== VideoCallEffect.NONE && !sessionStarted && (
              <Notice
                type={NoticeType.ERROR}
                text={
                  <FormattedMessage
                    defaultMessage='Background effect will be applied when your session has started'
                    description='Notice that informs user that the selected background effect will only be applied when the session starts'
                  />
                }
              />
            )}
          </SettingsContainer>
          <ButtonContainer>
            <CancelButton
              onPress={onCloseModal}
              text={
                <FormattedMessage
                  defaultMessage='Cancel'
                  description='Button text that describes cancelling video settings modal'
                />
              }
              testID={tID('ZoomSettingsModal-cancel-button')}
            />
            <SaveButton
              text={<FormattedMessage defaultMessage='Save' description='Button text that saves video settings' />}
              onPress={() =>
                onSave({
                  videoInput: selectedVideoInput,
                  audioInput: selectedAudioInput,
                  audioOutput: selectedAudioOutput,
                  videoEffect: selectedVideoEffect,
                })
              }
              testID={tID('ZoomSettingsModal-confirm-button')}
            />
          </ButtonContainer>
        </Container>
      }
    />
  )
}
