import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { View } from 'react-native'

import { noop } from 'lodash-es'

import { UICoreFormContext } from '../../../organisms/formBody/types'
import { tID } from '../../../utils/utils'
import { BodyText, Size as BodyTextSize } from '../../bodyText/BodyText'
import { Link } from '../../link/Link'

export interface ChildPrimaryNeedsSubtitleProps {
  contextProps?: UICoreFormContext
}

export const ChildPrimaryNeedsSubtitle: FunctionComponent<ChildPrimaryNeedsSubtitleProps> = ({ contextProps = {} }) => {
  const { stringTemplateData, onShowTopicDetailsPress } = contextProps
  const name = stringTemplateData?.searcherFirstName

  return (
    <BodyText
      text={
        <FormattedMessage
          defaultMessage='<bold>Pick one topic that’s most relevant.</bold> You’ll be able to share more details during {name}’s intake with a provider. <link>Show topic details</link>'
          description='Description on Primary Needs screen that includes link to navigate to modal with more details about each primary need'
          values={{
            name,
            bold: (chunks: string) => (
              <BodyText size={BodyTextSize.DEFAULT} bold textAlign='center'>
                {chunks}
              </BodyText>
            ),
            link: (chunks: string) => (
              <View testID={tID('ChildPrimaryNeedsSubtitle-ShowTopicDetails-link')}>
                <Link
                  text={chunks}
                  onPress={onShowTopicDetailsPress || noop}
                  underline
                  size={BodyTextSize.DEFAULT}
                  textAlign='center'
                />
              </View>
            ),
          }}
        />
      }
      size={BodyTextSize.DEFAULT}
      textAlign='center'
    />
  )
}
