import React, { Component } from 'react'
import CSSModules from 'react-css-modules'
import styles from './clientEmail.module.scss'
import { STSTypes } from '../../../../mixpanel/mixpanelConstants'
import { track } from '../../../../mixpanel/mixpanelTracking'
import { sessionTracking } from '../../common/constants/appConstants'

type Props = {
  initialSession?: boolean
  sessionsLeft?: number
  firstName?: string
  lastName?: string
  displayVisitsPerIssuePerYear: number
}

class ThankYou extends Component<Props> {
  render() {
    const { initialSession, sessionsLeft, firstName, lastName, displayVisitsPerIssuePerYear } = this.props
    return (
      <span>
        <h1 styleName='title'>Thank you!</h1>
        {displayVisitsPerIssuePerYear ? (
          <div>
            <p styleName='success'>
              Your payment was successfully submitted. As a reminder, your client,{' '}
              <span styleName='capitalize'>{firstName}</span> <span styleName='capitalize'>{lastName}</span> has session
              costs covered for a set number of sessions as part of their Lyra benefit
            </p>
            <p styleName='remaining-sessions'>
              Please note that <span styleName='capitalize'>{firstName}</span> has 5 sessions per issue per year. Issues
              determined by you and your client.
            </p>
            <p className='paragraph-small'>
              For any questions, please contact <a href='mailto:providers@lyrahealth.com'>providers@lyrahealth.com</a>
            </p>
          </div>
        ) : initialSession ? (
          <div>
            <p styleName='success'>
              Your payment was successfully submitted. As a reminder, your client,{' '}
              <span styleName='capitalize'>{firstName}</span> <span styleName='capitalize'>{lastName}</span> has session
              costs covered for a set number of sessions as part of their Lyra benefit
            </p>
            <p styleName='remaining-sessions'>
              Please note that <span styleName='capitalize'>{firstName}</span> has {sessionsLeft} sessions remaining.
            </p>
            <p className='paragraph-small'>
              We’ll let you both know when there are only {sessionTracking.WARNING_THRESHOLD} sessions left so you and{' '}
              {firstName} can start planning to complete treatment or discussing longer term care. For any questions,
              please contact <a href='mailto:providers@lyrahealth.com'>providers@lyrahealth.com</a>
            </p>
          </div>
        ) : (
          <h2 styleName='success'>Your payment was successfully submitted.</h2>
        )}
      </span>
    )
  }

  componentDidMount() {
    if (this.props.initialSession) {
      track({ event: 'STS', properties: { type: (STSTypes as any).STS_INITIAL } })
    }
  }
}

export default CSSModules(ThankYou, styles)
