import { IS_WEB } from '../constants'
import * as realLinking from 'expo-linking'

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  interface Window {
    Cypress: any
  }
}

let modifiedLinking = realLinking
// The block below stubs the `openURL` method of the expo-linking module.
if (window.Cypress && window.Cypress.runner.getStartTime()) {
  // Need to shallow clone the Linking module because `openURL` property is readonly which prevents it to be stubbed.
  modifiedLinking = { ...realLinking }
  window.Cypress.cy.stub(modifiedLinking, 'openURL').as('openURL')
}

export const Linking = modifiedLinking

const ensureHttpProtocolInUrl = (url: string) => {
  let urlWithHttpProtocol = url
  if (!url.match(/^https?:\/\//i)) {
    urlWithHttpProtocol = 'http://' + url
  }

  return urlWithHttpProtocol
}

export const openUrl = (url: string) => {
  const isPhoneNumber = url.startsWith('tel:')
  const isEmailAddress = url.startsWith('mailto:')
  let validUrl = url.replace(/\s/g, '')

  if (!isPhoneNumber && !isEmailAddress) {
    validUrl = ensureHttpProtocolInUrl(validUrl)
  }

  IS_WEB && !window.Cypress
    ? isPhoneNumber
      ? window.open(validUrl)
      : window.open(validUrl, '_blank')
    : Linking.openURL(validUrl)
}
