import React from 'react'
import { ViewStyle } from 'react-native'
import { Path, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { tID } from '../../utils'

export const VideoIcon: React.FC<VideoIconProps> = ({
  size = 14,
  fillColor,
  isFilled = false,
  isMuted = false,
  style,
}) => {
  const { colors } = useTheme()

  if (isFilled) {
    return (
      <Svg width={size} height={size} viewBox='0 0 24 24' testID={tID('VideoIcon')} style={style}>
        {isMuted ? (
          <Path
            fill={fillColor || colors.iconDefault}
            d='M24,7.25 L24,16.75 C24,17.02 23.86,17.26 23.63,17.4 C23.51,17.47 23.38,17.5 23.25,17.5 C23.12,17.5 23,17.47 22.89,17.41 L18.39,14.91 C18.15,14.77 18,14.52 18,14.25 L18,9.75 C18,9.48 18.15,9.23 18.39,9.09 L22.89,6.59 C23.12,6.47 23.4,6.47 23.63,6.6 C23.86,6.74 24,6.98 24,7.25 M14.4020109,17.862671 C14.117447,17.9519123 13.8144653,18 13.5,18 L4,18 C2.34,18 1,16.66 1,15 L1,9 C1,7.77021382 1.73544101,6.71605534 2.7915937,6.25225387 L1.21966991,4.68033009 C0.926776695,4.38743687 0.926776695,3.91256313 1.21966991,3.61966991 C1.51256313,3.3267767 1.98743687,3.3267767 2.28033009,3.61966991 L17.4831259,18.8224657 C17.7760191,19.1153589 17.7760191,19.5902327 17.4831259,19.8831259 C17.1902327,20.1760191 16.7153589,20.1760191 16.4224657,19.8831259 L14.4020109,17.862671 Z M16.4262735,15.6656133 L6.76066017,6 L13.5,6 C15.16,6 16.5,7.34 16.5,9 L16.5,15 C16.5,15.2288434 16.4745337,15.4516053 16.4262735,15.6656133 Z'
          />
        ) : (
          <Path
            fill={fillColor || colors.iconDefault}
            d='M24,7.25 L24,16.75 C24,17.02 23.86,17.26 23.63,17.4 C23.51,17.47 23.38,17.5 23.25,17.5 C23.12,17.5 23,17.47 22.89,17.41 L18.39,14.91 C18.15,14.77 18,14.52 18,14.25 L18,9.75 C18,9.48 18.15,9.23 18.39,9.09 L22.89,6.59 C23.12,6.47 23.4,6.47 23.63,6.6 C23.86,6.74 24,6.98 24,7.25 M13.5,6 L4,6 C2.34,6 1,7.34 1,9 L1,15 C1,16.66 2.34,18 4,18 L13.5,18 C15.16,18 16.5,16.66 16.5,15 L16.5,9 C16.5,7.34 15.16,6 13.5,6'
          />
        )}
      </Svg>
    )
  } else {
    return (
      <Svg width={size} height={size} viewBox='0 0 24 24' style={style} testID={tID('VideoIcon')}>
        {isMuted ? (
          <Path
            fill={fillColor || colors.iconDefault}
            d='M19.5,10.1914 L22.5,8.5244 L22.5,15.4754 L19.5,13.8084 L19.5,10.1914 Z M23.63,17.3964 C23.859,17.2614 24,17.0154 24,16.7504 L24,7.2504 C24,6.9844 23.859,6.7384 23.63,6.6034 C23.401,6.4694 23.118,6.4644 22.886,6.5944 L18.386,9.0944 C18.147,9.2264 18,9.4774 18,9.7504 L18,14.2504 C18,14.5224 18.147,14.7734 18.386,14.9054 L22.886,17.4054 C22.999,17.4684 23.125,17.5004 23.25,17.5004 C23.382,17.5004 23.513,17.4654 23.63,17.3964 Z M14.8248657,18.3855259 C14.5628005,18.4603383 14.2860801,18.5004 14,18.5004 L4,18.5004 C2.343,18.5004 1,17.1564 1,15.5004 L1,8.5004 C1,7.43126869 1.55910463,6.4928588 2.40091202,5.96157219 L1.21966991,4.78033009 C0.926776695,4.48743687 0.926776695,4.01256313 1.21966991,3.71966991 C1.51256313,3.4267767 1.98743687,3.4267767 2.28033009,3.71966991 L17.4831259,18.9224657 C17.7760191,19.2153589 17.7760191,19.6902327 17.4831259,19.9831259 C17.1902327,20.2760191 16.7153589,20.2760191 16.4224657,19.9831259 L14.8248657,18.3855259 Z M3.51881362,7.0794738 C2.92705126,7.28024378 2.5,7.84082709 2.5,8.5004 L2.5,15.5004 C2.5,16.3274 3.173,17.0004 4,17.0004 L13.4397398,17.0004 L3.51881362,7.0794738 Z M16.9065741,16.2459139 L15.5,14.8393398 L15.5,8.5004 C15.5,7.6724 14.827,7.0004 14,7.0004 L7.66106017,7.0004 L6.16106017,5.5004 L14,5.5004 C15.657,5.5004 17,6.8434 17,8.5004 L17,15.5004 C17,15.7577292 16.967571,16.0075245 16.9065741,16.2459139 Z'
          />
        ) : (
          <Path
            fill={fillColor || colors.iconDefault}
            d='M19.5,10.1914 L22.5,8.5244 L22.5,15.4754 L19.5,13.8084 L19.5,10.1914 Z M23.63,17.3964 C23.859,17.2614 24,17.0154 24,16.7504 L24,7.2504 C24,6.9844 23.859,6.7384 23.63,6.6034 C23.401,6.4694 23.118,6.4644 22.886,6.5944 L18.386,9.0944 C18.147,9.2264 18,9.4774 18,9.7504 L18,14.2504 C18,14.5224 18.147,14.7734 18.386,14.9054 L22.886,17.4054 C22.999,17.4684 23.125,17.5004 23.25,17.5004 C23.382,17.5004 23.513,17.4654 23.63,17.3964 Z M14,5.5004 C15.657,5.5004 17,6.8434 17,8.5004 L17,15.5004 C17,17.1564 15.657,18.5004 14,18.5004 L4,18.5004 C2.343,18.5004 1,17.1564 1,15.5004 L1,8.5004 C1,6.8434 2.343,5.5004 4,5.5004 L14,5.5004 Z M14,7.0004 L4,7.0004 C3.173,7.0004 2.5,7.6724 2.5,8.5004 L2.5,15.5004 C2.5,16.3274 3.173,17.0004 4,17.0004 L14,17.0004 C14.827,17.0004 15.5,16.3274 15.5,15.5004 L15.5,8.5004 C15.5,7.6724 14.827,7.0004 14,7.0004 Z'
          />
        )}
      </Svg>
    )
  }
}

type VideoIconProps = {
  size?: string | number
  fillColor?: string
  isFilled?: boolean
  isMuted?: boolean
  style?: ViewStyle
}
