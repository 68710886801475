import React, { FunctionComponent } from 'react'
import { ImageSourcePropType } from 'react-native'

import styled from 'styled-components/native'

import audImage from '../../assets/aud.png'
import { tID } from '../../utils'
import { Image } from '../image/Image'

const ImageContainer = styled(Image)<{ size: string }>(({ size }) => ({
  width: size,
  height: size,
}))

/**
 * Illustration to show on the treatment card
 */
export const Renew: FunctionComponent<RenewProps> = ({ size = 124 }) => {
  const sizeInPixels = `${size}px`
  return (
    <ImageContainer
      size={sizeInPixels}
      testID={`${tID('RenewIllustration')}`}
      source={audImage as ImageSourcePropType}
    />
  )
}

type RenewProps = {
  size?: number
}
