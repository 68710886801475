import { createReducer } from '@reduxjs/toolkit'

import { Appointment, ConversationMessages, ConversationObject } from '@lyrahealth-inc/shared-app-logic'

import {
  ADD_MESSAGE,
  CLEAR_MESSAGES,
  CLEAR_SELECTED_CONVERSATION,
  GET_MESSAGE_CONVERSATIONS,
  GET_MESSAGES,
  INCREMENT_UNREAD_MESSAGE_COUNT,
  SAVE_MESSAGE_DRAFT,
  SET_ACTIVE_LIVE_MSG_SESSION,
  SET_CONVERSATIONS_CLIENT,
  SET_LIVE_MSG_CLIENT_ID_TO_OPEN,
  SET_SELECTED_CONVERSATION,
  TOGGLE_LIVE_MSG_SESSION,
  UPDATE_SELECTED_CONVERSATION_ID,
  UPDATE_UNREAD_MESSAGE_COUNT,
} from '../../../common/constants/reduxConstants'
import { TwilioConversations } from '../../../common/twilio-conversations'

type MessagesState = {
  conversations: ConversationObject[]
  selectedConversation?: ConversationObject
  messages: ConversationMessages
  conversationsClient?: typeof TwilioConversations
  messagesFetched: boolean
  conversationsFetched: boolean
  inLiveMsgSession: boolean
  activeLiveMsgAppt?: Appointment
  liveMsgClientIdToOpen: string | null
  drafts: { content: string; conversationId: string }[]
}

const initialState = {
  messages: { conversation_messages: [] },
  conversations: [],
  messagesFetched: false,
  conversationsFetched: false,
  inLiveMsgSession: false,
  liveMsgClientIdToOpen: null,
  drafts: [],
}

export default createReducer<MessagesState>(initialState, (builder) => {
  builder.addCase(SET_CONVERSATIONS_CLIENT, (state, action: any) => {
    state.conversationsClient = action.data
    return state
  })
  builder.addCase(GET_MESSAGE_CONVERSATIONS, (state, action: any) => {
    state.conversations = action.data
    state.conversationsFetched = true
    return state
  })
  builder.addCase(GET_MESSAGES, (state, action: any) => {
    state.messages = action.data
    state.messagesFetched = true
    return state
  })
  builder.addCase(ADD_MESSAGE, (state, action: any) => {
    state.messages.conversation_messages.push(action.data)
    return state
  })
  builder.addCase(SET_SELECTED_CONVERSATION, (state, action: any) => {
    // When the conversation gets fetched, the server returns an array, hence the fallback.
    // Since only 1 message conversation is allowed per provider patient relationship,
    // we use the index 0 in that case.
    const conversation = action.data[0] || action.data
    if (!conversation) {
      return state
    }

    // Update the `conversations` array to include this selected conversation, if it doesn't already exist in the array
    const conversationExists = state.conversations.some((c) => c.conversation_id === conversation?.conversation_id)
    if (!conversationExists) {
      state.conversations.push(conversation)
    }

    state.selectedConversation = conversation
    return state
  })
  builder.addCase(CLEAR_SELECTED_CONVERSATION, (state) => {
    state.selectedConversation = undefined
    state.messages = { conversation_messages: [] }
    state.messagesFetched = false
    return state
  })
  builder.addCase(CLEAR_MESSAGES, () => {
    return initialState
  })
  builder.addCase(UPDATE_UNREAD_MESSAGE_COUNT, (state, action: any) => {
    const conversation = state.conversations.find(
      (conversation) => conversation.conversation_id === action.conversationId,
    )
    // Update conversation in the array
    if (conversation) {
      conversation.conversation_attributes = action.data
    }

    if (state.selectedConversation && state.selectedConversation.conversation_id === action.conversationId) {
      state.selectedConversation.conversation_attributes = action.data
    }
    return state
  })
  builder.addCase(UPDATE_SELECTED_CONVERSATION_ID, (state, action: any) => {
    const conversation = state.conversations.find(
      (conversation) => conversation.conversation_id === action.data.oldConversationId,
    )
    if (conversation) {
      conversation.conversation_id = action.data.newConversationId
      conversation.conversation_state = 'active'
    }

    if (state.selectedConversation) {
      state.selectedConversation.conversation_id = action.data.newConversationId
      state.selectedConversation.conversation_state = 'active'
    }
    return state
  })
  builder.addCase(INCREMENT_UNREAD_MESSAGE_COUNT, (state, action: any) => {
    const conversation = state.conversations.find(
      (conversation) => conversation.conversation_id === action.data.conversationId,
    )
    if (conversation) {
      conversation.conversation_attributes.unread_provider_messages_count =
        conversation.conversation_attributes.unread_provider_messages_count ?? 0
      conversation.conversation_attributes.unread_provider_messages_count += 1
    }

    if (state.selectedConversation && state.selectedConversation.conversation_id === action.data.conversationId) {
      state.selectedConversation.conversation_attributes.unread_provider_messages_count =
        state.selectedConversation.conversation_attributes.unread_provider_messages_count ?? 0
      state.selectedConversation.conversation_attributes.unread_provider_messages_count += 1
    }
    return state
  })
  builder.addCase(TOGGLE_LIVE_MSG_SESSION, (state, action: any) => {
    state.inLiveMsgSession = action.payload
    return state
  })
  builder.addCase(SET_ACTIVE_LIVE_MSG_SESSION, (state, action: any) => {
    const { appt } = action.payload
    state.activeLiveMsgAppt = appt
    return state
  })
  builder.addCase(SET_LIVE_MSG_CLIENT_ID_TO_OPEN, (state, action: any) => {
    state.liveMsgClientIdToOpen = action.payload
    return state
  })
  builder.addCase(SAVE_MESSAGE_DRAFT, (state, action: any) => {
    if (action.data.conversationId) {
      const draftIdx = state.drafts.findIndex((draft) => draft.conversationId === action.data.conversationId)
      if (draftIdx !== -1) {
        state.drafts[draftIdx] = action.data
      } else {
        state.drafts.push(action.data)
      }
    }
    return state
  })
})
