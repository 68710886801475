import React from 'react'
import {
  colors,
  tID,
  ToggleSwitch,
  ExpeditedBookingIcon,
  InfoIcon,
  SelectFieldRFF,
} from '@lyrahealth-inc/ui-core-crossplatform'
import { Tooltip } from '@lyrahealth-inc/ui-core'
import styled from 'styled-components'
import { Form, Field } from 'react-final-form'

const ExpeditedBookingContainer = styled.div`
  background-color: ${colors.ui_oatmeal2};
  border-radius: 8px;
  padding: 22px 16px;
  margin-bottom: 32px;

  p {
    margin-left: 8px;
    margin-right: 8px;
  }
`
const ToggleWrapper = styled.div`
  margin-left: auto;
`

const FlexRow = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;

  p {
    color: ${colors.ui_oatmeal6};
    margin-left: 16px;
  }
`
const MiddleColumn = styled.div`
  margin: 16px 16px 18px 40px;
  margin-bottom: 0;

  p {
    color: ${colors.ui_oatmeal6};
    margin-left: 0;
    margin-top: 8px;
  }
`
const DropdownContainer = styled.div`
  width: 126px;

  & > div:first-child {
    margin-bottom: 8px;
  }
`

const DropdownLabel = styled.div`
  color: black;
  padding-bottom: 8px;
`

const TooltipContainer = styled.div`
  margin-top: 8px;
  margin-left: -4px;
`

export const ExpeditedBookingToggle: React.FC<ExpeditedBookingToggleProps> = ({
  gracePeriod,
  onChangeGracePeriod,
  onChangeToggle,
  programName,
  status = false,
  displayError = false,
}) => {
  // drop down hours range from 1-23, and remove the first one because 0-index
  const hours = [...Array(24).keys()].slice(1)
  return (
    <ExpeditedBookingContainer data-testid={`ExpeditedBookingToggle-${programName}`}>
      <FlexRow>
        <ExpeditedBookingIcon width={24} />
        <p data-testid={tID('ExpeditedBookingToggle-text')}>Allow bookings less than 24 hours in advance</p>
        <TooltipContainer>
          <Tooltip
            content={
              programName === 'Coaching'
                ? 'This change will immediately apply to clients who book through your calendar (e.g. new clients, clients with your direct booking link, rescheduling)'
                : 'This change will immediately apply to clients who book through your calendar (e.g. new clients, clients with your direct booking link)'
            }
          >
            <InfoIcon />
          </Tooltip>
        </TooltipContainer>
        <ToggleWrapper data-testid={tID('ExpeditedBookingToggle-buffer-toggle')}>
          <ToggleSwitch
            isOn={status}
            testID={tID(`ExpeditedBookingToggle-${programName}`)}
            onPress={() => {
              onChangeToggle(programName, !status)
            }}
          />
        </ToggleWrapper>
      </FlexRow>
      {status && (
        <MiddleColumn>
          <DropdownLabel data-testid={tID('ExpeditedBooking-buffer-text')}>
            How many hours in advance can this be booked?
          </DropdownLabel>
          <Form
            onSubmit={() => {}}
            render={() => {
              let gracePeriodDisplayed = gracePeriod
              return (
                <DropdownContainer>
                  <Field
                    name={'ExpeditedBookingToggle-grace_period'}
                    component={SelectFieldRFF}
                    meta={{ touched: displayError, error: 'Select hours' }}
                    input={{
                      name: 'ExpeditedBookingToggle-grace_period',
                      value: gracePeriodDisplayed,
                      onChange: (value: number) => {
                        gracePeriodDisplayed = value
                        onChangeGracePeriod(programName, value)
                      },
                    }}
                    options={hours.map((i) => {
                      return { label: `${i} hrs`, value: i }
                    })}
                  />
                </DropdownContainer>
              )
            }}
          />
        </MiddleColumn>
      )}
    </ExpeditedBookingContainer>
  )
}

type ExpeditedBookingToggleProps = {
  gracePeriod?: number
  onChangeGracePeriod: (program: string, buffer: number) => void
  onChangeToggle: (program: string, currentStatus: boolean) => void
  programName: string
  status?: boolean
  displayError?: boolean
}
