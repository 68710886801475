import React from 'react'
import { reduxForm } from 'redux-form/immutable'
import CSSModules from 'react-css-modules'
import styles from './editProviderRates.module.scss'
import providerRatesValidate from '../../../../common/components/forms/providerRates/providerRatesValidate'
import ProviderRatesForm from '../../../../common/components/forms/providerRates/ProviderRatesForm'

type Props = {
  handleSubmit?: (...args: any[]) => any
  submitting?: boolean
  _submitForm?: (...args: any[]) => any
}

class EditProviderRates extends React.Component<Props> {
  render() {
    const { handleSubmit, submitting, _submitForm } = this.props

    return (
      <div styleName='content-container'>
        <h2 styleName='title'>Edit Provider Rates</h2>

        <ProviderRatesForm
          // @ts-expect-error TS(2322): Type '((...args: any[]) => any) | undefined' is no... Remove this comment to see the full error message
          handleSubmit={handleSubmit}
          // @ts-expect-error TS(2322): Type '((...args: any[]) => any) | undefined' is no... Remove this comment to see the full error message
          submitFunction={_submitForm}
          // @ts-expect-error TS(2322): Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
          submitting={submitting}
          primaryButtonText='Update'
        />
      </div>
    )
  }
}

export default reduxForm({
  form: 'editProviderRatesForm',
  validate: providerRatesValidate,
  touchOnBlur: false,
  // @ts-expect-error TS(2345): Argument of type 'typeof EditProviderRates' is not... Remove this comment to see the full error message
})(CSSModules(EditProviderRates, styles))
