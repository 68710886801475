import React, { Component } from 'react'
import CSSModules from 'react-css-modules'
import { connect } from 'react-redux'

import { isEmpty } from 'lodash-es'
import { bindActionCreators } from 'redux'

import {
  BaseModal,
  BootstrapContainer,
  ContentLayout,
  dateUtils,
  DefaultButton,
  LoadingIndicator,
} from '@lyrahealth-inc/ui-core'

import * as paymentHistoryActions from './data/paymentHistoryActions'
import {
  getPaymentHistoryHistoryData,
  getPaymentHistoryModalData,
  getPaymentHistoryShowModal,
} from './data/paymentHistorySelectors'
import styles from './paymentHistory.module.scss'
import PaymentHistoryCard from './paymentHistoryCard/PaymentHistoryCard'
import DownloadCSVButton from '../common/components/downloadCSV/DownloadCSVButton'
import PaymentDetailsModal from '../common/components/paymentDetailsModal/PaymentDetailsModal'
import TabFilters from '../common/components/tabFilters/TabFilters'
import { ROLES } from '../common/constants/appConstants'
import { GET_PAYMENT_HISTORY_OF_TYPE } from '../common/constants/reduxConstants'
import {
  PAYMENT_HISTORY_ALL,
  PAYMENT_HISTORY_APPROVED,
  PAYMENT_HISTORY_CANCELLED,
  PAYMENT_HISTORY_REJECTED,
  PAYMENT_HISTORY_REVERSED,
  PAYMENT_HISTORY_SUBMITTED,
  REQUEST_PAYMENT,
} from '../common/constants/routingConstants'
import { axiosAuthInstance } from '../common/http/axiosInstance'
import { hasErrorForAction, hasRole } from '../common/utils/utils'
import { getAlertsState } from '../data/alertsSelectors'
import { getAuthPracticeId, getAuthRoles, getAuthUserAccessType, getAuthUserId } from '../data/auth/authSelectors'
import * as paymentsDataActions from '../payments/data/paymentsDataActions'
import withRouter from '../routing/withRouter'

type PaymentHistoryProps = {
  showModal?: boolean
  actions?: any
  modalData?: any // TODO: PropTypes.instanceOf(Map)
  historyData?: any // TODO: PropTypes.instanceOf(Map)
  router?: any
  roles?: any // TODO: PropTypes.instanceOf(List)
  providerID?: string
  practiceID?: string
  alerts?: any // TODO: PropTypes.instanceOf(List)
  providerAccessType: any
}

type PaymentHistoryState = any

class PaymentHistory extends Component<PaymentHistoryProps, PaymentHistoryState> {
  constructor(props: PaymentHistoryProps) {
    super(props)

    this.state = {
      loadingMore: false,
      noMoreForType: {
        all: false,
        submitted: false,
        approved: false,
        rejected: false,
        cancelled: false,
        reversed: false,
      },
    }
  }

  componentDidMount() {
    const type = this._getActivePaymentType()

    if (!this.props.historyData || !(type in this.props.historyData)) {
      this._getHistoryOfStatusForRole(type, '0')
    }
  }

  componentWillUnmount() {
    this.props.actions.clearPaymentHistoryStore()
    if (typeof (axiosAuthInstance as any).cancelLastRequest === 'function') {
      ;(axiosAuthInstance as any).cancelLastRequest()
    }
  }

  _onCopyClick = (chargeID: any) => {
    this.props.router.navigate(REQUEST_PAYMENT.route, { state: { copy_charge_id: chargeID } })
  }

  _onViewClick = (data: any) => {
    this.props.actions.showTransfersModal(data)
  }

  _closeModal = () => {
    this.props.actions.hideTransfersModal()
  }

  _loadMore = () => {
    const type = this._getActivePaymentType()
    const currentPage = this.props.historyData?.[type]?.page
    const nextPage = parseInt(currentPage, 10) + 1

    this.setState({
      loadingMore: true,
    })
    this._getHistoryOfStatusForRole(type, nextPage).then(
      (successObj: any) => {
        const noMores = this.state.noMoreForType
        noMores[type] = successObj.data.length === 0

        this.setState({
          loadingMore: false,
          noMoreForType: noMores,
        })
      },
      () => {
        this.setState({
          loadingMore: false,
        })
      },
    )
  }

  _getHistoryOfStatusForRole = (type: any, page: any) => {
    // status, page, limit, providerID, practiceID

    const status = type === 'all' ? undefined : type

    if (hasRole(this.props.roles, ROLES.PRACTICES_ADMIN)) {
      return this.props.actions.getPaymentsOfStatus({ status, page, practiceID: this.props.practiceID })
    } else if (hasRole(this.props.roles, ROLES.INDIVIDUAL_PROVIDER)) {
      return this.props.actions.getPaymentsOfStatus({ status, page, providerID: this.props.providerID })
    }
  }

  _getActivePaymentType = () => {
    return this.props.router.params.type ?? 'all'
  }

  _renderHistoryList = (type: any) => {
    const { historyData, roles, alerts } = this.props
    if (!historyData || !(type in historyData)) {
      if (hasErrorForAction(alerts, GET_PAYMENT_HISTORY_OF_TYPE)) {
        return null // custom workflow or display could render here
      } else {
        this._getHistoryOfStatusForRole(type, '0')
        return (
          <div className={styles['loading-container']}>
            <LoadingIndicator size={45} />
          </div>
        )
      }
    }

    const historyList = historyData[type].data
    if (historyList.length === 0) {
      return <div className={styles['loading-container']}>No payments found.</div>
    }

    return (
      <div>
        {historyList.map((value: any, i: any) => {
          const key = `$$historyData_${i}`
          return (
            <PaymentHistoryCard
              isPracticeAdmin={hasRole(roles, ROLES.PRACTICES_ADMIN)}
              data={value}
              key={key}
              copyClick={this._onCopyClick}
              viewClick={this._onViewClick}
            />
          )
        })}
      </div>
    )
  }

  _handleTabClick = (tab: any) => {
    switch (tab.toLowerCase()) {
      case 'all':
        this.props.router.navigate(PAYMENT_HISTORY_ALL.route, { replace: true })
        break
      case 'submitted':
        this.props.router.navigate(PAYMENT_HISTORY_SUBMITTED.route, { replace: true })
        break
      case 'approved':
        this.props.router.navigate(PAYMENT_HISTORY_APPROVED.route, { replace: true })
        break
      case 'rejected':
        this.props.router.navigate(PAYMENT_HISTORY_REJECTED.route, { replace: true })
        break
      case 'cancelled':
        this.props.router.navigate(PAYMENT_HISTORY_CANCELLED.route, { replace: true })
        break
      case 'reversed':
        this.props.router.navigate(PAYMENT_HISTORY_REVERSED.route, { replace: true })
        break
    }
  }

  _renderTabs = () => {
    let tab = this._getActivePaymentType()

    const tabWordArray: any = []
    tab.split('_').map((word: any) => {
      tabWordArray.push(word.charAt(0).toUpperCase() + word.slice(1))
    })
    tab = tabWordArray.join(' ')

    return (
      <TabFilters
        activeTab={tab}
        tabList={['ALL', 'SUBMITTED', 'APPROVED', 'REJECTED', 'CANCELLED', 'REVERSED']}
        className={styles.tabs}
        // @ts-expect-error TS(2322): Type '(tab: any) => void' is not assignable to typ... Remove this comment to see the full error message
        handleClick={this._handleTabClick}
      />
    )
  }

  _onCSVDownloadClick = ($$values: any) => {
    const startDate = dateUtils.getDisplayDate($$values.get('start_date'), 'YYYY-MM-DD')
    const endDate = dateUtils.getDisplayDate($$values.get('end_date'), 'YYYY-MM-DD')
    if (hasRole(this.props.roles, ROLES.PRACTICES_ADMIN)) {
      return this.props.actions.downloadPayments(
        $$values.get('payment_status'),
        startDate,
        endDate,
        undefined,
        this.props.practiceID,
      )
    } else if (hasRole(this.props.roles, ROLES.INDIVIDUAL_PROVIDER)) {
      return this.props.actions.downloadPayments(
        $$values.get('payment_status'),
        startDate,
        endDate,
        this.props.providerID,
      )
    }
  }

  render() {
    const { showModal, modalData, historyData, providerAccessType } = this.props
    if (!historyData) {
      return (
        <ContentLayout>
          <BootstrapContainer>
            <div styleName='loading-container'>
              <LoadingIndicator size={45} />
            </div>
          </BootstrapContainer>
        </ContentLayout>
      )
    }

    const type = this._getActivePaymentType()

    const ModalData = (
      <PaymentDetailsModal viewData={modalData} copyClick={this._onCopyClick} closeModal={this._closeModal} />
    )
    const hasPayments = !!historyData && !!historyData?.[type]?.data && !isEmpty(historyData[type].data)
    return (
      <div styleName='page-container'>
        <div styleName='top-container'>
          <h2>Payment History</h2>
          {/* @ts-expect-error TS(2322): Type '($$values: any) => any' is not assignable to... Remove this comment to see the full error message */}
          {hasPayments ? <DownloadCSVButton downloadClick={this._onCSVDownloadClick} /> : ''}
        </div>
        {providerAccessType !== 'T0' ? this._renderTabs() : []}
        {this._renderHistoryList(type)}
        {this.state.noMoreForType[type] ? (
          <div styleName='end-of-list-spacer' />
        ) : (
          <div styleName='load-more'>
            {this.state.loadingMore ? (
              <LoadingIndicator size={30} />
            ) : hasPayments ? (
              <DefaultButton onClick={this._loadMore}>Load More</DefaultButton>
            ) : (
              []
            )}
          </div>
        )}
        {/* @ts-expect-error TS(2322): Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message */}
        <BaseModal isOpen={showModal} body={ModalData} closeModal={this.props.actions.hideTransfersModal} />
      </div>
    )
  }
}

const mapStateToProps = ($$state: any) => {
  return {
    showModal: getPaymentHistoryShowModal($$state),
    modalData: getPaymentHistoryModalData($$state),
    historyData: getPaymentHistoryHistoryData($$state),
    roles: getAuthRoles($$state),
    providerID: getAuthUserId($$state),
    practiceID: getAuthPracticeId($$state),
    alerts: getAlertsState($$state),
    providerAccessType: getAuthUserAccessType($$state),
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    actions: bindActionCreators({ ...paymentHistoryActions, ...paymentsDataActions }, dispatch),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CSSModules(PaymentHistory, styles)))
