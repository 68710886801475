import { Map } from 'immutable'
import _ from 'lodash-es'
import { createSelector } from 'reselect'

const getAuthState = (state: Map<string, any>) => state?.get('auth')

export const getAuthConfig = createSelector(getAuthState, (authState) => authState?.config)

export const getAuthUser = createSelector(getAuthState, (authState) => authState?.user)

export const getAuthPracticeId = createSelector(getAuthUser, (authUser) => authUser?.practice_id)

export const getAuthPracticeName = createSelector(getAuthUser, (authUser) => authUser?.practice_name)

export const getAuthUserId = createSelector(getAuthUser, (authUser) => authUser?.id)

export const getAuthEmploymentStatus = createSelector(getAuthUser, (authUser) => authUser?.employment_status)

export const getAuthUserOutcomesAgreed = createSelector(getAuthUser, (authUser) => authUser?.outcomes_agreed)

export const getAuthUserOutcomesEnabled = createSelector(getAuthUser, (authUser) => authUser?.outcomes_enabled)

export const getAuthUserIsRegistered = createSelector(getAuthUser, (authUser) => authUser?.is_registered)

export const getAuthUserEmail = createSelector(getAuthUser, (authUser) => authUser?.email)

export const getAuthUserZendeskJWT = createSelector(getAuthUser, (authUser) => authUser?.zendesk_jwt)

export const getAuthUserCapacityValue = createSelector(getAuthUser, (authUser) => authUser?.capacity_value)

export const getAuthUserFirstName = createSelector(getAuthUser, (authUser) => authUser?.first_name)

export const getAuthUserLastName = createSelector(getAuthUser, (authUser) => authUser?.last_name)

export const getAuthUserFullName = createSelector(
  [getAuthUserFirstName, getAuthUserLastName],
  (firstName, lastName) => `${firstName} ${lastName}`,
)

export const getAuthExpeditedBookingConfig = createSelector(
  getAuthUser,
  (authUser) => authUser?.expedited_booking_config,
)

export const getAuthRoles = createSelector(getAuthUser, (authUser) => authUser?.roles)

export const getLastAuthorizedRoute = createSelector(getAuthState, (authState) => authState?.lastAuthorizedRoute)

export const getAuthInPersonPreference = createSelector(getAuthUser, (authUser) => authUser?.in_person_preference)

export const getAuthProgramTaxonomies = createSelector(getAuthUser, (authUser) => authUser?.program_taxonomies)

export const getAuthUserAccessType = createSelector(getAuthUser, (authUser) => authUser?.access_type)

export const getAuthSupervisor = createSelector(getAuthState, (authState) => authState?.supervisor)

export const getAuthIsLTSupervisor = createSelector(getAuthSupervisor, (authSupervisor) => !_.isEmpty(authSupervisor))

export const getAuthSupervisorRoles = createSelector(getAuthSupervisor, (authSupervisor) => authSupervisor?.roles)

export const getAuthIsLoggedIn = createSelector(getAuthState, (authState) => authState?.isLoggedIn)

export const getAuthLTProviders = createSelector(getAuthState, (authState) => authState?.lyratherapy_providers)

export const getAuthHydrated = createSelector(getAuthState, (authState) => authState?.hydrated)
