import React from 'react'
import colors from '../../../styles/_0colors.scss'

type OwnProps = {
  fillColor?: string
  width?: string | number
}

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof AwaitingResultsIcon.defaultProps

// @ts-expect-error TS(7022): 'AwaitingResultsIcon' implicitly has type 'any' be... Remove this comment to see the full error message
const AwaitingResultsIcon = ({ width, fillColor }: Props) => {
  return (
    <svg width={width} viewBox='0 0 116 92' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <title>AwaitingResultsIcon</title>
      <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='Client_list_empty_state'
          transform='translate(-673.000000, -148.000000)'
          stroke={fillColor}
          strokeWidth='2.5'
        >
          <g id='Group' transform='translate(675.000000, 150.000000)'>
            <g id='Group-16' transform='translate(0.000000, 18.725664)' strokeLinejoin='round'>
              <g id='Group-15'>
                <path
                  d='M90.4320626,55.0714485 C90.4320626,57.2349697 88.6666714,59.0051234 86.5002338,59.0051234 L3.93182881,59.0051234 C1.76932296,59.0051234 0,57.2349697 0,55.0714485 L0,3.9336749 C0,1.7701537 1.76932296,0 3.93182881,0 L86.5002338,0 C88.6666714,0 90.4320626,1.7701537 90.4320626,3.9336749 L90.4320626,55.0714485 L90.4320626,55.0714485 Z'
                  id='Stroke-2235'
                />
                <polygon
                  id='Stroke-2236'
                  points='7.86365761 51.1377736 82.5684049 51.1377736 82.5684049 7.86734979 7.86365761 7.86734979'
                />
                <path d='M25.5568872,70 L64.8751753,70' id='Stroke-2237' strokeLinecap='round' />
                <path d='M38,59.0051234 L38,70.8061481' id='Stroke-2238' />
                <path d='M52,59.0051234 L52,70.8061481' id='Stroke-2239' />
              </g>
              <g id='Group-900' transform='translate(24.923796, 17.585841)'>
                <path
                  d='M21.9915849,10.9911504 C21.9915849,17.0626619 17.0698682,21.9823009 10.9957924,21.9823009 C4.92171669,21.9823009 0,17.0626619 0,10.9911504 C0,4.91963894 4.92171669,0 10.9957924,0 C17.0698682,0 21.9915849,4.91963894 21.9915849,10.9911504 L21.9915849,10.9911504 Z'
                  id='Stroke-6874'
                  strokeLinecap='round'
                />
                <polyline
                  id='Stroke-6875'
                  strokeLinecap='round'
                  points='17.2128135 2.19823009 10.9957924 11.263731 17.5970262 19.6924332'
                />
                <path d='M10.9957924,10 L0,10' id='Stroke-6876' strokeLinecap='round' />
                <path d='M26.3899018,4 L39.5848527,4' id='Stroke-6877' />
                <path d='M26.3899018,10 L39.5848527,10' id='Stroke-6878' />
                <path d='M26.3899018,17 L39.5848527,17' id='Stroke-6879' />
              </g>
            </g>
            <g>
              <animateTransform
                attributeType='xml'
                attributeName='transform'
                type='rotate'
                from='360 90.8985507 21.1681416'
                to='0 90.8985507 21.1681416'
                dur='8s'
                additive='sum'
                repeatCount='indefinite'
              />
              <ellipse id='Oval' fill='#F7F7F7' cx='90.8985507' cy='21.1681416' rx='21.1014493' ry='21.1681416' />
              <g id='Group-73' transform='translate(77.913043, 6.513274)' strokeLinecap='round' strokeLinejoin='round'>
                <path
                  d='M10.6058359,26.0501198 C4.990577,25.4211236 0.625932537,20.695588 0.625932537,14.9564629 C0.625932537,13.152596 1.05614391,11.4479791 1.82277057,9.94062132'
                  id='Stroke-4948'
                  strokeDasharray='0.8100000107288352,4.859999957084653'
                />
                <path
                  d='M10.777354,3.72187105 L13.2785829,3.72187105 C19.4953874,3.72187105 24.5341131,8.78857817 24.5341131,14.9557185 C24.5341131,20.703528 20.1544612,25.4389886 14.5229443,26.0530973'
                  id='Stroke-4949'
                />
                <polyline id='Stroke-4950' points='15.2520113 0.267146018 10.777354 3.72187105 14.5606459 7.93269679' />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

AwaitingResultsIcon.defaultProps = {
  fillColor: colors.x_primary_action,
  width: 112,
}

export default AwaitingResultsIcon
