import React, { FunctionComponent } from 'react'
import Svg, { G, Mask, Path } from 'react-native-svg'

type Props = {
  size?: number | string
}

export const PartialHospitalizationSpotIllustration: FunctionComponent<Props> = ({ size = 124 }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 512 512' fill='none'>
      <Path
        d='M257.8 2C222.459 2 188.17 8.06049 155.884 20.0095C79.0203 48.4907 24.9919 111.655 7.61986 193.295C-3.85359 246.524 -2.38652 295.757 12.1323 343.735C30.6679 405.007 76.1671 456.678 136.964 485.493C167.145 499.83 201.819 508.015 240.003 509.816C244.91 510.039 249.898 510.16 254.855 510.16C285.492 510.16 316.31 505.961 346.461 497.685C348.778 497.108 350.983 496.512 353.311 495.824C380.406 487.891 404.789 475.345 425.652 458.58C448.305 440.48 466.952 417.533 481.016 390.418C483.788 385.075 486.439 379.45 489.363 372.722C505.45 335.367 513.22 293.551 511.844 251.745C511.197 233.26 508.445 213.611 503.69 193.295C495.858 160.332 483.09 129.331 466.76 103.592C462.288 96.6812 458.18 90.8737 454.113 85.6529C424.762 47.7926 379.03 21.2236 321.905 8.85978C300.911 4.30683 279.35 2 257.8 2Z'
        fill='white'
      />
      <Mask
        id='mask0_18566_6905'
        mask-type='luminance'
        maskUnits='userSpaceOnUse'
        x='21'
        y='23'
        width='469'
        height='466'
      >
        <Path
          d='M489.892 252.443C489.265 234.727 486.553 216.374 482.284 198.132C475.303 168.74 463.829 139.763 448.096 114.954C444.444 109.329 440.7 103.906 436.633 98.6952C407.13 60.6527 362.42 39.4866 316.657 29.5814C265.685 18.5329 211.484 21.7604 162.484 39.9014C91.5998 66.1669 43.3385 123.706 27.5954 197.717C21.545 225.753 19.7744 257.442 22.486 287.886H22.2735C23.8417 305.187 26.9681 322.073 31.6526 337.604C49.0651 395.143 91.3772 440.804 145.274 466.341C174.464 480.202 207.083 487.082 240.239 488.651C274.113 490.219 308.513 485.939 340.312 477.187C342.397 476.671 344.38 476.145 346.464 475.518C371.374 468.223 393.056 456.962 411.612 442.059C431.837 425.901 448.511 405.574 461.441 380.665C464.254 375.242 466.753 369.829 469.151 364.294C484.368 328.953 491.147 290.597 489.791 252.555L489.892 252.454V252.443Z'
          fill='white'
        />
      </Mask>
      <G mask='url(#mask0_18566_6905)'>
        <Path
          d='M255.586 510.17C397.12 510.17 511.857 396.413 511.857 256.085C511.857 115.758 397.12 2 255.586 2C114.052 2 -0.68457 115.758 -0.68457 256.085C-0.68457 396.413 114.052 510.17 255.586 510.17Z'
          fill='#FFE9E3'
        />
        <Path
          d='M265.125 539.4C472.188 539.4 640.045 460.604 640.045 363.403C640.045 266.203 472.188 187.406 265.125 187.406C58.0618 187.406 -109.796 266.203 -109.796 363.403C-109.796 460.604 58.0618 539.4 265.125 539.4Z'
          fill='#F5F1EC'
        />
      </G>
      <Path
        d='M93.9757 263.977L69.3695 192.303C68.2971 189.177 64.9279 187.487 61.7813 188.479C58.5639 189.491 56.7831 192.941 57.8253 196.148L83.342 275.127C83.6355 276.027 83.7063 276.978 83.5646 277.919L69.7135 368.553C69.1469 372.236 72.0001 375.555 75.7234 375.555H76.9477C79.8616 375.555 82.3606 373.491 82.9171 370.638L97.3044 297.102C97.8811 294.168 100.491 292.084 103.476 292.185L148.814 291.619C151.889 291.72 154.399 294.097 154.672 297.163L164 370C164.283 373.137 166.914 375.545 170.061 375.545H171.133C174.715 375.545 177.527 372.469 177.194 368.897C177.194 368.897 170.314 277.211 169.221 272.486C168.705 270.23 165.68 267.619 163.373 267.468L99.2976 268.065C96.8491 267.903 94.7447 266.284 93.9555 263.967L93.9757 263.977Z'
        fill='#3BA5B5'
      />
      <Path
        d='M212.27 177.259L210.682 134.664C210.348 125.558 215.801 117.575 223.45 115.997C231.16 114.408 241.571 112.86 252.953 112.86C264.336 112.86 274.747 114.408 282.456 115.997C290.105 117.575 295.559 125.558 295.225 134.664L293.636 177.259C295.235 207.096 296.237 193.751 278.187 194.611H227.72C210.671 202.432 212.199 195.269 212.27 177.259Z'
        fill='#3BA5B5'
      />
      <Path
        d='M204.043 196.968L200.815 281.481C200.815 284.152 203.426 286.317 206.653 286.317H206.775C210.002 286.317 212.613 284.152 212.613 281.481L221.203 215.868C221.203 213.197 223.813 211.032 227.04 211.032H278.995C282.222 211.032 284.833 213.197 284.833 215.868L293.423 281.481C293.423 284.152 296.033 286.317 299.26 286.317C302.488 286.317 305.098 284.152 305.098 281.481L301.77 197.757C301.82 195.167 297.115 192.79 293.898 192.79H212.026C208.798 192.79 204.286 192.739 204.053 196.968H204.043Z'
        fill='#3BA5B5'
      />
      <Path
        d='M301.854 196.25C300.822 194.014 298.474 192.931 296.157 192.557C293.072 192.051 289.854 192.253 286.728 192.223C279.494 192.142 272.27 192.071 265.035 191.99C250.405 191.838 235.785 191.676 221.155 191.525C217.766 191.484 214.265 191.191 210.896 191.666C208.154 192.051 205.452 193.133 204.117 195.734C203.227 197.474 205.857 198.981 206.758 197.241C207.769 195.258 210.056 194.742 212.11 194.57C215.034 194.327 218.008 194.53 220.932 194.56C227.742 194.631 234.551 194.702 241.36 194.772C254.806 194.914 268.263 195.056 281.709 195.197C285.514 195.238 289.328 195.228 293.132 195.319C295.328 195.369 298.201 195.541 299.243 197.808C300.063 199.578 302.663 198.01 301.854 196.25Z'
        fill='#46C6CC'
      />
      <Path
        d='M431.967 226.177L411.357 316.872L427.839 387.807C428.588 391.459 425.633 394.899 421.617 395.051L420.291 395.102C417.145 395.223 414.362 393.27 413.654 390.447L395.26 317.57C394.532 314.666 391.618 312.693 388.4 312.916L339.421 314.261C336.102 314.494 333.481 316.973 333.309 320.039L326.065 393.22C325.883 396.366 323.131 398.886 319.732 399.017L318.568 399.058C314.703 399.209 311.556 396.245 311.769 392.673C311.769 392.673 315.644 300.764 316.636 295.999C316.727 295.564 316.919 295.109 317.182 294.653C318.022 293.227 319.63 291.841 321.229 291.153C321.745 290.94 322.261 290.788 322.757 290.738L370.36 289.301L371.615 289.261L373.386 289.21L391.972 288.643C394.602 288.38 396.818 286.681 397.587 284.333L419.35 222.818C420.079 220.582 422.072 218.224 424.399 217.354C425.36 216.97 426.382 216.858 427.394 217.111V218.67C430.115 219.358 432.007 220.147 432.23 223.04C432.291 223.89 432.21 224.922 431.967 226.177Z'
        fill='#3BA5B5'
      />
      <Path
        d='M426.065 215.766C425.468 215.735 424.821 215.796 424.153 215.927C415.148 216.707 405.263 217.445 396.785 220.177C387.992 223.01 385.038 229.677 382.549 237.175C376.428 255.609 375.325 273.538 370.823 292.68C369.528 298.194 368.84 300.764 373.342 299.024C375.467 299.054 377.591 298.164 378.897 295.938C379.028 295.715 379.15 295.483 379.271 295.26C381.386 299.499 388.984 300.451 391.179 295.776C394.761 296.98 398.919 295.513 400.012 291.689C403.442 279.659 407.621 267.791 412.76 256.246C417.374 245.896 422.524 235.515 429.829 226.45C434.341 223.293 433.34 216.059 426.045 215.745L426.065 215.766Z'
        fill='#3BA5B5'
      />
      <Path
        d='M357.642 324.278L356.509 410.673C356.104 419.131 369.237 419.769 369.642 411.31L376.846 324.683C377.25 316.224 358.047 315.82 357.632 324.288L357.642 324.278Z'
        fill='#3BA5B5'
      />
      <Path
        d='M402.089 310.437C389.978 310.467 377.867 310.497 365.757 310.528C363.258 310.528 360.121 311.054 357.683 310.548C355.143 310.022 353.302 307.755 351.42 306.005C347.201 302.11 343.174 298.012 339.299 293.773C333.572 287.51 324.294 296.828 330.001 303.071C334.281 307.755 325.124 311.6 329.829 315.87C335.09 320.645 356.519 325.421 363.177 327.809C376.471 332.574 389.189 326.918 403.313 326.878C411.772 326.858 410.568 310.406 402.089 310.427V310.437Z'
        fill='#3BA5B5'
      />
      <Path
        d='M433.084 220.491C432.82 220.116 432.507 219.772 432.153 219.479C430.969 218.488 429.279 217.921 427.397 217.617C426.436 217.466 425.424 217.385 424.403 217.354C420.861 217.243 417.168 217.739 414.973 218.063C408.285 219.034 401.698 220.794 395.426 223.304C393.038 224.265 390.599 225.357 388.596 226.996C386.38 228.808 385.166 231.165 384.326 233.866C382.505 239.795 381.069 245.876 379.703 251.926C378.317 258.048 377.113 264.199 376.081 270.391C375.099 276.29 374.482 282.229 373.571 288.138C373.511 288.492 373.46 288.846 373.389 289.21C372.813 292.508 371.882 295.928 369.949 298.68C369.433 299.409 368.857 300.087 368.189 300.704C364.344 304.285 359.063 304.579 354.125 303.759C348.257 302.788 342.439 300.795 336.783 298.984C333.859 298.043 330.946 297.051 328.052 295.999C326.919 295.574 321.708 292.073 317.186 294.653C318.025 293.227 319.634 291.841 321.233 291.153C322.255 291.102 323.185 291.163 323.863 291.304C325.725 291.709 327.536 292.589 329.317 293.237C330.996 293.844 332.676 294.431 334.365 294.997C337.896 296.181 341.448 297.284 345.029 298.316C348.227 299.247 351.444 300.238 354.732 300.805C357.403 301.26 360.267 301.392 362.867 300.481C365.153 299.662 366.924 297.982 368.108 295.898C369.251 293.864 369.929 291.618 370.364 289.301C370.921 286.418 371.143 283.423 371.517 280.62C373.248 267.558 375.737 254.618 379.005 241.849C379.814 238.703 380.593 235.516 381.605 232.43C382.505 229.688 383.831 227.168 386.006 225.226C390.164 221.503 396.053 219.641 401.294 218.032C407.324 216.181 413.627 214.916 419.931 214.471C424.595 214.147 432.871 214.805 435.704 218.953C436.817 220.572 434.186 222.089 433.084 220.491Z'
        fill='#46C6CC'
      />
      <Path
        d='M435.989 215.504C435.564 214.917 434.795 214.563 434.107 214.866C433.864 214.482 433.601 214.097 433.328 213.733C432.488 212.63 431.477 211.892 430.03 212.145C429.989 212.145 429.959 212.165 429.918 212.185C429.898 212.185 429.888 212.175 429.878 212.175C427.986 211.679 427.177 214.603 429.069 215.099C431.871 215.838 432.074 220.077 432.023 222.374C431.942 225.743 431.214 229.062 431.426 232.441C431.547 234.383 434.583 234.394 434.461 232.441C434.299 229.881 434.684 227.352 434.906 224.812C434.937 224.812 434.967 224.812 434.998 224.812C435.595 224.731 436.06 224.347 436.374 223.851C437.092 222.718 437.426 221.241 437.406 219.915C437.385 218.357 436.92 216.769 435.989 215.514V215.504Z'
        fill='#F5F1EC'
      />
    </Svg>
  )
}
