import { zonedTimeToUtc } from 'date-fns-tz'

import { type Appointment } from '../../models'

/**
 * @return a Date object given a local appointment startTime, startDate, and timeZone
 *
 * Ex: Given `2023-08-02`, `09:00:00`, and `America/Los_Angeles`,
 * returns a Date representing `2023-08-02T16:00:00.000Z` in UTC
 *
 * Uses [`zonedTimeToUtc`](https://github.com/marnusw/date-fns-tz#zonedtimetoutc)
 *
 * An invalid date string or time zone will result in an `Invalid Date`.
 */
export function getDateFromAppointment({
  startDate,
  startTime,
  timeZone,
}: Pick<Appointment, 'startDate' | 'startTime' | 'timeZone'>): Date {
  return zonedTimeToUtc(`${startDate} ${startTime}`, timeZone)
}
