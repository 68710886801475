// DEPRECATING in favor of libs/shared-app-logic/src/features/common/constants/constants.ts

export const KEYS = {
  ENTER: 'Enter',
  SHIFT: 'Shift',
  CONTROL: 'Control',
  ARROW_RIGHT: 'ArrowRight',
  ARROW_LEFT: 'ArrowLeft',
  ARROW_UP: 'ArrowUp',
  ARROW_DOWN: 'ArrowDown',
  ESC: 'Escape',
  META: 'Meta', // CMD key for mac,
}
