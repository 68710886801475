import React from 'react'

import styled, { useTheme } from 'styled-components/native'

import { InfoIcon } from '../../atoms/icons'
import { Subhead } from '../../atoms/subhead/Subhead'
import { Tooltip } from '../../atoms/tooltip/Tooltip'
import { SubheadSize } from '../../styles/typeStyles'
import { ThemeType } from '../../utils/themes/ThemeProvider'

export type DataBoardProps = {
  children: React.ReactNode[] | React.ReactNode
  backgroundColor: string
  centered?: boolean
  width?: string
  label?: string
  tooltipContent?: string
}

export const DataBoard: React.FC<DataBoardProps> = ({
  children,
  backgroundColor,
  centered = false,
  width = '100%',
  label,
  tooltipContent,
}) => {
  const { colors } = useTheme()

  const InfoCard = styled.View<{ theme: ThemeType; backgroundColor: string; width: string }>(
    ({ theme, backgroundColor }) => ({
      backgroundColor: backgroundColor,
      borderRadius: theme.spacing['16px'],
      padding: `0 ${theme.spacing['24px']}`,
      width: width,
    }),
  )

  const Info = styled.View<{ centered: boolean }>(({ centered }) => ({
    margin: centered ? 'auto' : '0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  }))

  const DataBoardHeaderContainer = styled.View({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  })

  const DataBoardHeader = styled(Subhead)<{ theme: ThemeType }>(({ theme }) => ({
    paddingTop: theme.spacing['24px'],
  }))

  const StyledTooltip = styled(Tooltip)<{ theme: ThemeType }>(({ theme }) => ({
    paddingTop: theme.spacing['24px'],
    marginRight: theme.spacing['4px'],
  }))

  return (
    <InfoCard backgroundColor={backgroundColor} width={width}>
      <DataBoardHeaderContainer>
        {label && <DataBoardHeader text={label} size={SubheadSize.MEDIUM} color={colors.textSecondary} />}
        {tooltipContent && (
          <StyledTooltip content={tooltipContent} hoverEnabled>
            <InfoIcon size={18} />
          </StyledTooltip>
        )}
      </DataBoardHeaderContainer>
      <Info centered={centered}>{children}</Info>
    </InfoCard>
  )
}

export default DataBoard
