import { Map } from 'immutable'
import { createSelector } from 'reselect'

import providersReducer from './providersReducer'

export const getProviders = (state: Map<string, any>) => state?.get('providers') as ReturnType<typeof providersReducer>

export const getProvidersDetails = createSelector(getProviders, (providers) => providers?.providerDetails)

export const getProviderPaymentHistoryDownload = createSelector(
  getProvidersDetails,
  (providerDetails) => providerDetails?.paymentHistoryDownload,
)

export const getProviderDetailsData = createSelector(getProvidersDetails, (providersDetails) => providersDetails?.data)

export const getProviderDetailsAddresses = createSelector(getProviderDetailsData, (data) => data?.addresses)

export const getProviderDetailsRates = createSelector(getProviderDetailsData, (data) => data?.rates)

export const getProviderDetailsCalendarState = createSelector(getProviderDetailsData, (data) => data?.calendar_state)

export const getProviderModalities = createSelector(getProviderDetailsData, (data) => data?.modalities)

export const getProvidersRegister = createSelector(getProviders, (providers) => providers?.register)

export const getProvidersRegisterAccountType = createSelector(getProvidersRegister, (register) => register?.accountType)

export const getProvidersRegisterShowModal = createSelector(getProvidersRegister, (register) => register?.showModal)

export const getProvidersData = createSelector(getProviders, (providers) => providers?.data)

export const getProviderFullName = createSelector(
  getProviderDetailsData,
  (getProvidersData) => getProvidersData?.full_name,
)

export const getProvidersList = createSelector(getProvidersData, (providersData) => providersData?.providersList)

export const getProvidersIsDownloadingCSV = createSelector(
  getProvidersData,
  (providersData) => providersData?.isDownloadingCSV,
)

export const getProvidersDashboard = createSelector(getProviders, (providers) => providers?.providersDashboard)

export const getProvidersDashboardTasks = createSelector(getProvidersDashboard, (dashboard) => dashboard?.providerTasks)
