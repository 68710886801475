import React, { FunctionComponent } from 'react'
import { Path, Svg } from 'react-native-svg'

import { PrimaryNeedsWords } from '@lyrahealth-inc/shared-app-logic'

import { tID } from '../../utils'

type RelationshipFamilyIllustrationProps = {
  size?: string | number
  selected?: string
}

export const RelationshipFamilyIllustration: FunctionComponent<RelationshipFamilyIllustrationProps> = ({
  selected = false,
  size,
}) => (
  <Svg
    width={size}
    height={size}
    viewBox='0 0 48 48'
    fill='none'
    testID={tID(`${PrimaryNeedsWords.RELATIONSHIPS_AND_FAMILY}${selected ? '-Selected-' : '-'}Illustration`)}
  >
    <Path
      d='M21.3989 12.208C21.3989 12.2204 21.3948 12.2349 21.3907 12.2473L19.2134 20.5927C19.1762 20.7311 19.2217 20.8798 19.327 20.9769L25.2927 26.4324C25.3981 26.5253 25.4415 26.6699 25.4105 26.8083L21.5456 43.4454C21.4877 43.6953 21.2047 43.8296 20.9837 43.6995C20.9837 43.6995 20.9837 43.6995 20.9796 43.6995C15.6129 40.4729 -3.71988 20.9604 3.6567 10.2993C10.0975 0.993392 18.3417 8.77278 20.8102 11.4974C20.8494 11.5408 20.8908 11.5801 20.9341 11.6152L21.3969 12.208H21.3989Z'
      fill={selected ? '#5CB6C3' : '#F69170'}
    />
    <Path
      d='M44.3509 21.7796C38.8995 34.7831 27.0797 41.5317 24.4418 42.9075C24.4418 42.9075 24.4376 42.9075 24.4356 42.9116C24.1732 43.0459 23.8737 42.7939 23.9419 42.5067L27.7841 25.9647C27.813 25.8387 27.7696 25.7085 27.6767 25.6218L21.6841 20.1415C21.587 20.0506 21.5457 19.9122 21.5829 19.7841L23.8737 11.6784L24.5905 10.8831C24.6442 10.8397 24.6959 10.7943 24.7434 10.7509C26.4124 9.24499 30.2732 5.9874 33.4358 5.03305C37.8068 3.71101 50.5005 7.11733 44.3488 21.7817L44.3509 21.7796Z'
      fill={selected ? '#85CCD5' : '#FFAF9B'}
    />
  </Svg>
)
