import React, { useEffect, useMemo, useState } from 'react'

import styled from 'styled-components'

import { useZoom } from '../../hooks/useZoom'
import { useZoomStream } from '../../hooks/useZoom/useZoomStream'

export type ZoomScreenshareViewProps = {}

const Video = styled.video<{ show: boolean }>(({ show }) => ({
  maxWidth: '100%',
  maxHeight: '100%',
  display: show ? undefined : 'none',
}))
const Canvas = styled.canvas<{ show: boolean }>(({ show }) => ({
  maxWidth: '100%',
  maxHeight: '100%',
  display: show ? undefined : 'none',
}))

export const ZoomScreenshareView: React.FC<ZoomScreenshareViewProps> = ({}) => {
  const stream = useZoomStream()
  const {
    setScreenshareElement,
    remoteParticipantScreenSharing,
    setParticipantScreenshareElement,
    config: { canScreenShare },
  } = useZoom()
  const [videoEl, setVideoEl] = useState<HTMLVideoElement | null>(null)
  const [selfCanvasEl, setSelfCanvasEl] = useState<HTMLCanvasElement | null>(null)
  const [participantCanvasEl, setParticipantCanvasEl] = useState<HTMLCanvasElement | null>(null)

  const usingVideoEl = useMemo(() => stream?.isStartShareScreenWithVideoElement() ?? false, [stream])
  useEffect(() => {
    setScreenshareElement(usingVideoEl ? videoEl : selfCanvasEl)
    return () => setScreenshareElement(null)
  }, [usingVideoEl, videoEl, selfCanvasEl, setScreenshareElement])

  useEffect(() => {
    setParticipantScreenshareElement(participantCanvasEl)
    return () => setParticipantScreenshareElement(null)
  }, [setParticipantScreenshareElement, participantCanvasEl])

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      {canScreenShare && (
        <>
          <Video show={false} ref={setVideoEl} width='1920' height='1080' />
          <Canvas show={false} ref={setSelfCanvasEl} width='1920' height='1080' />
        </>
      )}
      <Canvas show={remoteParticipantScreenSharing} ref={setParticipantCanvasEl} width='1920' height='1080' />
    </>
  )
}
