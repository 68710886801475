import React from 'react'
import { connect, ConnectedProps } from 'react-redux'

import { Map } from 'immutable'

import { BodyTextSize, Toast, ToastContent, toJS } from '@lyrahealth-inc/ui-core-crossplatform'

import { getToastContent } from '../../../data/lyraTherapy/clientSelectors'
import { clearToastContent } from '../../../lyraTherapy/data/ltToastAutoActions'

type ToastContanerProps = ConnectedProps<typeof connector>

const ToastContainer = ({ toast, clearToastContent }: ToastContanerProps) => {
  if (!toast) {
    return null
  }
  const { text, id, toastType } = toast
  if (!text || !id) {
    return null
  }
  return (
    <Toast
      text={text}
      toastType={toastType}
      id={id}
      textSize={BodyTextSize.SMALL}
      onAnimationEnd={clearToastContent}
      delay={500}
    />
  )
}

type StateProps = {
  toast?: ToastContent
}

const mapStateToProps = (state: Map<string, any>): StateProps => {
  return {
    toast: getToastContent(state),
  }
}

const mapDispatchToProps = {
  clearToastContent,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(toJS(ToastContainer))
