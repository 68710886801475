import React from 'react'
import { Circle, Path, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

export const SessionCompletedCheck: React.FC<SessionCompletedCheckProps> = ({
  backgroundColor,
  fillColor,
  size = 24,
}) => {
  const { colors } = useTheme()
  return (
    <Svg width={size} height={size} viewBox='0 0 40 41' fill='none'>
      <Circle cx='20' cy='20.0781' r='20' fill={backgroundColor || colors.backgroundIcon} />
      <Path
        d='M17.1665 25.6252C16.8358 25.6252 16.5052 25.4995 16.2537 25.2463L12.3785 21.3711C11.8738 20.8665 11.8738 20.0501 12.3785 19.5454C12.8831 19.0408 13.6995 19.0408 14.2041 19.5454L17.1665 22.5061L25.2959 14.3785C25.8005 13.8738 26.6169 13.8738 27.1215 14.3785C27.6262 14.8831 27.6262 15.6995 27.1215 16.2041L18.0794 25.2463C17.8279 25.4995 17.4972 25.6252 17.1665 25.6252Z'
        fill={fillColor || colors.iconDefault}
      />
    </Svg>
  )
}

type SessionCompletedCheckProps = {
  backgroundColor?: string
  fillColor?: string
  size?: string | number
}
