import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

/**
 * Represents live messaging
 */
export const ClockIcon: FunctionComponent<ClockIconProps> = ({ size = 24, fillColor }) => {
  const { colors } = useTheme()

  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' fill='none'>
      <Path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M12 3.5C7.32843 3.5 3.5 7.32843 3.5 12C3.5 16.6716 7.32843 20.5 12 20.5C16.6716 20.5 20.5 16.6716 20.5 12C20.5 7.32843 16.6716 3.5 12 3.5ZM2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12Z'
        fill={fillColor || colors.iconInactive}
      />
      <Path
        d='M15.5502 15.8L11.4702 13.29C11.1702 13.11 10.9902 12.79 10.9902 12.44V7.75C11.0002 7.34 11.3402 7 11.7502 7C12.1602 7 12.5002 7.34 12.5002 7.75V12.2L16.3402 14.51C16.7002 14.73 16.8202 15.2 16.6002 15.56C16.3802 15.91 15.9102 16.02 15.5502 15.8Z'
        fill={fillColor || colors.iconInactive}
      />
    </Svg>
  )
}

type ClockIconProps = {
  size?: string | number
  fillColor?: string
}
