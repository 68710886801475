import React from 'react'
import classNames from 'classnames'

import styles from './defaultButton.module.scss'
import LoadingIndicator from '../../loadingIndicator/LoadingIndicator'

export type DefaultButtonProps = MergeElementProps<
  'button',
  {
    'data-test-id'?: string
    isLoading?: boolean
    customClass?: string
    isSmall?: boolean
    isOutlined?: boolean
    loaderWithin?: boolean
    isPrimary?: boolean
    elliptic?: boolean
    unstyled?: boolean
    ariaLabel?: string
  }
>

const DefaultButton: React.FC<DefaultButtonProps> = ({
  children,
  onClick = () => {},
  onFocus = () => {},
  onBlur = () => {},
  isOutlined = false,
  isLoading = false,
  isSmall,
  loaderWithin,
  disabled = false,
  customClass,
  isPrimary = false,
  elliptic = false,
  unstyled = false,
  ariaLabel,
  ...props
}) => {
  if (isLoading) disabled = true

  let classes

  if (unstyled) {
    classes = classNames(customClass, styles.unstyled)
  } else {
    classes = classNames(
      customClass,
      isPrimary ? styles['primary-button'] : styles['default-button'],
      isSmall ? styles['small-size'] : styles['default-size'],
      { [styles.loading]: isLoading },
      { [styles['loader-within']]: loaderWithin },
      { [styles.outlined]: isOutlined },
      { [styles.elliptic]: elliptic },
    )
  }

  return (
    <button
      type='button'
      disabled={disabled}
      className={classes}
      onFocus={onFocus}
      onBlur={onBlur}
      onClick={onClick}
      aria-label={ariaLabel}
      {...props}
    >
      {children}
      {isLoading ? (
        <div className={styles['loading-container']}>
          <LoadingIndicator size={isSmall ? 20 : 25} />
        </div>
      ) : (
        ''
      )}
    </button>
  )
}

export default DefaultButton
