import { submitProviderOutcomesConsentData } from '../../../common/http/data/clients'

import {
  SUBMIT_PROVIDER_OUTCOMES_CONSENT,
  actionAlertHandler,
  actionStyles,
} from '../../../common/constants/reduxConstants'
import { track } from '../../../../../mixpanel/mixpanelTracking'

export const submitProviderOutcomesConsent = (data: any) => {
  const submitConsentRequest = submitProviderOutcomesConsentData(data)

  return (dispatch: any) => {
    return new Promise(function (resolve, reject) {
      submitConsentRequest.then(
        (consentReturn) => {
          track({ event: 'OUTCOMES_AGREED' })
          dispatch({
            type: SUBMIT_PROVIDER_OUTCOMES_CONSENT,
            payload: consentReturn,
          })
          resolve(true)
        },
        (error) => {
          track({ event: 'OUTCOMES_AGREED_FAILED' })
          actionAlertHandler({
            actionStyle: actionStyles.ERROR,
            message: 'There was an problem with our server. Please try again.',
            dispatch,
          })
          reject(error)
        },
      )
    })
  }
}
