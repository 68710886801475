import React, { FunctionComponent, useRef } from 'react'
import { GestureResponderEvent, Pressable, PressableStateCallbackType } from 'react-native'

import styled, { useTheme } from 'styled-components/native'
import { useFocusVisible } from 'use-focus-visible'

import { IS_WEB } from '../../constants'
import { ThemeType } from '../../utils'
import { ArrowIcon, ArrowIconDirection } from '../icons/ArrowIcon'

export type BackButtonSmallProps = {
  disabled?: boolean
  onPress: (event: GestureResponderEvent) => void
  testID?: string
  accessibilityLabelledBy?: string
  accessibilityLabel?: string
  color?: string
}

const StyledView = styled.View<{ theme: ThemeType; focused?: boolean }>(({ theme, focused }) => ({
  backgroundColor: focused ? theme.colors.secondaryButtonBackgroundFocus : theme.colors.backgroundTransparent,
  borderRadius: '50px',
}))

const BackArrowIcon = ({
  disabled,
  focused,
  hovered,
  color,
}: {
  disabled?: boolean
  focused?: boolean
  hovered?: boolean
  color?: string
}) => {
  const { colors } = useTheme()
  let fillColor
  if (color) {
    fillColor = color
  } else {
    fillColor = colors.iconDefault

    if (disabled) {
      fillColor = colors.iconInactive
    } else if (hovered) {
      fillColor = colors.iconActive
    } else if (focused) {
      fillColor = colors.iconActive
    } else {
      fillColor = colors.iconDefault
    }
  }

  return <ArrowIcon direction={ArrowIconDirection.LEFT} fillColor={fillColor} />
}

export const BackButtonSmall: FunctionComponent<BackButtonSmallProps> = ({
  disabled,
  onPress,
  testID,
  accessibilityLabelledBy,
  accessibilityLabel,
  color,
}) => {
  const { focusVisible, onBlur, onFocus } = useFocusVisible()
  const pressableRef = useRef<any>(null)
  const accessibilityAttr = accessibilityLabelledBy ? { accessibilityLabelledBy } : { accessibilityLabel }
  const { colors } = useTheme()

  return (
    <Pressable
      ref={pressableRef}
      accessible
      focusable
      accessibilityRole='button'
      onFocus={onFocus}
      onBlur={onBlur}
      onPress={onPress}
      testID={testID}
      style={{
        borderRadius: 50,
        boxShadow: focusVisible ? `0 0 0 2px ${colors.iconActive}` : 'none',
        ...(IS_WEB ? { width: 'fit-content' } : { alignSelf: 'flex-start' }),
      }}
      {...accessibilityAttr}
      hitSlop={24}
    >
      {({ hovered }: PressableStateCallbackType) => (
        <StyledView focused={focusVisible}>
          <BackArrowIcon disabled={disabled} focused={focusVisible} hovered={hovered} color={color} />
        </StyledView>
      )}
    </Pressable>
  )
}
