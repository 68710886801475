import React from 'react'
import colors from '../../../styles/_0colors.scss'
import { Icon } from './types'

const NotificationIcon: React.FC<NotificationIconProps> = ({
  width = 12,
  fillColor = colors.x_light_gray_10,
  isFilled = false,
  ...props
}) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} viewBox='0 0 24 24' {...props}>
      {isFilled ? (
        <path
          fill={fillColor}
          d='M12 22c1.1 0 2-.9 2-2h-4c0 1.1.89 2 2 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2z'
        />
      ) : (
        <path
          fill={fillColor}
          d='M12,22c1.1,0,2-0.9,2-2h-4C10,21.1,10.9,22,12,22z M18,16v-5c0-3.07-1.63-5.64-4.5-6.32V4c0-0.83-0.67-1.5-1.5-1.5 S10.5,3.17,10.5,4v0.68C7.64,5.36,6,7.92,6,11v5l-2,2v1h16v-1L18,16z M16,17H8v-6c0-2.48,1.51-4.5,4-4.5s4,2.02,4,4.5V17z'
        />
      )}
    </svg>
  )
}

type NotificationIconProps = Icon

export default NotificationIcon
