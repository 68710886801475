import React, { FunctionComponent } from 'react'

import { SubheadSize, TextType } from '../../styles/typeStyles'
import { BaseText, BaseTextProps } from '../baseText/BaseText'

export interface SubheadProps extends Omit<BaseTextProps, 'size' | 'type'> {
  size: SubheadSize
}

// Map subhead sizes to header levels for web accessibility (h1, h2, etc)
const defaultLevels = {
  [SubheadSize.LARGE]: '2',
  [SubheadSize.MEDIUM]: '3',
  [SubheadSize.SMALL]: '4',
  [SubheadSize.XSMALL]: '4',
}

export const Subhead: FunctionComponent<SubheadProps> = (props) => {
  const { size, level } = props
  return <BaseText {...props} type={TextType.SUBHEAD} level={level || defaultLevels[size]} />
}
export const Size = SubheadSize
