import React, { useCallback, useEffect, useState } from 'react'
import { connect, ConnectedProps, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import { isWithinInterval } from 'date-fns'

import {
  appointmentUtilization,
  BCX_EVENTS,
  COMMON_MIXPANEL_EVENTS,
  historicUtilization,
  targetUtilization,
  useFlags,
  utilizationQuarters,
} from '@lyrahealth-inc/shared-app-logic'
import {
  AlertPopUp,
  CaseloadManagement,
  DropdownButton,
  LoadingIndicator,
  toJS,
  useFetcher,
} from '@lyrahealth-inc/ui-core-crossplatform'

import { actions as mixpanelActions, mixpanelEvents } from '../../../../mixpanel/mixpanelConstants'
import { track } from '../../../../mixpanel/mixpanelTracking'
import AvailabilityCard from '../../common/components/availabilityCard/AvailabilityCard'
import { employmentTypes, ROLES } from '../../common/constants/appConstants'
import { BC_CASELOAD_DETAILED_WEEKLY_VIEW } from '../../common/constants/routingConstants'
import {
  getDropdownItemsFromQuarters,
  getPreviousQuartersSorted,
  getYearMonthDayLocaleByDate,
  hasRole,
} from '../../common/utils/utils'
import { getAuthConfig, getAuthEmploymentStatus, getAuthRoles, getAuthUserId } from '../../data/auth/authSelectors'
import {
  getProviderAppointmentUtilization,
  getProviderHistoricUtilization,
  getProviderTargetUtilization,
  getProviderUtilizationQuarters,
  getRequestedCaseloadManagementQuarter,
} from '../../data/lyraTherapy/clientSelectors'
import {
  CalendarAvailabilitiesAlertPopUpValues,
  useCalendarAvailabilitiesAlertPopUp,
} from '../../hooks/useCalendarAvailabilitiesAlertPopUp'
import {
  getAppointmentUtilization,
  getHistoricUtilization,
  getTargetUtilization,
  getUtilizationQuarters,
  setCurrentQuarterCMD,
  setCurrentQuarterCMDDetailedWeeklyView,
} from '../clients/clientDetails/data/appointmentsAutoActions'
import { Config } from '../types'

type Props = ConnectedProps<typeof connector>

const CaseloadManagementContainer: React.FC<Props> = ({
  getAppointmentUtilization,
  getHistoricUtilization,
  getTargetUtilization,
  getUtilizationQuarters,
  setCurrentQuarterCMD,
  setCurrentQuarterCMDDetailedWeeklyView,
}) => {
  const appointmentUtilization: appointmentUtilization | undefined = useSelector(getProviderAppointmentUtilization)
  const historicUtilization: historicUtilization | undefined = useSelector(getProviderHistoricUtilization)
  const targetUtilization: targetUtilization | undefined = useSelector(getProviderTargetUtilization)
  const requestedQuarter: utilizationQuarters | undefined = useSelector(getRequestedCaseloadManagementQuarter)
  const utilizationQuarters: utilizationQuarters | undefined = useSelector(getProviderUtilizationQuarters)
  const userRoles: string[] = useSelector(getAuthRoles)
  const employmentStatus: string = useSelector(getAuthEmploymentStatus)
  const { showMinifiedUtilization }: Config = useSelector(getAuthConfig)
  const userId: string = useSelector(getAuthUserId)
  const { showPastQuartersInLCCDashboard, showCalendarAvailabilitiesWithCapacity } = useFlags()
  const navigate = useNavigate()

  const [currentQuarter, setCurrentQuarter] = useState<string | null>(null)
  const [selectedQuarter, setSelectedQuarter] = useState<string | null>(null)
  const [isLoadingWeeklySnapshot, setIsLoadingSnapshot] = useState(false)

  useEffect(() => {
    if (!showPastQuartersInLCCDashboard || !utilizationQuarters) {
      return
    }
    const sortedQuarters = getPreviousQuartersSorted({
      availableQuarters: utilizationQuarters,
      howManyQuartersToFetch: 2,
    })
    const quarterName = requestedQuarter ? Object.keys(requestedQuarter)[0] : sortedQuarters[0]
    setCurrentQuarter(sortedQuarters[0])
    setSelectedQuarter(quarterName)
  }, [showPastQuartersInLCCDashboard, utilizationQuarters, requestedQuarter])

  const onPressDetailedWeeklyView = useCallback(() => {
    track({
      event: mixpanelEvents.BUTTON_PRESS,
      action: mixpanelActions.VIEW_QUARTERLY_DETAILS,
    })
    navigate(BC_CASELOAD_DETAILED_WEEKLY_VIEW.route)
  }, [navigate])
  const newCaseloadDashboardVersion =
    hasRole(userRoles, [ROLES.LT_COACH, ROLES.LT_SS_COACH]) &&
    [employmentTypes.FULL_TIME, employmentTypes.PART_TIME].includes(employmentStatus)

  const requestedQuarterName = requestedQuarter ? Object.keys(requestedQuarter)[0] : ''
  const todayDate = new Date()

  const requestedDate = requestedQuarterName.length && new Date(`${requestedQuarter?.[requestedQuarterName][1]}T00:00`) // need time or else UTC time is assumed
  const shouldRequestCurrentQuarter =
    !requestedDate ||
    isWithinInterval(todayDate, {
      start: new Date(`${requestedQuarter?.[requestedQuarterName][0]}T00:00`),
      end: new Date(`${requestedQuarter?.[requestedQuarterName][1]}T00:00`),
    })
  const targetDate = shouldRequestCurrentQuarter ? todayDate : requestedDate
  const [loadingUtilization, , fetchedUtilization] = useFetcher(
    [
      [getHistoricUtilization, { id: userId, date: getYearMonthDayLocaleByDate(targetDate) }, showMinifiedUtilization],
      [getAppointmentUtilization, { id: userId, date: getYearMonthDayLocaleByDate(targetDate) }],
      [
        getTargetUtilization,
        {
          id: userId,
          date: getYearMonthDayLocaleByDate(targetDate),
          includeFinalWeek: !shouldRequestCurrentQuarter,
        },
        !showMinifiedUtilization || newCaseloadDashboardVersion,
      ],
      [getUtilizationQuarters, { date: getYearMonthDayLocaleByDate(todayDate) }],
    ],
    [userId, newCaseloadDashboardVersion],
  )

  const onViewHelpArticle = () => {
    track({
      event: COMMON_MIXPANEL_EVENTS.BUTTON_PRESS,
      action: BCX_EVENTS.VIEW_CASELOAD_MANAGEMENT_ARTICLE,
    })
    window.open('https://coaching-lyrahealth.zendesk.com/hc/en-us/articles/7265611191827-Caseload-Dashboard', '_blank')
  }

  let preselectedValue = ''
  if (requestedQuarter) {
    const requestedQuarterName = Object.keys(requestedQuarter)[0]
    const dropdownAppend = requestedQuarterName === currentQuarter ? ' Current' : ''
    preselectedValue = `${requestedQuarterName.replace('_', ' ')}${dropdownAppend}`
  }

  const dropdownClickHandler = (quarter: string, isCurrent: boolean) => {
    const quarterEndDateBeforeLocale = new Date(`${utilizationQuarters?.[quarter][1]}T00:00`)
    setIsLoadingSnapshot(true)
    Promise.all([
      getHistoricUtilization({
        id: userId,
        date: getYearMonthDayLocaleByDate(isCurrent ? new Date() : quarterEndDateBeforeLocale),
      }),
      getAppointmentUtilization({
        id: userId,
        date: getYearMonthDayLocaleByDate(isCurrent ? new Date() : quarterEndDateBeforeLocale),
      }),
      getTargetUtilization({
        id: userId,
        date: getYearMonthDayLocaleByDate(isCurrent ? new Date() : quarterEndDateBeforeLocale),
        includeFinalWeek: isCurrent ? false : true,
      }),
      setCurrentQuarterCMD({
        quarter: {
          [quarter]: utilizationQuarters?.[quarter],
        },
      }),
      setCurrentQuarterCMDDetailedWeeklyView(currentQuarter),
      setSelectedQuarter(quarter),
    ]).then(() => {
      setIsLoadingSnapshot(false)
    })
  }
  const dropdownWithHandlers = (
    <DropdownButton
      dropdownItems={getDropdownItemsFromQuarters(
        getPreviousQuartersSorted({ availableQuarters: utilizationQuarters, howManyQuartersToFetch: 2 }),
        dropdownClickHandler,
      )}
      dropdownWidth={210}
      preselectedValue={preselectedValue}
    />
  )
  const {
    alertText,
    handleCloseAlertPopUpModal,
    handleLearnMore,
    handleOpenCalendar,
    hasProperRoleToSeeAlert,
    isAlertPopUpOpen,
  }: CalendarAvailabilitiesAlertPopUpValues = useCalendarAvailabilitiesAlertPopUp()

  if (loadingUtilization || !fetchedUtilization) {
    return <LoadingIndicator size={45} />
  }

  if (!appointmentUtilization || !historicUtilization || !targetUtilization) {
    return null
  }

  return (
    <>
      {hasProperRoleToSeeAlert && showCalendarAvailabilitiesWithCapacity && isAlertPopUpOpen && (
        <AlertPopUp
          headerText='Low availability'
          text={alertText}
          secondaryHandler={handleOpenCalendar}
          tertiaryHandler={handleLearnMore}
          onClose={handleCloseAlertPopUpModal}
        />
      )}
      <CaseloadManagement
        viewHelpArticle={onViewHelpArticle}
        availabilityCard={<AvailabilityCard isMinified />}
        appointmentUtilization={appointmentUtilization}
        historicUtilization={historicUtilization}
        targetUtilization={targetUtilization}
        employmentStatus={employmentStatus}
        handleDetailedWeeklyView={onPressDetailedWeeklyView}
        dropdownWithHandlers={dropdownWithHandlers}
        isLoadingWeeklySnapshot={isLoadingWeeklySnapshot}
        requestedQuarterName={requestedQuarterName !== currentQuarter ? requestedQuarterName : ''}
        shouldShowPastQuartersInBCXDashboards={showPastQuartersInLCCDashboard}
        utilizationQuarters={utilizationQuarters}
        selectedQuarter={selectedQuarter}
        currentQuarter={currentQuarter}
      />
    </>
  )
}

const connector = connect(null, {
  getAppointmentUtilization,
  getHistoricUtilization,
  getTargetUtilization,
  getUtilizationQuarters,
  setCurrentQuarterCMD,
  setCurrentQuarterCMDDetailedWeeklyView,
})
export default connector(toJS(CaseloadManagementContainer))
