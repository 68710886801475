import React, { FunctionComponent, useCallback, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Platform } from 'react-native'

import styled, { useTheme } from 'styled-components/native'

import {
  CameraVideoControlButton,
  CloseVideoControlButton,
  EndVideoControlButton,
  MessengerVideoControlButton,
  MicVideoControlButton,
  PrimaryButton,
  SecondaryButton,
  SettingsVideoControlButton,
  Subhead,
} from '../../atoms'
import { ButtonSize } from '../../atoms/baseButton/BaseButton'
import { useZoom } from '../../hooks/useZoom'
import { RemoteParticipantStatus } from '../../hooks/useZoom/types'
import { SubheadSize } from '../../styles'
import { RowView } from '../../templates'
import { ThemeProvider, ThemeType, tID } from '../../utils'
import { EndSessionModal } from '../endSessionModal/EndSessionModal'
import { ZoomSettingsMenu } from '../zoomSettingsMenu/ZoomSettingsMenu'
import { ZoomSettingsModal } from '../zoomSettingsModal/ZoomSettingsModal'

export type ZoomVideoControlsProps = {
  displayVideos: boolean
  messenger?: React.ReactElement
  buttonSize?: number
  landscape?: boolean
  onOpenMessagesPressed?: () => void
  unreadMessageCount?: number
  isRecording?: boolean
  onDisableRecordingPressed?: () => void
}

const Row = styled(RowView)<{ landscape?: boolean }>(({ landscape, theme }) => ({
  gap: theme.spacing['8px'],
  flexBasis: theme.breakpoints.isMobileSized ? undefined : 0,
  flexGrow: 1,
  justifyContent:
    theme.breakpoints.isMobileSized || Platform.OS !== 'web' ? (landscape ? 'center' : 'space-around') : 'flex-end',
  alignItems: 'center',
}))

export const ZoomVideoControls: FunctionComponent<ZoomVideoControlsProps> = ({
  displayVideos,
  messenger,
  landscape,
  buttonSize = 48,
  onOpenMessagesPressed,
  unreadMessageCount,
  isRecording,
  onDisableRecordingPressed,
}) => {
  const {
    muted,
    toggleMute,
    videoOff,
    toggleVideo,
    isScreensharing,
    startScreenshare,
    startSession,
    leaveSession,
    sessionStarted,
    remoteParticipantStatus,
    stopScreenshare,
    settings,
    updateSettings,
    remoteParticipant,
    config: { canScreenShare, showMessenger, canStartSession, shouldEndSessionOnLeave },
  } = useZoom()
  const {
    colors,
    breakpoints: { isMobileSized },
  } = useTheme() as ThemeType
  const [showSettings, setShowSettings] = useState(false)
  const [showEndSessionModal, setShowEndSessionModal] = useState(false)
  const zoomVideoControlsColors = colors.components.zoomVideoControls

  const onEndSession = useCallback(() => {
    if (shouldEndSessionOnLeave) {
      setShowEndSessionModal(true)
      return
    }

    leaveSession({ end: false })
  }, [leaveSession, shouldEndSessionOnLeave])
  return (
    <Row landscape={landscape}>
      {isScreensharing ? (
        <>
          {displayVideos && (
            <Subhead
              size={SubheadSize.XSMALL}
              text={
                <FormattedMessage
                  defaultMessage='Your camera is still on while you present'
                  description='Text informing user that their camera is still on when they are screensharing'
                />
              }
            />
          )}
          <PrimaryButton
            testID={tID('ZoomVideoControls-stop-presenting')}
            text={
              <FormattedMessage
                defaultMessage='Stop presenting'
                description='Button that stops user from sharing their screen'
              />
            }
            onPress={stopScreenshare}
          />
        </>
      ) : (
        <>
          {sessionStarted && remoteParticipant && canScreenShare && !isScreensharing && (
            <SecondaryButton
              testID={tID('ZoomVideoControls-present')}
              inverse
              text={
                <FormattedMessage defaultMessage='Present' description='Button for provider to start sharing screen' />
              }
              onPress={startScreenshare}
            />
          )}
          {showMessenger ? (
            messenger ? (
              <ThemeProvider>
                {React.cloneElement(messenger, {
                  customIconStyles: {
                    height: `${buttonSize}px`,
                    width: `${buttonSize}px`,
                    'justify-content': 'center',
                    backgroundColor: zoomVideoControlsColors.enabled.background,
                    borderRadius: '50%',
                  },
                  iconBadgeStyles: {
                    top: '8px',
                    right: '8px',
                    border: 'none',
                    fontSize: '10px',
                    borderRadius: '50%',
                  },
                  customIconOpenStyles: {
                    backgroundColor: zoomVideoControlsColors.disabled.background,
                  },
                  iconFillColor: zoomVideoControlsColors.icon.text,
                  iconSize: 20,
                  iconFilled: true,
                  isSessionControl: true,
                  modalStyle: isMobileSized
                    ? {
                        position: 'fixed',
                      }
                    : undefined,
                })}
              </ThemeProvider>
            ) : (
              <MessengerVideoControlButton
                size={buttonSize}
                onPress={() => onOpenMessagesPressed?.()}
                disabled={false}
                enabledColor={zoomVideoControlsColors.enabled.background}
                iconColor={zoomVideoControlsColors.icon.text}
                unreadMessageCount={unreadMessageCount}
              />
            )
          ) : null}
          <MicVideoControlButton
            size={buttonSize}
            onPress={toggleMute}
            disabled={muted}
            enabledColor={zoomVideoControlsColors.enabled.background}
            disabledColor={zoomVideoControlsColors.disabled.background}
            iconColor={zoomVideoControlsColors.icon.text}
          />
          <CameraVideoControlButton
            size={buttonSize}
            onPress={toggleVideo}
            disabled={videoOff}
            enabledColor={zoomVideoControlsColors.enabled.background}
            disabledColor={zoomVideoControlsColors.disabled.background}
            iconColor={zoomVideoControlsColors.icon.text}
          />
          {!isMobileSized &&
            Platform.OS === 'web' &&
            (onDisableRecordingPressed ? (
              <ZoomSettingsMenu
                disabledRecording={!isRecording}
                onDisableRecordingPressed={onDisableRecordingPressed}
                onSettingsPressed={() => setShowSettings(true)}
              />
            ) : (
              <SettingsVideoControlButton
                size={buttonSize}
                onPress={() => setShowSettings(true)}
                disabled={false}
                enabledColor={zoomVideoControlsColors.enabled.background}
                iconColor={zoomVideoControlsColors.icon.text}
              />
            ))}
        </>
      )}
      {canStartSession && !sessionStarted && (
        <PrimaryButton
          testID={tID('ZoomVideoControls-start-session')}
          size={ButtonSize.LARGE}
          text={
            <FormattedMessage defaultMessage='Start Session' description='Button for provider to start video session' />
          }
          onPress={startSession}
          disabled={remoteParticipantStatus !== RemoteParticipantStatus.READY}
        />
      )}

      {!isScreensharing ? (
        sessionStarted ? (
          <EndVideoControlButton
            size={buttonSize}
            onPress={onEndSession}
            disabled={false}
            color={zoomVideoControlsColors.end.background}
            iconColor={zoomVideoControlsColors.icon.text}
          />
        ) : (
          <CloseVideoControlButton
            size={buttonSize}
            onPress={() => leaveSession({ end: false })}
            enabledColor={zoomVideoControlsColors.enabled.background}
            iconColor={zoomVideoControlsColors.icon.text}
            disabled={false}
          />
        )
      ) : null}
      <ThemeProvider>
        <ZoomSettingsModal
          show={showSettings}
          onClose={() => setShowSettings(false)}
          onSave={(settings) => {
            setShowSettings(false)
            updateSettings(settings)
          }}
          settings={settings}
        />
        <EndSessionModal
          show={showEndSessionModal}
          onClose={() => setShowEndSessionModal(false)}
          onConfirm={() => {
            setShowEndSessionModal(false)
            leaveSession({ end: true })
          }}
        />
      </ThemeProvider>
    </Row>
  )
}
