import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

import { colors } from '../../styles/colors'

/**
 * Icon representing a lesson
 */
export const LessonIcon: FunctionComponent<LessonIconProps> = ({ size = 24, fillColor = colors.navy6 }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' fill='none' accessibilityHidden>
      <Path
        d='M20.22 2.75H3.78C3.109 2.75 2.46549 3.01655 1.99102 3.49102C1.51655 3.96549 1.25 4.609 1.25 5.28V17.72C1.25 18.391 1.51655 19.0345 1.99102 19.509C2.46549 19.9834 3.109 20.25 3.78 20.25H11.44C11.6389 20.25 11.8297 20.171 11.9703 20.0303C12.111 19.8897 12.19 19.6989 12.19 19.5C12.19 19.3011 12.111 19.1103 11.9703 18.9697C11.8297 18.829 11.6389 18.75 11.44 18.75H3.78C3.51478 18.75 3.26043 18.6446 3.07289 18.4571C2.88536 18.2696 2.78 18.0152 2.78 17.75V5.28C2.78 5.01478 2.88536 4.76043 3.07289 4.57289C3.26043 4.38536 3.51478 4.28 3.78 4.28H20.22C20.4852 4.28 20.7396 4.38536 20.9271 4.57289C21.1146 4.76043 21.22 5.01478 21.22 5.28V10.5C21.22 10.6989 21.299 10.8897 21.4397 11.0303C21.5803 11.171 21.7711 11.25 21.97 11.25C22.1689 11.25 22.3597 11.171 22.5003 11.0303C22.641 10.8897 22.72 10.6989 22.72 10.5V5.28C22.72 4.61417 22.4576 3.97516 21.9896 3.50154C21.5216 3.02793 20.8858 2.7579 20.22 2.75Z'
        fill={fillColor}
      />
      <Path
        d='M14.6701 11.6C14.6709 11.4834 14.6441 11.3683 14.592 11.2641C14.5398 11.1598 14.4638 11.0693 14.3701 11L10.5401 8.09C10.4277 8.00741 10.2948 7.95732 10.1558 7.94524C10.0169 7.93316 9.8773 7.95954 9.75235 8.0215C9.62741 8.08345 9.52192 8.1786 9.44743 8.29651C9.37295 8.41442 9.33236 8.55056 9.33008 8.69V14.5C9.32956 14.64 9.36874 14.7772 9.44309 14.8958C9.51743 15.0144 9.62388 15.1095 9.75008 15.17C9.85264 15.2212 9.96546 15.2486 10.0801 15.25C10.2453 15.2493 10.4062 15.1969 10.5401 15.1L14.3701 12.19C14.463 12.1222 14.5387 12.0335 14.5908 11.931C14.6429 11.8284 14.6701 11.715 14.6701 11.6Z'
        fill={fillColor}
      />
      <Path
        d='M22 13.75H17C16.8011 13.75 16.6103 13.829 16.4697 13.9697C16.329 14.1103 16.25 14.3011 16.25 14.5C16.25 14.6989 16.329 14.8897 16.4697 15.0303C16.6103 15.171 16.8011 15.25 17 15.25H22C22.1989 15.25 22.3897 15.171 22.5303 15.0303C22.671 14.8897 22.75 14.6989 22.75 14.5C22.75 14.3011 22.671 14.1103 22.5303 13.9697C22.3897 13.829 22.1989 13.75 22 13.75Z'
        fill={fillColor}
      />
      <Path
        d='M22 16.75H15C14.8011 16.75 14.6103 16.829 14.4697 16.9697C14.329 17.1103 14.25 17.3011 14.25 17.5C14.25 17.6989 14.329 17.8897 14.4697 18.0303C14.6103 18.171 14.8011 18.25 15 18.25H22C22.1989 18.25 22.3897 18.171 22.5303 18.0303C22.671 17.8897 22.75 17.6989 22.75 17.5C22.75 17.3011 22.671 17.1103 22.5303 16.9697C22.3897 16.829 22.1989 16.75 22 16.75Z'
        fill={fillColor}
      />
      <Path
        d='M22 19.75H15C14.8011 19.75 14.6103 19.829 14.4697 19.9697C14.329 20.1103 14.25 20.3011 14.25 20.5C14.25 20.6989 14.329 20.8897 14.4697 21.0303C14.6103 21.171 14.8011 21.25 15 21.25H22C22.1989 21.25 22.3897 21.171 22.5303 21.0303C22.671 20.8897 22.75 20.6989 22.75 20.5C22.75 20.3011 22.671 20.1103 22.5303 19.9697C22.3897 19.829 22.1989 19.75 22 19.75Z'
        fill={fillColor}
      />
    </Svg>
  )
}

type LessonIconProps = {
  size?: string | number
  fillColor?: string
}
