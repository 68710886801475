import React from 'react'
import CSSModules from 'react-css-modules'
import styles from './legalNotice.module.scss'
import { LWUrl } from '../constants/appConstants'

const TermsOfUse = () => {
  return (
    <div styleName='content'>
      <h3 styleName='main-header'>Terms of Use</h3>
      <h4 styleName='date'>Last Updated: December 1st, 2017</h4>

      <div styleName='body'>
        <p>
          We want you to have a great experience with our products and services. To this end, we’ve provided simplified
          Terms of Use on this page. These explanations are written in a way that makes our Terms of Use quick and easy
          to understand. We hope you will find these simplified terms helpful.
        </p>
        <p>
          <span styleName='strong'>But remember:</span> you’re agreeing to all of the fine print when you use our
          products and services, so we encourage you to read the entire terms of use carefully.
        </p>
        <h4>I. Terms of Use</h4>
        <p>
          This agreement (“Agreement”) is an electronic contract that sets out the legally binding terms of your use of
          the Lyra Clinical Associates P.C. Provider Portal managed by Lyra Health, Inc. (the “Portal”) and includes
          without limitation, all content and all self-service functions (“Services”) made accessible to you through the
          Portal.
        </p>
        <ol>
          <li>
            <b>Acceptance of Terms</b>. By visiting the Portal site at{' '}
            <a href={LWUrl('provider')}>https://provider.lyrahealth.com</a> , submitting information or data for the
            Portal (including client outcomes, availability, credentialing, or your provider profile), or otherwise
            using any of the services and/or information created, collected, compiled, submitted to or available through
            the Portal, you agree to be bound by this Agreement. If you do not want to be bound by our Agreement your
            only option is not to visit, view or otherwise use the services of the Portal. You understand, agree and
            acknowledge that your use of the Portal shall indicate your conclusive acceptance of this agreement. If we
            need to change the terms of this Agreement, we will post the revised Terms of Use through our Services and
            update the “Last Revised” date to reflect the date of the changes. You are responsible for being aware of
            any changes made to this Agreement. By continuing to use our Services after we post such changes, you agree
            to modified terms.
          </li>
          <br />
          <li>
            <b>Authenticity of Use</b>. By using the Portal, you represent and warrant that you have the right,
            authority, and capacity to enter into this Agreement and abide by all its terms and conditions. You
            represent and warrant that you reside in a jurisdiction in which we offers our Services. You represent and
            warrant that you are at least 18 years of age. You agree and acknowledge that you are a contracted provider
            or have received authorization from a contracted provider to access their information and assume all
            liability for costs and damages associated with the unauthorized and unintended access and disclosure of any
            health information made available to you if you are not the contracted provider or have not received
            authorization from a contracted provider to access their information.
          </li>
          <br />
          <li>
            <b>Provision of Services</b>. You must accept this Agreement before receiving access to the Services and the
            Portal. You agree and acknowledge that the Portal is entitled to modify, improve or discontinue any of its
            Services at our sole discretion and without notice to you. Furthermore, you agree and acknowledge that the
            Portal is entitled to provide Services to you through subsidiaries or affiliated entities.
          </li>
          <br />
          <li>
            <b>Proprietary Rights</b>. You acknowledge and agree that the Portal may contain proprietary and
            confidential information including trademarks, service marks and patents protected by intellectual property
            laws and international intellectual property treaties. The materials displayed or performed or available on
            or through the Portal or Services, including but not limited to text, graphics, data, articles, photos,
            images, illustrations, user submissions, and so forth (all of the foregoing, the “Content”) are protected by
            copyright and/or intellectual property laws. We own all right, title and interest in and to our Services and
            Portal, including all intellectual property rights, and any suggestions, ideas or other feedback provided by
            you or any other party relating to our Services. We will solely and exclusively own any copy, modification,
            revision, enhancement, adaptation, translation, or derivative work of or created from its Services. We also
            own any and all patent rights, copyrights, trade secret rights, trademark rights, and all other proprietary
            rights. Except for that information which is in the public domain or limited copies for offline, personal,
            non-commercial use, you may not copy, modify, publish, transmit, distribute, or sell any of Lyra’s
            proprietary information without express written permission. Our content may not be sold, reproduced, or
            distributed without our written permission. Any third-party trademarks, service marks and logos are the
            property of their respective owners. Any further rights not specifically granted herein are reserved.
          </li>
          <br />
          <li>
            <b>Term and Termination of Agreement</b>. The terms of this Agreement will continue to apply in perpetuity
            and shall be unaffected by the termination of this agreement.
          </li>
          <br />
          <li>
            <b>Security</b>. We may disable access to the Portal and Services at any time by revoking your access online
            or by written request. You must immediately disable or request that we disable access to the Services by a
            user when you have security concerns, including lost or stolen IDs and passwords, disclosure of confidential
            information or fraudulent activity. You acknowledge that you will immediately disable access of any
            terminated employee.
          </li>
          <br />
          <li>
            <b>Laws</b>. You shall not use the Portal in a manner which may violate the laws and regulations of the
            United States or any other nation, or the laws and regulations of any state, city, province or other
            jurisdiction in any fashion and shall not unlawfully transmit any information using the Portal.
          </li>
          <br />
          <li>
            <b>Confidential Information</b>. We may make certain claims and other medical information (“Patient Health
            Information” or “PHI”) available to you through the Portal for the purposes of providing treatment to
            patients, healthcare operations, or payment activities. The PHI is provided to you as a secondary source and
            is provided on an “as is” basis. You agree to be bound by the requirements of HIPAA, HITECH, and any other
            regulation regarding the security and privacy of PHI, and maintain the strict confidentiality and security
            of all PHI in accordance with other federal and state laws.
          </li>
          <br />
          <li>
            <b>Use</b>. Use of Services requires that you comply with acceptable use behavior as determined by us. As
            part of your responsibilities, you agree that you will not:
            <ul>
              <li>Use the Services in an unlawful manner; </li>
              <li>
                Use the Services in any manner that is harmful, hateful, harassing, abusive, or otherwise offensive to
                any other person or entity (including your patient);{' '}
              </li>
              <li>
                Express or imply that we endorses any statements you make, unless you have our prior written consent;{' '}
              </li>
              <li>
                Post, distribute, or reproduce in any way any copyrighted material, trademarks, or other proprietary
                information without containing the prior consent of the owner of such proprietary rights;{' '}
              </li>
              <li>
                Remove any copyright, trademark, or other proprietary rights notices contained on the Portal or
                Services;{' '}
              </li>
              <li>
                Use any robot, spider, site search/retrieval application, or other manual or automatic device or process
                to retrieve, index, “data mine”, or in any way reproduce or circumvent the navigational structure or
                presentation of the Portal or its contents;{' '}
              </li>
              <li>
                Interfere with or disrupt the Services, Portal, or servers or networks connected to the Portal or
                Services;{' '}
              </li>
              <li>
                Post, email, or otherwise transmit any material that contains software viruses or any other computer
                code, files or programs designed to interrupt, destroy, or limit the functionality of any computer
                software or hardware or telecommunications equipment;
              </li>
              <li>
                Forge headers or otherwise manipulate identifiers in order to disguise the origin of any information
                transmitted through the Services;
              </li>
              <li>Frame or mirror any part of the Services or Portal without our prior written authorization;</li>
              <li>
                Use meta tags, code, or other devices containing any references to us or the Services or the Portal in
                order to direct any person to any other web site for any purpose;
              </li>
              <li>Interfere with or inhibit any other user from using or enjoying the Services;</li>
              <li>
                Use the Services as a means to distribute unsolicited or unauthorized communications, advertisements or
                spam;
              </li>
              <li>Collect or use any data or Services content that violates any third-party right;</li>
              <li>
                Create a false identity, impersonate another person or entity, or otherwise misrepresent yourself;
              </li>
              <li>Breach or otherwise circumvent any security measures incorporated into the Services;</li>
              <li>Otherwise use the Services in any manner we considers unacceptable.</li>
            </ul>
          </li>
          <br />
          <li>
            <b>Indemnity</b>. You agree to indemnify, defend and hold us and any other intermediary harmless against any
            losses, damages, claims or expenses and costs incurred as a result of your breach of this Agreement, your
            use of the Portal, the Services and access to PHI as a result of your access to the Portal; in addition to
            the unauthorized or unlawful use of the Portal or Services by any other person using your IDs and passwords.
          </li>
          <br />
          <li>
            <b>Business Associate Provisions</b>. You and we each agree that each party will only use and disclose PHI
            in a manner consistent with applicable laws and HIPAA. You hereby represent and warrant that clients have
            been given all notices and that you have obtained necessary consents required by HIPAA and applicable laws
            in connection with the use of the Portal. Both parties agree that in the event any regulation or governing
            law affecting this agreement changes or is updated that such changes are incorporated by reference.
          </li>
          <br />
          <li>
            <b>General Disclaimers</b>.
            <b>
              OUR SERVICES AND PORTAL ARE PROVIDED ON AN “AS IS” BASIS. ANY ACCESS TO OR USE OF OUR SERVICES AND PORTAL
              ARE VOLUNTARY AND AT YOUR SOLE RISK. WE DO NOT MAKE ANY EXPRESS OR IMPLIED WARRANTIES, REPRESENTATIONS OR
              ENDORSEMENTS OF ANY KIND (INCLUDING, WITHOUT LIMITATION, WARRANTIES OF TITLE OR NON-INFRINGEMENT, OR ANY
              WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE) REGARDING OUR SERVICES AND PORTAL, OR
              WITH RESPECT TO ANY INFORMATION, PRODUCT, SERVICE, MERCHANDISE OR OTHER MATERIAL PROVIDED ON OR THROUGH
              OUR SERVICES AND PORTAL (INCLUDING ANY CONSULTATIONS OR OTHER SERVICES YOU MAY RECEIVE). WE DO NOT WARRANT
              OR GUARANTEE THE ACCURACY, COMPLETENESS, RELIABILITY, TIMELINESS OR USEFULNESS OF OUR SERVICES. WE DO NOT
              WARRANT THAT THE SERVICES WILL FUNCTION WITHOUT DELAYS, DISRUPTIONS, INTERFERENCES, IMPERFECTIONS, OR ANY
              ADVERSE INCIDENT. WE WILL NOT BE LIABLE TO YOU, ANY OTHER USER OR PARTY FOR ANY DIRECT, INDIRECT, SPECIAL
              OR CONSEQUENTIAL DAMAGES ARISING OUT OF THE USE OF THE PORTAL OR SERVICES. THIS INCLUDES ANY LOST PROFITS,
              BUSINESS INTERRUPTION, LOSS OF PROGRAMS OR DATA OR OTHER DAMAGES OF ANY NATURE, EVEN IF WE HAVE BEEN
              ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN THE EVENT THE FOREGOING LIMITATION OF LIABILITY IS
              DETERMINED BY A COURT OF COMPETENT JURISDICTION TO BE UNENFORCEABLE, THEN THE MAXIMUM LIABILITY FOR ALL
              CLAIMS OF EVERY KIND WILL NOT EXCEED U.S. $100. THE FOREGOING LIMITATION OF LIABILITY WILL COVER, WITHOUT
              LIMITATION, ANY TECHNICAL MALFUNCTION, COMPUTER ERROR OR LOSS OF DATA, AND ANY OTHER INJURY ARISING FROM
              THE USE OF OUR PORTAL OR SERVICES. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR
              THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. TO THE EXTENT THAT WE
              MAY NOT DISCLAIM ANY IMPLIED WARRANTY OR LIMIT ITS LIABILITIES, THE SCOPE AND DURATION OF SUCH WARRANTY
              AND THE EXTENT OF OUR LIABILITY WILL BE THE MINIMUM PERMITTED UNDER APPLICABLE LAW.
            </b>
          </li>
          <br />
          <li>
            <b>License Grant</b>. This is a legal Agreement between you and the producers of the Portal. The Agreement
            govern your use of and access to the Portal. By using the Portal, you are agreeing to be bound by this
            Agreement. In consideration of your agreement to these terms and for other valuable consideration, you are
            granted a nonexclusive, non-transferable, limited, terminable license to access and use the Portal under the
            laws of the United States. The producer of this Portal, Lyra Health, Inc., reserves all rights not expressly
            granted in this Agreement.
          </li>
          <br />
          <li>
            <b>Restrictions</b>. This Portal is protected by United States copyright law, international treaty
            provisions, and trade secret, trade dress and other intellectual property laws. Unauthorized copying of or
            access to this Portal is expressly forbidden. You may not copy, disclose, loan, rent, sell, lease, give
            away, give your password to or otherwise allow access to this Portal by any other person. You agree to only
            use this Portal for your own activities. You agree not to misuse, abuse, or overuse beyond reasonable
            amounts, this Portal. You agree not to attempt to view, disclose, copy, reverse engineer, disassemble,
            decompile or otherwise examine the source program code behind this Portal. You may be held legally
            responsible for any copyright infringement or other unlawful act that is caused or incurred by your failure
            to abide by the terms of this Agreement.
          </li>
          <br />
          <li>
            <b>Term and Termination</b>. This license is effective until terminated by either you or the producers of
            this Portal. This license will automatically terminate without notice if you fail to comply with any
            provisions of this Agreement. The provisions of this Agreement which by their nature extend beyond the
            termination of this Agreement shall survive termination of this Agreement, including but not limited to the
            sections relating to Restrictions, Content of the Portal, Links to Third Party Websites, Disclaimer of
            Warranties, Limitation of Liability, and Governing Law.
          </li>
          <br />
          <li>
            <b>Content of the Portal</b>. We may make opinions, advice, statements, offers, and other information
            available through the Portal or its Services. Any such information made available through the Portal or
            Services, but not authored by us, belongs to its respective authors, and should not necessarily be relied
            upon. We does not (i) guarantee the accuracy, completeness, or usefulness of any information on our Portal
            or Services, or (ii) adopt, endorse, or accept responsibility for the accuracy or reliability of any
            opinion, advice, or statement made by any party that appears on the Portal or Services. Under no
            circumstances will we or our affiliates be responsible for any loss or damage resulting from your reliance
            on information or other content posted on the Website or transmitted to or by other members.
          </li>
          <br />
          <li>
            <b>Links to Third Party Websites</b>. The hypertext links in the Portal allow you to leave our Portal. The
            linked websites are not under our control, and therefore we are not responsible for the contents or terms
            and conditions of any linked website. We are providing these links to you only as a convenience, and the
            inclusion of any link does not imply any endorsement by the producers of the Portal.
          </li>
          <br />
          <li>
            <b>Content You Post or Share</b>. Subject to HIPAA and our Privacy Policy, anything you post, upload, share,
            store, or otherwise provide through the Portal or Services (“Submissions”) is subject to review by us. You
            hereby grant us a license to translate, edit, modify (for technical purposes, for example making sure your
            content is viewable on mobile devices as well as computers) and reproduce and otherwise act with respect to
            such Submissions, in each case to enable us to operate our Portal and Services. You will not provide
            inaccurate, misleading or false information to us, and if information provided subsequently becomes
            inaccurate, misleading or false, you will promptly notify us of the change.
            <br />
            By posting Submissions to our Portal or Services, you automatically grant, and you represent and warrant
            that you have the right to grant, to us, its affiliates, licensees and successors, an irrevocable,
            perpetual, non-exclusive, fully paid, worldwide license to use, copy, perform, display, reproduce, adapt,
            modify and distribute that information and content and to prepare derivative works of, or incorporate into
            other works, such information and content, and to grant and authorize sublicenses of the foregoing.
            <br />
            You have the right to (1) review any information we may obtain from outside sources to support and verify
            your Submissions, (2) correct any erroneous information within thirty (30) days of your review, and (3)
            request the status of your application, by emailing us at{' '}
            <a href='mailto:credentials@lyraclinical.com'>credentials@lyraclinical.com</a> We are not required to make
            available any references, recommendations, or peer-review protected information, but will share information
            from outside sources such as malpractice insurance carriers and state licensing boards. Any corrections
            (with supporting evidence) to your Submissions must be submitted in writing to{' '}
            <a href='mailto:credentials@lyraclinical.com'>credentials@lyraclinical.com</a>, and we will review your
            corrections and get back to you within thirty (30) days of your submitted correction.
          </li>
          <br />
          <li>
            <b>Non-Electronic Copy</b>. By accessing the Portal or registering for Services, you consent to have this
            Agreement provided to you in electronic form. You have the right to receive this Agreement in non-electronic
            form. You may request a non-electronic copy of this Agreement; to receive a non-electronic copy of this
            agreement, please send an email to{' '}
            <a href='mailto:legalnotice@lyrahealth.com'>legalnotice@lyrahealth.com</a>
          </li>
          <br />
          <li>
            <b>Withdrawal of Consent to Electronic Agreement and Effect of Withdrawal</b>. You have the right to
            withdraw your consent to have this Agreement provided to you in electronic form. Should you choose to
            withdraw your consent to have this agreement provided to you in electronic form, we will discontinue your
            access to the Portal. This means you will not have the right to use Services unless, and until, we issue you
            a new username and password. We will only issue you a new username and password after we receive a signed
            copy of a non-electronic version of this agreement. To withdraw your consent, please send an email to{' '}
            <a href='mailto:legalnotice@lyrahealth.com'>legalnotice@lyrahealth.com</a>. Your withdrawal of consent will
            be effective within a reasonable time after we receive your withdrawal notice. Your withdrawal of consent
            will not affect the legal validity or enforceability of this electronic Agreement prior to the effective
            date of your withdrawal.
          </li>
          <br />
          <li>
            <b>Jurisdiction and Choice of Law</b>. The validity, interpretation, construction and performance of these
            Terms of Use will be governed by the laws of the State of Delaware, without giving effect to the principles
            of conflict of laws. Any dispute arising under or relating to these Terms of Use will be resolved
            exclusively by final and binding arbitration in San Francisco, California, under the rules of the American
            Arbitration Association, except that either party may bring a claim related to intellectual property rights,
            or seek temporary and preliminary specific performance and injunctive relief, in any court of competent
            jurisdiction, without the posting of bond or other security. The parties agree to the personal and subject
            matter jurisdiction and venue of the courts located in San Francisco, California, for any action related to
            this Agreement. You may only resolve disputes with us on an individual basis and not as part of any class,
            consolidated or representative action. <br /> Our failure to enforce any provision of this Agreement will
            not constitute a waiver of such right. If any provision is found to be invalid, the parties agree that the
            court should endeavor to give effect to the parties’ intentions as reflected in the provision, and that the
            other provisions of this Agreement remain in full force and effect. You agree that regardless of any statute
            or law to the contrary, any claim or cause of action related to this Agreement must be filed within one year
            after such claim arose or be forever barred. You have no authority to act on behalf of or bind us in any
            way.
          </li>
          <br />
        </ol>
      </div>
    </div>
  )
}

export default CSSModules(TermsOfUse, styles)
