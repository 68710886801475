import { getErrorText } from '../../common/utils/utils'
import { updateChargeStatusData, downloadPaymentsData, updateCommentData } from '../../common/http/data/payments'
import {
  UPDATE_CHARGE_STATUS,
  UPDATE_CHARGE_COMMENT,
  PURGE_TOO_FREQUENT_REJECTIONS,
  HANDLE_CHARGE_UPDATE_ERROR,
  actionAlertHandler,
  actionStyles,
} from '../../common/constants/reduxConstants'
import { isUndefined } from 'lodash-es'
import { toggleDownloadCSVModal } from '../../common/components/downloadCSV/data/downloadActions'
import { bannerMessages } from '../../common/constants/appConstants'

export const updateChargeStatus = (id: any, values: any, isTooFrequent: any, description = '') => {
  const formData = new FormData()
  Object.keys(values).forEach((key) => {
    formData.append(key, values[key])
  })
  if (description) {
    formData.append('admin_note', description)
  }
  const request = updateChargeStatusData(id, formData)

  return (dispatch: any) => {
    return new Promise(function (resolve, reject) {
      request.then(
        (updateReturn) => {
          dispatch({
            type: UPDATE_CHARGE_STATUS,
            payload: updateReturn.data,
            wasExcluded: values.status === 'exclude' || values.status === 'include',
          })

          if (values.status === 'reject' && isTooFrequent) {
            dispatch({
              type: PURGE_TOO_FREQUENT_REJECTIONS,
              payload: id,
            })
          }

          resolve(updateReturn)
        },
        (error) => {
          const errorText = error.response.data.message || getErrorText(error.response.status)
          dispatch({
            type: HANDLE_CHARGE_UPDATE_ERROR,
            payload: {
              contents: errorText,
              paymentId: id,
            },
          })
          reject(error)
        },
      )
    })
  }
}

export const downloadPayments = (status: any, start: any, end: any, providerId: any, practiceId: any) => {
  const request = downloadPaymentsData(status, start, end, providerId, practiceId)

  return (dispatch: any) => {
    return new Promise(function (resolve, reject) {
      request.then(
        () => {
          dispatch(toggleDownloadCSVModal(false))
          actionAlertHandler({
            actionStyle: actionStyles.SUCCESS,
            message: bannerMessages.PAYMENT_HISTORY_EMAIL_SENT,
            dispatch,
            expires: true,
          })
          // @ts-expect-error TS(2794): Expected 1 arguments, but got 0. Did you forget to... Remove this comment to see the full error message
          resolve()
        },
        (error) => {
          let errorText
          if (isUndefined(error.response)) {
            errorText = getErrorText('500')
          } else if (error.response.data && error.response.data.description) {
            errorText = error.response.data.description
          } else {
            errorText = getErrorText(error.response.status)
          }

          actionAlertHandler({
            actionStyle: actionStyles.ERROR,
            message: errorText,
            dispatch,
            expires: true,
          })

          dispatch(toggleDownloadCSVModal(false))

          reject(error)
        },
      )
    })
  }
}

export const submitComment = (chargeId: any, commentText: any, chargeStatus: any) => {
  const request = updateCommentData(chargeId, commentText)

  return (dispatch: any) => {
    return new Promise(function (resolve, reject) {
      request.then(
        (commentReturn) => {
          dispatch({
            type: UPDATE_CHARGE_COMMENT,
            payload: commentReturn.data,
            chargeStatus: chargeStatus,
          })

          resolve(commentReturn)
        },
        (error) => {
          actionAlertHandler({
            actionStyle: actionStyles.ERROR,
            message: error,
            dispatch,
            expires: true,
            action: UPDATE_CHARGE_COMMENT,
          })
          reject(error)
        },
      )
    })
  }
}
