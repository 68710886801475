import React, { FunctionComponent } from 'react'
import { ImageSourcePropType } from 'react-native'

import styled from 'styled-components/native'

import gif from '../../assets/S4CLoadingAnimation.gif'
import { tID } from '../../utils'

// TODO: Expo 51, move to Image component in ui-cc, gif is broken in Expo 50 for certain sizes
// eslint-disable-next-line no-restricted-properties
const ImageContainer = styled.Image<{ size: string }>(({ size }) => ({
  width: size,
  height: size,
}))

/**
 * Illustration to show on Search For Care - Provider Preferences, when
 * loading location-based search results.
 */
export const S4CLoadingAnimation: FunctionComponent<S4CLoadingAnimationProps> = ({ size = 256 }) => {
  const sizeInPixels = `${size}px`
  return (
    <ImageContainer
      dataSet={{ chromatic: 'ignore' }}
      size={sizeInPixels}
      testID={`${tID('S4CLoadingAnimation')}`}
      source={gif as ImageSourcePropType}
    />
  )
}

type S4CLoadingAnimationProps = {
  size?: number
}
