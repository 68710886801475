import React from 'react'
import CSSModules from 'react-css-modules'
import { Map } from 'immutable'

import { isImmutable } from '@lyrahealth-inc/shared-app-logic'

import styles from './displayPracticeInfo.module.scss'

import { paymentPreferences } from '../../common/constants/appConstants'

const DisplayPracticeInfo = ({ $$data }: { $$data: Map<string, any> | Dict }) => {
  const data = isImmutable({ value: $$data }) ? ($$data as Map<string, any>).toJS() : $$data
  return (
    <div>
      <div styleName='section'>
        <h3>Practice Info</h3>

        <div className='row'>
          <div className='col-xs-12 col-sm-6'>
            <div styleName='field-label'>Practice Name</div>
            <p>{data.practice_name}</p>
          </div>
          <div className='col-xs-12 col-sm-6'>
            <div styleName='field-label'>Practice Admin First Name</div>
            <p>{data.first_name}</p>
          </div>
          <div className='col-xs-12 col-sm-6'>
            <div styleName='field-label'>Practice Admin Last Name</div>
            <p>{data.last_name}</p>
          </div>
        </div>
      </div>

      <div styleName='section'>
        <h3>Contact Info</h3>

        <div className='row'>
          <div className='col-xs-12 col-sm-6'>
            <div styleName='field-label'>Practice Email</div>
            <p>{data.email}</p>
          </div>
          <div className='col-xs-12 col-sm-6'>
            <div styleName='field-label'>Practice Phone Number</div>
            <p>{data.phone}</p>
          </div>
          <div className='col-xs-12 col-sm-6'>
            <div styleName='field-label'>Street Address</div>
            <p>{data.street}</p>
          </div>
          <div className='col-xs-12 col-sm-6'>
            <div styleName='field-label'>City</div>
            <p>{data.city}</p>
          </div>
          <div className='col-xs-12 col-sm-6'>
            <div styleName='field-label'>State</div>
            <p>{data.state}</p>
          </div>
          <div className='col-xs-12 col-sm-6'>
            <div styleName='field-label'>Zip Code</div>
            <p>{data.zip_code}</p>
          </div>
        </div>
      </div>

      <div styleName='section'>
        <h3>Bank Info</h3>

        <div className='row'>
          <div className='col-xs-12 col-sm-6'>
            <div styleName='field-label'>Payment Preference</div>
            <p>{paymentPreferences[data.payment_preference]}</p>
          </div>
          <div className='col-xs-12 col-sm-6'>
            <div styleName='field-label'>Bank Name</div>
            <p>{data.bank}</p>
          </div>
          <div className='col-xs-12 col-sm-6'>
            <div styleName='field-label'>TIN Or EIN Number</div>
            <p>{data.account_identifier}</p>
          </div>
          <div className='col-xs-12 col-sm-6'>
            <div styleName='field-label'>Routing Number</div>
            <p>{data.routing_number}</p>
          </div>
          <div className='col-xs-12 col-sm-6'>
            <div styleName='field-label'>Account Number</div>
            <p>{data.account_number}</p>
          </div>
          <div className='col-xs-12 col-sm-6'>
            <div styleName='field-label'>Name on account (for tax purposes)</div>
            <p>{data.account_name}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CSSModules(DisplayPracticeInfo, styles, { allowMultiple: true })
