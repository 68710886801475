import React from 'react'

type OwnProps = {
  width?: string | number
}

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof PhoneIcon.defaultProps

// @ts-expect-error TS(7022): 'PhoneIcon' implicitly has type 'any' because it d... Remove this comment to see the full error message
const PhoneIcon = ({ width }: Props) => {
  const colorBg = '#FDE5E8'
  const colorBorder = '#FFFFFF'
  const colorFill = '#F598A3'
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={width}
      viewBox={`0 0 ${width} ${width}`}
      role='img'
      aria-label='Phone'
    >
      <g fill='none' fillRule='evenodd'>
        <circle fill={colorBg} cx='18' cy='18' r='18' />
        <path
          fill={colorBorder}
          fillOpacity='.5'
          d='m15.7455014 17.7351407.791762-.7917663c.6624379-.6624415.9846795-1.5878582.8775939-2.5210374l-.2422054-2.1046939c-.1846863-1.5545319-1.4931123-2.7201431-3.0549823-2.7201431h-1.4445742c-1.7493195 0-3.17852737 1.4632446-3.06984475 3.2177022.48620605 7.8343822 6.74945945 14.0949128 14.58121375 14.5815309 1.7547171.1086999 3.2179541-1.3205161 3.2179541-3.0698376v-1.444582c.015392-1.5476556-1.1546551-2.8616442-2.7066538-3.0460411l-.0063755-.0007427-2.1209356-.2421554c-.9323813-.1069954-1.8577983.2152498-2.5202381.8776932l-.7826767.7826809c-1.4231975-.8937254-2.6292004-2.0986528-3.5200374-3.5186077z'
        />
        <path
          fill={colorFill}
          d='m24.5302939 21.2289768c.8433642.1002023 1.4779748.809968 1.4696246 1.6533367v1.444582c0 .943571-.7849131 1.7284884-1.7284789 1.6700371-7.1226693-.4425598-12.8258148-6.1373861-13.2683722-13.2684443-.058451-.9435709.7264622-1.7284883 1.670028-1.7284883h1.4445742c.8517142 0 1.5614761.6346141 1.6616778 1.4779828l.242154 2.1042466c.058451.5093613-.1169019 1.0103724-.4759579 1.3694304l-1.5447759 1.5447843c1.2024201 2.3714526 3.1396526 4.3003453 5.5027422 5.502772l1.5364257-1.5364341c.359056-.3590579.8600644-.5344118 1.3694229-.4759605z'
        />
      </g>
    </svg>
  )
}

PhoneIcon.defaultProps = {
  width: 36,
}

export default PhoneIcon
