import React from 'react'
import ErrorPage from './ErrorPage'
import * as Sentry from '@sentry/react'

type ErrorBoundaryWrapperProps = {
  children: React.ReactNode
}

const ErrorBoundaryWrapper: React.FC<ErrorBoundaryWrapperProps> = ({ children }) => {
  return <Sentry.ErrorBoundary fallback={<ErrorPage />}>{children}</Sentry.ErrorBoundary>
}

export default ErrorBoundaryWrapper
