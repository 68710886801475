import { axiosGenericInstance } from '../axiosInstance'
import { oktaBaseUrl } from '../../constants/appConstants'

export function authenticate(queryString: any) {
  return axiosGenericInstance.get(`v1/okta/callback${queryString}`, { withCredentials: true })
}

export function closeOktaSession() {
  return axiosGenericInstance.delete(`${oktaBaseUrl}/api/v1/sessions/me`, { withCredentials: true })
}
