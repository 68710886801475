import React, { FunctionComponent, useState } from 'react'

import { View } from 'react-native'
import styled, { useTheme } from 'styled-components/native'
import { BodyTextSize } from '../../styles'
import { tID } from '../../utils'
import { BodyText } from '../bodyText/BodyText'
import { CopyIcon } from '../icons'
import { Tooltip } from '../tooltip/Tooltip'

export interface CopyToClipboardProps {
  copyText: string
  fillColor?: string
  testID?: string
  size?: number
  displayText?: string
}

const DisplayTextContainer = styled(BodyText)(({ theme }) => ({
  color: theme.colors.iconActive,
  marginRight: '8px',
  marginTop: '-4px',
}))

const TextAndIconContainer = styled(View)({
  display: 'flex',
  flexDirection: 'row',
})

export const CopyToClipboard: FunctionComponent<CopyToClipboardProps> = ({
  copyText,
  fillColor,
  testID = 'CopyToClipboard',
  size = 18,
  displayText,
}) => {
  const [copied, setCopied] = useState(false)
  const { colors } = useTheme()

  const copyToClipboard = () => {
    setCopied(true)
    navigator.clipboard.writeText(copyText)
    setTimeout(() => {
      setCopied(false)
    }, 2000)
  }

  return (
    <Tooltip
      content={copied ? 'Copied' : 'Copy to Clipboard'}
      placement='right'
      hoverEnabled
      onPress={copyToClipboard}
      testID={tID(testID)}
    >
      {displayText ? (
        <TextAndIconContainer>
          <DisplayTextContainer size={BodyTextSize.DEFAULT}>{displayText}</DisplayTextContainer>
          <CopyIcon fillColor={fillColor || colors.iconActive} size={size} />
        </TextAndIconContainer>
      ) : (
        <CopyIcon fillColor={fillColor || colors.iconActive} size={size} />
      )}
    </Tooltip>
  )
}
