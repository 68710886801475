import React from 'react'
import Tooltip from '../tooltip/Tooltip'
import colors from '../../../styles/_0colors.scss'

const AlcoholUseDisorderTherapyIcon: React.FC<AlcoholUseDisorderTherapyIconProps> = ({
  width = 20,
  showTooltip = true,
  tooltip = 'Alcohol Use Disorder Therapy',
  ...props
}) => {
  const icon = (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={width}
      viewBox='0 0 44 44'
      fill='none'
      id='AlcoholUseDisorderTherapyIcon'
      {...props}
    >
      <path d='m0 0h44v44h-44z' fill='#fff' />
      <rect fill={colors.x_purple1} height='44' rx='4' width='44' />
      <path
        d='m31.0139 33h-5.312l-4.064-7.776h-2.688v7.776h-4.832v-22.4h8.736c2.2186 0 4.032.6507 5.44 1.952s2.112 3.0613 2.112 5.28c0 1.4293-.352 2.7093-1.056 3.84-.6827 1.1093-1.632 1.9733-2.848 2.592zm-12.064-17.76v5.408h3.456c.896 0 1.6106-.256 2.144-.768.5333-.5333.8-1.184.8-1.952 0-.7893-.2667-1.4293-.8-1.92-.5334-.512-1.248-.768-2.144-.768z'
        fill={colors.x_purple5}
      />
    </svg>
  )
  return showTooltip ? (
    <Tooltip content={tooltip} delayHide={0} placement='bottom'>
      {icon}
    </Tooltip>
  ) : (
    icon
  )
}

type AlcoholUseDisorderTherapyIconProps = {
  showTooltip: string
  width?: number
  tooltip?: string
}

export default AlcoholUseDisorderTherapyIcon
