import React from 'react'
import styles from './progressBar.module.scss'

type OwnProps = {
  progress: number
  color?: string
  width?: number
  backgroundColor?: string
}

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof ProgressBar.defaultProps

// @ts-expect-error TS(7022): 'ProgressBar' implicitly has type 'any' because it... Remove this comment to see the full error message
const ProgressBar = ({ progress, color, backgroundColor, width }: Props) => {
  const progressTranslate = -100 + progress
  return (
    <div className={styles['bar-base']} style={{ maxWidth: width + 'px', backgroundColor: backgroundColor }}>
      <div
        className={styles['bar-progress']}
        style={{ transform: `translateX(${progressTranslate}%)`, backgroundColor: color }}
      />
    </div>
  )
}

ProgressBar.defaultProps = {
  color: styles.x_primary_action,
  width: 180,
}

export default ProgressBar
