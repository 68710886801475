import React from 'react'
import colors from '../../../styles/_0colors.scss'
import { Icon } from './types'

const LyraStarIcon: React.FC<LyraStarIconProps> = ({ width = 26, fillColor = colors.x_brand, ...props }) => {
  return (
    <svg {...props} xmlns='http://www.w3.org/2000/svg' width={width} viewBox='0 0 26 27'>
      <path
        fill={fillColor}
        fillRule='evenodd'
        d='M7.56041909,13.3996935 C9.86019598,14.6182455 11.7509904,16.5096396 12.970142,18.8094165 C14.1886939,16.5096396 16.0794883,14.6182455 18.3786655,13.3996935 C16.0794883,12.1811416 14.1886939,10.2897475 12.970142,7.98997064 C11.7509904,10.2897475 9.86019598,12.1811416 7.56041909,13.3996935 M12.970142,26.354004 C12.1419824,26.354004 11.4709393,25.6823612 11.4709393,24.8548013 C11.4709393,19.3647211 7.00451474,14.8988962 1.51503425,14.8988962 C0.686874695,14.8988962 0.0158315802,14.2278531 0.0158315802,13.3996935 C0.0158315802,12.571534 0.686874695,11.9004909 1.51503425,11.9004909 C7.00451474,11.9004909 11.4709393,7.43466597 11.4709393,1.9445858 C11.4709393,1.11702592 12.1419824,0.445383129 12.970142,0.445383129 C13.7977019,0.445383129 14.4693447,1.11702592 14.4693447,1.9445858 C14.4693447,7.43466597 18.9351696,11.9004909 24.4246501,11.9004909 C25.2528096,11.9004909 25.9238527,12.571534 25.9238527,13.3996935 C25.9238527,14.2278531 25.2528096,14.8988962 24.4246501,14.8988962 C18.9351696,14.8988962 14.4693447,19.3647211 14.4693447,24.8548013 C14.4693447,25.6823612 13.7977019,26.354004 12.970142,26.354004'
      />
    </svg>
  )
}

type LyraStarIconProps = Icon

export default LyraStarIcon
