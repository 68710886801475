import { useMemo } from 'react'

import { DisplayMode, ZoomConfig } from './types'

const ProviderConfig: ZoomConfig = {
  canScreenShare: true,
  showMessenger: false,
  canStartSession: true,
  showRemoteParticipantStatus: true,
  defaultDisplayMode: DisplayMode.BANNER,
  canChangeDisplayMode: true,
  shouldEndSessionOnLeave: true,
  shouldJoinOnOpen: true,
  shouldNotifyOnParticipantReady: true,
  shouldStopSessionOnParticipantLeave: false,
}

const ClientConfig: ZoomConfig = {
  canScreenShare: false,
  showMessenger: true,
  canStartSession: false,
  showRemoteParticipantStatus: false,
  defaultDisplayMode: DisplayMode.FULL_SCREEN,
  canChangeDisplayMode: false,
  shouldEndSessionOnLeave: false,
  shouldJoinOnOpen: false,
  shouldNotifyOnParticipantReady: false,
  shouldStopSessionOnParticipantLeave: true,
}

export const useZoomConfig: (isProvider: boolean) => ZoomConfig = (isProvider) =>
  useMemo(() => (isProvider ? ProviderConfig : ClientConfig), [isProvider])
