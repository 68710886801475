import React, { FunctionComponent, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { Pressable } from 'react-native'

import styled, { useTheme } from 'styled-components/native'

import { InfoPopover } from '../../atoms'
import { BodyText, Size as BodyTextSize } from '../../atoms/bodyText/BodyText'
import { AddIcon } from '../../atoms/icons/AddIcon'
import { ExternalLinkIcon } from '../../atoms/icons/ExternalLinkIcon'
import { InfoIcon } from '../../atoms/icons/InfoIcon'
import { SecondaryButton } from '../../atoms/secondaryButton/SecondaryButton'
import { Subhead, Size as SubheadSize } from '../../atoms/subhead/Subhead'
import { TextButton } from '../../atoms/textButton/TextButton'
import { ColumnView, RowView } from '../../templates'
import { ThemeType, tID } from '../../utils'

export interface ExerciseConfigStartDraftProps {
  onStartDraftPress: () => void
}

const StartDraftContainer = styled(ColumnView)<{ theme: ThemeType }>(({ theme }) => ({
  borderRadius: '8px',
  border: `1px dashed ${theme.colors.textSecondary}`,
  padding: `${theme.spacing['24px']} 0px`,
  alignItems: 'center',
}))

const SubheadContainer = styled(RowView)<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['16px'],
}))

const IconContainer = styled(Pressable)({
  flex: 'none',
  marginLeft: '4px',
})

const PopoverLinkContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['16px'],
}))

export const ExerciseConfigStartDraft: FunctionComponent<ExerciseConfigStartDraftProps> = ({ onStartDraftPress }) => {
  const { formatMessage } = useIntl()
  const { colors, spacing } = useTheme()

  const onLearnMoreClicked = useCallback(() => {
    window.open('https://blended-care.zendesk.com/hc/en-us/articles/25792697568403', '_blank')
  }, [])

  return (
    <StartDraftContainer>
      <SubheadContainer>
        <Subhead
          size={SubheadSize.XSMALL}
          text={formatMessage({
            defaultMessage: 'No draft started for this exercise',
            description: 'Text indicating that the exercise has no drafts',
          })}
        />
        <InfoPopover
          content={
            <>
              <BodyText
                text={formatMessage({
                  defaultMessage:
                    'During a session, select “Start a draft” and share your screen with a client, working together on the exercise. Once you save the draft and add or update the exercise, the draft will be transferred the client who can resume on their own.',
                  description: 'Instructions for starting a draft exercise with a client',
                })}
                color={colors.textSecondary}
                size={BodyTextSize.SMALL}
              />
              <PopoverLinkContainer>
                <TextButton
                  onPress={onLearnMoreClicked}
                  rightIcon={<ExternalLinkIcon strokeColor={colors.iconActive} size={18} />}
                  text={formatMessage({
                    defaultMessage: 'Learn more',
                    description: 'Text link to open an article about starting a draft',
                  })}
                />
              </PopoverLinkContainer>
            </>
          }
          header={
            <Subhead
              text={formatMessage({
                defaultMessage: 'How to use Exercise Drafts',
                description: 'Title for section with information about starting a draft',
              })}
              color={colors.textPrimary}
              size={SubheadSize.XSMALL}
            />
          }
          offset={5}
          placement='top'
          shouldDisplayCloseButton
          trigger={
            <IconContainer
              accessibilityRole='button'
              accessibilityLabel={formatMessage({
                defaultMessage: 'Info Button',
                description: 'This button provides the user with more information on exercise drafts.',
              })}
            >
              <InfoIcon size={20} />
            </IconContainer>
          }
          popoverTriggerAction='press'
          closeIconColor={colors.iconDefault}
          popoverStyle={{
            container: {
              borderRadius: spacing['16px'],
              width: '392px',
            },
          }}
          allowContentUnderCloseButton
        />
      </SubheadContainer>

      <ColumnView>
        <SecondaryButton
          isSmall
          leftIcon={<AddIcon width={24} />}
          onPress={onStartDraftPress}
          text={formatMessage({
            defaultMessage: 'Start a draft',
            description: 'Label for button that begins a new draft of an exercise',
          })}
          testID={tID('ExerciseConfigStartDraft-btn')}
        />
      </ColumnView>
    </StartDraftContainer>
  )
}
