// Some monkey business to listen to router navigations

import { parsePath, Path } from 'react-router-dom'
import { NavigateListener } from './patchRouterNavigate'

/**
 * Utility to preserve some query parameters during a route transition
 *
 * @param preserveParams List of parameters to copy from `current`, merge into `next` if it is not already set
 * @returns an updated search string
 */
export function preserveSearchParams(
  preserveParams: SearchParam[],
  current: string | undefined,
  next: string | undefined,
  to: Partial<Path>,
): string | undefined {
  if (!current) {
    return next
  }

  const curParams = new URLSearchParams(current)
  const nextParams = new URLSearchParams(next)
  let modified = false
  for (const param of preserveParams) {
    if (param.shouldPreserve && !param.shouldPreserve(to)) {
      continue
    }
    if (curParams.has(param.name) && !nextParams.has(param.name)) {
      modified = true
      const values = curParams.getAll(param.name)
      values.forEach((val) => nextParams.append(param.name, val))
    }
  }

  // return the original search string if we did not modify anything
  return modified ? '?' + nextParams.toString() : next
}

type SearchParam = {
  name: string
  shouldPreserve?: (to: Partial<Path>) => boolean
}

const SEARCH_PARAMS: SearchParam[] = [
  {
    name: 'relId',
    shouldPreserve: (to: Partial<Path>) => to.pathname != null && to.pathname.includes('/clients/lt/home'),
  },
]

// Preserves app-wide query parameters in the URL across route changes
const preserveSearchParamsListener: NavigateListener = (navigateArgs, { location }) => {
  const [toArg] = navigateArgs
  if (typeof toArg === 'number') {
    return
  }
  let to: Partial<Path>
  if (typeof toArg === 'string') {
    to = parsePath(toArg)
  } else {
    to = { ...toArg }
  }

  if (location.search) {
    to.search = preserveSearchParams(SEARCH_PARAMS, location.search, to.search, to)
    navigateArgs[0] = to
  }
}

export default preserveSearchParamsListener
