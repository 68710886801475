import React, { FunctionComponent } from 'react'
import Svg, { ClipPath, Defs, G, Path } from 'react-native-svg'

import { colors } from '../../styles/colors'

export interface RibbonHeartIllustrationProps {
  width?: number
  height?: number
}

export const RibbonHeartIllustration: FunctionComponent<RibbonHeartIllustrationProps> = ({
  width = 24,
  height = 24,
}) => {
  return (
    <Svg width={width} height={height} fill='none' viewBox='0 0 24 24'>
      <G clipPath='url(#a)'>
        <Path
          d='m17.564 13.8 3.31 7.737a.51.51 0 0 1-.75.612l-3.117-1.958-1.532 3.504a.51.51 0 0 1-.957-.067l-2.013-5.665-1.9 5.685a.51.51 0 0 1-.914.113l-1.68-2.616-3.59 1.61a.509.509 0 0 1-.682-.653l3.298-8.304h10.53l-.003.003Z'
          fill={colors.secondary_peach3}
        />
        <Path d='M12 17.966A8.983 8.983 0 1 0 12 0a8.983 8.983 0 0 0 0 17.966Z' fill={colors.secondary_peach4} />
        <Path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M15.286 6.146c-1.872-1.044-3.166 1.37-3.166 1.37s-2.483-2.777-4.038-.594c-1.729 2.428 3.83 6.266 4.365 6.147.35-.078.997-.644 1.661-1.376.711-.787 1.442-1.767 1.856-2.567.15-.288.237-.6.27-.909.093-.84-.234-1.674-.951-2.075l.003.004Z'
          fill={colors.white}
        />
      </G>
      <Defs>
        <ClipPath id='a'>
          <Path fill={colors.white} d='M0 0h24v24H0z' />
        </ClipPath>
      </Defs>
    </Svg>
  )
}
