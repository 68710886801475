import React from 'react'
import colors from '../../../styles/_0colors.scss'
import { Icon } from './types'

const CheckInCircleIcon: React.FC<CheckInCircleIconProps> = ({
  width = 24,
  fillColor = colors.x_secondary_action,
  ...props
}) => {
  return (
    <svg width={width} height={width} viewBox={`0 0 ${width} ${width}`} xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM14.59 5.58L8 12.17L5.41 9.59L4 11L8 15L16 7L14.59 5.58Z'
        fill={fillColor}
      />
    </svg>
  )
}

type CheckInCircleIconProps = Icon

export default CheckInCircleIcon
