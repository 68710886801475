import React, { FunctionComponent, useState } from 'react'
import { FieldRenderProps } from 'react-final-form'
import { ViewStyle } from 'react-native'

import { isNumber, range } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import { BaseInput } from './BaseInput'
import { AccessibilityRolesNative } from '../../constants'
import { SpacedView } from '../../templates'
import { ThemeType } from '../../utils'
import { BodyText, Size } from '../bodyText/BodyText'
import { SecondaryButton } from '../secondaryButton/SecondaryButton'

export interface ButtonRangeGroupProps {
  accessibilityLabelledBy?: string
  error?: string
  label?: string
  maximumValue: number
  maxLabel?: string
  minimumValue: number
  minLabel?: string
  name?: string
  onChange: (value: number) => void
  rangeContainerStyle?: ViewStyle
  value?: number
}

const ButtonContainer = styled.View<{ theme: ThemeType }>(({ theme: { breakpoints } }) => ({
  // Set to column-reverse on mobile so that the highest value button is first
  flexDirection: breakpoints.isMobileSized ? 'column-reverse' : 'row',
  justifyContent: 'space-between',
}))

const LabelContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: 'row',
  height: theme.spacing['24px'],
  justifyContent: 'space-between',
  marginTop: theme.spacing['12px'],
}))

const Label = styled.View<{ theme: ThemeType }>({
  flexDirection: 'row',
})

export const ButtonRangeGroup: FunctionComponent<ButtonRangeGroupProps> = ({
  accessibilityLabelledBy,
  error,
  label,
  maximumValue,
  maxLabel,
  minimumValue,
  minLabel,
  name,
  onChange,
  value,
  rangeContainerStyle,
}) => {
  const accessibilityAttr = accessibilityLabelledBy
    ? { accessibilityLabelledBy }
    : { accessibilityLabel: `Button range for - ${label}` }

  const buttonRange: number[] = range(minimumValue, maximumValue + 1)
  const initialSelectedButtonIndex = buttonRange.findIndex((buttonRangeValue) => buttonRangeValue === value)
  const [selectedIndex, setSelectedIndex] = useState<number>(initialSelectedButtonIndex)
  const { breakpoints, spacing } = useTheme()

  const onValueSelected = (rangeValue: number) => {
    setSelectedIndex(buttonRange.findIndex((buttonRangeValue) => buttonRangeValue === rangeValue))
    onChange(rangeValue)
  }

  const getMobileButtonText = (index: number) => {
    if (index === 0) {
      return `${buttonRange[index]} - ${minLabel}`
    } else if (index === buttonRange.length - 1) {
      return `${buttonRange[index]} - ${maxLabel}`
    } else {
      return `${buttonRange[index]}`
    }
  }

  return (
    <BaseInput error={error} label={label} name={name} style={rangeContainerStyle}>
      <ButtonContainer {...accessibilityAttr} accessibilityRole={AccessibilityRolesNative.RADIO_GROUP}>
        {buttonRange.map((rangeValue, index) => {
          return (
            <SpacedView
              key={`buttonRange-${rangeValue}`}
              marginBottom={breakpoints.isMobileSized ? spacing['8px'] : '0'}
            >
              <SecondaryButton
                accessibilityRole={AccessibilityRolesNative.RADIO}
                suppressAccessibilitySelected
                fullWidth={breakpoints.isMobileSized}
                isSmall
                text={breakpoints.isMobileSized ? getMobileButtonText(index) : rangeValue}
                onPress={() => onValueSelected(rangeValue)}
                selected={index === selectedIndex}
                style={breakpoints.isMobileSized ? undefined : { width: 40, minWidth: 0 }}
              />
            </SpacedView>
          )
        })}
      </ButtonContainer>
      {!breakpoints.isMobileSized && (
        <LabelContainer>
          <Label>
            <BodyText size={Size.SMALL} text={minLabel} selectable={false} />
          </Label>
          <Label>
            <BodyText size={Size.SMALL} text={maxLabel} selectable={false} />
          </Label>
        </LabelContainer>
      )}
    </BaseInput>
  )
}

export const ButtonRangeGroupRFF: FunctionComponent<FieldRenderProps<number | undefined>> = ({
  accessibilityLabelledBy,
  initValue,
  input: { value, onChange, name },
  meta: { touched, error },
  label,
  minimum,
  minLabel,
  maximum,
  maxLabel,
  rangeContainerStyle,
}) => {
  return (
    <ButtonRangeGroup
      accessibilityLabelledBy={accessibilityLabelledBy}
      error={touched && error}
      label={label}
      maximumValue={parseInt(maximum, 10)}
      maxLabel={maxLabel}
      minimumValue={parseInt(minimum, 10)}
      minLabel={minLabel}
      name={name}
      onChange={onChange}
      rangeContainerStyle={rangeContainerStyle}
      value={isNumber(value) ? value : initValue || 0}
    />
  )
}
