import React, { ChangeEvent, Component } from 'react'
import { FormattedDate } from 'react-intl'

import { get, isEqual } from 'lodash-es'

import { AssignmentResponse } from '@lyrahealth-inc/shared-app-logic'

import styles from './comment.module.scss'
import PrimaryButton from '../../atoms/buttons/primaryButton/PrimaryButton'
import LoadingIndicator from '../../atoms/loadingIndicator/LoadingIndicator'
import { replaceWebLinks } from '../../utils/stringUtils'

type Props = {
  response?: AssignmentResponse
  submitHandler?: (inputValue: string, response: AssignmentResponse) => Promise<any>
  fetchComment?: ({ messageId }: { messageId: string }) => Promise<any>
  receiverName?: string
  userRole?: 'provider' | 'client'
}

type CommentType = {
  attributes: string
  body: string
  conversation_sid: string
  date_created: string
  message_id: string
}

type State = {
  inputValue: string
  isLoading: boolean
  isSubmitting: boolean
  comment: CommentType | null
}

class Comment extends Component<Props, State> {
  state = { inputValue: '', isLoading: false, isSubmitting: false, comment: null }

  componentDidMount() {
    this.getComment()
  }

  componentDidUpdate(prevProps: Props) {
    if (!isEqual(this.props.response, prevProps.response)) {
      this.getComment()
    }
  }

  getComment() {
    const { response, fetchComment } = this.props
    const messageId = get(response, 'provider_feedback')

    if (messageId) {
      this.setState({ isLoading: true })
      fetchComment &&
        fetchComment({ messageId })
          .then((comment: CommentType) => {
            this.setState({ comment })
          })
          .finally(() => this.setState({ isLoading: false }))
    }
  }

  submitInput = () => {
    const { response, submitHandler } = this.props
    const { inputValue } = this.state
    if (inputValue === '') return false
    this.setState({ isSubmitting: true })
    submitHandler &&
      response &&
      submitHandler(inputValue, response).then(() => {
        this.setState({ inputValue: '', isSubmitting: false })
      })
    return false
  }

  renderComment = () => {
    const { isLoading, comment } = this.state
    if (isLoading) {
      return (
        <div className={styles['loading-container']}>
          <LoadingIndicator size={35} />
        </div>
      )
    }
    return (
      <div className={styles['posted-comment']} data-test-id='Comment-postedComment'>
        {comment && (
          <div className={styles['comment-box']}>
            <h5>
              <FormattedDate
                value={(comment as CommentType).date_created}
                year='numeric'
                month='short'
                day='numeric'
                hour='numeric'
                minute='numeric'
              />
            </h5>
            <span>{replaceWebLinks((comment as CommentType).body)}</span>
          </div>
        )}
      </div>
    )
  }

  renderAddComment = () => {
    const { inputValue, isSubmitting } = this.state
    const { receiverName } = this.props
    return (
      <div className={styles['add-comment']} data-test-id='Comment-addComment'>
        <textarea
          value={inputValue}
          disabled={isSubmitting}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => this.setState({ inputValue: e.target.value })}
          placeholder={`Type your comment to ${receiverName} here...`}
        />
        <PrimaryButton
          isSmall
          isLoading={isSubmitting}
          disabled={inputValue === ''}
          loaderWithin
          onClick={this.submitInput}
          data-test-id='Comment-addCommentButton'
        >
          Add Comment
        </PrimaryButton>
      </div>
    )
  }

  render() {
    const { response, userRole } = this.props
    const messageId = get(response, 'provider_feedback')
    if (messageId) return this.renderComment()
    else {
      if (userRole === 'provider') return this.renderAddComment()
      return false
    }
  }
}

export default Comment
