import React, { FunctionComponent, ReactNode } from 'react'
import { StyleProp, ViewStyle } from 'react-native'
import styled from 'styled-components/native'

const Container = styled.View(({ theme }) => ({
  paddingHorizontal: '16px',
  ...(theme.breakpoints.isMinWidthLaptop && {
    paddingHorizontal: '64px',
  }),
}))

const Content = styled.View(({ theme }) => ({
  width: '100%',
  marginHorizontal: 'auto',
  ...(theme.breakpoints.isMinWidthLaptop && {
    maxWidth: '944px',
  }),
}))

export const ContentContainer: FunctionComponent<ContentContainerProps> = ({ style, children }) => {
  return (
    <Container style={style}>
      <Content>{children}</Content>
    </Container>
  )
}

type ContentContainerProps = {
  children: ReactNode | ReactNode[]
  style?: StyleProp<ViewStyle>
}
