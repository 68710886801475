import React, { useEffect, useState } from 'react'
import Countdown from 'react-countdown-now'

import classNames from 'classnames'
import { cloneDeep, get, isEmpty, noop } from 'lodash-es'

import styles from './lesson.module.scss'
import LessonThumbnail from './LessonThumbnail'
import DefaultButton from '../../atoms/buttons/defaultButton/DefaultButton'
import CheckIcon from '../../atoms/icons/CheckIcon'
import LtIcon from '../assignmentsList/LtIcon'
import FormBody from '../formBody/FormBody'
import VideoLessonContainer from '../videoLessonContainer/VideoLessonContainer'

type Props = {
  instructions?: string | null
  title?: string
  metaData?: $TSFixMe
  response?: $TSFixMe
  updateLesson?: $TSFixMeFunction
  submitQuiz?: $TSFixMeFunction
  userRole?: 'provider' | 'client'
  closeModal?: $TSFixMeFunction
  onIntroView?: $TSFixMeFunction
  onVideoView?: $TSFixMeFunction
  onReviewView?: $TSFixMeFunction
  seconds?: number
}
function Lesson({
  instructions,
  title,
  metaData,
  response,
  updateLesson = noop,
  submitQuiz = noop,
  userRole,
  closeModal = noop,
  onIntroView = noop,
  onVideoView = noop,
  onReviewView = noop,
}: Props) {
  const [interstitial, setInterstitial] = useState(false)
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    setProgress(getLessonProgress(response))
    // TODO: the below may cause errors, but should be fine as we plan to remove this component soon
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const content = metaData.contents
  const selectedContent = content[progress - 1]

  const getLessonProgress = (lessonResponse: $TSFixMe) => {
    // This method derives a progress integer from the assignment response data

    // If the user is a provider and the lesson is complete, then go to lesson review
    if (userRole === 'provider') {
      if (get(lessonResponse, 'status') === 'completed') {
        return content.length
      } else {
        return 0
      }
    }

    // Should show intro
    if (
      isEmpty(lessonResponse) ||
      get(lessonResponse, 'status') === 'completed' ||
      get(lessonResponse, `response.${content[0].name}.status`) === 'not_started'
    ) {
      return 0
    }

    // Go to the first item that isn't complete
    return (
      content.findIndex((item: $TSFixMe) => get(lessonResponse, `response.${item.name}.status`) !== 'completed') + 1
    )
  }

  const progressInterstitial = () => {
    setProgress(progress + 1)
    setInterstitial(false)
  }

  const updateVideoStatus = (data: $TSFixMe) => {
    const responseToSubmit = cloneDeep(response.response) || {}
    responseToSubmit[selectedContent.name] = data
    return updateLesson(responseToSubmit, response.id)
  }

  const submitLessonQuiz = (data: $TSFixMe) => {
    const responseToSubmit = cloneDeep(response.response) || {}
    // When we are sumitting the form, we set `status` to completed
    // to be able to check if assignment is complete
    responseToSubmit[selectedContent.name] = { status: 'completed', data }
    return submitQuiz(responseToSubmit, response.id)
  }

  const onItemClick = (item: $TSFixMe, index: $TSFixMe) => {
    setInterstitial(false)
    setProgress(index + 1)
  }

  const renderNavItem = (text: $TSFixMe, selected: $TSFixMe, completed: $TSFixMe, onClick: $TSFixMe) => {
    return (
      <DefaultButton
        unstyled
        key={text}
        data-test-id={`Lesson-nav-${text.replace(/\s+/g, '')}`}
        onClick={onClick}
        customClass={classNames(styles['nav-item'], { [styles.selected]: selected })}
        aria-pressed={selected}
      >
        {completed ? (
          <CheckIcon
            className={styles.check}
            fillColor={styles.x_semi_dark_gray}
            withCircle={false}
            // @ts-expect-error TS(2322): Type '{ className: string; fillColor: string; with... Remove this comment to see the full error message
            role='img'
            alt='checkmark'
          />
        ) : null}
        <div className={styles.text}>{text}</div>
        <svg
          className={styles['nav-tab']}
          xmlns='http://www.w3.org/2000/svg'
          width='100%'
          height='100%'
          viewBox='0 0 124 36'
        >
          <path d='M1.0020423,0.5 L12.2829265,15.4901758 C13.500987,17.1087493 13.4874369,19.3416787 12.2498228,20.9453504 L1.01745007,35.5 L110.801625,35.5 L122.468208,20.3297755 C123.42602,19.0843197 123.436515,17.3532222 122.493875,16.0962447 L110.797847,0.5 L1.0020423,0.5 Z' />
        </svg>
      </DefaultButton>
    )
  }

  const renderNavBar = () => {
    const multiVideoLesson = content.filter((item: $TSFixMe) => item.content_type === 'video').length > 1
    return (
      <div className={styles['navbar-container']}>
        {renderNavItem(
          'Intro',
          progress === 0,
          get(response, `response.${content[0].name}.status`) !== 'not_started',
          () => onItemClick({}, -1),
        )}
        {content.map((item: $TSFixMe, index: $TSFixMe) => {
          const title = item.content_type === 'video' ? `Video ${multiVideoLesson ? index + 1 : ''}` : 'Review'
          return renderNavItem(
            title,
            progress === index + 1,
            get(response, `response.${item.name}.status`) === 'completed',
            () => onItemClick(item, index),
          )
        })}
      </div>
    )
  }

  const renderCountdown = () => {
    if (content[progress]) {
      return (
        <div>
          <h4 className={styles['intro-title']}>
            <span data-test-id='Lesson-countdown-header'>Next up, starting in </span>
            <Countdown
              date={Date.now() + 5000}
              onComplete={progressInterstitial}
              renderer={(props) => <span>{props.seconds}</span>}
            />
          </h4>
          {/* @ts-expect-error TS(2786): 'LessonThumbnail' cannot be used as a JSX componen... Remove this comment to see the full error message */}
          <LessonThumbnail
            data-test-id='Lesson-interstitial-next'
            content={content[progress]}
            onClick={progressInterstitial}
            order='next'
          />
        </div>
      )
    } else {
      return (
        <h3>
          Thanks for watching.{' '}
          <DefaultButton customClass={styles['close-modal']} onClick={closeModal} unstyled>
            Return to your activities.
          </DefaultButton>
        </h3>
      )
    }
  }

  const renderInterstitial = () => {
    return (
      <div className={styles['interstitial-container']}>
        <div>
          <h4 className={styles['intro-title']}>
            <CheckIcon className={styles.check} width={22} isFilled withCircle={false} />
            {selectedContent.title}
          </h4>
          {/* @ts-expect-error TS(2786): 'LessonThumbnail' cannot be used as a JSX componen... Remove this comment to see the full error message */}
          <LessonThumbnail
            data-test-id='Lesson-interstitial-previous'
            content={selectedContent}
            onClick={() => {
              setInterstitial(false)
            }}
            order='previous'
          />
        </div>
        {renderCountdown()}
      </div>
    )
  }

  const renderVideo = () => {
    if (interstitial) return renderInterstitial()
    return (
      // @ts-expect-error TS(2741): Property 'onClickPlay' is missing in type '{ onMou... Remove this comment to see the full error message
      <VideoLessonContainer
        onMount={onVideoView}
        videoMeta={selectedContent}
        response={response}
        updateVideoStatus={updateVideoStatus}
        videoEnded={() => setInterstitial(true)}
      />
    )
  }

  const renderIntro = () => {
    onIntroView()
    const firstVideo = metaData.contents[0]

    return (
      <div className={styles['intro-container']}>
        <div>
          <h4 className={styles['intro-title']}>Introduction</h4>
          <p className={styles.description}>{instructions || metaData.description}</p>
        </div>
        <div className={styles['up-next']}>
          <div>
            <h4 className={styles['intro-title']}>Get started</h4>
            {/* @ts-expect-error TS(2786): 'LessonThumbnail' cannot be used as a JSX componen... Remove this comment to see the full error message */}
            <LessonThumbnail
              data-test-id='Lesson-thumbnail-first'
              content={firstVideo}
              onClick={() => {
                onItemClick(firstVideo, 0)
              }}
              order='next'
            />
          </div>
        </div>
      </div>
    )
  }

  const renderQuiz = () => {
    onReviewView()
    const reviewResponse =
      get(response, `response.${selectedContent.name}.data`) || get(response, `response.${selectedContent.name}.values`)
    return (
      <div className={styles['quiz-container']}>
        <h4 style={{ marginBottom: selectedContent?.description ? '8px' : '20px' }}>Lesson Review</h4>
        {selectedContent?.description && (
          <p data-test-id='Lesson-Review-Subheader' style={{ marginBottom: '24px' }}>
            {selectedContent.description}
          </p>
        )}
        <FormBody
          autoSave={false}
          preview={userRole === 'provider'}
          submitForm={submitLessonQuiz}
          response={{ response: reviewResponse }}
          readOnly={!!reviewResponse}
          formContext={{ showAnswers: !!reviewResponse }}
          content={selectedContent}
        />
      </div>
    )
  }

  // @ts-expect-error TS(7030): Not all code paths return a value.
  const renderContent = () => {
    const contentType = get(selectedContent, 'content_type')
    if (progress === 0 || !contentType) return renderIntro()
    if (contentType === 'video') return renderVideo()
    if (contentType === 'form') return renderQuiz()
  }
  return (
    <div className={styles.container}>
      <div className={styles['header-container']}>
        <div className={styles['title-container']}>
          <LtIcon type={'lesson'} size={36} />
          <h3 className={styles.title}>{title}</h3>
        </div>
        {renderNavBar()}
      </div>
      <div className={styles['content-container']}>{renderContent()}</div>
    </div>
  )
}

export default Lesson
