import React, { FunctionComponent } from 'react'
import Svg, { ClipPath, Defs, G, Path } from 'react-native-svg'

type SharePreferencesIconProps = {
  width?: number
  height?: number
}

export const SharePreferencesIcon: FunctionComponent<SharePreferencesIconProps> = ({ width = 48, height = 48 }) => {
  return (
    <Svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill='none'>
      <G clipPath='url(#clip0_1062_21976)'>
        <G clipPath='url(#clip1_1062_21976)'>
          <Path
            d='M24.125.512c-3.32 0-6.54.57-9.565 1.695C7.355 4.872 2.28 10.797.64 18.467c-.99 4.615-.825 9.905.445 14.145 1.74 5.76 6.01 10.605 11.72 13.305 3.2 1.52 7.015 2.32 11.04 2.32 3.06 0 6.26-.465 9.25-1.35 5.36-1.585 9.395-4.91 11.995-9.88 2.05-3.925 3.05-8.415 2.895-12.99v-.045c-.2-5.645-2.235-11.48-5.445-15.615-2.765-3.56-7.055-6.055-12.405-7.21a28.506 28.506 0 00-6.01-.645v.01z'
            fill='#fff'
          />
          <Path
            d='M45.994 24.057c.145 4.135-.725 8.31-2.67 12.035-2.34 4.475-5.925 7.455-10.795 8.895-6.025 1.78-13.155 1.855-18.875-.86-5.065-2.395-9.035-6.675-10.665-12.08-1.21-4.045-1.295-9.025-.405-13.16 1.485-6.95 6.015-12.355 12.665-14.815 4.595-1.705 9.685-2.01 14.465-.97 4.295.925 8.485 2.915 11.255 6.485 3.065 3.945 4.85 9.5 5.025 14.465v.01-.005z'
            fill='#FFD2C4'
          />
          <Path
            d='M41.716 38.647c-.415-.34-.815-.69-1.21-1.045-.25-.23-.475-.46-.705-.715-1.295-1.43-1.88-3.35-3.175-4.735-.385-.415-.845-.735-1.31-1.055l-4.065-2.835c-.695-.485-1.385-1-2.1-1.47-.34-.22-1.065-.845-1.475-.68-1.35.54.42 2.845.88 3.34.56.615 1.21 1.12 1.78 1.75.725.815-2.33.76-2.715.745-1.2-.035-2.395-.21-3.585-.275-1.75-.09-3.42-.285-5.2-.285-1.67 0-3.385-.155-4.97.36-.515.165-1.09.54-1.02 1.075.13 1.035 1.355.625 3.275.845 1.695.195 2.965.055 5.365 1.02-1.44.2-3.485.42-4.97.42-.64.1-3.685.24-2.925 1.585.305.54.945.65 1.265 1.145.175.265.285.57.44.845.99 1.76 3.46 2.52 5.285 3.035.965.265 1.835.24 2.795.405 2.46.42 4.86.855 7.355.97 1.285.065 2.89-.31 4.025.31.24.13.47.285.69.44a16.842 16.842 0 006.255-5.19l.02-.01-.005.005z'
            fill='#DA9460'
          />
          <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M28.23 39.161c-.73.195-1.61.43-2.71.695-.575.14-1.32.085-2.08-.02a35.828 35.828 0 01-1.53-.25c-.315-.054-.625-.11-.93-.16-.84-.135-1.635-.235-2.315-.22-.69.015-1.18.155-1.47.41a.452.452 0 01-.64-.044.452.452 0 01.045-.64c.535-.465 1.285-.616 2.045-.63.77-.02 1.64.095 2.485.23.33.055.655.11.97.165.51.09 1 .174 1.47.24.76.105 1.355.13 1.745.035 1.085-.266 1.96-.495 2.685-.69.165-.045.325-.085.475-.125.815-.215 1.455-.375 2.03-.46 1.195-.175 2.07-.006 3.615.554a.452.452 0 11-.31.85c-1.475-.54-2.19-.654-3.17-.51-.515.075-1.11.22-1.925.44-.15.04-.305.08-.47.126l-.015.005zM25.466 36.346a.46.46 0 01-.27.585c-2.35.87-4.095.6-5.585.365-1.485-.235-2.635-.415-4.03.39a.456.456 0 01-.62-.165.456.456 0 01.165-.62c1.67-.97 3.11-.74 4.555-.51l.07.01c1.46.23 3.01.465 5.13-.32a.46.46 0 01.585.27v-.005z'
            fill='#BF7C4F'
          />
          <Path
            d='M32.31 14.781a7.35 7.35 0 00-7.345-7.345c-3.295 0-6.09 2.185-7.015 5.175-2.99.925-5.175 3.725-5.175 7.015a7.35 7.35 0 007.345 7.345c3.295 0 6.09-2.185 7.015-5.175 2.99-.925 5.175-3.725 5.175-7.015zM20.12 25.596a5.976 5.976 0 01-5.97-5.97c0-2.41 1.44-4.485 3.495-5.43-.02.195-.03.385-.03.585a7.35 7.35 0 007.345 7.345c.2 0 .395-.02.585-.03-.945 2.055-3.02 3.495-5.43 3.495l.005.005zm7.32-5.385c.02-.195.03-.385.03-.585a7.35 7.35 0 00-7.345-7.345c-.2 0-.395.02-.585.03.945-2.055 3.02-3.495 5.43-3.495 3.295 0 5.97 2.68 5.97 5.97 0 2.41-1.44 4.485-3.495 5.43l-.005-.005z'
            fill='#fff'
          />
        </G>
      </G>
      <Defs>
        <ClipPath id='clip0_1062_21976'>
          <Path fill='#fff' transform='translate(0 .511)' d='M0 0H48V48H0z' />
        </ClipPath>
        <ClipPath id='clip1_1062_21976'>
          <Path fill='#fff' transform='translate(0 .511)' d='M0 0H48V47.73H0z' />
        </ClipPath>
      </Defs>
    </Svg>
  )
}
