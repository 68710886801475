import React, { FunctionComponent } from 'react'
import { AccessibilityInfo, ActivityIndicator } from 'react-native'

import styled, { useTheme } from 'styled-components/native'

import { tID } from '../../utils'

const LoadingContainer = styled.View(({ topPadding }: { topPadding: number }) => ({
  paddingTop: topPadding,
}))

/**
 * Loading indicator shown on any pages where loading content occurs
 */
export const LoadingIndicator: FunctionComponent<LoadingIndicatorProps> = ({
  size = 'large',
  color,
  topPadding = 250,
}) => {
  const { colors } = useTheme()

  return (
    <LoadingContainer
      testID={tID('LoadingIndicator')}
      topPadding={topPadding}
      accessibilityLabel='Loading indicator'
      accessibilityRole='progressbar'
    >
      {AccessibilityInfo.announceForAccessibility('Loading, please wait')}
      <ActivityIndicator size={size} color={color || colors.iconActive} accessibilityLabel='Loading' />
    </LoadingContainer>
  )
}

type LoadingIndicatorProps = {
  size?: number | 'small' | 'large'
  color?: string
  topPadding?: number
}
