import { createSelector } from '@reduxjs/toolkit'

import { getClientsState } from '../../data/clientsDataSelectors'

export const getClientsClientDetails = createSelector(getClientsState, (clientsState) => clientsState.clientDetails)

export const getClientsClientDetailsData = createSelector(
  getClientsClientDetails,
  (clientDetails) => clientDetails.data,
)
