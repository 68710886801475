import { createReducer } from '@reduxjs/toolkit'
import { isEmpty } from 'lodash-es'

import { Transfer } from '@lyrahealth-inc/shared-app-logic'

import {
  CLEAR_PAYMENTS_LIST_OF_TYPE,
  CLEAR_UPDATED_CHARGES,
  GET_PAYMENTS_BALANCES,
  GET_PAYMENTS_OF_TYPE,
  HANDLE_CHARGE_UPDATE_ERROR,
  PURGE_TOO_FREQUENT_REJECTIONS,
  UPDATE_CHARGE_COMMENT,
  UPDATE_CHARGE_STATUS,
  UPDATE_REVERSE_CHARGE_STATUS,
} from '../../../common/constants/reduxConstants'

type DataItem = Transfer & { updateError?: string; hasUpdatedTo?: string }
type PaymentsData = {
  [status: string]: {
    data: DataItem[]
    eligibilityError: boolean
    page: string
  }
}
type PaymentsDashboardState = {
  showModal: boolean
  modalData: any
  paymentsData: PaymentsData
  balances?: { available_balance: number; pending_balance: number }
}

const initialState = {
  showModal: false,
  modalData: {},
  paymentsData: {},
}

export default createReducer<PaymentsDashboardState>(initialState, (builder) => {
  builder.addCase(GET_PAYMENTS_OF_TYPE, (state, action: any) => {
    const list = state.paymentsData?.all?.data
    let newList: Transfer[]

    if (action.page > 0 && list && list.length > 0) {
      // load more
      newList = [...list, ...action.data.data]
    } else {
      newList = action.data.data
    }

    state.paymentsData.all = {
      data: newList,
      eligibilityError: action.data.eligibilityError,
      page: action.page,
    }
    return state
  })
  builder.addCase(`${GET_PAYMENTS_BALANCES}_FULFILLED`, (state, action: any) => {
    state.balances = action.payload.data
    return state
  })
  builder.addCase(UPDATE_CHARGE_STATUS, (state, action: any) => {
    const allPayments = state.paymentsData?.all?.data
    if (allPayments) {
      const idx = allPayments.findIndex((payment) => payment.id === action.payload.id)
      // Clear any errors
      if (idx === -1) {
        return state
      }
      state.paymentsData.all.data[idx].updateError = undefined

      if (action.payload.status !== 'submitted' && !action.wasExcluded) {
        state.paymentsData.all.data[idx].hasUpdatedTo = action.payload.status
        state.paymentsData.all.data[idx].admin_note = action.payload.admin_note
      }
      if (action.wasExcluded) {
        state.paymentsData.all.data[idx].exclude_from_session_count = action.payload.exclude_from_session_count
      }
      if (action.payload.status === 'submitted') {
        state.paymentsData.all.data[idx].status = action.payload.status
      }
    }

    return state
  })
  builder.addCase(UPDATE_REVERSE_CHARGE_STATUS, (state, action: any) => {
    const allPayments = state.paymentsData?.all?.data
    if (allPayments) {
      const idx = allPayments.findIndex((payment) => payment.id === action.payload.id)

      // Clear any errors
      state.paymentsData.all.data[idx].updateError = undefined
      state.paymentsData.all.data[idx].hasUpdatedTo = action.payload.status
    }

    return state
  })
  builder.addCase(CLEAR_UPDATED_CHARGES, (state, action: any) => {
    action.data.forEach(function (payment: any) {
      const allPayments = state.paymentsData?.all?.data
      if (allPayments) {
        const idx = allPayments.findIndex((item) => item.id === payment.id)
        state.paymentsData.all.data.splice(idx, 1)
      }
    })
    return state
  })
  builder.addCase(HANDLE_CHARGE_UPDATE_ERROR, (state, action: any) => {
    const allPayments = state.paymentsData?.all?.data
    if (allPayments) {
      const idx = allPayments.findIndex((item) => item.id === action.payload.paymentId)
      state.paymentsData.all.data[idx].updateError = action.payload.contents
    }

    return state
  })
  builder.addCase(PURGE_TOO_FREQUENT_REJECTIONS, (state, action: any) => {
    // remove this id's charge from any linked charges' conflict array, since it's been rejected
    // this FE purge mirrors the BE cleanup done by the API, so that we don't have to re-fetch immediately, and can rely on the next fetch to match
    const typeList = state.paymentsData?.all?.data
    if (typeList) {
      const typePurged = typeList.map((charge) => {
        if (charge.is_too_frequent) {
          const idx = charge.is_too_frequent_with_charge_ids.findIndex((obj) => {
            return obj.id === action.payload
          })
          if (idx !== -1) {
            charge.is_too_frequent_with_charge_ids.splice(idx, 1)
          }
        }
        return charge
      })
      state.paymentsData.all.data = typePurged
    }

    return state
  })
  builder.addCase(UPDATE_CHARGE_COMMENT, (state, action: any) => {
    const allPayments = state.paymentsData?.all?.data
    if (allPayments) {
      const idx = allPayments.findIndex((payment) => payment.id === action.payload.id)
      if (idx !== -1) {
        state.paymentsData.all.data[idx].admin_note = action.payload.admin_note
      }
      if (!isEmpty(state.modalData)) {
        state.modalData.admin_note = action.payload.admin_note
      }
    }
    return state
  })
  builder.addCase(CLEAR_PAYMENTS_LIST_OF_TYPE, (state, action: any) => {
    const status = action.status
    let allPayments = state.paymentsData?.all?.data
    if (allPayments) {
      const statusList = state.paymentsData[status]?.data
      allPayments = allPayments.filter((item) => !statusList.includes(item))

      delete state.paymentsData[status]
      state.paymentsData.all.data = allPayments
    }
    return state
  })
})
