import React, { FunctionComponent } from 'react'
import { Path, Svg } from 'react-native-svg'

import { PrimaryNeedsWords } from '@lyrahealth-inc/shared-app-logic'

import { tID } from '../../utils'

type AutismIllustrationProps = {
  size?: string | number
  selected?: string
}

export const AutismIllustration: FunctionComponent<AutismIllustrationProps> = ({ selected = false, size }) => (
  <Svg
    width={size}
    height={size}
    viewBox='0 0 48 48'
    fill='none'
    testID={tID(`${PrimaryNeedsWords.AUTISM_SPECTRUM}${selected ? '-Selected-' : '-'}Illustration`)}
  >
    <Path
      d='M8.49281 24.4155C11.8654 23.0227 15.4973 22.3804 19.1218 22.4761C19.2704 22.4811 19.4013 22.5088 19.5197 22.5542C20.4239 22.5114 21.3584 22.6625 21.6279 23.6851C21.9125 24.7631 20.9504 26.2592 20.4693 27.1987C19.0714 29.9365 17.0136 32.3847 14.5402 34.236C12.3918 35.8429 9.77987 36.7219 7.24101 35.6036C5.16811 34.6893 2.87104 32.6769 2.54109 30.3345C2.08017 27.0576 6.00936 25.4431 8.49532 24.4155H8.49281Z'
      fill={selected ? '#3BA5B5' : '#FCB097'}
    />
    <Path
      d='M21.6076 22.6189C21.6378 22.3922 21.5874 22.1429 21.4212 21.891C19.1166 18.4026 14.3915 15.8008 10.0618 15.0653C5.9689 14.3701 1.28662 15.7504 0.196016 19.6242C-0.864361 23.3947 2.5359 26.4348 6.57591 26.8C11.5856 27.2559 15.6105 23.8128 20.5951 24.2535C21.4766 24.3316 22.0005 23.2763 21.6076 22.6164V22.6189Z'
      fill={selected ? '#85CCD5' : '#FFDC69'}
    />
    <Path
      d='M29.1985 23.5038C29.259 23.2822 29.395 23.0731 29.6267 22.9019C32.833 20.5469 37.6967 19.9222 41.5352 20.8743C45.1621 21.7735 48.4969 24.8161 47.9377 28.8082C47.3937 32.6921 43.457 34.221 39.9937 33.0372C35.7018 31.5688 33.6844 26.8714 29.4177 25.4004C28.6646 25.141 28.6293 23.9673 29.1985 23.5038Z'
      fill={selected ? '#85CCD5' : '#FFC03C'}
    />
    <Path
      d='M31.7329 37.2737C31.1864 33.5888 29.8288 30.0652 27.8163 26.9344C27.7332 26.806 27.6375 26.7052 27.5342 26.6271C27.0934 25.8111 26.4587 25.0706 25.4009 25.3678C24.2851 25.6801 23.4564 27.3047 22.8696 28.2164C21.1619 30.8712 20.0612 33.9515 19.7187 37.0873C19.4215 39.8101 20.0234 42.5605 22.3759 44.2052C24.2977 45.5477 27.3227 46.5149 29.597 45.5855C32.7782 44.2833 32.1359 39.9914 31.7329 37.2737Z'
      fill={selected ? '#85CCD5' : '#C2E599'}
    />
    <Path
      d='M14.9227 12.7285C17.25 15.6376 20.1944 18.0001 23.509 19.6927C23.645 19.7607 23.7785 19.801 23.907 19.8161C24.6978 20.2972 25.6197 20.6196 26.3854 19.8312C27.1939 19.0001 27.0906 17.179 27.1385 16.096C27.2795 12.9426 26.6801 9.72869 25.3955 6.84477C24.2823 4.34117 22.3781 2.26827 19.5169 2.03151C17.182 1.83757 14.079 2.52518 12.5828 4.47215C10.4898 7.19991 13.205 10.5825 14.9202 12.7285H14.9227Z'
      fill={selected ? '#85CCD5' : '#C2E599'}
    />
    <Path
      d='M38.3749 5.07881C35.1485 4.02851 31.7784 6.34068 29.7761 8.69316C27.1113 11.8239 25.8872 15.7682 24.7487 19.6445C24.4112 20.793 25.8166 21.4026 26.5622 20.8182C26.6075 20.8107 26.6554 20.8031 26.7007 20.788C30.6148 19.5916 34.5339 18.4154 38.4404 17.1963C40.9843 16.4004 44.0572 15.0202 44.1277 11.9121C44.1957 8.85686 41.0901 5.9654 38.3699 5.07881H38.3749Z'
      fill={selected ? '#3BA5B5' : '#FCB097'}
    />
  </Svg>
)
