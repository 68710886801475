import React, { useCallback } from 'react'
import { connect, ConnectedProps, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import { Map } from 'immutable'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import styled from 'styled-components'

import { LoadingIndicator, PlatformEngagement, useFetcher } from '@lyrahealth-inc/ui-core-crossplatform'

import { getPlatformEngagementMetrics, PEMetrics } from './data/platformEngagementActions'
import { getCurrentPlatformEngagementMetrics } from './data/platformEngagementSelectors'
import usePEQuarters from './hooks/usePEQuarters'
import { actions as mixpanelActions, mixpanelEvents } from '../../../../../mixpanel/mixpanelConstants'
import { track } from '../../../../../mixpanel/mixpanelTracking'
import { BC_PLATFORM_ENGAGEMENT_QUARTERLY_DETAILS } from '../../../common/constants/routingConstants'
import { getAuthUserId } from '../../../data/auth/authSelectors'

const Container = styled.div({
  display: 'flex',
  justifyContent: 'center',
})

type PlatformEngagementContainerProps = ConnectedProps<typeof connector>

const PlatformEngagementContainer: React.FunctionComponent<PlatformEngagementContainerProps> = ({
  currentYearMetrics,
  actions: { getPlatformEngagementMetrics },
}) => {
  const userId: string = useSelector(getAuthUserId)
  const navigate = useNavigate()

  const peQuarters = usePEQuarters()

  const onLearnMoreClicked = useCallback(() => {
    track({
      event: mixpanelEvents.BUTTON_PRESS,
      action: mixpanelActions.LEARN_MORE,
    })
    window.open(
      'https://bct-lyrahealth.zendesk.com/hc/en-us/articles/10189970197651-Platform-Engagement-Metric',
      '_blank',
    )
  }, [])

  const onViewQuarterlyDetailsClicked = useCallback(() => {
    track({
      event: mixpanelEvents.BUTTON_PRESS,
      action: mixpanelActions.VIEW_QUARTERLY_DETAILS,
    })
    navigate(BC_PLATFORM_ENGAGEMENT_QUARTERLY_DETAILS.route)
  }, [navigate])

  const [loading] = useFetcher([[getPlatformEngagementMetrics, { providerId: userId, data: peQuarters }]], [userId])
  const currentYearMetricsYTD = currentYearMetrics?.ytd
  return (
    <>
      {loading && !currentYearMetrics && <LoadingIndicator />}
      <Container>
        {currentYearMetricsYTD && (
          <PlatformEngagement
            assignmentRate={currentYearMetricsYTD.assignment_rate}
            feedbackRate={currentYearMetricsYTD.feedback_rate}
            onLearnMoreClicked={onLearnMoreClicked}
            onViewQuarterlyDetailsClicked={onViewQuarterlyDetailsClicked}
          />
        )}
      </Container>
    </>
  )
}

type StateProps = {
  currentYearMetrics?: PEMetrics
}

const mapStateToProps = (state: Map<string, any>): StateProps => ({
  currentYearMetrics: getCurrentPlatformEngagementMetrics(state),
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  actions: bindActionCreators({ getPlatformEngagementMetrics }, dispatch),
})
const connector = connect(mapStateToProps, mapDispatchToProps)
export default connector(PlatformEngagementContainer)
