import React from 'react'
import CSSModules from 'react-css-modules'
import styles from './paymentAdditionalInfoContent.module.scss'
import { PrimaryButton, TextButton } from '@lyrahealth-inc/ui-core'

const PaymentAdditionalInfoContent: React.FC<PaymentAdditionalInfoContentProps> = ({
  closeModal,
  title,
  description,
  url,
}) => {
  return (
    <div styleName='container' data-test-id='PaymentAdditionalInfoContent-container'>
      <h3 styleName='modal-title' data-test-id='PaymentAdditionalInfoContent-title'>
        {title}
      </h3>
      <p styleName='description'>{description}</p>
      <PrimaryButton
        data-test-id='PaymentAdditionalInfoContent-learnHow'
        onClick={() => {
          window.open(url)
          closeModal()
        }}
      >
        Learn how
      </PrimaryButton>
      <TextButton
        isPrimary={true}
        customClass={styles['dismiss-button']}
        data-test-id='PaymentAdditionalInfoContent-dismiss'
        text='No thanks'
        onClick={closeModal}
      />
    </div>
  )
}

interface PaymentAdditionalInfoContentProps {
  closeModal: () => void
  title: string
  description: string
  url: string
}

export default CSSModules(PaymentAdditionalInfoContent, styles)
