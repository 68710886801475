import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { tID } from '../../utils/utils'

export const CopyIcon: FunctionComponent<CopyIconProps> = ({ size = 22, fillColor }) => {
  const { colors } = useTheme()
  return (
    <Svg width={size} height={size} viewBox={`0 0 19 22`} fill='none' testID={tID('CopyIcon')}>
      <Path
        fill={fillColor || colors.iconActive}
        fillRule='evenodd'
        d='M15 4h1c1.66 0 3 1.34 3 3v12c0 1.66-1.34 3-3 3H7c-1.66 0-3-1.34-3-3v-1H3c-1.66 0-3-1.34-3-3V3c0-1.66 1.34-3 3-3h9c1.66 0 3 1.34 3 3v1ZM3 16.5c-.83 0-1.5-.67-1.5-1.5V3c0-.83.67-1.5 1.5-1.5h9c.83 0 1.5.67 1.5 1.5v1H7C5.34 4 4 5.34 4 7v9.5H3ZM17.5 19c0 .83-.67 1.5-1.5 1.5H7c-.83 0-1.5-.67-1.5-1.5V7c0-.83.67-1.5 1.5-1.5h9c.83 0 1.5.67 1.5 1.5v12Z'
        clipRule='evenodd'
      />
    </Svg>
  )
}

export interface CopyIconProps {
  size?: number
  fillColor?: string
}
