import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { tID } from '../../utils/utils'

export const HamburgerMenuIcon: FunctionComponent<HamburgerMenuIconProps> = ({ size = 24, fillColor }) => {
  const { colors } = useTheme()

  return (
    <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill='none' testID={tID('HamburgerMenuIcon')}>
      <Path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4 8C3.44772 8 3 7.55228 3 7V7C3 6.44772 3.44772 6 4 6L20 6C20.5523 6 21 6.44772 21 7V7C21 7.55228 20.5523 8 20 8L4 8ZM3 12C3 12.5523 3.44772 13 4 13L20 13C20.5523 13 21 12.5523 21 12V12C21 11.4477 20.5523 11 20 11L4 11C3.44772 11 3 11.4477 3 12V12ZM3 17C3 17.5523 3.44772 18 4 18L20 18C20.5523 18 21 17.5523 21 17V17C21 16.4477 20.5523 16 20 16L4 16C3.44772 16 3 16.4477 3 17V17Z'
        fill={fillColor || colors.iconDefault}
      />
    </Svg>
  )
}

export interface HamburgerMenuIconProps {
  size?: number
  fillColor?: string
}
