import React, { FunctionComponent } from 'react'
import Svg, { G, Mask, Path } from 'react-native-svg'

export const ControlledMedsSecondaryRec: FunctionComponent<ControlledMedsSecondaryRecProps> = ({
  width = 80,
  height = 80,
}) => {
  return (
    <Svg width={width} height={height} viewBox='0 0 80 80' fill='none'>
      <Path
        d='M40.2812 0.300781C34.7592 0.300781 29.4015 1.24773 24.3569 3.11476C12.3469 7.56654 3.9034 17.436 1.1906 30.1922C-0.602123 38.5092 -0.372894 46.2018 1.89568 53.6984C4.79186 63.2723 11.9011 71.3458 21.4007 75.8482C26.1164 78.0883 31.5341 79.3673 37.5004 79.6487C38.2671 79.6834 39.0465 79.7024 39.8212 79.7024C44.6081 79.7024 49.4235 79.0464 54.1345 77.7532C54.4965 77.6631 54.8412 77.5698 55.2048 77.4623C59.4384 76.2229 63.2483 74.2626 66.5081 71.6431C70.0477 68.8148 72.9613 65.2294 75.1587 60.9926C75.5919 60.1579 76.0061 59.2789 76.463 58.2276C78.9766 52.391 80.1907 45.8572 79.9757 39.325C79.8745 36.4367 79.4445 33.3666 78.7015 30.1922C77.4779 25.0417 75.4828 20.1978 72.9313 16.176C72.2325 15.0963 71.5907 14.1889 70.9551 13.3731C66.369 7.45746 59.2234 3.30605 50.2977 1.3742C47.0189 0.662804 43.6485 0.302362 40.2828 0.302362L40.2812 0.300781Z'
        fill='white'
      />
      <Path
        d='M76.5437 39.4318C76.4457 36.6637 76.022 33.7959 75.3549 30.9456C74.2641 26.3531 72.4713 21.8255 70.0131 17.9491C69.4424 17.0702 68.8574 16.2228 68.2219 15.4087C63.6121 9.46452 56.6261 6.1573 49.4758 4.60961C41.5113 2.88328 33.0424 3.38759 25.3862 6.22212C14.3105 10.3261 6.76968 19.3166 4.30982 30.8808C3.36445 35.2614 3.0878 40.2128 3.51148 44.9697H3.47828C3.72332 47.673 4.21181 50.3115 4.94376 52.7381C7.66447 61.7286 14.2757 68.8632 22.6971 72.8533C27.258 75.0192 32.3548 76.0942 37.5353 76.3392C42.8281 76.5842 48.2031 75.9155 53.1719 74.5481C53.4975 74.4674 53.8074 74.3852 54.1331 74.2872C58.0252 73.1474 61.413 71.3879 64.3124 69.0592C67.4726 66.5345 70.0779 63.3585 72.0983 59.4664C72.5377 58.619 72.9282 57.7733 73.3029 56.9085C75.6805 51.3865 76.7397 45.3933 76.5279 39.4492L76.5437 39.4334V39.4318Z'
        fill='#FFE9E3'
      />
      <Path
        d='M49.9848 61.8899H29.4428C28.0374 61.8899 26.8976 60.7501 26.8976 59.3447L25.9316 26.418L53.9228 28.1064L52.5285 59.3984C52.4984 60.7817 51.3681 61.8883 49.9848 61.8883V61.8899Z'
        fill='#FFC03C'
      />
      <Path
        d='M34.6109 49.1774C33.1861 47.9903 31.0688 48.183 29.8818 49.6078C28.6947 51.0325 28.8874 53.1498 30.3122 54.3369L35.5688 58.7165C36.9936 59.9036 39.1109 59.7109 40.2979 58.2862C41.485 56.8614 41.2923 54.7441 39.8676 53.5571L34.6109 49.1774Z'
        fill='#00A4B3'
      />
      <Path
        d='M40.2982 58.2853C39.111 59.7097 36.9942 59.9026 35.5682 58.7153L33.0625 56.6444L37.3609 51.4844L39.8666 53.5553C41.291 54.7426 41.4839 56.8594 40.2966 58.2853H40.2982Z'
        fill='#008295'
      />
      <Path
        d='M44.1262 48.5805L42.544 55.2371C42.1152 57.0413 43.2302 58.8515 45.0344 59.2804C46.8386 59.7092 48.6488 58.5942 49.0776 56.79L50.6598 50.1334C51.0886 48.3292 49.9736 46.5189 48.1694 46.0901C46.3652 45.6613 44.555 46.7763 44.1262 48.5805Z'
        fill='#00A4B3'
      />
      <Path
        d='M48.1724 46.0921C49.9762 46.5206 51.0923 48.3323 50.6623 50.1361L49.9225 53.301L43.3887 51.747L44.1285 48.582C44.5569 46.7783 46.3686 45.6621 48.1724 46.0921Z'
        fill='#008295'
      />
      <Mask id='mask0_4898_179385' maskUnits='userSpaceOnUse' x='25' y='26' width='29' height='36'>
        <Path
          d='M49.9848 61.8899H29.4428C28.0374 61.8899 26.8976 60.7501 26.8976 59.3447L25.9316 26.418L53.9228 28.1064L52.5285 59.3984C52.4984 60.7817 51.3681 61.8883 49.9848 61.8883V61.8899Z'
          fill='white'
        />
      </Mask>
      <G mask='url(#mask0_4898_179385)'>
        <Path
          d='M53.2311 46.9691L33.5823 46.7572C32.7381 46.743 32.0583 46.06 32.0488 45.2158L32.0646 34.2808C32.0552 33.4129 32.7555 32.7031 33.6234 32.7031L54.2887 32.8264L53.2311 46.9691Z'
          fill='white'
        />
        <Path d='M53.5858 36.1016H36.4395V37.9338H53.5858V36.1016Z' fill='#DCCDBF' />
        <Path d='M53.5858 40.1328H36.4395V41.807H53.5858V40.1328Z' fill='#DCCDBF' />
      </G>
      <Path
        d='M54.2928 23.9561L25.7135 23.7696C25.2488 23.7664 24.863 24.1253 24.8314 24.5885L24.5468 28.86C24.5136 29.3659 24.9152 29.7959 25.4227 29.7959H54.4556C54.9552 29.7959 55.3536 29.3801 55.333 28.8821L55.1639 24.7971C55.1449 24.3292 54.7608 23.9593 54.2928 23.9561Z'
        fill='#6ECBD3'
      />
      <Path
        d='M52.2972 20.6612L28.1286 19.7901C27.3698 19.7633 26.7043 20.2439 26.5699 20.9189L25.4648 26.4631H54.3888L53.7469 21.8611C53.6536 21.1955 53.0403 20.6881 52.2972 20.6612Z'
        fill='#6ECBD3'
      />
    </Svg>
  )
}

type ControlledMedsSecondaryRecProps = {
  width?: number
  height?: number
}
