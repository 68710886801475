import React from 'react'
import classNames from 'classnames'
import styles from './cardMedium.module.scss'
import CardUtilityBar from '../../cardUtilityBar/CardUtilityBar'

const CardMedium: React.FC<CardMediumProps> = ({
  children,
  progress,
  progressColor,
  backText,
  backFunc,
  withShadow,
  className,
  ...props
}) => {
  let renderUtilityBar = false
  if (progress || backText || backFunc) {
    renderUtilityBar = true
  }

  return (
    <div className='container'>
      <div
        className={classNames(
          className,
          styles.card,
          { [styles.progress]: renderUtilityBar },
          { [styles.shadow]: withShadow },
        )}
        {...props}
      >
        {renderUtilityBar ? (
          <CardUtilityBar progress={progress} progressColor={progressColor} backText={backText} backFunc={backFunc} />
        ) : (
          ''
        )}
        {children}
      </div>
    </div>
  )
}

type CardMediumProps = {
  children: any
  progress?: number
  progressColor?: string
  backText?: string
  backFunc?: () => void
  withShadow?: boolean
  className?: string
}

export default CardMedium
