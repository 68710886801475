import React, { FunctionComponent } from 'react'
import Svg, { ClipPath, Defs, G, Mask, Path, Rect } from 'react-native-svg'

type Props = {
  size?: number | string
}

export const SupportForSelfHarmSpotIllustration: FunctionComponent<Props> = ({ size = 124 }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 512 512' fill='none'>
      <G clip-path='url(#clip0_18566_6837)'>
        <Path
          d='M258.142 0C222.848 0 188.605 6.10628 156.362 18.1455C79.6006 46.8522 25.6341 110.484 8.29526 192.751C-3.16291 246.382 -1.6978 295.986 12.8017 344.327C31.3126 406.063 76.7512 458.124 137.467 487.157C167.608 501.602 202.235 509.849 240.368 511.664C245.269 511.888 250.25 512.01 255.201 512.01C285.797 512.01 316.574 507.78 346.685 499.441C348.999 498.86 351.201 498.258 353.525 497.565C380.585 489.573 404.936 476.932 425.77 460.041C448.394 441.803 467.016 418.683 481.061 391.363C483.829 385.98 486.477 380.312 489.397 373.533C505.462 335.896 513.222 293.764 511.848 251.642C511.201 233.017 508.453 213.22 503.704 192.751C495.884 159.538 483.132 128.303 466.824 102.369C462.358 95.4068 458.255 89.5554 454.194 84.2952C424.881 46.1488 379.21 19.379 322.162 6.92181C301.196 2.32426 279.654 0 258.142 0Z'
          fill='white'
        />
        <Mask
          id='mask0_18566_6837'
          mask-type='luminance'
          maskUnits='userSpaceOnUse'
          x='21'
          y='21'
          width='469'
          height='470'
        >
          <Path
            d='M489.913 252.335C489.286 234.485 486.578 215.993 482.314 197.613C475.342 167.999 463.884 138.803 448.172 113.807C444.524 108.139 440.786 102.675 436.724 97.4253C407.26 59.0954 362.61 37.7693 316.908 27.7892C266.003 16.6572 211.875 19.9091 162.941 38.1872C92.1506 64.6512 43.9536 122.625 28.2315 197.195C22.1891 225.443 20.4209 257.371 23.1288 288.045H22.9166C24.4828 305.477 27.605 322.491 32.2832 338.139C49.6726 396.113 91.9283 442.119 145.753 467.849C174.904 481.815 207.48 488.747 240.591 490.327C274.42 491.907 308.775 487.595 340.532 478.777C342.614 478.257 344.594 477.727 346.675 477.095C371.552 469.745 393.205 458.399 411.736 443.383C431.935 427.103 448.586 406.623 461.5 381.525C464.309 376.061 466.804 370.607 469.199 365.031C484.396 329.423 491.165 290.777 489.812 252.447L489.913 252.345V252.335Z'
            fill='white'
          />
        </Mask>
        <G mask='url(#mask0_18566_6837)'>
          <Path
            d='M255.929 512.01C397.275 512.01 511.859 397.393 511.859 256.005C511.859 114.617 397.275 0 255.929 0C114.583 0 0 114.617 0 256.005C0 397.393 114.583 512.01 255.929 512.01Z'
            fill='#FFE9E3'
          />
          <Path
            d='M276.431 511.184C276.431 511.184 252.1 413.321 236.165 410.11C220.231 406.899 176.692 419.213 159.161 397.785C141.63 376.357 142.166 334.561 142.166 334.561C142.166 334.561 120.391 332.95 118.27 312.063C116.148 291.175 145.864 272.255 146.925 255.995C147.986 239.735 139.256 186.44 186.241 147.05C228.456 111.656 300.095 87.8937 371.38 148.885C439.815 207.43 377.412 332.42 374.219 352.778C371.026 373.136 421.476 456.717 421.476 456.717C421.476 456.717 318.868 511.307 276.41 511.184H276.431Z'
            fill='#D9C7B9'
          />
          <Path
            d='M341.057 189.091C334.975 179.712 323.365 175.257 312.574 177.398C301.994 179.488 295.103 186.277 289.677 194.596C285.323 188.5 279.866 183.239 273.369 179.427C263.204 173.463 249.22 171.159 238.783 177.796C228.133 184.575 226.486 198.714 230.214 209.917C234.448 222.65 244.461 233.976 252.544 244.425C257.395 250.694 262.457 256.81 267.852 262.611C270.621 265.577 281.533 276.862 282.665 278.035C284.494 279.92 287.808 281.205 290.082 279.044C292.062 277.158 293.962 275.211 295.79 273.223C298.458 270.939 300.802 268.085 303.146 265.567C305.824 262.692 308.441 259.777 310.987 256.78C312.371 255.404 313.736 254.027 315.089 252.661C324.143 243.568 333.156 233.67 339.562 222.446C345.594 211.885 347.989 199.815 341.037 189.101L341.057 189.091Z'
            fill='#EB757D'
          />
        </G>
      </G>
      <Defs>
        <ClipPath id='clip0_18566_6837'>
          <Rect width='512' height='512' fill='white' />
        </ClipPath>
      </Defs>
    </Svg>
  )
}
