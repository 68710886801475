import React, { FunctionComponent } from 'react'

import styled from 'styled-components/native'

// TODO: Expo 51, move to Image component in ui-cc, gif is broken in Expo 50 for certain sizes
// eslint-disable-next-line no-restricted-properties
const ImageContainer = styled.Image<{ width: number; height: number }>(({ width, height }) => ({
  width: `${width}px`,
  height: `${height}px`,
}))

export const CheckEmailAnimation: FunctionComponent<CheckEmailAnimationProps> = ({ width = 172, height = 151 }) => {
  return (
    <ImageContainer
      dataSet={{ chromatic: 'ignore' }}
      source={require('../../assets/registration_check_email_animation.gif')}
      width={width}
      height={height}
      resizeMode='contain'
    />
  )
}

type CheckEmailAnimationProps = {
  width?: number
  height?: number
}
