import React from 'react'
import type { FunctionComponent } from 'react'
import { View } from 'react-native'

import { tID } from '../../utils'
import { Image } from '../image/Image'

type PrivacyIllustrationProps = {
  width?: number
  height?: number
  useSmall?: boolean
}

export const PrivacyIllustration: FunctionComponent<PrivacyIllustrationProps> = ({
  width = 322,
  height = 215,
  useSmall = false,
}) => {
  const size = useSmall ? { width: 278, height: 195 } : { width, height }
  return (
    <View>
      {/* eslint-disable-next-line react-native-a11y/has-valid-accessibility-ignores-invert-colors */}
      <Image
        source={require('../../assets/privacy.png')}
        testID={`${tID('PrivacyIllustration')}`}
        accessibilityLabel={'privacy'}
        style={size}
      />
    </View>
  )
}
