import React from 'react'
import classNames from 'classnames'
import styles from './cardSmall.module.scss'

const CardSmall: React.FC<CardSmallProps> = ({ children, withShadow, className, ...props }) => {
  return (
    <div className={classNames(className, styles.card, { [styles.shadow]: withShadow })} {...props}>
      {children}
    </div>
  )
}

type CardSmallProps = {
  children: any
  withShadow?: boolean
  className?: string
}

export default CardSmall
