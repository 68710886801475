export default {
  schema: {
    type: 'object',
    properties: {
      feedbackStarsLabel: {
        content: 'How would you rate the quality of this draft? *',
        name: 'feedbackStarsLabel',
      },
      FeedbackStars: {
        name: 'FeedbackStars',
        type: 'number',
        starLabels: ['Terrible', 'Bad', 'Okay', 'Good', 'Great'],
        backgroundColor: 'periwinkle',
        testID: 'SatisfactionSurvey',
        validation: {
          isRequired: true,
        },
      },
      wordCloudLabel: {
        content: 'Anything specific?',
        condition: {
          parentField: 'FeedbackStars',
          parentValue: [1, 2, 3, 4, 5],
        },
        name: 'wordCloudLabel',
      },
      wordCloudSelection1to3stars: {
        name: 'wordCloudSelection1to3stars',
        type: 'string',
        enum: ['Inaccurate', 'Not specific', 'Strange tone'],
        condition: {
          parentField: 'FeedbackStars',
          parentValue: [1, 2],
        },
        modifier: 'periwinkle',
      },
      wordCloudSelection4to5stars: {
        name: 'wordCloudSelection4to5stars',
        type: 'string',
        enum: ['Accurate', 'Comprehensive', 'Appropriate tone'],
        condition: {
          parentField: 'FeedbackStars',
          parentValue: [3, 4, 5],
        },
        modifier: 'periwinkle',
      },
      tellUsMore: {
        name: 'tellUsMore',
        type: 'string',
        title: 'Tell us more',
        placeholder: 'Your feedback',
        numberOfLines: 1,
        condition: {
          parentField: 'FeedbackStars',
          parentValue: [1, 2, 3, 4, 5],
        },
      },
    },
  },
  uiSchema: {
    'ui:order': [
      'feedbackStarsLabel',
      'FeedbackStars',
      'wordCloudLabel',
      'wordCloudSelection1to3stars',
      'wordCloudSelection4to5stars',
      'tellUsMore',
    ],
    FeedbackStars: {
      'ui:field': 'FeedbackStars',
    },
    wordCloudSelection1to3stars: {
      'ui:widget': 'wordCloud',
    },
    wordCloudSelection4to5stars: {
      'ui:widget': 'wordCloud',
    },
    tellUsMore: {
      'ui:widget': 'textarea',
    },
  },
}
