import React from 'react'
import colors from '../../../styles/_0colors.scss'

const ExclamationCircleIcon: React.FC<ExclamationCircleIconProps> = ({
  fillColor = colors.x_warning,
  width = 20,
  ...props
}) => {
  return (
    <svg width={width} height={width} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 10C0 4.48 4.48 0 10 0C15.52 0 20 4.48 20 10C20 15.52 15.52 20 10 20C4.48 20 0 15.52 0 10ZM9.25 5.5C9.25 5.09 9.59 4.75 10 4.75C10.41 4.75 10.75 5.09 10.75 5.5V10.5C10.75 10.91 10.41 11.25 10 11.25C9.59 11.25 9.25 10.91 9.25 10.5V5.5ZM10 15C9.45 15 9 14.55 9 14C9 13.45 9.45 13 10 13C10.55 13 11 13.45 11 14C11 14.55 10.55 15 10 15Z'
        fill={fillColor}
      />
    </svg>
  )
}

type ExclamationCircleIconProps = {
  fillColor?: string
  width?: number
}

export default ExclamationCircleIcon
