import { createReducer } from '@reduxjs/toolkit'

import { ProviderSelfHarmTask } from '@lyrahealth-inc/shared-app-logic'

import { GET_PROVIDER_TASK, PATCH_PROVIDER_TASK } from '../../../common/constants/reduxConstants'

type ProvidersDashboardState = {
  providerTasks?: ProviderSelfHarmTask[]
}

const initialState = {}

export default createReducer<ProvidersDashboardState>(initialState, (builder) => {
  builder.addCase(GET_PROVIDER_TASK, (state, action: any) => {
    state.providerTasks = action.data
    return state
  })
  builder.addCase(PATCH_PROVIDER_TASK, (state, action: any) => {
    const updatedTask = action.data
    const updatedTasks = state.providerTasks?.map((task) => {
      if (task.id === action.data.id) return updatedTask
      return task
    })
    state.providerTasks = updatedTasks
    return state
  })
})
