import React, { FunctionComponent } from 'react'
import { Path, Svg } from 'react-native-svg'

import { TriageScreenerValue } from '@lyrahealth-inc/shared-app-logic'

import { tID } from '../../utils'

type ContactCNIllustrationProps = {
  size?: string | number
  selected?: string
}

export const ContactCNIllustration: FunctionComponent<ContactCNIllustrationProps> = ({ selected = false, size }) => (
  <Svg
    width={size}
    height={size}
    viewBox='0 0 48 48'
    fill='none'
    testID={tID(`${TriageScreenerValue.IM_NOT_SURE}-Child${selected ? '-Selected-' : '-'}Illustration`)}
  >
    <Path
      d='M24.1577 17.577C23.9398 17.7723 23.6041 17.7559 23.4073 17.5395C22.2218 16.2415 18.2644 12.5335 15.1724 16.9681C11.6307 22.0481 20.9122 31.3454 23.4882 32.8826C23.6482 32.9785 23.846 32.9849 24.0115 32.899C25.2779 32.2436 30.9515 29.0286 33.5698 22.8322C36.5229 15.8435 30.4291 14.2213 28.3294 14.8512C26.812 15.3067 24.9579 16.8586 24.1577 17.576V17.577Z'
      fill={selected ? '#85CCD5' : '#FFAF9B'}
    />
    <Path
      d='M40.2576 33.0099L37.8821 32.1614C37.9329 32.0071 42.9837 16.5602 37.2124 8.11704C34.7405 4.50031 30.5347 2.64374 24.7125 2.5964C18.829 2.55082 14.4391 4.39162 11.6621 8.07496C5.27894 16.5374 9.08326 32.1035 9.12183 32.2595L6.69022 32.9135C6.64639 32.7416 5.63308 28.6568 5.46478 23.3834C5.36485 20.2645 5.58574 17.3754 6.12045 14.7965C6.80067 11.5094 7.99806 8.71135 9.67582 6.48487C12.9191 2.18091 17.9016 0 24.4881 0C24.5687 0 24.6494 0 24.7318 0C31.413 0.0543473 36.3025 2.28259 39.2688 6.62336C40.8046 8.87089 41.8039 11.6812 42.2386 14.9753C42.5788 17.5507 42.5788 20.4328 42.2351 23.5394C41.6566 28.7866 40.3154 32.8416 40.2576 33.0116V33.0099Z'
      fill='#87CDD6'
    />
    <Path
      d='M10.3616 22.8581C9.92677 20.8805 9.18344 18.7452 7.81249 17.3041C6.42049 15.8438 4.40964 15.4037 2.70384 16.5135C0.920892 17.674 0.146004 19.8304 0.0250378 22.006C-0.0941757 24.1624 0.219637 26.5116 0.810445 28.5698C1.38022 30.5561 2.36022 32.737 4.08531 33.8047C4.9461 34.3376 5.85423 34.4358 6.66944 34.1834C6.67821 34.1834 6.68697 34.1834 6.69749 34.1834C8.9082 34.1553 10.2301 31.906 10.5772 29.7654C10.9471 27.4793 10.8577 25.1126 10.3616 22.8581Z'
      fill='#3BA5B5'
    />
    <Path
      d='M47.1878 18.4952C46.3147 16.6527 44.5423 15.578 42.5858 16.0706C40.5381 16.5878 39.1444 18.3777 38.3625 20.4026C37.5876 22.4082 37.1686 24.7399 37.0984 26.8857C37.0301 28.9562 37.293 31.3457 38.6009 32.9235C38.8393 33.2093 39.1005 33.4425 39.3775 33.6266C38.8323 36.1563 37.7436 38.5511 35.7906 40.2604C34.5757 41.3246 33.0925 41.8418 31.541 42.1065C30.6872 42.252 29.8212 42.3484 28.9551 42.4273C28.8604 42.2976 28.7465 42.1731 28.6115 42.0557C28.4011 41.8716 28.1802 41.7033 27.9523 41.5472C27.8611 41.4123 27.7419 41.2983 27.5912 41.2247C25.9678 40.4287 23.9341 39.9028 22.1705 40.5164C20.4138 41.1282 19.3128 42.8937 20.1263 44.759C20.8609 46.4473 22.77 47.28 24.4811 47.2046C26.1553 47.131 27.7735 46.3298 28.8026 44.9518C28.8359 44.9063 28.8692 44.8589 28.899 44.8133C29.5372 44.752 30.1753 44.6871 30.8117 44.6117C32.4298 44.4171 34.0129 44.1051 35.4785 43.3337C38.0714 41.9698 39.8719 39.4312 40.9115 36.6805C41.2078 35.8986 41.434 35.0957 41.6093 34.2805C43.4588 34.4821 45.112 32.9393 45.9956 31.2195C47.0475 29.1736 47.6874 26.898 47.9066 24.5979C48.1012 22.58 48.0486 20.3097 47.1895 18.4952H47.1878Z'
      fill='#8CD0D8'
    />
    <Path
      d='M47.9059 24.6004C47.6851 26.9006 47.0452 29.1761 45.9933 31.2221C45.1658 32.8332 43.6598 34.2936 41.9488 34.2988C41.4824 34.2094 40.981 34.1112 40.4604 34.0078C39.9712 33.9096 39.5172 33.7027 39.1052 33.4293C39.1017 33.4275 39.0982 33.4257 39.0964 33.424C38.9229 33.2802 38.7563 33.1154 38.6003 32.9261C37.2907 31.35 37.0295 28.9587 37.0978 26.8883C37.168 24.7424 37.5887 22.4125 38.3619 20.4052C39.1438 18.3803 40.5393 16.5903 42.5852 16.0732C44.5417 15.5788 46.3159 16.6552 47.1889 18.4978C48.048 20.3123 48.0988 22.5808 47.9059 24.6004Z'
      fill='#3BA5B5'
    />
    <Path
      d='M28.9725 44.8069C28.9479 44.8069 28.9234 44.8121 28.8988 44.8139C28.8673 44.8594 28.8357 44.9068 28.8007 44.9524C27.7716 46.3303 26.1534 47.1315 24.4792 47.2051C22.7699 47.2823 20.8607 46.4478 20.1244 44.7595C19.3127 42.8942 20.4136 41.1288 22.1703 40.5169C23.9339 39.9016 25.9693 40.4293 27.591 41.2252C27.7418 41.2988 27.861 41.411 27.9521 41.5478C28.18 41.7038 28.4009 41.8721 28.6113 42.0562C28.6902 42.1245 28.7621 42.1964 28.827 42.2683C28.827 42.2683 28.827 42.2695 28.827 42.2718C29.0303 42.6505 29.1899 43.1098 29.2389 43.6498C29.274 44.0407 29.1653 44.4317 28.9707 44.8069H28.9725Z'
      fill='#3BA5B5'
    />
  </Svg>
)
