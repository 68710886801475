import React, { FunctionComponent } from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'

import { useTheme } from 'styled-components/native'

import { tID } from '../../utils'
import { BodyText, Size } from '../bodyText/BodyText'
import { ArrowIcon, ArrowIconDirection } from '../icons/ArrowIcon'

export type NewMessageIndicatorProp = {
  onPress?: () => void
  plural?: boolean
}

/**
 * This component is an indicator representing new messages are available
 * in the chat
 */
export const NewMessageIndicator: FunctionComponent<NewMessageIndicatorProp> = ({ onPress, plural = false }) => {
  const { colors } = useTheme()
  const { newMessageIndicator } = colors.components

  const styles = StyleSheet.create({
    newMessageIndicator: {
      flexDirection: 'row',
      backgroundColor: colors.backgroundPrimary,
      paddingVertical: 8,
      paddingHorizontal: 24,
      borderRadius: 64,
      flexWrap: 'nowrap',
      alignContent: 'center',
      shadowColor: colors.shadowLow,
      // TODO: Clean up, standardize box shadows with UX, rough estimate for now
      shadowOffset: {
        width: 0,
        height: 3,
      },
      shadowOpacity: 0.27,
      shadowRadius: 4.65,
      elevation: 6,
      alignItems: 'center',
      justifyContent: 'center',
    },
    arrowIcon: {
      marginLeft: 5,
    },
  })

  return (
    <TouchableOpacity testID={tID('NewMessageIndicator')} style={styles.newMessageIndicator} onPress={onPress}>
      <BodyText size={Size.SMALL} text={plural ? 'New messages' : 'New message'} />
      <View style={styles.arrowIcon}>
        <ArrowIcon fillColor={newMessageIndicator.arrow.fill} size={16} direction={ArrowIconDirection.DOWN} />
      </View>
    </TouchableOpacity>
  )
}
