import React, { FunctionComponent } from 'react'
import { Path, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

export const ExternalLinkIcon: FunctionComponent<ExternalLinkIconProps> = ({ size = 24, strokeColor }) => {
  const { colors } = useTheme()

  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' fill='none'>
      <Path
        d='M18.0001 12.72V18.48C18.0001 18.9892 17.7978 19.4776 17.4377 19.8376C17.0777 20.1977 16.5893 20.4 16.0801 20.4H5.5201C5.01088 20.4 4.52252 20.1977 4.16245 19.8376C3.80238 19.4776 3.6001 18.9892 3.6001 18.48V7.92C3.6001 7.41078 3.80238 6.92242 4.16245 6.56235C4.52252 6.20229 5.01088 6 5.5201 6H11.2801'
        stroke={strokeColor || colors.iconDefault}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <Path
        d='M14.4004 3.59998H20.4004V9.59998'
        stroke={strokeColor || colors.iconDefault}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <Path
        d='M10.8003 13.2C15.0961 8.90421 16.1045 7.89574 20.4003 3.59998'
        stroke={strokeColor || colors.iconDefault}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </Svg>
  )
}

type ExternalLinkIconProps = {
  size?: string | number
  strokeColor?: string
}
