import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

export const VolumeUpIcon: React.FC<VolumeUpIconProps> = ({ color, width = 16, height = 16, isFilled = true }) => {
  const { colors } = useTheme()

  return (
    <Svg width={width} height={height} viewBox='0 0 12 12' fill='none'>
      {isFilled ? (
        <Path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M7.80142 1.80377C7.52938 1.69271 7.33333 1.44382 7.33333 1.1564V1.11619C7.33333 0.699389 7.7323 0.389491 8.13214 0.537535C10.3942 1.37508 12 3.50437 12 6C12 8.49564 10.3942 10.6249 8.13214 11.4625C7.7323 11.6105 7.33333 11.3006 7.33333 10.8838V10.8436C7.33333 10.5562 7.52938 10.3073 7.80142 10.1962C9.48363 9.50948 10.6667 7.8886 10.6667 6C10.6667 4.1114 9.48363 2.49052 7.80142 1.80377ZM0.666667 4.05106C0.298477 4.05106 0 4.34192 0 4.70071V7.29929C0 7.65808 0.298477 7.94894 0.666667 7.94894H2.39052C2.56734 7.94894 2.7369 8.01738 2.86193 8.13922L4.86193 10.0882C5.28191 10.4974 6 10.2076 6 9.62879V2.37122C6 1.79244 5.28191 1.50259 4.86193 1.91185L2.86193 3.86079C2.7369 3.98262 2.56734 4.05106 2.39052 4.05106H0.666667ZM9 6C9 5.22786 8.69338 4.52895 8.19318 4.00753C7.85474 3.65474 7.33333 3.95014 7.33333 4.43308V7.56677C7.33333 8.04817 7.85205 8.34385 8.19055 7.99323C8.69231 7.47349 9 6.77349 9 6Z'
          fill={color ?? colors.iconInactive}
        />
      ) : (
        <Path
          fill={color ?? colors.iconInactive}
          fillRule='evenodd'
          d='M7.371 2.5a.373.373 0 0 1-.223-.48.373.373 0 0 1 .48-.223 5.373 5.373 0 0 1 1.488.918c.939.808 1.505 1.863 1.505 3.176 0 1.972-1.004 3.433-2.965 4.332a.374.374 0 0 1-.313-.68c1.704-.781 2.528-1.98 2.528-3.652 0-1.075-.46-1.934-1.242-2.61a4.79 4.79 0 0 0-.875-.601 4.151 4.151 0 0 0-.383-.18ZM1.523 7.29c0 .206.168.374.375.374h1.536l1.996 2c.238.238.64.07.64-.266v-6.75c0-.332-.402-.5-.64-.261L3.434 4.383H1.898a.375.375 0 0 0-.375.375Zm2.063-.376H2.273V5.133h1.313c.102 0 .195-.04.266-.11L5.32 3.555v4.941L3.852 7.027a.376.376 0 0 0-.266-.113Zm3.578-2.473a.374.374 0 0 0 .172.504c.05.024.152.094.254.215.18.207.285.469.285.813 0 .535-.188.898-.57 1.132a.375.375 0 0 0 .39.641c.617-.379.93-.984.93-1.773 0-.532-.176-.965-.465-1.301a1.637 1.637 0 0 0-.496-.402.373.373 0 0 0-.5.171Zm0 0'
          clipRule='evenodd'
        />
      )}
    </Svg>
  )
}
type VolumeUpIconProps = {
  width?: string | number
  height?: string | number
  color?: string
  isFilled?: boolean
}
