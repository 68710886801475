import React, { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'

import { useTheme } from 'styled-components/native'

import { VideoControlButton } from './VideoControlButton'
import { tID } from '../../utils'
import { GearIcon } from '../icons'

export type SettingsVideoControlButtonProps = {
  disabled: boolean
  onPress: () => void
  enabledColor?: string
  disabledColor?: string
  iconColor?: string
  size?: number
}

export const SettingsVideoControlButton: FunctionComponent<SettingsVideoControlButtonProps> = ({
  disabled,
  enabledColor,
  disabledColor,
  iconColor,
  onPress,
  size = 64,
}) => {
  const { formatMessage } = useIntl()
  const { colors } = useTheme()
  const { videoControlButton } = colors.components
  const iconSize = size / 2.6

  return (
    <VideoControlButton
      enabledColor={enabledColor || videoControlButton.enabledColor.fill}
      enabledIcon={<GearIcon color={iconColor || colors.iconInverse} size={iconSize} />}
      disabledColor={disabledColor || videoControlButton.disabledColor.fill}
      disabledIcon={<GearIcon color={iconColor || colors.iconInverse} size={iconSize} />}
      onPress={onPress}
      disabled={disabled}
      testID={tID('settings')}
      size={size}
      accessibilityLabel={formatMessage({
        defaultMessage: 'Settings',
        description: 'Button to open settings menu',
      })}
    />
  )
}
