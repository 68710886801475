import React, { FunctionComponent } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { StyleSheet, View } from 'react-native'

import styled from 'styled-components/native'

import { Subhead } from '../../atoms'
import { BodyText, Size } from '../../atoms/bodyText/BodyText'
import { Avatar, avatarAltTextGeneric, AvatarDetails } from '../../atoms/icons/Avatar'
import { SubheadSize } from '../../styles'
import { ThemeType, tID } from '../../utils'
import { getEmptyMessageContent } from '../../utils/utils'

export type EmptyMessagesViewProps = {
  avatarDetails?: AvatarDetails
  displayName?: string
}

const NoPreviousMessageContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  backgroundColor: theme.colors.backgroundPrimary,
  color: `${theme.colors.textSecondary}`,
  flexDirection: 'column',
  flexGrow: 1,
  justifyContent: 'center',
  alignItems: 'center',
  padding: `${theme.spacing['12px']} ${theme.spacing['24px']}`,
}))

export const EmptyMessagesView: FunctionComponent<EmptyMessagesViewProps> = ({ avatarDetails, displayName }) => {
  const { formatMessage } = useIntl()
  return (
    <View style={styles.container} testID={tID('EmptyMessages')}>
      {avatarDetails && displayName ? (
        <>
          <Avatar
            size={80}
            details={avatarDetails}
            accessibilityLabel={formatMessage(avatarAltTextGeneric, { name: displayName })}
          />
          <BodyText text={getEmptyMessageContent(displayName)} size={Size.SMALL} style={styles.text} />
        </>
      ) : (
        <NoPreviousMessageContainer testID={tID('EmptyMessages-noPreviousMsgs')}>
          <Subhead
            text={
              <FormattedMessage
                defaultMessage='No messages'
                description='A heading that says there are no messages in the chat'
              />
            }
            size={SubheadSize.SMALL}
          />
          <FormattedMessage
            defaultMessage='Start typing below to start a conversation'
            description='A message to let the chat viewer know there were no previous messages'
          />
        </NoPreviousMessageContainer>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  text: {
    textAlign: 'center',
    marginTop: 15,
  },
})
