import React, { FunctionComponent } from 'react'

import styled from 'styled-components/native'

import { tID } from '../../utils'
import { Image } from '../image/Image'

const ImageContainer = styled(Image)<{ size: string }>(({ size }) => ({
  width: size,
  height: size,
}))

export const FAQIllustrationIcon: FunctionComponent<FAQIllustrationIconProps> = ({ size = 48 }) => {
  const sizeInPixels = `${size}px`
  return (
    <ImageContainer
      size={sizeInPixels}
      testID={`${tID('FAQIllustrationIcon')}`}
      source={require('../../assets/faq_illustration_icon.png')}
    />
  )
}

type FAQIllustrationIconProps = {
  size?: number
}
