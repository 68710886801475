import React, { FunctionComponent } from 'react'
import { ImageSourcePropType } from 'react-native'

import styled from 'styled-components/native'

import childProfilesUrl from '../../assets/childProfilesPeriwinkle.png'
import { tID } from '../../utils'
import { Image } from '../image/Image'

type ChildProfilesIllustrationProps = {
  width?: number
}

const ImageContainer = styled(Image)<{ imageHeight?: string; imageWidth: string }>(({ imageHeight, imageWidth }) => ({
  height: imageHeight,
  width: imageWidth,
}))

/**
 * Periwinkle background. There's an image with oatmeal background 'assets/childProfiles.png' but no component for it
 */
export const ChildProfilesIllustration: FunctionComponent<ChildProfilesIllustrationProps> = ({ width = 219 }) => {
  const heightInPixels = `${Math.floor(width / 1.39)}px`
  const widthInPixels = `${width}px`
  return (
    <ImageContainer
      imageHeight={heightInPixels}
      imageWidth={widthInPixels}
      testID={`${tID('ChildProfilesIllustration')}`}
      source={childProfilesUrl as ImageSourcePropType}
    />
  )
}
