import { useEffect, useRef } from 'react'

/** @see {@tutorial https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state} */
export function usePrevious<T>(value: T): T {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current as T
}
