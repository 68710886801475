import React, { FunctionComponent, useEffect } from 'react'
import { useIntl } from 'react-intl'

import { ValidationErrors } from 'final-form'
import styled, { useTheme } from 'styled-components/native'

import { BodyText, Size } from '../../atoms/bodyText/BodyText'
import { WarningIcon } from '../../atoms/icons/WarningIcon'
import { useAccessibilityFocus } from '../../hooks/useAccessibilityFocus'
import { ThemeType, tID } from '../../utils'

const FormErrorContainer = styled.View<{ theme: ThemeType }>(({ theme: { colors } }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  backgroundColor: colors.components.formErrorBanner.background,
  padding: '13px 20px',
  borderRadius: '4px',
}))

const IconContainer = styled.View`
  margin-right: 8px;
`

export type FormErrorBannerProps = {
  validationErrors: ValidationErrors
  customErrorMessage?: string
}

export const FormErrorBanner: FunctionComponent<FormErrorBannerProps> = ({ validationErrors, customErrorMessage }) => {
  /* this delay is needed but also not that useful because sometimes the voiceover will read something really
  long and get cut off mid-sentence to focus on the red error banner. Not sure what the best solution is but
  adding a delay of 1000ms does cover most cases */
  const [errorRef, setFocus] = useAccessibilityFocus({ delay: 500 })
  const { formatMessage } = useIntl()
  const { colors } = useTheme()

  const getKeys = (validationErrors: ValidationErrors) => {
    const keys: string[] = []
    if (!validationErrors) {
      return keys
    }
    Object.keys(validationErrors).map((key) => {
      if (typeof validationErrors[key] === 'object') {
        getKeys(validationErrors[key]).forEach((key) => keys.push(key))
      } else {
        keys.push(key)
      }
    })
    return keys
  }

  const errorFields = getKeys(validationErrors)
  const errorFieldsString = errorFields.toString()
  const numberOfErrors = errorFields.length

  useEffect(() => {
    setFocus()
  }, [setFocus])

  return (
    <FormErrorContainer testID={tID('formErrorBanner')} ref={errorRef}>
      <IconContainer testID={tID('AlertLoudIcon')}>
        <WarningIcon size={16} fillColor={colors.iconInverse} />
      </IconContainer>
      <BodyText
        text={
          customErrorMessage ??
          formatMessage(
            {
              defaultMessage:
                'Complete {numberOfErrors} {numberOfErrors, plural, one {required item} other {required items}} to continue',
              description:
                'Error message to designate how many required items have to be completed in the form to continue',
            },
            {
              numberOfErrors,
            },
          )
        }
        size={Size.SMALL}
        color={colors.textInverse}
        accessibilityLabel={
          numberOfErrors > 1
            ? formatMessage(
                {
                  defaultMessage: `Complete multiple required fields to continue: {errorFieldsString}`,
                  description: 'Specifies which fields are required',
                },
                { errorFieldsString },
              )
            : formatMessage(
                {
                  defaultMessage: `Complete one required field to continue: {errorFieldsString}`,
                  description: 'Specifies which field is required',
                },
                { errorFieldsString },
              )
        }
      />
    </FormErrorContainer>
  )
}
