import { STSTypes } from '../../../../mixpanel/mixpanelConstants'
import { track } from '../../../../mixpanel/mixpanelTracking'
import {
  actionAlertHandler,
  actionStyles,
  CLEAR_LYRA_CODE_VALIDATION_INFO,
  CLEAR_REQUEST_PAYMENT_STORE,
  CLOSE_CANCELLED_SESSIONS_EXCEEDED_MODAL,
  CLOSE_DUPLICATE_PAYMENT_MODAL,
  CLOSE_INELIGIBLE_CLIENT_MODAL,
  CLOSE_OUTCOME_REMINDER_MODAL,
  CLOSE_SESSIONS_EXCEEDED_MODAL,
  CLOSE_SESSIONS_MODAL,
  DISPLAY_INELIGIBLE_CLIENT_MODAL,
  GET_CHARGE_TO_COPY,
  GET_CUSTOMERS,
  GET_PRACTICE_PROVIDERS,
  GET_PROVIDER_RATES,
  SELECT_PROVIDER,
  SET_ELIGIBILITY_STATUS,
  SET_LYRA_CODE_VALIDATION_INFO,
  SHOW_CANCELLED_SESSIONS_EXCEEDED_MODAL,
  SHOW_DUPLICATE_PAYMENT_MODAL,
  SUBMIT_PAYMENT_REQUEST,
} from '../../common/constants/reduxConstants'
import { getChargeToCopyData, submitRequestPaymentForm } from '../../common/http/data/payments'
import { getPracticeProvidersData } from '../../common/http/data/practices'
import { logToSumoLogic } from '../../common/utils/utils'

export const getProviderRates = ({ id, cancelToken }: any) => ({
  action: GET_PROVIDER_RATES,
  request: {
    method: 'get',
    url: `/v1/providers/${id}/rates`,
    cancelToken,
  },
})

enum PaymentRequestError {
  DefaultProviderPaymentError = 'default_provider_payment_error',
  HealthPlanMembershipID = 'health_plan_membership_id',
  SessionLimitExceeded = 'session_limit_exceeded',
  PastNinetyDays = 'past_ninety_days',
}

type PaymentRequestErrorMap = {
  [key in PaymentRequestError]?: string
}

type PaymentRequestErrors = {
  bcx: PaymentRequestErrorMap
  da: PaymentRequestErrorMap
}

const paymentRequestErrors: PaymentRequestErrors = {
  bcx: {
    [PaymentRequestError.DefaultProviderPaymentError]:
      'You are unable to close this session due to a client side issue related to the client’s health plan. Please report the error and select “Closing Session or Episode - Health Plan Issue” and include the client’s name. DO NOT SELECT the session closure option "Yes, but the client is ineligible to continue with care.” until you have received confirmation from Platform Support.',
    [PaymentRequestError.HealthPlanMembershipID]:
      'You are unable to close this session due to an issue with your client’s health plan information. Please report the error and select “Closing Session or Episode - Health Plan Issue” and include the client’s name. DO NOT SELECT the session closure option "Yes, but the client is ineligible to continue with care.” until you have received direction from Platform Support.',
    [PaymentRequestError.SessionLimitExceeded]:
      'You are unable to close this session because the client has exceeded their EAP limit. Please report the error and select “Closing Session or Episode - Cannot Close Session” and include the client’s name. DO NOT SELECT the session closure option "Yes, but the client is ineligible to continue with care.” until you have received direction from Platform Support.',
  },
  da: {
    [PaymentRequestError.DefaultProviderPaymentError]:
      'Error. This session cannot be submitted due to a client side issue related to their health plan, Please report the error and select “Submitting Session - Health Plan Issue” and include the client’s name.',
    [PaymentRequestError.HealthPlanMembershipID]:
      'Error due to an issue with your client’s health plan information. Instruct your client to review their health plan information as entered in their profile, and to contact the care team via care@lyrahealth.com. Report the issue and include “Submitting Session - Health Plan Issue” and include the client’s name and a copy of this error message.',
    [PaymentRequestError.SessionLimitExceeded]:
      'Error. Your client is beyond their sponsoring company’s EAP session limit. If you believe this is incorrect, report this error and include the client’s name, date of birth, sponsoring company and a copy of this error message.',
    [PaymentRequestError.PastNinetyDays]: 'Error. Visit date must not be more than ninety days before present day.',
  },
}

export const submitPaymentRequest = (data: any, isBCx: boolean) => {
  const request = submitRequestPaymentForm(data)

  return (dispatch: any, getState: any) => {
    logToSumoLogic('paymentRequest', getState().get('auth')?.user?.id ?? '', data)

    return new Promise(function (resolve, reject) {
      request.then(
        (rpReturn) => {
          track({ event: 'SUBMIT_PAYMENT' })
          resolve(rpReturn)

          dispatch({
            type: SUBMIT_PAYMENT_REQUEST,
            // Pass the `rpReturn` as soon as the server sends the `warning` flag
            payload: rpReturn,
          })
        },
        (error) => {
          if (!error.response) return
          track({
            event: 'SUBMIT_PAYMENT_FAILED',
            properties: { status: error.response.status, message: error.response.data.message },
          })
          const errorType = error.response.data.type
          if (Object.values(PaymentRequestError).includes(errorType)) {
            error.response.data.message = paymentRequestErrors[isBCx ? 'bcx' : 'da'][errorType]
          }
          if (errorType === 'multiple_requests_duplicate_payment') {
            resolve({})
          } else {
            reject(error)
          }
          switch (errorType) {
            case 'duplicate_payment':
              dispatch({
                type: SHOW_DUPLICATE_PAYMENT_MODAL,
              })
              break
            case 'cancellation_policy':
              dispatch({
                type: SHOW_CANCELLED_SESSIONS_EXCEEDED_MODAL,
              })
              break
            case 'multiple_requests_duplicate_payment':
              // do nothing here because this means there's a charge associated with an appointment we want to close
              break
            default:
              actionAlertHandler({ actionStyle: actionStyles.ERROR, message: error, dispatch })
          }
        },
      )
    })
  }
}

export const clearRequestPaymentStore = () => {
  return {
    type: CLEAR_REQUEST_PAYMENT_STORE,
  }
}

export const getPracticeProviders = (id: any) => {
  const request = getPracticeProvidersData(id)

  return {
    type: GET_PRACTICE_PROVIDERS,
    payload: request,
  }
}

export const getChargeToCopy = (id: any) => {
  const request = getChargeToCopyData(id)

  return {
    type: GET_CHARGE_TO_COPY,
    payload: request,
  }
}

export const getCustomers = () => ({
  action: GET_CUSTOMERS,
  request: {
    method: 'get',
    url: '/v1/customers',
  },
})

export const closeRequestPaymentModal = (type: any) => {
  switch (type) {
    case 'duplicatePayment':
      return {
        type: CLOSE_DUPLICATE_PAYMENT_MODAL,
      }
    case 'sessionsExceeded':
      track({ event: 'STS', properties: { type: (STSTypes as any).STS_EXCEEDED } })
      return {
        type: CLOSE_SESSIONS_EXCEEDED_MODAL,
      }
    case 'cancelledSessionsExceeded':
      return {
        type: CLOSE_CANCELLED_SESSIONS_EXCEEDED_MODAL,
      }
    case 'outcomeReminder':
      return {
        type: CLOSE_OUTCOME_REMINDER_MODAL,
      }
    case 'ineligibleClient':
      return {
        type: CLOSE_INELIGIBLE_CLIENT_MODAL,
      }
    default:
      // type starts with STS_, for session tracking modal
      track({ event: 'STS', properties: { type: STSTypes[type] } })
      return {
        type: CLOSE_SESSIONS_MODAL,
      }
  }
}

export const selectProvider = (id: any) => {
  return {
    type: SELECT_PROVIDER,
    payload: id,
  }
}

export const validateLyraCode = (lyraCode: any) => ({
  action: SET_LYRA_CODE_VALIDATION_INFO,

  request: {
    method: 'get',
    url: `/v1/clients/lyra-code/${lyraCode}`,
  },
})

export const clearLyraCodeValidationInfo = () => {
  return {
    type: CLEAR_LYRA_CODE_VALIDATION_INFO,
  }
}

export const checkEligibility = (data: any) => ({
  action: SET_ELIGIBILITY_STATUS,

  request: {
    method: 'post',
    url: '/v1/clients/eligibility',
    data,
  },
})

export const displayIneligibleClientModal = () => {
  return {
    type: DISPLAY_INELIGIBLE_CLIENT_MODAL,
  }
}
