import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { View } from 'react-native'

import { DatesSetArg, DayHeaderContentArg, EventContentArg, SlotLabelContentArg } from '@fullcalendar/core'
import googleCalendarPlugin from '@fullcalendar/google-calendar'
import FullCalendar from '@fullcalendar/react'
import timeGridWeek from '@fullcalendar/timegrid'
import { addMinutes, subMinutes } from 'date-fns'
import { format } from 'date-fns-tz'
import styled, { useTheme } from 'styled-components/native'

import { getTimeZoneName } from '@lyrahealth-inc/shared-app-logic'

import {
  BodyText,
  ChevronIconDirection,
  ChevronV2Icon,
  LoadingIndicator,
  SecondaryButton,
  Subhead,
  TertiaryIconButton,
} from '../../atoms'
import { ProviderCalendarAuthorizationCard } from '../../molecules'
import { BodyTextSize, SubheadSize } from '../../styles'
import { ThemeType } from '../../utils'

export type ProviderCalendarProps = {
  onAuthorizePressed?: () => void
  accessToken?: string
  calendarId?: string
  timeZone?: string
  loading?: boolean
}

const Container = styled.View<{ lowOpacity: boolean }>(({ lowOpacity }) => ({
  opacity: lowOpacity ? 0.4 : 1,
  height: 'calc(100vh - 63px)',
  overflow: 'scroll',
}))

const Header = styled.View(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing['20px'],
  padding: `${theme.spacing['16px']} ${theme.spacing['24px']}`,
  position: 'sticky',
  top: 0,
  backgroundColor: theme.colors.backgroundPrimary,
  zIndex: 3,
}))

const IconButton = styled(TertiaryIconButton)({
  alignSelf: 'center',
})

const DayContentContainer = styled.View(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing['4px'],
}))

const DateText = styled(Subhead)<{ isToday: boolean }>(({ theme, isToday }) => ({
  backgroundColor: isToday ? theme.colors.backgroundActive : undefined,
  padding: `${theme.spacing['4px']} ${isToday ? theme.spacing['8px'] : '0px'}`,
  marginTop: theme.spacing['4px'],
  marginBottom: theme.spacing['4px'],
  borderRadius: '4px',
  color: isToday ? theme.colors.textDarkBackground : undefined,
}))

const EventContainer = styled.View({
  flexDirection: 'column',
})

const LoadingView = styled.View({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  height: '100%',
  zIndex: 2,
  justifyContent: 'center',
})

export const ProviderCalendar: FunctionComponent<ProviderCalendarProps> = ({
  calendarId,
  accessToken,
  onAuthorizePressed,
  timeZone = 'America/Los_Angeles',
  loading = false,
}) => {
  const intl = useIntl()
  const theme = useTheme() as ThemeType
  const ref = useRef<FullCalendar>(null)

  const [dateRange, setDateRange] = useState<{ start: Date; end: Date } | null>(null)

  const onDatesSet = useCallback((dates: DatesSetArg) => {
    setDateRange(dates)
  }, [])

  const dayContentRenderer = useCallback(
    ({ date, isToday }: DayHeaderContentArg) => {
      const newDate = addMinutes(date, date.getTimezoneOffset())
      return (
        <DayContentContainer>
          <Subhead size={SubheadSize.XSMALL} text={format(newDate, 'eee', { timeZone })} />
          <DateText isToday={isToday} size={SubheadSize.XSMALL} text={format(newDate, 'd', { timeZone })} />
        </DayContentContainer>
      )
    },
    [timeZone],
  )

  const slotLabelContent = useCallback(
    ({ text }: SlotLabelContentArg) => {
      return <BodyText color={theme.colors.textSecondary} size={BodyTextSize.SMALL} text={text} />
    },
    [theme.colors.textSecondary],
  )

  const eventContent = useCallback(
    (args: EventContentArg) => {
      return (
        <EventContainer>
          <BodyText
            style={{ lineHeight: 14 }}
            color={theme.colors.textSecondary}
            size={BodyTextSize.BADGE}
            text={args.event.title}
          />
          <BodyText
            style={{ lineHeight: 14 }}
            color={theme.colors.textSecondary}
            size={BodyTextSize.CAPTION}
            text={args.timeText}
          />
        </EventContainer>
      )
    },
    [theme.colors.textSecondary],
  )

  useEffect(() => {
    if (!ref.current) {
      return
    }
    const calendar = ref.current.getApi()
    setDateRange({ start: calendar.view.currentStart, end: calendar.view.currentEnd })
    calendar.on('datesSet', onDatesSet)

    return () => {
      calendar.off('datesSet', onDatesSet)
    }
  }, [onDatesSet])

  const rangeText = useMemo(() => {
    if (!dateRange) {
      return ''
    }

    const startDate = addMinutes(dateRange.start, dateRange.start.getTimezoneOffset())
    const endDate = subMinutes(addMinutes(dateRange.end, dateRange.end.getTimezoneOffset()), 1)
    const startMonth = intl.formatDate(startDate, { month: 'short', timeZone })
    const endMonth = intl.formatDate(endDate, { month: 'short', timeZone })
    const startYear = intl.formatDate(startDate, { year: 'numeric', timeZone })
    const endYear = intl.formatDate(endDate, { year: 'numeric', timeZone })
    if (startMonth === endMonth && startYear === endYear) {
      return intl.formatDate(endDate, { month: 'long', year: 'numeric', timeZone })
    }
    const startDateFormatted = intl.formatDate(startDate, {
      month: 'short',
      year: startYear === endYear ? undefined : 'numeric',
      timeZone,
    })

    const endDateFormatted = intl.formatDate(endDate, { month: 'short', year: 'numeric', timeZone })
    return `${startDateFormatted} - ${endDateFormatted}`
  }, [dateRange, intl, timeZone])

  useEffect(() => {
    document.getElementsByClassName('fc-timegrid-now-indicator-line').item(0)?.scrollIntoView({ block: 'center' })
  }, [])

  return (
    <View>
      <Container lowOpacity={loading || !accessToken}>
        <Header>
          <SecondaryButton
            text={<FormattedMessage defaultMessage='Today' description='Button text that moves calendar to today' />}
            onPress={() => ref.current?.getApi().today()}
          />
          <IconButton
            leftIcon={<ChevronV2Icon direction={ChevronIconDirection.LEFT} />}
            onPress={() => ref.current?.getApi().prev()}
          />
          <IconButton
            leftIcon={<ChevronV2Icon direction={ChevronIconDirection.RIGHT} />}
            onPress={() => ref.current?.getApi().next()}
          />
          <Subhead text={rangeText} size={SubheadSize.LARGE} />
        </Header>
        <FullCalendar
          ref={ref}
          headerToolbar={false}
          plugins={[googleCalendarPlugin, timeGridWeek]}
          initialView='timeGridWeek'
          eventSources={
            calendarId && accessToken
              ? [
                  {
                    googleCalendarId: calendarId,
                    googleCalendarAccessToken: accessToken,
                  },
                ]
              : []
          }
          allDaySlot={false}
          dayHeaderContent={dayContentRenderer}
          nowIndicator
          slotLabelContent={slotLabelContent}
          eventColor='#CAE9ED'
          expandRows={true}
          contentHeight={2000}
          stickyHeaderDates={true}
          eventContent={eventContent}
          timeZone={timeZone}
          viewDidMount={({ el }) => {
            const axisEl = el.querySelector('.fc-timegrid-axis-frame')
            if (axisEl) {
              axisEl.innerHTML = getTimeZoneName({ timeZone, timeZoneName: 'short' }) ?? ''
            }
          }}
        />
      </Container>
      {loading && (
        <LoadingView>
          <LoadingIndicator topPadding={0} />
        </LoadingView>
      )}
      {!accessToken && !loading && <ProviderCalendarAuthorizationCard onAuthorizePressed={onAuthorizePressed} />}
    </View>
  )
}
