import React from 'react'
import { useSelector } from 'react-redux'

import { size } from 'lodash-es'
import styled from 'styled-components/native'

import { Capacity, ProviderExpeditedBookingConfig, ProviderUser } from '@lyrahealth-inc/shared-app-logic'
import { dateUtils } from '@lyrahealth-inc/ui-core'
import { ExpeditedBookingIcon, PrimaryButton } from '@lyrahealth-inc/ui-core-crossplatform/'

import styles from './availabilityCard.module.scss'
import { getAuthExpeditedBookingConfig } from '../../../data/auth/authSelectors'
import { programConfig, programNameTitleMap } from '../../constants/appConstants'
import { programIconMap } from '../../constants/programIconMap'

const ProgramCapacityContainer = styled.View({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
})

const ProgramIconWrapper = styled.View({
  borderRadius: '5px',
  height: '44px',
  overflow: 'hidden',
  width: '44px',
})

const CapacitiesMinified: React.FC<CapacitiesMinifiedProps> = ({
  provider,
  currentAvailabilityStatusText,
  handleUpdateClick,
  currentCapacity,
}) => {
  const providerExpeditedBookingConfig: ProviderExpeditedBookingConfig | undefined =
    useSelector(getAuthExpeditedBookingConfig)
  return (
    <>
      {Object.entries(provider.capacity_value).map(([programName]) => {
        const ProgramIcon = programConfig[programName]?.icon && programIconMap[programConfig[programName].icon]
        return (
          <div className={styles['capacity-card']} key={programName}>
            {size(provider.capacity_value) > 1 ? (
              <div
                className={styles['icon-container']}
                data-test-id={`CapacitiesMinified-${programNameTitleMap[programName]}-${currentCapacity[programName]}`}
              >
                {ProgramIcon && (
                  <span className={styles['icon-inner-container']}>
                    <ProgramIcon
                      width={44}
                      tooltip={`${programNameTitleMap[programName]} - ${currentAvailabilityStatusText(
                        programName,
                      )} Last updated ${dateUtils.getFriendlyDate(provider.capacity_updated_at)}`}
                      customTooltipWidth='350px'
                    />
                    {providerExpeditedBookingConfig && providerExpeditedBookingConfig[programName]?.enabled && (
                      <div className={styles['expedited-booking-icon-wrapper']}>
                        <ExpeditedBookingIcon width={24} />
                      </div>
                    )}
                  </span>
                )}
                <span className={styles['icon-label']}>{currentCapacity[programName]}</span>
              </div>
            ) : (
              <ProgramCapacityContainer>
                <div
                  className={styles['icon-container']}
                  data-test-id={`CapacitiesMinified-${programNameTitleMap[programName]}-${currentCapacity[programName]}`}
                >
                  {ProgramIcon && (
                    <span className={styles['icon-inner-container']}>
                      <ProgramIconWrapper>
                        <ProgramIcon
                          width={44}
                          tooltip={`${programNameTitleMap[programName]} - ${currentAvailabilityStatusText(
                            programName,
                          )} Last updated ${dateUtils.getFriendlyDate(provider.capacity_updated_at)}`}
                        />
                      </ProgramIconWrapper>
                      {providerExpeditedBookingConfig && providerExpeditedBookingConfig[programName]?.enabled && (
                        <div className={styles['expedited-booking-icon-wrapper']}>
                          <ExpeditedBookingIcon width={24} />
                        </div>
                      )}
                    </span>
                  )}
                </div>
                <div className={styles['session-info']}>
                  {
                    <p data-test-id='CapacitiesMinified-program-capacity' className={styles['capacity-title']}>
                      {size(provider.capacity_value) > 1 && `${programNameTitleMap[programName]} -`}{' '}
                      {currentAvailabilityStatusText(programName)}
                    </p>
                  }
                  {provider.capacity_updated_at && (
                    <p data-test-id='CapacitiesMinified-updated-date' className={styles['update-date']}>
                      Last updated {dateUtils.getFriendlyDate(provider.capacity_updated_at)}
                    </p>
                  )}
                </div>
              </ProgramCapacityContainer>
            )}
          </div>
        )
      })}
      <PrimaryButton testID={'CapacitiesMinified-update'} onPress={handleUpdateClick} text={'Update'} />
    </>
  )
}

type CapacitiesMinifiedProps = {
  provider: ProviderUser
  currentAvailabilityStatusText: (program_name: string) => React.ReactNode
  handleUpdateClick: () => void
  currentCapacity: Capacity
}

export default CapacitiesMinified
