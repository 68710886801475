import { createReducer } from '@reduxjs/toolkit'
import { uniqBy } from 'lodash-es'

import { Transfer } from '@lyrahealth-inc/shared-app-logic'

import {
  CLEAR_PAYMENT_HISTORY_STORE,
  GET_PAYMENT_HISTORY_OF_TYPE,
  PAYMENT_HISTORY_HIDE_PAYMENT_MODAL,
  PAYMENT_HISTORY_SHOW_PAYMENT_MODAL,
  UPDATE_CHARGE_STATUS,
} from '../../common/constants/reduxConstants'

type PaymentHistory = { [status: string]: { data: Transfer[]; page: number } }

type PaymentHistoryState = {
  showModal: boolean
  modalData: any
  historyData?: PaymentHistory
}

const initialState = {
  showModal: false,
  modalData: {},
}

export default createReducer<PaymentHistoryState>(initialState, (builder) => {
  builder.addCase(PAYMENT_HISTORY_SHOW_PAYMENT_MODAL, (state, action: any) => {
    state.showModal = true
    state.modalData = action.data
    return state
  })
  builder.addCase(PAYMENT_HISTORY_HIDE_PAYMENT_MODAL, (state) => {
    state.showModal = false
    return state
  })
  builder.addCase(GET_PAYMENT_HISTORY_OF_TYPE, (state, action: any) => {
    const type = action.status ? action.status : 'all'

    // create or append to list
    const list = state.historyData?.[type]?.data
    let newList: Transfer[]
    if (list && list.length > 0) {
      const newHistoryData = action.data.data as Transfer[]

      // concatenate existing items with new items and filter out duplicates by ID
      const mergedHistoryData = [...list, ...newHistoryData]
      const uniqueHistoryData = uniqBy(mergedHistoryData, 'id')

      newList = uniqueHistoryData
    } else {
      const newHistoryData = action.data.data as Transfer[]
      const uniqueHistoryData = uniqBy(newHistoryData, 'id')

      newList = uniqueHistoryData
    }

    state.historyData = {
      ...state.historyData,
      [type]: {
        data: newList,
        page: action.page,
      },
    }
    return state
  })
  builder.addCase(CLEAR_PAYMENT_HISTORY_STORE, () => {
    return initialState
  })
  builder.addCase(UPDATE_CHARGE_STATUS, (state, action: any) => {
    // update list views (only) if it was a cancel action
    if (action.payload.status.toLowerCase() === 'cancelled') {
      // remove it from all list
      if (state.historyData?.all?.data) {
        state.historyData.all.data = state.historyData.all.data.filter((item) => item.id !== action.payload.id)
      }

      // remove it from submitted list
      if (state.historyData?.submitted?.data) {
        state.historyData.all.data = state.historyData.submitted.data.filter((item) => item.id !== action.payload.id)
      }

      // add it to the appropriate list, if needed
      if (state.historyData?.[action.payload.status.toLowerCase()]?.data) {
        state.historyData[action.payload.status.toLowerCase()].data = [
          action.payload,
          ...state.historyData[action.payload.status.toLowerCase()].data,
        ]
      }
    }

    return state
  })
})
