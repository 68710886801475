import { submitRegisterProviderForm } from '../../../common/http/data/providers'
import {
  TOGGLE_REGISTER_PROVIDER_MODAL,
  SELECT_ACCOUNT_TYPE,
  CLEAR_ACCOUNT_TYPE,
} from '../../../common/constants/reduxConstants'

export const submitRegisterProvider = (data: any) => {
  const request = submitRegisterProviderForm(data)

  return (_: any) => {
    return new Promise(function (resolve, reject) {
      request.then(
        (registrationReturn) => {
          resolve(registrationReturn)
        },
        (error) => {
          reject(error)
        },
      )
    })
  }
}

export const toggleRegisterProviderModal = (show: any) => {
  return {
    type: TOGGLE_REGISTER_PROVIDER_MODAL,
    payload: show,
  }
}

export const selectAccountType = (type: any) => {
  return {
    type: SELECT_ACCOUNT_TYPE,
    payload: type,
  }
}

export const clearAccountType = () => {
  return {
    type: CLEAR_ACCOUNT_TYPE,
  }
}
