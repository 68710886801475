import React, { FunctionComponent } from 'react'
import { Path, Svg } from 'react-native-svg'

/**
 * Heart icon used for word cloud categories
 */

type EmotionsFeelingsIconProps = {
  size?: string | number
}

export const EmotionsFeelingsIcon: FunctionComponent<EmotionsFeelingsIconProps> = ({ size = 24 }) => (
  <Svg width={size} height={size} viewBox='0 0 24 24' fill='none'>
    <Path
      d='M11.7675 5.12837C11.5275 5.34264 11.1581 5.32461 10.9414 5.08732C9.63662 3.66356 5.2808 -0.403477 1.87752 4.46054C-2.02075 10.0324 8.19514 20.2301 11.0305 21.9162C11.2067 22.0213 11.4243 22.0283 11.6065 21.9342C13.0004 21.2153 19.2451 17.6889 22.1271 10.8925C25.3775 3.22702 18.6702 1.44782 16.3591 2.13867C14.6889 2.63829 12.6481 4.3404 11.7675 5.12737V5.12837Z'
      fill='#FFAF9B'
    />
  </Svg>
)
