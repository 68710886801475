import { axiosAuthInstance } from '../axiosInstance'

export function getScheduling(providerId: string) {
  return axiosAuthInstance.get(`/v1/scheduling/${providerId}`)
}

export function confirmOnlineBooking(providerId: string) {
  return axiosAuthInstance.post(`/v1/scheduling/valid/${providerId}`)
}

export function submitCalendarUse(data: any, providerId: string) {
  return axiosAuthInstance.post(`/v1/scheduling/use/${providerId}`, data)
}

export function submitSchedulingOAuth(data: any, providerId: string) {
  return axiosAuthInstance.post(`/v1/scheduling/oauth/oauth2callback/${providerId}`, data)
}
