import React, { FunctionComponent } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import styled, { useTheme } from 'styled-components/native'

import { BodyText } from '../../atoms/bodyText/BodyText'
import { HeaderText } from '../../atoms/headerText/HeaderText'
import { LeaveSiteIcon } from '../../atoms/icons'
import { CloseIcon } from '../../atoms/icons/CloseIcon'
import { PressableOpacity } from '../../atoms/pressableOpacity/PressableOpacity'
import { SecondaryButton } from '../../atoms/secondaryButton/SecondaryButton'
import { TertiaryButton } from '../../atoms/tertiaryButton/TertiaryButton'
import { BodyTextSize } from '../../styles/typeStyles'
import { ThemeType, tID } from '../../utils'

export type AlertPopUpProps = {
  headerText: string
  onClose: () => void
  secondaryHandler: () => void
  tertiaryHandler: () => void
  text: string
  textSize?: BodyTextSize
}

const AlertPopUpContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  backgroundColor: theme.colors.backgroundWarning,
  borderRadius: theme.spacing['16px'],
  bottom: theme.spacing['48px'],
  color: theme.colors.borderWarning,
  display: 'flex',
  height: '175px',
  left: theme.spacing['32px'],
  padding: theme.spacing['16px'],
  position: 'fixed',
  width: '400px',
  zIndex: 9,
}))

const CloseIconContainer = styled(PressableOpacity)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing['12px'],
  right: theme.spacing['12px'],
  zIndex: 1,
}))

const HeaderTextContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['8px'],
}))

const ButtonsContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  alignItems: 'center',
  flexDirection: 'row-reverse',
  marginTop: theme.spacing['32px'],
}))

const TertiaryButtonContainer = styled.View(() => ({
  marginTop: '0px',
}))

export const AlertPopUp: FunctionComponent<AlertPopUpProps> = ({
  headerText,
  onClose,
  secondaryHandler,
  tertiaryHandler,
  text,
  textSize = BodyTextSize.SMALL,
}) => {
  const { colors } = useTheme()

  const handleCloseModal = () => {
    onClose()
  }

  const { formatMessage } = useIntl()

  return (
    <AlertPopUpContainer testID={tID('AlertPopUp')}>
      <CloseIconContainer
        onPress={handleCloseModal}
        testID={tID('AlertPopUp-close')}
        accessibilityLabel={formatMessage({
          defaultMessage: 'Close Alert',
          description: 'This button closes the alert pop up',
        })}
      >
        <CloseIcon size={12} />
      </CloseIconContainer>
      <HeaderTextContainer>
        <HeaderText text={headerText} color={colors.borderWarning} />
      </HeaderTextContainer>
      <BodyText text={text} size={textSize} color={colors.borderWarning} />
      <ButtonsContainer>
        <SecondaryButton
          customTextColor={colors.borderWarning}
          fullWidth={true}
          onPress={secondaryHandler}
          iconColor={colors.borderWarning}
          rightIcon={<LeaveSiteIcon />}
          testID={tID('AlertPopUp-openCalendarButton')}
          text={
            <FormattedMessage
              defaultMessage='Open calendar'
              description='This button opens a new tab and takes the user to their calendar page'
            />
          }
          style={{ borderColor: colors.borderWarning, backgroundColor: colors.backgroundWarning }}
        />
        <TertiaryButtonContainer>
          <TertiaryButton
            customTextColor={colors.borderWarning}
            fullWidth={true}
            onPress={tertiaryHandler}
            testID={tID('AlertPopUp-learnMoreButton')}
            text={
              <FormattedMessage
                defaultMessage='Learn more'
                description='This button is a call to action for the user to go to a different page to learn more about creating more calendar availabilities'
              />
            }
            style={{ backgroundColor: colors.backgroundWarning }}
          />
        </TertiaryButtonContainer>
      </ButtonsContainer>
    </AlertPopUpContainer>
  )
}
