import React, { FunctionComponent } from 'react'
import Svg, { G, Mask, Path } from 'react-native-svg'

type MeetWithProviderBenefitIllustrationProps = {
  size?: number
}

export const MeetWithProviderBenefitIllustration: FunctionComponent<MeetWithProviderBenefitIllustrationProps> = ({
  size = 513,
}) => {
  return (
    <Svg width={size} height={size} fill='none' viewBox='0 0 513 513'>
      <Path
        fill='#FFE9E3'
        d='M258.725.735c-35.341 0-69.629 6.06-101.915 18.01C79.946 47.234 25.908 110.4 8.546 192.04c-11.474 53.229-10.007 102.461 4.512 150.439 18.536 61.273 64.035 112.944 124.832 141.759 30.181 14.337 64.854 22.522 103.038 24.323 4.907.223 9.896.344 14.853.344 30.637 0 61.455-4.199 91.606-12.475a160.723 160.723 0 0 0 6.849-1.862c27.095-7.932 51.479-20.478 72.342-37.243 22.653-18.1 41.3-41.047 55.364-68.163 2.772-5.342 5.423-10.967 8.347-17.695 16.087-37.355 23.857-79.171 22.481-120.978-.647-18.485-3.399-38.133-8.155-58.449-7.831-32.964-20.599-63.964-36.929-89.704-4.472-6.91-8.58-12.718-12.647-17.938-29.352-37.86-75.084-64.43-132.208-76.793a302.865 302.865 0 0 0-64.095-6.86l-.011-.01Z'
      />
      <Mask
        id='MeetWithProviderBenefitIllustration-a'
        width={513}
        height={509}
        x={0}
        y={0}
        maskUnits='userSpaceOnUse'
        // @ts-ignore-next-line
        style={{
          maskType: 'alpha',
        }}
      >
        <Path
          fill='#FFE9E3'
          d='M258.725.735c-35.341 0-69.629 6.06-101.915 18.01C79.946 47.234 25.908 110.4 8.546 192.04c-11.474 53.229-10.007 102.461 4.512 150.439 18.536 61.273 64.035 112.944 124.832 141.759 30.181 14.337 64.854 22.522 103.038 24.323 4.907.223 9.896.344 14.853.344 30.637 0 61.455-4.199 91.606-12.475a160.723 160.723 0 0 0 6.849-1.862c27.095-7.932 51.479-20.478 72.342-37.243 22.653-18.1 41.3-41.047 55.364-68.163 2.772-5.342 5.423-10.967 8.347-17.695 16.087-37.355 23.857-79.171 22.481-120.978-.647-18.485-3.399-38.133-8.155-58.449-7.831-32.964-20.599-63.964-36.929-89.704-4.472-6.91-8.58-12.718-12.647-17.938-29.352-37.86-75.084-64.43-132.208-76.793a302.865 302.865 0 0 0-64.095-6.86l-.011-.01Z'
        />
      </Mask>
      <G mask='url(#MeetWithProviderBenefitIllustration-a)'>
        <Path
          fill='#7F6E51'
          d='M297.607 97.312s-21.283-37.647-55.118-25.647-31.105 31.106-47.471 43.659c-16.365 12.552-32.199 16.375-38.199 34.928-6.001 18.553-1.094 36.013-16.376 57.295-15.282 21.282-40.93 107.516-9.824 111.328 31.106 3.813 207.906 22.907 207.906 22.907s40.929-38.199 36.564-76.399c-4.364-38.2-14.188-32.2-12.552-56.201 1.635-24.011 1.635-43.658-11.459-56.752-13.095-13.095-5.459-51.836-48.023-46.93l-5.459-8.188h.011Z'
        />
        <Path
          fill='#00A4B3'
          d='M58.053 485.841s23.46-150.611 60.023-178.435c36.565-27.825 93.318-35.471 113.506-35.471 20.188 0 48.023-6 96.036 12.001 48.023 18.011 90.035 32.199 119.506 91.671 29.47 59.482 40.929 170.258 40.929 170.258s-47.471 52.93-215 55.659c-167.529 2.729-207.906-38.741-208.448-60.576-.541-21.824-6.552-55.117-6.552-55.117v.01Z'
        />
        <Path
          fill='#D49D73'
          d='M220.123 233.884s-6.553 36.437-4.907 44.657c1.636 8.209 16.918 33.877 45.836 33.357 28.918-.52 44.742-24.118 45.835-33.357 1.094-9.239-9.823-45.676-12.552-63.125-2.73-17.448-58.93-24.638-74.212 18.479v-.011Z'
        />
        <Path
          fill='#E6B087'
          d='M203.768 192.265s-4.939 63.847 54.002 65.482c58.93 1.636 64.941-48.565 66.576-53.482 1.636-4.917 21.282-11.459 18.012-28.376-3.271-16.918-19.647-13.647-19.647-13.647s1.858-50.752-35.906-51.294c-37.765-.542-65.047 6-72.693 32.2-7.647 26.199-10.344 49.106-10.344 49.106v.011Z'
        />
        <Path
          fill='#E6B087'
          d='M212.477 167.159s-14.188-20.188-24.011-3.271c-9.824 16.918 19.094 34.377 19.094 34.377l4.907-31.106h.01Z'
        />
        <Path
          fill='#EDE6DE'
          d='M258.791 376.134s71.578-16.777 78.389-15.983c6.289.733 10.759 2.471 15.923 17.46 4.973 14.446 38.979 160.023 39.039 160.365 3.606 19.951-5.505 21.87-14.145 25.316-8.549 3.416-103.957 25.808-129.353 31.705-6.65 1.547-13.934 1.748-19.55-.171-4.45-1.527-9.192-2.782-14.275-19.097-6.46-20.735-35.543-158.959-35.543-158.959s-3.114-17.018 13.914-22.171c17.028-5.154 65.601-18.465 65.601-18.465Z'
        />
        <Path
          fill='#85CCD5'
          d='M239.733 377.641s-25.427 4.099-25.055 11.955c.372 7.856.532 19.901 13.19 18.896 12.658-1.004 81.926-18.163 86.145-22.292 4.22-4.129.864-23.005-5.857-24.13-6.72-1.126-25.768 5.224-25.768 5.224s-8.217-15.964-24.211-11.051c-15.993 4.922-18.444 21.398-18.444 21.398Z'
        />
        <Path
          fill='#AA8052'
          d='M298.689 171.673a6.663 6.663 0 0 0-2.06-3.016c-.712-.68-1.561-1.19-2.496-1.402-2.899-.659-5.416.573-6.786 2.942a6.973 6.973 0 0 0-1.455 3.111 8.882 8.882 0 0 0-.138 2.517 21.716 21.716 0 0 0-.17 3.313c.075 2.411 1.445 4.525 3.675 5.47 2.156.913 4.864.499 6.542-1.243 2.941-3.058 4.226-7.604 2.878-11.692h.01ZM245.429 168.391c.797-2.177-.488-4.896-2.166-6.202-1.806-1.391-4.663-1.795-6.648-.467-1.88 1.253-3.335 2.92-4.153 4.981-.871 1.157-1.391 2.58-1.412 4.014-.032 1.965.839 4.025 2.315 5.395 1.147 1.572 2.4 2.591 4.375 2.857 1.912.254 3.611-.33 5.023-1.647 1.806-1.677 2.847-4.152 2.91-6.605a9.196 9.196 0 0 0-.244-2.337v.011Z'
        />
        <Path
          fill='#C49069'
          d='M267.955 169.942c-.605-7.031-11.661-7.094-11.055 0 .849 9.866-1.084 19.243-6.033 27.845-3.547 6.181 5.99 11.746 9.537 5.576 5.841-10.174 8.549-21.718 7.54-33.421h.011Z'
        />
        <Path
          fill='#7F6E51'
          d='M294.93 111.33s20.878 50.849 27.781 50.923c6.903.074 5.448-37.17 5.448-37.17L294.93 111.32v.01ZM296.83 108.835l-1.901 2.485s-34.908 13.583-58.569 14.751c-23.661 1.168-27.165 36.522-27.165 36.522l-6.425-27.463 31.254-35.64 25.116-3.218 37.69 12.563Z'
        />
        <Path
          fill='#AA8052'
          d='M309.904 156.603c-6.606-7.646-16.1-11.82-26.189-11.671-4.099.064-4.11 6.435 0 6.372 8.539-.128 16.111 3.356 21.686 9.802 2.687 3.112 7.179-1.413 4.503-4.503ZM253.851 138.932c-9.154-3.409-19.137-2.337-27.431 2.803-3.483 2.156-.287 7.668 3.218 5.501 6.988-4.333 14.825-5.023 22.514-2.166 3.844 1.434 5.512-4.726 1.688-6.149l.011.011Z'
        />
        <Path
          fill='#C49069'
          d='M281.581 212.655c-9.399 7.105-21.76 9.048-32.699 4.312-3.727-1.614-6.977 3.876-3.217 5.501 12.945 5.607 27.771 4.28 39.134-4.312 3.228-2.442.053-7.975-3.218-5.501Z'
        />
        <Path
          fill='#7F6E51'
          d='M209.185 162.593s12.861-3.346 21.696-13.711c8.836-10.365 14.157-20.358 27.824-21.898 13.668-1.54 36.225-15.654 36.225-15.654l-28.153-12.5-45.209 14.869-18.808 21.441 6.425 27.463v-.01Z'
        />
      </G>
    </Svg>
  )
}
