import React from 'react'

/**
 * Formats a string consisting of digits as a phone number of format +1 (xxx) yyy-zzzz,
 * with the international calling code +1 only included if the phone number already includes it.
 * Can only handle strings of 10+ digits long; will return the unmodified string otherwise.
 */
const formatPhoneNumber = function (phone = '') {
  phone = phone?.replace(/\D/g, '')
  if (phone?.length < 10) {
    console.warn(`This phone number is missing digits.
    Please reach out to the appropriate person for the correct phone number.`)
    return phone
  }
  const internationalAccessCode = phone.charAt(0) === '1' ? '+1 ' : ''
  const domesticPhoneNumber = internationalAccessCode.length > 0 ? phone.substring(1) : phone
  const areaCode = domesticPhoneNumber.substring(0, 3)
  const centralOfficeCode = domesticPhoneNumber.substring(3, 6)
  const subscriberNumber = domesticPhoneNumber.substring(6, 10)
  const extension = domesticPhoneNumber.length > 10 ? ' ext. ' + domesticPhoneNumber.substring(10) : ''

  return `${internationalAccessCode}(${areaCode}) ${centralOfficeCode}-${subscriberNumber}${extension}`
}

const getInitials = function (name: $TSFixMe) {
  let letters = name.match(/\b\w/g) || []
  const titleIndex = name.indexOf(',')
  if (titleIndex > -1) {
    letters = name.slice(0, titleIndex).match(/\b\w/g) || []
  }
  return ((letters.shift() || '') + (letters.pop() || '')).toUpperCase()
}

const urlRegex = /(\b(?:https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;$]*[-A-Z0-9+&@#/%=~_|])/gi
export const replaceWebLinks = function (text: $TSFixMe) {
  const tokens = text.split(urlRegex)
  // Replace every other token
  return tokens.map((token: $TSFixMe, index: $TSFixMe) =>
    index % 2 ? (
      <a key={index} href={token} target='_blank' rel='noreferrer'>
        {token}
      </a>
    ) : (
      token
    ),
  )
}

export default {
  formatPhoneNumber,
  getInitials,
}
