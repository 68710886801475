import ZoomVideo, { LocalAudioTrack, LocalVideoTrack, MediaCompatiblity, SupportFeatures } from '@zoom/videosdk'

import MockVideoClient from './MockVideoClient'

const MockSystemRequirements: MediaCompatiblity = {
  audio: true,
  video: true,
  screen: true,
}
const MockFeatureRequirements: SupportFeatures = {
  platform: 'platform',
  supportFeatures: [],
  unSupportFeatures: [],
}

const getDevices: () => Promise<MediaDeviceInfo[]> = async () => {
  return await navigator.mediaDevices.enumerateDevices()
}

const MockLocalAudioTrack: LocalAudioTrack = {
  start: () => Promise.resolve(),
  stop: () => Promise.resolve(),
  unmute: () => Promise.resolve(),
  mute: () => Promise.resolve(),
  getCurrentVolume: () => 1,
  testSpeaker: () => ({
    stop: () => {},
    destroy: () => {},
  }),
  testMicrophone: () => ({
    stop: () => {},
    stopRecording: () => {},
    destroy: () => {},
  }),
}

class MockLocalVideoTrack implements LocalVideoTrack {
  private videoEl?: HTMLVideoElement

  deviceId?: string

  constructor(deviceId?: string) {
    this.deviceId = deviceId
  }

  async start(videoDOMElement: HTMLVideoElement | HTMLCanvasElement) {
    this.videoEl = videoDOMElement as HTMLVideoElement
    const constraints = {
      audio: false,
      video: { deviceId: this.deviceId, width: 1280, height: 720 },
    }

    const mediaStream = await navigator.mediaDevices.getUserMedia(constraints)
    this.videoEl.srcObject = mediaStream
    this.videoEl.onloadedmetadata = () => {
      this.videoEl?.play()
    }
  }

  async stop() {
    if (this.videoEl && this.videoEl.srcObject instanceof MediaStream) {
      this.videoEl.srcObject.getTracks().forEach((track) => track.stop())
      this.videoEl.srcObject = null
    }

    return
  }

  switchCamera() {
    return Promise.resolve()
  }

  updateVirtualBackground() {
    return Promise.resolve()
  }
}

export const MockZoomVideo: typeof ZoomVideo = {
  createClient: () => MockVideoClient,
  checkSystemRequirements: () => MockSystemRequirements,
  checkFeatureRequirements: () => MockFeatureRequirements,
  getDevices: getDevices,
  createLocalAudioTrack: () => MockLocalAudioTrack,
  createLocalVideoTrack: (deviceId) => new MockLocalVideoTrack(deviceId),
  destroyClient: () => {},
  preloadDependentAssets: () => {},
  VERSION: 'mock-version',
}
