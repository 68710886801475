import React, { FunctionComponent } from 'react'
import Svg, { G, Mask, Path } from 'react-native-svg'

type Props = {
  size?: number | string
}

export const InPatientHospitalizationSpotIllustration: FunctionComponent<Props> = ({ size = 124 }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 512 512' fill='none'>
      <Path
        d='M257.8 2C222.459 2 188.17 8.06049 155.884 20.0095C79.0203 48.4907 24.9919 111.655 7.61986 193.295C-3.85358 246.524 -2.38653 295.757 12.1323 343.735C30.6679 405.007 76.1671 456.678 136.964 485.493C167.145 499.83 201.819 508.015 240.003 509.816C244.91 510.039 249.898 510.16 254.855 510.16C285.492 510.16 316.31 505.961 346.461 497.685C348.778 497.108 350.983 496.512 353.311 495.824C380.406 487.891 404.789 475.345 425.652 458.58C448.305 440.48 466.952 417.533 481.016 390.418C483.788 385.075 486.439 379.45 489.363 372.722C505.45 335.367 513.22 293.551 511.844 251.745C511.197 233.26 508.445 213.611 503.69 193.295C495.858 160.332 483.09 129.331 466.76 103.592C462.288 96.6812 458.18 90.8737 454.113 85.6529C424.762 47.7926 379.03 21.2236 321.905 8.85978C300.911 4.30683 279.34 2 257.8 2Z'
        fill='white'
      />
      <Mask
        id='mask0_18566_6983'
        mask-type='luminance'
        maskUnits='userSpaceOnUse'
        x='21'
        y='23'
        width='469'
        height='466'
      >
        <Path
          d='M489.884 252.442C489.257 234.726 486.546 216.373 482.276 198.131C475.295 168.739 463.821 139.762 448.088 114.953C444.436 109.328 440.692 103.905 436.625 98.6942C407.122 60.6518 362.412 39.4856 316.65 29.5804C265.677 18.5319 211.476 21.7594 162.476 39.9004C91.592 66.1659 43.3307 123.705 27.5876 197.716C21.5372 225.752 19.7666 257.441 22.4781 287.885H22.2657C23.8339 305.186 26.9603 322.072 31.6448 337.603C49.0573 395.142 91.3694 440.803 145.266 466.34C174.456 480.201 207.075 487.081 240.231 488.65C274.105 490.218 308.505 485.938 340.305 477.186C342.389 476.67 344.372 476.144 346.456 475.517C371.366 468.222 393.048 456.961 411.604 442.058C431.829 425.9 448.503 405.573 461.434 380.664C464.246 375.241 466.745 369.828 469.143 364.293C484.36 328.952 491.139 290.596 489.783 252.554L489.884 252.453V252.442Z'
          fill='white'
        />
      </Mask>
      <G mask='url(#mask0_18566_6983)'>
        <Path
          d='M255.586 510.17C397.12 510.17 511.857 396.413 511.857 256.085C511.857 115.758 397.12 2 255.586 2C114.052 2 -0.68457 115.758 -0.68457 256.085C-0.68457 396.413 114.052 510.17 255.586 510.17Z'
          fill='#FFE9E3'
        />
        <Path
          d='M228.283 582.148C435.346 582.148 603.204 528.705 603.204 462.779C603.204 396.854 435.346 343.411 228.283 343.411C21.22 343.411 -146.638 396.854 -146.638 462.779C-146.638 528.705 21.22 582.148 228.283 582.148Z'
          fill='#7FB04D'
        />
        <Path
          d='M386.271 138.366V389.477C386.271 394.182 382.73 397.996 378.369 397.996H133.653C129.292 397.996 125.751 394.182 125.751 389.477V138.366C125.751 133.662 129.292 129.847 133.653 129.847H378.369C382.73 129.847 386.271 133.662 386.271 138.366Z'
          fill='#DCCDBF'
        />
        <Path
          d='M324.794 285.619V398.138H187.224V285.619C187.224 283.505 189.136 281.785 191.493 281.785H320.524C322.881 281.785 324.794 283.505 324.794 285.619Z'
          fill='#F7F5EF'
        />
        <Path
          d='M386.271 134.461V152.805H125.751V134.461C125.751 131.244 129.292 128.644 133.653 128.644H378.369C382.73 128.644 386.271 131.244 386.271 134.461Z'
          fill='#FDA694'
        />
        <Path d='M260.561 281.41H251.455V397.763H260.561V281.41Z' fill='#DCCDBF' />
        <Path d='M265.635 187.355H246.371V244.682H265.635V187.355Z' fill='#E5744C' />
        <Path d='M284.668 225.652V206.388H227.341V225.652H284.668Z' fill='#E5744C' />
      </G>
    </Svg>
  )
}
