import React, { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'

import styled, { useTheme } from 'styled-components/native'

import { getCurrentQuarter, getDaysToDate, getWeeksToDate } from '@lyrahealth-inc/shared-app-logic'

import { ThemeType } from '../../utils/themes/ThemeProvider'
import { tID } from '../../utils/utils'
import { BodyText } from '../bodyText/BodyText'
import { InfoIcon, ScheduleIcon } from '../icons'
import { Tooltip } from '../tooltip/Tooltip'

export interface DashboardUpdateInfoProps {
  currentDate?: Date
  resetDateTime: Date
  resetsQuarterly?: boolean
  quarterAndYear?: string | null
}

const CalendarHeadingContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing['8px'],
}))

const ScheduleIconContainer = styled.View({
  marginTop: '4px',
})

const TooltipIconContainer = styled.View({
  marginTop: '4px',
})

export const DashboardUpdateInfo: FunctionComponent<DashboardUpdateInfoProps> = ({
  currentDate = new Date(),
  resetDateTime,
  resetsQuarterly = true,
  quarterAndYear = '',
}) => {
  const { formatMessage, formatDate } = useIntl()
  const { colors } = useTheme()

  const resetTime = formatDate(resetDateTime.toString(), {
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
  })

  const resetDate = formatDate(resetDateTime.toString(), {
    dateStyle: 'short',
  })
  const dashboardUpdateInfo = quarterAndYear?.length
    ? formatMessage(
        {
          defaultMessage: `{quarter} {year} • 0 weeks left in the {resetsQuarterly, select, true {quarter} other {performance year}}`,
          description: 'Subhead showing past quarter and when the dashboard was reset',
        },
        {
          quarter: quarterAndYear.split('_')[0],
          year: quarterAndYear.split('_')[1],
          resetsQuarterly: resetsQuarterly,
        },
      )
    : getWeeksToDate(resetDateTime, currentDate) > 1
    ? formatMessage(
        {
          defaultMessage: `Q{quarter} {year} • 
          {weeksInQuarter} {weeksInQuarter, plural,
            one {week}
            other {weeks}
          }
          left in the {resetsQuarterly, select, true {quarter} other {performance year}}`,
          description: 'Subhead showing current quarter and how many weeks are left before the dashboard reset',
        },
        {
          quarter: getCurrentQuarter(currentDate),
          year: currentDate.getFullYear(),
          weeksInQuarter: getWeeksToDate(resetDateTime, currentDate),
          resetsQuarterly: resetsQuarterly,
        },
      )
    : getDaysToDate(resetDateTime, currentDate) > 1
    ? formatMessage(
        {
          defaultMessage: `Q{quarter} {year} • 
        {daysInQuarter} {daysInQuarter, plural,
          one {day}
          other {days}
        }
        left in the {resetsQuarterly, select, true {quarter} other {performance year}}`,
          description: 'Subhead showing current quarter and how many days are left before the dashboard reset',
        },
        {
          quarter: getCurrentQuarter(currentDate),
          year: currentDate.getFullYear(),
          daysInQuarter: getDaysToDate(resetDateTime, currentDate),
          resetsQuarterly: resetsQuarterly,
        },
      )
    : formatMessage(
        {
          defaultMessage: `Q{quarter} {year} • Dashboard will reset at {resetTime} on {resetDate}`,
          description: 'Subhead showing current quarter and when the dashboard will reset',
        },
        {
          quarter: getCurrentQuarter(currentDate),
          year: currentDate.getFullYear(),
          resetTime,
          resetDate,
        },
      )

  return (
    <CalendarHeadingContainer>
      <ScheduleIconContainer>
        <ScheduleIcon size={24} fillColor={colors.iconDefault} />
      </ScheduleIconContainer>
      <BodyText testID={tID('DashboardUpdateInfo')} color={colors.textSecondary} text={dashboardUpdateInfo} />
      {!quarterAndYear && (
        <TooltipIconContainer>
          <Tooltip hoverEnabled content={`Dashboard resets on ${resetDate} at ${resetTime}`}>
            <InfoIcon size={18} />
          </Tooltip>
        </TooltipIconContainer>
      )}
    </CalendarHeadingContainer>
  )
}
