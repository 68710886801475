import React, { FunctionComponent } from 'react'
import { Path, Svg } from 'react-native-svg'

import styled from 'styled-components/native'

import { tID } from '../../utils'
import { Image } from '../image/Image'

const ImageContainer = styled(Image)<{ size: string }>(({ size }) => ({
  width: size,
  height: size,
}))

/**
 * Illustration to show on the treatment card
 */
export const Family: FunctionComponent<FamilyProps> = ({ size = 124, backgroundFill }) => {
  const sizeInPixels = `${size}px`
  return !backgroundFill ? (
    <ImageContainer
      size={sizeInPixels}
      testID={`${tID('FamilyIllustration')}`}
      source={require('../../assets/family.png')}
    />
  ) : (
    <Svg width={size} height={size} viewBox='0 0 56 56' fill='none'>
      <Path
        d='M28.1968 0.209473C24.3314 0.209473 20.5811 0.872339 17.0498 2.17926C8.64285 5.2955 2.73238 12.2041 0.833422 21.1335C-0.421486 26.9554 -0.261026 32.3402 1.32697 37.5878C3.3543 44.2895 8.33078 49.941 14.9805 53.0927C18.2815 54.6608 22.0739 55.556 26.2503 55.753C26.787 55.7773 27.3326 55.7906 27.8748 55.7906C31.2257 55.7906 34.5964 55.3314 37.8942 54.4262C38.1476 54.3631 38.3888 54.2978 38.6433 54.2225C41.6069 53.3549 44.2738 51.9827 46.5557 50.1491C49.0334 48.1693 51.0729 45.6595 52.6111 42.6938C52.9143 42.1095 53.2043 41.4942 53.5241 40.7583C55.2836 36.6726 56.1335 32.099 55.983 27.5264C55.9122 25.5046 55.6112 23.3556 55.091 21.1335C54.2345 17.5281 52.838 14.1374 51.0519 11.3222C50.5628 10.5663 50.1135 9.93114 49.6686 9.36012C46.4583 5.21915 41.4564 2.31316 35.2084 0.960868C32.9133 0.462889 30.5539 0.210579 28.1979 0.210579L28.1968 0.209473Z'
        fill={backgroundFill}
      />
      <Path
        d='M41.8672 26.415C41.8606 25.4544 41.4389 24.5437 40.7119 23.9162L38.4887 21.9985V17.0851C38.4887 16.7442 38.212 16.4687 37.8712 16.4687H36.188C35.8472 16.4687 35.5705 16.7453 35.5705 17.0851V19.482L29.67 14.3926C28.7482 13.597 27.3826 13.597 26.4608 14.3926L15.399 23.935C14.6842 24.5514 14.2648 25.4423 14.2437 26.3862L14.0645 36.888C14.0401 37.9924 14.9298 38.901 16.0342 38.901H39.9572C41.0516 38.901 41.9358 38.0102 41.9281 36.9157L41.8672 26.415Z'
        fill='#6ECBD3'
      />
      <Path
        d='M17.6842 40.4803L18.7521 40.5213L18.793 41.9997H19.6142L19.737 40.4394L21.4611 40.3984L21.543 41.9997L22.3232 42.0816L22.446 40.5622H23.0613C23.0613 40.5622 23.2948 35.2881 22.9794 34.4448C22.4869 33.1312 21.9536 32.9254 21.9536 32.9254C21.9536 32.9254 23.8094 32.3588 23.9244 30.7498C24.0473 29.0257 23.3501 27.6291 21.1734 27.4653C21.1734 27.4653 18.5894 27.4333 18.3404 30.1755C18.1767 31.9815 19.6949 32.8026 19.6949 32.8026C19.6949 32.8026 19.0376 33.0494 18.4633 34.198C17.8889 35.3478 17.6831 40.4792 17.6831 40.4792L17.6842 40.4803Z'
        fill='#127719'
      />
      <Path
        d='M37.9358 40.6495L36.9099 40.5677L36.7871 42.2099L36.0888 42.128L35.966 40.5677L34.2009 40.4858L33.7904 42.0052L33.1331 41.9233L33.2149 40.281H32.271C32.271 40.281 32.4348 36.1755 33.174 34.2865C33.5912 33.2219 34.4057 32.9729 34.4057 32.9729C34.4057 32.9729 32.9693 32.6896 32.9693 30.6734C32.9693 28.4977 34.6469 27.8813 35.8841 27.8813C36.952 27.8813 38.3851 29.1506 38.3065 30.5085C38.1837 32.6431 36.7871 33.0537 36.7871 33.0537C36.7871 33.0537 37.2386 33.3414 37.6901 34.1216C38.1416 34.9018 38.1826 39.1302 37.9369 40.6495H37.9358Z'
        fill='#127719'
      />
      <Path
        d='M30.0643 40.8411L29.3118 40.7814L29.221 41.9843L28.7098 41.9245L28.619 40.7814L27.3254 40.7216L27.0244 41.8349L26.543 41.7751L26.6028 40.5722H25.9111C25.9111 40.5722 26.0318 37.5633 26.5729 36.18C26.8783 35.3999 27.4759 35.2173 27.4759 35.2173C27.4759 35.2173 26.4224 35.0092 26.4224 33.533C26.4224 31.9383 27.6518 31.4924 28.5582 31.4868C29.5685 31.4813 30.3907 32.4175 30.3332 33.4124C30.2424 34.9771 29.2199 35.2781 29.2199 35.2781C29.2199 35.2781 29.5508 35.4884 29.8817 36.0605C30.2126 36.6326 30.2424 39.7312 30.0621 40.8444L30.0643 40.8411Z'
        fill='#127719'
      />
      <Path
        d='M25.0723 33.9404L24.499 34.3908C24.499 34.3908 26.2696 37.0113 27.9915 37.1209C30.5666 37.2846 32.4147 34.6232 32.4147 34.6232L31.9045 34.1319C31.9045 34.1319 29.2785 35.9467 28.2715 35.9202C27.2523 35.8925 25.0723 33.9415 25.0723 33.9415V33.9404Z'
        fill='#127719'
      />
    </Svg>
  )
}

type FamilyProps = {
  size?: number
  backgroundFill?: string
}
