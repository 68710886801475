import React from 'react'
import { reduxForm } from 'redux-form/immutable'
import CSSModules from 'react-css-modules'
import styles from './editPracticeInfo.module.scss'
import practiceInfoValidate from '../../../common/components/forms/practiceInfo/practiceInfoValidate'
import PracticeInfoForm from '../../../common/components/forms/practiceInfo/PracticeInfoForm'
import { PRACTICES_DETAILS_INFO } from '../../../common/constants/routingConstants'
import withRouter from '../../../routing/withRouter'

type Props = {
  handleSubmit?: (...args: any[]) => any
  submitting?: boolean
  router?: any
}

class EditPracticeInfo extends React.Component<Props> {
  _submitForm = (_: any) => {
    this.props.router.navigate(PRACTICES_DETAILS_INFO.route, { replace: true })
  }

  render() {
    const { handleSubmit, submitting } = this.props

    return (
      <div styleName='content-container'>
        <h2 styleName='title'>Edit Practice Info</h2>

        <PracticeInfoForm
          handleSubmit={handleSubmit}
          submitFunction={this._submitForm}
          submitting={submitting}
          showEmailField
          primaryButtonText='Save'
        />
      </div>
    )
  }
}

export default withRouter(
  reduxForm({
    form: 'editPracticeInfoForm',
    validate: practiceInfoValidate,
    touchOnBlur: false,
    // @ts-expect-error TS(2345): Argument of type 'typeof EditPracticeInfo' is not ... Remove this comment to see the full error message
  })(CSSModules(EditPracticeInfo, styles)),
)
