import React, { useState } from 'react'
import classNames from 'classnames'
import styles from './dashlet.module.scss'
import Skeleton from '../../../atoms/skeleton/Skeleton'
import ChevronIcon from '../../../atoms/icons/ChevronIcon'
import TextButton from '../../../atoms/buttons/textButton/TextButton'
import { times } from 'lodash-es'

const Dashlet: React.FC<DashletProps> = ({
  title,
  buttons,
  children,
  initiallyExpanded = false,
  isLoading = false,
  maxMinimizedChildren = 3,
  tabs,
  ...props
}) => {
  const [isExpanded, setIsExpanded] = useState(initiallyExpanded)

  const loadingContainerStyle = {
    '--loading-container-height': maxMinimizedChildren * 65 + 'px',
  } as React.CSSProperties

  const renderLoadingItem = () => {
    return (
      <div className={styles['loading-item']}>
        <div className={styles.left}>
          <Skeleton size='medium' />
        </div>
        <Skeleton size='icon' />
      </div>
    )
  }

  return (
    <div
      className={classNames(styles.dashlet, isExpanded ? styles['no-height-limit'] : styles['height-limit'])}
      {...props}
    >
      <div className={styles.title}>
        {title}
        {buttons}
      </div>
      {isLoading ? (
        <div
          data-test-id='Dashlet-loadingContainer'
          className={styles['loading-container']}
          style={loadingContainerStyle}
        >
          {times(maxMinimizedChildren, renderLoadingItem)}
        </div>
      ) : (
        <div
          className={classNames(
            isExpanded ? styles['children-container-show-all'] : styles['children-container-height-limited'],
          )}
          data-test-id='Dashlet-childrenContainer'
        >
          <>
            {tabs}
            {isExpanded ? children : React.Children.toArray(children).slice(0, maxMinimizedChildren)}
            {children.length > maxMinimizedChildren && (
              <div className={styles['expand-button']} data-test-id='Dashlet-expandButton'>
                <TextButton
                  onClick={() => {
                    setIsExpanded(!isExpanded)
                  }}
                >
                  <div>
                    See {isExpanded ? 'less' : 'more'} <ChevronIcon direction={isExpanded ? 'up' : 'down'} />
                  </div>
                </TextButton>
              </div>
            )}
          </>
        </div>
      )}
    </div>
  )
}

type DashletProps = {
  title: string
  buttons?: React.ReactNode
  children: any
  initiallyExpanded?: boolean
  isLoading?: boolean
  maxMinimizedChildren?: number
  tabs?: React.ReactNode
}

export default Dashlet
