import { createReducer } from '@reduxjs/toolkit'

import { PEMetrics } from './platformEngagementActions'
import {
  GET_PLATFORM_ENGAGEMENT_METRICS_CURRENT,
  GET_PLATFORM_ENGAGEMENT_METRICS_PREVIOUS,
} from '../../../../common/constants/reduxConstants'

type PlatformEngagementState = {
  current?: PEMetrics
  previous?: PEMetrics
}

const initialState = {}

export default createReducer<PlatformEngagementState>(initialState, (builder) => {
  builder.addCase(GET_PLATFORM_ENGAGEMENT_METRICS_CURRENT, (state, action: any) => {
    state.current = action.data
    return state
  })
  builder.addCase(GET_PLATFORM_ENGAGEMENT_METRICS_PREVIOUS, (state, action: any) => {
    state.previous = action.data
    return state
  })
})
