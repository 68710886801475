/* eslint-disable formatjs/no-literal-string-in-jsx */
import React, { FunctionComponent } from 'react'

import styled, { useTheme } from 'styled-components/native'

import { getStartOfNextPerformanceYear } from '@lyrahealth-inc/shared-app-logic'

import { DashboardUpdateInfo, ExternalLinkIcon } from '../../atoms'
import { Subhead, Size as SubheadSize } from '../../atoms/subhead/Subhead'
import { TextButton } from '../../atoms/textButton/TextButton'
import { PlatformEngagementYTDCard } from '../../organisms/platformEngagementYTDCard/PlatformEngagementYTDCard'
import { tID } from '../../utils'
import { ThemeType } from '../../utils/themes/ThemeProvider'

export interface PlatformEngagementProps {
  assignmentRate: number | null
  feedbackRate: number | null
  onLearnMoreClicked: () => void
  onViewQuarterlyDetailsClicked: () => void
  currentDate?: string
}

const Container = styled.View({
  flexGrow: 1,
})

const TitleContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  padding: theme.spacing['24px'],
  backgroundColor: theme.colors.backgroundPrimary,
  borderBottomWidth: '1px',
  borderBottomColor: theme.colors.borderDefault,
}))

const ContentContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  width: theme.breakpoints.isMinWidthDesktopL ? '1440px' : '100%',
  padding: '0 84px',
  margin: '0 auto',
}))

const Heading = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  paddingTop: theme.spacing['32px'],
  paddingBottom: theme.spacing['32px'],
  justifyContent: 'space-between',
  flexDirection: 'row',
  alignItems: 'center',
}))

const HeadingTextContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing['24px'],
}))

export const PlatformEngagement: FunctionComponent<PlatformEngagementProps> = ({
  assignmentRate,
  feedbackRate,
  onLearnMoreClicked,
  onViewQuarterlyDetailsClicked,
  currentDate,
}) => {
  const { colors } = useTheme()
  const resetDateTime = getStartOfNextPerformanceYear(currentDate ? new Date(currentDate) : new Date())

  return (
    <Container>
      <TitleContainer>
        <Subhead size={SubheadSize.LARGE} text='Platform Engagement' />
      </TitleContainer>
      <ContentContainer>
        <Heading>
          <HeadingTextContainer>
            <DashboardUpdateInfo
              resetDateTime={resetDateTime}
              resetsQuarterly={false}
              currentDate={currentDate ? new Date(currentDate) : undefined}
            />
          </HeadingTextContainer>
          <TextButton
            testID={tID('PlatformEngagementLearnMoreButton')}
            style={{ alignSelf: 'center' }}
            onPress={onLearnMoreClicked}
            text='Learn more'
            rightIcon={<ExternalLinkIcon size={24} strokeColor={colors.iconActive} />}
          />
        </Heading>
        <PlatformEngagementYTDCard
          assignmentRate={assignmentRate}
          feedbackRate={feedbackRate}
          onViewQuarterlyDetailsClicked={onViewQuarterlyDetailsClicked}
        />
      </ContentContainer>
    </Container>
  )
}
