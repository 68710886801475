import type { Router } from '@remix-run/router'

type NavigateArgs = Parameters<Router['navigate']>

/**
 * @param navigateArgs args passed to the current navigation, mutable
 * @param currentRouterState should be deep read-only, don't mutate the router's state
 */
export type NavigateListener = (navigateArgs: NavigateArgs, currentRouterState: Readonly<Router['state']>) => void

const listenersSym = Symbol('beforeNavigateListeners')

/**
 * Patches the router's navigate function to allow listening to navigations /before/
 * they take place. See UNSAFE_listenBeforeNavigate to attach listeners.
 *
 * Use with caution. If you wish to subscribe to router state changes,
 * see the useLocation() hook or Router.subscribe().
 *
 * This uses router.state, which is marked as internal and do not use,
 * so this may break between releases, hence marking it as UNSAFE.
 */
export function UNSAFE_patchRouterNavigate(router: Router) {
  if (router[listenersSym]) {
    console.warn('UNSAFE_patchRouterNavigate called more than once on the same router')
    return
  }

  const _navigate: Router['navigate'] = router.navigate.bind(router)

  const navigate: Router['navigate'] = async (...args) => {
    const navigateArgs = args as Parameters<Router['navigate']>
    const listeners = router[listenersSym] as Set<NavigateListener>
    if (listeners) {
      listeners.forEach((listener) => listener(navigateArgs, router.state))
    }
    return _navigate(...navigateArgs)
  }

  router.navigate = navigate
  router[listenersSym] = new Set<NavigateListener>()
}

/**
 * Adds listener function to navigate
 *
 * @param router router instance patched with UNSAFE_patchRouterNavigate
 * @param listener function to call before navigation starts
 * @returns a cleanup function to remove the listener
 */
export function UNSAFE_listenBeforeNavigate(router: Router, listener: NavigateListener) {
  const listeners = router[listenersSym] as Set<NavigateListener> | undefined
  if (!listeners) {
    throw new Error('UNSAFE_listenBeforeNavigate: router was not patched')
  }
  listeners.add(listener)
  return () => {
    listeners.delete(listener)
  }
}
