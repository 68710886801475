import React, { FunctionComponent } from 'react'

import styled from 'styled-components/native'

import { tID } from '../../utils'

// TODO: Expo 51, move to Image component in ui-cc, gif is broken in Expo 50 for certain sizes
// eslint-disable-next-line no-restricted-properties
const ImageContainer = styled.Image<{ size: string }>(({ size }) => ({
  width: size,
  height: size,
}))

export const GlobeAnimation: FunctionComponent<S4CLoadingAnimationProps> = ({ size = 200 }) => {
  const sizeInPixels = `${size}px`
  return (
    <ImageContainer
      dataSet={{ chromatic: 'ignore' }}
      size={sizeInPixels}
      testID={`${tID('GlobeAnimation')}`}
      // Using gif instead of lottie because this animation has rasterized elements
      source={require('../../assets/globe.gif')}
    />
  )
}

type S4CLoadingAnimationProps = {
  size?: number
}
