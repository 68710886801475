import React, { useEffect, useMemo, useState } from 'react'

import { AddToastFunction, RemoteParticipantStatus, Zoom, ZoomNetworkQuality } from './types'
import { useZoomConfig } from './useZoomConfig'
import { useZoomPlatformSpecific } from './useZoomPlatformSpecific'
import { ZoomContext } from './ZoomContext'

type ZoomProviderProps = {
  isProvider: boolean
}

const PARTICIPANT_REJOIN_START_SESSION_DELAY = 2000

export const ZoomProvider: React.FC<React.PropsWithChildren<ZoomProviderProps>> = ({ isProvider, children }) => {
  const config = useZoomConfig(isProvider)
  const [displayMode, setDisplayMode] = useState(config.defaultDisplayMode)
  const [sessionEndedCallback, setSessionEndedCallback] = useState<() => void>()
  const [sessionClosedCallback, setSessionClosedCallback] = useState<() => void>()
  const [addToast, setAddToast] = useState<AddToastFunction>()
  const [attached, setAttached] = useState(false)
  const [networkQuality, setNetworkQuality] = useState<ZoomNetworkQuality>(ZoomNetworkQuality.NONE)

  const zoomPlatformSpecific = useZoomPlatformSpecific({
    sessionEndedCallback,
    sessionClosedCallback,
    addToast,
    attached,
    setNetworkQuality,
  })
  const [remoteParticipantStatus, setRemoteParticipantStatus] = useState(RemoteParticipantStatus.WAITING)
  const value: Zoom = useMemo(() => {
    const isScreensharing =
      zoomPlatformSpecific.userId != null && zoomPlatformSpecific.userId === zoomPlatformSpecific.sharingUserId
    return {
      ...zoomPlatformSpecific,
      config,
      isScreensharing,
      remoteParticipantStatus,
      remoteParticipant:
        zoomPlatformSpecific.remoteParticipants.length > 0 ? zoomPlatformSpecific.remoteParticipants[0] : null,
      displayMode,
      setDisplayMode,
      setSessionEndedCallback: (sessionEndedCallback?: () => void) =>
        setSessionEndedCallback(() => sessionEndedCallback),
      setSessionClosedCallback: (sessionClosedCallback?: () => void) =>
        setSessionClosedCallback(() => sessionClosedCallback),
      remoteParticipantScreenSharing: !!zoomPlatformSpecific.sharingUserId && !isScreensharing,
      setAddToast: (addToast?: AddToastFunction) => setAddToast(() => addToast),
      setAttached,
      networkQuality,
    }
  }, [zoomPlatformSpecific, config, remoteParticipantStatus, displayMode, networkQuality])

  useEffect(() => {
    if (value.sessionStarted) {
      value.startAudio()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value.sessionStarted])

  useEffect(() => {
    if (!value.sessionStarted && value.isScreensharing) {
      value.stopScreenshare()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value.sessionStarted, value.isScreensharing, value.stopScreenshare])

  useEffect(() => {
    setRemoteParticipantStatus((prevParticipantStatus) => {
      if (value.remoteParticipants.length !== 0) {
        return RemoteParticipantStatus.READY
      }
      if (prevParticipantStatus !== RemoteParticipantStatus.WAITING && value.inSession) {
        return RemoteParticipantStatus.LEFT
      }

      return RemoteParticipantStatus.WAITING
    })
  }, [value.remoteParticipants, value.inSession])

  useEffect(() => {
    if (
      value.sessionStarted &&
      config.shouldStopSessionOnParticipantLeave &&
      value.remoteParticipantStatus === RemoteParticipantStatus.LEFT
    ) {
      value.setSessionStarted(false)
    }
  }, [value, config.shouldStopSessionOnParticipantLeave])

  useEffect(() => {
    if (value.sessionStarted && value.remoteParticipant) {
      setTimeout(() => value.startSession(), PARTICIPANT_REJOIN_START_SESSION_DELAY)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value.sessionStarted, value.remoteParticipant, value.startSession])

  useEffect(() => {
    const { joinTime, userIdentity, leaveSession, remoteParticipants } = value
    if (!joinTime || !userIdentity) {
      return
    }
    const existingUsersWithSameIdentity = remoteParticipants.filter(
      (participant) => participant.userIdentity === userIdentity,
    )

    if (existingUsersWithSameIdentity.some((user) => user.joinTime > joinTime)) {
      leaveSession({ rejoining: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value.joinTime, value.userIdentity, value.leaveSession, value.remoteParticipants])
  return <ZoomContext.Provider value={value}>{children}</ZoomContext.Provider>
}
