import React from 'react'
import colors from '../../../styles/_0colors.scss'
import { Icon } from './types'

const SparklesIcon: React.FC<SparklesIconProps> = ({ width = 24, fillColor = colors.x_light_gray_10, ...props }) => {
  return (
    <svg data-test-id={'SparklesIcon'} width={width} viewBox='0 0 24 24' {...props}>
      <g clipPath='url(#a)' fill={fillColor}>
        <path d='M10.432 19.373a.567.567 0 0 1-.562-.563c0-2.61-2.205-4.807-4.807-4.807a.567.567 0 0 1-.563-.563c0-.307.255-.562.563-.562 2.61 0 4.807-2.205 4.807-4.808 0-.307.255-.562.563-.562.307 0 .562.255.562.562 0 2.61 2.205 4.808 4.808 4.808.307 0 .562.255.562.562a.567.567 0 0 1-.562.563c-2.61 0-4.808 2.205-4.808 4.807a.567.567 0 0 1-.563.563ZM15.81 10.875a.567.567 0 0 1-.562-.563c0-.9-.787-1.687-1.688-1.687a.567.567 0 0 1-.562-.563c0-.307.255-.562.563-.562.9 0 1.687-.787 1.687-1.688 0-.307.255-.562.563-.562.307 0 .562.255.562.563 0 .9.787 1.687 1.688 1.687.307 0 .562.255.562.563a.567.567 0 0 1-.563.562c-.9 0-1.687.787-1.687 1.688a.567.567 0 0 1-.563.562Z' />
      </g>
      <path fill={fillColor} d='m10.512 9.75 3.662 3.762-3.762 3.662-3.662-3.762z' />
      <circle cx={15.75} cy={8.25} r={0.75} fill={fillColor} />
      <defs>
        <clipPath id='a'>
          <path fill='#fff' transform='translate(4.5 5.25)' d='M0 0h14.25v14.25H0z' />
        </clipPath>
      </defs>
    </svg>
  )
}

type SparklesIconProps = Icon

export default SparklesIcon
