import React, { forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle, useState } from 'react'
import { Field } from 'react-final-form'

import { FieldState, FormApi } from 'final-form'
import { cloneDeep, get, isEmpty, mapValues, merge, set } from 'lodash-es'

import { Contentmetadata, FieldValue } from '@lyrahealth-inc/shared-app-logic'
import { DataTable, SecondaryButton, XIcon } from '@lyrahealth-inc/ui-core'
import { CheckboxRFF } from '@lyrahealth-inc/ui-core-crossplatform'

import styles from './configureCheckIn.module.scss'

type ConfigureCheckInProps = {
  formValues: any
  metadata: Contentmetadata
  name?: string
  initialize: (formValues: any) => void
  form: FormApi
}

const ConfigureCheckIn: ForwardRefRenderFunction<ConfigureCheckInRef, ConfigureCheckInProps> = (
  { formValues, metadata, name = 'checkInConfig', initialize, form },
  ref,
) => {
  const [addingCustomField, setAddingCustomField] = useState(false)
  const [customFieldType, setCustomFieldType] = useState<string>('')
  const [customFieldTitle, setCustomFieldTitle] = useState<string>()
  const [customFieldsIndex, setCustomFieldsIndex] = useState(0)
  const [tableData, setTableData] = useState<Dict[]>([])
  const [customFields, setCustomFields] = useState({})
  const [customFieldMaximum, setCustomFieldMaximum] = useState<number>()

  const [maxFieldsReached, setMaxFieldsReached] = useState(false)

  useImperativeHandle(ref, () => ({
    configure(values) {
      return configure(values)
    },
  }))

  const tableColumns = [
    {
      Header: '',
      accessor: 'title',
      Cell: (row: any) => {
        const disabled =
          row.original.isRequired || (maxFieldsReached && !get(formValues, `${name}.${row.original.name}`))
        return (
          <div className={styles['row-wrapper']}>
            <Field
              name={`${name}.${row.original.name}`}
              component={CheckboxRFF}
              disabled={disabled}
              title={row.original.title}
              baseInputStyle={{ marginBottom: 0 }}
            />
            {row.original.custom ? (
              <XIcon
                fillColor={styles.x_light_gray_100}
                width='16'
                // @ts-expect-error TS(2322): Type '(event: any) => void' is not assignable to t... Remove this comment to see the full error message
                onClick={(event: React.MouseEvent<HTMLElement>) => removeCustomField(event, row.original)}
                style={{ position: 'absolute', right: 0, top: '35%' }}
              />
            ) : (
              ''
            )}
          </div>
        )
      },
    },
  ]

  useEffect(() => {
    const initialValues = formValues ? formValues : {}
    const assignmentValues = {}
    const fieldsMeta = metadata?.schema?.properties || {}
    // Formats data to:    property: true/false
    Object.entries(fieldsMeta).forEach(([key, value]) => {
      assignmentValues[key] = (value as any).show ?? true
    })

    initialValues[name] = assignmentValues
    initialize(initialValues)

    const tableData = Object.values(fieldsMeta).sort((a, b) => {
      if ((a as any).isRequired && !(b as any).isRequired) return -1
      if (((a as any).isRequired && (b as any).isRequired) || (!(a as any).isRequired && !(b as any).isRequired))
        return (a as any).show ? -1 : 1
      return 1
    })
    const customFieldsIndex = getCustomFieldsCount(fieldsMeta)
    setCustomFieldsIndex(customFieldsIndex)
    setTableData(tableData as Dict[])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const configure = (values: Dict) => {
    // We first start by grabbing the 'properties' of the fields. Such as, min value and max value.
    const metaDataSchemaProperties = get(metadata, 'schema.properties')
    let fieldsMetaData = mapValues(cloneDeep(metaDataSchemaProperties), (fieldMeta: any, fieldName: string) => {
      // We want to be really sure we are showing the ones that we want to show by default
      fieldMeta = set(fieldMeta, 'show', get(values, `${name}.${fieldName}`, false))
      return fieldMeta
    })

    // We'll want to maintain the original UI widgets if there are any present in the meta_data
    let metadataUISchema = get(metadata, 'uiSchema')
    // Here is the check for if there are custom fields present.
    if (!isEmpty(customFields)) {
      // We first begin by 'merging' or adding the custom field JSON's key and value to the end of our array
      // containing all of the 'properties' of our fields.
      fieldsMetaData = merge(
        fieldsMetaData,
        mapValues(customFields, (customField: any) => get(customField, 'schema')),
      )

      // Then we add the widgets associated with the custom fields into our separate widget array.
      metadataUISchema = merge(
        cloneDeep(metadataUISchema),
        mapValues(customFields, (customField: any) => get(customField, 'uiSchema')),
      )
    }
    // Now we update the entire metadata of our widgets with the proper widgets/uiSchema, including the custom fields(if there are any).
    metadata = set(cloneDeep(metadata), 'uiSchema', metadataUISchema)
    // Ensure every field that is shown has some default value. Integers should have a default of 0.
    metadata = set(
      metadata,
      'initialValues',
      mapValues(fieldsMetaData, (fieldMeta: any) => (get(fieldMeta, 'type') === 'integer' ? 0 : undefined)),
    )
    // Update the display layer once again to the client, but this time, the properties.
    metadata = set(metadata, 'schema.properties', fieldsMetaData)

    return metadata
  }

  const getCustomFieldsCount = (meta: any) => {
    if (!meta) return 0
    let counter = 0
    for (const key in meta) {
      if (meta[key].custom) {
        counter++
      }
    }
    return counter
  }

  const removeCustomField = (event: any, field: any) => {
    event.preventDefault()
    const newTableData = [...tableData]
    const newCustomFields = { ...customFields }

    newTableData.splice(tableData.indexOf(field), 1)

    delete newCustomFields[field.name]
    delete form[name[field.name]]

    // Remove the custom field from all the metadata if it was added by config()
    if (metadata?.schema?.properties?.[field.name]) {
      delete metadata.schema.properties[field.name]
    }

    if (metadata?.uiSchema?.[field.name]) {
      delete metadata.uiSchema[field.name]
    }

    if (metadata?.initialValues?.[field.name]) {
      delete metadata.initialValues[field.name]
    }

    const selectedFields = get(formValues, name)
    Object.keys(selectedFields).forEach((key) => {
      if (!selectedFields[key]) delete selectedFields[key]
    })

    setTableData(newTableData)
    setCustomFields(newCustomFields)
    setMaxFieldsReached(!(Object.keys(selectedFields).length <= 99))
  }

  const configureCustomField = () => {
    return (
      <div className={styles['add-custom-field-row']}>
        <div>
          <label className={styles['field-label']} htmlFor='custom-field-type'>
            Field Type
          </label>
          <select name='custom-field-type' onChange={(e) => setCustomFieldType(e.target.value)} id='custom-field-type'>
            <option value='' hidden>
              Select...
            </option>
            <option value='string'>Free text</option>
            <option value='integer'>Number</option>
            <option value='boolean'>Yes/No</option>
          </select>
        </div>
        {customFieldType === 'integer' ? (
          <div>
            <label className={styles['field-label']} htmlFor='custom-field-max-value'>
              Maximum
            </label>
            <input
              id='custom-field-max-value'
              type='number'
              min='0'
              value={customFieldMaximum || '10'}
              onChange={(e) => setCustomFieldMaximum(parseInt(e.target.value, 10))}
            />
          </div>
        ) : (
          ''
        )}
        <div style={{ flexBasis: '40%' }}>
          <label className={styles['field-label']} htmlFor='custom-field-diplayed-label'>
            Displayed Label
          </label>
          <input
            id='custom-field-diplayed-label'
            data-test-id='ConfigureCheckIn-displayed-label'
            type='text'
            onChange={(e) => setCustomFieldTitle(e.target.value)}
            style={{ width: '90%' }}
            disabled={!customFieldType}
          />
        </div>
        <SecondaryButton
          data-test-id='ConfigureCheckIn-save-button'
          onClick={createCustomField}
          disabled={!customFieldTitle || maxFieldsReached}
        >
          Save
        </SecondaryButton>
      </div>
    )
  }

  const createCustomFieldName = () => {
    const currentIndex = customFieldsIndex
    return `checkIn${currentIndex}`
  }

  const createCustomField = () => {
    const customField = {}
    const customName = createCustomFieldName()

    switch (customFieldType) {
      case 'string':
        ;(customField as any).schema = {
          custom: true,
          name: customName,
          show: true,
          title: customFieldTitle,
          type: 'string',
        }
        ;(customField as any).uiSchema = {
          'ui:widget': 'textarea',
        }
        break
      case 'boolean':
        ;(customField as any).schema = {
          custom: true,
          name: customName,
          show: true,
          title: customFieldTitle,
          type: 'boolean',
        }
        ;(customField as any).uiSchema = {
          'ui:widget': 'radio',
        }
        break
      case 'integer': {
        const maximum = customFieldMaximum || 10
        ;(customField as any).schema = {
          custom: true,
          default: 0,
          maximum,
          minimum: 0,
          name: customName,
          show: true,
          title: customFieldTitle,
          type: 'integer',
        }
        ;(customField as any).uiSchema = {
          'ui:widget': 'range',
        }
        break
      }
    }

    const newTableData = tableData.concat([(customField as any).schema])

    const newCustomFields = set(customFields, customName, customField)

    setTableData(newTableData)
    setCustomFields(newCustomFields)
    setAddingCustomField(false)
    setCustomFieldType('')
    setCustomFieldMaximum(10)
    setCustomFieldTitle('')
    setCustomFieldsIndex(customFieldsIndex + 1)

    // Set the intitial formValue for the custom field to true (checked) so it matches the 'show' schema value
    set(formValues, `${name}.${customName}`, true)

    // Register the custom field and subscribe to changes in the value
    form.registerField(
      `${name}.${customName}`,
      (fieldState: FieldState<FieldValue>) => {
        const { value, change } = fieldState
        // Update the formValues with the value of the checkbox
        change(value)
      },
      { value: true },
    )

    const selectedFields = get(formValues, name)
    Object.keys(selectedFields).forEach((key) => {
      if (!selectedFields[key]) delete selectedFields[key]
    })

    // 99 is an arbitrary number to represent no limit for allowed selections.
    setMaxFieldsReached(Object.keys(selectedFields).length >= 99)
  }

  const addCustomDisabled = Object.keys(customFields).length >= 99 || maxFieldsReached || addingCustomField
  return (
    <div className={styles.content}>
      <div className={styles['tables-container']}>
        <DataTable
          data={tableData}
          columns={tableColumns}
          pageSize={tableData.length}
          sortable={false}
          resizable={false}
          pagination={false}
        />
      </div>
      {addingCustomField ? configureCustomField() : ''}
      <SecondaryButton
        customClass={styles.customFieldButton}
        disabled={addCustomDisabled}
        onClick={() => setAddingCustomField(true)}
      >
        + Add custom field
      </SecondaryButton>
    </div>
  )
}

export type ConfigureCheckInRef = {
  configure: (values: Dict) => any
}

export default forwardRef(ConfigureCheckIn)
