import React, { FunctionComponent, MutableRefObject, useState } from 'react'
import { useIntl } from 'react-intl'
import { AccessibilityPropsAndroid, LayoutChangeEvent, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'

import { isNil } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import { Headline, Size as HeadlineSize } from '../../atoms/headline/Headline'
import { ArrowIcon, ArrowIconDirection } from '../../atoms/icons/ArrowIcon'
import { CloseIcon } from '../../atoms/icons/CloseIcon'
import { PressableOpacity } from '../../atoms/pressableOpacity/PressableOpacity'
import { Subhead, Size as SubheadSize } from '../../atoms/subhead/Subhead'
import { AccessibilityRolesNative, IS_WEB, Layout } from '../../constants'
import { ProgressBar } from '../../molecules/progressBar/ProgressBar'
import { ThemeType } from '../../utils/themes/ThemeProvider'
import { tID } from '../../utils/utils'

export type ActivityHeaderProps = {
  title?: string
  onBackPress?: () => void
  onClosePress?: () => void
  percentProgress?: number
  status?: string
  date?: string | null
  mode?: string
  backgroundColor?: string
  setHeaderHeight?: (height: number) => void
  a11yFocusedElementOnLoad?: MutableRefObject<any>
  progressBarAccessibilityLiveRegion?: AccessibilityPropsAndroid['accessibilityLiveRegion']
  isAtTopOfPage?: boolean
  isProviderPreview?: boolean
}

/**
 * A header component that sits above activity pages and displays metadata about
 * the assignment/response
 */

enum StatusLabel {
  draft = 'Draft saved',
  completed = 'Submitted',
}

/**
 * putting all custom styles on the bottom of the defined styled components override the predefined style
 */

const HeaderContainer = styled(SafeAreaView)<{
  backgroundColor: string
  theme: ThemeType
  hasTopBorder?: boolean
}>(
  ({
    hasTopBorder,
    backgroundColor,
    theme: {
      spacing,
      breakpoints: { isMinWidthTablet },
      colors,
    },
  }) => ({
    backgroundColor,
    padding: isMinWidthTablet ? `${spacing['16px']} ${spacing['24px']}` : `${spacing['12px']} ${spacing['16px']}`,
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 1,
    ...(hasTopBorder && {
      borderBottomColor: colors.borderDefault,
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
    }),
  }),
)

const Description = styled.View({
  justifyContent: 'center',
  alignItems: 'center',
})

const TitleContainer = styled.View({
  width: '100%',
  display: 'flex',
})

const CloseButton = styled(PressableOpacity)<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  position: 'absolute',
  right: '0px',
  top: spacing['4px'],
}))

const BackButton = styled(PressableOpacity)({
  position: 'absolute',
  left: '0px',
  top: '1px',
})

const TitleLabel = styled(Subhead)<{ theme: ThemeType; hasModeActivated: boolean }>(
  ({ theme: { spacing }, hasModeActivated }) => ({
    paddingBottom: hasModeActivated ? spacing['8px'] : spacing['4px'],
  }),
)

const HeadlineContainer = styled.View<{ theme: ThemeType; isVisible: boolean; hasModeActivated: boolean }>(
  ({ isVisible, hasModeActivated, theme: { spacing, colors } }) => ({
    opacity: isVisible ? 1 : 0,
    ...(hasModeActivated && {
      padding: `${spacing['4px']} ${spacing['16px']}`,
      borderRadius: '20px',
      borderColor: colors.components.activityHeader.headline.border,
      borderStyle: 'solid',
      borderWidth: '1px',
      alignSelf: 'center',
      marginBottom: '0px',
      backgroundColor: colors.backgroundHighlightTeal,
    }),
  }),
)

const ProgressBarComponent = styled(ProgressBar)({
  marginTop: '19px',
  marginBottom: '3px',
})

export const ActivityHeader: FunctionComponent<ActivityHeaderProps> = ({
  title = '',
  onBackPress,
  onClosePress,
  percentProgress,
  status,
  date,
  mode,
  backgroundColor,
  setHeaderHeight,
  a11yFocusedElementOnLoad,
  progressBarAccessibilityLiveRegion,
  isAtTopOfPage = false,
  isProviderPreview = false,
}) => {
  const [width, setWidth] = useState<number>(Layout.window.width)
  const { formatTime, formatMessage } = useIntl()
  const getStatus = (status?: string) => (status !== undefined ? StatusLabel[status] : '')
  const statusLabel = getStatus(status)
  const isSubmitted = statusLabel === StatusLabel.completed
  const submittedDateLabel = `${statusLabel} ${formatTime(date !== null ? date : new Date())}`.toUpperCase()
  const {
    breakpoints: { isMinWidthTablet },
    colors,
  } = useTheme()
  const hasModeActivated = Boolean(mode) || isSubmitted

  return (
    <HeaderContainer
      edges={['top']}
      testID={tID('ActivityHeader')}
      backgroundColor={isAtTopOfPage ? colors.backgroundSecondary : backgroundColor || colors.backgroundPrimary}
      hasTopBorder={!isAtTopOfPage}
      onLayout={(e: LayoutChangeEvent) => {
        setWidth(e.nativeEvent.layout.width)
        if (typeof setHeaderHeight === 'function') setHeaderHeight(e.nativeEvent.layout.height)
      }}
    >
      <TitleContainer>
        <Description>
          <View accessible ref={a11yFocusedElementOnLoad}>
            <TitleLabel
              size={SubheadSize.XSMALL}
              color={colors.textPrimary}
              textAlign='center'
              text={title}
              hasModeActivated={hasModeActivated}
            />
            {!isProviderPreview && (
              <HeadlineContainer
                isVisible={(Boolean(status) && Boolean(date)) || Boolean(mode)}
                hasModeActivated={hasModeActivated}
              >
                <Headline
                  testID={tID(hasModeActivated ? 'ActivityHeader-Mode' : 'ActivityHeader-Headline')}
                  size={HeadlineSize.EYEBROW}
                  color={hasModeActivated ? colors.textActive : colors.textSecondary}
                  // formatTime does not accept param that can be a null type, so we must fallback to an acceptable type
                  // addtionally, since this component is conditionally rendered when date is not null, incorrect dates will not be shown
                  text={
                    hasModeActivated ? (isSubmitted ? submittedDateLabel : mode?.toUpperCase()) : submittedDateLabel
                  }
                  textAlign='center'
                  accessibilityRole={
                    hasModeActivated
                      ? AccessibilityRolesNative.HEADER
                      : IS_WEB
                      ? AccessibilityRolesNative.TEXT
                      : undefined
                  }
                />
              </HeadlineContainer>
            )}
          </View>
        </Description>
        {!!onBackPress && (
          <BackButton
            onPress={onBackPress}
            hitSlop={18}
            testID={tID('ActivityHeader-backButton')}
            accessibilityLabel={formatMessage({
              defaultMessage: 'Back',
              description: 'Button to go back to the previous page',
            })}
          >
            <ArrowIcon size={26} fillColor={colors.iconDefault} direction={ArrowIconDirection.LEFT} />
          </BackButton>
        )}
        {!!onClosePress && (
          <CloseButton
            onPress={onClosePress}
            accessibilityRole='button'
            accessibilityLabel={formatMessage({
              defaultMessage: 'Close',
              description: 'button text Close',
            })}
            hitSlop={24}
            testID={tID('ActivityHeader-closeButton')}
            accessible
          >
            <CloseIcon size={13} fillColor={colors.iconDefault} />
          </CloseButton>
        )}
        {!isNil(percentProgress) && (
          <ProgressBarComponent
            color={colors.progressIndicator}
            progress={percentProgress}
            width={width - (isMinWidthTablet ? 48 : 32)}
            backgroundColor={colors.progressIndicatorBackgroundTop}
            height={4}
            borderRadius={100}
            duration={300}
            accessibilityLiveRegion={progressBarAccessibilityLiveRegion}
          />
        )}
      </TitleContainer>
    </HeaderContainer>
  )
}
