import React, { useState } from 'react'
import { TextArea, DefaultButton, InputField, SelectField } from '@lyrahealth-inc/ui-core'
import { reduxForm, Field } from 'redux-form/immutable'
import { paymentRejectionReasons } from '../../../constants/appConstants'
import { Map } from 'immutable'

const RejectPaymentForm: React.FC<RejectPaymentFormProps> = ({
  initialValues,
  showRejectText,
  submitting,
  submitFunction,
  handleSubmit,
}) => {
  const [rejectionReason, setRejectionReason] = useState(initialValues?.get('description') || '')
  const [rejectionReasonTextArea, setRejectionReasonTextArea] = useState(
    initialValues?.get('description_text_area') || '',
  )

  const renderRejectionOptions = () => {
    return [
      <option key={0} hidden>
        Select
      </option>,
      ...Object.keys(paymentRejectionReasons).map((reason, i) => (
        <option key={i + 1} value={reason}>
          {reason}
        </option>
      )),
      <option key={Object.keys(paymentRejectionReasons).length + 1} value='other'>
        Other
      </option>,
    ]
  }

  return (
    // @ts-expect-error TS(2322): Type 'void' is not assignable to type 'FormEventHa... Remove this comment to see the full error message
    <form onSubmit={handleSubmit(submitFunction)}>
      {showRejectText && (
        <div>
          <h3>
            <strong>Reject Payment</strong>
          </h3>
          <p>Please select the reason for rejecting the payment</p>
        </div>
      )}
      <Field name='charge_id' type='hidden' component={InputField} />
      <Field
        name='description'
        component={SelectField}
        onChange={(e: any) => {
          if (e.target.value !== 'other' && rejectionReasonTextArea) {
            setRejectionReasonTextArea('')
          }
          setRejectionReason(e.target.value)
        }}
      >
        {renderRejectionOptions()}
      </Field>
      {rejectionReason === 'other' && (
        <Field
          name='description_text_area'
          component={TextArea}
          onChange={(e: any) => setRejectionReasonTextArea(e.target.value)}
          placeholder='Reason for rejecting payment'
        />
      )}
      <DefaultButton
        id='requestreject-submit'
        type='submit'
        isLoading={submitting}
        disabled={
          !(rejectionReason && rejectionReason !== 'other') && !(rejectionReason === 'other' && rejectionReasonTextArea)
        }
      >
        {showRejectText ? 'Reject Payment' : 'Submit Comment'}
      </DefaultButton>
    </form>
  )
}

type RejectPaymentFormProps = {
  initialValues: Map<string, any>
  showRejectText: boolean
  submitting: boolean
  handleSubmit: (submitFunction: () => void) => void
  submitFunction: () => void
}

export default reduxForm({
  form: 'rejectPaymentForm',
  touchOnBlur: false,
  enableReinitialize: true,
  // @ts-expect-error TS(2345): Argument of type 'FC<RejectPaymentFormProps>' is n... Remove this comment to see the full error message
})(RejectPaymentForm)
