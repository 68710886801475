import React, { FunctionComponent, PropsWithChildren } from 'react'
import { View, ViewStyle } from 'react-native'

import styled from 'styled-components/native'

import { BodyTextSize, HeadlineSize } from '../../styles'
import { BodyText, BodyTextProps } from '../bodyText/BodyText'
import { Headline, HeadlineProps } from '../headline/Headline'

export type TableRowProps = PropsWithChildren<{ style?: ViewStyle; testID?: string }>
export type TableTitleProps = { size?: HeadlineSize } & Omit<HeadlineProps, 'size'>
export type TableCellProps = BodyTextProps
export type TableHeaderProps = PropsWithChildren<{ style?: ViewStyle }>
export type TableProps = PropsWithChildren<{ style?: ViewStyle }>

const RowView = styled.View({
  flexDirection: 'row',
})
const TitleView = styled(Headline)({
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: 0,
})
const CellView = styled(BodyText)({
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: 0,
})

export const Table: FunctionComponent<TableProps> = ({ children, ...props }) => {
  return <View {...props}>{children}</View>
}
export const TableHeader: FunctionComponent<TableHeaderProps> = ({ children, ...props }) => {
  return <RowView {...props}>{children}</RowView>
}
export const TableTitle: FunctionComponent<TableTitleProps> = ({ size = HeadlineSize.EYEBROW, ...props }) => {
  return <TitleView size={size} {...props} />
}
export const TableRow: FunctionComponent<TableRowProps> = ({ children, ...props }) => {
  return <RowView {...props}>{children}</RowView>
}
export const TableCell: FunctionComponent<TableCellProps> = ({ size = BodyTextSize.SMALL, ...props }) => {
  return <CellView size={size} {...props} />
}
