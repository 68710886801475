import React, { ReactNode } from 'react'
import classNames from 'classnames'

import LoadingIndicator from '../../loadingIndicator/LoadingIndicator'
import Dropdown from 'react-bootstrap/lib/Dropdown'
import MenuItem from 'react-bootstrap/lib/MenuItem'
import XIcon from '../../icons/XIcon'
import ChevronIcon from '../../icons/ChevronIcon'
import styles from './dropdownButton.module.scss'

type DropdownItem = {
  text?: string | ReactNode
  id: string
  selectHandler: (event?: React.SyntheticEvent<unknown>) => void
  subText?: string
  header?: boolean
}

type DropdownButtonProps = {
  isLoading?: boolean
  isDisabled?: boolean
  dropdownItems: DropdownItem[]
  styling?: string
  id?: string
  pullRight?: boolean
  pullLeft?: boolean
  contained?: boolean
  className?: string
  iconOnly?: boolean
  activeIndex?: number
  disabledIndex?: number
  menuItemsWrap?: boolean
  ariaLabel?: string
}

const CustomToggle: React.FC<ToggleProps> = React.forwardRef<HTMLButtonElement, ToggleProps>(
  (
    {
      id,
      contained,
      children,
      isLoading = false,
      isDisabled = false,
      open,
      styling,
      iconOnly = false,
      onClick,
      ariaLabel,
    },
    ref,
  ) => {
    const classes = classNames(
      styles['dropdown-button'],
      { [styles.open]: open },
      styling ? styles[`${styling}`] : styles.primary,
      {
        [styles.loading]: isLoading,
      },
      { [styles.contained]: contained },
      {
        [styles['icon-only']]: iconOnly,
      },
    )

    const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation()
      onClick && onClick()
    }
    return (
      <button
        ref={ref}
        type='button'
        disabled={isDisabled}
        className={classes}
        aria-expanded={open}
        onClick={handleClick}
        id={id}
        aria-label={ariaLabel}
      >
        {children}
        {isLoading && (
          <div className={styles['loading-container']}>
            <LoadingIndicator />
          </div>
        )}
        {!iconOnly && (
          <div className={styles['open-close-container']}>
            {open ? (
              <XIcon isFilled width={11} fillColor={styles.x_white} />
            ) : (
              <ChevronIcon isFilled fillColor={styles.x_white} direction='down' />
            )}
          </div>
        )}
      </button>
    )
  },
)

type ToggleProps = {
  children: React.ReactNode | React.ReactNode[]
  isLoading: boolean
  isDisabled: boolean
  open?: boolean
  secondary?: boolean
  contained: boolean
  styling?: string
  iconOnly: boolean
  bsRole: string
  id: string
  ariaLabel?: string
  onClick?: () => any
}

const DropdownButton: React.FC<DropdownButtonProps> = ({
  pullLeft = false,
  pullRight = false,
  contained = false,
  id = '',
  className,
  children,
  isLoading = false,
  isDisabled = false,
  dropdownItems,
  styling,
  iconOnly = false,
  activeIndex,
  disabledIndex,
  menuItemsWrap = true,
  ariaLabel,
}) => {
  let pullClass = ''
  if (pullLeft) {
    pullClass = styles['pull-left']
  } else if (pullRight) {
    pullClass = styles['pull-right']
  }

  return (
    <Dropdown id={id} className={classNames(className, pullClass)}>
      <CustomToggle
        contained={contained}
        isLoading={isLoading}
        styling={styling}
        isDisabled={isDisabled}
        iconOnly={iconOnly}
        ariaLabel={ariaLabel}
        bsRole='toggle'
        id={id}
      >
        {children}
      </CustomToggle>
      <Dropdown.Menu
        className={classNames(styles['dropdown-menu'], styling && styles[`${styling}`], {
          [styles['with-max-width']]: menuItemsWrap,
        })}
      >
        {dropdownItems.map((item, index) =>
          item.header ? (
            <div id={item.id} className={styles['list-header']} key={item.id}>
              {item.text}
            </div>
          ) : (
            <MenuItem
              key={item.id}
              className={classNames(
                styles['dropdown-list-item'],
                { [styles.active]: index === activeIndex },
                { [styles.disabled]: index === disabledIndex },
              )}
              id={item.id}
              onSelect={(eventKey: any, event?: React.SyntheticEvent<unknown>) => item.selectHandler(event)}
            >
              <div className={menuItemsWrap ? '' : styles['no-white-space']}>{item.text}</div>
              <div className={styles['sub-text']}>{item.subText}</div>
            </MenuItem>
          ),
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default DropdownButton
