import validation from '../../../utils/validation'

const practiceInfoValidate = ($$values: any) => {
  let errors = {}

  const justRequired = [
    {
      fieldName: 'practice_name',
      errorText: 'Practice name',
    },
    {
      fieldName: 'first_name',
      errorText: 'Practice admin first name',
    },
    {
      fieldName: 'last_name',
      errorText: 'Practice admin last name',
    },
    {
      fieldName: 'street',
      errorText: 'Address',
    },
    {
      fieldName: 'city',
      errorText: 'City',
    },
    {
      fieldName: 'state',
      errorText: 'State',
    },
    {
      fieldName: 'name_to_send_payments_to',
      errorText: 'Payment recipients',
    },
    {
      fieldName: 'payment_preference',
      errorText: 'Payment preference',
    },
    {
      fieldName: 'bank',
      errorText: 'Bank name',
    },
    {
      fieldName: 'account_name',
      errorText: 'Name on account',
    },
    {
      fieldName: 'identification_file_front',
      errorText: 'Identification document front',
    },
    {
      fieldName: 'identification_file_back',
      errorText: 'Identification document back',
    },
    {
      fieldName: 'tos',
      errorText: 'Terms of use consent',
    },
  ]

  errors = Object.assign(errors, validation.requiredFields($$values, justRequired))

  errors = Object.assign(
    errors,
    validation.requiredValidEmail($$values, {
      fieldName: 'email',
      errorText: 'Practice email',
    }),
  )

  errors = Object.assign(
    errors,
    validation.requiredValidDOB($$values, {
      fieldName: 'date_of_birth',
      errorText: 'Date of birth',
    }),
  )

  errors = Object.assign(
    errors,
    validation.requiredValidPhone($$values, {
      fieldName: 'phone',
      errorText: 'Phone',
    }),
  )

  errors = Object.assign(
    errors,
    validation.requiredValidNumberOfLength(
      $$values,
      {
        fieldName: 'zip_code',
        errorText: 'Zipcode',
      },
      5,
    ),
  )

  errors = Object.assign(
    errors,
    validation.requiredValidNumberOfLength(
      $$values,
      {
        fieldName: 'ssn',
        errorText: 'Social security number',
      },
      9,
    ),
  )

  errors = Object.assign(
    errors,
    validation.requiredValidNumberOfLength(
      $$values,
      {
        fieldName: 'routing_number',
        errorText: 'Routing number',
      },
      9,
    ),
  )

  errors = Object.assign(
    errors,
    validation.requiredValidNumber($$values, {
      fieldName: 'account_number',
      errorText: 'Account number',
    }),
  )

  errors = Object.assign(
    errors,
    validation.requiredValidNumberOfLength(
      $$values,
      {
        fieldName: 'account_identifier',
        errorText: 'TIN or EIN Number',
      },
      9,
    ),
  )

  return errors
}

export default practiceInfoValidate
