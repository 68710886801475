import React, { FunctionComponent } from 'react'

import styled from 'styled-components/native'

import { MuteBadge, ZoomNetworkIndicator } from '../../atoms'
import { useZoom } from '../../hooks/useZoom'
import { DisplayMode } from '../../hooks/useZoom/types'
import { useZoomVideoPlacement } from '../../hooks/useZoom/useZoomVideoPlacement'
import { RowView } from '../../templates'
import { tID } from '../../utils'
import { ZoomDisplayModeButton } from '../zoomDisplayModeButton/ZoomDisplayModeButton'
import { ZoomParticipantView } from '../zoomParticipantView/ZoomParticipantView'
import { ZoomSelfView } from '../zoomSelfView/ZoomSelfView'

const Container = styled(RowView)<{ show: boolean }>(({ show, theme }) => ({
  alignItems: 'center',
  gap: theme.spacing['8px'],
  display: show ? undefined : 'none',
}))

const VideoContainer = styled.View<{ show: boolean }>(({ show }) => ({
  width: '146px',
  height: '82px',
  borderRadius: '4px',
  overflow: 'hidden',
  display: show ? undefined : 'none',
}))

const DisplayButton = styled(ZoomDisplayModeButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing['4px'],
  left: theme.spacing['4px'],
  zIndex: 2,
}))

const MuteIcon = styled(MuteBadge)(({ theme }) => ({
  bottom: theme.spacing['8px'],
  left: theme.spacing['8px'],
}))

export type ZoomVideoBannerVideosProps = {
  participantFirstName?: string
  displayVideos: boolean
}

export const ZoomVideoBannerVideos: FunctionComponent<ZoomVideoBannerVideosProps> = ({
  participantFirstName,
  displayVideos,
}) => {
  const {
    sessionStarted,
    isScreensharing,
    muted,
    remoteParticipant,
    networkQuality,
    config: { canChangeDisplayMode },
  } = useZoom()
  const zoomVideoPlacement = useZoomVideoPlacement(DisplayMode.BANNER)
  const showSelfMaximizeBtn = !sessionStarted || !remoteParticipant

  return (
    <Container show={displayVideos || !isScreensharing}>
      <VideoContainer testID={tID('ZoomVideoBannerVideos-self')} show={zoomVideoPlacement.self}>
        {canChangeDisplayMode && !isScreensharing && showSelfMaximizeBtn && <DisplayButton isMaximize />}
        {muted && <MuteIcon />}
        <ZoomNetworkIndicator networkQuality={networkQuality} />
        {zoomVideoPlacement.self && <ZoomSelfView />}
      </VideoContainer>
      <VideoContainer testID={tID('ZoomVideoBannerVideos-participant')} show={zoomVideoPlacement.participant}>
        {canChangeDisplayMode && !isScreensharing && !showSelfMaximizeBtn && <DisplayButton isMaximize />}
        {sessionStarted && remoteParticipant?.isMuted && <MuteIcon />}
        <ZoomParticipantView participantFirstName={participantFirstName} show={zoomVideoPlacement.participant} />
      </VideoContainer>
    </Container>
  )
}
