import React, { FunctionComponent, useMemo } from 'react'
import { FormattedDateTimeRange, FormattedMessage } from 'react-intl'
import { Animated, Platform, ViewStyle } from 'react-native'

import { addMinutes } from 'date-fns'
import styled, { useTheme } from 'styled-components/native'

import { BodyText, GearIcon, Subhead } from '../../atoms'
import TertiaryIconButton from '../../atoms/tertiaryIconButton/TertiaryIconButton'
import { BodyTextSize, SubheadSize } from '../../styles'
import { ThemeType } from '../../utils'

const Container = styled(Animated.View)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  position: 'absolute',
  zIndex: 2,
  top: theme.spacing['16px'],
  left: theme.spacing['24px'],
  right: theme.spacing['24px'],
}))

const SessionDetailsContainer = styled.View(({ theme }) => ({
  gap: theme.spacing['4px'],
}))

export type ZoomMobileHeaderProps = {
  participantName: string
  sessionStartDate: Date
  sessionDuration: number
  sessionNumber?: number
  openSettings?: () => void
  style?: ViewStyle
  hidden: boolean
}

export const ZoomMobileHeader: FunctionComponent<ZoomMobileHeaderProps> = ({
  participantName,
  sessionStartDate,
  sessionDuration,
  sessionNumber,
  openSettings,
  hidden,
  style,
}) => {
  const theme = useTheme() as ThemeType

  const sessionEndDate = useMemo(
    () => addMinutes(sessionStartDate, sessionDuration),
    [sessionStartDate, sessionDuration],
  )
  return (
    <Container style={style} pointerEvents={hidden ? 'none' : 'auto'}>
      <SessionDetailsContainer>
        <Subhead
          size={SubheadSize.SMALL}
          text={
            <FormattedMessage
              defaultMessage='{participantName} • Session {sessionNumber}'
              description='Title of video session with participant name and session number'
              values={{ participantName, sessionNumber }}
            />
          }
        />
        <BodyText
          size={BodyTextSize.SMALL}
          text={
            <FormattedDateTimeRange
              from={sessionStartDate}
              to={sessionEndDate}
              hour='numeric'
              minute='numeric'
              timeZoneName='short'
            />
          }
        />
      </SessionDetailsContainer>
      {Platform.OS === 'web' && (
        <TertiaryIconButton leftIcon={<GearIcon color={theme.colors.iconDefault} />} onPress={() => openSettings?.()} />
      )}
    </Container>
  )
}
