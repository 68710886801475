import React, { FunctionComponent, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import CSSModules from 'react-css-modules'
import { FormattedMessage } from 'react-intl'
import { connect, ConnectedProps, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import { isAfter, sub } from 'date-fns'
import { List, Map } from 'immutable'
import { get, isEmpty, isNil } from 'lodash-es'
import { bindActionCreators } from 'redux'
import { v4 as uuidv4 } from 'uuid'

import {
  Appointment,
  canSeeDirectBookingLink,
  Episode,
  Message,
  MessageAttributes,
  parseAllowedMessages,
  useFlags,
} from '@lyrahealth-inc/shared-app-logic'
import { useSaveMessageDraft } from '@lyrahealth-inc/ui-core'
import {
  Chat,
  CloseStyles,
  DirectBookingLinkInline,
  DirectBookingLinkMessageAttachment,
  MessageAttachmentType,
  MessageType,
  toJS,
  useFetcher,
} from '@lyrahealth-inc/ui-core-crossplatform'

import {
  getMessage,
  getMessages,
  saveMessageDraft,
  submitMessage,
  toggleLiveMsgSession,
  updateSelectedConversationId,
  updateUnreadMessageCount,
} from './data/messagesAutoActions'
import {
  getConversationMessages,
  getConversationsClient,
  getMessagesFetched,
  getMessengerDrafts,
  getSelectedConversation,
  inLiveMsgSession,
} from './data/messagesSelectors'
import styles from './messagesPopover.module.scss'
import { actions, mixpanelEvents } from '../../../../mixpanel/mixpanelConstants'
import { track } from '../../../../mixpanel/mixpanelTracking'
import { SUPERVISOR_ROLES } from '../../common/constants/appConstants'
import { CLIENTS_ASSIGNMENT_DETAILS, CLIENTS_SESSIONS } from '../../common/constants/routingConstants'
import { getClientFullName, hasRole } from '../../common/utils/utils'
import { getAuthSupervisor, getAuthUser, getAuthUserId } from '../../data/auth/authSelectors'
import {
  getClientAppointmentsData,
  getClientDetailsData,
  getClientEpisodesData,
  getSelectedEpisodeProgram,
} from '../../data/lyraTherapy/clientSelectors'
import { clearSelectedAssignment, getAssignment } from '../assignments/data/assignmentsAutoActions'
import { getLTAppointmentsForPatient } from '../clients/clientDetails/data/appointmentsAutoActions'
import { setToastContent } from '../data/ltToastAutoActions'
import { getClientEpisodes as getClientEpisodesAction } from '../episodes/data/episodesAutoActions'

const messageReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'SET_INPUT': {
      return { ...state, inputValue: action.inputValue }
    }
    case 'ON_TYPING': {
      return { ...state, receiverTyping: action.receiverTyping }
    }
    case 'NEW_MESSAGE': {
      return { ...state, newMessageCount: state.newMessageCount + 1, receiverTyping: false }
    }
    case 'READ_MESSAGE': {
      return { ...state, newMessageCount: 0 }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

type MessagePopoverProps = ConnectedProps<typeof connector> & {
  closePopover: () => void
  onUpdateMessageCount?: () => void
}

export const MessagesPopover: FunctionComponent<MessagePopoverProps> = ({
  client,
  selectedConversation,
  draft,
  messages,
  conversationsClient,
  messagesFetched,
  closePopover,
  inLiveMsgSession,
  selectedEpisodeProgram,
  onUpdateMessageCount,
  episodes,
  appointments,
  actions: {
    getMessage,
    getMessages,
    submitMessage,
    updateUnreadMessageCount,
    clearSelectedAssignment,
    saveMessageDraft,
    toggleLiveMsgSession,
    updateSelectedConversationId,
    setToastContent,
    getLTAppointmentsForPatient,
    getClientEpisodesAction,
  },
}: any) => {
  const providerId: string = useSelector(getAuthUserId)
  const supervisor = useSelector(getAuthSupervisor)
  const provider = useSelector(getAuthUser)
  const [state, dispatch] = useReducer(messageReducer, {
    inputValue: '',
    receiverTyping: false,
    newMessageCount: 0,
  })

  const isSupervisor = supervisor && !isEmpty(supervisor.roles) && hasRole(supervisor.roles, SUPERVISOR_ROLES)
  const { receiverTyping, inputValue, newMessageCount } = state
  const messageContainer = useRef(null)
  const { isPreferredNameEnabled, shouldEnableDBLAttachment, supervisorsThatCanMarkConversationsAsRead } = useFlags()
  const [addedDBLAttachment, setAddedDBLAttachment] = useState(false)
  const markMessagesAsRead = useCallback(async () => {
    const unreadMessageCount = selectedConversation?.conversation_attributes?.unread_provider_messages_count ?? 0
    if (unreadMessageCount > 0 && isEmpty(supervisor)) {
      dispatch({ type: 'READ_MESSAGE' })
      try {
        await updateUnreadMessageCount({
          conversationId: selectedConversation?.conversation_id,
          unread_provider_messages_count: 0,
        })
      } catch (e) {
        console.error(`Unable to update unread message - ${e}`)
      }
    }
  }, [updateUnreadMessageCount, selectedConversation, supervisor])
  const markMessagesAsUnread = useCallback(async () => {
    try {
      track({ event: mixpanelEvents.BUTTON_PRESS, action: actions.MARK_MESSAGE_UNREAD })
      await updateUnreadMessageCount({
        conversationId: selectedConversation?.conversation_id,
        unread_provider_messages_count: 1,
      })
      setToastContent({
        text: 'Done! Successfully marked message as unread',
        id: 'MessagesPopover-Toast-Success',
        toastType: 'success',
      })
    } catch (e) {
      console.error(`Unable to update unread message - ${e}`)
    } finally {
      onUpdateMessageCount && onUpdateMessageCount()
    }
  }, [updateUnreadMessageCount, selectedConversation, onUpdateMessageCount, setToastContent])

  // memoized save draft with updated inputValue
  const { saveDraft } = useSaveMessageDraft({
    saveMessageDraft,
    inputValue,
    selectedConversationId: selectedConversation?.conversation_id,
  })

  // tell the conversation that we are typing
  const onTyping = useCallback(async () => {
    if (conversationsClient) {
      const activeConversation = await conversationsClient.getConversationBySid(selectedConversation?.conversation_id)
      activeConversation.typing()
    }
  }, [conversationsClient, selectedConversation])

  const setInputValue = useCallback((value) => dispatch({ type: 'SET_INPUT', inputValue: value ?? '' }), [])

  const setInputValueAndStartTypingIndicator = (value: string) => {
    setInputValue(value)
    onTyping()
  }

  const [loadingMessages, , hasFetchedMessages] = useFetcher(
    [getMessages, { conversationId: selectedConversation?.conversation_id }, selectedConversation?.conversation_id],
    [selectedConversation?.conversation_id],
  )

  useEffect(() => {
    setInputValue(draft)
  }, [draft, setInputValue])

  const onReceiverTyping = useCallback(
    (member) => {
      // ensure that the typing started event comes from the selected conversation
      if (member.conversation.sid === selectedConversation?.conversation_id) {
        dispatch({ type: 'ON_TYPING', receiverTyping: member.isTyping })
      }
    },
    [selectedConversation],
  )

  const onNewMessage = useCallback(
    async (message) => {
      if (
        get(message, 'state.attributes.from') === providerId ||
        message.conversation.sid !== selectedConversation?.conversation_id
      ) {
        return
      }
      await getMessage({ conversationId: selectedConversation?.conversation_id, messageId: message.sid })
      dispatch({ type: 'NEW_MESSAGE' })
    },
    [selectedConversation, getMessage, providerId],
  )

  // save draft if the component unmounts
  useEffect(
    () => () => {
      saveDraft()
      // clear live messaging in session if component is unmounted anywhere
      if (inLiveMsgSession) toggleLiveMsgSession(false)
    },
    [inLiveMsgSession, saveDraft, toggleLiveMsgSession],
  )

  const handleOutsideClick = useCallback(
    (e) => {
      e.stopPropagation()
      if (messageContainer.current) {
        const chatBoundingRectangle = (messageContainer.current as HTMLElement).children[0].getBoundingClientRect()
        const isTargetInsideMessageContainer =
          e.clientX > chatBoundingRectangle.left &&
          e.clientX < chatBoundingRectangle.right &&
          e.clientY > chatBoundingRectangle.top &&
          e.clientY < chatBoundingRectangle.bottom
        if (!isTargetInsideMessageContainer) {
          closePopover()
        }
      }
    },
    [closePopover],
  )

  // Clear unread messages when component is mounted and messages have already been fetched
  const hasValidSupervisor = useMemo(() => {
    return isEmpty(supervisor) || supervisorsThatCanMarkConversationsAsRead?.includes(supervisor?.id)
  }, [supervisor, supervisorsThatCanMarkConversationsAsRead])

  useEffect(() => {
    const updateMessage = async () => {
      try {
        await updateUnreadMessageCount({
          conversationId: selectedConversation?.conversation_id,
          unread_provider_messages_count: 0,
        })
      } catch (e) {
        console.error(`Unable to update unread message - ${e}`)
      }
    }
    if (messagesFetched && selectedConversation?.conversation_id && hasValidSupervisor) {
      updateMessage()
    }
  }, [messagesFetched, selectedConversation?.conversation_id, updateUnreadMessageCount, hasValidSupervisor])

  useEffect(() => {
    window.addEventListener('mousedown', handleOutsideClick, false)
    return () => {
      window.removeEventListener('mousedown', handleOutsideClick, false)
    }
  }, [handleOutsideClick])

  useEffect(() => {
    if (conversationsClient) {
      conversationsClient.on('messageAdded', onNewMessage)
      conversationsClient.on('typingStarted', onReceiverTyping)
      conversationsClient.on('typingEnded', onReceiverTyping)
    }
    return () => {
      if (conversationsClient) {
        conversationsClient.off('messageAdded', onNewMessage)
        conversationsClient.off('typingStarted', onReceiverTyping)
        conversationsClient.off('typingEnded', onReceiverTyping)
      }
    }
  }, [conversationsClient, onNewMessage, onReceiverTyping])

  const submitNewMessage = useCallback(
    (value) => {
      if (selectedConversation?.patient_lyra_id === client?.id) {
        let metadata = null
        if (addedDBLAttachment) {
          metadata = {
            attachments: [
              {
                key: uuidv4(),
                type: MessageAttachmentType.DBL,
              },
            ],
          }
        }
        const data = {
          conversationId: selectedConversation?.conversation_id,
          message_body: value,
          message_type: 'direct',
          message_author_id: providerId,
          message_author_type: 'provider',
          metadata,
        }
        submitMessage(data).then((response: any) => {
          if ('new_conversation_id' in response) {
            updateSelectedConversationId({
              oldConversationId: selectedConversation?.conversation_id,
              newConversationId: response.new_conversation_id,
            })
          }
        })
        saveMessageDraft({ content: '', conversationId: selectedConversation?.conversation_id })
        setInputValue('')
        setAddedDBLAttachment(false)
        track({ event: 'SEND_MESSAGE', details: { program: selectedEpisodeProgram } })
      }
    },
    [
      selectedConversation?.patient_lyra_id,
      selectedConversation?.conversation_id,
      client?.id,
      addedDBLAttachment,
      providerId,
      submitMessage,
      saveMessageDraft,
      setInputValue,
      selectedEpisodeProgram,
      updateSelectedConversationId,
    ],
  )

  const navigate = useNavigate()

  const handleLinkClickNew = useCallback(
    (messageId, receiver, { id, responseId, activityType }) => {
      switch (activityType) {
        case 'feedback':
          clearSelectedAssignment()
          navigate(CLIENTS_ASSIGNMENT_DETAILS.route, {
            state: { assignmentId: id, responseId: responseId },
          })
          break
        case 'session_cancellation':
        case 'request_session_time':
          navigate(CLIENTS_SESSIONS.route)
          break
      }
      closePopover()
    },
    [clearSelectedAssignment, closePopover, navigate],
  )

  useFetcher([
    [getClientEpisodesAction, { provider_id: provider.id, patient_id: client.id }, episodes.length > 0],
    [getLTAppointmentsForPatient, { provider_id: provider.id, patient_id: client.id }, appointments.length > 0],
  ])

  const canAttachDBL = useMemo(
    () => canSeeDirectBookingLink({ provider, episodes, appointments }),
    [appointments, episodes, provider],
  )

  const attachDBLDisabled = !canAttachDBL || addedDBLAttachment

  const getMessageType = (attributes: MessageAttributes) => {
    const linkText = attributes.metadata?.linkText
    const activityResponseId = attributes.metadata?.activity_response_id
    const activityId = attributes.metadata?.activity_id
    const isDeletedMessage =
      !isNil(attributes?.retention_status) && attributes?.retention_status === 'DELETED_LIVE_MESSAGE'
    const isActivity = Boolean(linkText) || (Boolean(activityResponseId) && Boolean(activityId))

    if (isDeletedMessage) {
      return MessageType.DELETED
    } else if (isActivity) {
      return MessageType.ACTIVITY
    } else {
      return MessageType.CHAT_BUBBLE
    }
  }

  const memoizedMessages = useMemo(() => {
    if (!messages) return []
    const msgs = parseAllowedMessages(messages).map((m, index) => {
      const attributes = JSON.parse(m.attributes)
      const isPatient = attributes.author_type === 'patient'
      const previousMessage = messages[index - 1]
      let showTime = true
      const dateCreated = new Date(m.date_created)
      // we only want to show the time stamp if 10 minutes have passed
      showTime =
        index === 0 ? true : isAfter(sub(dateCreated, { minutes: 10 }), new Date(previousMessage?.date_created))
      return {
        authorType: attributes.author_type,
        messageId: m.message_id,
        receiver: isPatient,
        dateCreated: m?.date_created,
        message: m.body,
        isLatestMessage: index === messages.length - 1,
        // if the message has no status, it has been sent
        sendStatus: m.sendStatus === undefined ? 'sent' : m.sendStatus,
        showTime,
        linkText: attributes.metadata?.linkText,
        activityResponseId: attributes.metadata?.activity_response_id,
        activityId: attributes.metadata?.activity_id,
        submitDate: attributes.metadata?.submit_date,
        activityTitle: attributes.metadata?.title,
        timeZone: provider.time_zone,
        type: getMessageType(attributes),
        messageType: attributes.message_type,
        showAvatar: false,
        attachments: attributes.metadata?.attachments,
      }
    })
    return msgs.reverse()
  }, [messages, provider.time_zone])

  return (
    <>
      <div className='popover-container' styleName='popover-container' ref={messageContainer}>
        <Chat
          displayName={getClientFullName(client, isPreferredNameEnabled)}
          chatHeaderTitle={
            inLiveMsgSession
              ? 'In-session messaging'
              : isPreferredNameEnabled
              ? client?.preferred_first_name ?? client?.first_name
              : client?.first_name
          }
          liveSessionBadge={inLiveMsgSession ? 'LIVE SESSION' : ''}
          onBack={closePopover}
          onSendMessage={submitNewMessage}
          onInputChange={setInputValueAndStartTypingIndicator}
          messages={memoizedMessages}
          receiverTyping={receiverTyping}
          receiverMsgable={true}
          unreadMsgCount={newMessageCount}
          scrollInfo={{ scrollToEnd: false, animatedScroll: false }}
          showNewMsgIndicator={newMessageCount > 0}
          onNewMsgPressed={markMessagesAsRead}
          onChatBubblePressed={handleLinkClickNew}
          closeStyle={CloseStyles.BackArrow}
          showChevron={true}
          inputValue={inputValue}
          conversationDateCreated={selectedConversation?.conversation_date_created}
          isLoading={loadingMessages || !hasFetchedMessages}
          showClose={false}
          markMessagesAsUnread={markMessagesAsUnread}
          showMenu={!isSupervisor}
          shouldInvert={true}
          inputActions={
            shouldEnableDBLAttachment
              ? [
                  <DirectBookingLinkInline
                    key='DBL'
                    onPress={() => setAddedDBLAttachment(true)}
                    disabled={attachDBLDisabled}
                    disabledTooltipContent={
                      !canAttachDBL ? (
                        <FormattedMessage
                          defaultMessage='Unable to send direct booking link to this client'
                          description='Tooltip on DBL attachment button when provider cannot add attachment'
                        />
                      ) : (
                        <FormattedMessage
                          defaultMessage='A direct booking link is already 
                    inserted into the message'
                          description='Tooltip on DBL attachment button when attachment already added'
                        />
                      )
                    }
                  />,
                ]
              : []
          }
          inputAttachments={
            addedDBLAttachment
              ? [
                  <DirectBookingLinkMessageAttachment
                    key='DBLAttachment'
                    onPress={() => {}}
                    onClosePress={() => setAddedDBLAttachment(false)}
                    showClose
                  />,
                ]
              : []
          }
        />
      </div>
    </>
  )
}

type StateProps = {
  client: any
  selectedEpisodeProgram?: string
  messages: Message[]
  messagesFetched: boolean
  conversationsClient: any
  draft: string
  inLiveMsgSession: boolean
  selectedConversation: any
  episodes: Episode[]
  appointments: Appointment[]
}

const mapStateToProps = (state: Map<string, any>): StateProps => {
  const selectedConversation = getSelectedConversation(state) ?? Map()
  const draft = getMessengerDrafts(state).find(
    (draft: Message) => draft.conversationId === selectedConversation.conversation_id,
    Map(),
  )
  return {
    client: getClientDetailsData(state),
    selectedConversation: selectedConversation,
    messages: getConversationMessages(state) ?? List(),
    messagesFetched: getMessagesFetched(state),
    conversationsClient: getConversationsClient(state),
    draft: draft?.content,
    inLiveMsgSession: inLiveMsgSession(state),
    selectedEpisodeProgram: getSelectedEpisodeProgram(state),
    episodes: getClientEpisodesData(state),
    appointments: getClientAppointmentsData(state),
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      getMessages,
      submitMessage,
      getAssignment,
      clearSelectedAssignment,
      updateUnreadMessageCount,
      getMessage,
      toggleLiveMsgSession,
      saveMessageDraft,
      updateSelectedConversationId,
      setToastContent,
      getLTAppointmentsForPatient,
      getClientEpisodesAction,
    },
    dispatch,
  ),
})

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(toJS(CSSModules(MessagesPopover, styles)))
