import { CancelToken } from 'axios'
import { AnyAction, DeepPartial, Dispatch } from 'redux'

import {
  Assignment,
  AssignmentResponse,
  ExerciseDraftPayload,
  setAssignmentInterface,
} from '@lyrahealth-inc/shared-app-logic'

import {
  CLEAR_ASSIGNMENT,
  CLEAR_ASSIGNMENT_RESPONSE,
  DECREASE_ASSIGNMENT_UNREAD_COUNT,
  DECREASE_SELECTED_ASSIGNMENT_UNREAD_COUNT,
  DECREASE_SELECTED_CLIENT_UNREAD_COUNT,
  DELETE_ASSIGNMENT_RESPONSE,
  GET_ASSIGNMENTS,
  SELECT_ASSIGNMENT,
  SELECT_ASSIGNMENT_RESPONSE,
  SET_ASSIGNMENT,
  SET_ASSIGNMENT_RESPONSE,
  UNSET_ASSIGNMENT,
  UPDATE_ASSIGNMENT,
  UPDATE_ASSIGNMENT_RESPONSE,
} from '../../../common/constants/reduxConstants'

export const getAssignments = ({
  patient_id,
  provider_id,
  cancelToken,
}: {
  patient_id?: string
  provider_id?: string
  cancelToken?: CancelToken
}) => ({
  action: GET_ASSIGNMENTS,
  request: {
    method: 'get',
    url: '/lt/v1/assignments',
    params: { patient_id, provider_id },
    cancelToken,
  },
})

export const getAssignment = ({ id }: { id: string }) => ({
  action: SELECT_ASSIGNMENT,
  request: {
    method: 'get',
    url: `/lt/v1/assignments/${id}`,
  },
})

export const selectAssignment = (data: Assignment) => ({
  type: SELECT_ASSIGNMENT,
  data,
})

export const setAssignment = (data: setAssignmentInterface) => ({
  action: SET_ASSIGNMENT,
  request: {
    method: 'post',
    url: '/lt/v1/assignments',
    data,
  },
})

export const updateAssignment = (data: DeepPartial<Assignment>) => ({
  action: UPDATE_ASSIGNMENT,
  request: {
    method: 'put',
    url: `/lt/v1/assignments/${data.id}`,
    data,
  },
})

export const patchAssignment = (id: string, data: { session_period: number }) => ({
  action: UPDATE_ASSIGNMENT,
  request: {
    method: 'patch',
    url: `/lt/v1/assignments/${id}`,
    data,
  },
})

export const unsetAssignment = ({ id }: { id: string }) => ({
  action: { type: UNSET_ASSIGNMENT, data: id },
  request: {
    method: 'delete',
    url: `/lt/v1/assignments/${id}`,
  },
})

export const setAssignmentResponse = (data: ExerciseDraftPayload) => ({
  action: SET_ASSIGNMENT_RESPONSE,
  request: {
    method: 'post',
    url: `/lt/v1/assignmentResponses/${data.assignment_id}`,
    data,
  },
})

export const setAssignmentDraft = (data: ExerciseDraftPayload) => ({
  request: {
    method: 'post',
    url: `/lt/v1/unassignedAssignmentResponse`,
    data,
  },
})

export const setAssignmentDraftResponse = (data: DeepPartial<AssignmentResponse>) => ({
  type: SELECT_ASSIGNMENT_RESPONSE,
  data,
})

export const setAssignmentDraftResponseToAssignment = (data: DeepPartial<AssignmentResponse>) => ({
  action: SET_ASSIGNMENT_RESPONSE,
  request: {
    method: 'put',
    url: `/lt/v1/unassignedAssignmentResponse/${data.id}`,
    data,
  },
})

export const updateAssignmentResponse = (data: DeepPartial<AssignmentResponse>) => ({
  action: UPDATE_ASSIGNMENT_RESPONSE,
  request: {
    method: 'put',
    url: `/lt/v1/assignmentResponse/${data.id}`,
    data,
  },
})

export const deleteAssignmentResponse = (data: AssignmentResponse) => ({
  action: { type: DELETE_ASSIGNMENT_RESPONSE, data },
  request: {
    method: 'delete',
    url: `/lt/v1/assignmentResponse/${data.id}`,
    data,
  },
})

export const clearAssignmentResponse = () => ({
  type: CLEAR_ASSIGNMENT_RESPONSE,
})

export const clearSelectedAssignment = () => ({
  type: CLEAR_ASSIGNMENT,
})

export const decreaseAssignmentUnreadCount = ({ assignmentId }: { assignmentId: string }) => {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch({ type: DECREASE_SELECTED_ASSIGNMENT_UNREAD_COUNT })
    dispatch({ type: DECREASE_ASSIGNMENT_UNREAD_COUNT, data: { assignmentId } })
    dispatch({ type: DECREASE_SELECTED_CLIENT_UNREAD_COUNT })
  }
}
