import React, { FunctionComponent, useRef, useState } from 'react'
import { FieldRenderProps } from 'react-final-form'
import { useIntl } from 'react-intl'
import { Pressable } from 'react-native'

import BottomSheetGorhom from '@gorhom/bottom-sheet'
import styled, { useTheme } from 'styled-components/native'

import { CheckboxGroup, CheckBoxGroupValue } from './CheckboxGroup'
import { useAccessibilityFocus } from '../../hooks/useAccessibilityFocus'
import { useMediaQuerySize } from '../../hooks/useMediaQuerySize'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { Modal } from '../../organisms/modal/Modal'
import { BodyTextSize, TextType } from '../../styles/typeStyles'
import { ThemeType, tID } from '../../utils'
import { BaseInput } from '../formElements/BaseInput'
import { CheckboxButtonType } from '../formElements/Checkbox'
import { ChevronIcon, ChevronIconDirection } from '../icons/ChevronIcon'
import { CloseIcon } from '../icons/CloseIcon'
import { PrimaryButton } from '../primaryButton/PrimaryButton'
import { TruncatedText } from '../truncate/TruncatedText'

import type * as CSS from 'csstype'

export type MultiSelectProps = {
  label?: string
  subLabel?: string
  labelAlignment?: CSS.Properties['alignItems']
  value: (string | boolean)[]
  onChange: (arg: (string | boolean)[]) => void
  error?: string
  name?: string
  readOnly?: boolean
  options: Array<{
    id: string
    text: string | React.ReactNode
    group?: number
    buttonType?: CheckboxButtonType
    badge?: string
  }>
  active?: boolean
  isInModal?: boolean
}

const InputFieldContainer = styled.Pressable<{ theme: ThemeType }>(({ theme }) => ({
  height: '46px',
  width: '100%',
  border: `1px solid ${theme.colors.inputOutlineDefault}`,
  borderRadius: '4px',
  justifyContent: 'center',
  padding: `0 ${theme.spacing['24px']} 0 ${theme.spacing['16px']}`,
}))

const IconContainer = styled.View({
  position: 'absolute',
  top: '14px',
  right: '14px',
})

const DropdownContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.colors.inputBackgroundDefault,
  padding: theme.spacing['16px'],
  borderRadius: theme.spacing['8px'],
  boxShadow: `0 2px 16px ${theme.colors.shadowLow}`,
}))

const DropdownCheckboxGroupContainer = styled.View({
  marginBottom: '-24px', // to compensate for the forced field margin
})

const BottomSheetContent = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  padding: `${theme.spacing['24px']} ${theme.spacing['24px']} ${theme.spacing['32px']} ${theme.spacing['24px']}`,
}))

const BottomSheetCheckboxGroupContainer = styled.View({
  marginBottom: '-12px',
})

const CloseIconContainer = styled(Pressable)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing['24px'],
  top: theme.spacing['24px'],
  zIndex: 1,
}))

export const MultiSelect: FunctionComponent<MultiSelectProps> = ({
  value,
  onChange,
  name,
  label,
  subLabel,
  labelAlignment,
  error,
  options,
  readOnly,
  active,
  isInModal = false,
}) => {
  const { colors } = useTheme()
  const { formatMessage } = useIntl()
  const { isMinWidthTablet } = useMediaQuerySize()

  const [focusRef] = useAccessibilityFocus({ active, delay: 200 })
  const bottomSheetRef = React.useRef<BottomSheetGorhom>(null)
  const ref = useRef(null)

  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [dropdownSelections, setDropdownSelections] = useState(value)

  const openBottomSheet = () => bottomSheetRef.current?.expand()
  const closeBottomSheet = () => bottomSheetRef.current?.close()

  const dropdownContainerRef = `DropDownContainer-${name}`
  const multiSelectInputFieldRef = `MultiSelectInputField-${name}`

  useOnClickOutside(
    dropdownContainerRef,
    ref,
    () => {
      setIsDropdownOpen(false)
    },
    multiSelectInputFieldRef,
  )

  const checkboxGroup = (
    <CheckboxGroup
      onChange={(args) => {
        setDropdownSelections(args)
        onChange(args)
      }}
      value={value}
      options={options}
      readOnly={readOnly}
      name={name}
    />
  )

  const bottomSheetContent = (
    <BottomSheetContent>
      <CloseIconContainer
        testID={tID('BottomSheet-closeIcon')}
        onPress={() => {
          setIsBottomSheetOpen(false)
          closeBottomSheet()
        }}
      >
        <CloseIcon size={16} />
      </CloseIconContainer>
      <BottomSheetCheckboxGroupContainer>{checkboxGroup}</BottomSheetCheckboxGroupContainer>
      <PrimaryButton
        fullWidth
        text={formatMessage({
          defaultMessage: 'Continue',
          description: 'Button to indicate client has finished selecting multiselect checkbox options',
        })}
        accessibilityLabel={formatMessage({
          defaultMessage: 'Continue',
          description: 'Button to indicate client has finished selecting multiselect checkbox options',
        })}
        onPress={() => {
          setIsBottomSheetOpen(false)
          closeBottomSheet()
        }}
        testID={tID('MultiSelect-closeBottomSheet')}
      />
    </BottomSheetContent>
  )

  return (
    <>
      <BaseInput
        labelRef={focusRef}
        label={label}
        subLabel={subLabel}
        labelAlignment={labelAlignment}
        error={error}
        name={name}
      >
        <InputFieldContainer
          onPress={() => {
            if (isMinWidthTablet) {
              setIsDropdownOpen((prev) => !prev)
            } else {
              setIsBottomSheetOpen(true)
            }
          }}
          testID={tID('MultiSelect-text')}
          nativeID={multiSelectInputFieldRef}
        >
          <TruncatedText
            textType={TextType.BODY}
            textSize={BodyTextSize.DEFAULT}
            fontColor={colors.textSecondary}
            toggleCollapseExpand={false}
            noMargin={true}
            text={
              dropdownSelections.length
                ? dropdownSelections
                    .map((selection) => options.find((option) => String(selection) === option.id)?.text)
                    .join('; ')
                : formatMessage({
                    defaultMessage: 'Select all that apply.',
                    description: 'Placeholder text for multi select checkbox field',
                  })
            }
            maxNumberOfLines={1}
          />
          <IconContainer>
            <ChevronIcon
              fillColor={colors.iconDefault}
              direction={ChevronIconDirection.DOWN}
              testID={tID('MultiSelect-chevronIcon')}
            />
          </IconContainer>
        </InputFieldContainer>
        {isDropdownOpen && (
          <DropdownContainer testID={tID('typeAhead-dropdown')} ref={ref} nativeID={dropdownContainerRef}>
            <DropdownCheckboxGroupContainer>{checkboxGroup}</DropdownCheckboxGroupContainer>
          </DropdownContainer>
        )}
      </BaseInput>
      <Modal
        modalContents={bottomSheetContent}
        visible={isBottomSheetOpen}
        width={'600px'}
        onRequestClose={() => setIsBottomSheetOpen(false)}
        onLayout={openBottomSheet}
        onCloseEnd={() => {
          setIsBottomSheetOpen(false)
        }}
        bottomSheetRef={bottomSheetRef}
        useDynamicHeightForBottomSheet
        withBottomSheetPortal={!isInModal}
      />
    </>
  )
}

export const MultiSelectRFF: FunctionComponent<FieldRenderProps<CheckBoxGroupValue>> = (props) => {
  const {
    input: { value, onChange, name },
    meta: { touched, error, active },
    label,
    subLabel,
    labelAlignment,
    readOnly,
    options,
    isInModal,
  } = props
  return (
    <MultiSelect
      value={value}
      onChange={onChange}
      name={name}
      label={label}
      subLabel={subLabel}
      labelAlignment={labelAlignment}
      error={touched && error}
      options={options}
      readOnly={readOnly}
      active={active}
      isInModal={isInModal}
    />
  )
}
