import React from 'react'
import { Image, ImageSourcePropType } from 'react-native'
import { useNavigate } from 'react-router-dom'

import styled, { useTheme } from 'styled-components/native'

import { BodyText, PrimaryButton, Subhead, SubheadSize, tID } from '@lyrahealth-inc/ui-core-crossplatform'
import SimplePracticeStepOneImage from '@lyrahealth-inc/ui-core-crossplatform/src/assets/SimplePracticeStepOne.png'
import SimplePracticeStepTwoImage from '@lyrahealth-inc/ui-core-crossplatform/src/assets/SimplePracticeStepTwo.png'

import { actions, mixpanelEvents } from '../../../../mixpanel/mixpanelConstants'
import { track } from '../../../../mixpanel/mixpanelTracking'
import { SCHEDULING_ACTIVATE_STEP_SIX } from '../../common/constants/routingConstants'
import { BodyTextContainer, ButtonContainer, HeaderContainer, WalkthroughContentContainer } from '../constants'

const ImageContainer = styled.View(({ theme }) => ({
  marginTop: theme.spacing['32px'],
}))

export const SchedulingWalkthrough5: React.FC = () => {
  const navigate = useNavigate()
  const { colors } = useTheme()

  const onNextStep = () => {
    track({ event: mixpanelEvents.BUTTON_PRESS, action: actions.SCHEDULING_WALKTHROUGH_GOOGLE_CONNECTION })
    navigate(SCHEDULING_ACTIVATE_STEP_SIX.route)
  }

  return (
    <WalkthroughContentContainer>
      <HeaderContainer>
        <Subhead
          level='2'
          size={SubheadSize.LARGE}
          textAlign='center'
          text='Connect SimplePractice to Google Calendar'
          color={colors.textPrimary}
        />
      </HeaderContainer>
      <BodyTextContainer>
        <BodyText color={colors.textSecondary} textAlign='center' text='1. Turn Calendar Sync on.' />
      </BodyTextContainer>
      <ImageContainer>
        <Image
          source={SimplePracticeStepOneImage as ImageSourcePropType}
          style={{ width: 720, height: 288, alignSelf: 'center' }}
          resizeMode='contain'
        />
      </ImageContainer>
      <BodyTextContainer>
        <BodyText
          color={colors.textSecondary}
          textAlign='center'
          text='2. Click the Connect Google Calendar button. Follow the rest of their instructions, and come back when you’re done.'
        />
      </BodyTextContainer>
      <ImageContainer>
        <Image
          source={SimplePracticeStepTwoImage as ImageSourcePropType}
          style={{ width: 720, height: 329, alignSelf: 'center' }}
          resizeMode='contain'
        />
      </ImageContainer>
      <ButtonContainer>
        <PrimaryButton text='Next' onPress={onNextStep} testID={tID('CompleteStepFive-button')} />
      </ButtonContainer>
    </WalkthroughContentContainer>
  )
}
