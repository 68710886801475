import React, { FunctionComponent } from 'react'

import LottieView from 'lottie-react-native'
import styled, { useTheme } from 'styled-components/native'

import rotatingProvidersLottieDarkMode from '../../assets/lottie/rotatingProvidersLottieDarkMode.json'
import rotatingProvidersLottieLightMode from '../../assets/lottie/rotatingProvidersLottieLightMode.json'
import { tID } from '../../utils'

const Container = styled.View<{ width: number; height: number }>(({ width, height }) => ({
  height: `${height}px`,
  width: `${width}px`,
}))

export interface RotatingProvidersLottieProps {
  width: number
  height: number
}

export const RotatingProvidersLottie: FunctionComponent<RotatingProvidersLottieProps> = ({ width, height }) => {
  const { isDarkMode } = useTheme()
  return (
    <Container width={width} height={height} testID={tID('HomebaseHeader-rotatingProvidersLottie')}>
      <LottieView
        source={isDarkMode ? rotatingProvidersLottieDarkMode : rotatingProvidersLottieLightMode}
        loop
        autoPlay
        style={{ width: width, height: height }}
      />
    </Container>
  )
}
