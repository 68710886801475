import React, { FunctionComponent } from 'react'
import { Animated } from 'react-native'

import styled from 'styled-components/native'

import { ZoomMiniView } from '../zoomMiniView/ZoomMiniView'
import { ZoomMobileHeader } from '../zoomMobileHeader/ZoomMobileHeader'

const Container = styled.View({
  height: '100%',
  width: '100%',
  flexGrow: 1,
})

export type ZoomVideoSessionMobileBaseProps = {
  participantName: string
  sessionStartDate: Date
  sessionDuration: number
  sessionNumber?: number
  openSettings?: () => void
  landscape: boolean
  onOpenMessagesPressed?: () => void
  unreadMessageCount?: number
  elementOpacity: Animated.Value
  hidden: boolean
  participantFirstName?: string
  messenger?: React.ReactElement
  isFocused?: boolean
}

export const ZoomVideoSessionMobileBase: FunctionComponent<ZoomVideoSessionMobileBaseProps> = ({
  participantName,
  sessionStartDate,
  sessionDuration,
  sessionNumber,
  openSettings,
  landscape,
  onOpenMessagesPressed,
  unreadMessageCount,
  elementOpacity,
  participantFirstName,
  hidden,
  messenger,
  isFocused,
  children,
}) => {
  return (
    <Container>
      <ZoomMobileHeader
        style={{ opacity: elementOpacity }}
        participantName={participantName}
        sessionStartDate={sessionStartDate}
        sessionDuration={sessionDuration}
        sessionNumber={sessionNumber}
        openSettings={openSettings}
        hidden={hidden}
      />
      {children}
      <ZoomMiniView
        landscape={landscape}
        onOpenMessagesPressed={onOpenMessagesPressed}
        unreadMessageCount={unreadMessageCount}
        elementOpacity={elementOpacity}
        participantFirstName={participantFirstName}
        controlsHidden={hidden}
        messenger={messenger}
        isFocused={isFocused}
      />
    </Container>
  )
}
