import React, { FunctionComponent } from 'react'

import styled, { useTheme } from 'styled-components/native'

import { BodyText, Subhead } from '../../atoms'
import { AssignmentIconSelector } from '../../organisms/assignmentsList/AssignmentIconSelector'
import { BodyTextSize, SubheadSize } from '../../styles'
import { ThemeType } from '../../utils'

export type FormContentHeaderProps = {
  title?: string
  dateDisplay?: string
  group?: string
}

const FormContentHeaderContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginHorizontal: theme.spacing['16px'],
}))

const TitleContainer = styled.View({
  flexDirection: 'row',
  alignItems: 'center',
})

const IconContainer = styled(BodyText)({
  height: '24px',
  marginRight: '10px',
})

const TitleTextContainer = styled(Subhead)<{ theme: ThemeType }>(({ theme: { colors } }) => ({
  fontWeight: 700,
  color: colors.textPrimary,
}))

export const FormContentHeader: FunctionComponent<FormContentHeaderProps> = ({ title, dateDisplay, group }) => {
  const { colors } = useTheme()

  return (
    <FormContentHeaderContainer>
      <TitleContainer>
        <IconContainer>
          <AssignmentIconSelector type={group} size={24} />
        </IconContainer>
        <TitleTextContainer text={title} size={SubheadSize.MEDIUM} />
      </TitleContainer>
      <BodyText text={dateDisplay} size={BodyTextSize.DEFAULT} color={colors.textPrimary} />
    </FormContentHeaderContainer>
  )
}
