import React, { FunctionComponent } from 'react'
import { Path, Svg } from 'react-native-svg'

import { PrimaryNeedsWords } from '@lyrahealth-inc/shared-app-logic'

import { tID } from '../../utils'

type StressIllustrationProps = {
  size?: string | number
  selected?: string
}

export const StressIllustration: FunctionComponent<StressIllustrationProps> = ({ selected = false, size }) => (
  <Svg
    width={size}
    height={size}
    viewBox='0 0 48 48'
    fill='none'
    testID={tID(`${PrimaryNeedsWords.STRESS}${selected ? '-Selected-' : '-'}Illustration`)}
  >
    <Path
      d='M10.975 37.6011C10.5272 37.0072 10.0813 36.4115 9.63353 35.8176C11.8181 35.6015 13.989 35.2841 16.1424 34.8733C16.6311 34.7799 16.7518 34.0926 16.5104 33.7012C15.8036 32.5603 15.0969 31.4193 14.3901 30.2783C16.0704 29.5287 17.7643 28.8122 19.4757 28.1386C20.1299 27.8816 20.1358 26.7951 19.3745 26.6238C16.7148 26.0261 13.9968 25.9229 11.345 26.3512C11.3235 26.3551 11.3021 26.3609 11.2807 26.3668C11.0295 26.3901 10.7784 26.4174 10.5292 26.4525C10.4454 26.4525 10.3617 26.4525 10.2799 26.4525C10.1943 26.4544 10.1164 26.4719 10.0463 26.5011C9.89637 26.5187 9.74645 26.5381 9.61016 26.5868C9.61016 26.5868 9.60627 26.5868 9.60432 26.5868C9.40378 26.5907 9.28501 26.7153 9.24218 26.8691C9.21686 26.9119 9.1935 26.9606 9.17403 27.0151C9.05721 27.3695 9.18377 27.683 9.38431 27.9594C9.39015 28.0879 9.41156 28.2242 9.4544 28.3683C9.63742 28.9992 9.86522 29.6241 10.0911 30.2472C10.0249 30.3017 9.96063 30.3601 9.89443 30.4185C7.72934 30.8605 5.56425 31.3025 3.39917 31.7425C2.90657 31.8438 2.79559 32.5174 3.03118 32.9146C3.61334 33.892 4.25196 34.8266 4.94705 35.7203C3.60945 37.3188 2.06351 38.6836 0.326771 39.7973C-0.405308 40.2666 0.213844 41.4095 0.994599 41.2109C4.19939 40.397 7.40419 39.5832 10.607 38.7693C11.049 38.6564 11.271 37.9924 10.975 37.5972V37.6011Z'
      fill={selected ? '#3BA5B5' : '#FFAF9B'}
    />
    <Path
      d='M46.9239 18.0863C46.6377 18.2304 46.3535 18.3745 46.0673 18.5166C45.9602 18.5186 45.8531 18.5478 45.7518 18.6159L45.415 18.8437C43.1643 19.9769 40.9116 21.112 38.6608 22.2452C38.4349 21.3242 38.1273 20.4169 37.7262 19.5369C37.5627 19.1767 37.0506 18.9333 36.7255 19.202C34.9946 20.6214 33.2637 22.0407 31.5328 23.4601C31.4627 22.4321 31.3108 21.408 31.0616 20.3838C30.9603 19.9711 30.4035 19.5349 30.016 19.8873C28.326 21.4255 26.636 22.9636 24.946 24.4998C24.8876 24.5427 24.8311 24.5855 24.7727 24.6283C24.1847 25.0664 24.7318 25.9095 25.3588 25.7829C25.4503 25.7751 25.5418 25.7401 25.6313 25.67C26.7957 25.0041 27.96 24.3402 29.1243 23.6743C29.4339 25.0976 29.8116 26.5111 30.2652 27.9071C30.3937 28.3024 30.9603 28.4737 31.266 28.242C33.069 26.883 34.8719 25.524 36.6729 24.163C37.0117 25.3779 37.3524 26.5909 37.6912 27.8059C37.7048 27.8545 37.7262 27.8993 37.7535 27.9422C37.7126 28.5126 38.5011 28.8534 38.9334 28.4328C41.2854 26.1431 43.5537 23.7736 45.7382 21.3301C46.445 20.5766 47.1479 19.8172 47.841 19.0501C48.355 18.4835 47.51 17.7904 46.922 18.0863H46.9239Z'
      fill={selected ? '#3BA5B5' : '#F69170'}
    />
    <Path
      d='M24.125 30.6103C24.602 33.6905 25.079 36.7687 25.556 39.8489C25.6281 40.3142 26.1927 40.4252 26.6016 40.248C28.2215 39.5452 29.8006 38.7333 31.3465 37.8123C32.1934 40.9042 33.3305 43.8383 34.746 46.6051C35.2172 47.526 36.6872 46.3403 36.2199 45.4291C34.7324 42.5222 33.5817 39.4108 32.7522 36.1301C32.6529 35.7348 32.1272 35.4681 31.7067 35.7309C30.1958 36.6753 28.6538 37.5105 27.0728 38.2426C26.6386 35.435 26.2025 32.6274 25.7683 29.8178C25.6008 28.7411 23.9595 29.5472 24.125 30.6122V30.6103Z'
      fill={selected ? '#85CCD5' : '#CDB8A6'}
    />
    <Path
      d='M13.4317 20.0357C11.6949 18.9921 9.74987 18.2756 7.89825 17.4676C5.88309 16.5876 3.86792 15.7075 1.85276 14.8275C0.933762 14.4264 0.491788 15.9217 1.3991 16.3189C3.2955 17.1483 5.19189 17.9758 7.09024 18.8052C8.93991 19.6132 10.883 20.3239 12.6159 21.3656C13.4765 21.8815 14.2923 20.5556 13.4317 20.0396V20.0357Z'
      fill='#85CCD5'
    />
    <Path
      d='M29.1222 16.6631C30.6545 15.3391 31.9415 13.7134 33.2888 12.2064C34.7529 10.567 36.2191 8.92564 37.6832 7.28625C38.351 6.5386 37.068 5.6527 36.4079 6.39062C35.0294 7.93461 33.6509 9.4786 32.2724 11.0226C30.9271 12.5276 29.6459 14.1514 28.1175 15.4754C27.3582 16.1316 28.3648 17.3212 29.1241 16.665L29.1222 16.6631Z'
      fill='#85CCD5'
    />
    <Path
      d='M21.2989 10.8522C21.2814 10.0656 21.4449 8.87207 20.707 8.34053C20.0217 7.84793 19.0267 8.2646 18.2927 8.38921C17.6444 8.50019 16.9902 8.58001 16.334 8.63064C15.8473 8.66958 15.1677 8.82923 14.6985 8.70268C14.313 8.59948 14.5973 8.80003 14.3967 8.4885C14.2643 8.28212 14.2974 7.79926 14.2643 7.56367C14.1806 6.95425 14.1027 6.34289 14.0287 5.73152C13.8827 4.49322 13.76 3.25491 13.6549 2.01272C13.651 1.95625 13.6374 1.90174 13.6218 1.85111C13.69 0.963272 12.3115 0.620596 12.1051 1.55906C11.8675 2.6416 11.6495 3.72804 11.4392 4.81642C11.3555 4.93325 11.3049 5.08511 11.3107 5.27008L11.3165 5.46284C10.9972 7.16064 10.7071 8.86428 10.454 10.5738C10.3878 11.0196 10.6059 11.4752 11.0673 11.5492C11.19 11.6602 11.3516 11.7361 11.5619 11.742C12.9365 11.779 14.3091 11.816 15.6837 11.8549C15.8881 12.1917 16.0381 12.8109 16.1413 13.0796C16.3476 13.6092 16.5501 14.1388 16.7507 14.6703C17.5762 16.8549 18.3589 19.055 19.1027 21.2687C19.3227 21.9229 20.4812 22.0923 20.6038 21.2687C20.9465 18.9888 21.1801 16.6971 21.2678 14.3938C21.3125 13.2159 21.3223 12.036 21.295 10.8561L21.2989 10.8522Z'
      fill={selected ? '#85CCD5' : '#FFC03C'}
    />
    <Path
      d='M19.1241 37.1698C18.026 40.1546 16.9279 43.1375 15.8298 46.1222C15.4832 47.0646 16.9883 47.4715 17.3309 46.537C18.429 43.5522 19.5272 40.5693 20.6253 37.5846C20.9719 36.6422 19.4668 36.2353 19.1241 37.1698Z'
      fill='#85CCD5'
    />
  </Svg>
)
