import { Platform, StyleSheet } from 'react-native'

import { ThemeColors } from '@lyrahealth-inc/shared-app-logic'

import { IS_WEB } from '../constants'
import { getTypeStyles } from '../styles/typeStyles'

export const getCommonStyles = (colors: ThemeColors) => {
  const typeStyles = getTypeStyles(colors)
  return StyleSheet.create({
    header: {
      fontSize: 20,
      fontFamily: 'Moderat-Bold',
      color: colors.textPrimary,
      margin: -10,
      marginBottom: 10,
      paddingTop: 50,
      paddingLeft: 20,
      display: 'flex',
      flexDirection: 'row',
      height: 80,
      backgroundColor: colors.backgroundPrimary,
    },
    container: {
      backgroundColor: colors.backgroundSecondary,
      padding: 10,
    },
    link: {
      ...typeStyles.moderatMediumNew,
      color: colors.linkDefault,
    },
    linkDarkBg: {
      fontFamily: 'Moderat-Medium',
      color: colors.textInverse,
      textDecorationLine: 'underline',
    },
    textInput: {
      ...typeStyles.bodyDefault,
      backgroundColor: colors.inputBackgroundDefault,
      borderWidth: 1,
      borderColor: colors.components.commonStyles.textInput.border,
      borderRadius: 4,
      height: 48,
      paddingHorizontal: 15,
    },
    fixedButtonContainer: {
      paddingHorizontal: 16,
      paddingVertical: 12,
      backgroundColor: colors.backgroundPrimary,
      borderTopWidth: 1,
      borderTopColor: colors.borderDefault,
    },
    flex1View: {
      flex: 1,
    },
  })
}

export const getFocusBoxShadow = ({
  innerBoxShadowColor,
  outerBoxShadowColor,
  innerBoxShadowSize = '4px',
  outerBoxShadowSize = '5.5px',
  colors,
}: {
  innerBoxShadowColor?: string
  outerBoxShadowColor?: string
  innerBoxShadowSize?: string
  outerBoxShadowSize?: string
  colors: ThemeColors
}) => {
  const componentColors = colors.components.focusBoxShadow
  return {
    ...(IS_WEB
      ? {
          boxShadow: `0 0 0 ${innerBoxShadowSize} ${
            innerBoxShadowColor || componentColors.innerBox.fill
          }, 0 0 0 ${outerBoxShadowSize} ${outerBoxShadowColor || componentColors.outerBox.fill}`,
        }
      : {}),
  }
}

export const getLinkStyle = (colors: ThemeColors) =>
  StyleSheet.create({
    link: {
      ...getTypeStyles(colors).moderatMediumNew,
      color: colors.linkDefault,
    },
  })

export const getFloatingStyles = ({
  interactive,
  hovered,
  pressed,
}: {
  interactive?: boolean
  hovered?: boolean
  pressed?: boolean
}) => {
  return StyleSheet.create({
    floatingCard: {
      ...Platform.select({
        ios: {
          shadowColor: '#000',
          shadowOpacity: 0.1,
          shadowOffset: { width: 0, height: 4 },
          shadowRadius: 5,
        },
        android: {
          elevation: 5,
        },
        web: {
          boxShadow: '0px 3px 6px 0px rgba(0, 0, 0, 0.16), 0px 2px 4px 1px rgba(0, 0, 0, 0.08)',
          ...(interactive && { transition: 'all 0.15s ease-out' }),
          ...(hovered &&
            !pressed && {
              boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.16), 0px 4px 8px 2px rgba(0, 0, 0, 0.08)',
            }),
          ...(pressed && {
            boxShadow: 'none',
          }),
        },
      }),
    },
  })
}
