import React, { FunctionComponent } from 'react'
import { Path, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

/**
 * Close Icon used on Modals and Switches
 */
export const CloseIcon: FunctionComponent<CloseIconProps> = ({ size = 24, fillColor }) => {
  const { colors } = useTheme()

  return (
    <Svg width={size} height={size} viewBox='0 0 14 14'>
      <Path
        d='M13.3 0.709956C13.1131 0.522704 12.8595 0.417471 12.595 0.417471C12.3305 0.417471 12.0768 0.522704 11.89 0.709956L6.99997 5.58996L2.10997 0.699956C1.92314 0.512704 1.66949 0.407471 1.40497 0.407471C1.14045 0.407471 0.886802 0.512704 0.699971 0.699956C0.309971 1.08996 0.309971 1.71996 0.699971 2.10996L5.58997 6.99996L0.699971 11.89C0.309971 12.28 0.309971 12.91 0.699971 13.3C1.08997 13.69 1.71997 13.69 2.10997 13.3L6.99997 8.40996L11.89 13.3C12.28 13.69 12.91 13.69 13.3 13.3C13.69 12.91 13.69 12.28 13.3 11.89L8.40997 6.99996L13.3 2.10996C13.68 1.72996 13.68 1.08996 13.3 0.709956Z'
        fill={fillColor || colors.iconDefault}
      />
    </Svg>
  )
}

type CloseIconProps = {
  size?: string | number
  fillColor?: string
}
