import React, { FunctionComponent, HTMLAttributes } from 'react'

import classNames from 'classnames'

import styles from './textButton.module.scss'

const TextButton: FunctionComponent<TextButtonProps & HTMLAttributes<HTMLElement>> = ({
  text,
  isLink,
  align = TextButtonAlign.NONE,
  isSmall,
  isPrimary,
  disabled,
  children,
  customClass,
  href,
  ...rest
}) => {
  const classes = classNames(
    styles['text-button'],
    isSmall ? styles['small-size'] : styles['default-size'],
    {
      [styles.primary]: isPrimary,
    },
    align && styles[`${align}`],
    { [styles.disabled]: disabled },
    customClass,
  )

  if (isLink) {
    return (
      <a className={classes} href={href} {...rest}>
        {text}
        {children}
      </a>
    )
  } else {
    return (
      <button className={classes} disabled={disabled} {...rest}>
        <span>
          {text}
          {children}
        </span>
      </button>
    )
  }
}

type TextButtonProps = {
  'data-test-id'?: string
  text?: string // This prop is being DEPRECATED in favor of children
  isLink?: boolean
  onDarkBg?: boolean
  onClick: React.MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>
  align?: TextButtonAlign
  isSmall?: boolean
  isPrimary?: boolean
  disabled?: boolean
  customClass?: string
  href?: string
}

export enum TextButtonAlign {
  LEFT = 'left',
  RIGHT = 'right',
  NONE = '',
}

export default TextButton
