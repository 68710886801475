import React from 'react'
import { useIntl } from 'react-intl'

import { HighFiveIllustration, PrimaryButton } from '@lyrahealth-inc/ui-core-crossplatform'

import styles from './awayMessage.module.scss'

export const SuccessPrompt: React.FC<SuccessPromptProps> = ({
  isScheduled,
  scheduledDate,
  scheduledTime,
  closeModal,
}) => {
  const intl = useIntl()

  return (
    <div>
      <div className={styles['success-container']}>
        <HighFiveIllustration />
        <div className={styles['text-container']}>
          <h2>{`${isScheduled ? 'Message scheduled!' : 'Sending messages!'}`}</h2>
          <p>{`${
            isScheduled && scheduledDate && scheduledTime
              ? `Your message will be sent on ${intl.formatDate(scheduledDate, {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                })} at ${intl.formatDate(new Date(`${scheduledDate}, ${scheduledTime}`), {
                  hour: '2-digit',
                  minute: '2-digit',
                })}.`
              : 'We’re busy sending your messages. You can close this modal.'
          }`}</p>
          <p>
            Next step: Update your new client availability and Google calendar blocks to reflect your upcoming time
            away.
          </p>
        </div>
      </div>
      <div className={styles.buttons}>
        <PrimaryButton fullWidth={true} text={'Close'} onPress={closeModal} />
      </div>
    </div>
  )
}

type SuccessPromptProps = {
  isScheduled: boolean
  scheduledDate: string | null
  scheduledTime: string | null
  closeModal: () => void
}

export default SuccessPrompt
