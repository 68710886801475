import {
  changeNoticeReasons,
  changeNotices,
  technicalIssuesCancelSelections,
} from '../../common/constants/appConstants'

export default (
  clientFirstName: string,
  appointmentDate: string,
  inLateCancelWindow: boolean,
  sessionNumber: number,
  showCreditedSessionTransition: boolean,
) => ({
  schema: {
    type: 'object',
    properties: {
      changeReason: {
        name: 'changeReason',
        type: 'string',
        title: 'Why are you canceling this session?',
        enum: inLateCancelWindow
          ? ['providerInitiated', 'clientTransition', 'clientCancel']
          : ['providerInitiated', 'clientTransition', 'clientInitiated'],
        enumNames: [
          'I am unable to meet at this time (non-credited session; no client penalty)',
          `${clientFirstName} transitioned to another program prior to the session (${
            showCreditedSessionTransition ? 'credited' : 'non-credited'
          } session; no client penalty)`,
          `${clientFirstName} canceled this session ${inLateCancelWindow ? '(more options to follow)' : ''}`,
        ],
        validation: {
          isRequired: true,
        },
      },
      changeNotice: {
        name: 'changeNotice',
        type: 'string',
        title: `Did ${clientFirstName} give you 24 hours notice?`,
        enum: changeNotices(clientFirstName).map(({ value }) => value),
        enumNames: changeNotices(clientFirstName).map(({ text }) => text),
        condition: {
          parentField: 'changeReason',
          parentValue: 'clientCancel',
        },
        validation: {
          isRequired: true,
        },
      },
      changeNoticeDetail: {
        name: 'changeNoticeDetail',
        type: 'string',
        title: 'Reason',
        enum: changeNoticeReasons(clientFirstName).map(({ value }) => value),
        enumNames: changeNoticeReasons(clientFirstName).map(({ text }) => text),
        condition: {
          parentField: 'changeNotice',
          parentValue: 'clientInitiatedReasonRequired',
        },
        validation: {
          isRequired: true,
        },
      },
      technicalIssuesCancelSelection: {
        name: 'technicalIssuesCancelSelection',
        type: 'string',
        title:
          'Which of the following best describes the “technical issue” that prevented this session from occurring?',
        enum: technicalIssuesCancelSelections(clientFirstName).map(({ value }) => value),
        enumNames: technicalIssuesCancelSelections(clientFirstName).map(({ text }) => text),
        condition: {
          parentField: 'changeNoticeDetail',
          parentValue: 'technicalIssues',
        },
        validation: {
          isRequired: true,
        },
      },
      technicalIssuesCancelDetails: {
        name: 'technicalIssuesCancelDetails',
        type: 'string',
        title: 'Additional details on technical issue(s) experienced:',
        condition: {
          parentField: 'changeNoticeDetail',
          parentValue: 'technicalIssues',
        },
      },
    },
  },

  uiSchema: {
    'ui:order': [
      'title',
      'appointmentDate',
      'implicationMessage',
      'changeReason',
      'changeNotice',
      'changeNoticeDetail',
      'technicalIssuesCancelSelection',
      'technicalIssuesCancelDetails',
      'notificationMessage',
    ],
    changeReason: {
      'ui:widget': 'radio',
    },
    changeNotice: {
      'ui:widget': 'radio',
    },
    changeNoticeDetail: {
      'ui:widget': 'radio',
    },
    technicalIssuesCancelSelection: {
      'ui:widget': 'radio',
      'ui:options': { styleType: '' },
    },
    technicalIssuesCancelDetails: {
      'ui:widget': 'textarea',
    },
  },
})
