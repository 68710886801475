import {
  UNRECONCILED_CLIENT_SELECTED,
  CLEAR_RECONCILER_DETAILS_STORE,
  GET_POSSIBLE_MATCHES,
  SUBMIT_RECONCILER_MATCH,
} from '../../../common/constants/reduxConstants'
import { getReconcilerPossibleMatchesData, submitReconciledClient } from '../../../common/http/data/clients'

export const clientSelected = (dataObj: any) => {
  return {
    type: UNRECONCILED_CLIENT_SELECTED,
    payload: dataObj,
  }
}

export const clearReconcilerDetailsStore = () => {
  return {
    type: CLEAR_RECONCILER_DETAILS_STORE,
  }
}

export const getPossibleMatches = (unreconciledClient: any) => {
  const request = getReconcilerPossibleMatchesData(unreconciledClient)

  return {
    type: GET_POSSIBLE_MATCHES,
    payload: request,
  }
}

export const submitMatch = (clientID: any, formData: any) => {
  const request = submitReconciledClient(clientID, formData)

  return (dispatch: any) => {
    return new Promise(function (resolve, reject) {
      request.then(
        (rmReturn) => {
          resolve(rmReturn)

          dispatch({
            type: SUBMIT_RECONCILER_MATCH,
            payload: true,
          })
        },
        (error) => {
          reject(error)
        },
      )
    })
  }
}
