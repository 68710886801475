import { createReducer } from '@reduxjs/toolkit'

import { ProviderInfo } from '@lyrahealth-inc/shared-app-logic'

import { DOWNLOAD_PROVIDERS_CSV, GET_PROVIDERS, GET_PROVIDERS_LITE } from '../../common/constants/reduxConstants'

type ProvidersDataState = {
  providersList?: ProviderInfo[]
  isDownloadingCSV: boolean
}

const initialState = {
  isDownloadingCSV: false,
}

export default createReducer<ProvidersDataState>(initialState, (builder) => {
  builder.addCase(GET_PROVIDERS, (state, action: any) => {
    state.providersList = action.payload.data.providers
    return state
  })
  builder.addCase(GET_PROVIDERS_LITE, (state, action: any) => {
    state.providersList = action.payload.data.providers
    return state
  })
  builder.addCase(DOWNLOAD_PROVIDERS_CSV, (state, action: any) => {
    state.isDownloadingCSV = action.payload.isDownloadingCSV
    return state
  })
})
