import React from 'react'

import { useTheme } from 'styled-components/native'

import { CheckIcon } from '../../atoms/icons/CheckIcon'
import { InlineBanner } from '../inlineBanner/InlineBanner'

export interface InlineSuccessBannerProps {
  text: React.ReactNode | string
  testId?: string
  backgroundColor?: string
  iconColor?: string
  textColor?: string
}

export const InlineSuccessBanner: React.FC<InlineSuccessBannerProps> = ({
  text,
  testId,
  backgroundColor,
  iconColor,
  textColor,
}) => {
  const { colors } = useTheme()

  const icon = <CheckIcon size={15} fillColor={iconColor || colors.iconSuccess} />
  return (
    <InlineBanner
      text={text}
      icon={icon}
      testId={testId}
      backgroundColor={backgroundColor || colors.backgroundSuccess}
      textColor={textColor || colors.textSuccess}
    />
  )
}
