import React from 'react'
import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { getAuthHydrated } from '../../../../data/auth/authSelectors'

const PublicRoute = () => {
  const hydrated: boolean = useSelector(getAuthHydrated)
  if (!hydrated) {
    return null
  }

  return <Outlet />
}

export default PublicRoute
