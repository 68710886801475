import React, { FunctionComponent } from 'react'
import { Path, Svg } from 'react-native-svg'

/**
 * Lightning icon used for word cloud categories
 */

type SourcesStressIconProps = {
  size?: string | number
}

export const SourcesStressIcon: FunctionComponent<SourcesStressIconProps> = ({ size = 24 }) => (
  <Svg width={size} height={size} viewBox='0 0 24 24' fill='none'>
    <Path
      d='M10.1082 0.913194L11.4795 8.83624C11.5125 8.92213 11.4665 9.01371 11.3813 9.03867L4.83809 10.273C4.74791 10.2981 4.70445 10.397 4.7434 10.4848L11.9995 22.6829C12.0693 22.8379 12.2977 22.8018 12.3049 22.6371L12.5375 14.2529C12.5403 14.1883 12.5827 14.1332 12.6448 14.1139L19.6075 12.3484C19.7129 12.3168 19.7492 12.1894 19.6791 12.1014L10.38 0.768211C10.2654 0.624974 10.0428 0.744852 10.1082 0.913194Z'
      fill='#FFC03C'
    />
  </Svg>
)
