import { useCallback, useEffect, useState, useRef } from 'react'
import { Animated } from 'react-native'

// UI states
export enum ElementState {
  Shown = 'Show',
  Showing = 'Showing',
  Hidden = 'Hidden',
  Hiding = 'Hiding',
}

export const useToggleElement = ({
  showElementOnLoad,
  quickFadeOutDuration = 0,
  fadeOutDuration = 1000,
  hideElementTimerDuration = 3000,
  fadeInDuration = 200,
}: ToggleElementOptions): ToggleElementReturn => {
  const [elementState, setElementState] = useState(showElementOnLoad ? ElementState.Shown : ElementState.Hidden)
  const elementTimer = useRef<number | null>(null)

  const elementOpacity = useRef(new Animated.Value(showElementOnLoad ? 1 : 0)).current

  /**
   * Handles hiding the element
   */
  const hideElement = useCallback(
    (specifiedOptions?: { immediately: boolean }) => {
      const options = { immediately: false, ...specifiedOptions }
      if (elementTimer.current) {
        window.clearTimeout(elementTimer.current)
      }
      Animated.timing(elementOpacity, {
        toValue: 0,
        duration: options.immediately ? quickFadeOutDuration : fadeOutDuration,
        useNativeDriver: true,
      }).start(({ finished }) => {
        if (finished) {
          setElementState(ElementState.Hidden)
        }
      })
    },
    [elementOpacity, fadeOutDuration, quickFadeOutDuration],
  )

  const onTimerDone = useCallback(() => {
    // After the element timer runs out, fade away the element slowly
    setElementState(ElementState.Hiding)
    hideElement()
  }, [hideElement])

  const clearElementTimer = () => {
    elementTimer.current && window.clearTimeout(elementTimer.current)
  }

  /**
   * Resets the animation timer for the element
   */
  const resetElementTimer = useCallback(() => {
    if (elementTimer.current) {
      window.clearTimeout(elementTimer.current)
    }

    elementTimer.current = window.setTimeout(() => onTimerDone(), hideElementTimerDuration)
  }, [hideElementTimerDuration, onTimerDone])

  /**
   * Handles animtions for showing the element
   */
  const showElement = useCallback(
    (specifiedOptions?: { shouldPersist: boolean }) => {
      const options = { shouldPersist: false, ...specifiedOptions }
      Animated.timing(elementOpacity, {
        toValue: 1,
        duration: fadeInDuration,
        useNativeDriver: true,
      }).start(({ finished }) => {
        if (finished) {
          setElementState(ElementState.Shown)
          if (!options.shouldPersist) {
            resetElementTimer()
          }
        }
      })
    },
    [elementOpacity, fadeInDuration, resetElementTimer],
  )

  /**
   * This handles showing and hiding the element and updating the state of the element
   */
  const toggleElement = () => {
    switch (elementState) {
      case ElementState.Shown:
        // If the element are currently Shown, a tap should hide element quickly
        setElementState(ElementState.Hiding)
        hideElement({ immediately: true })
        break
      case ElementState.Hidden:
        // If the element are currently, show element with fade-in animation
        showElement()
        setElementState(ElementState.Showing)
        break
      case ElementState.Hiding:
        // If element are fading out, a tap should reverse, and show element
        setElementState(ElementState.Showing)
        showElement()
        break
      case ElementState.Showing:
        // A tap when the element are fading in should do nothing
        break
    }
  }

  useEffect(() => {
    if (showElementOnLoad) {
      showElement()
    }
  }, [showElementOnLoad, showElement])

  return {
    showElement,
    hideElement,
    elementState,
    toggleElement,
    clearElementTimer,
    resetElementTimer,
    elementOpacity,
  }
}

type ToggleElementOptions = {
  showElementOnLoad?: boolean
  quickFadeOutDuration?: number
  fadeOutDuration?: number
  hideElementTimerDuration?: number
  fadeInDuration?: number
}

type ToggleElementReturn = {
  showElement: (options?: { shouldPersist: boolean }) => void
  hideElement: (options?: { immediately: boolean }) => void
  elementState: ElementState
  toggleElement: () => void
  clearElementTimer: () => void
  resetElementTimer: () => void
  elementOpacity: Animated.Value
}
