import { createReducer } from '@reduxjs/toolkit'

import { Practice } from '@lyrahealth-inc/shared-app-logic'

import { GET_AVAILABLE_PRACTICES, GET_PRACTICES } from '../../common/constants/reduxConstants'

type PracticesDataState = {
  practicesList?: Practice[]
  availablePractices?: { name: string }[]
  isLoadingAvailablePractices?: boolean
}

const initialState = {}

export default createReducer<PracticesDataState>(initialState, (builder) => {
  builder.addCase(GET_PRACTICES, (state, action: any) => {
    state.practicesList = action.payload.data.practices
    return state
  })

  builder.addCase(`${GET_AVAILABLE_PRACTICES}_PENDING`, (state) => {
    state.isLoadingAvailablePractices = true
    return state
  })

  builder.addCase(`${GET_AVAILABLE_PRACTICES}_FULFILLED`, (state, action: any) => {
    state.availablePractices = action.payload.data
    state.isLoadingAvailablePractices = false
    return state
  })

  builder.addCase(`${GET_AVAILABLE_PRACTICES}_REJECTED`, (state) => {
    state.isLoadingAvailablePractices = false
    return state
  })
})
