import { useMemo } from 'react'

import { intervalToDuration, startOfDay } from 'date-fns'
import { format, utcToZonedTime } from 'date-fns-tz'

import { AvailabilitiesResponse, AvailabilityDate, ParsedAvailability } from '../../../models/calendar/CalendarV2'

export const parseAvailability = (
  response: AvailabilitiesResponse | null,
  timeZone: string,
  sliceDurationMinutes: number,
): ParsedAvailability | null => {
  if (!(response && response.provider_availability?.length)) {
    return null
  }
  const availabilities = response.provider_availability[0]
  const dates = new Map<string, Array<{ datetime: string; time: string }>>()
  let highestMinutes = 0
  let lowestMinutes = Number.MAX_SAFE_INTEGER

  /**
   * Compare all the availability slots and find the min start time
   */
  for (const availability of availabilities.availability) {
    const zonedDate = utcToZonedTime(availability.start, timeZone)
    const dateParts = format(zonedDate, 'MM/dd/yyyy', { timeZone }).split('/')
    const time = format(zonedDate, 'HH:mm:ss', { timeZone })
    const dateStr = `${dateParts[2]}-${dateParts[0]}-${dateParts[1]}`
    // Gets the total hours and minutes from midnight
    const timeSinceMidnight = intervalToDuration({ start: startOfDay(zonedDate), end: zonedDate })
    // Gets the number of hours since midnight and converts it to minutes
    const timeSinceMidnightHoursToMinutes = (timeSinceMidnight?.hours ?? 0) * 60
    // Gets the minutes since midnight
    const timeSinceMidnightMinutes = timeSinceMidnight?.minutes ?? 0
    // Adds up the total hours and minutes since midnight
    const totalMinutesSinceMidnight = timeSinceMidnightHoursToMinutes + timeSinceMidnightMinutes

    // it the total minutes since midnight for the slot is greater than the previous or equal to, set it
    if (totalMinutesSinceMidnight >= highestMinutes) {
      highestMinutes = totalMinutesSinceMidnight
    }

    // if the total minutes of the slot is less than the previous or equal to, set it.
    if (totalMinutesSinceMidnight <= lowestMinutes) {
      lowestMinutes = totalMinutesSinceMidnight
    }

    // If the map does not have the date already, add it
    if (!dates.has(dateStr)) {
      dates.set(dateStr, [])
    }
    dates.get(dateStr)!.push({ datetime: availability.start, time })
  }

  const timeRange = []
  let currTime = lowestMinutes

  // Creates a range of times starting from the earliest time slot that is within the appointment duration
  while (currTime <= highestMinutes) {
    const hours = Math.floor(currTime / 60)
    const minutes = currTime % 60
    timeRange.push(`${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:00`)
    currTime += sliceDurationMinutes
  }

  const dateArr: AvailabilityDate[] = []
  for (const [date, datetimes] of dates) {
    dateArr.push({
      date,
      // Only add the times in the time range that are within our appointment duration that exists in our list of earliest times
      times: timeRange.map((time) => {
        const match = datetimes.find((d) => d.time === time)
        if (match == null) {
          return null
        }
        return match.datetime
      }),
    })
  }

  return {
    appointmentDurationMinutes: availabilities.appointment_duration_minutes,
    firstAvailability: availabilities.availability[0]?.start,
    lastAvailability: availabilities.availability[availabilities.availability.length - 1]?.start,
    dates: dateArr,
  }
}

/**
 * Note: This hook is expensive so be cautious of how many times it's called
 */
export const useParseAvailabilities = (
  availabilities: AvailabilitiesResponse | null,
  timeZone: string,
  sliceDurationMinutes: number,
): ParsedAvailability | null => {
  return useMemo(() => {
    // allows for usage where the availabilities might not be available yet as we are fetching from the api
    return parseAvailability(availabilities, timeZone, sliceDurationMinutes)
  }, [availabilities, timeZone, sliceDurationMinutes])
}
