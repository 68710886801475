import React, { FunctionComponent } from 'react'
import { FieldRenderProps } from 'react-final-form'
import { useIntl } from 'react-intl'
import { TouchableOpacity } from 'react-native'

import styled, { useTheme } from 'styled-components/native'

import { BodyText, Size } from '../../atoms/bodyText/BodyText'
import { CheckIcon } from '../../atoms/icons/CheckIcon'
import { CloseIcon } from '../../atoms/icons/CloseIcon'
import { AccessibilityRolesNative } from '../../constants'
import { tID } from '../../utils'

export type ToggleSwitchProps = {
  isOn?: boolean
  width?: number
  height?: number
  onPress: (on: boolean) => void
  offColor?: string
  onColor?: string
  handleColor?: string
  testID?: string
  accessibilityLabel?: string
}

const ToggleContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
`

const Container = styled(TouchableOpacity)<{
  width: number
  height: number
  off: boolean
  offColor: string
  onColor: string
}>`
  ${({ width, height, onColor }) => `
    width: ${width}px;
    height: ${height}px;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    border-radius: ${height / 2}px;
    background-color: ${onColor};
    line-height: ${width}px;
  `}

  ${({ off, width, offColor }) =>
    off &&
    `
    width: ${width}px;
    text-align: right;
    background-color: ${offColor};
    flex-direction: row;
  `}
`

const RadioCircleContainer = styled.View<{ height: number; handleColor: string }>`
  ${({ height, handleColor }) => `
  border-radius: ${(height - 2) / 2}px;
  background-color: ${handleColor};
  margin: 2px;
  height: ${height - 4}px;
  width: ${height - 4}px;
  `}
`

const OnTextContainer = styled.View<{ off: boolean }>`
  ${({ off }) => (off ? 'padding-right: 10px;' : 'padding-left: 20px;')}
`

/**
 * This component represents a toggle for switching on and off
 */
export const ToggleSwitch: FunctionComponent<ToggleSwitchProps> = ({
  isOn = false,
  width = 62,
  height = 32,
  onPress,
  offColor,
  onColor,
  handleColor,
  testID = '',
  accessibilityLabel,
}) => {
  const toggleName = testID === '' ? 'ToggleSwitch' : `${testID}-ToggleSwitch`
  const { colors } = useTheme()

  return (
    <Container
      width={width}
      height={height}
      off={!isOn}
      onPress={() => onPress(!isOn)}
      testID={tID(`${toggleName}-${isOn}`)}
      offColor={offColor || colors.iconDefault}
      onColor={onColor || colors.iconActive}
      accessibilityLabel={accessibilityLabel}
      aria-checked={isOn}
      role={AccessibilityRolesNative.SWITCH}
    >
      <RadioCircleContainer height={height} handleColor={handleColor || colors.iconInverse} />
      <OnTextContainer off={!isOn}>
        {isOn ? (
          <CheckIcon withCircle={false} fillColor={handleColor || colors.iconInverse} size={24} />
        ) : (
          <CloseIcon fillColor={handleColor || colors.iconInverse} size={13} />
        )}
      </OnTextContainer>
    </Container>
  )
}

export const ToggleSwitchRFF: FunctionComponent<FieldRenderProps<boolean>> = ({
  input: { value, onChange, name },
  label,
  labelColor,
  offColor,
  onColor,
  handleColor,
}) => {
  const { formatMessage } = useIntl()

  return (
    <ToggleContainer>
      <BodyText text={label} size={Size.DEFAULT} color={labelColor} />
      <ToggleSwitch
        isOn={!!value}
        onPress={() => onChange(!value)}
        offColor={offColor}
        onColor={onColor}
        handleColor={handleColor}
        testID={name}
        accessibilityLabel={`${label}, ${
          !!value
            ? formatMessage({ defaultMessage: 'on', description: 'Toggle button on state' })
            : formatMessage({ defaultMessage: 'off', description: 'Toggle button off state' })
        }`}
      />
    </ToggleContainer>
  )
}
