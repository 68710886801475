import React from 'react'
import { RestingCatIllustration } from '../../illustrations/RestingCatIllustration'
import { Subhead, Size as SubheadSize } from '../../subhead/Subhead'
import { BodyText, Size as BodyTextSize } from '../../bodyText/BodyText'
import styled from 'styled-components/native'
import { tID } from '../../../utils'
import { FormattedMessage } from 'react-intl'

const Container = styled.View({
  width: '100%',
})

const Content = styled.View(({ theme }) => ({
  maxWidth: '256px',
  alignItems: 'center',
  margin: '0 auto',
  paddingVertical: theme.spacing['24px'],
}))

const SubheadStyled = styled(Subhead)(({ theme }) => ({
  marginVertical: theme.spacing['4px'],
}))

export const SearchNoResults = () => {
  return (
    <Container testID={tID('typeAhead-searchNoResults')}>
      <Content>
        <RestingCatIllustration />
        <SubheadStyled
          text={<FormattedMessage defaultMessage='No results found' description='Title for no search results found' />}
          size={SubheadSize.XSMALL}
          textAlign='center'
        />
        <BodyText
          text={
            <FormattedMessage
              defaultMessage='Try searching again with only a single word or less filters'
              description='Body text for no search results found'
            />
          }
          size={BodyTextSize.SMALL}
          textAlign='center'
        />
      </Content>
    </Container>
  )
}
