import { createReducer } from '@reduxjs/toolkit'

import { Transfer } from '@lyrahealth-inc/shared-app-logic'

import {
  CLEAR_HEALTHPLAN_CHARGE,
  CLEAR_HP_ELIGIBILITY_STATUS,
  GET_ICD10S,
  SAVE_HEALTHPLAN_CHARGE,
  SET_HP_ELIGIBILITY_STATUS,
} from '../common/constants/reduxConstants'

type ICD10 = {
  code: string
  desc: string
  value: string
}

type ICD10s = { all: ICD10[]; common: ICD10[] }

type HPEligibility = {
  status: string
  health_plan_extension_eligible: boolean
  speciality_care_extension_eligible: boolean
  speciality_care_attestation_status: string
}

type HealthPlanState = {
  icd10s?: ICD10s
  currentCharge?: Transfer
  HPEligibility?: HPEligibility
}

const initialState = {}

export default createReducer<HealthPlanState>(initialState, (builder) => {
  builder.addCase(GET_ICD10S, (state, action: any) => {
    state.icd10s = action.data.icd10
    return state
  })
  builder.addCase(SAVE_HEALTHPLAN_CHARGE, (state, action: any) => {
    state.currentCharge = action.data
    return state
  })
  builder.addCase(CLEAR_HEALTHPLAN_CHARGE, (state) => {
    state.currentCharge = undefined
    return state
  })
  builder.addCase(SET_HP_ELIGIBILITY_STATUS, (state, action: any) => {
    state.HPEligibility = action.data
    return state
  })
  builder.addCase(CLEAR_HP_ELIGIBILITY_STATUS, (state) => {
    state.HPEligibility = undefined
    return state
  })
})
