import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form/immutable'
import styles from './addProviderView.module.scss'
import { PrimaryButton, InputField } from '@lyrahealth-inc/ui-core'
import addProviderValidate from './addProviderValidate'

type Props = {
  handleSubmit?: (...args: any[]) => any
  submitting?: boolean
  submitAddProvider?: (...args: any[]) => any
}

class AddProviderView extends Component<Props> {
  render() {
    const { handleSubmit, submitting, submitAddProvider } = this.props
    return (
      <div className={styles['modal-content']}>
        <h2>Add a new provider</h2>
        {/* @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message */}
        <form onSubmit={handleSubmit(submitAddProvider)}>
          <div className={styles.section}>
            <div className='row'>
              <div className='col-xs-12 col-sm-6'>
                <Field
                  name='first_name'
                  label='Provider First Name *'
                  type='text'
                  placeholder='First Name'
                  component={InputField}
                />
              </div>
              <div className='col-xs-12 col-sm-6'>
                <Field
                  name='last_name'
                  label='Provider Last Name *'
                  type='text'
                  placeholder='Last Name'
                  component={InputField}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-xs-12 col-sm-6'>
                <Field
                  name='provider_lyra_id'
                  label='Lyra Id *'
                  type='text'
                  placeholder='Provider Lyra_id'
                  component={InputField}
                />
              </div>
              <div className='col-xs-12 col-sm-6'>
                <Field name='email' label='Provider Email' type='text' placeholder='Email' component={InputField} />
              </div>
            </div>
            <PrimaryButton type='submit' isLoading={submitting} data-test-id='AddProviderView-submit'>
              Add Provider
            </PrimaryButton>
          </div>
        </form>
      </div>
    )
  }
}

export default reduxForm({
  form: 'addProviderForm',
  validate: addProviderValidate,
  // @ts-expect-error TS(2345): Argument of type 'typeof AddProviderView' is not a... Remove this comment to see the full error message
})(AddProviderView)
