import React from 'react'
import classNames from 'classnames'
import Tooltip from '../../tooltip/Tooltip'
import { uniqueId } from 'lodash-es'
import styles from './textArea.module.scss'
import BaseInput from '../baseInput/BaseInput'

type Props = {
  placeholder?: string
  meta?: $TSFixMe
  input?: $TSFixMe
  label?: string
  tooltipContent?: React.ReactNode
  rows?: number
  className?: string
  readOnly?: boolean
  disabled?: boolean
  resizeable?: $TSFixMe // TODO: PropTypes.oneOf(['vertical', 'horizontal', true, PropTypes.bool])
  autocomplete?: string
}

const TextArea = ({
  input,
  readOnly,
  label,
  placeholder,
  tooltipContent,
  rows = 3,
  meta: { touched, error, submitError, warning },
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
  className = null,
  disabled,
  resizeable = false,
  autocomplete,
  ...rest
}: Props) => {
  let hasTooltip
  if (tooltipContent) {
    hasTooltip = <Tooltip content={tooltipContent} id={uniqueId('inputTooltip_')} />
  }

  const classes = [touched && error ? styles['textarea-invalid'] : styles.textarea]

  switch (resizeable) {
    case 'vertical':
      classes.push(styles['resizable-vertical'])
      break
    case 'horizontal':
      classes.push(styles['resizable-horizontal'])
      break
    case true:
      classes.push(styles.resizable)
      break
  }

  const fieldInfoId = `${input.name}_info`
  return (
    <BaseInput
      className={className}
      label={label}
      toolTip={hasTooltip}
      touched={touched}
      error={error || submitError}
      warning={warning}
      readOnly={readOnly}
      disabled={disabled}
      htmlFor={input.name}
      fieldInfoId={fieldInfoId}
    >
      {readOnly ? (
        <p className={styles['read-only-content']}>{input.value}</p>
      ) : (
        <textarea
          {...input}
          readOnly={readOnly}
          disabled={disabled}
          placeholder={placeholder}
          rows={rows}
          className={classNames(classes)}
          id={input.name}
          aria-describedby={fieldInfoId}
          autoComplete={autocomplete}
          {...rest}
        />
      )}
    </BaseInput>
  )
}

export default TextArea
