import {
  PROVIDER_SELECTED,
  CLEAR_PROVIDER_DETAILS_STORE,
  TOGGLE_PROVIDER_DETAILS_MODAL,
  GET_PROVIDER_PAYMENT_HISTORY,
  GET_PROVIDER,
  UPDATE_RATES,
  CLEAR_PROVIDER_DETAILS_PAYMENT_HISTORY,
  actionAlertHandler,
  actionStyles,
  GET_PROVIDER_PAYMENT_HISTORY_CSV,
} from '../../../common/constants/reduxConstants'
import { getProviderData, updateProviderRatesData } from '../../../common/http/data/providers'

export const providerSelected = (dataObj: any) => {
  return {
    type: PROVIDER_SELECTED,
    payload: dataObj,
  }
}

export const getProvider = (id: any) => {
  const request = getProviderData(id)

  return (dispatch: any) => {
    return new Promise(function (resolve, reject) {
      request.then(
        (providerReturn) => {
          dispatch({
            type: GET_PROVIDER,
            payload: providerReturn,
          })

          resolve(providerReturn)
        },
        (error) => {
          if (!error.response) return
          actionAlertHandler({
            actionStyle: actionStyles.ERROR,
            message: error,
            dispatch,
            expires: true,
            action: GET_PROVIDER,
          })
          reject(error)
        },
      )
    })
  }
}

export const clearProviderDetailsStore = () => {
  return {
    type: CLEAR_PROVIDER_DETAILS_STORE,
  }
}

export const clearProviderPaymentHistory = () => {
  return {
    type: CLEAR_PROVIDER_DETAILS_PAYMENT_HISTORY,
  }
}

export const toggleProviderDetailsModal = (show: any, data: any) => {
  return {
    type: TOGGLE_PROVIDER_DETAILS_MODAL,
    visible: show,
    data: data,
  }
}

export const getProviderPaymentHistory = ({ providerID, page, limit }: any) => ({
  action: { type: GET_PROVIDER_PAYMENT_HISTORY, page, status: 'all' },
  request: {
    method: 'get',
    url: '/v1/transfers',
    params: {
      page,
      limit,
      provider_id: providerID,
    },
  },
})

export const editRates = (providerID: any, data: any) => {
  const request = updateProviderRatesData(providerID, data)
  return {
    type: UPDATE_RATES,
    payload: request,
  }
}

export const getProviderCSV = (providerID: any) => ({
  action: { type: GET_PROVIDER_PAYMENT_HISTORY_CSV },

  request: {
    method: 'get',
    url: `/v1/transfers/csv-downloads/${providerID}`,
  },
})
