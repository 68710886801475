import React, { useState } from 'react'
import PropTypes from 'prop-types'
import CSSModules from 'react-css-modules'
import { Field } from 'react-final-form'
import styles from './icd10s.module.scss'
import { every, isEqual, noop, isEmpty } from 'lodash-es'
// @ts-expect-error TS(7016): Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Highlighter from 'react-highlight-words' // note: react-bootstrap-typeahead's version does not support an array of search terms, so using this instead
import { TypeAheadField, PrimaryButton, TextButton } from '@lyrahealth-inc/ui-core'
import TabFilters from '../tabFilters/TabFilters'

const ICD10sSelection = ({ initialCodes = [], multiSelect, availableCodes = {}, closeModal, submitFunction }: any) => {
  const { common: commonCodes, all: allCodes } = availableCodes
  const [activeTab, setActiveTab] = useState('Common')
  const [selectedCodes, setSelectedCodes] = useState(initialCodes)

  const onSubmit = (codes: any) => {
    setSelectedCodes([])
    setActiveTab('Common')
    submitFunction(codes)
  }

  const renderMenuItemChildren = (option: any, fieldProps: any) => (
    <div className={styles['option-container']}>
      <div className={styles['code-capsule']}>
        <Highlighter
          highlightClassName='mark'
          searchWords={fieldProps.text.split(' ')}
          autoEscape
          textToHighlight={option.code}
        />
      </div>
      <div className={styles.description}>
        <Highlighter
          highlightClassName='mark'
          searchWords={fieldProps.text.split(' ')}
          autoEscape
          textToHighlight={option.desc}
        />
      </div>
    </div>
  )

  const toggleCommonCode = (option: any, isSelected: any) => {
    setSelectedCodes(
      isSelected ? selectedCodes.filter((obj: any) => obj.code !== option.code) : [...selectedCodes, option],
    )
  }

  const renderCommonCode = (option: any) => {
    const isSelected = selectedCodes.some((o: any) => isEqual(o, option))
    const isDisabled = selectedCodes.length > 0 && !multiSelect && !isSelected
    return (
      <li key={option.code}>
        <a
          className={isDisabled ? styles.disabled : undefined}
          onClick={isDisabled ? noop : () => toggleCommonCode(option, isSelected)}
        >
          <div
            className={`${styles['option-container']} ${isSelected ? styles.selected : ''} ${
              isDisabled ? styles.disabled : ''
            }`}
          >
            <div className={styles['code-capsule']}>{option.code}</div>
            <div className={styles.description}>{option.desc}</div>
          </div>
        </a>
      </li>
    )
  }
  return (
    <div styleName='icd10s-selection-container'>
      <h3>Add ICD-10 Diagnosis Code{multiSelect ? 's' : ''}</h3>
      <p>{multiSelect ? 'Add additional diagnosis codes as appropriate.' : 'Add one primary diagnosis.'}</p>
      {/* @ts-expect-error TS(2322): Type 'Dispatch<SetStateAction<string>>' is not ass... Remove this comment to see the full error message */}
      <TabFilters tabList={['Common', 'Search']} className={styles.tabs} handleClick={setActiveTab} />
      {activeTab === 'Common' ? (
        <div className='row'>
          <div className='col-xs-12' styleName='common-container'>
            <ul>{commonCodes.map(renderCommonCode)}</ul>
          </div>
        </div>
      ) : (
        <div className='row'>
          <div className='col-xs-12' styleName='field-container'>
            <Field
              name='codes'
              // @ts-expect-error TS(2322): Type 'FC<TypeAheadFieldProps>' is not assignable t... Remove this comment to see the full error message
              component={TypeAheadField}
              defaultSelected={selectedCodes}
              options={allCodes}
              alwaysOpen
              forceMatch
              filterBy={(option: any, fieldProps: any) => {
                // Don't show selected options in the menu for the multi-select case.
                if (fieldProps.multiple && fieldProps.selected.some((o: any) => isEqual(o, option))) {
                  return false
                }

                const searchArray = fieldProps.text.split(' ')
                return every(
                  searchArray,
                  (s) => fieldProps.labelKey(option).toLowerCase().indexOf(s.toLowerCase()) !== -1,
                )
              }}
              placeholder='Search all codes or descriptions'
              multiSelect={multiSelect}
              className={styles['icd10s-field']}
              onChange={setSelectedCodes}
              labelKey={(option: any) => `${option.code} ${option.desc}`}
              renderMenuItemChildren={renderMenuItemChildren}
            />
          </div>
        </div>
      )}
      <div className='row'>
        <div className='col-xs-12'>
          <div styleName='cta-container'>
            <PrimaryButton
              data-test-id='ICD10sSelection-addSelection'
              type='button'
              disabled={isEmpty(selectedCodes)}
              onKeyDown={() => onSubmit(selectedCodes)}
              onClick={() => onSubmit(selectedCodes)}
            >
              Add
            </PrimaryButton>
            {/* @ts-expect-error TS(2322): Type '{ type: string; size: string; text: string; ... Remove this comment to see the full error message */}
            <TextButton type='button' size='primary' text='Cancel' onClick={closeModal} onKeyDown={closeModal} />
          </div>
        </div>
      </div>
    </div>
  )
}

ICD10sSelection.propTypes = {
  initialCodes: PropTypes.array,
  submitFunction: PropTypes.func,
  closeModal: PropTypes.func,
  availableCodes: PropTypes.object,
  multiSelect: PropTypes.bool,
}

export default CSSModules(ICD10sSelection, styles)
