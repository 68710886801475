import React from 'react'
import colors from '../../../styles/_0colors.scss'

const InfoSheetIcon: React.FC<InfoSheetIconProps> = ({ size = 24, fillColor = colors.x_navy6 }) => {
  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} role='img' aria-label='InfoSheet'>
      <path
        d='M11 18.28L8.80005 18.98V5.44003L13.25 4.00003V10C13.25 10.1989 13.3291 10.3897 13.4697 10.5304C13.6104 10.671 13.8011 10.75 14 10.75C14.199 10.75 14.3897 10.671 14.5304 10.5304C14.671 10.3897 14.75 10.1989 14.75 10V4.00003L19.25 5.42003V10C19.25 10.1989 19.3291 10.3897 19.4697 10.5304C19.6104 10.671 19.8011 10.75 20 10.75C20.199 10.75 20.3897 10.671 20.5304 10.5304C20.671 10.3897 20.75 10.1989 20.75 10V4.89003C20.7514 4.73028 20.7016 4.57429 20.6081 4.44478C20.5145 4.31527 20.3821 4.21902 20.23 4.17003L14.23 2.28003H14.05C13.9775 2.26521 13.9026 2.26521 13.83 2.28003H13.77L8.00005 4.10003L2.23005 2.28003C2.11702 2.2441 1.99694 2.23625 1.8802 2.25716C1.76346 2.27807 1.65358 2.32711 1.56005 2.40003C1.46325 2.46707 1.38438 2.55682 1.33033 2.66143C1.27629 2.76603 1.24872 2.8823 1.25005 3.00003V18.11C1.24874 18.2698 1.29848 18.4258 1.39201 18.5553C1.48554 18.6848 1.61799 18.781 1.77005 18.83L7.77005 20.72C7.8464 20.7298 7.92369 20.7298 8.00005 20.72H8.23005L11.4 19.72C11.5761 19.6545 11.7211 19.5253 11.8064 19.358C11.8918 19.1907 11.9113 18.9974 11.861 18.8164C11.8107 18.6355 11.6944 18.4799 11.535 18.3806C11.3755 18.2813 11.1847 18.2454 11 18.28Z'
        fill={fillColor}
      />
      <path
        d='M17.5 11.25C16.4617 11.25 15.4466 11.5579 14.5833 12.1348C13.7199 12.7117 13.047 13.5316 12.6496 14.4909C12.2523 15.4502 12.1483 16.5058 12.3509 17.5242C12.5535 18.5426 13.0535 19.4781 13.7877 20.2123C14.5219 20.9465 15.4574 21.4465 16.4758 21.6491C17.4942 21.8517 18.5498 21.7477 19.5091 21.3504C20.4684 20.953 21.2883 20.2801 21.8652 19.4167C22.4421 18.5534 22.75 17.5384 22.75 16.5C22.7474 15.1084 22.1934 13.7746 21.2094 12.7906C20.2254 11.8066 18.8916 11.2526 17.5 11.25ZM17.5 20.25C16.7583 20.25 16.0333 20.0301 15.4166 19.618C14.7999 19.206 14.3193 18.6203 14.0355 17.9351C13.7516 17.2498 13.6774 16.4958 13.8221 15.7684C13.9668 15.041 14.3239 14.3728 14.8484 13.8483C15.3728 13.3239 16.041 12.9667 16.7684 12.8221C17.4958 12.6774 18.2498 12.7516 18.9351 13.0355C19.6203 13.3193 20.206 13.7999 20.618 14.4166C21.0301 15.0333 21.25 15.7583 21.25 16.5C21.25 17.4946 20.8549 18.4484 20.1517 19.1517C19.4484 19.8549 18.4946 20.25 17.5 20.25Z'
        fill={fillColor}
      />
      <path
        d='M17.5 15.75C17.3019 15.7526 17.1126 15.8324 16.9725 15.9725C16.8324 16.1126 16.7526 16.3019 16.75 16.5V18.3C16.75 18.4989 16.829 18.6897 16.9697 18.8303C17.1103 18.971 17.3011 19.05 17.5 19.05C17.6989 19.05 17.8897 18.971 18.0303 18.8303C18.171 18.6897 18.25 18.4989 18.25 18.3V16.5C18.2474 16.3019 18.1676 16.1126 18.0275 15.9725C17.8874 15.8324 17.6981 15.7526 17.5 15.75Z'
        fill={fillColor}
      />
      <path
        d='M17.5003 15.38C17.8759 15.38 18.1803 15.0756 18.1803 14.7C18.1803 14.3245 17.8759 14.02 17.5003 14.02C17.1248 14.02 16.8203 14.3245 16.8203 14.7C16.8203 15.0756 17.1248 15.38 17.5003 15.38Z'
        fill={fillColor}
      />
    </svg>
  )
}

type InfoSheetIconProps = {
  size?: number | string
  fillColor?: string
}

export default InfoSheetIcon
