// @ts-expect-error TS(7006): Parameter 'employer' implicitly has an 'any' type.
export default (employer, clientFullName) => ({
  meta_data: {
    schema: {
      type: 'object',
      properties: {
        approachingSpecialityCare: {
          name: 'approachingSpecialityCare',
          content: `### Please note that ${clientFullName} has fewer than 5 sessions remaining.
          \n
          \n${employer} offers a high-risk care program for select mental health diagnoses. The goal of this program is to reduce hospitalizations among high-risk clients.
          \n
          \nThis program covers ongoing treatment for clients with at least one of the following:
          \ni. High-risk factors, such as recent suicide behaviors, self-harm, recent trauma exposure, or hospitalizations/residential stays.
          \n
          \nii. An active substance use disorder.
          \n
          \niii. Persistent mental health conditions, such as OCD, a personality disorder, mood disorders, schizophrenia, eating disorders, or PTSD.
          \n
          \niv. Treatment resistant anxiety or depression.
          \n
          \nv. Minors, though attestation will not be needed.
          \n
          \nFor any questions, please contact [providers@lyraclinical.com](mailto:providers@lyraclinical.com)`,
        },
      },
    },
    uiSchema: {
      'ui:order': ['approachingSpecialityCare'],
    },
  },
})
