/* eslint-disable react/no-unused-prop-types */
/* eslint-disable formatjs/no-literal-string-in-jsx */
import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { LayoutChangeEvent, Platform } from 'react-native'

import styled from 'styled-components/native'

import { Appointment, getDateFromAppointment, Theme } from '@lyrahealth-inc/shared-app-logic'

import { ZoomVideoSessionMobileView } from './ZoomVideoSessionMobileView'
import { AvatarDetails, ThemedStatusBar } from '../../atoms'
import { ElementState, useToggleElement } from '../../hooks'
import { useZoom } from '../../hooks/useZoom'
import { AddToastFunction, JoinSessionParams } from '../../hooks/useZoom/types'
import { ZoomPermissionsModal } from '../../molecules'
import { ZoomSettingsModal } from '../../molecules/zoomSettingsModal/ZoomSettingsModal'
import { ZoomVideoCheck } from '../../molecules/zoomVideoCheck/ZoomVideoCheck'
import { ZoomVideoSessionMobileBase } from '../../molecules/zoomVideoSessionMobileBase/ZoomVideoSessionMobileBase'
import { ZoomWaitingRoom } from '../../molecules/zoomWaitingRoom/ZoomWaitingRoom'
import { Flex1SafeAreaView } from '../../templates'
import { ThemeProvider } from '../../utils'

const Container = styled(Flex1SafeAreaView)(({ theme }) => ({
  backgroundColor: theme.colors.components.zoomVideoSession.background,
  height: '100%',
}))

export type ZoomVideoSessionMobileProps = {
  getSessionParams: () => Promise<JoinSessionParams>
  appointment: Appointment
  participantName: string
  participantFirstName?: string
  avatarDetails?: AvatarDetails
  participantTitle?: string
  onSessionOpened?: () => void
  onSessionStarted?: () => void
  onSessionEnded?: () => void
  onSessionClosed?: () => void
  showRecordingStatus?: boolean
  isRecording?: boolean
  messenger?: React.ReactElement
  onOpenMessagesPressed?: () => void
  unreadMessageCount?: number
  onLockScreenOrientation?: (lock: boolean) => void
  isFocused?: boolean
  addToast?: AddToastFunction
}

export const ZoomVideoSessionMobile: FunctionComponent<ZoomVideoSessionMobileProps> = ({
  getSessionParams,
  participantName,
  appointment,
  avatarDetails,
  participantTitle,
  participantFirstName,
  onSessionStarted,
  onSessionEnded,
  onSessionOpened,
  onSessionClosed,
  onOpenMessagesPressed,
  unreadMessageCount,
  onLockScreenOrientation,
  messenger,
  isFocused,
  addToast,
}) => {
  const {
    inSession,
    sessionStarted,
    settings,
    updateSettings,
    setSessionClosedCallback,
    setSessionEndedCallback,
    setAddToast,
    setAttached,
    showPermissionsModal,
    closePermissionsModal,
    config: { shouldJoinOnOpen },
  } = useZoom()
  const [theme, setTheme] = useState<Theme>(Theme.DARK)
  const [showSettings, setShowSettings] = useState(false)
  const [landscape, setLandscape] = useState(false)
  const hasStarted = useRef(false)

  useEffect(() => {
    onSessionOpened?.()
  }, [onSessionOpened])

  useEffect(() => {
    setAttached(true)
    return () => setAttached(false)
  }, [setAttached])

  useEffect(() => {
    setSessionEndedCallback(onSessionEnded)
    setSessionClosedCallback(onSessionClosed)
    setAddToast(addToast)
  }, [addToast, onSessionClosed, onSessionEnded, setAddToast, setSessionClosedCallback, setSessionEndedCallback])

  useEffect(() => {
    if (sessionStarted && !hasStarted.current) {
      hasStarted.current = true
      onSessionStarted?.()
    }
  }, [onSessionStarted, sessionStarted])

  useEffect(() => {
    setTheme(inSession && !sessionStarted ? Theme.LIGHT : Theme.DARK)
  }, [inSession, sessionStarted, shouldJoinOnOpen])

  useEffect(() => {
    onLockScreenOrientation?.(!inSession || !sessionStarted)
  }, [inSession, onLockScreenOrientation, sessionStarted])

  const { elementState, toggleElement, elementOpacity, clearElementTimer, resetElementTimer, showElement } =
    useToggleElement({
      showElementOnLoad: true,
      hideElementTimerDuration: 5000,
    })

  const onLayout = useCallback((e: LayoutChangeEvent) => {
    const { width, height } = e.nativeEvent.layout
    setLandscape(width > height)
  }, [])

  useEffect(() => {
    if (Platform.OS === 'web' || !sessionStarted) {
      showElement()
      clearElementTimer()
      return
    }
    resetElementTimer()
  }, [clearElementTimer, resetElementTimer, sessionStarted, showElement])

  const startDate = useMemo(() => getDateFromAppointment(appointment), [appointment])
  const openSettings = useCallback(() => setShowSettings(true), [])

  const children = useMemo(() => {
    if (!inSession) {
      return (
        <ZoomVideoCheck
          getSessionParams={getSessionParams}
          participantName={participantName}
          sessionStartDate={startDate}
          sessionNumber={appointment.sessionNumber}
          sessionDuration={appointment.appointmentDuration}
          openSettings={openSettings}
          isFocused={isFocused}
        />
      )
    }

    return (
      <ZoomVideoSessionMobileBase
        participantName={participantName}
        sessionStartDate={startDate}
        sessionNumber={appointment.sessionNumber}
        sessionDuration={appointment.appointmentDuration}
        openSettings={openSettings}
        landscape={landscape}
        onOpenMessagesPressed={onOpenMessagesPressed}
        unreadMessageCount={unreadMessageCount}
        elementOpacity={elementOpacity}
        hidden={elementState === ElementState.Hidden}
        participantFirstName={participantFirstName}
        messenger={messenger}
        isFocused={isFocused}
      >
        {sessionStarted ? (
          <ZoomVideoSessionMobileView
            participantName={participantName}
            avatarDetails={avatarDetails}
            participantTitle={participantTitle}
            participantFirstName={participantFirstName}
            landscape={landscape}
            onPress={Platform.OS !== 'web' ? toggleElement : undefined}
          />
        ) : (
          <ZoomWaitingRoom participantName={participantName} />
        )}
      </ZoomVideoSessionMobileBase>
    )
  }, [
    appointment.appointmentDuration,
    appointment.sessionNumber,
    avatarDetails,
    elementOpacity,
    elementState,
    inSession,
    isFocused,
    landscape,
    messenger,
    onOpenMessagesPressed,
    openSettings,
    participantFirstName,
    participantName,
    participantTitle,
    getSessionParams,
    sessionStarted,
    startDate,
    toggleElement,
    unreadMessageCount,
  ])
  return (
    <>
      <ThemeProvider theme={theme}>
        <ThemedStatusBar defaultStyle='dark' />
        <Container onLayout={onLayout}>{children}</Container>
      </ThemeProvider>
      <ZoomSettingsModal
        show={showSettings}
        onClose={() => setShowSettings(false)}
        onSave={(settings) => {
          setShowSettings(false)
          updateSettings(settings)
        }}
        settings={settings}
      />
      <ZoomPermissionsModal show={showPermissionsModal} onClose={closePermissionsModal} />
    </>
  )
}
