import React from 'react'
import { useIntl } from 'react-intl'
import { TextStyle, ViewStyle } from 'react-native'

import { useTheme } from 'styled-components/native'

import { BodyText } from '../../atoms/bodyText/BodyText'
import { BodyTextSize } from '../../styles/typeStyles'
import { Accordion } from '../accordion/Accordion'

export interface AppleEmployeeIdDisclaimerProps {
  textSize?: BodyTextSize.SMALL | BodyTextSize.DEFAULT
}

export const AppleEmployeeIdDisclaimer: React.FC<AppleEmployeeIdDisclaimerProps> = ({
  textSize = BodyTextSize.SMALL,
}) => {
  const { formatMessage } = useIntl()
  const { colors } = useTheme()

  const title = formatMessage({
    defaultMessage: 'How is the Apple Employee ID used?',
    description: 'Question text for answer explaining how employee ID is used after collecting from users',
  })

  const contentText = formatMessage({
    defaultMessage:
      'Your personally identifiable health information is never shared with Apple. Employee ID is used by Lyra for service eligibility verification and program utilization, development, and assessment purposes. Information provided to Apple will always be at the aggregate level and anonymized and, therefore, will never include any information that directly or indirectly identifies you as an individual, including your Employee ID.',
    description: 'Text to explain how employee ID is being used after collecting from users',
  })
  const content = <BodyText text={contentText} size={textSize} />

  const containerStyle: ViewStyle = { paddingVertical: 0, backgroundColor: 'transparent' }
  const titleStyle: TextStyle = {
    textDecorationLine: 'underline',
    color: colors.textActive,
    fontSize: textSize === BodyTextSize.SMALL ? 14 : 16,
  }
  const buttonStyle: ViewStyle = { alignItems: 'flex-start' }
  const contentInnerContainerStyle: ViewStyle = {
    paddingTop: 4,
    paddingBottom: 16,
  }

  return (
    <Accordion
      testIDPrefix='AppleEmployeeIdDisclaimer'
      title={title}
      content={content}
      contentInnerContainerStyle={contentInnerContainerStyle}
      style={containerStyle}
      titleStyle={titleStyle}
      buttonStyle={buttonStyle}
      showGradient={false}
    />
  )
}
