import React from 'react'
import { connect, useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'

import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'

import { useFetcher } from '@lyrahealth-inc/ui-core-crossplatform'

import useTwilioMessaging from '../../../common/components/hooks/useTwilioMessaging'
import { getAuthConfig, getAuthUserId } from '../../../data/auth/authSelectors'
import {
  getAccessToken,
  getMessageConversations,
  incrementUnreadMessageCount,
  setConversationsClient,
} from '../../messages/data/messagesAutoActions'
import { getConversationsClient } from '../../messages/data/messagesSelectors'
import { Config } from '../../types'

export const ClientsContainer = ({
  conversationsClient,
  actions: { getMessageConversations, getAccessToken, incrementUnreadMessageCount, setConversationsClient },
}: any) => {
  const userId: string = useSelector(getAuthUserId)
  const { showOverdueMessageFlag }: Config = useSelector(getAuthConfig)
  useTwilioMessaging({
    userId,
    conversationsClient,
    actions: { getAccessToken, incrementUnreadMessageCount, setConversationsClient },
  })

  useFetcher(
    [
      getMessageConversations,
      { provider_lyra_id: userId, ...(showOverdueMessageFlag && { derived_properties: 'overdue' }) },
    ],
    [userId],
  )

  return <Outlet />
}

ClientsContainer.propTypes = {
  conversationsClient: PropTypes.object,
  actions: PropTypes.object,
}

const mapStateToProps = ($$state: any) => ({
  conversationsClient: getConversationsClient($$state),
})

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      getMessageConversations,
      getAccessToken,
      incrementUnreadMessageCount,
      setConversationsClient,
    },
    dispatch,
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(ClientsContainer)
