import React from 'react'

type OwnProps = {
  width?: string | number
  fillColor?: string
}

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof LinkIcon.defaultProps

// @ts-expect-error TS(7022): 'LinkIcon' implicitly has type 'any' because it do... Remove this comment to see the full error message
const LinkIcon = ({ width, fillColor }: Props) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={width} viewBox={`0 0 ${width} ${width}`}>
      <path
        fill={fillColor}
        fillRule='evenodd'
        d='M12.5857864,2 L10,2 L10,0 L16,0 L16,2 L16,6 L14,6 L14,3.41421356 L9.87867966,7.53553391 L8.46446609,6.12132034 L12.5857864,2 Z M2,14 L14,14 L14,10 L16,10 L16,14 L16,16 L0,16 L0,14 L0,2 L0,0 L6,0 L6,2 L2,2 L2,14 Z'
      />
    </svg>
  )
}

LinkIcon.defaultProps = {
  width: 16,
  fillColor: '#008599',
}

export default LinkIcon
