import React from 'react'
import styles from './sliderField.module.scss'
// there is no type for react-bootstrap-slider
// @ts-expect-error TS(7016): Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ReactBootstrapSlider from 'react-bootstrap-slider'
import classNames from 'classnames'
import 'bootstrap-slider/src/sass/bootstrap-slider.scss'
import Tooltip from '../../tooltip/Tooltip'
import { uniqueId, isString } from 'lodash-es'
import BaseInput from '../baseInput/BaseInput'
import { FieldInputProps } from 'react-final-form'

const SliderField: React.FC<SliderProps> = ({
  step = 1,
  input,
  minLabel,
  medianLabel,
  maxLabel,
  label,
  minValue = 0,
  maxValue = 100,
  initialValue = 50,
  tooltipContent,
  hideSliderSelection = true,
  showEndDots = false,
  displayValue = false,
  percentage,
  meta: { touched, error, submitError, warning },
  className = undefined,
  disabled,
  readOnly,
}) => {
  const onSlideMove = (e: any) => {
    input.onChange(e?.target?.value)
  }
  let hasTooltip = null
  if (tooltipContent) {
    hasTooltip = <Tooltip content={tooltipContent} id={uniqueId('inputTooltip_')} />
  }
  let val = input.value || input.value === 0 ? input.value : initialValue
  val = isString(val) ? parseInt(val, 10) : val
  const displayedVal = percentage ? Math.floor((val / maxValue) * 100) + '%' : val

  return (
    <BaseInput
      className={className}
      fieldInfoId=''
      touched={touched}
      error={error || submitError}
      warning={warning}
      readOnly={readOnly}
      disabled={disabled}
      htmlFor={input.name}
      label={label}
      labelId={input.name}
      toolTip={hasTooltip}
    >
      <div
        className={classNames(
          className,
          styles['slider-container'],
          { [styles['read-only']]: readOnly },
          { [styles['hide-slider-selection']]: hideSliderSelection },
          { [styles['end-dots']]: showEndDots },
        )}
      >
        {displayValue ? (
          <span data-test-id={`${input.name}-SliderField-value`} className={styles['value-label']}>
            {displayedVal}
          </span>
        ) : (
          ''
        )}
        <ReactBootstrapSlider
          disabled={`${disabled || readOnly ? 'disabled' : undefined}`}
          id={input.name}
          labelledby={input.name}
          value={val}
          change={onSlideMove}
          step={step}
          tooltip='hide'
          max={maxValue}
          min={minValue}
        />
        <div className={styles['min-max-labels-container']}>
          <div className={styles['min-label']}>{minLabel}</div>
          {medianLabel ? <div className={styles['median-label']}>{medianLabel}</div> : null}
          <div className={styles['max-label']}>{maxLabel}</div>
        </div>
      </div>
    </BaseInput>
  )
}

type SliderProps = {
  step?: number
  meta: {
    touched: boolean
    error: string
    submitError: string
    warning: string
  }
  input: FieldInputProps<any, any>
  label: string
  tooltipContent?: any
  minValue?: number
  maxValue?: number
  minLabel: string
  medianLabel?: string
  maxLabel: string
  initialValue?: number
  displayValue?: boolean
  className?: string
  hideSliderSelection?: boolean
  showEndDots?: boolean
  disabled: boolean
  percentage: boolean
  readOnly: boolean
  children?: any
}

export default SliderField
