import React from 'react'
import CSSModules from 'react-css-modules'
import { connect } from 'react-redux'
import { Navigate, Route } from 'react-router-dom'

import { Map } from 'immutable'
import * as _ from 'lodash-es'
import queryString from 'query-string'
import { bindActionCreators } from 'redux'

import { BootstrapContainer, NavButton } from '@lyrahealth-inc/ui-core'

import styles from './providerDetails.module.scss'
import DisplayProviderRates from './rates/DisplayProviderRates'
import EditProviderRates from './rates/EditProviderRates'
import TabFilters from '../../../common/components/tabFilters/TabFilters'
import {
  PRACTICES_DETAILS_PROVIDERS,
  PROVIDERS,
  PROVIDERS_DETAILS_CALENDAR,
  PROVIDERS_DETAILS_EDIT_INFO,
  PROVIDERS_DETAILS_EDIT_RATES,
  PROVIDERS_DETAILS_INFO,
  PROVIDERS_DETAILS_RATES,
} from '../../../common/constants/routingConstants'
import { axiosAuthInstance } from '../../../common/http/axiosInstance'
import { SentryRoutes } from '../../../index'
import withRouter from '../../../routing/withRouter'
import { getProviderDetailsData } from '../../data/providerSelectors'
import * as providerDetailsActions from '../data/providerDetailsActions'
// pages
import DisplayProviderInfo from '../info/DisplayProviderInfo'
import EditProviderInfo from '../info/EditProviderInfo'

type ProviderDetailsProps = {
  router?: any
  actions?: any
  providerDetails?: any // TODO: PropTypes.instanceOf(Map)
}

class ProviderDetails extends React.Component<ProviderDetailsProps> {
  router: any

  componentDidMount() {
    const { router } = this.props
    const providerId =
      (router.location.state && router.location.state.provider_id) ||
      // Reading the `id` from the url is useful for routing from the `calendar` python page to this view.
      (queryString.parse(router.location.search) && queryString.parse(router.location.search).id)
    if (providerId) {
      // get provider by ID
      this.props.actions.getProvider(providerId)
    } else {
      // If we don't have an ID, punt them back to the providers list
      router.navigate(PROVIDERS.route, { replace: true })
    }
  }

  componentWillUnmount() {
    this.props.actions.clearProviderDetailsStore()
    if (typeof (axiosAuthInstance as any).cancelLastRequest === 'function') {
      ;(axiosAuthInstance as any).cancelLastRequest()
    }
  }

  _backToProviders = () => {
    const { router } = this.router
    if (router.location.state && router.location.state.practiceId) {
      return router.navigate(PRACTICES_DETAILS_PROVIDERS.route, {
        state: { practiceId: router.location.state.practiceId },
      })
    }

    router.navigate(PROVIDERS.route, { replace: true })
  }

  _handleTabClick = (tab: any) => {
    const providerId = this.props.providerDetails?.lyra_id
    switch (tab.toLowerCase()) {
      case 'rates':
        this.props.router.navigate(PROVIDERS_DETAILS_RATES.route, { replace: true })
        break
      case 'info':
        this.props.router.navigate(PROVIDERS_DETAILS_INFO.route, { replace: true })
        break
      case 'calendar':
        this.props.router.navigate(`${PROVIDERS_DETAILS_CALENDAR.route}/${providerId}`, { replace: true })
    }
  }

  _renderTabs = () => {
    let tab
    const pathname = this.props.router.location.pathname
    if (_.includes(pathname, 'info')) {
      tab = 'Info'
    } else if (_.includes(pathname, 'rates')) {
      tab = 'Rates'
    } else if (_.includes(pathname, 'calendar')) {
      tab = 'Calendar'
    }

    // @ts-expect-error TS(2322): Type '(tab: any) => void' is not assignable to typ... Remove this comment to see the full error message
    return <TabFilters activeTab={tab} tabList={['INFO', 'RATES', 'CALENDAR']} handleClick={this._handleTabClick} />
  }

  _submitRates = ($$values: any) => {
    return this.props.actions.editRates(this.props.providerDetails.lyra_id, $$values.toJS()).then(() => {
      this.props.router.navigate(PROVIDERS_DETAILS_RATES.route, { replace: true })
    })
  }

  render() {
    if (!this.props.providerDetails) {
      return false
    }

    const { providerDetails } = this.props

    return (
      <BootstrapContainer>
        <NavButton
          // @ts-expect-error TS(2322): Type '{ className: string; styleType: string; text... Remove this comment to see the full error message
          className={styles['back-button']}
          styleType='back'
          text='Back to Providers'
          onClick={this._backToProviders}
        />
        <div styleName='overview'>
          <h2>
            {providerDetails.first_name} {providerDetails.last_name}
          </h2>
          <p>{providerDetails.email}</p>
        </div>
        {this._renderTabs()}
        <SentryRoutes>
          <Route
            path={PROVIDERS_DETAILS_EDIT_INFO.subroute}
            element={<EditProviderInfo initialValues={Map(providerDetails)} />}
          />
          <Route path={PROVIDERS_DETAILS_INFO.subroute} element={<DisplayProviderInfo data={providerDetails} />} />
          <Route
            path={PROVIDERS_DETAILS_EDIT_RATES.subroute}
            element={
              // @ts-expect-error TS(2322): Type '{ initialValues: any; _submitForm: ($$values... Remove this comment to see the full error message
              <EditProviderRates initialValues={Map(providerDetails.rates)} _submitForm={this._submitRates} />
            }
          />
          <Route
            path={PROVIDERS_DETAILS_RATES.subroute}
            element={<DisplayProviderRates $$data={Map(providerDetails.rates)} />}
          />
          <Route path='*' element={<Navigate to={PROVIDERS_DETAILS_INFO.route} />} />
        </SentryRoutes>
      </BootstrapContainer>
    )
  }
}

const mapStateToProps = ($$state: any) => {
  return {
    providerDetails: getProviderDetailsData($$state),
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    actions: bindActionCreators({ ...providerDetailsActions }, dispatch),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CSSModules(ProviderDetails, styles)))
