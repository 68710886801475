export interface Note {
  _create_date: string
  _update_date: string
  appointment_id: number | null
  content_id: string
  content_meta_data: NoteMetadata
  content_meta_data_id: string
  create_date: string
  episode_id: string
  id: string
  note_type: NoteTypes
  overdue?: boolean
  patient_id: string
  provider_id: string
  provider_patient_id: string
  status: NoteStatusStates
  submit_date: string | null
  update_date: string
  version_id: number
  body: { [key: string]: string | number | boolean | string[] | null }
}
export interface GeneralNote extends Note {
  body: GeneralNoteBody
}

export interface NoteMetadata {
  content_type: string
  description: string
  group: string
  id: string
  instructions: string
  meta_data: Metadata
  name: string
  program_id: string
  tags?: string[] | null
  title: string
}

interface Metadata {
  schema: {
    properties?: {}
    type?: string
  }
  uiSchema?: Dict
  initialValues?: Dict
}

type GeneralNoteBody = {
  accurateAndComplete?: boolean
  authorName: string
  details?: string
  subject?: string
}

export enum NoteStatusStates {
  COMPLETED = 'completed',
  DRAFT = 'draft',
}

export enum NoteTypes {
  GENERAL = 'general',
  INITIAL = 'initial',
  REFERRAL = 'referral',
  SESSION = 'session',
}
