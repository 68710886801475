import React from 'react'
import { useFormState } from 'react-final-form'
import ReactMarkdown from 'react-markdown'

import { get } from 'lodash-es'
// @ts-expect-error TS(7016): Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import SchemaField from 'react-jsonschema-form/lib/components/fields/SchemaField'

import { isFieldDisplayed } from '@lyrahealth-inc/shared-app-logic'

import SUDSTable from '../SUDSTable/SUDSTable'

const addTitleStar = (schema = {}) => {
  const title = (schema as $TSFixMe).title
  if (title) {
    if (get(schema, 'validation.isRequired') && title[title.length - 1] !== '*') {
      ;(schema as $TSFixMe).title = `${(schema as $TSFixMe).title} *`
    }
  }
}

/**
 * Function below is intended to open links in markdown in a new tab
 * https://github.com/rexxars/react-markdown/issues/65
 * Links that are in JSON are technically already markdown,
 * so doing "<a href="/link" target="_blank"></a> will not render correctly
 * to set up link to open in new tab, set string path to be {{new_tab}}/https://...
 **/
export const LinkRenderer = (props: $TSFixMe) => {
  // parse href and check if {{new_tab}} was passed and if it was, remove it from the url
  const decodedHREF = decodeURIComponent(props.href)
  const newTab = decodedHREF.indexOf('{{new_tab}}') !== -1
  const href = decodedHREF.replace('{{new_tab}}', '')
  return (
    <a href={href} target={`${newTab ? '_blank' : '_self'}`}>
      {props.children}
    </a>
  )
}

const CustomSchemaField = (props: $TSFixMe) => {
  const { schema, registry } = props
  if (schema.show === false) return null
  let field
  addTitleStar(schema)
  if (schema.type === undefined) {
    const components = { a: LinkRenderer, p: (props: $TSFixMe) => <p style={schema.style}>{props.children}</p> }
    field = (
      <div style={{ marginBottom: '25px' }} id={schema.name}>
        {/* eslint-disable-next-line react/no-children-prop */}
        <ReactMarkdown disallowedElements={['inlineCode', 'code']} components={components} children={schema.content} />
      </div>
    )
  } else {
    field = <SchemaField {...props} />
  }
  if (schema.condition) {
    field = (
      <Condition condition={schema.condition} name={schema.name} formFields={registry?.formContext?.schema.properties}>
        {field}
      </Condition>
    )
  }
  return field
}

const Condition = ({ name, condition, formFields, children }: $TSFixMe) => {
  const { values } = useFormState()
  return (
    <div
      data-test-id={name}
      style={isFieldDisplayed({ condition, formValues: values, formFields, name }) ? {} : { display: 'none' }}
    >
      {children}
    </div>
  )
}

const fields = {
  SchemaField: CustomSchemaField,
  SUDSTable: () => <SUDSTable />,
}

export default fields
