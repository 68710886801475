import React from 'react'
import styles from './outcomeChart.module.scss'
import { get, isNil } from 'lodash-es'
import { Outcome } from '@lyrahealth-inc/shared-app-logic'
import colors from '../../../styles/_0colors.scss'

const CustomDot: React.FC<CustomDotProps> = ({
  cx = 0,
  cy = 0,
  dataKey = '',
  fill = '#fff',
  fillNoValueEntries = false,
  index = 0,
  isActive = false,
  payload = {},
  stroke = colors.x_secondary_action,
  tooltipIndex = -1,
}) => {
  const dotRadius = 4
  const lineHeight = 440 - 55 + dotRadius - cy // 440 is the height of the chart. 55 is the height of the x axis labels.
  let fillColor = fill === '#fff' ? stroke : fill
  const ourActive = isActive || tooltipIndex === index
  const noValue = fillNoValueEntries && isNil(get(payload, dataKey))
  const phqSelfHarm = dataKey.includes('PHQ') && get(payload, 'scores.PHQ-SELF-HARM')
  if (phqSelfHarm && phqSelfHarm > 0) fillColor = styles.x_alert

  return (
    <svg x={cx - dotRadius} y={cy - dotRadius} height={lineHeight} width={dotRadius * 2}>
      {ourActive && (
        <line
          x1={dotRadius}
          y1='0'
          x2={dotRadius}
          y2={lineHeight}
          strokeLinecap='round'
          stroke={fillColor}
          strokeWidth={'2'}
          data-test-id='CustomDot-activeLine'
        />
      )}
      {noValue ? (
        <line
          x1={dotRadius}
          y1='0'
          x2={dotRadius}
          y2={lineHeight}
          strokeLinecap='round'
          stroke={styles.x_light_gray_10}
          strokeWidth={String(dotRadius * 2)}
          data-test-id='CustomDot-noValueLine'
        />
      ) : (
        <circle cx={dotRadius} cy={dotRadius} r={dotRadius} fill={fillColor} data-test-id='CustomDot-valueCicle' />
      )}
    </svg>
  )
}

type CustomDotProps = {
  cx?: number
  cy?: number
  dataKey?: string
  fill?: string
  fillNoValueEntries?: boolean
  index?: number
  isActive?: boolean
  payload?: Outcome
  stroke?: string
  tooltipIndex?: number
}

export default CustomDot
