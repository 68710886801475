import React from 'react'
import styles from './oktaStyleWrapper.module.scss'

type Props = {
  children: React.ReactNode
}

const OktaStyleWrapper = ({ children, ...props }: Props) => {
  return (
    <div {...props} className={styles['okta-wrapper']}>
      {children}
    </div>
  )
}

export default OktaStyleWrapper
