import React from 'react'
import CSSModules from 'react-css-modules'
import styles from './requestPayment.module.scss'
import { format } from 'date-fns'
import sessionResetDate from '../common/content/sessionResetDate'
import { CustomerInfo } from '@lyrahealth-inc/shared-app-logic'

const SessionsExceededModal = ({ firstName, lastName, company }: SessionsExceededModalProps) => {
  return (
    <div styleName='modal-content'>
      <h2 styleName='main-header'>Invoice not submitted</h2>
      <div>
        <p>
          Your invoice dated {format(new Date(), 'MMMM d, yyyy')} for client{' '}
          <span styleName='capitalize'>{firstName}</span> <span styleName='capitalize'>{lastName}</span> was not
          processed because <span styleName='capitalize'>{firstName}</span> doesn’t have any more covered sessions
          remaining as part of their Lyra benefit. Unfortunately, Lyra is unable to reimburse for any additional visits
          for this client. {sessionResetDate({ employer: company?.label, date: company?.session_count_starts_on })}
        </p>
        <p className='paragraph-small'>
          For any questions, please contact <a href='mailto:providers@lyrahealth.com'>providers@lyrahealth.com</a>
        </p>
      </div>
    </div>
  )
}

type SessionsExceededModalProps = {
  firstName: string
  lastName: string
  company?: CustomerInfo
}

export default CSSModules(SessionsExceededModal, styles)
