import React, { FunctionComponent } from 'react'
import Svg, { G, Mask, Path } from 'react-native-svg'

import { tID } from '../../utils'

export const GoogleIcon: FunctionComponent<GoogleIconProps> = ({ size = 24 }) => {
  return (
    <Svg testID={`${tID('GoogleIcon')}`} width={size} height={size} viewBox='0 0 24 24' fill='none'>
      <Mask id='a' maskUnits='userSpaceOnUse' x={0} y={0} width={size} height={size}>
        <Path
          d='M23.182 9.818H12v4.637h6.436c-.6 2.945-3.109 4.636-6.436 4.636A7.077 7.077 0 014.91 12 7.077 7.077 0 0112 4.91c1.69 0 3.218.6 4.418 1.58L19.91 3C17.782 1.145 15.055 0 12 0 5.345 0 0 5.345 0 12s5.345 12 12 12c6 0 11.455-4.364 11.455-12 0-.71-.11-1.473-.273-2.182z'
          fill='#fff'
        />
      </Mask>
      <G mask='url(#a)'>
        <Path d='M-1.09 19.091V4.909L8.181 12l-9.273 7.091z' fill='#FBBC05' />
      </G>
      <Mask id='b' maskUnits='userSpaceOnUse' x={0} y={0} width={size} height={size}>
        <Path
          d='M23.182 9.818H12v4.637h6.436c-.6 2.945-3.109 4.636-6.436 4.636A7.077 7.077 0 014.91 12 7.077 7.077 0 0112 4.91c1.69 0 3.218.6 4.418 1.58L19.91 3C17.782 1.145 15.055 0 12 0 5.345 0 0 5.345 0 12s5.345 12 12 12c6 0 11.455-4.364 11.455-12 0-.71-.11-1.473-.273-2.182z'
          fill='#fff'
        />
      </Mask>
      <G mask='url(#b)'>
        <Path d='M-1.09 4.91L8.181 12 12 8.673l13.091-2.127v-7.637H-1.091v6z' fill='#EA4335' />
      </G>
      <Mask id='c' maskUnits='userSpaceOnUse' x={0} y={0} width={size} height={size}>
        <Path
          d='M23.182 9.818H12v4.637h6.436c-.6 2.945-3.109 4.636-6.436 4.636A7.077 7.077 0 014.91 12 7.077 7.077 0 0112 4.91c1.69 0 3.218.6 4.418 1.58L19.91 3C17.782 1.145 15.055 0 12 0 5.345 0 0 5.345 0 12s5.345 12 12 12c6 0 11.455-4.364 11.455-12 0-.71-.11-1.473-.273-2.182z'
          fill='#fff'
        />
      </Mask>
      <G mask='url(#c)'>
        <Path d='M-1.09 19.091L15.272 6.546l4.309.545 5.509-8.182v26.182H-1.091v-6z' fill='#34A853' />
      </G>
      <Mask id='d' maskUnits='userSpaceOnUse' x={0} y={0} width={size} height={size}>
        <Path
          d='M23.72 9.818H12.28v4.637h6.585c-.614 2.945-3.181 4.636-6.586 4.636-4.019 0-7.256-3.164-7.256-7.091S8.26 4.91 12.28 4.91A7.2 7.2 0 0116.8 6.49L20.372 3c-2.177-1.855-4.967-3-8.093-3C5.47 0 0 5.345 0 12s5.47 12 12.28 12C18.418 24 24 19.636 24 12c0-.71-.112-1.473-.28-2.182z'
          fill='#fff'
        />
      </Mask>
      <G mask='url(#d)'>
        <Path d='M25.674 25.091L8.372 12.001 6.14 10.363l19.534-5.455v20.182z' fill='#4285F4' />
      </G>
    </Svg>
  )
}
type GoogleIconProps = {
  size?: string | number
}
