import {
  CLEAR_VIDEO_SESSION_INFO,
  GET_VIDEO_CALL_TOKEN,
  GET_VIDEO_SESSION_RECORDING,
  GET_VIDEO_SESSIONS,
  SUBMIT_VIDEO_QUALITY_FEEDBACK,
  UPDATE_VIDEO_SESSION_METADATA,
  VIDEO_SESSION_SETTINGS_UPDATE,
  VIDEO_SESSION_STATUS_UPDATE,
} from '../../common/constants/reduxConstants'

export const pingLT = () => ({
  request: {
    method: 'get',
    url: '/lt/v1/ping',
  },
})

export const getToken = (data: any) => ({
  action: GET_VIDEO_CALL_TOKEN,

  request: {
    method: 'post',
    url: '/lt/v1/video/session',
    params: data,
  },
})

export const toggleVideoSessionStatus = (data: any) => ({
  type: VIDEO_SESSION_STATUS_UPDATE,
  data,
})

export const updateVideoSessionSettings = (data: any) => ({
  type: VIDEO_SESSION_SETTINGS_UPDATE,
  data,
})

export const updateVideoSessionMetadata = (data: any) => ({
  action: UPDATE_VIDEO_SESSION_METADATA,

  request: {
    method: 'put',
    url: `/lt/v1/video/session/${data.id}`,
    data,
  },
})

export const clearSessionInfo = () => ({ type: CLEAR_VIDEO_SESSION_INFO })

export const getVideoSessionRecording = ({ id }: any) => ({
  action: GET_VIDEO_SESSION_RECORDING,
  request: {
    method: 'get',
    url: `/lt/v1/video/session/${id}/content/cloudfront`,
  },
})

export const getVideoSessions = (data: any) => ({
  action: GET_VIDEO_SESSIONS,

  request: {
    method: 'get',
    url: '/lt/v1/video/session',
    params: data,
  },
})

export const submitVideoQualityFeedback = (data: any) => ({
  action: SUBMIT_VIDEO_QUALITY_FEEDBACK,

  request: {
    method: 'post',
    url: '/fdbks/v1/feedback',
    data,
  },
})
