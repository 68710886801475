import styled from 'styled-components/native'
import { css } from 'styled-components'
import { Flex1View, Flex1SafeAreaView } from './CommonViews'
import { Animated } from 'react-native'

const padding = css`
  padding: 0 24px;
`

/**
 * Common template styles used (padding, margin, etc)
 */

export const ContentAreaScrollView = styled.ScrollView`
  ${padding}
`

export const ContentAreaView = styled.View`
  ${padding}
`

export const ContentAreaFlex1View = styled(Flex1View)`
  ${padding}
`

export const ContentAreaAnimatedView = styled(Animated.View)`
  ${padding}
`

export const ContentAreaFlex1SafeAreaView = styled(Flex1SafeAreaView)`
  ${padding}
`
