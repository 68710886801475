import React, { Component, createRef } from 'react'
import { get } from 'lodash-es'
import { VideoProcessor, AddProcessorOptions, VideoTrack } from 'twilio-video'
import { isSafari, isIOS } from 'react-device-detect'
import { VirtualBackgroundProcessor, GaussianBlurBackgroundProcessor, Pipeline } from '@twilio/video-processors'
import { videoScreen, videoViewStyle } from '@lyrahealth-inc/shared-app-logic'

import hillsLandscape from '../../images/backgrounds/hills-landscape.jpg'
import therapyOffice from '../../images/backgrounds/therapy-office.jpg'
import providerSessionAlert from '../../sounds/providerSessionAlert.wav'
import TwilioVideo from '../../utils/importTwilioVideo'

const { connect, createLocalTracks, createLocalVideoTrack, createLocalAudioTrack, LocalDataTrack, LocalVideoTrack } =
  TwilioVideo

type VideoCallProps = OwnVideoCallProps & typeof VideoCall.defaultProps

export enum VideoCallEffects {
  NONE = 'none',
  BLUR = 'blur',
  OFFICE = 'office',
  HILL_LANDSCAPE = 'hill_landscape',
}

/* The twilio models have to be manually uploaded to our CDN documentation on how here 
   https://lyrahealth.atlassian.net/wiki/spaces/EN/pages/2655256681/Upload+Twilio+Models+for+Video+Background+Effects */
/* For localhost, we cannot load from assets.lyrahealth.com because it's not allowed origin. 
   Using a 3rd party source instead as workaround. */
const TWILIO_VIDEO_PROCESSOR_CDN_URL =
  location.host.indexOf('localhost') === -1
    ? 'https://assets.lyrahealth.com/LyraWebUI/external/twilio/'
    : 'https://cdn.jsdelivr.net/npm/@twilio/video-processors@2.0.0/dist/build/'

const BACKGROUND_EFFECTS_BLUR_RADIUS = 5

class VideoCall extends Component<VideoCallProps, VideoCallState> {
  static defaultProps = {
    logger: () => {},
    showAlert: () => {},
  }

  room = null

  userTracks: UserTrack[] | null = null

  user = null

  screenshareTrack = null

  localDataTrack = null

  roomName = null

  reconnecting = false

  statsInterval = null

  userAudio = createRef()

  participantAudio = createRef()

  effectsTestingRef = createRef<HTMLDivElement>()

  clientWaitingSound = new Audio(providerSessionAlert)

  // ========================================
  // Lifecycle Methods: =====================
  // ========================================

  constructor(props: VideoCallProps) {
    super(props)
    this.state = {
      connectedToRoom: false,
      reconnectionAttempts: 0,
    }
  }

  componentDidMount() {
    this._initializeUserTracks(this.props.deviceConstraints)
    window.addEventListener('beforeunload', this.browserCloseConfirmation)
  }

  componentWillUnmount() {
    // fire mixpanel
    this.props.track({
      page: 'video call',
      event: 'video call unmounting',
      details: this.props.type,
    })

    this.userTracks &&
      this.userTracks.forEach((track) => {
        if ((track as $TSFixMe).stop) {
          ;(track as $TSFixMe).stop()
        }
      })
    this.room && (this.room as $TSFixMe).disconnect()
    this.statsInterval && window.clearInterval(this.statsInterval)
    this.user && this._participantDisconnected(this.user)
    this._clearContainerContents()
  }

  async componentDidUpdate(prevProps: VideoCallProps) {
    if (this.props.muted !== prevProps.muted) {
      this._toggleAudioTrack(this.props.muted)
    }
    if (this.props.videoOff !== prevProps.videoOff) {
      this._toggleVideoTrack(this.props.videoOff)
    }
    if (this.props.viewStyle !== prevProps.viewStyle) {
      this._toggleViewStyle(this.props.viewStyle)
    }
    if (this.props.deviceSettings.videoInput !== prevProps.deviceSettings.videoInput) {
      this._updateVideoInput(this.props.deviceSettings.videoInput)
    }
    if (this.props.deviceSettings.audioInput !== prevProps.deviceSettings.audioInput) {
      this._updateAudioInput(this.props.deviceSettings.audioInput)
    }
    if (this.props.deviceSettings.audioOutput !== prevProps.deviceSettings.audioOutput) {
      this._updateAudioOutput(this.props.deviceSettings.audioOutput)
    }
    if (this.props.effects !== prevProps.effects) {
      this._updateEffects(this.props.effects)
    }
  }

  // ========================================
  // External Methods: ======================
  // ========================================

  sendData = (data: $TSFixMe) => {
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    this.localDataTrack.send(data)
  }

  shareScreen = async () => {
    const { showAlert, logger } = this.props
    await navigator.mediaDevices
      .getDisplayMedia({
        video: { frameRate: 15 },
        // @ts-expect-error
        selfBrowserSurface: 'include',
      })
      .then((returnedStream) => {
        // @ts-ignore-next-line
        if (returnedStream?.getTracks()[0]?.getSettings()?.displaySurface !== 'browser') {
          showAlert({
            message:
              "Oops! It looks like you've selected a sharing source that isn't a tab, please select a tab that you wish to share.",
            type: 'warning',
            customLog: true,
          })
          return
        }
        this._broadcastSharedScreen(returnedStream)
      })
      .catch((err) => {
        const errorName = err.name ? err.name.toLowerCase() : 'unknown name'
        const errorMessage = err.message ? err.message.toLowerCase() : 'unknown message'
        logger({
          message: `Could not get media for sharing: ${errorName} - ${errorMessage}`,
          type: 'error',
          ...err,
        })
        // 'notallowederror' occurs when the user is prompted which source to share and they press cancel, or if sharing is globally disabled, or the browsing context is deemed insecure
        // all are valid promise rejection reasons but not something we want to present as an "error"
        errorName !== 'notallowederror' &&
          showAlert({
            message: 'An error occurred in trying to present your screen',
            type: 'error',
            customLog: true,
          })
      })
  }

  stopScreenshare = () => {
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    this.screenshareTrack.stop()
  }

  // @ts-expect-error TS(7030): Not all code paths return a value.
  joinRoom = (
    token: $TSFixMe,
    roomName: $TSFixMe,
    options: $TSFixMe,
    callback: $TSFixMe,
    attempt = this.state.reconnectionAttempts,
  ) => {
    this.setState({
      reconnectionAttempts: attempt,
    })
    if (!this.state.connectedToRoom) {
      this.props.logger({ message: `Setting room options with twilio session info: ${options}`, type: 'info' })
      const roomOptions = {
        networkQuality: true,
        name: roomName,
        tracks: this.reconnecting ? this.userTracks : [], // local track will automatically be discovered and publish unless we explicitly set the tracks option to an empty array
        preferredAudioCodecs: get(options, 'preferred_audio_codecs'),
        preferredVideoCodecs: get(options, 'preferred_video_codecs'),
        maxAudioBitrate: get(options, 'max_audio_bitrate'),
        maxVideoBitrate: get(options, 'max_video_bitrate'),
        video: {
          height: { min: 240, ideal: 720, max: 720 },
          frameRate: { min: 10, ideal: 20, max: 30 },
        },
      }

      const recordedValues = {
        localAudioTrackStats: {},
        localVideoTrackStats: {},
        remoteAudioTrackStats: {},
        remoteVideoTrackStats: {},
      }

      const addCallStatsRateInfo = ({
        localAudioTrackStats,
        localVideoTrackStats,
        remoteAudioTrackStats,
        remoteVideoTrackStats,
      }: $TSFixMe) => {
        let currentTime
        // add sent audio bytes and packets rate
        if (localAudioTrackStats.length) {
          const [{ timestamp, bytesSent, packetsSent }] = localAudioTrackStats
          currentTime = Math.round(timestamp / 1000)
          if ('timestamp' in recordedValues.localAudioTrackStats) {
            localAudioTrackStats[0].bytesSentPerSecond =
              (bytesSent - (recordedValues.localAudioTrackStats as $TSFixMe).bytesSent) /
              (currentTime - (recordedValues.localAudioTrackStats as $TSFixMe).timestamp)
            localAudioTrackStats[0].packetsSentPerSecond =
              (packetsSent - (recordedValues.localAudioTrackStats as $TSFixMe).packetsSent) /
              (currentTime - (recordedValues.localAudioTrackStats as $TSFixMe).timestamp)
          }
          ;(recordedValues.localAudioTrackStats as $TSFixMe).timestamp = currentTime
          ;(recordedValues.localAudioTrackStats as $TSFixMe).bytesSent = bytesSent
          ;(recordedValues.localAudioTrackStats as $TSFixMe).packetsSent = packetsSent
        }
        // add sent video bytes and packets rate
        if (localVideoTrackStats.length) {
          const [{ timestamp, bytesSent, packetsSent }] = localVideoTrackStats
          currentTime = Math.round(timestamp / 1000)
          if ('timestamp' in recordedValues.localVideoTrackStats) {
            localVideoTrackStats[0].bytesSentPerSecond =
              (bytesSent - (recordedValues.localVideoTrackStats as $TSFixMe).bytesSent) /
              (currentTime - (recordedValues.localVideoTrackStats as $TSFixMe).timestamp)
            localVideoTrackStats[0].packetsSentPerSecond =
              (packetsSent - (recordedValues.localVideoTrackStats as $TSFixMe).packetsSent) /
              (currentTime - (recordedValues.localVideoTrackStats as $TSFixMe).timestamp)
          }
          ;(recordedValues.localVideoTrackStats as $TSFixMe).timestamp = currentTime
          ;(recordedValues.localVideoTrackStats as $TSFixMe).bytesSent = bytesSent
          ;(recordedValues.localVideoTrackStats as $TSFixMe).packetsSent = packetsSent
        }
        // add received audio bytes and packets rate
        if (remoteAudioTrackStats.length) {
          const [{ timestamp, bytesSent, packetsSent }] = remoteAudioTrackStats
          currentTime = Math.round(timestamp / 1000)
          if ('timestamp' in recordedValues.remoteAudioTrackStats) {
            remoteAudioTrackStats[0].bytesSentPerSecond =
              (bytesSent - (recordedValues.remoteAudioTrackStats as $TSFixMe).bytesSent) /
              (currentTime - (recordedValues.remoteAudioTrackStats as $TSFixMe).timestamp)
            remoteAudioTrackStats[0].packetsSentPerSecond =
              (packetsSent - (recordedValues.remoteAudioTrackStats as $TSFixMe).packetsSent) /
              (currentTime - (recordedValues.remoteAudioTrackStats as $TSFixMe).timestamp)
          }
          ;(recordedValues.remoteAudioTrackStats as $TSFixMe).timestamp = currentTime
          ;(recordedValues.remoteAudioTrackStats as $TSFixMe).bytesSent = bytesSent
          ;(recordedValues.remoteAudioTrackStats as $TSFixMe).packetsSent = packetsSent
        }
        // add received video bytes and packets rate
        if (remoteVideoTrackStats.length) {
          const [{ timestamp, bytesSent, packetsSent }] = remoteVideoTrackStats
          currentTime = Math.round(timestamp / 1000)
          if ('timestamp' in recordedValues.remoteVideoTrackStats) {
            remoteVideoTrackStats[0].bytesSentPerSecond =
              (bytesSent - (recordedValues.remoteVideoTrackStats as $TSFixMe).bytesSent) /
              (currentTime - (recordedValues.remoteVideoTrackStats as $TSFixMe).timestamp)
            remoteVideoTrackStats[0].packetsSentPerSecond =
              (packetsSent - (recordedValues.remoteVideoTrackStats as $TSFixMe).packetsSent) /
              (currentTime - (recordedValues.remoteVideoTrackStats as $TSFixMe).timestamp)
          }
          ;(recordedValues.remoteVideoTrackStats as $TSFixMe).timestamp = currentTime
          ;(recordedValues.remoteVideoTrackStats as $TSFixMe).bytesSent = bytesSent
          ;(recordedValues.remoteVideoTrackStats as $TSFixMe).packetsSent = packetsSent
        }
        return {
          localAudioTrackStats,
          localVideoTrackStats,
          remoteAudioTrackStats,
          remoteVideoTrackStats,
        }
      }

      this.props.logger({ message: `Connecting to room with options: ${roomOptions}`, type: 'info' })
      // @ts-expect-error TS(2345): Argument of type '{ networkQuality: boolean; name:... Remove this comment to see the full error message
      return connect(token, roomOptions)
        .then((room) => {
          this.props.logger({ message: `Successfully connected to twilio video call`, type: 'success' })

          // @ts-expect-error TS(2322): Type 'Room | RoomMock' is not assignable to type '... Remove this comment to see the full error message
          this.room = room
          // @ts-expect-error TS(2322): Type 'LocalParticipant | LocalParticipantMock' is ... Remove this comment to see the full error message
          this.user = room.localParticipant

          // get statistics on an interval, send them logger
          // @ts-expect-error TS(2322): Type 'number' is not assignable to type 'null'.
          this.statsInterval = window.setInterval(() => {
            // @ts-expect-error TS(2531): Object is possibly 'null'.
            this.room.getStats().then((statsReturn: $TSFixMe) => {
              statsReturn[0] = addCallStatsRateInfo(statsReturn[0])
              this.props.logger({
                sourceCategory: 'videoStats',
                // @ts-expect-error TS(2531): Object is possibly 'null'.
                roomSid: this.room.sid,
                type: 'info',
                lyra_id: this.props.type === 'provider' ? options.provider_id : options.patient_id,
                recipient_lyra_id: this.props.type === 'provider' ? options.patient_id : options.provider_id,
                userRole: this.props.type,
                ...statsReturn[0],
              })
            })
          }, 1000 * 60) // every minute

          // event listeners
          // @ts-expect-error TS(2531): Object is possibly 'null'.
          this.room.on('participantConnected', this._participantConnected)
          // @ts-expect-error TS(2531): Object is possibly 'null'.
          this.room.on('participantDisconnected', this._participantDisconnected)
          // @ts-expect-error TS(2531): Object is possibly 'null'.
          this.room.on('reconnecting', (error: $TSFixMe) => {
            // Twilio emits "reconnecting" first, in attempt to fix the situation, before "disconnected" is triggered
            this.props.showAlert({
              message: `${error.message ?? 'An error occured'}. Attempting to reconnect...`,
              type: 'info',
              noTimer: true,
            })
          })
          // @ts-expect-error TS(2531): Object is possibly 'null'.
          this.room.on('disconnected', (room: $TSFixMe, error: $TSFixMe) => {
            this.setState({
              connectedToRoom: false,
            })

            if (error) {
              this.props.track({
                page: 'video call',
                event: 'twillio room disconnected with error',
                details: this.props.type,
                errorMessage: `${error.message ? error.message : 'no error message'}`,
              })
              if (error.message.toLowerCase().includes('room completed')) {
                this.props.showAlert({
                  message: 'The session has been completed',
                  type: 'success',
                })
                setTimeout(() => {
                  this.props.endSession()
                }, 1000)
              } else {
                this.props.showAlert({
                  message: `Unexpectedly disconnected${error.message ? ': ' + error.message.toLowerCase() : ''}`,
                  type: 'error',
                })
                setTimeout(() => {
                  this.reconnecting = true
                  this.setState({
                    connectedToRoom: false,
                  })
                  this.props.rejoinSession(this.state.reconnectionAttempts + 1)
                }, 2000)
              }
            } else {
              this.props.track({
                page: 'video call',
                event: 'twillio room disconnected with no error',
                details: this.props.type,
              })
            }

            room.participants.forEach(this._participantDisconnected)
          })
          // @ts-expect-error TS(2531): Object is possibly 'null'.
          this.room.on('reconnected', () => {
            this.props.track({
              page: 'video call',
              event: 'video call reconnected (from "reconnected" room event)',
              details: this.props.type,
            })
            this.props.showAlert({ message: 'Reconnected to room!', type: 'success' })
          })

          if (this.reconnecting) {
            this.props.track({
              page: 'video call',
              event: 'video call reconnected',
              details: this.props.type,
            })
            this.props.showAlert({ message: 'Reconnected to room!', type: 'success' })

            // re-join
            if (room.participants.size > 0) {
              room.participants.forEach(this._participantConnected)
            }

            this.reconnecting = false
            this.setState({
              connectedToRoom: true,
              reconnectionAttempts: 0,
            })
          } else {
            // first join
            this.setState({
              connectedToRoom: true,
            })

            // parent component callback
            return callback().then(() => {
              if (this.props.viewStyle === videoViewStyle.FULLSCREEN) {
                // move the user video to the preview spot, to make way for participant
                // really only triggers for the client who defaults to fullscreen
                this._toggleViewStyle(videoViewStyle.PREVIEW, false)
              }

              if (room.participants.size > 0) {
                room.participants.forEach(this._participantConnected)
              } else {
                this.props.setParticipantStatusMessage('pending')
              }
            })
          }
        })
        .catch((error: Error) => {
          this.props.logger({ message: `Initial joinRoom failed with error - ${error}`, type: 'error' })
          if (this.reconnecting) {
            this.props.rejoinSession(this.state.reconnectionAttempts + 1)
          } else {
            // only show the error if the "reconnecting" alert is not already showing
            this.props.showAlert({
              message: `Error connecting to room${error.message ? ': ' + error.message.toLowerCase() : ''}`,
              type: 'error',
            })
          }
        })
    }
  }

  // allows for provider to soft join a room, but activate their tracks later on when they "start session"
  startSession = () => {
    // add provider local tracks
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    this.user.publishTracks(this.userTracks)

    // clear status messages (client-waiting)
    this.props.setParticipantStatusMessage(null)

    // render the client tracks if they already exist, or set listeners if they don't
    const participant = this._firstNonBotParticipant()
    if (participant) {
      if (participant.tracks.size > 0) {
        // From twilio-video 2.0.0+, `participant.tracks` are the track publications (An ES6 Map as well), not the tracks.
        // So we need to grab the actual tracks.
        const tracks = [...participant.tracks.values()].map((pub) => pub.track)
        tracks.forEach(this._renderParticipantTracks)
      } else {
        participant.on('trackSubscribed', (track: $TSFixMe) => this._renderParticipantTracks(track))
        participant.on('trackSubscriptionFailed', this._subscriptionFailure)
      }
    }

    if (this.props.viewStyle === videoViewStyle.FULLSCREEN) {
      // move the user video to the preview spot, to make way for participant
      this._toggleViewStyle(videoViewStyle.PREVIEW, false)
      // move the participant preview to fullscreen
      this._toggleViewStyle(videoViewStyle.FULLSCREEN, true)
    }

    return Promise.resolve(true) // return to parent component function for chaining
  }

  /**
   * [LOLWAT codec negotiation] The provider needs to reinitialize and re-publish
   * their tracks after rejoining the room with a new codec
   */
  initAndPublishUserTracks = async (onDataTrackPublished: $TSFixMe) => {
    await this._initializeUserTracks(this.props.deviceConstraints)
    if (this.user && this.userTracks) {
      await (this.user as $TSFixMe).publishTracks(this.userTracks)
    }
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    this.user.on('trackPublished', (publication: $TSFixMe) => {
      // send data only after the data track has successfully published
      if (publication.track === this.localDataTrack) {
        onDataTrackPublished()
      }
    })
    // re-toggle all the tracks
    this._toggleAudioTrack(this.props.muted)
    this._toggleVideoTrack(this.props.videoOff)
  }

  leaveRoom = (callback: $TSFixMe) => {
    this.userTracks &&
      this.userTracks.forEach((track) => {
        if ((track as $TSFixMe).stop) {
          ;(track as $TSFixMe).stop()
        }
      })
    window.removeEventListener('beforeunload', this.browserCloseConfirmation)
    this.room && (this.room as $TSFixMe).disconnect()
    this.statsInterval && window.clearInterval(this.statsInterval)
    this.user && this._participantDisconnected(this.user)

    this._clearContainerContents()

    // to make sure it closes
    this.props.setFullScreenHasVideo(false)

    // parent callback
    callback()
  }

  // ========================================
  // Internal Methods: ======================
  // ========================================

  // Utility Functions: ---------------------

  _clearContainerContents = () => {
    this.props.setParticipantStatusMessage(null)
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    if (document.getElementById(videoScreen.FULLSCREENVIDEOCONTAINER).querySelector('video')) {
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      document.getElementById(videoScreen.FULLSCREENVIDEOCONTAINER).querySelector('video').remove()
      this.props.setFullScreenHasVideo(false)
    }
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    if (document.getElementById(videoScreen.USERPREVIEWCONTAINER).querySelector('video')) {
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      document.getElementById(videoScreen.USERPREVIEWCONTAINER).querySelector('video').remove()
    }
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    if (document.getElementById(videoScreen.PARTICIPANTPREVIEWCONTAINER).querySelector('video')) {
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      document.getElementById(videoScreen.PARTICIPANTPREVIEWCONTAINER).querySelector('video').remove()
    }
    if (this.userAudio.current && (this as $TSFixMe).userAudio.current.querySelector('audio')) {
      ;(this as $TSFixMe).userAudio.current.querySelector('audio').remove()
    }
  }

  _firstNonBotParticipant = () => {
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    const participantsMap = this.room.participants
    const participants = Array.from(participantsMap.keys())
    return participants.length > 0 ? participantsMap.get(participants[0]) : null
  }

  browserCloseConfirmation = (event: $TSFixMe) => {
    // fire mixpanel
    this.props.track({
      page: 'video call',
      event: 'display close tab confirmation modal',
      details: this.props.type,
    })

    event.preventDefault()
    event.returnValue = ''
  }

  // Primary Feature Methods: ----------------

  _initializeUserTracks = (constraints: $TSFixMe) => {
    return createLocalTracks(constraints).then(
      (tracks: $TSFixMe) => {
        this.userTracks = tracks
        if (!(this as $TSFixMe).userAudio.current.querySelector('audio')) {
          tracks.forEach((track: $TSFixMe) => {
            if (track.kind === 'audio') {
              // attach audio to a static location, so it is uninterrupted during video location moves
              ;(this as $TSFixMe).userAudio.current.appendChild(track.attach())
            } else {
              // video
              if (this.props.viewStyle === videoViewStyle.FULLSCREEN) {
                // @ts-expect-error TS(2531): Object is possibly 'null'.
                document.getElementById(videoScreen.FULLSCREENVIDEOCONTAINER).appendChild(track.attach())
                this.props.setFullScreenHasVideo(true)
              } else {
                // preview
                // @ts-expect-error TS(2531): Object is possibly 'null'.
                document.getElementById(videoScreen.USERPREVIEWCONTAINER).appendChild(track.attach())
              }
            }
          })

          // create a local data track
          // @ts-expect-error TS(2351): This expression is not constructable.
          this.localDataTrack = new LocalDataTrack()
          // @ts-expect-error TS(2531): Object is possibly 'null'.
          this.userTracks.push(this.localDataTrack)

          // parent component callback
          this.props.userTracksInitialized()
        }
      },
      (error: $TSFixMe) => {
        this.props.logger({ message: `Unable to createLocalTracks: ${error.message}`, type: 'error', ...error })
        this.props.showAlert({ message: 'Unable to access Camera and Microphone', type: 'error', customLog: true })
        this.props.userTracksInitialized()
      },
    )
  }

  _removeTrack = (track: $TSFixMe) => {
    const trackName = track.name

    if (track.stop) {
      track.stop()
    }

    if (track.detach) {
      track.detach().forEach((element: $TSFixMe) => element.remove())
    }

    if (trackName === 'screenShare') {
      // move provider's video back to fullscreen
      this._toggleViewStyle(videoViewStyle.FULLSCREEN)

      this.props.screenShareStoppedCallback()
    }
  }

  // Settings Modal Methods: -------------------

  // render the video input change made in the settings modal
  _updateVideoInput = (deviceId: $TSFixMe) => {
    const options = Object.assign({}, this.props.deviceConstraints.video, { deviceId: { exact: deviceId } })

    // pull off default to allow others to be chosen
    if (options.facingMode) {
      delete options.facingMode
    }

    // create the new track first
    createLocalVideoTrack(options).then((track: $TSFixMe) => {
      // then, remove any existing track
      this.userTracks?.forEach((oldTrack, index) => {
        if (oldTrack.kind === 'video') {
          if (this.props.inSession) {
            // @ts-expect-error TS(2531): Object is possibly 'null'.
            this.user.unpublishTrack(oldTrack) // broadcast the removal to the room
          }
          this._removeTrack(oldTrack) // detach from local

          // replace it with the new one
          // @ts-expect-error TS(2531): Object is possibly 'null'.
          this.userTracks[index] = track
        }
      })

      // then attach it locally
      if (this.props.inSession) {
        // preview
        // @ts-expect-error TS(2531): Object is possibly 'null'.
        document.getElementById(videoScreen.USERPREVIEWCONTAINER).appendChild(track.attach())
      } else {
        if (this.props.viewStyle === videoViewStyle.FULLSCREEN) {
          // @ts-expect-error TS(2531): Object is possibly 'null'.
          document.getElementById(videoScreen.FULLSCREENVIDEOCONTAINER).appendChild(track.attach())
          this.props.setFullScreenHasVideo(true)
        } else {
          // preview
          // @ts-expect-error TS(2531): Object is possibly 'null'.
          document.getElementById(videoScreen.USERPREVIEWCONTAINER).appendChild(track.attach())
        }
      }

      // finally, publish the track if the session is in process
      if (this.props.inSession) {
        // @ts-expect-error TS(2531): Object is possibly 'null'.
        this.user.publishTrack(track)
      }
    })
  }

  // render the audio input change made in the settings modal
  _updateAudioInput = (deviceId: $TSFixMe) => {
    const options = Object.assign({}, this.props.deviceConstraints.audio, { deviceId: { exact: deviceId } })

    // create the new track first
    createLocalAudioTrack(options).then((track: $TSFixMe) => {
      // then, remove any existing track
      this.userTracks?.forEach((oldTrack, index) => {
        if (oldTrack.kind === 'audio') {
          if (this.props.inSession) {
            // @ts-expect-error TS(2531): Object is possibly 'null'.
            this.user.unpublishTrack(oldTrack) // broadcast the removal to the room
          }
          this._removeTrack(oldTrack) // detach from local
          // replace it with the new one
          // @ts-expect-error TS(2531): Object is possibly 'null'.
          this.userTracks[index] = track
        }
      })
      // then attach it locally
      ;(this as $TSFixMe).userAudio.current.appendChild(track.attach())
      // finally, publish the track if the session is in process
      if (this.props.inSession) {
        // @ts-expect-error TS(2531): Object is possibly 'null'.
        this.user.publishTrack(track)
      }
    })
  }

  // render the audio output change made in the settings modal
  _updateAudioOutput = (deviceId: $TSFixMe) => {
    console.log('updating audio output')
    if (
      (this as $TSFixMe).participantAudio.current.querySelector('audio') &&
      (this as $TSFixMe).participantAudio.current.querySelector('audio').setSinkId
    ) {
      // setSinkId is not yet supported in Firefox
      ;(this as $TSFixMe).participantAudio.current.querySelector('audio').setSinkId(deviceId)
    }
  }

  // Session Event Listeners: -------------------

  _participantConnected = (participant: $TSFixMe) => {
    // parent callback
    this.props.participantConnectedCallback()

    // save and track changes to Network Quality Level
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    this.props.networkQualityHandler(this.user.networkQualityLevel)
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    this.user.on('networkQualityLevelChanged', this.props.networkQualityHandler)

    // clear participant status message
    this.props.setParticipantStatusMessage(null)

    // event listeners
    participant.on('trackUnsubscribed', this._removeTrack)
    participant.on('trackSubscriptionFailed', this._subscriptionFailure)
    participant.on('trackMessage', (message: $TSFixMe) => {
      this.props.handleDataMessage(message)
    })

    // update ui for new participant
    if (this.props.type === 'provider') {
      if (!this.props.inSession) {
        // a client is waiting
        this.props.setParticipantStatusMessage('waiting')
        this.clientWaitingSound.play()
      } else {
        // render tracks
        participant.on('trackSubscribed', (track: $TSFixMe) => this._renderParticipantTracks(track))
      }
    } else {
      // client

      // render tracks
      participant.on('trackSubscribed', (track: $TSFixMe) => {
        // can now add local client tracks to the room
        // @ts-expect-error TS(2531): Object is possibly 'null'.
        this.user.publishTracks(this.userTracks)

        this._renderParticipantTracks(track)
      })

      if (participant.tracks.size === 0) {
        // a provider is in room but hasn't started session
        this.props.setParticipantStatusMessage('pending')
      }
    }
  }

  _participantDisconnected = (participant: $TSFixMe) => {
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    if (this.room.participants.size <= 1) {
      this.props.setParticipantStatusMessage('left')
      if (this.props.type === 'provider') {
        this.props.setViewStyle(videoViewStyle.PREVIEW)
        this.props.setFullScreenHasVideo(false)
      }
    }

    participant.tracks.forEach(this._removeTrack)
  }

  _subscriptionFailure = (error: $TSFixMe, trackPublication: $TSFixMe) => {
    this.props.logger({
      message: `Failed to subscribe to RemoteTrack ${trackPublication.trackSid} with name "${trackPublication.trackName}": ${error.message}`,
      type: 'error',
      ...error,
    })
    this.props.showAlert({
      message: `An error occurred in retrieving the participant's media`,
      type: 'error',
      customLog: true,
    })
  }

  _renderParticipantTracks = (track: $TSFixMe) => {
    if (track.kind === 'audio') {
      // attach audio to a static location, so it uninterrupted during video location moves
      const audioElement = track.attach() // create the <audio> node
      if (this.props.deviceSettings && this.props.deviceSettings.audioOutput && audioElement.setSinkId) {
        // setSinkId not yet supported in Firefox
        audioElement.setSinkId(this.props.deviceSettings.audioOutput) // subscribe the current set speakers to this node
      }
      ;(this as $TSFixMe).participantAudio.current.appendChild(audioElement) // inject to DOM
      // inject to DOM
      if (this.props.viewStyle === videoViewStyle.FULLSCREEN && this.props.inSession) {
        this.props.setFullScreenMuted(!track.isEnabled)
      } else {
        this.props.setParticipantPreviewMuted(!track.isEnabled)
      }
    } else if (track.kind === 'video') {
      if (track.name === 'screenShare') {
        this.props.screenShareStartedCallback().then(() => {
          // move provider's video from fullscreen to preview to make way for screen share
          this._toggleViewStyle(videoViewStyle.PREVIEW)

          // render screen share
          this.props.setFullScreenHasVideo(true) // the presence of this attribute is what shows the fullscreen container on screen
          // @ts-expect-error TS(2531): Object is possibly 'null'.
          document.getElementById(videoScreen.FULLSCREENVIDEOCONTAINER).appendChild(track.attach())
        })
      } else {
        if (this.props.viewStyle === videoViewStyle.FULLSCREEN && this.props.inSession) {
          // clear status messages (provider-pending)
          this.props.setParticipantStatusMessage(null)

          this.props.setFullScreenHasVideo(true) // the presence of this attribute is what shows the fullscreen container on screen
          // @ts-expect-error TS(2531): Object is possibly 'null'.
          document.getElementById(videoScreen.FULLSCREENVIDEOCONTAINER).appendChild(track.attach())
          this.props.setFullScreenVideoOff(!track.isEnabled)
        } else {
          // @ts-expect-error TS(2531): Object is possibly 'null'.
          document.getElementById(videoScreen.PARTICIPANTPREVIEWCONTAINER).appendChild(track.attach())
          this.props.setParticipantPreviewVideoOff(!track.isEnabled)
        }
      }
    }
    track.on('disabled', this._trackDisabled)
    track.on('enabled', this._trackEnabled)
  }

  // participant track disabled
  _trackDisabled = (track: $TSFixMe) => {
    if (track.kind === 'audio') {
      // show muted icon
      this.props.viewStyle === videoViewStyle.FULLSCREEN
        ? this.props.setFullScreenMuted(true)
        : this.props.setParticipantPreviewMuted(true)
    } else {
      // video
      // show "video off" state
      this.props.viewStyle === videoViewStyle.FULLSCREEN
        ? this.props.setFullScreenVideoOff(true)
        : this.props.setParticipantPreviewVideoOff(true)
    }
  }

  // participant track enabled
  _trackEnabled = (track: $TSFixMe) => {
    if (track.kind === 'audio') {
      // remove muted icon
      this.props.viewStyle === videoViewStyle.FULLSCREEN
        ? this.props.setFullScreenMuted(false)
        : this.props.setParticipantPreviewMuted(false)
    } else {
      // video
      // remove "video off" state
      this.props.viewStyle === videoViewStyle.FULLSCREEN
        ? this.props.setFullScreenVideoOff(false)
        : this.props.setParticipantPreviewVideoOff(false)
    }
  }

  // Auxilary Feature Methods: -----------------

  _broadcastSharedScreen = (stream: $TSFixMe) => {
    // @ts-expect-error TS(2351): This expression is not constructable.
    this.screenshareTrack = new LocalVideoTrack(stream.getVideoTracks()[0], { name: 'screenShare' })

    // @ts-expect-error TS(2531): Object is possibly 'null'.
    this.screenshareTrack.once('stopped', () => {
      this.props.logger({ message: 'Screensharing stopped', type: 'info' })
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      this.user.unpublishTrack(this.screenshareTrack) // pull broadcast from room
      this.props.screenShareStoppedCallback()
    })

    // @ts-expect-error TS(2531): Object is possibly 'null'.
    this.user.publishTrack(this.screenshareTrack) // broadcast to room

    this.props.logger({ message: 'Screensharing started', type: 'info' })
    // call parent callback
    this.props.screenShareStartedCallback()
  }

  // toggle user audio
  _toggleAudioTrack = (muted: $TSFixMe) => {
    const setUserContainerMuted =
      this.props.viewStyle === videoViewStyle.FULLSCREEN && !this.props.inSession
        ? this.props.setFullScreenMuted
        : this.props.setUserPreviewMuted
    this.userTracks
      ?.filter((track) => track.kind === 'audio')
      .forEach((track) => {
        if (muted) {
          track.disable()
          setUserContainerMuted(true)
        } else {
          track.enable()
          setUserContainerMuted(false)
        }
      })
  }

  // toggle user video
  _toggleVideoTrack = (videoOff: $TSFixMe) => {
    this.userTracks
      ?.filter((track) => track.kind === 'video')
      .forEach((track) => {
        if (videoOff) {
          track.disable()
          if (this.props.inSession) {
            this.props.setUserPreviewVideoOff(true)
          } else {
            if (this.props.viewStyle === videoViewStyle.FULLSCREEN) {
              this.props.setFullScreenVideoOff(true)
            } else {
              this.props.setUserPreviewVideoOff(true)
            }
          }
        } else {
          track.enable()
          if (this.props.inSession) {
            this.props.setUserPreviewVideoOff(false)
          } else {
            if (this.props.viewStyle === videoViewStyle.FULLSCREEN) {
              this.props.setFullScreenVideoOff(false)
            } else {
              this.props.setUserPreviewVideoOff(false)
            }
          }
        }
      })
  }

  // render background changes https://twilio.github.io/twilio-video-processors.js/modules.html
  _updateEffects = async (effect: VideoCallEffects) => {
    const backgroundImage = new Image()
    // Canvas2D offers considerable performance advantages over WebGL2 however
    // safari and mobile devices do not support the neccessary canvas functions
    // to render custom backgrounds so for these devies we use WebGL2.
    const pipeline = isSafari || isIOS ? Pipeline.WebGL2 : Pipeline.Canvas2D
    backgroundImage.crossOrigin = 'anonymous'
    let background: VirtualBackgroundProcessor | GaussianBlurBackgroundProcessor | null = null
    let useImage = false
    switch (effect) {
      case VideoCallEffects.HILL_LANDSCAPE:
        backgroundImage.src = hillsLandscape
        useImage = true
        break
      case VideoCallEffects.OFFICE:
        backgroundImage.src = therapyOffice
        useImage = true
        break
      case VideoCallEffects.BLUR:
        background = new GaussianBlurBackgroundProcessor({
          assetsPath: TWILIO_VIDEO_PROCESSOR_CDN_URL,
          debounce: true,
          pipeline,
          maskBlurRadius: BACKGROUND_EFFECTS_BLUR_RADIUS,
        })
        await background.loadModel()
        break
      default:
        background = null
        break
    }

    if (useImage) {
      await backgroundImage.decode()
      // Desktop Safari and iOS browsers do not support SIMD.
      // Set debounce to true to achieve an acceptable performance.
      // https://twilio.github.io/twilio-video-processors.js/classes/virtualbackgroundprocessor.html
      background = new VirtualBackgroundProcessor({
        assetsPath: TWILIO_VIDEO_PROCESSOR_CDN_URL,
        backgroundImage: backgroundImage,
        debounce: isSafari,
        pipeline,
        maskBlurRadius: BACKGROUND_EFFECTS_BLUR_RADIUS,
      })
      await background.loadModel()
    }
    this.userTracks
      ?.filter((track) => track.kind === 'video')
      .forEach((track) => {
        if (track.processor) {
          try {
            track.removeProcessor(track.processor)
            if (this.effectsTestingRef.current) {
              this.effectsTestingRef.current.removeAttribute('data-test-id')
            }
          } catch (error) {
            this.props.logger({ message: `Error could not add processor to video session: ${error}`, type: 'error' })
          }
        }
        if (background) {
          try {
            track.addProcessor(background, {
              inputFrameBufferType: 'video',
              outputFrameBufferContextType: pipeline === Pipeline.WebGL2 ? 'webgl2' : '2d',
            })
            if (this.effectsTestingRef.current) {
              this.effectsTestingRef.current.setAttribute('data-test-id', `VideoImageTest-${effect}`)
            }
          } catch (error) {
            this.props.logger({ message: `Error could not add processor to video session: ${error}`, type: 'error' })
          }
        }
      })
  }

  // switch the appropriate video between fullscreen and preview
  _toggleViewStyle = (viewStyle: $TSFixMe, sessionActive = this.props.inSession) => {
    let scopedTracks
    if (!sessionActive) {
      // use local user tracks
      scopedTracks = this.userTracks
    } else {
      // use remote participant tracks
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      if (this.room.participants.size > 0) {
        // From twilio-video 2.0.0+, `participant.tracks` are the track publications (An ES6 Map as well), not the tracks.
        // So we need to grab the actual tracks.
        const participant = this._firstNonBotParticipant()
        if (participant) {
          const publications = participant.tracks
          scopedTracks = [...publications.values()].map((pub) => pub.track)
        }
      }
    }

    if (scopedTracks) {
      // reattach to new location
      scopedTracks.forEach((track) => {
        if (!!track && track.kind === 'video' && track.name !== 'screenShare') {
          // first detach
          if (track.detach) {
            const mediaElements = track.detach()
            mediaElements.forEach((mediaElement: $TSFixMe) => mediaElement.remove())
          }

          const previewContainerId = sessionActive
            ? videoScreen.PARTICIPANTPREVIEWCONTAINER
            : videoScreen.USERPREVIEWCONTAINER
          const isPertinentPreviewVideoOff = sessionActive
            ? this.props.isParticipantPreviewVideoOff
            : this.props.isUserPreviewVideoOff
          const setPertinentPreviewVideoOff = sessionActive
            ? this.props.setParticipantPreviewVideoOff
            : this.props.setUserPreviewVideoOff
          const isPertinentPreviewMuted = sessionActive
            ? this.props.isParticipantPreviewMuted
            : this.props.isUserPreviewMuted
          const setPertinentPreviewMuted = sessionActive
            ? this.props.setParticipantPreviewMuted
            : this.props.setUserPreviewMuted

          if (viewStyle === videoViewStyle.FULLSCREEN) {
            this.props.setFullScreenHasVideo(true) // the presence of this attribute is what shows the fullscreen container on screen
            // @ts-expect-error TS(2531): Object is possibly 'null'.
            document.getElementById(videoScreen.FULLSCREENVIDEOCONTAINER).appendChild(track.attach())
            this.props.setFullScreenVideoOff(isPertinentPreviewVideoOff)
            this.props.setFullScreenMuted(isPertinentPreviewMuted)
          } else {
            // "Preview"
            this.props.setFullScreenHasVideo(false)
            // @ts-expect-error TS(2531): Object is possibly 'null'.
            document.getElementById(previewContainerId).appendChild(track.attach())
            setPertinentPreviewVideoOff(this.props.isFullScreenVideoOff)
            setPertinentPreviewMuted(this.props.isFullScreenMuted)
          }
        }
      })
    }
  }

  // Rendering: --------------------------------

  render() {
    return (
      <div>
        {/* @ts-expect-error TS(2322): Type 'RefObject<unknown>' is not assignable to typ... Remove this comment to see the full error message */}
        <div ref={this.userAudio} />
        {/* @ts-expect-error TS(2322): Type 'RefObject<unknown>' is not assignable to typ... Remove this comment to see the full error message */}
        <div ref={this.participantAudio} />
        {/* Added empty div for testing video background selection purpose only */}
        {this.props.effects !== VideoCallEffects.NONE && <div ref={this.effectsTestingRef} />}
      </div>
    )
  }
}

type OwnVideoCallProps = {
  type: string
  inSession: boolean
  muted: boolean
  videoOff: boolean
  viewStyle: string
  setViewStyle: (PREVIEW: string) => {}
  userTracksInitialized: () => {}
  setParticipantStatusMessage: (pending: string | null) => {}
  deviceConstraints: {
    video: any
    audio: any
  }
  deviceSettings: {
    videoInput: any
    audioInput: any
    audioOutput: any
  }
  screenShareStartedCallback: () => Promise<any>
  screenShareStoppedCallback: () => {}
  handleDataMessage: (message: any) => {}
  showAlert: (alertObj: any) => {}
  participantConnectedCallback: () => {}
  logger: (p: { message: string; type: string }) => {}
  networkQualityHandler: (networkQualityLevel: any) => {}
  endSession: () => {}
  track: (trackingObj: any) => {}
  rejoinSession: (number: number) => {}
  isFullScreenMuted: boolean
  setFullScreenMuted: (b: boolean) => {}
  setFullScreenHasVideo: (b: boolean) => {}
  isFullScreenVideoOff: boolean
  setFullScreenVideoOff: (b: boolean) => {}
  isUserPreviewMuted: boolean
  setUserPreviewMuted: () => {}
  isUserPreviewVideoOff: boolean
  setUserPreviewVideoOff: (b: boolean) => {}
  isParticipantPreviewMuted: boolean
  setParticipantPreviewMuted: (b: boolean) => {}
  isParticipantPreviewVideoOff: boolean
  setParticipantPreviewVideoOff: (b: boolean) => {}
  effects: VideoCallEffects
}

type VideoCallState = {
  connectedToRoom: boolean
  reconnectionAttempts: number
}

type UserTrack = {
  disable: () => void
  enable: () => void
  kind: string
  name: string
  sid: string
  processor: VideoProcessor | null
  addProcessor: (processor: VideoProcessor, options?: AddProcessorOptions) => VideoTrack
  removeProcessor: (processor: VideoProcessor) => VideoTrack
}

export default VideoCall
