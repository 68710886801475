import React from 'react'
import { Circle, Path, Rect, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

export const SessionListCancelIcon: React.FC<SessionListCancelIconProps> = ({ fillColor, size = 40, strokeColor }) => {
  const { colors } = useTheme()

  return (
    <Svg width={size} height={size} viewBox='0 0 40 40' fill='none'>
      <Circle cx='20' cy='20' r='20' fill={fillColor || colors.backgroundIcon} />
      <Rect x='10.75' y='10.75' width='18.5' height='18.5' rx='9.25' stroke={colors.iconDefault} stroke-width='1.5' />
      <Path
        d='M18 22L22.2426 17.7574'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke={strokeColor || colors.iconDefault}
      />
    </Svg>
  )
}

type SessionListCancelIconProps = {
  fillColor?: string
  size?: string | number
  strokeColor?: string
}
