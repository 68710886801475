import React, { FunctionComponent } from 'react'

import careTeamAltIllustrationUrl from '../../assets/careTeamAltIllustration.png'
import { Image } from '../image/Image'

interface CareTeamAltIllustrationProps {
  width?: number
  height?: number
}

export const CareTeamAltIllustration: FunctionComponent<CareTeamAltIllustrationProps> = ({
  width = 112,
  height = 92,
  ...props
}) => {
  return (
    <Image
      source={careTeamAltIllustrationUrl}
      style={{ width, height }}
      contentFit='contain'
      accessibilityIgnoresInvertColors
      {...props}
    />
  )
}
