import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import { Navigate } from 'react-router-dom'

import Axios, { Canceler, CancelToken } from 'axios'
import { Iterable, Map } from 'immutable'
import * as _ from 'lodash-es'
import moment from 'moment'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import { change, formValueSelector, getFormMeta, getFormSyncErrors, isValid, reduxForm } from 'redux-form/immutable'

import {
  Address,
  ChargeEligiblityPayload,
  ChargeHealthPlanEligibilityPayload,
  ChargeHealthPlanEligiblityResponse,
  CustomerInfo,
  LyraCodeValidationInfo,
  ProviderAdminProviderInfo,
  ProviderRates,
  ProviderUser,
  Transfer,
  usePrevious,
} from '@lyrahealth-inc/shared-app-logic'
import {
  BaseModal,
  BootstrapContainer,
  ContentLayout,
  dateUtils,
  formValidationConstants,
  LoadingIndicator,
} from '@lyrahealth-inc/ui-core'

import CancelledSessionsExceededModal from './CancelledSessionsExceededModal'
import ClientIneligibleModal from './ClientIneligibleModal'
import * as requestPaymentActions from './data/requestPaymentActions'
import DuplicatePaymentModal from './DuplicatePaymentModal'
import FirstSessionModal from './FirstSessionModal'
import LastSessionModal from './LastSessionModal'
import OutcomeReminder from './OutcomeReminder'
import styles from './requestPayment.module.scss'
import SessionsExceededModal from './SessionsExceededModal'
import SessionsWarningModal from './SessionsWarningModal'
import * as clientsDataActions from '../clients/data/clientsDataActions'
import AvailabilityCard from '../common/components/availabilityCard/AvailabilityCard'
import paymentRequestAsyncValidate from '../common/components/forms/paymentRequest/paymentRequestAsyncValidate'
import PaymentRequestForm from '../common/components/forms/paymentRequest/PaymentRequestForm'
import paymentRequestValidate from '../common/components/forms/paymentRequest/paymentRequestValidate'
import {
  bannerMessages,
  coachingSessionTypesList,
  ICD10_PRIMARY_FIELD,
  ICD10_SECONDARY_FIELD,
  LYRA_CODE_LENGTH,
  outcomesEligibleSessionTypes,
  PAYMENT_FORM,
  ROLES,
  specialityCareAttestationStatuses,
} from '../common/constants/appConstants'
import { SET_HP_ELIGIBILITY_STATUS } from '../common/constants/reduxConstants'
import {
  CLIENT_EMAIL,
  HEALTHPLAN_INFO,
  PRACTICES_DETAILS_HEALTHPLAN_PAYMENT_INFO,
  PRACTICES_DETAILS_NEW_PAYMENT,
  PRACTICES_REGISTER,
  PROVIDERS_DETAILS_HEALTHPLAN_PAYMENT_INFO,
  PROVIDERS_DETAILS_NEW_PAYMENT,
  PROVIDERS_REGISTER,
} from '../common/constants/routingConstants'
import {
  convertToFormData,
  getChargeCopyPrepopInfo,
  getErrorText,
  getPatientPrepopInfo,
  hasRole,
  sanitizeLyraCode,
  shouldShowPaymentFormEligibleMemberDetails,
} from '../common/utils/utils'
import * as alertActions from '../data/alertActions'
import { getAuthConfig, getAuthUser } from '../data/auth/authSelectors'
import { getHealthPlanCurrentCharge, getHealthPlanHPEligibility } from '../data/healthPlan/healthPlanSelectors'
import {
  clearHealthPlanCharge,
  clearHPEligibility,
  getHPEligibility,
  saveHealthPlanCharge,
} from '../data/healthPlanAutoActions'
import {
  getRequestPaymentCopy,
  getRequestPaymentCustomers,
  getRequestPaymentDataData,
  getRequestPaymentDataOutcomeReminderStatus,
  getRequestPaymentDataSessionTrackingStatus,
  getRequestPaymentEligibilityStatus,
  getRequestPaymentLyraCodeValidationInfo,
  getRequestPaymentPatient,
  getRequestPaymentProvidersList,
  getRequestPaymentRates,
  getRequestPaymentResponseDataSessionTrackingStatus,
  getRequestPaymentSelectedProvider,
  getRequestPaymentShowCancelledSessionsExceededModal,
  getRequestPaymentShowDuplicateModal,
  getRequestPaymentShowIneligibleClientModal,
} from '../data/requestPayment/requestPaymentSelectors'
import { Config } from '../lyraTherapy/types'
import { getPayments } from '../payments/dashboard/data/paymentsDashboardActions'
import { getPracticeDetailsDataId } from '../practices/individualPractice/data/practiceDetailsSelectors'
import { getProviderDetailsAddresses, getProviderDetailsData } from '../providers/data/providerSelectors'
import * as providerDetailsActions from '../providers/individualProvider/data/providerDetailsActions'

const cancelToken = Axios.CancelToken
let cancelGetClient: Canceler

const requestPaymentWarnings = ($$values = Map(), props: RequestPaymentProps) => {
  const warnings = {}
  // Only display lyra code date of birth match warning for payments admin
  if (hasRole(props.user.roles, ROLES.PAYMENTS_ADMIN)) {
    const lyraCodeDOB = props.lyraCodeValidationInfo?.user_info?.dob
    const enteredDOB = $$values.get(PAYMENT_FORM.FIELDS.DATE_OF_BIRTH)
    if (lyraCodeDOB && enteredDOB && !moment(enteredDOB, 'MM/DD/YYYY').isSame(moment(lyraCodeDOB, 'MM-DD-YYYY'))) {
      warnings[PAYMENT_FORM.FIELDS.DATE_OF_BIRTH] = 'Date of birth does not correspond with Lyra code'
    }
  }
  return warnings
}

export const RequestPaymentAdmins: React.FC<RequestPaymentProps> = ({
  user,
  providersList,
  practiceID,
  initialize,
  guaranteedTime,
  currentHPCharge,
  sessionType,
  rates,
  prepopInfo,
  synchronousErrors,
  dispatch,
  eligibilityCheckFields,
  HPEligibilityCheckFields,
  sessionTrackingStatus,
  HPEligibility,
  initialICD10s,
  showCancelledSessionsExceededModal,
  showDuplicateModal,
  paymentResponse,
  sessionsExceeded,
  outcomeReminderStatus,
  customers,
  enteredVisitDate,
  enteredPatient,
  activeField,
  enteredlyraCode,
  touch,
  asyncValidate,
  selectedProvider,
  attendance,
  selectedCompany,
  eligibilityStatus,
  formIsValid,
  handleSubmit,
  submitting,
  showEligibleMemberDetails,
  showDiagnosisDescription,
  showIneligibleClientModal,
  clientOutcomesEligibility,
  initialCompany,
  initialDiagnoses,
  onSiteEligible,
  guaranteedTimeEligible,
  providerOutcomesAgreed,
  patientOutcomesAgreed,
  config,
  isPractice,
  providerAddresses,
  lyraCodeValidationInfo,
  actions: {
    getPracticeProviders,
    addAlert,
    getProviderRates,
    getProvider,
    getChargeToCopy,
    selectProvider,
    clearHPEligibility,
    clearHealthPlanCharge,
    checkEligibility,
    displayIneligibleClientModal,
    getHPEligibility,
    getPayments,
    getClient,
    hideAlerts,
    submitPaymentRequest,
    saveHealthPlanCharge,
    clearLyraCodeValidationInfo,
    closeRequestPaymentModal,
    clearRequestPaymentStore,
  },
  primaryCondition,
  secondaryCondition,
  ebtType,
}) => {
  const [loading, setLoading] = useState(true)
  const [isHPEligibilityLoading, setIsHPEligibilityLoading] = useState(false)
  const [pastPaymentLoading, setPastPaymentLoading] = useState(false)
  const [submittedEligibityCheckData, setSubmittedEligibilityCheckData] = useState({})
  const [submittedHPEligibilityCheckData, setSubmittedHPEligibilityCheckData] = useState({})
  const [copiedLocation, setCopiedLocation] = useState({})
  const [dateInputKey, setDateInputKey] = useState(_.uniqueId('dateInput'))
  const prevActiveField = usePrevious(activeField)
  const prevLyraCode = usePrevious(enteredlyraCode)
  const prevGuaranteedTime = usePrevious(guaranteedTime)
  const prevPrepopInfo = usePrevious(prepopInfo)
  const prevSessionType = usePrevious(sessionType)
  const prevRates = usePrevious(rates)
  const prevSelectedCompany = usePrevious(selectedCompany)
  const location = useLocation()
  const navigate = useNavigate()

  const roles = user.roles

  // https://stackoverflow.com/questions/54666401/how-to-use-throttle-or-debounce-with-react-hook
  const useDebouncedEffect = (effect: Function, deps: Array<string>, delay: number) => {
    useEffect(() => {
      const handler = setTimeout(() => effect(), delay)

      return () => clearTimeout(handler)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...(deps || []), delay])
  }

  // This function is specific to admins roles
  // In RequestPayment, there is a useEffect hook similar to this but for non-admin roles which has a similar comment
  // REMINDER: When we merge these two files, we will need both useEffect hooks.
  useEffect(() => {
    // practice_admins: get providers if you don't already have them
    if (hasRole(roles, [ROLES.PRACTICES_ADMIN, ROLES.PAYMENTS_ADMIN]) && providersList.length == 0 && practiceID) {
      getPracticeProviders(practiceID).then(
        () => {
          // got providers
          setLoading(false)
          const providerIdToLoad = currentHPCharge?.provider_lyra_id
          if (providerIdToLoad) {
            selectProvider(providerIdToLoad)
            getProviderRates({ id: providerIdToLoad })
          }
        },
        (error: any) => {
          setLoading(false)
          const errorText = error.response.data.message || getErrorText(error.response.status)
          addAlert({
            show: true,
            contents: 'Error in getting list of providers: ' + errorText,
            style: 'danger',
            expires: true,
          })
        },
      )
    } else {
      setLoading(false)
    }
    return function cleanup() {
      clearRequestPaymentStore()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const userId = user.id
    if (hasRole(roles, ROLES.INDIVIDUAL_PROVIDER)) {
      getProviderRates({ id: userId })
      getProvider(userId)
    }
  }, [user, roles, getProviderRates, getProvider])

  useEffect(() => {
    const prevRoute = location.state?.prev_route
    if (
      prevRoute !== PRACTICES_DETAILS_HEALTHPLAN_PAYMENT_INFO.route &&
      prevRoute !== PROVIDERS_DETAILS_HEALTHPLAN_PAYMENT_INFO.route &&
      prevRoute !== HEALTHPLAN_INFO.route
    ) {
      clearHPEligibility()
      clearHealthPlanCharge()
    }
  }, [clearHPEligibility, clearHealthPlanCharge, location])

  useEffect(() => {
    const copiedChargeID = location.state?.copy_charge_id

    if (copiedChargeID) {
      getChargeToCopy(copiedChargeID).then(
        (rpReturn: any) => {
          if (hasRole(roles, ROLES.PRACTICES_ADMIN) && rpReturn.value.data.provider_lyra_id) {
            getProviderRates({ id: rpReturn.value.data.provider_lyra_id })
          }
          // Call getInitialValues inside getChargeToCopy callback to make sure this.props.prepopInfo is populated]
          if (Object.keys(prepopInfo).length > 2) {
            const onlyUseCopiedCharge = true
            const { initialValues } = getInitialValues(guaranteedTime, onlyUseCopiedCharge)
            initialize(initialValues)
          }
        },
        (error: any) => {
          const errorText = error.response.data.message || getErrorText(error.response.status)
          addAlert({
            show: true,
            contents: errorText,
            style: 'danger',
            expires: true,
          })
        },
      )
    } else {
      const { initialValues } = getInitialValues(guaranteedTime)
      initialize(initialValues)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (prepopInfo?.provider_lyra_id && !(providersList?.length == 0) && !selectedProvider) {
      selectProvider(prepopInfo.provider_lyra_id)
    }
  })

  useEffect(() => {
    // When company, lyra code, or visit date lose focus trigger eligibilty check
    const eligibilityTriggerFields = [
      PAYMENT_FORM.FIELDS.COMPANY,
      PAYMENT_FORM.FIELDS.LYRA_CODE,
      PAYMENT_FORM.FIELDS.VISIT_DATE,
    ]
    if (
      eligibilityTriggerFields.some((field) => {
        return field === prevActiveField && field !== activeField
      })
    ) {
      eligibilityCheck()
    }

    if (
      Object.keys(HPEligibilityCheckFields).some((field) => {
        return field === prevActiveField && field !== activeField && field !== PAYMENT_FORM.FIELDS.PROVIDER_LYRA_ID
      })
    ) {
      checkHPEligibility()
    }
  })

  const _getClient = () => {
    if (cancelGetClient) {
      cancelGetClient() // cancel any pending axios requests
    }
    getClient(
      enteredPatient,
      new cancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancelGetClient = c
      }),
    )
  }

  useDebouncedEffect(
    () => {
      //new values entered

      // test them
      if (
        enteredPatient.first_name &&
        enteredPatient.first_name.length > 1 &&
        enteredPatient.last_name &&
        enteredPatient.last_name.length > 1 &&
        enteredPatient.dob &&
        moment(enteredPatient.dob).isValid()
      ) {
        // valid required data for retrieving patient
        _getClient()
      }
    },
    [enteredPatient.first_name, enteredPatient.last_name, enteredPatient.dob],
    1000,
  )

  useEffect(() => {
    // checks for updated company field and force sessionType to be cleared(required to be reselected again)
    // coach's session type should never be touched as they can only submit smp_session + field is disabled
    const prevCompany = prevSelectedCompany?.[0]?.value
    const currCompany = selectedCompany?.[0]?.value
    // We check to see if prevCompany is undefined because if you reload the page after copying a charge in payment history tab, prevCompany will be undefined
    // causing session type to be cleared when it shouldn't be.
    if (prevCompany) {
      if (prevCompany !== currCompany && !hasRole(roles, [ROLES.LT_COACH, ROLES.LT_SS_COACH]) && !guaranteedTime) {
        dispatch(change(PAYMENT_FORM.NAME, PAYMENT_FORM.FIELDS.SESSION_TYPE, ''))
      }
    }
  }, [dispatch, guaranteedTime, prevSelectedCompany, roles, selectedCompany])

  useEffect(() => {
    // Manually trigger async validation. This is needed so that it fires on the last character press
    if (prevLyraCode !== enteredlyraCode && sanitizeLyraCode(enteredlyraCode).length === LYRA_CODE_LENGTH) {
      asyncValidate(PAYMENT_FORM.FIELDS.LYRA_CODE, enteredlyraCode, 'manual')
      // Set lyra_code field to touched so that the error message displays immediately
      touch(PAYMENT_FORM.FIELDS.LYRA_CODE)
    }
  }, [prevLyraCode, enteredlyraCode, asyncValidate, touch])

  const locationChangeHandler = useCallback(
    (address: Address[]) => {
      dispatch(change(PAYMENT_FORM.NAME, PAYMENT_FORM.FIELDS.LOCATION, address))
    },
    [dispatch],
  )

  useEffect(() => {
    const addresses = selectedProvider?.addresses
    const foundAddress = addresses?.find((address: any) => {
      return address.state === (copiedLocation as any)?.state && address.zipcode === (copiedLocation as any)?.zipcode
    })
    if (foundAddress) {
      locationChangeHandler([foundAddress])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [(copiedLocation as any)?.state, (copiedLocation as any)?.zipcode, selectedProvider, locationChangeHandler])

  const getInitialValues = useCallback(
    (isGuaranteedTime: boolean, onlyUseCopiedCharge = false, afterPaymentSubmit = false) => {
      let initialValuesByLyraType = {}
      let disabledFieldsByLyraType = {}
      if (hasRole(roles, [ROLES.LT_THERAPIST, ROLES.INDIVIDUAL_PROVIDER])) {
        initialValuesByLyraType = PAYMENT_FORM.THERAPIST.initialValues
        disabledFieldsByLyraType = PAYMENT_FORM.THERAPIST.disabledFields
      }

      // It is possible that currentHPCharge is in the redux store when a user wants to copy charge from payment history
      // onlyUseCopiedCharge is set to true to make sure the form is filled correctly with the copied charge and not the one in redux store
      const chargeObj = !onlyUseCopiedCharge ? currentHPCharge : {}

      // When copying a payment from payment history and submitting a non-HP charge, prepopInfo will still contain copied charge from redux store when getInitialValues is called
      // SubmitPaymentRequest action clears copied charge from store, however it seems like it waits until after getInitialValues is called, therefore we need to clear out those values in prepopInfo
      if (afterPaymentSubmit) {
        Object.keys(prepopInfo).forEach((item) => {
          if (item !== 'practice_id' && item !== 'provider_lyra_id') delete prepopInfo[item]
        })
      }

      const initialLocation = prepopInfo.location
      if (prepopInfo.modality === 'in_person' && initialLocation && prepopInfo.provider_lyra_id) {
        setCopiedLocation(initialLocation)
        delete prepopInfo.location
      }
      // loads the base default values (applicable for all provider types) and then fields based on provider type
      let initialValues = {
        ...PAYMENT_FORM.DEFAULT.initialValues,
        ...initialValuesByLyraType,
        ...prepopInfo,
        ...chargeObj,
      }

      let disabledFields = { ...disabledFieldsByLyraType }

      // load guaranteed time specific field values and provider+guaranteed time specific fields values if guaranted time box is checked
      if (isGuaranteedTime) {
        let initialValuesByLyraTypeGuaranteedTime = {}
        let disabledFieldsByLyraTypeGuaranteedTime = {}
        if (hasRole(roles, [ROLES.LT_THERAPIST, ROLES.INDIVIDUAL_PROVIDER])) {
          initialValuesByLyraTypeGuaranteedTime = PAYMENT_FORM.THERAPIST_GUARANTEED_TIME.initialValues
          disabledFieldsByLyraTypeGuaranteedTime = PAYMENT_FORM.THERAPIST_GUARANTEED_TIME.disabledFields
        }

        initialValues = {
          ...initialValues,
          ...PAYMENT_FORM.GUARANTEED_TIME.initialValues,
          ...initialValuesByLyraTypeGuaranteedTime,
        }
        disabledFields = {
          ...disabledFields,
          ...PAYMENT_FORM.GUARANTEED_TIME.disabledFields,
          ...disabledFieldsByLyraTypeGuaranteedTime,
        }
      }
      initialValues.session_rate =
        sessionType && sessionType !== 'other' && rates && Object.keys(rates).length > 0 ? rates[sessionType] : '0'
      if (initialValues.visit_date) {
        initialValues.visit_date = moment(initialValues.visit_date)
      }

      // disable FN LN until valid DOB is entered
      if (_.get(synchronousErrors, PAYMENT_FORM.FIELDS.DATE_OF_BIRTH)) {
        disabledFields[PAYMENT_FORM.FIELDS.FIRST_NAME] = true
        disabledFields[PAYMENT_FORM.FIELDS.LAST_NAME] = true
      }

      return { initialValues, disabledFields }
    },
    [currentHPCharge, prepopInfo, rates, roles, sessionType, synchronousErrors],
  )

  useEffect(() => {
    // re-initialize the form if the guaranteedTime box has been toggled. Force fields to update to the calculated initialValues
    const { initialValues } = getInitialValues(guaranteedTime)
    if (prevGuaranteedTime !== guaranteedTime || _.isEqual(prevPrepopInfo, prepopInfo) === false) {
      initialize({ ...prepopInfo, ...initialValues })
    }
    // updates the rates when we receive rates from backend, or when user changes session_type
    if (prevSessionType !== sessionType || prevRates !== rates) {
      dispatch(change(PAYMENT_FORM.NAME, PAYMENT_FORM.FIELDS.SESSION_RATE, initialValues.session_rate))
    }
  }, [
    prevGuaranteedTime,
    guaranteedTime,
    prevPrepopInfo,
    prepopInfo,
    prevSessionType,
    sessionType,
    prevRates,
    rates,
    getInitialValues,
    initialize,
    dispatch,
  ])

  const convertHPEligibilityData = (data: ChargeHealthPlanEligibilityPayload) => {
    Object.keys(data).forEach((key) => {
      if (key === PAYMENT_FORM.FIELDS.COMPANY) {
        data[key] = data[key].map((d: any) => d.value).toString()
      }
      if (
        key === PAYMENT_FORM.FIELDS.DATE_OF_BIRTH ||
        key === PAYMENT_FORM.FIELDS.VISIT_DATE ||
        key === PAYMENT_FORM.FIELDS.ELIGIBLE_MEMBER_DOB
      ) {
        data[key] = dateUtils.getDisplayDate(data[key], 'YYYY-MM-DD')
      }
      if (key === PAYMENT_FORM.FIELDS.RELATIONSHIP) {
        if (data[key] === 'dependent') {
          // This is done to match the field name and value that PP-API /transfers/healthplan/eligible expects
          data.patient_relationship = 'dependent_other'
        }
        delete data[key]
      }
      if (key === PAYMENT_FORM.FIELDS.LYRA_CODE) {
        data[key] = sanitizeLyraCode(data[key])
      }
    })
    return data
  }

  const eligibilityCheck = () => {
    // Fire checkEligibility if there are no corresponding errors for eligibilityCheckFields
    // And eligibilityCheck data is different than previous request
    if (
      _.isEmpty(_.pick(synchronousErrors, Object.keys(eligibilityCheckFields))) &&
      !_.isEqual(submittedEligibityCheckData, eligibilityCheckFields)
    ) {
      checkEligibility(convertToFormData(eligibilityCheckFields)).then((response: any) => {
        if (response.user_eligibility.status === false) {
          displayIneligibleClientModal()
        }
        setSubmittedEligibilityCheckData(eligibilityCheckFields)
      })
    }
  }

  // Fires off an eligibility check - POST /v1/transfers/healthplan/eligible
  // Once company, dob, FN/LN, visit_date, and session_type are populated
  const checkHPEligibility = () => {
    const { ATTESTATION_NEEDED } = specialityCareAttestationStatuses
    const convertedHPEligibilityData = convertHPEligibilityData(HPEligibilityCheckFields)
    if (
      _.isEmpty(_.pick(synchronousErrors, Object.keys(HPEligibilityCheckFields))) &&
      !_.isEqual(submittedHPEligibilityCheckData, convertedHPEligibilityData)
    ) {
      setIsHPEligibilityLoading(true) // block form submission if request hasn't finished
      getHPEligibility(convertedHPEligibilityData).then((response: any) => {
        setSubmittedHPEligibilityCheckData(convertedHPEligibilityData)
        setIsHPEligibilityLoading(false)
        dispatch({
          type: SET_HP_ELIGIBILITY_STATUS,
          data: response,
        })
        // If attestation_needed, load the latest transfer for corresponding patient/provider lyra id
        // Transfer data used in HealthPlanInfo to prepopulate attestation fields
        if (response.speciality_care_attestation_status === ATTESTATION_NEEDED && response.patient_lyra_id) {
          setPastPaymentLoading(true)
          getPayments({
            status: 'submitted',
            page: 0,
            limit: 20,
            providerId: convertedHPEligibilityData[PAYMENT_FORM.FIELDS.PROVIDER_LYRA_ID],
            isHealthPlan: true,
            patientId: response.patient_lyra_id,
          }).then(() => {
            setPastPaymentLoading(false)
          })
        }
      })
    }
  }

  const providerChangeHandler = (_event: ChangeEvent, providerId: string) => {
    if (hasRole(roles, ROLES.PRACTICES_ADMIN)) {
      getProviderRates({ id: providerId })
    }

    // Clear the dynamic fields since they are based on the selected provider attributes.
    dispatch(change(PAYMENT_FORM.NAME, PAYMENT_FORM.FIELDS.ONSITE_SESSION, false))
    dispatch(change(PAYMENT_FORM.NAME, PAYMENT_FORM.FIELDS.IS_GUARANTEED_TIME, false))

    clearHPEligibility()
    clearHealthPlanCharge()

    selectProvider(providerId)
  }

  const lastSessionHandler = (e: ChangeEvent<HTMLInputElement>, value: boolean) => {
    if (e.target.name === PAYMENT_FORM.FIELDS.LAST_SESSION && value) {
      dispatch(change(PAYMENT_FORM.NAME, PAYMENT_FORM.FIELDS.LAST_SESSION_TERMINATED, false))
    } else if (e.target.name === PAYMENT_FORM.FIELDS.LAST_SESSION_TERMINATED && value) {
      dispatch(change(PAYMENT_FORM.NAME, PAYMENT_FORM.FIELDS.LAST_SESSION, false))
    }
  }

  // @ts-expect-error TS(7030): Not all code paths return a value.
  const submitForm = (values: Map<any, any>) => {
    hideAlerts()

    const isPaymentsAdmin = hasRole(roles, ROLES.PAYMENTS_ADMIN)
    const chargeObj = values.toJS()
    const { APPROACHING_ATTESTATION, ATTESTATION_PRIOR_AUTHORIZATION, ATTESTATION_NEEDED } =
      specialityCareAttestationStatuses

    // Coaches should not be asked to provide ICD10 and verify co-pay consent for coaching health plan sessions.
    // Default values will be used when the health plan claim is generated.
    if (
      !coachingSessionTypesList.includes(chargeObj.session_type) &&
      (HPEligibility?.next_session_uses_health_plan ||
        (HPEligibility &&
          [APPROACHING_ATTESTATION, ATTESTATION_PRIOR_AUTHORIZATION, ATTESTATION_NEEDED].includes(
            HPEligibility?.speciality_care_attestation_status,
          )))
    ) {
      chargeObj.next_session_uses_health_plan = HPEligibility.next_session_uses_health_plan
      chargeObj.speciality_care_attestation_status = HPEligibility.speciality_care_attestation_status
      chargeObj.patient_lyra_id = HPEligibility.patient_lyra_id
      if (initialICD10s?.icd10_primary_diagnosis)
        chargeObj.icd10_primary_diagnosis = initialICD10s.icd10_primary_diagnosis
      if (initialICD10s?.icd10_secondary_diagnosis)
        chargeObj.icd10_secondary_diagnosis = initialICD10s.icd10_secondary_diagnosis
      window.scrollTo(0, 0)
      saveHealthPlanCharge(chargeObj)
      if (isPaymentsAdmin && location.pathname === PROVIDERS_DETAILS_NEW_PAYMENT.route) {
        navigate(PROVIDERS_DETAILS_HEALTHPLAN_PAYMENT_INFO.route)
      } else if (isPaymentsAdmin && location.pathname === PRACTICES_DETAILS_NEW_PAYMENT.route) {
        navigate(PRACTICES_DETAILS_HEALTHPLAN_PAYMENT_INFO.route)
      } else {
        navigate(HEALTHPLAN_INFO.route)
      }
    } else {
      return submitPaymentRequest(convertToFormData(chargeObj), false)
        .then((rpReturn: any) => {
          if (!isPaymentsAdmin && (rpReturn.data.email_required || rpReturn.data.health_plan_reminder_email_required)) {
            navigate(CLIENT_EMAIL.route, {
              state: {
                patient_id: rpReturn.data.data.patient_lyra_id,
                initialSession: rpReturn.data.session_tracking_status === 'session_limit_initial_session',
                sessionsLeft: parseInt(rpReturn.data.data.sessions_remaining, 10),
                firstName: rpReturn.data.data.first_name,
                lastName: rpReturn.data.data.last_name,
                isHealthPlanEligible: rpReturn.data.health_plan_reminder_email_required,
                company: rpReturn.data.data.company,
                displayVisitsPerIssuePerYear: customers.find(
                  (customer) => customer.value === rpReturn.data.data.company,
                )?.display_visits_per_issue_per_year,
              },
            })
          } else {
            // forward them along with success message
            clearLyraCodeValidationInfo()
            const { initialValues } = getInitialValues(guaranteedTime, false, true)
            initialize(initialValues)
            dispatch(change(PAYMENT_FORM.NAME, PAYMENT_FORM.FIELDS.SESSION_RATE, initialValues.session_rate))
            // Hack: Setting a new key to reset the DateInputField after submit.
            // This should go away when we move to react-final-form
            setDateInputKey(_.uniqueId('dateInput'))
            if (
              sessionTrackingStatus === 'session_limit_warning' ||
              sessionTrackingStatus === 'session_limit_initial_session'
            ) {
              window.scrollTo(0, 0)
              return
            }
            addAlert({
              show: true,
              contents: bannerMessages.SUBMIT_PAYMENT_SUCCESS,
              style: 'success',
              expires: true,
              autoDismissTimer: 10000,
            })
          }
        })
        .catch(() => window.scrollTo(0, 0))
    }
  }

  const buildModals = () => {
    let showModal = false
    let modalBody = <div />
    let modalType = ''

    // prep payment info if exists
    let sessionsLeft
    let company: CustomerInfo | undefined
    let firstName = enteredPatient.first_name
    let lastName = enteredPatient.last_name
    let displayVisitsPerIssuePerYear
    if (paymentResponse) {
      firstName = paymentResponse.first_name
      lastName = paymentResponse.last_name
      company = customers.find((customer: CustomerInfo) => customer.value === paymentResponse.company)
      displayVisitsPerIssuePerYear = company?.display_visits_per_issue_per_year
      if (!_.isEmpty(paymentResponse.sessions_remaining)) {
        sessionsLeft = parseInt(paymentResponse.sessions_remaining, 10)
      }
    }

    // session tracking modal
    switch (sessionTrackingStatus) {
      case 'session_limit_warning':
        showModal = true
        if (sessionsLeft === 0) {
          modalType = 'STS_LAST'
          modalBody = <LastSessionModal firstName={firstName} lastName={lastName} company={company} />
          break
        }
        modalType = 'STS_WARNING'
        modalBody = (
          <SessionsWarningModal
            firstName={firstName}
            lastName={lastName}
            sessionsLeft={sessionsLeft ?? 0}
            company={company}
          />
        )
        break
      case 'session_limit_initial_session':
        showModal = true
        modalType = 'STS_INITIAL'
        modalBody = (
          <FirstSessionModal
            firstName={firstName}
            lastName={lastName}
            sessionsLeft={sessionsLeft ?? 0}
            displayVisitsPerIssuePerYear={displayVisitsPerIssuePerYear}
          />
        )
        break
      // outcome reminder modal
      case 'session_limit_ok':
        if (outcomeReminderStatus?.show_reminder) {
          showModal = true
          modalType = 'outcomeReminder'
          modalBody = (
            <OutcomeReminder
              firstName={firstName}
              outcomeSentDate={dateUtils.getDisplayDate(outcomeReminderStatus?.outcome_sent_date, 'MMMM Do')}
              closeFunc={() => closeRequestPaymentModal(modalType)}
            />
          )
        }
    }
    if (sessionsExceeded === 'session_limit_exceeded') {
      showModal = true
      modalBody = <SessionsExceededModal firstName={firstName} lastName={lastName} company={company} />
      modalType = 'sessionsExceeded'
    }

    // duplicate payment modal
    if (showDuplicateModal) {
      showModal = true
      modalType = 'duplicatePayment'
      modalBody = <DuplicatePaymentModal closeFunc={() => closeRequestPaymentModal(modalType)} />
    }

    // exceeded cancelled sessions modal
    if (showCancelledSessionsExceededModal) {
      showModal = true
      modalBody = (
        <CancelledSessionsExceededModal
          firstName={firstName}
          lastName={lastName}
          visitDate={enteredVisitDate}
          company={company}
        />
      )
      modalType = 'cancelledSessionsExceeded'
    }

    // ineligible client modal
    if (showIneligibleClientModal) {
      showModal = true
      modalType = 'ineligibleClient'
      modalBody = <ClientIneligibleModal closeFunc={() => closeRequestPaymentModal(modalType)} />
    }

    return {
      showModal,
      modalBody,
      modalType,
    }
  }

  const submitButtonText = () => {
    const { APPROACHING_ATTESTATION, ATTESTATION_PRIOR_AUTHORIZATION, ATTESTATION_NEEDED } =
      specialityCareAttestationStatuses

    return HPEligibility?.next_session_uses_health_plan ||
      (HPEligibility &&
        [APPROACHING_ATTESTATION, ATTESTATION_PRIOR_AUTHORIZATION, ATTESTATION_NEEDED].includes(
          HPEligibility?.speciality_care_attestation_status,
        ))
      ? 'Next'
      : 'Submit'
  }

  const shouldDisableSubmitButton = () => {
    if (!attendance) {
      return false
    } else if (
      _.get(selectedCompany, '[0].lyra_code_required') === 'true' &&
      !hasRole(roles, ROLES.PAYMENTS_ADMIN) &&
      !guaranteedTime
    ) {
      return !eligibilityStatus || !formIsValid || isHPEligibilityLoading || pastPaymentLoading
    }
    return !formIsValid || isHPEligibilityLoading || pastPaymentLoading
  }

  const getProviderAddresses = () => {
    if ((hasRole(roles, ROLES.PAYMENTS_ADMIN) && isPractice) || hasRole(roles, ROLES.PRACTICES_ADMIN)) {
      return selectedProvider?.addresses
    } else {
      return providerAddresses
    }
  }

  if (!user.is_registered) {
    if (hasRole(roles, ROLES.PRACTICES_ADMIN)) {
      return <Navigate to={PRACTICES_REGISTER.route} />
    } else if (hasRole(roles, ROLES.INDIVIDUAL_PROVIDER)) {
      return <Navigate to={PROVIDERS_REGISTER.route} />
    }
  }

  if (loading) {
    return (
      <ContentLayout>
        <div className={styles['loading-container']}>
          <LoadingIndicator size={45} />
        </div>
      </ContentLayout>
    )
  }

  if (!loading && (hasRole(roles, ROLES.PRACTICES_ADMIN) || isPractice) && providersList?.length === 0) {
    return (
      <ContentLayout>
        <div className={styles['no-providers-container']}>
          <h1>No Providers Found</h1>
          <p className={styles['paragraph-small']}>
            No providers were found for this practice. Please align registered providers to this practice and try again.
          </p>
        </div>
      </ContentLayout>
    )
  }
  const { showModal, modalBody, modalType } = buildModals()
  const { initialValues, disabledFields } = getInitialValues(guaranteedTime)
  const showAvailabilityCard = !hasRole(roles, ROLES.PAYMENTS_ADMIN)
  return (
    <BootstrapContainer col='col-md-10 col-md-offset-1'>
      {/* @ts-expect-error TS(2741): Property 'isMinified' is missing in type '{}' but ... Remove this comment to see the full error message */}
      {showAvailabilityCard && <AvailabilityCard />}
      <div className={styles['title-block']}>
        <h1 className={styles.title}>Request Payment</h1>
      </div>
      <div className={styles['request-payment-container']}>
        <PaymentRequestForm
          attendance={attendance}
          dateInputKey={dateInputKey}
          disabledFields={disabledFields}
          handleSubmit={handleSubmit}
          submitFunction={_.throttle(submitForm, 2000)}
          submitting={submitting}
          providers={providersList}
          showEligibleMemberDetails={showEligibleMemberDetails}
          showOutcomesMessage={providerOutcomesAgreed}
          patientOutcomesAgreed={patientOutcomesAgreed}
          sessionType={sessionType}
          showDiagnosisDescription={showDiagnosisDescription}
          clientOutcomesEligibility={clientOutcomesEligibility}
          providerChangeHandler={providerChangeHandler}
          initialCompany={initialValues.company || initialCompany}
          initialDiagnoses={initialDiagnoses}
          initialModality={initialValues.modality}
          lastSessionHandler={lastSessionHandler}
          sponsoringCompanies={customers ?? []}
          guaranteedTime={guaranteedTime}
          guaranteedTimeEligible={guaranteedTimeEligible}
          onSiteEligible={onSiteEligible}
          selectedCompany={selectedCompany}
          primaryButtonText={submitButtonText()}
          lyraCodeValidationUser={lyraCodeValidationInfo?.user_info}
          disableSubmitButton={shouldDisableSubmitButton()}
          showLyraCode={selectedCompany?.[0]?.lyra_code_enabled === 'true' && !guaranteedTime}
          hideDependentField={selectedCompany?.[0]?.hide_dependent_field === 'true'}
          selectedProvider={selectedProvider}
          loggedInUser={user}
          config={config}
          providerAddresses={getProviderAddresses() ?? []}
          locationChangeHandler={locationChangeHandler}
          primaryCondition={primaryCondition}
          secondaryCondition={secondaryCondition}
          ebtType={ebtType}
        />
        <BaseModal isOpen={showModal} body={modalBody} closeModal={() => closeRequestPaymentModal(modalType)} />
      </div>
    </BootstrapContainer>
  )
}

type RequestPaymentProps = {
  handleSubmit: Function
  initialize: Function
  submitting: boolean
  user: ProviderUser
  providersList: ProviderAdminProviderInfo[]
  showEligibleMemberDetails: boolean
  sessionType: string
  showDiagnosisDescription: boolean
  clientOutcomesEligibility: boolean
  enteredPatient: { first_name: string; last_name: string; dob: string }
  initialCompany: CustomerInfo[]
  initialDiagnoses: { label: string; value: string }[]
  initialICD10s: { icd10_primary_diagnosis: string; icd10_secondary_diagnosis: string }
  dispatch: Dispatch<AnyAction>
  customers: CustomerInfo[]
  sessionTrackingStatus: string
  sessionsExceeded: string
  paymentResponse: Transfer
  showDuplicateModal: boolean
  showCancelledSessionsExceededModal: boolean
  enteredVisitDate: string
  guaranteedTimeEligible: boolean
  onSiteEligible: boolean
  guaranteedTime: boolean
  providerOutcomesAgreed: boolean
  patientOutcomesAgreed: boolean
  rates: ProviderRates
  outcomeReminderStatus: { show_reminder: boolean; outcome_sent_date: string }
  selectedCompany: CustomerInfo[]
  config: Config
  prepopInfo: any
  formIsValid: boolean
  practiceID: string
  activeField: string
  asyncValidate: Function
  enteredlyraCode: string
  touch: Function
  eligibilityCheckFields: ChargeEligiblityPayload
  HPEligibilityCheckFields: ChargeHealthPlanEligibilityPayload
  HPEligibility?: ChargeHealthPlanEligiblityResponse
  currentHPCharge?: any
  synchronousErrors: any
  showIneligibleClientModal: boolean
  eligibilityStatus: boolean
  lyraCodeValidationInfo: LyraCodeValidationInfo
  selectedProvider: ProviderAdminProviderInfo
  isPractice: boolean
  attendance: string
  providerAddresses: Address[]
  primaryCondition: { label: string; value: string }[]
  secondaryCondition: { label: string; value: string }[]
  ebtType: { label: string; value: string }[]
  actions: {
    getPracticeProviders: (id: string) => Promise<any>
    addAlert: ({
      show,
      contents,
      style,
      expires,
      autoDismissTimer,
    }: {
      show: boolean
      contents: string
      style: string
      expires: boolean
      autoDismissTimer?: number
    }) => void
    getProviderRates: ({ id }: { id: string }) => void
    getProvider: (providerId: string) => void
    getChargeToCopy: (chargeId: string) => Promise<any>
    selectProvider: (providerId: string) => void
    clearHPEligibility: () => void
    clearHealthPlanCharge: () => void
    checkEligibility: (eligiblityObj: FormData) => Promise<any>
    displayIneligibleClientModal: () => void
    getHPEligibility: (hpEligibilityObj: ChargeHealthPlanEligibilityPayload) => Promise<any>
    getPayments: ({
      status,
      page,
      limit,
      providerId,
      isHealthPlan,
      patientId,
    }: {
      status: string
      page: number
      limit: number
      providerId: string
      isHealthPlan: boolean
      patientId: string
    }) => Promise<any>
    getClient: (
      enteredPatient: { first_name: string; last_name: string; dob: string },
      cancelFunction: CancelToken,
    ) => Promise<any>
    hideAlerts: () => void
    submitPaymentRequest: (chargeObj: FormData, isBCx: boolean) => Promise<any>
    saveHealthPlanCharge: (HPCharge: any) => void
    clearLyraCodeValidationInfo: () => void
    closeRequestPaymentModal: (modalType: string) => void
    clearRequestPaymentStore: () => void
  }
}

RequestPaymentAdmins.defaultProps = {
  patientOutcomesAgreed: true,
}

const selector = formValueSelector(PAYMENT_FORM.NAME)

const mapStateToProps = ($$state: Map<any, any>, ownProps: RequestPaymentProps) => {
  const userData = getAuthUser($$state)
  // role based fields
  let practiceID
  let provider = userData
  let rates = getRequestPaymentRates($$state)
  const providersList = getRequestPaymentProvidersList($$state) ?? []
  if (hasRole(userData?.roles, ROLES.PRACTICES_ADMIN)) {
    provider = getRequestPaymentSelectedProvider($$state) ?? {}
    practiceID = userData?.practice_id
  } else if (hasRole(userData?.roles, ROLES.PAYMENTS_ADMIN)) {
    provider = getProviderDetailsData($$state) ?? {}
    if (ownProps.isPractice) {
      provider = getRequestPaymentSelectedProvider($$state) ?? {}
      practiceID = getPracticeDetailsDataId($$state)
    }
    rates = provider.rates
  }

  const attendance = selector($$state, PAYMENT_FORM.FIELDS.ATTENDANCE)
  const relationship = selector($$state, PAYMENT_FORM.FIELDS.RELATIONSHIP)
  const sessionType = selector($$state, PAYMENT_FORM.FIELDS.SESSION_TYPE)
  const diagnosisArray = selector($$state, PAYMENT_FORM.FIELDS.DIAGNOSIS)
  const firstName = selector($$state, PAYMENT_FORM.FIELDS.FIRST_NAME)
  const lastName = selector($$state, PAYMENT_FORM.FIELDS.LAST_NAME)
  const clientDOB = selector($$state, PAYMENT_FORM.FIELDS.DATE_OF_BIRTH)
  let selectedCompany = selector($$state, PAYMENT_FORM.FIELDS.COMPANY)
  selectedCompany = Iterable.isIterable(selectedCompany) ? selectedCompany.toJS() : selectedCompany
  const visitDate = selector($$state, PAYMENT_FORM.FIELDS.VISIT_DATE)
  const lyraCode = selector($$state, PAYMENT_FORM.FIELDS.LYRA_CODE)
  const eligibleMemberFirstName = selector($$state, PAYMENT_FORM.FIELDS.ELIGIBLE_MEMBER_FIRST_NAME)
  const eligibleMemberLastName = selector($$state, PAYMENT_FORM.FIELDS.ELIGIBLE_MEMBER_LAST_NAME)
  const eligibleMemberDOB = selector($$state, PAYMENT_FORM.FIELDS.ELIGIBLE_MEMBER_DOB)
  const guaranteedTime = selector($$state, PAYMENT_FORM.FIELDS.IS_GUARANTEED_TIME)
  const customers = getRequestPaymentCustomers($$state)
  const chargeCopy = getChargeCopyPrepopInfo(getRequestPaymentCopy($$state), customers)
  const currentProviderLyraID = provider.id || provider.lyra_id
  const providerPaymentInfo = {
    provider_lyra_id: currentProviderLyraID,
    ...(practiceID && { practice_id: practiceID }),
  }
  const primaryCondition = selector($$state, PAYMENT_FORM.FIELDS.PRIMARY_CONDITION)
  const secondaryCondition = selector($$state, PAYMENT_FORM.FIELDS.SECONDARY_CONDITION)
  const ebtType = selector($$state, PAYMENT_FORM.FIELDS.EBT_TYPE)

  // get patient outcomes and employer data
  const { patientOutcomesAgreed, patientEmployer } = getPatientPrepopInfo(getRequestPaymentPatient($$state), customers)

  // Used for visit date field validation
  const minDate = hasRole(userData?.roles, ROLES.PAYMENTS_ADMIN) ? null : moment().subtract(90, 'days')

  // Set outcomes eligibility text
  let isUnderage = false
  if (clientDOB !== undefined) {
    isUnderage = formValidationConstants.isValidDateOfBirth(clientDOB, { min: 0, max: 17 }).isValid
  }
  const isClientOutcomesEligible =
    !isUnderage && (sessionType === undefined || outcomesEligibleSessionTypes[sessionType])

  const showEligibleMemberDetails = shouldShowPaymentFormEligibleMemberDetails(
    relationship,
    isUnderage,
    selectedCompany?.[0],
  )
  const eligibilityCheckFields = {
    [PAYMENT_FORM.FIELDS.COMPANY]: selectedCompany,
    [PAYMENT_FORM.FIELDS.FIRST_NAME]: firstName,
    [PAYMENT_FORM.FIELDS.LAST_NAME]: lastName,
    ...(_.get(selectedCompany, '[0].lyra_code_enabled') === 'true' && { [PAYMENT_FORM.FIELDS.LYRA_CODE]: lyraCode }),
    ...(showEligibleMemberDetails && {
      [PAYMENT_FORM.FIELDS.ELIGIBLE_MEMBER_FIRST_NAME]: eligibleMemberFirstName,
      [PAYMENT_FORM.FIELDS.ELIGIBLE_MEMBER_LAST_NAME]: eligibleMemberLastName,
      [PAYMENT_FORM.FIELDS.ELIGIBLE_MEMBER_DOB]: eligibleMemberDOB,
    }),
    [PAYMENT_FORM.FIELDS.VISIT_DATE]: visitDate,
  }
  const HPEligibilityCheckFields = {
    ...eligibilityCheckFields,
    [PAYMENT_FORM.FIELDS.SESSION_TYPE]: sessionType,
    [PAYMENT_FORM.FIELDS.DATE_OF_BIRTH]: clientDOB,
    [PAYMENT_FORM.FIELDS.IS_GUARANTEED_TIME]: guaranteedTime,
    provider_lyra_id: currentProviderLyraID,
    ...(showEligibleMemberDetails && {
      [PAYMENT_FORM.FIELDS.RELATIONSHIP]: relationship,
    }),
  }
  return {
    providersList,
    user: userData,
    guaranteedTime,
    guaranteedTimeEligible: provider.guaranteed_time_eligible ?? false,
    onSiteEligible: provider.onsite ?? false,
    showEligibleMemberDetails,
    sessionType,
    rates,
    practiceID,
    prepopInfo: Object.assign({}, providerPaymentInfo, chargeCopy.prepopInfo),
    initialCompany: chargeCopy.initialCompany || patientEmployer,
    initialDiagnoses: chargeCopy.initialDiagnoses,
    showDiagnosisDescription: _.includes(diagnosisArray, 'Other'),
    clientOutcomesEligibility: isClientOutcomesEligible,
    providerOutcomesAgreed: provider.outcomes_agreed ?? false,
    patientOutcomesAgreed,
    customers: customers,
    sessionTrackingStatus: getRequestPaymentDataSessionTrackingStatus($$state),
    outcomeReminderStatus: getRequestPaymentDataOutcomeReminderStatus($$state) ?? {},
    sessionsExceeded: getRequestPaymentResponseDataSessionTrackingStatus($$state),
    showDuplicateModal: getRequestPaymentShowDuplicateModal($$state),
    showCancelledSessionsExceededModal: getRequestPaymentShowCancelledSessionsExceededModal($$state),
    paymentResponse: getRequestPaymentDataData($$state),
    clientDOB,
    enteredVisitDate: visitDate,
    enteredPatient: {
      first_name: firstName,
      last_name: lastName,
      dob: clientDOB,
    },
    initialICD10s: Object.fromEntries(
      Object.entries(getRequestPaymentCopy($$state) ?? {}).filter(
        ([key, value]: [string, string]) => [ICD10_SECONDARY_FIELD, ICD10_PRIMARY_FIELD].includes(key) && value,
      ),
    ),
    eligibilityCheckFields,
    HPEligibilityCheckFields,
    HPEligibility: getHealthPlanHPEligibility($$state),
    currentHPCharge: getHealthPlanCurrentCharge($$state),
    selectedCompany,
    selectedProvider: getRequestPaymentSelectedProvider($$state),
    lyraCodeValidationInfo: getRequestPaymentLyraCodeValidationInfo($$state) ?? {},
    synchronousErrors: getFormSyncErrors(PAYMENT_FORM.NAME)($$state),
    showIneligibleClientModal: getRequestPaymentShowIneligibleClientModal($$state),
    activeField: $$state.getIn(['form', PAYMENT_FORM.NAME, 'active']),
    enteredlyraCode: lyraCode,
    eligibilityStatus: getRequestPaymentEligibilityStatus($$state) ?? false,
    formMeta: getFormMeta(PAYMENT_FORM.NAME)($$state),
    formIsValid: isValid(PAYMENT_FORM.NAME)($$state),
    config: getAuthConfig($$state),
    attendance,
    providerAddresses: getProviderDetailsAddresses($$state),
    minDate,
    primaryCondition,
    secondaryCondition,
    ebtType,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    actions: bindActionCreators(
      {
        ...requestPaymentActions,
        ...alertActions,
        ...clientsDataActions,
        ...providerDetailsActions,
        saveHealthPlanCharge,
        getHPEligibility,
        clearHPEligibility,
        clearHealthPlanCharge,
        getPayments,
      },
      dispatch,
    ),
  }
}

export const RequestPaymentReduxForm = reduxForm({
  form: PAYMENT_FORM.NAME,
  validate: paymentRequestValidate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  asyncValidate: paymentRequestAsyncValidate,
  warn: requestPaymentWarnings,
  // @ts-expect-error TS(2367): This condition will always return 'false' since th... Remove this comment to see the full error message
  shouldAsyncValidate: ({ trigger }) => trigger === 'manual',
})(RequestPaymentAdmins)

export default connect(mapStateToProps, mapDispatchToProps)(RequestPaymentReduxForm)
