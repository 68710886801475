import React from 'react'
import { Circle, Line, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

export const PlusSignIcon: React.FC<PlusSignIconProps> = ({ fillColor, size = 24 }) => {
  const { colors } = useTheme()
  return (
    <Svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      stroke={fillColor || colors.iconInverse}
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <Circle cx={12} cy={12} r={10} />
      <Line x1={12} y1={8} x2={12} y2={16} />
      <Line x1={8} y1={12} x2={16} y2={12} />
    </Svg>
  )
}

type PlusSignIconProps = {
  fillColor?: string
  size?: string | number
}
