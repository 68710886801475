import React, { FunctionComponent } from 'react'
import Svg, { ClipPath, Defs, G, Mask, Path, Rect } from 'react-native-svg'

type MemberPreferencesGenderIllustrationIconProps = {
  size?: number
}

export const MemberPreferencesGenderIllustrationIcon: FunctionComponent<
  MemberPreferencesGenderIllustrationIconProps
> = ({ size = 519, ...props }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 519 519' fill='none' {...props} pointerEvents='none'>
      <G clipPath='url(#clip0_3456_28212)'>
        <Path
          d='M261.272 0.0230507C225.533 0.0230507 190.877 6.16003 158.229 18.2309C80.5016 47.0432 25.8554 110.895 8.3018 193.451C-3.2953 247.285 -1.80618 297.058 12.8594 345.567C31.6088 407.523 77.6136 459.778 139.074 488.906C169.601 503.414 204.663 511.671 243.267 513.499C248.231 513.725 253.285 513.837 258.294 513.837C289.272 513.837 320.431 509.596 350.912 501.225C353.259 500.638 355.493 500.052 357.839 499.33C385.23 491.298 409.891 478.617 430.986 461.673C453.887 443.375 472.75 420.181 486.964 392.745C489.762 387.352 492.447 381.667 495.402 374.853C511.67 337.083 519.521 294.801 518.145 252.519C517.491 233.838 514.716 213.96 509.887 193.428C501.968 160.104 489.062 128.764 472.546 102.727C468.011 95.7329 463.882 89.8667 459.754 84.5871C430.084 46.2986 383.831 19.4493 326.071 6.92716C304.862 2.32442 283.045 0.000488281 261.249 0.000488281L261.272 0.0230507Z'
          fill='white'
        />
        <Path
          d='M496.703 253.266C496.071 235.351 493.341 216.805 489.009 198.349C481.947 168.634 470.35 139.325 454.443 114.236C450.766 108.55 446.953 103.067 442.846 97.7879C413.019 59.3189 367.804 37.9297 321.528 27.912C269.996 16.7436 215.169 20.0151 165.644 38.3358C93.9634 64.8918 45.1609 123.08 29.2544 197.92C23.1399 226.281 21.3575 258.32 24.0876 289.095H23.8845C25.4639 306.581 28.6226 323.66 33.3607 339.364C50.9594 397.552 93.7603 443.715 148.249 469.527C177.76 483.538 210.747 490.51 244.274 492.089C278.524 493.668 313.315 489.359 345.467 480.492C347.565 479.973 349.573 479.431 351.694 478.8C376.874 471.422 398.804 460.028 417.576 444.956C438.018 428.621 454.895 408.066 467.958 382.864C470.801 377.382 473.328 371.899 475.765 366.326C491.152 330.587 498.011 291.802 496.635 253.333L496.748 253.22L496.703 253.266Z'
          fill='#CBECEF'
        />
        <Mask
          id='mask0_3456_28212'
          maskUnits='userSpaceOnUse'
          x={22}
          y={21}
          width={519}
          height={519}
          mask-type='luminance'
        >
          <Path
            d='M496.699 253.264C496.067 235.349 493.337 216.803 489.005 198.347C481.943 168.632 470.346 139.323 454.44 114.234C450.762 108.548 446.949 103.066 442.842 97.7859C413.015 59.317 367.8 37.9278 321.524 27.91C269.992 16.7416 215.165 20.0132 165.64 38.3339C93.9595 64.8899 45.157 123.078 29.2504 197.918C23.136 226.279 21.3536 258.318 24.0836 289.093H23.8806C25.46 306.579 28.6187 323.658 33.3568 339.362C50.9555 397.55 93.7565 443.713 148.245 469.525C177.756 483.536 210.743 490.508 244.27 492.087C278.52 493.666 313.311 489.357 345.463 480.49C347.561 479.971 349.569 479.429 351.69 478.798C376.87 471.42 398.801 460.026 417.572 444.954C438.014 428.619 454.891 408.065 467.954 382.862C470.797 377.38 473.324 371.897 475.761 366.324C491.149 330.585 498.008 291.8 496.631 253.331L496.744 253.219L496.699 253.264Z'
            fill='white'
          />
        </Mask>
        <G mask='url(#mask0_3456_28212)'>
          <Path
            d='M232.129 134.927C232.129 134.927 187.298 194.041 197.474 204.216C207.649 214.392 228.046 185.873 228.046 185.873L274.93 124.729L232.129 134.927Z'
            fill='#DA9460'
          />
          <Path
            d='M405.356 194.016C399.896 161.301 276.953 108.414 248.186 133.369C248.186 133.369 199.947 201.958 192.795 242.661C241.62 232.26 301.049 234.854 318.31 257.394C327.402 269.262 323.86 299.225 317.813 325.397C327.718 326.819 336.202 326.706 342.181 324.45C374.784 312.221 409.439 218.474 405.356 194.016Z'
            fill='#3BA5B5'
          />
          <Path
            d='M408.442 16.7186C408.442 16.7186 286.085 72.7637 259.597 93.1376C233.109 113.511 176.048 184.854 192.339 190.968C208.651 197.083 226.994 152.251 257.567 140.022C288.139 127.793 306.482 133.908 304.451 137.992C302.421 142.076 269.795 174.678 279.994 184.876C290.192 195.075 337.054 148.19 351.314 137.992C365.573 127.794 430.801 87.0457 479.716 84.9926C528.632 82.9619 465.592 3.47449 408.464 16.7186H408.442Z'
            fill='#EFB08A'
          />
          <Path
            d='M255.678 403.121C258.566 403.933 308.699 386.064 315.22 396.014C321.74 405.964 295.252 416.162 258.566 428.391C221.879 440.62 152.59 448.765 152.59 448.765C152.59 448.765 238.485 398.248 255.655 403.121H255.678Z'
            fill='#674826'
          />
          <Path
            d='M193.356 261.274C191.506 256.332 191.483 249.947 192.747 242.682C158.858 249.902 130.068 263.349 124.089 280.745C109.469 323.162 211.767 405.899 215.941 408.02C220.115 410.14 297.369 380.448 303.618 369.844C306.416 365.106 312.892 346.492 317.742 325.419C273.61 319.056 200.959 281.67 193.333 261.296L193.356 261.274Z'
            fill='#F69170'
          />
          <Path
            d='M318.295 257.39C301.035 234.85 241.606 232.278 192.781 242.657C191.494 249.922 191.517 256.307 193.39 261.248C201.038 281.622 273.667 319.008 317.799 325.371C323.823 299.198 327.388 269.258 318.295 257.368V257.39Z'
            fill='#FFD2C4'
          />
          <Path
            d='M-26.7338 389.673C-25.1093 387.304 52.7537 389.673 122.043 367.246C191.332 344.819 207.645 324.445 221.904 332.59C236.164 340.735 187.135 384.123 187.135 384.123C187.135 384.123 210.803 389.515 224.499 387.552C238.194 385.59 305.475 353.19 312.605 360.207C319.735 367.224 315.651 381.506 258.591 418.192C201.53 454.879 75.1581 473.222 64.9825 475.253C54.7843 477.283 -79.7104 467.108 -26.7338 389.651V389.673Z'
            fill='#A2633E'
          />
        </G>
      </G>
      <Defs>
        <ClipPath id='clip0_3456_28212'>
          <Rect width={519} height={519} fill='white' transform='translate(0.599609 0.000488281)' />
        </ClipPath>
      </Defs>
    </Svg>
  )
}
