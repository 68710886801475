import { createReducer } from '@reduxjs/toolkit'

import { HIDE_ADDPRACTICE_MODAL, SHOW_ADDPRACTICE_MODAL } from '../../../common/constants/reduxConstants'

type PracticesDashboardState = {
  showModal: boolean
}

const initialState = {
  showModal: false,
}

export default createReducer<PracticesDashboardState>(initialState, (builder) => {
  builder.addCase(SHOW_ADDPRACTICE_MODAL, (state) => {
    state.showModal = true
    return state
  })
  builder.addCase(HIDE_ADDPRACTICE_MODAL, (state) => {
    state.showModal = false
    return state
  })
})
