import React, { FunctionComponent } from 'react'
import { Path, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { tID } from '../../utils/utils'

/**
 * Icon with open or closed eye used in PasswordField
 */
export const EyeIcon: FunctionComponent<EyeIconProps> = ({ size = 24, color, isOpen = false }) => {
  const { colors } = useTheme()
  const { fill } = colors.icons.eyeIcon

  return isOpen ? (
    <Svg width={size} height={size} fill='none' testID={tID('EyeIcon-open')}>
      <Path
        stroke={color || fill}
        d='M4.15 12.26a.49.49 0 0 1 0-.52C4.82 10.65 7.66 6.5 12 6.5s7.17 4.14 7.85 5.24a.49.49 0 0 1 0 .52c-.68 1.1-3.51 5.24-7.85 5.24s-7.18-4.15-7.85-5.24Z'
      />
      <Path fill={color || fill} d='M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z' />
    </Svg>
  ) : (
    <Svg width={size} height={size} fill='none' testID={tID('EyeIcon-closed')}>
      <Path stroke={color || fill} d='M4 8s3 5.5 8 5.5S20 8 20 8M4 13l2-2M9 16l.69-2.42M20 13l-2-2M15 16l-.69-2.42' />
    </Svg>
  )
}

type EyeIconProps = {
  size?: string | number
  color?: string
  isOpen?: boolean
}
