import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { View, ViewStyle } from 'react-native'

import { appleCustomerCodeFieldLabel } from '@lyrahealth-inc/shared-app-logic'

import { AppleEmployeeIdDisclaimer } from '../../../molecules/appleEmployeeIdDisclaimer/AppleEmployeeIdDisclaimer'
import { BodyTextSize } from '../../../styles/typeStyles'
import { BodyText } from '../../bodyText/BodyText'

export interface AppleEmployeeIdSublabelProps {
  text?: React.ReactNode
  textSize?: BodyTextSize.SMALL | BodyTextSize.DEFAULT
  style?: ViewStyle
}
export const AppleEmployeeIdSublabel: FunctionComponent<AppleEmployeeIdSublabelProps> = ({
  text,
  textSize = BodyTextSize.SMALL,
  style,
}) => {
  return (
    <View style={{ marginVertical: 4, ...style }}>
      <AppleEmployeeIdDisclaimer textSize={textSize} />
      {text ?? <BodyText text={<FormattedMessage {...appleCustomerCodeFieldLabel.sublabel} />} size={textSize} />}
    </View>
  )
}
