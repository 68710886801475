import { createSelector } from '@reduxjs/toolkit'

import { getPracticesState } from '../../data/practicesSelectors'

export const getPracticeDetails = createSelector(getPracticesState, (practicesState) => practicesState.practiceDetails)

export const getPracticeDetailsAddProviderModalShown = createSelector(
  getPracticeDetails,
  (practiceDetailsState) => practiceDetailsState.addProviderModalShown,
)

export const getPracticeDetailsShowModal = createSelector(
  getPracticeDetails,
  (practiceDetailsState) => practiceDetailsState.showModal,
)

export const getPracticeDetailsModalData = createSelector(
  getPracticeDetails,
  (practiceDetailsState) => practiceDetailsState.modalData,
)

export const getPracticeDetailsData = createSelector(
  getPracticeDetails,
  (practiceDetailsState) => practiceDetailsState.data,
)

export const getPracticeDetailsDataId = createSelector(
  getPracticeDetailsData,
  (practiceDetailsState) => practiceDetailsState.id,
)

export const getPracticeDetailsProviders = createSelector(
  getPracticeDetails,
  (practiceDetailsState) => practiceDetailsState.providers,
)

export const getPracticeDetailsPaymentHistory = createSelector(
  getPracticeDetails,
  (practiceDetailsState) => practiceDetailsState.paymentHistory,
)
