import { createSelector } from '@reduxjs/toolkit'

import { getPracticesState } from './practicesSelectors'

export const getPracticesData = createSelector(getPracticesState, (practicesState) => practicesState.data)

export const getPracticesDataPracticesList = createSelector(getPracticesData, (dataState) => dataState.practicesList)

export const getPracticesDataAvailablePractices = createSelector(
  getPracticesState,
  (dataState) => dataState.availablePractices,
)

export const getPracticesDataIsLoadingAvailablePractices = createSelector(
  getPracticesState,
  (dataState) => dataState.isLoadingAvailablePractices,
)
