import { useCallback, useEffect, useMemo, useState } from 'react'

import ZoomVideo, { ConnectionChangePayload, ConnectionState, Stream } from './ZoomVideo'

export const useZoomStream: () => typeof Stream | null = () => {
  const client = useMemo(() => ZoomVideo.createClient(), [])
  const [stream, setStream] = useState<typeof Stream | null>(null)

  const onConnectionChange = useCallback(
    (payload: ConnectionChangePayload) => {
      switch (payload.state) {
        case ConnectionState.Connected:
          const stream = client.getMediaStream()
          setStream(stream)
          break
        case ConnectionState.Closed:
          setStream(null)
      }
    },
    [client],
  )

  useEffect(() => {
    if (client.getCurrentUserInfo()?.userId) {
      setStream(client.getMediaStream())
    }
    client.on('connection-change', onConnectionChange)
    return () => client.off('connection-change', onConnectionChange)
  }, [client, onConnectionChange])

  return stream
}
