import React, { ReactElement } from 'react'
import { useIntl } from 'react-intl'

import { InlineWarningBanner } from '../inlineWarningBanner/InlineWarningBanner'

export const EligibilityErrorBanner = () => {
  const { formatMessage } = useIntl()

  return (
    <InlineWarningBanner
      text={formatMessage(
        {
          defaultMessage:
            'This client’s eligibility cannot be confirmed. Please ask your client to confirm that their legal name and DOB are entered correctly in their profile. If more support is needed, direct them to the Lyra Care Navigation Team. <b>Unless clinically-necessary to continue care, please do not schedule further sessions.</b>',
          description:
            'Banner describing that client does not have confirmed elibility for care and that further sessions should not be booked with them',
        },
        { b: (chunks: ReactElement) => <b>{chunks}</b> },
      )}
      testId='EligibilityErrorBanner'
    />
  )
}
