import { defineMessage, defineMessages, IntlShape } from 'react-intl'

import { ELIGIBILITY_TYPES, EligibilityInfo, EligibilityType } from '../common/constants/constants'

export const WalmartMessages = defineMessages({
  requireEligibilitySubheaderText: {
    defaultMessage:
      'To continue using Lyra, you need to add a WIN number to your profile. Your access to providers and services will be paused until you make this update.',
    description: 'Subheader text of form to require eligibility information to be confirmed',
  },
  confirmEligibilitySuccessMessage: {
    defaultMessage:
      'You’re confirmed! Your Lyra code is {lyraCode}. If your provider requests this information, you can always find this code in your profile.',
    description: 'Banner text for informing user their Lyra Code when eligibility is confirmed successfully',
  },
})

export interface EligibilityErrorMessageOptions {
  formatMessage: IntlShape['formatMessage']
  eligibilityType: EligibilityType | ELIGIBILITY_TYPES | undefined
  eligibilityInfo: EligibilityInfo
  customEmployeeEligibilityError?: React.ReactNode
  isHealthPlanDirect?: boolean
}

export function getEligibilityErrorMessage({
  formatMessage,
  eligibilityInfo,
  eligibilityType,
  customEmployeeEligibilityError,
  isHealthPlanDirect = false,
}: EligibilityErrorMessageOptions): React.ReactNode {
  if (eligibilityType === ELIGIBILITY_TYPES.EMPLOYEE && customEmployeeEligibilityError !== undefined) {
    return customEmployeeEligibilityError
  }
  if (
    eligibilityType === ELIGIBILITY_TYPES.DEPENDENT &&
    !eligibilityInfo.checkAdultDependentEligibilityUsingDependentInfo
  ) {
    if (eligibilityInfo.hasBlockingEligibilityCheck) {
      return formatMessage(
        {
          defaultMessage:
            'It looks like the name of the {employeeType, select, associate {associate} member {member} partner {partner} teammate {teammate} employee {employee} other {employee}} isn’t currently in our system. Check their information with the organization that grants them access to Lyra to make sure their name and date of birth match the information you shared.',
          description: 'Registration tip for continuing with hard eligibility error for dependent',
        },
        { employeeType: eligibilityInfo.employeeRegistrationNomenclature },
      )
    } else {
      return formatMessage(
        {
          defaultMessage:
            'Enter the version of the {employeeType, select, associate {associate} member {member} partner {partner} teammate {teammate} employee {employee} other {employee}}’s name that they used when signing up for their employer benefits. If you’re still having trouble, you can contact our care navigator team or continue and confirm later.',
          description: 'Registration tip for continuing with soft eligibility error for dependent',
        },
        { employeeType: eligibilityInfo.employeeRegistrationNomenclature },
      )
    }
  } else if (
    eligibilityInfo.hasBlockingEligibilityCheck ||
    eligibilityInfo.checkAdultDependentEligibilityUsingDependentInfo
  ) {
    return formatMessage({
      defaultMessage:
        'It looks like your name isn’t currently in our system. Check your information with the organization that grants you access to Lyra to make sure your name and date of birth match the information you shared.',
      description: `Error message when eligibility cannot be confirmed because user is found ineligible based on their name`,
    })
  } else if (isHealthPlanDirect) {
    return formatMessage({
      defaultMessage:
        'Your name and date of birth must exactly match the information on file with your sponsor. If you’re unsure, please contact a Lyra Care Navigator and they can help determine the problem.',
      description: `Error message when eligibility cannot be confirmed because user is found ineligible based on their name and date of birth`,
    })
  } else {
    return formatMessage({
      defaultMessage:
        'Your name and date of birth must exactly match the information on file with your employer. If you’re not sure what’s wrong you can continue and confirm eligibility later.',
      description: `Error message when eligibility cannot be confirmed because user is found ineligible based on their name and date of birth`,
    })
  }
}

export const confirmEligibilitySuccessMessage = defineMessage({
  defaultMessage: 'Success! Your coverage for the Lyra benefit is confirmed.',
  description: 'Success message confirming the user’s coverage eligibility for Lyra',
})

export const starbucksCustomerCodeFieldLabel = 'Starbucks Partner Number' // SBUX supports English only

export const walmartCustomerCodeFieldLabel = defineMessage({
  defaultMessage: 'WIN number',
  description: 'Label for input box to enter Walmart Identification Number (WIN) number',
})

export const appleCustomerCodeFieldLabel = defineMessages({
  label: {
    defaultMessage: 'Apple employee ID',
    description: 'Label for input box to enter Employee ID from Apple users',
  },
  sublabel: {
    defaultMessage: 'Any team member can find their employee ID on their Workday profile.',
    description:
      'Sub-label for input box to enter Employee ID from Apple users, informing user on how to find their ID',
  },
})

export const starbucksEligibilityFormHeader = 'Let’s confirm your eligibility' // SBUX supports English only

export const registrationEligibilityFormHeader = defineMessage({
  defaultMessage: 'Let’s confirm your eligibility',
  description: 'Subheader text for the eligibility information section of the registration form',
})

export const eligibilityDelayNotice = defineMessage({
  defaultMessage:
    'If you recently changed jobs, there may be a delay in processing your Lyra benefit. Please <contactCNTLink>contact our care navigator team</contactCNTLink> if you need help.',
  description:
    'Subtitle text for the form to confirm user employment. Informs the user of the reason for a potential delay in processing their Lyra benefit.',
})

export const eligibilityDelayNoticeForApple = defineMessage({
  defaultMessage:
    'Any team member can find their employee ID on their Workday profile. If you recently changed jobs, there may be a delay in processing your Lyra benefit. Please <contactCNTLink>contact our care navigator team</contactCNTLink> if you need help.',
  description:
    'Subtitle text for the form to confirm user employment. Informs the user of the reason for a potential delay in processing their Lyra benefit and where they can find their employee ID.',
})
