import React, { FunctionComponent } from 'react'
import { ViewStyle } from 'react-native'
import { Path, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

export enum ArrowIconDirection {
  LEFT = 'left',
  RIGHT = 'right',
  UP = 'up',
  DOWN = 'down',
}

/**
 * Arrow Icon that can be flipped in any direction, default is right
 */
export const ArrowIcon: FunctionComponent<ArrowIconProps> = ({
  size = 24,
  fillColor,
  direction = ArrowIconDirection.RIGHT,
  isFilled,
  style,
  testID,
}) => {
  const { colors } = useTheme()
  const { fill } = colors.icons.arrowIcon

  let rotate

  switch (direction) {
    case 'down':
      rotate = 'rotate(90 12 12)'
      break
    case 'left':
      rotate = 'rotate(180 12 12)'
      break
    case 'up':
      rotate = 'rotate(-90 12 12)'
      break
    default:
      rotate = 'rotate(0)'
  }

  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' testID={testID} style={style}>
      {isFilled ? (
        <Path
          fill={fillColor || fill}
          transform={rotate}
          d='M4,13.25 C3.30964406,13.25 2.75,12.690356 2.75,12 C2.75,11.309644 3.30964406,10.75 4,10.75 L17.0730123,10.75 L12.8748108,6.3643787 C12.3974276,5.86568376 12.4147042,5.0744161 12.9133991,4.59703299 C13.412094,4.11964979 14.2033616,4.13692638 14.6807448,4.6356213 L20.902967,11.1356213 C21.3656777,11.6189886 21.3656777,12.3810114 20.902967,12.8643787 L14.6807448,19.3643786 C14.2033617,19.8630736 13.412094,19.8803502 12.9133992,19.402967 C12.4147042,18.9255839 12.3974276,18.1343162 12.8748108,17.6356213 L17.0730123,13.25 L4,13.25 Z'
        />
      ) : (
        <Path
          fill={fillColor || fill}
          transform={rotate}
          d='M18.2438074,12.75 L4,12.75 C3.58578644,12.75 3.25,12.4142136 3.25,12 C3.25,11.5857864 3.58578644,11.25 4,11.25 L18.2438074,11.25 L13.2359976,6.01862722 C12.9495677,5.71941027 12.9599336,5.24464968 13.2591506,4.95821978 C13.5583675,4.67178988 14.0331281,4.68215583 14.319558,4.98137278 L20.5417802,11.4813728 C20.8194066,11.7713932 20.8194066,12.2286068 20.5417802,12.5186272 L14.319558,19.0186272 C14.0331281,19.3178442 13.5583675,19.3282101 13.2591506,19.0417802 C12.9599336,18.7553503 12.9495677,18.2805897 13.2359976,17.9813728 L18.2438074,12.75 Z'
        />
      )}
    </Svg>
  )
}

type ArrowIconProps = {
  direction?: ArrowIconDirection
  size?: number
  fillColor?: string
  isFilled?: boolean
  style?: ViewStyle
  testID?: string
}
