import React from 'react'
import classNames from 'classnames'
import styles from './navButton.module.scss'
import ChevronIcon from '../../icons/ChevronIcon'

const NavButton: React.FC<NavButtonProps> = ({
  text = 'Back',
  isSelected,
  href,
  styleType = 'back',
  customClass = '',
  customSelectedClass = '',
  ...props
}) => {
  const style = styleType
  let backIcon = null

  if (styleType === 'back') {
    backIcon = <ChevronIcon isFilled direction='left' />
  }

  if (href) {
    const classes = classNames(styles['nav-button'], styles.isAnchor, styles[`${style}`], customClass, {
      [styles.selected]: isSelected,
      [customSelectedClass]: isSelected,
    })
    return (
      <a href={href} className={classes} {...props}>
        {backIcon}
        {text}
      </a>
    )
  } else {
    const classes = classNames(
      styles['nav-button'],
      {
        [styles.tab]: styleType === 'dashlet',
      },
      {
        [styles.dashlet]: styleType === 'dashlet',
      },
      styles[`${style}`],
      customClass,
      {
        [styles.selected]: isSelected,
        [customSelectedClass]: isSelected,
      },
      {
        [styles.muted]: styleType === 'dashlet' && !isSelected,
      },
    )
    return (
      <button className={classes} data-test-id={'NavButton-press'} {...props}>
        {backIcon}
        {text}
      </button>
    )
  }
}

type NavButtonProps = {
  text?: string
  styleType?: string
  isSelected?: boolean
  href?: string
  customClass?: string
  customSelectedClass?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>
}

NavButton.defaultProps = {
  text: 'Back',
  styleType: 'back',
}

export default NavButton
