import React from 'react'
import { FormRenderProps } from 'react-final-form'
import { FormattedMessage, useIntl } from 'react-intl'

import { css } from 'styled-components/native'

import { FieldSchema } from '@lyrahealth-inc/shared-app-logic'
import { FormBody, PrimaryButton, tID } from '@lyrahealth-inc/ui-core-crossplatform'

import { eligibilityFormMetadata } from './eligibilityFormMetadata'

export interface EligibilityFormProps {
  customers: string[]
  onSubmit({ values }: { values: Dict }): void
  onFormChange?({ values }: { values: Dict }): void
}

export const EligibilityForm: React.FC<EligibilityFormProps> = ({ customers, onSubmit, onFormChange }) => {
  const intl = useIntl()
  const content = eligibilityFormMetadata(customers)
  const { schema, uiSchema } = content ?? {}

  const FormButton = ({ handleSubmit }: { handleSubmit: FormRenderProps['handleSubmit'] }) => {
    return (
      <PrimaryButton
        text={<FormattedMessage defaultMessage='Check Coverage' description='Button to check coverage' />}
        onPress={() => {
          handleSubmit()
        }}
        testID={tID('CheckCoverage-Button')}
        accessibilityLabel={intl.formatMessage({
          defaultMessage: 'Check Coverage',
          description: 'Button to check coverage',
        })}
        fullWidth
      />
    )
  }

  return (
    <FormBody
      name='eligibityForm'
      schema={schema as FieldSchema}
      uiSchema={uiSchema}
      saveForm={onSubmit}
      onFormChange={onFormChange}
      intl={intl}
      useMultiSelectTypeAhead
      scrollContainerCustomStyles={{
        formBodyPageContainer: css`
          max-width: 100%;
        `,
        scrollContainerContentCustomStyles: {
          padding: 0,
        },
      }}
      formBodyCustomStyles={{
        submitButtonWrapper: css`
          align-self: end;
          max-width: 180px;
        `,
        submitButtonContainer: css`
          border: none;
          box-shadow: none;
          border-top-width: 0;
          padding: 0;
        `,
      }}
      formButton={FormButton}
    />
  )
}

export default EligibilityForm
