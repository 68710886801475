import {
  AlcoholUseDisorderTherapyIcon,
  BlendedCareTherapyIcon,
  CoachingIcon,
  SingleSessionCoachingIcon,
} from '@lyrahealth-inc/ui-core'
import { BlendedCareMedicineIcon, ClinicalLeaveEvaluationIcon } from '@lyrahealth-inc/ui-core-crossplatform'

export const programIconMap = {
  AlcoholUseDisorderTherapyIcon,
  BlendedCareTherapyIcon,
  CoachingIcon,
  SingleSessionCoachingIcon,
  BlendedCareMedicineIcon,
  ClinicalLeaveEvaluationIcon,
}
