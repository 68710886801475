import React from 'react'
import classNames from 'classnames'
import styles from './cardFullWidth.module.scss'

type OwnProps = {
  children: $TSFixMe
  roundCorners?: boolean
  withShadow?: boolean
  className?: string
}

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof CardFullWidth.defaultProps

// @ts-expect-error TS(7022): 'CardFullWidth' implicitly has type 'any' because ... Remove this comment to see the full error message
const CardFullWidth = ({ roundCorners, children, withShadow, className, ...props }: Props) => {
  return (
    <div
      className={classNames(className, styles.card, { [styles.round]: roundCorners }, { [styles.shadow]: withShadow })}
      {...props}
    >
      {children}
    </div>
  )
}

CardFullWidth.defaultProps = {
  withShadow: false,
}

export default CardFullWidth
