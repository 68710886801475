import { useEffect, useState } from 'react'

import { ClientObject, convertObjToFormData } from '@lyrahealth-inc/shared-app-logic'

type eligibilityResponse = {
  message: string
  user_eligibility: {
    code: number
    error: string
    status: boolean
  }
}

const ELIGIBILITY_SERVICE_CONNECTION_ERROR = 'Unknown - RTE Service Error'

const useFetchClientEligibility = ({
  clientDetails,
  checkEligibility,
  shouldFetchEligibility,
  programName,
}: {
  clientDetails: ClientObject | null
  checkEligibility: (params: FormData) => Promise<eligibilityResponse>
  shouldFetchEligibility: boolean
  programName?: string
}) => {
  const [hasFetchedEligibility, setHasFetchedEligibility] = useState(false)
  const [eligibilityError, setEligibilityError] = useState(false)

  useEffect(() => {
    if (!clientDetails?.id) {
      return
    }

    if (shouldFetchEligibility && !hasFetchedEligibility) {
      const isoCurrentDate = new Date().toISOString()
      checkEligibility &&
        checkEligibility(
          convertObjToFormData({
            first_name: clientDetails.first_name,
            last_name: clientDetails.last_name,
            company: clientDetails.employer,
            visit_date: isoCurrentDate.substring(0, isoCurrentDate.indexOf('T')),
            is_bcx: true,
            patient_lyra_id: clientDetails.id,
            program_name: programName,
          }),
        ).then((response: eligibilityResponse) => {
          setHasFetchedEligibility(true)
          if (
            !response?.user_eligibility?.status &&
            response?.user_eligibility?.error !== ELIGIBILITY_SERVICE_CONNECTION_ERROR
          ) {
            setEligibilityError(true)
          }
        })
    }
  }, [
    shouldFetchEligibility,
    checkEligibility,
    hasFetchedEligibility,
    clientDetails?.first_name,
    clientDetails?.last_name,
    clientDetails?.employer,
    clientDetails?.id,
    programName,
  ])
  return eligibilityError
}

export default useFetchClientEligibility
