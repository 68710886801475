import { IS_WEB } from '../constants'

export const useGetIsMobileWebBrowser = () => {
  let isMobileWebBrowser = false
  if (IS_WEB) {
    const mobileKeywords = ['Mobile', 'iPhone', 'iPad', 'Android']
    const userAgent = navigator.userAgent
    isMobileWebBrowser = mobileKeywords.some((keyword) => userAgent.includes(keyword))
  }
  return isMobileWebBrowser
}
