import React, { FunctionComponent } from 'react'

import styled from 'styled-components/native'

import careNavigatorCalendarIconIllustrationImage from '../../assets/care_navigator_calendar_icon_illustration.png'
import { tID } from '../../utils'
import { Image } from '../image/Image'

const ImageContainer = styled(Image)<{ size: string }>(({ size }) => ({
  width: size,
  height: size,
}))

export const CareNavigatorCalendarIconIllustration: FunctionComponent<CareNavigatorCalendarIconIllustrationProps> = ({
  size = 48,
}) => {
  const sizeInPixels = `${size}px`
  return (
    <ImageContainer
      size={sizeInPixels}
      testID={`${tID('LiveChatIllustrationIcon')}`}
      source={careNavigatorCalendarIconIllustrationImage}
    />
  )
}

type CareNavigatorCalendarIconIllustrationProps = {
  size?: number
}
