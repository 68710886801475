export enum OSs {
  UNKNOWN = 'UNKNOWN',
  MAC = 'Mac OS',
  WINDOWS = 'Windows',
  IOS = 'iOS',
  ANDROID = 'Android',
  LINUX = 'Linux',
}

// function to return operating system that the user is using or return unknown if operating system could not be determined
export const useOS = () => {
  const { userAgent, platform } = window.navigator
  let os = OSs.UNKNOWN
  const osMap = {
    [OSs.MAC]: ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'darwin'].indexOf(platform) !== -1,
    [OSs.WINDOWS]: ['Win32', 'Win64', 'Windows', 'WinCE'].indexOf(platform) !== -1,
    [OSs.IOS]: ['iPhone', 'iPad', 'iPod'].indexOf(platform) !== -1,
    [OSs.ANDROID]: /Android/.test(userAgent),
    [OSs.LINUX]: /Linux/.test(platform), // there are multiple linux versions
  }
  for (const [key, value] of Object.entries(osMap)) {
    if (value) {
      os = key as OSs
      break
    }
  }
  return os
}
