import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { tID } from '../../utils'

/**
 * Alert Quiet Icon - circle icon with an exclaimation mark to show medium urgency
 */
export const AlertQuietIcon: FunctionComponent<AlertQuietIconProps> = ({ size = 15, fillColor }) => {
  const { colors } = useTheme()

  return (
    <Svg width={size} height={size} viewBox='0 0 16 16' fill='none' testID={tID('AlertQuietIcon')}>
      <Path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8 2a6 6 0 1 0 0 12A6 6 0 0 0 8 2ZM8 .5a7.5 7.5 0 1 0 0 15 7.5 7.5 0 0 0 0-15Z'
        fill={fillColor || colors.iconError}
      />
      <Path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8 8.833a.75.75 0 0 1-.75-.75V5.417a.75.75 0 0 1 1.5 0v2.666a.75.75 0 0 1-.75.75ZM8.75 10.75a.75.75 0 0 1-.75.75h-.007a.75.75 0 0 1 0-1.5H8a.75.75 0 0 1 .75.75Z'
        fill={fillColor || colors.iconError}
      />
    </Svg>
  )
}

type AlertQuietIconProps = {
  size?: string | number
  fillColor?: string
}
