import React, { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'

import styled from 'styled-components/native'

import {
  BodyText,
  BodyTextSize,
  PrimaryButton,
  Subhead,
  SubheadSize,
  TertiaryButton,
  ThemeType,
  tID,
} from '@lyrahealth-inc/ui-core-crossplatform'

type ConfirmSessionForAssignmentProps = {
  appointmentDate: string
  assignContent: (session: number) => void
  selectedSession: number
  showNextSessionOption: boolean
}

const ModalContent = styled.View<{ theme: ThemeType }>(({ theme: { breakpoints, spacing } }) => ({
  ...(!breakpoints.isMinWidthLaptop && { padding: `0px ${spacing['16px']} ${spacing['32px']}` }),
}))

const HeaderContainer = styled.View<{ theme: ThemeType }>(({ theme: { breakpoints, spacing } }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: spacing['16px'],
  ...(!breakpoints.isMinWidthLaptop && { marginTop: spacing['32px'] }),
}))

const ButtonsContainer = styled.View<{ theme: ThemeType }>(({ theme: { breakpoints, spacing } }) => ({
  flexDirection: breakpoints.isMinWidthLaptop ? 'row' : 'column-reverse',
  justifyContent: 'flex-end',
  marginTop: breakpoints.isMinWidthLaptop ? spacing['32px'] : spacing['64px'],
}))

const PrimaryButtonContainer = styled.View<{ theme: ThemeType }>(({ theme: { breakpoints, spacing } }) => ({
  ...(breakpoints.isMinWidthLaptop && { marginLeft: spacing['16px'] }),
}))

const TertiaryButtonContainer = styled.View<{ theme: ThemeType }>(({ theme: { breakpoints, spacing } }) => ({
  ...(!breakpoints.isMinWidthLaptop && { marginTop: spacing['8px'] }),
}))

const ConfirmSessionForAssignment: FunctionComponent<ConfirmSessionForAssignmentProps> = ({
  appointmentDate,
  assignContent,
  selectedSession,
  showNextSessionOption,
}) => {
  const { formatMessage } = useIntl()

  return (
    <ModalContent testID={tID('ConfirmSessionForAssignment-container')}>
      <HeaderContainer>
        <Subhead
          size={SubheadSize.MEDIUM}
          text={formatMessage(
            {
              defaultMessage: 'Add to session {selectedSession}?',
              description: 'Title asking if the activity should be added to the session',
            },
            { selectedSession },
          )}
        />
      </HeaderContainer>
      <BodyText
        size={BodyTextSize.DEFAULT}
        text={formatMessage(
          {
            defaultMessage:
              'Session {selectedSession} is scheduled for {appointmentDate}, which means that the client may not see this in their current activities after the session is closed.',
            description:
              'Warning that states the client may not see the activity because the session has already ocurred',
          },
          {
            appointmentDate,
            selectedSession,
          },
        )}
      />
      <ButtonsContainer>
        {showNextSessionOption && (
          <TertiaryButtonContainer>
            <TertiaryButton
              onPress={() => assignContent(selectedSession + 1)}
              testID={tID('ConfirmSessionForAssignment-next')}
              text={formatMessage({
                defaultMessage: 'No, add to next session',
                description: 'Button label that will add the activity to the next session',
              })}
            />
          </TertiaryButtonContainer>
        )}
        <PrimaryButtonContainer>
          <PrimaryButton
            onPress={() => assignContent(selectedSession)}
            testID={tID('ConfirmSessionForAssignment-confirm')}
            text={formatMessage({
              defaultMessage: 'Yes',
              description: 'Button label to confirm the action',
            })}
          />
        </PrimaryButtonContainer>
      </ButtonsContainer>
    </ModalContent>
  )
}

export default ConfirmSessionForAssignment
