import React from 'react'
import Svg, { Path } from 'react-native-svg'

export const GraduationIcon: React.FC<GraduationIconProps> = ({ width = 36 }) => {
  return (
    <Svg width={width} viewBox='0 0 36 36'>
      <g fill='none' fillRule='evenodd'>
        <circle cx='18' cy='18' r='18' fill='#D6E3EB' fillRule='nonzero' />
        <g id='school-24px-(1)' transform='translate(6.000000, 6.000000)'>
          <polygon id='Path' points='0 0 24 0 24 24 0 24' />
          <Path
            d='M5,13.18 L5,15.99 C5,16.72 5.4,17.4 6.04,17.75 L11.04,20.48 C11.64,20.81 12.36,20.81 12.96,20.48 L17.96,17.75 C18.6,17.4 19,16.72 19,15.99 L19,13.18 L12.96,16.48 C12.36,16.81 11.64,16.81 11.04,16.48 L5,13.18 Z M11.04,3.52 L2.61,8.12 C1.92,8.5 1.92,9.5 2.61,9.88 L11.04,14.48 C11.64,14.81 12.36,14.81 12.96,14.48 L21,10.09 L21,16 C21,16.55 21.45,17 22,17 C22.55,17 23,16.55 23,16 L23,9.59 C23,9.22 22.8,8.89 22.48,8.71 L12.96,3.52 C12.36,3.2 11.64,3.2 11.04,3.52 Z'
            id='Shape'
            fill='#80A2B4'
            fillRule='nonzero'
          />
        </g>
      </g>
    </Svg>
  )
}

type GraduationIconProps = {
  width?: number | string
}
