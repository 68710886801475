import React from 'react'
import { convertUTCToISO8601format, Outcome } from '@lyrahealth-inc/shared-app-logic'
import { FormattedDate } from 'react-intl'

const CustomTick: React.FC<CustomTickProps> = ({ y = 0, height, width, textAnchor, payload, data }) => {
  const topMargin = 25

  const renderDescription = () => {
    if (payload?.value === data[data.length - 1]?.response_date) {
      return 'Baseline'
    } else if (payload?.value === data[0]?.response_date) {
      return 'Most Recent'
    }
    return ''
  }

  return (
    <text textAnchor={textAnchor} height={height} width={width} y={y + topMargin} x={payload?.coordinate}>
      <tspan x={payload?.coordinate}>
        <FormattedDate
          value={convertUTCToISO8601format(payload?.value || '')}
          year='numeric'
          month='2-digit'
          day='2-digit'
        />
      </tspan>
      <tspan x={payload?.coordinate} dy='20px'>
        {renderDescription()}
      </tspan>
    </text>
  )
}

type CustomTickProps = {
  y?: number
  height?: number
  width?: number
  textAnchor?: string
  payload?: Tick
  data: Outcome[]
}

type Tick = {
  coordinate: number
  isShow: boolean
  offset: number
  tickCoord: number
  value: string
}

export default CustomTick
