import { ActivityGroupType, Assignment, Content } from '../../models/assignments/Assignments'

/**
 * Types that are in features are data types created by shared-app-logic
 */
export enum ActivityGroupNames {
  'lesson' = 'Lessons',
  'exercise' = 'Exercises',
  'assessment' = 'Assessments',
  'infosheet' = 'Guides',
}

export enum ActivityGroupNamesCurrentActivities {
  'lesson' = 'Watch lessons',
  'exercise' = 'Complete exercises',
  'assessment' = 'Fill out assessments',
  'infosheet' = 'Read guides',
}

export enum ActivityGroupTypes {
  'lesson' = 'lesson',
  'exercise' = 'exercise',
  'assessment' = 'assessment',
  'infosheet' = 'infosheet',
}

export enum AssignmentStatuses {
  'in_progress' = 'in_progress',
  'new' = 'new',
  'completed' = 'completed',
  'draft' = 'draft',
  'missed' = 'missed',
  'provider_draft' = 'provider_draft',
}

export enum AssessmentStages {
  'initial' = 'initial',
  'ongoing' = 'ongoing',
  'pre_session' = 'pre_session',
  'post_session' = 'post_session',
  'end' = 'end',
  'post_episode' = 'post_episode',
}

export enum AssessmentSeverity {
  'none' = 'none',
  'ongoing' = 'ongoing',
  'severe' = 'severe',
  'moderate' = 'moderate',
}

const AssignmentStatusesLabel = new Map<AssignmentStatuses, string>([
  [AssignmentStatuses.in_progress, 'In progress'],
  [AssignmentStatuses.completed, 'Completed'],
  [AssignmentStatuses.new, 'Not started'],
  [AssignmentStatuses.missed, 'Missed'],
  [AssignmentStatuses.draft, 'In progress'],
  [AssignmentStatuses.provider_draft, 'In progress'],
])

/**
 * Helper function to get the assignment status label from the AssignmentStatusesLabel map
 */
export const getAssignmentStatuesLabel = (status: AssignmentStatuses) => {
  return AssignmentStatusesLabel.get(status) ?? ''
}

export interface ActivityResponseData {
  id: string
  submit_date: string | null
  update_date: string
  status: string
  response: { [key: string]: any }
  provider_started: boolean
}

export enum ActivityCompletionStatus {
  'in_progress' = 'In progress',
  'new' = 'Not started',
  'completed' = 'Completed',
}

export interface VideoLesson {
  assignmentId: string
  thumbnail: string
  videoPropertyName: string
  videoTitle: string
  time?: number | null
  timeFormatted?: string
  completionStatus: VideoStatuses
  url: string
  videoId: string
  videoProgress: number
  videoIndex: number
  description?: string
  content_type?: string
}

export enum VideoStatuses {
  'new' = 'new',
  'started' = 'started',
  'in_progress' = 'in_progress',
  'completed' = 'completed',
}

export const VideoStatusesLabel = new Map<VideoStatuses, string>([
  [VideoStatuses.new, 'Not started'],
  [VideoStatuses.started, 'In progress'],
  [VideoStatuses.in_progress, 'In progress'],
  [VideoStatuses.completed, 'Completed'],
])

/**
 * Helper function to get the video status label from the VideoStatusesLabel map
 */
export const getVideoStatusesLabel = (videoStatus: VideoStatuses) => {
  return VideoStatusesLabel.get(videoStatus) ?? ''
}

export interface Review {
  reviewPropertyName: string
  reviewTime?: number
  reviewCompletionStatus: VideoStatuses
}

export interface ActivityGroups {
  groups: {
    [key in ActivityGroupType]: Assignment[]
  }
}

export interface ActivityProgramGroups {
  programName: string
  providerId: string
  assignments: Assignment[]
}

export interface ActivitiesByProgram {
  [programId: string]: ActivityProgramGroups
}

export interface ActivitySessionGroups extends ActivityGroups {
  sessionEndDate: string
}

export interface ActivitiesByProvider {
  [providerId: string]: {
    providerName: string
    providerImage: string
    providerType: string
    sessions: {
      [sessionPeriod: number]: ActivitySessionGroups
    }
  }
}

export interface CurriculumPeriod {
  name: string
  week: number
  title: string
  session: number
  assignments: Assignment[]
  numberCompletedAssignments: number
  date: string | undefined
  active: boolean
}
export interface ActivitiesByCurriculum {
  periods: CurriculumPeriod[]
}

export interface Track {
  curriculumSchedule: TrackSession[]
  programId: string
  rank: number
  title: string
}

export interface TrackSession {
  contentOptions: { [key: string]: ContentOption }
  contents: Content[]
  name: string
  session: number
  title: string
}

interface ContentOption {
  contents: Content[]
  name: string
  title: string
}
