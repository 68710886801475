import React from 'react'
import classNames from 'classnames'
import styles from './paragraph.module.scss'

type Props = {
  isBold?: boolean
  isLarge?: boolean
  children: $TSFixMe
}

const Paragraph = ({ isBold = false, isLarge = false, children, ...rest }: Props) => {
  const classes = classNames(styles.paragraph, { [styles.strong]: isBold }, { [styles.large]: isLarge })
  return (
    <p className={classes} {...rest}>
      {children}
    </p>
  )
}

export default Paragraph
