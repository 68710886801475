import React, { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'

import styled from 'styled-components/native'

import { Theme } from '@lyrahealth-inc/shared-app-logic'

import { PrimaryButton } from '../../atoms'
import { RowView } from '../../templates'
import { ThemeProvider, tID } from '../../utils'
import { ZoomVideoBannerInfo } from '../zoomVideoBannerInfo/ZoomVideoBannerInfo'

export type ProviderJoinSessionBannerProps = {
  sessionStartDate: Date
  sessionDuration: number
  sessionStartsIn: string
  onPress: () => void
  isFetchingToken?: boolean
}

const Container = styled(RowView)(({ theme }) => ({
  alignItems: 'center',
  gap: theme.spacing['8px'],
  justifyContent: 'space-between',
  padding: theme.spacing['16px'],
  backgroundColor: theme.colors.components.zoomVideoSession.background,
  height: '90px',
}))

const JoinButton = styled(PrimaryButton)({
  alignSelf: 'center',
})

export const ProviderJoinSessionBanner: FunctionComponent<ProviderJoinSessionBannerProps> = ({
  sessionStartDate,
  sessionDuration,
  sessionStartsIn,
  onPress,
  isFetchingToken,
}) => {
  const intl = useIntl()
  const joinText =
    sessionStartsIn === 'now'
      ? intl.formatMessage({
          defaultMessage: 'Join (starts now)',
          description: 'Button to join session that is starting now',
        })
      : intl.formatMessage(
          {
            defaultMessage: 'Join (starts in {startsIn})',
            description: 'Button to join session with countdown',
          },
          {
            startsIn: sessionStartsIn,
          },
        )
  return (
    <ThemeProvider theme={Theme.DARK}>
      <Container testID={tID('ProviderJoinSessionBanner')}>
        <ZoomVideoBannerInfo sessionStartDate={sessionStartDate} sessionDuration={sessionDuration} />
        <JoinButton
          testID={tID('ProviderJoinSessionBanner-joinBtn')}
          text={joinText}
          onPress={onPress}
          disabled={isFetchingToken}
        />
      </Container>
    </ThemeProvider>
  )
}
