import {
  GET_ICD10S,
  SAVE_HEALTHPLAN_CHARGE,
  CLEAR_HEALTHPLAN_CHARGE,
  CLEAR_HP_ELIGIBILITY_STATUS,
} from '../common/constants/reduxConstants'

export const getICD10s = ({ cancelToken }: any) => ({
  action: GET_ICD10S,
  request: {
    method: 'get',
    url: '/v1/icd10',
    cancelToken,
  },
})

export const updateCharge = ({ chargeID, ...body }: any) => ({
  bodyType: 'formData',
  request: {
    method: 'put',
    url: `/v1/transfers/${chargeID}`,
    data: body,
  },
})

export const getHPEligibility = (data: any) => ({
  bodyType: 'formData',

  request: {
    method: 'post',
    url: '/v1/transfers/healthplan/eligible',
    data,
  },
})

export const clearHPEligibility = () => ({
  type: CLEAR_HP_ELIGIBILITY_STATUS,
})

export const clearHealthPlanCharge = (data: any) => ({
  type: CLEAR_HEALTHPLAN_CHARGE,
  data,
})

export const saveHealthPlanCharge = (data: any) => ({
  type: SAVE_HEALTHPLAN_CHARGE,
  data,
})
