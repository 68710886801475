/* eslint-disable formatjs/no-literal-string-in-jsx */

import React from 'react'
import { View } from 'react-native'

import { getQuarter } from 'date-fns'
import styled, { useTheme } from 'styled-components/native'

import { appointmentUtilization, historicUtilization, targetUtilization } from '@lyrahealth-inc/shared-app-logic'

import { ArrowIcon, ArrowIconDirection, BodyText } from '../../atoms'
import { AlertQuietIcon } from '../../atoms/icons/AlertQuietIcon'
import { ClockIcon } from '../../atoms/icons/ClockIcon'
import { ScheduleIcon } from '../../atoms/icons/ScheduleIcon'
import { Subhead, Size as SubheadSize } from '../../atoms/subhead/Subhead'
import { BodyTextSize } from '../../styles/typeStyles'
import { Flex1View } from '../../templates/content/CommonViews'
import { ThemeType, tID } from '../../utils'

const Header = styled(View)(({ theme }) => ({
  paddingBottom: theme.spacing['24px'],
  justifyContent: 'space-between',
  flexDirection: 'row',
}))

const SnapshotContainer = styled.View({
  width: '100%',
  minHeight: '98px',
  borderRadius: '16px',
  backgroundColor: '#fff',
  padding: '24px',
  shadowColor: '#000',
  shadowOffset: {
    width: '0px',
    height: '2px',
  },
  shadowOpacity: '0.1',
  shadowRadius: '10px',
})

const WeekHeader = styled.View(({ theme }) => ({
  paddingBottom: theme.spacing['24px'],
  flexDirection: 'row',
}))

const IconContainer = styled.View(({ theme }) => ({
  marginLeft: theme.spacing['16px'],
  marginRight: theme.spacing['8px'],
  marginTop: '2px',
}))

const WeeklySectionHeader = styled(Subhead)<{ theme: ThemeType }>(({ theme }) => ({
  justifyContent: 'space-between',
  flexDirection: 'row',
  marginTop: theme.spacing['8px'],
  color: theme.colors.textPrimary,
}))

const GraphContainer = styled.View({})

const Table = styled(Flex1View)<{ theme: ThemeType }>(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  borderBottomColor: theme.colors.borderDefault,
  borderBottomWidth: '1px',
}))

const Row = styled(Flex1View)<{ theme: ThemeType }>(({ theme }) => ({
  alignSelf: 'stretch',
  flexDirection: 'row',
  height: '64px',
  borderTopColor: theme.colors.borderDefault,
  borderTopWidth: '1px',
}))

const BarGraphLabelCell = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flex: 4,
  borderRightColor: theme.colors.borderDefault,
  borderRightWidth: '1px',
}))

const BarGraphCell = styled.View({
  flex: 20,
  display: 'inline-block',
})

const BarGraphBar = styled(View)<{ theme: ThemeType; width: string }>(({ theme, width }) => ({
  backgroundColor: theme.colors.backgroundActive,
  borderTopRightRadius: '5px',
  borderBottomRightRadius: '5px',
  marginTop: '16px',
  height: '32px',
  width,
}))

const BarGraphValue = styled.View<{ theme: ThemeType }>(({ theme: { colors } }) => ({
  flex: 1,
  backgroundColor: colors.backgroundSecondary,
}))

const BelowTargetFooter = styled.View(({ theme }) => ({
  flexDirection: 'row',
  marginTop: theme.spacing['24px'],
}))

const BelowTargetIcon = styled.View<{ theme: ThemeType }>(({ theme: { colors } }) => ({
  height: '30px',
  width: '30px',
  paddingLeft: '6px',
  paddingTop: '6px',
  backgroundColor: colors.backgroundWarning,
  borderRadius: '6px',
  marginRight: '20px',
}))

const QuarterTargetRow = styled.View({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
})

const QuarterlyTarget = styled.View({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '20px',
  marginLeft: 'auto',
})

export const CaseloadManagementQuarterlySnapshot: React.FC<CaseloadManagementQuarterlySnapshotProps> = ({
  appointmentUtilization,
  historicUtilization,
  targetUtilization,
  requestedQuarterName = '',
  showPastQuartersInLCCDashboard = false,
}) => {
  const { colors } = useTheme()

  const expectedSessionsToDate = historicUtilization
    .slice(0, appointmentUtilization?.currentWeekCount ?? 1)
    .reduce((sessionSum, currentWeek) => {
      return (
        sessionSum +
        Math.round(
          (targetUtilization?.providerExpectedWeekWorkHours -
            (currentWeek.providerPaidTimeOffHours ? parseFloat(currentWeek.providerPaidTimeOffHours) : 0)) *
            0.75,
        )
      )
    }, 0)
  const expectedSessionsForQuarter = historicUtilization.reduce((sessionSum, currentWeek) => {
    return (
      sessionSum +
      Math.round(
        (targetUtilization?.providerExpectedWeekWorkHours -
          (currentWeek.providerPaidTimeOffHours ? parseFloat(currentWeek.providerPaidTimeOffHours) : 0)) *
          0.75,
      )
    )
  }, 0)

  const creditedThisQuarter =
    appointmentUtilization?.completedThisQuarter + appointmentUtilization?.providerNoFaultThisQuarter

  const expectedSessionsDiscrepancy = expectedSessionsToDate - creditedThisQuarter

  const completedPercentage = (creditedThisQuarter / expectedSessionsForQuarter) * 100 + '%'
  const expectedPercentage = (expectedSessionsToDate / expectedSessionsForQuarter) * 100 + '%'
  return (
    <View testID={tID('CaseloadManagementQuarterlySnapshot-container')}>
      <Header testID={tID('CaseloadManagementQuarterlySnapshot-header')}>
        <WeeklySectionHeader size={SubheadSize.MEDIUM} text='Quarterly snapshot' />
      </Header>
      <SnapshotContainer>
        <WeekHeader>
          <Subhead size={SubheadSize.MEDIUM}>
            {requestedQuarterName?.length ? requestedQuarterName.split('_')[0] : `Q${getQuarter(new Date())}`} progress
          </Subhead>
          <IconContainer>
            <ScheduleIcon size={24} fillColor={colors.iconDefault} />
          </IconContainer>
          <BodyText
            size={BodyTextSize.DEFAULT}
            color={colors.textSecondary}
            testID={tID('CaseloadManagementQuarterlySnapshot-weeksRemaining')}
          >
            {appointmentUtilization?.totalWeekCount - appointmentUtilization?.currentWeekCount} weeks left in the
            quarter
          </BodyText>
          <IconContainer>
            <ClockIcon size={24} fillColor={colors.iconDefault} />
          </IconContainer>
          <BodyText
            size={BodyTextSize.DEFAULT}
            color={colors.textSecondary}
            testID={tID('CaseloadManagementQuarterlySnapshot-quarterPTO')}
          >
            {targetUtilization.quarterPaidTimeOff} hours of scheduled time off this quarter
          </BodyText>
        </WeekHeader>
        <GraphContainer>
          <Table>
            <Row>
              <BarGraphLabelCell>
                <BodyText size={BodyTextSize.DEFAULT} style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                  Credited sessions
                </BodyText>
              </BarGraphLabelCell>
              <BarGraphCell>
                <BarGraphBar width={completedPercentage} />
              </BarGraphCell>
              <BarGraphValue>
                <BodyText
                  size={BodyTextSize.DEFAULT}
                  style={{ margin: 'auto' }}
                  testID={tID(`CaseloadManagementQuarterlySnapshot-quarterCompletedSessions-${creditedThisQuarter}`)}
                >
                  {creditedThisQuarter}
                </BodyText>
              </BarGraphValue>
            </Row>
            <Row>
              <BarGraphLabelCell>
                <BodyText size={BodyTextSize.DEFAULT} style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                  Total expected sessions
                </BodyText>
              </BarGraphLabelCell>
              <BarGraphCell>
                <BarGraphBar width={expectedPercentage} />
              </BarGraphCell>
              <BarGraphValue>
                <BodyText
                  size={BodyTextSize.DEFAULT}
                  style={{ margin: 'auto' }}
                  testID={tID(`CaseloadManagementQuarterlySnapshot-quarterExpectedSessions-${expectedSessionsToDate}`)}
                >
                  {expectedSessionsToDate}
                </BodyText>
              </BarGraphValue>
            </Row>
          </Table>
        </GraphContainer>
        <QuarterTargetRow>
          {expectedSessionsDiscrepancy > 0 && (
            <BelowTargetFooter>
              <BelowTargetIcon>
                {showPastQuartersInLCCDashboard ? (
                  <AlertQuietIcon size={18} fillColor={colors.iconWarning} />
                ) : (
                  <ArrowIcon direction={ArrowIconDirection.DOWN} size={18} fillColor={colors.iconWarning} />
                )}
              </BelowTargetIcon>
              <BodyText
                text={`${expectedSessionsDiscrepancy} credited sessions needed to meet total expected sessions`}
                size={BodyTextSize.DEFAULT}
                color={colors.textPrimary}
                testID={tID('CaseloadManagementQuarterlySnapshot-trackingTowardsDeviation')}
              />
            </BelowTargetFooter>
          )}
          <QuarterlyTarget>
            <BodyText
              text={`${expectedSessionsForQuarter} Quarterly Target`}
              size={BodyTextSize.DEFAULT}
              color={colors.textPrimary}
              testID={tID('CaseloadManagementQuarterlySnapshot-quarterTarget')}
            />
          </QuarterlyTarget>
        </QuarterTargetRow>
      </SnapshotContainer>
    </View>
  )
}

export type CaseloadManagementQuarterlySnapshotProps = {
  appointmentUtilization: appointmentUtilization
  historicUtilization: historicUtilization
  targetUtilization: targetUtilization
  requestedQuarterName?: string
  showPastQuartersInLCCDashboard?: boolean
}

export default CaseloadManagementQuarterlySnapshot
