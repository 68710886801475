import React, { FunctionComponent } from 'react'

import styled from 'styled-components/native'

import calendarNoOpenSpotsIllustrationUrl from '../../assets/calendarNoOpenSpots.png'
import { tID } from '../../utils'
import { Image } from '../image/Image'

type CalendarNoOpenSpotsIllustrationProps = {
  height?: number
  width?: number
}

const ImageContainer = styled(Image)<{ imageHeight: string; imageWidth: string }>(({ imageHeight, imageWidth }) => ({
  height: imageHeight,
  width: imageWidth,
}))

/**
 * Illustration shown on appointment booking confirmation
 */
export const CalendarNoOpenSpotsIllustration: FunctionComponent<CalendarNoOpenSpotsIllustrationProps> = ({
  height = 100,
  width = 100,
}) => {
  const heightInPixels = `${height}px`
  const widthInPixels = `${width}px`
  return (
    <ImageContainer
      imageHeight={heightInPixels}
      imageWidth={widthInPixels}
      testID={`${tID('CalendarNoOpenSpotsIllustration')}`}
      source={calendarNoOpenSpotsIllustrationUrl}
    />
  )
}
