import React from 'react'
import colors from '../../../styles/_0colors.scss'
import { Icon } from './types'

const ListIcon: React.FC<ListIconProps> = ({
  width = 9,
  fillColor = colors.x_light_gray_10,
  isFilled = false,
  ...props
}) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} viewBox='0 0 24 24' {...props}>
      {isFilled ? (
        <path
          fill={fillColor}
          d='M17,8.25 L10.75,8.25 C10.34,8.25 10,7.91 10,7.5 C10,7.09 10.34,6.75 10.75,6.75 L17,6.75 C17.41,6.75 17.75,7.09 17.75,7.5 C17.75,7.91 17.41,8.25 17,8.25 M17,12.75 L10.75,12.75 C10.34,12.75 10,12.41 10,12 C10,11.59 10.34,11.25 10.75,11.25 L17,11.25 C17.41,11.25 17.75,11.59 17.75,12 C17.75,12.41 17.41,12.75 17,12.75 M17,17.25 L10.75,17.25 C10.34,17.25 10,16.91 10,16.5 C10,16.09 10.34,15.75 10.75,15.75 L17,15.75 C17.41,15.75 17.75,16.09 17.75,16.5 C17.75,16.91 17.41,17.25 17,17.25 M7.25,8.5 C6.7,8.5 6.25,8.05 6.25,7.5 C6.25,6.95 6.7,6.5 7.25,6.5 C7.8,6.5 8.25,6.95 8.25,7.5 C8.25,8.05 7.8,8.5 7.25,8.5 M7.25,13 C6.7,13 6.25,12.55 6.25,12 C6.25,11.45 6.7,11 7.25,11 C7.8,11 8.25,11.45 8.25,12 C8.25,12.55 7.8,13 7.25,13 M7.25,17.5 C6.7,17.5 6.25,17.05 6.25,16.5 C6.25,15.95 6.7,15.5 7.25,15.5 C7.8,15.5 8.25,15.95 8.25,16.5 C8.25,17.05 7.8,17.5 7.25,17.5 M18,3 L6,3 C4.34,3 3,4.34 3,6 L3,18 C3,19.66 4.34,21 6,21 L18,21 C19.66,21 21,19.66 21,18 L21,6 C21,4.34 19.66,3 18,3'
        />
      ) : (
        <path
          fill={fillColor}
          d='M17.25,16 C17.25,15.586 16.914,15.25 16.5,15.25 L11.25,15.25 C10.836,15.25 10.5,15.586 10.5,16 C10.5,16.414 10.836,16.75 11.25,16.75 L16.5,16.75 C16.914,16.75 17.25,16.414 17.25,16 M7.75,15 C7.197,15 6.75,15.448 6.75,16 C6.75,16.552 7.197,17 7.75,17 C8.303,17 8.75,16.552 8.75,16 C8.75,15.448 8.303,15 7.75,15 M17.25,12 C17.25,11.586 16.914,11.25 16.5,11.25 L11.25,11.25 C10.836,11.25 10.5,11.586 10.5,12 C10.5,12.414 10.836,12.75 11.25,12.75 L16.5,12.75 C16.914,12.75 17.25,12.414 17.25,12 M7.75,11 C7.197,11 6.75,11.448 6.75,12 C6.75,12.552 7.197,13 7.75,13 C8.303,13 8.75,12.552 8.75,12 C8.75,11.448 8.303,11 7.75,11 M17.25,8 C17.25,7.586 16.914,7.25 16.5,7.25 L11.25,7.25 C10.836,7.25 10.5,7.586 10.5,8 C10.5,8.414 10.836,8.75 11.25,8.75 L16.5,8.75 C16.914,8.75 17.25,8.414 17.25,8 M7.75,7 C7.197,7 6.75,7.448 6.75,8 C6.75,8.552 7.197,9 7.75,9 C8.303,9 8.75,8.552 8.75,8 C8.75,7.448 8.303,7 7.75,7 M18,3 L6,3 C4.343,3 3,4.343 3,6 L3,18 C3,19.657 4.343,21 6,21 L18,21 C19.657,21 21,19.657 21,18 L21,6 C21,4.343 19.657,3 18,3 M18,4.5 C18.827,4.5 19.5,5.173 19.5,6 L19.5,18 C19.5,18.827 18.827,19.5 18,19.5 L6,19.5 C5.173,19.5 4.5,18.827 4.5,18 L4.5,6 C4.5,5.173 5.173,4.5 6,4.5 L18,4.5'
        />
      )}
    </svg>
  )
}

type ListIconProps = Icon

export default ListIcon
