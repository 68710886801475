import { createSelector } from '@reduxjs/toolkit'

import calendarReducer from './calendarReducer'

export const getCalendarState = (state: Map<string, any>) =>
  state?.get('calendar') as ReturnType<typeof calendarReducer>

export const getCalendarToken = createSelector(getCalendarState, (calendarState) => calendarState?.token)

export const getCalendarOAuthURL = createSelector(getCalendarState, (calendarState) => calendarState?.oAuthUrl)

export const getCalendarCalendars = createSelector(getCalendarState, (calendarState) => calendarState?.calendars)

export const getCalendarIsLoading = createSelector(getCalendarState, (calendarState) => calendarState?.isLoading)
