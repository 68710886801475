import React, { FunctionComponent, useEffect, useState } from 'react'
import { FieldRenderProps } from 'react-final-form'
import { useIntl } from 'react-intl'
import { View, ViewStyle } from 'react-native'

import { noop } from 'lodash-es'
import styled from 'styled-components/native'

import {
  generateTypeAheadSectionList,
  GOVERNMENT_HEALTH_PLANS,
  HealthPlansList,
  useFormattedHealthPlans,
} from '@lyrahealth-inc/shared-app-logic'
import { Section } from '@lyrahealth-inc/shared-app-logic/src/features/common/utils/typeAheadUtils'

import { BodyText } from '../../atoms'
import { TYPE_AHEAD_Z_INDEX, TypeAhead } from '../../atoms/formElements/typeAhead/TypeAhead'
import { TypeAheadCustomOptionsConfig } from '../../atoms/formElements/typeAhead/TypeAheadCustomOptionsConfig'
import { HEALTH_PLAN_INFO_MESSAGES } from '../../pages/healthPlanInformation/healthPlanInfoMessages'
import { ThemeType, tID } from '../../utils'

export interface HealthPlanSelectorProps {
  label?: string
  healthPlan?: string
  healthPlansList?: HealthPlansList[]
  nonIntegratedHealthPlansList?: HealthPlansList[]
  error?: string
  baseInputStyle?: ViewStyle
  inputContainerStyle?: ViewStyle
  dropdownContainerStyle?: ViewStyle
  onSelect: (healthPlan: string) => void
  onBlur?: () => void
}

const HealthPlanContainer = styled.View<{ theme: ThemeType }>({
  zIndex: TYPE_AHEAD_Z_INDEX,
})

export const HealthPlanSelector: FunctionComponent<HealthPlanSelectorProps> = ({
  label,
  healthPlan,
  healthPlansList = [],
  nonIntegratedHealthPlansList = [],
  error,
  baseInputStyle,
  inputContainerStyle,
  dropdownContainerStyle,
  onSelect,
  onBlur = noop,
}) => {
  const { formatMessage } = useIntl()
  const [focused, setFocused] = useState(false)
  const [defaultHealthPlan, setDefaultHealthPlan] = useState(healthPlan)

  const isOtherHealthPlanSelected = defaultHealthPlan === 'Other'
  const formattedHealthPlans = useFormattedHealthPlans(
    healthPlansList,
    nonIntegratedHealthPlansList,
    !isOtherHealthPlanSelected,
  )
  const allHealthPlans = [...healthPlansList, ...nonIntegratedHealthPlansList, ...GOVERNMENT_HEALTH_PLANS]

  useEffect(() => {
    if (!isOtherHealthPlanSelected && healthPlan !== undefined) {
      setDefaultHealthPlan(healthPlan)
    }
  }, [healthPlan, isOtherHealthPlanSelected])

  const onFocus = () => setFocused(true)
  const handleOnBlur = () => {
    setFocused(false)
    onBlur()
  }

  const changeHealthPlan = (value: string) => {
    const healthPlan = allHealthPlans.find((healthPlan) => healthPlan.name === value)

    if (healthPlan) {
      setDefaultHealthPlan(healthPlan?.name)
    } else {
      setDefaultHealthPlan(formatMessage(HEALTH_PLAN_INFO_MESSAGES.otherHealthPlan))
    }

    if (onSelect) {
      onSelect(healthPlan?.eligibility_name ?? 'Other')
    }
  }

  const healthPlanInputLabelRenderer = (option: HealthPlansList) => {
    return <BodyText testID={tID(`healthPlanSelector-inputLabel`)} text={option.name} />
  }

  const healthPlanMenuItemRenderer = (option: HealthPlansList, displayText: JSX.Element) => (
    <View testID={tID(`healthPlanSelector-menuItem-${option.eligibility_name}`)}>{displayText}</View>
  )

  return (
    <HealthPlanContainer>
      <TypeAhead
        label={label}
        name='healthPlanSelector'
        inputName='healthPlanSelector-input'
        customOptionsConfig={
          new TypeAheadCustomOptionsConfig(
            generateTypeAheadSectionList(formattedHealthPlans as Section<any>[], 'name', true),
            healthPlanMenuItemRenderer,
            healthPlanInputLabelRenderer,
            'name',
          )
        }
        accessibilityLabel={formatMessage(HEALTH_PLAN_INFO_MESSAGES.healthPlanSelectorLabel)}
        fixedDropdownMenuPosition
        multiSelect={false}
        value={defaultHealthPlan ?? ''}
        isFocused={focused}
        onChange={changeHealthPlan}
        onFocus={onFocus}
        onBlur={handleOnBlur}
        error={error}
        baseInputStyle={baseInputStyle}
        inputContainerStyle={inputContainerStyle}
        dropdownContainerStyle={dropdownContainerStyle}
      />
    </HealthPlanContainer>
  )
}

export const HealthPlanSelectorRFF: FunctionComponent<FieldRenderProps<string>> = ({
  input: { onChange, onBlur },
  meta: { touched, error },
  label,
  healthPlan,
  healthPlansList,
  nonIntegratedHealthPlansList,
  baseInputStyle,
  inputContainerStyle,
  dropdownContainerStyle,
}) => {
  return (
    <HealthPlanSelector
      label={label}
      error={touched && error}
      healthPlan={healthPlan}
      healthPlansList={healthPlansList}
      nonIntegratedHealthPlansList={nonIntegratedHealthPlansList}
      baseInputStyle={baseInputStyle}
      inputContainerStyle={inputContainerStyle}
      dropdownContainerStyle={dropdownContainerStyle}
      onSelect={onChange}
      onBlur={onBlur}
    />
  )
}
