import React, { useEffect, useState, useCallback } from 'react'
import styles from './goSearchBar.module.scss'
import { KEYS } from '@lyrahealth-inc/ui-core'
import { isEmpty } from 'lodash-es'
import { Client } from './GoSearchBar'
import Hit from './Hit'

const Hits: React.FC<HitsProps> = ({ hits, onHitClick }) => {
  const [selectedHit, setSelectedHit] = useState<Client | null>(null)
  const onKeyDown = useCallback(
    (event: { key: string; keyCode: number; ctrlKey: any }) => {
      if (!hits) {
        return
      }
      if (event.key === KEYS.ARROW_DOWN) {
        if (!selectedHit) {
          setSelectedHit(hits[0])
        } else {
          const selectedHitIndex = hits.findIndex(({ id }) => id === selectedHit.id)
          if (selectedHitIndex !== -1) {
            // When the last one is selected, go back to the top
            if (selectedHitIndex === hits.length - 1) {
              setSelectedHit(hits[0])
            } else {
              setSelectedHit(hits[selectedHitIndex + 1])
            }
          }
        }
      } else if (event.key === KEYS.ARROW_UP) {
        if (!selectedHit) {
          setSelectedHit(hits[hits.length - 1])
        } else {
          const selectedHitIndex = hits.findIndex(({ id }) => id === selectedHit.id)
          if (selectedHitIndex !== -1) {
            // When the first one is selected, go back to the bottom
            if (selectedHitIndex === 0) {
              setSelectedHit(hits[hits.length - 1])
            } else {
              setSelectedHit(hits[selectedHitIndex - 1])
            }
          }
        }
      } else if (event.key === KEYS.ENTER && !!selectedHit) {
        onHitClick(selectedHit)
      }
    },
    [hits, onHitClick, selectedHit],
  )

  useEffect(() => {
    document.body.addEventListener('keydown', onKeyDown)
    return () => {
      document.body.removeEventListener('keydown', onKeyDown)
    }
  }, [onKeyDown])

  if (isEmpty(hits) || hits === undefined) {
    return null
  }
  return (
    <div className={styles.hitsContainer} data-test-id='Hits'>
      {hits.map((hit) => (
        <Hit key={hit.id} hit={hit} selected={selectedHit?.id === hit.id} onClick={() => onHitClick(hit)} />
      ))}
    </div>
  )
}

type HitsProps = {
  hits?: Client[]
  onHitClick: (hit: Client) => void
}

export default Hits
