import validation from '../../../utils/validation'

const clientEmailValidate = ($$values: any) => {
  let errors = {}

  const justRequired = [
    {
      fieldName: 'client_consent',
      errorText: 'Client consent',
    },
  ]

  errors = Object.assign(errors, validation.requiredFields($$values, justRequired))

  errors = Object.assign(
    errors,
    validation.requiredValidEmail($$values, {
      fieldName: 'email',
      errorText: 'Client email',
    }),
  )

  return errors
}

export default clientEmailValidate
