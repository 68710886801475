import React from 'react'
import styles from './exposurePracticeFormIntro.module.scss'
import DataTable from '../../molecules/table/DataTable'

const ExposurePracticeFormIntro = () => {
  return (
    <>
      <p className={styles['section-title']}>Anchor points for Subjective Units of Distress Scale (SUDS)</p>
      <div className={styles['table-container']}>
        {/* @ts-expect-error TS(2741): Property 'pagination' is missing in type '{ column... Remove this comment to see the full error message */}
        <DataTable
          columns={[
            { Header: 'SUDS', accessor: 'suds', maxWidth: 75 },
            { Header: 'Description', accessor: 'description' },
          ]}
          data={[
            { suds: '0', description: 'Do not avoid/No distress' },
            { suds: '25', description: 'Hesitate to enter but rarely avoid/Slight distress' },
            { suds: '50', description: 'Sometimes avoid/Definite distress' },
            { suds: '75', description: 'Usually avoid/Strong distress' },
            { suds: '100', description: 'Always avoid/Extreme distress' },
          ]}
          sortable={false}
          resizable={false}
          highlight={false}
        />
      </div>
      <p>
        Record your Subjective Units of Distress (SUDS) <b>Before</b> and <b>After</b> each real-life experiment. Also
        record the <b>Peak</b> (highest) SUDS rating that you experienced while in the situation.
      </p>
      <p>
        Remember to remain in the exposure task for at least 30-45 minutes or until your SUDS ratings decrease by half.
      </p>
    </>
  )
}

export default ExposurePracticeFormIntro
