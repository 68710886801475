import React, { FunctionComponent } from 'react'
import { View } from 'react-native'

import { Image } from '../image/Image'

type DiverseProvidersIllustrationProps = {
  width?: number
  height?: number
}

export const DiverseProvidersIllustration: FunctionComponent<DiverseProvidersIllustrationProps> = ({
  width = 112,
  height = 112,
  ...props
}) => {
  return (
    <View {...props}>
      <Image
        source={require('../../assets/diverseProvidersIllustration.png')}
        style={{ width, height }}
        accessibilityIgnoresInvertColors
      />
    </View>
  )
}
