import React, { FunctionComponent } from 'react'
import Svg, { G, Mask, Path } from 'react-native-svg'

type Props = {
  size?: number | string
}

export const SelfCareEssentialsSpotIllustrationWhiteBorder: FunctionComponent<Props> = ({ size = 124 }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 513 512' fill='none'>
      <Path
        d='M257.942 1.77881C222.601 1.77881 188.312 7.8393 156.027 19.7883C79.1629 48.2797 25.1244 111.444 7.76244 193.084C-3.71101 246.313 -2.24395 295.546 12.2749 343.524C30.8105 404.796 76.3097 456.467 137.107 485.282C167.288 499.619 201.961 507.804 240.145 509.605C245.052 509.828 250.04 509.949 254.998 509.949C285.634 509.949 316.453 505.75 346.603 497.474C348.92 496.897 351.126 496.301 353.453 495.613C380.548 487.68 404.932 475.134 425.794 458.369C448.448 440.269 467.095 417.322 481.158 390.207C483.931 384.864 486.581 379.239 489.505 372.511C505.593 335.156 513.363 293.34 511.987 251.534C511.339 233.049 508.587 213.4 503.832 193.084C496.001 160.12 483.233 129.12 466.903 103.381C462.431 96.4702 458.323 90.6626 454.255 85.4419C424.904 47.5815 379.172 21.0125 322.048 8.64871C301.064 4.09576 279.493 1.78893 257.952 1.78893L257.942 1.77881Z'
        fill='white'
      />
      <Path
        d='M490.023 252.221C489.396 234.505 486.684 216.152 482.415 197.91C475.433 168.518 463.96 139.541 448.227 114.732C444.574 109.107 440.831 103.684 436.764 98.4733C407.26 60.4308 362.551 39.2646 316.788 29.3594C265.815 18.3109 211.615 21.5385 162.615 39.6795C91.7307 65.945 43.4693 123.484 27.7262 197.495C21.6759 225.531 19.9053 257.22 22.6168 287.664H22.4043C23.9726 304.965 27.0989 321.851 31.7834 337.382C49.1959 394.921 91.5081 440.582 145.405 466.119C174.594 479.981 207.214 486.861 240.369 488.429C274.243 489.997 308.643 485.717 340.443 476.965C342.528 476.449 344.511 475.923 346.595 475.296C371.505 468.001 393.187 456.74 411.743 441.837C431.968 425.679 448.642 405.353 461.572 380.443C464.385 375.02 466.884 369.607 469.282 364.072C484.499 328.731 491.278 290.375 489.922 252.333L490.023 252.232V252.221Z'
        fill='#FFE9E3'
      />
      <Mask id='mask0_16033_9779' maskUnits='userSpaceOnUse' x='24' y='25' width='470' height='467'>
        <Path
          d='M493.023 255.221C492.396 237.505 489.684 219.152 485.415 200.909C478.433 171.517 466.96 142.54 451.227 117.732C447.574 112.106 443.831 106.683 439.764 101.473C410.26 63.4303 365.551 42.2642 319.788 32.3589C268.815 21.3104 214.615 24.538 165.615 42.679C94.7307 68.9445 46.4693 126.484 30.7262 200.495C24.6759 228.531 22.9053 260.219 25.6168 290.663H25.4043C26.9726 307.964 30.0989 324.851 34.7834 340.382C52.1959 397.921 94.5081 443.582 148.405 469.119C177.594 482.98 210.214 489.86 243.369 491.428C277.243 492.997 311.643 488.717 343.443 479.965C345.528 479.449 347.511 478.923 349.595 478.296C374.505 471.001 396.187 459.74 414.743 444.836C434.968 428.678 451.642 408.352 464.572 383.442C467.385 378.019 469.884 372.606 472.282 367.072C487.499 331.731 494.278 293.375 492.922 255.332L493.023 255.231V255.221Z'
          fill='white'
        />
      </Mask>
      <G mask='url(#mask0_16033_9779)'>
        <Path
          d='M259.123 280.623C260.753 264.127 264.846 251.604 266.898 246.736C265.641 249.713 252.676 245.056 249.557 244.211C235.093 275.674 234.832 372.498 240.394 406.677C244.397 407.562 256.397 408.306 260.441 408.97C255.422 384.749 251.479 358.134 259.123 280.623Z'
          fill='#127719'
        />
        <Path
          d='M292.894 310.083C262.034 330.431 250.658 364.741 249.019 376.198C248.878 377.194 249.019 378.572 250.025 378.702C250.437 378.763 250.799 378.451 251.201 378.34C274.95 372.305 312.74 356.654 351.415 291.525C351.757 290.952 352.37 290.288 351.897 289.815C350.58 290.026 327.636 287.17 292.884 310.073L292.894 310.083Z'
          fill='#127719'
        />
        <Path
          d='M186.81 304.491C203.366 328.018 233.521 349.02 239.778 343.749C247.231 337.473 231.248 308.765 228.552 305.114C195.339 260.062 170.132 255.485 167.024 257.004C160.305 260.303 181.67 297.188 186.81 304.491Z'
          fill='#127719'
        />
        <Path
          d='M246.439 235.841C243.049 235.811 241.44 235.368 241.168 232.632C243.783 231.848 244.618 233.528 246.439 235.841Z'
          fill='#FFF5DB'
        />
        <Path
          d='M252.193 240.187C250.433 240.278 248.713 240.318 248.381 237.874C250.141 236.315 250.966 237.904 251.962 238.9C252.344 239.121 252.505 239.393 252.444 239.694C252.384 239.996 252.304 240.157 252.193 240.187Z'
          fill='#FFECB9'
        />
        <Path
          d='M204.454 201.099C199.052 188.124 195.964 174.665 202.18 161.841C208.014 149.811 216.635 140.064 229.369 135.105C240.262 130.86 254.143 131.363 261.938 140.084C270.066 130.83 284.319 127.571 295.665 132.369C307.011 137.167 314.595 149.65 313.629 161.931C321.857 159.296 331.282 163.681 336.019 170.893C346.319 186.565 341.793 210.343 332.408 225.089C321.646 241.997 303.53 253.575 283.645 256.119C261.797 258.926 227.981 257.467 211.143 241.122C203.85 234.041 199.163 224.576 196.769 214.698C196.005 211.54 195.481 208.13 196.699 205.123C197.916 202.115 201.466 199.842 204.464 201.099H204.454Z'
          fill='#F47352'
        />
        <Path
          d='M271.505 161.278C277.862 158.924 285.416 162.948 289.107 168.842C295.374 178.84 283.152 190.95 273.074 186.122C266.556 182.994 261.255 171.004 266.667 164.728C268.115 163.048 269.765 161.932 271.495 161.288L271.505 161.278Z'
          fill='#FFD974'
        />
        <Path
          d='M218.805 622.22C445.69 622.22 629.616 572.99 629.616 512.26C629.616 451.531 445.69 402.3 218.805 402.3C-8.07947 402.3 -192.006 451.531 -192.006 512.26C-192.006 572.99 -8.07947 622.22 218.805 622.22Z'
          fill='#5DAD3E'
        />
      </G>
    </Svg>
  )
}
