import React, { FunctionComponent } from 'react'

import styled from 'styled-components/native'

import { Image } from '../image/Image'

type CareOptionOutPatientSupportIconProps = {
  size?: number
}

const CareOptionOutPatientSupportPNG = styled(Image)<{ size: number }>(({ size }) => ({
  width: `${size}px`,
  height: `${size}px`,
}))

export const CareOptionOutPatientSupportIcon: FunctionComponent<CareOptionOutPatientSupportIconProps> = ({
  size = 64,
}) => {
  return (
    <CareOptionOutPatientSupportPNG size={size} source={require('./../../assets/careOptionOutPatientSupport.png')} />
  )
}
