import mixpanel from 'mixpanel-browser'
import { MixpanelMock } from '@lyrahealth-inc/shared-app-logic/src/testing'

declare global {
  interface Window {
    Cypress: any
    mixpanelMock: MixpanelMock
  }
}
const mixpanelMock = new MixpanelMock('mockToken')

if (window.Cypress) {
  window.mixpanelMock = mixpanelMock
  window.Cypress.cy.spy(window.mixpanelMock, 'track').as('track')
  window.Cypress.log({ message: 'Now spying on Mixpanel track calls' })
}
const Mixpanel = window.Cypress ? mixpanelMock : mixpanel
export default Mixpanel
