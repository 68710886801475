import React, { FunctionComponent } from 'react'
import { ViewStyle } from 'react-native'

import styled from 'styled-components/native'

import { QUALITY_STRENGTH_MAP, ZoomNetworkQuality } from '../../hooks/useZoom/types'
import { ThemeType, tID } from '../../utils'
import { ConnectionIndicator } from '../connectionIndicator/ConnectionIndicator'

const Container = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  position: 'absolute',
  zIndex: 2,
  bottom: theme.spacing['8px'],
  right: theme.spacing['8px'],
}))

export type ZoomNetworkIndicatorProps = {
  style?: ViewStyle
  networkQuality: ZoomNetworkQuality
}

export const ZoomNetworkIndicator: FunctionComponent<ZoomNetworkIndicatorProps> = ({ networkQuality, style }) => {
  const strength = QUALITY_STRENGTH_MAP[networkQuality]
  if (!strength) {
    return null
  }

  return (
    <Container style={style} testID={tID('ZoomNetworkIndicator')}>
      <ConnectionIndicator strength={strength} />
    </Container>
  )
}
