import React, { FunctionComponent } from 'react'

import { useAnimateLottie } from '../../hooks'

export interface FlyingEnvelopeLottieProps {
  size?: number
}

export const FlyingEnvelopeLottie: FunctionComponent<FlyingEnvelopeLottieProps> = ({ size = 158 }) => {
  const { AnimationView } = useAnimateLottie(900)

  return (
    <AnimationView
      source={require('../../assets/lottie/envelope-flying.json')}
      loop={true}
      autoPlay={true}
      style={{ height: size, width: size }}
    />
  )
}
