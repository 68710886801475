import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import {
  LayoutChangeEvent,
  NativeSyntheticEvent,
  Platform,
  Pressable,
  TextInput,
  TextInputKeyPressEventData,
  TextStyle,
  ViewStyle,
} from 'react-native'

import { isEmpty, noop } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'
import { useFocusVisible } from 'use-focus-visible'

import { AccessibilityRolesNative, IS_WEB } from '../../../constants'
import { getFocusBoxShadow } from '../../../styles/commonStyles'
import { getFontStyles } from '../../../styles/typeStyles'
import { ThemeType, tID } from '../../../utils'
import { BodyText, Size } from '../../bodyText/BodyText'
import { ChevronIcon, ChevronIconDirection } from '../../icons/ChevronIcon'
import { CloseCircleIcon } from '../../icons/CloseCircleIcon'
import { CloseIcon } from '../../icons/CloseIcon'
import { LoadingSpinner } from '../../icons/LoadingSpinner'
import { SearchIcon } from '../../icons/SearchIcon'

const InputFieldContainer = styled.Pressable<{ theme: ThemeType; active?: boolean }>(({ theme, active }) => ({
  ...(IS_WEB && { cursor: active ? 'default' : 'pointer' }),
  ...(IS_WEB && { outlineWidth: '0' }),
  flexDirection: 'row',
  alignItems: 'center',
  backgroundColor: theme.colors.backgroundPrimary,
  borderWidth: '1px',
  borderColor: theme.colors.inputOutlineDefault,
  borderRadius: '4px',
  height: 'auto',
  minHeight: 'auto',
  maxHeight: 'auto',
  padding: `11px 15px`,
  ...(active && {
    borderColor: theme.colors.inputOutlineFocus,
  }),
}))

const Input = styled(TextInput)<{ hidden: boolean; padTop: boolean }>(({ hidden, padTop }) => ({
  marginRight: '8px',
  marginTop: padTop ? '8px' : '0px',
  flexGrow: 1,
  ...(IS_WEB && { outlineWidth: '0' }),
  ...(IS_WEB && { outlineStyle: 'none' }),
  opacity: hidden ? 0 : 100,
  ...(hidden && {
    width: 0,
  }),
}))

const RightIconContainer = styled.View({
  marginLeft: 'auto',
  flexDirection: 'row',
  paddingLeft: '8px',
})

const SelectedAndInputContainer = styled.View<{ multiSelect: boolean }>(({ multiSelect }) => ({
  flexDirection: 'row',
  flex: 1,
  ...(multiSelect && { flexWrap: 'wrap' }),
}))

const SelectedOptionTextContainer = styled.View({
  flexWrap: 'wrap',
  flexShrink: 1,
  flexGrow: 1,
})

const ClearButton = styled(Pressable)({
  ...(IS_WEB && { outlineWidth: 0 }),
  borderRadius: '3px',
})

const SelectedContainer = styled.View({
  margin: '-2px',
  flexDirection: 'row',
  flexShrink: 1,
  flexWrap: 'wrap',
  display: 'flex',
})

const SelectedOption = styled(Pressable)<{ theme: ThemeType }>(({ theme }) => ({
  borderRadius: '4px',
  backgroundColor: theme.colors.secondaryTertiaryButtonBackgroundDefault,
  padding: '3px 7px 3px 11px',
  flexDirection: 'row',
  alignItems: 'center',
  margin: '2px',
  marginRight: '8px',
  display: 'flex',
  flexBasis: Platform.OS === 'web' ? 'fit-content' : '',
}))

const CloseIconContainer = styled(Pressable)({
  marginLeft: '4px',
  height: '16px',
  width: '16px',
  alignItems: 'center',
  justifyContent: 'center',
})

const SearchIconContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginRight: theme.spacing['8px'],
}))

export const TypeAheadInput = forwardRef<any, any>(
  (
    {
      placeholder,
      value,
      inputValue,
      onChange,
      onBlur = noop,
      onFocus = noop,
      onPress,
      isFocused,
      isLoading,
      editable = true,
      onKeyPress,
      name,
      onDeleteItem,
      style,
      multiSelect = true,
      singleSelectModeSelectedItemLabel,
      accessibilityLabel,
      id,
    },
    ref,
  ) => {
    useImperativeHandle(ref, () => ({
      focus: () => {
        inputRef?.current?.focus()
      },
    }))
    const { colors } = useTheme()
    const { focusVisible: clearFocusVisible, onBlur: onClearBlur, onFocus: onClearFocus } = useFocusVisible()
    const clearRef = useRef<any>(null)
    const inputRef = useRef<TextInput>(null)
    const [searchValue, setSearchValue] = useState<string>(inputValue)
    /** Pad input if it wraps around due to multiple selected options  */
    const [padInputTop, setPadInputTop] = useState(false)
    const showClearButton = !isEmpty(searchValue) && !isLoading && isFocused
    const showSearchIcon = isEmpty(value) || (!multiSelect && isFocused)
    const isDisplayingSingleSelectedOption = !multiSelect && !isEmpty(value) && !isFocused
    const showChevron = isDisplayingSingleSelectedOption

    useEffect(() => {
      setSearchValue(inputValue)
    }, [inputValue])

    const handleOnLayoutInput = (event: LayoutChangeEvent) => {
      setPadInputTop(event.nativeEvent.layout.y > 8)
    }

    const getSelectedOptionsDisplay = () => {
      if (!isEmpty(value)) {
        if (multiSelect) {
          return (
            <SelectedContainer testID={tID('typeAheadInput-selectedContainer')}>
              {value.map((item: string) => {
                return (
                  <SelectedOption key={item} testID={tID('typeAheadInput-selectedOption')}>
                    <BodyText text={item} size={Size.BADGE} testID={tID('typeAheadInput-selection')} />
                    <CloseIconContainer
                      onPress={() => {
                        onDeleteItem(item)
                      }}
                      testID={tID('typeAheadInput-selection-delete')}
                    >
                      <CloseIcon size={7.5} fillColor={colors.iconDefault} />
                    </CloseIconContainer>
                  </SelectedOption>
                )
              })}
            </SelectedContainer>
          )
        } else if (!isFocused) {
          return (
            (singleSelectModeSelectedItemLabel && (
              <SelectedOptionTextContainer testID={tID('typeAheadInput-singleSelection')}>
                {singleSelectModeSelectedItemLabel}
              </SelectedOptionTextContainer>
            )) || <BodyText testID={tID('typeAheadInput-singleSelection')} text={value[0]} />
          )
        }
      }
      return <></>
    }

    return (
      <InputFieldContainer
        onPress={() => {
          inputRef?.current?.focus()
          onPress()
        }}
        active={isFocused}
        style={style}
        accessibilityLabel={accessibilityLabel}
        accessibilityRole={AccessibilityRolesNative.SEARCH}
        accessibilityValue={{ text: value[0] }}
        nativeID={id}
      >
        <>
          {showSearchIcon && (
            <SearchIconContainer>
              <SearchIcon fillColor={colors.iconDefault} />
            </SearchIconContainer>
          )}
          <SelectedAndInputContainer multiSelect={multiSelect}>
            {getSelectedOptionsDisplay()}
            <Input
              onPressOut={onPress}
              placeholder={isEmpty(value) ? placeholder : undefined}
              placeholderTextColor={colors.inputTextPlaceholder}
              value={searchValue}
              onChangeText={(text: string) => {
                setSearchValue(text)
                onChange(text)
              }}
              onFocus={onFocus}
              onBlur={onBlur}
              ref={inputRef}
              editable={editable}
              onKeyPress={onKeyPress}
              onSubmitEditing={() => {
                onKeyPress(undefined, true)
              }}
              testID={tID(name)}
              style={{ ...getFontStyles(colors).body.default, lineHeight: 20 } as TextStyle}
              hidden={isDisplayingSingleSelectedOption}
              accessibilityLabel={accessibilityLabel}
              onLayout={handleOnLayoutInput}
              padTop={padInputTop}
            />
          </SelectedAndInputContainer>
          <RightIconContainer>
            {isLoading && isFocused && <LoadingSpinner />}
            {showClearButton && (
              <ClearButton
                ref={clearRef}
                onPress={() => {
                  onChange('')
                  setSearchValue('')
                  inputRef?.current?.focus()
                }}
                testID={tID('typeAheadInput-clear-button')}
                onFocus={onClearFocus}
                onBlur={onClearBlur}
                style={clearFocusVisible && getFocusBoxShadow({ colors })}
              >
                <CloseCircleIcon />
              </ClearButton>
            )}
            {showChevron && (
              <ChevronIcon size={18} fillColor={colors.iconDefault} direction={ChevronIconDirection.DOWN} />
            )}
          </RightIconContainer>
        </>
      </InputFieldContainer>
    )
  },
)

export interface TypeAheadInputProps {
  placeholder?: string
  /** Selected items */
  value: string[]
  inputValue: string
  onChange: (text: string) => void
  onFocus?: () => void
  onBlur?: (e?: any) => void
  onPress: () => void
  isFocused?: boolean
  isLoading?: boolean
  editable?: boolean
  onKeyPress?: (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => void
  name?: string
  onDeleteItem: (item: string) => void
  style?: ViewStyle
  multiSelect?: boolean
  singleSelectModeSelectedItemLabel?: JSX.Element
  accessibilityLabel?: string
  id?: string
}
