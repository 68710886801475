import moment from 'moment-timezone'
import { Iterable } from 'immutable'
import { get } from 'lodash-es'

const DATETIME_TIMEZONE_FORMAT = 'MMM. D, h:mm A z'
const HOUR_MINUTES_MERIDIEM_TIMEZONE = 'h:mm A z'
const DISPLAY_DATE_FORMAT = 'DD MMM YYYY'
const DISPLAY_DAY_DATE = 'dddd, MMM D'
const MERIDIAN_ONLY = 'z'

const getAppointmentDateTimeObject = (appt: $TSFixMe) => {
  if (Iterable.isIterable(appt)) {
    appt = appt.toJS()
  }
  const date = `${get(appt, 'startDate', '')} ${get(appt, 'startTime', '')}`
  const zoneId = `${get(appt, 'timeZone', '')}`
  return moment.tz(date, zoneId)
}

/* Pass in a javascript date and return a consistent string
 that should be displayed everywhere in application.
 Note: Moment is pretty smart about handing different types of inputs,
 but this function is built for converting server date/datetime string format
 to UI display format.
 It doesn't handle edge cases such as '02342011' */
const getDisplayDate = function (date = '', format = DISPLAY_DATE_FORMAT) {
  return moment(date).format(format)
}

const getFriendlyDate = (date: $TSFixMe, addPreposition = false, displayTime = false, showTimezone = false) => {
  if (!date) return ''
  const momentObj = moment.isMoment(date) ? date : moment(date)
  const minutesFormat = showTimezone ? ', h:mm a z' : ', h:mm a'
  const minutes = displayTime ? momentObj.format(minutesFormat) : ''
  const dateFormat = moment().diff(momentObj, 'years', true) <= 1 ? 'MMMM Do' : 'MMMM Do YYYY'
  if (momentObj.isSame(moment(), 'day')) return `today${minutes}`
  if (moment().subtract(1, 'day').isSame(date, 'day')) return `yesterday${minutes}`
  if (moment().add(1, 'day').isSame(date, 'day')) return `tomorrow${minutes}`
  return `${addPreposition ? 'on ' : ''}${momentObj.format(dateFormat)}${minutes}`
}

export default {
  DATETIME_TIMEZONE_FORMAT,
  HOUR_MINUTES_MERIDIEM_TIMEZONE,
  DISPLAY_DATE_FORMAT,
  DISPLAY_DAY_DATE,
  MERIDIAN_ONLY,
  getAppointmentDateTimeObject,
  getDisplayDate,
  getFriendlyDate,
}
