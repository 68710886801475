import React from 'react'
import styles from './assignmentsList.module.scss'
import moment from 'moment-timezone'
import dateUtils from '../../utils/dateUtils'
import { partition, uniq, uniqueId } from 'lodash-es'
import PrimaryButton from '../../atoms/buttons/primaryButton/PrimaryButton'
import { ItemAction } from './AssignmentsList'
import WeekAssignmentsList from './WeekAssignmentsList'
import AssignmentListItem from './AssignmentListItem'
import { Assignment, EpisodeContents, Episode, Appointment } from '@lyrahealth-inc/shared-app-logic'

const SSCAssignmentsList: React.FC<SSCAssignmentsListProps> = ({
  activityActions = [],
  addTrackHandler,
  activities,
  appointments,
  assessments,
  currentEpisode,
  itemClickHandler,
  sessionData,
  userRole,
}) => {
  const [currentEpisodeAssessments, otherEpisodeAssessments] = partition(assessments, ['episode_id', currentEpisode.id])

  const assignments = [...activities, ...currentEpisodeAssessments]

  const distinctWeeks: number[] =
    currentEpisode.curriculum[0].contents.length === 0
      ? [0]
      : uniq(assignments.map(({ session_period }) => Number(session_period) - 1)).sort((a: number, b: number) => a - b)

  const firstAppointmentDateTime = dateUtils.getAppointmentDateTimeObject(
    appointments
      .filter((appointment: Appointment) => appointment.episodeId === currentEpisode.id)
      .sort((a: Appointment, b: Appointment) => {
        if (a.startDate > b.startDate) return 1
        else if (a.startDate < b.startDate) return -1
        else return a.startTime > b.startTime ? 1 : -1
      })[0] || {},
  )

  const getAssignmentsToDisplay = (week: number) => {
    let weekAssignments = assignments.filter((assignment: Assignment) => assignment.session_period === Number(week) + 1)
    let contents: EpisodeContents[] = []
    if (week > 0) contents = currentEpisode?.curriculum[week - 1].contents
    const curriculumAssignments = weekAssignments
      .filter(
        (assignment: Assignment) =>
          contents.findIndex((content: EpisodeContents) => content.name === assignment.content.name) > -1,
      )
      .sort(
        (assignmentA: Assignment, assignmentB: Assignment) =>
          contents.findIndex((content: EpisodeContents) => content.name === assignmentA.content.name) -
          contents.findIndex((content: EpisodeContents) => content.name === assignmentB.content.name),
      )
    const assessments = weekAssignments.filter(
      (assignment: Assignment) =>
        contents.findIndex((content: EpisodeContents) => content.name === assignment.content.name) === -1 &&
        assignment.content.group === 'assessment',
    )
    const otherAssignments = weekAssignments.filter(
      (assignment: Assignment) =>
        contents.findIndex((content: EpisodeContents) => content.name === assignment.content.name) === -1 &&
        assignment.content.group !== 'assessment',
    )
    weekAssignments = [...curriculumAssignments, ...assessments]
    weekAssignments = weekAssignments.map((assignment: Assignment, index: number) => ({
      ...assignment,
      content: { ...assignment.content, title: (index + 1 + '. ').concat(assignment.content.title) },
    })) // updating assignment.content.title while avoiding mutating props
    return [...weekAssignments, ...otherAssignments]
  }

  const getDueDate = (week: number) => firstAppointmentDateTime.clone().add(week, 'week')

  const isCurrentWeek = (week: number) => {
    return (
      (moment().isSameOrAfter(getDueDate(week).subtract(1, 'week')) && moment().isBefore(getDueDate(week))) ||
      (moment().isBefore(firstAppointmentDateTime) && week === 0) ||
      (moment().isAfter(firstAppointmentDateTime.clone().add(6, 'week')) && week === Math.max(...distinctWeeks))
    )
  }

  return (
    <div data-test-id='SSCAssignmentsList-currentAssignments'>
      <div className={styles.container} data-test-id='SSCAssignmentsList-weekAssignmentsContainer'>
        {distinctWeeks.map((week) => (
          <WeekAssignmentsList
            data-test-id={`SSCAssignmentsList-weekAssignmentsWeek${week}`}
            key={`WeekAssignmentsList=${week}`}
            activityActions={activityActions}
            assignments={getAssignmentsToDisplay(week)}
            currentEpisode={currentEpisode}
            isOpen={distinctWeeks.length === 1 || isCurrentWeek(week)}
            itemClickHandler={itemClickHandler}
            sessionData={sessionData}
            subtext={`Complete by ${dateUtils.getFriendlyDate(getDueDate(week))}`}
            userRole={userRole}
            week={week}
          />
        ))}
      </div>

      {userRole === 'client' && otherEpisodeAssessments.length > 0 && (
        <div className={styles.container} data-test-id='SSCAssignmentsList-currentAssessmentsContainer'>
          <h3 className={styles.title} data-test-id='SSCAssignmentsList-currentAssessmentsTitle'>
            Current assessments
          </h3>
          <ul className={styles.list} data-test-id='SSCAssignmentsList-currentAssessments'>
            {otherEpisodeAssessments.map((assessment: Assignment) => (
              <AssignmentListItem
                key={uniqueId('assignmentListItem')}
                assignment={assessment}
                fullBorder
                itemActions={activityActions}
                itemClickHandler={itemClickHandler}
                showDueDate={false}
                showProviderName={false}
                userRole={userRole}
              />
            ))}
          </ul>
        </div>
      )}

      {userRole === 'provider' && distinctWeeks.length === 1 && (
        <PrimaryButton data-test-id='SSCAssignments-addTrackBtn' onClick={addTrackHandler}>
          Add Care Plan
        </PrimaryButton>
      )}
    </div>
  )
}

type SSCAssignmentsListProps = {
  activityActions?: Array<ItemAction>
  addTrackHandler?: () => void
  assessments: Assignment[]
  appointments: Appointment[]
  activities: Assignment[]
  currentEpisode: Episode
  itemClickHandler?: (val: any) => void
  sessionData?: any
  userRole: string
}

export default SSCAssignmentsList
