import React, { FunctionComponent } from 'react'

import styled from 'styled-components/native'

import { tID } from '../../utils'
import { Image } from '../image/Image'

/**
 * Illustration to show on the disclaimer screen when client first downloads the app
 */

const DisclaimerIllustrationPNG = styled(Image)`
  width: 280px;
  height: 223px;
`

export const DisclaimerIllustration: FunctionComponent = () => {
  return (
    <DisclaimerIllustrationPNG
      testID={`${tID('DisclaimerIllustration')}`}
      source={require('./../../assets/disclaimerIllustration.png')}
    />
  )
}
