import { createSelector } from '@reduxjs/toolkit'

import { getPracticesState } from '../../data/practicesSelectors'

export const getPracticesDashboard = createSelector(
  getPracticesState,
  (practicesState) => practicesState.practicesDashboard,
)

export const getPracticesDashboardShowModal = createSelector(
  getPracticesDashboard,
  (practicesDashboardState) => practicesDashboardState.showModal,
)
