import { useState } from 'react'

import moment from 'moment-timezone'

import { useInterval } from '../../common/hooks/useInterval'
import { calculateTimeLeft } from '../utils'

// hook for handling the counting down for the appointment by one secon
export const useApptCountDown = () => {
  const [startsIn, setStartsIn] = useState<string>('')
  const [startTime, setStartTime] = useState<moment.Moment | null>(null)
  useInterval(
    () => {
      startTime && setStartsIn(calculateTimeLeft({ startTime }))
    },
    startsIn === 'now' || !startTime ? null : 1000,
  )
  return { startsIn, setStartTime }
}
