import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'

import styled, { useTheme } from 'styled-components/native'

import { BodyText, Subhead, WaitingRoom } from '../../atoms'
import { BodyTextSize, SubheadSize } from '../../styles'
import { ThemeType } from '../../utils'

const Container = styled.View(({ theme }) => ({
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  paddingTop: theme.spacing['16px'],
  paddingBottom: theme.breakpoints.isMobileSized ? '120px' : undefined,
  flexGrow: 1,
}))

const CenteredContainer = styled.View(({ theme }) => ({
  gap: theme.breakpoints.isMobileSized ? theme.spacing['16px'] : theme.spacing['72px'],
  alignItems: 'center',
  justifyContent: theme.breakpoints.isMobileSized ? undefined : 'center',
  width: '100%',
}))

const TextContainer = styled.View(({ theme }) => ({
  gap: theme.breakpoints.isMobileSized ? theme.spacing['4px'] : theme.spacing['8px'],
  alignItems: 'center',
}))

export type ZoomWaitingRoomProps = {
  participantName: string
}

export const ZoomWaitingRoom: FunctionComponent<ZoomWaitingRoomProps> = ({ participantName }) => {
  const {
    colors,
    breakpoints: { isMobileSized },
  } = useTheme() as ThemeType
  const subText = (
    <FormattedMessage
      defaultMessage='Calls may be recorded with your consent.'
      description='Subtext about recording consent'
    />
  )
  return (
    <Container>
      <CenteredContainer>
        <WaitingRoom />
        <TextContainer>
          <Subhead
            size={isMobileSized ? SubheadSize.XSMALL : SubheadSize.LARGE}
            text={
              <FormattedMessage
                defaultMessage='{participantName} will join the session shortly'
                description='Subtext about participant joining shortly'
                values={{ participantName }}
              />
            }
          />
          {isMobileSized ? (
            <BodyText size={BodyTextSize.SMALL} color={colors.textSecondary} text={subText} />
          ) : (
            <Subhead size={SubheadSize.MEDIUM} color={colors.textSecondary} text={subText} />
          )}
        </TextContainer>
      </CenteredContainer>
    </Container>
  )
}
