import React, { ReactElement, useEffect, useRef, useState } from 'react'
import QuestionMarkIcon from '../icons/QuestionMarkIcon'
import sassStyles from './tooltip.module.scss'
import { KEYS } from '../../constants/keyboardConstants'
import { usePopper } from '@lyrahealth-inc/react-popper'
import { Placement } from '@popperjs/core'

export enum TOOLTIP_PLACEMENTS {
  TOP = 'top',
  BOTTOM = 'bottom',
  RIGHT = 'right',
  LEFT = 'left',
}

const Tooltip: React.FC<TooltipProps> = ({
  content,
  children,
  placement = TOOLTIP_PLACEMENTS.BOTTOM,
  delayHide = 500,
  childTabIndex = 0,
  id = `${Math.random()}`,
  triggerStyle = { display: 'inline-block' },
  customWidth,
  show = true,
}) => {
  const [shouldShow, setShouldShow] = useState(false)
  const tooltipElement = useRef(null)
  const referenceElement = useRef(null)
  const timeoutId = useRef<number | undefined>(undefined)
  const { styles, attributes } = usePopper(referenceElement.current, tooltipElement.current, {
    placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 5],
        },
      },
    ],
  })
  useEffect(() => {
    const onESCKey = (e: { key: string }) => {
      if (e.key === KEYS.ESC) {
        setShouldShow(false)
      }
    }
    document.addEventListener('keydown', onESCKey)
    return () => document.removeEventListener('keydown', onESCKey)
  }, [])

  const showTooltip = () => {
    if (show) {
      clearTimeout(timeoutId.current)
      setShouldShow(true)
    }
  }

  const hideTooltip = () => {
    timeoutId.current = window.setTimeout(() => {
      setShouldShow(false)
    }, delayHide)
  }

  const child = children ? (
    React.cloneElement(children, { tabIndex: childTabIndex, 'aria-describedby': id })
  ) : (
    <QuestionMarkIcon isFilled tabIndex={childTabIndex} aria-label='Help icon' aria-describedby={id} />
  )

  const tooltipStyle = customWidth ? { ...styles.popper, width: customWidth } : styles.popper

  return (
    <>
      <span
        ref={referenceElement}
        style={triggerStyle}
        onFocus={showTooltip}
        onBlur={hideTooltip}
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
      >
        {child}
      </span>
      <div
        className={sassStyles.tooltip}
        id={id}
        role='tooltip'
        data-show={shouldShow}
        ref={tooltipElement}
        style={tooltipStyle}
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
        {...attributes.popper}
      >
        {content}
        <div data-popper-arrow className={sassStyles.arrow} style={styles.arrow} />
      </div>
    </>
  )
}

type TooltipProps = {
  content: string | React.ReactNode
  delayHide?: number
  placement?: Placement
  id?: string
  children?: ReactElement
  childTabIndex?: number
  triggerStyle?: Dict // Style applied to the span containing the tooltip trigger, which is typically an icon.
  show?: boolean
  customWidth?: string
}

export default Tooltip
