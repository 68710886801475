import React, { FunctionComponent } from 'react'
import { Path, Svg } from 'react-native-svg'

import { tID } from '../../utils'

type FamilyIllustrationProps = {
  size?: string | number
}

export const FamilyIllustration: FunctionComponent<FamilyIllustrationProps> = ({ size }) => (
  <Svg width={size} height={size} viewBox='0 0 42 42' fill='none' testID={tID('FamilyIllustration')}>
    <Path
      d='M40.9126 18.7206C40.903 17.3417 40.2978 16.0343 39.2541 15.1336L36.0626 12.3805V5.32717C36.0626 4.83788 35.6654 4.44232 35.1761 4.44232H32.7599C32.2706 4.44232 31.8735 4.83947 31.8735 5.32717V8.76807L23.4031 1.46212C22.0798 0.319919 20.1194 0.319919 18.7961 1.46212L2.91655 15.1606C1.89031 16.0454 1.28823 17.3242 1.25805 18.6793L1.0007 33.7551C0.965751 35.3405 2.24298 36.6447 3.8284 36.6447H38.1706C39.7418 36.6447 41.011 35.3659 40.9999 33.7948L40.9126 18.7206Z'
      fill='#6ECBD3'
    />
    <Path
      d='M6.1969 38.912L7.7299 38.9708L7.78867 41.0931H8.96741L9.14374 38.8532L11.6188 38.7945L11.7363 41.0931L12.8563 41.2107L13.0326 39.0296H13.9159C13.9159 39.0296 14.2511 31.4583 13.7983 30.2478C13.0914 28.3621 12.3257 28.0667 12.3257 28.0667C12.3257 28.0667 14.9898 27.2533 15.155 24.9435C15.3313 22.4685 14.3305 20.4637 11.2057 20.2285C11.2057 20.2285 7.49637 20.1825 7.13894 24.119C6.90383 26.7116 9.08338 27.8903 9.08338 27.8903C9.08338 27.8903 8.13975 28.2446 7.31527 29.8935C6.49079 31.5441 6.19531 38.9104 6.19531 38.9104L6.1969 38.912Z'
      fill='#127719'
    />
    <Path
      d='M35.2726 39.1549L33.8 39.0373L33.6237 41.3948L32.6213 41.2773L32.4449 39.0373L29.9111 38.9198L29.3218 41.1009L28.3781 40.9834L28.4957 38.6259H27.1406C27.1406 38.6259 27.3757 32.7322 28.4369 30.0205C29.0358 28.4922 30.205 28.1348 30.205 28.1348C30.205 28.1348 28.143 27.7281 28.143 24.8337C28.143 21.7105 30.5513 20.8257 32.3274 20.8257C33.8604 20.8257 35.9176 22.6478 35.8048 24.597C35.6285 27.6614 33.6237 28.2508 33.6237 28.2508C33.6237 28.2508 34.2718 28.6638 34.92 29.7838C35.5681 30.9037 35.6269 36.9738 35.2742 39.1549H35.2726Z'
      fill='#127719'
    />
    <Path
      d='M23.9659 39.4294L22.8857 39.3436L22.7554 41.0704L22.0215 40.9846L21.8912 39.3436L20.0341 39.2578L19.602 40.856L18.911 40.7702L18.9968 39.0434H18.0039C18.0039 39.0434 18.1771 34.724 18.9539 32.7382C19.3923 31.6183 20.2502 31.3562 20.2502 31.3562C20.2502 31.3562 18.7378 31.0575 18.7378 28.9383C18.7378 26.6492 20.5028 26.009 21.8038 26.001C23.2542 25.9931 24.4345 27.337 24.3519 28.7652C24.2217 31.0114 22.7538 31.4435 22.7538 31.4435C22.7538 31.4435 23.2288 31.7454 23.7038 32.5667C24.1788 33.388 24.2217 37.836 23.9627 39.4342L23.9659 39.4294Z'
      fill='#127719'
    />
    <Path
      d='M16.8034 29.5229L15.9805 30.1695C15.9805 30.1695 18.5222 33.9313 20.9941 34.0886C24.6907 34.3237 27.3437 30.5031 27.3437 30.5031L26.6113 29.7978C26.6113 29.7978 22.8416 32.4031 21.396 32.3649C19.9329 32.3252 16.8034 29.5245 16.8034 29.5245V29.5229Z'
      fill='#127719'
    />
  </Svg>
)
