import { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { useApptCountDown } from './useApptCountDown'
import { Appointment } from '../../../models'
import { getAppointmentDateTimeObject } from '../utils'

// This is a hook for getting formatted time information such as (startsIn, sessionTime, sessionDate) to display for an appointment
// startsIn returns 'now' or 00:00 format
// sessionTime returns 2:57 PM - 2:59 PM PDT format
// sessionDate returns Mon, Nov 29 format
export const useApptFormattedTime = (appt: Appointment | null): ApptFormattedTime => {
  const intl = useIntl()
  const [formattedTime, setFormattedTime] = useState<ApptFormattedTime>({
    startsIn: '',
    sessionTime: '',
    sessionDate: '',
  })

  const { startsIn, setStartTime } = useApptCountDown()

  const getFormattedTime = useCallback(
    (a: Appointment) => {
      const startTime = getAppointmentDateTimeObject(a)
      setStartTime(startTime) // set our countdown hooks start time
      const endTime = getAppointmentDateTimeObject(a).add(a.appointmentDuration, 'minutes')
      const sessionTime = `${intl.formatTime(startTime.toDate())} - ${intl.formatTime(endTime.toDate(), {
        hour: 'numeric',
        minute: 'numeric',
        timeZoneName: 'short',
      })}`
      const sessionDate = intl.formatDate(startTime.toDate(), { day: 'numeric', weekday: 'short', month: 'short' })

      setFormattedTime({
        startsIn,
        sessionTime,
        sessionDate,
      })
    },
    [intl, setStartTime, startsIn],
  )

  useEffect(() => {
    if (appt) {
      getFormattedTime(appt)
    }
  }, [appt, startsIn, getFormattedTime])
  return formattedTime
}

type ApptFormattedTime = {
  startsIn: string
  sessionTime: string
  sessionDate: string
}
