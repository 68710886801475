import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import classNames from 'classnames'
import { Map } from 'immutable'
import { find, isEmpty } from 'lodash-es'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'

import { ClientObject, ProviderSelfHarmTask, useFlags } from '@lyrahealth-inc/shared-app-logic'
import { Checkbox, Dashlet, NavButton, RefreshButton } from '@lyrahealth-inc/ui-core'
import { toJS, useFetcher, useMediaQuerySize } from '@lyrahealth-inc/ui-core-crossplatform'

import styles from './selfHarmDashlet.module.scss'
import { selfHarmDashletTabs, selfHarmTasksStates } from '../../../common/constants/appConstants'
import { CLIENT_HOME } from '../../../common/constants/routingConstants'
import { getClientFullName } from '../../../common/utils/utils'
import { getProviderTasks, patchProviderTask } from '../../../providers/dashboard/data/providersDashboardActions'
import { getProvidersDashboardTasks } from '../../../providers/data/providerSelectors'
import { selectLtClient } from '../../clients/clientDetails/data/ltClientDetailsAutoActions'
import { getLtClientsV2 } from '../../clients/data/ltClientsAutoActions'

const SelfHarmDashlet: React.FC<SelfHarmDashletProps> = ({
  clients,
  isLoading,
  providerId,
  selfHarmTasks,
  actions: { selectLtClient, getLtClientsV2, getProviderTasks, patchProviderTask },
}) => {
  const intl = useIntl()
  const navigate = useNavigate()

  const [activeTab, setActiveTab] = useState(selfHarmDashletTabs.NEW)
  const [isRefreshingClients, setIsRefreshingClients] = useState(false)
  const [idToSlide, setIdToSlide] = useState<string | null>(null)
  const { isPreferredNameEnabled } = useFlags()

  const { isMinWidthDesktopL } = useMediaQuerySize()

  const [, , hasFetchedProviderSelfHarmTasks] = useFetcher(
    [getProviderTasks, { providerId, type: 'self_harm' }],
    [providerId],
  )

  useEffect(() => {
    if (idToSlide) {
      const updatedStatusType =
        activeTab === selfHarmDashletTabs.NEW
          ? selfHarmTasksStates.ACKNOWLEDGED
          : selfHarmTasksStates.REQUIRES_ACKNOWLEDGMENT
      patchProviderTask({ id: idToSlide, data: { status: updatedStatusType } }).then(() => setIdToSlide(null))
    }
  }, [activeTab, idToSlide, patchProviderTask])

  const selfHarmTasksWithClientInfo =
    selfHarmTasks &&
    selfHarmTasks
      ?.filter((task: ProviderSelfHarmTask) => task?.status !== selfHarmTasksStates.INACTIVE)
      ?.map((task: ProviderSelfHarmTask) => {
        const patient = find(clients, (client) => client.id === task?.arguments?.patient_id)
        return { ...task, patient }
      })
      ?.filter((task: ProviderTaskWithClientObject) => task.patient)

  const displayedTasks = selfHarmTasksWithClientInfo
    ?.filter((task: ProviderTaskWithClientObject) =>
      activeTab === selfHarmDashletTabs.NEW
        ? task?.acknowledged_datetime === null
        : task?.acknowledged_datetime !== null,
    )
    ?.sort((a: ProviderTaskWithClientObject, b: ProviderTaskWithClientObject) => {
      const aDateTime = new Date(`${a?.arguments?.self_harm_date} ${a?.arguments?.self_harm_time}`).getTime()
      const bDateTime = new Date(`${b?.arguments?.self_harm_date} ${b?.arguments?.self_harm_time}`).getTime()
      return bDateTime - aDateTime
    })
  const handleCheckboxClick = (target: HTMLInputElement) => {
    target.disabled = true
    const selfHarmTaskId = target.id
    setIdToSlide(selfHarmTaskId)
  }

  const renderClientListItem = (task: ProviderTaskWithClientObject) => {
    if (!task || !task.patient) return
    return (
      <li key={task.id} className={`self-harm-row-${task.id}`}>
        <div className={styles['self-harm-item-border']}>
          <div
            className={classNames(
              styles['self-harm-item-wrapper'],
              {
                [styles.slideRight]: idToSlide === task.id && activeTab === selfHarmDashletTabs.NEW,
              },
              {
                [styles.slideLeft]: idToSlide === task.id && activeTab === selfHarmDashletTabs.ADDRESSED,
              },
            )}
          >
            <div className={styles['self-harm-item-checkbox-wrapper']}>
              <Checkbox
                key={task.id}
                input={{
                  name: task.id,
                  value: activeTab === selfHarmDashletTabs.ADDRESSED ? true : '',
                  onChange: (e: Event) => handleCheckboxClick(e.target as HTMLInputElement),
                  onBlur: () => {},
                  onFocus: () => {},
                  checked: activeTab === selfHarmDashletTabs.ADDRESSED,
                }}
                meta={{
                  touched: false,
                  error: '',
                  submitError: '',
                  warning: '',
                }}
                readOnly={false}
                stroked={false}
                className={''}
                fieldInfoId={''}
              >
                <></>
              </Checkbox>
            </div>
            <button
              className={styles['self-harm-item']}
              data-test-id={`SelfHarmDashlet-list-item-${task?.patient?.id}`}
              onClick={() => {
                task.patient && selectLtClient(task.patient)
                task.patient && navigate(CLIENT_HOME.route)
              }}
            >
              <div className={styles.left}>
                <div className={styles.name}>{getClientFullName(task.patient, isPreferredNameEnabled)}</div>
                {task?.arguments?.self_harm_date && (
                  <div className={styles.date}>
                    {intl.formatDate(task.arguments.self_harm_date, {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                      timeZone: 'UTC',
                    })}
                  </div>
                )}
              </div>
              <div className={styles.right}>
                {
                  <div className={styles['label-container']}>
                    <div className={styles.red}>ideation</div>
                    {isMinWidthDesktopL && <>•</>}
                    <div className={task?.arguments?.self_harm_plan ? styles.red : styles.gray}>
                      {task.arguments?.self_harm_plan ? 'plan' : 'no plan'}
                    </div>
                    {isMinWidthDesktopL && <>•</>}
                    <div className={task?.arguments?.self_harm_intent ? styles.red : styles.gray}>
                      {task?.arguments?.self_harm_intent ? 'intent' : 'no intent'}
                    </div>
                  </div>
                }
              </div>
            </button>
          </div>
        </div>
      </li>
    )
  }

  const isLoadingClientsOrTasks = isLoading || (!hasFetchedProviderSelfHarmTasks && isEmpty(selfHarmTasks))
  return (
    <div className={styles['self-harm-container']} data-test-id='SelfHarmDashlets-content'>
      <Dashlet
        isLoading={isLoadingClientsOrTasks}
        tabs={
          !isLoadingClientsOrTasks &&
          selfHarmTasksWithClientInfo &&
          selfHarmTasksWithClientInfo.length > 0 && (
            <>
              <NavButton
                text={selfHarmDashletTabs.NEW}
                isSelected={activeTab === selfHarmDashletTabs.NEW}
                onClick={() => setActiveTab(selfHarmDashletTabs.NEW)}
                styleType='dashlet'
                data-test-id='SelfHarmDashlets-Tab-new'
              />
              <NavButton
                text={selfHarmDashletTabs.ADDRESSED}
                isSelected={activeTab === selfHarmDashletTabs.ADDRESSED}
                onClick={() => setActiveTab(selfHarmDashletTabs.ADDRESSED)}
                styleType='dashlet'
                data-test-id='SelfHarmDashlets-Tab-addressed'
              />
            </>
          )
        }
        title={`Self harm${
          !isLoadingClientsOrTasks && selfHarmTasksWithClientInfo
            ? ` (${
                selfHarmTasksWithClientInfo?.filter((task: ProviderTaskWithClientObject) =>
                  activeTab === selfHarmDashletTabs.NEW
                    ? task?.acknowledged_datetime === null
                    : task?.acknowledged_datetime !== null,
                ).length
              })`
            : ''
        }`}
        buttons={
          <RefreshButton
            data-test-id='SelfHarmDashlets-refresh-button'
            size='20'
            isLoading={isRefreshingClients}
            onClick={() => {
              setIsRefreshingClients(true)
              Promise.all([
                getLtClientsV2({ providerId, status: 'active' }),
                getLtClientsV2({ providerId, status: 'inactive' }),
                getProviderTasks({ providerId, type: 'self_harm' }),
              ]).then(() => {
                setIsRefreshingClients(false)
              })
            }}
          />
        }
      >
        {!selfHarmTasks ? (
          ''
        ) : selfHarmTasksWithClientInfo?.length === 0 ? (
          <div className={styles['empty-row']}>No clients with self harm</div>
        ) : selfHarmTasksWithClientInfo?.length > 0 && displayedTasks.length === 0 ? (
          <div className={styles['empty-row']}>No {activeTab.toLowerCase()} self harm cases</div>
        ) : (
          displayedTasks?.map(renderClientListItem)
        )}
      </Dashlet>
    </div>
  )
}
interface ProviderTaskWithClientObject extends ProviderSelfHarmTask {
  patient: ClientObject | undefined
}

type SelfHarmDashletProps = {
  clients: ClientObject[]
  isLoading: boolean
  selfHarmTasks: ProviderSelfHarmTask[]
  providerId: string
  actions: {
    selectLtClient: (client: ClientObject) => void
    getLtClientsV2: ({ providerId, status }: { providerId: string; status: string }) => Promise<{}>
    getProviderTasks: ({ providerId, type }: { providerId: string; type: string }) => Promise<{}>
    patchProviderTask: ({ id, data }: { id: string; data: Object }) => Promise<{}>
  }
}

const mapStateToProps = ($$state: Map<string, any>) => {
  return {
    selfHarmTasks: getProvidersDashboardTasks($$state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  actions: bindActionCreators({ selectLtClient, getLtClientsV2, getProviderTasks, patchProviderTask }, dispatch),
})

// @ts-expect-error TS(2345): Argument of type '(wrappedComponentProps: SelfHarm... Remove this comment to see the full error message
export default connect(mapStateToProps, mapDispatchToProps)(toJS(SelfHarmDashlet))
