import React from 'react'
import { Image, ImageSourcePropType } from 'react-native'

import styled, { useTheme } from 'styled-components/native'

import { BodyText, BodyTextSize, Subhead, SubheadSize, ThemeType } from '@lyrahealth-inc/ui-core-crossplatform'

import { SchedulingLandingInfo } from '../constants'

const DescriptionContainer = styled.View(() => ({
  maxWidth: '240px',
}))

const InformationContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  display: 'flex',
  width: 'auto',
  paddingTop: theme.spacing['16px'],
  paddingBottom: theme.spacing['48px'],
  flexDirection: 'row',
  justifyContent: 'space-between',
}))

const InfoItem = styled.View(() => ({
  maxWidth: '253px',
}))

const SubheadContainer = styled.View(({ theme }) => ({
  marginBottom: theme.spacing['8px'],
  marginTop: theme.spacing['16px'],
}))

export const SchedulingInformation: React.FC = () => {
  const { colors } = useTheme()

  const generateSchedulingInfo = (
    informationElement: {
      image: ImageSourcePropType
      title: string
      description: string
    },
    index: number,
  ) => {
    return (
      <InfoItem key={index}>
        <Image
          source={informationElement.image}
          style={{
            width: 64,
            height: 64,
          }}
        />
        <SubheadContainer>
          <Subhead level='4' color={colors.textSecondary} size={SubheadSize.XSMALL} text={informationElement.title} />
        </SubheadContainer>
        <DescriptionContainer>
          <BodyText color={colors.textSecondary} size={BodyTextSize.SMALL} text={informationElement.description} />
        </DescriptionContainer>
      </InfoItem>
    )
  }

  const renderSchedulingInfoList = () => {
    const schedulingInfoList = SchedulingLandingInfo.map((informationElement, index) =>
      generateSchedulingInfo(informationElement, index),
    )
    return <InformationContainer>{schedulingInfoList}</InformationContainer>
  }

  return renderSchedulingInfoList()
}
