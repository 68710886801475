import React, { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { ViewStyle } from 'react-native'

import { noop } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'
import tinyColor from 'tinycolor2'

import { NavBarProps } from '@lyrahealth-inc/shared-app-logic'

import { Subhead } from '../../atoms'
import { BackButtonSmall } from '../../atoms/backButtonSmall/BackButtonSmall'
import { LyraLogo } from '../../atoms/icons/LyraLogo'
import { XButton } from '../../atoms/xButton/XButton'
import { IS_WEB } from '../../constants'
import { useMediaQuerySize } from '../../hooks/useMediaQuerySize'
import { Modal } from '../../organisms/modal/Modal'
import { SubheadSize } from '../../styles'
import { tID } from '../../utils'
import { CareNavigatorHeaderEntryPointButton } from '../careNavigatorHeaderEntryPointButton/CareNavigatorHeaderEntryPointButton'

type StyledViewProps = {
  isTransparent?: boolean
  backgroundColor?: string
  borderBottomColor?: string
  style?: ViewStyle
}

const StyledView = styled.View<StyledViewProps>(({ isTransparent, backgroundColor, theme, borderBottomColor }) => {
  const styles = {
    alignItems: 'center',
    flexDirection: 'row' as const,
    justifyContent: 'space-between',
    padding: theme.breakpoints.isMobileSized ? '16px' : '16px 24px',
    backgroundColor,
    width: '100%',
  }

  if (isTransparent) {
    return { ...styles, backgroundColor: 'transparent' }
  } else {
    return {
      ...styles,
      backgroundColor: backgroundColor ?? theme.colors.backgroundPrimary,
      borderBottomColor: borderBottomColor ?? theme.colors.dividerPrimary,
      borderBottomWidth: '1px',
    }
  }
})

const RightContainer = styled.View({
  flexDirection: 'row',
})

const StyledCareNavigatorHeaderEntryPointButton = styled(CareNavigatorHeaderEntryPointButton)(({ theme }) => ({
  marginRight: theme.breakpoints.isMobileSized ? theme.spacing['16px'] : theme.spacing['24px'],
}))

const StyledXButton = styled(XButton)({
  alignSelf: 'center',
  top: 0,
})

const LeftContainer = styled.View<{
  shouldShowCareNavigatorHeaderEntrypoint?: boolean
  placeholderWidth?: number
}>(({ shouldShowCareNavigatorHeaderEntrypoint = false, placeholderWidth = 0 }) => ({
  ...(shouldShowCareNavigatorHeaderEntrypoint && { width: placeholderWidth }),
}))

const PlaceHolderView = styled.View({})

export const NavBar: FunctionComponent<NavBarProps> = ({
  onBack,
  onExit,
  isTransparent,
  backgroundColor,
  borderBottomColor,
  backIconColor,
  onLayout,
  showBackButton = true,
  showCloseButton = true,
  style,
  title,
  a11yFocusedElementOnLoad,
  shouldShowCareNavigatorHeaderEntrypoint = false,
  isInternational = false,
  testId = 'NavBar',
  onCareNavigatorHeaderEntryPointButtonPress,
  careNavigatorHeaderEntryPointModal,
  careNavigatorHeaderEntryPointModalProps: {
    isShowingBottomSheet = false,
    setIsShowingBottomSheet,
    snapPoints = [],
    bottomSheetRef,
    openBottomSheet = noop,
    closeBottomSheet,
  } = {},
  careNavigatorConsolidatedBottomSheet,
  isSidePanel = false,
  useTitle = false,
  alternateExitButtonText,
}) => {
  const { colors } = useTheme()
  const { isMinWidthTablet } = useMediaQuerySize()
  const { formatMessage } = useIntl()
  const lightBgColor = tinyColor(backgroundColor || colors.backgroundPrimary).isLight()

  // Calculated width to correctly center the lyra logo if the care navigator entry point exists
  const placeholderWidth = isInternational ? 197 : 207

  const openBottomSheetWeb = () => {
    setIsShowingBottomSheet && setIsShowingBottomSheet(!isShowingBottomSheet)
  }
  // Web versions are handled by the useState whereas native mobile utilizes snap points
  const handleOnPress = () => {
    IS_WEB ? openBottomSheetWeb() : openBottomSheet()
    onCareNavigatorHeaderEntryPointButtonPress?.()
  }

  return (
    <>
      <StyledView
        isTransparent={isTransparent}
        backgroundColor={backgroundColor}
        borderBottomColor={borderBottomColor}
        onLayout={onLayout}
        style={style}
        ref={a11yFocusedElementOnLoad}
      >
        <LeftContainer
          shouldShowCareNavigatorHeaderEntrypoint={shouldShowCareNavigatorHeaderEntrypoint}
          placeholderWidth={placeholderWidth}
        >
          {showBackButton && onBack ? (
            <BackButtonSmall
              testID={tID(`${testId}-backButton`)}
              onPress={onBack}
              accessibilityLabel={formatMessage({
                defaultMessage: 'Back',
                description: 'Back button to go to the previous page',
              })}
              color={backIconColor}
            />
          ) : (
            <PlaceHolderView />
          )}
        </LeftContainer>
        {useTitle || !isMinWidthTablet ? (
          title && <Subhead text={title} size={SubheadSize.SMALL} />
        ) : (
          <LyraLogo fillColor={colors.iconActive} height={24} />
        )}
        {(showCloseButton && onExit) || shouldShowCareNavigatorHeaderEntrypoint ? (
          <RightContainer>
            {shouldShowCareNavigatorHeaderEntrypoint && (
              <StyledCareNavigatorHeaderEntryPointButton
                isActive={isShowingBottomSheet}
                isInternational={isInternational}
                onPress={handleOnPress}
                suppressAccessibilitySelected={IS_WEB}
                lightBgColor={lightBgColor}
              />
            )}
            {onExit && (
              <StyledXButton
                testID={tID(`${testId}-exitButton`)}
                onPress={onExit}
                size={24}
                alternateText={alternateExitButtonText}
              />
            )}
          </RightContainer>
        ) : (
          <PlaceHolderView />
        )}
      </StyledView>
      {careNavigatorHeaderEntryPointModal || (
        <Modal
          modalContents={careNavigatorConsolidatedBottomSheet}
          onRequestClose={() => setIsShowingBottomSheet && setIsShowingBottomSheet(false)}
          visible={isShowingBottomSheet}
          bottomSheetRef={bottomSheetRef}
          snapPoints={snapPoints}
          onCloseEnd={() => {
            setIsShowingBottomSheet && setIsShowingBottomSheet(false)
            closeBottomSheet && closeBottomSheet()
          }}
          onLayout={openBottomSheet}
          isSidePanel={isSidePanel}
          scrollable
        />
      )}
    </>
  )
}
