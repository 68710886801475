import { createReducer } from '@reduxjs/toolkit'

import { ClientObject, ClientSessionData } from '@lyrahealth-inc/shared-app-logic'

import { GET_CLIENT_SESSION, GET_CLIENTS } from '../../common/constants/reduxConstants'

type ClientsDataState = {
  clientList?: ClientObject[]
  sessionData?: ClientSessionData[]
}
const initialState = {}

export default createReducer<ClientsDataState>(initialState, (builder) => {
  builder.addCase(GET_CLIENTS, (state, action: any) => {
    state.clientList = action.payload.data.clients
    return state
  })
  builder.addCase(GET_CLIENT_SESSION, (state, action: any) => {
    state.sessionData = action.payload.data
    return state
  })
})
