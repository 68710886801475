import React, { useState } from 'react'
import classNames from 'classnames'
import styles from './drawer.module.scss'
import ChevronIcon from '../../../atoms/icons/ChevronIcon'
import DefaultButton from '../../../atoms/buttons/defaultButton/DefaultButton'

const Drawer: React.FC<DrawerProps> = ({ title, selectedValue, children }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <div className={classNames(styles['drawer-group'], isExpanded ? styles.open : styles.closed)}>
      <DefaultButton
        unstyled
        customClass={styles['drawer-container']}
        onClick={() => {
          setIsExpanded(!isExpanded)
        }}
        data-test-id={`Drawer-${title}`}
      >
        <div className={styles['main-container']}>
          <div className={styles['left-container']}>
            <p className={styles['drawer-title']}>{title}</p>
            <p
              className={classNames(
                { [styles['animate-showing']]: isExpanded },
                { [styles['animate-hiding']]: !isExpanded },
                styles['drawer-active'],
              )}
            >
              {selectedValue}
            </p>
          </div>
          <ChevronIcon direction={isExpanded ? 'up' : 'down'} isFilled fillColor={styles.x_soft_black} />
        </div>
      </DefaultButton>
      <div className={classNames({ [styles.hidden]: !isExpanded }, { [styles['animate-open']]: isExpanded })}>
        {children}
      </div>
    </div>
  )
}

type DrawerProps = {
  title: string
  selectedValue: string
}

export default Drawer
