import { sessionTracking } from '../constants/appConstants'

// @ts-expect-error TS(7030): Not all code paths return a value.
const sessionLimitWarning = ({
  client: {
    // @ts-expect-error TS(7031): Binding element 'openBhbSessionCount' implicitly h... Remove this comment to see the full error message
    open_bhb_session_count: openBhbSessionCount,
    // @ts-expect-error TS(7031): Binding element 'openBhbSessionLimit' implicitly h... Remove this comment to see the full error message
    open_bhb_session_limit: openBhbSessionLimit,
    // @ts-expect-error TS(7031): Binding element 'sessionsRemaining' implicitly has... Remove this comment to see the full error message
    sessions_remaining: sessionsRemaining,
    // @ts-expect-error TS(7031): Binding element 'sessionsLimit' implicitly has an ... Remove this comment to see the full error message
    sessions_limit: sessionsLimit,
  },
}) => {
  const warningThreshold = sessionTracking.WARNING_THRESHOLD
  const showEAPWarning = sessionsLimit > 0 && sessionsRemaining <= warningThreshold
  const showHPIWarning =
    openBhbSessionCount > 0 && openBhbSessionLimit > 0 && openBhbSessionLimit - openBhbSessionCount <= warningThreshold

  if (showEAPWarning || showHPIWarning) {
    return `Less than ${warningThreshold} sessions remaining.`
  }
}

export default sessionLimitWarning
