import React, { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { ViewStyle } from 'react-native'
import Svg, { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

type LocationOnMapWithSkyProps = {
  width?: number
  height?: number
  style?: ViewStyle
}

export const LocationOnMapWithSky: FunctionComponent<LocationOnMapWithSkyProps> = ({
  width = 160,
  height = 118,
  style,
}) => {
  const { formatMessage } = useIntl()
  return (
    <Svg
      accessibilityLabel={formatMessage({
        defaultMessage: 'Image of a location marker placed on a map with clouds in the background',
        description: 'Label for the Image of a location marker placed on a map with clouds in the background',
      })}
      style={style}
      width={width}
      height={height}
      viewBox='0 0 160 118'
      fill='none'
    >
      <G clip-path='url(#clip0_5344_81217)'>
        <Path
          d='M1.11133 50.4539C1.44406 34.8752 13.9884 20.8662 25.2162 12.8933C54.9974 -6.26581 91.9305 -1.52802 123.001 10.6293C138.061 17.4539 151.176 27.9874 155.336 47.8825C161.945 83.6402 120.133 94.7324 98.3102 97.3472C64.7677 99.2568 2.36088 107.705 1.11133 50.4539Z'
          fill='#E2F7F7'
        />
        <Path
          d='M123.472 38.9422C132.871 35.0217 138.571 47.4901 127.855 49.8065C119.819 51.0362 112.484 49.8897 103.616 49.1501C95.5421 48.4774 92.7066 46.9331 95.2799 41.6384C99.9327 32.0615 124.052 21.5082 123.472 38.9404V38.9422Z'
          fill='#FEFEFE'
        />
        <Path
          d='M36.3889 22.0615C41.6583 14.0651 57.7559 8.7794 58.7541 22.4955C58.7795 29.0506 50.7704 30.4738 42.5498 31.2188C35.4974 31.8572 28.3292 32.4756 25.4992 29.9783C21.5172 26.4629 34.9332 11.4412 36.3889 22.0615Z'
          fill='#FEFEFE'
        />
        <Path
          d='M138.033 69.4485C136.086 67.5967 117.27 60.8553 112.167 59.4322C111.545 59.2586 109.963 59.2911 109.178 59.4882C106.802 60.0886 102.849 61.4412 98.6879 62.8282C92.4745 64.8987 81.876 69.4051 79.7747 69.1935C78.5324 69.0687 72.328 66.1573 71.0315 65.4846C67.1942 63.4973 59.4365 59.6257 56.3859 58.5371C54.4492 57.8445 45.2449 61.0199 38.234 63.2152C35.0044 64.226 31.6897 65.0524 28.442 66.0127C26.1092 66.7016 24.7566 66.7016 22.5903 70.5353C17.8977 78.8372 6.81448 101.91 2.82895 109.881C-0.93416 117.407 -2.35188 119.289 7.69333 116.363C19.3968 112.955 39.3642 105.589 43.0369 105.906C45.3136 106.101 72.9971 117.345 75.4148 117.526C78.6662 117.769 111.469 105.705 113.681 105.589C116.31 105.45 146.341 112.787 152.511 115.04C158.182 117.112 162.916 118.931 157.84 110.398C152.333 101.143 139.914 71.2315 138.031 69.443L138.033 69.4485Z'
          fill='#B2E188'
        />
        <Path
          d='M157.842 110.401C155.697 106.797 152.504 100.06 149.292 93.0687C148.607 92.6745 147.831 92.2731 146.952 91.8626C138.909 88.1013 129.412 86.3544 121.175 86.349C118.549 86.3472 114.627 86.4774 112.159 87.3707C101.826 91.1067 96.6522 98.2948 92.1386 103.609C90.1043 106.004 88.6287 109.36 90.5328 112.242C90.7046 112.503 90.8909 112.75 91.088 112.989C101.122 109.642 112.415 105.66 113.681 105.593C116.31 105.454 146.341 112.79 152.511 115.043C158.182 117.116 162.916 118.935 157.84 110.401H157.842Z'
          fill='#B6EAEF'
        />
        <Path
          d='M43.6906 104.161C43.6346 104.161 43.5767 104.155 43.5188 104.143C43.0794 104.049 42.7991 103.617 42.8932 103.177C43.4483 100.582 45.2639 93.6926 47.5134 85.924C50.3543 76.1157 53.5749 65 54.3416 61.0181C54.4266 60.5768 54.8534 60.2875 55.2946 60.3725C55.7358 60.4575 56.0252 60.8842 55.9402 61.3255C55.1608 65.3815 51.9293 76.5352 49.0776 86.3779C46.8353 94.1175 45.0288 100.982 44.4845 103.521C44.4031 103.902 44.065 104.165 43.6888 104.165L43.6906 104.161Z'
          fill='#7ECB5B'
        />
        <Path
          d='M75.6179 114.837C75.5835 114.837 75.5474 114.835 75.513 114.83C75.0681 114.772 74.7517 114.365 74.8096 113.919C75.5365 108.286 78.164 81.1537 78.8729 72.3598C78.909 71.9114 79.2996 71.5768 79.7499 71.6148C80.1984 71.651 80.5311 72.0434 80.4949 72.4919C79.7842 81.2984 77.1531 108.479 76.4244 114.128C76.3719 114.539 76.0211 114.837 75.6179 114.837Z'
          fill='#7ECB5B'
        />
        <Path
          d='M112.638 86.0018C112.202 86.0018 111.842 85.6582 111.826 85.2188C111.686 81.6203 111.122 71.6239 110.426 62.1501C110.393 61.7016 110.73 61.311 111.178 61.2785C111.625 61.2459 112.016 61.5823 112.05 62.0307C112.748 71.5208 113.314 81.5425 113.453 85.1555C113.469 85.604 113.12 85.9819 112.672 86C112.661 86 112.65 86 112.639 86L112.638 86.0018Z'
          fill='#7ECB5B'
        />
        <Path
          d='M113.698 103.808C113.263 103.808 112.903 103.465 112.886 103.025C112.836 101.712 112.711 100.631 112.597 98.7866C112.4 95.5877 112.176 91.9638 112.176 89.3996C112.176 88.9493 112.539 88.5859 112.99 88.5859C113.44 88.5859 113.803 88.9493 113.803 89.3996C113.803 91.9132 114.026 95.5117 114.221 98.6853C114.335 100.539 114.46 101.629 114.512 102.962C114.528 103.41 114.179 103.788 113.731 103.806C113.72 103.806 113.709 103.806 113.698 103.806V103.808Z'
          fill='#6CCCD4'
        />
        <Path
          d='M123.363 79.4141C123.182 79.4141 123.001 79.3544 122.849 79.2314C122.5 78.9475 122.448 78.4358 122.732 78.0868C125.032 75.2622 127.101 73.886 129.048 73.886C129.088 73.886 129.128 73.886 129.167 73.886C130.641 73.9294 131.972 74.7595 133.124 76.3562C133.388 76.7197 133.305 77.2296 132.941 77.4918C132.578 77.7558 132.068 77.6727 131.806 77.3092C130.952 76.1284 130.075 75.5407 129.12 75.5135C127.692 75.4683 125.974 76.6835 123.994 79.1139C123.833 79.311 123.598 79.4141 123.363 79.4141Z'
          fill='#7ECB5B'
        />
        <Path
          d='M106.489 87.1808C105.295 85.2098 103.856 84.1519 102.21 84.0362C101.521 83.9874 100.813 84.1103 100.086 84.396C98.7239 81.302 96.9753 79.5733 94.8831 79.2604C90.8776 78.6619 87.0784 83.3364 85.0386 85.8499L84.8469 86.085C84.563 86.434 84.6154 86.9458 84.9644 87.2297C85.1145 87.3526 85.2972 87.4123 85.478 87.4123C85.7149 87.4123 85.9482 87.3092 86.1091 87.1121L86.3026 86.8752C88.0078 84.7758 91.5521 80.4069 94.6426 80.8698C96.1706 81.0995 97.516 82.5461 98.6462 85.1664C97.5829 85.8789 96.4889 86.9223 95.3659 88.3002C95.082 88.6492 95.1344 89.161 95.4834 89.4449C95.6335 89.5678 95.8162 89.6275 95.997 89.6275C96.2339 89.6275 96.4671 89.5244 96.6281 89.3273C98.6697 86.821 100.559 85.5497 102.096 85.6582C103.183 85.7342 104.192 86.5299 105.095 88.0217C105.328 88.4069 105.827 88.5299 106.212 88.2966C106.597 88.0633 106.72 87.5642 106.487 87.179L106.489 87.1808Z'
          fill='#7ECB5B'
        />
        <Path
          d='M31.4989 98.7902C30.6074 97.8987 29.5297 97.4286 28.4085 97.3689C28.4175 97.2839 28.4157 97.1971 28.3958 97.1085C27.7105 94.1067 26.5731 92.4575 24.9166 92.0705C22.998 91.622 20.6725 93.0542 18.0071 96.3273C17.7232 96.6763 17.7756 97.1881 18.1246 97.472C18.4736 97.7559 18.9854 97.7034 19.2693 97.3544C21.4465 94.6817 23.3199 93.3671 24.5477 93.6546C25.5098 93.8788 26.2928 95.1989 26.81 97.4701C26.8172 97.4973 26.8262 97.5244 26.8353 97.5497C25.8877 97.8173 24.9564 98.3707 24.121 99.2043C23.8027 99.5226 23.8027 100.038 24.121 100.354C24.2801 100.514 24.4881 100.593 24.696 100.593C24.904 100.593 25.1119 100.514 25.2711 100.354C26.9383 98.6871 28.9329 98.5244 30.347 99.9385C30.6653 100.257 31.1807 100.257 31.4971 99.9385C31.8154 99.6202 31.8154 99.1049 31.4971 98.7884L31.4989 98.7902Z'
          fill='#7ECB5B'
        />
        <Path
          d='M43.8425 53.5027C42.8371 51.4864 42.1048 49.3363 41.5243 47.1591C40.3778 42.8517 40.1916 38.2748 41.7051 34.0307C44.3416 26.8137 51.9366 23.3996 59.1355 22.7884C67.2404 22.3182 75.5532 26.868 77.6292 35.7251C77.8661 36.9819 77.9818 38.2586 77.9854 39.5352C78.0089 46.3833 74.8679 52.8029 71.5424 58.622C68.98 63.1067 61.8697 72.2387 59.5044 74.7559C59.0812 75.2061 58.9926 75.1718 58.5731 74.7034C50.159 65.3219 46.8371 59.5081 43.8425 53.5045V53.5027Z'
          fill='#00A4B3'
        />
        <Path
          d='M50.4277 41.0361C50.4151 31.235 65.0516 29.0434 67.3572 38.5913C68.5037 43.3417 65.5923 48.8896 60.7804 49.669C54.614 50.669 50.435 46.4502 50.4277 41.0361Z'
          fill='#6CCCD4'
        />
      </G>
      <Defs>
        <ClipPath id='clip0_5344_81217'>
          <Rect width='160' height='117.678' fill='white' />
        </ClipPath>
      </Defs>
    </Svg>
  )
}
