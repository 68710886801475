import React, { FunctionComponent } from 'react'

import { BaseButton, BaseButtonProps, ButtonType } from '../baseButton/BaseButton'

export type TertiaryIconButtonProps = Omit<
  BaseButtonProps,
  'rightIcon' | 'loading' | 'buttonType' | 'text' | 'selected'
>

export const TertiaryIconButton: FunctionComponent<TertiaryIconButtonProps> = (props) => {
  return <BaseButton buttonType={ButtonType.TERTIARY_ICON} {...props} />
}

export default TertiaryIconButton
