import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'

import styled, { useTheme } from 'styled-components/native'

import { ZoomParticipantEmptyView } from './ZoomParticipantEmptyView'
import { ZoomParticipantViewInner } from './ZoomParticipantViewInner'
import { AvatarDetails, BodyText } from '../../atoms'
import { useZoom } from '../../hooks/useZoom'
import { RemoteParticipantStatus } from '../../hooks/useZoom/types'
import { BodyTextSize } from '../../styles'
import { Flex1View } from '../../templates'
import { ThemeType } from '../../utils'

const BackgroundContainer = styled(Flex1View)<{ backgroundColor: string }>(({ backgroundColor }) => ({
  backgroundColor,
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  alignSelf: 'stretch',
}))

export type ZoomParticipantViewProps = {
  participantFirstName?: string
  participantName?: string
  avatarDetails?: AvatarDetails
  isFullscreen?: boolean
  participantTitle?: string
  landscape?: boolean
  show?: boolean
}

export const ZoomParticipantView: React.FC<ZoomParticipantViewProps> = ({
  participantFirstName,
  participantName,
  avatarDetails,
  isFullscreen,
  participantTitle,
  landscape,
  show,
}) => {
  const {
    remoteParticipantStatus,
    sessionStarted,
    remoteParticipant,
    config: { showRemoteParticipantStatus },
  } = useZoom()
  const { colors } = useTheme() as ThemeType
  const { formatMessage } = useIntl()
  const colorsForStatus = useMemo(() => {
    switch (remoteParticipantStatus) {
      case RemoteParticipantStatus.LEFT:
        return colors.components.zoomParticipantStatus.left
      case RemoteParticipantStatus.READY:
        return colors.components.zoomParticipantStatus.ready
      case RemoteParticipantStatus.WAITING:
        return colors.components.zoomParticipantStatus.waiting
    }
  }, [remoteParticipantStatus, colors])

  const text = useMemo(() => {
    if (!participantFirstName) {
      return ''
    }

    switch (remoteParticipantStatus) {
      case RemoteParticipantStatus.LEFT:
        return formatMessage(
          {
            defaultMessage: '{firstName} has left',
            description: 'Shown during video session when other user has left',
          },
          { firstName: participantFirstName },
        )
      case RemoteParticipantStatus.READY:
        return formatMessage(
          {
            defaultMessage: '{firstName} is ready',
            description: 'Shown during video session when other user is ready for session',
          },
          { firstName: participantFirstName },
        )
      case RemoteParticipantStatus.WAITING:
        return formatMessage(
          {
            defaultMessage: 'Waiting for {firstName}',
            description: 'Shown during video session when other user has not joined',
          },
          { firstName: participantFirstName },
        )
    }
  }, [formatMessage, participantFirstName, remoteParticipantStatus])
  const showParticipantStatus =
    showRemoteParticipantStatus && (!sessionStarted || remoteParticipantStatus === RemoteParticipantStatus.LEFT)
  const showEmptyView = sessionStarted && !showParticipantStatus && !remoteParticipant?.isVideoOn
  return (
    <>
      {show && showParticipantStatus && (
        <BackgroundContainer backgroundColor={colorsForStatus.background}>
          <BodyText size={BodyTextSize.SMALL} color={colorsForStatus.text} text={text} />
        </BackgroundContainer>
      )}
      {show && showEmptyView && (
        <ZoomParticipantEmptyView
          participantFirstName={participantFirstName}
          participantName={participantName}
          avatarDetails={avatarDetails}
          isFullscreen={isFullscreen}
          participantTitle={participantTitle}
        />
      )}
      <ZoomParticipantViewInner show={show} isFullscreen={isFullscreen} landscape={landscape} />
    </>
  )
}
