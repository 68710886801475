import {
  GET_PLATFORM_ENGAGEMENT_METRICS_CURRENT,
  GET_PLATFORM_ENGAGEMENT_METRICS_PREVIOUS,
} from '../../../../common/constants/reduxConstants'

type PEMetricsQuarter = {
  start_date: string
  end_date: string
}
export type PEMetricsQuarters = {
  start_date: string
  end_date: string
  quarters: PEMetricsQuarter[]
}

export type PEMetric = {
  engagement_rate: number | null
  assignment_rate: number | null
  feedback_rate: number | null
}
export type PEMetrics = { [key: string]: PEMetric }

export const getPlatformEngagementMetrics = ({
  providerId,
  data,
  isCurrent,
}: {
  providerId: string
  data: PEMetricsQuarters
  isCurrent?: boolean
}) => ({
  action: isCurrent !== false ? GET_PLATFORM_ENGAGEMENT_METRICS_CURRENT : GET_PLATFORM_ENGAGEMENT_METRICS_PREVIOUS,
  request: {
    method: 'post',
    url: `/v1/providers/${providerId}/platform-engagement`,
    data,
  },
})
