import { useRef, useEffect, useCallback } from 'react'

export default ({ saveMessageDraft, inputValue, selectedConversationId }: UseSaveMessageDraft) => {
  // memoize the draft function as it depends on inputValue
  const saveDraft = () => {
    saveMessageDraft({ content: inputValue, conversationId: selectedConversationId })
  }

  const saveMessageDraftRef = useRef(saveDraft)

  // manually update the saveDraft function each re-render so inputValue and selectedConversationId is up to date
  useEffect(() => {
    saveMessageDraftRef.current = saveDraft
  })

  const saveDraftMemoized = useCallback(() => {
    saveMessageDraftRef.current()
  }, [])

  return { saveDraft: saveDraftMemoized }
}

export interface UseSaveMessageDraft {
  saveMessageDraft: ({ content, conversationId }: { content: string; conversationId: string }) => void
  inputValue: string
  selectedConversationId: string
}
