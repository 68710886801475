import React, { FunctionComponent } from 'react'

import { StatusBar, StatusBarStyle } from 'expo-status-bar'
import { useTheme } from 'styled-components/native'

const DARK_STYLE = 'dark'
const LIGHT_STYLE = 'light'

export type ThemedStatusBarProps = {
  defaultStyle: StatusBarStyle
}

/**
 * Themed status bar component to show correct status bar text color
 */
export const ThemedStatusBar: FunctionComponent<ThemedStatusBarProps> = ({ defaultStyle }) => {
  const { isDarkMode } = useTheme()
  const inverseStyle = defaultStyle === DARK_STYLE ? LIGHT_STYLE : DARK_STYLE
  return <StatusBar style={isDarkMode ? inverseStyle : defaultStyle} />
}
