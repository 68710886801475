import { Map } from 'immutable'
import { createSelector } from 'reselect'

const getPaymentsState = (state: Map<string, any>) => state?.get('payments')

export const getPaymentsDashboard = createSelector(
  getPaymentsState,
  (paymentsState) => paymentsState?.paymentsDashboard,
)

export const getPaymentsData = createSelector(
  getPaymentsDashboard,
  (paymentsDashboard) => paymentsDashboard?.paymentsData,
)

export const getPaymentsBalances = createSelector(getPaymentsDashboard, (dashboard) => dashboard.balances)

export const getPaymentsDataAllData = createSelector(getPaymentsData, (paymentsData) => paymentsData?.all?.data)
