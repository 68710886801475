import React from 'react'
import styles from './refreshButton.module.scss'
import RefreshIcon from '../../icons/RefreshIcon'
import LoadingIndicator from '../../loadingIndicator/LoadingIndicator'
import DefaultButton from '../defaultButton/DefaultButton'

const RefreshButton: React.FC<RefreshButtonProps> = ({ size = '20', isLoading, onClick = () => {}, ...props }) => {
  return isLoading ? (
    <div style={{ width: size, height: size }}>
      <LoadingIndicator size={size} />
    </div>
  ) : (
    <DefaultButton unstyled onClick={onClick} customClass={styles['refresh-button']}>
      <RefreshIcon width={size} {...props} />
    </DefaultButton>
  )
}

type RefreshButtonProps = {
  size?: string | number
  isLoading: boolean
  onClick?: () => void
}

export default RefreshButton
