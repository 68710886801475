import React, { FunctionComponent, ReactElement, ReactNode } from 'react'
import { Platform, Pressable, View } from 'react-native'

import styled, { useTheme } from 'styled-components/native'

import { InfoPopoverPlacement, InfoPopoverTriggerAction, spacing } from '@lyrahealth-inc/shared-app-logic'

import { MenuDotsIcon } from '../../atoms'
import { InfoPopover } from '../../atoms/'
import { tID } from '../../utils'

const MenuContainer = styled(View)({
  display: 'flex',
  flexDirection: 'row',
})

const TriggerContainer = styled(Pressable)(({ theme }) => {
  return {
    borderRadius: Platform.OS === 'web' ? '50%' : 0,
    padding: theme.spacing['12px'],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
})

export type ChatMenuProps = {
  children: ReactNode
  activeColor?: string
  hoverColor?: string
  triggerIcon?: ReactElement
}

export const ChatMenu: FunctionComponent<ChatMenuProps> = ({ children, activeColor, hoverColor, triggerIcon }) => {
  const { colors } = useTheme()
  const { active, hover } = colors.components.chatMenu

  const icon = triggerIcon || <MenuDotsIcon />

  const trigger = (
    <TriggerContainer
      style={({ pressed, hovered }) => {
        const pressedStyles: {
          backgroundColor?: string
          cursor?: string
        } = {}
        if (hovered) {
          pressedStyles.backgroundColor = hoverColor || hover.background
        }
        if (pressed) {
          pressedStyles.backgroundColor = activeColor || active.background
        }
        pressedStyles.cursor = 'pointer'
        return [pressedStyles]
      }}
    >
      {icon}
    </TriggerContainer>
  )
  return (
    <MenuContainer testID={tID('ChatMenu')}>
      <InfoPopover
        content={<>{children}</>}
        offset={0}
        placement={InfoPopoverPlacement.BOTTOM_RIGHT}
        shouldDisplayCloseButton={false}
        trigger={trigger}
        popoverTriggerAction={InfoPopoverTriggerAction.PRESS}
        closeIconColor={colors.iconDefault}
        popoverStyle={{
          container: {
            borderRadius: spacing['12px'],
            width: '140px',
            paddingLeft: 0,
            paddingRight: 0,
          },
        }}
        allowContentUnderCloseButton
        clickToClose={true}
      />
    </MenuContainer>
  )
}
