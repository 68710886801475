import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

type MemberPreferencesRaceEthnicityIllustrationIconProps = {
  size?: number
}

export const MemberPreferencesRaceEthnicityIllustrationIcon: FunctionComponent<
  MemberPreferencesRaceEthnicityIllustrationIconProps
> = ({ size = 519, ...props }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 519 519' fill='none' {...props}>
      <Path
        d='M260.67 0.0225624C224.931 0.0225624 190.275 6.15955 157.628 18.2304C79.9 47.0427 25.2538 110.894 7.70023 193.45C-3.89686 247.284 -2.40774 297.057 12.2578 345.566C31.0072 407.523 77.012 459.777 138.472 488.905C168.999 503.413 204.061 511.671 242.665 513.498C247.629 513.724 252.683 513.837 257.692 513.837C288.67 513.837 319.829 509.595 350.311 501.225C352.657 500.638 354.891 500.051 357.237 499.329C384.628 491.297 409.289 478.617 430.385 461.673C453.286 443.374 472.148 420.18 486.362 392.744C489.16 387.352 491.845 381.666 494.801 374.852C511.068 337.083 518.92 294.801 517.544 252.519C516.889 233.837 514.114 213.96 509.286 193.428C501.366 160.103 488.461 128.764 471.945 102.727C467.41 95.7324 463.281 89.8662 459.152 84.5866C429.482 46.2981 383.229 19.4488 325.47 6.92667C304.261 2.32393 282.443 0 260.648 0L260.67 0.0225624Z'
        fill='white'
      />
      <Path
        d='M498.269 255.109C497.63 236.871 494.859 217.989 490.49 199.214C483.35 168.959 471.628 139.134 455.537 113.6C451.807 107.807 447.971 102.228 443.815 96.8638C413.658 57.7048 367.942 35.9259 321.16 25.7338C269.051 14.3616 213.638 17.6874 163.553 36.355C91.0898 63.3909 41.7509 122.612 25.6598 198.785C19.4791 227.644 17.6675 260.259 20.4382 291.586H20.2251C21.8235 309.396 25.0204 326.776 29.8158 342.761C47.6119 401.983 90.8767 448.974 145.97 475.259C175.808 489.527 209.162 496.608 243.049 498.218C277.683 499.827 312.848 495.428 345.35 486.416C347.482 485.88 349.506 485.343 351.638 484.7C377.106 477.19 399.272 465.603 418.24 450.261C438.913 433.632 455.963 412.711 469.177 387.07C472.054 381.491 474.612 375.913 477.063 370.226C492.621 333.857 499.548 294.376 498.162 255.217L498.269 255.109Z'
        fill='#FFD2C4'
      />
      <Path
        d='M255.698 254.359C266.035 261.439 278.716 265.194 291.29 264.229C295.659 263.907 300.135 263.049 304.078 261.118C319.849 253.608 322.513 231.507 312.177 218.418C311.964 218.418 311.75 218.204 311.537 218.096C308.66 217.023 301.947 215.736 302.266 211.659C302.373 209.835 303.545 208.333 304.93 207.26C314.947 198.785 317.718 185.267 317.079 172.071C316.865 172.178 316.439 170.891 316.333 170.784C314.201 169.389 301.414 164.346 288.2 147.073C274.879 153.725 253.886 168.209 240.992 171.534C241.418 177.006 242.91 183.336 244.189 188.7C245.468 193.957 250.903 202.003 250.476 207.26C250.476 208.226 250.157 209.084 249.73 210.05C248.558 212.625 246.32 214.985 244.402 216.702C242.484 218.525 240.459 220.135 238.328 221.744C240.779 234.511 244.509 246.634 255.698 254.359Z'
        fill='#E6B087'
      />
      <Path
        d='M316.662 172.285C316.662 172.285 316.981 172.285 317.088 172.178C318.473 170.891 320.072 161.557 319.646 159.84C320.392 162.308 329.023 167.565 331.368 167.565C334.884 168.101 338.507 167.672 341.811 166.385C360.566 158.66 360.033 129.8 344.262 119.287C339.36 116.068 333.499 114.888 327.744 115.853C324.974 116.283 322.31 117.248 319.965 118.75C319.326 119.179 314.531 123.256 314.104 122.934C310.375 119.93 306.005 117.785 301.423 116.604C296.841 115.424 291.939 115.317 287.25 116.175C285.972 116.497 284.586 116.604 283.307 116.497C282.561 116.283 281.922 116.068 281.283 115.746C278.299 114.459 275.102 113.708 271.905 113.386C266.577 112.957 258.158 113.386 254.322 117.677C251.871 111.347 243.985 108.343 237.805 107.056C230.665 105.447 223.206 106.734 217.025 110.596C204.024 118.857 199.229 140.314 209.352 152.759C212.656 156.514 217.131 159.089 222.033 160.055C227.148 161.128 233.436 161.02 237.805 157.695C237.698 160.699 238.444 165.419 241.108 172.715C241.321 172.715 241.641 172.5 241.854 172.5C254.855 169.174 275.102 155.763 288.422 149.112C301.636 166.277 314.531 170.676 316.662 172.071V172.285Z'
        fill='#073345'
      />
      <Path
        d='M326.824 304.998C330.234 294.162 335.242 283.541 339.185 273.349C343.874 261.226 348.243 247.815 353.571 234.834C345.472 230.65 338.333 226.573 327.463 222.925C323.84 221.745 315.954 218.741 312.011 218.205C312.011 218.205 312.118 218.419 312.224 218.526C322.561 231.615 319.79 248.995 304.019 256.505C300.076 258.437 295.601 259.295 291.231 259.617C278.764 260.475 265.976 256.827 255.639 249.746C244.45 242.022 240.827 234.619 238.376 221.852V221.53C225.375 228.504 215.145 232.152 204.169 237.409C197.349 240.734 190.422 245.133 184.135 249.639C184.668 255.754 184.881 262.299 184.455 268.736C184.029 275.173 182.963 281.503 180.512 287.082C179.446 289.549 177.954 291.695 176.143 293.519C175.29 294.377 174.225 294.806 173.266 295.45C179.553 301.565 187.971 307.788 191.275 310.148C191.275 310.148 191.488 310.041 191.595 309.933C192.873 310.684 194.152 311.65 195.431 312.401C219.94 328.065 241.573 348.449 257.238 373.017C264.697 384.818 272.157 397.8 278.87 411.211C292.404 386.32 308.708 363.254 329.275 343.621C324.053 336.004 322.028 326.455 324.053 315.834C324.799 312.186 325.651 308.539 326.824 304.998V304.998Z'
        fill='#85CCD5'
      />
      <Path
        d='M287.279 191.704C289.944 193.528 291.436 191.06 291.223 188.593C290.69 182.263 289.518 176.255 292.716 170.032C293.675 168.101 292.396 166.17 290.371 165.741C288.345 165.312 287.066 166.492 285.787 167.887C282.909 170.998 281.949 175.289 281.949 179.474C281.949 184.409 282.909 189.129 287.173 191.919L287.279 191.704Z'
        fill='#C17C4D'
      />
      <Path
        d='M68.8217 207.257C52.8371 202.107 56.3537 231.718 65.8379 237.833C66.797 238.369 67.8626 239.013 68.1823 240.086C68.2888 240.515 68.2888 241.051 68.1823 241.481C67.5429 251.136 64.1329 258.217 59.1244 264.118C54.4356 269.589 48.3615 273.988 42.0742 278.387C67.756 314.434 107.611 333.102 149.703 329.991C165.368 328.811 179.648 321.408 191.05 310.036C187.747 307.675 179.328 301.453 173.041 295.338C167.073 299.093 69.8873 211.655 68.7151 207.257H68.8217Z'
        fill='#936457'
      />
      <Path
        d='M76.3991 228.717C77.145 231.399 77.7844 234.081 78.2107 236.764C78.6369 240.519 78.85 244.274 78.7435 248.029C78.7435 255.538 78.85 263.263 81.0879 270.558C82.6863 275.386 85.2439 279.785 88.7605 283.54C92.1705 287.295 96.433 290.192 101.122 292.123C117.106 298.989 134.476 301.778 151.739 300.276C159.519 299.633 167.404 299.096 173.372 295.341C174.331 294.698 175.397 294.268 176.249 293.41C178.167 291.586 179.659 289.441 180.618 286.973C183.069 281.394 184.135 275.064 184.561 268.627C184.987 262.19 184.774 255.646 184.241 249.531C184.241 248.565 184.135 247.385 184.028 246.419C183.815 243.737 183.176 236.549 181.684 233.438C180.405 235.476 172.626 183.121 174.438 186.34V186.125C174.864 181.834 173.265 175.826 171.773 172.071C170.282 167.672 167.404 163.917 163.675 161.235C137.247 145.035 85.6701 144.928 70.325 177.328C65.7427 186.983 66.3821 197.39 69.0462 207.367C70.2184 211.659 71.7103 215.95 73.4153 220.027C74.5875 222.817 75.6531 225.821 76.5056 228.717H76.3991Z'
        fill='#211307'
      />
      <Path
        d='M163.983 179.899C153.114 178.505 142.244 178.183 131.268 178.827C118.907 179.148 96.8482 180.865 93.1184 194.49C90.2412 205.219 90.6675 217.127 93.1184 227.963C96.3153 241.481 100.791 244.27 112.3 249.635C116.456 251.566 120.825 252.961 125.407 253.604C126.793 253.819 128.284 253.926 129.776 254.141C133.399 254.355 137.023 254.677 140.752 254.463C142.138 254.463 143.416 254.248 144.802 254.141C145.548 254.141 146.294 254.033 147.146 253.926C148.638 253.712 150.023 253.39 151.515 253.175C152.261 253.068 153.007 252.853 153.753 252.639C155.245 252.317 156.737 251.888 158.122 251.351C158.655 251.137 159.294 250.922 159.827 250.708C162.172 249.849 164.409 248.776 166.647 247.596C167.713 247.06 168.779 246.416 169.738 245.772C170.59 245.236 171.336 244.592 172.082 244.056C173.361 243.09 174.533 242.017 175.705 240.837C176.131 240.408 176.558 239.979 176.984 239.55C178.582 237.726 180.074 235.795 181.353 233.649H181.566C181.566 232.469 181.566 231.504 181.353 230.323C180.287 220.239 179.115 210.154 177.304 200.176C176.664 196.421 175.705 191.164 174.213 186.336V186.551C172.295 183.333 170.803 181.08 163.877 180.114L163.983 179.899Z'
        fill='#936457'
      />
      <Path
        d='M152.268 206.079C154.186 203.397 151.948 201.788 149.283 202.002C146.511 202.753 143.953 203.933 141.608 205.65C137.451 208.868 137.344 214.233 137.877 218.953C138.09 223.352 139.689 227.536 142.461 230.862C143.74 232.257 148.11 235.475 150.882 235.153C151.308 235.153 151.628 234.939 152.054 234.831C152.374 234.617 152.694 234.295 152.907 233.973C153.653 232.579 152.694 230.969 151.841 229.789C146.725 221.957 146.725 213.696 152.374 206.186L152.268 206.079Z'
        fill='#71483C'
      />
      <Path
        d='M29.8092 342.759C47.6053 401.98 90.8701 448.971 145.963 475.256C175.801 489.525 209.156 496.606 243.043 498.215C252.42 468.175 263.929 438.565 278.848 411.207C272.135 397.796 264.782 384.815 257.216 373.013C241.658 348.445 219.919 328.061 195.409 312.397C194.13 311.539 192.851 310.681 191.573 309.93C191.573 309.93 191.36 310.037 191.253 310.144C179.744 321.516 164.825 325.486 149.16 326.666C107.068 329.777 74.6725 307.355 42.2772 278.495C38.0146 280.534 30.0224 285.469 20.6448 291.691H20.4316C22.0301 309.501 25.227 326.881 30.0224 342.866L29.8092 342.759Z'
        fill='#3BA5B5'
      />
      <Path
        d='M446.166 365.934C446.166 365.934 424.533 344.906 424.64 328.921L421.55 329.994C419.418 330.101 417.181 329.994 414.943 329.779C412.598 329.779 408.336 328.492 406.098 327.633H404.819C403.54 345.013 394.269 357.459 374.022 356.815C371.252 356.815 368.588 356.493 365.924 356.278C359.21 355.635 352.603 354.24 346.316 351.451C339.389 348.339 332.782 348.876 329.159 343.511C308.592 363.037 292.288 386.104 278.755 411.101C263.942 438.459 252.327 468.07 242.949 498.109C277.582 499.719 312.748 495.32 345.25 486.308C347.381 485.772 349.406 485.235 351.537 484.591C377.006 477.082 399.171 465.495 418.14 450.153C438.813 433.524 455.863 412.603 469.077 386.962C471.954 381.383 474.512 375.804 476.963 370.118C468.224 371.835 448.084 366.9 445.953 365.934H446.166Z'
        fill='#086B81'
      />
      <Path
        d='M427.631 240.197C426.991 240.197 426.458 240.197 425.925 240.305C421.768 240.948 417.611 246.634 416.651 252.857C416.332 255.11 416.012 260.474 420.169 262.62C422.621 263.693 425.286 263.907 427.844 263.371C430.403 262.727 432.641 261.332 434.347 259.294C435.839 257.148 436.692 254.681 436.905 252.106C437.012 249.531 436.479 246.956 435.2 244.703C434.453 243.416 433.387 242.236 432.108 241.485C430.829 240.734 429.337 240.305 427.738 240.305L427.631 240.197Z'
        fill='#FEDFB7'
      />
      <Path
        d='M421.661 328.924C421.661 325.92 426.243 322.272 426.882 319.375C427.628 316.157 427.628 313.26 428.374 310.041C429.973 303.604 434.022 296.524 435.621 290.194C438.711 278.178 442.867 264.445 443.08 251.893C443.08 248.245 443.826 242.559 442.121 239.341C439.883 235.049 435.408 232.582 433.17 228.29C430.719 223.677 427.522 217.133 425.71 212.197C422.513 203.615 408.66 208.764 402.479 211.983C377.224 224.964 370.937 265.84 382.765 289.765C386.282 296.845 394.061 301.673 398.537 308.11C403.225 314.762 408.66 321.092 408.127 328.709C410.472 329.245 412.709 329.675 415.16 329.889C417.398 330.104 419.53 330.211 421.874 330.104C421.874 329.996 421.874 329.782 421.874 329.675V329.031L421.661 328.924Z'
        fill='#FEDFB7'
      />
      <Path
        d='M385.104 262.304C387.662 261.66 389.901 260.265 391.606 258.227C393.099 256.081 393.951 253.614 394.165 251.039C394.271 248.464 393.738 245.889 392.459 243.636C391.713 242.349 390.647 241.168 389.368 240.417C388.089 239.666 386.596 239.237 384.997 239.237C384.358 239.237 383.825 239.237 383.292 239.345C382.972 239.345 382.759 239.666 382.545 239.666C380.627 246.318 379.774 254.257 379.987 262.196C381.693 262.518 383.505 262.733 385.21 262.196L385.104 262.304Z'
        fill='#FEDFB7'
      />
      <Path
        d='M475.972 187.195C465.102 180.544 452.954 176.359 440.273 174.857C427.592 173.463 414.804 174.857 402.763 179.042C376.974 188.161 363.547 210.691 353.637 234.83C348.309 247.811 343.94 261.222 339.251 273.345C335.308 283.645 330.193 294.159 326.889 304.994C325.717 308.535 324.865 312.182 324.119 315.83C322.094 326.451 324.119 336 329.34 343.617C333.07 348.981 338.398 353.487 345.325 356.491C351.612 359.281 358.219 360.675 364.933 361.319C367.597 361.641 370.261 361.748 373.031 361.855C393.279 362.499 406.919 346.085 408.091 328.704C408.624 321.087 406.919 313.255 402.23 306.604C397.754 300.167 388.803 295.124 385.286 288.151C378.999 275.491 377.827 259.398 381.024 245.666C384.221 231.933 391.467 220.346 403.615 214.124C409.796 210.905 419.919 207.901 423.116 216.484C428.338 230.538 439.953 243.198 438.994 258.969C438.461 268.088 434.732 277.315 432.387 286.112C429.936 295.553 426.846 304.887 424.182 314.328C418.534 334.498 424.395 358.637 444.855 368.078C446.987 369.044 449.224 369.795 451.462 370.331C451.782 370.331 452.102 370.546 452.634 370.653C460.307 372.048 468.512 372.155 477.251 370.438C492.809 334.069 499.735 294.588 498.35 255.429C497.711 237.19 494.94 218.308 490.571 199.533C485.243 193.74 479.488 189.556 476.185 187.517L475.972 187.195Z'
        fill='#F69170'
      />
      <Path
        d='M399.484 266.805C405.24 268.843 412.169 256.827 406.946 253.18C406.732 253.072 406.519 252.965 406.306 252.858C403.535 251.678 401.083 254.574 399.804 256.72C398.631 258.651 396.179 264.123 398.205 265.947C398.631 266.376 399.057 266.59 399.59 266.805H399.484Z'
        fill='#DBA88D'
      />
    </Svg>
  )
}
