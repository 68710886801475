import React, { useState } from 'react'
import PropTypes from 'prop-types'
import CSSModules from 'react-css-modules'
import styles from './lyraCode.module.scss'
import { BaseModal } from '@lyrahealth-inc/ui-core'
import LyraCodeExplanationModalContent from './LyraCodeExplanationModalContent'

const LyraCodeProfileDisplay = ({ lyraCode }: any) => {
  const [isModalOpen, setModalOpen] = useState(false)
  return (
    <>
      <div styleName='lyra-code'>{lyraCode}</div>
      <a onClick={() => setModalOpen(true)}>What is this?</a>
      <BaseModal
        isOpen={isModalOpen}
        // @ts-expect-error TS(2741): Property 'closeModal' is missing in type '{}' but ... Remove this comment to see the full error message
        body={<LyraCodeExplanationModalContent />}
        closeModal={() => setModalOpen(false)}
      />
    </>
  )
}

LyraCodeExplanationModalContent.propTypes = {
  // @ts-expect-error TS(2322): Type '{ lyraCode: PropTypes.Requireable<string>; }... Remove this comment to see the full error message
  lyraCode: PropTypes.string,
}

export default CSSModules(LyraCodeProfileDisplay, styles)
