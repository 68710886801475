import React from 'react'
import CSSModules from 'react-css-modules'
import styles from './requestPayment.module.scss'
import { PrimaryButton } from '@lyrahealth-inc/ui-core'

const ClientIneligibleModal = ({ closeFunc }: any) => {
  return (
    <div data-test-id='requestpayment-clientIneligibleModal' styleName='modal-content'>
      <h2 styleName='main-header'>Your client may not be eligible</h2>
      <p>
        According to our records, this client does not seem to be eligible. Please check their details or have them
        reach out to their benefits team.
      </p>
      <p>
        Feel free to reach out to <a href='mailto:providers@lyrahealth.com'>providers@lyrahealth.com</a> for additional
        assistance.
      </p>
      <PrimaryButton styleName='dismiss-button' onClick={closeFunc}>
        Return to form
      </PrimaryButton>
    </div>
  )
}

export default CSSModules(ClientIneligibleModal, styles)
