import React, { Component } from 'react'

import classNames from 'classnames'
import { isEmpty, isObject, isString } from 'lodash-es'

import styles from './notificationBanner.module.scss'
import XIcon from '../../atoms/icons/XIcon'
import { KEYS } from '../../constants/keyboardConstants'
import ChevronIcon from '../icons/ChevronIcon'
import Tooltip from '../tooltip/Tooltip'

type NotificationItem = {
  text: string
  icon?: React.ElementType
  clickAction?: (event?: React.SyntheticEvent) => void
  isDisabled: boolean
  disabledTooltip: string
}

export enum NotificationLevel {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  WARNING_GENTLE = 'warning-gentle',
  DANGER = 'danger',
  ERROR_GENTLE = 'error-gentle',
  GENTLE = 'gentle',
  MICROSITE_INFO = 'microsite-info',
  PRIMARY_ACTION = 'primary-action',
}

export enum ContentAlignment {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export enum StyleType {
  DEFAULT = 'default',
  BORDER_BAR = 'border-bar',
}

type NotificationBannerProps = {
  notifications: string[] | NotificationItem[] | React.ReactNode[]
  contentStyle?: { [key: string]: string }
  withShadow?: boolean
  isDisabled?: boolean
  styleType?: string
  level?: NotificationLevel
  contentAlignment?: ContentAlignment
  disabledTooltip?: string
  closeBannerCTA?: () => void
  closeBannerBtnCustomClass?: { [key: string]: string }
}

type State = $TSFixMe

class NotificationBanner extends Component<{}, State> {
  state = { displayedNotification: 0 }

  render() {
    const {
      notifications,
      contentStyle,
      styleType = '',
      level = NotificationLevel.INFO,
      withShadow,
      isDisabled,
      disabledTooltip,
      contentAlignment = ContentAlignment.LEFT,
      closeBannerCTA = undefined, // default to undefined unless specifically stated by parent
      closeBannerBtnCustomClass,
      ...props
    } = this.props as NotificationBannerProps
    if (isEmpty(notifications)) return false
    let Content = () => <div />
    const notif = notifications[this.state.displayedNotification] as NotificationItem
    let tooltipText = disabledTooltip || ''
    let notificationDisabled = isDisabled
    const notificationClickable = notif.clickAction && !isDisabled && !notif.isDisabled

    if (isString(notif)) {
      Content = () => (
        <span className={styles['content-container']} style={contentStyle}>
          {notif}
        </span>
      )
    } else if (React.isValidElement(notif)) {
      Content = () => notif
    } else if (isObject(notif)) {
      const Icon = notif.icon as React.ElementType
      if (notif.disabledTooltip) tooltipText = notif.disabledTooltip
      notificationDisabled = notif.isDisabled || isDisabled
      Content = () => (
        <span className={styles['content-container']} style={contentStyle}>
          {notif.icon ? <Icon style={{ marginRight: '10px' }} isFilled width={20} fillColor={styles.x_white} /> : ''}
          <span>{notif.text}</span>
        </span>
      )
    }

    const classes = classNames(
      styles.banner,
      styles[`${level}`],
      styleType && styles[`${styleType}`],
      { [styles.shadow]: withShadow },
      { [styles.disabled]: notificationDisabled },
      styles[contentAlignment],
    )

    return (
      <div style={{ zIndex: 9 }} {...props}>
        {notifications.length > 1 ? this.renderArrowsContainer() : ''}
        {notificationDisabled && tooltipText.length > 0 ? (
          <Tooltip content={tooltipText} triggerStyle={{}}>
            <div className={classes}>
              <Content />
            </div>
          </Tooltip>
        ) : (
          <div
            className={classes}
            onClick={notificationClickable ? notif.clickAction : () => {}}
            role={notificationClickable ? 'button' : undefined}
            tabIndex={notificationClickable ? 0 : undefined}
            onKeyDown={(e) => {
              if (!notificationDisabled && notificationClickable && e.key === KEYS.ENTER && notif.clickAction) {
                notif.clickAction()
              }
            }}
          >
            <Content />
            {notificationClickable ? (
              <div className={styles['click-indicator']}>
                <ChevronIcon isFilled width={18} />
              </div>
            ) : null}
            {closeBannerCTA ? (
              <button
                className={classNames(styles['close-banner-btn'], closeBannerBtnCustomClass)}
                onClick={closeBannerCTA}
              >
                <XIcon fillColor='currentColor' width={24} height={24} />
              </button>
            ) : null}
          </div>
        )}
      </div>
    )
  }

  renderArrowsContainer = () => {
    const { notifications } = this.props as NotificationBannerProps
    const leftArrowColor = styles.x_secondary_action
    const rightArrowColor = styles.x_secondary_action
    const leftArrowDisabled = this.state.displayedNotification === 0
    const rightArrowDisabled = this.state.displayedNotification === notifications.length - 1

    return (
      <div className={styles['arrows-container']}>
        <ChevronIcon
          width={15}
          className={classNames(styles.arrow, { [styles['is-disabled']]: leftArrowDisabled })}
          fillColor={leftArrowColor}
          direction='left'
          onClick={this.showPrevious}
        />
        <span className={styles['notification-counter']}>
          {this.state.displayedNotification + 1} of {notifications.length}
        </span>
        <ChevronIcon
          width={15}
          className={classNames(styles.arrow, { [styles['is-disabled']]: rightArrowDisabled })}
          fillColor={rightArrowColor}
          direction='right'
          onClick={this.increasePointer}
        />
      </div>
    )
  }

  showPrevious = () => {
    if (this.state.displayedNotification === 0) return
    this.setState({ displayedNotification: --this.state.displayedNotification })
  }

  increasePointer = () => {
    const { notifications } = this.props as NotificationBannerProps
    if (this.state.displayedNotification === notifications.length - 1) return
    this.setState({ displayedNotification: ++this.state.displayedNotification })
  }
}

export default NotificationBanner
