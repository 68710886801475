import '../styles/bootstrap-custom'
import '@lyrahealth-inc/ui-core/lib/root.module.scss'
import './index.scss'
import React from 'react'
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  Routes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'

import * as Sentry from '@sentry/react'
import { createRoot } from 'react-dom/client'

import App from './App'

if (!window.Cypress && ['production', 'staging'].includes(process.env.NODE_ENV ?? '')) {
  Sentry.init({
    environment: process.env.NODE_ENV === 'production' ? 'production' : 'staging',
    dsn: 'https://2c5c4e160916dd404b7011137fd9a724@o525650.ingest.sentry.io/4506396554887168',
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      ...(process.env.NODE_ENV === 'production'
        ? [
            Sentry.replayIntegration({
              beforeErrorSampling(event) {
                // Check if the event is an error event
                if (event.exception) {
                  // Check if the event has a 404 error
                  const requestErrors = event.exception?.values?.filter(
                    (value) => value.type === 'Error' && value?.value?.includes('404'),
                  )

                  // If there is a 404 error, return false to filter it out
                  if (requestErrors?.length ?? 0 > 0) {
                    return false
                  }
                }

                // Otherwise, allow the error to be sampled
                return true
              },
            }),
          ]
        : []),
    ],
    sampleRate: 0.25,
    tracePropagationTargets: ['localhost', /^https:\/\/provider\.(staging\.)?lyrahealth\.com\/api/],
    tracesSampleRate: 0.002,
    // Session Replay
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0.005,
  })
}

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)
export const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)

// @ts-expect-error TS(2345): Argument of type 'HTMLElement | null' is not assig... Remove this comment to see the full error message
const root = createRoot(document.getElementById('root'))

window.onload = function () {
  root.render(<App />)
  // if ('serviceWorker' in navigator) {
  //   navigator.serviceWorker.register('/service-worker.js')
  // }
}
