import React, { FunctionComponent, KeyboardEvent } from 'react'
import { GestureResponderEvent, Pressable, TextStyle } from 'react-native'

import { useTheme } from 'styled-components/native'

import { KEYS } from '@lyrahealth-inc/shared-app-logic'

import { IS_WEB } from '../../constants'
import { BodyTextSize, TextAlign } from '../../styles/typeStyles'
import { tID } from '../../utils'
import { BodyText } from '../bodyText/BodyText'

export type LinkProps = {
  text: string | React.ReactNode
  onPress: (e: GestureResponderEvent | KeyboardEvent<Element>) => void
  size?: BodyTextSize
  color?: string
  testID?: string
  style?: TextStyle
  underline?: boolean
  textAlign?: TextAlign
  containerStyle?: Object // containerStyle only for web
}

/**
 * Component that styles a link
 */
export const Link: FunctionComponent<LinkProps> = ({
  text,
  onPress,
  size = BodyTextSize.DEFAULT,
  testID,
  color,
  underline,
  textAlign,
  containerStyle, // containerStyle only for web
  style,
}) => {
  const { colors } = useTheme()

  const onKeyDown = (event: KeyboardEvent<Element>) => {
    if (event?.key === KEYS.ENTER) {
      onPress(event)
    }
  }

  const textColor = color || colors.linkDefault
  // there's a known bug in React Native where the vertical alignment of text is misaligned
  // if Text is nested inside other Text / View elements
  // https://github.com/facebook/react-native/issues/31955
  // https://stackoverflow.com/questions/66590167/vertically-align-pressable-inside-a-text-component
  // this alignment issue only appears in the app and not on web

  // if we remove the parent Pressable container, the misalignment goes away
  // however this means containerStyle is only applied for web elements,
  // which is fine so far because it's only being used in lyrahealthwebapp/MedsOptionsAdvisory
  // but we may need to add it back in for app later if it becomes necessary
  return IS_WEB ? (
    <Pressable
      accessibilityRole='link'
      testID={tID(testID)}
      onPress={onPress}
      onKeyDown={onKeyDown}
      style={containerStyle}
    >
      <BodyText size={size} color={textColor} style={style} underline={underline} textAlign={textAlign}>
        {text}
      </BodyText>
    </Pressable>
  ) : (
    <BodyText
      accessibilityRole='link'
      testID={tID(testID)}
      size={size}
      color={textColor}
      style={style}
      underline={underline}
      textAlign={textAlign}
      onPress={onPress}
    >
      {text}
    </BodyText>
  )
}
