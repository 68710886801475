import React, { FunctionComponent } from 'react'
import { Path, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

export interface QuestionMarkIconProps {
  width?: string | number
  height?: string | number
  fillColor?: string
  isFilled?: boolean
}

export const QuestionMarkIcon: FunctionComponent<QuestionMarkIconProps> = ({
  width = 14,
  fillColor,
  isFilled = false,
  ...props
}) => {
  const { colors } = useTheme()

  return (
    <Svg width={width} height={width} viewBox='0 0 24 24' {...props}>
      {isFilled ? (
        <Path
          fill={fillColor || colors.iconActive}
          d='M13.5898,11.7197 C13.1598,12.2597 12.7498,12.7597 12.7498,13.2497 C12.7498,13.6597 12.4098,13.9997 11.9998,13.9997 C11.5898,13.9997 11.2498,13.6597 11.2498,13.2497 C11.2498,12.2407 11.8698,11.4597 12.4098,10.7797 C12.8398,10.2407 13.2498,9.7407 13.2498,9.2497 C13.2498,8.5597 12.6908,7.9997 11.9998,7.9997 C11.3098,7.9997 10.7498,8.5597 10.7498,9.2497 C10.7498,9.6597 10.4098,9.9997 9.9998,9.9997 C9.5898,9.9997 9.2498,9.6597 9.2498,9.2497 C9.2498,7.7297 10.4808,6.4997 11.9998,6.4997 C13.5198,6.4997 14.7498,7.7297 14.7498,9.2497 C14.7498,10.2597 14.1298,11.0397 13.5898,11.7197 M11.9998,16.9997 C11.4498,16.9997 10.9998,16.5497 10.9998,15.9997 C10.9998,15.4497 11.4498,14.9997 11.9998,14.9997 C12.5498,14.9997 12.9998,15.4497 12.9998,15.9997 C12.9998,16.5497 12.5498,16.9997 11.9998,16.9997 M11.9998,1.9997 C6.4808,1.9997 1.9998,6.4797 1.9998,11.9997 C1.9998,17.5197 6.4808,21.9997 11.9998,21.9997 C17.5198,21.9997 21.9998,17.5197 21.9998,11.9997 C21.9998,6.4797 17.5198,1.9997 11.9998,1.9997'
        />
      ) : (
        <Path
          fill={fillColor || colors.iconActive}
          d='M12,2 C17.522,2 22,6.477 22,12 C22,17.523 17.522,22 12,22 C6.478,22 2,17.523 2,12 C2,6.477 6.478,2 12,2 M12,3.5 C7.313,3.5 3.5,7.313 3.5,12 C3.5,16.687 7.313,20.5 12,20.5 C16.687,20.5 20.5,16.687 20.5,12 C20.5,7.313 16.687,3.5 12,3.5 M12,15 C11.447,15 11,15.448 11,16 C11,16.552 11.447,17 12,17 C12.553,17 13,16.552 13,16 C13,15.448 12.553,15 12,15 M14.75,9.25 C14.75,10.26 14.13,11.04 13.59,11.72 C13.16,12.26 12.75,12.76 12.75,13.25 C12.75,13.66 12.41,14 12,14 C11.59,14 11.25,13.66 11.25,13.25 C11.25,12.24 11.87,11.46 12.41,10.78 C12.84,10.24 13.25,9.74 13.25,9.25 C13.25,8.56 12.69,8 12,8 C11.31,8 10.75,8.56 10.75,9.25 C10.75,9.66 10.41,10 10,10 C9.59,10 9.25,9.66 9.25,9.25 C9.25,7.73 10.48,6.5 12,6.5 C13.52,6.5 14.75,7.73 14.75,9.25'
        />
      )}
    </Svg>
  )
}
