import React, { FunctionComponent } from 'react'
import { Path, Svg } from 'react-native-svg'

import { isString } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import { NumberBadge } from './NumberBadge'
import { tID } from '../../utils'

const Container = styled.View`
  position: relative;
`

const MessagingIconContainer = styled(Svg)`
  z-index: 1;
`

const BadgeContainer = styled.View<{ size: number; doubleDigits?: boolean }>`
  position: absolute;
  z-index: 2;

  ${({ size }) => `
    top: -${size / 4}px;
    right: -${size / 3}px;
  `}

  ${({ doubleDigits }) => doubleDigits && 'min-width: 24px;'}
`

/**
 * Messaging Icon that can display a number badge representing unread messages
 */
export const MessagingIcon: FunctionComponent<MessagingIconProps> = ({ size = 24, unreadMessages = 0, fillColor }) => {
  const { colors } = useTheme()
  const { messagingIcon } = colors.icons
  size = isString(size) ? parseInt(size) : size

  return (
    <Container testID={tID('MessageIcon')}>
      {unreadMessages > 0 && unreadMessages <= 9 && (
        <BadgeContainer testID={tID('UnreadMessages')} size={size}>
          <NumberBadge number={String(unreadMessages)} borderColor={messagingIcon.border} />
        </BadgeContainer>
      )}
      {unreadMessages > 9 && (
        <BadgeContainer testID={tID('UnreadMessages')} size={size} doubleDigits>
          <NumberBadge number='9+' borderColor={messagingIcon.border} />
        </BadgeContainer>
      )}

      <MessagingIconContainer width={size} height={size} viewBox='0 0 24 24'>
        <Path
          d='M18 7.25H9C8.80109 7.25 8.61032 7.32902 8.46967 7.46967C8.32902 7.61032 8.25 7.80109 8.25 8C8.25 8.19891 8.32902 8.38968 8.46967 8.53033C8.61032 8.67098 8.80109 8.75 9 8.75H18C18.1989 8.75 18.3897 8.67098 18.5303 8.53033C18.671 8.38968 18.75 8.19891 18.75 8C18.75 7.80109 18.671 7.61032 18.5303 7.46967C18.3897 7.32902 18.1989 7.25 18 7.25Z'
          fill={fillColor || colors.iconDefault}
        />
        <Path
          d='M15 10.25H9C8.80109 10.25 8.61032 10.329 8.46967 10.4697C8.32902 10.6103 8.25 10.8011 8.25 11C8.25 11.1989 8.32902 11.3897 8.46967 11.5303C8.61032 11.671 8.80109 11.75 9 11.75H15C15.1989 11.75 15.3897 11.671 15.5303 11.5303C15.671 11.3897 15.75 11.1989 15.75 11C15.75 10.8011 15.671 10.6103 15.5303 10.4697C15.3897 10.329 15.1989 10.25 15 10.25Z'
          fill={fillColor || colors.iconDefault}
        />
        <Path
          d='M20.5 3.25H6.5C5.90326 3.25 5.33097 3.48705 4.90901 3.90901C4.48705 4.33097 4.25 4.90326 4.25 5.5V7.25H3.5C2.90326 7.25 2.33097 7.48705 1.90901 7.90901C1.48705 8.33097 1.25 8.90326 1.25 9.5V16.5C1.25 17.0967 1.48705 17.669 1.90901 18.091C2.33097 18.5129 2.90326 18.75 3.5 18.75H4.69L6.47 20.53C6.61063 20.6705 6.80125 20.7493 7 20.7493C7.19875 20.7493 7.38937 20.6705 7.53 20.53L9.31 18.75H16C16.0997 18.7495 16.1983 18.7291 16.29 18.69C16.3793 18.6521 16.4606 18.5978 16.53 18.53L19.31 15.75H20.5C21.0967 15.75 21.669 15.5129 22.091 15.091C22.5129 14.669 22.75 14.0967 22.75 13.5V5.5C22.75 5.20453 22.6918 4.91194 22.5787 4.63896C22.4657 4.36598 22.2999 4.11794 22.091 3.90901C21.8821 3.70008 21.634 3.53434 21.361 3.42127C21.0881 3.3082 20.7955 3.25 20.5 3.25ZM21.25 13.5C21.2474 13.6981 21.1676 13.8874 21.0275 14.0275C20.8874 14.1676 20.6981 14.2474 20.5 14.25H19C18.8012 14.2502 18.6105 14.3293 18.47 14.47L16 16.94L13.53 14.47C13.3895 14.3293 13.1988 14.2502 13 14.25H6.5C6.30189 14.2474 6.11263 14.1676 5.97253 14.0275C5.83244 13.8874 5.75259 13.6981 5.75 13.5V5.5C5.75259 5.30189 5.83244 5.11263 5.97253 4.97253C6.11263 4.83244 6.30189 4.75259 6.5 4.75H20.5C20.6981 4.75259 20.8874 4.83244 21.0275 4.97253C21.1676 5.11263 21.2474 5.30189 21.25 5.5V13.5Z'
          fill={fillColor || colors.iconDefault}
        />
      </MessagingIconContainer>
    </Container>
  )
}

type MessagingIconProps = {
  size?: string | number
  unreadMessages?: number
  fillColor?: string
}
