import React from 'react'

import calendarIllustrationUrl from '../../assets/calendarIllustration.png'
import { Image } from '../image/Image'

interface CalendarIllustrationProps {
  width?: number
  height?: number
}

export function CalendarIllustration({ width = 48, height = 48, ...props }: CalendarIllustrationProps) {
  return (
    <Image
      source={calendarIllustrationUrl}
      style={{ width, height }}
      contentFit='contain'
      {...props}
      accessibilityIgnoresInvertColors
    />
  )
}
