import { trackPageLoad } from '../../../mixpanel/mixpanelTracking'
import { useLocation } from 'react-router'
import { useLayoutEffect } from 'react'
import { expireAlerts } from '../data/alertActions'
import store from '../data/store'

const usePathChanged = () => {
  const { pathname } = useLocation()

  useLayoutEffect(() => {
    if (pathname.includes('/python/')) {
      window.location.href = pathname.replace(/(\/python)/g, '')
    }
    trackPageLoad(pathname)
    // page is changing, remove alerts that expire on page-change
    store.dispatch(expireAlerts())
  }, [pathname])
}

export default usePathChanged
