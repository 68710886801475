import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

export const RenewSecondaryRec: FunctionComponent<RenewSecondaryRecProps> = ({ width = 80, height = 80 }) => {
  return (
    <Svg width={width} height={height} viewBox='0 0 80 80' fill='none'>
      <Path
        d='M40.2812 0.298828C34.7592 0.298828 29.4015 1.24578 24.3569 3.11281C12.3469 7.56459 3.9034 17.4341 1.1906 30.1902C-0.602123 38.5073 -0.372894 46.1999 1.89568 53.6965C4.79186 63.2703 11.9011 71.3439 21.4007 75.8463C26.1164 78.0864 31.5341 79.3653 37.5004 79.6467C38.2671 79.6815 39.0465 79.7005 39.8212 79.7005C44.6081 79.7005 49.4235 79.0444 54.1345 77.7512C54.4965 77.6611 54.8412 77.5678 55.2048 77.4604C59.4384 76.2209 63.2483 74.2606 66.5081 71.6411C70.0477 68.8129 72.9613 65.2274 75.1587 60.9907C75.5919 60.156 76.0061 59.277 76.463 58.2257C78.9766 52.3891 80.1907 45.8553 79.9757 39.323C79.8745 36.4347 79.4445 33.3647 78.7015 30.1902C77.4779 25.0397 75.4828 20.1959 72.9313 16.1741C72.2325 15.0944 71.5907 14.1869 70.9551 13.3712C66.369 7.4555 59.2234 3.30409 50.2977 1.37225C47.0189 0.660851 43.6485 0.300409 40.2828 0.300409L40.2812 0.298828Z'
        fill='white'
      />
      <Path
        d='M76.5437 39.4299C76.4457 36.6617 76.022 33.794 75.3549 30.9437C74.2641 26.3512 72.4713 21.8235 70.0131 17.9472C69.4424 17.0682 68.8574 16.2209 68.2219 15.4067C63.6121 9.46256 56.6261 6.15535 49.4758 4.60766C41.5113 2.88133 33.0424 3.38563 25.3862 6.22016C14.3105 10.3241 6.76968 19.3147 4.30982 30.8788C3.36445 35.2595 3.0878 40.2108 3.51148 44.9677H3.47828C3.72332 47.671 4.21181 50.3095 4.94376 52.7362C7.66447 61.7267 14.2757 68.8612 22.6971 72.8514C27.258 75.0172 32.3548 76.0922 37.5353 76.3372C42.8281 76.5823 48.2031 75.9136 53.1719 74.5461C53.4975 74.4655 53.8074 74.3833 54.1331 74.2853C58.0252 73.1454 61.413 71.3859 64.3124 69.0573C67.4726 66.5326 70.0779 63.3566 72.0983 59.4644C72.5377 58.6171 72.9282 57.7713 73.3029 56.9066C75.6805 51.3845 76.7397 45.3914 76.5279 39.4472L76.5437 39.4314V39.4299Z'
        fill='#FFE9E3'
      />
      <Path
        d='M59.8896 49.3522C62.8411 42.6998 62.6419 32.9315 58.3735 26.8862C55.4109 22.6905 49.5933 19.4497 44.5044 18.9059C39.1894 18.3384 33.5315 18.7699 28.8647 21.4812C24.7639 23.8636 21.297 27.419 19.4031 31.8012C18.1795 34.6326 17.6325 37.7501 17.7874 40.8312C18.0957 46.9872 20.9492 53.2981 26.1867 56.648C32.5149 60.6951 41.0723 62.3898 48.2543 59.756C52.6855 58.1309 57.7791 54.1075 59.888 49.3522H59.8896Z'
        fill='#FFA338'
      />
      <Path
        d='M50.8425 27.75C55.2058 31.2548 57.5897 36.5539 56.216 42.7637C55.5235 45.8907 53.8715 48.7726 51.8163 51.2024C48.3052 55.3523 44.2091 57.7615 38.6365 57.0027C33.6251 56.3214 28.1141 53.4742 25.6195 48.8643C23.8173 45.5334 24.124 41.0373 24.9033 37.4503C25.523 34.5968 26.6597 31.854 28.2627 29.4131C29.7567 27.1366 31.8798 25.5731 34.3665 24.4491C37.2817 23.1322 40.6173 23.2587 43.6416 24.1124C46.3512 24.8775 48.8111 26.1185 50.8409 27.75H50.8425Z'
        fill='#FFC03C'
      />
      <Path
        d='M51.7161 41.1918C52.1382 46.6285 48.6966 52.258 42.1265 53.2145C31.2057 54.8033 23.5479 43.5726 31.3875 34.8003C38.1047 27.2848 50.9209 30.9382 51.7161 41.1918Z'
        fill='#FFD974'
      />
      <Path
        d='M39.9641 48.5981C43.4521 48.5981 46.2797 45.7705 46.2797 42.2824C46.2797 38.7944 43.4521 35.9668 39.9641 35.9668C36.476 35.9668 33.6484 38.7944 33.6484 42.2824C33.6484 45.7705 36.476 48.5981 39.9641 48.5981Z'
        fill='white'
      />
    </Svg>
  )
}

type RenewSecondaryRecProps = {
  width?: number
  height?: number
}
