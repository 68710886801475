import React, { FunctionComponent } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { css } from 'styled-components'
import styled, { useTheme } from 'styled-components/native'

import { Contentmetadata } from '@lyrahealth-inc/shared-app-logic'

import VideoSessionCallQualityMetadata from './videoSessionCallQualityFormMetadata'
import { PrimaryButton } from '../../atoms'
import { InputAccessory } from '../../molecules/inputAccessory/InputAccessory'
import { Flex1View } from '../../templates'
import { tID } from '../../utils'
import { FormBody, FormButtonParams, FormChangeProperties } from '../formBody/FormBody'

export interface VideoSessionFeedbackProps {
  saveForm: (values: FormChangeProperties) => void
  onLayout: () => void
  onFormChange?: (values: FormChangeProperties) => void
  scrollEnabled: boolean
  scrollFieldContainerHeight?: number
  useNarrowView?: boolean
}

const formBodyCustomStyles = (bottom: number, backgroundColor: string) => ({
  submitButtonContainer: css`
    background-color: ${backgroundColor};
    padding: 0 24px ${bottom ? '0' : '24px'} 24px;
    box-shadow: none;
    border-top-width: 0px;
  `,
})

const scrollContainerCustomStyles = {
  formBodyPageContainer: css`
    overflow: 'hidden';
    padding-top: 36px;
  `,
  scrollContainerContentCustomStyles: {
    overflow: 'hidden',
    paddingBottom: '8px',
  },
  overflow: 'hidden',
}

const Flex1Container = styled(Flex1View)({
  alignItems: 'center',
})

const FormBodyContainer = styled(Flex1View)({
  zIndex: -1,
  minHeight: '100%',
  minWidth: '300px',
})

const SubmitButtonContainer = styled(PrimaryButton)({
  marginTop: '16px',
})

export const VideoSessionFeedback: FunctionComponent<VideoSessionFeedbackProps> = ({
  saveForm,
  onLayout,
  onFormChange,
  scrollEnabled,
  scrollFieldContainerHeight,
  useNarrowView,
}) => {
  const { colors } = useTheme()
  const { bottom } = useSafeAreaInsets()
  const { schema, uiSchema } = VideoSessionCallQualityMetadata.meta_data as Contentmetadata
  const intl = useIntl()
  const inputAccessoryViewID = 'VideoSessionFeedback'
  const formButton = ({ handleSubmit, pristine }: FormButtonParams) => {
    return (
      <SubmitButtonContainer
        text={<FormattedMessage defaultMessage='Submit' description='Button to submit video session feedback' />}
        onPress={handleSubmit}
        testID={tID('VideoSessionFeedback-Submit')}
        disabled={pristine}
        accessibilityLabel={intl.formatMessage({
          defaultMessage: 'Submit',
          description: 'Button text submit',
        })}
        fullWidth
      />
    )
  }

  const OuterContainer = useNarrowView ? Flex1Container : Flex1View

  return (
    <OuterContainer testID={tID('VideoSessionFeedback')} onLayout={onLayout}>
      <FormBodyContainer testID={tID('VideoSessionFeedback-formbody-container')}>
        <FormBody
          intl={intl}
          schema={schema}
          uiSchema={uiSchema}
          name='VideoSessionFeedback'
          formButton={formButton}
          saveForm={saveForm}
          onFormChange={onFormChange}
          formBodyCustomStyles={formBodyCustomStyles(bottom, colors.backgroundPrimary)}
          scrollEnabled={scrollEnabled}
          scrollContainerCustomStyles={scrollContainerCustomStyles}
          inputAccessoryViewID={inputAccessoryViewID}
          scrollFieldContainerHeight={scrollFieldContainerHeight}
        />
      </FormBodyContainer>
      <InputAccessory nativeID={inputAccessoryViewID} />
    </OuterContainer>
  )
}
