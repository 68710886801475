import { combineReducers } from '@reduxjs/toolkit'

// Reducers
import data from './reconcilerDataReducer'
import reconcilerDashboard from '../dashboard/data/reconcilerDashboardReducer'
import reconcilerDetails from '../individualClient/data/reconcilerDetailsReducer'

export default combineReducers({
  reconcilerDashboard,
  reconcilerDetails,
  data,
})
