import React from 'react'
import styles from './cardUtilityBar.module.scss'
import Progressbar from '../../atoms/progressBar/ProgressBar'
import NavButton from '../../atoms/buttons/navButton/NavButton'

type Props = {
  progress?: number
  progressColor?: string
  backText?: string
  backFunc?: $TSFixMeFunction
}

const CardUtilityBar = ({ progress, progressColor, backText, backFunc }: Props) => {
  // @ts-expect-error TS(6133): 'progressTranslate' is declared but its value is n... Remove this comment to see the full error message
  const progressTranslate = -100 + progress
  return (
    <div className={styles.container}>
      {backText || backFunc ? (
        <div className={styles['back-button']}>
          <NavButton styleType='back' text={backText} onClick={backFunc} />
        </div>
      ) : (
        ''
      )}
      {progress ? (
        <div className={styles['progress-bar-container']}>
          <Progressbar progress={progress} color={progressColor} />
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default CardUtilityBar
