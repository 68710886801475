import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'

import styled, { useTheme } from 'styled-components/native'

import { BodyText, Link, Subhead } from '../../atoms'
import { LCTReminderBannerIllustration } from '../../atoms/illustrations/LCTReminderBannerIllustration'
import { BodyTextSize, SubheadSize } from '../../styles'
import { ThemeType } from '../../utils'
import { tID } from '../../utils/utils'

export interface LCTTeensInlineCalloutProps {
  onPressContactCareNavigator: () => void
  // Determines if banner content is for the booking confirmation page or for care experience callout
  isBookingStep: boolean
  // Language of banner will change depending on if this banner is on Lyra Care Therapy or Direct Access screen
  isLCTProvider?: boolean
  isForParent?: boolean // the callout is for parent instead of the teen themselve
}

const LCTTeensInlineCalloutContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  backgroundColor: theme.colors.backgroundSection,
  borderRadius: theme.spacing['16px'],
  padding: theme.spacing['16px'],
  flexDirection: 'row',
}))

const LCTTeensInlineCalloutTextContainer = styled.View<{ theme: ThemeType; isMobileSized: boolean }>(
  ({ theme, isMobileSized }) => ({
    flexDirection: 'column',
    marginRight: !isMobileSized ? theme.spacing['12px'] : '',
    flexShrink: 1,
  }),
)

const TeenReminderMobileImageContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginRight: theme.spacing['12px'],
}))

const LCTTeensInlineCalloutHeaderText = styled(Subhead)<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['8px'],
}))

export const LCTTeensInlineCallout: FunctionComponent<LCTTeensInlineCalloutProps> = ({
  onPressContactCareNavigator,
  isBookingStep,
  isLCTProvider,
  isForParent,
}) => {
  const {
    breakpoints: { isMobileSized },
  } = useTheme()

  const contactLink = (chunks: string) => (
    <Link
      text={chunks}
      onPress={onPressContactCareNavigator}
      testID={tID('LCTTeensInlineCallout-learnMore-link')}
      size={BodyTextSize.CAPTION}
      underline
      containerStyle={{ display: 'inline' }}
    />
  )

  const content = {
    LCT: (
      <FormattedMessage
        defaultMessage='Your parent/guardian needs to attend your initial session to consent to your care. Questions? <link>Contact your Care Navigator</link>'
        description='A sentence informing the user that a parent or guardian will need to give consent for the child to receive care'
        values={{
          link: contactLink,
        }}
      />
    ),
    LCT_PARENT_LED: (
      <FormattedMessage
        defaultMessage='As a parent/guardian, you must attend the initial session to consent to care. Questions? <link>Contact your Care Navigator</link>'
        description='A sentence informing the user that a parent or guardian will need to give consent for the parent whose child will receive care'
        values={{
          link: contactLink,
        }}
      />
    ),
    DA: (
      <FormattedMessage
        defaultMessage='Your provider may need a parent/guardian(s) to consent to your care. Questions about confidentiality? <link>Contact your Care Navigator</link>'
        description='A sentence informing the user that their provider may tell them that a parent or guardian may need to give consent for the child to receive care'
        values={{
          link: contactLink,
        }}
      />
    ),
    IN_CARE: {
      header: (
        <FormattedMessage
          defaultMessage='Invite your parent/guardian'
          description='A title informing the user to invite their parent or guardian'
        />
      ),
      body: (
        <FormattedMessage
          defaultMessage='They need to attend your initial session to consent to your care. Questions? <link>Contact your Care Navigator</link>'
          description='A sentence informing the user that a parent or guardian will need to attend the first session to give consent for the child to receive care'
          values={{
            link: contactLink,
          }}
        />
      ),
    },
  }

  let headerText
  let bodyText
  if (isBookingStep) {
    headerText = (
      <FormattedMessage
        defaultMessage='Consent for care'
        description='A title informing the user that a parent or guardian will need to give consent for the child to receive care'
      />
    )
    if (isLCTProvider) {
      headerText = isForParent ? (
        <FormattedMessage
          defaultMessage='Attendance required'
          description='A title informing the guardian user that they need to attend the session to give consent for the child to receive care'
        />
      ) : (
        headerText
      )
      bodyText = isForParent ? content.LCT_PARENT_LED : content.LCT
    } else {
      bodyText = content.DA
    }
  } else {
    headerText = content.IN_CARE.header
    bodyText = content.IN_CARE.body
  }

  return (
    <LCTTeensInlineCalloutContainer testID={tID('LCTTeensInlineCalloutContainer')}>
      {isMobileSized && (
        <TeenReminderMobileImageContainer>
          <LCTReminderBannerIllustration width={48} height={41} />
        </TeenReminderMobileImageContainer>
      )}
      <LCTTeensInlineCalloutTextContainer isMobileSized={isMobileSized}>
        <LCTTeensInlineCalloutHeaderText text={headerText} size={SubheadSize.XSMALL} />
        <BodyText text={bodyText} size={BodyTextSize.CAPTION} />
      </LCTTeensInlineCalloutTextContainer>
      {!isMobileSized && <LCTReminderBannerIllustration />}
    </LCTTeensInlineCalloutContainer>
  )
}
