import React from 'react'
import { LostIllustrationUrl, DefaultButton } from '@lyrahealth-inc/ui-core'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0 25%;
`

const Info = styled.div`
  margin-right: $medium;
  max-width: 270px;
`

export default function ErrorFallback() {
  return (
    <Container>
      <Info>
        <h1>Oops!</h1>
        <p>
          Something went wrong... try reloading the page, and if there are still errors contact{' '}
          <a href='mailto:care@lyrahealth.com' target='_blank' rel='noreferrer'>
            care@lyrahealth.com
          </a>
        </p>
        <DefaultButton onClick={() => (window.location.href = '/')}>Go home</DefaultButton>
      </Info>
      <img src={LostIllustrationUrl} alt='Three people studying map together' />
    </Container>
  )
}
