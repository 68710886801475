import { createReducer } from '@reduxjs/toolkit'

import { ClientListClientObject } from '@lyrahealth-inc/shared-app-logic'

import {
  CLEAR_LT_CLIENTS,
  GET_CLIENTS_COUNTS,
  GET_LT_ACTIVE_CLIENTS_V2,
  GET_LT_ACTIVE_CLIENTS_V2_BY_PROGRAM,
  GET_LT_INACTIVE_CLIENTS_V2,
  GET_LT_INACTIVE_CLIENTS_V2_BY_PROGRAM,
  SET_INITIAL_ACTIVE_CLIENTS_FETCHED,
  SET_INITIAL_INACTIVE_CLIENTS_FETCHED,
} from '../../../common/constants/reduxConstants'

type Count = {
  overdue_responses: number
  patient_id: string
  provider_id: string
  unread_messages: number
  unread_responses: number
}
type ClientsDataState = {
  activeV2: ClientListClientObject[]
  inactiveV2: ClientListClientObject[]
  counts?: { [key: string]: Count[] }
  initialActiveClientsFetched: boolean
  initialInactiveClientsFetched: boolean
  filtered: { [key: string]: ClientListClientObject[] }
}
const initialState = {
  activeV2: [],
  inactiveV2: [],
  initialActiveClientsFetched: false,
  initialInactiveClientsFetched: false,
  filtered: {},
}

export default createReducer<ClientsDataState>(initialState, (builder) => {
  builder.addCase(GET_LT_ACTIVE_CLIENTS_V2, (state, action: any) => {
    state.activeV2 = action.data.clients
    return state
  })
  builder.addCase(GET_LT_INACTIVE_CLIENTS_V2, (state, action: any) => {
    state.inactiveV2 = action.data.clients
    return state
  })
  builder.addCase(GET_LT_ACTIVE_CLIENTS_V2_BY_PROGRAM, (state, action: any) => {
    state.filtered[`${action.data.program_name}-active`] = action.data.clients
    return state
  })
  builder.addCase(GET_LT_INACTIVE_CLIENTS_V2_BY_PROGRAM, (state, action: any) => {
    state.filtered[`${action.data.program_name}-inactive`] = action.data.clients
    return state
  })
  builder.addCase(SET_INITIAL_ACTIVE_CLIENTS_FETCHED, (state) => {
    state.initialActiveClientsFetched = true
    return state
  })
  builder.addCase(SET_INITIAL_INACTIVE_CLIENTS_FETCHED, (state) => {
    state.initialInactiveClientsFetched = true
    return state
  })
  builder.addCase(CLEAR_LT_CLIENTS, () => {
    return initialState
  })
  builder.addCase(GET_CLIENTS_COUNTS, (state, action: any) => {
    state.counts = action.data
    return state
  })
})
