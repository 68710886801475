import React from 'react'
import { connect } from 'react-redux'

import { includes } from 'lodash-es'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import styled, { useTheme } from 'styled-components/native'

import {
  BodyText,
  BodyTextSize,
  Checkbox,
  ContentContainer,
  RadioButtonType,
  RadioGroup,
  Subhead,
  SubheadSize,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  TableTitle,
  Tooltip,
  TOOLTIP_TRIGGER_ICON,
} from '@lyrahealth-inc/ui-core-crossplatform'

import { actions, mixpanelEvents } from '../../../../mixpanel/mixpanelConstants'
import { track } from '../../../../mixpanel/mixpanelTracking'
import { accessRolesForAppointment, BodyTextContainer, HeaderContainer } from '../constants'
import { getSchedulingDataUpdated, updateCalendarUse } from '../data/schedulingActions'

const SchedulingAvailabilityPicker: React.FC<SchedulingAvailabilityPickerProps> = ({
  setBlockingCalendars,
  blockingCalendars,
  setAppointmentCalendar,
  appointmentCalendar,
  setStatusButton,
  statusButton,
  calendarDataList,
  providerId,
  actions: { getSchedulingDataUpdated, updateCalendarUse },
}) => {
  const { colors } = useTheme()

  const handleCheckboxChange = (id: string, title: string) => {
    const data = { id: id, use: '' }
    const isChecked = includes(blockingCalendars, id)
    if (isChecked) {
      const newBlockingList = blockingCalendars.filter((val) => val !== id)
      track({
        event: mixpanelEvents.BUTTON_PRESS,
        action: actions.SCHEDULING_REMOVE_BLOCKING_CALENDAR,
        properties: { calendar_id: id, calendar_name: title },
      })
      setBlockingCalendars(newBlockingList)
      data.use = 'non_blocking'
    } else {
      const newBlockingList = [...blockingCalendars, id]
      track({
        event: mixpanelEvents.BUTTON_PRESS,
        action: actions.SCHEDULING_ADD_BLOCKING_CALENDAR,
        properties: { calendar_id: id, calendar_name: title },
      })
      setBlockingCalendars(newBlockingList)
      data.use = 'blocking'
    }
    updateCalendarUse(data, providerId)
    getSchedulingDataUpdated(providerId)
  }

  const handleAppointmentChange = (id: string) => {
    //Setting a blocking calendar as the new 'appointment', previous appointment will be auto updated to blocking by LW
    const appointmentData = { id: id, use: 'appointment' }
    updateCalendarUse(appointmentData, providerId)
    track({
      event: mixpanelEvents.BUTTON_PRESS,
      action: actions.SCHEDULING_SET_APPOINTMENT_CALENDAR,
      properties: { calendar_id: id },
    })
    setAppointmentCalendar(id)
    // Doing this check here to prevent unnecessary re-rendering. May be overkill, I'm open to feedback!
    if (statusButton) {
      setStatusButton(false)
    }
  }

  const AvailabilityTitle = styled(HeaderContainer)({
    marginTop: '0px',
  })

  const TableHeaderContainer = styled(TableHeader)({
    paddingTop: '32px',
    paddingBottom: '16px',
  })

  const TableTitleCenterContainer = styled(TableTitle)({
    textAlign: 'center',
    alignItems: 'center',
  })

  const TableRowContainer = styled(TableRow)({
    height: '57px',
    borderBottomWidth: '1px',
    borderBottomColor: colors.borderDefault,
    paddingBottom: '16px',
    paddingTop: '16px',
  })

  const TableCellCenterContainer = styled.View({
    alignItems: 'center',
    flexShrink: 1,
    flexGrow: 1,
    flexBasis: 0,
  })

  const CheckboxContainer = styled.View({
    marginLeft: '10px',
  })

  const generateCalendarRow = (
    calendarItem: { accessRole: string; id: string; summary: string; use: string; primary: boolean },
    index: number,
  ) => {
    return (
      <TableRowContainer key={calendarItem.id}>
        <TableCell color={colors.textSecondary} size={BodyTextSize.DEFAULT} bold={calendarItem.primary}>
          {calendarItem.summary}
        </TableCell>
        <TableCellCenterContainer>
          <CheckboxContainer>
            <Checkbox
              name={'CalendarCheckbox-' + calendarItem.id}
              key={index}
              checked={includes(blockingCalendars, calendarItem.id)}
              onPress={() => handleCheckboxChange(calendarItem.id, calendarItem.summary)}
              readOnly={appointmentCalendar === calendarItem.id}
              title=''
            />
          </CheckboxContainer>
        </TableCellCenterContainer>
        <TableCellCenterContainer>
          {accessRolesForAppointment.includes(calendarItem.accessRole) && (
            <RadioGroup
              buttons={[{ value: calendarItem.id, label: '' }]}
              buttonType={RadioButtonType.DESCRIPTION_NO_OUTLINE}
              onChange={handleAppointmentChange}
              readOnly={!includes(blockingCalendars, calendarItem.id) || appointmentCalendar === calendarItem.id}
              selectedValue={appointmentCalendar}
            />
          )}
        </TableCellCenterContainer>
      </TableRowContainer>
    )
  }

  const renderAvailabilityList = () => {
    return calendarDataList.map((calendarElement, index) => generateCalendarRow(calendarElement, index))
  }

  return (
    <ContentContainer>
      <AvailabilityTitle>
        <Subhead level='2' size={SubheadSize.LARGE} textAlign='left' text='Calendar' />
      </AvailabilityTitle>
      <BodyTextContainer>
        <BodyText
          color={colors.textSecondary}
          textAlign='left'
          text='Select at least one calendar you’d like to use to check your availability, then from those pick one where you’d like new appointments to be added.'
        />
      </BodyTextContainer>
      <Table>
        <TableHeaderContainer>
          <TableTitle>
            <BodyText bold={true} textAlign='left' text='CONNECTED CALENDARS' size={BodyTextSize.CAPTION} />
          </TableTitle>
          <TableTitleCenterContainer>
            <BodyText bold={true} textAlign='left' text='GET AVAILABILITY FROM' size={BodyTextSize.CAPTION} />
            <Tooltip
              content='We’ll check these calendars for your availability'
              placement='top'
              hoverEnabled={true}
              triggerConfig={{
                icon: TOOLTIP_TRIGGER_ICON.INFO,
                size: 16,
                fillColor: colors.textSecondary,
              }}
              contentStyle={{ container: { maxWidth: 315 } }}
            />
          </TableTitleCenterContainer>
          <TableTitleCenterContainer>
            <BodyText bold={true} textAlign='left' text='CREATE APPOINTMENTS IN' size={BodyTextSize.CAPTION} />
            <Tooltip
              content='We’ll add new appointments in this calendar'
              placement='top'
              hoverEnabled={true}
              triggerConfig={{
                icon: TOOLTIP_TRIGGER_ICON.INFO,
                size: 16,
                fillColor: colors.textSecondary,
              }}
            />
          </TableTitleCenterContainer>
        </TableHeaderContainer>
        {renderAvailabilityList()}
      </Table>
    </ContentContainer>
  )
}

type SchedulingAvailabilityPickerProps = {
  setBlockingCalendars: Function
  blockingCalendars: string[]
  setAppointmentCalendar: Function
  appointmentCalendar: string
  setStatusButton: Function
  statusButton: boolean
  calendarDataList: { accessRole: string; id: string; summary: string; use: string; primary: boolean }[]
  providerId: string
  actions: {
    updateCalendarUse: (data: { id: string; use: string }, providerId: string) => void
    getSchedulingDataUpdated: (providerId: string) => void
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    actions: bindActionCreators(
      {
        updateCalendarUse,
        getSchedulingDataUpdated,
      },
      dispatch,
    ),
  }
}

export default connect(null, mapDispatchToProps)(SchedulingAvailabilityPicker)
