import React, { FunctionComponent } from 'react'

import { HeadlineSize, TextType } from '../../styles/typeStyles'
import { BaseText, BaseTextProps } from '../baseText/BaseText'

// Map headline sizes to header levels for web accessibility (h1, h2, etc)
const defaultLevels = {
  [HeadlineSize.LARGE]: '1',
  [HeadlineSize.MEDIUM]: '1',
  [HeadlineSize.SMALL]: '2',
  [HeadlineSize.EYEBROW]: '5',
}

export const Headline: FunctionComponent<HeadlineProps> = (props) => {
  const { size, level, style } = props
  return <BaseText {...props} type={TextType.HEADLINE} level={level || defaultLevels[size]} style={style} />
}
export const Size = HeadlineSize

export interface HeadlineProps extends Omit<BaseTextProps, 'size' | 'type'> {
  size: HeadlineSize
}
