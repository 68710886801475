import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'

import styled from 'styled-components/native'

import { DropdownButton, tID, toJS } from '@lyrahealth-inc/ui-core-crossplatform'
import { ButtonType } from '@lyrahealth-inc/ui-core-crossplatform/src/atoms/baseButton/BaseButton'

import { mixpanelEvents } from '../../../../mixpanel/mixpanelConstants'
import { track } from '../../../../mixpanel/mixpanelTracking'
import { COACHING_HIDDEN_FILTER_CATEGORIES } from '../../common/constants/appConstants'

const evidenceBasedModalString = 'Evidence Based Model'
const skillsFocusString = 'Skills Focus'
const presentingIssueString = 'Presenting Issue'

enum FILTER_NAME_DEFAULT_VALUE {
  'Evidence Based Model' = 'All Evidence Based Models',
  'Skills Focus' = 'All Skills Focus',
  'Presenting Issue' = 'All Presenting Issues',
}

interface CategoryItems {
  text: string
  id: string
  selectHandler: (event?: React.SyntheticEvent<unknown>) => void
}
type ContentFilterProps = {
  categoryTags: any[]
  setFilteredActivities: Dispatch<SetStateAction<any[]>>
  allActivities: any[]
  isProviderCoach: boolean
}

const ContentFilterContainer = styled.View({
  flexDirection: 'row',
  gap: '5px',
})

const ContentFilter: React.FC<ContentFilterProps> = ({
  categoryTags,
  setFilteredActivities,
  allActivities,
  isProviderCoach,
}: any) => {
  const [evidenceBasedModel, setEvidenceBasedModel] = useState(evidenceBasedModalString)
  const [skillsFocus, setSkillsFocus] = useState(skillsFocusString)
  const [presentingIssue, setPresentingIssue] = useState(presentingIssueString)
  const populateCategoryItems = useCallback(
    (categoryName: string, categoryHandler: React.Dispatch<any>) => {
      const categoryItems: CategoryItems[] = []
      const index = categoryTags.findIndex((tag: { category_name: string }) => tag.category_name === categoryName)
      const categoryNameDefaultValue = FILTER_NAME_DEFAULT_VALUE[categoryName]
      categoryItems.push({
        text: categoryNameDefaultValue,
        id: 'All',
        selectHandler: () => {
          categoryHandler(categoryNameDefaultValue)
          track({
            event: mixpanelEvents.FILTER_PP_CONTENT_LIBRARY,
            properties: { category: categoryName, content_tag: categoryNameDefaultValue },
          })
        },
      })
      categoryTags?.[index]?.content_tags.forEach((tag: { name: string }) => {
        if (!isProviderCoach || !COACHING_HIDDEN_FILTER_CATEGORIES.includes(tag.name)) {
          categoryItems.push({
            text: tag.name,
            id: tag.name,
            selectHandler: () => {
              categoryHandler(tag.name)
              track({
                event: mixpanelEvents.FILTER_PP_CONTENT_LIBRARY,
                properties: { category: categoryTags?.[index]?.category_name, content_tag: tag.name },
              })
            },
          })
        }
      })
      return categoryItems
    },
    [categoryTags, isProviderCoach],
  )
  const getFilteredItemsForCategory = useCallback(
    (updatedListOfActivities: any[], categoryTagName: string, categoryFilterString: string) => {
      const index = categoryTags.findIndex(
        (tag: { category_name: string }) => tag.category_name === categoryFilterString,
      )
      if (!(categoryTagName.startsWith('All') || categoryTagName === categoryTags?.[index]?.category_name)) {
        updatedListOfActivities = updatedListOfActivities.filter((content: any) => {
          return content?.content_tags?.some(
            (tags: { category_id: string; name: string }) =>
              categoryTags?.[index]?.category_id === tags.category_id && tags.name === categoryTagName,
          )
        })
      }
      return updatedListOfActivities
    },
    [categoryTags],
  )
  useEffect(() => {
    let updatedActivities = allActivities
    updatedActivities = getFilteredItemsForCategory(updatedActivities, evidenceBasedModel, evidenceBasedModalString)
    updatedActivities = getFilteredItemsForCategory(updatedActivities, skillsFocus, skillsFocusString)
    updatedActivities = getFilteredItemsForCategory(updatedActivities, presentingIssue, presentingIssueString)
    setFilteredActivities(updatedActivities)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evidenceBasedModel, skillsFocus, presentingIssue])

  const evidenceBasedModelItems: CategoryItems[] = populateCategoryItems(
    evidenceBasedModalString,
    setEvidenceBasedModel,
  )
  const skillsFocusItems: CategoryItems[] = populateCategoryItems(skillsFocusString, setSkillsFocus)
  const presentingIssuesItems: CategoryItems[] = populateCategoryItems(presentingIssueString, setPresentingIssue)

  return (
    <ContentFilterContainer>
      {
        // Hide category from Coaching
        !isProviderCoach && (
          <DropdownButton
            testID={tID('ContentFilter-evidenceBasedModel')}
            dropdownItems={evidenceBasedModelItems}
            buttonType={ButtonType.SECONDARY}
            dropdownWidth={'275px'}
          />
        )
      }
      <DropdownButton
        testID={tID('ContentFilter-skillsFocus')}
        dropdownItems={skillsFocusItems}
        buttonType={ButtonType.SECONDARY}
        dropdownWidth={'315px'}
      />
      <DropdownButton
        testID={tID('ContentFilter-presentingIssue')}
        dropdownItems={presentingIssuesItems}
        buttonType={ButtonType.SECONDARY}
        dropdownWidth={'255px'}
      />
    </ContentFilterContainer>
  )
}

export default toJS(ContentFilter)
