import React, { useState } from 'react'
import CSSModules from 'react-css-modules'
import { Field, useForm, useFormState } from 'react-final-form'
import styles from './icd10s.module.scss'
import { SecondaryButton, BaseModal, Title } from '@lyrahealth-inc/ui-core'
import ICD10sSelection from './ICD10sSelection'
import { ICD10_PRIMARY_FIELD, ICD10_SECONDARY_FIELD, attendanceValues } from '../../constants/appConstants'
import { isEmpty } from 'lodash-es'

type Props = {
  availableCodes?: any
  titleLevel?: number
}

const ICD10sField = ({ availableCodes = {}, titleLevel = 1 }: Props) => {
  const [showModal, setShowModal] = useState(false)
  const [selectedField, setSelectedField] = useState(ICD10_PRIMARY_FIELD)
  const { change } = useForm()
  const { values } = useFormState()

  const saveSelectedCodes = (codes: any) => {
    change(selectedField, codes)
    setShowModal(false)
  }

  const setAndShowModal = (field: any) => {
    setSelectedField(field)
    setShowModal(true)
  }
  // @ts-expect-error TS(7030): Not all code paths return a value.
  const validatePrimaryCode = (fieldName: any, val: any) => {
    if (fieldName === ICD10_PRIMARY_FIELD && isEmpty(val) && attendanceValues.ATTENDED === values?.attendance) {
      return 'Primary code'
    }
  }

  return (
    <>
      <BaseModal
        closeModal={() => setShowModal(false)}
        showCloseButton={false}
        isOpen={showModal}
        body={
          <ICD10sSelection
            availableCodes={availableCodes}
            initialCodes={
              selectedField === ICD10_PRIMARY_FIELD ? values[ICD10_PRIMARY_FIELD] : values[ICD10_SECONDARY_FIELD]
            }
            multiSelect={selectedField !== ICD10_PRIMARY_FIELD}
            submitFunction={saveSelectedCodes}
          />
        }
      />
      {/* @ts-expect-error TS(2322): Type 'number' is not assignable to type '1 | 2 | 3... Remove this comment to see the full error message */}
      <Title level={titleLevel} styleName='title'>
        Required Information for Health Plan and High-Risk Care
      </Title>
      <p>
        The client is currently using their medical plan for Lyra sessions. ICD-10&apos;s are required by their medical
        plan.
      </p>
      {[ICD10_PRIMARY_FIELD, ICD10_SECONDARY_FIELD].map((field) => (
        <div className='row' key={field}>
          <Field
            name={field}
            validate={(val) => validatePrimaryCode(field, val)}
            component={CSSModules(
              ({ input: { value } }: any) => (
                <div
                  className='col-xs-12'
                  styleName='section'
                  data-test-id={`ICD10sField-${field === ICD10_PRIMARY_FIELD ? 'primaryField' : 'secondaryField'}`}
                >
                  <div styleName='label'>
                    {field === ICD10_PRIMARY_FIELD ? (
                      <>Primary Mental Health Diagnosis *</>
                    ) : (
                      <>
                        Secondary Mental Health Diagnosis <span>(if applicable)</span>
                      </>
                    )}
                  </div>
                  <div styleName='context'>
                    {field === ICD10_PRIMARY_FIELD
                      ? 'Required for clients using their medical plan.'
                      : 'e.g. substance abuse'}
                  </div>
                  <div styleName='code-tokens-container'>
                    {(value || []).map((code: any) => (
                      <div styleName='code-token code-token-removeable' key={code.value}>
                        {code.value}
                        <button
                          // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'number'.
                          tabIndex='-1'
                          aria-label='Remove'
                          styleName='close'
                          type='button'
                          onClick={() =>
                            change(
                              field,
                              value.filter((c: any) => c.code !== code.code),
                            )
                          }
                        >
                          <span aria-hidden='true'>×</span>
                        </button>
                      </div>
                    ))}
                  </div>
                  <SecondaryButton
                    data-test-id={`ICD10sForm-add${field === ICD10_PRIMARY_FIELD ? 'Primary' : 'Secondary'}`}
                    onClick={() => setAndShowModal(field)}
                  >{`Add ${field === ICD10_PRIMARY_FIELD ? 'Primary' : 'Secondary'} ICD-10 Code`}</SecondaryButton>
                </div>
              ),
              styles,
              { allowMultiple: true },
            )}
          />
        </div>
      ))}
    </>
  )
}

export default CSSModules(ICD10sField, styles, { allowMultiple: true })
