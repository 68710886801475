import React, { Component } from 'react'

// @ts-expect-error TS(7016): Could not find a declaration file for module '@okt... Remove this comment to see the full error message
import SignIn from '@okta/okta-signin-widget/dist/js/okta-sign-in.min.js'

import { OktaStyleWrapper } from '@lyrahealth-inc/ui-core'

import { LWUrl, oktaBaseUrl, oktaClientId, oktaGoogleSSOClientId } from '../common/constants/appConstants'

import '@okta/okta-signin-widget/dist/sass/okta-sign-in.scss'

class Login extends Component {
  componentDidMount() {
    const LINK_HREF = `${LWUrl('care')}/forgot-password?referrer=${window.location.origin}`
    const signIn = new SignIn({
      baseUrl: oktaBaseUrl,
      clientId: oktaClientId,
      language: 'en',
      redirectUri: window.location.origin + '/okta/callback',
      idps: [{ type: 'GOOGLE', id: oktaGoogleSSOClientId }],
      idpDisplay: 'PRIMARY',
      authParams: {
        responseType: 'code',
        display: 'page',
        scopes: ['openid', 'email', 'profile'],
      },
      features: {
        rememberMe: false,
      },
      i18n: {
        en: {
          'primaryauth.title': 'Login',
          'primaryauth.username.placeholder': 'Email',
          'error.username.required': 'Please enter an Email',
          'socialauth.google.label': 'Continue with Google',
          'errors.E0000004': 'Incorrect username and/or password',
          'error.auth.lockedOut': 'This account may be locked, or may not exist. Please try to reset your password.',
        },
      },
      helpLinks: {
        forgotPassword: LINK_HREF,
        help: 'https://auth-help.zendesk.com/hc/en-us',
      },
    })
    signIn.remove()
    signIn.renderEl({ el: '#sign-in-container' }, () => {})
  }

  render() {
    return (
      <OktaStyleWrapper>
        <div id='sign-in-container' />
      </OktaStyleWrapper>
    )
  }
}

export default Login
