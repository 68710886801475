import React, { Component, createRef, ReactElement, RefObject } from 'react'

import classNames from 'classnames'
import DetectRTC from 'detectrtc'
import { fromJS, Iterable, List, Map } from 'immutable'
import { capitalize, debounce, isFunction, isNil } from 'lodash-es'
import { clearInterval, clearTimeout, setInterval, setTimeout } from 'long-timeout'
import momentDurationFormatSetup from 'moment-duration-format'
import moment from 'moment-timezone' // note: moments in time within this component are set up / converted to user locals

import {
  Codec,
  DataTrackMessage,
  VideoSession as VideoAppointment,
  videoViewStyle,
} from '@lyrahealth-inc/shared-app-logic'

import ButtonsContainer from './ButtonsContainer'
import FullScreenContainer from './FullScreenContainer'
import NextSessionReminder from './NextSessionReminder'
import SessionInfo from './SessionInfo'
import { getDefaultConstraints } from './utils'
import VideoPreviews from './VideoPreviews'
import styles from './videoSession.module.scss'
import PrimaryButton from '../../atoms/buttons/primaryButton/PrimaryButton'
import TextButton from '../../atoms/buttons/textButton/TextButton'
import ToggleSwitch from '../../atoms/form/toggleSwitch/ToggleSwitch'
import dateUtils from '../../utils/dateUtils'
import Notifications from '../notifications/Notifications'
import VideoCall, { VideoCallEffects } from '../videoCall/VideoCall'

// so we can format a moment duration as 'HH:mm:ss'
momentDurationFormatSetup(moment as any)

type VideoSessionProps = OwnVideoSessionProps & typeof VideoSession.defaultProps

class VideoSession extends Component<VideoSessionProps, VideoSessionState> {
  static defaultProps = {
    reminderWindow: 10,
    $$appointments: fromJS([]),
    settings: {},
    track: () => {},
    logger: () => {},
    isRecording: true,
    timeZone: moment.tz.guess(true),
    hasOpenedSessionFromBanner: false,
    disableBanner: false,
  }

  videoSessionNotifications: any = null

  videoContainers: null | Dict = null

  hidden = null

  hiddenTerm = ''

  sessionInfo = {
    patient_id: '',
    provider_id: '',
  }

  defaultContraints = {}

  videoCallRef = createRef<VideoCall>()

  sessionStarted = false

  // ===================================
  // Life Cycle Methods: ===============
  // ===================================

  constructor(props: VideoSessionProps) {
    super(props)
    this.state = {
      inSession: false,
      muted: false,
      videoOff: false,
      providerSharingScreen: false,
      startingScreenshare: false,
      startingSession: false, // used to display a loading spinner within the 'start session' (provider) and 'join' (client) buttons
      openingSession: false, // used to display a loading spinner within the reminder bar button for providers
      initializingTracks: true, // used to display loading spinners within video containers while tracks are being initialized
      participantStatusMessage: null, // used to display the appropriate status message for the participant, eg - 'pending', 'waiting', 'left'
      viewStyle: this.props.type === 'provider' ? videoViewStyle.PREVIEW : videoViewStyle.FULLSCREEN,
      notificationIntervals: {}, // { notificationID: timeout/interval id, ... }
      reminders: {}, // { appointmentId: { dateTime, timer }, ... }
      $$nextSession: fromJS({}), // { id, appointment, dateTime, countdown, startsIn }
      $$currentSession: fromJS({}), // { id, appointment, dateTime, countdown, startsIn }
      alert: null, // { message: '', type: ['error', 'warning', 'info', 'success'], timer }
      participantNetworkQuality: null,
      showVideoWhileScreenSharing: false,
      isFullScreenMuted: false,
      fullScreenOwnsVideo: false,
      isFullScreenVideoOff: false,
      isUserPreviewMuted: false,
      isUserPreviewVideoOff: false,
      isParticipantPreviewMuted: false,
      isParticipantPreviewVideoOff: false,
      effect: VideoCallEffects.NONE,
    }
    this.defaultContraints = getDefaultConstraints()
  }

  componentDidMount() {
    const { $$appointments } = this.props
    if ($$appointments && $$appointments.size > 0) {
      this.scheduleReminders($$appointments)
    }

    this.detectVisibility()
  }

  componentDidUpdate(prevProps: VideoSessionProps) {
    const { $$appointments, type } = this.props
    const { reminders } = this.state
    const isProvider = type === 'provider'
    if (!$$appointments.equals(prevProps.$$appointments)) {
      if ($$appointments.size > 0) {
        this.scheduleReminders($$appointments)
      } else {
        this.cleanupAppointmentReminders($$appointments, Object.assign({}, reminders))
      }
    }

    if (type !== prevProps.type) {
      // TODO: why are we setting state in componentDidUpdate, disabling eslint complaining for now,
      // because removing this causes a bug with the provider view to occur
      // eslint-disable-next-line
      this.setState({
        viewStyle: isProvider ? videoViewStyle.PREVIEW : videoViewStyle.FULLSCREEN,
      })
    }

    if (this.props.hasOpenedSessionFromBanner && !this.sessionStarted) {
      this.openSession()
    }
  }

  // since browsers throttle Javascript activity on inactive tabs, it throws off our setTimeouts if the user leaves and returns, their computer goes to sleep, etc.
  // the below detects when the user returns to our page, and rebuilds the timers
  private detectVisibility = () => {
    let visibilityChangeTerm = ''

    if (typeof document.hidden !== 'undefined') {
      this.hiddenTerm = 'hidden'
      visibilityChangeTerm = 'visibilitychange'
    } else if (typeof (document as any).msHidden !== 'undefined') {
      this.hiddenTerm = 'msHidden'
      visibilityChangeTerm = 'msvisibilitychange'
    }

    this.hidden = (document as any)[this.hiddenTerm]

    document.addEventListener(visibilityChangeTerm, this.handleVisibilityChange)
  }

  private handleVisibilityChange = () => {
    if (this.hidden !== document[this.hiddenTerm]) {
      if (document[this.hiddenTerm]) {
        // Document hidden
        // leaving this here as an optional TODO, in case we need to further optimize/debounce by setting leave times to compare against on return.
        // Only issue is we don't know for sure what time threshold is in play here, so it would be a guess
      } else {
        // Document shown
        if (this.props.$$appointments.size > 0) {
          this.refreshTimers()
        }
      }

      this.hidden = document[this.hiddenTerm]
    }
  }

  // clear and reset timers for reminders
  private refreshTimers = debounce(
    () => {
      const { $$appointments } = this.props
      const reminders = Object.assign({}, this.state.reminders)

      // refresh reminder timers
      for (const key in reminders) {
        // find corresponding appointment object
        const $$matchedAppointment = $$appointments.find((appt: $TSFixMe) => {
          return appt.get('appointmentId') === parseInt(key, 10)
        })

        // clear timer and reminder, since they could now be inaccurate
        clearTimeout(reminders[key].timer)
        delete reminders[key]

        // now build a new one
        reminders[$$matchedAppointment.get('appointmentId')] = this.buildReminder($$matchedAppointment)
      }
      this.setState({ reminders })
    },
    2000,
    { leading: false, trailing: true },
  )

  // Helper Utilities: =================
  // ===================================

  private setNotificationsRef = (elem: RefObject<HTMLDivElement>) => {
    this.videoSessionNotifications = elem
  }

  private getActiveSessionIDs = () => {
    const { $$currentSession, $$nextSession } = this.state
    const currentAndNextIdsArray = []
    if ($$currentSession.get('id')) currentAndNextIdsArray.push($$currentSession.get('id'))
    if ($$nextSession.get('id')) currentAndNextIdsArray.push($$nextSession.get('id'))
    return currentAndNextIdsArray
  }

  private getExpiration = (sessionObj: any) => {
    let expirationTime
    if (Iterable.isIterable(sessionObj.appointment)) {
      expirationTime = sessionObj.appointment.get('appointmentDuration', 50)
    } else {
      expirationTime = sessionObj.appointment.appointmentDuration || 50
    }
    const expiration = moment
      .duration(sessionObj.dateTime.diff(moment()))
      .add(expirationTime, 'minutes')
      .asMilliseconds()
    return expiration
  }

  // builds an object for updating state
  // optionally clears the given scoped object if called for.
  // (needed since the session in question could be in the "next" or "current" bucket,
  // so we need to find its current location in order to correctly update it)
  private buildStateUpdateObj(id: string, clear?: boolean) {
    const stateUpdateObj = {}
    let scope = ''
    if (this.state.$$nextSession.get('id') === id) {
      scope = '$$nextSession'
    } else {
      scope = '$$currentSession'
    }

    if (clear) {
      stateUpdateObj[scope] = fromJS({})
      clearInterval(this.state[scope].get('countdown'))
    } else {
      stateUpdateObj[scope] = this.state[scope]
    }

    return { stateUpdateObj, scope }
  }

  private sendLog = ({
    sourceCategory = 'ltVideoSession',
    message,
    type,
    ...rest
  }: {
    sourceCategory?: string
    message: string
    type: string
    [key: string]: any
  }) => {
    const { provider_id, patient_id } = this.sessionInfo
    const { type: userRole, logger } = this.props
    const idToLog = userRole === 'provider' ? provider_id : patient_id
    const dataToLog = {
      alertMessage: message,
      alertType: type,
      sessionInfo: this.sessionInfo,
      userRole: userRole,
      ...rest,
    }
    logger(sourceCategory, idToLog, dataToLog)
  }

  private showAlert = (alertObj: any) => {
    const { alert } = this.state
    // send error and context to our logger, if a customized one has not already been sent
    if (!alertObj.customLog) {
      this.sendLog(alertObj)
    }

    if (alert && alert.timer) {
      clearTimeout(alert.timer)
    }

    if (!alertObj.noTimer) {
      const timer = setTimeout(() => {
        this.setState({
          alert: null,
        })
      }, 5000)
      alertObj.timer = timer
    }

    this.setState({
      alert: alertObj,
    })
  }

  // ===================================
  // Primary Feature Methods: ==========
  // ===================================

  private cleanupAppointmentReminders = ($$appointments: List<any>, reminders: any) => {
    // remove no longer needed reminders
    for (const key in reminders) {
      if (
        !$$appointments.find((appt: any) => {
          return appt.get('appointmentId') === parseInt(key, 10)
        })
      ) {
        clearTimeout(reminders[key].timer)
        delete reminders[key]
      }
    }

    // account for cancellations of active session
    // check to see if next/current session still exists in the appointsment array,
    // and remove it from state and view if it doesn't
    const currentAndNextIdsArray = this.getActiveSessionIDs()
    currentAndNextIdsArray.forEach((idToCheck: string) => {
      if (
        !$$appointments.find((appt) => {
          return appt.get('appointmentId') === idToCheck
        })
      ) {
        const { stateUpdateObj, scope } = this.buildStateUpdateObj(idToCheck, true)
        if (!(scope === '$$currentSession' && this.state.inSession)) {
          // guard against deleting a session that is truly in progress
          this.setState(stateUpdateObj)
        }
      }
    })
  }

  private scheduleReminders = ($$appointments: List<any>) => {
    const { timeZone } = this.props
    const { inSession } = this.state
    const reminders = Object.assign({}, this.state.reminders)
    const currentAndNextIdsArray = this.getActiveSessionIDs()

    // cleanup
    this.cleanupAppointmentReminders($$appointments, reminders)

    // traverse appointments and set/update reminders for them
    $$appointments.forEach((appointment) => {
      let newReminder
      const appointmentDateTime = dateUtils.getAppointmentDateTimeObject(appointment).tz(timeZone)

      if (currentAndNextIdsArray.includes(appointment.get('appointmentId'))) {
        // appointment "is now" and showing as either next or current session

        const { stateUpdateObj, scope } = this.buildStateUpdateObj(appointment.get('appointmentId'))
        if (!appointmentDateTime.isSame(stateUpdateObj[scope].get('dateTime'))) {
          // time has changed, remove and set new reminder
          if (!(scope === '$$currentSession' && inSession)) {
            // ... as long as we are not inSession
            clearInterval(stateUpdateObj[scope].get('countdown'))
            stateUpdateObj[scope] = fromJS({})
            this.setState(stateUpdateObj)
          }
          newReminder = this.buildReminder(appointment)
        }
      } else {
        if (!reminders[appointment.get('appointmentId')]) {
          // set new reminder
          newReminder = this.buildReminder(appointment)
        } else {
          // reminder already exists, check if the time has changed

          const reminder = reminders[appointment.get('appointmentId')]
          if (!appointmentDateTime.isSame(reminder.dateTime)) {
            // time has changed, remove and set new reminder
            clearTimeout(reminder.timer)
            delete reminders[appointment.get('appointmentId')]
            newReminder = this.buildReminder(appointment)
          }
        }
      }

      if (newReminder) {
        reminders[appointment.get('appointmentId')] = newReminder
      }
    })

    // update state after loop is done
    this.setState({ reminders })
  }

  // sets a timer for each appointment, that shows the reminder bar when triggered
  private buildReminder = ($$appointment: any) => {
    const { $$nextSession, notificationIntervals, $$currentSession } = this.state
    const { timeZone, reminderWindow } = this.props
    // remove already showing session reminder if it exists, it will reshow with the updated information when applicable
    if ($$nextSession.get('id') === $$appointment.get('appointmentId')) {
      this.setState({
        $$nextSession: fromJS({}),
      })
    }

    // remove any already showing "next session begins in" notifications if it exists, it will reshow with the updated information when applicable
    const nextUid = `${$$appointment.get('appointmentId')}_start`
    if (nextUid in notificationIntervals) {
      this.dismissNotification(nextUid)
    }

    const appointmentDateTime = dateUtils.getAppointmentDateTimeObject($$appointment).tz(timeZone)
    // determine time until the showing window
    let delay = moment.duration(appointmentDateTime.diff(moment())).subtract(reminderWindow, 'minutes').asMilliseconds()

    // if reminder is already within the showing window, then trigger immediately
    if (delay < 0) {
      delay = 0
    }

    let newReminder
    const expiration = this.getExpiration({
      id: $$appointment.get('appointmentId'),
      appointment: $$appointment,
      dateTime: appointmentDateTime,
    })
    // only set new reminders for upcoming appointments
    if (expiration >= 0) {
      newReminder = {
        dateTime: appointmentDateTime,
        timer: setTimeout(() => {
          const apptID = $$appointment.get('appointmentId')

          // if a session is already in progress, we show a notification for this next session
          if ($$currentSession.size > 0) {
            const minutesLeft = moment.duration(appointmentDateTime.diff(moment())).asMinutes()
            const message = this.generateNotificationMessageUpdate('nextSession', minutesLeft)

            this.showNotification({
              message: message,
              level: 'info',
              position: 'bc',
              autoDismiss: 0,
              dismissible: 'button',
              uid: `${apptID}_start`,
              onAdd: (notification: any) =>
                this.setNotificationTimer(notification.uid, appointmentDateTime, 'nextSession'),
              onRemove: (notification: any) => this.clearNotificationTimer(notification.uid),
            })
          }

          // set session reminder bar. will show immediately-if/eventually-when no current session is active
          this.showSessionReminder({
            id: apptID,
            appointment: $$appointment,
            dateTime: appointmentDateTime,
          })
        }, delay),
      }
    }

    // can't setstate here due to race conditions, so return so it can be set in parent function
    return newReminder
  }

  // generates a countdown timer and shows the session reminder bar
  private showSessionReminder = (sessionObj: any) => {
    // delete reminder object in state once triggered
    const reminders = Object.assign({}, this.state.reminders)
    delete reminders[sessionObj.id]
    this.setState({
      reminders: reminders,
    })

    // determine how long until session start
    let startsIn: any = moment.duration(sessionObj.dateTime.diff(moment()))
    if (startsIn.asMilliseconds() < 0) {
      // we're already beyond the start time, so display "now"
      startsIn = 'now'
    }
    sessionObj.startsIn = startsIn

    // if not already within the window, generate a countdown timer for the session
    if (sessionObj.startsIn !== 'now') {
      const intervalID = setInterval(() => {
        this.updateSessionCountdown(sessionObj.id)
      }, 1000) // update the "starts in" time every second

      sessionObj.countdown = intervalID
    }

    // expire the visible reminder if it's been active and idle for too long past the appointment start time
    const expiration = this.getExpiration(sessionObj)
    sessionObj.expiration = setTimeout(() => {
      this.setState({ $$nextSession: fromJS({}) })
    }, expiration)

    this.setState({
      $$nextSession: fromJS(sessionObj),
    })
  }

  // subtract a second from the current "starts in" display
  private updateSessionCountdown = (id: string) => {
    const { stateUpdateObj, scope } = this.buildStateUpdateObj(id)
    let duration = stateUpdateObj[scope].get('startsIn')
    if (isNil(duration) || !isFunction(duration.subtract)) {
      return
    }
    duration = duration.subtract(1, 'second')

    // if beyond start time, display "now" and clear interval
    if (duration.asMilliseconds() < 0) {
      duration = 'now'
      clearInterval(stateUpdateObj[scope].get('countdown'))
      stateUpdateObj[scope] = stateUpdateObj[scope].delete('countdown')
    }

    stateUpdateObj[scope] = stateUpdateObj[scope].set('startsIn', duration)

    this.setState(stateUpdateObj)
  }

  private openSession = () => {
    this.sessionStarted = true
    const { $$nextSession } = this.state
    const { type, sessionOpenedCallback, track } = this.props
    const isProvider = type === 'provider'
    DetectRTC.load(() => {
      if (DetectRTC.isWebRTCSupported) {
        // fire mixpanel
        track({
          page: 'Video Session',
          event: 'Button Press',
          action: 'Open Video Session',
          details: capitalize(type),
        })

        let $$session = $$nextSession
        clearInterval($$session.get('countdown'))
        clearTimeout($$session.get('expiration'))
        $$session = $$session.delete('countdown').delete('expiration')

        // move session from next to current
        this.setState({
          $$currentSession: $$session,
          $$nextSession: fromJS({}),
        })

        if (isProvider) {
          this.setState({
            openingSession: true,
          })
          this.providerSoftJoin({
            apptID: $$session.get('id'),
            participantID: $$session.getIn(['appointment', 'userInfo', 'lyraId']),
          })
        } else {
          // parent callback
          sessionOpenedCallback()
        }
      } else {
        this.showUnsupportedModal()
      }
    })
  }

  // init tracks, start call, but don't publish tracks to room
  private providerSoftJoin = (ids: { apptID: string; participantID: string }) => {
    // get token and room name using the parent component function
    this.props
      .getTokenFunction(ids)
      .then((returnObj: any) => {
        this.sessionInfo = returnObj // save session info for future context (logging)

        // start twilio functionality to enter room
        this.sendLog({
          message: 'Provider joining room attempt',
          type: 'info',
        })
        if (this.videoCallRef.current) {
          const promise = this.videoCallRef.current.joinRoom(
            returnObj.access_token,
            returnObj.room_name,
            this.sessionInfo,
            () => this.providerEnteredWaitingRoom(),
          )

          promise &&
            promise.catch((error: Error) => {
              this.showAlert({ message: 'Unable to connect to room: ' + error.message, type: 'error' })
              this.setState({
                openingSession: false,
              })
            })
        }
      })
      .catch((error: Error) => {
        this.showAlert({ message: 'Unable to connect to room: ' + error.message, type: 'error' })
        this.setState({
          openingSession: false,
        })
      })
  }

  // function to handle refreshes or other reconnections from either side of the call
  private rejoinSession = (attempt: number, preferredVideoCodecs: Codec[] = []): Promise<unknown> => {
    const { type, track, getTokenFunction } = this.props
    const { $$currentSession } = this.state
    const contact = this.props.type === 'provider' ? 'Lyra.' : 'the provider.'
    const reconnectionFailureMessage =
      'We are unable to connect to the room at this time. Please check your internet connection, refresh, and try again later. If the problem persists, please contact'
    const retryRejoin = () => {
      this.sendLog({
        message: `Retry Rejoin STARTED with attempt: ${attempt} and preferredVideoCodecs: ${preferredVideoCodecs}`,
        type: 'info',
      })
      if (attempt < 3) {
        return new Promise((resolve) =>
          setTimeout(() => {
            this.rejoinSession(attempt + 1, preferredVideoCodecs)
            resolve(true)
          }, 5000),
        )
      } else {
        return new Promise((resolve) => {
          setTimeout(() => {
            this.showAlert({
              message: `${reconnectionFailureMessage} ${contact}`,
              type: 'error',
            })
            this.sendLog({
              message: `Retry Rejoin FAILED with attempt: ${attempt}, preferredVideoCodecs: ${preferredVideoCodecs}, with error: ${reconnectionFailureMessage}`,
              type: 'info',
            })
            resolve(true)
          }, 5000)
        })
      }
    }
    this.showAlert({
      message: `Trying to reconnect... (attempt ${attempt} of 3)`,
      type: 'info',
      noTimer: true,
    })
    if (preferredVideoCodecs.length > 0) {
      this.sendLog({
        message: `Attempting to reconnect to room for codec negotiation with preferred codecs - ${preferredVideoCodecs}`,
        type: 'info',
      })
    } else {
      // let our logger know
      this.sendLog({ message: 'Attempting to reconnect to room due to unexpected disconnection', type: 'info' })
    }
    track({
      page: 'Video Session',
      event: 'Rejoining Session',
      details: capitalize(type),
    })
    if (navigator.onLine) {
      return getTokenFunction({
        apptID: $$currentSession.get('id'),
        participantID:
          this.props.type === 'provider'
            ? $$currentSession.getIn(['appointment', 'userInfo', 'lyraId'])
            : $$currentSession.getIn(['appointment', 'provider', 'lyra_id']),
      })
        .then((videoSession: VideoAppointment) => {
          // assign the preferred video codecs if passed
          if (preferredVideoCodecs.length > 0) {
            videoSession.preferred_video_codecs = preferredVideoCodecs
          }
          this.sessionInfo = videoSession // save session info for future context (logging)
          // start twilio functionality to enter room
          if (this.videoCallRef.current) {
            return this.videoCallRef.current.joinRoom(
              videoSession.access_token,
              videoSession.room_name,
              this.sessionInfo,
              this.props.type === 'provider' ? this.providerEnteredWaitingRoom : this.clientJoinedRoom,
              attempt,
            )
          } else {
            return Promise.resolve()
          }
        })
        .catch(retryRejoin)
    } else {
      return retryRejoin()
    }
  }

  private startSession = () => {
    const { type, track, getTokenFunction, sessionStartedCallback } = this.props
    const { $$currentSession } = this.state
    this.setState({
      startingSession: true,
    })

    if (type === 'client') {
      // they clicked the "Join" button
      // fire mixpanel
      track({
        page: 'Video Session',
        event: 'Button Press',
        action: 'Join Session',
        details: capitalize(type),
      })

      // get token and room name using the parent component function
      getTokenFunction({
        apptID: $$currentSession.get('id'),
        participantID: $$currentSession.getIn(['appointment', 'provider', 'lyra_id']),
      })
        .then((videoSession: VideoAppointment) => {
          this.sessionInfo = videoSession // save session info for future context (logging)

          this.sendLog({
            message: 'Successfully retrieved access token, starting video session for Client',
            type: 'info',
          })

          // start twilio functionality to enter room
          if (this.videoCallRef.current) {
            const promise = this.videoCallRef.current.joinRoom(
              videoSession.access_token,
              videoSession.room_name,
              this.sessionInfo,
              this.clientJoinedRoom,
            )
            promise &&
              promise.catch((error: Error) => {
                this.setState({
                  startingSession: false,
                })
                this.showAlert({ message: 'Client unable to connect to room: ' + error, type: 'error' })
              })
          }
        })
        .catch((error: Error) => {
          this.showAlert({ message: 'Client unable to get video session access token: ' + error, type: 'error' })
          this.setState({
            startingSession: false,
          })
        })
    } else {
      // the provider clicked the "Start Session" button
      // fire mixpanel
      track({
        page: 'Video Session',
        event: 'Button Press',
        action: 'Start Session',
        details: capitalize(type),
      })

      // publish provider tracks
      if (this.videoCallRef.current) {
        this.sendLog({
          message: 'Starting video session for Provider. AKA, publish provider tracks',
          type: 'info',
        })
        this.videoCallRef.current.startSession().then(() => {
          this.setState({
            inSession: true,
            startingSession: false,
          })

          // now that the session has officially started, set a timer for the "5 minutes left" notification
          this.setSessionEndingNotification()

          // fire mixpanel
          track({ page: 'Video Session', event: 'Session Started', details: capitalize(type) })

          // parent callback
          sessionStartedCallback($$currentSession.getIn(['appointment', 'userInfo', 'lyraId']))
        })
      }
    }
  }

  private endSession = () => {
    const { type, closeModal, setAndShowModal, track } = this.props
    const isProvider = type === 'provider'
    // fire mixpanel
    track({
      page: 'video session',
      event: 'click end call button',
      details: type,
    })
    if (isProvider) {
      const modalContent = (
        <div className={styles['end-confirmation-modal']} data-test-id='VideoSession-endSessionModal'>
          <h2>Are you sure you want to end the session?</h2>
          <p>
            If you end this session, you will not be able to rejoin. If you are experiencing technical difficulties, try
            refreshing the page and then rejoining.
          </p>
          <div className={styles['ctas-container']}>
            <TextButton text='Cancel' onClick={closeModal} data-test-id='VideoSession-endSessionCancelBtn' />
            <PrimaryButton onClick={this.continueWithEndSession} data-test-id='VideoSession-endSessionConfirmBtn'>
              End Session
            </PrimaryButton>
          </div>
        </div>
      )
      setAndShowModal(modalContent)
    } else {
      this.continueWithEndSession()
    }
    this.sessionStarted = false
  }

  private continueWithEndSession = () => {
    const { closeModal, type, track } = this.props
    const isProvider = type === 'provider'
    track({
      page: 'video session',
      event: 'continue with end session',
      details: type,
    })
    if (isProvider) {
      closeModal()
    }

    if (this.videoCallRef.current) {
      this.videoCallRef.current.leaveRoom(this.sessionEnded)
    }

    // clear notifications and timers
    this.videoSessionNotifications?.clearNotifications()
    for (const key in this.state.notificationIntervals) {
      clearInterval(this.state.notificationIntervals[key])
    }

    this.setState({
      initializingTracks: true,
      notificationIntervals: {},
    }) // reset for next session
  }

  // Auxilary Feature Methods: ==========
  // ====================================

  private toggleAudio = () => {
    const { track, type } = this.props

    this.setState({
      muted: !this.state.muted,
    })

    // fire mixpanel
    track({
      page: 'Video Session',
      event: 'Button Press',
      action: 'Toggle Mute Microphone',
      details: capitalize(type),
    })
  }

  private toggleVideo = () => {
    const { track, type } = this.props
    this.setState({
      videoOff: !this.state.videoOff,
    })

    // fire mixpanel
    track({
      page: 'Video Session',
      event: 'Button Press',
      action: 'Toggle Hide Camera',
      details: capitalize(type),
    })
  }

  private turnOffRecording = () => {
    const { isRecording, track, disableRecording } = this.props
    if (isRecording && this.videoCallRef.current) {
      this.videoCallRef.current.sendData(DataTrackMessage.RECORDING_OFF)

      // fire mixpanel
      track({ page: 'Video Session', event: 'Button Press', action: 'Turn Off Recording' })

      disableRecording()
    }
  }

  private setViewStyle = (viewStyle: string) => {
    this.setState({ viewStyle })

    // fire mixpanel
    const action = viewStyle === videoViewStyle.FULLSCREEN ? 'Maximize Video' : 'Minimize Video'
    this.props.track({ page: 'Video Session', event: 'Button Press', action })
  }

  // Local Callback Functions: ==========
  // ====================================

  // for after provider enters waiting room
  private providerEnteredWaitingRoom = () => {
    const { track, type, sessionOpenedCallback } = this.props
    this.setState({
      openingSession: false,
    })
    this.sendLog({
      message: 'Provider entered waiting room. Set React state of openingSession to false',
      type: 'info',
    })

    // fire mixpanel
    track({ page: 'Video Session', event: 'Entered Waiting Room', details: capitalize(type) })

    // parent callback
    sessionOpenedCallback()

    return Promise.resolve(true) // returned to VideoCall component to support chaining over there
  }

  // for after entering room
  private clientJoinedRoom = () => {
    const { track, sessionStartedCallback, type } = this.props
    this.setState({
      startingSession: false,
      inSession: true,
    })

    // fire mixpanel
    track({ page: 'Video Session', event: 'Entered Waiting Room', details: capitalize(type) })

    // parent callback
    sessionStartedCallback()

    return Promise.resolve(true) // returned to VideoCall component to support chaining over there
  }

  // for after leaving room
  private sessionEnded = () => {
    this.setState({
      inSession: false,
      $$currentSession: fromJS({}),
    })

    // parent callback
    this.props.sessionEndedCallback()
  }

  private userTracksInitialized = () => this.setState({ initializingTracks: false })

  private networkQualityHandler = (level: number) => {
    this.sendLog({ message: 'Network Quality Level changed', type: 'info', networkQualityLevel: level })
    if (level < 2) {
      this.sendLog({ message: 'Low network quality detected for user', type: 'warning', networkQualityLevel: level })
    }
    this.setState({
      participantNetworkQuality: level,
    })
  }

  private participantConnected = () => {
    const { type, track } = this.props
    if (type === 'client') {
      // fire mixpanel
      track({ page: 'Video Session', event: 'Session Started', details: capitalize(type) })
    }
  }

  // called from within VideoCall when a participant status message is warranted
  private setParticipantStatusMessage = (status: string) => {
    this.setState({
      participantStatusMessage: status,
    })
  }

  // Notification Methods: ==============
  // ====================================

  private showNotification = (notification: any) => this.videoSessionNotifications.addNotification(notification)

  private dismissNotification = (uid: string) => this.videoSessionNotifications.removeNotification(uid)

  // create the text message, with appropriate use case and tense, for inside a notification
  private generateNotificationMessageUpdate = (type: string, minutesLeft: number) => {
    const timeLeft = Math.round(minutesLeft)
    let message = ''
    if (type === 'nextSession') {
      let timing = 'now'
      if (timeLeft > 0) {
        timing = `in ${moment.duration(timeLeft, 'minutes').humanize()}`
      }
      message = `Next session begins ${timing}`
    } else if (type === 'sessionEnding') {
      message = 'Session time is up'
      if (timeLeft > 0) {
        const humanizedString = moment.duration(timeLeft, 'minutes').humanize()
        message = `${humanizedString.charAt(0).toUpperCase() + humanizedString.slice(1)} left in this session`
      }
    }
    return message
  }

  // given a new moment in time, update the message within a notification
  private updateNotificationMessage = (uid: string, momentInTime: moment.Moment, type: string) => {
    const minutesLeft = moment.duration(momentInTime.diff(moment())).asMinutes()
    const newMessage = this.generateNotificationMessageUpdate(type, minutesLeft)
    this.videoSessionNotifications.editNotification(uid, { message: newMessage })
  }

  // set a timer for updating the minutes within the notification
  private setNotificationTimer = (uid: string, momentInTime: moment.Moment, type: string) => {
    // since we could be mid-minute, we need to calculate the time to the top of the next minute, so we can set anchor there
    const nearestMinute = moment().add(1, 'minute').startOf('minute')
    const timeToNearestMinute = moment.duration(nearestMinute.diff(moment())).asMilliseconds()
    // wait until the top of the next minute, then set a minute-interval for going forward
    setTimeout(() => {
      const { notificationIntervals } = this.state
      this.updateNotificationMessage(uid, momentInTime, type) // call immediately, then set every minute interval
      const intervalID = setInterval(() => {
        this.updateNotificationMessage(uid, momentInTime, type)
      }, 60000) // update the "starts in" time every minute
      notificationIntervals[uid] = intervalID
      this.setState({
        notificationIntervals: notificationIntervals,
      })
    }, timeToNearestMinute)
  }

  // clear a specific notifiction timer
  private clearNotificationTimer = (uid: string) => {
    const { notificationIntervals } = this.state
    clearInterval(notificationIntervals.uid)
    const intervals = notificationIntervals
    delete intervals[uid]
    this.setState({
      notificationIntervals: intervals,
    })
  }

  // the "5 minutes left" notification
  private setSessionEndingNotification = () => {
    const { $$currentSession, notificationIntervals } = this.state
    // figure out your 5 minutes before moment
    const apptID = $$currentSession.get('id')
    const endTimeMoment = moment($$currentSession.get('dateTime')).add(
      $$currentSession.getIn(['appointment', 'appointmentDuration'], 50),
      'minutes',
    )
    const reminderTime = moment(endTimeMoment).subtract(5, 'minutes')
    let delay = moment.duration(reminderTime.diff(moment())).asMilliseconds()

    // if already within 5 minutes, show immediately
    if (delay < 0) {
      delay = 0
    }

    const timeoutID = setTimeout(() => {
      // show the notification
      const minutesLeft = moment.duration(endTimeMoment.diff(moment())).asMinutes()
      const message = this.generateNotificationMessageUpdate('sessionEnding', minutesLeft)
      this.showNotification({
        message: message,
        level: 'info',
        position: 'bc',
        autoDismiss: 0,
        dismissible: 'button',
        uid: `${apptID}_end`,
        onAdd: (notification: any) => this.setNotificationTimer(notification.uid, endTimeMoment, 'sessionEnding'),
        onRemove: (notification: any) => this.clearNotificationTimer(notification.uid),
      })
    }, delay)

    // save timeout id to state so we can cancel later if needed
    notificationIntervals[`${apptID}_end`] = timeoutID
    // note: this timeoutID gets overwritten by an intervalID once the notification closes.
    // this is okay because the clearInterval from the 'long-timeout' class that is ultimately called will clear either type
    this.setState({
      notificationIntervals: notificationIntervals,
    })
  }

  // Screen Sharing Methods: ============
  // ====================================

  private startScreenshare = () => {
    const { setAndShowModal, track } = this.props
    if ('chrome' in window) {
      track({ page: 'Video Session', event: 'Button Press', action: 'Present Screen' })
      this.setState({
        startingScreenshare: true,
        viewStyle: videoViewStyle.PREVIEW,
        showVideoWhileScreenSharing: true,
      })
      if (this.videoCallRef.current) {
        this.videoCallRef.current.shareScreen()
      }
    } else {
      // the user is not using Chrome
      // show modal to alert the user and direct them to the installation page.
      const modalContent = (
        <div className={styles['unsupported-modal']}>
          <h2>Unsupported Browser</h2>
          <p>
            The browser you are currently using does not support presenting your screen. Please switch to Google Chrome.
            If you do not have Google Chrome, you can download it{' '}
            <a href='https://www.google.com/chrome/' target='_blank' rel='noreferrer'>
              here
            </a>
          </p>
        </div>
      )
      setAndShowModal(modalContent)
    }
  }

  private stopScreenshare = () => {
    this.props.track({ page: 'Video Session', event: 'Button Press', action: 'Stop Present Screen' })
    if (this.videoCallRef.current) this.videoCallRef.current.stopScreenshare()
  }

  private screenShareStarted = () => {
    this.setState({
      providerSharingScreen: true,
    })

    return Promise.resolve(true) // returned to VideoCall component to support chaining over there
  }

  private screenShareStopped = () => {
    this.setState({
      providerSharingScreen: false,
      startingScreenshare: false,
    })
  }

  private handleDataMessage = (message: string) => {
    const { $$currentSession } = this.state
    const apptID = $$currentSession.get('id')
    const isProvider = this.props.type === 'provider'
    const videoCallRef = this.videoCallRef.current
    /**
     * [LOLWAT codec negotiation] This is to remedy the situation where the client is using an Android Device
     * that does not support the H264 codec. By default we want all of our users to use the H264 codec, for better connectivity
     * The android participant will send a message so that the provider
     * will disconnect from the room and rejoin & publish with the VP8 codec, then the android user can subscribe to the video tracks
     * from the provider as all android devices support VP8. Twilio does not handle this for us automatically.
     */
    if (isProvider && message === DataTrackMessage.H264_NOT_SUPPORTED && videoCallRef) {
      this.sendLog({
        message: 'H264 Not supported. codec negotiation BEGINS. Connecting to VP8',
        type: 'info',
      })
      videoCallRef.leaveRoom(() => {
        try {
          // change the order with VP8 as the first codec, so that it is chosen
          // start with attempt of 1 since we will be retrying to join for the first time
          this.rejoinSession(1, [
            { codec: 'VP8', simulcast: false },
            { codec: 'H264', simulcast: false },
            { codec: 'VP9', simulcast: false },
          ]).then(() => {
            if (videoCallRef) {
              videoCallRef
                .initAndPublishUserTracks(() => videoCallRef.sendData(DataTrackMessage.RECONNECTED_WITH_VP8))
                .then(() => {
                  this.showAlert({
                    message: 'Reconnected to room!',
                    type: 'success',
                  })
                  this.sendLog({
                    message: 'H264 codec negotiation reconnected successfully with VP8',
                    type: 'info',
                  })
                })
            }
          })
        } catch (e) {
          this.sendLog({
            message: `Unable to rejoin session for H264 codec negotiation. Connecting to VP8 Failed - ${e}`,
            type: 'info',
          })
        }
        this.sendLog({
          message: 'H264 Not supported. codec negotiation to VP8 COMPLETES',
          type: 'info',
        })
      })
    }

    // only client needs this message
    if (!isProvider && message === DataTrackMessage.RECORDING_OFF) {
      this.showNotification({
        message: `${$$currentSession.getIn(['appointment', 'provider', 'first_name'], 'Provider')} disabled recording`,
        level: 'info',
        position: 'bc',
        autoDismiss: 5,
        dismissible: 'button',
        uid: `${apptID}_recordingOff`,
      })
    }
  }

  private setFullScreenMuted = (mutedValue: boolean) => {
    this.setState({
      isFullScreenMuted: mutedValue,
    })
  }

  private setFullScreenHasVideo = (hasVideo: boolean) => {
    this.setState({
      fullScreenOwnsVideo: hasVideo,
    })
  }

  private setFullScreenVideoOff = (videoValue: boolean) => {
    this.setState({
      isFullScreenVideoOff: videoValue,
    })
  }

  private setUserPreviewMuted = (mutedValue: boolean) => {
    this.setState({
      isUserPreviewMuted: mutedValue,
    })
  }

  private setUserPreviewVideoOff = (videoValue: boolean) => {
    this.setState({
      isUserPreviewVideoOff: videoValue,
    })
  }

  private setParticipantPreviewMuted = (mutedValue: boolean) => {
    this.setState({
      isParticipantPreviewMuted: mutedValue,
    })
  }

  private setParticipantPreviewVideoOff = (videoValue: boolean) => {
    this.setState({
      isParticipantPreviewVideoOff: videoValue,
    })
  }

  private changeEffects = (effect: VideoCallEffects) => {
    this.setState({
      effect,
    })
  }

  // Rendering: ======================
  // =================================

  private showUnsupportedModal = () => {
    let modalContent
    if (DetectRTC.isMobileDevice) {
      modalContent = (
        <div className={styles['unsupported-modal']}>
          <h2>Unsupported Browser</h2>
          <p>
            The browser you are currently using does not support Lyra video sessions. Please try joining from a
            computer.
          </p>
        </div>
      )
    } else {
      modalContent = (
        <div className={styles['unsupported-modal']}>
          <h2>Unsupported Browser</h2>
          <p>
            The browser you are currently using does not support Lyra video sessions. Please switch to Google Chrome. If
            you do not have Google Chrome, you can download it{' '}
            <a href='https://www.google.com/chrome/' target='_blank' rel='noreferrer'>
              here
            </a>
          </p>
        </div>
      )
    }

    this.props.setAndShowModal(modalContent)
  }

  render() {
    const {
      $$currentSession,
      $$nextSession,
      participantStatusMessage,
      viewStyle,
      inSession,
      participantNetworkQuality,
      alert,
      startingSession,
      muted,
      videoOff,
      providerSharingScreen,
      initializingTracks,
      openingSession,
      startingScreenshare,
      showVideoWhileScreenSharing,
    } = this.state
    const {
      timeZone,
      type,
      showRecordingStatus,
      isRecording,
      messenger,
      settings,
      track,
      saveSettingsFunction,
      setAndShowModal,
    } = this.props
    const isProvider = type === 'provider'
    const showSharingScreenBanner = isProvider && providerSharingScreen

    return (
      <div
        className={classNames(
          styles['video-session-container'],
          { [styles.sticky]: $$currentSession.size !== 0 },
          type && styles[`${type}`],
        )}
      >
        {$$currentSession.size > 0 ? (
          <div
            data-test-id='VideoSession-currentSessionContainer'
            className={classNames(
              styles['current-session-container'],
              { [styles['in-session']]: inSession },
              type && styles[`${type}`],
            )}
          >
            <div className={classNames(styles.inner, { [styles['screen-sharing']]: showSharingScreenBanner })}>
              {showSharingScreenBanner ? (
                <div className={styles['client-video-toggle']}>
                  <div>Client video</div>
                  <ToggleSwitch
                    onClick={() => this.setState({ showVideoWhileScreenSharing: !showVideoWhileScreenSharing })}
                    isOn={showVideoWhileScreenSharing}
                  />
                </div>
              ) : (
                []
              )}
              {!showSharingScreenBanner && (
                <SessionInfo
                  $$currentSession={$$currentSession}
                  timeZone={timeZone}
                  type={type}
                  showRecordingStatus={showRecordingStatus}
                  isRecording={isRecording}
                />
              )}
              <VideoPreviews
                inSession={inSession}
                $$currentSession={$$currentSession}
                providerSharingScreen={providerSharingScreen}
                showVideoWhileScreenSharing={showVideoWhileScreenSharing}
                openingSession={openingSession}
                participantStatusMessage={participantStatusMessage}
                type={type}
                setViewStyle={this.setViewStyle}
                participantNetworkQuality={participantNetworkQuality}
                initializingTracks={initializingTracks}
                viewStyle={viewStyle}
                isUserPreviewMuted={this.state.isUserPreviewMuted}
                isUserPreviewVideoOff={this.state.isUserPreviewVideoOff}
                isParticipantPreviewMuted={this.state.isParticipantPreviewMuted}
                isParticipantPreviewVideoOff={this.state.isParticipantPreviewVideoOff}
              />
              {showSharingScreenBanner ? (
                <div className={styles['currently-sharing']}>
                  {!showVideoWhileScreenSharing ? 'Your camera is still on while you present' : ''}
                  <PrimaryButton customClass={styles['stop-presenting']} onClick={this.stopScreenshare}>
                    Stop Presenting
                  </PrimaryButton>
                </div>
              ) : (
                []
              )}
              <ButtonsContainer
                defaultContraints={this.defaultContraints}
                inSession={inSession}
                participantStatusMessage={participantStatusMessage}
                track={track}
                muted={muted}
                videoOff={videoOff}
                startingScreenshare={startingScreenshare}
                startingSession={startingSession}
                type={type}
                isRecording={isRecording}
                messenger={messenger}
                settings={settings}
                saveSettingsFunction={saveSettingsFunction}
                setAndShowModal={setAndShowModal}
                showSharingScreenBanner={showSharingScreenBanner}
                toggleAudio={this.toggleAudio}
                startScreenshare={this.startScreenshare}
                endSession={this.endSession}
                startSession={this.startSession}
                turnOffRecording={this.turnOffRecording}
                toggleVideo={this.toggleVideo}
                changeEffects={this.changeEffects}
              />
            </div>
            <VideoCall
              ref={this.videoCallRef}
              // @ts-expect-error TS(2739): Type '{}' is missing the following properties from... Remove this comment to see the full error message
              deviceConstraints={this.defaultContraints}
              // @ts-expect-error TS(2739): Type 'Dict' is missing the following properties fr... Remove this comment to see the full error message
              deviceSettings={settings}
              type={type}
              inSession={inSession}
              muted={muted}
              videoOff={videoOff}
              viewStyle={viewStyle}
              // @ts-expect-error TS(2322): Type '(viewStyle: string) => void' is not assignab... Remove this comment to see the full error message
              setViewStyle={this.setViewStyle}
              // @ts-expect-error TS(2322): Type '() => void' is not assignable to type '() =>... Remove this comment to see the full error message
              userTracksInitialized={this.userTracksInitialized}
              // @ts-expect-error TS(2322): Type '(status: string) => void' is not assignable ... Remove this comment to see the full error message
              setParticipantStatusMessage={this.setParticipantStatusMessage}
              screenShareStartedCallback={this.screenShareStarted}
              // @ts-expect-error TS(2322): Type '() => void' is not assignable to type '() =>... Remove this comment to see the full error message
              screenShareStoppedCallback={this.screenShareStopped}
              // @ts-expect-error TS(2322): Type '(message: string) => void' is not assignable... Remove this comment to see the full error message
              handleDataMessage={this.handleDataMessage}
              // @ts-expect-error TS(2322): Type '(alertObj: any) => void' is not assignable t... Remove this comment to see the full error message
              showAlert={this.showAlert}
              // @ts-expect-error TS(2322): Type '() => void' is not assignable to type '() =>... Remove this comment to see the full error message
              participantConnectedCallback={this.participantConnected}
              // @ts-expect-error TS(2322): Type '({ sourceCategory, message, type, ...rest }:... Remove this comment to see the full error message
              logger={this.sendLog}
              // @ts-expect-error TS(2322): Type '(level: number) => void' is not assignable t... Remove this comment to see the full error message
              networkQualityHandler={this.networkQualityHandler}
              // @ts-expect-error TS(2322): Type '() => void' is not assignable to type '() =>... Remove this comment to see the full error message
              endSession={this.endSession}
              track={track}
              rejoinSession={this.rejoinSession}
              isFullScreenMuted={this.state.isFullScreenMuted}
              // @ts-expect-error TS(2322): Type '(mutedValue: boolean) => void' is not assign... Remove this comment to see the full error message
              setFullScreenMuted={this.setFullScreenMuted}
              fullScreenOwnsVideo={this.state.fullScreenOwnsVideo}
              // @ts-expect-error TS(2322): Type '(hasVideo: boolean) => void' is not assignab... Remove this comment to see the full error message
              setFullScreenHasVideo={this.setFullScreenHasVideo}
              isFullScreenVideoOff={this.state.isFullScreenVideoOff}
              // @ts-expect-error TS(2322): Type '(videoValue: boolean) => void' is not assign... Remove this comment to see the full error message
              setFullScreenVideoOff={this.setFullScreenVideoOff}
              isUserPreviewMuted={this.state.isUserPreviewMuted}
              // @ts-expect-error TS(2322): Type '(mutedValue: boolean) => void' is not assign... Remove this comment to see the full error message
              setUserPreviewMuted={this.setUserPreviewMuted}
              isUserPreviewVideoOff={this.state.isUserPreviewVideoOff}
              // @ts-expect-error TS(2322): Type '(videoValue: boolean) => void' is not assign... Remove this comment to see the full error message
              setUserPreviewVideoOff={this.setUserPreviewVideoOff}
              isParticipantPreviewMuted={this.state.isParticipantPreviewMuted}
              // @ts-expect-error TS(2322): Type '(mutedValue: boolean) => void' is not assign... Remove this comment to see the full error message
              setParticipantPreviewMuted={this.setParticipantPreviewMuted}
              isParticipantPreviewVideoOff={this.state.isParticipantPreviewVideoOff}
              // @ts-expect-error TS(2322): Type '(videoValue: boolean) => void' is not assign... Remove this comment to see the full error message
              setParticipantPreviewVideoOff={this.setParticipantPreviewVideoOff}
              effects={this.state.effect}
            />
          </div>
        ) : (
          []
        )}
        {$$nextSession.size > 0 && $$currentSession.size === 0 && !this.props.disableBanner ? (
          <NextSessionReminder
            $$nextSession={$$nextSession}
            timeZone={timeZone}
            openSession={this.openSession}
            type={type}
          />
        ) : (
          []
        )}

        <FullScreenContainer
          $$currentSession={$$currentSession}
          participantStatusMessage={participantStatusMessage}
          viewStyle={viewStyle}
          inSession={inSession}
          type={type}
          isProvider={isProvider}
          alert={alert}
          participantNetworkQuality={participantNetworkQuality}
          startingSession={startingSession}
          startSession={this.startSession}
          setViewStyle={this.setViewStyle}
          toggleAudio={this.toggleAudio}
          endSession={this.endSession}
          toggleVideo={this.toggleVideo}
          isFullScreenMuted={this.state.isFullScreenMuted}
          fullScreenOwnsVideo={this.state.fullScreenOwnsVideo}
          isFullScreenVideoOff={this.state.isFullScreenVideoOff}
        />
        <Notifications setRef={this.setNotificationsRef} />
        {alert ? (
          <div
            data-test-id='VideoSession-alertBanner'
            className={classNames(styles['alert-banner'], alert.type && styles[`${alert.type}`])}
          >
            {alert.message}
          </div>
        ) : (
          []
        )}
      </div>
    )
  }
}

type OwnVideoSessionProps = {
  $$appointments: List<any>
  type: string
  getTokenFunction: (ids: { apptID: string; participantID: string }) => Promise<any>
  sessionStartedCallback: (id?: string) => {}
  sessionEndedCallback: () => {}
  sessionOpenedCallback: () => {}
  reminderWindow: number
  settings: Dict
  setAndShowModal: (params: any) => {}
  closeModal: () => {}
  saveSettingsFunction: () => {}
  track: (params: any) => {}
  disableRecording: () => {}
  logger: (sourceCategory: string, idToLog: string, dataToLog: Dict) => {}
  isRecording: boolean
  showRecordingStatus: boolean
  timeZone: string
  messenger: ReactElement
}

type VideoSessionState = {
  $$currentSession: Map<any, any>
  $$nextSession: Map<any, any>
  participantStatusMessage: string | null
  viewStyle: string
  inSession: boolean
  participantNetworkQuality: number | null
  alert: any
  startingSession: boolean
  muted: boolean
  videoOff: boolean
  providerSharingScreen: boolean
  initializingTracks: boolean
  openingSession: boolean
  startingScreenshare: boolean
  notificationIntervals: { [key: string]: any }
  reminders: any
  showVideoWhileScreenSharing: boolean
  isFullScreenMuted: boolean
  fullScreenOwnsVideo: boolean
  isFullScreenVideoOff: boolean
  isUserPreviewMuted: boolean
  isUserPreviewVideoOff: boolean
  isParticipantPreviewMuted: boolean
  isParticipantPreviewVideoOff: boolean
  effect: VideoCallEffects
}

export default VideoSession
