import React, { Component } from 'react'

import { get } from 'lodash-es'

import { AssignmentResponse, LessonContent } from '@lyrahealth-inc/shared-app-logic'

import VideoPlayer from '../../atoms/videoPlayer/VideoPlayer'

type VideoLessonProps = {
  response: AssignmentResponse
  videoMeta: LessonContent
  onClickPlay: () => void
  videoEnded: () => void
  updateVideoStatus: ({ status, progress }: { status: string; progress: number; totalDuration: number }) => void
  onMount: () => void
}

type VideoLessonState = {
  progress: number
  ended: boolean
  duration: number
}

class VideoLessonContainer extends Component<VideoLessonProps, VideoLessonState> {
  watched: boolean

  started: boolean

  player: any

  constructor(props: VideoLessonProps) {
    super(props)
    this.watched = get(props.response, `response.${props.videoMeta.name}.status`) === 'completed'
    this.started = get(props.response, `response.${props.videoMeta.name}.status`) === 'started'
    this.state = {
      progress: 0,
      ended: false,
      duration: 0,
    }
  }

  ref = (player: any) => {
    this.player = player
  }

  componentDidMount() {
    this.props.onMount()
    const progress = get(this.props.response, `response.${this.props.videoMeta.name}.progress`)
    this.player?.seekTo(parseFloat(progress))
  }

  onStart = (): void => {
    if (this.watched || this.started) return
    this.props.updateVideoStatus({ status: 'started', progress: 0, totalDuration: this.state.duration })
    if (!this.state.ended) this.setState({ ended: false })
  }

  onProgress = ({ played }: { played: number }): void => {
    if (!played || this.watched) return
    this.setState({ progress: played })
    if (played <= 0.9) {
      return this.props.updateVideoStatus({ status: 'started', progress: played, totalDuration: this.state.duration })
    } else {
      this.watched = true
      return this.props.updateVideoStatus({ status: 'completed', progress: 0.0, totalDuration: this.state.duration })
    }
  }

  onEnd = (): void => {
    if (!this.watched) {
      this.watched = true
      this.props.updateVideoStatus({ status: 'completed', progress: 0.0, totalDuration: this.state.duration })
    }
    this.setState({ ended: true })
    this.props.videoEnded()
  }

  onPlay = (): void => {
    if (this.state.ended) this.props.onClickPlay()
  }

  onSeek = (num: number): void => {
    const seekedValue = num / this.state.duration
    this.onProgress({ played: seekedValue })
  }

  onDuration = (duration: number): void => {
    this.setState({ duration: duration })
  }

  render() {
    return (
      <div>
        <VideoPlayer
          url={get(this.props.videoMeta, 'meta_data.url')}
          reference={this.ref}
          onError={(e) => console.log('onError', e)}
          onProgress={this.onProgress}
          progressInterval={10000}
          onStart={this.onStart}
          onEnded={this.onEnd}
          onPlay={this.onPlay}
          onSeek={this.onSeek}
          onDuration={this.onDuration}
          playing
        />
      </div>
    )
  }
}

export default VideoLessonContainer
