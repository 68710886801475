import React, { ReactElement, useEffect } from 'react'
import { FieldRenderProps } from 'react-final-form'

import classNames from 'classnames'
import { isNil } from 'lodash-es'

import styles from './radioGroup.module.scss'
import BaseInput from '../baseInput/BaseInput'

const RadioGroup: React.FC<RadioGroupProps> = ({
  meta: { dirty, touched, error } = {},
  input: { name, onChange, value },
  selected,
  label,
  subLabel,
  description,
  buttons,
  className,
  styleType = StyleType.BASIC,
  isStacked = true,
  showDescription = true,
  disabled,
  hintContent,
  fullWidth,
  autoComplete = 'off',
  readOnly = false,
}) => {
  useEffect(() => {
    if (!dirty && !isNil(selected)) {
      onChange(selected)
    }
  }, [dirty, onChange, selected])

  const fieldInfoId = `${name}_info`

  const getInputButtons = () => {
    return buttons.map((btn) => {
      let btnTxt,
        btnVal: string | number,
        btnDescription,
        btnOnClick: ((val: string | number) => void) | undefined,
        highlight: Highlight | undefined
      if (typeof btn === 'string') {
        btnTxt = btn
        btnVal = btn
      } else {
        btnTxt = btn.text
        btnVal = btn.value
        btnDescription = btn.description ? btn.description : null
        highlight = btn.highlight
        btnOnClick = btn.onClick
      }
      const isSelected = value === btnVal
      const isElementDisabled = readOnly ? true : disabled

      const classes = classNames(
        styles['radio-button'],
        { [styles.selected]: selected ?? isSelected },
        highlight && styles[`${highlight}`],
        { [styles['read-only']]: readOnly },
        { [styles.disabled]: disabled },
      )
      const clickAction = () => {
        if (!disabled && !readOnly) {
          onChange(btnVal)
          if (btnOnClick) {
            btnOnClick(btnVal)
          }
        }
      }
      const id = `${name}-${btnVal}`
      return (
        <div
          role={'radio'}
          aria-checked={isSelected}
          aria-describedby={fieldInfoId}
          tabIndex={0}
          className={classes}
          key={id}
          onClick={clickAction}
          aria-disabled={isElementDisabled}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              clickAction()
            }
          }}
        >
          <input
            data-test-id={id}
            id={id}
            tabIndex={-1}
            disabled={isElementDisabled}
            type='radio'
            name={name}
            autoComplete={autoComplete}
            value={btnVal}
            checked={isSelected}
          />
          <div style={{ position: 'relative' }}>
            <label className={styles.text} htmlFor={id}>
              {btnTxt}
            </label>
            {showDescription && btnDescription && !isSelected && (
              <>
                <div>Answer Detail:</div>
                <div className={styles['option-description']}>{btnDescription}</div>
              </>
            )}
          </div>
        </div>
      )
    })
  }

  const btnGrpDescription = description ? <p className={styles.description}>{description}</p> : ''
  return (
    <BaseInput
      className={className}
      label={label}
      labelId={name}
      subLabel={subLabel}
      touched={touched}
      error={error}
      disabled={disabled}
      hintContent={hintContent}
      fieldInfoId={fieldInfoId}
    >
      <>
        {btnGrpDescription}
        <div
          role='radiogroup'
          aria-labelledby={name}
          className={classNames(
            styles['radio-button-group'],
            styleType && styles[`${styleType}`],
            { [styles['side-by-side']]: !isStacked },
            { [styles['full-width']]: fullWidth },
          )}
        >
          {getInputButtons()}
        </div>
      </>
    </BaseInput>
  )
}

enum StyleType {
  BASIC = '',
  STROKED = 'stroked',
  JUMBO = 'jumbo',
  THREE_COL = 'three-col',
  SIDE_BY_SIDE = 'side-by-side',
}

enum Highlight {
  SUCCESS = 'success',
  FAIL = 'fail',
}

export type RadioButton =
  | string
  | {
      onClick?: (btnVal: string | number) => void
      description?: string
      value: string | number
      text: string
      highlight?: Highlight
    }

interface RadioGroupProps extends FieldRenderProps<number, any> {
  selected?: string
  label?: string
  subLabel?: string
  description?: string
  buttons: RadioButton[]
  className?: string
  styleType?: StyleType
  isStacked?: boolean
  showDescription?: boolean
  disabled?: boolean
  hintContent?: ReactElement
  fullWidth?: boolean
  autoComplete?: string
  readOnly?: boolean
}

export default RadioGroup
