import { GET_CLIENT_PRESCRIPTIONS } from '../../../common/constants/reduxConstants'

export const getDSPatientUrl = ({ clientId }: any) => ['get', `/meds/v1/patients/${clientId}/sso/url`]

export const getDSPrescriptionsNotificationsUrl = ({ providerId }: any) => [
  'get',
  `meds/v1/providers/${providerId}/refills/sso/url`,
]

export const getPatientPrescriptions = ({ clientId, cancelToken }: any) => ({
  action: GET_CLIENT_PRESCRIPTIONS,
  request: {
    method: 'get',
    url: `/meds/v1/patients/${clientId}/prescriptions`,
    cancelToken,
  },
})
