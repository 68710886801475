import { createSelector } from '@reduxjs/toolkit'
import { Map } from 'immutable'

const getPlatformEngagementState = (state: Map<string, any>) => state?.get('platformEngagement')

export const getCurrentPlatformEngagementMetrics = createSelector(getPlatformEngagementState, (state) => state?.current)
export const getPreviousPlatformEngagementMetrics = createSelector(
  getPlatformEngagementState,
  (state) => state?.previous,
)
