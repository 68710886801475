import React, { FunctionComponent } from 'react'
import { Path, Svg } from 'react-native-svg'

type BallotIllustrationProps = {
  size?: string | number
}

export const BallotIllustration: FunctionComponent<BallotIllustrationProps> = ({ size = 32 }) => (
  <Svg width={size} height={size} viewBox='0 0 32 32' fill='none'>
    <Path
      d='M28.2121 18.8869V30.0875C28.2121 30.8683 27.5836 31.5001 26.8082 31.5001H5.19181C4.41637 31.5001 3.78787 30.8683 3.78787 30.0875V18.488C3.78787 18.363 3.79465 18.238 3.80935 18.1141C3.81387 18.055 3.82178 17.997 3.83083 17.9379C3.85683 17.7766 3.893 17.6163 3.94047 17.4584L6.05091 12.9274C6.30072 12.1228 6.6794 11.5148 7.45484 11.5148H24.5452C25.3217 11.5148 25.7072 12.1331 25.9502 12.9274L28.0437 17.7061C28.0663 17.7834 28.0867 17.8607 28.1036 17.9379C28.1172 17.997 28.1296 18.055 28.1409 18.1141C28.1884 18.3686 28.2121 18.6266 28.2121 18.8869Z'
      fill='#85CCD5'
    />
    <Path
      d='M28.1409 18.1141H3.80933C3.81385 18.055 3.82176 17.997 3.8308 17.9379C3.8568 17.7766 3.89297 17.6163 3.94045 17.4584L6.05088 12.9274C6.3007 12.1228 6.67938 11.5148 7.45482 11.5148H24.5451C25.3217 11.5148 25.7072 12.1331 25.9502 12.9274L28.0437 17.7061C28.0663 17.7834 28.0866 17.8607 28.1036 17.9379C28.1171 17.997 28.1296 18.055 28.1409 18.1141Z'
      fill='#CAE9ED'
    />
    <Path
      d='M22.6031 13.6202H9.3968C7.66505 13.6202 7.66279 16.0067 9.3968 16.0067H22.6031C24.3349 16.0067 24.3371 13.6202 22.6031 13.6202Z'
      fill='#3BA5B5'
    />
    <Path
      d='M26.1743 6.65565L19.5183 0.877564C19.278 0.668974 18.9145 0.695967 18.7065 0.937856L9.80097 11.2927C9.59294 11.5346 9.61908 11.8998 9.85937 12.1084L16.5154 17.8865C16.7556 18.0951 17.1191 18.0681 17.3271 17.8262L26.2326 7.47132C26.4407 7.22943 26.4145 6.86424 26.1743 6.65565Z'
      fill='#FFC03C'
    />
    <Path d='M22.5522 16.0068H10.9658V18.1137H22.5522V16.0068Z' fill='#CAE9ED' />
    <Path d='M20.4509 18.1117H10.9658V20.2186H20.4509V18.1117Z' fill='#85CCD5' />
  </Svg>
)
