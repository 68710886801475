import React from 'react'

import styled, { useTheme } from 'styled-components/native'

import {
  BodyText,
  BodyTextSize,
  BulletedList,
  ContentContainer,
  Link,
  Subhead,
  SubheadSize,
  ThemeType,
} from '@lyrahealth-inc/ui-core-crossplatform'

import { SchedulingFooterSupportConnected, SchedulingFooterSupportNotConnected } from './constants'
import { actions, mixpanelEvents } from '../../../mixpanel/mixpanelConstants'
import { track } from '../../../mixpanel/mixpanelTracking'

const DescriptionContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['24px'],
}))

const SubheadContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['8px'],
  marginTop: theme.spacing['32px'],
}))

export const SchedulingFooter: React.FC<SchedulingFooterProps> = ({
  providerCalendarState,
  schedulingCredentialsValid,
}) => {
  const { colors } = useTheme()
  const supportConstants =
    providerCalendarState === 'ProviderCalendarStatus.ONLINE_BOOKING_LIVE' || schedulingCredentialsValid
      ? SchedulingFooterSupportConnected
      : SchedulingFooterSupportNotConnected

  const generateSupport = (supportElement: { text: string; link: string; mixpanelAction: string }) => {
    return {
      value: (
        <Link
          text={supportElement.text}
          onPress={() => {
            track({ event: mixpanelEvents.BUTTON_PRESS, action: supportElement.mixpanelAction })
            window.open(supportElement.link, '_blank')
          }}
          size={BodyTextSize.DEFAULT}
        />
      ),
    }
  }

  const supportListData = supportConstants.map((supportElement) => generateSupport(supportElement))

  return (
    <ContentContainer>
      <SubheadContainer>
        <Subhead level='3' color={colors.textSecondary} size={SubheadSize.MEDIUM} text='Support' />
      </SubheadContainer>
      <BulletedList
        spaceBetween={0}
        textColor={colors.linkDefault}
        bulletColor={colors.linkDefault}
        data={supportListData}
      />
      <SubheadContainer>
        <Subhead level='3' size={SubheadSize.MEDIUM} color={colors.textSecondary} text='Still Need Help?' />
      </SubheadContainer>
      <DescriptionContainer>
        <BodyText
          color={colors.textSecondary}
          text={
            <>
              Submit a ticket{' '}
              <Link
                text='here'
                onPress={() => {
                  track({ event: mixpanelEvents.BUTTON_PRESS, action: actions.SCHEDULING_FOOTER_SUBMIT_TICKET })
                  window.open(
                    'https://provider-support.lyrahealth.com/hc/en-us/requests/new?ticket_form_id=26162178922387',
                    '_blank',
                  )
                }}
                size={BodyTextSize.DEFAULT}
              />
              . We’ll get back to you soon.
            </>
          }
        />
      </DescriptionContainer>
    </ContentContainer>
  )
}

type SchedulingFooterProps = {
  providerCalendarState: string
  schedulingCredentialsValid: boolean
}
