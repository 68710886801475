import React from 'react'
import { Field } from 'react-final-form'

import { capitalize, get, isEmpty, isFunction, isNil } from 'lodash-es'
import moment from 'moment'

import { formatFunctions, parseFunctions } from '@lyrahealth-inc/shared-app-logic'

import Checkbox from '../../atoms/form/checkbox/Checkbox'
import CheckboxGroup from '../../atoms/form/checkboxGroup/CheckboxGroup'
import DateInputField from '../../atoms/form/dateInputField/DateInputField'
import InputField from '../../atoms/form/inputField/InputField'
import MaskedInputField from '../../atoms/form/maskedInputField/MaskedInputField'
import RadioGroup from '../../atoms/form/radioGroup/RadioGroup'
import SelectField from '../../atoms/form/selectField/SelectField'
import Slider from '../../atoms/form/sliderField/SliderField'
import TextArea from '../../atoms/form/textArea/TextArea'
import TypeAheadField from '../../atoms/form/typeAheadField/TypeAheadField'

export const TextareaWidget = ({
  formContext: { readOnly },
  schema: { name, title, placeholder, autocomplete, rows },
}: $TSFixMe) => {
  return (
    <Field
      readOnly={readOnly}
      type='text'
      name={name}
      label={title}
      component={TextArea}
      resizeable='vertical'
      placeholder={readOnly ? '' : placeholder}
      autocomplete={autocomplete}
      rows={rows}
    />
  )
}

export const BaseInput = ({
  formContext: { readOnly },
  schema: { name, title, placeholder, autocomplete },
}: $TSFixMe) => {
  return (
    <Field
      readonly={readOnly}
      type='text'
      name={name}
      label={title}
      component={InputField}
      placeholder={placeholder}
      autocomplete={autocomplete}
    />
  )
}

export const RangeWidget = ({
  formContext: { readOnly },
  schema: { name, title, step, maximum, minimum, minLabel, maxLabel },
  options,
}: $TSFixMe) => {
  return (
    <Field
      step={step}
      readOnly={readOnly}
      name={name}
      component={Slider}
      percentage={options.percentage}
      label={title}
      minValue={minimum}
      maxValue={maximum}
      showEndDots={false}
      hideSliderSelection={false}
      displayValue
      minLabel={minLabel}
      maxLabel={maxLabel}
    />
  )
}

export const RadioWidget = ({
  value,
  options,
  schema: { type, name, correctValue, correctValueDetails, title, subTitle, descriptions },
  formContext: { readOnly, showAnswers },
}: $TSFixMe) => {
  let buttons
  const shouldHighlight = showAnswers && !isNil(correctValue)
  if (type === 'boolean') {
    buttons = [
      {
        // By default the label for the first option is 'yes'
        text: capitalize(get(options, 'enumOptions[0].label')),
        value: 1,
        description: correctValue === 1 ? correctValueDetails : undefined,
        highlight: shouldHighlight ? (correctValue === 1 ? 'success' : 'fail') : undefined,
      },
      {
        //  By default the label for the second option is 'no'
        text: capitalize(get(options, 'enumOptions[1].label')),
        value: 0,
        description: correctValue === 0 ? correctValueDetails : undefined,
        highlight: shouldHighlight ? (correctValue === 0 ? 'success' : 'fail') : undefined,
      },
    ]
  } else {
    buttons = options.enumOptions.map((option: $TSFixMe, i: $TSFixMe) => {
      option.text = option.label

      option.description =
        correctValue === option.value ? correctValueDetails : descriptions ? descriptions[i] : undefined
      option.highlight = shouldHighlight ? (correctValue === option.value ? 'success' : 'fail') : undefined
      return option
    })
  }

  return (
    <Field
      readOnly={readOnly}
      component={RadioGroup}
      stretch={false}
      label={title}
      subLabel={subTitle}
      isStacked={type !== 'boolean'}
      styleType={get(options, 'styleType', 'stroked')}
      name={name}
      buttons={buttons}
      showDescription={showAnswers}
      answerDescription={correctValueDetails}
    />
  )
}

export const CheckboxWidget = ({ formContext: { readOnly }, schema: { name, title } }: $TSFixMe) => {
  return (
    <Field readOnly={readOnly} name={name} type='checkbox' component={Checkbox}>
      {title}
    </Field>
  )
}

export const CheckboxGroupWidget = ({ formContext: { readOnly }, options: { stroked }, schema }: $TSFixMe) => {
  const options = schema.enum.map((enumValue: $TSFixMe, index: $TSFixMe) => {
    return {
      id: enumValue,
      text: schema.enumNames?.[index] ?? enumValue,
      group: schema.enumExclusiveValuesGroups?.[index],
    }
  })
  return (
    <Field
      readOnly={readOnly}
      name={schema.name}
      component={CheckboxGroup}
      stroked={stroked}
      label={schema.title}
      options={options}
    />
  )
}

export const DateWidget = ({ formContext: { readOnly }, schema: { name, title, maxDate } }: $TSFixMe) => {
  maxDate = maxDate === false ? undefined : moment()
  return (
    <Field
      readOnly={readOnly}
      name={name}
      type='tel'
      component={DateInputField}
      showMonthDropdown
      showYearDropdown
      maxDate={maxDate}
      dropdownMode='select'
      placeholder='__/__/____'
      label={title}
      appendFormat
    />
  )
}

export const SelectWidget = ({
  options: { enumOptions },
  formContext: { readOnly },
  schema: { name, title, autocomplete },
}: $TSFixMe) => {
  return (
    <Field readOnly={readOnly} name={name} component={SelectField} label={title} autocomplete={autocomplete}>
      <option hidden value=''>
        Select
      </option>
      {enumOptions.map((option: $TSFixMe) => (
        <option disabled={readOnly} key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Field>
  )
}

export const MaskedInputWidget = ({
  options: { mask },
  formContext: { readOnly },
  schema: { name, placeholder, title, autocomplete, parseData, formatData },
}: $TSFixMe) => {
  return (
    <Field
      name={name}
      // @ts-expect-error TS(2322): Type '({ input, label, meta: { touched, error, sub... Remove this comment to see the full error message
      component={MaskedInputField}
      mask={mask}
      placeholder={placeholder}
      label={title}
      readOnly={readOnly}
      autocomplete={autocomplete}
      parse={
        !isEmpty(parseData)
          ? (value) => {
              const functionToRun = parseData[0]
              const params = parseData.slice(1)
              if (isFunction(parseFunctions[functionToRun])) {
                value = parseFunctions[functionToRun](value, params)
              }
              return value
            }
          : undefined
      }
      format={
        !isEmpty(formatData)
          ? (value) => {
              const functionToRun = formatData[0]
              const params = formatData.slice(1)
              if (isFunction(formatFunctions[functionToRun])) {
                value = formatFunctions[functionToRun](value, params)
              }
              return value
            }
          : undefined
      }
    />
  )
}

export const TypeAheadWidget = ({
  formContext: { readOnly, ...contextProps },
  schema: { name, placeholder, title, options, multiSelect },
}: $TSFixMe) => {
  if (typeof options === 'object' && 'fromContext' in options) {
    options = contextProps[options.fromContext] || []
  }
  return (
    <Field
      name={name}
      type='text'
      // @ts-expect-error TS(2322): Type 'FC<TypeAheadFieldProps>' is not assignable t... Remove this comment to see the full error message
      component={TypeAheadField}
      multiSelect={multiSelect}
      options={options}
      placeholder={placeholder}
      readOnly={readOnly}
      label={title}
    />
  )
}

export default {
  // rjsf built in widgets names
  BaseInput,
  TextareaWidget,
  RangeWidget,
  RadioWidget,
  DateWidget,
  CheckboxWidget,
  SelectWidget,
  // Custom widgets names
  mask: MaskedInputWidget,
  typeAhead: TypeAheadWidget,
  checkboxGroup: CheckboxGroupWidget,
  // Using BaseInput instead of InternationalPhoneInputWidget cross-platform
  // Only used for read-only Provider Portal and ui-core storybook
  internationalPhone: BaseInput,
}
