import React, { FunctionComponent } from 'react'
import Svg, { Defs, G, Mask, Path, Rect } from 'react-native-svg'

type Props = {
  size?: number | string
}

export const CareNavigatorCharacterSpotIllustration: FunctionComponent<Props> = ({ size = 124 }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 515 513' fill='none'>
      <Path
        d='M257.8 0.575195C222.459 0.575195 188.17 6.63568 155.884 18.5847C79.0203 47.076 24.9818 110.241 7.61986 191.88C-3.85359 245.109 -2.38652 294.342 12.1323 342.32C30.6679 403.593 76.1671 455.264 136.964 484.079C167.145 498.415 201.819 506.601 240.003 508.402C244.91 508.624 249.898 508.746 254.855 508.746C285.492 508.746 316.31 504.547 346.461 496.271C348.778 495.694 350.983 495.097 353.311 494.409C380.406 486.477 404.789 473.931 425.652 457.166C448.305 439.065 466.952 416.118 481.016 389.003C483.788 383.661 486.439 378.035 489.363 371.307C505.45 333.953 513.22 292.136 511.844 250.33C511.197 231.845 508.445 212.197 503.69 191.88C495.858 158.917 483.09 127.916 466.76 102.177C462.288 95.2665 458.18 89.459 454.113 84.2383C424.762 46.3779 379.03 19.8089 321.905 7.4451C300.921 2.89214 279.35 0.585312 257.81 0.585312L257.8 0.575195Z'
        fill='white'
      />
      <Mask
        id='mask0_16344_11324'
        mask-type='luminance'
        maskUnits='userSpaceOnUse'
        x='21'
        y='21'
        width='469'
        height='467'
      >
        <Path
          d='M489.879 251.018C489.251 233.302 486.54 214.948 482.27 196.706C475.289 167.314 463.815 138.337 448.082 113.529C444.43 107.903 440.686 102.48 436.619 97.2697C407.116 59.2272 362.406 38.061 316.644 28.1558C265.671 17.1073 211.47 20.3349 162.471 38.4758C91.5861 64.7413 43.3248 122.281 27.5817 196.291C21.5313 224.327 19.7607 256.016 22.4723 286.46H22.2598C23.828 303.761 26.9544 320.648 31.6389 336.178C49.0514 393.718 91.3635 439.379 145.26 464.916C174.45 478.777 207.069 485.657 240.225 487.225C274.099 488.793 308.499 484.514 340.299 475.762C342.383 475.246 344.366 474.72 346.45 474.092C371.36 466.798 393.042 455.537 411.598 440.633C431.823 424.475 448.497 404.149 461.428 379.239C464.24 373.816 466.739 368.403 469.137 362.869C484.354 327.528 491.133 289.172 489.777 251.129L489.879 251.028V251.018Z'
          fill='white'
        />
      </Mask>
      <G mask='url(#mask0_16344_11324)'>
        <Path
          d='M265.215 522.748C412.069 522.748 531.118 403.699 531.118 256.846C531.118 109.992 412.069 -9.05713 265.215 -9.05713C118.361 -9.05713 -0.6875 109.992 -0.6875 256.846C-0.6875 403.699 118.361 522.748 265.215 522.748Z'
          fill='#FFE9E3'
          stroke='#3BA5B5'
          stroke-miterlimit='10'
        />
        <G clip-path='url(#clip0_16344_11324)'>
          <Path
            d='M535.015 522.441C515.631 504.402 492.526 489.363 470.75 474.403C459.573 466.728 448.037 459.662 436.484 452.627C421.665 441.857 407.049 430.836 393.417 418.596C382.084 408.419 371.344 396.601 359.386 386.768C358.995 386.034 359.761 387.519 359.386 386.768L345.457 376.826C338.235 372.512 330.45 369.197 321.774 367.494C314.74 366.118 309.518 370.182 307.299 375.357C306.783 375.732 306.298 376.138 305.876 376.607C281.677 402.573 243.722 398.993 212.535 390.317C204.75 388.144 196.762 384.627 188.54 385.658C179.613 386.768 171.813 392.943 163.965 396.945C148.02 405.089 131.216 411.264 114.38 417.236C79.5039 429.632 43.612 440.434 9.59596 455.066C-5.08282 461.381 -19.3083 469.714 -30.1884 481.625C-41.6 494.116 -49.0723 509.248 -55.9505 524.536C-73.3024 563.117 -87.606 602.964 -98.6581 643.811C-99.2834 646.14 -99.2678 648.251 -98.7988 650.127C-100.612 653.847 -100.581 658.396 -97.4075 663.039C-97.2043 663.336 -97.0011 663.633 -96.7979 663.914C-95.688 672.919 -90.498 678.828 -83.3071 682.751C-41.4281 738.84 12.0502 789.473 73.9544 821.77C111.175 841.185 151.084 850.252 192.823 852.706C213.098 853.894 233.42 853.754 253.711 853.425C275.393 853.081 296.997 852.487 318.476 849.345C358.51 843.499 395.309 825.021 430.153 805.231C465.123 785.378 498.326 762.57 529.7 737.449C545.036 725.162 560.605 712.5 574.409 698.493C581.365 691.427 587.243 682.751 587.681 672.528C588.728 670.777 589.353 668.635 589.322 666.056C588.775 615.579 573.064 557.864 535.015 522.441ZM82.9586 463.789C81.4735 464.758 80.0041 465.759 78.5347 466.759C77.3466 466.962 76.1429 467.165 74.9548 467.4C77.628 466.181 80.2855 464.977 82.943 463.789H82.9586Z'
            fill='#B8ECF1'
          />
          <Path
            d='M330.81 326.831C330.31 319.218 318.929 318.827 318.335 325.784C314.927 324.002 310.753 323.408 306.861 323.517C286.82 324.111 265.967 328.598 246.442 332.865C232.091 335.992 211.676 338.008 200.858 349.529C197.622 347.263 192.401 347.763 191.244 352.703C189.306 361.035 187.352 369.367 185.413 377.699C183.678 385.155 180.942 393.018 180.255 400.663C179.707 406.884 182.209 410.855 186.164 415.357C191.729 421.688 197.669 427.707 203.828 433.444C208.471 437.774 213.723 443.542 219.57 447.122C221.149 448.435 222.759 449.639 224.416 450.655C230.638 454.454 237.5 453.688 243.8 450.452C253.68 445.387 263.012 438.305 272.235 432.146C281.88 425.706 291.401 419.078 300.639 412.074C308.784 405.884 317.804 399.74 324.432 391.877C330.904 384.186 331.982 374.854 332.076 365.13C332.185 352.359 331.654 339.571 330.81 326.831Z'
            fill='#BC6A41'
          />
          <Path
            d='M131.45 200.285C127.745 197.565 121.773 197.237 118.303 202.067C111.722 211.212 109.033 221.936 108.033 233.019C107.142 242.977 105.438 255.577 114.301 262.127C118.303 265.081 123.571 262.455 126.072 259.047C131.059 252.216 132.153 243.04 133.545 234.911C135.077 226.047 136.093 217.106 136.687 208.133C136.968 203.849 134.545 201.239 131.45 200.285Z'
            fill='#006C84'
          />
          <Path
            d='M150.708 208.024C149.426 204.647 147.441 202.334 145.128 200.958C141.72 195.299 132.903 193.095 129.292 200.614C122.508 214.761 118.428 229.737 117.693 245.463C117.068 258.813 117.568 275.462 127.385 285.623C130.808 289.171 137.233 289.297 140.657 285.623C149.88 275.681 152.647 261.549 154.069 248.465C155.461 235.646 155.32 220.248 150.708 208.055V208.024Z'
            fill='#00A7B7'
          />
          <Path
            d='M362.671 270.26C358.81 263.194 348.008 269.494 351.869 276.575C352.854 278.389 351.838 281.922 350.274 285.517C348.07 283.391 344.678 282.797 342.161 285.845C335.955 293.411 328.592 303.682 327.607 313.765C326.857 321.518 333.954 326.474 340.582 321.487C348.148 315.781 354.229 305.667 358.231 297.241C362.014 289.269 367.282 278.733 362.671 270.275V270.26Z'
            fill='#543411'
          />
          <Path
            d='M149.242 196.189C148.288 200.253 126.309 264.971 140.207 294.157C154.104 323.342 179.115 365.722 202.048 372.663C224.981 379.603 286.119 382.386 333.36 308.742C380.601 235.098 347.945 155.889 347.945 155.889C347.945 155.889 270.83 92.6714 225.669 115.588C180.507 138.505 160.278 149.386 149.242 196.189Z'
            fill='#D98659'
          />
          <Path
            d='M369.501 151.293C368.673 146.15 366.531 141.476 362.779 137.818C358.965 134.082 354.213 131.299 349.789 128.376C340.409 122.154 330.921 116.057 321.041 110.649C311.364 105.349 301.266 100.691 290.698 97.4549C279.725 94.0939 268.344 92.4525 256.87 92.7496C244.38 93.0622 232.046 95.3445 219.853 97.9239C208.988 100.222 197.967 102.363 187.509 106.146C177.958 109.601 169.063 114.666 162.091 122.138C155.869 128.813 151.414 136.895 148.241 145.399C144.895 154.341 142.91 163.752 141.519 173.178C140.815 177.899 140.268 182.651 139.799 187.403C139.565 189.78 139.33 192.156 139.143 194.532C138.94 197.017 138.814 199.644 139.846 201.988C140.675 203.849 142.707 205.396 144.77 205.725C144.442 206.287 145.114 207.163 145.849 206.788C156.385 201.332 166.046 194.25 174.488 185.903C182.866 177.602 190.057 168.066 195.669 157.686C198.53 152.387 200.969 146.853 202.985 141.179C210.63 158.452 226.559 170.614 243.692 177.743C254.666 182.307 266.296 185.012 277.958 187.106C283.867 188.169 289.807 189.061 295.732 189.952C298.562 190.374 301.407 190.733 304.22 191.296C306.847 191.812 309.473 192.703 311.193 194.876C312.85 196.955 313.537 199.644 314.225 202.176C314.882 204.583 315.429 207.022 315.929 209.476C316.945 214.416 317.727 219.403 318.68 224.343C320.494 233.878 322.917 243.664 328.716 251.637C331.421 255.373 335.173 259.75 340.316 258.75C342.801 258.265 345.021 256.733 346.975 255.17C348.773 253.732 350.352 252.059 351.758 250.246C357.746 242.508 360.247 232.69 362.295 223.311C364.952 211.133 366.953 198.799 368.282 186.403C368.954 180.166 369.454 173.913 369.798 167.644C370.08 162.22 370.392 156.733 369.517 151.34L369.501 151.293Z'
            fill='#543411'
          />
          <Path
            d='M367.877 136.079C352.37 114.662 328.78 101.343 303.44 94.9498C278.132 88.5718 250.369 86.9304 224.466 90.2288C200.673 93.2458 176.599 101.375 159.857 119.336C139.285 141.378 134.752 171.767 130.969 200.468C130.296 205.533 135.752 209.801 140.348 209.848C145.96 209.91 149.071 205.471 149.727 200.468C152.541 179.146 155.699 156.072 168.549 138.142C180.82 121.025 201.064 112.974 221.167 109.519C242.709 105.83 266.157 106.83 287.589 110.707C309.834 114.725 331.563 123.26 347.18 140.159C366.783 161.372 366.095 190.151 363.86 217.164C362.859 229.2 381.634 229.122 382.618 217.164C384.916 189.369 384.979 159.652 367.893 136.063L367.877 136.079Z'
            fill='#00A7B7'
          />
          <Path
            d='M376.565 203.616C377.394 198.379 374.455 192.689 367.686 193.158C349.412 194.408 337.516 208.243 332.529 225.016C327.574 241.712 324.056 264.097 329.95 280.918C336.046 298.301 355.759 303.835 369.312 290.391C381.912 277.901 386.836 256.234 388.024 239.179C388.837 227.58 387.774 209.118 376.55 203.616H376.565Z'
            fill='#00A7B7'
          />
          <Path
            d='M391.871 248.541C390.745 236.629 371.971 236.504 373.112 248.541C374.925 267.628 377.942 293.328 360.872 306.928C345.317 319.309 321.259 321.419 302.532 317.433C290.761 314.916 285.727 333.003 297.545 335.519C322.322 340.803 351.68 337.661 372.236 321.669C394.7 304.192 394.309 274.194 391.886 248.525L391.871 248.541Z'
            fill='#00A7B7'
          />
          <Path
            d='M387.718 211.164C383.388 207.647 378.089 209.054 375.056 212.196C369.538 214.369 364.614 219.809 361.221 224.405C353.702 234.566 349.419 246.634 347.887 259.124C346.543 270.13 346.23 286.356 356.719 293.359C368.147 300.972 378.855 290.077 384.576 280.932C395.441 263.58 407.009 226.828 387.718 211.164Z'
            fill='#006C84'
          />
          <Path
            d='M319.43 324.092C319.321 320.044 316.413 316.87 312.536 315.323C304.111 311.962 291.026 310.836 283.929 317.714C280.271 321.247 278.692 326.656 280.24 331.533C281.866 336.63 286.446 339.397 291.339 340.772C301 343.492 312.802 345.384 318.617 334.973C320.696 331.236 320.743 327.391 319.446 324.092H319.43Z'
            fill='#006C84'
          />
          <Path
            d='M226.797 275.757C221.466 279.337 212.29 284.089 205.537 282.213C200.988 280.947 202.535 277.227 203.645 273.381C204.661 269.864 205.771 266.378 206.912 262.907C211.461 248.979 216.37 235.144 217.683 220.45C218.762 208.304 216.979 195.626 205.396 189.154C193.734 182.635 180.806 185.934 169.301 190.92C161.938 194.109 168.3 204.896 175.616 201.722C182.401 198.784 190.936 196.048 198.096 199.409C205.912 203.082 205.865 211.618 205.302 219.121C204.161 234.441 198.205 249.026 193.593 263.533C190.451 273.428 184.871 287.372 197.392 293C208.647 298.049 223.545 293.015 233.128 286.591C239.771 282.135 233.518 271.286 226.812 275.789L226.797 275.757Z'
            fill='#BC6A41'
          />
          <Path
            d='M297.62 202.507C287.334 192.815 273.405 189.157 259.68 192.564C251.879 194.503 255.178 206.571 263.01 204.617C272.358 202.288 281.753 204.726 288.772 211.339C294.634 216.857 303.482 208.025 297.62 202.491V202.507Z'
            fill='#BC6A41'
          />
          <Path
            d='M277.159 223.858C276.753 223.202 276.268 222.702 275.768 222.264C275.018 221.342 273.986 220.622 272.689 220.263C269.578 219.403 265.701 221.31 264.997 224.624C264.122 228.783 263.387 233.222 263.966 237.474C264.575 241.945 267.608 246.947 272.689 246.947C278.332 246.947 280.27 241.242 280.52 236.583C280.755 232.128 279.473 227.641 277.159 223.858Z'
            fill='#BC6A41'
          />
          <Path
            d='M191.554 218.559C190.35 214.744 186.614 212.493 182.721 213.728C180.517 214.432 179.095 216.12 178.125 218.105C177.953 218.34 177.782 218.543 177.641 218.809C174.702 224.139 174.671 230.908 178.266 235.958C180.845 239.569 186.676 240.413 189.068 235.958C191.804 230.861 193.336 224.249 191.554 218.559Z'
            fill='#BC6A41'
          />
          <Path
            d='M250.714 318.071C237.177 324.793 222.279 325.621 207.96 321.01C200.284 318.54 196.986 330.608 204.63 333.062C222.201 338.721 240.553 337.048 257.03 328.873C264.236 325.293 257.905 314.507 250.714 318.071Z'
            fill='#BC6A41'
          />
        </G>
      </G>
      <Defs>
        <clipPath id='clip0_16344_11324'>
          <Rect width='689.324' height='765.063' fill='white' transform='translate(-100 88.5752)' />
        </clipPath>
      </Defs>

      <Path
        d='M258.3 0.728027C222.959 0.728027 188.67 6.78852 156.384 18.7375C79.5203 47.2289 25.4818 110.394 8.11986 192.033C-3.35359 245.262 -1.88652 294.495 12.6323 342.473C31.1679 403.745 76.6671 455.416 137.464 484.232C167.645 498.568 202.319 506.754 240.503 508.555C245.41 508.777 250.398 508.899 255.355 508.899C285.992 508.899 316.81 504.7 346.961 496.423C349.278 495.847 351.483 495.25 353.811 494.562C380.906 486.629 405.289 474.084 426.152 457.319C448.805 439.218 467.452 416.271 481.516 389.156C484.288 383.814 486.939 378.188 489.863 371.46C505.95 334.106 513.72 292.289 512.344 250.483C511.697 231.998 508.945 212.349 504.19 192.033C496.358 159.07 483.59 128.069 467.26 102.33C462.788 95.4194 458.68 89.6118 454.613 84.3911C425.262 46.5308 379.53 19.9617 322.405 7.59793C301.421 3.04497 279.85 0.738144 258.31 0.738144L258.3 0.728027Z'
        fill='white'
      />
      <Path
        d='M490.38 251.171C489.753 233.455 487.042 215.101 482.772 196.859C475.791 167.467 464.317 138.49 448.584 113.682C444.932 108.056 441.188 102.633 437.121 97.4225C407.618 59.38 362.908 38.2139 317.146 28.3087C266.173 17.2601 211.972 20.4877 162.973 38.6287C92.0881 64.8942 43.8268 122.433 28.0837 196.444C22.0333 224.48 20.2627 256.169 22.9742 286.613H22.7618C24.33 303.914 27.4564 320.801 32.1408 336.331C49.5534 393.87 91.8655 439.532 145.762 465.069C174.952 478.93 207.571 485.81 240.727 487.378C274.601 488.946 309.001 484.667 340.801 475.915C342.885 475.399 344.868 474.873 346.952 474.245C371.862 466.95 393.544 455.689 412.1 440.786C432.325 424.628 448.999 404.302 461.93 379.392C464.742 373.969 467.241 368.556 469.639 363.022C484.856 327.681 491.635 289.324 490.279 251.282L490.38 251.181V251.171Z'
        fill='#FFE9E3'
      />
      <Path
        d='M393.813 330.011C413.712 285.157 412.376 219.294 383.589 178.539C363.609 150.251 324.393 128.395 290.08 124.736C254.248 120.913 216.092 123.818 184.634 142.106C156.988 158.172 133.613 182.138 120.84 211.69C112.594 230.783 108.903 251.803 109.943 272.568C112.023 314.077 131.259 356.627 166.581 379.206C209.242 406.485 266.94 417.923 315.361 400.155C345.239 389.196 379.583 362.07 393.803 330.011H393.813Z'
        fill='#F69170'
      />
      <Path
        d='M366.383 257.807C370.216 307.279 338.91 358.483 279.143 367.188C179.783 381.643 110.118 279.469 181.445 199.661C242.547 131.28 359.145 164.523 366.383 257.807Z'
        fill='white'
      />
      <Path
        d='M260.569 336.025C297.423 336.025 327.299 306.149 327.299 269.296C327.299 232.442 297.423 202.566 260.569 202.566C223.716 202.566 193.84 232.442 193.84 269.296C193.84 306.149 223.716 336.025 260.569 336.025Z'
        fill='#F69170'
      />
      <Path
        d='M260.57 296.401C275.541 296.401 287.676 284.266 287.676 269.296C287.676 254.326 275.541 242.19 260.57 242.19C245.6 242.19 233.465 254.326 233.465 269.296C233.465 284.266 245.6 296.401 260.57 296.401Z'
        fill='white'
      />
      <Path
        d='M264.399 264.218L186.462 145.614C184.619 142.809 180.852 142.03 178.048 143.873L178.039 143.878C175.235 145.721 174.455 149.488 176.298 152.292L254.236 270.896C256.079 273.7 259.846 274.48 262.65 272.637L262.659 272.632C265.463 270.789 266.242 267.022 264.399 264.218Z'
        fill='#068298'
      />
      <Path
        d='M184.176 91.3604L221.68 148.987L208.132 188.356L208.122 188.376H208.101L166.622 184.808L129.129 127.182L172.688 133.92L184.176 91.3604Z'
        fill='#3BA5B5'
      />
    </Svg>
  )
}
