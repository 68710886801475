import React, { ReactElement, FunctionComponent } from 'react'
import classNames from 'classnames'
import styles from './selectField.module.scss'
import BaseInput from '../baseInput/BaseInput'
import Tooltip from '../../tooltip/Tooltip'
import { uniqueId } from 'lodash-es'
import ChevronIcon from '../../icons/ChevronIcon'
import { FieldRenderProps } from 'react-final-form'
import { Placement } from '@popperjs/core'
import { TOOLTIP_PLACEMENTS } from '../../../atoms/tooltip/Tooltip'

const SelectField: FunctionComponent<SelectFieldProps> = ({
  children,
  readOnly,
  input,
  label,
  labelStyle,
  meta: { touched = false, error, submitError, pristine, initial },
  className,
  disabled,
  tooltipContent,
  tooltipIcon,
  tooltipPlacement = TOOLTIP_PLACEMENTS.TOP,
  tooltipStyle,
  autocomplete,
  subLabel,
  dataTestId,
}) => {
  const fieldInfoId = `${input.name}_info`

  return (
    <BaseInput
      readOnly={readOnly}
      className={className}
      label={label}
      labelStyle={labelStyle}
      subLabel={subLabel}
      toolTip={
        tooltipContent ? (
          <Tooltip
            content={tooltipContent}
            id={uniqueId('inputTooltip_')}
            triggerStyle={tooltipStyle}
            placement={tooltipPlacement}
          >
            {tooltipIcon}
          </Tooltip>
        ) : null
      }
      touched={touched}
      error={error || submitError}
      disabled={disabled}
      insetIcon={<ChevronIcon direction='down' isFilled width={17} fillColor={styles.x_semi_dark_gray} />}
      htmlFor={input.name}
      fieldInfoId={fieldInfoId}
    >
      <select
        disabled={disabled}
        id={input.name}
        aria-describedby={fieldInfoId}
        {...input}
        className={classNames(touched && error ? styles['lyra-select-error'] : styles['lyra-select'], {
          [styles['with-placeholder']]: pristine && !initial,
        })}
        autoComplete={autocomplete}
        data-test-id={dataTestId}
      >
        {children}
      </select>
    </BaseInput>
  )
}

interface SelectFieldProps extends FieldRenderProps<number | any, any> {
  label: string
  labelStyle?: Dict // This style will be applied to the span containing the text and tooltip icon inside the field input's label.
  subLabel?: string
  className: string
  readOnly?: boolean
  tooltipIcon?: ReactElement // This prop is for passing in an icon component (e.g. InfoIcon) if you want to use a different icon rather than using the default QuestionMarkIcon.
  tooltipContent?: string // This string is the text that shows up in the tooltip bubble.
  tooltipPlacement?: Placement // This determines where the tooltip will show up relative to the trigger icon (e.g. top, right, etc.)
  tooltipStyle?: Dict // This style will be applied to the span that wraps the tooltip icon.
  disabled?: boolean
  autocomplete?: string
  dataTestId?: string
}

export default SelectField
