import React from 'react'
import colors from '../../../styles/_0colors.scss'
import { Icon } from './types'

const WarningIcon: React.FC<WarningIconProps> = ({ width = 24, fillColor = colors.x_light_gray_10, ...props }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} viewBox='0 0 24 24' {...props}>
      <path
        fill={fillColor}
        d='M2.51972711,24 L21.4802729,24 C23.4191335,24 24.6277738,21.7415846 23.6583436,19.9429663 L14.1780707,2.34896369 C13.2086404,0.550345436 10.7913596,0.550345436 9.82192934,2.34896369 L0.341656447,19.9429663 C-0.627773848,21.7415846 0.58086652,24 2.51972711,24 Z M12.5,14 C11.675,14 11,13.4375 11,12.75 L11,10.25 C11,9.5625 11.675,9 12.5,9 C13.325,9 14,9.5625 14,10.25 L14,12.75 C14,13.4375 13.325,14 12.5,14 Z M14,20 L11,20 L11,17 L14,17 L14,20 Z'
      />
    </svg>
  )
}

type WarningIconProps = Icon

export default WarningIcon
