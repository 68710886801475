import { TOGGLE_CSV_DOWNLOAD_MODAL } from '../../../constants/reduxConstants'

export const toggleDownloadCSVModal = (show: any) => {
  return {
    type: TOGGLE_CSV_DOWNLOAD_MODAL,
    payload: {
      showDownloadCSVModal: show,
    },
  }
}
