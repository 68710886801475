import { ADD_ALERT, REMOVE_ALERT, HIDE_ALERTS, EXPIRE_ALERTS } from '../common/constants/reduxConstants'
import * as _ from 'lodash-es'

export const addAlert = (alertObj: any) => {
  return {
    type: ADD_ALERT,
    payload: Object.assign({}, alertObj, { id: alertObj.id ?? _.uniqueId('alert_') }),
  }
}

export const removeAlert = (alertID: any) => {
  return {
    type: REMOVE_ALERT,
    payload: alertID,
  }
}

export const hideAlerts = () => {
  return {
    type: HIDE_ALERTS,
  }
}

export const expireAlerts = () => {
  return {
    type: EXPIRE_ALERTS,
  }
}
