import React from 'react'

import { useTheme } from 'styled-components/native'

import { AlertLoudIcon } from '../../atoms/icons/AlertLoudIcon'
import { InlineBanner } from '../inlineBanner/InlineBanner'

export interface InlineErrorBannerProps {
  text: React.ReactNode | string
  testId?: string
  backgroundColor?: string
  iconColor?: string
  textColor?: string
  trailingLink?: React.ReactElement
}

export const InlineErrorBanner: React.FC<InlineErrorBannerProps> = ({
  text,
  testId,
  backgroundColor,
  iconColor,
  textColor,
  trailingLink,
}) => {
  const { colors } = useTheme()

  const icon = <AlertLoudIcon fillColor={iconColor || colors.iconError} />
  return (
    <InlineBanner
      text={text}
      icon={icon}
      testId={testId}
      backgroundColor={backgroundColor || colors.backgroundError}
      textColor={textColor || colors.textError}
      trailingLink={trailingLink}
    />
  )
}
