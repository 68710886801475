import React, { FunctionComponent } from 'react'
import { ViewStyle } from 'react-native'

import styled, { useTheme } from 'styled-components/native'

import { MicrophoneIcon } from '../../atoms/icons/MicrophoneIcon'
import { ThemeType, tID } from '../../utils'

const Container = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  position: 'absolute',
  backgroundColor: theme.colors.components.zoomMuteButton.background,
  borderRadius: '50px',
  padding: theme.spacing['4px'],
  zIndex: 2,
}))

export type MuteBadgeProps = {
  style?: ViewStyle
}

export const MuteBadge: FunctionComponent<MuteBadgeProps> = ({ style }) => {
  const { colors } = useTheme() as ThemeType
  return (
    <Container style={style} testID={tID('MuteBadge')}>
      <MicrophoneIcon size={12} isMuted isFilled fillColor={colors.components.zoomMuteButton.fill} />
    </Container>
  )
}
