import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'
import { Pressable, ViewStyle } from 'react-native'

import BottomSheetGorhom from '@gorhom/bottom-sheet'
import { noop } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import { HamburgerMenuIcon, XIcon } from '../../atoms'
import { DropdownMenu, MENU_SELECTED_ITEM_STYLE } from '../../atoms/formElements/typeAhead/DropdownMenu'
import { TypeAheadCustomOptionsConfig } from '../../atoms/formElements/typeAhead/TypeAheadCustomOptionsConfig'
import { IS_WEB } from '../../constants'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { BottomSheet } from '../../molecules'
import { ThemeType, tID } from '../../utils'

export interface MenuItemProps {
  text: string
  id: string
  testId?: string
  onPressItem: () => void
  icon?: React.ReactElement
}

export interface MenuProps {
  menuItems: MenuItemProps[]
  testID?: string
  dropdownStyle?: ViewStyle
  menuItemStyle?: ViewStyle
  preselectedValue?: string
  dropdownWidth?: string | number
  horizontalPosition?: 'right' | 'left'
  disableSelect?: boolean
  showIcons?: boolean
  disableBottomSheetForWeb?: boolean
  trackMenuClick?: () => void
}
const MenuContainer = styled.View<{ positionRight?: boolean }>(({ positionRight }) => ({
  alignItems: positionRight ? 'flex-end' : 'flex-start',
}))

const MenuDropdownContainer = styled.View<{ theme: ThemeType; width?: string | number; positionRight?: boolean }>(
  ({ theme, width, positionRight }) => ({
    position: 'absolute',
    backgroundColor: theme.colors.backgroundPrimary,
    padding: `${theme.spacing['12px']} 0`,
    borderRadius: theme.spacing['8px'],
    boxShadow: `0 2px 16px ${theme.colors.shadowLow}`,
    ...(width && { width: width }),
    top: '100%',
    ...(positionRight ? { right: 0 } : { left: 0 }),
  }),
)

const BottomSheetMenuContainer = styled.View<{
  height: number
  paddingTop: number
  paddingBottom: number
}>(({ height, paddingTop, paddingBottom }) => ({
  padding: `${paddingTop}px 0 ${paddingBottom}px 0`,
  height,
}))

const IconContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginRight: theme.spacing['12px'],
}))

export const Menu: FunctionComponent<MenuProps> = ({
  menuItems,
  dropdownStyle,
  menuItemStyle,
  testID = 'Menu',
  preselectedValue,
  dropdownWidth = 206,
  horizontalPosition = 'left',
  disableSelect = true,
  showIcons = false,
  disableBottomSheetForWeb = false,
  trackMenuClick = noop,
}) => {
  const menuDropdownRef = useRef(null)
  const bottomSheetRef = useRef<BottomSheetGorhom>(null)
  const [selectedValue, setSelectedValue] = useState(preselectedValue)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const { breakpoints } = useTheme()
  const menuDropdownRefId = `${testID}-menuDropdown`
  const menuTriggerRefId = `${testID}-menuTrigger`
  const useBottomSheet = !IS_WEB || (!disableBottomSheetForWeb && breakpoints.isMobileSized)

  const numMenuItems = menuItems.length
  const bottomSheetMenuItemHeight = 56
  const bottomSheetMenuTopPadding = 24
  const bottomSheetMenuBottomPadding = 48
  const bottomSheetMenuHeight =
    numMenuItems * bottomSheetMenuItemHeight + bottomSheetMenuTopPadding + bottomSheetMenuBottomPadding
  const bottomSheetMenuItemStyle = { paddingVertical: 16, paddingHorizontal: 24 }
  const menuItemBorder = { borderRadius: 0 }

  useOnClickOutside(
    menuDropdownRefId,
    menuDropdownRef,
    () => {
      if (!useBottomSheet) {
        setIsDropdownOpen(false)
      }
    },
    menuTriggerRefId,
  )

  useEffect(() => {
    setSelectedValue(preselectedValue)
  }, [preselectedValue])

  const menuItemRenderer = (item: MenuItemProps, displayText: JSX.Element) => {
    return (
      <>
        {item.icon && showIcons && <IconContainer>{item.icon}</IconContainer>}
        {displayText}
      </>
    )
  }

  const dropdown = (
    <DropdownMenu
      options={menuItems.map((option) => option.text)}
      onOptionPress={(selected) => {
        if (!disableSelect) {
          setSelectedValue(selected)
        }
        menuItems.find((option) => option.text === selected)?.onPressItem()
        setIsDropdownOpen((prev) => !prev)
      }}
      value={selectedValue ? [selectedValue] : ['']}
      selectedItemStyle={MENU_SELECTED_ITEM_STYLE.LEFT_BORDER}
      menuItemStyle={{ ...menuItemStyle, ...(useBottomSheet && bottomSheetMenuItemStyle), ...menuItemBorder }}
      customOptionsConfig={new TypeAheadCustomOptionsConfig(menuItems, menuItemRenderer, undefined, 'text')}
      customOptions={menuItems}
    />
  )

  const closeBottomSheet = useCallback(() => {
    bottomSheetRef?.current?.close()
    setIsDropdownOpen(false)
  }, [])

  return (
    <MenuContainer testID={tID(testID)} positionRight={horizontalPosition === 'right'}>
      <Pressable
        testID={tID(menuTriggerRefId)}
        nativeID={menuTriggerRefId}
        onPress={() => {
          setIsDropdownOpen((prev) => !prev)
          trackMenuClick()
        }}
      >
        {isDropdownOpen && IS_WEB ? <XIcon></XIcon> : <HamburgerMenuIcon></HamburgerMenuIcon>}
      </Pressable>
      {!useBottomSheet && isDropdownOpen && (
        <MenuDropdownContainer
          testID={tID(`${testID}-menuDropdown`)}
          width={dropdownWidth}
          ref={menuDropdownRef}
          nativeID={menuDropdownRefId}
          style={dropdownStyle}
          positionRight={horizontalPosition === 'right'}
        >
          {dropdown}
        </MenuDropdownContainer>
      )}
      {useBottomSheet && isDropdownOpen && (
        <BottomSheet
          ref={bottomSheetRef}
          onCloseEnd={() => {
            closeBottomSheet()
          }}
          snapPoints={[bottomSheetMenuHeight]}
          enableScroll
        >
          <BottomSheetMenuContainer
            height={bottomSheetMenuHeight}
            paddingTop={bottomSheetMenuTopPadding}
            paddingBottom={bottomSheetMenuBottomPadding}
          >
            {dropdown}
          </BottomSheetMenuContainer>
        </BottomSheet>
      )}
    </MenuContainer>
  )
}
