import React, { FunctionComponent, ReactElement } from 'react'
import { FlatList, View, ViewStyle } from 'react-native'

import styled, { useTheme } from 'styled-components/native'

import { BodyText } from '../../atoms/bodyText/BodyText'
import { IS_WEB } from '../../constants'
import { BodyTextSize } from '../../styles/typeStyles'
import { ThemeType, tID } from '../../utils'

export interface BulletedListProps {
  data: Array<{ value: React.ReactNode | string; bulletIcon?: ReactElement; textColor?: string }>
  spaceBetween?: number
  style?: ViewStyle
  textColor?: string
  bulletColor?: string
  size?: BodyTextSize
  bulletAnnotation?: string
  scrollEnabled?: boolean
}

const Container = styled.View<{ spaceBetween: number; hasIcon: boolean }>(({ spaceBetween, hasIcon }) => ({
  marginBottom: spaceBetween,
  flexDirection: 'row',
  ...(hasIcon && { alignItems: 'center' }),
}))

const TextContainer = styled(BodyText)<{ textColor?: string; theme: ThemeType }>(({ textColor, theme }) => ({
  color: textColor,
  paddingLeft: theme.spacing['12px'],
  flex: 1,
}))

/**
 * Creates a simple bulleted list
 * TODO: Support nested bulleted lists
 */
export const BulletedList: FunctionComponent<BulletedListProps> = ({
  data,
  style,
  spaceBetween = 10,
  textColor,
  bulletColor,
  bulletAnnotation = '\u25CF',
  size = BodyTextSize.DEFAULT,
  scrollEnabled = true,
}) => {
  const { colors } = useTheme()
  const { fill: bulletFill } = colors.components.bulletedList

  const renderBulletItem = ({
    item,
    key,
  }: {
    item: { value: React.ReactNode | string; bulletIcon?: ReactElement; textColor?: string }
    key?: number
  }) => {
    return (
      <Container
        spaceBetween={spaceBetween}
        key={key}
        hasIcon={!!item.bulletIcon}
        // ignoring this TS issue, listitem is not defined in react native accessibility roles but since this only affects WEB, we are leaving it as undefined for now
        // @ts-ignore next-line
        accessibilityRole={IS_WEB ? 'listitem' : undefined}
      >
        {item.bulletIcon || (
          <BodyText size={size} color={bulletColor || bulletFill}>
            {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
            {bulletAnnotation}{' '}
          </BodyText>
        )}

        <TextContainer
          testID={tID('BulletText')}
          size={size}
          color={item.textColor || textColor || colors.textPrimary}
          text={item.value}
        />
      </Container>
    )
  }

  // prospect-2848 - Flatlists are not accessibility friendly for web, this creates a web-friendly list component
  return IS_WEB ? (
    <View style={style} accessibilityRole='list'>
      {data.map((item, idx) => renderBulletItem({ item, key: idx }))}
    </View>
  ) : (
    <FlatList
      data={data}
      style={style}
      renderItem={renderBulletItem}
      keyExtractor={(item, index) => index.toString()}
      scrollEnabled={scrollEnabled}
    />
  )
}
