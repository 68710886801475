import { HealthPlansList } from '../../../models'

export enum HEALTH_PLAN_INFO_KEYS {
  HEALTH_PLAN_NAME = 'healthPlanName',
  LEGAL_FIRST_NAME = 'legalFirstName',
  LEGAL_LAST_NAME = 'legalLastName',
  LEGAL_DOB = 'legalDob',
  HEALTH_PLAN_MEMBERSHIP_ID = 'healthPlanMembershipId',
  HEALTH_PLAN_NAME_TEXT_INPUT = 'healthPlanNameTextInput',
  SECONDARY_HEALTH_PLAN_OPTION = 'secondaryHealthPlanOption',
  IS_HEALTH_PLAN_INTEGRATED = 'isHealthPlanIntegrated',
  SECONDARY_HEALTH_PLAN_NAME = 'secondaryHealthPlanName',
  SECONDARY_LEGAL_FIRST_NAME = 'secondaryHealthPlanLegalFirstName',
  SECONDARY_LEGAL_LAST_NAME = 'secondaryHealthPlanLegalLastName',
  SECONDARY_LEGAL_DOB = 'secondaryHealthPlanLegalDob',
  SECONDARY_HEALTH_PLAN_MEMBERSHIP_ID = 'secondaryHealthPlanMembershipId',
  IS_SECONDARY_HEALTH_PLAN_INTEGRATED = 'isSecondaryHealthPlanIntegrated',
}

export interface HealthPlanFormValues {
  [HEALTH_PLAN_INFO_KEYS.HEALTH_PLAN_NAME]?: string
  [HEALTH_PLAN_INFO_KEYS.HEALTH_PLAN_NAME_TEXT_INPUT]?: string
  [HEALTH_PLAN_INFO_KEYS.LEGAL_FIRST_NAME]?: string
  [HEALTH_PLAN_INFO_KEYS.LEGAL_LAST_NAME]?: string
  [HEALTH_PLAN_INFO_KEYS.LEGAL_DOB]?: string
  [HEALTH_PLAN_INFO_KEYS.HEALTH_PLAN_MEMBERSHIP_ID]?: string
  [HEALTH_PLAN_INFO_KEYS.SECONDARY_HEALTH_PLAN_OPTION]?: boolean
}

export const HPI_DEFAULT_MAX_COST = {
  considerMeds: 475,
  blendedCareMeds: 475,
  directAccessTherapy: 285,
  considerLyraTherapy: 285,
  stressManagement: 115,
  guidedSelfCareEnabled: 115,
  alcoholUseDisorderTherapy: 285,
  clinicalLeaveEvaluation: 475,
}

export const COST_SHARE_TERMS_LINK = 'https://www.lyrahealth.com/cost-share-terms-conditions/'

export enum COST_STATUS {
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum HEALTH_PLAN_TYPE {
  INTEGRATED = 'Integrated',
  NON_INTEGRATED = 'NonIntegrated',
  GOVERNMENT = 'Government',
  OTHER = 'Other',
}

// TODO: Placeholder for handling government health plan error messages (pending ACCOUNT-1904)
export const GOVERNMENT_HEALTH_PLANS = [
  {
    name: 'Medicare',
    eligibility_name: 'Medicare',
  },
  {
    name: 'Medicaid',
    eligibility_name: 'Medicaid',
  },
  {
    name: 'TriCare',
    eligibility_name: 'TriCare',
  },
  {
    name: 'Kaiser',
    eligibility_name: 'Kaiser',
  },
] as HealthPlansList[]
