import React, { FunctionComponent } from 'react'

import { FlattenSimpleInterpolation } from 'styled-components'
import styled, { useTheme } from 'styled-components/native'

import { tID } from '../../utils'
import { BodyText, Size } from '../bodyText/BodyText'

export type ActivityBadgeProps = {
  text: string | number | React.ReactNode | null
  backgroundColor?: string
  width?: number | string
  color?: string
  height?: string | number
  activityBadgeCustomStyle?: FlattenSimpleInterpolation
}

const ActivityBadgeContainer = styled.View<{
  width: number | string
  backgroundColor: string
  height?: number | string
  activityBadgeCustomStyle?: FlattenSimpleInterpolation
}>`
  border-radius: 30px;
  ${({ backgroundColor, width, height }) => `
    width: ${width}px;
    height: ${height}px;
    backgroundColor: ${backgroundColor};
`}
  ${({ activityBadgeCustomStyle }) => activityBadgeCustomStyle};
`

const ActivityBadgeTextContainer = styled.View`
  flex-grow: 1;
  justify-content: center;
  align-self: center;
`
/**
 * Badge used on the activities page
 */
export const ActivityBadge: FunctionComponent<ActivityBadgeProps> = ({
  text,
  backgroundColor,
  color,
  width = 53,
  height = 24,
  activityBadgeCustomStyle,
}) => {
  const { colors } = useTheme()
  const componentColors = colors.components.activityBadge

  return (
    <ActivityBadgeContainer
      width={width}
      backgroundColor={backgroundColor || componentColors.background}
      height={height}
      activityBadgeCustomStyle={activityBadgeCustomStyle}
      testID={tID('ActivityBadge')}
    >
      <ActivityBadgeTextContainer>
        <BodyText text={text} size={Size.BADGE} color={color || componentColors.text} />
      </ActivityBadgeTextContainer>
    </ActivityBadgeContainer>
  )
}
