export default {
  meta_data: {
    schema: {
      type: 'object',
      properties: {
        'attestation_data.noteAppropriateSections_4': {
          name: 'attestation_data.noteAppropriateSections_4',
          content:
            'Complete the appropriate sections for all high-risk programs for which you believe your client qualifies. For example, if your client meets criteria for a cannabis use disorder and is engaging in self-harm related to BPD, complete both the substance use and high risk sections. You may be asked for documentation supporting the information you attest to below.',
        },
        'attestation_data.doesClientHigh_6_1': {
          name: 'attestation_data.doesClientHigh_6_1',
          title:
            'Does your client have high-risk factors, such as recent suicide behaviors, self-harm, recent trauma exposure, or hospitalizations/residential stays?',
          type: 'boolean',
          validation: {
            isRequired: true,
          },
        },
        'attestation_data.highRiskFactors': {
          name: 'attestation_data.highRiskFactors',
          title: 'I attest that my client in the past 12 months has had (check all that apply):',
          type: 'string',
          enum: [
            'Suicidal behavior or active suicidal ideation with intent and plan',
            'Self-harm',
            'Exposure to a traumatic event (interpersonal violence; terminal illness; exposure to egregious content)',
            'A psychiatric hospitalization',
            'A stay in a residential facility for a behavioral health or substance use problem',
            'Treatment in an IOP or PHP for a behavioral health or substance use problem',
            'An ED visit for a behavioral health or substance use problem',
          ],
          condition: {
            conditionType: 'AND',
            items: [
              {
                parentField: 'attestation_data.doesClientHigh_6_1',
                parentValue: 1,
              },
              {
                parentField: 'attestation_data.otherHighRiskFactors',
                match: 'isNot',
                checkVisibility: false,
                parentValue: [
                  'Suicide',
                  'Self-harm',
                  'Inpatient admission, PHP/IOP admission or residential treatment',
                ],
              },
            ],
          },
          validation: {
            isRequired: true,
          },
        },
        'attestation_data.highRiskFactorsTreatment': {
          name: 'attestation_data.highRiskFactorsTreatment',
          title:
            'In the space below, provide (free text): \n1. A brief case conceptualization\n2. A brief treatment plan, including treatment targets\n3. Your rationale for why additional treatment is warranted including an estimate of how many more treatment sessions the client will require',
          type: 'string',
          condition: {
            conditionType: 'AND',
            items: [
              {
                parentField: 'attestation_data.doesClientHigh_6_1',
                parentValue: 1,
              },
              {
                parentField: 'attestation_data.otherHighRiskFactors',
                match: 'isNot',
                checkVisibility: false,
                parentValue: [
                  'Suicide',
                  'Self-harm',
                  'Inpatient admission, PHP/IOP admission or residential treatment',
                ],
              },
            ],
          },
          validation: {
            isRequired: true,
          },
        },
        'attestation_data.otherHighRiskFactors': {
          name: 'attestation_data.otherHighRiskFactors',
          title: 'OR I attest that my client is at high risk of (check all that apply):',
          type: 'string',
          enum: ['Suicide', 'Self-harm', 'Inpatient admission, PHP/IOP admission or residential treatment'],
          condition: {
            conditionType: 'AND',
            items: [
              {
                parentField: 'attestation_data.doesClientHigh_6_1',
                parentValue: 1,
              },
              {
                parentField: 'attestation_data.highRiskFactors',
                match: 'isNot',
                checkVisibility: false,
                parentValue: [
                  'Suicidal behavior or active suicidal ideation with intent and plan',
                  'Self-harm',
                  'Exposure to a traumatic event (interpersonal violence; terminal illness; exposure to egregious content)',
                  'A psychiatric hospitalization',
                  'A stay in a residential facility for a behavioral health or substance use problem',
                  'Treatment in an IOP or PHP for a behavioral health or substance use problem',
                  'An ED visit for a behavioral health or substance use problem',
                ],
              },
            ],
          },
          validation: {
            isRequired: true,
          },
        },
        'attestation_data.otherHighRiskFactorsTreatment': {
          name: 'attestation_data.otherHighRiskFactorsTreatment',
          title:
            'In the space below, provide (free text): \n1. A brief case conceptualization\n2. A brief treatment plan, including treatment targets\n3. Your rationale for why additional treatment is warranted including an estimate of how many more treatment sessions the client will require',
          type: 'string',
          condition: {
            conditionType: 'AND',
            items: [
              {
                parentField: 'attestation_data.doesClientHigh_6_1',
                parentValue: 1,
              },
              {
                parentField: 'attestation_data.highRiskFactors',
                match: 'isNot',
                checkVisibility: false,
                parentValue: [
                  'Suicidal behavior or active suicidal ideation with intent and plan',
                  'Self-harm',
                  'Exposure to a traumatic event (interpersonal violence; terminal illness; exposure to egregious content)',
                  'A psychiatric hospitalization',
                  'A stay in a residential facility for a behavioral health or substance use problem',
                  'Treatment in an IOP or PHP for a behavioral health or substance use problem',
                  'An ED visit for a behavioral health or substance use problem',
                ],
              },
            ],
          },
          validation: {
            isRequired: true,
          },
        },
        'attestation_data.doesClientHigh_6_2': {
          name: 'attestation_data.doesClientHigh_6_2',
          title: 'Does your client have an active substance use disorder?',
          type: 'boolean',
          validation: {
            isRequired: true,
          },
        },
        'attestation_data.activeSubstanceAbuseTypes': {
          name: 'attestation_data.activeSubstanceAbuseTypes',
          title: 'I attest that my client is in active treatment with me for (check all that apply):',
          type: 'string',
          enum: [
            'Alcohol use disorder',
            'Cannabis use disorder',
            'Opioid use disorder',
            'Sedative, hypnotic or anxiolytic use disorder',
            'Stimulant use disorder',
          ],
          condition: {
            parentField: 'attestation_data.doesClientHigh_6_2',
            parentValue: 1,
          },
          validation: {
            isRequired: true,
          },
        },
        'attestation_data.activeSubstanceAbuseTreatment': {
          name: 'attestation_data.activeSubstanceAbuseTreatment',
          title:
            'I attest that I am currently utilizing the following treatment for the above condition (check all that apply):',
          type: 'string',
          enum: ['MI', 'Relapse Prevention', 'Harm Reduction', 'Other'],
          condition: {
            parentField: 'attestation_data.doesClientHigh_6_2',
            parentValue: 1,
          },
          validation: {
            isRequired: true,
          },
        },
        'attestation_data.activeSubstanceAbuseTreatmentDescription': {
          name: 'attestation_data.activeSubstanceAbuseTreatmentDescription',
          title:
            'In the space below, provide (free text): \n1. A brief case conceptualization\n2. A brief treatment plan, including treatment targets\n3. Your rationale for why additional treatment is warranted including an estimate of how many more treatment sessions the client will require',
          type: 'string',
          condition: {
            parentField: 'attestation_data.doesClientHigh_6_2',
            parentValue: 1,
          },
          validation: {
            isRequired: true,
          },
        },
        'attestation_data.hasPersistentMentalHealthCondition': {
          name: 'attestation_data.hasPersistentMentalHealthCondition',
          title: 'Does your client have a persistent mental health condition?',
          type: 'boolean',
          validation: {
            isRequired: true,
          },
        },
        'attestation_data.hasPersistentMentalHealthConditionType': {
          name: 'attestation_data.hasPersistentMentalHealthConditionType',
          title: 'I attest that my client is in active treatment with me for (check all that apply):',
          type: 'string',
          enum: [
            'Obsessive-compulsive disorder',
            'Personality disorder',
            'Depression with psychotic features',
            'Treatment resistant mood disorder',
            'Schizoaffective disorder or Schizophrenia',
            'Moderate to severe eating disorder',
            'Moderate to severe PTSD',
          ],
          condition: {
            parentField: 'attestation_data.hasPersistentMentalHealthCondition',
            parentValue: 1,
          },
          validation: {
            isRequired: true,
          },
        },
        'attestation_data.hasHighPhqGadScoreContent': {
          name: 'attestation_data.hasHighPhqGadScore',
          content:
            "I attest that my client's most recent score on the PHQ-9 from within the past 30 days is 14 or higher\nOR I attest that my client's most recent score on the GAD-7 from within the past 30 days is 10+ range after treatment",
          condition: {
            parentField: 'attestation_data.hasPersistentMentalHealthCondition',
            parentValue: 1,
          },
        },
        'attestation_data.hasHighPhqGadScore': {
          name: 'attestation_data.hasHighPhqGadScore',
          title: 'Yes',
          type: 'boolean',
          condition: {
            parentField: 'attestation_data.hasPersistentMentalHealthCondition',
            parentValue: 1,
          },
          validation: {
            isRequired: true,
          },
        },
        'attestation_data.hasPersistentMentalHealthConditionTreatment': {
          name: 'attestation_data.hasPersistentMentalHealthConditionTreatment',
          title:
            'I attest that I am currently utilizing the following treatment for the above condition (check all that apply):',
          type: 'string',
          enum: ['CBT', 'ERP', 'IPT', 'DBT', 'CPT', 'PE', 'Other'],
          condition: {
            parentField: 'attestation_data.hasPersistentMentalHealthCondition',
            parentValue: 1,
          },
          validation: {
            isRequired: true,
          },
        },
        'attestation_data.hasPersistentMentalHealthConditionDescription': {
          name: 'attestation_data.hasPersistentMentalHealthConditionDescription',
          title:
            'In the space below, please provide: \n1. A brief case conceptualization\n2. A brief treatment plan, including treatment targets\n3. Your rationale for why additional treatment is warranted including an estimate of how many more treatment sessions the client will require',
          type: 'string',
          validation: {
            isRequired: true,
          },
          condition: {
            parentField: 'attestation_data.hasPersistentMentalHealthCondition',
            parentValue: 1,
          },
        },
      },
    },
    uiSchema: {
      'ui:order': [
        'attestation_data.noteAppropriateSections_4',
        'attestation_data.doesClientHigh_6_1',
        'attestation_data.highRiskFactors',
        'attestation_data.highRiskFactorsTreatment',
        'attestation_data.otherHighRiskFactors',
        'attestation_data.otherHighRiskFactorsTreatment',
        'attestation_data.doesClientHigh_6_2',
        'attestation_data.activeSubstanceAbuseTypes',
        'attestation_data.activeSubstanceAbuseTreatment',
        'attestation_data.activeSubstanceAbuseTreatmentDescription',
        'attestation_data.hasPersistentMentalHealthCondition',
        'attestation_data.hasPersistentMentalHealthConditionType',
        'attestation_data.hasHighPhqGadScoreContent',
        'attestation_data.hasHighPhqGadScore',
        'attestation_data.hasPersistentMentalHealthConditionTreatment',
        'attestation_data.hasPersistentMentalHealthConditionDescription',
      ],
      'attestation_data.doesClientHigh_6_1': {
        'ui:widget': 'radio',
      },
      'attestation_data.highRiskFactors': {
        'ui:widget': 'checkboxGroup',
      },
      'attestation_data.highRiskFactorsTreatment': {
        'ui:widget': 'textarea',
      },
      'attestation_data.otherHighRiskFactors': {
        'ui:widget': 'checkboxGroup',
      },
      'attestation_data.otherHighRiskFactorsTreatment': {
        'ui:widget': 'textarea',
      },
      'attestation_data.doesClientHigh_6_2': {
        'ui:widget': 'radio',
      },
      'attestation_data.activeSubstanceAbuseTypes': {
        'ui:widget': 'checkboxGroup',
      },
      'attestation_data.activeSubstanceAbuseTreatment': {
        'ui:widget': 'checkboxGroup',
      },
      'attestation_data.activeSubstanceAbuseTreatmentDescription': {
        'ui:widget': 'textarea',
      },
      'attestation_data.hasPersistentMentalHealthCondition': {
        'ui:widget': 'radio',
      },
      'attestation_data.hasPersistentMentalHealthConditionType': {
        'ui:widget': 'checkboxGroup',
      },
      'attestation_data.hasHighPhqGadScore': {
        'ui:widget': 'checkbox',
      },
      'attestation_data.hasPersistentMentalHealthConditionTreatment': {
        'ui:widget': 'checkboxGroup',
      },
      'attestation_data.hasPersistentMentalHealthConditionDescription': {
        'ui:widget': 'textarea',
      },
    },
  },
}
