import React, { FunctionComponent } from 'react'

import styled from 'styled-components/native'

import { Image } from '../image/Image'

type CareOptionEmployerBenefitsIconProps = {
  size?: number
}

const CareOptionEmployerBenefitsPNG = styled(Image)<{ size: number }>(({ size }) => ({
  width: `${size}px`,
  height: `${size}px`,
}))

export const CareOptionEmployerBenefitsIcon: FunctionComponent<CareOptionEmployerBenefitsIconProps> = ({
  size = 64,
}) => {
  return <CareOptionEmployerBenefitsPNG size={size} source={require('./../../assets/careOptionEmployerBenefits.png')} />
}
