/* eslint-disable formatjs/no-literal-string-in-jsx */
import React, { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'

import { createColumnHelper } from '@tanstack/react-table'
import { format, isBefore } from 'date-fns'
import { isNil } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import { HistoricCharges, targetUtilization, weekUtilizationData } from '@lyrahealth-inc/shared-app-logic'

import { ThemeType, tID } from '../..'
import { BodyText, ChevronIcon, ChevronIconDirection, InfoIcon, Subhead, TextButton, Tooltip } from '../../atoms'
import { DataTable, InlineInfoBanner } from '../../molecules'
import { SubheadSize } from '../../styles'
import { BodyTextSize } from '../../styles/typeStyles'

export interface CaseloadManagementDetailedWeeklyViewProps {
  displayPTO: boolean
  displayTarget?: boolean
  historicUtilization: weekUtilizationData[]
  targetUtilization?: targetUtilization
  onBackPressed: () => void
  historicQuarter?: string
  currentQuarter?: string
  isTeensTherapist?: boolean
  historicCharges?: HistoricCharges[]
}

const Container = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  maxWidth: '1275px',
  marginLeft: '84px',
  marginRight: '84px',
  flexGrow: 1,
  gap: theme.spacing['32px'],
  paddingVertical: theme.spacing['32px'],
}))

const DataBoardContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  backgroundColor: theme.colors.backgroundPrimary,
  padding: `${theme.spacing['32px']} ${theme.spacing['32px']}`,
  borderRadius: '16px',
  border: `1px solid ${theme.colors.borderDefault}`,
}))

const CardContainer = styled.View({
  gap: '24px',
})

const DetailsTableViewHeader = styled.View({
  textAlign: 'center',
  flexDirection: 'row',
  alignItems: 'start',
})

const DetailsTableViewTitle = styled(BodyText)({
  textAlign: 'center',
})

const DetailsTableViewHeaderTooltip = styled(Tooltip)({
  marginLeft: '4px',
})

const InlineBannerContainer = styled.View({
  marginTop: '8px',
  marginBottom: '24px',
})

export const CaseloadManagementDetailedWeeklyView: FunctionComponent<CaseloadManagementDetailedWeeklyViewProps> = ({
  displayPTO,
  displayTarget,
  historicUtilization,
  targetUtilization,
  onBackPressed,
  historicQuarter,
  currentQuarter,
  isTeensTherapist,
  historicCharges,
}) => {
  // @ts-ignore-next-line Property 'colors' does not exist on type 'DefaultTheme'.ts(2339)
  const { colors } = useTheme()
  const { formatDate } = useIntl()

  const columnHelper = createColumnHelper<weekUtilizationData>()

  const columns = [
    columnHelper.accessor('startDate', {
      id: 'startDateWeekNumber',
      header: 'WEEK',
      cell: (cell) => <>Week {cell.row.index + 1}</>,
    }),
    columnHelper.accessor('startDate', {
      id: 'startDateRange',
      header: 'DATES',
      cell: (item) => {
        const startDate = new Date(item.getValue())
        const endDate = new Date(item.row.original.endDate)
        const isSameYear = startDate.getFullYear() === endDate.getFullYear()
        const formattedStart = isSameYear
          ? formatDate(startDate, { month: 'numeric', day: 'numeric' })
          : formatDate(startDate, { month: 'numeric', day: 'numeric', year: '2-digit' })
        const formattedEnd = isSameYear
          ? formatDate(endDate, { month: 'numeric', day: 'numeric' })
          : formatDate(endDate, { month: 'numeric', day: 'numeric', year: '2-digit' })
        return (
          <>
            {formattedStart}-{formattedEnd}
          </>
        )
      },
    }),
    columnHelper.accessor('sessionsCredited', {
      header: () => (
        <DetailsTableViewHeader>
          <DetailsTableViewTitle text='CREDITED SESSIONS' size={BodyTextSize.CAPTION} color={colors.textPrimary} />
          <DetailsTableViewHeaderTooltip
            content='attended, late cancel, no shows, tech issues, medical emergency'
            hoverEnabled
          >
            <InfoIcon size={18} />
          </DetailsTableViewHeaderTooltip>
        </DetailsTableViewHeader>
      ),
    }),
    columnHelper.accessor('sessionsNonCredited', {
      header: () => (
        <DetailsTableViewHeader>
          <DetailsTableViewTitle text='NON-CREDITED SESSIONS' size={BodyTextSize.CAPTION} color={colors.textPrimary} />
          <DetailsTableViewHeaderTooltip content='24 hr provider and client cancels' hoverEnabled>
            <InfoIcon size={18} />
          </DetailsTableViewHeaderTooltip>
        </DetailsTableViewHeader>
      ),
    }),
  ]

  /* NON-PT/FT coaches see the legacy dashboard but should not see any references to PTO */
  if (displayPTO) {
    columns.push(
      columnHelper.accessor('providerPaidTimeOffHours', {
        header: () => (
          <DetailsTableViewHeader>
            <DetailsTableViewTitle
              text={displayPTO && displayTarget ? 'TIME OFF' : 'PTO'}
              size={BodyTextSize.CAPTION}
              color={colors.textPrimary}
            />
            <DetailsTableViewHeaderTooltip
              content='Time off hours reflected are based on hours blocked on your Google calendar'
              hoverEnabled
            >
              <InfoIcon size={18} />
            </DetailsTableViewHeaderTooltip>
          </DetailsTableViewHeader>
        ),
        cell: (cell) => <>{cell.getValue() || 0} hrs</>,
      }),
    )
  }

  if (!isTeensTherapist && historicUtilization && historicUtilization[0]?.providerUtilizationPercentage !== undefined) {
    columns.push(
      columnHelper.accessor('providerUtilizationPercentage', {
        header: 'CASELOAD PERCENTAGE',
        cell: (cell) => <>{cell.getValue()}%</>,
      }),
    )
  }

  if (isTeensTherapist && !isNil(historicCharges)) {
    columns.push(
      columnHelper.accessor('target', {
        header: 'CASELOAD PERCENTAGE',
        cell: (cell) => <>{cell.getValue()}%</>,
      }),
    )
  }

  if (displayTarget) {
    columns.push(
      columnHelper.accessor('target', {
        header: 'TARGET',
        cell: (cell) => <>{cell.getValue()}</>,
      }),
      columnHelper.accessor('achieved_target', {
        header: 'ACHIEVED TARGET',
        cell: (cell) => <>{cell.getValue()}</>,
      }),
    )
  }

  const teenTherapistData =
    !isNil(historicCharges) &&
    historicUtilization?.map((row, index) => {
      const dateKey = format(new Date(row.endDate), 'yyyy-MM-dd')
      const historicChargesForSelectedWeek = historicCharges?.find(
        (charge: HistoricCharges) => charge.endDate === dateKey,
      )
      const completedCurrentWeek = historicChargesForSelectedWeek?.sessionsCredited ?? 0
      const newTarget = targetUtilization
        ? ((row.sessionsCredited + completedCurrentWeek) /
            (targetUtilization?.providerExpectedWeekWorkHours -
              (row.providerPaidTimeOffHours ? parseFloat(row.providerPaidTimeOffHours) : 0))) *
          0.75
        : 0
      const newTargetPercentage = newTarget * 100
      return {
        week: 'Week ' + (index + 1),
        startDate: row.startDate,
        endDate: row.endDate,
        sessionsCredited: row.sessionsCredited + completedCurrentWeek,
        sessionsNonCredited: row.sessionsNonCredited,
        providerPaidTimeOffHours: row.providerPaidTimeOffHours
          ? parseFloat(Math.round(2 * parseFloat(row.providerPaidTimeOffHours)).toFixed()) / 2
          : 0,
        target: newTargetPercentage ? Math.round(newTargetPercentage * 2) / 2 : 0,
      }
    })
  const updatedData = historicUtilization?.map((row, index) => {
    const target = targetUtilization
      ? Math.round(
          (targetUtilization?.providerExpectedWeekWorkHours -
            (row.providerPaidTimeOffHours ? parseFloat(row.providerPaidTimeOffHours) : 0)) *
            0.75,
        )
      : 0
    return {
      week: 'Week ' + (index + 1),
      startDate: row.startDate,
      endDate: row.endDate,
      sessionsCredited: row.sessionsCredited,
      sessionsNonCredited: row.sessionsNonCredited,
      providerPaidTimeOffHours: row.providerPaidTimeOffHours
        ? parseFloat(Math.round(2 * parseFloat(row.providerPaidTimeOffHours)).toFixed()) / 2
        : 0,
      target: target,
      achieved_target: isBefore(new Date(), new Date(row.endDate))
        ? '-'
        : row.sessionsCredited >= target
        ? 'Yes'
        : 'No',
    }
  })
  const data = displayPTO && displayTarget ? updatedData : isTeensTherapist ? teenTherapistData : historicUtilization
  return (
    <Container>
      <TextButton
        text='Back'
        leftIcon={<ChevronIcon direction={ChevronIconDirection.LEFT} />}
        onPress={onBackPressed}
        testID={tID('CaseloadManagementDetailedWeeklyView-backButton')}
      />
      <CardContainer>
        <Subhead
          size={SubheadSize.LARGE}
          text='Detailed weekly view'
          testID={tID('CaseloadManagementDetailedWeeklyView-title')}
        />
        <DataBoardContainer>
          {historicQuarter && historicQuarter !== currentQuarter && (
            <InlineBannerContainer testID={tID('WeeklySnapshot-historicInlineBanner')}>
              <InlineInfoBanner
                marginBottom={'0px'}
                text={`You are viewing ${historicQuarter.replace(
                  '_',
                  ' ',
                )} data. If manual adjustments were made after the quarter they will not be reflected here.`}
              />
            </InlineBannerContainer>
          )}
          <DataTable
            columns={columns}
            // @ts-ignore-next-line Cannot find name 'Dict'.ts(2304)
            data={data as unknown as Dict[]}
            pagination={false}
            rowHeight='48px'
            headerBottomPadding='16px'
            tableZebraColor={colors.backgroundSection}
          />
        </DataBoardContainer>
      </CardContainer>
    </Container>
  )
}
