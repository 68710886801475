import React, { FunctionComponent } from 'react'
import { ImageSourcePropType } from 'react-native'

import styled from 'styled-components/native'

import notificationIllustration from '../../assets/notificationIllustration.png'
import { tID } from '../../utils'
import { Image } from '../image/Image'

const ImageContainer = styled(Image)<{ size: string }>(({ size }) => ({
  width: size,
  height: size,
}))

export const NotificationIllustration: FunctionComponent<NotificationIllustrationProps> = ({ size = 56 }) => {
  const sizeInPixels = `${size}px`
  return (
    <ImageContainer
      size={sizeInPixels}
      testID={`${tID('NotificationIllustration')}`}
      source={notificationIllustration as ImageSourcePropType}
    />
  )
}

type NotificationIllustrationProps = {
  size?: number
}
