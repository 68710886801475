import React from 'react'
import { PrimaryButton, hrefUtils } from '@lyrahealth-inc/ui-core'
import styles from './downloadPaymentHistoryCard.module.scss'

export const DownloadPaymentHistoryCard: React.FC<DownloadPaymentHistoryCardProps> = ({ paymentHistoryFile }) => {
  return (
    <div className={styles['download-list-row']}>
      <div className={styles.card}>
        <div className={styles.content}>
          <div className={styles.left}>
            <h4>{paymentHistoryFile.file_name}</h4>
          </div>
          <div className={styles.right}>
            <PrimaryButton
              isSmall
              onClick={() => hrefUtils.createCSVDownload(paymentHistoryFile.data, paymentHistoryFile.file_name)}
            >
              Download
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  )
}

type DownloadPaymentHistoryCardProps = {
  paymentHistoryFile: PaymentHistoryFile
}

type PaymentHistoryFile = {
  file_name: string
  data: string
}

export default DownloadPaymentHistoryCard
