import { Appointment, useApptCountDown, SESSION_TYPES, getJoinButtonText } from '@lyrahealth-inc/shared-app-logic'
import dateUtils from '../../utils/dateUtils'

import { useState, useEffect, useCallback } from 'react'
import useIsMobile from '../../hooks/useIsMobile'
import moment from 'moment-timezone'

// This is a hook for getting information to display the session banner for an appointment
export const useApptBannerInfo = ({ appt, sessionType, isClient, timeZone, title }: UseApptBannerInfo) => {
  const [bannerInfo, setBannerInfo] = useState<BannerInfo>({
    startsIn: '',
    startTime: moment(''),
    endTime: moment(''),
    joinText: '',
    sessionTitle: '',
    timeZone: '',
  })
  const { startsIn, setStartTime } = useApptCountDown()
  const [isMobile] = useIsMobile()

  const getBannerInfo = useCallback(
    (appt: Appointment) => {
      // use the timezone passed by user, default to appointment if nothing is passed
      const startTime = dateUtils.getAppointmentDateTimeObject(appt).tz(timeZone ?? moment.tz.guess(true))
      setStartTime(startTime) // set our countdown hooks start time

      const endTime = dateUtils.getAppointmentDateTimeObject(appt).add(appt.appointmentDuration, 'minutes')

      const sessionTitle =
        title || (isClient ? `${appt.provider?.first_name} ${appt.provider?.last_name}` : 'Next Session')

      const joinText = getJoinButtonText(sessionType, startsIn, isMobile)

      setBannerInfo({
        startsIn,
        startTime,
        endTime,
        joinText,
        sessionTitle,
        timeZone,
      })
    },
    [title, isClient, sessionType, setStartTime, startsIn, isMobile, timeZone],
  )

  useEffect(() => {
    if (appt) {
      getBannerInfo(appt)
    }
  }, [appt, startsIn, getBannerInfo])
  return bannerInfo
}

type UseApptBannerInfo = {
  appt: Appointment | null
  sessionType: SESSION_TYPES
  isClient: boolean
  timeZone: string
  title?: string
}
type BannerInfo = {
  startsIn: string
  startTime: moment.Moment
  endTime: moment.Moment
  joinText: string
  sessionTitle: string
  timeZone: string
}
