import React, { useMemo } from 'react'

import { cloneDeep, isEmpty, isUndefined } from 'lodash-es'
import moment from 'moment'
import pluralize from 'pluralize'

import {
  AssignmentResponse,
  AssignmentStatuses,
  Contentmetadata,
  FieldSchema,
  Response,
} from '@lyrahealth-inc/shared-app-logic'

import styles from './exerciseResponseTable.module.scss'
import ReportTable from '../reportTable/ReportTable'

const ExerciseResponseTable: React.FC<ExerciseResponseTableProps> = ({
  data,
  name,
  metaData,
  rowClickHandler,
  UIMetadata,
  userRole,
}) => {
  // For 'baseSleepDiary' the response data is cloned as filtering out viewable columns for the table mutates the data and
  // will prevent the fields hidden in the table from being in the modal form when a table row is clicked.
  const dataCopy = useMemo(() => {
    return name === 'baseSleepDiary' ? cloneDeep(data) : data
  }, [data, name])

  const drafts = useMemo(() => {
    return dataCopy
      .filter((response: Response) => response.status === 'draft')
      .sort((a: Response, b: Response) => {
        return moment(b.update_date).diff(moment(a.update_date))
      })
  }, [dataCopy])

  const submissions = useMemo(() => {
    return dataCopy
      .filter((response: Response) => response.status !== 'draft')
      .sort((a: Response, b: Response) => {
        return moment(b.submit_date).diff(moment(a.submit_date))
      })
  }, [dataCopy])
  const isClient = userRole === 'client'

  const renderTables = (entries: AssignmentResponse[], type: string) => {
    const completedEntries = entries.filter((entry) => entry.status === AssignmentStatuses.completed)
    const dataRecordKeys = Object.values(metaData?.schema?.properties || {})
      .filter((property: FieldSchema) => {
        return (
          !(property?.show === false) && (isUndefined(property?.hideTableColumn) || property?.hideTableColumn !== true)
        )
      })
      .map((property: FieldSchema) => property?.name)

    entries = isClient ? entries : completedEntries

    // This will mutate the assignmentResponse.response
    const dataRecords = entries.map((assignmentResponse: AssignmentResponse) => {
      const newResponse = {}
      // @ts-expect-error TS(2345): Argument of type '(key: string) => any' is not ass... Remove this comment to see the full error message
      dataRecordKeys.forEach((key: string) => (newResponse[key] = assignmentResponse.response[key]))
      const newAssignmentResponse = cloneDeep(assignmentResponse)
      newAssignmentResponse.response = newResponse
      return newAssignmentResponse
    })

    return (
      <div className={styles.container}>
        <div className={styles['filter-container']}>
          <h4>{type}</h4>
          <h4>{pluralize('Entry', entries.length, true)}</h4>
        </div>
        {dataRecords.length === 0 ? (
          <div style={{ textAlign: 'center' }}>No Data</div>
        ) : (
          <ReportTable
            metaData={metaData}
            data={dataRecords}
            rowClickHandler={rowClickHandler}
            destinationSelector='id'
            UIMetadata={UIMetadata}
          />
        )}
      </div>
    )
  }

  if ((!isClient && isEmpty(submissions)) || (isClient && isEmpty(data)))
    return (
      <div className={styles['no-data']}>
        Submitted entries will show up here{' '}
        <img
          src={require('../../../../ui-core-crossplatform/src/assets/Activity_Empty_Draft_State.png')}
          alt='empty state'
        />
      </div>
    )
  return (
    <div className={styles.container}>
      {isClient && drafts.length > 0 ? renderTables(drafts, 'Drafts') : null}
      {renderTables(submissions, 'Submitted')}
    </div>
  )
}

type Column = {
  accessor: string
  Cell: (props: any) => Element
  Header: string
  name: string
  width?: number
}

type ExerciseResponseTableProps = {
  data: AssignmentResponse[]
  name: string
  metaData?: Contentmetadata
  rowClickHandler: () => void
  UIMetadata: {
    report: {
      customColumns: Column[]
      fixedColumns: Column[]
    }
  }
  userRole: string
}

export default ExerciseResponseTable
