import React, { FunctionComponent, ReactElement } from 'react'
import { FormattedMessage } from 'react-intl'
import { View } from 'react-native'

import styled, { DefaultTheme, useTheme } from 'styled-components/native'

import { ChatBubbleText } from './ChatBubbleText'
import { tID } from '../../utils'

export type ChatBubbleProps = {
  body: ReactElement<View | typeof ChatBubbleText>
  borderColor?: string
  backgroundColor?: string
  messageType?: string
}

const ChatBubbleContainer = styled.View<{ backgroundColor: string; borderColor: string }>(
  ({ backgroundColor, borderColor }) => ({
    borderRadius: '20px',
    flexDirection: 'column',
    backgroundColor: `${backgroundColor}`,
    borderColor: `${borderColor}`,
    borderWidth: '1px',
  }),
)

const OutOfOffice = styled.View<{ theme: DefaultTheme }>(({ theme }) => {
  const componentColors = theme.colors.components.chatBubble.outOfOffice
  return {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: componentColors.background,
    padding: theme.spacing['16px'],
    color: componentColors.text,
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '20px',
  }
})

/**
 * Text bubble that is shown in the messenger for each message
 */
let ChatBubble: FunctionComponent<ChatBubbleProps> = ({ body, backgroundColor, borderColor, messageType = '' }) => {
  const { colors } = useTheme()
  const componentColors = colors.components.chatBubble

  return (
    <ChatBubbleContainer
      backgroundColor={backgroundColor || componentColors.background}
      borderColor={borderColor || componentColors.border}
      testID={tID('ChatBubble')}
    >
      {messageType === 'out_of_office' && (
        <OutOfOffice testID={tID('ChatBubble-outOfOffice')}>
          <FormattedMessage
            defaultMessage='Out of office'
            description='A phrase that says the provider is not working and not available'
          />
        </OutOfOffice>
      )}
      {body}
    </ChatBubbleContainer>
  )
}

ChatBubble = React.memo(ChatBubble)

export { ChatBubble }
