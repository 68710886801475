import React, { ReactNode, MouseEvent, KeyboardEvent } from 'react'
import classNames from 'classnames'
import styles from './genericListItem.module.scss'
import Dotdotdot from 'react-dotdotdot'
import LockIcon from '../../atoms/icons/LockIcon'
import { KEYS } from '../../constants/keyboardConstants'

const GenericListItem: React.FC<GenericListItemProps> = ({
  title,
  icon,
  subText,
  subText2,
  badgeContent,
  rightItems,
  isBold,
  isLocked,
  fullBorder,
  onClick,
  inactive,
  taller,
  ...props
}) => {
  const boldStyle = badgeContent ? true : isBold
  const onLinkCLick = (event: MouseEvent<HTMLDivElement>) => {
    if (onClick) {
      onClick(event)
    }
  }
  const onKeyDownEvent = (event: KeyboardEvent<HTMLDivElement>) => {
    if (onClick && event.key === KEYS.ENTER) {
      onClick(event)
    }
  }

  return (
    <li
      className={classNames(
        styles.container,
        { [styles.locked]: isLocked },
        { [styles.clickable]: onClick },
        { [styles['in-active']]: inactive },
        { [styles['full-border']]: fullBorder },
      )}
    >
      <div
        role={onClick ? 'button' : undefined}
        tabIndex={0}
        onKeyDown={onKeyDownEvent}
        onClick={onLinkCLick}
        className={classNames(
          styles['inner-container'],
          { [styles['partial-border']]: !fullBorder },
          { [styles.taller]: taller },
        )}
        {...props}
      >
        <div className={styles['left-container']}>
          {icon ? <div className={styles.icon}>{icon}</div> : null}
          <div className={styles['text-container']}>
            <div className={classNames(styles.title, { [styles.bold]: boldStyle })}>
              <Dotdotdot clamp={1}>{title}</Dotdotdot>
              {badgeContent ? (
                <div className={styles.badge} data-test-id='GenericListItem-badge'>
                  {badgeContent}
                </div>
              ) : null}
            </div>
            {subText ? (
              <div
                data-test-id='GenericListItem-subText'
                className={classNames(styles['sub-text'], { [styles.bold]: boldStyle })}
              >
                {subText}
              </div>
            ) : null}
            {subText2 ? (
              <div
                data-test-id='GenericListItem-subText2'
                className={classNames(styles['sub-text'], { [styles.bold]: boldStyle })}
              >
                {subText2}
              </div>
            ) : null}
          </div>
        </div>
        {isLocked ? (
          <LockIcon className={styles['lock-icon']} isFilled width={17} fillColor={styles.x_medium_gray} />
        ) : rightItems ? (
          <div className={styles['right-container']}>{rightItems}</div>
        ) : null}
      </div>
    </li>
  )
}

type GenericListItemProps = {
  title?: string
  subText?: string | ReactNode
  subText2?: string | ReactNode
  icon?: ReactNode
  badgeContent?: string | number | null
  itemClickHandler?: () => void
  rightItems?: ReactNode[]
  isLocked?: boolean
  fullBorder?: boolean
  onClick?: (event: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>) => void
  isBold?: boolean
  inactive?: boolean
  taller?: boolean
}

export default GenericListItem
