import React, { useMemo, useState } from 'react'
import { connect } from 'react-redux'

import classnames from 'classnames'
import { Map } from 'immutable'
import { cloneDeep, isEmpty } from 'lodash-es'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'

import { CustomerInfo, isValidDateOfBirth, overrideCompanyNames } from '@lyrahealth-inc/shared-app-logic'
import { BootstrapContainer, LoadingIndicator } from '@lyrahealth-inc/ui-core'
import { colors, toJS } from '@lyrahealth-inc/ui-core-crossplatform'

import styles from './eligibilityChecker.module.scss'
import EligibilityForm from './EligibilityForm'
import EligibilityResults from './EligibilityResults'
import { actions as mixpanelActions, mixpanelEvents } from '../../../mixpanel/mixpanelConstants'
import { track } from '../../../mixpanel/mixpanelTracking'
import { checkClientEligibility } from '../clients/data/clientsDataActions'
import { shouldCheckDependentEligibilityUsingDependentInfo } from '../common/utils/utils'
import { getRequestPaymentCustomers } from '../data/requestPayment/requestPaymentSelectors'

type eligibilityResponse = {
  message: string
  status: string
}

const DependentEligibilityInfo: React.FC<{ customer: CustomerInfo | undefined; isChildPatient: boolean }> = ({
  customer,
  isChildPatient,
}) => {
  if (shouldCheckDependentEligibilityUsingDependentInfo(isChildPatient, customer)) {
    return (
      <p className={classnames(styles['main-paragraph'], styles.bold)} style={{ color: colors.red4 }}>
        If your client is a dependent, use their information to check eligibility.
      </p>
    )
  }

  return (
    <p className={classnames(styles['main-paragraph'], styles.bold)}>
      If your client is a dependent, please make sure you are using the eligible member’s information when checking
      eligibility.
    </p>
  )
}

export const EligibilityChecker: React.FC<EligibilityCheckerProps> = ({
  customers,
  actions: { checkClientEligibility },
}) => {
  const [eligibilityResponse, setEligibilityResponse] = useState({})
  const [refetchingEligibility, setRefetchingEligibility] = useState(false)
  const [selectedCustomer, setSelectedCustomer] = useState<CustomerInfo | undefined>()
  const [isChildPatient, setIsChildPatient] = useState(false)

  const customersWithOverride = useMemo(
    () =>
      customers.map((customer) => {
        const newCustomer = cloneDeep(customer)
        if (newCustomer.value in overrideCompanyNames) {
          newCustomer.label = overrideCompanyNames[customer.value]
        }
        return newCustomer
      }),
    [customers],
  )
  return isEmpty(customers) ? (
    <div className={styles['loading-container']}>
      <LoadingIndicator size={45} />
    </div>
  ) : (
    <BootstrapContainer col='col-md-12'>
      <h1 className={styles.title}>Eligibility checker</h1>
      <div className={styles['form-container']}>
        <p className={styles['main-paragraph']}>
          This tool checks an individual’s eligibility for care. It will link to the appropriate company coverage, but
          you will have to confirm how many sessions the individual has left. Coverage status is liable to change, so
          the info is considered valid only through today.
        </p>
        <DependentEligibilityInfo customer={selectedCustomer} isChildPatient={isChildPatient} />
        <EligibilityForm
          customers={customersWithOverride?.map((customer) => customer.label)}
          onSubmit={({ values }: { values: { firstName: string; lastName: string; dob: string; company: string } }) => {
            setRefetchingEligibility(true)
            track({ event: mixpanelEvents.BUTTON_PRESS, action: mixpanelActions.SUBMIT_ELIGIBILITY_CHECKER_SEARCH })
            checkClientEligibility({
              first_name: values.firstName,
              last_name: values.lastName,
              date_of_birth: values.dob,
              company: customersWithOverride.find(({ label }) => label === values.company)?.value,
            }).then((res: eligibilityResponse) => {
              setEligibilityResponse(res)
              setRefetchingEligibility(false)
            })
          }}
          onFormChange={({ values }: { values: { company: string | undefined; dob: string | undefined } }) => {
            if (values.dob) {
              const isChild = isValidDateOfBirth(values.dob, { min: 0, max: 17 }).isValid as boolean
              if (isChild !== isChildPatient) {
                setIsChildPatient(isChild)
              }
            }

            const selected = customersWithOverride.find((c) => c.label === values.company?.[0])
            if (selected && selected?.label !== selectedCustomer?.label) {
              setSelectedCustomer(selected)
            }
          }}
        />
        {!isEmpty(eligibilityResponse) && (
          <>
            <hr className={styles['header-separator']} />
            <EligibilityResults result={eligibilityResponse} isLoading={refetchingEligibility} />
          </>
        )}
      </div>
    </BootstrapContainer>
  )
}

type EligibilityCheckerProps = {
  customers: CustomerInfo[]
  actions: {
    checkClientEligibility: ({
      first_name,
      last_name,
      date_of_birth,
      company,
    }: {
      first_name: string
      last_name: string
      date_of_birth: string
      company: string | undefined
    }) => Promise<any>
  }
}

const mapStateToProps = ($$state: Map<string, any>) => ({
  customers: getRequestPaymentCustomers($$state),
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  actions: bindActionCreators({ checkClientEligibility }, dispatch),
})

// @ts-expect-error TS(2345): Argument of type '(wrappedComponentProps: Eligibil... Remove this comment to see the full error message
export default connect(mapStateToProps, mapDispatchToProps)(toJS(EligibilityChecker))
