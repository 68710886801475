import React, { FunctionComponent, ReactChild, ReactNode, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { KeyboardAvoidingView, NativeScrollEvent, NativeSyntheticEvent, Platform, StyleSheet } from 'react-native'

import { differenceInMonths } from 'date-fns'
import styled from 'styled-components/native'

import { BlockedChatView, Message, MessageAttachmentData } from '../..'
import { LoadingIndicator } from '../../atoms'
import { Divider } from '../../atoms/divider/Divider'
import { Avatar, avatarAltTextGeneric } from '../../atoms/icons/Avatar'
import { NewMessageIndicator } from '../../atoms/newMessageIndicator/NewMessageIndicator'
import { TypingIndicator } from '../../atoms/typingIndicator/TypingIndicator'
import { EmptyMessagesView } from '../../molecules/emptyMessagesView/EmptyMessagesView'
import { ChatHeader, CloseStyles } from '../../organisms/chatHeader/ChatHeader'
import { ChatInput } from '../../organisms/chatInput/ChatInput'
import { Messages } from '../../organisms/messages/Messages'
import { Flex1View } from '../../templates/content/CommonViews'
import { ActivityReviewInfo } from '../../ui-models/assignments/Assignments'
import { tID } from '../../utils'

const IS_WEB = Platform.OS === 'web'
const SCROLL_THRESHOLD = 50

export type ChatProps = {
  displayName: string
  banner?: ReactChild | null
  chatHeaderTitle: string
  chatHeaderSubTitleMsg?: string
  liveSessionBadge: string
  onBack: () => void
  messages: Message[]
  onSendMessage: (text: string) => void
  onInputChange: (text?: string) => void
  avatarImage?: string
  receiverTyping: boolean
  receiverMsgable: boolean
  receiverBlocked?: boolean
  showChevron?: boolean
  showNewMsgIndicator?: boolean
  scrollInfo?: ChatScrollInfo
  onScroll?: (event: NativeSyntheticEvent<NativeScrollEvent>) => void
  onNewMsgPressed?: () => void
  unreadMsgCount?: number
  onChatBubblePressed?: (messageId: string, receiver: boolean, activityInfo?: ActivityReviewInfo) => void
  closeStyle?: CloseStyles
  chatHeaderCentered?: boolean
  inputValue?: string
  conversationDateCreated?: string
  isLoading?: boolean
  inputActions?: ReactNode[]
  inputAttachments?: ReactNode[]
  markMessagesAsUnread?: () => void
  showMenu?: boolean
  shouldInvert?: boolean
  showClose?: boolean
  onChevronPressed?: () => void
  onContactCareNavigatorPressed?: () => void
  showBlockedChatView?: boolean
  sendOnEnter?: boolean
  onAttachmentPressed?: (attachment: MessageAttachmentData) => void
  contactCarePhoneNumber?: string
}

export interface ChatScrollInfo {
  scrollToEnd: boolean
  animatedScroll: boolean
}

const TypingIndicatorContainer = styled(TypingIndicator)<{ receiverTyping: boolean }>(({ theme, receiverTyping }) => ({
  marginLeft: receiverTyping ? theme.spacing['16px'] : 0,
}))

const Container = styled(Flex1View)(({ theme }) => ({ backgroundColor: theme.colors.backgroundPrimary, flexShrink: 1 }))

const ReceiverTypingContainer = styled.View({ flexDirection: 'row' })

const NewMessageIndicatorContainer = styled.View<{ chatInputHeight: number }>(({ chatInputHeight }) => ({
  bottom: Platform.OS === 'web' ? '10px' : 24 + chatInputHeight,
  alignSelf: 'center',
  justifyContent: 'center',
  position: 'absolute',
}))

const AvatarContainer = styled.View(({ theme }) => ({
  marginRight: theme.spacing['16px'],
}))

const NoPreviousMessageContainer = styled.View(({ theme }) => ({
  backgroundColor: theme.colors.backgroundPrimary,
  color: `${theme.colors.textSecondary}`,
  flexDirection: 'column',
  flexGrow: 1,
  justifyContent: 'center',
  alignItems: 'center',
  padding: `${theme.spacing['12px']} ${theme.spacing['24px']}`,
}))

const LoadingContainer = styled(Flex1View)(({ theme }) => ({
  backgroundColor: theme.colors.backgroundPrimary,
}))

const ChatInnerFlexContainer = styled.View({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
})

const ChatMessagesContainer = styled(Flex1View)({
  overflow: 'hidden',
  flexGrow: 1,
  flexShrink: 1,
})

/**
 * This page is the thread conversation between a client and a provider
 * It consists of a header, a list of messages, and an input field for sending messages
 */
export const Chat: FunctionComponent<ChatProps> = ({
  displayName,
  banner,
  chatHeaderSubTitleMsg,
  liveSessionBadge,
  onBack,
  messages,
  onSendMessage,
  onInputChange,
  avatarImage,
  receiverTyping,
  receiverMsgable,
  showNewMsgIndicator,
  scrollInfo,
  onScroll,
  onNewMsgPressed,
  unreadMsgCount,
  onChatBubblePressed,
  chatHeaderTitle,
  chatHeaderCentered,
  closeStyle,
  showClose = true,
  showChevron = false,
  inputValue,
  conversationDateCreated,
  isLoading,
  inputActions,
  inputAttachments,
  markMessagesAsUnread,
  showMenu = false,
  shouldInvert = true,
  onChevronPressed,
  receiverBlocked = false,
  onContactCareNavigatorPressed,
  showBlockedChatView = false,
  sendOnEnter,
  onAttachmentPressed,
  contactCarePhoneNumber,
}) => {
  const [chatInputHeight, setChatInputHeight] = useState(60)
  const [scrollPosition, setScrollPosition] = useState<number>(0)
  const [chatMessagesCount, setChatMessagesCount] = useState<number>(messages.length)
  const [markedMessagesAsUnread, setMarkedMessagesAsUnread] = useState(false)

  useEffect(() => {
    if (Platform.OS === 'web') {
      setChatMessagesCount((prevState) => {
        if (messages?.length > prevState) {
          if (markedMessagesAsUnread && messages[0].authorType === 'patient') {
            setMarkedMessagesAsUnread(false)
          }
          return messages.length
        }
        return prevState
      })
    }
  }, [chatMessagesCount, markedMessagesAsUnread, messages, messages.length])

  useEffect(() => {
    if (
      Platform.OS === 'web' &&
      unreadMsgCount &&
      unreadMsgCount > 0 &&
      scrollPosition < SCROLL_THRESHOLD &&
      (markedMessagesAsUnread === false || !markMessagesAsUnread)
    ) {
      onNewMsgPressed && onNewMsgPressed()
    }
  }, [onNewMsgPressed, scrollPosition, unreadMsgCount, markedMessagesAsUnread, markMessagesAsUnread])

  const handleScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    const scrollYPosition = event?.nativeEvent?.contentOffset?.y
    setScrollPosition(scrollYPosition)
    onScroll && onScroll(event)
  }

  const send = (text: string) => {
    if (!receiverMsgable) return
    onSendMessage(text)
  }

  const getReceiverView = () => {
    return receiverTyping ? (
      <ReceiverTypingContainer>
        {avatarImage && (
          <AvatarContainer>
            <Avatar
              details={{ src: avatarImage, displayName }}
              accessibilityLabel={formatMessage(avatarAltTextGeneric, { name: displayName })}
            />
          </AvatarContainer>
        )}
        <TypingIndicatorContainer receiverTyping={receiverTyping} />
      </ReceiverTypingContainer>
    ) : null
  }

  const getNewMessageView = () => {
    return (IS_WEB &&
      scrollInfo &&
      showNewMsgIndicator &&
      unreadMsgCount &&
      scrollPosition &&
      scrollPosition > SCROLL_THRESHOLD) ||
      (!IS_WEB && scrollInfo && showNewMsgIndicator && unreadMsgCount) ? (
      <NewMessageIndicatorContainer chatInputHeight={chatInputHeight}>
        <NewMessageIndicator onPress={onNewMsgPressed} plural={unreadMsgCount !== 1} />
      </NewMessageIndicatorContainer>
    ) : null
  }

  const { formatMessage } = useIntl()
  const isConversationThirteenMonthsOld =
    conversationDateCreated && differenceInMonths(new Date(), new Date(conversationDateCreated)) > 13

  const emptyView = useMemo(
    () => <EmptyMessagesView avatarDetails={{ src: avatarImage || '', displayName }} displayName={displayName} />,
    [avatarImage, displayName],
  )
  if (isLoading) {
    return (
      <LoadingContainer>
        <LoadingIndicator size={45} />
      </LoadingContainer>
    )
  }

  const canSendMessages = !receiverBlocked && receiverMsgable

  const handleMarkMessagesAsUnread = () => {
    setMarkedMessagesAsUnread(true)
    markMessagesAsUnread && markMessagesAsUnread()
  }
  return (
    <Container testID={tID('Chat')}>
      <KeyboardAvoidingView
        behavior={Platform.select({ android: 'height', ios: 'padding' })}
        style={styles.keyboardAvoidingView}
      >
        <ChatInnerFlexContainer testID={tID('Chat-InnerFlexContainer')}>
          <ChatHeader
            titleMsg={chatHeaderTitle}
            subTitleMsg={chatHeaderSubTitleMsg}
            textBadgeMsg={liveSessionBadge}
            onClosePress={onBack}
            closeStyle={closeStyle}
            titleCentered={chatHeaderCentered}
            showChevron={showChevron}
            showMenu={messages.length > 0 && showMenu}
            markMessagesAsUnread={handleMarkMessagesAsUnread}
            showClose={showClose}
            onChevronPressed={onChevronPressed}
          />
          {banner}
          {isConversationThirteenMonthsOld && (
            <NoPreviousMessageContainer testID={tID('Chat-noPreviousMsgs')}>
              <FormattedMessage
                defaultMessage='Previous messages are not available. Messages are available for 13 months.'
                description='A notice that tells the user older messages are not displayed'
              />
            </NoPreviousMessageContainer>
          )}
          {!canSendMessages && showBlockedChatView ? (
            <BlockedChatView
              receiverBlocked={receiverBlocked}
              onContactCareNavigatorPressed={onContactCareNavigatorPressed}
              contactCarePhoneNumber={contactCarePhoneNumber}
            />
          ) : (
            <ChatMessagesContainer testID={tID('Chat-messages-container')}>
              <Messages
                messages={messages}
                onScroll={IS_WEB ? handleScroll : onScroll}
                scrollInfo={scrollInfo}
                listHeaderComponent={getReceiverView()}
                onChatBubblePressed={onChatBubblePressed}
                NewMessageView={getNewMessageView()}
                emptyMessagesView={emptyView}
                shouldInvert={shouldInvert}
                onAttachmentPressed={onAttachmentPressed}
              />
            </ChatMessagesContainer>
          )}

          <Divider />
          {canSendMessages && (
            <ChatInput
              onSendMessage={send}
              onInputChange={onInputChange}
              onInputHeightChange={setChatInputHeight}
              previousInputValue={inputValue}
              actions={inputActions}
              sendOnEnter={sendOnEnter}
              attachments={inputAttachments}
            />
          )}
        </ChatInnerFlexContainer>
      </KeyboardAvoidingView>
    </Container>
  )
}

const styles = StyleSheet.create({
  keyboardAvoidingView: {
    flex: 1,
  },
})
