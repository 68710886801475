/* eslint-disable formatjs/no-literal-string-in-jsx */
import React, { FunctionComponent } from 'react'

import styled, { useTheme } from 'styled-components/native'

import { InfoIcon, Tooltip } from '../../atoms'
import DataBoard from '../../molecules/dataBoard/DataBoard'
import { DataBoardItem, DataBoardItemTitlePosition } from '../../molecules/dataBoard/DataBoardItem'
import { PlatformEngagementYTDCardHeader } from '../../molecules/platformEngagementYTDCardHeader/PlatformEngagementYTDCardHeader'
import { ThemeType, tID } from '../../utils'

export interface PlatformEngagementYTDCardProps {
  onViewQuarterlyDetailsClicked: () => void
  assignmentRate: number | null
  feedbackRate: number | null
}

const Container = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  padding: theme.spacing['32px'],
  border: `1px solid ${theme.colors.borderDefault}`,
  borderRadius: '16px',
  gap: theme.spacing['24px'],
  backgroundColor: theme.colors.backgroundPrimary,
}))
const DataRow = styled.View(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.spacing['24px'],
}))
const AssignmentRateItem = styled.View({
  flexBasis: '50%',
  flexShrink: 1,
})
const FeedbackRateItem = styled.View({
  flexGrow: 1,
})

const SubDataBoardItem: FunctionComponent<{
  title: string
  label: string
  value: string
  tooltipText: string
  testID?: string
}> = ({ title, label, value, tooltipText, testID }) => {
  const { colors } = useTheme()

  return (
    <DataBoard backgroundColor={colors.backgroundSection}>
      <DataBoardItem
        testID={testID}
        style={{ margin: '24px' }}
        titleStyle={{ marginTop: '4px' }}
        labelStyle={{ marginTop: '4px', marginBottom: '0px' }}
        infoDataStyle={{ marginTop: '0px' }}
        value={value}
        tooltip={
          <Tooltip hoverEnabled content={tooltipText} placement='right'>
            <InfoIcon size={24} />
          </Tooltip>
        }
        title={title}
        titlePosition={DataBoardItemTitlePosition.BOTTOM}
        label={label}
      />
    </DataBoard>
  )
}

const convertToPercentage = (num: number | null): string => (num !== null ? Math.round(num * 1000) / 10 + '%' : '-')

export const PlatformEngagementYTDCard: FunctionComponent<PlatformEngagementYTDCardProps> = ({
  onViewQuarterlyDetailsClicked,
  assignmentRate,
  feedbackRate,
}) => {
  return (
    <Container>
      <PlatformEngagementYTDCardHeader onViewQuarterlyDetailsClicked={onViewQuarterlyDetailsClicked} />
      <DataRow>
        <AssignmentRateItem>
          <SubDataBoardItem
            testID={tID('PlatformEngagementYTDCardAssignmentRateItem')}
            title='Assignment rate'
            label='Goal 80%'
            value={convertToPercentage(assignmentRate)}
            tooltipText='Percentage of sessions with 1+ lesson, 1+ exercise, OR guide assigned - excludes final session of episode'
          />
        </AssignmentRateItem>
        <FeedbackRateItem>
          <SubDataBoardItem
            testID={tID('PlatformEngagementYTDCardFeedbackRateItem')}
            title='Feedback rate'
            label='Goal 25%'
            value={convertToPercentage(feedbackRate)}
            tooltipText='Percentage of client-submitted exercises with provider feedback. Comments sent in messages are not included'
          />
        </FeedbackRateItem>
      </DataRow>
    </Container>
  )
}
