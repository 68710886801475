import React, { useState, useEffect, ReactNode } from 'react'
import classNames from 'classnames'
import styles from './collapsibleSection.module.scss'
import { isEmpty } from 'lodash-es'
import AccordionIcon from '../../atoms/icons/AccordionIcon'
import ChevronIcon from '../../atoms/icons/ChevronIcon'

const CollapsibleSection: React.FC<CollapsibleSectionProps> = ({
  children,
  isOpen = false,
  noDataText,
  rightItems,
  subtext,
  title,
  iconOnLeft,
  listStyle,
  sectionHeadingLevel = '',
  customClass,
  titleCustomClass,
  expandedContentCustomClass,
  CustomIcon,
  ...props
}) => {
  const [isExpanded, setIsExpanded] = useState(isOpen)
  const [isPristine, setIsPristine] = useState(true)

  const ExpandIcon = CustomIcon || ChevronIcon

  // This is to set isPristine back to true when the "Icon on left of header" option
  // is selected (when viewing the CollapsibleSection in Storybook) so AccordionIcon doesn't
  // spin when the option is turned back on after being turned off.
  useEffect(() => {
    if (iconOnLeft) {
      setIsPristine(true)
    }
  }, [iconOnLeft])

  const renderTitle = () => {
    const validHeadingLevels = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']
    const sectionHeadingLevelTrimmedAndLowerCased = sectionHeadingLevel.trim().toLowerCase()
    const Title =
      sectionHeadingLevelTrimmedAndLowerCased && validHeadingLevels.includes(sectionHeadingLevelTrimmedAndLowerCased)
        ? (sectionHeadingLevelTrimmedAndLowerCased as keyof JSX.IntrinsicElements)
        : 'h3'

    return (
      <Title className={classNames(styles['no-margin'], titleCustomClass)} data-test-id='CollapsibleSection-title'>
        {title}
      </Title>
    )
  }

  return (
    <div
      className={classNames(styles['collapsible-section-container'], { [styles.list]: listStyle }, customClass)}
      data-test-id='CollapsibleSection-container'
      {...props}
    >
      <div
        className={classNames(
          styles['header-container'],
          isExpanded ? styles['bottom-border'] : styles['bottom-radius'],
        )}
        role='button'
        tabIndex={0}
        aria-expanded={isExpanded}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            setIsPristine(false)
            setIsExpanded(!isExpanded)
          }
        }}
        onClick={() => {
          setIsPristine(false)
          setIsExpanded(!isExpanded)
        }}
      >
        <div className={styles['inner-container']}>
          <div className={styles['left-container']}>
            {iconOnLeft && (
              <div className={styles.icon} data-test-id='CollapsibleSection-accordionIcon'>
                <AccordionIcon isOpen={isExpanded} isPristine={isPristine} fillColor='#000' />
              </div>
            )}
            <div className={styles['text-container']}>
              {renderTitle()}
              {subtext ? <div className={styles['sub-text']}>{subtext}</div> : null}
            </div>
          </div>
          <div className={styles['right-container']}>
            {rightItems && <div>{rightItems}</div>}
            {!iconOnLeft && (
              <div
                className={classNames(styles.icon, { [styles['space-between-right-items-and-icon']]: rightItems })}
                data-test-id='CollapsibleSection-accordionIcon'
              >
                <ExpandIcon
                  width={20}
                  direction={isExpanded ? 'up' : 'down'}
                  isFilled
                  fillColor={styles.x_gentle_notification}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {isExpanded && (
        <div
          data-test-id='CollapsibleSection-expandedContent'
          className={classNames(styles['expanded-contents-container'], expandedContentCustomClass)}
        >
          {isEmpty(children) ? (
            <div className={styles['empty-section']}>{noDataText || 'Nothing content'}</div>
          ) : (
            children
          )}
        </div>
      )}
    </div>
  )
}

type CollapsibleSectionProps = {
  isOpen?: boolean
  noDataText?: string
  rightItems?: string | ReactNode
  subtext?: string
  title?: string | ReactNode
  iconOnLeft?: boolean // Icon in header that indicates section is expanded vs. closed can be on left or right
  listStyle?: boolean // listStyle is the style used for Lyra Therapy
  sectionHeadingLevel?: string
  customClass?: string
  titleCustomClass?: string
  expandedContentCustomClass?: string
  CustomIcon?: React.ComponentType<any>
}

export default CollapsibleSection
