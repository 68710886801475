import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

import styled from 'styled-components/native'

import { Image } from '../image/Image'

type CareOptionParentingSupportIconProps = {
  size?: number
  backgroundFill?: string
}

const CareOptionParentingSupportPNG = styled(Image)<{ size: number }>(({ size }) => ({
  width: `${size}px`,
  height: `${size}px`,
}))

export const CareOptionParentingSupportIcon: FunctionComponent<CareOptionParentingSupportIconProps> = ({
  size = 64,
  backgroundFill,
}) => {
  return !backgroundFill ? (
    <CareOptionParentingSupportPNG size={size} source={require('./../../assets/careOptionParentingSupport.png')} />
  ) : (
    <Svg width={size} height={size} viewBox='0 0 56 56' fill='none'>
      <Path
        d='M28.1968 0.209473C24.3314 0.209473 20.5811 0.872338 17.0498 2.17926C8.64285 5.2955 2.73238 12.2041 0.833422 21.1335C-0.421486 26.9554 -0.261026 32.3402 1.32697 37.5878C3.3543 44.2895 8.33078 49.941 14.9805 53.0927C18.2815 54.6608 22.0739 55.556 26.2503 55.753C26.787 55.7773 27.3326 55.7906 27.8748 55.7906C31.2257 55.7906 34.5964 55.3314 37.8942 54.4262C38.1476 54.3631 38.3888 54.2978 38.6433 54.2225C41.6069 53.3549 44.2738 51.9827 46.5557 50.1491C49.0334 48.1693 51.0729 45.6595 52.6111 42.6938C52.9143 42.1095 53.2043 41.4942 53.5241 40.7583C55.2836 36.6726 56.1335 32.099 55.983 27.5264C55.9122 25.5046 55.6112 23.3556 55.091 21.1335C54.2345 17.5281 52.838 14.1374 51.0519 11.3222C50.5628 10.5663 50.1135 9.93114 49.6686 9.36012C46.4583 5.21915 41.4564 2.31316 35.2084 0.960868C32.9133 0.462889 30.5539 0.210579 28.1979 0.210579L28.1968 0.209473Z'
        fill={backgroundFill}
      />
      <Path
        d='M28.1331 37.2198C29.042 35.1209 28.9698 32.0333 27.6365 30.1192C26.7112 28.7903 24.901 27.7589 23.3195 27.5817C21.6679 27.3969 19.9102 27.5259 18.4643 28.3779C17.1934 29.1261 16.1204 30.2461 15.5374 31.6286C15.1601 32.5222 14.9938 33.5065 15.0452 34.48C15.1481 36.4258 16.0417 38.4229 17.6724 39.4883C19.6423 40.7745 22.3023 41.3192 24.5302 40.4956C25.9051 39.987 27.4823 38.7215 28.132 37.2209L28.1331 37.2198Z'
        fill='#4D9116'
      />
      <Path
        d='M29.8033 40.7188H40.9158C41.5035 40.7188 41.98 40.2423 41.98 39.6545V24.0358C41.98 23.448 41.5035 22.9716 40.9158 22.9716H29.8033C29.2155 22.9716 28.739 23.448 28.739 24.0358V39.6545C28.739 40.2423 29.2155 40.7188 29.8033 40.7188Z'
        fill='#3BA5B5'
      />
      <Path
        d='M12.6455 21.776L28.191 12.0679C28.8308 11.6687 29.6785 11.9465 29.9552 12.6487C31.9382 17.6723 30.4408 23.3215 26.1052 26.0285C21.7696 28.7367 16.035 27.6035 12.3928 23.6157C11.8843 23.059 12.0057 22.1753 12.6455 21.776Z'
        fill='#FFAF9B'
      />
    </Svg>
  )
}
