import React, { FunctionComponent } from 'react'
import { ImageSourcePropType } from 'react-native'

import styled from 'styled-components/native'

import restingCatImage from '../../assets/resting_cat.png'
import { tID } from '../../utils'
import { Image } from '../image/Image'

const ImageContainer = styled(Image)<{ $height: string; $width: string }>(({ $height, $width }) => ({
  width: $width,
  height: $height,
}))

/**
 * Illustration to show when no results
 */
export const RestingCatIllustration: FunctionComponent<RestingCatProps> = ({ height = 98, width = 120 }) => {
  const heightInPixels = `${height}px`
  const widthInPixels = `${width}px`
  return (
    <ImageContainer
      $height={heightInPixels}
      $width={widthInPixels}
      testID={`${tID('RestingCatIllustration')}`}
      source={restingCatImage as ImageSourcePropType}
    />
  )
}

type RestingCatProps = {
  height?: number
  width?: number
}
