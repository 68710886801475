import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { tID } from '../../utils'

export const EndCallIcon: FunctionComponent<EndCallIconProps> = ({ fillColor }) => {
  const { colors } = useTheme()

  return (
    <Svg width={24} height={9} fill='none' testID={tID('EndCallIcon')}>
      <Path
        d='M12 2c-1.6 0-3.15.25-4.6.72v3.1c0 .39-.23.74-.56.9-.98.49-1.87 1.12-2.66 1.85-.18.18-.43.28-.7.28-.28 0-.53-.11-.71-.29L.29 6.08a.956.956 0 0 1-.29-.7c0-.28.11-.53.29-.71C3.34 1.78 7.46 0 12 0c4.54 0 8.66 1.78 11.71 4.67.18.18.29.43.29.71 0 .28-.11.53-.29.71l-2.48 2.48c-.18.18-.43.29-.71.29-.27 0-.52-.11-.7-.28a11.27 11.27 0 0 0-2.67-1.85.996.996 0 0 1-.56-.9v-3.1C15.15 2.25 13.6 2 12 2Z'
        fill={fillColor || colors.iconInverse}
      />
    </Svg>
  )
}

type EndCallIconProps = {
  fillColor?: string
}
