import React, { FunctionComponent } from 'react'
import { Path, Svg } from 'react-native-svg'

import { PrimaryNeedsWords } from '@lyrahealth-inc/shared-app-logic'

import { tID } from '../../utils'

type SocialProblemsIllustrationProps = {
  size?: string | number
  selected?: string
}

export const SocialProblemsIllustration: FunctionComponent<SocialProblemsIllustrationProps> = ({
  selected = false,
  size,
}) => (
  <Svg
    width={size}
    height={size}
    viewBox='0 0 48 48'
    fill='none'
    testID={tID(`${PrimaryNeedsWords.SCHOOL}${selected ? '-Selected-' : '-'}Illustration`)}
  >
    <Path
      d='M47.6641 16.0414L43.1261 20.8259L31.2338 33.3647L30.698 29.0785L26.0547 29.1285L28.1978 23.7208L26.0547 21.3992L35.5057 12.8697L40.6991 8.18347C40.6991 8.18347 43.0208 7.11193 46.0568 10.148C49.0928 13.184 47.6641 16.0414 47.6641 16.0414Z'
      fill={selected ? '#85CCD5' : '#FFC03C'}
    />
    <Path
      d='M24.4473 26.2207L20.6969 26.0421L22.1256 31.3998L18.1948 33.3643L8.78667 29.6354L8.73131 29.6139L3.00749 23.2543L0.208977 20.1451C-0.244643 19.6414 0.0857495 18.836 0.762607 18.7949L5.11485 18.5235L12.1156 18.0859H12.1477L25.1616 23.899L24.4473 26.2207Z'
      fill={selected ? '#85CCD5' : '#FFC03C'}
    />
    <Path
      d='M47.6643 16.0414L43.1263 20.8259C40.676 18.4149 37.0239 14.5056 35.5059 12.8697L40.6993 8.18347C40.6993 8.18347 43.0209 7.11193 46.057 10.148C49.093 13.184 47.6643 16.0414 47.6643 16.0414Z'
      fill={selected ? '#3BA5B5' : '#F69170'}
    />
    <Path
      d='M12.1245 21.9345C11.5887 23.3633 9.98144 22.8275 9.08849 24.542C8.19553 26.2546 9.08849 26.5779 9.08849 28.3638C9.08849 28.9335 8.96169 29.3407 8.78667 29.6354L8.73131 29.6139L3.00749 23.2543L0.208977 20.1451C-0.244643 19.6414 0.0857495 18.836 0.762607 18.7949L5.11485 18.5235L12.1156 18.0859C11.8406 19.2307 12.521 20.8773 12.1245 21.9345Z'
      fill={selected ? '#FBFAF9' : '#EDE6DE'}
    />
    <Path
      d='M5.11485 18.5234C4.44157 20.0075 3.63255 21.8095 3.00749 23.2543L0.208977 20.145C-0.244643 19.6414 0.0857495 18.836 0.762607 18.7949L5.11485 18.5234Z'
      fill={selected ? '#3BA5B5' : '#3BA5B5'}
    />
    <Path
      d='M20.3398 39.2581C20.5184 38.9009 23.1973 33.9004 23.3759 33.9004C23.5545 33.9004 27.1263 38.3651 27.1263 38.3651L20.3398 39.2581Z'
      fill={selected ? '#85CCD5' : '#FFC03C'}
    />
  </Svg>
)
