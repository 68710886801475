import React from 'react'
import { ViewStyle } from 'react-native'
import { Path, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { tID } from '../../utils'

/**
 * Triangular warning icon used to display errors
 */
export const WarningIcon: React.FC<WarningIconProps> = ({ size = 24, fillColor, style, isFilled = true }) => {
  const { colors } = useTheme()
  const { warningIcon } = colors.icons

  const color = fillColor || warningIcon.fill
  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' testID={tID('WarningIcon')} style={style}>
      {isFilled ? (
        <Path
          fill={color}
          d='M2.51972711,24 L21.4802729,24 C23.4191335,24 24.6277738,21.7415846 23.6583436,19.9429663 L14.1780707,2.34896369 C13.2086404,0.550345436 10.7913596,0.550345436 9.82192934,2.34896369 L0.341656447,19.9429663 C-0.627773848,21.7415846 0.58086652,24 2.51972711,24 Z M12.5,14 C11.675,14 11,13.4375 11,12.75 L11,10.25 C11,9.5625 11.675,9 12.5,9 C13.325,9 14,9.5625 14,10.25 L14,12.75 C14,13.4375 13.325,14 12.5,14 Z M14,20 L11,20 L11,17 L14,17 L14,20 Z'
        />
      ) : (
        <>
          <Path
            fill={color}
            fillRule='evenodd'
            d='M10.787 3.817a2.48 2.48 0 0 1 3.332.874l.002.003 7.26 12.082a2.48 2.48 0 0 1-2.119 3.724H4.74a2.48 2.48 0 0 1-2.119-3.723l.006-.011L9.88 4.694 8.166 7.88 9.882 4.69a2.48 2.48 0 0 1 .905-.874Zm.404 1.68-.001.002L3.942 17.56a.926.926 0 0 0 .341 1.264.954.954 0 0 0 .469.13h14.497a.954.954 0 0 0 .81-.474.927.927 0 0 0 0-.92L12.81 5.5l-.001-.002a.94.94 0 0 0-.81-.452.954.954 0 0 0-.81.452Z'
            clipRule='evenodd'
          />
          <Path
            fill={color}
            fillRule='evenodd'
            d='M12 14.226a.91.91 0 0 1-.91-.911v-3.238a.91.91 0 1 1 1.821 0v3.238a.91.91 0 0 1-.91.91ZM12.911 16.553a.91.91 0 0 1-.91.91h-.009a.91.91 0 0 1 0-1.82H12a.91.91 0 0 1 .911.91Z'
            clipRule='evenodd'
          />
        </>
      )}
    </Svg>
  )
}

type WarningIconProps = {
  size: number
  fillColor?: string
  style?: ViewStyle
  isFilled?: boolean
}
