import React from 'react'
import colors from '../../../styles/_0colors.scss'
import { Icon } from './types'

const NetworkIssueIcon: React.FC<NetworkIssueIconProps> = ({
  width = 24,
  fillColor = colors.x_light_gray_10,
  isFilled = false,
  ...props
}) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} viewBox='0 0 24 24' {...props}>
      {isFilled ? (
        <g fill={fillColor} transform='translate(2.500000, 3.000000)'>
          <path d='M12.25,5.84894136 L12.25,3.27794747 C14.583197,3.77577463 16.8042152,4.93097702 18.6167929,6.74355463 C19.1049482,7.23170999 19.1049482,8.02316621 18.6167929,8.51132158 C18.1286375,8.99947694 17.3371813,8.99947694 16.8490259,8.51132158 C15.5268943,7.18918998 13.9346924,6.30172991 12.25,5.84894136 Z M6.75,5.88618635 C5.1152807,6.34948933 3.57266979,7.22453441 2.28588263,8.51132158 C1.79772726,8.99947694 1.00627104,8.99947694 0.518115673,8.51132158 C0.0299603078,8.02316621 0.0299603078,7.23170999 0.518115673,6.74355463 C2.29572509,4.96594521 4.46616787,3.82059155 6.75,3.30749366 L6.75,5.88618635 Z M12.25,11.8141446 L12.25,9.00412156 C13.1523473,9.36015583 13.9980596,9.90299595 14.7277056,10.6326419 C15.2158609,11.1207973 15.2158609,11.9122535 14.7277056,12.4004089 C14.2395502,12.8885642 13.448094,12.8885642 12.9599386,12.4004089 C12.7382981,12.1787684 12.5003672,11.983347 12.25,11.8141446 Z M6.75,11.9087469 C6.54881381,12.0550594 6.35643207,12.2189467 6.17496992,12.4004089 C5.68681456,12.8885642 4.89535833,12.8885642 4.40720297,12.4004089 C3.9190476,11.9122535 3.9190476,11.1207973 4.40720297,10.6326419 C5.10036973,9.93947516 5.89828723,9.41490219 6.75,9.05892299 L6.75,11.9087469 Z' />
          <path d='M10.75,11.9829665 L10.75,2 C10.75,1.30964406 10.1903559,0.75 9.5,0.75 C8.80964406,0.75 8.25,1.30964406 8.25,2 L8.25,11.9829665 C8.25,12.6733224 8.80964406,13.2329665 9.5,13.2329665 C10.1903559,13.2329665 10.75,12.6733224 10.75,11.9829665 Z' />
          <path d='M9.5,14.5 C10.328,14.5 11,15.172 11,16 C11,16.828 10.328,17.5 9.5,17.5 C8.672,17.5 8,16.828 8,16 C8,15.172 8.672,14.5 9.5,14.5' />
        </g>
      ) : (
        <g fill={fillColor} transform='translate(2.500000, 3.000000)'>
          <path d='M11.75,5.47222276 L11.75,3.43537887 C14.2003058,3.8659444 16.5472801,5.02759521 18.4400162,6.92033132 C18.8305405,7.31085561 18.8305405,7.94402059 18.4400162,8.33454488 C18.0494919,8.72506918 17.4163269,8.72506918 17.0258026,8.33454488 C15.5245536,6.83329593 13.6833545,5.87918855 11.75,5.47222276 Z M6.75,5.62654192 C5.05024061,6.09591672 3.44506644,6.99858438 2.10910593,8.33454488 C1.71858164,8.72506918 1.08541666,8.72506918 0.694892368,8.33454488 C0.304368076,7.94402059 0.304368076,7.31085561 0.694892368,6.92033132 C2.42349115,5.19173254 4.5309614,4.07291816 6.75,3.5638882 L6.75,5.62654192 Z M11.75,11.2399126 L11.75,9.09007909 C12.7741842,9.42265763 13.7372811,9.99577081 14.5509289,10.8094186 C14.9414532,11.1999429 14.9414532,11.8331079 14.5509289,12.2236322 C14.1604046,12.6141565 13.5272396,12.6141565 13.1367153,12.2236322 C12.7212401,11.808157 12.2513567,11.4802504 11.75,11.2399126 Z M6.75,11.6035286 C6.48480006,11.7823032 6.23282098,11.9890044 5.99819323,12.2236322 C5.60766893,12.6141565 4.97450396,12.6141565 4.58397966,12.2236322 C4.19345537,11.8331079 4.19345537,11.1999429 4.58397966,10.8094186 C5.22715063,10.1662477 5.96370573,9.6733762 6.75,9.33080425 L6.75,11.6035286 Z' />
          <path d='M10.25,11.9829665 L10.25,2 C10.25,1.30964406 9.80228475,0.75 9.25,0.75 C8.69771525,0.75 8.25,1.30964406 8.25,2 L8.25,11.9829665 C8.25,12.6733224 8.69771525,13.2329665 9.25,13.2329665 C9.80228475,13.2329665 10.25,12.6733224 10.25,11.9829665 Z' />
          <path d='M9.25,14.5 C9.94,14.5 10.5,15.06 10.5,15.75 C10.5,16.44 9.94,17 9.25,17 C8.56,17 8,16.44 8,15.75 C8,15.06 8.56,14.5 9.25,14.5' />
        </g>
      )}
    </svg>
  )
}

type NetworkIssueIconProps = Icon & { className: string }

export default NetworkIssueIcon
