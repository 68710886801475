import { getPaymentsBalancesData } from '../../../common/http/data/payments'
import {
  GET_PAYMENTS_OF_TYPE,
  GET_PAYMENTS_BALANCES,
  CLEAR_UPDATED_CHARGES,
  CLEAR_PAYMENTS_LIST_OF_TYPE,
} from '../../../common/constants/reduxConstants'

export const clearUpdatedCharges = (data: any) => {
  return { type: CLEAR_UPDATED_CHARGES, data }
}
export const clearPaymentsListOfType = (status: any) => {
  return { type: CLEAR_PAYMENTS_LIST_OF_TYPE, status }
}

export const getPayments = ({
  status,
  page,
  limit,
  providerId,
  practiceId,
  isHealthPlan,
  patientId,
  eligibilityCode,
  sessionType,
}: any) => ({
  action: { type: GET_PAYMENTS_OF_TYPE, page },
  request: {
    method: 'get',
    url: '/v1/transfers',
    // TODO: Remove those fallbacks to `undefined` by making sure valid values (or `undefined`) are passed for these parameters.
    params: {
      status: status === 'all' || !status ? null : status,
      page: page || 0,
      limit: limit || 100,
      provider_id: providerId || undefined,
      practice_id: practiceId || undefined,
      is_health_plan: isHealthPlan || undefined,
      patient_id: patientId || undefined,
      eligibility_code: eligibilityCode || undefined,
      session_type: sessionType || undefined,
    },
  },
})

export const getPaymentsBalances = () => {
  const request = getPaymentsBalancesData()

  return {
    type: GET_PAYMENTS_BALANCES,
    payload: request,
  }
}
