import React from 'react'
import styles from './lyraCode.module.scss'
import { PrimaryButton } from '@lyrahealth-inc/ui-core'
import { LWUrl } from '../../constants/appConstants'

const LyraCodeExplanationModalContent: React.FC<LyraCodeExplanationModalContentProps> = ({ closeModal }) => {
  return (
    <div className={styles.container}>
      <h3 className={styles['modal-title']}>What is this?</h3>
      <p className={styles['code-description']}>
        Some employer partners require a Lyra code with payment to confirm the client has registered. If your client
        doesn’t have one, ask them to register at <a href={LWUrl('care')}>care.lyrahealth.com</a> and create an account.{' '}
        <a
          target='_blank'
          href='https://provider-support.lyrahealth.com/hc/en-us/sections/360007845634-Understanding-and-using-Lyra-codes-for-eligibility-and-billing'
          rel='noreferrer'
        >
          Learn more
        </a>
        .
      </p>
      <div className={styles['list-title']}>Where can I find the client’s Lyra code?</div>
      <ul className={styles['code-locations-list']}>
        <li>Check your email - when your client requested care, Lyra sent an email with their Lyra code.</li>
        <li>
          Ask the client to check their email - every client receives a “Welcome to Lyra” email with their Lyra code
          when they register.
        </li>
        <li>
          Ask the client to check their account - clients can login to <a href={LWUrl('care')}>care.lyrahealth.com</a>,
          click the top-right menu, select Profile, and scroll to the Lyra code section.
        </li>
      </ul>
      <PrimaryButton onClick={closeModal}>Okay</PrimaryButton>
    </div>
  )
}

type LyraCodeExplanationModalContentProps = {
  closeModal: Function
}

export default LyraCodeExplanationModalContent
