import React from 'react'

import styled, { useTheme } from 'styled-components/native'

import { ZoomSelfViewInner } from './ZoomSelfViewInner'
import { VideoIcon } from '../../atoms'
import { useZoom } from '../../hooks/useZoom'
import { Flex1View } from '../../templates'
import { ThemeType, tID } from '../../utils'
import { ZoomSelfPreview } from '../zoomSelfPreview/ZoomSelfPreview'

export type ZoomSelfViewProps = {
  isFullscreen?: boolean
  landscape?: boolean
  isFocused?: boolean
}

const EmptyView = styled(Flex1View)(({ theme }) => ({
  backgroundColor: theme.colors.components.zoomSelfView.background,
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  alignSelf: 'stretch',
}))

export const ZoomSelfView: React.FC<ZoomSelfViewProps> = ({ isFullscreen, landscape, isFocused }) => {
  const { colors } = useTheme() as ThemeType
  const { videoOff, sessionStarted } = useZoom()
  return (
    <>
      {videoOff && (
        <EmptyView testID={tID('ZoomSelfView-empty')}>
          <VideoIcon size={isFullscreen ? 80 : 24} isFilled isMuted fillColor={colors.components.zoomSelfView.text} />
        </EmptyView>
      )}
      {!sessionStarted && <ZoomSelfPreview isFocused={isFocused} />}
      {sessionStarted && <ZoomSelfViewInner landscape={landscape} />}
    </>
  )
}
