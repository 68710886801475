import React, { FunctionComponent, useContext, useEffect, useState } from 'react'
import { FieldRenderProps } from 'react-final-form'
import { useIntl } from 'react-intl'
import { ViewStyle } from 'react-native'
import ReactNativeCountryFlag from 'react-native-country-flag'

import { CountryCode } from 'libphonenumber-js'
import { isNil, noop } from 'lodash-es'
import styled from 'styled-components/native'

import { CountryValues, useLocalizedCountryList } from '@lyrahealth-inc/shared-app-logic'

import { BodyText } from '../../atoms'
import { Divider } from '../../atoms/divider/Divider'
import { TYPE_AHEAD_Z_INDEX, TypeAhead } from '../../atoms/formElements/typeAhead/TypeAhead'
import { TypeAheadCustomOptionsConfig } from '../../atoms/formElements/typeAhead/TypeAheadCustomOptionsConfig'
import { AppContext } from '../../context'
import { tID } from '../../utils'
import { ThemeType } from '../../utils/themes/ThemeProvider'

export interface CountrySelectorProps {
  userCountryIsoCode?: CountryCode
  userCountryName?: string
  customerCountryList: CountryValues[]
  onCountrySelected?: (country: CountryCode) => void
  placeholder?: string
  label?: string
  error?: string
  onBlur?: () => void
  baseInputStyle?: ViewStyle
  inputContainerStyle?: ViewStyle
  dropdownContainerStyle?: ViewStyle
}

const CountrySelectorContainer = styled.View<{ theme: ThemeType }>({
  marginRight: '0px',
  zIndex: TYPE_AHEAD_Z_INDEX,
})

const CountryItemContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: 'column',
  width: '100%',
  paddingRight: theme.breakpoints.isMobileSized ? 0 : theme.spacing['20px'],
}))

const CountryItem = styled.View({
  flexDirection: 'row',
  alignItems: 'center',
})

const CountryFlagContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginRight: theme.spacing['12px'],
}))

export const CountrySelector: FunctionComponent<CountrySelectorProps> = ({
  userCountryIsoCode,
  userCountryName,
  customerCountryList,
  onCountrySelected,
  placeholder,
  label,
  error,
  onBlur = noop,
  baseInputStyle,
  inputContainerStyle,
  dropdownContainerStyle,
}) => {
  const supportedCountryList = useLocalizedCountryList(
    isNil(customerCountryList) ? [] : customerCountryList.filter((country: CountryValues) => country.countryIsoCode),
  )
  const selectedCountryObject = supportedCountryList.find((country) => country.countryIsoCode === userCountryIsoCode)

  const { detectedCountryIso } = useContext(AppContext)
  const [selectedCountry, setSelectedCountry] = useState(userCountryIsoCode)
  const [typeAheadValue, setTypeAheadValue] = useState<string>(selectedCountryObject?.localizedCountryName || '')
  const [initialScrollIndex, setInitialScrollIndex] = useState<number | undefined>(undefined)
  const [focused, setFocused] = useState(false)
  const { formatMessage } = useIntl()

  useEffect(() => {
    const countryIso = selectedCountry || detectedCountryIso
    if (countryIso) {
      const index = supportedCountryList.findIndex((country) => country.countryIsoCode === countryIso)
      setInitialScrollIndex(index >= 0 ? index : undefined)
    }
  }, [detectedCountryIso, selectedCountry, supportedCountryList])

  const onFocus = () => setFocused(true)
  const handleOnBlur = () => {
    setFocused(false)
    onBlur()
  }
  const onChange = (countryName: string) => {
    const country = supportedCountryList.find((c) => c.localizedCountryName === countryName)
    setTypeAheadValue(country?.localizedCountryName ?? countryName)
    const countryCode = country?.countryIsoCode
    setSelectedCountry(countryCode)
    if (onCountrySelected && countryCode) {
      onCountrySelected(countryCode)
    }
  }

  const countryMenuOptionRenderer = (option: CountryValues, displayText: JSX.Element) => {
    // Shows dividers on detected country only when no country has been explicitly selected by user (e.g. registration)
    const showDivider = !userCountryIsoCode && detectedCountryIso === option.countryIsoCode
    return (
      <CountryItemContainer>
        {showDivider && <Divider height={1} style={{ marginBottom: 16 }} />}
        <CountryItem testID={tID(`countrySelector-menuItem-${option.countryIsoCode}`)}>
          <CountryFlagContainer>
            <ReactNativeCountryFlag isoCode={option.countryIsoCode} size={18} />
          </CountryFlagContainer>
          {displayText}
        </CountryItem>
        {showDivider && <Divider height={1} style={{ marginTop: 16 }} />}
      </CountryItemContainer>
    )
  }

  const countryInputLabelRenderer = (option: CountryValues & { localizedCountryName: string }) => {
    return (
      <CountryItem testID={tID('countrySelector-inputLabel')}>
        <CountryFlagContainer>
          <ReactNativeCountryFlag isoCode={option.countryIsoCode} size={18} />
        </CountryFlagContainer>
        <BodyText text={option.localizedCountryName} />
      </CountryItem>
    )
  }

  return (
    <CountrySelectorContainer>
      <TypeAhead
        label={label}
        inputName={'countrySelector-input'}
        customOptionsConfig={
          new TypeAheadCustomOptionsConfig(
            supportedCountryList,
            countryMenuOptionRenderer,
            countryInputLabelRenderer,
            'localizedCountryName',
          )
        }
        placeholder={
          placeholder
            ? placeholder
            : formatMessage({
                defaultMessage: 'Select a country or territory',
                description:
                  'Placeholder of a search field that filters a list of countries or territories for ones that match the search query',
              })
        }
        onChange={onChange}
        value={typeAheadValue}
        onFocus={onFocus}
        onBlur={handleOnBlur}
        isFocused={focused}
        multiSelect={false}
        accessibilityLabel={formatMessage({
          defaultMessage: 'Select a country or territory',
          description:
            'Label of a search field that filters a list of countries or territories for ones that match the search query',
        })}
        fixedDropdownMenuPosition
        error={error}
        baseInputStyle={baseInputStyle}
        inputContainerStyle={inputContainerStyle}
        initialScrollIndex={initialScrollIndex}
        dropdownContainerStyle={dropdownContainerStyle}
      />
    </CountrySelectorContainer>
  )
}

export const CountrySelectorRFF: FunctionComponent<FieldRenderProps<string>> = ({
  input: { onChange, onBlur },
  meta: { touched, error },
  label,
  customerCountryList,
  userCountryIsoCode,
  userCountryName,
  baseInputStyle,
  inputContainerStyle,
  dropdownContainerStyle,
}) => {
  return (
    <CountrySelector
      onCountrySelected={onChange}
      label={label}
      error={touched && error}
      customerCountryList={customerCountryList}
      userCountryIsoCode={userCountryIsoCode}
      userCountryName={userCountryName}
      onBlur={onBlur}
      baseInputStyle={baseInputStyle}
      inputContainerStyle={inputContainerStyle}
      dropdownContainerStyle={dropdownContainerStyle}
    />
  )
}
