import React, { FunctionComponent } from 'react'

import styled from 'styled-components/native'

import { tID } from '../../utils'
import { Image } from '../image/Image'

const ImageContainer = styled(Image)<{ size: string }>(({ size }) => ({
  width: size,
  height: size,
}))

export const GetPersonalizedGuidanceIllustrationIcon: FunctionComponent<
  GetPersonalizedGuidanceIllustrationIconProps
> = ({ size = 75 }) => {
  const sizeInPixels = `${size}px`
  return (
    <ImageContainer
      size={sizeInPixels}
      testID={tID('GetPersonalizedGuidanceIllustrationIcon')}
      source={require('../../assets/get_personalized_guidance_illustration_icon.png')}
    />
  )
}

type GetPersonalizedGuidanceIllustrationIconProps = {
  size?: number
}
