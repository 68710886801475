/********************************************************************************
 This is a style wrapper that expands to fill the remaining space not taken up
 by the header or footer. Layouts should be children of this component.
 ********************************************************************************/

import React from 'react'

import styles from './contentLayout.module.scss'

type Props = {
  children: $TSFixMe
}

const ContentLayout = ({ children, ...props }: Props) => {
  return (
    <div className={styles['flex-grow']} {...props}>
      {children}
    </div>
  )
}

export default ContentLayout
