import React, { FunctionComponent, ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { View } from 'react-native'

import styled, { useTheme } from 'styled-components/native'

import { ChevronIcon, ChevronIconDirection } from '../../atoms'
import { BodyText } from '../../atoms/bodyText/BodyText'
import { ChatBubble } from '../../atoms/chatBubble/ChatBubble'
import { ChatBubbleText } from '../../atoms/chatBubble/ChatBubbleText'
import { Divider } from '../../atoms/divider/Divider'
import { Subhead, Size as SubheadSize } from '../../atoms/subhead/Subhead'
import { BodyTextSize } from '../../styles'
import { ThemeType, tID } from '../../utils'

export type ActivityBubbleProps = {
  title?: string
  submitDate?: string
  activityText?: string
  fontColor?: string
  backgroundColor?: string
  icon?: ReactElement
}

const TitleContainer = styled.View(({ theme }) => ({
  alignItems: 'center',
  flexDirection: 'row',
  paddingRight: theme.spacing['16px'],
}))

const TitleHeading = styled.View({
  paddingTop: '0px',
})

const AssignmentsIconContainer = styled.View<{ hasDate?: boolean }>`
  padding: ${({ hasDate }) => `${hasDate ? 14 : 8}`}px;
  padding-left: 16px;
  padding-right: 12px;
`

const SubmitDateContainer = styled(BodyText)<{ theme: ThemeType }>(({ theme }) => ({
  color: theme.colors.textSecondary,
}))

const HeadingAndChevronContainer = styled.View({
  flexDirection: 'row',
  alignItems: 'center',
  flexGrow: 1,
  justifyContent: 'space-between',
})

const HeadingContainer = styled.View({
  display: 'flex',
})

/**
 * Displays an activity in a chat bubble within the messenger icon, submitted date, and message body
 */
export const ActivityBubble: FunctionComponent<ActivityBubbleProps> = ({
  title,
  submitDate,
  activityText,
  fontColor,
  backgroundColor,
  icon,
}) => {
  const intl = useIntl()
  const { colors } = useTheme()
  const ActivityMessageBody = () => (
    <View>
      <TitleContainer testID={tID('ActivityBubble-titleContainer')}>
        <AssignmentsIconContainer hasDate={Boolean(submitDate)}>{icon}</AssignmentsIconContainer>
        <HeadingAndChevronContainer testID={tID('ActivityBubble-headingAndChevronContainer')}>
          <HeadingContainer>
            {Boolean(title) && (
              <TitleHeading testID={tID('ActivityBubble-titleHeading')}>
                <Subhead size={SubheadSize.SMALL} text={title} color={fontColor || colors.textPrimary} />
              </TitleHeading>
            )}
            {Boolean(submitDate) && (
              <SubmitDateContainer
                size={BodyTextSize.SMALL}
                testID={tID('ActivityMessageBody-submitDate')}
                color={fontColor || colors.textPrimary}
              >
                {intl.formatDate(submitDate, { day: '2-digit', month: '2-digit', year: 'numeric' })}
              </SubmitDateContainer>
            )}
          </HeadingContainer>
          <ChevronIcon
            size={18}
            fillColor={colors.iconDefault}
            direction={ChevronIconDirection.RIGHT}
            testID={tID('ChatHeader-chevronIcon')}
          />
        </HeadingAndChevronContainer>
      </TitleContainer>
      <Divider />
      {Boolean(activityText) && (
        <ChatBubbleText text={activityText ?? ''} fontColor={fontColor || colors.textPrimary} />
      )}
    </View>
  )

  return <ChatBubble body={<ActivityMessageBody />} backgroundColor={backgroundColor || colors.backgroundPrimary} />
}
