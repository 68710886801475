import React, { FunctionComponent } from 'react'

import styled from 'styled-components/native'

import { Image } from '../image/Image'

const ModalityQuestionnaireIllustrationPNG = styled(Image)({
  width: '136px',
  height: '136px',
})

export const ModalityQuestionnaireDrivingIllustration: FunctionComponent = () => {
  return (
    <ModalityQuestionnaireIllustrationPNG
      source={require('./../../assets/modality-questionnaire-driving-illustration.png')}
    />
  )
}
