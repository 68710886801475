import React from 'react'
import colors from '../../../styles/_0colors.scss'
import { Icon } from './types'

const ExternalLinkIcon: React.FC<ExternalLinkIconProps> = ({ width = 15, height = 14, fillColor = colors.x_brand }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.2509 0.000854492H7.24045C6.83033 0.000854492 6.49092 0.340267 6.49092 0.750389C6.49092 1.16051 6.83104 1.50063 7.24116 1.50063L11.4407 1.49992L7.0007 5.94038C6.71079 6.23029 6.71079 6.71112 7.0007 7.00104C7.29062 7.29095 7.77145 7.29095 8.06136 7.00104L12.5013 2.56058V6.7608C12.5013 7.17092 12.8414 7.51104 13.2516 7.51104C13.6617 7.51104 14.0011 7.17162 14.0011 6.7615V0.751094C14.0004 0.651392 13.9863 0.552398 13.9438 0.467545C13.9085 0.375621 13.8519 0.290768 13.7812 0.220057C13.7112 0.150054 13.6256 0.0927782 13.5337 0.0574229C13.4489 0.0149965 13.3499 0.000854492 13.2509 0.000854492ZM11.5002 9.74985C11.5002 9.33985 11.8402 8.99985 12.2502 8.99985C12.6602 8.99985 13.0002 9.33985 13.0002 9.74985V10.9998C13.0002 12.6498 11.6502 13.9999 10.0002 13.9999H3.00024C1.34924 13.9999 0.000244141 12.6498 0.000244141 10.9998V4.00009C0.000244141 2.35009 1.35024 1.00009 3.00024 1.00009L4.25024 1.00011C4.50024 1.00009 5.00024 1.32041 5.00024 1.75009C5.00024 2.17978 4.70024 2.50009 4.25024 2.50009H3.00024C2.20024 2.50009 1.50024 3.20009 1.50024 4.00009V10.9998C1.50024 11.8298 2.17024 12.4999 3.00024 12.4999H10.0002C10.8302 12.4999 11.5002 11.8298 11.5002 10.9998V9.74985Z'
        fill={fillColor}
      />
    </svg>
  )
}

type ExternalLinkIconProps = Icon

export default ExternalLinkIcon
