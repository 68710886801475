import React from 'react'
import CSSModules from 'react-css-modules'
import { connect } from 'react-redux'
import { Navigate, Route } from 'react-router-dom'

import { Map } from 'immutable'
import * as _ from 'lodash-es'
import { bindActionCreators } from 'redux'

import { BootstrapContainer, ContentLayout, LoadingIndicator, NavButton } from '@lyrahealth-inc/ui-core'

import FrequencyConflict from './frequencyConflict/FrequencyConflict'
import styles from './paymentsProviderDetails.module.scss'
import HealthPlanInfo from '../../../common/components/healthPlanInfo/HealthPlanInfoAdmins'
import TabFilters from '../../../common/components/tabFilters/TabFilters'
import { ROLES } from '../../../common/constants/appConstants'
import { GET_PROVIDER } from '../../../common/constants/reduxConstants'
import {
  PROVIDERS,
  PROVIDERS_DETAILS_EDIT_INFO,
  PROVIDERS_DETAILS_FREQUENCY_CONFLICT,
  PROVIDERS_DETAILS_HEALTHPLAN_PAYMENT_INFO,
  PROVIDERS_DETAILS_INFO,
  PROVIDERS_DETAILS_NEW_PAYMENT,
  PROVIDERS_DETAILS_PAYMENT_HISTORY,
} from '../../../common/constants/routingConstants'
import { axiosAuthInstance } from '../../../common/http/axiosInstance'
import { hasErrorForAction, hasRole } from '../../../common/utils/utils'
import { getAlertsState } from '../../../data/alertsSelectors'
import { getAuthRoles } from '../../../data/auth/authSelectors'
import { SentryRoutes } from '../../../index'
import PaymentHistoryDashboard from '../../../paymentsAdminHistoryDashboard/PaymentHistoryDashboard'
import RequestPayment from '../../../requestPayment/RequestPaymentAdmins'
import withRouter from '../../../routing/withRouter'
import { getProviderDetailsData } from '../../data/providerSelectors'
import * as providerDetailsActions from '../data/providerDetailsActions'
// pages
import DisplayProviderInfo from '../info/DisplayProviderInfo'
import EditProviderInfo from '../info/EditProviderInfo'

type PaymentsProviderdetailsProps = {
  router?: any
  actions?: any
  alerts?: any // TODO: PropTypes.instanceOf(List)
  providerDetails?: any // TODO: PropTypes.instanceOf(Map)
  roles?: any // TODO: PropTypes.instanceOf(List)
}

class PaymentsProviderdetails extends React.Component<PaymentsProviderdetailsProps> {
  componentDidMount() {
    if (this.props.router.location.state && this.props.router.location.state.provider_id) {
      // get provider by ID
      this.props.actions.getProvider(this.props.router.location.state.provider_id)
    } else {
      // If we don't have an ID, punt them back to the providers list
      this.props.router.navigate(PROVIDERS.route, { replace: true })
    }
  }

  componentWillUnmount() {
    this.props.actions.clearProviderDetailsStore()
    if (typeof (axiosAuthInstance as any).cancelLastRequest === 'function') {
      ;(axiosAuthInstance as any).cancelLastRequest()
    }
  }

  _backToProviders = () => {
    if (this.props.router.location.state?.fromPaymentCard) {
      this.props.router.navigate(-1)
    } else {
      this.props.router.navigate(PROVIDERS.route)
    }
  }

  _handleTabClick = (tab: any) => {
    switch (tab.toLowerCase()) {
      case 'payment history':
        this.props.router.navigate(PROVIDERS_DETAILS_PAYMENT_HISTORY.route, { replace: true })
        break
      case 'new payment':
        this.props.router.navigate(PROVIDERS_DETAILS_NEW_PAYMENT.route, { replace: true })
        break
      case 'info':
        this.props.router.navigate(PROVIDERS_DETAILS_INFO.route, { replace: true })
        break
      case 'frequency conflict':
        this.props.router.navigate(PROVIDERS_DETAILS_FREQUENCY_CONFLICT.route, { replace: true })
        break
    }
  }

  _renderTabs = () => {
    let tab
    const pathname = this.props.router.location.pathname
    if (_.includes(pathname, 'info')) {
      tab = 'Info'
    } else if (_.includes(pathname, 'new-payment')) {
      tab = 'New Payment'
    } else if (_.includes(pathname, 'payment-history')) {
      tab = 'Payment History'
    } else if (_.includes(pathname, 'frequency-conflict')) {
      tab = 'Frequency Conflict'
    }

    const tabsToRender = ['INFO', 'PAYMENT HISTORY', 'FREQUENCY CONFLICT']
    if (!hasRole(this.props.roles, ROLES.VIEW_ONLY)) {
      tabsToRender.splice(1, 0, 'NEW PAYMENT')
    }

    // @ts-expect-error TS(2322): Type '(tab: any) => void' is not assignable to typ... Remove this comment to see the full error message
    return <TabFilters activeTab={tab} tabList={tabsToRender} handleClick={this._handleTabClick} />
  }

  render() {
    const { providerDetails, router, alerts } = this.props
    // prevent error before punt
    if (!providerDetails) {
      if (router.location.state && router.location.state.provider_id) {
        // we'll be fetching data, so display spinner and listen for errors
        if (hasErrorForAction(alerts, GET_PROVIDER)) {
          return false // custom workflow or display could render here
        } else {
          return (
            <ContentLayout>
              <BootstrapContainer>
                <div styleName='loading-container'>
                  <LoadingIndicator size={45} />
                </div>
              </BootstrapContainer>
            </ContentLayout>
          )
        }
      } else {
        return false
      }
    }

    const backButtonText =
      router.location.state && router.location.state.fromPaymentCard ? 'Back to payments' : 'Back to providers'

    return (
      <BootstrapContainer>
        <NavButton
          // @ts-expect-error TS(2322): Type '{ className: string; styleType: string; text... Remove this comment to see the full error message
          className={styles['back-button']}
          styleType='back'
          text={backButtonText}
          onClick={this._backToProviders}
        />
        <div styleName='overview'>
          <h2>{providerDetails.full_name}</h2>
          <p>{providerDetails.email}</p>
        </div>
        {this._renderTabs()}
        <SentryRoutes>
          <Route
            path={PROVIDERS_DETAILS_EDIT_INFO.subroute}
            element={<EditProviderInfo initialValues={Map(providerDetails)} />}
          />
          <Route path={PROVIDERS_DETAILS_INFO.subroute} element={<DisplayProviderInfo data={providerDetails} />} />
          {/* @ts-expect-error TS(2740): Type '{}' is missing the following properties from... Remove this comment to see the full error message */}
          <Route path={PROVIDERS_DETAILS_NEW_PAYMENT.subroute} element={<RequestPayment />} />
          {/* @ts-expect-error TS(2322): Type '{}' is not assignable to type 'IntrinsicAttr... Remove this comment to see the full error message */}
          <Route path={PROVIDERS_DETAILS_PAYMENT_HISTORY.subroute} element={<PaymentHistoryDashboard />} />
          <Route path={PROVIDERS_DETAILS_FREQUENCY_CONFLICT.subroute} element={<FrequencyConflict />} />
          <Route path={PROVIDERS_DETAILS_HEALTHPLAN_PAYMENT_INFO.subroute} element={<HealthPlanInfo />} />
          <Route path='*' element={<Navigate to={PROVIDERS_DETAILS_INFO.subroute} />} />
        </SentryRoutes>
      </BootstrapContainer>
    )
  }
}

const mapStateToProps = ($$state: any) => {
  return {
    alerts: getAlertsState($$state),
    providerDetails: getProviderDetailsData($$state),
    roles: getAuthRoles($$state),
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    actions: bindActionCreators({ ...providerDetailsActions }, dispatch),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CSSModules(PaymentsProviderdetails, styles)))
