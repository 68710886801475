import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

/**
 * Standard checkmark icon
 */
export const CheckMarkIcon: FunctionComponent<CheckMarkIconProps> = ({
  fillColor,
  stroke,
  width = '18',
  height = '14',
  testID = 'CheckMarkIcon',
}) => {
  const { colors } = useTheme()

  return (
    <Svg width={width} height={height} viewBox='0 0 18 14' fill='none' testID={testID}>
      <Path
        d='M6.07116 13.2965V12.7039C6.29268 12.7039 6.50308 12.6183 6.6624 12.4574L16.6571 2.43215C16.9842 2.10407 16.9842 1.57414 16.6571 1.24606C16.33 0.917974 15.8017 0.917974 15.4746 1.2461L6.07123 10.6762L5.65353 10.2574L2.37896 6.97477C2.05188 6.64669 1.52357 6.64669 1.19648 6.97477C0.869401 7.30285 0.869401 7.83278 1.19648 8.16086L5.48134 12.4588C5.63924 12.6183 5.84964 12.7039 6.07116 12.7039V13.2965Z'
        fill={fillColor || colors.iconActive}
        stroke={stroke || colors.iconActive}
      />
    </Svg>
  )
}

type CheckMarkIconProps = {
  fillColor?: string
  stroke?: string
  width?: string
  height?: string
  testID?: string
}
