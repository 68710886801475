import React from 'react'
import { Path, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

export const SessionListPlayIcon: React.FC<SessionListPlayIconProps> = ({ fillColor, size = 24 }) => {
  const { colors } = useTheme()

  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' fill='none'>
      <Path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.02381 4.65984C8.65083 4.39994 9.39955 4.46047 9.95567 4.81601L19.2764 10.7749C19.7324 11.0664 20 11.5195 20 12C20 12.4805 19.7324 12.9336 19.2764 13.2251L9.95567 19.184C9.39955 19.5395 8.65083 19.6001 8.02381 19.3402C7.39679 19.0803 7 18.5449 7 17.9589V6.0411C7 5.45506 7.39679 4.91973 8.02381 4.65984Z'
        fill={fillColor || colors.iconActive}
      />
    </Svg>
  )
}

type SessionListPlayIconProps = {
  fillColor?: string
  size?: string | number
}
