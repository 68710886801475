import React, { FunctionComponent, ReactNode } from 'react'
import { AnyObject, Field, FormSpy } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'
import { IS_WEB } from '../../constants'

/**
 * How can I update field B when field A changes from X to Y?
 * https://medium.com/@erikras/declarative-form-rules-c5949ea97366
 * */
export const WhenFieldChanges: FunctionComponent<any> = ({
  field,
  becomes,
  set,
  to,
  cond,
}: {
  field: string
  becomes?: string | number | boolean
  set: string
  to: string | undefined
  cond?: boolean
}) => (
  <Field name={set} subscription={{}}>
    {(
      // No subscription. We only use Field to get to the change function
      { input: { onChange } },
    ) => (
      <FormSpy subscription={{}}>
        {() => (
          <OnChange name={field}>
            {(value) => {
              // check if condition is valid, if it is, change the value
              if (value === becomes || cond) {
                onChange(to)
              }
            }}
          </OnChange>
        )}
      </FormSpy>
    )}
  </Field>
)

/**
 * For desktop usage of react-final-form, we need the ability to auto submit when
 * pressing the enter key.
 * https://github.com/final-form/react-final-form/issues/613#issuecomment-623663315
 * */
export const FormContainer = ({
  handleSubmit,
  children,
  style,
}: {
  handleSubmit: (
    event?: Partial<Pick<React.SyntheticEvent, 'preventDefault' | 'stopPropagation'>>,
  ) => Promise<AnyObject | undefined> | undefined
  children: ReactNode
  style?: React.CSSProperties
}): JSX.Element => {
  return IS_WEB ? (
    <form onSubmit={handleSubmit} style={{ ...style, display: 'contents' }}>
      {children}
    </form>
  ) : (
    <>{children}</>
  )
}
