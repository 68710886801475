import React, { useState } from 'react'
import { FormattedDateTimeRange } from 'react-intl'
import { startCase, capitalize } from 'lodash-es'
import classNames from 'classnames'

import { Appointment, useApptsByDateTime, ProviderInfo } from '@lyrahealth-inc/shared-app-logic'

import GenericListItem from '../genericListItem/GenericListItem'
import dateUtils from '../../utils/dateUtils'
import stringUtils from '../../utils/stringUtils'
import appointmentUtils from '../../utils/appointmentUtils'
import DownloadIcon from '../../atoms/icons/DownloadIcon'
import CalendarIcon from '../../atoms/icons/CalendarIcon'
import MinusIcon from '../../atoms/icons/MinusIcon'
import SessionsIcon from '../../atoms/illustrations/SessionsIcon'
import PhoneSessionIcon from '../../atoms/illustrations/PhoneSessionIcon'
import PastSessionIcon from '../../atoms/illustrations/PastSessionsIcon'
import PeopleIcon from '../../atoms/illustrations/PeopleIcon'
import MenuDotsIcon from '../../atoms/icons/MenuDotsIcon'
import NavigationMenu from '../../atoms/navigationMenu/NavigationMenu'
import DefaultButton from '../../atoms/buttons/defaultButton/DefaultButton'
import Avatar from '../../atoms/avatar/Avatar'
import { LiveMessagingIcon } from '../../atoms/illustrations/LiveMessagingIcon'
import styles from './sessions.module.scss'

/**
 * Represents a list of sessions between a provider and a client.
 * (A session is an object consisting of an appointment and some amount of associated curriculum
 * for the client to complete. Session numbering is relative to chronological order of appointments.)
 * Allows the user to reschedule and cancel existing appointments.
 */
export const SessionsList: React.FC<SessionsListProps> = ({
  addToCalendar,
  appointments = [],
  currentSessionCount,
  onCancelAppointment,
  onRescheduleAppointment,
  onProfileLinkClick,
  viewSessionRecording,
  userRole,
  createNewAppointment,
  track = () => {},
  showAvatarHeader,
}) => {
  const [showPastSessions, setShowPastSessions] = useState(false)
  const isProviderRole = ['provider'].includes(userRole)
  const apptProvider = appointments.length > 0 ? appointments[0].provider : null

  const { pastAppts: pastAppointments, futureAppts: currentAppointments } = useApptsByDateTime({
    appts: appointments,
    useApptDuration: true,
    increment: 60,
    sort: true,
  })

  const renderRecordingDetails = (appointment: Appointment) => {
    if (isProviderRole) {
      return appointment?.videoRecording && viewSessionRecording ? (
        <div // eslint-disable-line
          // jsx-a11y/no-static-element-interactions
          className={styles['text-action']}
          key='watchRecording'
          data-test-id='watchRecording'
          onClick={() => viewSessionRecording(appointment?.videoRecording)}
        >
          ► Watch
        </div>
      ) : (
        <div className={styles['video-unavailable']} key='videoUnavailable' data-test-id='videoUnavailable'>
          Video unavailable
        </div>
      )
    }
    return null
  }

  const renderSubText = (appointment: Appointment) => {
    const appointmentStart = dateUtils.getAppointmentDateTimeObject(appointment)
    const appointmentEnd = appointmentStart.clone().add(appointment.appointmentDuration ?? 60, 'minutes')

    const meetingFormat = appointment.meetingFormat ?? ''
    const mapUrl = appointmentUtils.getMapUrlForAppointment(appointment)

    return (
      <span>
        <span>
          <FormattedDateTimeRange
            from={appointmentStart.toDate()}
            to={appointmentEnd.toDate()}
            month='short'
            day='numeric'
            weekday='short'
            hour='numeric'
            minute='numeric'
            timeZoneName='short'
          />
        </span>
        <span> • </span>
        {meetingFormat === 'inPerson' && mapUrl ? (
          <span>
            <a href={mapUrl} target='_blank' rel='noreferrer'>
              View address
            </a>
          </span>
        ) : (
          <span>{capitalize(meetingFormat).replace('_', ' ')}</span>
        )}
      </span>
    )
  }

  const togglePastSessions = (newValue: boolean) => {
    track({ event: 'BUTTON_PRESS', action: 'View Past Appointments' })
    setShowPastSessions(newValue)
  }

  const renderAppointmentActions = (
    appointment: Appointment,
    { reschedule, cancel, addToCal }: { reschedule: boolean; cancel: boolean; addToCal: boolean },
  ) => {
    const showCalendar = addToCal && addToCalendar
    const showReschedule = reschedule && onRescheduleAppointment
    const showCancel = cancel && onCancelAppointment
    const appointmentId = appointment?.appointmentId

    if (!showCalendar && !showReschedule && !showCancel) {
      return null
    }

    return (
      <NavigationMenu
        key='navigationMenu'
        className={styles['actions-menu']}
        customIcon={<MenuDotsIcon fillColor={styles.x_secondary_action} />}
        alignment='right'
        menuType=''
        data-test-id='SessionList-itemActions'
        buttonLabel='list of actions'
      >
        {showReschedule ? (
          <li // eslint-disable-line
            // jsx-a11y/no-static-element-interactions
            data-test-id='SessionsList-reschedule'
            onClick={() => {
              onRescheduleAppointment(appointment)
            }}
          >
            <a // eslint-disable-line
            // jsx-a11y/anchor-is-valid
            >
              <CalendarIcon isFilled width={20} fillColor={styles.x_primary_action} />
              Reschedule
            </a>
          </li>
        ) : null}
        {showCancel ? (
          <li // eslint-disable-line
            // jsx-a11y/no-static-element-interactions
            data-test-id='SessionsList-cancel'
            onClick={() => {
              onCancelAppointment(appointment)
            }}
          >
            <a // eslint-disable-line
            // jsx-a11y/anchor-is-valid
            >
              <MinusIcon isFilled width={20} fillColor={styles.x_primary_action} />
              Cancel
            </a>
          </li>
        ) : null}
        {showCalendar ? (
          <li // eslint-disable-line
            // jsx-a11y/no-static-element-interactions
            data-test-id='SessionsList-addToCalendar'
            onClick={() => {
              if (addToCalendar) {
                addToCalendar({ appointmentId })
              }
            }}
          >
            <a // eslint-disable-line
            // jsx-a11y/no-static-element-interactions
            >
              <DownloadIcon isFilled width={20} fillColor={styles.x_primary_action} />
              Add to Calendar
            </a>
          </li>
        ) : null}
      </NavigationMenu>
    )
  }

  const renderFilter = () => {
    return (
      <div // eslint-disable-line
        // jsx-a11y/no-static-element-interactions
        className={styles['filter-container']}
      >
        <div // eslint-disable-line
          // jsx-a11y/no-static-element-interactions
          onClick={() => togglePastSessions(false)}
          className={classNames({ [styles.active]: !showPastSessions })}
        >
          Upcoming
        </div>
        <div // eslint-disable-line
          // jsx-a11y/no-static-element-interactions
          onClick={() => togglePastSessions(true)}
          className={classNames({ [styles.active]: showPastSessions })}
        >
          All Sessions
        </div>
      </div>
    )
  }

  const renderProviderHeader = (
    provider: ProviderInfo | null | undefined,
    onProfileLinkClick?: (lyraId: string) => void,
  ) => {
    if (!provider) {
      return null
    }

    const displayName = provider.display_name ?? ''
    const initials = stringUtils.getInitials(displayName)
    const avatarSrc = provider?.lyraProviderImage?.imageUrl

    return (
      <div className={styles['provider-header']}>
        <div>
          <Avatar src={avatarSrc} ltStyle size={35} alt='Photograph of therapist'>
            {initials}
          </Avatar>
        </div>
        {displayName ? (
          <div className={styles['provider-info']}>
            <h3>Sessions with {displayName}</h3>
            {onProfileLinkClick ? (
              <a // eslint-disable-line
                onClick={() => {
                  onProfileLinkClick(provider.lyra_id ?? '')
                }}
              >
                View Profile
              </a>
            ) : null}
          </div>
        ) : null}
      </div>
    )
  }

  const renderPastSessions = (pastAppointments: Appointment[]) => {
    return pastAppointments.map((appointment) => {
      const status = (appointment.appointmentStatus ?? '').toLowerCase()
      const incomplete = ['canceled', 'missed'].includes(status)
      const sessionNumber = appointment.sessionNumber ?? 0
      const rightItems = [renderRecordingDetails(appointment)]
      const title = incomplete ? `${startCase(status)} Session` : `Session ${sessionNumber > 0 ? sessionNumber : ''}`

      return (
        <GenericListItem
          data-test-id={'SessionsList-PastAppointment'}
          key={appointment?.appointmentId}
          title={title}
          icon={<PastSessionIcon isChecked={!incomplete} />}
          subText={renderSubText(appointment)}
          rightItems={rightItems}
        />
      )
    })
  }

  const renderCurrentSessions = (currentAppointments: Appointment[]) => {
    return currentAppointments.map((appointment) => {
      const status = (appointment.appointmentStatus ?? '').toLowerCase()
      const sessionNumber = appointment.sessionNumber ?? 0
      const canceled = status === 'canceled'
      const incomplete = ['canceled', 'missed'].includes(status)
      const meetingFormat = appointment.meetingFormat ?? ''
      const title = incomplete ? `${startCase(status)} Session` : `Session ${sessionNumber > 0 ? sessionNumber : ''}`

      const reschedulable = dateUtils.getAppointmentDateTimeObject(appointment).subtract(24, 'hours').isAfter()
      const cancelable = dateUtils.getAppointmentDateTimeObject(appointment).isAfter() && !incomplete
      let icon = null
      switch (meetingFormat) {
        case 'video':
          icon = <SessionsIcon />
          break
        case 'inPerson':
          icon = <PeopleIcon />
          break
        case 'live_messaging':
          icon = <LiveMessagingIcon />
          break
        default:
          icon = <PhoneSessionIcon />
      }
      icon = incomplete ? <PastSessionIcon /> : icon

      return (
        <GenericListItem
          data-test-id={'SessionsList-CurrentAppointment'}
          key={appointment?.appointmentId}
          title={title}
          icon={icon}
          isBold={sessionNumber === currentSessionCount}
          subText={renderSubText(appointment)}
          rightItems={
            canceled
              ? []
              : [
                  renderAppointmentActions(appointment, {
                    reschedule: reschedulable,
                    cancel: cancelable,
                    addToCal: true,
                  }),
                ]
          }
        />
      )
    })
  }

  const renderEmptySessions = (provider: ProviderInfo | null | undefined) => {
    let emptyMessage = <div>You have no upcoming sessions scheduled</div>

    if (provider && provider?.lyra_type?.toLowerCase() === 'therapist') {
      emptyMessage = (
        <div>
          <a href={`mailto:${provider.email}`}>Contact {provider.display_name}</a> for more information about your
          sessions
        </div>
      )
    }

    return <div className={styles['no-data']}>{emptyMessage}</div>
  }

  return (
    <div data-test-id='SessionsList-container'>
      <div className={styles.top}>
        <div>
          {showAvatarHeader ? renderProviderHeader(apptProvider, onProfileLinkClick) : null}
          {pastAppointments.length > 0 ? renderFilter() : null}
        </div>
        {isProviderRole && createNewAppointment ? (
          <DefaultButton isSmall data-test-id='SessionsList-new-session' onClick={createNewAppointment}>
            Schedule new
          </DefaultButton>
        ) : null}
      </div>
      <ul className={styles.list}>
        {showPastSessions ? renderPastSessions(pastAppointments) : null}
        {renderCurrentSessions(currentAppointments)}
        {(appointments.length === 0 || currentAppointments.length === 0) && !showPastSessions
          ? renderEmptySessions(apptProvider)
          : null}
      </ul>
    </div>
  )
}

type SessionsListProps = {
  addToCalendar?: (appointmentId: { appointmentId: number }) => void
  appointments: Appointment[]
  currentSessionCount: number | string
  onCancelAppointment: (appointment: Appointment) => void
  onRescheduleAppointment?: (appointment: Appointment) => void
  onProfileLinkClick?: () => void
  viewSessionRecording?: (videoRecording?: string) => void
  userRole: string
  createNewAppointment?: () => void
  track?: (event: { event: string; action: string }) => void
  showAvatarHeader: boolean
}

export default SessionsList
