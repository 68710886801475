import React from 'react'
import CSSModules from 'react-css-modules'
import styles from './forbiddenRoute.module.scss'
import { BootstrapContainer, CardSmall, PrimaryButton } from '@lyrahealth-inc/ui-core'
import { useNavigate } from 'react-router'

export default CSSModules(() => {
  const navigate = useNavigate()
  return (
    <BootstrapContainer>
      <CardSmall>
        <div styleName='forbidden-container'>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          <h1>You don't have the required permissions to view this page.</h1>
          <PrimaryButton id='test-forbiddenroute-click' onClick={() => navigate('/')}>
            Return Home
          </PrimaryButton>
        </div>
      </CardSmall>
    </BootstrapContainer>
  )
}, styles)
