import React from 'react'
import CSSModules from 'react-css-modules'
import styles from './duplicatePaymentModal.module.scss'
import { PrimaryButton } from '@lyrahealth-inc/ui-core'
import PropTypes from 'prop-types'

const DuplicatePaymentModal = ({ closeFunc }: any) => {
  return (
    <div styleName='container'>
      <h1 styleName='header'>Duplicate Payment</h1>
      <h3>
        Your payment request was not processed because a previous request for the same client and session date has
        already been submitted.
      </h3>
      <p styleName='questions'>
        For any questions, please contact <a href='mailto:providers@lyrahealth.com'>providers@lyrahealth.com</a>.
      </p>
      <PrimaryButton onClick={() => closeFunc()}>OK</PrimaryButton>
    </div>
  )
}

DuplicatePaymentModal.propTypes = {
  closeFunc: PropTypes.func.isRequired,
}

export default CSSModules(DuplicatePaymentModal, styles)
