import React, { FunctionComponent, useCallback, useRef } from 'react'
import { FormattedMessage } from 'react-intl'

import BottomSheetGorhom from '@gorhom/bottom-sheet'
import styled, { useTheme } from 'styled-components/native'

import { BodyText, PressableOpacity, PrimaryButton, Subhead, TertiaryButton, XIcon } from '../../atoms'
import { Modal } from '../../organisms'
import { SubheadSize } from '../../styles'
import { ThemeType, tID } from '../../utils'

const HeadingAndCloseButtonContainer = styled.View({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
})

const ButtonContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: 'row',
  flexWrap: 'wrap-reverse',
  justifyContent: 'flex-end',
  gap: theme.spacing['16px'],
}))

const Container = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  gap: theme.spacing['16px'],
  margin: theme.breakpoints.isMobileSized ? theme.spacing['16px'] : '0px',
}))

export type EndSessionModalProps = {
  show: boolean
  onClose: () => void
  onConfirm: () => void
}

export const EndSessionModal: FunctionComponent<EndSessionModalProps> = ({ show, onClose, onConfirm }) => {
  const { colors } = useTheme() as ThemeType
  const ref = useRef<BottomSheetGorhom>(null)

  const openBottomSheet = useCallback(() => {
    ref.current?.expand()
  }, [])

  return (
    <Modal
      visible={show}
      onCloseEnd={onClose}
      onRequestClose={onClose}
      snapPoints={[480]}
      onLayout={openBottomSheet}
      bottomSheetRef={ref}
      modalContents={
        <Container>
          <HeadingAndCloseButtonContainer>
            <Subhead
              text={
                <FormattedMessage
                  defaultMessage='Are you sure you want to end the session?'
                  description='Title of modal where provider may end the session'
                />
              }
              size={SubheadSize.MEDIUM}
            />
            <PressableOpacity hitSlop={60} onPress={onClose} testID={tID('EndSessionModal-confirm-closeButton')}>
              <XIcon size={24} fillColor={colors.iconDefault} />
            </PressableOpacity>
          </HeadingAndCloseButtonContainer>
          <BodyText
            text={
              <FormattedMessage
                defaultMessage='If you end this session, you will not be able to rejoin. If you are experiencing technical difficulties, try
                refreshing the page and then rejoining.'
                description='Content of modal where provider may end the session'
              />
            }
          />
          <ButtonContainer>
            <TertiaryButton
              onPress={onClose}
              text={
                <FormattedMessage
                  defaultMessage='Cancel'
                  description='Button text that describes exiting confirm dialog without ending session'
                />
              }
              testID={tID('EndSessionModal-cancel-button')}
            />
            <PrimaryButton
              text={<FormattedMessage defaultMessage='End Session' description='Button text that ends sessions' />}
              onPress={onConfirm}
              testID={tID('EndSessionModal-confirm-button')}
            />
          </ButtonContainer>
        </Container>
      }
    />
  )
}
