import React, { FunctionComponent, useCallback, useState } from 'react'
import { IntlShape, RawIntlProvider } from 'react-intl'
import { View } from 'react-native'

import styled from 'styled-components/native'

import { AvailabilitiesResponse, useParseAvailabilities } from '@lyrahealth-inc/shared-app-logic'

import { Divider } from '../../atoms'
import { ExpandedCalendarBookingHeader } from '../../organisms/expandedCalendarBookingHeader/ExpandedCalendarBookingHeader'
import { ExpandedDateBlockSelector } from '../../organisms/expandedDateBlockSelector/ExpandedDateBlockSelector'
import { ThemeType } from '../../utils'

export interface ExpandedCalendarBookingProps {
  availabilities: AvailabilitiesResponse
  timeZone: string
  onSelected: (dateTime: string) => void
  intl?: IntlShape
  responsive?: boolean
  banner?: React.ReactNode | null
  sliceDurationMinutes: number
}

const ExpandedCalendarBookingInnerContainer = styled.View<{ theme: ThemeType }>(({ theme: { colors } }) => ({
  backgroundColor: colors.backgroundPrimary,
  borderWidth: '1px',
  borderColor: colors.borderDefault,
  borderRadius: '16px',
  padding: '16px',
}))

const ExpandedCalendarBookingDivider = styled(Divider)({
  position: 'absolute',
  top: '76px',
  left: '0px',
  right: '0px',
})

export const ExpandedCalendarBooking: FunctionComponent<ExpandedCalendarBookingProps> = ({
  availabilities,
  timeZone,
  onSelected,
  intl,
  sliceDurationMinutes,
  responsive = false,
  banner = null,
}) => {
  const parsedAvailabilities = useParseAvailabilities(availabilities, timeZone, sliceDurationMinutes)
  const [startDate, setStartDate] = useState(new Date().toISOString())
  const [endDate, setEndDate] = useState(new Date().toISOString())

  const onDatesChanged = useCallback((dates: string[]) => {
    setStartDate(dates[0])
    setEndDate(dates[dates.length - 1])
  }, [])
  if (parsedAvailabilities == null) {
    return null
  }

  const { dates, appointmentDurationMinutes } = parsedAvailabilities

  const content = (
    <View>
      <ExpandedCalendarBookingHeader
        startDate={startDate}
        endDate={endDate}
        appointmentDuration={appointmentDurationMinutes}
        timeZone={timeZone}
      />
      {banner && banner}
      <ExpandedCalendarBookingInnerContainer>
        <ExpandedCalendarBookingDivider />
        <ExpandedDateBlockSelector
          dates={dates}
          onDatesChanged={onDatesChanged}
          onSelected={onSelected}
          responsive={responsive}
          timeZone={timeZone}
        />
      </ExpandedCalendarBookingInnerContainer>
    </View>
  )

  if (intl == null) {
    return content
  }
  return <RawIntlProvider value={intl}>{content}</RawIntlProvider>
}
