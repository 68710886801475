import React from 'react'
import CSSModules from 'react-css-modules'
import { connect } from 'react-redux'

import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form/immutable'

import { BaseModal, dateUtils, NavButton } from '@lyrahealth-inc/ui-core'

import AccountType from './accountType/AccountType'
import * as registerProviderActions from './data/registerProviderActions'
import styles from './registerProvider.module.scss'
import ProviderInfoForm from '../../common/components/forms/providerInfo/ProviderInfoForm'
import providerInfoValidate from '../../common/components/forms/providerInfo/providerInfoValidate'
import { bannerMessages } from '../../common/constants/appConstants'
import { OUTCOMES_CONSENT } from '../../common/constants/routingConstants'
import TermsOfUse from '../../common/content/TermsOfUse'
import { getErrorText } from '../../common/utils/utils'
import * as alertActions from '../../data/alertActions'
import {
  getAuthUserEmail,
  getAuthUserFirstName,
  getAuthUserId,
  getAuthUserLastName,
  getAuthUserOutcomesEnabled,
} from '../../data/auth/authSelectors'
import * as authActions from '../../data/authActions'
import withRouter from '../../routing/withRouter'
import { getProvidersRegisterAccountType, getProvidersRegisterShowModal } from '../data/providerSelectors'

type RegisterProviderProps = {
  handleSubmit?: (...args: any[]) => any
  submitting?: boolean
  showModal?: boolean
  actions?: any
  router?: any
  accountType?: string
  outcomesEnabled?: boolean
}

class RegisterProvider extends React.Component<RegisterProviderProps> {
  _toggleTOUModal = (show: any) => {
    this.props.actions.toggleRegisterProviderModal(show)
  }

  _submitForm = (values: any) => {
    this.props.actions.hideAlerts()

    const formData = new FormData()
    const vals = values.toJS()
    Object.keys(vals).forEach((key) => {
      let val = vals[key]
      if (key === 'date_of_birth') {
        val = dateUtils.getDisplayDate(val, 'YYYY-MM-DD')
      }
      if (['identification_file_front', 'identification_file_back'].includes(key)) {
        val = val[0]
      }
      formData.append(key, val)
    })

    return this.props.actions.submitRegisterProvider(formData).then(
      () => {
        // request successful
        this.props.actions.registerSuccessful()
        if (this.props.outcomesEnabled) {
          // outcomes enabled, redirect to consent view
          this.props.router.navigate(OUTCOMES_CONSENT.route, { state: { withoutNav: true } })
        } else {
          this.props.router.navigate('/')
        }

        this.props.actions.addAlert({
          show: true,
          contents: bannerMessages.REGISTER_PROVIDER_SUCCESS,
          style: 'success',
          expires: true,
          autoDismissTimer: 10000,
        })
      },
      (error: any) => {
        const errorText = error.response.data.message || getErrorText(error.response.status)
        this.props.actions.addAlert({
          show: true,
          contents: errorText,
          style: 'danger',
          expires: true,
        })
      },
    )
  }

  render() {
    const { handleSubmit, submitting, showModal } = this.props

    if (!this.props.accountType) {
      return <AccountType />
    }

    return (
      <div>
        <div styleName='back-container'>
          <NavButton styleType='back' text='Account Type' onClick={() => this.props.actions.clearAccountType()} />
        </div>

        <div styleName='card'>
          <h2 styleName='title'>Register Provider</h2>

          <ProviderInfoForm
            handleSubmit={handleSubmit}
            submitFunction={this._submitForm}
            submitting={submitting}
            accountType={this.props.accountType}
            termsOfUseHandler={() => this._toggleTOUModal(true)}
            primaryButtonText='Register'
          />

          <BaseModal
            // @ts-expect-error TS(2322): Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
            isOpen={showModal}
            body={<TermsOfUse />}
            closeModal={() => this._toggleTOUModal(false)}
            size='large'
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = ($$state: any) => {
  const accountType = getProvidersRegisterAccountType($$state)

  return {
    showModal: getProvidersRegisterShowModal($$state),
    outcomesEnabled: getAuthUserOutcomesEnabled($$state) ?? false,
    accountType: accountType,
    initialValues: {
      lyra_id: getAuthUserId($$state),
      account_type: accountType,
      first_name: getAuthUserFirstName($$state),
      last_name: getAuthUserLastName($$state),
      email: getAuthUserEmail($$state),
      payment_preference: '2',
    },
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    actions: bindActionCreators({ ...registerProviderActions, ...alertActions, ...authActions }, dispatch),
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    reduxForm({
      form: 'ProviderInfoForm',
      validate: providerInfoValidate,
      enableReinitialize: true,
      keepDirtyOnReinitialize: true,
      touchOnBlur: false,
      // @ts-expect-error TS(2345): Argument of type 'typeof RegisterProvider' is not ... Remove this comment to see the full error message
    })(CSSModules(RegisterProvider, styles)),
  ),
)
