import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { colors } from '../../styles/colors'

export const SessionsIcon: React.FC<SessionsIconProps> = ({
  backgroundColor = colors.orange1,
  fillColor = colors.orange2,
  width = 36,
}) => {
  const colorBg = backgroundColor
  const colorFill = fillColor
  return (
    <Svg width={width} height={width} viewBox={`0 0 36 36`}>
      <g fill='none' fillRule='evenodd'>
        <circle cx='18' cy='18' r='18' fill={colorBg} fillRule='nonzero' />
        <Path d='M29.65,14.91 L29.65,21.08 C29.65,23.2156938 27.3087727,24.3913531 25.626222,23.3557342 C25.451919,24.6493965 24.3406635,25.65 23,25.65 L11,25.65 C9.53873016,25.65 8.35,24.4612698 8.35,23 L8.35,13 C8.35,11.5387302 9.53873016,10.35 11,10.35 L23,10.35 C24.3374753,10.35 25.4466324,11.3458501 25.6249625,12.6350414 C27.3076042,11.5980602 29.65,12.7737733 29.65,14.91 Z' />
        <Path
          fill={colorFill}
          fillRule='nonzero'
          d='M24,16.5 L24,13 C24,12.45 23.55,12 23,12 L11,12 C10.45,12 10,12.45 10,13 L10,23 C10,23.55 10.45,24 11,24 L23,24 C23.55,24 24,23.55 24,23 L24,19.5 L26.29,21.79 C26.92,22.42 28,21.97 28,21.08 L28,14.91 C28,14.02 26.92,13.57 26.29,14.2 L24,16.5 Z'
        />
      </g>
    </Svg>
  )
}

type SessionsIconProps = {
  backgroundColor?: string
  fillColor?: string
  width?: number | string
}
