import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'

import styled from 'styled-components/native'

import { BodyText, Link } from '../../atoms'
import { Size as BodyTextSize } from '../../atoms/bodyText/BodyText'
import { Image as ExpoImage } from '../../atoms/image/Image'
import { colors } from '../../styles'
import { openUrl, ThemeType, tID } from '../../utils'

type BlockedChatViewProps = {
  receiverBlocked: boolean
  onContactCareNavigatorPressed?: () => void
  contactCarePhoneNumber?: string
}

const UnmonitoredContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexGrow: 1,
  alignContent: 'center',
  padding: theme.spacing['24px'],
  justifyContent: 'center',
}))

const ReceiverBlockedContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  backgroundColor: colors.charcoal1,
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing['32px'],
}))

const ReceiverBlockedDescription = styled(BodyText)<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['8px'],
}))

const Image = styled(ExpoImage)({
  width: '160px',
  height: '130px',
})

export const BlockedChatView: FunctionComponent<BlockedChatViewProps> = ({
  receiverBlocked,
  onContactCareNavigatorPressed,
  contactCarePhoneNumber,
}) => {
  return (
    <>
      {receiverBlocked ? (
        <UnmonitoredContainer testID={tID('BlockedChatView')}>
          <BodyText textAlign='center'>
            <FormattedMessage
              defaultMessage='This message inbox is no longer being monitored. For assistance
          with booking appointments with a Lyra provider, please contact <link>the Lyra Care Navigator</link>'
              description='Message when provider is blocked informing user to contact care navigator'
              values={{
                link: (text: string) => (
                  <Link
                    containerStyle={{ display: 'inline' }}
                    onPress={(e) => {
                      e.preventDefault()
                      onContactCareNavigatorPressed?.()
                    }}
                    text={text}
                  />
                ),
              }}
            />
          </BodyText>
        </UnmonitoredContainer>
      ) : (
        <ReceiverBlockedContainer testID={tID('BlockedChatView-Messaging-Unavailable')}>
          <Image source={require('../../assets/resting_cat.png')} accessibilityIgnoresInvertColors />
          <ReceiverBlockedDescription
            textAlign='center'
            text={
              <FormattedMessage
                defaultMessage='Messaging is currently unavailable. If you need urgent support, contact our
              Care Navigator team at <link>care@lyrahealth.com</link> or {hasContactCarePhoneNumber, select, true {{contactCarePhoneNumber}} other {(877) 505-7147}}. For all other questions,
              please message your provider at a later time.'
                description='Message to redirect user when the messenger is not available'
                values={{
                  link: (text: string) => <Link text={text} onPress={() => openUrl(`mailto:${text}`)} />,
                  hasContactCarePhoneNumber: !!contactCarePhoneNumber,
                  contactCarePhoneNumber,
                }}
              />
            }
            size={BodyTextSize.SMALL}
          />
        </ReceiverBlockedContainer>
      )}
    </>
  )
}
