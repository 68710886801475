import React, { useEffect } from 'react'
import ReactMarkdown from 'react-markdown'

import { get, noop } from 'lodash-es'

import styles from './infoSheet.module.scss'
import LinkIcon from '../../atoms/illustrations/LinkIcon'
import FormContentHeader from '../formContentHeader/FormContentHeader'

type Props = {
  content: $TSFixMe
  onLinkClick?: $TSFixMeFunction
  onView?: $TSFixMeFunction
}

const InfoSheet = ({ content, onLinkClick = noop, onView = noop }: Props) => {
  useEffect(() => {
    onView()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const metaData = get(content, 'meta_data') || get(content, 'content_meta_data')
  let instructionsToDisplay = get(content, 'instructions')
  const displayTitle = get(metaData, 'title') || get(content, 'title') || get(content, ['content', 'title'])

  // If the metadata contains a `selectedMedication` field, use those values
  const selectedMed = get(metaData, 'selectedMedication')
  if (selectedMed) {
    instructionsToDisplay = selectedMed.instructions
  }

  return (
    <FormContentHeader title={displayTitle} group={content.group || content.content.group}>
      <div className={styles['content-container']}>
        <div>
          {get(metaData, 'imageUrl') && (
            <div className={styles['img-block']}>
              <img alt={metaData.imageAlt} src={metaData.imageUrl} />
            </div>
          )}
          {instructionsToDisplay && <div className={styles['instruction-block']}>{instructionsToDisplay}</div>}
          {get(metaData, 'content') && (
            <div className={styles['description-block']}>
              {/* eslint-disable-next-line react/no-children-prop */}
              <ReactMarkdown disallowedElements={['inlineCode', 'code']} children={metaData.content} />
            </div>
          )}
          {get(metaData, ['links', 'length']) &&
            metaData.links.map(({ linkText, linkUrl }: $TSFixMe, index: $TSFixMe) => (
              <div key={linkUrl} className={styles['link-well']}>
                <a
                  className={styles.link}
                  data-test-id={`InfoSheet-link-${index}`}
                  href={linkUrl}
                  target='_blank'
                  rel='noreferrer'
                  onClick={onLinkClick}
                >
                  <div className={styles['link-text']}>
                    <div>{linkText}</div>
                  </div>
                  <LinkIcon />
                </a>
              </div>
            ))}
        </div>
      </div>
    </FormContentHeader>
  )
}

export default InfoSheet
