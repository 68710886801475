import React from 'react'
import { FormattedDateTimeRange, FormattedTime } from 'react-intl'
import classNames from 'classnames'
import RecordingIcon from '../../atoms/icons/RecordingIcon'
import dateUtils from '../../utils/dateUtils'
import VideoIcon from '../../atoms/icons/VideoIcon'
import styles from './videoSession.module.scss'
import useIsMobile from '../../hooks/useIsMobile'

export const SessionInfo: React.FC<SessionInfoProps> = ({
  $$currentSession,
  timeZone,
  showRecordingStatus,
  isRecording,
  type,
}) => {
  const [isMobile] = useIsMobile()
  const startTimeMoment = dateUtils.getAppointmentDateTimeObject($$currentSession.get('appointment')).tz(timeZone)
  const endTimeMoment = startTimeMoment
    .clone()
    .add($$currentSession.getIn(['appointment', 'appointmentDuration']), 'minutes')

  const isProvider = type === 'provider'
  const providerFirstName = $$currentSession.getIn(['appointment', 'provider', 'first_name'], 'Provider')
  const providerLastName = $$currentSession.getIn(['appointment', 'provider', 'last_name'], '')

  const clientFirstName = $$currentSession.getIn(['appointment', 'userInfo', 'firstName'], 'Client')
  const clientLastName = $$currentSession.getIn(['appointment', 'userInfo', 'lastName'], '')

  const sessionName = isProvider ? `${clientFirstName} ${clientLastName}` : `${providerFirstName} ${providerLastName}`

  const renderSessionTime = () => {
    return isMobile ? (
      <FormattedTime value={startTimeMoment.toDate()} timeZoneName='short' />
    ) : (
      <>
        <FormattedDateTimeRange
          from={startTimeMoment.toDate()}
          to={endTimeMoment.toDate()}
          hour='numeric'
          minute='numeric'
          timeZoneName='short'
        />
      </>
    )
  }

  return (
    <div className={classNames(styles['session-info-container'], styles.container)}>
      <div className={styles['session-info']}>
        {/* @ts-expect-error TS(2322): Type '{ children: string; role: "heading"; "aria-l... Remove this comment to see the full error message */}
        <span role='heading' aria-level='1' className={styles['name-session']}>
          {sessionName}
        </span>
        <div className={styles['session-time-container']}>
          <div className={styles['session-schedule']}>
            <VideoIcon width={28} isFilled />
            <span className={styles['session-time']}>{renderSessionTime()}</span>
          </div>
          {isRecording ? (
            <div
              className={classNames(
                styles['recording-pill'],
                styles.recording,
                !showRecordingStatus ? styles.invisible : styles['slide-in'],
              )}
              data-test-id='SessionInfo-recordingPill'
            >
              <RecordingIcon isFull fillColor={styles.x_red4} width={12} />
              <span className={classNames(styles['recording-status'], styles['recording-text'])}>Recording</span>
            </div>
          ) : (
            <div
              className={classNames(
                styles['recording-pill'],
                styles['not-recording'],
                !showRecordingStatus ? styles.invisible : styles['slide-in'],
              )}
              data-test-id='SessionInfo-notRecordingPill'
            >
              <RecordingIcon isFull fillColor={styles.x_charcoal4} width={12} />
              <span className={classNames(styles['recording-status'], styles['not-recording-text'])}>
                Not recording
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default SessionInfo

type SessionInfoProps = {
  $$currentSession: any
  timeZone: string
  type: string
  showRecordingStatus: boolean
  isRecording: boolean
}
