import React, { FunctionComponent } from 'react'

import styled from 'styled-components/native'

import { BaseInput } from '../../atoms/formElements/BaseInput'
import { StyledMarkdown } from '../../atoms/styledMarkdown/StyledMarkdown'
import { ThemeType } from '../../utils'

export type InformedConsentProps = {
  title?: string
  name?: string
  content?: string
}

const ScrollContainer = styled.ScrollView<{ theme: ThemeType }>(({ theme: { colors } }) => ({
  borderRadius: '4px',
  borderColor: colors.borderDefault,
  borderWidth: '1px',
  height: '400px',
  margin: '16px 0',
}))

export const InformedConsent: FunctionComponent<InformedConsentProps> = ({ title, name, content }) => {
  return (
    <BaseInput label={title} name={name}>
      <ScrollContainer nestedScrollEnabled contentContainerStyle={{ padding: 16 }}>
        <StyledMarkdown content={content} />
      </ScrollContainer>
    </BaseInput>
  )
}
