import { ToastContent } from '@lyrahealth-inc/ui-core-crossplatform'

import { CLEAR_TOAST_CONTENT, SET_TOAST_CONTENT } from '../../common/constants/reduxConstants'

export const setToastContent = (content: ToastContent) => {
  return {
    content,
    type: SET_TOAST_CONTENT,
  }
}

export const clearToastContent = () => {
  return {
    type: CLEAR_TOAST_CONTENT,
  }
}
