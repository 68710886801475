import React, { FunctionComponent } from 'react'

import styled from 'styled-components/native'

import { Image } from '../image/Image'

const ModalityQuestionnaireIllustrationPNG = styled(Image)({
  width: 136,
  height: 136,
})

export const ModalityQuestionnaireUserLaptopIllustration: FunctionComponent = () => {
  return (
    <ModalityQuestionnaireIllustrationPNG
      source={require('./../../assets/modality-questionnaire-user-laptop-illustration.png')}
    />
  )
}
