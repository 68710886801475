import React, { useState } from 'react'

import SchedulingAvailabilityPicker from './SchedulingAvailabilityPicker'
import { SchedulingCalendarView } from './SchedulingCalendarView'
import SchedulingStatus from './SchedulingStatus'

export const SchedulingConnectedBody: React.FC<SchedulingConnectedBodyProps> = ({
  providerCalendarState,
  schedulingAvailability,
  schedulingCalendars,
  schedulingCredentialsValid,
  providerId,
}) => {
  const initialBlockingCalList: string[] = []
  let initialAppointmentCal = ''
  let buttonDisabledInit = true

  const checkAvailabilityStates = (calendarItem: { id: string; summary: string; use: string }) => {
    // We need to do this check before we set initial state, or it can/will cause an infinite re-render loop
    if (calendarItem.use === 'APPOINTMENT') {
      initialAppointmentCal = calendarItem.id
      initialBlockingCalList.push(calendarItem.id)
      buttonDisabledInit = false
    } else if (calendarItem.use === 'BLOCKING') {
      initialBlockingCalList.push(calendarItem.id)
    }
  }

  const setInitialAvailability = () => {
    schedulingCalendars.map((calendarElement: { id: string; summary: string; use: string }) =>
      checkAvailabilityStates(calendarElement),
    )
  }

  setInitialAvailability()

  const [blockingCalendars, setBlockingCalendars] = useState(initialBlockingCalList)
  const [appointmentCalendar, setAppointmentCalendar] = useState(initialAppointmentCal)
  const [buttonDisabled, setButtonDisabled] = useState(buttonDisabledInit)

  return (
    <>
      <SchedulingStatus
        providerCalendarState={providerCalendarState}
        schedulingCredentialsValid={schedulingCredentialsValid}
        buttonDisabled={buttonDisabled}
        providerId={providerId}
      />
      <SchedulingAvailabilityPicker
        setBlockingCalendars={setBlockingCalendars}
        blockingCalendars={blockingCalendars}
        setAppointmentCalendar={setAppointmentCalendar}
        appointmentCalendar={appointmentCalendar}
        setStatusButton={setButtonDisabled}
        statusButton={buttonDisabled}
        calendarDataList={schedulingCalendars}
        providerId={providerId}
      />
      <SchedulingCalendarView
        providerAvailabilities={schedulingAvailability}
        schedulingCredentialsValid={schedulingCredentialsValid}
      />
    </>
  )
}

type SchedulingConnectedBodyProps = {
  providerCalendarState: string
  schedulingAvailability: {
    availabilities: string[]
    times: string[][]
  }
  schedulingCalendars: {
    accessRole: string
    id: string
    summary: string
    use: string
    primary: boolean
  }[]
  schedulingCredentialsValid: boolean
  providerId: string
}
