import React, { FunctionComponent } from 'react'
import Svg, { G, Mask, Path } from 'react-native-svg'

type Props = {
  size?: number | string
}

export const CareForChildOrTeenSpotIllustrationWhiteBorder: FunctionComponent<Props> = ({ size = 124 }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 513 513' fill='none'>
      <Path
        d='M258.686 0.60498C223.345 0.60498 189.057 6.66547 156.771 18.6144C79.907 47.1058 25.8685 110.27 8.50658 191.91C-2.96687 245.139 -1.4998 294.372 13.0191 342.35C31.5546 403.622 77.0538 455.293 137.851 484.109C168.032 498.445 202.705 506.631 240.889 508.431C245.796 508.654 250.784 508.775 255.742 508.775C286.378 508.775 317.197 504.577 347.348 496.3C349.664 495.724 351.87 495.127 354.197 494.439C381.292 486.506 405.676 473.961 426.539 457.196C449.192 439.095 467.839 416.148 481.903 389.033C484.675 383.691 487.326 378.065 490.25 371.337C506.337 333.982 514.107 292.166 512.731 250.36C512.084 231.875 509.332 212.226 504.576 191.91C496.745 158.947 483.977 127.946 467.647 102.207C463.175 95.2963 459.067 89.4888 455 84.2681C425.648 46.4077 379.916 19.8387 322.792 7.47488C301.808 2.92193 280.237 0.615097 258.696 0.615097L258.686 0.60498Z'
        fill='white'
      />
      <Path
        d='M490.767 251.048C490.14 233.332 487.428 214.978 483.159 196.736C476.178 167.344 464.704 138.367 448.971 113.559C445.319 107.933 441.575 102.51 437.508 97.2995C408.005 59.257 363.295 38.0908 317.532 28.1856C266.56 17.1371 212.359 20.3646 163.359 38.5056C92.4748 64.7711 44.2135 122.31 28.4704 196.321C22.42 224.357 20.6494 256.046 23.3609 286.49H23.1485C24.7167 303.791 27.8431 320.678 32.5276 336.208C49.9401 393.747 92.2522 439.408 146.149 464.945C175.339 478.807 207.958 485.687 241.114 487.255C274.988 488.823 309.388 484.543 341.188 475.792C343.272 475.276 345.255 474.75 347.339 474.122C372.249 466.827 393.931 455.566 412.487 440.663C432.712 424.505 449.386 404.179 462.316 379.269C465.129 373.846 467.628 368.433 470.026 362.899C485.243 327.558 492.022 289.201 490.666 251.159L490.767 251.058V251.048Z'
        fill='#FFE9E3'
      />
      <Mask id='mask0_17041_10598' maskUnits='userSpaceOnUse' x='22' y='21' width='469' height='467'>
        <Path
          d='M490.767 251.048C490.14 233.332 487.428 214.978 483.159 196.736C476.178 167.344 464.704 138.367 448.971 113.559C445.319 107.933 441.575 102.51 437.508 97.2995C408.005 59.257 363.295 38.0908 317.532 28.1856C266.56 17.1371 212.359 20.3646 163.359 38.5056C92.4748 64.7711 44.2135 122.31 28.4704 196.321C22.42 224.357 20.6494 256.046 23.3609 286.49H23.1485C24.7167 303.791 27.8431 320.678 32.5276 336.208C49.9401 393.747 92.2522 439.408 146.149 464.945C175.339 478.807 207.958 485.687 241.114 487.255C274.988 488.823 309.388 484.543 341.188 475.792C343.272 475.276 345.255 474.75 347.339 474.122C372.249 466.827 393.931 455.566 412.487 440.663C432.712 424.505 449.386 404.179 462.316 379.269C465.129 373.846 467.628 368.433 470.026 362.899C485.243 327.558 492.022 289.201 490.666 251.159L490.767 251.058V251.048Z'
          fill='#FFE9E3'
        />
      </Mask>
      <G mask='url(#mask0_17041_10598)'>
        <Path
          d='M260.38 516.825C399.749 516.825 512.73 403.844 512.73 264.475C512.73 125.106 399.749 12.1248 260.38 12.1248C121.011 12.1248 8.03027 125.106 8.03027 264.475C8.03027 403.844 121.011 516.825 260.38 516.825Z'
          fill='#FFE9E3'
          stroke='#3BA5B5'
          stroke-miterlimit='10'
        />
        <Path
          d='M144.75 222.955C144.75 222.955 113.83 213.515 100.81 236.295C87.7904 259.075 88.7704 316.365 112.53 322.225C136.29 328.085 137.27 322.225 137.27 322.225L144.76 222.955H144.75Z'
          fill='#E77B56'
        />
        <Path
          d='M183.43 439.545C180.02 446.915 151.12 446.345 141.49 439.545C138.52 437.445 137.38 428.865 137.23 418.015C136.89 393.695 141.49 357.935 141.49 357.935L174.36 353.395L168.44 418.005L168.13 421.395C168.13 421.395 186.83 432.165 183.43 439.535V439.545Z'
          fill='#F59013'
        />
        <Path
          d='M183.43 439.545C180.02 446.915 151.12 446.345 141.49 439.545C138.52 437.445 137.38 428.865 137.23 418.015H168.44L168.13 421.405C168.13 421.405 186.83 432.175 183.43 439.545Z'
          fill='#E4744C'
        />
        <Path
          d='M232.311 439.545C228.901 446.915 200.001 446.345 190.371 439.545C187.401 437.445 186.261 428.865 186.111 418.015C185.771 393.695 190.371 357.935 190.371 357.935L223.241 353.395L217.321 418.005L217.011 421.395C217.011 421.395 235.711 432.165 232.311 439.535V439.545Z'
          fill='#F59013'
        />
        <Path
          d='M232.31 439.545C228.9 446.915 200 446.345 190.37 439.545C187.4 437.445 186.26 428.865 186.11 418.015H217.32L217.01 421.405C217.01 421.405 235.71 432.175 232.31 439.545Z'
          fill='#E4744C'
        />
        <Path
          d='M186.09 151.675C186.09 151.675 171.12 132.795 147.03 145.165C122.94 157.535 115.13 182.275 130.1 205.055C145.07 227.835 167.21 215.475 167.21 215.475L186.09 151.685V151.675Z'
          fill='#544022'
        />
        <Path
          d='M136.29 227.835C136.29 227.835 136.94 217.745 159.24 214.495C181.54 211.245 197.16 222.955 206.92 252.255C216.68 281.545 234.91 346.315 223.52 358.365C212.13 370.405 139.87 379.195 134.34 360.315C128.81 341.435 127.83 252.255 136.29 227.845V227.835Z'
          fill='#FFC522'
        />
        <Path
          d='M128.48 244.115C128.48 244.115 142.12 235.655 152.88 248.025C163.64 260.395 167.9 298.085 152.88 306.285C137.86 314.485 122.48 310.515 122.48 310.515L123.92 322.235C123.92 322.235 140.7 326.145 154.28 318.325C167.86 310.515 180.97 299.775 178.48 275.365C175.99 250.955 160.93 220.035 130.05 225.565C99.1802 231.095 128.47 244.115 128.47 244.115H128.48Z'
          fill='#E77B56'
        />
        <Path
          d='M145.75 260.215C145.75 260.215 157.1 269.655 178.57 257.935C200.04 246.215 201.71 249.475 204.22 254.025C206.73 258.585 214.53 279.735 204.22 286.895C193.91 294.055 144.28 308.705 137.59 304.475C130.9 300.245 145.75 261.835 145.75 261.835'
          fill='#FFC522'
        />
        <Path
          d='M179.25 156.075C181.26 156.135 189.99 155.585 194.87 167.795C199.75 180.005 206.59 192.205 203.17 203.925C199.75 215.645 176.32 229.805 159.23 206.855C142.14 183.905 163.14 155.595 179.25 156.075Z'
          fill='#A2633E'
        />
        <Path
          d='M156.14 196.345C156.14 196.345 164.93 177.065 175.34 172.505C185.75 167.945 197.15 171.205 197.47 168.595C197.79 165.985 193.24 143.855 170.78 152.645C148.32 161.435 140.21 186.985 147.48 197.885C153.86 207.455 162.26 201.355 156.13 196.335L156.14 196.345Z'
          fill='#E27A53'
        />
        <Path
          d='M160.271 189.755C160.271 189.755 151.431 180.965 148.011 189.755C144.591 198.545 159.241 206.845 159.241 206.845L160.271 189.755Z'
          fill='#966A40'
        />
        <Path
          d='M306.32 122.025C306.32 122.025 258.19 79.6647 270.77 73.8947C283.35 68.1347 334.71 120.015 334.71 120.015L306.32 122.025Z'
          fill='#A99881'
        />
        <Path
          d='M369.131 425.475C368.881 433.655 368.461 439.185 367.801 440.505C364.141 447.825 333.611 449.055 321.411 439.275C310.621 430.635 330.641 425.335 335.381 424.225H335.391C336.001 424.085 336.361 424.015 336.361 424.015C336.361 424.015 329.331 358.075 328.021 350.245C326.711 342.415 321.401 347.695 321.401 347.695C321.401 347.695 312.501 399.045 306.401 426.125C304.361 435.205 302.641 441.565 301.681 442.325C297.851 445.375 265.821 452.105 254.221 439.885C243.641 428.755 269.661 425.745 276.141 418.345C276.761 417.635 277.211 416.885 277.431 416.085C279.871 406.925 287.101 295.195 287.101 295.195H367.791C367.791 295.195 370.251 388.065 369.121 425.475H369.131Z'
          fill='#CEB9A7'
        />
        <Path
          d='M283.871 304.495V244.225C283.871 244.225 257.211 238.445 268.611 216.445C272.941 208.095 291.271 183.425 313.211 176.615C335.151 169.805 379.561 181.565 390.311 219.955C399.031 251.045 371.031 261.525 371.031 261.525V307.125C371.031 307.125 322.641 325.995 283.851 304.505L283.871 304.495Z'
          fill='#85CCD5'
        />
        <Path
          d='M305.3 103.795C305.3 103.795 285.2 103.095 293.55 131.655C301.89 160.215 305.3 103.795 305.3 103.795Z'
          fill='#523F20'
        />
        <Path
          d='M300.12 111.635C300.12 111.635 278.11 159.635 306.41 166.035C334.71 172.435 346.24 158.805 349.91 149.375C353.58 139.945 339.43 77.0548 300.12 111.645V111.635Z'
          fill='#E1A97F'
        />
        <Path
          d='M309.03 159.845C309.03 159.845 303.79 185.005 313.75 187.625C323.71 190.245 333.67 183.435 334.71 179.765C335.76 176.095 334.71 155.155 334.71 155.155L309.03 159.845Z'
          fill='#E1A97F'
        />
        <Path
          d='M284.091 231.295C284.091 231.295 278.481 217.335 270.081 218.055C261.681 218.775 257.721 257.535 263.431 267.115C269.141 276.695 289.811 286.685 307.651 280.265C325.491 273.845 299.901 260.165 291.961 257.795C280.011 254.225 284.081 231.305 284.081 231.305L284.091 231.295Z'
          fill='#E1A97F'
        />
        <Path
          d='M371.05 241.155C370.94 240.315 366.35 230.455 377.93 223.905C384.3 220.305 389.34 218.505 390.77 221.385C392.2 224.265 402.18 275.985 385.42 279.905C368.66 283.825 344.14 288.105 335.17 274.915C326.2 261.715 352.6 258.865 354.38 258.865C356.16 258.865 373.54 259.845 371.04 241.165L371.05 241.155Z'
          fill='#E1A97F'
        />
        <Path
          d='M295.53 108.335C295.53 108.335 301.63 96.1447 321.41 98.8447C341.19 101.555 362.951 140.445 362.951 140.445C362.951 140.445 369.01 158.515 355.82 162.675C342.62 166.825 330.94 171.685 329.94 148.285C329.12 128.955 291.971 119.395 295.541 108.335H295.53Z'
          fill='#523F20'
        />
        <Path
          d='M301.66 98.8447C301.66 98.8447 330.06 75.9947 350.46 89.0947C370.86 102.195 376.11 129.965 366.15 140.445C366.15 140.445 361.95 145.355 355.67 140.445C349.39 135.535 334.65 103.355 301.66 98.8447Z'
          fill='#A99881'
        />
        <Path
          d='M369.131 425.475C368.881 433.655 368.461 439.185 367.801 440.505C364.141 447.825 333.611 449.055 321.411 439.275C310.621 430.635 330.641 425.335 335.381 424.225C335.381 424.225 335.381 424.215 335.391 424.225C342.031 426.735 353.141 429.555 369.131 425.465V425.475Z'
          fill='#957C64'
        />
        <Path
          d='M306.41 426.135C304.37 435.215 302.65 441.575 301.69 442.335C297.86 445.385 265.83 452.115 254.23 439.895C243.65 428.765 269.67 425.755 276.15 418.355C280.9 422.965 290.17 428.445 306.41 426.135Z'
          fill='#957C64'
        />
      </G>
    </Svg>
  )
}
