import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

type GlobeTextBubbleIllustrationProps = {
  size?: number
}

export const GlobeTextBubbleIllustration: FunctionComponent<GlobeTextBubbleIllustrationProps> = ({
  size = 48,
  ...props
}) => {
  return (
    <Svg width={size} height={size} fill='none' viewBox={`0 0 ${size} ${size}`} {...props}>
      <Path
        fill='#FFD2C4'
        d='M47.978 23.93c.31 7.63-3.09 14.834-8.76 19.383-4.32 3.465-11.74 4.985-17.22 4.61-11.41-.79-20.59-8.886-21.87-20.922-1.57-14.732 11.5-28.309 25.71-26.9 8.08.8 21.4 5.532 22.14 23.83z'
      />
      <Path
        fill='#85CCD5'
        d='M39.008 25.786c-.42.03-.84.091-1.26.142-.1.01-.21.02-.31.04-5.51.78-10.64 3.354-13.83 7.315-.02.03-.05.061-.07.091-.32.406-.62.831-.91 1.267-.37.557-.72 1.125-1.05 1.712-1.84 3.303-2.87 7.123-2.93 11.115 1.09.223 2.2.395 3.34.476 1.26.091 2.63.071 4.04-.05 4.7-.386 9.86-1.895 13.19-4.56 3.45-2.766 6.05-6.515 7.5-10.75.63-1.844 1.05-3.779 1.21-5.765-2.63-.891-5.61-1.266-8.91-1.013l-.01-.02z'
      />
      <Path
        fill='#4D9116'
        d='M27.258 43.78c1.01-1.682 2.29-3.364 2.32-5.34.04-2.543-2.22-4.741-4.7-5.116a6.66 6.66 0 00-1.27-.05c-.02.03-.05.06-.07.09-.73.922-1.38 1.925-1.96 2.969-1.84 3.303-2.87 7.123-2.93 11.115 1.09.222 2.2.395 3.34.476 1.26.091 2.63.07 4.04-.05-.1-1.379.5-2.868 1.23-4.094zM36.318 33.313c.19.527.48 1.024.86 1.44.74.84 1.79 1.387 2.89 1.286 2.11-.182 3.44-2.452 5.42-3.202.4-.152.81-.223 1.23-.273.63-1.844 1.05-3.78 1.21-5.765-2.63-.892-5.61-1.267-8.91-1.014-.42.03-.84.092-1.26.142-.1.01-.21.02-.31.04-.37.72-.69 1.51-.97 2.422-.49 1.601-.72 3.354-.15 4.935l-.01-.01zM32.298 40.213c-.05.03-.11.07-.16.111-.98.78-3.14 3.982-1.17 4.722 1.55.577 3.09-1.398 3.45-2.655.37-1.317-.5-3.191-2.12-2.178z'
      />
      <Path
        fill='#fff'
        d='M23.278 9.757c-2.05-.628-4.2-.578-6.31-.517-2.52.081-5.18-.03-7.2 1.824-1.63 1.5-2.23 3.81-2.29 5.917-.06 2.198-.32 4.772.6 6.88 1.35 3.09 4.19 3.06 7.08 3.008 1.79-.03 3.75.051 5.58-.1.94.77 2.32 1.448 3.63 1.853.18.061.34-.121.25-.294a6.615 6.615 0 01-.73-2.198c2.13-.79 3.61-2.442 3.61-5.887 0-4.255.68-8.997-4.22-10.496v.01z'
      />
      <Path
        fill='#85CCD5'
        d='M10.358 17.447c-.8 1.398 1.44 2.564 2.12 1.135.27-.557.02-1.317-.52-1.6-.54-.285-1.29-.072-1.6.475v-.01zM17.798 17.204c-1.11-1.155-2.82.699-1.65 1.763.46.415 1.24.375 1.66-.071.42-.446.42-1.246-.01-1.692zM22.598 19.24c1.59-.11 1.3-2.633-.25-2.41-.61.08-1.09.709-1.02 1.327.07.618.66 1.124 1.28 1.084h-.01z'
      />
    </Svg>
  )
}
