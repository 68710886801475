import React from 'react'
import styles from './availabilityCard.module.scss'
import { dateUtils, DefaultButton, CardFullWidth } from '@lyrahealth-inc/ui-core'
import { size } from 'lodash-es'
import { programConfig, programNameTitleMap } from '../../constants/appConstants'
import { programIconMap } from '../../constants/programIconMap'
import { ProviderUser, Capacity } from '@lyrahealth-inc/shared-app-logic'

const CapacitiesFull: React.FC<CapacitiesFullProps> = ({
  provider,
  selectedProvider,
  currentAvailabilityStatusText,
  handleUpdateClick,
  shouldRequestAvailability,
  isCapacityProvider,
}) => {
  const renderCapacities = (capacities: Capacity) => {
    return Object.entries(capacities).map(([programName]) => {
      const ProgramIcon = programConfig[programName]?.icon && programIconMap[programConfig[programName].icon]
      return (
        <div className={styles['capacity-card']} key={programName}>
          {size(provider.capacity_value) > 1 && (
            <div className={styles['icon-container']}>{ProgramIcon && <ProgramIcon width={50} />}</div>
          )}
          <div className={styles['session-info']}>
            <p data-test-id='AvailabilityCard-programCapacity'>
              {size(provider.capacity_value) > 1 && `${programNameTitleMap[programName]} -`}{' '}
              {currentAvailabilityStatusText(programName)}
            </p>
            {provider.capacity_updated_at && (
              <p className={styles['update-date']}>
                Last updated {dateUtils.getFriendlyDate(provider.capacity_updated_at)}
              </p>
            )}
          </div>
        </div>
      )
    })
  }

  return (
    <>
      <div className={styles['header-container']}>
        <h3>{`${selectedProvider ? 'Provider' : ''} Availability`}</h3>
        {(!shouldRequestAvailability || isCapacityProvider) && (
          <DefaultButton
            data-test-id='AvailabilityButton-update'
            style={{ whiteSpace: 'normal' }}
            onClick={handleUpdateClick}
          >
            Update
          </DefaultButton>
        )}
      </div>
      <CardFullWidth className={styles['card-container']} style={{ padding: 0 }} roundCorners>
        {renderCapacities(provider.capacity_value)}
      </CardFullWidth>
    </>
  )
}

type CapacitiesFullProps = {
  provider: ProviderUser
  selectedProvider: ProviderUser
  currentAvailabilityStatusText: (program_name: string) => React.ReactNode
  handleUpdateClick: () => void
  shouldRequestAvailability: boolean
  isCapacityProvider: boolean
}

export default CapacitiesFull
