import React from 'react'

import styled from 'styled-components/native'

import { appointmentUtilization, targetUtilization, UtilizationCharges } from '@lyrahealth-inc/shared-app-logic'
import {
  BodyText,
  BodyTextSize,
  colors,
  DataBoard,
  DataBoardItem,
  InfoIcon,
  Subhead,
  SubheadSize,
  ThemeType,
  tID,
  Tooltip,
} from '@lyrahealth-inc/ui-core-crossplatform'

import { employmentTypes, ROLES } from '../../common/constants/appConstants'
import { hasRole } from '../../common/utils/utils'

const HeaderContainer = styled.View({
  display: 'flex',
  flexDirection: 'column',
})

const SubtextContainer = styled.View({
  flexDirection: 'row',
  alignItems: 'center',
})

const StyledTooltip = styled(Tooltip)<{ theme: ThemeType }>(({ theme }) => ({
  marginLeft: theme.spacing['4px'],
}))

const DataBoardSection = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  paddingTop: theme.spacing['24px'],
  display: 'flex',
  flexDirection: 'row',
}))

const StatusContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexGrow: 1,
  paddingRight: theme.spacing['12px'],
}))

const WeeklyScheduleTargetsContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexGrow: 2,
  paddingLeft: theme.spacing['12px'],
}))

export const QuarterlySnapshot: React.FC<QuarterlySnapshotProps> = ({
  appointmentUtilization: {
    completedThisQuarter = 0,
    // totalWeekCount = 0,
    // currentWeekCount = 0,
    // completedUpToCurrentWeek = 0,
    // providerNoFaultUpToCurrentWeek = 0
  } = {},
  employmentStatus,
  targetUtilization: {
    // quarterSessionTarget = 0,
    // quarterSessionTargetPlus = 0,
    quarterPaidTimeOff = 0,
    providerUtilizationPercentage = 0,
  } = {},
  showMinified,
  userRoles,
  hideWeeklySchedules = false,
  utilizationCharges,
}) => {
  // PROVIDER-3161 Short term copy edits. We'll put this back later
  // const targetRecommendation = Math.ceil(
  //   (quarterSessionTarget - (completedUpToCurrentWeek + providerNoFaultUpToCurrentWeek)) /
  //     (totalWeekCount - (currentWeekCount - 1))
  // )
  // const targetPlusRecommendation = Math.ceil(
  //   (quarterSessionTargetPlus - (completedUpToCurrentWeek + providerNoFaultUpToCurrentWeek)) /
  //     (totalWeekCount - (currentWeekCount - 1))
  // )
  let targetRecommendation
  let targetPlusRecommendation
  switch (employmentStatus) {
    case 'PT':
    case 'CQC':
      targetRecommendation = 14
      targetPlusRecommendation = 15
      break
    case 'ePT':
      targetRecommendation = 19
      targetPlusRecommendation = 20
      break
    case 'FT':
    case 'FT-R':
      targetRecommendation = 29
      targetPlusRecommendation = '30+'
      break
    case 'LCF Supervisor':
      targetRecommendation = 24
      targetPlusRecommendation = 26
  }

  return (
    <div>
      <div>
        <HeaderContainer>
          <Subhead text={'Quarterly snapshot'} size={SubheadSize.MEDIUM} color={colors.ui_oatmeal6} />
          {/* NON-PT/FT coaches see the legacy dashboard but should not see any references to PTO */}
          {(!hasRole(userRoles, [ROLES.LT_COACH, ROLES.LT_SS_COACH]) ||
            [employmentTypes.FULL_TIME, employmentTypes.PART_TIME].includes(employmentStatus)) && (
            <BodyText
              size={BodyTextSize.LARGE}
              color={colors.ui_oatmeal4}
              testID={tID('QuarterlySnapshot-pto-subtext')}
              text={
                <SubtextContainer>
                  {`${quarterPaidTimeOff} ${quarterPaidTimeOff === 1 ? `hour` : 'hours'} of scheduled PTO`}
                  <StyledTooltip
                    content='PTO you have indicated in Google calendar this quarter based on your working hours.'
                    hoverEnabled
                  >
                    <InfoIcon size={18} />
                  </StyledTooltip>
                </SubtextContainer>
              }
            />
          )}
        </HeaderContainer>
        <DataBoardSection>
          {showMinified ? (
            <DataBoard label='Total' backgroundColor={colors.ui_oatmeal2}>
              <DataBoardItem
                value={completedThisQuarter + (utilizationCharges?.completedThisQuarter ?? 0)}
                label='attended sessions'
              />
            </DataBoard>
          ) : (
            <>
              <StatusContainer>
                <DataBoard
                  label='Status'
                  backgroundColor={colors.ui_oatmeal2}
                  tooltipContent='This snapshot is updated every Sunday while the rest of the dashboard is updated in real-time.'
                >
                  <DataBoardItem
                    value={providerUtilizationPercentage === null ? 'N/A' : `${providerUtilizationPercentage}%`}
                    label='caseload percentage'
                  />
                </DataBoard>
              </StatusContainer>
              {!hideWeeklySchedules && (
                <WeeklyScheduleTargetsContainer testID={tID('QuarterlySnapshot-weeklyScheduleTargetsContainer')}>
                  <DataBoard label='Weekly scheduled targets' backgroundColor={colors.white}>
                    <DataBoardItem
                      // PROVIDER-3161 Short term copy edits. We'll put this back later
                      // value={targetRecommendation > 1 ? targetRecommendation : String.fromCodePoint(127881)}
                      value={targetRecommendation as number}
                      label='Target'
                      labelTooltip={
                        <Tooltip
                          content='This is the average number of sessions recommended to schedule each week of the quarter to meet Target. For a tailored weekly recommendation based on your PTO schedule, please use your caseload calculator.'
                          hoverEnabled
                        >
                          <InfoIcon size={18} />
                        </Tooltip>
                      }
                    />
                    <DataBoardItem
                      // PROVIDER-3161 Short term copy edits. We'll put this back later
                      // value={targetPlusRecommendation > 1 ? targetPlusRecommendation : String.fromCodePoint(127881)}
                      value={targetPlusRecommendation as string}
                      label='Target Plus'
                      labelTooltip={
                        <Tooltip
                          content='This is the average number of sessions recommended to schedule each week of the quarter to meet Target Plus. For a tailored weekly recommendation based on your PTO schedule, please use your caseload calculator.'
                          hoverEnabled
                        >
                          <InfoIcon size={18} />
                        </Tooltip>
                      }
                    />
                  </DataBoard>
                </WeeklyScheduleTargetsContainer>
              )}
            </>
          )}
        </DataBoardSection>
      </div>
    </div>
  )
}

type QuarterlySnapshotProps = {
  appointmentUtilization: appointmentUtilization
  employmentStatus: string
  targetUtilization?: targetUtilization
  showMinified: boolean
  userRoles: string[]
  hideWeeklySchedules: boolean
  utilizationCharges?: UtilizationCharges
}

export default QuarterlySnapshot
