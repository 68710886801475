import React, { FunctionComponent } from 'react'
import Svg, { Circle, Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { tID } from '../../utils'

/**
 * Standard circle icon used in PasswordChecklist
 */
export const CircleIcon: FunctionComponent<CirconIconProps> = ({ size = 16, fillColor, isFilled = false }) => {
  const { colors } = useTheme()

  return (
    <Svg width={size} height={size} fill='none' viewBox='0 0 15 15' testID={tID('CircleIcon')}>
      {isFilled ? (
        <Circle cx={7.5} cy={7.5} r={7.5} fill={fillColor || colors.iconInactive} />
      ) : (
        <Path
          fill={fillColor || colors.iconInactive}
          fillRule='evenodd'
          d='M0.877075 7.49991C0.877075 3.84222 3.84222 0.877075 7.49991 0.877075C11.1576 0.877075 14.1227 3.84222 14.1227 7.49991C14.1227 11.1576 11.1576 14.1227 7.49991 14.1227C3.84222 14.1227 0.877075 11.1576 0.877075 7.49991ZM7.49991 1.82708C4.36689 1.82708 1.82708 4.36689 1.82708 7.49991C1.82708 10.6329 4.36689 13.1727 7.49991 13.1727C10.6329 13.1727 13.1727 10.6329 13.1727 7.49991C13.1727 4.36689 10.6329 1.82708 7.49991 1.82708Z'
          clipRule='evenodd'
        />
      )}
    </Svg>
  )
}

type CirconIconProps = {
  size?: string | number
  fillColor?: string
  isFilled?: boolean
}
