export default {
  meta_data: {
    schema: {
      type: 'object',
      properties: {
        icd10s: {
          name: 'icd10s',
          type: 'string',
          condition: {
            parentField: 'attendance',
            parentValue: 'attended',
          },
        },
      },
    },
    uiSchema: {
      'ui:order': ['icd10s'],
      icd10s: {
        'ui:field': 'icd10s',
      },
    },
    initialValues: {
      is_health_plan: true,
    },
  },
}
