import React from 'react'
import colors from '../../../styles/_0colors.scss'
import { Icon } from './types'

const InfoIcon: React.FC<InfoIconProps> = ({
  width = 11,
  fillColor = colors.x_primary_action,
  isFilled = false,
  ...rest
}) => {
  return (
    <svg width={width} viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...rest}>
      {isFilled ? (
        <path
          fill={fillColor}
          d='M12,2 C17.523,2 22,6.477 22,12 C22,17.523 17.523,22 12,22 C6.477,22 2,17.523 2,12 C2,6.477 6.477,2 12,2 Z M12.75,17 L12.75,11 C12.75,10.586 12.414,10.25 12,10.25 C11.586,10.25 11.25,10.586 11.25,11 L11.25,17 C11.25,17.414 11.586,17.75 12,17.75 C12.414,17.75 12.75,17.414 12.75,17 Z M12,8.5 C12.552,8.5 13,8.052 13,7.5 C13,6.948 12.552,6.5 12,6.5 C11.448,6.5 11,6.948 11,7.5 C11,8.052 11.448,8.5 12,8.5 Z'
        />
      ) : (
        <path
          fill={fillColor}
          d='M12,2 C17.523,2 22,6.477 22,12 C22,17.523 17.523,22 12,22 C6.477,22 2,17.523 2,12 C2,6.477 6.477,2 12,2 M12,3.5 C7.313,3.5 3.5,7.313 3.5,12 C3.5,16.687 7.313,20.5 12,20.5 C16.687,20.5 20.5,16.687 20.5,12 C20.5,7.313 16.687,3.5 12,3.5 M12.75,17 C12.75,17.414 12.414,17.75 12,17.75 C11.586,17.75 11.25,17.414 11.25,17 L11.25,11 C11.25,10.586 11.586,10.25 12,10.25 C12.414,10.25 12.75,10.586 12.75,11 L12.75,17 Z M12,8.5 C12.552,8.5 13,8.052 13,7.5 C13,6.948 12.552,6.5 12,6.5 C11.448,6.5 11,6.948 11,7.5 C11,8.052 11.448,8.5 12,8.5'
        />
      )}
    </svg>
  )
}

type InfoIconProps = Icon

export default InfoIcon
