// Reducers
import { combineReducers } from '@reduxjs/toolkit'

import data from './providersDataReducer'
import providersDashboard from '../dashboard/data/providersDashboardReducer'
import providerDetails from '../individualProvider/data/providerDetailsReducer'
import register from '../register/data/registerProviderReducer'

export default combineReducers({
  providersDashboard,
  providerDetails,
  register,
  data,
})
