import React from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { useTheme } from 'styled-components/native'

import { BodyText, PrimaryButton, Subhead, SubheadSize, tID } from '@lyrahealth-inc/ui-core-crossplatform'

import { actions, mixpanelEvents } from '../../../../mixpanel/mixpanelConstants'
import { track } from '../../../../mixpanel/mixpanelTracking'
import { SCHEDULING } from '../../common/constants/routingConstants'
import { BodyTextContainer, ButtonContainer, HeaderContainer, WalkthroughContentContainer } from '../constants'
import { getSchedulingAuthUrl } from '../data/schedulingSelectors'

const SchedulingWalkthroughFinal: React.FC<SchedulingWalkthroughFinalProps> = ({ schedulingAuthUrl }) => {
  const navigate = useNavigate()
  const { colors } = useTheme()

  const onNextStep = () => {
    track({ event: mixpanelEvents.BUTTON_PRESS, action: actions.SCHEDULING_WALKTHROUGH_GOOGLE_CONNECTION })
    // This is to allow us to mock the redirect to google, this will shortcut to the callback url route with mock code.
    if (schedulingAuthUrl.startsWith(SCHEDULING.route)) {
      navigate(schedulingAuthUrl)
    } else {
      window.open(schedulingAuthUrl, '_self')
    }
  }

  return (
    <WalkthroughContentContainer>
      <HeaderContainer>
        <Subhead
          level='2'
          size={SubheadSize.LARGE}
          textAlign='center'
          text='Great! Let’s connect Lyra to your Google Calendar'
          color={colors.textPrimary}
        />
      </HeaderContainer>
      <BodyTextContainer>
        <BodyText
          color={colors.textSecondary}
          textAlign='center'
          text='In the following screens, please allow Lyra to connect to your Google Calendar. This will let Lyra see booked appointment times and show your availability to Lyra clients.'
        />
      </BodyTextContainer>
      <ButtonContainer>
        <PrimaryButton text='Continue' onPress={onNextStep} testID={tID('CompleteWalkthrough-button')} />
      </ButtonContainer>
    </WalkthroughContentContainer>
  )
}

type SchedulingWalkthroughFinalProps = {
  schedulingAuthUrl: string
}

const mapStateToProps = ($$state: any) => {
  return {
    schedulingAuthUrl: getSchedulingAuthUrl($$state),
  }
}

export default connect(mapStateToProps, null)(SchedulingWalkthroughFinal)
