import React from 'react'
import CSSModules from 'react-css-modules'
import { connect } from 'react-redux'

import * as _ from 'lodash-es'
import { bindActionCreators } from 'redux'

import {
  BaseModal,
  CheckIcon,
  DataTable,
  ExclamationIcon,
  LoadingIndicator,
  SecondaryButton,
} from '@lyrahealth-inc/ui-core'

import styles from './practiceProviders.module.scss'
import { ROLES } from '../../../../common/constants/appConstants'
import { GET_PROVIDERS_FOR_PRACTICE } from '../../../../common/constants/reduxConstants'
import { PROVIDERS_DETAILS_INFO } from '../../../../common/constants/routingConstants'
import { hasErrorForAction, hasRole } from '../../../../common/utils/utils'
import { getAlertsState } from '../../../../data/alertsSelectors'
import { getAuthRoles } from '../../../../data/auth/authSelectors'
import * as practiceDetailsActions from '../../../../practices/individualPractice/data/practiceDetailsActions'
import * as providerDetailsActions from '../../../../providers/individualProvider/data/providerDetailsActions'
import withRouter from '../../../../routing/withRouter'
import {
  getPracticeDetailsAddProviderModalShown,
  getPracticeDetailsProviders,
} from '../../data/practiceDetailsSelectors'
import AddProviderView from '../AddProviderView/AddProviderView'
import modalStyles from '../AddProviderView/addProviderView.module.scss'

const tableColumns = [
  {
    Header: 'Name',
    accessor: 'full_name',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Rates Set',
    accessor: 'rates',
    maxWidth: 130,
    Cell: (row: any) => (
      <span>
        {!_.isEmpty(row.value) ? (
          <CheckIcon fillColor={styles.x_success} isFilled width={15} />
        ) : (
          <ExclamationIcon isFilled width={15} />
        )}
      </span>
    ),
  },
  {
    Header: 'Calendar Status',
    accessor: 'calendar_state',
  },
]

type PracticeProvidersProps = {
  practiceID?: string
  actions?: any
  router?: any
  providers?: any // TODO: PropTypes.instanceOf(List)
  alerts?: any // TODO: PropTypes.instanceOf(List)
  roles?: any // TODO: PropTypes.instanceOf(List)
  addProviderModalShown?: boolean
}

export class PracticeProviders extends React.Component<PracticeProvidersProps> {
  componentDidMount() {
    const { providers, practiceID, actions, router } = this.props
    if (!providers) {
      const practiceId = practiceID || (router.location.state && router.location.state.practiceId)
      // get practice providers list since we don't yet have it
      actions.getProvidersForPractice(practiceId)
    }
  }

  _rowClickHandler = (state: any, rowInfo: any) => {
    this.props.actions.providerSelected(rowInfo.original)
    this.props.router.navigate(PROVIDERS_DETAILS_INFO.route, {
      state: {
        provider_id: rowInfo.original.lyra_id,
        practiceId: this.props.practiceID,
      },
    })
  }

  _submitAddProvider = ($$values: any) => {
    const {
      actions: { addProviderToPractice },
      practiceID,
    } = this.props
    const formData = new FormData()
    const vals = $$values.toJS()
    Object.keys(vals).forEach((key) => {
      const val = vals[key]
      formData.append(key, val)
    })
    return addProviderToPractice(practiceID, formData)
  }

  render() {
    const {
      addProviderModalShown,
      roles,
      actions: { toggleAddProviderModal },
      alerts,
      providers,
    } = this.props
    // @ts-expect-error TS(2322): Type '{ submitAddProvider: ($$values: any) => any;... Remove this comment to see the full error message
    const AddProvider = <AddProviderView submitAddProvider={this._submitAddProvider} />

    if (!providers) {
      if (hasErrorForAction(alerts, GET_PROVIDERS_FOR_PRACTICE)) {
        return false // custom workflow or display could render here
      } else {
        return (
          <div styleName='content-container'>
            <div styleName='loading-container'>
              <LoadingIndicator size={45} />
            </div>
          </div>
        )
      }
    }

    return (
      <div styleName='content-container'>
        <div styleName='button-container'>
          {hasRole(roles, ROLES.PROVIDERS_ADMIN) ? (
            <SecondaryButton
              id='add-provider'
              onClick={() => {
                toggleAddProviderModal(true)
              }}
            >
              Add a Provider
            </SecondaryButton>
          ) : (
            []
          )}
        </div>

        <DataTable
          // @ts-expect-error TS(2322): Type '(state: any, rowInfo: any) => void' is not a... Remove this comment to see the full error message
          rowClickHandler={this._rowClickHandler}
          destinationSelector='lyra_id'
          data={providers}
          pageSize={providers.length}
          columns={tableColumns}
        />
        <BaseModal
          // @ts-expect-error TS(2322): Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
          isOpen={addProviderModalShown}
          body={AddProvider}
          modalClass='add-provider-modal'
          customStyles={modalStyles}
          size='large'
          closeModal={() => {
            toggleAddProviderModal(false)
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ($$state: any) => {
  return {
    providers: getPracticeDetailsProviders($$state),
    alerts: getAlertsState($$state),
    roles: getAuthRoles($$state),
    addProviderModalShown: getPracticeDetailsAddProviderModalShown($$state),
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    actions: bindActionCreators({ ...providerDetailsActions, ...practiceDetailsActions }, dispatch),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CSSModules(PracticeProviders, styles)))
