import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { ThemeType } from '../../utils'

export const MaximizeIcon: FunctionComponent<MaximizeIconProps> = ({ size = 24, fillColor }) => {
  const { colors } = useTheme() as ThemeType

  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' fill='none'>
      <Path
        fill={fillColor ?? colors.iconDefault}
        fillRule='evenodd'
        d='M5.5 7.239v2.184a.75.75 0 0 1-1.5 0v-4a.75.75 0 0 1 .75-.75h4a.75.75 0 0 1 0 1.5H6.556l3.027 3.05a.75.75 0 1 1-1.065 1.057L5.5 7.239Zm3.25 11.434H6.556l3.027-3.05a.75.75 0 0 0-1.065-1.056L5.5 17.607v-2.183a.75.75 0 0 0-1.5 0v4c0 .414.336.75.75.75h4a.75.75 0 1 0 0-1.5Zm9.25-3.25v2.185l-3.018-3.04a.75.75 0 1 0-1.064 1.056l3.026 3.05H14.75a.75.75 0 0 0 0 1.5h4a.75.75 0 0 0 .75-.75v-4a.75.75 0 0 0-1.5 0Zm-3.25-9.25h2.194l-3.026 3.05a.75.75 0 0 0 1.064 1.057L18 7.239v2.184a.75.75 0 0 0 1.5 0v-4a.75.75 0 0 0-.75-.75h-4a.75.75 0 0 0 0 1.5Z'
        clipRule='evenodd'
      />
    </Svg>
  )
}

type MaximizeIconProps = {
  size?: string | number
  fillColor?: string
}
