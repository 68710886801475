import {
  GET_MESSAGE_CONVERSATIONS,
  GET_MESSAGES,
  SET_SELECTED_CONVERSATION,
  ADD_MESSAGE,
  UPDATE_UNREAD_MESSAGE_COUNT,
  INCREMENT_UNREAD_MESSAGE_COUNT,
  CLEAR_SELECTED_CONVERSATION,
  SET_CONVERSATIONS_CLIENT,
  TOGGLE_LIVE_MSG_SESSION,
  SET_ACTIVE_LIVE_MSG_SESSION,
  SET_LIVE_MSG_CLIENT_ID_TO_OPEN,
  SAVE_MESSAGE_DRAFT,
  UPDATE_SELECTED_CONVERSATION_ID,
  SUBMIT_PROVIDER_OOO_MESSAGE,
} from '../../../common/constants/reduxConstants'

export const getMessageConversations = ({
  provider_lyra_id,
  patient_lyra_id,
  derived_properties,
  cancelToken,
}: any) => ({
  action: GET_MESSAGE_CONVERSATIONS,
  request: {
    method: 'get',
    url: '/lt/v1/messaging/conversations',
    params: { provider_lyra_id, patient_lyra_id, derived_properties },
    cancelToken,
  },
  errorHandler: () => {},
})

export const getAccessToken = ({ cancelToken }: any) => ({
  request: {
    method: 'get',
    url: '/lt/v1/messaging/accessToken',
    cancelToken,
  },
})

export const setConversationsClient = (data: any) => ({
  type: SET_CONVERSATIONS_CLIENT,
  data,
})

export const getMessageConversation = ({ patient_lyra_id, provider_lyra_id, cancelToken }: any) => ({
  action: SET_SELECTED_CONVERSATION,
  request: {
    method: 'get',
    url: '/lt/v1/messaging/conversations',
    params: { provider_lyra_id, patient_lyra_id },
    cancelToken,
  },
})

export const getMessages = ({ conversationId }: any) => ({
  action: GET_MESSAGES,
  request: {
    method: 'get',
    url: `/lt/v1/messaging/conversations/${conversationId}/messages`,
  },
})

export const selectMessageConversation = (data: any) => ({
  type: SET_SELECTED_CONVERSATION,
  data,
})

export const submitMessage = ({ conversationId, ...data }: any) => ({
  action: ADD_MESSAGE,
  request: {
    method: 'post',
    url: `/lt/v1/messaging/conversations/${conversationId}/messages`,
    data,
  },
})

export const getMessage = ({ conversationId, messageId }: any) => ({
  action: ADD_MESSAGE,
  request: {
    method: 'get',
    url: `/lt/v1/messaging/conversations/${conversationId}/messages/${messageId}`,
  },
})

// The 2 actions below make the same requests as the ones above but does not update the store.
// Useful when the message is not in the context of the message thread. (Exple: provider feedback on assignment)

export const sendMessage = (data: any) => ({
  request: {
    method: 'post',
    url: `/lt/v1/messaging/conversations/${data.conversationId}/messages`,
    data,
  },
})

export const fetchMessage = ({ conversationId, messageId }: any) => ({
  request: {
    method: 'get',
    url: `/lt/v1/messaging/conversations/${conversationId}/messages/${messageId}`,
  },
})

export const updateUnreadMessageCount = ({ conversationId, ...data }: any) => ({
  action: { type: UPDATE_UNREAD_MESSAGE_COUNT, conversationId },
  request: {
    method: 'patch',
    url: `/lt/v1/messaging/conversations/${conversationId}/attributes`,
    data,
  },
})

export const incrementUnreadMessageCount = (data: any) => ({
  type: INCREMENT_UNREAD_MESSAGE_COUNT,
  data,
})

export const clearSelectedConversation = () => ({
  type: CLEAR_SELECTED_CONVERSATION,
})

export const updateSelectedConversationId = (data: any) => {
  return {
    type: UPDATE_SELECTED_CONVERSATION_ID,
    data,
  }
}

export const toggleLiveMsgSession = (bool: any) => ({
  type: TOGGLE_LIVE_MSG_SESSION,
  payload: bool,
})

export const setActiveLiveMsgSession = (data: any) => {
  return {
    type: SET_ACTIVE_LIVE_MSG_SESSION,
    payload: data,
  }
}

export const setLiveMsgClientIdToOpen = (string: any) => {
  return {
    type: SET_LIVE_MSG_CLIENT_ID_TO_OPEN,
    payload: string,
  }
}

export const saveMessageDraft = (data: any) => {
  return {
    type: SAVE_MESSAGE_DRAFT,
    data,
  }
}

export const saveOOOMessage = (data: any) => ({
  action: SUBMIT_PROVIDER_OOO_MESSAGE,

  request: {
    method: 'post',
    url: '/lt/v1/messaging/conversations/bulk_message',
    data,
  },
})
