import React, { FunctionComponent } from 'react'

import jugglingIllustrationUrl from '../../assets/jugglingIllustration.png'
import { Image } from '../image/Image'

interface JugglingIllustrationProps {
  width?: number
  height?: number
}

export const JugglingIllustration: FunctionComponent<JugglingIllustrationProps> = ({
  width = 185,
  height = 196,
  ...props
}) => {
  return (
    <Image
      source={jugglingIllustrationUrl}
      style={{ width, height }}
      contentFit='contain'
      accessibilityIgnoresInvertColors
      {...props}
    />
  )
}
