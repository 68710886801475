import { uniq } from 'lodash-es'

import { Episode } from '@lyrahealth-inc/shared-app-logic'
import { PROGRAM_TAXONOMY } from '@lyrahealth-inc/shared-app-logic/src/features/common/constants/customerProgram'

export const ROLES = {
  PRACTICES_ADMIN: 'ROLE_practice_admin',
  PAYMENTS_ADMIN: 'ROLE_payment_admin',
  PROVIDERS_ADMIN: 'ROLE_provider_admin',
  RECONCILER: 'ROLE_reconciler',
  VIEW_ONLY: 'ROLE_view_only',
  LT_SUPERVISOR: 'ROLE_lt_supervisor',
  LT_PRESCRIBER: 'ROLE_lt_prescriber',
  LT_PRESCRIBER_SUPERVISOR: 'ROLE_lt_prescriber_supervisor',
  LT_THERAPIST: 'ROLE_lt_therapist',
  LT_COACH: 'ROLE_lt_coach',
  LT_COACH_SUPERVISOR: 'ROLE_lt_coach_supervisor',
  LT_SS_COACH: 'ROLE_lt_ss_coach',
  LT_SS_COACH_SUPERVISOR: 'ROLE_lt_ss_coach_supervisor',
  LT_AUD_THERAPIST_SUPERVISOR: 'ROLE_lt_aud_therapist_supervisor',
  LT_AUD_THERAPIST: 'ROLE_lt_aud_therapist',
  INDIVIDUAL_PROVIDER: 'ROLE_provider',
  PRACTICE_PROVIDER: 'ROLE_practice_provider',
  LT_FELLOW_SUPERVISOR: 'ROLE_lt_fellow_supervisor',
  LT_FELLOW_THERAPIST: 'ROLE_lt_fellow_therapist',
  ICAS_PROVIDER: 'ROLE_icas_therapist',
  LT_CLINICAL_LEAVE_EVALUATOR: 'ROLE_lt_clinical_leave_evaluator',
  LT_TEENS_THERAPIST_SUPERVISOR: 'ROLE_lt_teens_therapist_supervisor',
  LT_TEENS_THERAPIST: 'ROLE_lt_teens_therapist',
}

export const SUPERVISOR_ROLES = [
  ROLES.LT_SUPERVISOR,
  ROLES.LT_PRESCRIBER_SUPERVISOR,
  ROLES.LT_COACH_SUPERVISOR,
  ROLES.LT_SS_COACH_SUPERVISOR,
  ROLES.LT_AUD_THERAPIST_SUPERVISOR,
  ROLES.LT_FELLOW_SUPERVISOR,
  ROLES.LT_TEENS_THERAPIST_SUPERVISOR,
]

export const BCT_ROLES = [
  ROLES.LT_THERAPIST,
  ROLES.LT_SUPERVISOR,
  ROLES.LT_FELLOW_THERAPIST,
  ROLES.LT_FELLOW_SUPERVISOR,
  ROLES.LT_TEENS_THERAPIST,
  ROLES.LT_TEENS_THERAPIST_SUPERVISOR,
]

export const BCC_ROLES = [ROLES.LT_COACH, ROLES.LT_COACH_SUPERVISOR, ROLES.LT_SS_COACH, ROLES.LT_SS_COACH_SUPERVISOR]

// This config map is used to set the user config in the store upon login.
// If a user has multiple roles, they will get merged together.
// Exple: A Guided Self-Care Coach will have the config properties of ROLE_provider and ROLE_lt_coach
export const config = {
  // DA Provider (All providers have this role, but DA provider have *only* this role)
  [ROLES.INDIVIDUAL_PROVIDER]: {
    bcPlatform: false,
    videoSessionsEnabled: false,
    medicationEnabled: false,
    zendeskWidget: true,
    directAccessClientList: true,
    requestPayment: true,
    eligibilityChecker: true,
    showOverdueMessageFlag: true,
    showInPersonPrompt: true,
    smartSchedulingEnabled: true,
  },
  [ROLES.ICAS_PROVIDER]: {
    sameDayBooking: false,
    bcPlatform: false,
    videoSessionsEnabled: false,
    medicationEnabled: false,
    zendeskWidget: true,
    directAccessClientList: true,
    requestPayment: false,
    eligibilityChecker: false,
    showOverdueMessageFlag: false,
    helpCenterUrl: 'https://icas-provider-support.zendesk.com/hc/',
    showInPersonPrompt: false,
    smartSchedulingEnabled: false,
  },
  [ROLES.LT_THERAPIST]: {
    sameDayBooking: true,
    bcPlatform: true,
    videoSessionsEnabled: true,
    medicationEnabled: false,
    zendeskWidget: false,
    requestPayment: false,
    directAccessClientList: false,
    helpCenterUrl: 'https://bct-lyrahealth.zendesk.com/hc/en-us',
    eligibilityChecker: false,
    utilizationEnabled: true,
    showMinifiedUtilization: false,
    showOverdueMessageFlag: false,
    showInPersonPrompt: false,
    smartSchedulingEnabled: false,
  },
  [ROLES.LT_PRESCRIBER]: {
    sameDayBooking: true,
    bcPlatform: true,
    videoSessionsEnabled: true,
    medicationEnabled: true,
    zendeskWidget: false,
    requestPayment: false,
    directAccessClientList: false,
    helpCenterUrl: 'https://blended-care-physicians-hub.zendesk.com/hc/en-us',
    eligibilityChecker: false,
    utilizationEnabled: false,
    showMinifiedUtilization: true,
    showOverdueMessageFlag: true,
    showInPersonPrompt: false,
    smartSchedulingEnabled: false,
  },
  [ROLES.LT_CLINICAL_LEAVE_EVALUATOR]: {
    sameDayBooking: true,
    bcPlatform: true,
    videoSessionsEnabled: true,
    medicationEnabled: true,
    zendeskWidget: false,
    requestPayment: false,
    helpCenterUrl: 'https://blended-care-physicians-hub.zendesk.com/hc/en-us',
    eligibilityChecker: false,
    utilizationEnabled: false,
    showMinifiedUtilization: true,
    showOverdueMessageFlag: true,
    showInPersonPrompt: false,
    smartSchedulingEnabled: false,
  },
  [ROLES.LT_COACH]: {
    sameDayBooking: true,
    bcPlatform: true,
    videoSessionsEnabled: true,
    medicationEnabled: false,
    zendeskWidget: false,
    requestPayment: false,
    directAccessClientList: false,
    helpCenterUrl: 'https://coaching-lyrahealth.zendesk.com/hc/en-us',
    eligibilityChecker: false,
    utilizationEnabled: true,
    showMinifiedUtilization: true,
    showOverdueMessageFlag: true,
    showInPersonPrompt: false,
    smartSchedulingEnabled: false,
  },
  [ROLES.LT_SS_COACH]: {
    sameDayBooking: true,
    eligibilityChecker: false,
    directAccessClientList: false,
    showInPersonPrompt: false,
    smartSchedulingEnabled: false,
  },
  [ROLES.LT_FELLOW_THERAPIST]: {
    sameDayBooking: true,
    bcPlatform: true,
    videoSessionsEnabled: true,
    medicationEnabled: false,
    zendeskWidget: false,
    requestPayment: false,
    directAccessClientList: false,
    helpCenterUrl: 'https://bct-lyrahealth.zendesk.com/hc/en-us',
    eligibilityChecker: false,
    utilizationEnabled: true,
    showMinifiedUtilization: false,
    showOverdueMessageFlag: true,
    showInPersonPrompt: false,
    smartSchedulingEnabled: false,
  },
  [ROLES.LT_SUPERVISOR]: {
    zendeskWidget: false,
    eligibilityChecker: false,
  },
  [ROLES.LT_PRESCRIBER_SUPERVISOR]: {
    zendeskWidget: false,
    eligibilityChecker: false,
  },
  [ROLES.LT_COACH_SUPERVISOR]: {
    zendeskWidget: false,
    eligibilityChecker: false,
  },
  [ROLES.LT_SS_COACH_SUPERVISOR]: {
    zendeskWidget: false,
    eligibilityChecker: false,
  },
  [ROLES.LT_AUD_THERAPIST_SUPERVISOR]: {
    zendeskWidget: false,
    eligibilityChecker: false,
  },
  [ROLES.LT_FELLOW_SUPERVISOR]: {
    zendeskWidget: false,
    eligibilityChecker: false,
  },
  [ROLES.PRACTICES_ADMIN]: {
    zendeskWidget: true,
    eligibilityChecker: true,
    requestPayment: true,
  },
  [ROLES.PAYMENTS_ADMIN]: {
    zendeskWidget: false,
    allSessionTypes: true,
    eligibilityChecker: true,
  },
  [ROLES.PROVIDERS_ADMIN]: {
    zendeskWidget: false,
    eligibilityChecker: true,
  },
  [ROLES.PRACTICE_PROVIDER]: {
    zendeskWidget: false,
    eligibilityChecker: true,
    smartSchedulingEnabled: true,
  },
}

export type ProgramConfig = {
  activityActions: {
    copy: boolean
    edit: boolean
    move: boolean
    unassign: boolean
  }
  appointmentDurationOptions: number[]
  autoAssignOnSessionClose: boolean
  canPlanFutureAssignments: boolean
  oldClientIntake?: string
  clientIntake: string
  clientIntakeNonUS?: string
  defaultAppointmentDuration: number
  displayCloseEpisodeBtn: boolean
  enhancedLCxDiagnosisEnabled: boolean
  icon: string
  maxCapacity: number
  program: string
  programCoverageName: string
  programId: string
  programGuidanceURL?: string
  programGuidelinesURL?: string
  recommendedEpisodeLength: number | null
  sessionClosureGuide?: string
  sessionFrequencyOptions?: { label: string; value: number }[]
  sessionLabel: string
  shouldConfirmSameSessionAssignment: boolean
}

export enum SESSION_FREQUENCY {
  NO_REPEAT = 'no repeat',
  WEEKLY = 'weekly',
  BIWEEKLY = 'biweekly',
}

export const sessionFrequencyOptions = {
  [SESSION_FREQUENCY.NO_REPEAT]: { label: 'Does not repeat', value: 0 },
  [SESSION_FREQUENCY.WEEKLY]: { label: 'Repeat every week', value: 7 },
  [SESSION_FREQUENCY.BIWEEKLY]: { label: 'Repeat every two weeks', value: 14 },
}

type ProgramConfigs = { [key: string]: ProgramConfig }

export const programConfig: ProgramConfigs = {
  Coaching: {
    activityActions: {
      copy: true,
      edit: true,
      move: true,
      unassign: true,
    },
    appointmentDurationOptions: [45],
    autoAssignOnSessionClose: true,
    canPlanFutureAssignments: true,
    oldClientIntake: 'coachingClientIntake',
    clientIntake: 'bccClientIntake',
    clientIntakeNonUS: 'bccClientIntakeNonUS',
    defaultAppointmentDuration: 45,
    displayCloseEpisodeBtn: false,
    enhancedLCxDiagnosisEnabled: false,
    // We pass a string of the icon component name here in order to be able to import the programConfig in the integration tests to make assertions.
    // Setting the icon component directly makes importing programConfig from the tests impossible as it would
    // require to have webpack and appropriate loaders. It would also slow down the tests.
    icon: 'CoachingIcon',
    maxCapacity: 13,
    program: 'Coaching',
    programCoverageName: 'stressManagement',
    programId: '7ec82ca3-843f-4996-bdf9-49d8c7a35ed5',
    programGuidanceURL: 'https://coaching-lyrahealth.zendesk.com/hc/en-us/articles/360044619034',
    programGuidelinesURL:
      'https://coaching-lyrahealth.zendesk.com/hc/en-us/articles/360045123993-Care-Referral-Based-on-Coaching-Conversation&sa=D&source=docs&ust=1648768380656279&usg=AOvVaw0TDydMJE6eH5IB3_imqvtC',
    recommendedEpisodeLength: 12,
    sessionClosureGuide:
      'https://coaching-lyrahealth.zendesk.com/hc/en-us/articles/13446360980883-Session-Closure-Guide',
    sessionFrequencyOptions: [
      sessionFrequencyOptions[SESSION_FREQUENCY.NO_REPEAT],
      sessionFrequencyOptions[SESSION_FREQUENCY.WEEKLY],
      sessionFrequencyOptions[SESSION_FREQUENCY.BIWEEKLY],
    ],
    sessionLabel: 'Session',
    shouldConfirmSameSessionAssignment: true,
  },
  SingleSessionCoaching: {
    activityActions: {
      copy: false,
      edit: true,
      move: false,
      unassign: false,
    },
    appointmentDurationOptions: [50],
    autoAssignOnSessionClose: false,
    // When set to true, the provider can create and configure activities for future sessions in the curriculum. They will be assigned to the client (hence visible to them) only when they get to the given session. When set to false, the provider can only configure and assign actitivites directly, so they will be visible to the client right away.
    canPlanFutureAssignments: false,
    clientIntake: 'singleSessionCoachingClientIntake',
    oldClientIntake: 'sscClientIntake',
    defaultAppointmentDuration: 50,
    displayCloseEpisodeBtn: true,
    enhancedLCxDiagnosisEnabled: false,
    // We pass a string of the icon component name here in order to be able to import the programConfig in the integration tests to make assertions.
    // Setting the icon component directly makes importing programConfig from the tests impossible as it would
    // require to have webpack and appropriate loaders. It would also slow down the tests.
    icon: 'SingleSessionCoachingIcon',
    maxCapacity: 13,
    program: 'SingleSessionCoaching',
    programCoverageName: 'guidedSelfCareEnabled',
    programId: 'deb574e4-6aa3-4b47-bd21-d8be3ba5d42b',
    recommendedEpisodeLength: 6,
    sessionClosureGuide:
      'https://coaching-lyrahealth.zendesk.com/hc/en-us/articles/13446360980883-Session-Closure-Guide',
    sessionFrequencyOptions: [
      sessionFrequencyOptions[SESSION_FREQUENCY.NO_REPEAT],
      sessionFrequencyOptions[SESSION_FREQUENCY.WEEKLY],
      sessionFrequencyOptions[SESSION_FREQUENCY.BIWEEKLY],
    ],
    sessionLabel: 'Week',
    shouldConfirmSameSessionAssignment: false,
  },
  BlendedCareTherapy: {
    activityActions: {
      copy: true,
      edit: true,
      move: true,
      unassign: true,
    },
    appointmentDurationOptions: [50],
    autoAssignOnSessionClose: true,
    canPlanFutureAssignments: true,
    oldClientIntake: 'clientIntake',
    clientIntake: 'bctClientIntake',
    defaultAppointmentDuration: 50,
    displayCloseEpisodeBtn: false,
    enhancedLCxDiagnosisEnabled: true,
    icon: 'BlendedCareTherapyIcon',
    maxCapacity: 30,
    program: 'BlendedCareTherapy',
    programCoverageName: 'considerLyraTherapy',
    programId: 'c8cb4da4-8957-4cff-9d8e-1fa742f06771',
    programGuidanceURL: 'https://bct-lyrahealth.zendesk.com/hc/en-us/articles/5383959501843/',
    programGuidelinesURL: 'https://bct-lyrahealth.zendesk.com/hc/en-us/articles/5383959501843/',
    recommendedEpisodeLength: 8,
    sessionClosureGuide: 'https://bct-lyrahealth.zendesk.com/hc/en-us/articles/5383959501843-Session-Closure-Guide',
    sessionFrequencyOptions: [
      sessionFrequencyOptions[SESSION_FREQUENCY.NO_REPEAT],
      sessionFrequencyOptions[SESSION_FREQUENCY.WEEKLY],
      sessionFrequencyOptions[SESSION_FREQUENCY.BIWEEKLY],
    ],
    sessionLabel: 'Session',
    shouldConfirmSameSessionAssignment: true,
  },
  TeensTherapy: {
    activityActions: {
      copy: true,
      edit: true,
      move: true,
      unassign: true,
    },
    appointmentDurationOptions: [25, 50, 80],
    autoAssignOnSessionClose: true,
    canPlanFutureAssignments: true,
    oldClientIntake: 'clientIntake',
    clientIntake: 'lcttClientIntake',
    defaultAppointmentDuration: 50,
    displayCloseEpisodeBtn: false,
    enhancedLCxDiagnosisEnabled: true,
    icon: 'BlendedCareTherapyIcon',
    maxCapacity: 30,
    program: 'TeensTherapy',
    programCoverageName: 'considerLyraTherapy',
    programId: 'b42a0c74-76c0-48c4-8a7e-a767418bd753',
    programGuidanceURL: 'https://bct-lyrahealth.zendesk.com/hc/en-us/articles/5383959501843/',
    programGuidelinesURL: 'https://bct-lyrahealth.zendesk.com/hc/en-us/articles/5383959501843/',
    recommendedEpisodeLength: 8,
    sessionClosureGuide: 'https://bct-lyrahealth.zendesk.com/hc/en-us/articles/5383959501843-Session-Closure-Guide',
    sessionFrequencyOptions: [
      sessionFrequencyOptions[SESSION_FREQUENCY.NO_REPEAT],
      sessionFrequencyOptions[SESSION_FREQUENCY.WEEKLY],
      sessionFrequencyOptions[SESSION_FREQUENCY.BIWEEKLY],
    ],
    sessionLabel: 'Session',
    shouldConfirmSameSessionAssignment: true,
  },
  MedicationManagement: {
    activityActions: {
      copy: true,
      edit: true,
      move: true,
      unassign: true,
    },
    appointmentDurationOptions: [25, 50, 80],
    autoAssignOnSessionClose: true,
    canPlanFutureAssignments: true,
    oldClientIntake: 'medsClientIntake',
    clientIntake: 'bcmClientIntake',
    defaultAppointmentDuration: 25,
    icon: 'BlendedCareMedicineIcon',
    maxCapacity: 20,
    recommendedEpisodeLength: null,
    displayCloseEpisodeBtn: false,
    enhancedLCxDiagnosisEnabled: false,
    program: 'MedicationManagement',
    programCoverageName: 'blendedCareMeds',
    programGuidanceURL: 'https://blended-care-physicians-hub.zendesk.com/hc/en-us/articles/4407784647315',
    programGuidelinesURL: 'https://blended-care-physicians-hub.zendesk.com/hc/en-us/articles/5458667903635',
    programId: '1b2b8b47-4c0e-49ff-a65c-40e187360b70',
    sessionClosureGuide:
      'https://blended-care-physicians-hub.zendesk.com/hc/en-us/articles/5458667903635-BCM-Session-Closure-Guide',
    sessionFrequencyOptions: [
      sessionFrequencyOptions[SESSION_FREQUENCY.NO_REPEAT],
      sessionFrequencyOptions[SESSION_FREQUENCY.BIWEEKLY],
    ],
    sessionLabel: 'Session',
    shouldConfirmSameSessionAssignment: true,
  },
  AlcoholUseDisorderTherapy: {
    activityActions: {
      copy: true,
      edit: true,
      move: true,
      unassign: true,
    },
    appointmentDurationOptions: [25, 50, 80],
    autoAssignOnSessionClose: true,
    canPlanFutureAssignments: true,
    clientIntake: 'audClientIntake',
    defaultAppointmentDuration: 50,
    displayCloseEpisodeBtn: false,
    enhancedLCxDiagnosisEnabled: true,
    icon: 'AlcoholUseDisorderTherapyIcon',
    maxCapacity: 30,
    program: 'AlcoholUseDisorderTherapy',
    programCoverageName: 'alcoholUseDisorderTherapy',
    programId: 'edcea864-b6eb-4d16-8c4c-ce748c18025e',
    recommendedEpisodeLength: 16,
    sessionClosureGuide: 'https://bct-lyrahealth.zendesk.com/hc/en-us/articles/5383959501843-Session-Closure-Guide',
    sessionFrequencyOptions: [
      sessionFrequencyOptions[SESSION_FREQUENCY.NO_REPEAT],
      sessionFrequencyOptions[SESSION_FREQUENCY.WEEKLY],
      sessionFrequencyOptions[SESSION_FREQUENCY.BIWEEKLY],
    ],
    sessionLabel: 'Session',
    shouldConfirmSameSessionAssignment: true,
  },
  ClinicalLeaveEvaluation: {
    activityActions: {
      copy: true,
      edit: true,
      move: true,
      unassign: true,
    },
    appointmentDurationOptions: [30, 50],
    autoAssignOnSessionClose: true,
    canPlanFutureAssignments: true,
    clientIntake: 'cleClientIntake',
    defaultAppointmentDuration: 30,
    displayCloseEpisodeBtn: false,
    enhancedLCxDiagnosisEnabled: false,
    icon: 'ClinicalLeaveEvaluationIcon',
    maxCapacity: 20,
    program: 'ClinicalLeaveEvaluation',
    programCoverageName: `clinicalLeaveEvaluation`,
    programId: '6f146c5b-ac3b-42e5-b2a2-aacb3035e6d8',
    recommendedEpisodeLength: 3,
    sessionFrequencyOptions: [
      sessionFrequencyOptions[SESSION_FREQUENCY.NO_REPEAT],
      sessionFrequencyOptions[SESSION_FREQUENCY.BIWEEKLY],
    ],
    sessionLabel: 'Session',
    sessionClosureGuide:
      'https://blended-care-physicians-hub.zendesk.com/hc/en-us/articles/5458667903635-BCM-Session-Closure-Guide',
    shouldConfirmSameSessionAssignment: true,
  },
}

export const ICD10_PRIMARY_FIELD = 'icd10_primary_diagnosis'
export const ICD10_SECONDARY_FIELD = 'icd10_secondary_diagnosis'

export const paymentPreferences = { 2: 'ACH' }

export const customerLabel = {
  roche: 'roche/genentech',
}

export const diagnoses = [
  { label: 'ADHD', value: 'adhd' },
  { label: 'Anxiety', value: 'anxiety' },
  { label: 'Behavioral Problems', value: 'behavioral_problems' },
  { label: 'Bipolar Disorder', value: 'bipolar_disorder' },
  { label: 'Chronic Pain', value: 'chronic_pain' },
  { label: 'Depression/Sadness', value: 'depression_sadness' },
  { label: 'Eating Disorder', value: 'eating_disorder' },
  { label: 'Grief', value: 'grief' },
  { label: 'OCD', value: 'ocd' },
  { label: 'Panic', value: 'panic' },
  { label: 'Parenting/Child Behavior Issues', value: 'parenting_child_behavior_issues' },
  { label: 'Relationship Issues', value: 'relationship_issues' },
  { label: 'Stress', value: 'stress' },
  { label: 'Sleep Issues', value: 'sleep_issues' },
  { label: 'Substance Abuse', value: 'substance_abuse' },
  { label: 'Trauma', value: 'trauma' },
  { label: 'Other', value: 'other' },
]

export const programNameTitleMap = {
  MedicationManagement: 'Meds',
  BlendedCareTherapy: 'Therapy',
  TeensTherapy: 'Therapy',
  Coaching: 'Coaching',
  SingleSessionCoaching: 'Guided Self-Care',
  AlcoholUseDisorderTherapy: 'Renew',
  Therapy: 'Therapy',
  ClinicalLeaveEvaluation: 'Clinical Leave Evaluation',
}

export const programs = {
  MedicationManagement: 'MedicationManagement',
  BlendedCareTherapy: 'BlendedCareTherapy',
  Coaching: 'Coaching',
  SingleSessionCoaching: 'SingleSessionCoaching',
  AlcoholUseDisorderTherapy: 'AlcoholUseDisorderTherapy',
  ClinicalLeaveEvaluation: 'ClinicalLeaveEvaluation',
  TeensTherapy: 'TeensTherapy',
}

export const benefitsProgramNames = {
  blendedCareMeds: 'Lyra Blended Care Medications',
  considerLyraTherapy: 'Lyra Blended Care Therapy',
  directAccessTherapy: 'Lyra Community Therapy (Direct Access)',
  considerMeds: 'Lyra Community Medications (Direct Access)',
  stressManagement: 'Lyra Blended Care Coaching',
  alcoholUseDisorderTherapy: 'Lyra Renew',
  guidedSelfCareEnabled: 'Lyra Guided Self Care',
  clinicalLeaveEvaluation: 'Clinical Leave Evaluation (excludes dependents)',
}

export const customerExclusionList = ['walmartisrael', 'qualcommglobal', 'intuit-seasonal']

/**
 * This creates a <value>: <label> map from the above `diagnoses` array.
 * It has the following format:
 *
 *   {
 *    adhd: 'ADHD',
 *    anxiety: 'Anxiety',
 *    behavioral_problems: 'Behavioral Problems',
 *    ....
 *   }
 *
 * It is useful to easily display Diagnoses labels from an api response since the server return the `values`.
 */
export const diagnosesMap = diagnoses.reduce((obj, { label, value }) => {
  obj[value] = label
  return obj
}, {})

export const outcomesEligibleSessionTypes = {
  individual_initial_evaluation: 'Individual Initial Evaluation',
  individual_follow_up_session: 'Individual Follow-Up Session',
  couples_initial_evaluation: 'Couples Initial Evaluation',
  couples_follow_up_session: 'Couples Follow-Up Session',
  medication_management_initial_evaluation: 'Medication Management Initial Evaluation',
  medication_management_follow_up_session: 'Medication Management Follow-Up Evaluation',
}

// Session types for DA providers
export const sessionTypes = {
  ...outcomesEligibleSessionTypes,
  family_initial_evaluation: 'Family Initial Evaluation',
  family_follow_up_session: 'Family Follow-Up Session',
  group_initial_evaluation: 'Group Initial Evaluation',
  group_follow_up_session: 'Group Follow-Up Session',
  smp_session: 'SMP Session',
  child_initial_evaluation: 'CPP Initial Evaluation',
  child_follow_up_session: 'CPP Follow-Up Session',
  lyra_therapy_teens_parent_session: 'LCT for Teen Parent Followup Session',
  other: 'Other',
}

const sessionTypesDisplayOrder = [
  'individual_initial_evaluation',
  'individual_follow_up_session',
  'couples_initial_evaluation',
  'couples_follow_up_session',
  'family_initial_evaluation',
  'family_follow_up_session',
  'group_initial_evaluation',
  'group_follow_up_session',
  'lyra_therapy_teens_parent_session',
  'medication_management_initial_evaluation',
  'medication_management_follow_up_session',
  'smp_session',
  'child_initial_evaluation',
  'child_follow_up_session',
  'other',
]

export const sessionTypesDisplay = sessionTypesDisplayOrder.map((sessionTypeKey) => ({
  value: sessionTypeKey,
  label: sessionTypes[sessionTypeKey],
}))

export const coachingSessionTypes = {
  SMP_SESSION: 'smp_session',
  SSC_SESSION: 'ssc_session',
}

// Session types for LT providers
export const LTsessionTypes = {
  AUD_SESSION: 'alcohol_use_disorder_session',
  BCT_SESSION: 'lyra_therapy_session',
  LCTT_SESSION: 'lyra_therapy_teens_session',
  INITIAL_MEDS_SESSION: 'lyra_therapy_meds_initial_session',
  FOLLOWUP_MEDS_SESSION: 'lyra_therapy_meds_follow_up_session',
  INITIAL_CLE_SESSION: 'clinical_leave_evaluation_initial_session',
  FOLLOWUP_CLE_SESSION: 'clinical_leave_evaluation_follow_up_session',
  ...coachingSessionTypes,
}

export const coachingSessionTypesList = Object.values(coachingSessionTypes)

// Meeting formats for creating a new session with a client
export const getMeetingFormats = ({ exclusions = [] }: { exclusions?: string[] } = {}) => {
  const defaultFormats = [
    { value: 'video', text: 'Video session' },
    { value: 'phone', text: 'Phone session' },
    { value: 'live_messaging', text: 'Live messaging session' },
  ]

  return defaultFormats.filter((format) => !exclusions.includes(format.value))
}

// mapping with LW meeting format enums with PP Charge modalities
export const meetingFormatChargeModalityMap = {
  phone: 'tele_therapy',
  video: 'video',
  live_messaging: 'live_messaging',
}

// Those are the attendance values expected and returned by the server
export const attendanceValues = {
  ATTENDED: 'attended',
  LAST_MIN_CANCEL: 'last_minute_cancellation',
  NO_SHOW: 'no_show',
  INELIGIBLE: 'clientIneligible',
}

/**
 * These are the reasons for a closing a session that did not happen
 * @param {string} clientName
 */
export const changeReasons = (clientName: string, showCreditedSessionTransition = false) => {
  return [
    {
      text: `${clientName} did not show up. (credited session; client penalty depending on customer policy; please follow program guidance to ensure technical issues didn't prevent the client from joining)`,
      value: 'clientInitiatedLate',
    },
    {
      text: `Technical issues (for either ${clientName} or me) prevented this session from happening (credited session; no client penalty)`,
      value: 'technicalIssues',
    },
    {
      text: 'I was unable to meet at this time (non-credited session; no client penalty)',
      value: 'providerInitiated',
    },
    {
      text: `Session resulted in a care transition, and did not meet program guidelines of an attended session (${
        showCreditedSessionTransition ? 'credited' : 'non-credited'
      } session; no client penalty)`,
      value: 'clientTransition',
    },
    { text: `${clientName} canceled this session (more options to follow)`, value: 'clientCancel' },
  ]
}

export const technicalIssuesCancelSelections = (clientName: string) => {
  return [
    {
      text: `Client login: ${clientName} experienced login issues that prevented them from joining the session`,
      value: 'clientLogin',
    },
    {
      text: 'Provider login: I experienced login issues that prevented me from joining the session',
      value: 'providerLogin',
    },
    {
      text: 'Platform outage',
      value: 'platformOutage',
    },
    {
      text: 'Client internet and/or power outage',
      value: 'clientOutage',
    },
    {
      text: `Client out of state: ${clientName} joined from a state I am not licensed in (for therapists/physicians) or from a country I am not permitted to coach (for coaches)`,
      value: 'clientOutOfState',
    },
    {
      text: 'Timezone mismatch: Client / provider have different session start times due to time zone differences not accurately reflected in platform',
      value: 'timezoneMismatch',
    },
    {
      text: 'None of the above (provide more details in the following question)',
      value: 'otherTechnicalIssue',
    },
  ]
}

export const changeReasonsDetails = (clientName: string) => {
  return [
    {
      text: `${clientName} was unable to attend the session due to a personal / family / medical emergency (e.g. a trip to the ER) (credited session; no client penalty)`,
      value: 'medicalEmergency',
    },
    {
      text: `${clientName} canceled within 24 hours (credited session; client penalty depending on customer policy)`,
      value: 'clientInitiatedLate',
    },
  ]
}

// Appointment Statuses used by BE
export const appointmentStatuses = {
  missed: 'missed',
  completed: 'completed',
  canceled: 'canceled',
}

// these are the options for whether a client gave a notice within 24 hrs
export const changeNotices = (clientName: string) => {
  return [
    {
      text: `Yes, ${clientName} reached out and requested a cancellation or reschedule more than 24 hours before the session. (non-credited session; no client penalty)`,
      value: 'clientInitiated',
    },
    {
      text: `No (more options to follow)`,
      value: 'clientInitiatedReasonRequired',
    },
  ]
}

export const changeNoticeReasons = (clientName: string) => {
  return [
    {
      text: `${clientName} was unable to attend the session due to a personal / family / medical emergency (e.g. a trip to the ER) (credited session; no client penalty)`,
      value: 'medicalEmergency',
    },
    {
      text: `Technical issues (for either ${clientName} or me) prevented this session from happening (credited session; no client penalty)`,
      value: 'technicalIssues',
    },
    {
      text: `${clientName} canceled within 24 hours (credited session; client penalty depending on customer policy)`,
      value: 'clientInitiatedLate',
    },
  ]
}

export const attendanceOptions = {
  [attendanceValues.ATTENDED]: 'Attended',
  [attendanceValues.LAST_MIN_CANCEL]: 'Last Minute Cancellation',
  [attendanceValues.NO_SHOW]: 'No Show',
}

export const modalityOptions = {
  in_person: 'In Person',
  video: 'Video',
  tele_therapy: 'Phone',
}

export const defaultErrorMessages = {
  400: 'The request contained invalid data. Please double check the information provided and try again.',
  401: 'Authorization failed for the given request. Please make sure you are logged in.',
  404: 'Nothing found for the requested service',
  413: 'The request your are trying to send is too large. The limit is 20MB.',
  500: 'The server is having issues. Please try again later.',
  503: 'An error occurred in making the request. Please try again.',
  504: 'The request has timed out. Please try again.',
}

const isProduction = process.env.NODE_ENV === 'production'

export const RECAPTCHA_SITE_KEY = isProduction
  ? '6LchKiAUAAAAAAPCLO376s0CbA70L8pxdZjxpeCU' // Production site key
  : '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI' // Staging site key

export const mixpanelToken = isProduction
  ? '89e7dd4bd73cd0eb3d7ccfd692e6c1b8' // Production token
  : 'f5a4b848dfa645cc08cfd4aed4b618a2' // Staging token

export const oktaClientId = isProduction
  ? '0oawbk45nAjpwJCZ6296' // Production ID
  : '0oad75oobtrqZkHvh0h7' // Staging ID

export const oktaGoogleSSOClientId = isProduction
  ? '0oaln2yztCiSdSALC296' // Production ID
  : '0oadklcqs9O0wBwGU0h7' // Staging ID

export const oktaBaseUrl = isProduction
  ? 'https://lyrahealth.okta.com' // Production URL
  : 'https://lyrahealth.oktapreview.com' // Staging URL
export const sumologicConfig = isProduction
  ? {
      endpoint:
        'https://endpoint2.collection.us2.sumologic.com/receiver/v1/http/ZaVnC4dhaV3m53WB37ZV72XEhUfNavqfKhzancYQUILxBj8Sfk3UCjNRCqi-qb5ioAvqo2FR-IaaePLbydaAc0w5GbFtFpdewu20BX6-vCQtuGAMYKfEaA==',
      hostName: window.location.hostname,
    } // Production config
  : {
      endpoint:
        'https://endpoint2.collection.us2.sumologic.com/receiver/v1/http/ZaVnC4dhaV2PV4jqbzl8e4nYCtYbIJ4yckGSzAzMqvxMJsrHKm4KU_hWGLvY59r8AylrK1CRFuh-iXWLg52exXWOKKhSklBcH3dL9RcVVhj6JHW17ozEZg==',
      hostName: window.location.hostname,
    } // Staging config

export const stripeUrl = isProduction
  ? 'https://dashboard.stripe.com/connect/transfers/' // Production value
  : 'https://dashboard.stripe.com/test/connect/transfers/' // Staging value

export const copaysUrl = isProduction
  ? 'https://dashboard.stripe.com/payments/' // Production value
  : 'https://dashboard.stripe.com/test/payments/' // Staging value

export const claimsUrl = (claimId: string) => {
  const appId = isProduction ? '16e070b8-d8ae-11e8-bf54-0242ac110003' : '16e070b8-d8ae-11e8-bf54-0242ac110003' // '4b5a7e88-c8d7-11e8-90d7-0242ac110003'
  return `https://dashboard.pokitdok.com/dashboard/#/apps/${appId}/claims/detail/${claimId}`
}

export const LWUrl = (employer: string) => {
  let LWURL = `https://${employer}.lyrahealth.com`
  if (!isProduction) {
    const { origin } = window.location
    if (origin.includes('staging')) {
      LWURL = `https://${employer}.staging.lyrahealth.com`
    } else if (origin.includes('prerelease')) {
      LWURL = `https://${employer}.prerelease2.automation.lyrahealth.com`
    } else if (origin.includes('dev')) {
      LWURL = `https://${employer}.dev.lyrahealth.com`
    } else if (origin.includes('localhost')) {
      LWURL = `https://${employer}.localhost:4000`
    }
  }
  return LWURL
}

export const parentIntakeQualtricsUrl = (qualtricsId: string) => {
  if (isProduction) {
    return `https://lyrahealth.qualtrics.com/jfe/form/SV_6yhUKME0cOzt9d4?req=${qualtricsId}`
  } else {
    return `https://lyrahealth.qualtrics.com/jfe/form/SV_3xR2c5y1FMtarUG?req=${qualtricsId}`
  }
}

export const providerDirectBookingLink = ({
  episode,
  shouldUseRebookDBL,
  ...params
}: {
  employer: string
  providerId: string
  episode?: Episode
  shouldUseRebookDBL: boolean
}) =>
  shouldUseRebookDBL && episode?.state === episodeStates.IN_PROGRESS
    ? providerRebookDirectBookingLink(params)
    : providerInitialDirectBookingLink(params)

export const providerInitialDirectBookingLink = ({
  employer,
  providerId,
}: {
  employer: string
  providerId: string
}) => {
  return `${LWUrl(employer)}/secure/index/provider/${providerId}`
}

const APP_LINK = isProduction ? 'https://lyra.app.link/' : 'https://lyra.test-app.link/'
const providerRebookDirectBookingLink = ({ employer, providerId }: { employer: string; providerId: string }) => {
  return `${APP_LINK}rebook?provider_id=${providerId}&$fallback_url=${LWUrl(
    employer,
  )}/secure/index/provider/${providerId}`
}

export const states = [
  {
    value: 'AL',
    text: 'Alabama',
  },
  {
    value: 'AK',
    text: 'Alaska',
  },
  {
    value: 'AS',
    text: 'American Samoa',
  },
  {
    value: 'AZ',
    text: 'Arizona',
  },
  {
    value: 'AR',
    text: 'Arkansas',
  },
  {
    value: 'CA',
    text: 'California',
  },
  {
    value: 'CO',
    text: 'Colorado',
  },
  {
    value: 'CT',
    text: 'Connecticut',
  },
  {
    value: 'DE',
    text: 'Delaware',
  },
  {
    value: 'DC',
    text: 'District of Columbia',
  },
  {
    value: 'FL',
    text: 'Florida',
  },
  {
    value: 'GA',
    text: 'Georgia',
  },
  {
    value: 'GU',
    text: 'Guam',
  },
  {
    value: 'HI',
    text: 'Hawaii',
  },
  {
    value: 'ID',
    text: 'Idaho',
  },
  {
    value: 'IL',
    text: 'Illinois',
  },
  {
    value: 'IN',
    text: 'Indiana',
  },
  {
    value: 'IA',
    text: 'Iowa',
  },
  {
    value: 'KS',
    text: 'Kansas',
  },
  {
    value: 'KY',
    text: 'Kentucky',
  },
  {
    value: 'LA',
    text: 'Louisiana',
  },
  {
    value: 'ME',
    text: 'Maine',
  },
  {
    value: 'MD',
    text: 'Maryland',
  },
  {
    value: 'MA',
    text: 'Massachusetts',
  },
  {
    value: 'MI',
    text: 'Michigan',
  },
  {
    value: 'MN',
    text: 'Minnesota',
  },
  {
    value: 'MS',
    text: 'Mississippi',
  },
  {
    value: 'MO',
    text: 'Missouri',
  },
  {
    value: 'MT',
    text: 'Montana',
  },
  {
    value: 'NA',
    text: 'National',
  },
  {
    value: 'NE',
    text: 'Nebraska',
  },
  {
    value: 'NV',
    text: 'Nevada',
  },
  {
    value: 'NH',
    text: 'New Hampshire',
  },
  {
    value: 'NJ',
    text: 'New Jersey',
  },
  {
    value: 'NM',
    text: 'New Mexico',
  },
  {
    value: 'NY',
    text: 'New York',
  },
  {
    value: 'NC',
    text: 'North Carolina',
  },
  {
    value: 'ND',
    text: 'North Dakota',
  },
  {
    value: 'MP',
    text: 'Northern Mariana Islands',
  },
  {
    value: 'OH',
    text: 'Ohio',
  },
  {
    value: 'OK',
    text: 'Oklahoma',
  },
  {
    value: 'OR',
    text: 'Oregon',
  },
  {
    value: 'PA',
    text: 'Pennsylvania',
  },
  {
    value: 'PR',
    text: 'Puerto Rico',
  },
  {
    value: 'RI',
    text: 'Rhode Island',
  },
  {
    value: 'SC',
    text: 'South Carolina',
  },
  {
    value: 'SD',
    text: 'South Dakota',
  },
  {
    value: 'TN',
    text: 'Tennessee',
  },
  {
    value: 'TX',
    text: 'Texas',
  },
  {
    value: 'UT',
    text: 'Utah',
  },
  {
    value: 'VT',
    text: 'Vermont',
  },
  {
    value: 'VI',
    text: 'Virgin Islands',
  },
  {
    value: 'VA',
    text: 'Virginia',
  },
  {
    value: 'WA',
    text: 'Washington',
  },
  {
    value: 'WV',
    text: 'West Virginia',
  },
  {
    value: 'WI',
    text: 'Wisconsin',
  },
  {
    value: 'WY',
    text: 'Wyoming',
  },
]

export const bannerMessages = {
  REGISTER_PRACTICE_SUCCESS: 'Thank you! You successfully registered!',
  REGISTER_PROVIDER_SUCCESS: 'Thank you! You successfully registered!',
  SUBMIT_PAYMENT_SUCCESS: 'Thank you! Your payment was successfully submitted',
  PAYMENT_APPROVED: 'Payment approved.',
  PAYMENT_REJECTED: 'Payment rejected.',
  ADD_PROVIDER_SUCCESS: 'The provider specified has been added to this practice.',
  UPDATE_AVAILABILITY: 'Thank you for updating your availability',
  ASSESSMENT_TRACK_SUCCESS: 'Assessment track changed',
  PAYMENT_HISTORY_EMAIL_SENT: 'An email was sent to you regarding your requested payment history CSV',
}

export const sessionTracking = {
  WARNING_THRESHOLD: 5,
}

export const PHQRanges = [
  {
    lower: 0,
    upper: 4,
    description: 'Minimal',
  },
  {
    lower: 5,
    upper: 9,
    description: 'Mild Depression',
  },
  {
    lower: 10,
    upper: 14,
    description: 'Moderate Depression',
  },
  {
    lower: 15,
    upper: 19,
    description: 'Moderately Severe Depression',
  },
  {
    lower: 20,
    description: 'Severe Depression',
  },
]

export const GADRanges = [
  {
    lower: 0,
    upper: 4,
    description: 'Minimal',
  },
  {
    lower: 5,
    upper: 9,
    description: 'Mild Anxiety',
  },
  {
    lower: 10,
    upper: 14,
    description: 'Moderate Anxiety',
  },
  {
    lower: 15,
    description: 'Severe Anxiety',
  },
]

// Enum representing potential operations that can be performed on an appointment.
// This defines one source of truth for components handling functionality for both
// creating appointments and rescheduling appointments.
export enum APPOINTMENT_OPERATIONS {
  NEW = 'new',
  RESCHEDULE = 'reschedule',
}

export const LT_PROVIDER_MAX_CAPACITY = 13

export const episodeTerminationStatuses = [
  {
    text: 'Never engaged - Client booked an appointment but never showed up for any appointments and was not referred to other care.',
    value: 'not_initiated',
    name: 'Never engaged',
  },
  {
    text: 'Client request not supported by any Lyra services - Client is looking for other support not covered by Blended Care programs and other Lyra services.',
    value: 'not_supported',
    name: 'Client request not supported by any Lyra services',
  },
  {
    text: 'Care transition - Client is not completing care with me and is transitioning to a different Blended Care provider or different care type within Lyra.',
    value: 'care_transition',
    name: 'Care transition',
  },
  {
    text: 'Drop out - Client attended at least 1 session and stopped care without having made progress on their goals. This can include clients who have been unresponsive to outreach attempts or declined further sessions.',
    value: 'drop_out',
    name: 'Drop out',
  },
  {
    text: 'No longer eligible - Client is losing eligibility for the Blended Care program and other Lyra services or has utilized all their sessions for the year.',
    value: 'not_eligible',
    name: 'No longer eligible',
  },
  {
    text: 'Completed - Client demonstrated readiness to complete care based on progress made on their goals.',
    value: 'completed',
    name: 'Completed',
  },
  {
    text: 'Booster session - This client was inactive and returned for a single care session ONLY. I am ending this episode.',
    value: 'booster',
    name: 'Booster session',
  },
]

export const specialityCareAttestationStatuses = {
  NO_ATTESTATION: 'no_attestation',
  APPROACHING_ATTESTATION: 'approaching_attestation',
  ATTESTATION_PRIOR_AUTHORIZATION: 'attestation_pre_authorization',
  ATTESTATION_NEEDED: 'attestation_needed',
}

export const healthPlanStates = {
  READY: 'health_plan_ready',
  NOT_READY: 'health_plan_not_ready',
  IN_USE: 'health_plan_in_use',
  NOT_ELIGIBLE: 'health_plan_not_eligible',
  LIMIT_NOT_REACHED: 'health_plan_warning_limit_not_reached',
  HEALTH_PLAN_PAYMENT_MISSING: 'health_plan_payment_missing',
}

export const episodeStates = {
  IN_PROGRESS: 'in_progress',
  COMPLETED: 'completed',
  TERMINATED: 'terminated',
  TERMINATED_NON_CLINICAL: 'terminated_non_clinical',
  TERMINATED_CLINICAL: 'terminated_clinical',
  NOT_INITIATED: 'not_initiated',
}

export const episodeProgress = {
  ALL: 'All',
  INTAKE_ONLY: 'Intake only',
  FOLLOWUP_ONLY: 'Follow-up only',
  OVER_EPISODE_COUNT: 'Over episode count',
  LONGER_EPISODES: 'Longer episodes',
}

export const selfHarmDashletTabs = {
  ADDRESSED: 'Addressed',
  NEW: 'New',
}

export const selfHarmTasksStates = {
  ACKNOWLEDGED: 'acknowledged',
  INACTIVE: 'inactive',
  PENDING: 'pending',
  REQUIRES_ACKNOWLEDGMENT: 'requires_acknowledgment',
}

const PAYMENT_FORM_FIELDS = {
  PRACTICE_ID: 'practice_id',
  COMPANY: 'company',
  LYRA_CODE: 'lyra_code',
  RELATIONSHIP: 'relationship',
  DATE_OF_BIRTH: 'date_of_birth',
  PROVIDER_LYRA_ID: 'provider_lyra_id',
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  ELIGIBLE_MEMBER_FIRST_NAME: 'eligible_member_first_name',
  ELIGIBLE_MEMBER_LAST_NAME: 'eligible_member_last_name',
  ELIGIBLE_MEMBER_DOB: 'eligible_member_dob',
  VISIT_DATE: 'visit_date',
  SESSION_TYPE: 'session_type',
  OTHER_SESSION_DESCRIPTION: 'other_session_description',
  SESSION_DURATION: 'session_duration',
  SESSION_RATE: 'session_rate',
  DIAGNOSIS: 'diagnosis',
  OTHER_DIAGNOSIS: 'other_diagnosis',
  ATTENDANCE: 'attendance',
  MODALITY: 'modality',
  IS_GUARANTEED_TIME: 'is_guaranteed_time',
  LAST_SESSION: 'last_session',
  LAST_SESSION_TERMINATED: 'last_session_terminated',
  ONSITE_SESSION: 'onsite_session',
  NOTES: 'notes',
  REFERENCE_NUMBER: 'reference_number',
  LOCATION: 'location',
  PRIMARY_CONDITION: 'primary_condition',
  SECONDARY_CONDITION: 'secondary_condition',
  OTHER_PRIMARY_DESCRIPTION: 'other_primary_description',
  OTHER_SECONDARY_DESCRIPTION: 'other_secondary_description',
  PRIMARY_CONDITION_SEVERITY: 'primary_condition_severity',
  EBT_TYPE: 'ebt_type',
  OTHER_EBT_DESCRIPTION: 'other_ebt_description',
}

export const PAYMENT_FORM = {
  NAME: 'requestPaymentForm',
  FIELDS: PAYMENT_FORM_FIELDS,
  DEFAULT: {
    initialValues: {
      [PAYMENT_FORM_FIELDS.IS_GUARANTEED_TIME]: false,
      [PAYMENT_FORM_FIELDS.ATTENDANCE]: 'attended',
      [PAYMENT_FORM_FIELDS.LAST_SESSION]: false,
      [PAYMENT_FORM_FIELDS.LAST_SESSION_TERMINATED]: false,
      [PAYMENT_FORM_FIELDS.RELATIONSHIP]: 'employee',
      [PAYMENT_FORM_FIELDS.COMPANY]: [],
      [PAYMENT_FORM_FIELDS.DIAGNOSIS]: [],
      [PAYMENT_FORM_FIELDS.PRIMARY_CONDITION]: [],
    },
  },
  GUARANTEED_TIME: {
    initialValues: {
      [PAYMENT_FORM_FIELDS.IS_GUARANTEED_TIME]: true,
      [PAYMENT_FORM_FIELDS.FIRST_NAME]: 'Guaranteed',
      [PAYMENT_FORM_FIELDS.LAST_NAME]: 'Time',
      [PAYMENT_FORM_FIELDS.DATE_OF_BIRTH]: '01/01/2000',
      [PAYMENT_FORM_FIELDS.MODALITY]: 'in_person',
    },
    disabledFields: {
      [PAYMENT_FORM_FIELDS.FIRST_NAME]: true,
      [PAYMENT_FORM_FIELDS.LAST_NAME]: true,
      [PAYMENT_FORM_FIELDS.DATE_OF_BIRTH]: true,
      [PAYMENT_FORM_FIELDS.DIAGNOSIS]: true,
      [PAYMENT_FORM_FIELDS.ATTENDANCE]: true,
      [PAYMENT_FORM_FIELDS.MODALITY]: true,
      [PAYMENT_FORM_FIELDS.LOCATION]: true,
      [PAYMENT_FORM_FIELDS.LAST_SESSION]: true,
      [PAYMENT_FORM_FIELDS.LAST_SESSION_TERMINATED]: true,
      [PAYMENT_FORM_FIELDS.PRIMARY_CONDITION]: true,
      [PAYMENT_FORM_FIELDS.SECONDARY_CONDITION]: true,
      [PAYMENT_FORM_FIELDS.PRIMARY_CONDITION_SEVERITY]: true,
      [PAYMENT_FORM_FIELDS.EBT_TYPE]: true,
    },
  },
  THERAPIST: {
    initialValues: {},
    disabledFields: {},
  },
  THERAPIST_GUARANTEED_TIME: {
    initialValues: {
      [PAYMENT_FORM_FIELDS.SESSION_TYPE]: 'individual_follow_up_session',
      [PAYMENT_FORM_FIELDS.ONSITE_SESSION]: true,
    },
    disabledFields: {
      [PAYMENT_FORM_FIELDS.SESSION_TYPE]: true,
      [PAYMENT_FORM_FIELDS.ONSITE_SESSION]: true,
    },
  },
}

// The period of time (in days) after which we consider provider's availability outdated and force to update
export const AVAILABILITY_CONSIDERED_FRESH_DAYS = { [ROLES.INDIVIDUAL_PROVIDER]: 7, [ROLES.PRACTICE_PROVIDER]: 14 }

export const LYRA_CODE_LENGTH = 9

const allSessionTypes = uniq([...Object.keys(sessionTypes), ...Object.values(LTsessionTypes)])

export const CHECKBOX_GROUP_SESSION_TYPE_FILTER = {
  filterKey: 'session_type',
  data: allSessionTypes.map((key, group) => ({ id: key, text: key, group })),
}

export const CHECKBOX_GROUP_INVOICE_TYPE_FILTER = {
  filterKey: 'is_health_plan',
  data: [
    {
      id: 'HP',
      text: 'HP',
      group: 0,
    },
    {
      id: 'EAP',
      text: 'EAP',
      group: 1,
    },
  ],
}

export const CHECKBOX_GROUP_PAYMENT_STATUS_FILTER = {
  filterKey: 'status',
  data: [
    {
      id: 'submitted',
      text: 'Submitted',
      group: 0,
    },
    {
      id: 'pending',
      text: 'Pending',
      group: 1,
    },
    {
      id: 'approved',
      text: 'Approved',
      group: 2,
    },
    {
      id: 'rejected',
      text: 'Rejected',
      group: 3,
    },
    {
      id: 'cancelled',
      text: 'Cancelled',
      group: 4,
    },
    {
      id: 't0_sessions',
      text: 'T0 Sessions',
      group: 5,
    },
    {
      id: 'reversed',
      text: 'Reversed',
      group: 6,
    },
  ],
}

export const CHECKBOX_GROUP_ELIGIBILITY_STATUS_FILTER = {
  filterKey: 'eligibility_code',
  data: [
    {
      id: '100',
      text: 'Eligiblity True',
      group: 0,
    },
    {
      id: '101',
      text: 'Eligiblity False: No customer eligibility file',
      group: 1,
    },
    {
      id: '102',
      text: 'Eligibility False: Member not found',
      group: 2,
    },
    {
      id: '103',
      text: 'Eligibility False: Not Eligible at session date',
      group: 3,
    },
    {
      id: '104',
      text: 'Eligibility False: Eligible with another company',
      group: 4,
    },
    {
      id: '105',
      text: 'Eligibility False: Member found in another company but not currently eligible',
      group: 5,
    },
    {
      id: '0',
      text: 'Eligibility False: Unknown - Historical Data',
      group: 6,
    },
    {
      id: '1',
      text: 'Eligibility False: Unknown - RTE Service Error',
      group: 7,
    },
  ],
}

export const INVOICE_NUMBER_PER_PAGE = 100

export const employmentTypes = {
  FULL_TIME: 'FT',
  PART_TIME: 'PT',
  ENHANCED_PART_TIME: 'ePT',
  REDUCED_PART_TIME: 'rPT',
}

export const paymentRejectionReasons = {
  'Incorrect Patient Name': 'Incorrect Patient Name',
  'Incorrect Eligible Member Name': 'Incorrect Eligible Member Name',
  'Incorrect session type for missed appointment':
    'Please resubmit under the appropriate pre-populated session type and selected “No Show” or “Last Minute Cancellation” for Attendance',
  'Patient First and Last Names Reversed': 'Patient First and Last Names Reversed',
  'Unable to Match Patient Date of Birth': 'Unable to Match Patient Date of Birth',
  'Unable to Match Eligible Member Date of Birth': 'Unable to Match Eligible Member Date of Birth',
  'Incorrect Sponsoring Company': 'Incorrect Sponsoring Company',
  'Please Include Eligible Member Information': 'Please Include Eligible Member Information',
  'Unable to Identify Client':
    'Unable to Identify Client. Client may not be eligible. Please contact client and confirm full legal name, date of birth, and that they are still eligible for Lyra benefits through their sponsoring company. If client receives Lyra through another eligible member, please indicate that patient is a dependent and provide eligible member information.',
  'Unable to Identify Eligible Member':
    'Unable to Identify Eligible Member. Client may not be eligible. Please contact client and confirm full legal name, date of birth, and that they are still eligible for Lyra benefits through their sponsoring company.',
  'Not eligible at session date':
    'Not eligible at session date. Please contact client and confirm full legal name, date of birth, and/or possible employment status changes. If client receives Lyra through another eligible member, please indicate that patient is a dependent and provide eligible member information.',
  'Client is not eligible':
    'Client is not eligible for Lyra benefits at this session date and will be responsible for the cost of this session.',
  'Missing ICD-10': 'Please Re-Submit With ICD-10 Code And Co-Payment Consent',
  'Non-Contracted Service/Session Rate': 'Non-Contracted Service/Session Rate',
  'Duplicate Invoice': 'Duplicate Invoice',
  'Meds are not covered': 'This employer does not cover Medication Management through Lyra',
  'No session occurred':
    'In the future, please email providers@lyrahealth.com to notify us of treatment termination for your clients without a session occurring. Submitting an invoice will charge the client a session.',
  'Test Client': 'Test Client',
  'Resubmit as Individual Follow Up': 'Please resubmit under session type "Individual Follow Up"',
  'Resubmit as Couples': 'Please resubmit as a "Couples" session type.',
  'Resubmit as Med Management': 'Please resubmit as a "Medication Management" session type.',
  'Reach out for further instructions':
    'Please reach out to providers@lyrahealth.com for assistance with submitting this invoice.',
  'EMDR Diagnosis does not contain trauma/PTSD':
    'Lyra is only able to reimburse for evidence-based therapies (EBTs) as defined by the American Psychological Association’s (APA) Division 12, the Society for Clinical Psychology. At this time, EMDR is only recognized as an EBT for PTSD/trauma and as such we will not be able to approve this service.',
  'Session length exceeds 90 minutes':
    'Lyra is unable to reimburse for sessions that exceed 90 minutes. Extended sessions must be clinically indicated and approved by providers@lyrahealth.com.',
  'Client not approved for extended session':
    'Extended sessions must be clinically indicated and approved by providers@lyrahealth.com.',
  'Multiple sessions in one day/Non-approved extended session':
    'As indicated in your contract with Lyra, therapy sessions should be delivered at a frequency of no more than once a week for a session duration of 45-50 minutes. Multiple sessions in the same day must be clinically indicated and approved by providers@lyrahealth.com.',
  'Non-approved multiple couples sessions in a week':
    'As indicated in your contract with Lyra, therapy sessions should be delivered at a frequency of no more than once a week. Multiple sessions in the same week must be clinically indicated and approved by providers@lyrahealth.com.',
  'Double/extended group session':
    'As indicated in your contract with Lyra, therapy sessions should be delivered at a frequency of no more than once a week.',
  'Collateral session submitted with family member as patient':
    'Please resubmit as a follow up session using the client’s name who is receiving treatment as the client on the invoice',
  'Non-emergency short session':
    'As indicated in your contract with Lyra, therapy sessions should be delivered with a session duration of 45-50 minutes. Please do not charge the client.',
}

export const paymentReversalReasons = {
  'Accidentally Approved': 'Invoice was incorrectly approved.',
  'Billed in Error': 'This invoice was billed in error.',
  Duplicate: 'This invoice is a duplicate.',
  'Not Attended/Session did not occur': 'This session did not occur.',
  'Provider Request - Unspecified': 'We received a request to reverse this invoice.',
  'Tech Issues': 'The client was experiencing technical issues.',
  'Wrong Group Provider': 'The invoice was submitted under the wrong group provider.',
  'Wrong Sponsoring Company': 'The invoice lists the incorrect sponsoring company.',
  'Wrong Client': 'The invoice lists the incorrect client.',
  'Wrong DOS': 'The invoice lists the incorrect date of service.',
  'Wrong Session Type': 'The invoice lists the incorrect session type.',
  'Wrong Session Length': 'The invoice lists the incorrect session length.',
  'Wrong Rate': 'The invoice was approved at an incorrect rate.',
  'Wrong Modality': 'The invoice lists the incorrect session modality.',
  'Wrong Attendance': 'The invoice lists the incorrect attendance.',
  'Wrong Diagnosis': 'The invoice lists the incorrect diagnosis.',
  'Discontinue Care': 'The invoice incorrectly identifies (dis)continuation of care.',
  'Provider License Expired':
    'The license Lyra has on file for you has expired. Please contact credentialing@lyrahealth.com with your updated license to continue seeing Lyra clients.',
  'Client out of Country': 'The client was not in the country, and therefore could not receive services.',
}

export const paymentExclusionReasons = {
  'Dual Eligibility - Same Sponsoring Company':
    '***Internal Use Only*** This session was excluded from the client’s session count for the following reason: Dual Eligibility - Same Sponsoring Company',
  'End of Employment':
    '***Internal Use Only*** This session was excluded from the client’s session count for the following reason: End of Employment',
  'Grief & Loss':
    '***Internal Use Only*** This session was excluded from the client’s session count for the following reason: Grief & Loss',
  'Monitored Referral':
    '***Internal Use Only*** This session was excluded from the client’s session count for the following reason: Monitored Referral',
  Relocation:
    '***Internal Use Only*** This session was excluded from the client’s session count for the following reason: Relocation',
  'Sponsoring Company Request - Incident':
    '***Internal Use Only*** This session was excluded from the client’s session count for the following reason: Sponsoring Company Request - Incident',
  'Sponsoring Company Request - Program Design':
    '***Internal Use Only*** This session was excluded from the client’s session count for the following reason: Sponsoring Company Request - Program Design',
  'Sponsoring Company Request - Unspecified':
    '***Internal Use Only*** This session was excluded from the client’s session count for the following reason: Sponsoring Company Request - Unspecified',
  'Trust & Safety':
    '***Internal Use Only*** This session was excluded from the client’s session count for the following reason: Trust & Safety',
}

export const companyMessages = {
  MULTIPLE_INSTANCES: 'multipleInstances',
  NUCLEAR_BADGED: 'nuclearBadged',
}

// certain companies have warnings that we want to display when a DA provider selects them in the
// payment request form dropdown in the Sponsoring Company list when Requesting Payment.
export const companiesWithWarnings = {
  aps: [companyMessages.NUCLEAR_BADGED],
  att: [companyMessages.MULTIPLE_INSTANCES],
  attunion: [companyMessages.MULTIPLE_INSTANCES],
  bluepearlvet: [companyMessages.MULTIPLE_INSTANCES],
  'bluepearlvet-2': [companyMessages.MULTIPLE_INSTANCES],
  mgb: [companyMessages.MULTIPLE_INSTANCES],
  mgbhealthplan: [companyMessages.MULTIPLE_INSTANCES],
  nextera: [companyMessages.NUCLEAR_BADGED],
  qualcomm: [companyMessages.MULTIPLE_INSTANCES],
  'qualcomm-16eap': [companyMessages.MULTIPLE_INSTANCES],
  vca: [companyMessages.MULTIPLE_INSTANCES],
  'vca-2': [companyMessages.MULTIPLE_INSTANCES],
  united: [companyMessages.MULTIPLE_INSTANCES],
  unitedgroundexpress: [companyMessages.MULTIPLE_INSTANCES],
}

export const cloudfrontOriginUrl = isProduction
  ? 'https://provider.lyrahealth.com'
  : 'https://provider.staging.lyrahealth.com'

export const affirmativeResponses = ['Y', 'y', 'Yes', 'yes', 'True', 'true', true, 1]

// TO-DO - switch this to the actual URLs when they come inz
export const measurementBasedCareUrls = {
  percentageHelpArticle:
    'https://bct-lyrahealth.zendesk.com/hc/en-us/articles/360035387014-Measurement-Based-Care-Compensation-Criteria',
  countHelpArticle:
    'https://bct-lyrahealth.zendesk.com/hc/en-us/articles/360042313293-Caseload-Measurement-Based-Care-MBC-Compensation-Criteria#h_01GB90V32NGZG8EWWBT6WNK3NP',
}

export const LAUNCHDARKLY_CLIENT_ID = isProduction ? '620c19ea67adea141b6aa714' : '620c19ea67adea141b6aa713'

export const severityValues = {
  MILD: 'mild',
  MODERATE: 'moderate',
  SEVERE: 'severe',
}

export const severityOptions = {
  [severityValues.MILD]: 'Mild',
  [severityValues.MODERATE]: 'Moderate',
  [severityValues.SEVERE]: 'Severe',
}
export const ebtTypes = [
  { label: 'Acceptance and Commitment Therapy (ACT)', value: 'acceptance_commitment_therapy' },
  { label: 'Attachment Based Family Therapy', value: 'attachment_based_family_therapy' },
  { label: 'Behavior Management Therapy', value: 'behavior_management_therapy' },
  { label: 'Behavior Modification Therapy', value: 'behavior_modification_therapy' },
  { label: 'Behavioral Activation (BA)', value: 'behavioral_activation' },
  { label: 'Behavioral Couples Therapy', value: 'behavioral_couples_therapy' },
  { label: 'Behavioral Therapy', value: 'behavioral_therapy' },
  { label: 'Child-Parent Psychotherapy', value: 'child_parent_psychotherapy' },
  { label: 'Cognitive Behavioral Conjoint Therapy for PTSD', value: 'cognitive_behavioral_conjoint_therapy_ptsd' },
  { label: 'Cognitive Behavioral Therapy (CBT)', value: 'cognitive_behavioral_therapy' },
  { label: 'Cognitive Behavioral Therapy for Suicidality (CBT-SI)', value: 'cognitive_behavioral_therapy_suicidality' },
  { label: 'Cognitive Processing Therapy (CPT)', value: 'cognitive_processing_therapy' },
  { label: 'Cognitive Therapy', value: 'cognitive_therapy' },
  { label: 'Dialectical Behavior Therapy (DBT)', value: 'dialectical_behavior_therapy' },
  { label: 'Emotion Focused Couples Therapy (EFT)', value: 'emotion_focused_couples_therapy' },
  { label: 'Exposure and Response Prevention (ERP)', value: 'exposure_response_prevention' },
  { label: 'Eye Movement Desensitization and Reprocessing (EMDR)', value: 'eye_movement_desensitization_reprocessing' },
  { label: 'Family Focused Therapy (FFT)', value: 'family_focused_therapy' },
  { label: 'Family-Based Treatment (FBT) for Eating Disorders', value: 'family_based_treatment_eating_disorders' },
  { label: 'Gottman Couples Therapy', value: 'gottman_couples_therapy' },
  { label: 'Habit Reversal Training (HRT)', value: 'habit_reversal_training' },
  { label: 'Integrative Behavioral Couples Therapy (IBCT)', value: 'integrative_behavioral_couples_therapy' },
  { label: 'Intensive Short-Term Dynamic Psychotherapy (ISTDP)', value: 'intensive_short_term_dynamic_psychotherapy' },
  { label: 'Interpersonal and Social Rhythm Therapy (ISRT)', value: 'interpersonal_social_rhythm_therapy' },
  { label: 'Interpersonal Psychotherapy (IPT)', value: 'interpersonal_psychotherapy' },
  { label: 'Mindfulness Based Approaches', value: 'mindfulness_based_approaches' },
  { label: 'Mindfulness-Based Cognitive Therapy (MBCT)', value: 'mindfulness_based_cognitive_therapy' },
  { label: 'Motivational Interviewing', value: 'motivational_interviewing' },
  { label: 'Multidimensional Family Therapy', value: 'multidimensional_family_therapy' },
  { label: 'Multisystemic Therapy', value: 'multisystemic_therapy' },
  { label: 'Parent Management Training (PMT)', value: 'parent_management_training' },
  { label: 'Parent-Child Interaction Therapy', value: 'parent_child_interaction_therapy' },
  { label: 'Problem-Solving Therapy', value: 'problem_solving_therapy' },
  { label: 'Prolonged Exposure Therapy', value: 'prolonged_exposure_therapy' },
  { label: 'Seeking Safety', value: 'seeking_safety' },
  { label: 'SMART Recovery', value: 'smart_recovery' },
  { label: 'Social Skills Training (SST)', value: 'social_skills_training' },
  { label: 'Trauma Focused CBT (Child/Adolescent)', value: 'trauma_focused_cbt' },
  { label: 'Unified Protocol (UP)', value: 'unified_protocol' },
  { label: 'Other', value: 'other' },
]

export const getConditions = ({ includeDivorce = true }: { includeDivorce?: boolean }) => {
  const conditions = [
    { label: 'ADHD', value: 'adhd' },
    { label: 'Generalized Anxiety Disorder', value: 'generalized_anxiety_disorder' },
    { label: 'Panic', value: 'panic' },
    { label: 'Social Anxiety Disorder', value: 'social_anxiety_disorder' },
    { label: 'Behavioral Problems', value: 'behavioral_problems' },
    { label: 'Bipolar Disorder', value: 'bipolar_disorder' },
    { label: 'Chronic Pain', value: 'chronic_pain' },
    { label: 'Depression/Sadness', value: 'depression_sadness' },
    { label: 'Dysthymic Disorder', value: 'dysthymic_disorder' },
    { label: 'Eating Issues', value: 'eating_issues' },
    { label: 'Grief', value: 'grief' },
    { label: 'OCD', value: 'ocd' },
    { label: 'Parenting / Child Behavior Issues', value: 'parenting_child_behavior_issues' },
    { label: 'Relationship Issues', value: 'relationship_issues' },
    { label: 'Separation or Divorce', value: 'divorce' },
    { label: 'Stress', value: 'stress' },
    { label: 'Life Transitions', value: 'life_transition' },
    { label: 'Career Issues', value: 'career_issues' },
    { label: 'Sleep Issues', value: 'sleep_issues' },
    { label: 'Alcohol Use', value: 'alcohol_use' },
    { label: 'Substance Use', value: 'substance_use' },
    { label: 'Self-Harm (Nonsuicidal Self-Harm)', value: 'self_harm' },
    { label: 'Suicidal Ideation', value: 'suicidal_ideation' },
    { label: 'Trauma/PTSD', value: 'trauma' },
    { label: 'Other', value: 'other' },
  ]
  return includeDivorce ? conditions : conditions.filter((condition) => condition.value !== 'divorce')
}

export const deprecatedConditions = [
  { label: 'Alcohol Use', value: 'alcohol_use_disorder' },
  { label: 'Generalized Anxiety Disorder', value: 'anxiety' },
  { label: 'Generalized Anxiety Disorder', value: 'anxiety_disorder' },
  { label: 'Behavioral Problems', value: 'child_conduct_behaviors' },
  { label: 'Depression/Sadness', value: 'depressive_disorder' },
  { label: 'Substance Use', value: 'substance_use_disorder' },
  { label: 'Suicidal Ideation', value: 'high_risk_safety_concerns' },
  { label: 'Relationship Issues', value: 'relationship_distress' },
  { label: 'Parenting / Child Behavior Issues', value: 'parenting_child_conflict' },
  { label: 'Other', value: 'psychosocial_adjustment' },
  { label: 'Other', value: 'psychotic_disorder' },
  { label: 'Trauma/PTSD', value: 'ptsd_trauma_related_disorder' },
  { label: 'Other', value: 'discriminatory_stress_trauma' },
  { label: 'Insomnia', value: 'sleep' },
  { label: 'Bipolar Disorder', value: 'bipolar_related_disorder' },
  { label: 'Eating Disorder', value: 'eating_food_related_disorder' },
  { label: 'OCD', value: 'ocd_compulsive_disorder' },
  { label: 'Substance Abuse', value: 'sud_addiction' },
  { label: 'Relationship Issues', value: 'marital_relationship_distress' },
  { label: 'Separation or Divorce', value: 'separation_divorce' },
]

export const demographicAgeMap = {
  Infant: 'Infant (0 -2)',
  'Younger Child': 'Younger Child (3-5)',
  'Early Child': 'Early Child (3-5)',
  Child: 'Child (6-9)',
  'Young Child': 'Young Child (0-5)',
  'Older Child': 'Older Child (6-11)',
  Adolescent: 'Adolescent (12-17)',
  'Early Adolescent': 'Early Adolescent (10-12)',
  'Middle Adolescent': 'Middle Adolescent (13-15)',
  'Late Adolescent': 'Late Adolescent (16-17)',
  Adult: 'Adult (18-64)',
  Senior: 'Senior (65+)',
}

export const DA_PROGRAM_TAXONOMY_TO_DISPLAY_VALUE = {
  [PROGRAM_TAXONOMY.clientele.couples]: {
    display: 'Couples Therapy',
  },
  [PROGRAM_TAXONOMY.clientele.family]: {
    display: 'Family Therapy',
  },
  [PROGRAM_TAXONOMY.clientele.individual]: {
    display: 'Individual Therapy',
  },
  [PROGRAM_TAXONOMY.clientele.group]: {
    display: 'Group Therapy',
  },
  [PROGRAM_TAXONOMY.treatment.medicationManagement]: {
    display: 'Medication Management',
  },
}

export const COACHING_HIDDEN_FILTER_CATEGORIES = ['Suicidal Ideation', 'OCD', 'PTSD', 'Disordered Eating']

export const LCTT_ACTIVITY_BADGE_TEXT = 'TEEN'
