import React from 'react'
import { useNavigate } from 'react-router-dom'

import { useTheme } from 'styled-components/native'

import { BodyTextSize, Link, PrimaryButton, Subhead, SubheadSize, tID } from '@lyrahealth-inc/ui-core-crossplatform'

import { actions, mixpanelEvents } from '../../../../mixpanel/mixpanelConstants'
import { track } from '../../../../mixpanel/mixpanelTracking'
import { SCHEDULING_ACTIVATE_STEP_FIVE } from '../../common/constants/routingConstants'
import { ButtonGroupContainer, HeaderContainer, LinkButtonContainer, WalkthroughContentContainer } from '../constants'

export const SchedulingWalkthrough4: React.FC = () => {
  const navigate = useNavigate()
  const { colors } = useTheme()

  const openSimplePractice = () => {
    track({ event: mixpanelEvents.BUTTON_PRESS, action: actions.SCHEDULING_WALKTHROUGH_SIMPLE_PRACTICE })
    window.open('https://secure.simplepractice.com/practice_settings/calendar_settings', '_blank')
    onNextStep()
  }

  const onNextStep = () => {
    track({ event: mixpanelEvents.BUTTON_PRESS, action: actions.SCHEDULING_WALKTHROUGH_NEXT_STEP })
    navigate(SCHEDULING_ACTIVATE_STEP_FIVE.route)
  }

  return (
    <WalkthroughContentContainer>
      <HeaderContainer>
        <Subhead
          level='2'
          size={SubheadSize.LARGE}
          textAlign='center'
          text='Open your SimplePractice settings. Then come back for the next step.'
          color={colors.textPrimary}
        />
      </HeaderContainer>
      <ButtonGroupContainer>
        <LinkButtonContainer>
          <Link
            text='Okay, I’ve opened SimplePractice'
            onPress={onNextStep}
            size={BodyTextSize.DEFAULT}
            testID={tID('CompleteStepFour-button')}
          />
        </LinkButtonContainer>
        <PrimaryButton
          text='Open SimplePractice Settings'
          onPress={openSimplePractice}
          testID={tID('OpenSimplePractice-button')}
        />
      </ButtonGroupContainer>
    </WalkthroughContentContainer>
  )
}
