import React from 'react'
import { Field } from 'redux-form/immutable'
import CSSModules from 'react-css-modules'
import styles from './providerInfoForm.module.scss'
import { states, paymentPreferences } from '../../../constants/appConstants'

import {
  DropZone,
  CardIcon,
  InputField,
  MaskedInputField,
  SelectField,
  Checkbox,
  PrimaryButton,
  DefaultButton,
} from '@lyrahealth-inc/ui-core'

const dropZonePlaceholder = CSSModules(() => {
  return (
    <div className={styles['dropzone-placeholder']}>
      <CardIcon fillColor={styles.x_light_gray_100} />
      <span>Drag image here</span>
    </div>
  )
}, styles)

const dropZoneContext = (
  <div>Please upload a photo of your passport, driver’s license or ID card in JPEG or PNG formats</div>
)

const nameOnAccountToolTipContent = (
  <strong>Check Line 1 of your Form W-9 for the owner’s name or business name.</strong>
)

const doingBusinessAsToolTipContent = <strong>Check Line 2 of your Form W-9.</strong>

type Props = {
  handleSubmit?: (...args: any[]) => any
  submitting?: boolean
  submitFunction?: (...args: any[]) => any
  primaryButtonText?: string
  termsOfUseHandler?: (...args: any[]) => any
  accountType?: string
}

class ProviderInfoForm extends React.Component<Props> {
  render() {
    const { handleSubmit, submitting, submitFunction, primaryButtonText, termsOfUseHandler, accountType } = this.props

    return (
      // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      <form onSubmit={handleSubmit(submitFunction)}>
        <Field name='lyra_id' type='hidden' component={InputField} />
        <Field name='account_type' type='hidden' component={InputField} />

        <h3>Identity</h3>
        {accountType === 'company' && <p>Enter the information of the business’s owner or operator.</p>}
        <div className='row'>
          <div className='col-xs-12 col-sm-6'>
            <Field
              name='first_name'
              type='text'
              readonly
              component={InputField}
              label='First Name'
              placeholder='First Name'
            />
          </div>
          <div className='col-xs-12 col-sm-6'>
            <Field
              name='last_name'
              type='text'
              readonly
              component={InputField}
              label='Last Name'
              placeholder='Last Name'
            />
          </div>
        </div>
        <div className='row'>
          <div className={`col-xs-12 ${accountType === 'individual' ? 'col-sm-6' : ''}`}>
            <Field name='email' type='text' readonly component={InputField} label='Email' placeholder='Email' />
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12 col-sm-6'>
            <Field
              name='phone'
              type='tel'
              component={MaskedInputField}
              mask='(111) 111-1111'
              placeholder='(XXX) XXX-XXXX'
              label='Phone Number'
              data-test-id='ProviderInfoForm-phonenumber-field'
            />
          </div>
          <div className='col-xs-12 col-sm-6'>
            <Field
              name='date_of_birth'
              type='tel'
              component={MaskedInputField}
              mask='11/11/1111'
              placeholder='MM/DD/YYYY'
              label='Date of Birth'
              data-test-id='ProviderInfoForm-dob-field'
            />
          </div>
        </div>
        <div className='row'>
          {accountType === 'company' && (
            <div className='col-xs-6'>
              <Field
                name='ssn'
                type='text'
                component={MaskedInputField}
                mask='111111111'
                label='Social Security Number'
                placeholder='XXXXXXXXX'
                data-test-id='ProviderInfoForm-company-ssn-field'
              />
            </div>
          )}
        </div>

        <div className='row'>
          <div className='col-xs-12'>
            <Field
              name='identification_file_front'
              className={styles['id-dropzone']}
              component={DropZone}
              // @ts-expect-error TS(2769): No overload matches this call.
              placeholder={dropZonePlaceholder}
              context={dropZoneContext}
              label='Please Upload Front of Your Identification Document'
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-xs-12'>
            <Field
              name='identification_file_back'
              className={styles['id-dropzone']}
              component={DropZone}
              // @ts-expect-error TS(2769): No overload matches this call.
              placeholder={dropZonePlaceholder}
              context={dropZoneContext}
              label='Please Upload Back of Your Identification Document'
            />
          </div>
        </div>

        <h3>Tax Information</h3>
        <p>
          Enter the exact tax name (Line 1), taxpayer ID number, and address from your W-9. We’ll send important tax
          documents to the address you enter.
        </p>
        {accountType === 'individual' && (
          <div className='row'>
            <div className='col-xs-12 col-sm-6'>
              <Field
                name='account_identifier'
                component={MaskedInputField}
                mask='111111111'
                placeholder='XXXXXXXXX'
                label='Social Security Number'
                data-test-id='ProviderInfoForm-individual-ssn-field'
              />
            </div>
            <div className='col-xs-12 col-sm-6'>
              <Field
                name='street'
                type='text'
                component={InputField}
                label='Street Address'
                placeholder='Street Address'
                data-test-id='ProviderInfoForm-individual-address-field'
              />
            </div>
          </div>
        )}

        {accountType === 'company' && (
          <div className='row'>
            <div className='col-xs-12 col-sm-6'>
              <Field
                name='account_identifier'
                component={InputField}
                placeholder='9 digit number'
                label='TIN or EIN Number'
                data-test-id='ProviderInfoForm-company-tin-field'
              />
            </div>
            <div className='col-xs-12 col-sm-6'>
              <Field
                name='street'
                type='text'
                component={InputField}
                label='Street Address'
                placeholder='Street Address'
                data-test-id='ProviderInfoForm-company-address-field'
              />
            </div>
          </div>
        )}

        <div className='row'>
          <div className='col-xs-12 col-sm-6'>
            <Field
              name='city'
              type='text'
              component={InputField}
              label='City'
              placeholder='City'
              data-test-id='ProviderInfoForm-city-field'
            />
          </div>
          <div className='col-xs-12 col-sm-6'>
            <Field name='state' component={SelectField} label='State' data-test-id='ProviderInfoForm-state-field'>
              <option hidden value=''>
                Select
              </option>
              {states.map((state) => (
                <option key={state.value} value={state.value}>
                  {state.text}
                </option>
              ))}
            </Field>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12 col-sm-6'>
            <Field
              name='zip_code'
              component={MaskedInputField}
              mask='11111'
              label='Zip Code'
              placeholder='Zip Code'
              data-test-id='ProviderInfoForm-zipcode-field'
            />
          </div>
          {accountType === 'company' && (
            <div className='col-xs-12 col-sm-6'>
              <Field
                name='account_name'
                type='text'
                tooltipContent={nameOnAccountToolTipContent}
                component={InputField}
                label='Name on account (for tax purposes)'
                placeholder='Name on account (for tax purposes)'
                data-test-id='ProviderInfoForm-nameonaccount-field'
              />
            </div>
          )}
        </div>

        {accountType === 'company' && (
          <div className='row'>
            <div className='col-xs-12 col-sm-6'>
              <Field
                name='dba'
                type='text'
                tooltipContent={doingBusinessAsToolTipContent}
                component={InputField}
                label='Doing Dusiness As (DBA)'
                placeholder='Doing Business As (DBA)'
                data-test-id='ProviderInfoForm-dba-field'
              />
            </div>
          </div>
        )}

        <h3>Bank Information</h3>
        <p>Lyra will deposit your payments here.</p>
        <div className='row'>
          <div className='col-xs-12 col-sm-6'>
            <Field
              name='payment_preference'
              label='Payment Preference'
              component={SelectField}
              data-test-id='ProviderInfoForm-paymentpreference-field'
            >
              <option hidden value=''>
                Select
              </option>
              {Object.entries(paymentPreferences).map(([key, val]) => (
                <option key={key} value={key}>
                  {val}
                </option>
              ))}
            </Field>
          </div>
          <div className='col-xs-12 col-sm-6'>
            <Field
              name='bank'
              type='text'
              component={InputField}
              label='Bank Name'
              placeholder='Bank Name'
              data-test-id='ProviderInfoForm-bankname-field'
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12 col-sm-6'>
            <Field
              name='routing_number'
              type='text'
              component={MaskedInputField}
              label='Routing Number'
              mask='111111111'
              placeholder='9 Digit Routing Number'
              data-test-id='ProviderInfoForm-routingnumber-field'
            />
          </div>
          <div className='col-xs-12 col-sm-6'>
            <Field
              name='account_number'
              type='text'
              component={InputField}
              label='Account Number'
              placeholder='Up to 17 Digit Account Number'
              data-test-id='ProviderInfoForm-accountnumber-field'
            />
          </div>
        </div>

        <div className={`row ${styles['consent-container']}`}>
          <Field name='tos' component={Checkbox} clickableLabel={false} type='checkbox'>
            I have read and understand Lyra Health&apos;s&nbsp;
            <DefaultButton
              id='termsOfServiceBtn'
              onClick={termsOfUseHandler}
              customClass={styles['modal-link']}
              unstyled
            >
              Terms of Use
            </DefaultButton>
          </Field>
        </div>

        <div className='row'>
          <div className='col-xs-12'>
            <PrimaryButton
              id='test-providerinfo-submit'
              isLoading={submitting}
              type='submit'
              // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
              onKeyDown={handleSubmit(submitFunction)}
            >
              {primaryButtonText}
            </PrimaryButton>
          </div>
        </div>
      </form>
    )
  }
}

export default ProviderInfoForm
