import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

type LyraHubBenefitIllustrationProps = {
  size?: number
}

export const LyraHubBenefitIllustration: FunctionComponent<LyraHubBenefitIllustrationProps> = ({ size = 45 }) => {
  return (
    <Svg width={size} height={size} fill='none' viewBox='0 0 45 45'>
      <Path
        fill='#FFE9E3'
        d='M22.658 0c-3.106 0-6.12.533-8.957 1.583C6.945 4.087 2.196 9.639.67 16.813c-1.009 4.68-.88 9.006.396 13.223 1.63 5.386 5.628 9.927 10.972 12.46 2.652 1.26 5.7 1.979 9.056 2.137.431.02.87.03 1.305.03a30.43 30.43 0 0 0 8.052-1.096c.203-.05.397-.103.602-.164 2.381-.697 4.524-1.8 6.358-3.273 1.99-1.59 3.63-3.608 4.866-5.99.243-.47.476-.965.733-1.556 1.414-3.283 2.097-6.959 1.976-10.633-.057-1.625-.298-3.352-.716-5.137-.689-2.897-1.81-5.622-3.246-7.884a21.908 21.908 0 0 0-1.112-1.577c-2.58-3.327-6.599-5.663-11.62-6.75A26.62 26.62 0 0 0 22.66.002V0Z'
      />
      <Path
        fill='#017A8D'
        fillRule='evenodd'
        d='M10.81 16.772a3.416 3.416 0 0 1 3.42-3.413h10.367a3.213 3.213 0 0 1 3.216 3.19v1.038h-8.347a2.277 2.277 0 0 0-2.28 2.275v6.488h-1.714l-3.521 3.668c-.294.54-1.115.349-1.137-.266l-.004-.089V16.772Zm6.377 9.624v2.424c0 .942.765 1.706 1.71 1.706h10.707l3.523 3.562c.3.495 1.063.283 1.063-.295V19.869a2.285 2.285 0 0 0-2.288-2.282h-4.089v6.534a2.277 2.277 0 0 1-2.28 2.275h-8.346Z'
        clipRule='evenodd'
      />
    </Svg>
  )
}
