import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

type Props = {
  size?: number | string
}

export const OutPatientSupportSpotIllustrationWhiteBorder: FunctionComponent<Props> = ({ size = 124 }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 513 513' fill='none'>
      <Path
        d='M258.3 2.84106C222.959 2.84106 188.67 8.90155 156.384 20.8505C79.5203 49.3419 25.4818 112.507 8.11986 194.146C-3.35359 247.375 -1.88652 296.608 12.6323 344.586C31.1679 405.859 76.6671 457.529 137.464 486.345C167.645 500.681 202.319 508.867 240.503 510.668C245.41 510.89 250.398 511.012 255.355 511.012C285.992 511.012 316.81 506.813 346.961 498.536C349.278 497.96 351.483 497.363 353.811 496.675C380.906 488.743 405.289 476.197 426.152 459.432C448.805 441.331 467.452 418.384 481.516 391.269C484.288 385.927 486.939 380.301 489.863 373.573C505.95 336.219 513.72 294.402 512.344 252.596C511.697 234.111 508.945 214.462 504.19 194.146C496.358 161.183 483.59 130.182 467.26 104.443C462.788 97.5324 458.68 91.7249 454.613 86.5041C425.262 48.6438 379.53 22.0748 322.405 9.71097C301.421 5.15801 279.85 2.85118 258.31 2.85118L258.3 2.84106Z'
        fill='white'
      />
      <Path
        d='M490.38 253.284C489.753 235.568 487.042 217.214 482.772 198.972C475.791 169.58 464.317 140.603 448.584 115.795C444.932 110.169 441.188 104.746 437.121 99.5355C407.618 61.4931 362.908 40.3269 317.146 30.4217C266.173 19.3732 211.972 22.6007 162.973 40.7417C92.0881 67.0072 43.8268 124.546 28.0837 198.557C22.0333 226.593 20.2627 258.282 22.9742 288.726H22.7618C24.33 306.027 27.4564 322.914 32.1408 338.444C49.5534 395.983 91.8655 441.645 145.762 467.182C174.952 481.043 207.571 487.923 240.727 489.491C274.601 491.059 309.001 486.78 340.801 478.028C342.885 477.512 344.868 476.986 346.952 476.358C371.862 469.063 393.544 457.802 412.1 442.899C432.325 426.741 448.999 406.415 461.93 381.505C464.742 376.082 467.241 370.669 469.639 365.135C484.856 329.794 491.635 291.438 490.279 253.395L490.38 253.294V253.284Z'
        fill='#FFE9E3'
      />
      <Path
        d='M219.209 126.63C219.209 126.63 310.749 105.17 319.459 106.19C327.499 107.13 333.219 109.35 339.819 128.52C346.179 146.99 389.669 333.16 389.749 333.6C394.369 359.11 382.709 361.57 371.649 365.98C360.719 370.35 238.709 398.98 206.229 406.53C197.719 408.51 188.409 408.77 181.229 406.31C175.549 404.36 169.469 402.75 162.969 381.89C154.699 355.37 117.509 178.6 117.509 178.6C117.509 178.6 113.519 156.84 135.309 150.25C157.089 143.66 219.199 126.63 219.199 126.63H219.209Z'
        fill='#3BA5B5'
      />
      <Path
        d='M223.6 144.06C223.6 144.06 302.94 125.46 310.48 126.34C317.45 127.15 322.4 129.07 328.13 145.7C333.64 161.71 371.33 323.05 371.4 323.43C375.4 345.54 365.3 347.67 355.72 351.49C346.25 355.27 240.51 380.09 212.36 386.63C204.99 388.34 196.92 388.57 190.7 386.44C185.77 384.75 180.51 383.36 174.87 365.27C167.71 342.29 135.47 189.09 135.47 189.09C135.47 189.09 132.02 170.23 150.89 164.52C169.77 158.81 223.6 144.05 223.6 144.05V144.06Z'
        fill='#F7F5EF'
      />
      <Path
        d='M194.84 128.56C194.84 128.56 162.32 133.8 162.8 143.85C163.27 153.89 163.48 169.3 179.67 168.02C195.86 166.74 284.44 144.79 289.83 139.51C295.23 134.22 290.94 110.09 282.34 108.65C273.74 107.21 249.39 115.33 249.39 115.33C249.39 115.33 238.88 94.9095 218.43 101.2C197.98 107.49 194.85 128.57 194.85 128.57L194.84 128.56Z'
        fill='#008295'
      />
      <Path d='M170.862 230.871L273.965 205.832L271.985 197.679L168.882 222.718L170.862 230.871Z' fill='#DCCDBF' />
      <Path d='M179.231 265.374L320.262 231.124L318.282 222.971L177.251 257.221L179.231 265.374Z' fill='#DCCDBF' />
      <Path d='M187.606 299.863L328.637 265.614L326.657 257.461L185.626 291.71L187.606 299.863Z' fill='#DCCDBF' />
      <Path d='M411.411 193.323L388.391 173.883L285.244 296.027L308.264 315.467L411.411 193.323Z' fill='#FFC03C' />
      <Path
        d='M411.715 161.916L419.286 168.31C423.549 171.91 424.087 178.294 420.487 182.557L411.39 193.33L388.37 173.89L397.467 163.118C401.068 158.854 407.451 158.316 411.715 161.916Z'
        fill='#F69170'
      />
      <Path d='M285.26 296.019L277.32 328.779L308.28 315.459L285.26 296.019Z' fill='#DCCDBF' />
    </Svg>
  )
}
