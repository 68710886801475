import React from 'react'
import { Outlet } from 'react-router'
import { useLocation } from 'react-router-dom'

import { toNumber } from 'lodash-es'
import styled from 'styled-components/native'

import { ThemeType } from '@lyrahealth-inc/ui-core-crossplatform'

import { SchedulingWalkthroughNavBar } from './SchedulingWalkthroughNavBar'

const BackgroundContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  backgroundColor: theme.colors.backgroundSecondary,
}))

export const SchedulingWalkthrough: React.FC = () => {
  const { pathname } = useLocation()

  const lastPathSegment = pathname.split('/').pop()
  const screenIndexFromPath = lastPathSegment === 'activate' ? 0 : toNumber(lastPathSegment)

  return (
    <BackgroundContainer>
      <SchedulingWalkthroughNavBar screenIndex={screenIndexFromPath} />
      <Outlet />
    </BackgroundContainer>
  )
}
