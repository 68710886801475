import { combineReducers } from '@reduxjs/toolkit'

// Reducers
import data from './ltClientsDataReducer'
import clientDetails from '../clientDetails/data/ltClientDetailsReducer'

export default combineReducers({
  clientDetails,
  data,
})
