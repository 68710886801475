import { isAfter, parse } from 'date-fns'
import * as _ from 'lodash-es'

import { formValidationConstants, stringUtils } from '@lyrahealth-inc/ui-core'

import { sanitizeLyraCode } from './utils'
import { LYRA_CODE_LENGTH } from '../../common/constants/appConstants'

const requiredFields = ($$values: any, fields: any) => {
  const errors = {}
  let val
  for (const r of fields) {
    val = $$values.get(r.fieldName)
    if (
      // A boolean set to false is invalid.
      // TODO: This is incorrect and needs to be fixed, but some forms rely on it like the ClientEmailForm.
      (_.isBoolean(val) && !val) ||
      // An empty value is invalid (null, undefined and [])
      (!_.isBoolean(val) && _.isEmpty(val)) ||
      // And empty immutable object is invalid
      (typeof val.isEmpty === 'function' && val.isEmpty())
    ) {
      errors[r.fieldName] = r.errorText + ' is required'
    }
  }
  return errors
}

const requiredValidEmail = ($$values: any, field: any) => {
  const errors = {}
  const value = $$values.get(field.fieldName)
  if (!value) {
    errors[field.fieldName] = field.errorText + ' is required'
  } else if (!formValidationConstants.isValidEmail(value)) {
    errors[field.fieldName] = 'Invalid email format'
  }
  return errors
}

const optionalValidEmail = ($$values: any, field: any) => {
  const errors = {}
  const value = $$values.get(field.fieldName)
  if (!value) {
    return {}
  } else if (!formValidationConstants.isValidEmail(value)) {
    errors[field.fieldName] = 'Invalid email format'
  }
  return errors
}

const requiredValidDOB = ($$values: any, field: any) => {
  const errors = {}
  const value = $$values.get(field.fieldName)

  if (!value) {
    errors[field.fieldName] = 'Date of birth is required'
  } else {
    const { isValid, message } = formValidationConstants.isValidDateOfBirth(value, { min: 18, max: 150 })
    if (!isValid) {
      errors[field.fieldName] = message
    }
  }

  return errors
}

const requiredValidName = ($$values: any, field: any) => {
  const errors = {}
  const value = $$values.get(field.fieldName)

  if (!value) {
    errors[field.fieldName] = field.errorText + ' is required'
  } else {
    if (!formValidationConstants.isValidNameString(value)) {
      errors[field.fieldName] = 'Invalid ' + field.errorText
    }
  }

  return errors
}

const requiredValidMinimumDOB = ($$values: any, field: any) => {
  const errors = {}
  const value = $$values.get(field.fieldName)

  if (!value) {
    errors[field.fieldName] = 'Date of birth is required'
  } else {
    const { isValid, message } = formValidationConstants.isValidDateOfBirth(value, { min: 1, max: 150 })
    if (!isValid) {
      errors[field.fieldName] = message
    }
  }

  return errors
}

const requiredValidPhone = ($$values: any, field: any) => {
  const errors = {}
  const value = $$values.get(field.fieldName)

  if (!value) {
    errors[field.fieldName] = field.errorText + ' is required'
  } else {
    const enteredValue = value
    const formattedNumber = stringUtils.formatPhoneNumber(enteredValue)

    if (enteredValue !== formattedNumber) {
      errors[field.fieldName] = 'Invalid phone number'
    }
  }

  return errors
}

const requiredValidNumberOfLength = ($$values: any, field: any, length: any) => {
  const errors = {}
  let value = $$values.get(field.fieldName)
  if (value) {
    value = value.replace(/\D/g, '')
  }

  if (!value) {
    errors[field.fieldName] = field.errorText + ' is required'
  } else {
    const enteredValue = value
    if (!(enteredValue.length === length && formValidationConstants.isNumber(parseInt(enteredValue, 10)))) {
      errors[field.fieldName] = 'Invalid ' + field.errorText.toLowerCase()
    }
  }

  return errors
}

const requiredValidStringOfLength = ($$values: any, field: any, length: any) => {
  const errors = {}
  const value = $$values.get(field.fieldName)

  if (!value) {
    errors[field.fieldName] = field.errorText + ' is required'
  } else {
    if (!(value.length === length) || typeof value !== 'string') {
      errors[field.fieldName] = 'Invalid ' + field.errorText.toLowerCase()
    }
  }

  return errors
}

/**
 * Checks that the given field value only contains numbers.
 *
 * @param {Immutable.Map} $$values The form values map.
 * @param {Object} field The field to be checked.
 *
 * @return {Object} An object containing the errors, if any.
 */
const requiredValidNumber = ($$values: any, field: any) => {
  const errors = {}
  const value = $$values.get(field.fieldName)

  if (!value) {
    errors[field.fieldName] = field.errorText + ' is required'
  } else if (!formValidationConstants.isNumber(parseInt(value, 10))) {
    errors[field.fieldName] = 'Invalid ' + field.errorText.toLowerCase()
  }

  return errors
}

/**
 * Checks that a given field value is a valid integer.
 *
 * @param {Immutable.Map} $$values The form values map.
 * @param {Object} field The field to be checked.
 * @return {Object} An object containing the errors, if any.
 */
const requiredValidInteger = ($$values: any, field: any) => {
  const errors = {}
  const value = $$values.get(field.fieldName)

  if (!value) {
    errors[field.fieldName] = field.errorText + ' is required'
  } else if (!formValidationConstants.isValidInteger(value)) {
    errors[field.fieldName] = 'Invalid ' + field.errorText.toLowerCase()
  }

  return errors
}

/**
 * Checks that a given field value is a valid currency amount (= a positive decimal number).
 *
 * @param {Immutable.Map} $$values The form values map.
 * @param {Object} field The field to be checked.
 * @return {Object} An object containing the errors, if any.
 */
const requiredValidCurrencyAmount = ($$values: any, field: any) => {
  const errors = {}
  const value = $$values.get(field.fieldName)

  if (!value) {
    errors[field.fieldName] = field.errorText + ' is required'
  } else if (!formValidationConstants.isValidCurrencyAmount(value)) {
    errors[field.fieldName] = 'Invalid ' + field.errorText.toLowerCase()
  }

  return errors
}

const requiredValidDate = ($$values: any, field: any) => {
  const errors = {}
  const value = $$values.get(field.fieldName)

  if (!value) {
    errors[field.fieldName] = field.errorText + ' is required'
  } else if (!formValidationConstants.isValidDate(value)) {
    errors[field.fieldName] = 'Invalid ' + field.errorText.toLowerCase()
  }

  return errors
}

const requiredValidDateRange = ($$values: any, startField: any, endField: any) => {
  const errors = {}
  const startValue = $$values.get(startField.fieldName)
  const endValue = $$values.get(endField.fieldName)
  if (startValue && endValue && !formValidationConstants.isValidDateRange(startValue, endValue)) {
    errors[endField.fieldName] = 'Invalid date range. Start date is past the end date.'
  }

  return errors
}

const validLyraCode = ($$values: any, field: any, isRequired: any) => {
  const errors = {}
  const value = sanitizeLyraCode($$values.get(field.fieldName))
  if (!value) {
    if (isRequired) errors[field.fieldName] = field.errorText + ' is required'
  } else {
    if (value.length < LYRA_CODE_LENGTH) {
      errors[field.fieldName] = 'Incomplete ' + field.errorText.toLowerCase()
    }
  }

  return errors
}

const requiredBooleanField = ($$values: any, field: any) => {
  const errors = {}
  const value = $$values.get(field.fieldName)
  if (_.isNil(value)) {
    errors[field.fieldName] = field.errorText + ' is required'
  } else if (!_.isBoolean(value)) {
    errors[field.fieldName] = 'Invalid format'
  }
  return errors
}

/**
 * Used for the visitDate field in RequestPayment
 * Payment can't be requested after the present date
 *
 * @param {Immutable.Map} $$values The form values map.
 * @param {Object} field The field to be checked.
 * @return {Object} An object containing the errors, if any.
 */
const validVisitDate = ($$values: any, field: any) => {
  const errors = {}
  const maxDate = new Date()
  const value = parse($$values.get(field.fieldName), 'MM/dd/yyyy', new Date())
  if (isAfter(value, maxDate)) {
    errors[field.fieldName] = `${field.errorText} must not be in the future`
  }
  return errors
}

export default {
  optionalValidEmail,
  requiredFields,
  requiredValidEmail,
  requiredValidDOB,
  requiredValidMinimumDOB,
  requiredValidPhone,
  requiredValidNumberOfLength,
  requiredValidStringOfLength,
  requiredValidNumber,
  requiredValidDate,
  requiredValidDateRange,
  requiredValidInteger,
  requiredValidCurrencyAmount,
  requiredValidName,
  validLyraCode,
  requiredBooleanField,
  validVisitDate,
}
