import React, { FunctionComponent, ReactElement, useState } from 'react'
import { GestureResponderEvent, PressableStateCallbackType } from 'react-native'

import styled from 'styled-components/native'

import { IS_WEB } from '../../constants'
import { ThemeType, tID } from '../../utils'

export type ManageSessionLinkProps = {
  disabled?: boolean
  onPress: (event: GestureResponderEvent) => void
  icon?: ReactElement
  linkText: ReactElement
}

const StyledPressable = styled.Pressable<{ theme: ThemeType }>(({ theme }) => ({
  alignSelf: 'flex-start',
  color: theme.colors.textActive,
  ...(IS_WEB && { width: 'fit-content' }),
}))

const IconContainer = styled.View({
  marginRight: '4px',
})

const StyledView = styled.View<{
  theme: ThemeType
  focused?: boolean
  hovered?: boolean
}>(({ theme, focused, hovered }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  fontFamily: 'Moderat',
  padding: '8px',
  textDecoration: hovered || focused ? 'underline' : 'none',
  textDecorationColor: hovered || focused ? theme.colors.textActive : '',
  textTransform: 'capitalize',
}))

export const ManageSessionLink: FunctionComponent<ManageSessionLinkProps> = ({ disabled, linkText, onPress, icon }) => {
  const [isFocused, setIsFocused] = useState(false)

  return (
    <StyledPressable
      testID={tID(`ManageSessionLink`)}
      disabled={disabled}
      onBlur={() => {
        setIsFocused(false)
      }}
      onPress={onPress}
      onPressOut={() => {
        setIsFocused(true)
      }}
    >
      {({ hovered }: PressableStateCallbackType) => (
        <StyledView focused={isFocused} hovered={hovered}>
          {icon && <IconContainer>{icon}</IconContainer>}
          {linkText}
        </StyledView>
      )}
    </StyledPressable>
  )
}
