import { createReducer } from '@reduxjs/toolkit'

import { GET_SCHEDULING, GET_SCHEDULING_UPDATED, SUBMIT_SCHEDULING_OAUTH } from '../../common/constants/reduxConstants'

type CalendarData = {
  accessRole: string
  backgroundColor?: string
  foregroundColor?: string
  id: string
  primary: boolean
  summary: string
  timeZone?: string
  use: string
}

type CredentialsData = {
  access_token: string
  refresh_token: string
  valid: boolean
}

type SchedulingDataState = {
  auth_url?: string
  availability?: { availabilities: string[]; times: string[][] }
  calendars?: CalendarData[]
  credentials?: CredentialsData
  oauthStatus?: string
  error?: string
  schedulingLoaded?: boolean
}

// Since no one piece of response ever guaranteed to be present, here we set an initial value of false for our component
// to check if scheduling data is present in state, that is changed to true after we load scheduling data below.
const initialState = { schedulingLoaded: false }

export default createReducer<SchedulingDataState>(initialState, (builder) => {
  builder.addCase(GET_SCHEDULING, (state, action: any) => {
    state = action.payload.data
    state.schedulingLoaded = true
    return state
  })
  builder.addCase(GET_SCHEDULING_UPDATED, (state, action: any) => {
    state.availability = action.payload.data.availability
    return state
  })
  builder.addCase(SUBMIT_SCHEDULING_OAUTH, (state, action: any) => {
    state.oauthStatus = action.payload.data.oauth_status
    return state
  })
})
