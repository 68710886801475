import React, { FunctionComponent, MutableRefObject, ReactElement, ReactNode, useEffect, useMemo, useRef } from 'react'
import { Form, FormRenderProps, FormSpy } from 'react-final-form'
import { FormattedMessage, IntlShape } from 'react-intl'
import { LayoutChangeEvent, Platform, ReturnKeyTypeOptions, ViewStyle } from 'react-native'
import Animated from 'react-native-reanimated'
import { Edges, SafeAreaView } from 'react-native-safe-area-context'

import { default as SchemaForm_ } from '@rjsf/core'
import { FormApi, MutableState, ValidationErrors } from 'final-form'
import { isEqual, isNil, merge, toString } from 'lodash-es'
import { FlattenSimpleInterpolation } from 'styled-components'
import styled, { useTheme } from 'styled-components/native'

import {
  ActivityResponseData,
  CountryValues,
  FieldSchema,
  FieldValue,
  getVisibleFields,
  HealthPlansList,
  PasswordPolicy,
  removeHiddenFields,
  UiMetadata,
  validateForm,
} from '@lyrahealth-inc/shared-app-logic'

import { BasicObjectFieldTemplate } from './BasicObjectFieldTemplate'
import { fields } from './fields'
import { customArrayFieldTemplate, customFieldTemplate } from './fieldTemplates'
import { MockHTMLForm } from './MockHTMLForm'
import { ScrollFieldList } from './ScrollFieldList'
import { UICoreFormContext, UICoreFormProps } from './types'
import widgets from './widgets'
import { BodyText } from '../../atoms/bodyText/BodyText'
import { IS_WEB } from '../../constants'
import { useKeyboardVisible } from '../../hooks'
import { useGetLegacyMetadata } from '../../hooks/useGetLegacyMetadata'
import { useGetTranslatedFieldSchema } from '../../hooks/useGetTranslatedFieldSchema'
import { FormErrorBanner } from '../../molecules/formErrorBanner/FormErrorBanner'
import { getCommonStyles } from '../../styles/commonStyles'
import { BodyTextSize } from '../../styles/typeStyles'
import { DisplayNoneView } from '../../templates/content/CommonViews'
import { ThemeType } from '../../utils'

export type FormBodyProps = {
  schema?: FieldSchema
  uiSchema?: UiMetadata
  name?: string
  currentPage?: number
  totalPages?: number
  instructions?: string | null
  hideInstructions?: boolean
  initialValues?: ActivityResponseData | Dict
  readOnly?: boolean
  initialScrollIndex?: number
  saveForm: ({
    values,
    formSubmit,
  }: {
    values: Dict
    formSubmit: boolean
    formContext: Dict | null
  }) => Promise<any> | any
  onEditPress?: (index: number) => void
  onPopOverPress?: (link: string) => void
  formButton?: ({ handleSubmit, loading, errors }: FormButtonParams) => ReactElement
  showEditButtons?: boolean
  showAnswers?: boolean
  onFormChange?: ({ values }: FormChangeProperties) => void
  transformAndEvaluateErrors?: (errors: Dict) => Dict
  checkPristineOnFormChange?: boolean
  inputAccessoryViewID?: string
  returnKeyType?: ReturnKeyTypeOptions
  showErrorBanner?: boolean
  withPageBreaks?: boolean
  scrollContainerCustomStyles?: ScrollContainerCustomStyles
  formBodyCustomStyles?: FormBodyCustomStyles
  setFooterHeight?: (height: number) => void
  scrollListHeight?: number
  scrollEnabled?: boolean
  locale?: string
  customerCountryList?: CountryValues[]
  userCountryIsoCode?: string
  userCountryName?: string
  formContext?: Pick<
    UICoreFormContext,
    | 'externalValues'
    | 'stringTemplateData'
    | 'stringMessage'
    | 'labelAlignment'
    | 'loading'
    | 'useMultiSelectTypeAhead'
    | 'saveSingleSelectFieldsAsArray'
    | 'CustomLoadingIndicator'
    | 'showWordCloudCategories'
    | 'onContactCNT'
    | 'onShowTopicDetailsPress'
    | 'buttonLoading'
    | 'useTriageStyling'
    | 'insertElementInScrollContainer'
    | 'animatedSharedValues'
    | 'excludeNotSureField'
  >
  renderAboveSubmitButton?: ReactNode
  renderBelowSubmitButton?: ReactNode
  customFields?: { [name: string]: FunctionComponent }
  /**
   * Passing intl to FormBody because it is being used in some react bootstrap modals
   * React bootstrap modals render outside of IntlProvider so components inside the modal
   * will not have the intl context
   */
  intl: IntlShape
  useMultiSelectTypeAhead?: boolean
  saveSingleSelectFieldsAsArray?: boolean
  maxNumberOfFields?: number

  /**
   * Defaults to `false`.
   * When `true`, traverses the schema data and formats and translates the text with react-intl.
   * Expects the schema data to contain `MessageDescriptor` fields.
   */
  enableTranslations?: true
  inlineErrorBannerMessage?: ReactNode
  inlineSuccessBannerMessage?: ReactNode
  inlineWarningBannerMessage?: ReactNode
  passwordPolicy?: PasswordPolicy
  useCustomizedActivityIntroduction?: boolean
  activityIntroductionProviderInfo?: Dict
  showAsteriskForRequiredFields?: boolean
  hasNewLookAndFeel?: boolean
  setIsAtTopOfPage?: (isAtTopOfPage: boolean) => void
  backgroundColor?: string
  customValidation?: (fields: any, values: any) => { [key: string]: { [key: string]: string } }
  shouldResetForm?: boolean
  shouldHideSubmitButtonOnKeyboardOpen?: boolean
  submitButtonContainerSafeAreaEdges?: Edges
  disableIsRequiredFormValidation?: boolean
  scrollFieldContainerHeight?: number
  useBasicObjectFieldTemplate?: boolean
  formRef?: MutableRefObject<any>
  healthPlan?: string
  healthPlansList?: HealthPlansList[]
  nonIntegratedHealthPlansList?: HealthPlansList[]
  onReset?: () => void
  keyboardFormNavigationProps?: {
    setInputRefs: ({ name, ref }: { name: string; ref: MutableRefObject<any> }) => void
    handleOnInputFocus: (focusedFieldName: string) => void
    setFormInfo: ({ schema, uiSchema }: { schema: FieldSchema; uiSchema: UiMetadata }) => void
    handleOnInputBlur: (blurredFieldName: string) => void
    getReturnKeyType: (fieldName: string) => ReturnKeyTypeOptions | undefined
    navigateToNewField: (direction: 'previous' | 'next') => void
    areAllInputRefsSet: boolean
  }
  withConditionalLastPages?: boolean
  showCurrentPageOnly?: boolean // only show the current page and hide all others
  handleSubmitRef?: MutableRefObject<any>
  customErrorMessage?: string
}

export interface FormBodyNativeStyles {
  submitButtonContainer?: ViewStyle
}

export type ScrollContainerCustomStyles = {
  formBodyPageContainer: FlattenSimpleInterpolation
  scrollContainerContentCustomStyles?: Dict
}

export type FormBodyCustomStyles = {
  positionAboveButton?: FlattenSimpleInterpolation
  error?: FlattenSimpleInterpolation
  submitButtonContainer?: FlattenSimpleInterpolation
  submitButtonWrapper?: FlattenSimpleInterpolation
}

export type FormButtonParams = {
  handleSubmit: FormRenderProps['handleSubmit']
  setTouched: () => Array<string>
  loading: boolean
  pristine: boolean
  errors: ValidationErrors
}

export interface FormChangeProperties {
  values: Dict
}

const SchemaForm = SchemaForm_ as React.ComponentType<UICoreFormProps<unknown>>

const PositionAboveButton = styled.View<{ styles?: { [key: string]: FlattenSimpleInterpolation } }>`
  position: absolute;
  top: -50%;
  left: 12px;
  right: 12px;
  ${IS_WEB &&
  `
    width: 100%;
    top: unset;
    bottom: 110px;
    left: 0;
    alignItems: center;
    padding: 0 23px;
`}
  ${({ styles }) => styles?.positionAboveButton}
`

const Error = styled.View<{ styles?: { [key: string]: FlattenSimpleInterpolation } }>`
  max-width: 560px;
  width: 100%;
  ${({ styles }) => styles?.error}
`

const SubmitButtonContainer = styled(SafeAreaView)<{
  theme: ThemeType
  styles?: { [key: string]: FlattenSimpleInterpolation }
  hasNewLookAndFeel: boolean
  isMinWidthTablet: boolean
}>`
  ${({ theme }) =>
    `
  border-top-color: ${theme.colors.components.formBody.submitButton.border};
  box-shadow: 2px 2px 5px ${theme.colors.components.formBody.submitButton.boxShadow.fill};
  background: ${theme.colors.backgroundPrimary};
  `}
  border-top-width: 1px;
  padding: 0 23px;
  bottom: 0;
  left: 0;
  right: 0;
  ${({ theme, hasNewLookAndFeel, isMinWidthTablet }) =>
    hasNewLookAndFeel &&
    `
  padding: 0 ${isMinWidthTablet ? '40px' : '16px'};
  border-top-width: 1px;
  border-top-color: ${theme.colors.borderDefault};
  box-shadow: none;
  `}
  ${({ styles }) => styles?.submitButtonContainer}
`

const NavigationButton = styled.View<{
  styles?: { [key: string]: FlattenSimpleInterpolation }
  hasNewLookAndFeel: boolean
  isMinWidthTablet: boolean
}>`
  max-width: 560px;
  width: 100%;
  align-self: center;
  ${({ hasNewLookAndFeel, isMinWidthTablet }) =>
    hasNewLookAndFeel &&
    `
  max-width: ${isMinWidthTablet ? '160px' : '544px'};
  align-self: ${isMinWidthTablet && IS_WEB ? 'end' : 'center'};
  `}
  ${({ styles }) => styles?.submitButtonWrapper}
`

/**
 * This component is a metadata based Form Renderer. It uses [react-json-schemaform](https://github.com/rjsf-team/react-jsonschema-form) to render
 * the form and [react-final-form](https://github.com/final-form/react-final-form) to manage the form state.
 */
export const FormBody: FunctionComponent<FormBodyProps> = ({
  schema = {},
  uiSchema,
  currentPage,
  totalPages = 1,
  instructions,
  hideInstructions = false,
  initialValues,
  onEditPress,
  onPopOverPress,
  readOnly,
  showEditButtons,
  initialScrollIndex,
  formButton,
  name,
  saveForm,
  onFormChange,
  showAnswers,
  inputAccessoryViewID,
  returnKeyType,
  showErrorBanner,
  withPageBreaks,
  scrollContainerCustomStyles,
  formBodyCustomStyles,
  setFooterHeight,
  transformAndEvaluateErrors,
  checkPristineOnFormChange = true,
  scrollListHeight,
  scrollEnabled,
  locale,
  customerCountryList,
  userCountryIsoCode,
  userCountryName,
  formContext = {},
  renderAboveSubmitButton,
  renderBelowSubmitButton,
  customFields,
  intl,
  useMultiSelectTypeAhead,
  saveSingleSelectFieldsAsArray,
  enableTranslations = false,
  inlineErrorBannerMessage,
  inlineSuccessBannerMessage,
  inlineWarningBannerMessage,
  maxNumberOfFields,
  passwordPolicy,
  useCustomizedActivityIntroduction = false,
  activityIntroductionProviderInfo,
  showAsteriskForRequiredFields = true,
  hasNewLookAndFeel = false,
  setIsAtTopOfPage,
  backgroundColor,
  children,
  customValidation,
  shouldResetForm,
  shouldHideSubmitButtonOnKeyboardOpen = true,
  submitButtonContainerSafeAreaEdges = ['bottom'],
  disableIsRequiredFormValidation,
  scrollFieldContainerHeight,
  useBasicObjectFieldTemplate,
  formRef,
  healthPlan,
  healthPlansList,
  nonIntegratedHealthPlansList,
  onReset,
  keyboardFormNavigationProps,
  withConditionalLastPages,
  showCurrentPageOnly,
  handleSubmitRef,
  customErrorMessage,
}) => {
  const previousValues = useRef<Dict | null>(null)
  const finalFormRef = useRef<FormApi<Dict>>()
  const legacyMetadata = useGetLegacyMetadata({ schema, uiSchema, name })
  const newSchema = useGetTranslatedFieldSchema(intl, schema, formContext?.stringTemplateData, enableTranslations)
  const keyboardVisible = useKeyboardVisible()
  const {
    breakpoints: { isMinWidthTablet },
    colors,
  } = useTheme()
  const commonStyles = getCommonStyles(colors)
  /**
   * Hides the submit button for android when the keyboard is visible because for activites, the next button
   * gets pushed upwards when the user is trying to type directly into the input field. In the messenger however,
   * we want to push the input field for sending a message up so they can see what they are typing. This issue
   * is only applicable to android as iOS correctly handles the keyboard resizing.
   */
  const hideSubmitButton = keyboardVisible && Platform.OS === 'android' && shouldHideSubmitButtonOnKeyboardOpen
  const externalValues = formContext?.externalValues

  // Note: maxNumberOfFields only works on content that relies on externalValues i.e. intensity sliders
  const fieldsToDisplay = useMemo(
    () => getVisibleFields({ uiSchema, schema, values: externalValues }),
    [externalValues, schema, uiSchema],
  )
  const limitedFields = maxNumberOfFields ? fieldsToDisplay?.slice(0, maxNumberOfFields) : null
  const lastField = useMemo(() => {
    const fieldList = limitedFields ? limitedFields : fieldsToDisplay
    const last = fieldList?.[fieldList?.length - 1]
    //exclude notSure
    if (formContext?.excludeNotSureField && last === 'notSure') {
      return fieldList?.[fieldList?.length - 2]
    } else {
      return last
    }
  }, [limitedFields, fieldsToDisplay, formContext?.excludeNotSureField])

  // Never show divider on last field
  const uiMetaData = !!lastField
    ? merge({}, legacyMetadata, { [lastField]: { 'ui:options': { showDivider: false } } })
    : legacyMetadata

  // reset form state when prop changes to true
  useEffect(() => {
    if (shouldResetForm && finalFormRef?.current) {
      finalFormRef.current.reset()
      onReset?.()
      finalFormRef.current.restart()
      previousValues.current = null
    }
  }, [onReset, shouldResetForm])

  const submit = async (values: { [key: string]: FieldValue }) => {
    const filteredValues = removeHiddenFields({
      values,
      fields: schema.properties,
      externalValues,
    })
    return await saveForm({ values: filteredValues, formSubmit: true, formContext })
  }

  const SpyOnFormChange = () => (
    <FormSpy subscription={{ values: true, pristine: true }}>
      {({ values, pristine }) => {
        const pristineValue = checkPristineOnFormChange ? pristine : false
        // Only call onFormChange if current values are different from previous values
        if (!!onFormChange && !pristineValue && !isEqual(values, previousValues.current)) {
          previousValues.current = values
          // wrapped in a setTimeout to nerf warning: Cannot update a component while rendering a different component
          setTimeout(() => onFormChange({ values }), 0)
        }
        return null
      }}
    </FormSpy>
  )

  // Set fields to touched so that errors become visible
  const setTouched = ([fields]: Array<Dict>, state: MutableState<Dict>) => {
    const fieldKeys = Object.keys(fields)
    fieldKeys.forEach((field) => {
      if (state?.fields?.[field]) {
        state.fields[field].touched = true
      } else {
        console.warn(`Field '${field}' and/or state.fields is not defined`)
      }
    })

    return fieldKeys
  }

  // storing form info needed for form navigation from keyboard
  useEffect(() => {
    if (keyboardFormNavigationProps) {
      keyboardFormNavigationProps.setFormInfo({ schema, uiSchema: uiMetaData })
    }
  }, [keyboardFormNavigationProps, schema, uiMetaData])

  if (uiMetaData !== undefined) {
    return (
      <Form
        onSubmit={submit}
        initialValues={initialValues}
        subscription={{ submitting: true, errors: true, pristine: true, active: true }}
        validate={(values: { [key: string]: FieldValue }) => {
          const customValidationResults =
            customValidation && customValidation(schema.properties, { ...values, ...externalValues })
          const errors = {
            ...validateForm({
              fields: schema.properties,
              values: { ...values, ...externalValues },
              pagination: !isNil(currentPage),
              currentPage,
              totalPages,
              formatMessage: intl.formatMessage,
              disableIsRequiredFormValidation,
            }),
            ...customValidationResults,
          }
          return transformAndEvaluateErrors ? transformAndEvaluateErrors(errors) : errors
        }}
        mutators={{
          setTouched,
          setFormAttribute: ([fieldName, fieldVal], state, { changeValue }) => {
            changeValue(state, fieldName, () => fieldVal)
          },
        }}
        render={({ handleSubmit, submitting, errors, form, pristine }) => {
          if (handleSubmitRef) {
            handleSubmitRef.current = handleSubmit
          }
          const {
            mutators: { setTouched },
          } = form
          finalFormRef.current = form
          if (formRef) {
            formRef.current = form
          }

          const numberOfErrors = Object.keys(errors || {}).length
          return (
            <SchemaForm
              widgets={widgets}
              tagName={MockHTMLForm}
              FieldTemplate={customFieldTemplate}
              ObjectFieldTemplate={useBasicObjectFieldTemplate ? BasicObjectFieldTemplate : ScrollFieldList}
              ArrayFieldTemplate={customArrayFieldTemplate}
              schema={newSchema}
              uiSchema={uiMetaData}
              formContext={{
                setIsAtTopOfPage,
                currentPage,
                totalPages,
                instructions,
                hideInstructions,
                readOnly,
                showEditButtons,
                onEditPress,
                onPopOverPress,
                initialScrollIndex,
                showAnswers,
                inputAccessoryViewID,
                returnKeyType,
                withPageBreaks,
                scrollContainerCustomStyles,
                scrollListHeight,
                scrollEnabled,
                locale,
                customerCountryList,
                userCountryIsoCode,
                userCountryName,
                schema,
                intl,
                useMultiSelectTypeAhead,
                saveSingleSelectFieldsAsArray,
                inlineErrorBannerMessage,
                inlineSuccessBannerMessage,
                inlineWarningBannerMessage,
                limitedFields,
                passwordPolicy,
                useCustomizedActivityIntroduction,
                activityIntroductionProviderInfo,
                showAsteriskForRequiredFields,
                backgroundColor,
                scrollFieldContainerHeight,
                healthPlan,
                healthPlansList,
                nonIntegratedHealthPlansList,
                withConditionalLastPages,
                showCurrentPageOnly,
                handleOnInputFocus: keyboardFormNavigationProps?.handleOnInputFocus,
                setInputRefs: keyboardFormNavigationProps?.setInputRefs,
                handleOnInputBlur: keyboardFormNavigationProps?.handleOnInputBlur,
                getReturnKeyType: keyboardFormNavigationProps?.getReturnKeyType,
                navigateToNewField: keyboardFormNavigationProps?.navigateToNewField,
                areAllInputRefsSet: keyboardFormNavigationProps?.areAllInputRefsSet,
                ...formContext,
              }}
              fields={{ ...fields, ...customFields }}
            >
              {!!onFormChange && <SpyOnFormChange />}
              {children}
              {renderAboveSubmitButton}
              {!!formButton && !hideSubmitButton && (
                <Animated.View
                  style={{
                    opacity: formContext.animatedSharedValues?.opacity || 1,
                    ...(isMinWidthTablet && {
                      transform: [{ translateY: formContext.animatedSharedValues?.translateY || 0 }],
                    }),
                  }}
                >
                  <SubmitButtonContainer
                    hasNewLookAndFeel={hasNewLookAndFeel}
                    isMinWidthTablet={isMinWidthTablet}
                    styles={formBodyCustomStyles}
                    edges={submitButtonContainerSafeAreaEdges}
                    style={formBodyCustomStyles ? {} : commonStyles.fixedButtonContainer}
                    onLayout={(e: LayoutChangeEvent) => {
                      if (typeof setFooterHeight === 'function') setFooterHeight(e.nativeEvent.layout.height)
                    }}
                  >
                    {/* For some reason errors are not passed to setTouched unless they are also rendered. See CLIENT-1820 */}
                    <DisplayNoneView>
                      <BodyText text={toString(numberOfErrors)} size={BodyTextSize.DEFAULT} />
                    </DisplayNoneView>
                    {showErrorBanner && !!numberOfErrors && (
                      <PositionAboveButton styles={formBodyCustomStyles}>
                        <Error styles={formBodyCustomStyles}>
                          <FormErrorBanner validationErrors={errors} customErrorMessage={customErrorMessage} />
                        </Error>
                      </PositionAboveButton>
                    )}
                    <NavigationButton
                      styles={formBodyCustomStyles}
                      hasNewLookAndFeel={hasNewLookAndFeel}
                      isMinWidthTablet={isMinWidthTablet}
                    >
                      {formButton({
                        handleSubmit,
                        setTouched: () => setTouched(errors),
                        loading: submitting,
                        pristine,
                        errors,
                      })}
                    </NavigationButton>
                  </SubmitButtonContainer>
                </Animated.View>
              )}
              {renderBelowSubmitButton}
            </SchemaForm>
          )
        }}
      />
    )
  } else {
    return (
      <BodyText
        text={<FormattedMessage defaultMessage='No uiSchema' description='No uiScheme text' />}
        size={BodyTextSize.DEFAULT}
      />
    )
  }
}
