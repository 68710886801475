import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'

import { ContentLayout } from '@lyrahealth-inc/ui-core'

import { ROLES } from '../common/constants/appConstants'
import { PROVIDERS_DETAILS, PROVIDERS_REGISTER } from '../common/constants/routingConstants'
import { hasRole } from '../common/utils/utils'
import { getAuthRoles } from '../data/auth/authSelectors'
import { SentryRoutes } from '../index'
// pages
import ProvidersDashboard from '../providers/dashboard/ProvidersDashboard'
import PaymentsProviderDetails from '../providers/individualProvider/paymentsAdmin/PaymentsProviderDetails'
import ProviderDetails from '../providers/individualProvider/providersAdmin/ProviderDetails'
import RegisterProvider from '../providers/register/RegisterProvider'

type ProvidersRoutesProps = {
  roles?: any // TODO: PropTypes.instanceOf(List)
}

class ProvidersRoutes extends Component<ProvidersRoutesProps> {
  _getDashboard() {
    if (hasRole(this.props.roles, ROLES.PRACTICES_ADMIN)) {
      return <h1>PROVIDERS_DASHBOARD, for Practice Admin </h1>
    } else if (hasRole(this.props.roles, [ROLES.PROVIDERS_ADMIN, ROLES.PAYMENTS_ADMIN])) {
      // @ts-expect-error TS(2739): Type '{}' is missing the following properties from... Remove this comment to see the full error message
      return <ProvidersDashboard />
    } else if (hasRole(this.props.roles, ROLES.INDIVIDUAL_PROVIDER)) {
      return <h1>PROVIDERS_DASHBOARD, for Individual Provider </h1>
    } else {
      return <h1>PROVIDERS_DASHBOARD, default</h1>
    }
  }

  _getDetails() {
    if (hasRole(this.props.roles, ROLES.PROVIDERS_ADMIN)) {
      return <ProviderDetails />
    } else if (hasRole(this.props.roles, ROLES.PAYMENTS_ADMIN)) {
      return <PaymentsProviderDetails />
    } else {
      return <h1>PROVIDER_DETAILS, default</h1>
    }
  }

  render() {
    return (
      <div>
        <ContentLayout>
          <div style={{ marginTop: '50px' }}>
            <SentryRoutes>
              <Route path='/' element={this._getDashboard()} />
              <Route path={`${PROVIDERS_DETAILS.subroute}/*`} element={this._getDetails()} />
              <Route path={PROVIDERS_REGISTER.subroute} element={<RegisterProvider />} />
            </SentryRoutes>
          </div>
        </ContentLayout>
      </div>
    )
  }
}

const mapStateToProps = ($$state: any) => {
  return {
    roles: getAuthRoles($$state),
  }
}

export default connect(mapStateToProps, null)(ProvidersRoutes)
