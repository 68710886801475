import { createReducer } from '@reduxjs/toolkit'

import { ReconcilerClient } from '@lyrahealth-inc/shared-app-logic'

import { CLEAR_RECONCILER_LIST, GET_CLIENTS_TO_RECONCILE } from '../../common/constants/reduxConstants'

type ReconcilerDataState = {
  clientsList?: ReconcilerClient[]
  clientsListCount?: number
}

const initialState = {}

export default createReducer<ReconcilerDataState>(initialState, (builder) => {
  builder.addCase(GET_CLIENTS_TO_RECONCILE, (state, action: any) => {
    state.clientsList = action.payload.data.identities
    state.clientsListCount = action.payload.data.total
    return state
  })
  builder.addCase(CLEAR_RECONCILER_LIST, () => {
    return initialState
  })
})
