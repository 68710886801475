import { createReducer } from '@reduxjs/toolkit'

import { Alert } from './alertTypes'
import { ADD_ALERT, EXPIRE_ALERTS, HIDE_ALERTS, REMOVE_ALERT } from '../common/constants/reduxConstants'

type AlertState = Alert[]

const initialState: Alert[] = []

export default createReducer<AlertState>(initialState, {
  [ADD_ALERT]: (state, action: any) => {
    state = [action.payload]
    return state
  },
  [REMOVE_ALERT]: (state, action: any) => {
    state = state.filter((obj) => obj.id !== action.payload)
    return state
  },
  [HIDE_ALERTS]: (state, _: any) => {
    return state.map((alert) => ({
      ...alert,
      show: false,
    }))
  },
  [EXPIRE_ALERTS]: (state, _: any) => {
    return state.filter((alert) => alert.expires === false)
  },
})
