import { getClientsToReconcileData } from '../../common/http/data/clients'
import {
  GET_CLIENTS_TO_RECONCILE,
  CLEAR_RECONCILER_LIST,
  actionAlertHandler,
  actionStyles,
} from '../../common/constants/reduxConstants'

export const getClientsToReconcile = (data: any) => {
  const request = getClientsToReconcileData(data)

  return (dispatch: any) => {
    return new Promise(function (resolve, reject) {
      request.then(
        (reconcilerReturn) => {
          dispatch({
            type: GET_CLIENTS_TO_RECONCILE,
            payload: reconcilerReturn,
          })

          resolve(reconcilerReturn)
        },
        (error) => {
          actionAlertHandler({
            actionStyle: actionStyles.ERROR,
            message: error,
            dispatch,
            expires: true,
            action: GET_CLIENTS_TO_RECONCILE,
          })
          reject(error)
        },
      )
    })
  }
}

export const clearReconcilerList = () => {
  return {
    type: CLEAR_RECONCILER_LIST,
  }
}
