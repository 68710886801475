import React from 'react'
import CSSModules from 'react-css-modules'
import styles from './miniBackNav.module.scss'
import { ChevronIcon } from '@lyrahealth-inc/ui-core'

const MiniBackNav: React.FC<MiniBackNavProps> = ({ subText, backFunc, ...props }) => {
  return (
    <div styleName='container' {...props}>
      <button data-test-id='back' styleName='back-button' onClick={backFunc}>
        <ChevronIcon direction='left' width={20} />
        Back
      </button>
      <div styleName='sub-text'>{subText}</div>
    </div>
  )
}

type MiniBackNavProps = {
  subText: string
  backFunc: () => void
}

export default CSSModules(MiniBackNav, styles)
