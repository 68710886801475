import React, { FunctionComponent } from 'react'
import { FieldRenderProps } from 'react-final-form'
import { ViewStyle } from 'react-native'

import { get, includes, uniq, without } from 'lodash-es'

import { useAccessibilityFocus } from '../../hooks/useAccessibilityFocus'
import { BaseInput } from '../formElements/BaseInput'
import { Checkbox, CheckboxButtonType, checkBoxButtonTypeHasOutline } from '../formElements/Checkbox'

import type * as CSS from 'csstype'

export const CheckboxGroup: FunctionComponent<CheckBoxGroupProps> = ({
  value,
  onChange,
  name,
  label,
  labelSecondary,
  subLabel,
  labelAlignment,
  error,
  options,
  readOnly,
  buttonType = CheckboxButtonType.TEXT_OUTLINE,
  active,
  baseInputStyle,
  labelStyle,
}) => {
  const [focusRef] = useAccessibilityFocus({ active, delay: 200 })
  const handleCheckboxPress = (pressedId: string) => {
    const wasChecked = includes(value, pressedId)
    const newValue = wasChecked ? without(value, pressedId) : uniq([...value, pressedId])
    const groupsChecked = uniq(
      newValue.map((selectedId: string) =>
        get(
          options.find(({ id }) => selectedId === id),
          'group',
        ),
      ),
    )
    onChange(groupsChecked.length > 1 ? [pressedId] : newValue)
  }

  return (
    <BaseInput
      labelRef={focusRef}
      label={label}
      labelSecondary={labelSecondary}
      subLabel={subLabel}
      labelAlignment={labelAlignment}
      error={error}
      name={name}
      style={baseInputStyle}
      labelStyle={labelStyle}
    >
      <>
        {options.map(({ id, text, badge, readOnly: readOnlyPerCheckbox }) => (
          <Checkbox
            key={id}
            checked={includes(value, id)}
            onPress={() => handleCheckboxPress(id)}
            name={`${name}_${id}`}
            title={text}
            readOnly={readOnly || readOnlyPerCheckbox}
            buttonType={buttonType}
            badge={badge}
            baseInputStyle={{ marginBottom: checkBoxButtonTypeHasOutline[buttonType] ? 8 : 16 }}
          />
        ))}
      </>
    </BaseInput>
  )
}

export const CheckboxGroupRFF: FunctionComponent<FieldRenderProps<CheckBoxGroupValue>> = (props) => {
  const {
    input: { value, onChange, name },
    meta: { touched, error, active },
    label,
    labelSecondary,
    subLabel,
    labelAlignment,
    readOnly,
    options,
    buttonType = CheckboxButtonType.TEXT_OUTLINE,
    baseInputStyle,
    labelStyle,
  } = props
  return (
    <CheckboxGroup
      value={value}
      onChange={onChange}
      name={name}
      label={label}
      labelSecondary={labelSecondary}
      subLabel={subLabel}
      labelAlignment={labelAlignment}
      error={touched && error}
      options={options}
      readOnly={readOnly}
      buttonType={buttonType}
      active={active}
      baseInputStyle={baseInputStyle}
      labelStyle={labelStyle}
    />
  )
}
export type CheckBoxGroupValue = Array<string | boolean>

type CheckBoxGroupProps = {
  label?: string
  subLabel?: string
  labelSecondary?: string
  labelAlignment?: CSS.Properties['alignItems']
  value: CheckBoxGroupValue
  onChange: (arg: CheckBoxGroupValue) => void
  error?: string
  name?: string
  readOnly?: boolean
  options: Array<{
    id: string
    text: string | React.ReactNode
    group?: number
    buttonType?: CheckboxButtonType
    badge?: string
    readOnly?: boolean
  }>
  buttonType?: CheckboxButtonType
  active?: boolean
  baseInputStyle?: ViewStyle
  labelStyle?: ViewStyle
}
