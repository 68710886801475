import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

import styled from 'styled-components/native'

import { Image } from '../image/Image'

type CareOptionCouplesAndFamilyTherapyIconProps = {
  size?: number
  backgroundFill?: string
}

const CareOptionCouplesAndFamilyTherapyPNG = styled(Image)<{ size: number }>(({ size }) => ({
  width: `${size}px`,
  height: `${size}px`,
}))

export const CareOptionCouplesAndFamilyTherapyIcon: FunctionComponent<CareOptionCouplesAndFamilyTherapyIconProps> = ({
  size = 64,
  backgroundFill,
}) => {
  return !backgroundFill ? (
    <CareOptionCouplesAndFamilyTherapyPNG
      size={size}
      source={require('./../../assets/careOptionCouplesAndFamilyTherapy.png')}
    />
  ) : (
    <Svg width={size} height={size} viewBox='0 0 56 56' fill='none'>
      <Path
        d='M28.1968 0.209473C24.3314 0.209473 20.5811 0.872338 17.0498 2.17926C8.64285 5.2955 2.73238 12.2041 0.833422 21.1335C-0.421486 26.9554 -0.261026 32.3402 1.32697 37.5878C3.3543 44.2895 8.33078 49.941 14.9805 53.0927C18.2815 54.6608 22.0739 55.556 26.2503 55.753C26.787 55.7773 27.3326 55.7906 27.8748 55.7906C31.2257 55.7906 34.5964 55.3314 37.8942 54.4262C38.1476 54.3631 38.3888 54.2978 38.6433 54.2225C41.6069 53.3549 44.2738 51.9827 46.5557 50.1491C49.0334 48.1693 51.0729 45.6595 52.6111 42.6938C52.9143 42.1095 53.2043 41.4942 53.5241 40.7583C55.2836 36.6726 56.1335 32.099 55.983 27.5264C55.9122 25.5046 55.6112 23.3556 55.091 21.1335C54.2345 17.5281 52.838 14.1374 51.0519 11.3222C50.5628 10.5663 50.1135 9.93114 49.6686 9.36012C46.4583 5.21915 41.4564 2.31316 35.2084 0.960868C32.9133 0.462889 30.5539 0.210579 28.1979 0.210579L28.1968 0.209473Z'
        fill={backgroundFill}
      />
      <Path
        d='M26.465 25.5407C28.1914 23.9295 29.8812 23.7668 31.3441 24.2183C31.5732 22.1578 30.7598 20.1117 28.9748 19.1334C24.3116 16.576 20.684 21.4872 20.684 21.4872C20.684 21.4872 14.8953 15.6874 11.0221 21.0401C6.71627 26.9904 20.5645 36.41 21.8991 36.1134C22.7733 35.9197 24.3857 34.5354 26.0434 32.7338C24.8261 30.0292 24.5373 27.3401 26.465 25.5418V25.5407Z'
        fill='#FFAF9B'
      />
      <Path
        d='M44.4499 27.1209C40.1905 23.4901 35.8614 27.8369 35.8614 27.8369C35.8614 27.8369 33.9746 25.0294 31.3442 24.2183C31.2601 24.9752 31.0387 25.7343 30.6669 26.4437C29.6389 28.4024 27.8163 30.8071 26.0435 32.7337C28.2169 37.563 33.366 42.4465 34.2424 42.4244C36.0938 42.3779 42.0253 37.9581 44.7055 34.6205C46.5702 32.2988 46.5691 28.928 44.4499 27.1209Z'
        fill='#F69170'
      />
      <Path
        d='M31.3445 24.2183C29.8826 23.7668 28.1917 23.9306 26.4654 25.5407C24.5376 27.3401 24.8254 30.0281 26.0437 32.7327C27.8165 30.806 29.6391 28.4014 30.6672 26.4426C31.039 25.7344 31.2604 24.9753 31.3445 24.2172V24.2183Z'
        fill='#E5744C'
      />
    </Svg>
  )
}
