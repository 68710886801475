import React from 'react'
import CSSModules from 'react-css-modules'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import { isImmutable, PROGRAM_TAXONOMY, ProviderProgramTaxonomy } from '@lyrahealth-inc/shared-app-logic'

import styles from './displayProviderInfo.module.scss'
import {
  DA_PROGRAM_TAXONOMY_TO_DISPLAY_VALUE,
  demographicAgeMap,
  paymentPreferences,
} from '../../common/constants/appConstants'

const PracticeInformationHelperTextContainer = styled.p({
  paddingBottom: '16px',
  fontStyle: 'italic',
})

// Given a provider's treatment modality such as 'individual,' 'lyraTherapy,' or 'singleSessionCoaching,'
// return the corresponding treatment option
const convertProviderTreatmentModalityToTreatmentDisplay = (program: string): string => {
  return DA_PROGRAM_TAXONOMY_TO_DISPLAY_VALUE[program]?.display
}

// parses a provider's program taxonomies and returns array of corresponding provider facing program display names
const retrieveTreatmentDisplayFromProviderProgramTaxonomy = (
  data: ProviderProgramTaxonomy[],
): (string | undefined)[] => {
  return data
    .map((item: ProviderProgramTaxonomy) =>
      item.treatment === PROGRAM_TAXONOMY.treatment.therapy
        ? convertProviderTreatmentModalityToTreatmentDisplay(item.clientele)
        : item.treatment === PROGRAM_TAXONOMY.treatment.medicationManagement
        ? convertProviderTreatmentModalityToTreatmentDisplay(item.treatment)
        : undefined,
    )
    .filter(Boolean) // removes undefined values
}
const DisplayProviderInfo = ({
  $$data,
  showSummaryAndSpecialties,
}: {
  $$data: any
  showSummaryAndSpecialties?: boolean
}) => {
  const data = isImmutable({ value: $$data }) ? $$data.toJS() : $$data
  return (
    <div>
      <div styleName='section'>
        <h3>Personal Info</h3>

        <div className='row'>
          <div className='col-xs-12 col-sm-6'>
            <div styleName='field-label'>First Name</div>
            <p>{data.first_name}</p>
          </div>
          <div className='col-xs-12 col-sm-6'>
            <div styleName='field-label'>Last Name</div>
            <p>{data.last_name}</p>
          </div>
          <div className='col-xs-12 col-sm-6'>
            <div styleName='field-label'>Date of Birth</div>
            <p>{data.date_of_birth}</p>
          </div>
        </div>
      </div>

      <div styleName='section'>
        <h3>Contact Info</h3>

        <div className='row'>
          <div className='col-xs-12 col-sm-6'>
            <div styleName='field-label'>Email</div>
            <p>{data.email}</p>
          </div>
          <div className='col-xs-12 col-sm-6'>
            <div styleName='field-label'>Practice Phone Number</div>
            <p>{data.phone}</p>
          </div>
          <div className='col-xs-12 col-sm-6'>
            <div styleName='field-label'>Street Address</div>
            <p>{data.street}</p>
          </div>
          <div className='col-xs-12 col-sm-6'>
            <div styleName='field-label'>City</div>
            <p>{data.city}</p>
          </div>
          <div className='col-xs-12 col-sm-6'>
            <div styleName='field-label'>State</div>
            <p>{data.state}</p>
          </div>
          <div className='col-xs-12 col-sm-6'>
            <div styleName='field-label'>Zip Code</div>
            <p>{data.zip_code}</p>
          </div>
        </div>
      </div>

      <div styleName='section'>
        <h3>Bank Info</h3>

        <div className='row'>
          <div className='col-xs-12 col-sm-6'>
            <div styleName='field-label'>Payment Preference</div>
            <p>{paymentPreferences[data.payment_preference]}</p>
          </div>
          <div className='col-xs-12 col-sm-6'>
            <div styleName='field-label'>Bank Name</div>
            <p>{data.bank}</p>
          </div>
          {data.account_type === 'company' && (
            <div className='col-xs-12 col-sm-6'>
              <div styleName='field-label'>Name on account (for tax purposes)</div>
              <p>{data.account_name}</p>
            </div>
          )}
          <div className='col-xs-12 col-sm-6'>
            <div styleName='field-label'>Routing Number</div>
            <p>{data.routing_number}</p>
          </div>
          <div className='col-xs-12 col-sm-6'>
            <div styleName='field-label'>Account Number</div>
            <p>{data.account_number}</p>
          </div>
        </div>
      </div>

      {showSummaryAndSpecialties && (
        <div styleName='section'>
          <h3>Practice Information</h3>
          <PracticeInformationHelperTextContainer>
            Information below is visible to Lyra members and may impact how you are matched with members searching for
            care. To update this information,{' '}
            <a
              href='https://provider-support.lyrahealth.com/hc/en-us/requests/new?ticket_form_id=538748'
              target='_blank'
              rel='noreferrer'
            >
              submit a request
            </a>{' '}
            to our support team. To optimize your Lyra Profile,{' '}
            <a
              href='https://provider-support.lyrahealth.com/hc/en-us/articles/10883636435219-How-to-Optimize-Your-Profile-to-Increase-Referrals'
              target='_blank'
              rel='noreferrer'
            >
              visit our Help Center
            </a>
            .
          </PracticeInformationHelperTextContainer>

          <div className='row'>
            <div className='col-lg-12 col-sm-6'>
              <div styleName='field-label'>Summary</div>
              <p>{data.summary}</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-lg-12 col-sm-6'>
              <div styleName='field-label'>Conditions Treated</div>
              <ul>
                {data.conditions?.map((condition: string) => (
                  <li key={condition}>{condition}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className='row'>
            {data.negative_conditions && (
              <div className='col-xs-12 col-sm-6'>
                <div styleName='field-label'>Excluded Conditions</div>
                <ul>
                  {data.negative_conditions?.map((condition: string) => (
                    <li key={condition}>{condition}</li>
                  ))}
                </ul>
              </div>
            )}
            <div className='col-xs-12 col-sm-6'>
              <div styleName='field-label'>Ages Treated</div>
              <ul>
                {data.demographics?.map((demographic: string) => (
                  <li key={demographic}>{demographicAgeMap[demographic]}</li>
                ))}
              </ul>
            </div>
            <div className='col-xs-12 col-sm-6'>
              <div styleName='field-label'>Contracted Services</div>
              <ul>
                {retrieveTreatmentDisplayFromProviderProgramTaxonomy(data.program_taxonomies)?.map(
                  (condition: string) => (
                    <li key={condition}>{condition}</li>
                  ),
                )}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

DisplayProviderInfo.propTypes = {
  $$data: PropTypes.object,
}

export default CSSModules(DisplayProviderInfo, styles, { allowMultiple: true })
