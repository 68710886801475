/**
 * Theme to control the styling of fonts, icons, and buttons
 */

export enum Theme {
  SYSTEM = 'system',
  LIGHT = 'light',
  DARK = 'dark',
}

export const DEFAULT_THEME = Theme.LIGHT
