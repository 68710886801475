import React, { useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import { AccessibilityRole, Platform, PressableStateCallbackType, ViewStyle } from 'react-native'

import { FlashList, ListRenderItem } from '@shopify/flash-list'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import styled, { useTheme } from 'styled-components/native'

import { sectionListHeaderMessages } from '@lyrahealth-inc/shared-app-logic'

import { Item, TypeAheadCustomOptionsConfig } from './TypeAheadCustomOptionsConfig'
import { AccessibilityRolesNative, IS_WEB } from '../../../constants'
import { ThemeType, tID } from '../../../utils'
import { BodyText, Size as BodyTextSize } from '../../bodyText/BodyText'
import { Divider } from '../../divider/Divider'
import { CheckMarkIcon } from '../../icons'

export enum MENU_SELECTED_ITEM_STYLE {
  CHECKMARK = 'checkmark',
  LEFT_BORDER = 'leftBorder',
}
export interface DropdownMenuItemProps<T> {
  onPress: (text: string, customSelectedItem?: T) => void
  option: string
  highlightValue?: string
  selected?: boolean
  customOptionsConfig?: TypeAheadCustomOptionsConfig<T>
  customOption?: T
  value: string[]
  item?: Item
  selectedItemStyle?: MENU_SELECTED_ITEM_STYLE
  style?: ViewStyle
}

export interface DropdownMenuProps<T> {
  onOptionPress: (text: string, customSelectedItem?: T) => void
  options: string[]
  highlightValue?: string
  optionSelected?: number
  customOptionsConfig?: TypeAheadCustomOptionsConfig<T>
  /** Subset of the `options` list in `customOptionsConfig` (filtered by search query) */
  customOptions?: T[]
  /** List of all selected options */
  value: string[]
  initialScrollIndex?: number
  selectedItemStyle?: MENU_SELECTED_ITEM_STYLE
  menuItemStyle?: ViewStyle
}

const MenuItem = styled.Pressable({
  flexDirection: 'row',
  borderRadius: '8px',
})

const DisplayTextContainer = styled.View({
  flexDirection: 'row',
  alignItems: 'center',
})

const CheckMarkIconContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  paddingLeft: Platform.OS === 'web' ? theme.spacing['16px'] : '36px',
}))

const SelectedItemLeftBorder = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  position: 'absolute',
  borderTopRightRadius: theme.spacing['16px'],
  borderBottomRightRadius: theme.spacing['16px'],
  backgroundColor: theme.colors.backgroundActive,
  width: '3px',
  top: 0,
  bottom: 0,
  left: 0,
}))

const SectionHeader = styled(BodyText)({
  flexDirection: 'row',
  padding: '12px 16px 8px 16px',
})

const DropdownMenuItemNotMemoized = <T,>({
  onPress,
  option,
  highlightValue = '',
  selected,
  customOptionsConfig,
  customOption,
  value,
  item,
  selectedItemStyle,
  style,
}: DropdownMenuItemProps<T>): JSX.Element => {
  const { colors } = useTheme()
  const { formatMessage } = useIntl()
  const menuItemPadding = { paddingVertical: 8, paddingHorizontal: 16 }
  const { menuItem_header, menuItem_divider, menuItem_section } = item || {}
  if (menuItem_header) {
    return (
      <SectionHeader
        text={formatMessage(sectionListHeaderMessages[option])}
        size={BodyTextSize.LARGE}
        testID={tID(`typeAhead-menu-section-header-${option}`)}
      />
    )
  } else if (menuItem_divider) {
    return <Divider height={1} style={{ marginTop: 12, marginBottom: 12 }} />
  }

  const stringMatches = match(option, highlightValue, { insideWords: true })
  const parsedText = parse(option, stringMatches)
  const isSelectedOption = (value && value[0]) === option
  const accessibilityLabel = formatMessage(
    {
      defaultMessage: 'List item {option}, {isSectionItem, select, true {section {sectionName}} other {}}',
      description: 'Accessibility label for an item in a dropdown list',
    },
    {
      option,
      sectionName: menuItem_section,
      isSectionItem: !!menuItem_section,
    },
  )
  const getHighlightedText = (extraProps?: any) => {
    return parsedText.map(({ text, highlight }) => (
      <BodyText
        key={`${text}-${highlight}`}
        text={text}
        size={BodyTextSize.DEFAULT}
        underline={highlight}
        bold={highlight}
        color={menuItem_section ? colors.textSecondary : colors.textPrimary}
        {...extraProps}
        accessibilityLabel={accessibilityLabel}
        accessibilityRole={IS_WEB ? ('option' as AccessibilityRole) : AccessibilityRolesNative.MENU_ITEM}
      />
    ))
  }
  const displayText = isSelectedOption ? (
    <DisplayTextContainer>
      {getHighlightedText({ color: colors.textActive })}
      {selectedItemStyle === MENU_SELECTED_ITEM_STYLE.CHECKMARK && (
        <CheckMarkIconContainer>
          <CheckMarkIcon />
        </CheckMarkIconContainer>
      )}
    </DisplayTextContainer>
  ) : (
    <DisplayTextContainer>{getHighlightedText()}</DisplayTextContainer>
  )

  const menuItemContent =
    customOption && customOptionsConfig?.menuItemRenderer
      ? customOptionsConfig?.menuItemRenderer(customOption, displayText)
      : displayText

  return (
    <MenuItem
      onPress={() => onPress(option, customOption)}
      testID={tID((customOption && customOptionsConfig?.getOptionTestId(customOption)) || 'typeAhead-menu-item')}
      style={({ hovered, pressed }: PressableStateCallbackType) => [
        (hovered || selected) && { backgroundColor: colors.tertiaryButtonBackgroundHover },
        pressed && { backgroundColor: colors.tertiaryButtonBackgroundPressed },
        menuItemPadding,
        style,
      ]}
      accessibilityRole={IS_WEB ? ('option' as AccessibilityRole) : AccessibilityRolesNative.MENU_ITEM}
      accessibilityState={{ selected: isSelectedOption }}
      aria-selected={isSelectedOption}
      accessibilityLabel={accessibilityLabel}
    >
      {isSelectedOption && selectedItemStyle === MENU_SELECTED_ITEM_STYLE.LEFT_BORDER && <SelectedItemLeftBorder />}
      {menuItemContent}
    </MenuItem>
  )
}
const DropdownMenuItem = React.memo(DropdownMenuItemNotMemoized)

/**
 * A dropdown component that is used to display a list of items
 * - Currently used in `TypeAhead`, `DropdownButton`, and `Menu`
 *
 * todo: move component so that it is separate from the typeahead
 */
export const DropdownMenu = <T,>({
  options,
  onOptionPress,
  highlightValue,
  optionSelected,
  customOptionsConfig,
  customOptions,
  value,
  initialScrollIndex,
  selectedItemStyle = MENU_SELECTED_ITEM_STYLE.CHECKMARK,
  menuItemStyle,
}: DropdownMenuProps<T>): JSX.Element => {
  const ITEM_HEIGHT = 40
  const ref = useRef<FlashList<string> | null>(null)
  // follow highlighted item
  useEffect(() => {
    ref.current?.scrollToIndex({ animated: true, index: optionSelected || 0 })
  }, [optionSelected])

  const renderItem: ListRenderItem<string> = ({ item, index }) => (
    <DropdownMenuItem
      option={item}
      onPress={onOptionPress}
      highlightValue={highlightValue}
      selected={optionSelected === index}
      value={value}
      selectedItemStyle={selectedItemStyle}
      style={menuItemStyle}
    />
  )
  const renderItemCustom: ListRenderItem<T> = ({ item, index }) => (
    <DropdownMenuItem
      option={customOptionsConfig?.getDisplayString(item) || ''}
      onPress={onOptionPress}
      highlightValue={highlightValue}
      selected={optionSelected === index}
      customOptionsConfig={customOptionsConfig}
      customOption={customOptions && customOptions[index]}
      value={value}
      item={item as Item}
      selectedItemStyle={selectedItemStyle}
      style={menuItemStyle}
    />
  )
  const keyExtractor = (option: string) => option
  const keyExtractorCustom = (option: T) => customOptionsConfig?.getDisplayString(option) || ''

  return (
    <>
      {/* Default string option items */}
      {!customOptionsConfig && (
        <FlashList
          data={options}
          renderItem={renderItem}
          keyExtractor={keyExtractor}
          estimatedItemSize={ITEM_HEIGHT}
          initialScrollIndex={initialScrollIndex}
          /* FlashList will not re-render the list if a prop in the renderItem function changes
             so to ensure the highlighting of text disappears after a user backspaces all characters, include that in extraData.
             Issue here: https://github.com/Shopify/flash-list/issues/699 */
          extraData={highlightValue}
          ref={ref}
        />
      )}

      {/* Custom option items, rendered using `TypeAheadCustomOptionsConfig menuItemRenderer()` */}
      {customOptionsConfig && customOptions && (
        <FlashList
          data={customOptions}
          renderItem={renderItemCustom}
          keyExtractor={keyExtractorCustom}
          estimatedItemSize={ITEM_HEIGHT}
          drawDistance={ITEM_HEIGHT} // render ahead offset in px
          initialScrollIndex={initialScrollIndex}
          extraData={highlightValue}
        />
      )}
    </>
  )
}
