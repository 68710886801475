import React, { FunctionComponent } from 'react'
import Svg, { ClipPath, Defs, G, Path } from 'react-native-svg'

type GlobeTextBubbleIllustrationWithBorderProps = {
  size?: number
}

export const GlobeTextBubbleIllustrationWithBorder: FunctionComponent<GlobeTextBubbleIllustrationWithBorderProps> = ({
  size = 48,
  ...props
}) => {
  return (
    <Svg width={size} height={size} fill='none' viewBox={`0 0 96 96`} {...props}>
      <G clipPath='url(#clip0_8380_41845)'>
        <Path
          d='M47.63 1C34.71 1 21.98 6.92 12.71 17.25 3.38 27.65-1.16 41.15.26 54.29c1.24 11.5 6.2 21.56 14.35 29.07 7.8 7.2 18.26 11.58 29.45 12.35 1.08.07 2.21.11 3.35.11 8.87 0 22.31-2.44 30.98-9.31 11.62-9.2 18.19-23.54 17.58-38.38-.65-15.83-6.29-28.08-16.76-36.4C69.4 3.95 57.8 1.79 51.76 1.2c-1.37-.13-2.75-.2-4.12-.2'
          fill='#fff'
        />
        <Path
          d='M91.97 48.31c.57 13.81-5.67 26.84-16.06 35.08-7.92 6.27-21.53 9.02-31.57 8.34C23.42 90.3 6.59 75.65 4.24 53.87 1.35 27.2 25.32 2.63 51.37 5.18c14.82 1.45 39.24 10.01 40.6 43.13z'
          fill='#FFD2C4'
        />
        <Path
          d='M75.52 51.66c-.77.05-1.54.17-2.31.26-.18.02-.39.04-.57.07-10.1 1.41-19.51 6.07-25.36 13.24-.04.05-.09.11-.13.17-.59.73-1.14 1.5-1.67 2.29-.68 1.01-1.32 2.04-1.93 3.1-3.37 5.98-5.26 12.89-5.37 20.11 2 .4 4.03.72 6.12.86 2.31.17 4.82.13 7.41-.09 8.62-.7 18.08-3.43 24.18-8.25 6.33-5.01 11.09-11.79 13.75-19.45 1.16-3.34 1.93-6.84 2.22-10.43-4.82-1.61-10.29-2.29-16.34-1.83l-.02-.04.02-.01z'
          fill='#85CCD5'
        />
        <Path
          d='M53.98 84.22c1.85-3.04 4.2-6.09 4.25-9.66.07-4.6-4.07-8.58-8.62-9.26-.77-.11-1.56-.13-2.33-.09-.04.06-.09.11-.13.17-1.34 1.67-2.53 3.48-3.59 5.37-3.37 5.98-5.26 12.89-5.37 20.11 2 .4 4.03.72 6.12.86 2.31.17 4.82.13 7.41-.09-.18-2.49.92-5.19 2.26-7.41zM70.59 65.28c.35.95.88 1.85 1.58 2.6 1.36 1.52 3.28 2.51 5.3 2.33 3.87-.33 6.31-4.44 9.94-5.79.73-.28 1.49-.4 2.26-.5 1.16-3.34 1.93-6.84 2.22-10.43-4.82-1.61-10.29-2.29-16.34-1.83-.77.05-1.54.17-2.31.26-.18.02-.39.04-.57.07-.68 1.3-1.27 2.73-1.78 4.38-.9 2.9-1.32 6.07-.28 8.93l-.02-.02zM63.22 77.77c-.09.06-.2.13-.29.2-1.8 1.41-5.76 7.21-2.15 8.54 2.84 1.05 5.67-2.53 6.33-4.8.68-2.38-.92-5.78-3.89-3.94z'
          fill='#4D9116'
        />
        <Path
          d='M46.68 22.65c-3.76-1.14-7.7-1.05-11.57-.94-4.62.15-9.5-.06-13.2 3.3-2.99 2.71-4.09 6.89-4.2 10.71-.11 3.98-.59 8.64 1.1 12.45 2.48 5.59 7.68 5.54 12.98 5.45 3.28-.05 6.88.09 10.23-.18 1.72 1.39 4.25 2.62 6.66 3.36.33.11.62-.22.46-.53-.64-1.14-1.14-2.59-1.34-3.98 3.91-1.43 6.62-4.42 6.62-10.65 0-7.7 1.25-16.28-7.74-19v.02-.01z'
          fill='#fff'
        />
        <Path
          d='M22.99 36.57c-1.47 2.53 2.64 4.64 3.89 2.05.5-1.01.04-2.38-.95-2.9-.99-.51-2.37-.13-2.93.86v-.02l-.01.01zM36.63 36.13c-2.04-2.09-5.17 1.27-3.03 3.19.84.75 2.27.68 3.04-.13s.77-2.26-.02-3.06h.01zM45.43 39.82c2.92-.2 2.38-4.77-.46-4.36-1.12.15-2 1.28-1.87 2.4.13 1.12 1.21 2.04 2.35 1.96h-.02z'
          fill='#85CCD5'
        />
      </G>
      <Defs>
        <ClipPath id='clip0_8380_41845'>
          <Path fill='#fff' transform='translate(0 1)' d='M0 0H96V94.82H0z' />
        </ClipPath>
      </Defs>
    </Svg>
  )
}
