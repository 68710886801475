import React, { useState } from 'react'
import PropTypes from 'prop-types'
import CSSModules from 'react-css-modules'
import MiniBackNav from '../../../../lyraTherapy/miniBackNav/MiniBackNav'
import { Paragraph, PrimaryButton, SecondaryButton, Title } from '@lyrahealth-inc/ui-core'
import styles from './recordingConsent.module.scss'

const RecordingConsent = ({ disableRecording, disableEpisodeRecording, closeModal, displayRecordingStatus }: any) => {
  const [isEpisodeModalOpen, setEpisodeModalOpen] = useState(false)

  return (
    <div data-test-id='ProviderVideoSession-recordingConsentModal'>
      {isEpisodeModalOpen ? (
        <span>
          {/* @ts-expect-error TS(2741): Property 'subText' is missing in type '{ backFunc:... Remove this comment to see the full error message */}
          <MiniBackNav
            backFunc={() => {
              setEpisodeModalOpen(false)
            }}
          />
          <Title level={3} styleName='title-style'>
            Turn off recordings for just this session or the entire episode?
          </Title>
          <div styleName='button-style'>
            <SecondaryButton
              onClick={() => {
                disableRecording()
                closeModal()
                displayRecordingStatus()
              }}
              type='submit'
              customClass={styles['left-button']}
              data-test-id='RecordingConsent-ThisSession'
            >
              This session
            </SecondaryButton>
            <PrimaryButton
              onClick={() => {
                disableEpisodeRecording()
                closeModal()
                displayRecordingStatus()
              }}
              type='submit'
              data-test-id='RecordingConsent-EntireEpisode'
            >
              Entire episode
            </PrimaryButton>
          </div>
        </span>
      ) : (
        <span>
          <Title level={3} styleName='title-style'>
            Remember to get consent for recording:
          </Title>
          <Paragraph>
            Let your client know:
            <br />- Sessions are securely recorded for quality assurance purposes
            <br />- Kept for 2 weeks then deleted
            <br />- Only certain internal team members may review recordings, all bound by same commitment to
            confidentiality
          </Paragraph>
          <div styleName='button-style'>
            <PrimaryButton
              onClick={() => {
                closeModal()
                displayRecordingStatus()
              }}
              type='submit'
              customClass={styles['left-button']}
              data-test-id='RecordingConsent-Record'
            >
              Record
            </PrimaryButton>
            <PrimaryButton
              onClick={() => {
                setEpisodeModalOpen(true)
              }}
              type='submit'
              data-test-id='RecordingConsent-DoNotRecord'
            >
              Don't Record
            </PrimaryButton>
          </div>
        </span>
      )}
    </div>
  )
}

RecordingConsent.propTypes = {
  disableRecording: PropTypes.func,
  disableEpisodeRecording: PropTypes.func,
  closeModal: PropTypes.func,
  displayRecordingStatus: PropTypes.func,
}

export default CSSModules(RecordingConsent, styles)
