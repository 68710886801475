import React from 'react'
import { FormattedMessage } from 'react-intl'
import { View } from 'react-native'

import { addDays, endOfWeek, format, getYear, startOfWeek } from 'date-fns'
import { noop } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import { appointmentUtilization, historicUtilization, targetUtilization } from '@lyrahealth-inc/shared-app-logic'

import { ArrowIcon, ArrowIconDirection, BodyText } from '../../atoms'
import { AlertQuietIcon } from '../../atoms/icons/AlertQuietIcon'
import { ClockIcon } from '../../atoms/icons/ClockIcon'
import { InfoIcon } from '../../atoms/icons/InfoIcon'
import { ScheduleIcon } from '../../atoms/icons/ScheduleIcon'
import { SecondaryButton } from '../../atoms/secondaryButton/SecondaryButton'
import { Subhead, Size as SubheadSize } from '../../atoms/subhead/Subhead'
import { Tooltip } from '../../atoms/tooltip/Tooltip'
import { BodyTextSize } from '../../styles/typeStyles'
import { ThemeType, tID } from '../../utils'
import { DataBoard } from '../dataBoard/DataBoard'
import { DataBoardItem } from '../dataBoard/DataBoardItem'

const Header = styled(View)(({ theme }) => ({
  paddingBottom: theme.spacing['24px'],
  justifyContent: 'space-between',
  flexDirection: 'row',
}))

const SnapshotContainer = styled.View({
  width: '100%',
  minHeight: '98px',
  borderRadius: '16px',
  backgroundColor: '#fff',
  padding: '24px',
  marginBottom: '16px',
  shadowColor: '#000',
  shadowOffset: {
    width: '0px',
    height: '2px',
  },
  shadowOpacity: '0.1',
  shadowRadius: '10px',
})

const WeekHeader = styled.View(({ theme }) => ({
  paddingBottom: theme.spacing['24px'],
  flexDirection: 'row',
}))

const IconContainer = styled.View(({ theme }) => ({
  marginLeft: theme.spacing['16px'],
  marginRight: theme.spacing['8px'],
  marginTop: '2px',
}))

const WeeklySectionHeader = styled(Subhead)(({ theme }) => ({
  justifyContent: 'space-between',
  flexDirection: 'row',
  marginTop: theme.spacing['8px'],
}))

const DataBoardContainer = styled.View({
  justifyContent: 'space-between',
  flexDirection: 'row',
})

const CurrentDataBoardContainer = styled.View(({ theme }) => ({
  width: '75%',
  paddingRight: theme.spacing['24px'],
}))

const TargetDataBoardContainer = styled.View({
  width: '25%',
})

const CurrentScheduledDataBoardContainer = styled.View(({ theme }) => ({
  width: '50%',
  paddingRight: theme.spacing['24px'],
}))

const AimToScheduleDataBoardContainer = styled.View({
  width: '50%',
})

const BelowTargetFooter = styled.View(({ theme }) => ({
  flexDirection: 'row',
  marginTop: theme.spacing['24px'],
}))

const BelowTargetIcon = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  height: '30px',
  width: '30px',
  paddingLeft: '6px',
  paddingTop: '6px',
  backgroundColor: theme.colors.backgroundWarning,
  borderRadius: '6px',
  marginRight: '20px',
}))

export const CaseloadManagementWeeklySnapshot: React.FC<CaseloadManagementWeeklySnapshotProps> = ({
  appointmentUtilization,
  historicUtilization,
  targetUtilization,
  employmentStatus,
  handleDisplayWeeklySnapshot = noop,
  requestedQuarterName,
  showPastQuartersInLCCDashboard = false,
}) => {
  const { colors } = useTheme()

  const providerPaidTimeOffHours =
    historicUtilization[appointmentUtilization?.currentWeekCount - 1]?.providerPaidTimeOffHours

  const currentWeekPTO = providerPaidTimeOffHours ? parseFloat(providerPaidTimeOffHours) : 0
  const creditedSessions =
    appointmentUtilization?.completedCurrentWeek + appointmentUtilization?.providerNoFaultCurrentWeek
  const scheduledSessions = appointmentUtilization?.scheduledCurrentWeek

  const targetSessions = Math.round((targetUtilization?.providerExpectedWeekWorkHours - currentWeekPTO) * 0.75)

  const trackingTowardsDeviation = targetSessions - (creditedSessions + scheduledSessions)

  const today = new Date()
  const formatDateFNSToString = (targetDate: Date) => {
    return getYear(targetDate) === getYear(today) ? format(targetDate, 'MM/dd') : format(targetDate, 'MM/dd/yyyy')
  }

  return (
    <View testID={tID('CaseloadManagementWeeklySnapshot-containerView')}>
      <Header>
        <WeeklySectionHeader
          size={SubheadSize.MEDIUM}
          text={
            <FormattedMessage
              defaultMessage='Weekly snapshot'
              description='This is a heading that tells the Provider that this is a week of information that may be relevant to see at a quick glance'
            />
          }
        />
        <SecondaryButton
          onPress={() => {
            handleDisplayWeeklySnapshot()
          }}
          text={
            <FormattedMessage
              defaultMessage='Detailed weekly view'
              description='This is text for a button and it describes that the button will take the Provider to a page that offers more in depth information about a weeks worth of data'
            />
          }
          testID={tID('CaseloadManagementWeeklySnapshot-detailedWeeklyViewButton')}
        />
      </Header>
      <SnapshotContainer testID={tID('CaseloadManagementWeeklySnapshot-snapshotContainer')}>
        <WeekHeader>
          <Subhead color={colors.textPrimary} size={SubheadSize.MEDIUM}>
            <FormattedMessage
              defaultMessage='This Week'
              description='This is a heading that says the following information is about this current week'
            />
          </Subhead>
          <IconContainer>
            <ScheduleIcon size={24} fillColor={colors.iconDefault} />
          </IconContainer>
          <BodyText size={BodyTextSize.DEFAULT} color={colors.textSecondary}>
            <FormattedMessage
              defaultMessage='Week {weekCount} of 13{beginningOfWeekToEndOfWeek}'
              description='This is informational text that tells the Provider which week we are at in the current quarter of the year and it may also contain the month and day of the start of the week to the month and day of the end of the week'
              values={{
                weekCount: requestedQuarterName?.length ? 13 : appointmentUtilization?.currentWeekCount,
                beginningOfWeekToEndOfWeek:
                  !requestedQuarterName?.length &&
                  ` | ${formatDateFNSToString(startOfWeek(today))}-${formatDateFNSToString(endOfWeek(today))}`,
              }}
            />
          </BodyText>
          {!requestedQuarterName && (
            <>
              <IconContainer>
                <ClockIcon size={24} fillColor={colors.iconDefault} />
              </IconContainer>

              <BodyText size={BodyTextSize.DEFAULT} color={colors.textSecondary}>
                <FormattedMessage
                  defaultMessage='{currentWeekPTO} hours of scheduled time off this week'
                  description='This is informational text that tells the Provider how many Paid Time Off or vacation days are planned for this week'
                  values={{
                    currentWeekPTO: currentWeekPTO,
                  }}
                />
              </BodyText>
            </>
          )}
        </WeekHeader>
        <DataBoardContainer>
          <CurrentDataBoardContainer testID={tID('CaseloadManagementWeeklySnapshot-currentDataBoardContainer')}>
            <DataBoard
              backgroundColor={showPastQuartersInLCCDashboard ? colors.backgroundSection : colors.backgroundSecondary}
            >
              <DataBoardItem
                title={
                  <FormattedMessage
                    defaultMessage='Credited'
                    description='This is a heading that tells the Provider the following are sessions they receive credit for and they count towards the total amount for the period'
                  />
                }
                testID={'Credited'}
                value={creditedSessions}
                label='sessions'
                tooltip={
                  <Tooltip
                    hoverEnabled
                    content='Attended, client late cancellations/no-shows, care transitions within 24h, tech issues, med emergencies'
                  >
                    <InfoIcon size={18} />
                  </Tooltip>
                }
                infoDataStyle={{ color: colors.textPrimary }}
                labelStyle={{ color: colors.textSecondary }}
              />
              <DataBoardItem
                title={
                  <FormattedMessage
                    defaultMessage='Scheduled'
                    description='This is a heading that says the following information is about the upcoming sessions that are supposed to take place in the near future'
                  />
                }
                value={scheduledSessions}
                label='sessions'
                tooltip={
                  <Tooltip
                    hoverEnabled
                    content='# of scheduled sessions on your calendar for the remainder of this week'
                  >
                    <InfoIcon size={18} />
                  </Tooltip>
                }
                infoDataStyle={{ color: colors.textPrimary }}
                labelStyle={{ color: colors.textSecondary }}
                testID={'Scheduled'}
              />
              <DataBoardItem
                title={
                  <FormattedMessage
                    defaultMessage='Tracking towards'
                    description='This is a heading that describes how the Provider is making progress in the current period and the pace the Provider is going at'
                  />
                }
                value={creditedSessions + scheduledSessions}
                label='sessions'
                tooltip={
                  <Tooltip
                    hoverEnabled
                    content='Credited sessions + Scheduled sessions = # of sessions tracking towards '
                  >
                    <InfoIcon size={18} />
                  </Tooltip>
                }
                infoDataStyle={{ color: colors.textPrimary }}
                labelStyle={{ color: colors.textSecondary }}
                testID={'Tracking towards'}
              />
            </DataBoard>
          </CurrentDataBoardContainer>
          <TargetDataBoardContainer>
            <DataBoard
              backgroundColor={showPastQuartersInLCCDashboard ? colors.backgroundSection : colors.backgroundSecondary}
            >
              <DataBoardItem
                title={
                  <FormattedMessage
                    defaultMessage='Target'
                    description='This is a heading that says the following information is about the goal or aim of the Provider for this period'
                  />
                }
                testID={'Target'}
                value={
                  targetSessions - creditedSessions > 1
                    ? targetSessions
                    : targetSessions + ' ' + String.fromCodePoint(127881)
                }
                label='sessions'
                tooltip={
                  <Tooltip
                    hoverEnabled
                    content='The number of credited sessions you’re responsible for achieving this week (includes time off)'
                  >
                    <InfoIcon size={18} />
                  </Tooltip>
                }
                infoDataStyle={{ color: colors.textPrimary }}
                labelStyle={{ color: colors.textSecondary }}
              />
            </DataBoard>
          </TargetDataBoardContainer>
        </DataBoardContainer>
        {trackingTowardsDeviation > 1 && (
          <BelowTargetFooter>
            {showPastQuartersInLCCDashboard ? (
              <BelowTargetIcon>
                <AlertQuietIcon size={18} fillColor={colors.iconWarning} />
              </BelowTargetIcon>
            ) : (
              <BelowTargetIcon>
                <ArrowIcon direction={ArrowIconDirection.DOWN} size={18} fillColor={colors.iconWarning} />
              </BelowTargetIcon>
            )}
            <BodyText
              size={BodyTextSize.DEFAULT}
              color={colors.textPrimary}
              testID={tID('CaseloadManagementWeeklySnapshot-trackingTowardsDeviation')}
            >
              <FormattedMessage
                defaultMessage='{trackingTowardsDeviation} credited sessions needed to meet target for this week'
                description='This is informational text that tells the Provider how many more appointments or sessions the Provider must schedule and finish for this period of time in order to meet the goal'
                values={{
                  trackingTowardsDeviation: trackingTowardsDeviation,
                }}
              />
            </BodyText>
          </BelowTargetFooter>
        )}
      </SnapshotContainer>
      <SnapshotContainer testID={tID('CaseloadManagementWeeklySnapshot-nextWeekSnapshotContainer')}>
        <WeekHeader>
          <Subhead color={colors.textPrimary} size={SubheadSize.MEDIUM}>
            <FormattedMessage
              defaultMessage='Next Week'
              description='This is a heading that tells the Provider the following information is about what is coming up after this current week is over'
            />
          </Subhead>
          <IconContainer>
            <ScheduleIcon fillColor={colors.iconDefault} />
          </IconContainer>
          <BodyText size={BodyTextSize.DEFAULT} color={colors.textSecondary}>
            <FormattedMessage
              defaultMessage='Week {weekCount} of 13{beginningOfWeekToEndOfWeek}'
              description='This is informational text that tells the Provider which week we are at in the current quarter of the year and it may also contain the month and day of the start of the week to the month and day of the end of the week'
              values={{
                weekCount: requestedQuarterName?.length ? 13 : appointmentUtilization?.currentWeekCount,
                beginningOfWeekToEndOfWeek:
                  !requestedQuarterName?.length &&
                  ` | ${formatDateFNSToString(startOfWeek(addDays(today, 7)))}-${formatDateFNSToString(
                    endOfWeek(addDays(today, 7)),
                  )}`,
              }}
            />
          </BodyText>
        </WeekHeader>
        <DataBoardContainer>
          <CurrentScheduledDataBoardContainer>
            <DataBoard
              backgroundColor={showPastQuartersInLCCDashboard ? colors.backgroundSection : colors.backgroundSecondary}
            >
              <DataBoardItem
                title={
                  <FormattedMessage
                    defaultMessage='Currently scheduled'
                    description='This is a heading that tells the Provider the following information is about sessions or meetings that are arranged and should occur in the near future'
                  />
                }
                value={appointmentUtilization?.scheduledNextWeek}
                label='sessions'
                infoDataStyle={{ color: colors.textPrimary }}
                labelStyle={{ color: colors.textSecondary }}
                testID={'Currently scheduled'}
              />
            </DataBoard>
          </CurrentScheduledDataBoardContainer>
          <AimToScheduleDataBoardContainer>
            <DataBoard backgroundColor={colors.backgroundPrimary}>
              <DataBoardItem
                title={
                  <FormattedMessage
                    defaultMessage='Aim to schedule'
                    description='This is a heading that tells the Provider how many more sessions to schedule or arrange for the future to meet a threshold or goal'
                  />
                }
                value={employmentStatus === 'FT' ? '32-34' : '18-22'}
                label='total sessions'
                tooltip={
                  <Tooltip
                    hoverEnabled
                    content='Coaches who regularly hit target aim to schedule a few more sessions each working week to account for non-credited schedule changes (e.g. non-late cancellations)'
                  >
                    <InfoIcon size={18} />
                  </Tooltip>
                }
                testID={'Aim to schedule'}
                infoDataStyle={{ color: colors.textPrimary }}
                labelStyle={{ color: colors.textSecondary }}
              />
            </DataBoard>
          </AimToScheduleDataBoardContainer>
        </DataBoardContainer>
      </SnapshotContainer>
    </View>
  )
}

export type CaseloadManagementWeeklySnapshotProps = {
  appointmentUtilization: appointmentUtilization
  historicUtilization: historicUtilization
  targetUtilization: targetUtilization
  employmentStatus: string
  handleDisplayWeeklySnapshot?: () => void
  requestedQuarterName: string
  showPastQuartersInLCCDashboard: boolean
}

export default CaseloadManagementWeeklySnapshot
