import React, { FunctionComponent } from 'react'
import Svg, { G, Mask, Path } from 'react-native-svg'

type Props = {
  size?: number | string
}

export const IntensiveOutpatientSpotIllustration: FunctionComponent<Props> = ({ size = 124 }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 512 512' fill='none'>
      <Path
        d='M257.8 2C222.469 2 188.18 8.06049 155.884 20.0095C79.0203 48.4907 24.9919 111.655 7.61986 193.295C-3.85359 246.524 -2.38652 295.757 12.1323 343.735C30.6679 405.007 76.1671 456.678 136.964 485.493C167.145 499.83 201.819 508.015 240.003 509.816C244.91 510.039 249.898 510.16 254.855 510.16C285.492 510.16 316.31 505.961 346.461 497.685C348.778 497.108 350.983 496.512 353.311 495.824C380.406 487.891 404.789 475.345 425.652 458.58C448.305 440.48 466.952 417.533 481.016 390.418C483.788 385.075 486.439 379.45 489.363 372.722C505.45 335.367 513.22 293.551 511.844 251.745C511.197 233.26 508.445 213.611 503.69 193.295C495.858 160.332 483.09 129.331 466.76 103.592C462.288 96.6812 458.18 90.8737 454.113 85.6529C424.762 47.7926 379.03 21.2236 321.905 8.85978C300.921 4.30683 279.35 2 257.8 2Z'
        fill='white'
      />
      <Mask
        id='mask0_18566_6865'
        mask-type='luminance'
        maskUnits='userSpaceOnUse'
        x='21'
        y='23'
        width='469'
        height='466'
      >
        <Path
          d='M489.892 252.443C489.265 234.727 486.553 216.373 482.284 198.131C475.303 168.739 463.829 139.762 448.096 114.953C444.444 109.328 440.7 103.905 436.633 98.6944C407.13 60.6519 362.42 39.4857 316.657 29.5805C265.685 18.532 211.484 21.7595 162.484 39.9005C91.5998 66.166 43.3385 123.705 27.5954 197.716C21.545 225.752 19.7744 257.441 22.4859 287.885H22.2735C23.8417 305.186 26.9681 322.072 31.6526 337.603C49.0651 395.142 91.3772 440.803 145.274 466.34C174.464 480.202 207.083 487.082 240.239 488.65C274.113 490.218 308.513 485.938 340.313 477.186C342.397 476.671 344.38 476.144 346.464 475.517C371.374 468.222 393.056 456.961 411.612 442.058C431.837 425.9 448.511 405.574 461.441 380.664C464.254 375.241 466.753 369.828 469.151 364.293C484.368 328.952 491.147 290.596 489.791 252.554L489.892 252.453V252.443Z'
          fill='white'
        />
      </Mask>
      <G mask='url(#mask0_18566_6865)'>
        <Path
          d='M255.594 510.17C397.128 510.17 511.865 396.413 511.865 256.085C511.865 115.758 397.128 2 255.594 2C114.06 2 -0.676758 115.758 -0.676758 256.085C-0.676758 396.413 114.06 510.17 255.594 510.17Z'
          fill='#FFE9E3'
        />
        <Path
          d='M252.134 538.075C459.196 538.075 627.054 484.632 627.054 418.706C627.054 352.781 459.196 299.338 252.134 299.338C45.0706 299.338 -122.787 352.781 -122.787 418.706C-122.787 484.632 45.0706 538.075 252.134 538.075Z'
          fill='#EDE2DB'
        />
      </G>
      <Path
        d='M95.4257 323.641C95.4257 323.641 91.6721 365.731 108.042 365.114C124.423 364.486 120.679 323.641 120.679 323.641H95.4257Z'
        fill='#D1B2A2'
      />
      <Path
        d='M387.269 323.641C387.269 323.641 383.515 365.731 399.885 365.114C416.266 364.486 412.522 323.641 412.522 323.641H387.269Z'
        fill='#D1B2A2'
      />
      <Path
        d='M391.589 220.784C391.447 222.454 391.276 224.184 391.073 225.965C390.81 228.18 390.486 230.487 390.092 232.895C386.814 253.07 385.114 290.09 364.686 290.09L147.136 281.146C126.385 281.146 117.147 252.635 114.304 232.086C113.909 229.212 113.545 226.359 113.252 223.536C113.07 221.837 112.908 220.157 112.776 218.488C111.259 199.78 112.847 182.6 123.643 170.763C141.652 151.043 295.299 125.628 367.135 161.798C376.463 166.493 383.768 174.506 387.35 184.32C390.567 193.153 392.904 204.222 391.589 220.784Z'
        fill='#3BA5B5'
      />
      <Path
        d='M412.698 328.497H94.902C94.902 328.497 68.8995 223.395 113.235 223.395C142.87 223.395 148.92 261.609 149.892 269.977C149.892 269.997 149.892 270.017 149.892 270.037V270.068C150.003 271.039 150.043 271.575 150.043 271.575C150.043 271.575 151.804 271.353 154.981 270.998C168.842 269.43 209.616 265.231 247.315 265.231C285.013 265.231 340.357 271.069 357.263 272.992C360.137 273.326 361.938 273.538 362.383 273.599C362.454 273.609 362.494 273.609 362.494 273.609C362.494 273.609 361.483 223.395 400.739 225.884C446.795 228.808 412.688 328.497 412.688 328.497H412.698Z'
        fill='#3BA5B5'
      />
      <Path
        d='M358.887 304.377C359.029 305.813 359.949 307.048 361.416 307.048C362.671 307.048 364.088 305.823 363.946 304.377C362.803 292.691 362.459 280.934 362.934 269.187C363.076 265.747 358.017 265.757 357.875 269.187C357.4 280.924 357.734 292.691 358.887 304.377Z'
        fill='#058496'
      />
      <Path
        d='M360.414 301.999H151.707C148.358 301.999 148.358 307.058 151.707 307.058H360.414C363.763 307.058 363.763 301.999 360.414 301.999Z'
        fill='#058496'
      />
      <Path
        d='M153.235 270.938C153.093 269.582 152.172 268.409 150.705 268.409C149.451 268.409 148.034 269.572 148.176 270.938C149.319 281.997 149.663 293.116 149.188 304.225C149.046 307.483 154.105 307.473 154.246 304.225C154.722 293.116 154.388 281.997 153.235 270.938Z'
        fill='#058496'
      />
      <Path
        d='M152.176 254.668C152.267 256.034 150.941 257.198 149.646 257.198C148.209 257.198 147.208 256.024 147.117 254.668C146.854 250.975 145.963 247.282 144.496 243.873C139.144 231.428 126.72 223.455 113.253 223.536C113.071 221.836 112.909 220.157 112.777 218.487C117.057 218.386 121.377 219.013 125.455 220.329C140.52 225.205 151.073 238.905 152.176 254.668Z'
        fill='#058496'
      />
      <Path
        d='M391.581 220.785C391.439 222.454 391.267 224.184 391.065 225.965C387.655 226.734 384.458 228.353 381.656 230.437C374.937 235.415 370.779 243.003 368.179 250.804C366.681 255.296 365.609 259.93 364.638 264.564C363.646 269.35 362.371 274.226 362.705 279.154C362.796 280.519 361.471 281.683 360.176 281.683C358.739 281.683 357.737 280.509 357.646 279.154C357.424 275.956 357.818 272.719 358.405 269.511C345.06 265.565 331.502 262.348 317.803 259.869C293.015 255.387 267.791 253.374 242.608 253.778C217.415 254.183 192.293 257.036 167.667 262.348C164.48 263.026 163.134 258.149 166.321 257.461C180.385 254.436 194.61 252.19 208.927 250.713C234.281 248.102 259.899 247.991 285.275 250.359C310.387 252.706 335.256 257.421 359.447 264.544C359.7 263.431 359.953 262.318 360.186 261.225C362.26 251.664 365.083 241.85 371.113 233.968C376.232 227.28 383.244 222.191 391.581 220.785Z'
        fill='#058496'
      />
      <Path
        d='M146.704 221.635C141.28 224.559 133.895 227.361 135.928 235.061C137.952 242.71 141.149 250.217 143.749 257.694C146.38 265.262 148.859 272.901 151.651 280.408C152.764 283.393 156.74 287.997 160.069 288.573C163.398 289.14 166.726 287.299 169.893 286.267C185.313 281.208 198.931 274.813 212.468 265.94C213.764 265.09 214.947 263.198 214.664 261.549C213.015 251.836 207.389 242.841 203.12 233.968C193.872 214.765 189.542 201.319 166.565 212.144L146.714 221.645L146.704 221.635Z'
        fill='#FDA694'
      />
    </Svg>
  )
}
