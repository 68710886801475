import React, { FunctionComponent } from 'react'
import { Mask, Path, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { tID } from '../../utils'

/**
 * Lyra main logo icon
 */
export const LyraLogo: FunctionComponent<LyraLogoProps> = ({ width = 124, height = 72, fillColor }) => {
  const { colors } = useTheme()

  return (
    <Svg width={width} height={height} viewBox='0 0 124 72' fill='none' testID={tID('LyraLogo')}>
      <Mask id='mask0' mask-type='alpha' x='0' y='0' width='124' height='72'>
        <Path d='M0 0H124V71.2797H0V0Z' fill={`${fillColor || colors.iconActive}`} />
      </Mask>
      <Path
        fillRule='evenodd'
        clip-rule='evenodd'
        d='M50.5588 14.4626L38.1507 43.4282L27.0318 14.4626H19.2164L34.1222 52.7342L25.7427 71.2658H33.9207L58.6966 14.4626H50.5588ZM83.5127 14.5432C76.6641 14.5432 72.434 18.2495 70.2586 21.5932V14.5432H63.0071V52.976H70.2586V36.3782C70.2586 29.1267 74.8109 21.5932 83.1904 21.5932H84.6407V14.5432H83.5127ZM116.91 33.7193C116.91 27.0318 111.471 21.5932 104.784 21.5932C98.0962 21.5932 92.6576 27.0318 92.6576 33.7193C92.6576 40.4068 98.0962 45.8454 104.784 45.8454C111.511 45.8454 116.91 40.4068 116.91 33.7193ZM124 33.7193V52.9357H116.789V48.7459C113.485 51.3645 109.336 52.976 104.784 52.976C94.1884 52.976 85.5673 44.3951 85.5673 33.7596C85.5673 23.1241 94.1482 14.5432 104.784 14.5432C115.379 14.5432 124 23.1241 124 33.7193ZM14.9058 45.6439C15.9935 45.6842 19.0955 45.6439 19.0955 45.6439V52.8954C19.0955 52.8954 16.2755 52.8954 14.9058 52.8954C10.7966 52.8954 0 52.13 0 40.729C0 27.7167 0 0 0 0H7.25146C7.25146 0 7.25146 32.6316 7.25146 38.6745C7.25146 44.3145 10.9981 45.4828 14.9058 45.6439Z'
        fill={`${fillColor || colors.iconActive}`}
      />
    </Svg>
  )
}

type LyraLogoProps = {
  fillColor?: string
  width?: number | string
  height?: number | string
}
