import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { AnyAction, bindActionCreators, Dispatch } from 'redux'

import { LoadingIndicator } from '@lyrahealth-inc/ui-core-crossplatform'

import { LoadingContainer } from './constants'
import { getSchedulingData, updateSchedulingOAuth } from './data/schedulingActions'
import { getSchedulingCredentials, getSchedulingOAuthStatus } from './data/schedulingSelectors'
import { SCHEDULING } from '../common/constants/routingConstants'
import { getAuthUserId } from '../data/auth/authSelectors'

const SchedulingOAuth: React.FC<SchedulingOAuthProps> = ({
  userId,
  schedulingOAuthStatus,
  schedulingCredentials,
  actions: { updateSchedulingOAuth, getSchedulingData },
}) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const googleOAuthCode = searchParams.get('code')
  const googleOAuthError = searchParams.get('error')

  useEffect(() => {
    // We need to show an error message if Google gives us an error on callback or if the credentials code isn't present
    if (!googleOAuthCode || googleOAuthError) {
      navigate(SCHEDULING.route + '?oac=400')
    } else if (userId) {
      updateSchedulingOAuth({ code: googleOAuthCode }, userId)
    }
  }, [googleOAuthError, googleOAuthCode, navigate, updateSchedulingOAuth, userId])

  useEffect(() => {
    if (schedulingOAuthStatus) {
      if (schedulingOAuthStatus === 'success') {
        // We want to refresh the providers scheduling data before redirecting to /scheduling
        getSchedulingData(userId)
      } else {
        // Here, if PP-API has an issue with the auth process, we should show an error on redirect to /scheduling
        navigate(SCHEDULING.route + '?oac=500')
      }
    }
  }, [getSchedulingData, navigate, schedulingOAuthStatus, userId])

  useEffect(() => {
    // This waits for the provider's scheduling data to refresh and populate in the redux store before redirecting
    if (schedulingCredentials) {
      navigate(SCHEDULING.route)
    }
  }, [navigate, schedulingCredentials])

  return (
    <LoadingContainer>
      <LoadingIndicator size={45} />
    </LoadingContainer>
  )
}

type SchedulingOAuthProps = {
  userId: string
  schedulingOAuthStatus: string
  schedulingCredentials: {
    access_token: string
    refresh_token: string
    valid: boolean
  }
  actions: {
    updateSchedulingOAuth: (data: { code: string }, providerId: string) => void
    getSchedulingData: (providerId: string) => void
  }
}

const mapStateToProps = ($$state: any) => {
  return {
    userId: getAuthUserId($$state),
    schedulingOAuthStatus: getSchedulingOAuthStatus($$state),
    schedulingCredentials: getSchedulingCredentials($$state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    actions: bindActionCreators(
      {
        updateSchedulingOAuth,
        getSchedulingData,
      },
      dispatch,
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SchedulingOAuth)
