import React, { ReactElement, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import styled, { useTheme } from 'styled-components/native'

import { ChevronIcon, ChevronIconDirection } from '../../atoms/icons/ChevronIcon'
import { SparklesIcon } from '../../atoms/icons/SparklesIcon'
import { PressableOpacity } from '../../atoms/pressableOpacity/PressableOpacity'
import { ThemeType, tID } from '../../utils'

const Container = styled.View({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'flex-start',
  height: '100%',
})

const Tab = styled(PressableOpacity)<{ theme: ThemeType; isPanelOpen: boolean; tabColor: string }>(
  ({ theme: { spacing }, isPanelOpen, tabColor }) => ({
    height: '75px',
    width: isPanelOpen ? '75px' : '60px',
    borderTopLeftRadius: spacing['16px'],
    borderBottomLeftRadius: spacing['16px'],
    backgroundColor: tabColor,
    right: isPanelOpen ? '-15px' : 0,
    transition: '1s ease-in-out',
    padding: `${spacing['20px']} ${spacing['16px']}`,
  }),
)

const PanelContainer = styled.View<{ theme: ThemeType; isPanelOpen: boolean }>(
  ({ theme: { colors, spacing }, isPanelOpen }) => ({
    backgroundColor: colors.backgroundPrimary,
    width: isPanelOpen ? '500px' : 0,
    transition: '1s ease-in-out',
    height: '100%',
    zIndex: '1',
    borderTopLeftRadius: spacing['16px'],
    borderBottomLeftRadius: spacing['16px'],
    boxShadow: `0 2px 10px ${colors.shadowLow}`,
    padding: `${spacing['16px']} 0`,
  }),
)

const ChildrenContainer = styled.View<{ renderChild: boolean }>(({ renderChild }) => ({
  opacity: renderChild ? 1 : 0,
  transition: '300ms ease-in-out',
  height: '100%',
}))

export type ExpandingSidePanelProps = {
  children: ReactElement
  iconColor?: string
  isPanelOpen: boolean
  setIsPanelOpen: () => void
  tabColor?: string
  icon?: ReactElement
}

export const ExpandingSidePanel = ({
  children,
  icon,
  iconColor,
  isPanelOpen,
  setIsPanelOpen,
  tabColor,
}: ExpandingSidePanelProps) => {
  const { colors } = useTheme()
  const [renderChild, setRenderChild] = useState(false)
  const [hoverOnTab, setHoverOnTab] = useState(false)
  const { formatMessage } = useIntl()
  useEffect(() => {
    if (isPanelOpen) {
      const timer = setTimeout(() => {
        setRenderChild(true)
      }, 1000)

      return () => clearTimeout(timer)
    } else {
      setRenderChild(false)
      return
    }
  }, [isPanelOpen])

  const getTabIcon = () => {
    if (hoverOnTab && isPanelOpen) {
      return (
        <ChevronIcon
          color={iconColor ?? colors.components.aiTab.text}
          direction={ChevronIconDirection.RIGHT}
          size={32}
        />
      )
    } else if (hoverOnTab && !isPanelOpen) {
      return (
        <ChevronIcon
          color={iconColor ?? colors.components.aiTab.text}
          direction={ChevronIconDirection.LEFT}
          size={32}
        />
      )
    } else {
      return icon ?? <SparklesIcon color={iconColor ?? colors.components.aiTab.text} size={'32'} />
    }
  }

  return (
    <Container testID={tID('ExpandingSidePanel-container')}>
      <Tab
        onPress={setIsPanelOpen}
        isPanelOpen={isPanelOpen}
        accessibilityLabel={formatMessage({
          defaultMessage: 'Side Panel Button',
          description: 'This button allows the user to expand the side panel.',
        })}
        tabColor={tabColor ?? colors.components.aiTab.background}
        testID={tID('ExpandingSidePanel-tab')}
        onHoverIn={() => setHoverOnTab(true)}
        onHoverOut={() => setHoverOnTab(false)}
      >
        {getTabIcon()}
      </Tab>
      <PanelContainer isPanelOpen={isPanelOpen} testID={tID('ExpandingSidePanel-panelContainer')}>
        <ChildrenContainer renderChild={renderChild}>{children}</ChildrenContainer>
      </PanelContainer>
    </Container>
  )
}
