import { Flex1View } from '../../templates/content/CommonViews'
import React, { Component, ReactElement, SyntheticEvent } from 'react'

/**
 * A work around so that RJSF doesn't render an unsupported html form element
 */
class Event {
  preventDefault() {}
}

global.Event = global.Event ?? Event
global.CustomEvent = global.CustomEvent ?? Event

export class MockHTMLForm extends Component<MockHTMLFormProps> {
  render(): ReactElement {
    return <Flex1View>{this.props.children}</Flex1View>
  }

  dispatchEvent(e: SyntheticEvent): void {
    e.persist = () => null
    this.props.onSubmit(e)
  }
}

type MockHTMLFormProps = {
  onSubmit: (e: SyntheticEvent) => void
  children: React.ReactNode
}
