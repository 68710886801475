import React, { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect, ConnectedProps, useSelector } from 'react-redux'
import styles from './myPracticeInfo.module.scss'
import { CardMedium, ContentLayout, LoadingIndicator } from '@lyrahealth-inc/ui-core'
import * as myPracticeInfoActions from './data/myPracticeInfoActions'
import { hasErrorForAction } from '../../common/utils/utils'
import { SET_MY_INFO_DATA } from '../../common/constants/reduxConstants'
import { Alert } from '../../data/alertTypes'
import { getMyInfoData } from '../data/myInfoSelectors'
import { getAuthPracticeId } from '../../data/auth/authSelectors'
import { getAlertsState } from '../../data/alertsSelectors'

// sub views
import DisplayPracticeInfo from './DisplayPracticeInfo'
// import EditPracticeInfo from './EditPracticeInfo'

type MyPracticeInfoProps = ConnectedProps<typeof connector>
const MyPracticeInfo = ({ actions: { returnPracticeInfo } }: MyPracticeInfoProps) => {
  const id: string = useSelector(getAuthPracticeId)
  const myPracticeInfo = useSelector(getMyInfoData)
  const alerts: Alert[] = useSelector(getAlertsState)
  useEffect(() => {
    returnPracticeInfo(id)
  }, [id, returnPracticeInfo])

  if (!myPracticeInfo) {
    if (hasErrorForAction(alerts, SET_MY_INFO_DATA)) {
      return null // custom workflow or display could render here
    } else {
      return (
        <div className={styles['loading-container']}>
          <LoadingIndicator size={45} />
        </div>
      )
    }
  }
  return (
    <ContentLayout>
      <CardMedium>
        <div>
          <h1 className={styles.title}>My Practice Info</h1>
          <DisplayPracticeInfo $$data={myPracticeInfo} />
        </div>
      </CardMedium>
    </ContentLayout>
  )
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    actions: bindActionCreators({ ...myPracticeInfoActions }, dispatch),
  }
}
const connector = connect(null, mapDispatchToProps)

export default connector(MyPracticeInfo)
