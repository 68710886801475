import { getClientsData, getClientData, getClientSessionData } from '../../common/http/data/clients'
import {
  GET_CLIENTS,
  GET_CLIENT,
  GET_CLIENT_SESSION,
  CHECK_CLIENT_ELIGIBILITY,
  actionStyles,
  actionAlertHandler,
} from '../../common/constants/reduxConstants'
import { format, parse } from 'date-fns'

export const getClients = () => {
  const request = getClientsData()

  return (dispatch: any) => {
    return new Promise(function (resolve, reject) {
      request.then(
        (clientsReturn) => {
          dispatch({
            type: GET_CLIENTS,
            payload: clientsReturn,
          })

          resolve(clientsReturn)
        },
        (error) => {
          actionAlertHandler({
            actionStyle: actionStyles.ERROR,
            message: error,
            dispatch,
            expires: true,
            action: GET_CLIENTS,
          })
          reject(error)
        },
      )
    })
  }
}

export const getClient = (values: any, cancelToken: any) => {
  const data = {
    full_name: encodeURIComponent(values.first_name + ' ' + values.last_name),
    dob: format(parse(values.dob, 'MM/dd/yyyy', new Date()), 'yyyy-MM-dd'),
  }

  const formData = new FormData()
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key])
  })
  const request = getClientData(formData, cancelToken)

  return {
    type: GET_CLIENT,
    payload: request,
  }
}

export const getClientSession = (lyraIds: string[], searchYear: number) => {
  const data = {
    lyra_id_list: lyraIds,
    searchYear: searchYear,
  }
  const response = getClientSessionData(data)

  return (dispatch: any) => {
    return new Promise(function (resolve, reject) {
      response.then(
        (clientsReturn) => {
          dispatch({
            type: GET_CLIENT_SESSION,
            payload: clientsReturn,
          })

          resolve(clientsReturn)
        },
        (error) => {
          actionAlertHandler({
            actionStyle: actionStyles.ERROR,
            message: error,
            dispatch,
            action: GET_CLIENT_SESSION,
            expires: true,
          })
          reject(error)
        },
      )
    })
  }
}

export const resendOutcomeEmail = (clientID: any) => ({
  action: 'RESENT_OUTCOME_EMAIL',

  request: {
    method: 'get',
    url: `v1/outcomes/resend/${clientID}`,
  },
})

export const checkClientEligibility = (data: any) => ({
  action: CHECK_CLIENT_ELIGIBILITY,

  request: {
    method: 'post',
    url: '/v1/eligibility-checker',
    data,
  },
})
