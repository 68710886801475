import { createReducer } from '@reduxjs/toolkit'

import { TOGGLE_CSV_DOWNLOAD_MODAL } from '../../../constants/reduxConstants'

type DownloadState = {
  showDownloadCSVModal: boolean
}

const initialState = {
  showDownloadCSVModal: false,
}

export default createReducer<DownloadState>(initialState, (builder) => {
  builder.addCase(TOGGLE_CSV_DOWNLOAD_MODAL, (state, action: any) => {
    state.showDownloadCSVModal = action.payload.showDownloadCSVModal
    return state
  })
})
