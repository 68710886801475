import { Client } from '@twilio/conversations'

/**
 * This class is a mock of `@twilio/conversations` Client class. It allows for testing the event based messaging features.
 * It is meant to be used through the `receiveMessage` Cypress command defined in `cypress/support/commands.js`
 */
class ConversationsClientMock {
  events: any

  constructor() {
    this.events = {
      messageAdded: [],
      typingStarted: [],
      typingEnded: [],
    }
  }

  on(eventName: any, callback: any) {
    this.events[eventName].push(callback)
  }

  off(eventName: any, callback: any) {
    const index = this.events[eventName].indexOf(callback)
    if (index !== -1) {
      this.events[eventName].splice(index, 1)
    }
  }

  trigger(eventName: any, params: any) {
    this.events[eventName].forEach((callback: any) => callback(params))
  }

  getConversationBySid() {
    return { typing: () => ({}) }
  }
}

const conversationsClientMock = new ConversationsClientMock()
;(window as any).conversationsClientMock = conversationsClientMock

class TwilioConversationsMock {
  constructor() {
    return conversationsClientMock
  }
}

export const TwilioConversations = window.Cypress ? TwilioConversationsMock : Client
