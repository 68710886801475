import React from 'react'
import CSSModules from 'react-css-modules'
import { connect } from 'react-redux'

import { bindActionCreators } from 'redux'
import { Field, reduxForm } from 'redux-form/immutable'

import {
  BootstrapContainer,
  CardFullWidth,
  Checkbox,
  DataTable,
  LoadingIndicator,
  NavButton,
  PrimaryButton,
} from '@lyrahealth-inc/ui-core'
import { tID } from '@lyrahealth-inc/ui-core-crossplatform'

import * as reconcilerDetailsActions from './data/reconcilerDetailsActions'
import { getReconcilerDetailsData, getReconcilerDetailsPossibleMatches } from './data/reconcilerDetailsSelectors'
import styles from './reconcilerDetails.module.scss'
import { RECONCILER } from '../../common/constants/routingConstants'
import { getErrorText } from '../../common/utils/utils'
import * as alertActions from '../../data/alertActions'
import withRouter from '../../routing/withRouter'
import * as reconcilerDataActions from '../data/reconcilerDataActions'

const lyraId = (value: any) =>
  value && !/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(value)
    ? 'Invalid Lyra Id'
    : undefined

const validate = (values: any) => {
  const errors = {}
  const manualId = values.get('manual_canonical_id')
  const checkboxChecked = values.get('No Match')
  if (manualId && checkboxChecked) {
    const isNotValid = lyraId(manualId)
    if (isNotValid) (errors as any).manual_canonical_id = 'Invalid Lyra Id'
  }
  return errors
}

type ReconcilerDetailsProps = {
  router?: any
  actions?: any
  handleSubmit?: (...args: any[]) => any
  submitting?: boolean
  pristine?: boolean
  invalid?: boolean
  reconcilerDetails?: any // TODO: PropTypes.instanceOf(Map)
  possibleMatches?: any // TODO: PropTypes.instanceOf(List)
}

type ReconcilerDetailsState = any

class ReconcilerDetails extends React.Component<ReconcilerDetailsProps, ReconcilerDetailsState> {
  tableColumns = [
    {
      Header: 'Name',
      accessor: 'identity.full_name',
      Cell: (row: any) => {
        return (
          <label className={styles['radio-button']}>
            <Field
              name='canonical_id'
              component='input'
              type='radio'
              value={row.original.identity.lyra_id}
              checked={this.state.selectedRadioButton === row.original.identity.lyra_id}
              onClick={() => {
                if (!this.state.checkboxChecked) {
                  if (this.state.selectedRadioButton) {
                    if (row.original.identity.lyra_id !== this.state.selectedRadioButton) {
                      this.setState({ selectedRadioButton: row.original.identity.lyra_id })
                    } else {
                      this.setState({ selectedRadioButton: undefined })
                    }
                  } else {
                    this.setState({ selectedRadioButton: row.original.identity.lyra_id })
                  }
                }
              }}
            />
            {row.value}
          </label>
        )
      },
    },
    {
      Header: 'DOB',
      accessor: 'identity.dob',
      sortable: false,
      maxWidth: 120,
    },
    {
      Header: 'Gender',
      accessor: 'identity.gender',
      sortable: false,
      maxWidth: 95,
    },
    {
      Header: 'Company',
      accessor: 'identity.displayed_employer',
      sortable: false,
      maxWidth: 150,
    },
    {
      Header: 'Email',
      accessor: 'email',
      sortable: false,
    },
  ]

  state = {
    checkboxChecked: false,
    showAllCompanies: false,
    manual_lyra_id: undefined,
    selectedRadioButton: undefined,
  }

  componentDidMount() {
    if (!this.props.reconcilerDetails) {
      // we don't have the context for what client we should fetch/display,
      // so punt them back to the reconciler dashboard
      this.props.router.navigate(RECONCILER.route, { replace: true })
    } else {
      this.props.actions.getPossibleMatches(this.props.reconcilerDetails)
    }
  }

  componentWillUnmount() {
    this.props.actions.clearReconcilerDetailsStore()
  }

  _backToDashboard = () => {
    this.props.router.navigate(-1)
  }

  _renderClientInfo = () => {
    const client = this.props.reconcilerDetails
    return (
      <div>
        <div styleName='title'>
          <h1 styleName='client-full-name'>{client.full_name}</h1>
        </div>
        <div styleName='info'>
          <div>
            <h4>Gender</h4>
            <p styleName='capitalize'>{client.gender ? client.gender : <span>&ndash;</span>}</p>
          </div>
          <div>
            <h4>Date of Birth</h4>
            <p>{client.dob ? client.dob : <span>&ndash;</span>}</p>
          </div>
          <div>
            <h4>Sponsoring Company</h4>
            <p>{client.displayed_employer ? client.displayed_employer : <span>&ndash;</span>}</p>
          </div>
          <div>
            <h4>Email</h4>
            <p>{client.email ? client.email : <span>&ndash;</span>}</p>
          </div>
        </div>
      </div>
    )
  }

  _confirmMatch = (values: any) => {
    this.props.actions.hideAlerts()

    const formData = new FormData()
    const vals = values.toJS()

    if (!this.state.selectedRadioButton) {
      if (vals.canonical_id) {
        delete vals.canonical_id
      }
    }
    if (!this.state.checkboxChecked) {
      if (vals.manual_canonical_id) {
        delete vals.manual_canonical_id
      }
    }

    Object.keys(vals).forEach((key) => {
      formData.append(key, vals[key])
    })

    return this.props.actions.submitMatch(this.props.reconcilerDetails?.lyra_id, formData).then(
      () => {
        // success
        this.props.actions.clearReconcilerList()

        this.props.actions.addAlert({
          show: true,
          contents: "Thank you! The client's identity has been successfully reconciled",
          style: 'success',
          expires: false,
          autoDismissTimer: 8000,
        })

        this._backToDashboard()
      },
      (error: any) => {
        const errorText = error.response.data.message || getErrorText(error.response.status)
        this.props.actions.addAlert({
          show: true,
          contents: errorText,
          style: 'danger',
          expires: true,
        })
      },
    )
  }

  _renderMatchesTable = () => {
    const { handleSubmit, submitting, pristine, invalid, reconcilerDetails, possibleMatches } = this.props
    const filterByCompany = (element: { identity: { employer: string; lyra_id: string } }) => {
      return element.identity.employer === reconcilerDetails?.employer || element.identity.lyra_id === 'no-match'
    }

    return (
      // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      <form onSubmit={handleSubmit(this._confirmMatch)}>
        {/* @ts-expect-error TS(2741): Property 'pagination' is missing in type '{ stripe... Remove this comment to see the full error message */}
        <DataTable
          striped={false}
          highlight={false}
          resizable={true}
          className={styles['possible-matches-table']}
          data={this.state.showAllCompanies ? possibleMatches?.filter(filterByCompany) : possibleMatches}
          columns={this.tableColumns}
        />

        <div styleName='manualId-container'>
          <Field
            name='No Match'
            component={Checkbox}
            className='checkbox'
            type='checkbox'
            onChange={() => {
              if (!this.state.checkboxChecked) {
                if (this.state.selectedRadioButton) {
                  this.setState({ selectedRadioButton: undefined })
                }
              } else this.setState({ manual_lyra_id: undefined })
              this.setState({ checkboxChecked: !this.state.checkboxChecked })
            }}
            disabled={false}
            dataTestId={tID('ReconcilerDetails-manualId-checkbox')}
          >
            <div style={{ marginLeft: 30, color: '#878D97' }}>Resolve ID Manually</div>
          </Field>
          <Field
            name='manual_canonical_id'
            value={this.state.manual_lyra_id}
            onChange={(e: any) => this.setState({ manual_lyra_id: e.target.value })}
            style={{ display: this.state.checkboxChecked ? '' : 'none' }}
            styleName='manualId-field'
            placeholder='Lyra ID'
            component='input'
            data-test-id='manualId-input'
          />
        </div>

        <PrimaryButton
          type='submit'
          data-test-id='lyraId-submit'
          disabled={
            pristine ||
            submitting ||
            invalid ||
            (!this.state.checkboxChecked && !this.state.selectedRadioButton) ||
            (this.state.checkboxChecked && !this.state.manual_lyra_id)
          }
          isLoading={submitting}
        >
          Confirm Match
        </PrimaryButton>
      </form>
    )
  }

  render() {
    // prevent error before punt
    if (!this.props.reconcilerDetails) {
      return false
    }

    return (
      <BootstrapContainer col='col-md-10 col-md-offset-1'>
        <NavButton
          // @ts-expect-error TS(2322): Type '{ className: string; styleType: string; text... Remove this comment to see the full error message
          className={styles['back-button']}
          styleType='back'
          text='Back to List'
          onClick={this._backToDashboard}
        />
        <CardFullWidth styleName='details-card' roundCorners>
          {this._renderClientInfo()}
          <h3>Possible Matches</h3>
          <Field
            name='Company Toggle'
            component={Checkbox}
            className='checkbox'
            type='checkbox'
            onChange={() => {
              if (this.state.selectedRadioButton) {
                this.setState({ selectedRadioButton: undefined })
              }
              this.setState({ showAllCompanies: !this.state.showAllCompanies })
            }}
            disabled={false}
            dataTestId={tID('ReconcilerDetails-companyToggle-checkbox')}
          >
            <div style={{ marginLeft: 30, color: '#878D97' }}>Exclude Results Outside Sponsoring Company</div>
          </Field>
          {!this.props.possibleMatches ? <LoadingIndicator size={30} /> : this._renderMatchesTable()}
        </CardFullWidth>
      </BootstrapContainer>
    )
  }
}

const mapStateToProps = ($$state: any) => {
  return {
    reconcilerDetails: getReconcilerDetailsData($$state),
    possibleMatches: getReconcilerDetailsPossibleMatches($$state),
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    actions: bindActionCreators({ ...reconcilerDetailsActions, ...alertActions, ...reconcilerDataActions }, dispatch),
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    reduxForm({
      form: 'reconcilerForm',
      validate,
      touchOnBlur: false,
      // @ts-expect-error TS(2345): Argument of type 'typeof ReconcilerDetails' is not... Remove this comment to see the full error message
    })(CSSModules(ReconcilerDetails, styles)),
  ),
)
