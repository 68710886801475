import React, { FunctionComponent } from 'react'
import Svg, { ClipPath, Defs, G, Mask, Path, Rect } from 'react-native-svg'

type MemberPreferencesVeteransIllustrationIconProps = {
  size?: number
}

export const MemberPreferencesVeteransIllustrationIcon: FunctionComponent<
  MemberPreferencesVeteransIllustrationIconProps
> = ({ size = 519, ...props }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 519 519' fill='none' {...props} pointerEvents='none'>
      <G clipPath='url(#clip0_3456_28262)'>
        <Path
          d='M266.986 0.825512C231.945 0.825512 197.965 6.88801 165.954 18.8124C89.742 47.2749 36.1616 110.352 18.9504 191.906C7.57954 245.086 9.03961 294.255 23.4192 342.175C41.8028 403.38 86.9103 455 147.172 483.775C177.103 498.106 211.481 506.264 249.333 508.069C254.2 508.292 259.155 508.404 264.066 508.404C294.44 508.404 324.991 504.213 354.879 495.944C357.179 495.365 359.369 494.785 361.67 494.072C388.527 486.137 412.706 473.611 433.391 456.872C455.845 438.796 474.339 415.884 488.276 388.781C491.02 383.454 493.652 377.837 496.55 371.106C512.5 333.795 520.199 292.026 518.85 250.257C518.208 231.802 515.487 212.166 510.753 191.883C502.988 158.963 490.334 128.004 474.14 102.283C469.694 95.3737 465.645 89.5787 461.597 84.3632C432.506 46.5394 387.155 20.016 330.522 7.64582C309.727 3.09895 288.334 0.803223 266.964 0.803223L266.986 0.825512Z'
          fill='white'
        />
        <Mask
          id='mask0_3456_28262'
          mask-type='luminance'
          maskUnits='userSpaceOnUse'
          x={32}
          y={21}
          width={469}
          height={467}
        >
          <Path
            d='M500.682 250.96C500.058 233.264 497.361 214.944 493.082 196.712C486.106 167.36 474.65 138.408 458.938 113.624C455.305 108.008 451.538 102.592 447.482 97.3767C418.018 59.3764 373.353 38.2477 327.642 28.3521C276.737 17.3197 222.578 20.5514 173.657 38.6489C102.849 64.9037 54.641 122.383 38.9282 196.311C32.8883 224.327 31.1276 255.975 33.8244 286.375H33.6238C35.1839 303.648 38.3042 320.52 42.9846 336.032C60.3689 393.512 102.648 439.112 156.473 464.609C185.625 478.45 218.21 485.337 251.329 486.897C285.161 488.457 319.529 484.2 351.289 475.441C353.361 474.928 355.345 474.393 357.44 473.769C382.313 466.481 403.976 455.226 422.52 440.338C442.712 424.202 459.383 403.898 472.288 379.003C475.096 373.587 477.592 368.171 479.999 362.666C495.2 327.362 501.975 289.05 500.615 251.05L500.727 250.938L500.682 250.96Z'
            fill='white'
          />
        </Mask>
        <G mask='url(#mask0_3456_28262)'>
          <Path
            d='M276.294 522.402C422.982 522.402 541.896 403.489 541.896 256.801C541.896 110.114 422.982 -8.7998 276.294 -8.7998C129.607 -8.7998 10.6934 110.114 10.6934 256.801C10.6934 403.489 129.607 522.402 276.294 522.402Z'
            fill='#FFE9E3'
            stroke='#3BA5B5'
            strokeWidth={2.43513}
            strokeMiterlimit={10}
          />
          <Path
            d='M241.267 126.374C244.961 118.269 259.415 91.3966 266.443 78.4396C268.16 75.2853 272.652 75.1655 274.508 78.24L304.016 126.714C304.754 127.932 306.032 128.75 307.45 128.91L361.314 135.119C365.007 135.538 366.744 139.93 364.328 142.765L327.953 185.349C327.095 186.348 326.715 187.665 326.875 188.963L334.042 244.943C334.502 248.557 330.808 251.292 327.494 249.775L277.243 226.736C276.006 226.177 274.588 226.177 273.35 226.736L218.628 251.991C215.214 253.568 211.421 250.633 212.099 246.94L222.581 189.282C222.86 187.805 222.381 186.268 221.323 185.19L181.953 145.081C179.298 142.366 180.815 137.794 184.568 137.215L237.734 129.03C239.291 128.79 240.609 127.772 241.267 126.334V126.374Z'
            fill='#3BA5B5'
          />
          <Path
            d='M40.8117 235.092C92.4743 253.1 231.215 302.757 258.896 312.675C262.038 313.789 265.448 313.812 268.591 312.72L492.135 235.382C501.719 232.061 511.681 239.282 511.525 249.423L511.236 268.412C511.147 274.563 507.202 280.001 501.385 282.007L271.399 361.507C268.279 362.577 264.891 362.577 261.793 361.507L31.1612 280.559C25.2996 278.508 21.377 272.981 21.377 266.763V248.91C21.377 238.859 31.2949 231.793 40.7894 235.092H40.8117Z'
            fill='#85CCD5'
          />
          <Path
            d='M40.9454 307.504C92.7195 325.557 231.103 375.08 258.829 385.02C261.994 386.157 265.426 386.179 268.613 385.065L491.957 307.794C501.63 304.451 511.659 311.716 511.503 321.946L511.213 340.668C511.124 346.864 507.157 352.346 501.295 354.375L271.444 433.83C268.301 434.922 264.891 434.9 261.748 433.83L31.2281 352.948C25.3219 350.876 21.377 345.304 21.377 339.041V321.434C21.377 311.293 31.3618 304.161 40.9454 307.504Z'
            fill='#85CCD5'
          />
          <Path
            d='M43.4862 380.721C96.709 399.308 229.565 446.87 258.205 457.122C261.771 458.393 265.649 458.415 269.237 457.167L489.394 380.988C500.292 377.221 511.637 385.423 511.481 396.968L511.258 411.656C511.146 418.654 506.667 424.85 500.047 427.123L272.068 505.932C268.524 507.158 264.668 507.158 261.124 505.91L32.4985 425.719C25.8345 423.379 21.377 417.094 21.377 410.029V396.433C21.377 384.978 32.6768 376.932 43.4862 380.721Z'
            fill='#85CCD5'
          />
        </G>
      </G>
      <Defs>
        <ClipPath id='clip0_3456_28262'>
          <Rect width={519} height={519} fill='white' transform='translate(0.599609 0.803223)' />
        </ClipPath>
      </Defs>
    </Svg>
  )
}
