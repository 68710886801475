import { createReducer } from '@reduxjs/toolkit'

import { Episode, EpisodeSummary } from '@lyrahealth-inc/shared-app-logic'

import { ProgramConfig, programConfig } from '../../../common/constants/appConstants'
import {
  CLEAR_EPISODES,
  GET_CLIENT_EPISODES,
  GET_CLOSED_EPISODES,
  SELECT_EPISODE,
  UPDATE_EPISODE,
} from '../../../common/constants/reduxConstants'

type EpisodesState = {
  data: Episode[]
  selectedEpisodeIdx?: number
  programConfig?: ProgramConfig
  closedEpisodes?: { [key: string]: EpisodeSummary }
}

const initialState = {
  data: [],
}

export default createReducer<EpisodesState>(initialState, (builder) => {
  builder.addCase(GET_CLIENT_EPISODES, (state, action: any) => {
    state.data = action.data
    return state
  })
  builder.addCase(GET_CLOSED_EPISODES, (state, action: any) => {
    state.closedEpisodes = action.data
    return state
  })
  builder.addCase(UPDATE_EPISODE, (state, action: any) => {
    const updatedEpisode = action.data
    const idx = state.data.findIndex((episode) => episode.id === updatedEpisode.id)
    if (idx !== -1) {
      state.data[idx] = updatedEpisode
    }
    return state
  })
  builder.addCase(SELECT_EPISODE, (state, action: any) => {
    const selectedEpisodeIdx = state.data.findIndex((episode) => episode.id === action.data.episode.id)
    if (selectedEpisodeIdx !== -1) {
      state.selectedEpisodeIdx = selectedEpisodeIdx
      state.programConfig = programConfig[action.data.episode.program_name]
    }

    return state
  })
  builder.addCase(CLEAR_EPISODES, () => initialState)
})
