import React, { useState } from 'react'
import CSSModules from 'react-css-modules'
import { connect, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'

import { BootstrapContainer, CardFullWidth, NavButton, PrimaryButton, SessionsList } from '@lyrahealth-inc/ui-core'
import { toJS } from '@lyrahealth-inc/ui-core-crossplatform'

import styles from './sessionSelectionConfirmation.module.scss'
import { APPOINTMENT_OPERATIONS } from '../../common/constants/appConstants'
import {
  CLIENTS_NEW_SESSION_DETAILS,
  CLIENTS_RESCHEDULE_SESSION_DETAILS,
  CLIENTS_SESSIONS,
} from '../../common/constants/routingConstants'
import { getAuthUserId } from '../../data/auth/authSelectors'
import {
  getClientAppointmentDetails,
  getClientDetailsData,
  getClientSelectedEpisode,
} from '../../data/lyraTherapy/clientSelectors'
import {
  createAppointment,
  createRepeatingAppointment,
  getLTAppointments,
  getLTAppointmentsForPatient,
  setCurrentSessionCount,
  updateAppointment,
} from '../clients/clientDetails/data/appointmentsAutoActions'
import { setToastContent } from '../data/ltToastAutoActions'

function SessionSelectionConfirmation({
  appointment,
  selectedEpisode,
  clientDetails,
  operation,
  actions: {
    createAppointment,
    createRepeatingAppointment,
    updateAppointment,
    getLTAppointmentsForPatient,
    getLTAppointments,
    setCurrentSessionCount,
    setToastContent,
  },
}: any) {
  const providerID: string = useSelector(getAuthUserId)
  const [isSchedulingAppointment, setIsSchedulingAppointment] = useState(false)
  const navigate = useNavigate()

  let headerCTA, submitText, goBack, onSubmit: any
  switch (operation) {
    case APPOINTMENT_OPERATIONS.NEW:
      headerCTA = 'Create new'
      submitText = 'Create session'
      goBack = () => navigate(CLIENTS_NEW_SESSION_DETAILS.route)
      const sessionCount = appointment?.repeatFor || 0
      const appointmentCreationFunction = sessionCount > 1 ? createRepeatingAppointment : createAppointment
      onSubmit = () => {
        return appointmentCreationFunction({
          ...appointment,
          appointment,
          episodeId: selectedEpisode?.id,
          providerPatientId: selectedEpisode?.provider_patient_id,
          providerID,
          intervalDays: appointment?.sessionFrequency,
          sessionCount,
          programId: selectedEpisode?.program_id,
        })
      }
      break
    case APPOINTMENT_OPERATIONS.RESCHEDULE:
      headerCTA = 'Reschedule'
      submitText = 'Reschedule'
      goBack = () => navigate(CLIENTS_RESCHEDULE_SESSION_DETAILS.route)
      onSubmit = () => {
        return updateAppointment({
          providerID,
          ...appointment,
          programId: selectedEpisode.program_id,
        })
      }
      break
  }

  const submitAppointment = () => {
    setIsSchedulingAppointment(true)
    onSubmit()
      .then(() => {
        getLTAppointmentsForPatient({ provider_id: providerID, patient_id: clientDetails?.id }).then(() => {
          setCurrentSessionCount({ episodeId: selectedEpisode?.id })
          getLTAppointments({
            id: providerID,
          }).then(() => {
            navigate(CLIENTS_SESSIONS.route)
            setToastContent({
              text: `Done! Successfully ${
                operation === APPOINTMENT_OPERATIONS.NEW ? 'scheduled' : 'rescheduled'
              } the appointment`,
              id: 'SessionSelectionConfirmation-Toast-Success',
              toastType: 'success',
            })
          })
        })
      })
      .catch(() => {
        setIsSchedulingAppointment(false)
      })
  }

  return (
    <BootstrapContainer col='col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2'>
      <div>
        <NavButton text='Back' styleType='back' onClick={goBack} />
        <h2>{`${headerCTA} session`}</h2>
        <CardFullWidth styleName='details-card' roundCorners>
          <div styleName='container'>
            {/* @ts-expect-error TS(2739): Type '{ userRole: string; appointments: any[]; }' ... Remove this comment to see the full error message */}
            <SessionsList userRole='provider' appointments={[appointment]} />
            <PrimaryButton
              type='submit'
              data-test-id='SessionSelectionConfirmation-submit-button'
              onClick={submitAppointment}
              isLoading={isSchedulingAppointment}
            >
              {submitText}
            </PrimaryButton>
          </div>
        </CardFullWidth>
      </div>
    </BootstrapContainer>
  )
}

SessionSelectionConfirmation.propTypes = {
  operation: PropTypes.oneOf([APPOINTMENT_OPERATIONS.NEW, APPOINTMENT_OPERATIONS.RESCHEDULE]),
  selectedEpisode: PropTypes.object,
  clientDetails: PropTypes.object,
}

const mapStateToProps = ($$state: any) => {
  return {
    appointment: getClientAppointmentDetails($$state),
    selectedEpisode: getClientSelectedEpisode($$state),
    clientDetails: getClientDetailsData($$state),
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    ...bindActionCreators(
      {
        createAppointment,
        createRepeatingAppointment,
        updateAppointment,
        getLTAppointmentsForPatient,
        getLTAppointments,
        setCurrentSessionCount,
        setToastContent,
      },
      dispatch,
    ),
    dispatch,
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(toJS(CSSModules(SessionSelectionConfirmation, styles)))
