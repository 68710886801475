import React, { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { MessageDescriptor } from 'react-intl'
import { LayoutChangeEvent, Platform, View } from 'react-native'
import Animated, { runOnJS, SlideInDown, SlideInLeft, SlideOutDown, SlideOutLeft } from 'react-native-reanimated'

import { noop } from 'lodash-es'
import { CSSObject } from 'styled-components'
import styled, { useTheme } from 'styled-components/native'

import { formatMessageHelper } from '@lyrahealth-inc/shared-app-logic/src/features/intl'

import { AlertIconStroke, InfoIcon, WarningIcon } from '../../atoms'
import { BodyText } from '../../atoms/bodyText/BodyText'
import { CheckIcon } from '../../atoms/icons/CheckIcon'
import { BodyTextSize } from '../../styles/typeStyles'
import { ToastTypes } from '../../ui-models/toast/Toast'
import { ThemeType, tID } from '../../utils'

const ToastContainer = styled.View<{ theme: ThemeType; toastHeight: number; containerStyle: CSSObject }>(
  ({ theme, containerStyle, toastHeight }) => {
    const margin = theme.breakpoints.isMinWidthTablet ? theme.spacing['40px'] : theme.spacing['16px']
    const horizontalCenterOnMobile = {
      alignItems: 'center',
      display: 'flex',
    }
    return {
      position: Platform.OS === 'web' ? 'fixed' : 'absolute', // Avoid fixed on mobile bc toast will overlap the bottom tab navigator
      bottom: margin,
      left: margin,
      right: margin,
      zIndex: 101,
      maxWidth: theme.breakpoints.isMinWidthTablet ? '426px' : 'auto',
      ...(Platform.OS === 'web' && { height: toastHeight }), // Height must be set for the toast to animate correctly on web
      ...((Platform.OS === 'ios' || Platform.OS === 'android') && horizontalCenterOnMobile),
      ...containerStyle,
    }
  },
)

const ToastContentContainer = styled.View<{ theme: ThemeType; toastType: ToastTypes }>(
  ({ toastType, theme: { breakpoints, spacing, colors } }) => ({
    backgroundColor: toastType ? colors.components.toast[toastType].background : undefined,
    borderRadius: spacing['16px'],
    padding: spacing['16px'],
    flexDirection: 'row',
    alignItems: 'center',
    width: Platform.OS === 'web' && breakpoints.isMinWidthTablet ? '426px' : '343px',
    zIndex: 9,
  }),
)

const ToastTextContainer = styled.View({
  width: 'auto',
  paddingRight: '32px',
})

const IconContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  marginRight: spacing['8px'],
}))

/**
 * Displays a temporary message when id changes.
 * - Toast slides in and out from the left for web, up and down from the bottom for mobile
 */
export const Toast: FunctionComponent<ToastProps> = ({
  text,
  id = 'toast',
  wrapText = false,
  toastType = 'success',
  delay = 500,
  duration = 3000,
  textSize = BodyTextSize.SMALL,
  onAnimationEnd = noop,
  containerStyle = {},
}) => {
  const { colors } = useTheme()
  const [showToast, setShowToast] = useState(false)
  const [toastHeight, setToastHeight] = useState<number>(0)
  const enterDuration = 150
  const exitDuration = 300
  const transitionEntering = Platform.OS === 'web' ? SlideInLeft : SlideInDown
  const transitionExiting = Platform.OS === 'web' ? SlideOutLeft : SlideOutDown

  useEffect(() => {
    setShowToast(true)
    const timeoutID = window.setTimeout(() => {
      setShowToast(false)
    }, duration + delay + enterDuration)
    return () => window.clearTimeout(timeoutID)
  }, [duration, delay, enterDuration])

  const onLayout = (event: LayoutChangeEvent) => {
    if (Platform.OS !== 'web') {
      return
    }
    // Add delay to setting height, otherwise the toast will render early and flicker when the animation starts
    const height = event.nativeEvent.layout.height
    setTimeout(() => {
      setToastHeight(height)
    }, delay)
  }

  const icon = useMemo(() => {
    switch (toastType) {
      case 'success':
        return <CheckIcon fillColor={colors.iconInverse} size={24} />
      case 'info':
        return <InfoIcon fillColor={colors.iconInverse} size={24} />
      case 'error':
        return <WarningIcon fillColor={colors.iconInverse} isFilled={false} size={24} />
      case 'warning':
        return <AlertIconStroke fillColor={colors.iconInverse} size={24} />
      case '':
        return null
    }
  }, [colors.iconInverse, toastType])

  return (
    <ToastContainer testID={tID('toast')} containerStyle={containerStyle} toastHeight={toastHeight}>
      <View>
        {showToast && (
          <Animated.View
            testID={tID(id)}
            entering={transitionEntering.delay(delay).duration(enterDuration)}
            exiting={transitionExiting.duration(exitDuration).withCallback((finished: boolean) => {
              'worklet'
              if (finished) {
                runOnJS(onAnimationEnd)()
              }
            })}
            onLayout={onLayout}
          >
            <ToastContentContainer toastType={toastType}>
              <IconContainer>{icon}</IconContainer>
              <ToastTextContainer>
                <BodyText
                  text={typeof text === 'string' ? text : formatMessageHelper(text)}
                  size={textSize}
                  color={colors.textInverse}
                  wrap={wrapText}
                />
              </ToastTextContainer>
            </ToastContentContainer>
          </Animated.View>
        )}
      </View>
    </ToastContainer>
  )
}

export type ToastProps = {
  text: string | MessageDescriptor
  id?: string
  toastType?: ToastTypes
  delay?: number
  duration?: number
  textSize?: BodyTextSize
  onAnimationEnd?: () => void
  containerStyle?: CSSObject
  wrapText?: boolean
}
