import React, { Component } from 'react'

import classNames from 'classnames'
import { isFunction } from 'lodash-es'

import styles from './formContentHeader.module.scss'
import DefaultButton from '../../atoms/buttons/defaultButton/DefaultButton'
import TextButton, { TextButtonAlign } from '../../atoms/buttons/textButton/TextButton'
import ChevronIcon from '../../atoms/icons/ChevronIcon'
import LtIcon from '../assignmentsList/LtIcon'

type Props = {
  title?: string
  date?: string
  isDraft?: boolean
  showNext?: () => void
  showPrevious?: () => void
  group?: string
  dataLength?: number
  responseIdx?: number
  deleteDraft?: () => void
  children: JSX.Element
}

class FormContentHeader extends Component<Props> {
  handleDeleteDraft = () => {
    const { deleteDraft } = this.props
    return window.confirm('Are you sure you want to delete this draft?') ? deleteDraft && deleteDraft() : false
  }

  render() {
    const { title, date, group, children, showPrevious, showNext, dataLength, responseIdx, isDraft } = this.props
    return (
      <div>
        <div className={styles['title-container']}>
          <LtIcon type={group} size={24} />
          <div className={styles.title}>
            <h3>{title}</h3>
          </div>
        </div>
        {date}
        {isDraft && (
          <span>
            <TextButton isSmall align={TextButtonAlign.LEFT} onClick={this.handleDeleteDraft}>
              Delete draft
            </TextButton>
          </span>
        )}
        <div>{children}</div>
        <div>
          {isFunction(showPrevious) && (
            <DefaultButton
              onClick={showPrevious}
              customClass={classNames(styles.arrow, styles.left, { [styles.disabled]: responseIdx === 0 })}
              unstyled
              aria-label='previous submission'
            >
              <ChevronIcon width={50} isFilled direction='left' />
            </DefaultButton>
          )}
          {isFunction(showNext) && (
            <DefaultButton
              onClick={showNext}
              customClass={classNames(
                styles.arrow,
                styles.right,
                responseIdx !== undefined && dataLength && { [styles.disabled]: responseIdx >= dataLength - 1 },
              )}
              unstyled
              aria-label='next submission'
              data-test-id='FormContentHeader-nextFormButton'
            >
              <ChevronIcon width={50} isFilled direction='right' />
            </DefaultButton>
          )}
        </div>
      </div>
    )
  }
}

export default FormContentHeader
