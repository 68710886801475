import { createSelector } from '@reduxjs/toolkit'

import { getReconcilerState } from '../../data/reconcilerSelectors'

export const getReconcilerDetails = createSelector(
  getReconcilerState,
  (reconcilerState) => reconcilerState?.reconcilerDetails,
)

export const getReconcilerDetailsData = createSelector(
  getReconcilerDetails,
  (reconcilerDetails) => reconcilerDetails?.data,
)

export const getReconcilerDetailsPossibleMatches = createSelector(
  getReconcilerDetails,
  (reconcilerDetails) => reconcilerDetails?.possibleMatches,
)
