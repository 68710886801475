import React, { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'

import { useTheme } from 'styled-components/native'

import { VideoControlButton } from './VideoControlButton'
import { tID } from '../../utils'
import { EndCallIcon } from '../icons/EndCallIcon'

export type EndVideoControlButtonProps = {
  disabled: boolean
  color?: string
  iconColor?: string
  onPress: () => void
  size?: number
}

export const EndVideoControlButton: FunctionComponent<EndVideoControlButtonProps> = ({
  disabled,
  color,
  iconColor,
  onPress,
  size = 64,
}) => {
  const { formatMessage } = useIntl()
  const { colors } = useTheme()
  const { endButton } = colors.components.videoControlButton

  return (
    <VideoControlButton
      enabledColor={color || endButton.fill}
      enabledIcon={<EndCallIcon fillColor={iconColor || colors.iconInverse} />}
      disabledColor={color || endButton.fill}
      disabledIcon={<EndCallIcon fillColor={iconColor || colors.iconInverse} />}
      onPress={onPress}
      disabled={disabled}
      testID={tID('end')}
      size={size}
      accessibilityLabel={formatMessage({
        defaultMessage: 'End call',
        description: 'End call button to drop the call',
      })}
    />
  )
}
