import React, { FunctionComponent } from 'react'

import styled from 'styled-components/native'

import { Image } from '../image/Image'

/**
 * This component contains a couch illustration that is in the video session preview screen
 */

const WaitingRoomPNG = styled(Image)(({ theme }) => ({
  width: theme.breakpoints.isMobileSized ? '241px' : '557px',
  height: theme.breakpoints.isMobileSized ? '141px' : '336px',
}))

export const WaitingRoom: FunctionComponent = () => {
  return <WaitingRoomPNG source={require('./../../assets/waitingRoom.png')} />
}
