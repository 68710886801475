import React, { FunctionComponent } from 'react'
import { Path, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

/**
 * Add to Calendar Icon that can be used for adding an appointment to a calendar
 */
export const AddToCalendarIcon: FunctionComponent<AddToCalendarIconProps> = ({ size = 24, fillColor }) => {
  const { colors } = useTheme()

  return (
    <Svg width={size} height={size} fill='none'>
      <Path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M15.0012 9.25H19.5012V9.75C19.5012 9.94891 19.5802 10.1393 19.7208 10.28C19.8615 10.4207 20.0523 10.5 20.2512 10.5C20.4501 10.5 20.6409 10.4207 20.7815 10.28C20.9222 10.1393 21.0012 9.94891 21.0012 9.75V5.625C21.0201 5.04842 20.8097 4.48786 20.416 4.06612C20.0224 3.64438 19.4777 3.39586 18.9012 3.375H17.0012V2.25C17.0012 2.05109 16.9222 1.86032 16.7815 1.71967C16.6409 1.57902 16.4501 1.5 16.2512 1.5C16.0523 1.5 15.8615 1.57902 15.7208 1.71967C15.5802 1.86032 15.5012 2.05109 15.5012 2.25V3.375H7.00117V2.25C7.00117 2.05109 6.92215 1.86032 6.7815 1.71967C6.64085 1.57902 6.45008 1.5 6.25117 1.5C6.05226 1.5 5.86149 1.57902 5.72084 1.71967C5.58019 1.86032 5.50117 2.05109 5.50117 2.25V3.375H3.60117C3.02466 3.39586 2.47992 3.64438 2.0863 4.06612C1.69268 4.48786 1.48227 5.04842 1.50117 5.625L1.50117 19C1.48227 19.5766 1.69268 20.1371 2.0863 20.5589C2.47992 20.9806 3.02466 21.2291 3.60117 21.25H9.76172C9.96063 21.25 10.1511 21.171 10.2917 21.0303C10.4324 20.8897 10.5 20.6989 10.5 20.5C10.5 20.3011 10.4324 20.1103 10.2917 19.9697C10.1511 19.829 9.96063 19.75 9.76172 19.75H9.75906H7.50117H3.60117C3.42333 19.7274 3.26125 19.6365 3.14926 19.4965C3.03727 19.3565 2.98416 19.1785 3.00117 19V16V14.5V9.25H7.50117H15.0012ZM5.50008 4.6272H3.60008C3.42223 4.64978 3.26523 4.74068 3.15324 4.88067C3.04124 5.02066 2.98813 5.19874 3.00515 5.3772L3.00507 7.75H19.5051L19.5052 5.3772C19.5222 5.19874 19.4691 5.02066 19.3571 4.88067C19.2451 4.74068 19.083 4.64978 18.9052 4.6272H17.0051L17.0051 5.74882C17.0051 5.94773 16.9261 6.1385 16.7854 6.27915C16.6447 6.4198 16.454 6.49882 16.2551 6.49882C16.0562 6.49882 15.8654 6.4198 15.7247 6.27915C15.5841 6.1385 15.5051 5.94773 15.5051 5.74882L15.5051 4.6272H7.00008L7.00507 5.74882C7.00507 5.94773 6.92605 6.1385 6.7854 6.27915C6.64475 6.4198 6.45398 6.49882 6.25507 6.49882C6.05616 6.49882 5.86539 6.4198 5.72474 6.27915C5.58409 6.1385 5.50507 5.94773 5.50507 5.74882L5.50008 4.6272Z'
        fill={fillColor || colors.iconActive}
      />
      <Path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M16.5 22.5C19.8137 22.5 22.5 19.8137 22.5 16.5C22.5 13.1863 19.8137 10.5 16.5 10.5C13.1863 10.5 10.5 13.1863 10.5 16.5C10.5 19.8137 13.1863 22.5 16.5 22.5ZM19 17.25C19.4142 17.25 19.75 16.9143 19.75 16.5C19.75 16.0858 19.4142 15.75 19 15.75L17.2554 15.75L17.2554 13.992C17.2554 13.5763 16.9184 13.2393 16.5027 13.2393C16.087 13.2393 15.75 13.5763 15.75 13.992L15.75 15.75H14C13.5858 15.75 13.25 16.0858 13.25 16.5C13.25 16.9143 13.5858 17.25 14 17.25H15.75V18.9986C15.75 19.4143 16.087 19.7513 16.5027 19.7513C16.9184 19.7513 17.2554 19.4143 17.2554 18.9986V17.25L19 17.25Z'
        fill={fillColor || colors.iconActive}
      />
    </Svg>
  )
}

export type AddToCalendarIconProps = {
  size?: string | number
  fillColor?: string
}
