import React from 'react'

type OwnProps = {
  width?: string | number
}

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof PeopleIcon.defaultProps

// @ts-expect-error TS(7022): 'PeopleIcon' implicitly has type 'any' because it ... Remove this comment to see the full error message
const PeopleIcon = ({ width }: Props) => {
  const colorBg = '#FDE5E8'
  const colorFill = '#F598A3'

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={width} viewBox={`0 0 ${width} ${width}`}>
      <g fill='none' fillRule='evenodd'>
        <circle fill={colorBg} cx='18' cy='18' r='18' fillRule='nonzero' />
        <g transform='translate(6 6)'>
          <polygon points='0 24 24 24 24 0 0 0' />
          <path
            fill={colorFill}
            fillRule='nonzero'
            stroke='#FFF'
            strokeOpacity='.5'
            strokeWidth='1.65'
            d='M8.5,5.14423828 C10.8885683,5.14423828 12.825,7.08067003 12.825,9.46923828 C12.825,11.8578065 10.8885683,13.7942383 8.5,13.7942383 C6.11143175,13.7942383 4.175,11.8578065 4.175,9.46923828 C4.175,7.08067003 6.11143175,5.14423828 8.5,5.14423828 Z M11.25,13.175 C13.2293417,13.175 14.825,14.8673649 14.825,16.9166667 L14.825,18.375 C14.825,19.1651351 14.2028417,19.825 13.4107143,19.825 L3.58928571,19.825 C2.79715831,19.825 2.175,19.1651351 2.175,18.375 L2.175,16.9166667 C2.175,14.8673649 3.77065831,13.175 5.75,13.175 L11.25,13.175 Z M22.325,14.4 C22.325,15.1796258 21.6936557,15.825 20.9130435,15.825 L14.0947021,15.825 L13.8867325,15.3077733 C13.7137174,14.8774797 13.4880672,14.5443763 13.2200546,14.2851959 C13.1091809,14.1779759 11.6081698,13.4501091 11.7056687,12.7675071 C11.9159765,11.295117 13.1698037,10.175 14.6521739,10.175 L19.3478261,10.175 C20.9893079,10.175 22.325,11.5403742 22.325,13.2 L22.325,14.4 Z M13.175,6.96923828 C13.175,4.85680336 14.8875651,3.14423828 17,3.14423828 C19.1124349,3.14423828 20.825,4.85680336 20.825,6.96923828 C20.825,9.0816732 19.1124349,10.7942383 17,10.7942383 C14.8875651,10.7942383 13.175,9.0816732 13.175,6.96923828 Z'
          />
        </g>
      </g>
    </svg>
  )
}

PeopleIcon.defaultProps = {
  width: 36,
}

export default PeopleIcon
