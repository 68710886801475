import { combineReducers } from '@reduxjs/toolkit'

// Reducers
import data from './clientsDataReducer'
import clientDetails from '../individualClient/data/clientDetailsReducer'

export default combineReducers({
  clientDetails,
  data,
})
