import React, { FunctionComponent } from 'react'
import { ViewStyle } from 'react-native'
import { Path, Rect, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { tID } from '../../utils/utils'

/**
 * Updated (X) icon with border
 */
export const XIconRound: FunctionComponent<XIconRoundProps> = ({ size = 24, fillColor, backgroundColor, style }) => {
  const { colors } = useTheme()
  const { xIconRound } = colors.icons

  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' style={style} testID={tID('XIconRound')}>
      <Rect
        x='0.75'
        y='0.75'
        width='18.5'
        height='18.5'
        rx='9.25'
        stroke={xIconRound.border}
        strokeWidth='1.5'
        fill={backgroundColor}
      />
      <Path
        stroke={fillColor || xIconRound.fill}
        d='M13 7L10 10M7 13L10 10M10 10L7 7M10 10L13 13'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </Svg>
  )
}

type XIconRoundProps = {
  size?: number
  fillColor?: string
  backgroundColor?: string
  style?: ViewStyle
}
