import {
  GET_PAYMENT_HISTORY_OF_TYPE,
  PAYMENT_HISTORY_SHOW_PAYMENT_MODAL,
  PAYMENT_HISTORY_HIDE_PAYMENT_MODAL,
  CLEAR_PAYMENT_HISTORY_STORE,
} from '../../common/constants/reduxConstants'

export const showTransfersModal = (data: any) => {
  return { type: PAYMENT_HISTORY_SHOW_PAYMENT_MODAL, data }
}
export const hideTransfersModal = () => {
  return { type: PAYMENT_HISTORY_HIDE_PAYMENT_MODAL }
}
export const getPaymentsOfStatus = ({ status, page, providerID, practiceID }: any) => ({
  action: { type: GET_PAYMENT_HISTORY_OF_TYPE, page, status },
  request: {
    method: 'get',
    url: '/v1/transfers',
    params: {
      status,
      page,
      provider_id: providerID,
      practice_id: practiceID,
    },
  },
})

export const clearPaymentHistoryStore = () => {
  return {
    type: CLEAR_PAYMENT_HISTORY_STORE,
  }
}
