import { createSelector } from 'reselect'

import { getLyraTherapyState } from '../../../data/lyraTherapy/clientSelectors'

export const getMessenger = createSelector(getLyraTherapyState, (lyraTherapyState) => lyraTherapyState?.messenger)

export const getSelectedConversation = createSelector(getMessenger, (messenger) => messenger?.selectedConversation)

export const getMessengerDrafts = createSelector(getMessenger, (messenger) => messenger?.drafts)

export const getMessagesFetched = createSelector(getMessenger, (messenger) => messenger?.messagesFetched)

export const getMessagesConversationsFetched = createSelector(
  getMessenger,
  (messenger) => messenger?.conversationsFetched,
)

export const getConversationsClient = createSelector(getMessenger, (messenger) => messenger?.conversationsClient)

export const inLiveMsgSession = createSelector(getMessenger, (messenger) => messenger?.inLiveMsgSession)

export const getMessagesActiveLiveMsgAppt = createSelector(getMessenger, (messenger) => messenger?.activeLiveMsgAppt)

export const getConversations = createSelector(getMessenger, (messenger) => messenger?.conversations)

export const getConversationMessages = createSelector(
  getMessenger,
  (messenger) => messenger?.messages?.conversation_messages,
)

export const getMessagesLiveMsgClientIdToOpen = createSelector(
  getMessenger,
  (messenger) => messenger?.liveMsgClientIdToOpen,
)
