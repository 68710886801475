import { NavigateFunction } from 'react-router'

import Cookies from 'universal-cookie'

import * as mixpanelTracker from '../../../mixpanel/mixpanelTracking'
import {
  actionAlertHandler,
  actionStyles,
  CLEAR_HISTORIC_UTILIZATION,
  CLEAR_LT_CLIENTS,
  CLEAR_MESSAGES,
  LOGIN,
  LOGOUT,
  REFRESH_API_TOKEN,
  REGISTER_SUCCESSFUL,
  REPLACE_USER,
  RESET_VIDEO_SESSION_INFO,
  SAVE_LAST_AUTHORIZED_ROUTE,
} from '../common/constants/reduxConstants'
import { authenticate, closeOktaSession } from '../common/http/data/auth'

const cookies = new Cookies()

export const login = (query: any, navigate: NavigateFunction) => {
  const request = authenticate(query)
  return (dispatch: any) => {
    return new Promise(function () {
      request
        .then((authReturn) => {
          if (authReturn.data.access_token) {
            mixpanelTracker.setUser(authReturn.data.user)
            mixpanelTracker.track({ event: 'LOGIN' })

            dispatch({
              type: LOGIN,
              payload: authReturn.data,
            })
          } else {
            actionAlertHandler({
              actionStyle: actionStyles.ERROR,
              message: 'There was an error in retrieving an access token. Please try again.',
              dispatch,
            })
          }
        })
        .catch((error) => {
          navigate('/login')
          actionAlertHandler({
            actionStyle: actionStyles.ERROR,
            message: error,
            dispatch,
          })
        })
    })
  }
}

export const logout = (clearLastPage: any) => {
  return (dispatch: any) => {
    const request = closeOktaSession()
    return new Promise(function () {
      request.finally(() => {
        // remove python session cookie
        cookies.remove('session', { path: '/' })
        if (clearLastPage) {
          dispatch({
            type: SAVE_LAST_AUTHORIZED_ROUTE,
            payload: '',
          })
        }
        mixpanelTracker.track({ event: 'LOGOUT' })
        dispatch({
          type: LOGOUT,
        })
      })
    })
  }
}

export const saveLastAuthorizedRoute = (location: any) => {
  return (dispatch: any) => {
    dispatch({
      type: SAVE_LAST_AUTHORIZED_ROUTE,
      payload: `${location.pathname}${location.search}`,
    })
  }
}

export const registerSuccessful = () => {
  mixpanelTracker.track({ event: 'REGISTER' })
  return {
    type: REGISTER_SUCCESSFUL,
  }
}

export const refreshApiToken = (token: any) => {
  return {
    type: REFRESH_API_TOKEN,
    payload: token,
  }
}

export const supervisorSelectProvider = (user: any) => (dispatch: any) => {
  dispatch({ type: REPLACE_USER, user })
  // Clear Actions
  dispatch({ type: CLEAR_LT_CLIENTS })
  dispatch({ type: CLEAR_MESSAGES })
  dispatch({ type: RESET_VIDEO_SESSION_INFO })
  dispatch({ type: CLEAR_HISTORIC_UTILIZATION })
}
