import { Map } from 'immutable'
import { createSelector } from 'reselect'

import healthPlanReducer from '../healthPlanReducer'

const getHealthPlanState = (state: Map<string, any>) => state?.get('healthPlan') as ReturnType<typeof healthPlanReducer>

export const getHealthPlanICD10s = createSelector(getHealthPlanState, (healthPlanState) => healthPlanState?.icd10s)

export const getHealthPlanCurrentCharge = createSelector(
  getHealthPlanState,
  (healthPlanState) => healthPlanState?.currentCharge,
)

export const getHealthPlanHPEligibility = createSelector(
  getHealthPlanState,
  (healthPlanState) => healthPlanState?.HPEligibility,
)
