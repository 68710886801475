import { useCallback, useEffect, useState } from 'react'

import { DurationInputArg2 } from 'moment'
import moment from 'moment-timezone'

import { Appointment } from '../../../models'
import { getAppointmentDateTimeObject } from '../utils'

export const sortAppts = (appts: Appointment[]): Appointment[] => {
  appts.sort((a, b) => {
    const aDateTime = getAppointmentDateTimeObject(a)
    const bDateTime = getAppointmentDateTimeObject(b)
    return aDateTime.valueOf() - bDateTime.valueOf()
  })
  return appts
}

/**
 * Take a list of appointments and return current, past, future, and active
 */
export const useApptsByDateTime = ({
  appts,
  timeZone = moment.tz.guess(true),
  increment = 0,
  decrement = 0,
  unitOfTime = 'minutes',
  sort = false,
  useApptDuration = false,
  setCancelledIntoPast = false,
}: UseApptsByDateTimeParams): UseApptsByDateTimeParamsReturn => {
  const [result, setResult] = useState<ReturnAppointments>({
    currentAppts: [],
    pastAppts: [],
    futureAppts: [],
    activeAppts: [],
  })
  const getApptsByDateTime = useCallback(() => {
    const appointments = sort ? sortAppts(appts) : appts
    const apptsByDataTime = appointments.reduce(
      (res: ReturnAppointments, appt: Appointment): ReturnAppointments => {
        const d = useApptDuration && decrement > 0 ? appt.appointmentDuration : decrement
        const i = useApptDuration && increment > 0 ? appt.appointmentDuration : increment
        const startTime = getAppointmentDateTimeObject(appt).tz(timeZone).add(i, unitOfTime).subtract(d, unitOfTime)
        const endTime = getAppointmentDateTimeObject(appt).tz(timeZone).add(appt.appointmentDuration, unitOfTime)

        const currentTime = moment.duration(startTime.diff(moment()))

        // current
        if (currentTime.asMilliseconds() === 0) {
          res.currentAppts.push(appt)
        }

        if (setCancelledIntoPast) {
          // past
          if (startTime.isBefore() || (!startTime.isBefore() && appt.appointmentStatus === 'canceled')) {
            res.pastAppts.push(appt)
          }
          // future
          if (startTime.isAfter() && appt.appointmentStatus !== 'canceled') {
            res.futureAppts.push(appt)
          }
        } else {
          // past
          if (startTime.isBefore()) {
            res.pastAppts.push(appt)
          }
          // future
          if (startTime.isAfter()) {
            res.futureAppts.push(appt)
          }
        }
        // set any active appointments
        if (moment().isBetween(startTime, endTime, unitOfTime, '[]')) {
          res.activeAppts.push(appt)
        }
        return res
      },
      {
        currentAppts: [],
        pastAppts: [],
        futureAppts: [],
        activeAppts: [],
      },
    )
    setResult(apptsByDataTime)
  }, [appts, decrement, increment, setCancelledIntoPast, sort, timeZone, unitOfTime, useApptDuration])

  useEffect(() => getApptsByDateTime(), [getApptsByDateTime])
  return { ...result, getApptsByDateTime }
}

type UseApptsByDateTimeParams = {
  appts: Appointment[]
  timeZone?: string
  decrement?: number // subtract appointment datetime from what is current
  increment?: number // add appointment datetime from what is current
  unitOfTime?: DurationInputArg2 // by minutes, seconds, etc
  sort?: boolean
  useApptDuration?: boolean // use the appointment duration as increment/delay
  setCancelledIntoPast?: boolean // by default canceled appointments in the future show up in Upcoming
}

type ReturnAppointments = {
  currentAppts: Appointment[]
  pastAppts: Appointment[]
  futureAppts: Appointment[]
  activeAppts: Appointment[]
}
interface UseApptsByDateTimeParamsReturn extends ReturnAppointments {
  getApptsByDateTime: () => void
}
